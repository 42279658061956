import includes from 'lodash/includes';
import { useMemo } from 'react';

import { convertToFilterModel, parseFilterModelFromQueryState } from '../filter.helpers';
import { FilterModel, FilterOption } from '../filter.types';

export const useFilterOptionsWithQueryState = <FilterKey extends string = string>(
  options: FilterOption<FilterKey>[],
  queryState?: string,
) => {
  const filterModel = useMemo(() => parseFilterModelFromQueryState<FilterKey>(queryState), [queryState]);

  const optionsMergedWithQueryState = useMemo(() => {
    return options.map(o => {
      const filter = filterModel[o.value];
      const selectedChildren = filter?.values ?? [];

      return {
        ...o,
        selected: Boolean(filter),
        operator: filter?.operator ?? o.operator,
        children: o.children.map(c => ({ ...c, selected: includes(selectedChildren, c.value) })),
      };
    });
  }, [options, filterModel]);

  /**
   * Converts the selected options or a given filter model to a query state ready string
   * @param modelOrOptions
   * @returns An encoded json string or null if there are no filter keys
   */
  const convertFilterModelToQueryState = (modelOrOptions: FilterModel | FilterOption[]): string | null => {
    let filterModel: FilterModel;
    if (Array.isArray(modelOrOptions)) {
      filterModel = convertToFilterModel(modelOrOptions);
    } else {
      filterModel = modelOrOptions;
    }

    if (Object.keys(filterModel).length === 0) {
      return null;
    }

    return encodeURIComponent(JSON.stringify(filterModel));
  };

  return {
    options: optionsMergedWithQueryState,
    filterModel,
    convertFilterModelToQueryState,
  };
};
