import { MAX_NUMBER_FOR_RANGES } from '@circadian-risk/data-utils';
import { useCallback, useRef } from 'react';
import { v4 } from 'uuid';

export const usePropertyHtmlName = () => {
  const nameToIdStore = useRef<Record<string, string>>({});
  const idToNameStore = useRef<Record<string, string>>({});

  const convertNameToId = useCallback((name: string) => {
    if (nameToIdStore.current[name]) {
      return nameToIdStore.current[name];
    }

    const uId = v4();
    nameToIdStore.current[name] = uId;
    idToNameStore.current[uId] = name;
    return uId;
  }, []);

  const convertIdToName = useCallback((id: string) => {
    if (idToNameStore.current[id]) {
      return idToNameStore.current[id];
    }

    throw new Error(`Attempted to access name for unrecognized id ${id}`);
  }, []);

  return {
    convertNameToId,
    convertIdToName,
  };
};

const generateRangeOption = (min: number, max: number | 'infinite') => {
  const isMinMaxSame = max === min;
  const maxLabel = isMinMaxSame ? '' : ` - ${max}`;

  const label = max === 'infinite' ? `${min}+` : `${min}${maxLabel}`;
  const value = { min, max: max === 'infinite' ? MAX_NUMBER_FOR_RANGES : max };
  return { label, value: JSON.stringify(value) };
};

/**
 * Helper Function that will generate options for the SelectInput of specified ranges like `[100 - 1000, 1001 - 1000]`
 *
 * Important: you will need to parse value to get `min` and `max` numbers
 *
 * Examples:
 * - If two values are the same it will output
 *   - label: `1`
 *   - value: stringified object - `{ min: 1, max: 1 }`
 * - If max value is `infinite`:
 *   - label: `100+`
 *   - value: stringified object - `{min: 100, {MAX_NUMBER_FOR_RANGES}}`)
 */
export const generateSelectRangeOptions = (ranges: [number, number | 'infinite'][]) =>
  ranges.map(([min, max]) => generateRangeOption(min, max));
