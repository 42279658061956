import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { IconButton, IconButtonOwnProps, styled, Tooltip } from '@mui/material';
import React from 'react';

const PointerIconButton = styled(IconButton)(() => ({
  '*:hover': {
    cursor: 'pointer !important',
  },
}));

export const DeficiencyIncludedToggle: React.FC<{
  included: boolean | undefined;
  onDeficiencyIncludedToggle: () => void;
  canExclude: boolean;
}> = ({ included, onDeficiencyIncludedToggle, canExclude }) => {
  if (included == null || onDeficiencyIncludedToggle == null) return null;

  const {
    icon: Icon,
    title,
    color,
  }: {
    color: IconButtonOwnProps['color'];
    title: string;
    icon: typeof RemoveCircleIcon;
  } = included
    ? {
        title: canExclude ? 'Exclude deficiency' : 'There must be at least one deficiency selected.',
        icon: RemoveCircleIcon,
        color: 'error',
      }
    : { title: 'Include deficiency', icon: AddCircleIcon, color: 'primary' };

  return (
    <Tooltip title={title} enterDelay={1000}>
      <PointerIconButton
        color={color}
        // Can always include, can only exclude if there is at least one other deficiency included
        disabled={included && !canExclude}
        onClick={evt => {
          evt.stopPropagation();
          onDeficiencyIncludedToggle();
        }}
      >
        <Icon />
      </PointerIconButton>
    </Tooltip>
  );
};
