import { z } from 'zod';

export const isValidLatitude = (latitude: number) => isFinite(latitude) && Math.abs(latitude) <= 90;
export const isValidLongitude = (longitude: number) => isFinite(longitude) && Math.abs(longitude) <= 180;

const invalidLatMessage = 'Latitude value must be between -90 and 90'
const invalidLongMessage = 'Longitude value must be between -180 and 180'

export const latitudeSchema = z.number().refine(isValidLatitude, invalidLatMessage);
export const longitudeSchema = z.number().refine(isValidLongitude, invalidLongMessage);

export const customCoordinatesSchema = z.object({
    lat: latitudeSchema,
    long: longitudeSchema,
})

/**
 * Validates the coordinates following Mapbox lat/long range values
 * @see https://docs.mapbox.com/help/glossary/lat-lon/
 * @param param0 
 * @returns 
 */
export const validateCoordinates = ({ lat, long }: { lat?: number, long?: number }): { error?: string, field?: 'latitude' | 'longitude' } => {
    if (!lat || !isValidLatitude(lat)) {
        return { error: 'Latitude value must be between -90 and 90', field: 'latitude' }
    }

    if (!long || !isValidLongitude(long)) {
        return { error: 'Longitude value must be between -180 and 180', field: 'longitude' }
    }

    return { error: undefined, field: undefined }
}

export const safeParseCoordinates = (coordinates: unknown | z.infer<typeof customCoordinatesSchema>) => {
    const coordinatesParseResult = customCoordinatesSchema.safeParse(coordinates);
    return coordinatesParseResult.success ? coordinatesParseResult.data : null
}