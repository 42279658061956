export const timezones = [
  {
    name: 'Singapore',
    abbrev: '+08',
  },
  {
    name: 'MST7MDT',
    abbrev: 'MST',
  },
  {
    name: 'NZ-CHAT',
    abbrev: '+1345',
  },
  {
    name: 'GMT0',
    abbrev: 'GMT',
  },
  {
    name: 'Asia/Kuwait',
    abbrev: '+03',
  },
  {
    name: 'Asia/Singapore',
    abbrev: '+08',
  },
  {
    name: 'Asia/Vientiane',
    abbrev: '+07',
  },
  {
    name: 'Asia/Nicosia',
    abbrev: 'EET',
  },
  {
    name: 'Asia/Macao',
    abbrev: 'CST',
  },
  {
    name: 'Asia/Jakarta',
    abbrev: 'WIB',
  },
  {
    name: 'Asia/Urumqi',
    abbrev: '+06',
  },
  {
    name: 'Asia/Omsk',
    abbrev: '+06',
  },
  {
    name: 'Asia/Dubai',
    abbrev: '+04',
  },
  {
    name: 'Asia/Pontianak',
    abbrev: 'WIB',
  },
  {
    name: 'Asia/Bangkok',
    abbrev: '+07',
  },
  {
    name: 'Asia/Ashkhabad',
    abbrev: '+05',
  },
  {
    name: 'Asia/Phnom_Penh',
    abbrev: '+07',
  },
  {
    name: 'Asia/Dhaka',
    abbrev: '+06',
  },
  {
    name: 'Asia/Kuching',
    abbrev: '+08',
  },
  {
    name: 'Asia/Yekaterinburg',
    abbrev: '+05',
  },
  {
    name: 'Asia/Shanghai',
    abbrev: 'CST',
  },
  {
    name: 'Asia/Vladivostok',
    abbrev: '+10',
  },
  {
    name: 'Asia/Irkutsk',
    abbrev: '+08',
  },
  {
    name: 'Asia/Muscat',
    abbrev: '+04',
  },
  {
    name: 'Asia/Thimbu',
    abbrev: '+06',
  },
  {
    name: 'Asia/Bishkek',
    abbrev: '+06',
  },
  {
    name: 'Asia/Krasnoyarsk',
    abbrev: '+07',
  },
  {
    name: 'Asia/Dili',
    abbrev: '+09',
  },
  {
    name: 'Asia/Beirut',
    abbrev: 'EET',
  },
  {
    name: 'Asia/Katmandu',
    abbrev: '+0545',
  },
  {
    name: 'Asia/Baghdad',
    abbrev: '+03',
  },
  {
    name: 'Asia/Rangoon',
    abbrev: '+0630',
  },
  {
    name: 'Asia/Kolkata',
    abbrev: 'IST',
  },
  {
    name: 'Asia/Kashgar',
    abbrev: '+06',
  },
  {
    name: 'Asia/Hebron',
    abbrev: 'EET',
  },
  {
    name: 'Asia/Makassar',
    abbrev: 'WITA',
  },
  {
    name: 'Asia/Seoul',
    abbrev: 'KST',
  },
  {
    name: 'Asia/Ulan_Bator',
    abbrev: '+08',
  },
  {
    name: 'Asia/Hong_Kong',
    abbrev: 'HKT',
  },
  {
    name: 'Asia/Chita',
    abbrev: '+09',
  },
  {
    name: 'Asia/Tel_Aviv',
    abbrev: 'IST',
  },
  {
    name: 'Asia/Tokyo',
    abbrev: 'JST',
  },
  {
    name: 'Asia/Tehran',
    abbrev: '+0330',
  },
  {
    name: 'Asia/Jerusalem',
    abbrev: 'IST',
  },
  {
    name: 'Asia/Aden',
    abbrev: '+03',
  },
  {
    name: 'Asia/Aqtau',
    abbrev: '+05',
  },
  {
    name: 'Asia/Damascus',
    abbrev: 'EET',
  },
  {
    name: 'Asia/Ujung_Pandang',
    abbrev: 'WITA',
  },
  {
    name: 'Asia/Kamchatka',
    abbrev: '+12',
  },
  {
    name: 'Asia/Yangon',
    abbrev: '+0630',
  },
  {
    name: 'Asia/Tomsk',
    abbrev: '+07',
  },
  {
    name: 'Asia/Samarkand',
    abbrev: '+05',
  },
  {
    name: 'Asia/Brunei',
    abbrev: '+08',
  },
  {
    name: 'Asia/Baku',
    abbrev: '+04',
  },
  {
    name: 'Asia/Tbilisi',
    abbrev: '+04',
  },
  {
    name: 'Asia/Dushanbe',
    abbrev: '+05',
  },
  {
    name: 'Asia/Choibalsan',
    abbrev: '+08',
  },
  {
    name: 'Asia/Sakhalin',
    abbrev: '+11',
  },
  {
    name: 'Asia/Gaza',
    abbrev: 'EET',
  },
  {
    name: 'Asia/Atyrau',
    abbrev: '+05',
  },
  {
    name: 'Asia/Karachi',
    abbrev: 'PKT',
  },
  {
    name: 'Asia/Chongqing',
    abbrev: 'CST',
  },
  {
    name: 'Asia/Qyzylorda',
    abbrev: '+05',
  },
  {
    name: 'Asia/Khandyga',
    abbrev: '+09',
  },
  {
    name: 'Asia/Ho_Chi_Minh',
    abbrev: '+07',
  },
  {
    name: 'Asia/Yakutsk',
    abbrev: '+09',
  },
  {
    name: 'Asia/Chungking',
    abbrev: 'CST',
  },
  {
    name: 'Asia/Jayapura',
    abbrev: 'WIT',
  },
  {
    name: 'Asia/Colombo',
    abbrev: '+0530',
  },
  {
    name: 'Asia/Kathmandu',
    abbrev: '+0545',
  },
  {
    name: 'Asia/Oral',
    abbrev: '+05',
  },
  {
    name: 'Asia/Anadyr',
    abbrev: '+12',
  },
  {
    name: 'Asia/Qostanay',
    abbrev: '+06',
  },
  {
    name: 'Asia/Taipei',
    abbrev: 'CST',
  },
  {
    name: 'Asia/Calcutta',
    abbrev: 'IST',
  },
  {
    name: 'Asia/Magadan',
    abbrev: '+11',
  },
  {
    name: 'Asia/Bahrain',
    abbrev: '+03',
  },
  {
    name: 'Asia/Hovd',
    abbrev: '+07',
  },
  {
    name: 'Asia/Amman',
    abbrev: 'EET',
  },
  {
    name: 'Asia/Macau',
    abbrev: 'CST',
  },
  {
    name: 'Asia/Saigon',
    abbrev: '+07',
  },
  {
    name: 'Asia/Barnaul',
    abbrev: '+07',
  },
  {
    name: 'Asia/Pyongyang',
    abbrev: 'KST',
  },
  {
    name: 'Asia/Ashgabat',
    abbrev: '+05',
  },
  {
    name: 'Asia/Riyadh',
    abbrev: '+03',
  },
  {
    name: 'Asia/Istanbul',
    abbrev: '+03',
  },
  {
    name: 'Asia/Almaty',
    abbrev: '+06',
  },
  {
    name: 'Asia/Kuala_Lumpur',
    abbrev: '+08',
  },
  {
    name: 'Asia/Qatar',
    abbrev: '+03',
  },
  {
    name: 'Asia/Aqtobe',
    abbrev: '+05',
  },
  {
    name: 'Asia/Ulaanbaatar',
    abbrev: '+08',
  },
  {
    name: 'Asia/Thimphu',
    abbrev: '+06',
  },
  {
    name: 'Asia/Manila',
    abbrev: 'PST',
  },
  {
    name: 'Asia/Kabul',
    abbrev: '+0430',
  },
  {
    name: 'Asia/Harbin',
    abbrev: 'CST',
  },
  {
    name: 'Asia/Tashkent',
    abbrev: '+05',
  },
  {
    name: 'Asia/Novokuznetsk',
    abbrev: '+07',
  },
  {
    name: 'Asia/Ust-Nera',
    abbrev: '+10',
  },
  {
    name: 'Asia/Srednekolymsk',
    abbrev: '+11',
  },
  {
    name: 'Asia/Novosibirsk',
    abbrev: '+07',
  },
  {
    name: 'Asia/Dacca',
    abbrev: '+06',
  },
  {
    name: 'Asia/Yerevan',
    abbrev: '+04',
  },
  {
    name: 'Asia/Famagusta',
    abbrev: 'EET',
  },
  {
    name: 'Egypt',
    abbrev: 'EET',
  },
  {
    name: 'HST',
    abbrev: 'HST',
  },
  {
    name: 'Eire',
    abbrev: 'GMT',
  },
  {
    name: 'Chile/Continental',
    abbrev: '-03',
  },
  {
    name: 'Chile/EasterIsland',
    abbrev: '-05',
  },
  {
    name: 'Portugal',
    abbrev: 'WET',
  },
  {
    name: 'posixrules',
    abbrev: 'EST',
  },
  {
    name: 'ROK',
    abbrev: 'KST',
  },
  {
    name: 'Cuba',
    abbrev: 'CST',
  },
  {
    name: 'NZ',
    abbrev: 'NZDT',
  },
  {
    name: 'Mexico/General',
    abbrev: 'CST',
  },
  {
    name: 'Mexico/BajaNorte',
    abbrev: 'PST',
  },
  {
    name: 'Mexico/BajaSur',
    abbrev: 'MST',
  },
  {
    name: 'PST8PDT',
    abbrev: 'PST',
  },
  {
    name: 'Universal',
    abbrev: 'UTC',
  },
  {
    name: 'Navajo',
    abbrev: 'MST',
  },
  {
    name: 'Etc/GMT-14',
    abbrev: '+14',
  },
  {
    name: 'Etc/GMT0',
    abbrev: 'GMT',
  },
  {
    name: 'Etc/GMT-5',
    abbrev: '+05',
  },
  {
    name: 'Etc/Universal',
    abbrev: 'UTC',
  },
  {
    name: 'Etc/GMT-10',
    abbrev: '+10',
  },
  {
    name: 'Etc/GMT+1',
    abbrev: '-01',
  },
  {
    name: 'Etc/GMT+6',
    abbrev: '-06',
  },
  {
    name: 'Etc/GMT-13',
    abbrev: '+13',
  },
  {
    name: 'Etc/GMT+12',
    abbrev: '-12',
  },
  {
    name: 'Etc/GMT+0',
    abbrev: 'GMT',
  },
  {
    name: 'Etc/GMT-6',
    abbrev: '+06',
  },
  {
    name: 'Etc/GMT+11',
    abbrev: '-11',
  },
  {
    name: 'Etc/GMT+5',
    abbrev: '-05',
  },
  {
    name: 'Etc/GMT',
    abbrev: 'GMT',
  },
  {
    name: 'Etc/Zulu',
    abbrev: 'UTC',
  },
  {
    name: 'Etc/GMT-2',
    abbrev: '+02',
  },
  {
    name: 'Etc/GMT-8',
    abbrev: '+08',
  },
  {
    name: 'Etc/GMT-4',
    abbrev: '+04',
  },
  {
    name: 'Etc/GMT+7',
    abbrev: '-07',
  },
  {
    name: 'Etc/Greenwich',
    abbrev: 'GMT',
  },
  {
    name: 'Etc/GMT+4',
    abbrev: '-04',
  },
  {
    name: 'Etc/GMT-1',
    abbrev: '+01',
  },
  {
    name: 'Etc/GMT+3',
    abbrev: '-03',
  },
  {
    name: 'Etc/GMT+2',
    abbrev: '-02',
  },
  {
    name: 'Etc/GMT-11',
    abbrev: '+11',
  },
  {
    name: 'Etc/GMT-3',
    abbrev: '+03',
  },
  {
    name: 'Etc/GMT+9',
    abbrev: '-09',
  },
  {
    name: 'Etc/UCT',
    abbrev: 'UTC',
  },
  {
    name: 'Etc/GMT-0',
    abbrev: 'GMT',
  },
  {
    name: 'Etc/GMT-7',
    abbrev: '+07',
  },
  {
    name: 'Etc/GMT+10',
    abbrev: '-10',
  },
  {
    name: 'Etc/GMT+8',
    abbrev: '-08',
  },
  {
    name: 'Etc/UTC',
    abbrev: 'UTC',
  },
  {
    name: 'Etc/GMT-12',
    abbrev: '+12',
  },
  {
    name: 'Etc/GMT-9',
    abbrev: '+09',
  },
  {
    name: 'CET',
    abbrev: 'CET',
  },
  {
    name: 'ROC',
    abbrev: 'CST',
  },
  {
    name: 'Africa/Lubumbashi',
    abbrev: 'CAT',
  },
  {
    name: 'Africa/Banjul',
    abbrev: 'GMT',
  },
  {
    name: 'Africa/Douala',
    abbrev: 'WAT',
  },
  {
    name: 'Africa/Freetown',
    abbrev: 'GMT',
  },
  {
    name: 'Africa/Asmara',
    abbrev: 'EAT',
  },
  {
    name: 'Africa/Blantyre',
    abbrev: 'CAT',
  },
  {
    name: 'Africa/Bujumbura',
    abbrev: 'CAT',
  },
  {
    name: 'Africa/Brazzaville',
    abbrev: 'WAT',
  },
  {
    name: 'Africa/Cairo',
    abbrev: 'EET',
  },
  {
    name: 'Africa/Luanda',
    abbrev: 'WAT',
  },
  {
    name: 'Africa/Conakry',
    abbrev: 'GMT',
  },
  {
    name: 'Africa/Malabo',
    abbrev: 'WAT',
  },
  {
    name: 'Africa/Bangui',
    abbrev: 'WAT',
  },
  {
    name: 'Africa/Mbabane',
    abbrev: 'SAST',
  },
  {
    name: 'Africa/Khartoum',
    abbrev: 'CAT',
  },
  {
    name: 'Africa/Harare',
    abbrev: 'CAT',
  },
  {
    name: 'Africa/Windhoek',
    abbrev: 'CAT',
  },
  {
    name: 'Africa/Djibouti',
    abbrev: 'EAT',
  },
  {
    name: 'Africa/Gaborone',
    abbrev: 'CAT',
  },
  {
    name: 'Africa/Asmera',
    abbrev: 'EAT',
  },
  {
    name: 'Africa/Porto-Novo',
    abbrev: 'WAT',
  },
  {
    name: 'Africa/Addis_Ababa',
    abbrev: 'EAT',
  },
  {
    name: 'Africa/Abidjan',
    abbrev: 'GMT',
  },
  {
    name: 'Africa/Kinshasa',
    abbrev: 'WAT',
  },
  {
    name: 'Africa/Juba',
    abbrev: 'EAT',
  },
  {
    name: 'Africa/Ceuta',
    abbrev: 'CET',
  },
  {
    name: 'Africa/Sao_Tome',
    abbrev: 'GMT',
  },
  {
    name: 'Africa/El_Aaiun',
    abbrev: '+01',
  },
  {
    name: 'Africa/Ouagadougou',
    abbrev: 'GMT',
  },
  {
    name: 'Africa/Niamey',
    abbrev: 'WAT',
  },
  {
    name: 'Africa/Timbuktu',
    abbrev: 'GMT',
  },
  {
    name: 'Africa/Lusaka',
    abbrev: 'CAT',
  },
  {
    name: 'Africa/Lagos',
    abbrev: 'WAT',
  },
  {
    name: 'Africa/Maseru',
    abbrev: 'SAST',
  },
  {
    name: 'Africa/Tunis',
    abbrev: 'CET',
  },
  {
    name: 'Africa/Johannesburg',
    abbrev: 'SAST',
  },
  {
    name: 'Africa/Lome',
    abbrev: 'GMT',
  },
  {
    name: 'Africa/Libreville',
    abbrev: 'WAT',
  },
  {
    name: 'Africa/Nairobi',
    abbrev: 'EAT',
  },
  {
    name: 'Africa/Bissau',
    abbrev: 'GMT',
  },
  {
    name: 'Africa/Accra',
    abbrev: 'GMT',
  },
  {
    name: 'Africa/Casablanca',
    abbrev: '+01',
  },
  {
    name: 'Africa/Kigali',
    abbrev: 'CAT',
  },
  {
    name: 'Africa/Maputo',
    abbrev: 'CAT',
  },
  {
    name: 'Africa/Dakar',
    abbrev: 'GMT',
  },
  {
    name: 'Africa/Kampala',
    abbrev: 'EAT',
  },
  {
    name: 'Africa/Ndjamena',
    abbrev: 'WAT',
  },
  {
    name: 'Africa/Bamako',
    abbrev: 'GMT',
  },
  {
    name: 'Africa/Algiers',
    abbrev: 'CET',
  },
  {
    name: 'Africa/Mogadishu',
    abbrev: 'EAT',
  },
  {
    name: 'Africa/Nouakchott',
    abbrev: 'GMT',
  },
  {
    name: 'Africa/Monrovia',
    abbrev: 'GMT',
  },
  {
    name: 'Africa/Tripoli',
    abbrev: 'EET',
  },
  {
    name: 'Africa/Dar_es_Salaam',
    abbrev: 'EAT',
  },
  {
    name: 'CST6CDT',
    abbrev: 'CST',
  },
  {
    name: 'GMT+0',
    abbrev: 'GMT',
  },
  {
    name: 'GMT',
    abbrev: 'GMT',
  },
  {
    name: 'Canada/Eastern',
    abbrev: 'EST',
  },
  {
    name: 'Canada/Mountain',
    abbrev: 'MST',
  },
  {
    name: 'Canada/Central',
    abbrev: 'CST',
  },
  {
    name: 'Canada/Pacific',
    abbrev: 'PST',
  },
  {
    name: 'Canada/Yukon',
    abbrev: 'PST',
  },
  {
    name: 'Canada/Saskatchewan',
    abbrev: 'CST',
  },
  {
    name: 'Canada/Atlantic',
    abbrev: 'AST',
  },
  {
    name: 'Canada/Newfoundland',
    abbrev: 'NST',
  },
  {
    name: 'Zulu',
    abbrev: 'UTC',
  },
  {
    name: 'GB-Eire',
    abbrev: 'GMT',
  },
  {
    name: 'Brazil/East',
    abbrev: '-03',
  },
  {
    name: 'Brazil/Acre',
    abbrev: '-05',
  },
  {
    name: 'Brazil/DeNoronha',
    abbrev: '-02',
  },
  {
    name: 'Brazil/West',
    abbrev: '-04',
  },
  {
    name: 'PRC',
    abbrev: 'CST',
  },
  {
    name: 'EET',
    abbrev: 'EET',
  },
  {
    name: 'Greenwich',
    abbrev: 'GMT',
  },
  {
    name: 'Turkey',
    abbrev: '+03',
  },
  {
    name: 'America/Tijuana',
    abbrev: 'PST',
  },
  {
    name: 'America/Indianapolis',
    abbrev: 'EST',
  },
  {
    name: 'America/Swift_Current',
    abbrev: 'CST',
  },
  {
    name: 'America/Bahia_Banderas',
    abbrev: 'CST',
  },
  {
    name: 'America/Porto_Acre',
    abbrev: '-05',
  },
  {
    name: 'America/Nipigon',
    abbrev: 'EST',
  },
  {
    name: 'America/Montreal',
    abbrev: 'EST',
  },
  {
    name: 'America/Detroit',
    abbrev: 'EST',
  },
  {
    name: 'America/Atikokan',
    abbrev: 'EST',
  },
  {
    name: 'America/Argentina/ComodRivadavia',
    abbrev: '-03',
  },
  {
    name: 'America/Argentina/Rio_Gallegos',
    abbrev: '-03',
  },
  {
    name: 'America/Argentina/Cordoba',
    abbrev: '-03',
  },
  {
    name: 'America/Argentina/Buenos_Aires',
    abbrev: '-03',
  },
  {
    name: 'America/Argentina/San_Juan',
    abbrev: '-03',
  },
  {
    name: 'America/Argentina/Ushuaia',
    abbrev: '-03',
  },
  {
    name: 'America/Argentina/Mendoza',
    abbrev: '-03',
  },
  {
    name: 'America/Argentina/San_Luis',
    abbrev: '-03',
  },
  {
    name: 'America/Argentina/La_Rioja',
    abbrev: '-03',
  },
  {
    name: 'America/Argentina/Salta',
    abbrev: '-03',
  },
  {
    name: 'America/Argentina/Tucuman',
    abbrev: '-03',
  },
  {
    name: 'America/Argentina/Jujuy',
    abbrev: '-03',
  },
  {
    name: 'America/Argentina/Catamarca',
    abbrev: '-03',
  },
  {
    name: 'America/Nassau',
    abbrev: 'EST',
  },
  {
    name: 'America/Bogota',
    abbrev: '-05',
  },
  {
    name: 'America/Mazatlan',
    abbrev: 'MST',
  },
  {
    name: 'America/El_Salvador',
    abbrev: 'CST',
  },
  {
    name: 'America/Santo_Domingo',
    abbrev: 'AST',
  },
  {
    name: 'America/Tortola',
    abbrev: 'AST',
  },
  {
    name: 'America/Costa_Rica',
    abbrev: 'CST',
  },
  {
    name: 'America/Sitka',
    abbrev: 'AKST',
  },
  {
    name: 'America/Cambridge_Bay',
    abbrev: 'MST',
  },
  {
    name: 'America/Fort_Nelson',
    abbrev: 'MST',
  },
  {
    name: 'America/Port_of_Spain',
    abbrev: 'AST',
  },
  {
    name: 'America/Chihuahua',
    abbrev: 'MST',
  },
  {
    name: 'America/Guadeloupe',
    abbrev: 'AST',
  },
  {
    name: 'America/Bahia',
    abbrev: '-03',
  },
  {
    name: 'America/Phoenix',
    abbrev: 'MST',
  },
  {
    name: 'America/Antigua',
    abbrev: 'AST',
  },
  {
    name: 'America/Managua',
    abbrev: 'CST',
  },
  {
    name: 'America/Guyana',
    abbrev: '-04',
  },
  {
    name: 'America/Chicago',
    abbrev: 'CST',
  },
  {
    name: 'America/St_Johns',
    abbrev: 'NST',
  },
  {
    name: 'America/Puerto_Rico',
    abbrev: 'AST',
  },
  {
    name: 'America/Goose_Bay',
    abbrev: 'AST',
  },
  {
    name: 'America/Godthab',
    abbrev: '-03',
  },
  {
    name: 'America/Panama',
    abbrev: 'EST',
  },
  {
    name: 'America/Scoresbysund',
    abbrev: '-01',
  },
  {
    name: 'America/Lima',
    abbrev: '-05',
  },
  {
    name: 'America/Miquelon',
    abbrev: '-03',
  },
  {
    name: 'America/Cordoba',
    abbrev: '-03',
  },
  {
    name: 'America/Virgin',
    abbrev: 'AST',
  },
  {
    name: 'America/Marigot',
    abbrev: 'AST',
  },
  {
    name: 'America/North_Dakota/New_Salem',
    abbrev: 'CST',
  },
  {
    name: 'America/North_Dakota/Beulah',
    abbrev: 'CST',
  },
  {
    name: 'America/North_Dakota/Center',
    abbrev: 'CST',
  },
  {
    name: 'America/Punta_Arenas',
    abbrev: '-03',
  },
  {
    name: 'America/Barbados',
    abbrev: 'AST',
  },
  {
    name: 'America/Kralendijk',
    abbrev: 'AST',
  },
  {
    name: 'America/Adak',
    abbrev: 'HST',
  },
  {
    name: 'America/Indiana/Indianapolis',
    abbrev: 'EST',
  },
  {
    name: 'America/Indiana/Winamac',
    abbrev: 'EST',
  },
  {
    name: 'America/Indiana/Petersburg',
    abbrev: 'EST',
  },
  {
    name: 'America/Indiana/Tell_City',
    abbrev: 'CST',
  },
  {
    name: 'America/Indiana/Marengo',
    abbrev: 'EST',
  },
  {
    name: 'America/Indiana/Vevay',
    abbrev: 'EST',
  },
  {
    name: 'America/Indiana/Vincennes',
    abbrev: 'EST',
  },
  {
    name: 'America/Indiana/Knox',
    abbrev: 'CST',
  },
  {
    name: 'America/Metlakatla',
    abbrev: 'AKST',
  },
  {
    name: 'America/Rio_Branco',
    abbrev: '-05',
  },
  {
    name: 'America/Port-au-Prince',
    abbrev: 'EST',
  },
  {
    name: 'America/Toronto',
    abbrev: 'EST',
  },
  {
    name: 'America/Dawson_Creek',
    abbrev: 'MST',
  },
  {
    name: 'America/Grand_Turk',
    abbrev: 'EST',
  },
  {
    name: 'America/Cayenne',
    abbrev: '-03',
  },
  {
    name: 'America/Santa_Isabel',
    abbrev: 'PST',
  },
  {
    name: 'America/Atka',
    abbrev: 'HST',
  },
  {
    name: 'America/La_Paz',
    abbrev: '-04',
  },
  {
    name: 'America/Eirunepe',
    abbrev: '-05',
  },
  {
    name: 'America/Manaus',
    abbrev: '-04',
  },
  {
    name: 'America/Boise',
    abbrev: 'MST',
  },
  {
    name: 'America/Danmarkshavn',
    abbrev: 'GMT',
  },
  {
    name: 'America/Montserrat',
    abbrev: 'AST',
  },
  {
    name: 'America/Knox_IN',
    abbrev: 'CST',
  },
  {
    name: 'America/Lower_Princes',
    abbrev: 'AST',
  },
  {
    name: 'America/St_Thomas',
    abbrev: 'AST',
  },
  {
    name: 'America/Juneau',
    abbrev: 'AKST',
  },
  {
    name: 'America/Inuvik',
    abbrev: 'MST',
  },
  {
    name: 'America/Yakutat',
    abbrev: 'AKST',
  },
  {
    name: 'America/Cancun',
    abbrev: 'EST',
  },
  {
    name: 'America/Thule',
    abbrev: 'AST',
  },
  {
    name: 'America/Dawson',
    abbrev: 'PST',
  },
  {
    name: 'America/Caracas',
    abbrev: '-04',
  },
  {
    name: 'America/Whitehorse',
    abbrev: 'PST',
  },
  {
    name: 'America/Yellowknife',
    abbrev: 'MST',
  },
  {
    name: 'America/Santarem',
    abbrev: '-03',
  },
  {
    name: 'America/Buenos_Aires',
    abbrev: '-03',
  },
  {
    name: 'America/Curacao',
    abbrev: 'AST',
  },
  {
    name: 'America/Los_Angeles',
    abbrev: 'PST',
  },
  {
    name: 'America/St_Vincent',
    abbrev: 'AST',
  },
  {
    name: 'America/Jamaica',
    abbrev: 'EST',
  },
  {
    name: 'America/Denver',
    abbrev: 'MST',
  },
  {
    name: 'America/Guayaquil',
    abbrev: '-05',
  },
  {
    name: 'America/Blanc-Sablon',
    abbrev: 'AST',
  },
  {
    name: 'America/Coral_Harbour',
    abbrev: 'EST',
  },
  {
    name: 'America/Grenada',
    abbrev: 'AST',
  },
  {
    name: 'America/Mendoza',
    abbrev: '-03',
  },
  {
    name: 'America/Asuncion',
    abbrev: '-03',
  },
  {
    name: 'America/Rainy_River',
    abbrev: 'CST',
  },
  {
    name: 'America/Winnipeg',
    abbrev: 'CST',
  },
  {
    name: 'America/Paramaribo',
    abbrev: '-03',
  },
  {
    name: 'America/Iqaluit',
    abbrev: 'EST',
  },
  {
    name: 'America/Halifax',
    abbrev: 'AST',
  },
  {
    name: 'America/Belize',
    abbrev: 'CST',
  },
  {
    name: 'America/St_Barthelemy',
    abbrev: 'AST',
  },
  {
    name: 'America/Araguaina',
    abbrev: '-03',
  },
  {
    name: 'America/Porto_Velho',
    abbrev: '-04',
  },
  {
    name: 'America/Dominica',
    abbrev: 'AST',
  },
  {
    name: 'America/Maceio',
    abbrev: '-03',
  },
  {
    name: 'America/Glace_Bay',
    abbrev: 'AST',
  },
  {
    name: 'America/Tegucigalpa',
    abbrev: 'CST',
  },
  {
    name: 'America/Hermosillo',
    abbrev: 'MST',
  },
  {
    name: 'America/Campo_Grande',
    abbrev: '-04',
  },
  {
    name: 'America/Nome',
    abbrev: 'AKST',
  },
  {
    name: 'America/Havana',
    abbrev: 'CST',
  },
  {
    name: 'America/Montevideo',
    abbrev: '-03',
  },
  {
    name: 'America/Recife',
    abbrev: '-03',
  },
  {
    name: 'America/Vancouver',
    abbrev: 'PST',
  },
  {
    name: 'America/Cuiaba',
    abbrev: '-04',
  },
  {
    name: 'America/Fortaleza',
    abbrev: '-03',
  },
  {
    name: 'America/Thunder_Bay',
    abbrev: 'EST',
  },
  {
    name: 'America/Santiago',
    abbrev: '-03',
  },
  {
    name: 'America/Regina',
    abbrev: 'CST',
  },
  {
    name: 'America/Fort_Wayne',
    abbrev: 'EST',
  },
  {
    name: 'America/Matamoros',
    abbrev: 'CST',
  },
  {
    name: 'America/Rankin_Inlet',
    abbrev: 'CST',
  },
  {
    name: 'America/Louisville',
    abbrev: 'EST',
  },
  {
    name: 'America/Jujuy',
    abbrev: '-03',
  },
  {
    name: 'America/Boa_Vista',
    abbrev: '-04',
  },
  {
    name: 'America/Aruba',
    abbrev: 'AST',
  },
  {
    name: 'America/Catamarca',
    abbrev: '-03',
  },
  {
    name: 'America/Rosario',
    abbrev: '-03',
  },
  {
    name: 'America/Martinique',
    abbrev: 'AST',
  },
  {
    name: 'America/Ensenada',
    abbrev: 'PST',
  },
  {
    name: 'America/Menominee',
    abbrev: 'CST',
  },
  {
    name: 'America/Anchorage',
    abbrev: 'AKST',
  },
  {
    name: 'America/Ojinaga',
    abbrev: 'MST',
  },
  {
    name: 'America/Belem',
    abbrev: '-03',
  },
  {
    name: 'America/Cayman',
    abbrev: 'EST',
  },
  {
    name: 'America/New_York',
    abbrev: 'EST',
  },
  {
    name: 'America/Moncton',
    abbrev: 'AST',
  },
  {
    name: 'America/Shiprock',
    abbrev: 'MST',
  },
  {
    name: 'America/Creston',
    abbrev: 'MST',
  },
  {
    name: 'America/St_Kitts',
    abbrev: 'AST',
  },
  {
    name: 'America/Merida',
    abbrev: 'CST',
  },
  {
    name: 'America/Noronha',
    abbrev: '-02',
  },
  {
    name: 'America/Resolute',
    abbrev: 'CST',
  },
  {
    name: 'America/Mexico_City',
    abbrev: 'CST',
  },
  {
    name: 'America/Anguilla',
    abbrev: 'AST',
  },
  {
    name: 'America/Monterrey',
    abbrev: 'CST',
  },
  {
    name: 'America/Edmonton',
    abbrev: 'MST',
  },
  {
    name: 'America/St_Lucia',
    abbrev: 'AST',
  },
  {
    name: 'America/Guatemala',
    abbrev: 'CST',
  },
  {
    name: 'America/Sao_Paulo',
    abbrev: '-03',
  },
  {
    name: 'America/Pangnirtung',
    abbrev: 'EST',
  },
  {
    name: 'America/Kentucky/Monticello',
    abbrev: 'EST',
  },
  {
    name: 'America/Kentucky/Louisville',
    abbrev: 'EST',
  },
  {
    name: 'Israel',
    abbrev: 'IST',
  },
  {
    name: 'Jamaica',
    abbrev: 'EST',
  },
  {
    name: 'Poland',
    abbrev: 'CET',
  },
  {
    name: 'WET',
    abbrev: 'WET',
  },
  {
    name: 'Arctic/Longyearbyen',
    abbrev: 'CET',
  },
  {
    name: 'Antarctica/Davis',
    abbrev: '+07',
  },
  {
    name: 'Antarctica/South_Pole',
    abbrev: 'NZDT',
  },
  {
    name: 'Antarctica/Palmer',
    abbrev: '-03',
  },
  {
    name: 'Antarctica/DumontDUrville',
    abbrev: '+10',
  },
  {
    name: 'Antarctica/Troll',
    abbrev: '+00',
  },
  {
    name: 'Antarctica/Syowa',
    abbrev: '+03',
  },
  {
    name: 'Antarctica/Casey',
    abbrev: '+08',
  },
  {
    name: 'Antarctica/Vostok',
    abbrev: '+06',
  },
  {
    name: 'Antarctica/Mawson',
    abbrev: '+05',
  },
  {
    name: 'Antarctica/McMurdo',
    abbrev: 'NZDT',
  },
  {
    name: 'Antarctica/Rothera',
    abbrev: '-03',
  },
  {
    name: 'Antarctica/Macquarie',
    abbrev: '+11',
  },
  {
    name: 'Kwajalein',
    abbrev: '+12',
  },
  {
    name: 'UCT',
    abbrev: 'UTC',
  },
  {
    name: 'Pacific/Port_Moresby',
    abbrev: '+10',
  },
  {
    name: 'Pacific/Midway',
    abbrev: 'SST',
  },
  {
    name: 'Pacific/Apia',
    abbrev: '+14',
  },
  {
    name: 'Pacific/Norfolk',
    abbrev: '+12',
  },
  {
    name: 'Pacific/Palau',
    abbrev: '+09',
  },
  {
    name: 'Pacific/Wake',
    abbrev: '+12',
  },
  {
    name: 'Pacific/Easter',
    abbrev: '-05',
  },
  {
    name: 'Pacific/Nauru',
    abbrev: '+12',
  },
  {
    name: 'Pacific/Tongatapu',
    abbrev: '+13',
  },
  {
    name: 'Pacific/Fakaofo',
    abbrev: '+13',
  },
  {
    name: 'Pacific/Truk',
    abbrev: '+10',
  },
  {
    name: 'Pacific/Rarotonga',
    abbrev: '-10',
  },
  {
    name: 'Pacific/Fiji',
    abbrev: '+12',
  },
  {
    name: 'Pacific/Kosrae',
    abbrev: '+11',
  },
  {
    name: 'Pacific/Yap',
    abbrev: '+10',
  },
  {
    name: 'Pacific/Honolulu',
    abbrev: 'HST',
  },
  {
    name: 'Pacific/Samoa',
    abbrev: 'SST',
  },
  {
    name: 'Pacific/Kiritimati',
    abbrev: '+14',
  },
  {
    name: 'Pacific/Pitcairn',
    abbrev: '-08',
  },
  {
    name: 'Pacific/Noumea',
    abbrev: '+11',
  },
  {
    name: 'Pacific/Auckland',
    abbrev: 'NZDT',
  },
  {
    name: 'Pacific/Chatham',
    abbrev: '+1345',
  },
  {
    name: 'Pacific/Saipan',
    abbrev: 'ChST',
  },
  {
    name: 'Pacific/Ponape',
    abbrev: '+11',
  },
  {
    name: 'Pacific/Johnston',
    abbrev: 'HST',
  },
  {
    name: 'Pacific/Pago_Pago',
    abbrev: 'SST',
  },
  {
    name: 'Pacific/Galapagos',
    abbrev: '-06',
  },
  {
    name: 'Pacific/Marquesas',
    abbrev: '-0930',
  },
  {
    name: 'Pacific/Kwajalein',
    abbrev: '+12',
  },
  {
    name: 'Pacific/Majuro',
    abbrev: '+12',
  },
  {
    name: 'Pacific/Gambier',
    abbrev: '-09',
  },
  {
    name: 'Pacific/Niue',
    abbrev: '-11',
  },
  {
    name: 'Pacific/Guadalcanal',
    abbrev: '+11',
  },
  {
    name: 'Pacific/Funafuti',
    abbrev: '+12',
  },
  {
    name: 'Pacific/Tahiti',
    abbrev: '-10',
  },
  {
    name: 'Pacific/Guam',
    abbrev: 'ChST',
  },
  {
    name: 'Pacific/Efate',
    abbrev: '+11',
  },
  {
    name: 'Pacific/Enderbury',
    abbrev: '+13',
  },
  {
    name: 'Pacific/Chuuk',
    abbrev: '+10',
  },
  {
    name: 'Pacific/Bougainville',
    abbrev: '+11',
  },
  {
    name: 'Pacific/Wallis',
    abbrev: '+12',
  },
  {
    name: 'Pacific/Tarawa',
    abbrev: '+12',
  },
  {
    name: 'Pacific/Pohnpei',
    abbrev: '+11',
  },
  {
    name: 'Hongkong',
    abbrev: 'HKT',
  },
  {
    name: 'GMT-0',
    abbrev: 'GMT',
  },
  {
    name: 'Australia/Adelaide',
    abbrev: 'ACDT',
  },
  {
    name: 'Australia/Melbourne',
    abbrev: 'AEDT',
  },
  {
    name: 'Australia/Canberra',
    abbrev: 'AEDT',
  },
  {
    name: 'Australia/Brisbane',
    abbrev: 'AEST',
  },
  {
    name: 'Australia/LHI',
    abbrev: '+11',
  },
  {
    name: 'Australia/Queensland',
    abbrev: 'AEST',
  },
  {
    name: 'Australia/ACT',
    abbrev: 'AEDT',
  },
  {
    name: 'Australia/Perth',
    abbrev: 'AWST',
  },
  {
    name: 'Australia/Sydney',
    abbrev: 'AEDT',
  },
  {
    name: 'Australia/Hobart',
    abbrev: 'AEDT',
  },
  {
    name: 'Australia/Broken_Hill',
    abbrev: 'ACDT',
  },
  {
    name: 'Australia/Lord_Howe',
    abbrev: '+11',
  },
  {
    name: 'Australia/South',
    abbrev: 'ACDT',
  },
  {
    name: 'Australia/Yancowinna',
    abbrev: 'ACDT',
  },
  {
    name: 'Australia/Currie',
    abbrev: 'AEDT',
  },
  {
    name: 'Australia/Darwin',
    abbrev: 'ACST',
  },
  {
    name: 'Australia/Lindeman',
    abbrev: 'AEST',
  },
  {
    name: 'Australia/West',
    abbrev: 'AWST',
  },
  {
    name: 'Australia/Victoria',
    abbrev: 'AEDT',
  },
  {
    name: 'Australia/Tasmania',
    abbrev: 'AEDT',
  },
  {
    name: 'Australia/North',
    abbrev: 'ACST',
  },
  {
    name: 'Australia/Eucla',
    abbrev: '+0845',
  },
  {
    name: 'Australia/NSW',
    abbrev: 'AEDT',
  },
  {
    name: 'Atlantic/Madeira',
    abbrev: 'WET',
  },
  {
    name: 'Atlantic/Canary',
    abbrev: 'WET',
  },
  {
    name: 'Atlantic/Faroe',
    abbrev: 'WET',
  },
  {
    name: 'Atlantic/Azores',
    abbrev: '-01',
  },
  {
    name: 'Atlantic/Stanley',
    abbrev: '-03',
  },
  {
    name: 'Atlantic/Jan_Mayen',
    abbrev: 'CET',
  },
  {
    name: 'Atlantic/St_Helena',
    abbrev: 'GMT',
  },
  {
    name: 'Atlantic/Reykjavik',
    abbrev: 'GMT',
  },
  {
    name: 'Atlantic/Bermuda',
    abbrev: 'AST',
  },
  {
    name: 'Atlantic/Faeroe',
    abbrev: 'WET',
  },
  {
    name: 'Atlantic/Cape_Verde',
    abbrev: '-01',
  },
  {
    name: 'Atlantic/South_Georgia',
    abbrev: '-02',
  },
  {
    name: 'US/Aleutian',
    abbrev: 'HST',
  },
  {
    name: 'US/Eastern',
    abbrev: 'EST',
  },
  {
    name: 'US/Pacific-New',
    abbrev: 'PST',
  },
  {
    name: 'US/East-Indiana',
    abbrev: 'EST',
  },
  {
    name: 'US/Mountain',
    abbrev: 'MST',
  },
  {
    name: 'US/Samoa',
    abbrev: 'SST',
  },
  {
    name: 'US/Alaska',
    abbrev: 'AKST',
  },
  {
    name: 'US/Michigan',
    abbrev: 'EST',
  },
  {
    name: 'US/Central',
    abbrev: 'CST',
  },
  {
    name: 'US/Pacific',
    abbrev: 'PST',
  },
  {
    name: 'US/Arizona',
    abbrev: 'MST',
  },
  {
    name: 'US/Hawaii',
    abbrev: 'HST',
  },
  {
    name: 'US/Indiana-Starke',
    abbrev: 'CST',
  },
  {
    name: 'EST',
    abbrev: 'EST',
  },
  {
    name: 'Libya',
    abbrev: 'EET',
  },
  {
    name: 'Europe/Nicosia',
    abbrev: 'EET',
  },
  {
    name: 'Europe/Mariehamn',
    abbrev: 'EET',
  },
  {
    name: 'Europe/Moscow',
    abbrev: 'MSK',
  },
  {
    name: 'Europe/Uzhgorod',
    abbrev: 'EET',
  },
  {
    name: 'Europe/Bratislava',
    abbrev: 'CET',
  },
  {
    name: 'Europe/Vatican',
    abbrev: 'CET',
  },
  {
    name: 'Europe/Tiraspol',
    abbrev: 'EET',
  },
  {
    name: 'Europe/Andorra',
    abbrev: 'CET',
  },
  {
    name: 'Europe/Vaduz',
    abbrev: 'CET',
  },
  {
    name: 'Europe/Volgograd',
    abbrev: '+04',
  },
  {
    name: 'Europe/Kirov',
    abbrev: '+03',
  },
  {
    name: 'Europe/Tirane',
    abbrev: 'CET',
  },
  {
    name: 'Europe/Helsinki',
    abbrev: 'EET',
  },
  {
    name: 'Europe/Sarajevo',
    abbrev: 'CET',
  },
  {
    name: 'Europe/Rome',
    abbrev: 'CET',
  },
  {
    name: 'Europe/Prague',
    abbrev: 'CET',
  },
  {
    name: 'Europe/Dublin',
    abbrev: 'GMT',
  },
  {
    name: 'Europe/Isle_of_Man',
    abbrev: 'GMT',
  },
  {
    name: 'Europe/Ljubljana',
    abbrev: 'CET',
  },
  {
    name: 'Europe/Brussels',
    abbrev: 'CET',
  },
  {
    name: 'Europe/Samara',
    abbrev: '+04',
  },
  {
    name: 'Europe/Monaco',
    abbrev: 'CET',
  },
  {
    name: 'Europe/Jersey',
    abbrev: 'GMT',
  },
  {
    name: 'Europe/Belfast',
    abbrev: 'GMT',
  },
  {
    name: 'Europe/Tallinn',
    abbrev: 'EET',
  },
  {
    name: 'Europe/Copenhagen',
    abbrev: 'CET',
  },
  {
    name: 'Europe/Riga',
    abbrev: 'EET',
  },
  {
    name: 'Europe/Vilnius',
    abbrev: 'EET',
  },
  {
    name: 'Europe/Oslo',
    abbrev: 'CET',
  },
  {
    name: 'Europe/Luxembourg',
    abbrev: 'CET',
  },
  {
    name: 'Europe/Skopje',
    abbrev: 'CET',
  },
  {
    name: 'Europe/Ulyanovsk',
    abbrev: '+04',
  },
  {
    name: 'Europe/Amsterdam',
    abbrev: 'CET',
  },
  {
    name: 'Europe/Paris',
    abbrev: 'CET',
  },
  {
    name: 'Europe/Warsaw',
    abbrev: 'CET',
  },
  {
    name: 'Europe/Podgorica',
    abbrev: 'CET',
  },
  {
    name: 'Europe/Minsk',
    abbrev: '+03',
  },
  {
    name: 'Europe/Lisbon',
    abbrev: 'WET',
  },
  {
    name: 'Europe/Kiev',
    abbrev: 'EET',
  },
  {
    name: 'Europe/Zagreb',
    abbrev: 'CET',
  },
  {
    name: 'Europe/Budapest',
    abbrev: 'CET',
  },
  {
    name: 'Europe/Kaliningrad',
    abbrev: 'EET',
  },
  {
    name: 'Europe/Gibraltar',
    abbrev: 'CET',
  },
  {
    name: 'Europe/Athens',
    abbrev: 'EET',
  },
  {
    name: 'Europe/Bucharest',
    abbrev: 'EET',
  },
  {
    name: 'Europe/Vienna',
    abbrev: 'CET',
  },
  {
    name: 'Europe/Busingen',
    abbrev: 'CET',
  },
  {
    name: 'Europe/Guernsey',
    abbrev: 'GMT',
  },
  {
    name: 'Europe/Simferopol',
    abbrev: 'MSK',
  },
  {
    name: 'Europe/Istanbul',
    abbrev: '+03',
  },
  {
    name: 'Europe/Stockholm',
    abbrev: 'CET',
  },
  {
    name: 'Europe/Madrid',
    abbrev: 'CET',
  },
  {
    name: 'Europe/Malta',
    abbrev: 'CET',
  },
  {
    name: 'Europe/San_Marino',
    abbrev: 'CET',
  },
  {
    name: 'Europe/Berlin',
    abbrev: 'CET',
  },
  {
    name: 'Europe/Sofia',
    abbrev: 'EET',
  },
  {
    name: 'Europe/London',
    abbrev: 'GMT',
  },
  {
    name: 'Europe/Zurich',
    abbrev: 'CET',
  },
  {
    name: 'Europe/Chisinau',
    abbrev: 'EET',
  },
  {
    name: 'Europe/Astrakhan',
    abbrev: '+04',
  },
  {
    name: 'Europe/Saratov',
    abbrev: '+04',
  },
  {
    name: 'Europe/Zaporozhye',
    abbrev: 'EET',
  },
  {
    name: 'Europe/Belgrade',
    abbrev: 'CET',
  },
  {
    name: 'Iran',
    abbrev: '+0330',
  },
  {
    name: 'GB',
    abbrev: 'GMT',
  },
  {
    name: 'MST',
    abbrev: 'MST',
  },
  {
    name: 'UTC',
    abbrev: 'UTC',
  },
  {
    name: 'MET',
    abbrev: 'MET',
  },
  {
    name: 'Japan',
    abbrev: 'JST',
  },
  {
    name: 'Iceland',
    abbrev: 'GMT',
  },
  {
    name: 'W-SU',
    abbrev: 'MSK',
  },
  {
    name: 'EST5EDT',
    abbrev: 'EST',
  },
  {
    name: 'Factory',
    abbrev: '-00',
  },
  {
    name: 'Indian/Maldives',
    abbrev: '+05',
  },
  {
    name: 'Indian/Chagos',
    abbrev: '+06',
  },
  {
    name: 'Indian/Reunion',
    abbrev: '+04',
  },
  {
    name: 'Indian/Comoro',
    abbrev: 'EAT',
  },
  {
    name: 'Indian/Kerguelen',
    abbrev: '+05',
  },
  {
    name: 'Indian/Mauritius',
    abbrev: '+04',
  },
  {
    name: 'Indian/Christmas',
    abbrev: '+07',
  },
  {
    name: 'Indian/Antananarivo',
    abbrev: 'EAT',
  },
  {
    name: 'Indian/Mayotte',
    abbrev: 'EAT',
  },
  {
    name: 'Indian/Mahe',
    abbrev: '+04',
  },
  {
    name: 'Indian/Cocos',
    abbrev: '+0630',
  },
] as const;
