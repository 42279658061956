import { sessionAccessPayloadSchema } from '@circadian-risk/shared';
import { useUserSessionStore } from '@circadian-risk/stores';
import Session from 'supertokens-web-js/recipe/session';

export const useUpdateSessionHelper = () => {
  const setSessionData = useUserSessionStore(state => state.setSessionData);
  const updateSessionWithLatestPayload = async () => {
    const payload = await Session.getAccessTokenPayloadSecurely();
    const result = sessionAccessPayloadSchema.safeParse(payload);
    if (!result.success) {
      throw new Error('Error while parsing the session information');
    }
    setSessionData(result.data);
  };

  return updateSessionWithLatestPayload;
};
