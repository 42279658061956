import { Box, ButtonBase, useTheme } from '@mui/material';
import React from 'react';
import { ChromePicker } from 'react-color';

import { PersistentPopover } from '../..';

export interface ColorPickerProps {
  colorHex: string;
  onChange: (colorHex: string) => void;
}
export const ColorPicker: React.FC<ColorPickerProps> = ({ colorHex, onChange }) => {
  const { palette, zIndex } = useTheme();
  return (
    <PersistentPopover
      zIndex={zIndex.modal}
      hideCloseButton
      placement="right-end"
      trigger={
        <ButtonBase>
          <Box
            border={`1px solid ${palette.utils.getOutlineColor(colorHex)}`}
            borderRadius="3px"
            width={20}
            height={20}
            bgcolor={colorHex}
          />
        </ButtonBase>
      }
    >
      <ChromePicker disableAlpha color={colorHex} onChange={e => onChange(e.hex)} />
    </PersistentPopover>
  );
};
