import { passwordRegex, USER_ROLE } from '@circadian-risk/shared';
import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();

const AuthAppDestinationSchema = z.enum(['web', 'companion']);

export type AuthAppDestination = z.infer<typeof AuthAppDestinationSchema>;

const OrgLoginStrategySchema = z.object({
  organizationName: z.string(),
  strategyId: z.string(),
  redirectUri: z.string().optional(),
});

export type OrgLoginStrategy = z.infer<typeof OrgLoginStrategySchema>;

const SignInCheckResponseSchema = z.object({
  loginStrategies: z.array(OrgLoginStrategySchema),
});

const passwordSchema = z
  .string()
  .min(5)
  .max(20)
  .refine(str => passwordRegex.test(str), { message: 'password too weak' });

const registerSchema = z.object({
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  email: z.string().email(),
  consentsToMarketingCommunication: z.boolean(),
  password: passwordSchema,
});

export const assignableRoleSchema = z.enum([USER_ROLE.MEMBER, USER_ROLE.ORGANIZATION_ADMIN]);

const registerUserToOrgSchema = z.object({
  email: z.string().email(),
  organizationId: z.string().uuid(),
  role: assignableRoleSchema,
  password: passwordSchema,
});

const registerUserToOrgResponseSchema = z.object({
  userId: z.string(),
  role: z.nativeEnum(USER_ROLE),
  created_at: z.string(),
});

const inviteUserToOrgSchema = z.object({
  email: z.string().email(),
  role: assignableRoleSchema,
});

const inviteUserToOrgResultSchema = inviteUserToOrgSchema.extend({
  id: z.string().uuid(),
  last_signed_in_at: z.string().nullable(),
  created_at: z.string(),
  first_name: z.string().nullish(),
  last_name: z.string().nullish(),
  allowAdminPasswordChange: z.boolean(),
  avatar: z
    .object({
      id: z.string().uuid(),
      filepath: z.string(),
    })
    .nullable(),
  created_by_id: z.string().uuid().nullable(),
});

const grantUserAccessToOrgSchema = z.object({
  email: z.string().email(),
  role: assignableRoleSchema,
  organizationId: z.string().uuid(),
});

const resetPasswordSchema = z.object({
  currentPassword: passwordSchema,
  newPassword: passwordSchema,
  userId: z.string().uuid(),
});

const resetMyPasswordSchema = z.object({
  verificationCode: z.string(),
  jwt: z.string(),
  password: passwordSchema,
});

export const setPasswordSchema = z.object({
  userId: z.string().uuid(),
  password: passwordSchema,
  needsToSetPassword: z
    .boolean({ description: 'If enabled then the user will be asked to reset password' })
    .default(false)
    .optional(),
});

export type SetPasswordSchema = z.infer<typeof setPasswordSchema>;

const signInSchema = z.object({
  email: z.string().email(),
  password: z.string(),
});

const signInResponseSchema = z.object({
  organizations: z.array(
    z.object({
      id: z.string().uuid(),
      name: z.string(),
    }),
  ),
});

const verifyEmailResponseSchema = z.object({ isPendingEmailVerification: z.boolean() });
const verifyEmailSchema = z.object({
  verificationCode: z.string(),
});

const switchOrganizationResponseSchema = z.object({
  organizationId: z.string(),
  name: z.string(),
  logo: z.string().nullable().optional(),
  role: z.string(),
});

export type SwitchOrganizationResponse = z.infer<typeof switchOrganizationResponseSchema>;
export type SignInCheckResponse = z.infer<typeof SignInCheckResponseSchema>;
export type VerifyEmailResponse = z.infer<typeof verifyEmailResponseSchema>;
export type VerifyEmail = z.infer<typeof verifyEmailSchema>;
export type SignInResponse = z.infer<typeof signInResponseSchema>;
export type Register = z.infer<typeof registerSchema>;
export type InviteUserToOrg = z.infer<typeof inviteUserToOrgSchema>;
export type InviteUserToOrgResult = z.infer<typeof inviteUserToOrgResultSchema>;
export type RegisterUserToOrg = z.infer<typeof registerUserToOrgSchema>;
export type RegisterUserToOrgResponse = z.infer<typeof registerUserToOrgResponseSchema>;
export type GrantUserAccessToOrg = z.infer<typeof grantUserAccessToOrgSchema>;
export type ResetPassword = z.infer<typeof resetPasswordSchema>;
export type ResetMyPassword = z.infer<typeof resetMyPasswordSchema>;
export type SetPassword = z.infer<typeof setPasswordSchema>;
export type SignIn = z.infer<typeof signInSchema>;

export const authContract = c.router(
  {
    login: {
      method: 'POST',
      path: '/sign-in',
      description: 'Attempts to sign in using the provided credentials',
      body: signInSchema,
      responses: {
        201: signInResponseSchema,
      },
    },
    signInCheck: {
      method: 'POST',
      path: '/sign-in-check',
      description: 'Checks to see what login strategies may be available for a given email address',
      body: z.object({
        email: z.string().email(),
        app: AuthAppDestinationSchema,
      }),
      responses: {
        200: SignInCheckResponseSchema,
      },
    },
    sso: {
      method: 'POST',
      path: '/sso',
      responses: {
        201: z.object({}),
      },
      body: z.object({
        code: z.string(),
        state: z.string(),
      }),
    },
    switchOrganization: {
      method: 'GET',
      path: '/:organizationId/token',
      responses: {
        200: switchOrganizationResponseSchema,
      },
    },
    register: {
      method: 'POST',
      path: '/register',
      description: 'Registers a new user to the platform',
      body: registerSchema,
      responses: {
        201: z.object({ id: z.string() }),
      },
    },
    verifyEmail: {
      method: 'POST',
      path: '/verify-email',
      description: 'Confirms email verification',
      body: verifyEmailSchema,
      responses: {
        201: verifyEmailResponseSchema,
      },
    },
    removeUserAccessToOrg: {
      method: 'DELETE',
      path: '/:organizationId/remove-access',
      description: 'Removes access to an organization for a specified user',
      body: z.object({
        email: z.string().email(),
      }),
      responses: {
        201: z.object({}),
      },
    },
    inviteUserToOrg: {
      method: 'POST',
      path: '/:organizationId/invite-user',
      description: 'Invites a new user to the organization if SSO is disabled',
      body: inviteUserToOrgSchema,
      responses: {
        201: inviteUserToOrgResultSchema,
      },
    },
    grantUserAccessToOrg: {
      method: 'POST',
      path: '/grant-access',
      description: 'Grants access to an organization for a specified user',
      body: grantUserAccessToOrgSchema,
      responses: {
        201: z.object({}),
      },
    },
    resetPassword: {
      method: 'POST',
      path: '/reset-password',
      body: resetPasswordSchema,
      description: 'Resets the user password',
      responses: {
        201: z.object({}),
      },
    },
    resetMyPassword: {
      method: 'POST',
      path: '/reset-my-password',
      description: `Resets the user's password if they have a valid Reset Password JWT and matching password reset token`,
      body: resetMyPasswordSchema,
      responses: {
        201: z.object({}),
      },
    },
    forgotPasswordSession: {
      method: 'POST',
      path: '/forgot-password-session',
      description: 'Returns a user-identifying JWT for use against the Reset Password endpoint',
      body: z.object({
        jwtRequestToken: z.string(),
      }),
      responses: {
        201: z.object({
          jwt: z.string(),
          role: z.literal('app-user'),
        }),
      },
    },
    forgotPassword: {
      method: 'POST',
      path: '/forgot-password',
      description: 'Initiate the forgot password flow for a user',
      body: z.object({
        email: z.string().email(),
      }),
      responses: {
        201: z.object({
          expiresAt: z
            .string({ description: 'The timestamp that the verification code expired at' })
            .default('10 minutes from NOW'),
        }),
      },
    },
    setPassword: {
      method: 'POST',
      path: '/set-password',
      description: 'Sets user password',
      body: setPasswordSchema,
      responses: {
        201: z.object({}),
      },
    },
    setOrgMemberPassword: {
      method: 'POST',
      path: '/set-org-member-password',
      description: 'Sets an organization member password',
      body: setPasswordSchema,
      responses: {
        201: z.object({}),
      },
    },
    ssoExchange: {
      method: 'GET',
      path: '/sso-exchange',
      description: 'Attempts to exchange a cookie based token for a valid SuperTokens session to complete the SSO flow',
      responses: {
        201: z.object({}),
        400: z.object({}),
      },
    },
    resendConfirmationEmail: {
      method: 'POST',
      path: '/resend-confirmation-email',
      description: 'Re-sends the confirmation email to the given user id',
      body: z.object({
        userId: z.string().uuid(),
      }),
      responses: {
        200: z.object({}).describe('Successfully sent the email'),
        400: z.object({}).describe('An error is thrown related with invalid input'),
        404: z.object({}).describe('User/Entity not found'),
      },
    },
  },
  {
    pathPrefix: '/auth',
  },
);
