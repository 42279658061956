import { useEffect } from 'react';

import { apolloAuthError$, ApolloAuthErrorData } from './apolloClientFactory';

export const useSubscribeApolloAuthError = (cb: (data: ApolloAuthErrorData) => void) => {
  useEffect(() => {
    const sub$ = apolloAuthError$.subscribe(data => {
      cb(data);
    });

    return () => sub$.unsubscribe();
  }, [cb]);
};
