import { FCC } from '@circadian-risk/front-end-utils';
import { Card, CardProps } from '@mui/material';

import { VStack } from '../Layouts';

export const QuestionCard: FCC<CardProps> = ({ children, sx, ...cardProps }) => (
  <Card
    variant="outlined"
    {...cardProps}
    sx={{
      padding: 2,
      paddingBottom: 1,
      ...sx,
    }}
  >
    <VStack>{children}</VStack>
  </Card>
);
