import { FormPaper, FormStack, FormTitle, generateCircadianForm } from '@circadian-risk/form';
import { Box } from '@mui/material';
import { z } from 'zod';

import { ReactComponent as Logo } from '../../../assets/LogoWithText.svg';
import { ReturnToSignIn } from '../../components/ReturnToSignIn/ReturnToSignIn';

export const forgotPasswordSchema = z.object({
  email: z.string().email(),
});

export type ForgotPasswordFormData = z.infer<typeof forgotPasswordSchema>;

const { useCircadianForm, ariaLabels, Text, SubmitButton, CircadianForm } = generateCircadianForm(
  forgotPasswordSchema,
  {
    email: 'Email',
    form: 'Forgot Password',
    submit: 'Reset',
  },
);

export const ForgotPasswordFormAriaLabels = ariaLabels;

export interface ForgotPasswordFormProps {
  onSubmit: (formData: ForgotPasswordFormData) => void;
  email?: string;
}

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({ onSubmit, email = '' }) => {
  const formMethods = useCircadianForm({
    defaultValues: {
      email,
    },
  });

  return (
    <CircadianForm {...{ onSubmit, formMethods }}>
      <FormPaper showBg>
        <FormStack>
          <FormTitle>{ariaLabels.form}</FormTitle>
          <Box display="flex" justifyContent="center" p={2}>
            {/* TODO(iprokopovich): [CR-1507] move logo component to presentational and use it here */}
            <Logo width="210px" height="105px" />
          </Box>
          <Text formKey="email" />
          <SubmitButton color="success" skipDirtyCheck={true} />
          <ReturnToSignIn email={() => formMethods.getValues('email')} />
        </FormStack>
      </FormPaper>
    </CircadianForm>
  );
};
