/**
 * Helpers that require a running app to test
 */
import { useOrganizationId } from '@circadian-risk/stores';
import { browserHistory } from '@web-app/lib/browserHistory';
import { useHistory, useLocation } from 'react-router-dom';

import { convertNestedQueryParamObjToString, TableQueryParams } from './helpers';

export const updateQueryParams = (newParams?: TableQueryParams) => {
  if (newParams) {
    const search = convertNestedQueryParamObjToString(newParams);
    browserHistory.push({
      pathname: browserHistory.location.pathname,
      search: `?${search}`,
    });
  }
};

export interface HierarchyTotals {
  [key: string]: {
    id: string;
    total: number;
    name?: string;
  };
}

export const useAggregateNodesPerLayer = (_activeHierarchy: number): HierarchyTotals => {
  const totals: HierarchyTotals = {};
  return totals;
  /* Object.values(.nodes.records).forEach((record: any) => {
    const isNotOrg = Boolean(store.layers.records[record.layer]?.parent_id);
    const isInActiveHierarchy = store.layers.records[record.layer]?.hierarchyType === activeHierarchy;
    if (isNotOrg && isInActiveHierarchy) {
      if (!totals[record.layer]) {
        totals[record.layer] = { id: record.layer, name: undefined, total: 0 };
      }
      totals[record.layer].total = totals[record.layer].total + 1;
    }
  });
  Object.keys(totals).forEach((id: string) => {
    if (store.layers.records[id]) {
      const layerName = store.layers.records[id].name;
      totals[layerName] = totals[id];
      totals[layerName].name = layerName;
    }
    delete totals[id];
  });
  return totals;*/
};

export const orgIdRegex = /\/organization\/([\w-]+)\//;
export const useBackUpOrganizationIdFromLocation = (): string | null => {
  const { pathname } = useLocation();
  const matcher = orgIdRegex.exec(pathname);
  return matcher ? matcher[1] : null;
};

export const useHistoryPushForActiveOrg = () => {
  const history = useHistory();
  const activeOrgId = useOrganizationId();
  return {
    ...history,
    push: (path: string) => {
      path.includes(':organizationId') && activeOrgId
        ? history.push(path.replace(':organizationId', activeOrgId))
        : history.push(path);
    },
  };
};

export const replaceRouteOrgId = (route: string, organizationId: string) => {
  return route.replace(':organizationId', organizationId);
};

export const getRouteWithOrgId = (to: string) => {
  const pathname = window.location.pathname;
  const matcher = orgIdRegex.exec(pathname);
  const activeOrgId = matcher ? matcher[1] : null;
  return activeOrgId ? to.replace(':organizationId', activeOrgId) : to;
};
