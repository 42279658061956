import React from 'react';

const HiddenSemanticHeader: React.FC = () => {
  return (
    <div className="sr-only">
      <h1>Circadian Risk</h1>
      <button
        onClick={e => {
          e.preventDefault();
        }}
      >
        Jump to content
      </button>
    </div>
  );
};

export default HiddenSemanticHeader;
