import { Enum_Feature_Flags_Enum } from '@circadian-risk/client-graphql-hooks';
import { useOrganizationSessionStore, useUserSessionStore } from '@circadian-risk/stores';
import {
  alpha,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { capitalCase } from 'change-case';
import noop from 'lodash/noop';
import { ReactNode } from 'react';
import * as React from 'react';
import { generatePath, Link, Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';

import { useHistoryPushForActiveOrg } from '../../lib/appHelpers';

export interface TabContentConfig {
  icon: ReactNode;
  component: ReactNode;
  label?: string;
  circadianAdminOnly?: boolean;
  featureFlag?: Enum_Feature_Flags_Enum;
  renderLabel?: (smallViewport: boolean) => React.ReactNode;
}

export interface TabNavConfig<T extends string> {
  baseUrl: string;
  redirectBaseUrl?: T;
  tabContent: Record<T, TabContentConfig>;
  defaultTab: T;
  isCircadianAdmin?: boolean;
}

export const TabNav: <T extends string>(props: TabNavConfig<T> & { top?: number }) => React.ReactElement = props => {
  const params = useParams();
  const theme = useTheme();
  const navBackground = theme.palette.auxColor;
  const navSelectedColor = theme.palette.getContrastText(navBackground);
  const navColor = alpha(navSelectedColor, 0.5);
  const history = useHistoryPushForActiveOrg();
  const isSmallViewport = useMediaQuery(theme.breakpoints.down('md'));
  const isCircadianAdmin = useUserSessionStore(user => user.isCircadianAdmin);
  const featureFlags = useOrganizationSessionStore(state => state.enabledFeatures);
  const { tabContent, baseUrl, defaultTab = false } = props;
  const baseRouteMatch = useRouteMatch([baseUrl]);

  const tabsData: [string, TabContentConfig & { tabUrl: string; tabPath: string }][] =
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    (Object.entries(tabContent) as [string, TabContentConfig][])
      .filter(([_key, tabContent]) => (tabContent.circadianAdminOnly ? isCircadianAdmin : true))
      .filter(([_key, tabContent]) => (tabContent.featureFlag ? featureFlags.includes(tabContent.featureFlag) : true))
      .map(([key, tabContent]) => {
        const tabPath = `${baseUrl}/${key}`;
        return [key, { ...tabContent, tabUrl: generatePath(`${baseUrl}/${key}`, params), tabPath }];
      });

  const tabRouteMatch = useRouteMatch(tabsData.map(([_, options]) => options.tabPath));

  if (baseRouteMatch?.isExact && defaultTab) {
    const defaultTabData = tabsData.find(([key]) => key === defaultTab);
    if (defaultTabData) {
      return <Redirect to={defaultTabData[1].tabUrl} />;
    }
  }

  const canVisitTab = tabsData.some(([_, config]) => config.tabPath === tabRouteMatch?.path);

  if (!canVisitTab) {
    const [first] = tabsData;
    return <Redirect to={first[1].tabUrl} />;
  }

  return (
    <Box position="relative" display="flex" alignItems="flex-start">
      {!isSmallViewport ? (
        <Tabs
          aria-label={'vertical nav'}
          orientation="vertical"
          sx={theme => ({
            position: 'sticky',
            top: 0,
            flex: 'none',
            marginRight: theme.spacing(1),
          })}
          value={tabRouteMatch?.path}
          style={{ top: props.top ?? 0 }}
          indicatorColor={'secondary'}
          textColor={'primary'}
        >
          {tabsData.map(([key, config]) => {
            const label = config.label ?? capitalCase(key);

            return (
              <Tab
                key={key}
                label={
                  config.renderLabel ? (
                    config.renderLabel(false)
                  ) : (
                    <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} width={'100%'}>
                      <Box display={'flex'} mr={1}>
                        {label}
                      </Box>
                      <Box display={'flex'}>{config.icon}</Box>
                    </Box>
                  )
                }
                value={config.tabPath}
                component={Link}
                to={config.tabUrl}
                aria-label={label}
                sx={theme => ({
                  padding: `${theme.spacing(0.8)} ${theme.spacing(2)}`,
                  '&:not(.Mui-selected)': {
                    color: theme.palette.text.secondary,
                  },
                })}
              />
            );
          })}
        </Tabs>
      ) : (
        <BottomNavigation
          aria-label={'bottom navigation'}
          showLabels
          value={tabRouteMatch?.url}
          sx={theme => ({
            '&.MuiBottomNavigation-root': {
              backgroundColor: navBackground,
              height: 'auto',
              width: '100%',
              zIndex: theme.zIndex.appBar,
              position: 'fixed',
              top: 'auto',
              bottom: 0,
              left: 0,
              boxShadow: theme.shadows[6],
              '&.MuiBottomNavigationAction-root': {
                color: navColor,
                maxWidth: 200,
              },
              '&.MuiBottomNavigationAction-root.Mui-selected': {
                color: navSelectedColor,
              },
            },
          })}
          onChange={noop}
        >
          {tabsData.map(([key, config]) => {
            const isSelected = config.tabUrl === tabRouteMatch?.url;
            return (
              <BottomNavigationAction
                key={`bottom-navigation-action-${key}-${config.tabUrl}`}
                label={
                  config.renderLabel ? (
                    config.renderLabel(true)
                  ) : (
                    <Typography variant="button">{config.label ?? capitalCase(key)}</Typography>
                  )
                }
                icon={config.icon}
                value={config.tabUrl}
                showLabel
                aria-selected={isSelected}
                onClick={() => history.push(config.tabUrl)}
              />
            );
          })}
        </BottomNavigation>
      )}
      <Switch>
        {tabsData.map(([key, config]) => {
          return <Route key={key} path={config.tabPath} exact render={() => config.component} />;
        })}
      </Switch>
    </Box>
  );
};
