import { useApolloClient } from '@apollo/client';
import { useApiClient } from '@circadian-risk/api-client-provider';
import { modifyApolloCache } from '@circadian-risk/apollo-utils';
import { useNotification, useStructuralMemo } from '@circadian-risk/front-end-utils';
import { ROUTES } from '@circadian-risk/routes';
import { DateFormat } from '@circadian-risk/shared';
import { LinearProgress } from '@mui/material';
import { useHistoryPushForActiveOrg } from '@web-app/lib/appHelpers';
import dayjs from 'dayjs';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Prompt, useParams } from 'react-router-dom';

import { ScenarioAssessmentForm } from './ScenarioAssessmentForm';
import { useAssessmentFormStore } from './ScenarioAssessmentForm/AssessmentFormStore';
import { ScenarioFormData } from './ScenarioAssessmentForm/ScenarioAssessmentForm.types';
import { singleAssessmentForm } from './single-assessment-form';
import { useRelevantNodes } from './utils';

export type CreateAssessmentContext = {
  organizationId: string;
};

interface ScenarioAssessmentFormPageProps {
  initialValues: Partial<ScenarioFormData>;
}

const { useCircadianForm, ariaLabels, CircadianForm } = singleAssessmentForm;

export const SCENARIO_ASSESSMENT_FORM_ARIA_LABELS = ariaLabels;
export const CreateScenarioAssessmentFormPage: React.FC<ScenarioAssessmentFormPageProps> = memo(props => {
  const history = useHistoryPushForActiveOrg();
  const { organizationId } = useParams<{ organizationId: string }>();
  const { displayError, displaySuccess } = useNotification();
  const { cache } = useApolloClient();
  const { tsRestClient } = useApiClient();

  const methods = useCircadianForm({
    defaultValues: props.initialValues,
    shouldUnregister: false,
  });

  const { watch, getValues, setValue } = methods;
  const assessmentTemplateId = watch('assessmentTemplateId');
  const rootLocationId = watch('rootLocationId');
  const [submitting, setSubmitting] = useState(false);
  const [enablePrompt, setEnablePrompt] = useState(true);

  const { templates, usingPreviousAssessmentData, selectTemplate } = useAssessmentFormStore(
    ({ templates, usingPreviousAssessmentData, selectTemplate }) => ({
      templates,
      usingPreviousAssessmentData,
      selectTemplate,
    }),
  );

  useEffect(() => {
    if (assessmentTemplateId) {
      selectTemplate(assessmentTemplateId);
    }
  }, [assessmentTemplateId, selectTemplate]);

  // By default, select all relevant nodes
  const [relevantNodes] = useRelevantNodes(
    rootLocationId,
    templates?.find(t => t.id === assessmentTemplateId) ?? undefined,
  );

  const structuralRelevantNodes = useStructuralMemo(relevantNodes);
  useEffect(() => {
    useAssessmentFormStore.setState({
      relevantNodes: structuralRelevantNodes,
    });
    if (!usingPreviousAssessmentData) {
      setValue(
        'locationIds',
        structuralRelevantNodes.map(n => n.id),
      );
    }
  }, [setValue, structuralRelevantNodes, usingPreviousAssessmentData]);

  const handleSubmit = useCallback(async () => {
    setEnablePrompt(false);
    setSubmitting(true);
    if (submitting) {
      return;
    }
    const formData = getValues();
    try {
      const { status, body } = await tsRestClient.assessments.createSingle({
        params: {
          organizationId,
        },
        body: {
          assessmentTemplateId: formData.assessmentTemplateId,
          locationIds: formData.locationIds,
          name: formData.name,
          description: formData.description,
          startDate: formData.startDate ? dayjs(formData.startDate).format(DateFormat.YEAR_MONTH_DAY) : null,
          dueDate: formData.dueDate ? dayjs(formData.dueDate).format(DateFormat.YEAR_MONTH_DAY) : null,
          assignment: formData.assignment,
          importActiveAnswers: Boolean(formData.importActiveAnswers),
          ratificationRequired: formData.importActiveAnswers ? formData.ratificationRequired : undefined,
          // TODO(v-rrodrigues)[CR-5317]
          notifications: [],
        },
      });

      if (status === 201) {
        displaySuccess('Successfully created Assessment');

        // For each affected scenario just update the cache
        for (const id of body.relatedScenarioIds) {
          modifyApolloCache({
            typeName: 'scenario',
            key: id,
            modify: {
              relatedAssessments: (_existing, { DELETE }) => {
                return DELETE;
              },
            },
          });
        }

        cache.evict({
          fieldName: 'v2_assessments',
        });

        cache.evict({
          fieldName: 'assessment_summaries',
        });

        history.push(`${ROUTES.ASSESSMENTS}/${body.assessmentId}`);
      }
    } catch (err) {
      displayError(err);
      setEnablePrompt(true);
      setSubmitting(false);
    }
  }, [submitting, getValues, tsRestClient.assessments, organizationId, displaySuccess, cache, history, displayError]);

  return (
    <CircadianForm formMethods={methods} onSubmit={handleSubmit}>
      {submitting && <LinearProgress />}
      <ScenarioAssessmentForm onSubmit={handleSubmit} />
      <Prompt
        when={enablePrompt}
        message="This assessment is not yet created. If you leave this page, your changes will not be saved."
      />
    </CircadianForm>
  );
});
