import React from 'react';

import { EmailVerificationPage, EmailVerificationPageProps } from '../EmailVerificationPage';
import { SignUpPage, SignUpPageProps } from '../SignUpPage';

export type RegistrationFlowPageProps = {
  isPendingEmailVerification: boolean;
  eulaText: string;
  email: string;
} & SignUpPageProps &
  Pick<EmailVerificationPageProps, 'onVerificationResponse' | 'signout'>;

export const RegistrationFlowPage: React.FC<RegistrationFlowPageProps> = ({
  onRegistrationSuccess,
  isPendingEmailVerification,
  onVerificationResponse,
  eulaText,
  signout,
  email,
}) => {
  if (isPendingEmailVerification) {
    return (
      <EmailVerificationPage
        {...{
          email,
          onVerificationResponse,
          signout,
        }}
      />
    );
  }

  return <SignUpPage onRegistrationSuccess={onRegistrationSuccess} eulaText={eulaText} />;
};
