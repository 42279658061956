import { AssessmentItemStatuses } from '@circadian-risk/front-end-utils';
import { ItemIconProps } from '@circadian-risk/presentational';
import { useTheme } from '@mui/material';

export const useMapStyles = () => {
  const { compliance } = useTheme().palette.organizationColors;

  const statusToStylePropMap: Record<AssessmentItemStatuses, Omit<ItemIconProps, 'alt'>> = {
    notStarted: {
      hasBorder: true,
      backgroundColor: compliance.unknown,
      hasShadow: true,
    },
    inProgress: {
      hasBorder: true,
      backgroundColor: compliance.unknown,
      hasShadow: true,
    },
    isMissing: {
      hasBorder: true,
      backgroundColor: compliance.deficient,
      isBorderDashed: true,
      hasShadow: true,
    },
    isDeficient: {
      hasBorder: true,
      backgroundColor: compliance.deficient,
      hasShadow: true,
    },
    isCompliant: {
      hasBorder: true,
      backgroundColor: compliance.compliant,
      hasShadow: true,
    },
    notNeeded: {
      hasBorder: true,
      backgroundColor: compliance.unknown,
      hasShadow: true,
    },
  };

  return { statusToStylePropMap };
};
