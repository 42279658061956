import { pluralizeResource } from '@circadian-risk/data-utils';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import isNumber from 'lodash/isNumber';
import React, { CSSProperties } from 'react';

import { InfoIconWithTooltip } from '../InfoIconWithTooltip';

export enum LabelPosition {
  bottomCenter = 'bottom-center',
  bottomLeft = 'bottom-left',
  bottomRight = 'bottom-right',
  topCenter = 'top-center',
  topLeft = 'top-left',
  topRight = 'top-right',
}

interface StyleProps {
  labelPosition: LabelPosition;
  variant?: 'small';
  sx?: SxProps<Theme>;
}

export interface NumberAndLabelProps extends StyleProps {
  dontPluralize?: boolean;
  href?: string;
  label: string;
  labelSx?: SxProps<Theme>;
  numberSx?: SxProps<Theme>;
  num: number | string;
  decimalPlaces?: number;
  tooltip?: string;
}

export const NumberAndLabel: React.FC<NumberAndLabelProps> = ({
  dontPluralize,
  href,
  num,
  label,
  labelPosition,
  labelSx,
  variant,
  sx,
  tooltip,
  numberSx,
  decimalPlaces = 0,
}) => {
  const needsPlural = num !== 1 && label.slice(-1) !== 's' && !dontPluralize;
  const pluralizedLabel = needsPlural ? pluralizeResource(label) : label;
  const labelId = `label-for-number-of-${pluralizedLabel}`;
  let alignItems: CSSProperties['alignItems'] = 'center';
  let textAlign: CSSProperties['textAlign'] = 'center';
  if (labelPosition.toLowerCase().includes('left')) {
    alignItems = 'flex-start';
    textAlign = 'left';
  } else if (labelPosition.toLowerCase().includes('right')) {
    alignItems = 'flex-end';
    textAlign = 'right';
  }

  const content = (
    <Box
      component="dl"
      title={`count for ${pluralizedLabel}`}
      sx={[
        {
          display: 'flex',
          flexDirection: labelPosition.toString().includes('top') ? 'column' : 'column-reverse',
          flexWrap: 'wrap',
          margin: 0,
          alignItems,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Typography
        id={labelId}
        role="term"
        sx={[
          theme => ({
            ...(variant === 'small' ? { fontSize: theme.typography.caption.fontSize } : {}),
            textAlign,
            width: '100%',
          }),
          ...(Array.isArray(labelSx) ? labelSx : [labelSx]),
        ]}
      >
        {pluralizedLabel}
        {tooltip && <InfoIconWithTooltip tooltipContent={tooltip} size="inherit" />}
      </Typography>
      <Typography
        role="definition"
        aria-labelledby={labelId}
        noWrap
        variant={variant === 'small' ? 'h5' : 'h3'}
        sx={numberSx}
      >
        {isNumber(num) ? num.toFixed(decimalPlaces) : num}
      </Typography>
    </Box>
  );
  return href ? (
    <Box
      component={'a'}
      href={href}
      sx={theme => ({
        color: theme.palette.text.primary,
        transition: theme.transitions.create(['color'], {
          duration: theme.transitions.duration.short,
        }),
        textDecoration: 'none',
        '&:visited, &:active': {
          color: theme.palette.text.primary,
        },
        '&:hover': {
          textDecoration: 'none',
          color: theme.palette.info.main,
        },
      })}
    >
      {content}
    </Box>
  ) : (
    content
  );
};
