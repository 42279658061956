import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';

export interface NotificationGoActionProps {
  onClick: () => void;
  snackKey: React.ReactText;
  label?: string;
}

export const NotificationGoAction: React.FC<NotificationGoActionProps> = ({ snackKey, onClick, label = 'Go' }) => {
  const { closeSnackbar } = useSnackbar();

  const handleClick = () => {
    onClick();
    closeSnackbar(snackKey);
  };

  return (
    <Button
      onClick={handleClick}
      sx={theme => ({ color: theme.palette.primary.contrastText, marginRight: theme.spacing(1) })}
    >
      {label}
    </Button>
  );
};
