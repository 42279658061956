import { useEffect, useRef } from 'react';

import { FilterAggregates, FilterOption } from '../filter.types';
import { processFilter } from './utils';

export type useFilterOptionAggregatesParams<Row, FilterKey extends string> = {
  rows: Row[];
  keyMapper: Record<FilterKey, keyof Row>;
  filterOptions: FilterOption<FilterKey>[];
};

export type FilterOptionsAggregatesRef = React.MutableRefObject<FilterAggregates>;

export const useFilterOptionAggregates = <Row extends object, FilterKey extends string>({
  rows,
  filterOptions,
  keyMapper,
}: useFilterOptionAggregatesParams<Row, FilterKey>): FilterOptionsAggregatesRef => {
  const aggregatesRef = useRef<FilterAggregates>({});

  // Keep the ref up-to-date
  useEffect(() => {
    filterOptions.forEach(o => {
      const key = o.value as string;
      const childrenAggregates: FilterAggregates = {};
      o.children.forEach(c => {
        const mappedKey = keyMapper?.[key as FilterKey] ?? (key as keyof Row);
        const count = rows.filter(e => processFilter('isAnyOf', e[mappedKey], [c.value])).length;
        childrenAggregates[c.value] = count;
        aggregatesRef.current[c.value] = count;
      });

      const childrenWithFilterableData = Object.entries(childrenAggregates).filter(
        ([_key, number]) => number > 0,
      ).length;
      aggregatesRef.current[o.value] = childrenWithFilterableData;
    });
  }, [filterOptions, keyMapper, rows]);

  return aggregatesRef;
};
