import { useFileManagerStore } from '@circadian-risk/file-manager';
import { UploadStatus } from '@circadian-risk/form';
import { ROUTES } from '@circadian-risk/routes';
import { useIsFeatureFlagEnabledForOrg, useOrganizationId } from '@circadian-risk/stores';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {
  Badge,
  Box,
  FormControlLabel,
  LinearProgress,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
} from '@mui/material';
import { LinkWithOrgId } from '@web-app/components/NavLinkWithActiveOrgId';
import { generatePath } from 'react-router-dom';

export const SideFileManager: React.FC = () => {
  const enabled = useIsFeatureFlagEnabledForOrg('smoother_uploads');
  const organizationId = useOrganizationId();
  const [files, uploadsEnabled, setUploadsEnabled] = useFileManagerStore(state => [
    state.files,
    state.uploadsEnabled,
    state.setUploadsEnabled,
  ]);
  const pendingFiles = files.filter(f => f.status !== UploadStatus.Completed);

  if (!enabled) {
    return null;
  }

  return (
    <ListItem key="side-file-manager">
      <ListItemIcon>
        <Badge
          color="primary"
          variant="dot"
          invisible={pendingFiles.length === 0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <AttachFileIcon />
        </Badge>
      </ListItemIcon>
      <ListItemText>
        <Box display="flex" flexDirection="row" alignItems="center">
          <LinkWithOrgId
            href={generatePath(ROUTES.FILES, {
              organizationId,
            })}
            sx={theme => ({
              color: theme.palette.text.primary,
              width: 'auto !important',
              display: 'inline !important ',
              background: 'initial !important',
              '&:hover': {
                color: `${theme.palette.primary.light} !important`,
              },
            })}
          >
            Files {!uploadsEnabled && pendingFiles.length > 0 && `(${pendingFiles.length} Pending)`}
          </LinkWithOrgId>
          {uploadsEnabled && pendingFiles.length > 0 && (
            <Box flex={1} display="flex" flexDirection="row" alignItems="center" ml={1} mr={3}>
              <LinearProgress sx={theme => ({ flex: 1, marginRight: theme.spacing(1) })} />
              <Box
                component="span"
                sx={theme => ({ fontWeight: theme.typography.body2.fontWeight, color: theme.palette.text.secondary })}
              >
                {pendingFiles.length} Files Left
              </Box>
            </Box>
          )}
        </Box>
      </ListItemText>
      <ListItemSecondaryAction>
        <FormControlLabel
          style={{ marginLeft: 0, marginRight: 0 }}
          control={
            <Switch
              checked={uploadsEnabled}
              onChange={() => setUploadsEnabled(!uploadsEnabled)}
              name={'Uploads'}
              color="primary"
            />
          }
          label={''}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};
