import { Enum_Feature_Flags_Enum } from '@circadian-risk/client-graphql-hooks';
import { useOrganizationSessionStore, useUserSessionStore } from '@circadian-risk/stores';

export const useCanAccessFeatureFlag = (flagName: Enum_Feature_Flags_Enum) => {
  const isCircadianAdmin = useUserSessionStore(state => state.isCircadianAdmin);
  const enabledFeatures = useOrganizationSessionStore(store => store.enabledFeatures);
  if (isCircadianAdmin) {
    return true;
  }

  return enabledFeatures.includes(flagName);
};
