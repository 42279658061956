import { debug, FCC } from '@circadian-risk/front-end-utils';
import EditPhotoIcon from '@mui/icons-material/Gesture';
import { Box, Button, Modal } from '@mui/material';
import debounce from 'lodash/debounce';
import omitBy from 'lodash/omitBy';
import size from 'lodash/size';
import React from 'react';
import isEqual from 'react-fast-compare';
import Lightbox from 'react-image-lightbox';

import { ImageMarkup } from '../ImageEditor/ImageEditor';
import { LightboxImage } from './lightbox.types';
import { useLightbox } from './useLightboxContext';

const ImageEditor = React.lazy(() => import('../ImageEditor/ImageEditor' /* webpackChunkName: "ImageEditor" */));

export interface ImageProcessingOptions {
  id: string;
  flattenedImage: File;
  markup: ImageMarkup;
}
export interface LightboxWithEditingProps {
  onImageProcessing?: (options: ImageProcessingOptions) => void;
}

export const LightboxWithEditing: FCC<LightboxWithEditingProps> = ({ onImageProcessing, children }) => {
  const [initialImageData, setInitialImageData] = React.useState<null | LightboxImage>(null);
  const newMarkupRef = React.useRef<null | ImageMarkup>(null);
  const initialMarkup = initialImageData?.markup ?? null;

  const lightboxStore = useLightbox();
  const { currIndex, getImages, isOpen: isLightboxOpen, onCloseRequest } = lightboxStore;

  const handleEditImageClick = () => {
    const images = getImages();
    if (images[currIndex]?.originalUrl == null) {
      debug.extend('LightboxWithEditing')('Images need original url in order to perform editing');
    } else {
      setInitialImageData(images[currIndex]);
    }
  };

  const handleEdit = (newMarkup: ImageMarkup) => {
    newMarkupRef.current = newMarkup;
  };

  const getImageHasEdits = () => {
    // unused keys are set as undefined by pintura but are filtered out in our backend
    const currentSessionMarkup = omitBy(newMarkupRef.current, value => value === undefined);
    return (
      newMarkupRef.current !== null &&
      currentSessionMarkup != null &&
      size(currentSessionMarkup) > 0 &&
      !isEqual(currentSessionMarkup, initialMarkup)
    );
  };

  const handleEditorClose = () => {
    setInitialImageData(null);
    onCloseRequest();
  };

  const handleEditorCloseWithPrompt = () => {
    const userAgreedToClose = () => {
      const userAgreed = window.confirm('You have unsaved changes! Are you sure you want to close?');
      return userAgreed;
    };

    const imageHasNoEdits = !getImageHasEdits();
    if (imageHasNoEdits || userAgreedToClose()) {
      handleEditorClose();
    }
  };

  const editImageButton = (
    <Button variant="contained" onClick={handleEditImageClick} startIcon={<EditPhotoIcon />}>
      Edit / Draw
    </Button>
  );

  return (
    <>
      {initialImageData && initialImageData.originalUrl && onImageProcessing && (
        <Modal
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1400 }}
          aria-labelledby="image-editing-modal"
          onClose={handleEditorCloseWithPrompt}
          open={Boolean(initialImageData)}
        >
          <Box width="100vw" height="100svh">
            <ImageEditor
              src={initialImageData.originalUrl}
              initialMarkup={initialImageData.markup}
              enableButtonClose
              enableButtonResetHistory
              onClose={handleEditorCloseWithPrompt}
              onUpdate={debounce(handleEdit, 50)}
              onReady={() => {
                // needed since pintura will update the ref upon load
                // with some defaults, which will seem like new changes
                newMarkupRef.current = null;
              }}
              onProcess={({ dest: flattenedImage }) => {
                const newMarkupData = newMarkupRef.current;
                const imageHasEdits = getImageHasEdits();
                if (newMarkupData && imageHasEdits) {
                  onImageProcessing({ flattenedImage, id: initialImageData.id, markup: newMarkupData });
                }
                handleEditorClose();
              }}
            />
          </Box>
        </Modal>
      )}
      {isLightboxOpen && (
        <Lightbox toolbarButtons={[onImageProcessing ? editImageButton : undefined]} {...lightboxStore} />
      )}
      {children}
    </>
  );
};
