import { CropDin } from '@mui/icons-material';
import { Box, Divider, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { MenuGroupItem } from '@web-app/lib/getNavigationGroups';
import React from 'react';
import { NavLink } from 'react-router-dom';

export interface ListPlainProps {
  menuGroupItems: MenuGroupItem[];
  isLastItem: boolean;
}

export const ListPlain: React.FC<ListPlainProps> = ({ isLastItem, menuGroupItems }) => {
  return (
    <>
      {menuGroupItems.map((menuGroupItem: MenuGroupItem, index: number) => {
        if (menuGroupItem.customComponent) {
          return (
            <Box pl={5} key={menuGroupItem.name}>
              {menuGroupItem.customComponent}
            </Box>
          );
        }

        const content = (
          <ListItem key={menuGroupItem.name} button>
            <ListItemIcon>{menuGroupItem.icon ? menuGroupItem.icon : <CropDin />}</ListItemIcon>
            <ListItemText>{menuGroupItem.name}</ListItemText>
          </ListItem>
        );

        if (menuGroupItem.isExternal) {
          return (
            <a href={menuGroupItem.link} key={index} target={'_blank'} rel={'noreferrer'}>
              {content}
            </a>
          );
        }

        return (
          <NavLink to={menuGroupItem.link} key={index}>
            {content}
          </NavLink>
        );
      })}
      {!isLastItem && <Divider />}
    </>
  );
};
