import { Box } from '@mui/material';
import React from 'react';

import { HandlePointChange, HandlePointClick } from '../Map/Map.types';
import { markerFromPoint } from './markerFromPoint.helper';

export interface MarkerPointLayerProps {
  activeDraggingPoint?: string; // a point id
  customImageSize?: number;
  handlePointClick: HandlePointClick;
  handlePointChange: HandlePointChange;
  hasCustomImages?: boolean;
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  markerPoints: any[];
}

export const MarkerPointLayer: React.FC<MarkerPointLayerProps> = ({
  id,
  activeDraggingPoint,
  handlePointClick,
  handlePointChange,
  markerPoints,
}) => {
  const thisLayerContainsDraggablePoint = markerPoints.length === 1 && markerPoints[0].id === activeDraggingPoint;
  return (
    <Box
      id={`marker-layer-${id}`}
      sx={
        thisLayerContainsDraggablePoint
          ? {
              '& .mapboxgl-marker': {
                zIndex: 9999,
              },
            }
          : undefined
      }
    >
      {markerPoints.map(point =>
        markerFromPoint({
          ...point,
          sx: {
            backgroundColor: 'transparent',
            display: 'inline-block',
            borderRadius: '50%',
            position: 'absolute',
            width: 0,
            height: 0,
          },
          handlePointChange,
          handlePointClick,
          draggable: thisLayerContainsDraggablePoint,
        }),
      )}
    </Box>
  );
};
