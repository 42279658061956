import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();

export const eulaContract = c.router(
  {
    signEula: {
      method: 'POST',
      path: '/sign',
      body: z.object({
        eulaId: z.number().positive(),
      }),
      responses: {
        201: z.object({}),
      },
    },
    checkEula: {
      method: 'GET',
      path: '/',
      responses: {
        200: z.object({
          needsToSignEula: z.boolean(),
        }),
      },
    },
  },
  {
    pathPrefix: '/eula',
  },
);
