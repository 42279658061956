import styled from '@emotion/styled';
import { Paper } from '@mui/material';

export const Background = styled(Paper)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5000;
  overflow: hidden;
`;
