import { useApiClient } from '@circadian-risk/api-client-provider';
import {
  useGetAssessmentReportQuery,
  useListenReportPreviewUrlSubscription,
} from '@circadian-risk/client-graphql-hooks';
import { fileSrc, useNotification, useQueryState } from '@circadian-risk/front-end-utils';
import { QueryHandlerComponentProps, useGqlQueryHandler } from '@circadian-risk/graphql-utils';
import { ROUTES } from '@circadian-risk/routes';
import { AssessmentReportSectionTemplateId, propertyConfigSchema } from '@circadian-risk/shared';
import { useOrganizationId } from '@circadian-risk/stores';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Box, Container, Fab, Grid, Typography, useTheme } from '@mui/material';
import { ButtonWithInlineChildren } from '@web-app/components/Buttons';
import { CircadianHeader } from '@web-app/components/CircadianHeader/CircadianHeader';
import { SmartLocationLink } from '@web-app/smart-components/SmartLocationLink';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { generatePath, Redirect, useParams } from 'react-router-dom';

import NavLinkWithActiveOrgId from '../../../../components/NavLinkWithActiveOrgId';
import { AssessmentSummaryExtraContent } from './AssessmentSummaryExtraContent';
import { AssessmentSummaryInfoBox } from './AssessmentSummaryInfoBox';
import { AssessorBioExtraContent } from './AssessorBioExtraContent';
import { ExecutiveSummaryExtraContent } from './ExecutiveSummaryExtraContent';
import { ExecutiveSummaryInfoBox } from './ExecutiveSummaryInfoBox';
import { LocationInformationInfoBox } from './LocationInformationInfoBox';
import { PreviewReport } from './PreviewReport';
import { useReportGenerationStore } from './reportGenerationStore';
import { ReportPreviewTooltipWrapper } from './ReportPreviewTooltipWrapper';
import { RiskAnalysisInfoBox } from './RiskAnalysisInfoBox';
import { SectionForm } from './SectionForm';
import { SectionStepper } from './SectionStepper';

const previewOnlySectionIds: AssessmentReportSectionTemplateId[] = [
  AssessmentReportSectionTemplateId.ExecutiveSummary,
  AssessmentReportSectionTemplateId.TableOfContents,
  AssessmentReportSectionTemplateId.RiskAnalysis,
  AssessmentReportSectionTemplateId.LocationInformation,
  AssessmentReportSectionTemplateId.InspectionQuestionnaire,
  AssessmentReportSectionTemplateId.PhysicalInspection,
  AssessmentReportSectionTemplateId.AssessmentSummary,
];

const reverseSectionContent: AssessmentReportSectionTemplateId[] = [
  AssessmentReportSectionTemplateId.AssessorBiographies,
];

const AssessmentReportGenerationContent: React.FC<QueryHandlerComponentProps<typeof useGetAssessmentReportQuery>> = ({
  data,
}) => {
  const organizationId = useOrganizationId();
  const theme = useTheme();
  const { displayError } = useNotification();
  const report = data.reports[0];
  // Report preview components
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const getReportPreviewUrlQuery = useListenReportPreviewUrlSubscription({
    variables: {
      organizationId,
      reportId: report.id,
    },
  });

  const reportPreviewId = getReportPreviewUrlQuery.data?.report_previews[0]?.id ?? null;
  const reportPreviewStatus = getReportPreviewUrlQuery.data?.report_previews[0]?.status;
  const fileUrl = fileSrc(getReportPreviewUrlQuery.data?.report_previews[0]?.file?.filepath) ?? null;
  const reportStartedAt = getReportPreviewUrlQuery.data?.report_previews[0]?.started_at;
  const reportCopmletedAt = getReportPreviewUrlQuery.data?.report_previews[0]?.completed_at;
  const [previewIdKey, setPreviewIdKey] = useState<number | null>(reportPreviewId);
  useEffect(() => {
    setPreviewIdKey(reportPreviewId);
  }, [reportPreviewId, reportPreviewStatus]);

  const getCustomPreviewBySectionId = useCallback(
    (sectionId: string, isVisible: boolean): React.ReactNode | undefined => {
      const disabled = !isVisible;

      return {
        [AssessmentReportSectionTemplateId.ExecutiveSummary]: (
          <ExecutiveSummaryInfoBox disabled={disabled} assessmentId={report?.assessment?.id} />
        ),
        [AssessmentReportSectionTemplateId.RiskAnalysis]: (
          <RiskAnalysisInfoBox disabled={disabled} locationId={report?.assessment?.scored_node_id ?? undefined} />
        ),
        [AssessmentReportSectionTemplateId.AssessmentSummary]: (
          <AssessmentSummaryInfoBox
            disabled={disabled}
            assessmentId={report?.assessment?.id}
            assessmentState={report?.assessment?.state}
          />
        ),
        [AssessmentReportSectionTemplateId.LocationInformation]: (
          <LocationInformationInfoBox
            disabled={disabled}
            locationId={report?.assessment?.scored_node_id ?? undefined}
          />
        ),
      }[sectionId];
    },
    [report?.assessment?.id, report?.assessment?.scored_node_id, report?.assessment?.state],
  );

  const extraContentMap: Record<string, React.ReactNode> = useMemo(
    () => ({
      [AssessmentReportSectionTemplateId.ExecutiveSummary]: (
        <ExecutiveSummaryExtraContent
          background={report?.assessment?.background ?? ''}
          recommendations={report?.assessment?.recommendations ?? ''}
          findings={report?.assessment?.findings ?? ''}
        />
      ),
      [AssessmentReportSectionTemplateId.AssessmentSummary]: (
        <AssessmentSummaryExtraContent description={report?.assessment?.description ?? null} />
      ),
      [AssessmentReportSectionTemplateId.AssessorBiographies]: (
        <AssessorBioExtraContent assessmentId={report?.assessment?.id} />
      ),
    }),
    [
      report?.assessment?.id,
      report?.assessment?.background,
      report?.assessment?.description,
      report?.assessment?.findings,
      report?.assessment?.recommendations,
    ],
  );

  useEffect(() => {
    if (report) {
      useReportGenerationStore.setState({
        sections: report.sections.map(section => {
          const isItemizedReportSections =
            section.report_section_template_id === AssessmentReportSectionTemplateId.InspectionQuestionnaire ||
            section.report_section_template_id === AssessmentReportSectionTemplateId.PhysicalInspection;
          return {
            id: String(section.id),
            stationary: false,
            visible: section.visible,
            fields: section.fields.map(
              ({ version, field_value, id, field_name, has_default_value, field_type, field_config }) => ({
                value: field_value,
                version,
                id,
                name: field_name,
                hasDefaultValue: has_default_value,
                kind: field_type,
                config: propertyConfigSchema.parse(field_config),
                labelType: isItemizedReportSections ? 'description' : 'name',
                placeholder: isItemizedReportSections
                  ? 'If this field is left empty, it will not appear in the final report'
                  : undefined,
              }),
            ),
            template: {
              id: section.report_section_template_id,
              name: section.name,
            },
            ordinal: section.ordinal,
            isPreviewOnly: previewOnlySectionIds.includes(
              section.report_section_template_id as AssessmentReportSectionTemplateId,
            ),
            customPreviewInfo: getCustomPreviewBySectionId(section.report_section_template_id, section.visible),
            extraContent: extraContentMap[section.report_section_template_id],
          };
        }),
        assessmentId: report.assessment!.id,
        assessmentState: report.assessment!.state,
        reportId: report.id,
      });
    }
  }, [report, getCustomPreviewBySectionId, extraContentMap]);

  const { tsRestClient } = useApiClient();
  const sections = useReportGenerationStore(state => state.sections);
  const setActiveSectionTemplateId = useReportGenerationStore(state => state.setActiveSectionTemplateId);
  const previewDisabled = useReportGenerationStore(state => state.previewDisabled);
  const [triggerPreviewRequest, cancelPreviewRequest] = useReportGenerationStore(state => [
    state.triggerPreviewRequest,
    state.cancelPreviewRequest,
  ]);

  const [activeSection, activeSectionIndex] = useReportGenerationStore(state => [
    state.activeSection,
    state.activeSectionIndex,
  ]);

  const onFirstStep = activeSectionIndex === 0;
  const onLastStep = activeSectionIndex === sections.length - 1;

  // Source of truth for which section we are on
  const [sectionIdState, setSectionIdState] = useQueryState('section', activeSection?.template.id ?? '');

  useEffect(() => {
    if (sectionIdState) {
      setActiveSectionTemplateId(sectionIdState);
    }
  }, [sectionIdState, setActiveSectionTemplateId]);

  const initialSearchSet = useRef(false);
  useEffect(() => {
    if (isEmpty(sectionIdState) && sections.length > 0 && !initialSearchSet.current) {
      setSectionIdState(sections[0].template.id);
      initialSearchSet.current = true;
    }
  }, [sectionIdState, sections, setSectionIdState]);

  const handleNavigationChange = async (newSectionTemplateId: string) => {
    const section = sections.find(s => s.template.id === newSectionTemplateId);
    if (section) {
      setSectionIdState(section.template.id);
    }
  };

  const handleBack = () => {
    const nextSection = sections[activeSectionIndex - 1];
    if (nextSection) {
      setSectionIdState(nextSection.template.id);
    }
  };

  const handleNext = () => {
    const nextSection = sections[activeSectionIndex + 1];
    if (nextSection) {
      setSectionIdState(nextSection.template.id);
    }
  };

  const handlePreviewReportClose = useCallback(() => {
    cancelPreviewRequest();
    setIsPreviewOpen(false);
  }, [cancelPreviewRequest]);

  if (!report) {
    return <span>Not Found</span>;
  }

  const { assessment } = report;

  const handleDownloadPreview = async (skipCache: 'true' | 'false' = 'false') => {
    setPreviewIdKey(null);
    setIsPreviewOpen(true);
    try {
      const result = await triggerPreviewRequest(async cancelToken => {
        const { status, body } = await tsRestClient.reports.produceReport({
          params: {
            organizationId,
            id: report.id,
            assessmentId: assessment!.id,
          },
          query: {
            skipCache,
          },
          body: {},
          cancelToken,
        });

        return status === 201 ? { status: body.status } : undefined;
      });

      if (result?.status === 'completed') {
        setPreviewIdKey(reportPreviewId);
      }
    } catch (err) {
      displayError(err as Error);
      setIsPreviewOpen(false);
    }
  };

  const onRegenerateReport = async () => {
    await handleDownloadPreview('true');
  };

  return (
    <>
      <Helmet>
        <title>{`Report Generator - ${activeSection?.template.name}`}</title>
      </Helmet>
      <CircadianHeader
        header={
          <Box flex={1}>
            <NavLinkWithActiveOrgId
              to={generatePath(ROUTES.ASSESSMENTS_DETAIL, {
                organizationId,
                id: assessment?.id ?? '',
              })}
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                <ArrowBackIcon />
                <span>Assessment Details</span>
              </Box>
            </NavLinkWithActiveOrgId>
            <Box display="flex" flexDirection="row" alignItems="center" my={1}>
              <Typography variant="h4">Report Generator</Typography>
              <Box
                flex={1}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-end"
                gap={theme.spacing(1)}
              >
                <ReportPreviewTooltipWrapper>
                  <ButtonWithInlineChildren onClick={() => handleDownloadPreview()} disabled={previewDisabled}>
                    <span>Preview Report</span>
                    <GetAppIcon />
                  </ButtonWithInlineChildren>
                </ReportPreviewTooltipWrapper>
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" my={1} gap={theme.spacing(2)}>
              <Typography variant="h5">{assessment!.name}</Typography>
              {assessment?.scored_node_id && (
                <SmartLocationLink nodeId={assessment.scored_node_id} includeBreadCrumbs />
              )}
            </Box>
          </Box>
        }
      />
      <Container sx={theme => ({ marginBottom: theme.spacing(6) })}>
        <Grid container>
          <Grid item xs={12} md={3}>
            <SectionStepper handleNavigationChange={handleNavigationChange} />
          </Grid>
          <Grid item xs={12} md={9}>
            {activeSection && (
              <SectionForm
                id={activeSection.id}
                template={activeSection.template}
                reverseContent={reverseSectionContent.includes(
                  activeSection.template.id as AssessmentReportSectionTemplateId,
                )}
              />
            )}
          </Grid>
        </Grid>

        <Box
          position="fixed"
          sx={theme => ({
            left: '50%',
            transform: 'translate(calc(-50%), 0)',
            bottom: 10,
            '& > :not(:first-of-type)': {
              marginLeft: theme.spacing(2),
            },
          })}
        >
          <Fab variant="extended" onClick={handleBack} disabled={onFirstStep}>
            <ArrowBackIcon />
            Back
          </Fab>
          {!onLastStep && (
            <Fab variant="extended" onClick={handleNext} color="primary">
              <span>Next</span>
              <ArrowForwardIcon />
            </Fab>
          )}
        </Box>
      </Container>
      <PreviewReport
        open={isPreviewOpen}
        onClose={handlePreviewReportClose}
        fileUrl={previewIdKey ? fileUrl : null}
        status={reportPreviewStatus}
        startedAt={reportStartedAt}
        completedAt={reportCopmletedAt}
        onRegenerateReport={onRegenerateReport}
      />
    </>
  );
};

const AssessmentReportGenerationPage: React.FC<{ assessmentId: number }> = ({ assessmentId }) => {
  const params = useParams<{ id: string; assessmentId: string }>();
  const organizationId = useOrganizationId();
  const reportId = Number(params.id);
  const { content } = useGqlQueryHandler(
    useGetAssessmentReportQuery,
    {
      variables: {
        assessmentId,
        organizationId,
        id: reportId,
      },
    },
    props => {
      if (props.data.reports.length === 0) {
        return <Redirect to={ROUTES.ROOT} />;
      }
      return <AssessmentReportGenerationContent {...props} />;
    },
  );

  return content;
};
export default AssessmentReportGenerationPage;
