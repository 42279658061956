/* eslint-disable no-console */
import { createContext } from 'react';

import { NotificationProviderContext } from '../interfaces';

export default createContext<NotificationProviderContext>({
  // Initialize with default function but will be lazy loaded
  display: console.log,
  close: console.log,
  displayError: console.log,
  displaySuccess: console.log,
  displayWarning: console.log,
});
