import { ColStateParam } from '../hooks';

export const createAgGridColumnSortingSearchParams = (column: string, sort: 'asc' | 'desc'): string => {
  const params = new URLSearchParams({
    [ColStateParam]: JSON.stringify([
      {
        colId: column,
        sort,
      },
    ]),
  });
  return params.toString();
};
