import { FCC } from '@circadian-risk/front-end-utils';
import ArrowLeftIcon from '@mui/icons-material/ArrowBack';
import { Box, Paper, Typography } from '@mui/material';
import { LinkWithOrgId } from '@web-app/components/NavLinkWithActiveOrgId';
import React from 'react';

export interface NavigationDrawerHeaderProps {
  title: string;
  backLink?: {
    name: string;
    to: string;
  };
}

export const NavigationDrawerHeader: FCC<NavigationDrawerHeaderProps> = ({ title, backLink, children }) => {
  return (
    <Paper elevation={3} square sx={theme => ({ padding: theme.spacing(2), paddingRight: theme.spacing(4) })}>
      <Box>
        {backLink && (
          <LinkWithOrgId
            sx={theme => ({
              ...theme.typography.button,
              alignItems: 'center',
              display: 'flex',
              cursor: 'pointer',
              fontSize: theme.typography.caption.fontSize,
            })}
            href={backLink.to}
          >
            <ArrowLeftIcon sx={theme => ({ height: theme.spacing(2), width: theme.spacing(2) })} />
            {backLink.name}
          </LinkWithOrgId>
        )}
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box flex={1}>
          <Typography variant="h6">{title}</Typography>
        </Box>
        {children}
      </Box>
    </Paper>
  );
};
