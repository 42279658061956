import CloseIcon from '@mui/icons-material/Close';
import { Divider, Stack, Typography } from '@mui/material';
import { useRef } from 'react';
import { useUnmount } from 'react-use';

import { HStack } from '../../../..';
import { Bold } from '../../../Text';
import { FilterIconType } from '../../filter.types';
import { ChipButton } from '../ChipButton';
import { CHIP_HEIGHT, SPACING } from '../constants';
import { FilterIcon } from '../FilterIcon';

interface SelectedFilterChipProps {
  close: () => void;
  onChipDeleteClick: (value: string) => void;
  icon: FilterIconType;
  value: string;
  label: React.ReactNode;
  operator: React.ReactNode;
  state: React.ReactNode;
  openDropdown: (anchorRef: HTMLElement, defaultSelectedOption: string) => void;
}

export const SelectedFilterChip: React.FC<SelectedFilterChipProps> = ({
  state,
  label,
  operator,
  value,
  icon,
  close,
  onChipDeleteClick,
  openDropdown,
}) => {
  const filterChipRef = useRef<HTMLDivElement | null>(null);

  // Make sure that once the chip unmounts we close any possible popper open
  useUnmount(close);

  return (
    <Stack
      ref={filterChipRef}
      alignItems="center"
      direction="row"
      overflow="hidden"
      divider={<Divider sx={{ height: CHIP_HEIGHT - 4 }} orientation="vertical" />}
      key={value}
      sx={{
        border: theme => `1px solid ${theme.palette.action.active}`,
        height: CHIP_HEIGHT,
        whiteSpace: 'nowrap',
        borderRadius: 2,
      }}
    >
      <HStack noFullWidth display="flex" px={SPACING} spacing={0.5}>
        <FilterIcon icon={icon} />
        <Typography variant="caption" color="text.secondary" sx={{ userSelect: 'none' }}>
          {label}
        </Typography>
      </HStack>

      {operator}

      <ChipButton onClick={e => openDropdown(filterChipRef.current ?? (e.target as HTMLButtonElement), value)}>
        <Bold color="primary.main">{state}</Bold>
      </ChipButton>

      <ChipButton onClick={() => onChipDeleteClick(value)}>
        <CloseIcon fontSize="small" sx={{ color: theme => theme.palette.action.active }} />
      </ChipButton>
    </Stack>
  );
};
