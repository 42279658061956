export const isDirectChildOf = (candidateChild: string, candidateParent: string) => {
  return (
    candidateChild.startsWith(candidateParent) &&
    candidateChild.split('.').length - 1 === candidateParent.split('.').length
  );
};

export const isDescendantOrSelfOf = (candidateDescendant: string, candidateAncestor: string) => {
  return (
    candidateDescendant.startsWith(candidateAncestor) &&
    candidateDescendant.split('.').length >= candidateAncestor.split('.').length
  );
};

export const isAncestorOrSelfOf = (source: string, target: string) => {
  return source === target || target.startsWith(source);
};
