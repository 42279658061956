import { useApolloClient, useQuery } from '@apollo/client';
import { TypedNodes } from '@circadian-risk/client-graphql-hooks';
import { useFileManagerStore } from '@circadian-risk/file-manager';
import { FCC } from '@circadian-risk/front-end-utils';
import { LoadingPage } from '@circadian-risk/layout';
import { USER_ROLE } from '@circadian-risk/shared';
import {
  ActiveOrganizationData,
  GlobalOrganizationContextNode,
  GlobalOrganizationLayer,
  SemanticLayerName,
  useOrganizationSessionStore,
  useOrgRoleQueryPrecedence,
  useThemeStore,
} from '@circadian-risk/stores';
import fromPairs from 'lodash/fromPairs';
import keyBy from 'lodash/keyBy';
import React, { useEffect } from 'react';

import { AllowedNodesProvider } from './AllowedNodesProvider';
import { FeatureFlagsProvider } from './FeatureFlagsProvider';
import { LocationSyncProvider } from './LocationSyncProvider';
import { UsersProvider } from './UsersProvider';

interface GlobalOrganizationWrapperInterface {
  organizationId: string;
}

const GlobalOrganizationWrapperContents: FCC<{
  organizationId: string;
  data: TypedNodes.GetAppWrapperDataQuery;
  refetch: () => Promise<any>;
}> = ({ organizationId, data, refetch, children }) => {
  const { cache } = useApolloClient();
  const setFileManagerOrgId = useFileManagerStore(state => state.setActiveOrganizationId);

  useEffect(() => {
    setFileManagerOrgId(organizationId);
  }, [organizationId, setFileManagerOrgId]);

  useEffect(() => {
    if (data?.organizations_by_pk) {
      const org = data.organizations_by_pk;
      const { meta, name } = org;
      const rawThresholdLabels = org?.scenarioRangeLabels?.[0];
      const themeOverride = org?.theme_overrides;

      const thresholdLabels: ActiveOrganizationData['thresholdLabels'] | undefined = rawThresholdLabels
        ? {
            low: rawThresholdLabels.lower_range_label!,
            medium: rawThresholdLabels.mid_range_label!,
            high: rawThresholdLabels.upper_range_label!,
          }
        : undefined;

      useThemeStore.setState({
        primaryColor: themeOverride?.primary_color ?? undefined,
        lightLogoSrc: themeOverride?.light_logo?.filepath ?? undefined,
        darkLogoSrc: themeOverride?.dark_logo?.filepath ?? undefined,
        organizationColors: themeOverride?.organization_colors ?? undefined,
        finishedFetchingTheme: Boolean(org),
      });

      const tags = org.layers.flatMap(l => l.layerTags);
      const tagsById = keyBy(tags, t => t.id);

      const semanticLayerMapping: Record<string, SemanticLayerName> = {
        // TODO(iprokopovich)[CR-1578]: once these layers are enforced to be non-null, remove all the question marks
        [meta?.organization_layer_id ?? '']: 'organization',
        [meta?.campus_layer_id ?? '']: 'campus',
        [meta?.building_layer_id ?? '']: 'building',
        [meta?.level_layer_id ?? '']: 'level',
      };

      const nodes = org.nodes.map<GlobalOrganizationContextNode>(node => ({
        ...node,
        childrenCount: node.nodes_aggregate.aggregate?.count ?? 0,
        tags: node.layerTags.map(({ layer_tag_id }) => tagsById[layer_tag_id]),
        created_at: node.created_at,
        hasFloorPlan: node.mapbox_map_id != null,
        teamsAndUserIds: node.layerTeamUsers.map(({ layer_team_id, user_id }) => ({
          teamId: layer_team_id,
          userId: user_id,
        })),
      }));

      const layers = org.layers
        .map<GlobalOrganizationLayer>(layer => ({
          ...layer,
          nodeCount: layer.nodes_aggregate.aggregate?.count ?? 0,
          layerDepth: layer.layerDepth ?? 0,
          semanticName: semanticLayerMapping[layer.id] ?? null,
          teams: layer.teams.map(t => ({
            id: t.id,
            name: t.name,
          })),
        }))
        .sort((layer1, layer2) => layer1.layerDepth - layer2.layerDepth);

      const criticalityLabels = fromPairs((org.criticalityLabels ?? []).map(x => [x.criticality_value, x.label]));
      const nodesById = keyBy(nodes, e => e.id);
      const layersById = keyBy(layers, e => e.id);
      const rootNodeId = nodes.find(x => x.parent_id === null)?.id ?? '';

      useOrganizationSessionStore.setState({
        id: organizationId,
        name,
        primaryColor: themeOverride?.primary_color ?? undefined,
        organizationColors: themeOverride?.organization_colors ?? undefined,
        onNodesUpdated: refetch,
        onLayersUpdated: refetch,
        nodes,
        nodesById,
        layersById,
        rootNodeId,
        layers,
        scoredLayerId: org.scored_layer_id ?? '',
        criticalityLabels,
        scenarios: org.scenarios,
        groups: org.groups.map(g => ({
          id: g.id,
          name: g.name,
          userIds: g.users.map(u => u.user_id),
        })),
      });

      if (thresholdLabels) {
        useOrganizationSessionStore.setState({
          thresholdLabels,
        });
      }
    }
  }, [data, organizationId, refetch, cache]);

  return <>{children}</>;
};

export const GlobalOrganizationWrapper: FCC<GlobalOrganizationWrapperInterface> = ({ children, organizationId }) => {
  const queryContext = useOrgRoleQueryPrecedence(USER_ROLE.ORGANIZATION_ADMIN, USER_ROLE.MEMBER);

  const { data, refetch, loading } = useQuery(TypedNodes.GetAppWrapperDataDocument, {
    variables: { organizationId },
    context: queryContext,
    // Prevents unnecessary trips to the server
    fetchPolicy: 'cache-first',
  });

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <>
      <GlobalOrganizationWrapperContents organizationId={organizationId} data={data!} refetch={refetch}>
        {children}
      </GlobalOrganizationWrapperContents>
      <UsersProvider organizationId={organizationId} />
      <LocationSyncProvider />
      <AllowedNodesProvider />
      <FeatureFlagsProvider organizationId={organizationId} />
    </>
  );
};
