import { Box, FormControl, FormHelperText, OutlinedInput, useTheme } from '@mui/material';
import { Controller } from 'react-hook-form';

import { PropertyFieldProps } from '../types';

export interface PropertyCodeFieldProps extends Omit<PropertyFieldProps, 'label'> {
  label?: string;
  autoFocus?: boolean;
  placeholder?: string;
  maxLength?: number;
  onlyNumbers?: boolean;
}

export const PropertyCodeField: React.FC<PropertyCodeFieldProps> = ({
  autoFocus,
  label,
  htmlName,
  control,
  disabled,
  rules,
  boxProps,
  errorMessage,
  helperText,
  startAdornment,
  endAdornment,
  type,
  onlyNumbers,
  maxLength,
  onBlur: blurCallback,
}) => {
  const id = `text-field-${htmlName}`;
  const { typography } = useTheme();
  const font = typography.h5;
  const hasError = Boolean(errorMessage);

  return (
    <Box {...boxProps}>
      <Controller
        control={control}
        name={htmlName}
        defaultValue={null}
        rules={rules}
        render={fieldProps => {
          const { onChange, value, onBlur } = fieldProps.field;
          const handleBlur = () => {
            onBlur();
            if (blurCallback) {
              blurCallback(value);
            }
          };
          return (
            <FormControl variant="outlined" fullWidth disabled={disabled} error={Boolean(errorMessage)}>
              <OutlinedInput
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={autoFocus}
                onBlur={handleBlur}
                id={id}
                name={htmlName}
                value={value ?? ''}
                onChange={e => {
                  if (onlyNumbers && e.target.value.match(/[^0-9]/)) {
                    return;
                  }
                  onChange(e);
                }}
                data-name={label}
                endAdornment={endAdornment}
                startAdornment={startAdornment}
                placeholder={label}
                type={type ?? 'tel'}
                inputProps={{
                  'aria-label': label,
                  maxLength,
                  style: {
                    fontSize: font.fontSize,
                    fontFamily: font.fontFamily,
                    letterSpacing: '18px',
                    textTransform: 'uppercase',
                    textAlign: 'center',
                  },
                }}
              />
              <FormHelperText error={hasError}>{errorMessage ?? helperText}</FormHelperText>
            </FormControl>
          );
        }}
      />
    </Box>
  );
};
