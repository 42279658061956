import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';

export interface SearchProps {
  text: string;
  onTextChange: (value: string) => void;
  onReset?: () => void;
  endAdornmentRenderer?: (showReset: boolean, handleReset: () => void) => React.ReactNode;
}
export const Search: React.FC<SearchProps & Partial<TextFieldProps>> = ({
  text,
  onTextChange,
  onReset,
  endAdornmentRenderer,
  ...textFieldProps
}) => {
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up('sm'));
  const handleReset = () => {
    onTextChange('');
    if (onReset) {
      onReset();
    }
  };
  const showReset = [text].some(x => x);

  return (
    <Box display="flex" flexDirection={isNotMobile ? 'row' : 'column'} alignItems="center">
      <TextField
        fullWidth
        placeholder="Search..."
        {...textFieldProps}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: endAdornmentRenderer ? (
            endAdornmentRenderer(showReset, handleReset)
          ) : (
            <InputAdornment
              position="end"
              sx={theme => ({
                '& > :not(:first-of-type)': {
                  marginLeft: theme.spacing(0.5),
                },
              })}
            >
              {showReset && (
                <Tooltip title="Reset">
                  <IconButton size="small" onClick={handleReset}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              )}
            </InputAdornment>
          ),
        }}
        value={text}
        onChange={({ target: { value } }) => {
          onTextChange(value);
        }}
      />
    </Box>
  );
};
