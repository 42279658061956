import { useIsPhoneScreen } from '@circadian-risk/front-end-utils';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Drawer, Fab, IconButton, Tooltip } from '@mui/material';
import React, { useContext } from 'react';

import { DrawerContext } from './DrawerContext';
import { DrawerStyleProps, getDrawerSx } from './styles';

export interface DrawerProps extends DrawerStyleProps {
  children: React.ReactNode;
  hideControl?: boolean;
}

const CustomDrawer = ({ children, top = 64, drawerWidth, hideControl = false }: DrawerProps) => {
  const isMobile = useIsPhoneScreen();
  const drawerSx = getDrawerSx({ top, drawerWidth });

  const { isOpen, setIsOpen } = useContext(DrawerContext);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const tooltipLabel = isOpen ? 'Close Menu' : 'Open Menu';

  return (
    <>
      <Drawer
        variant="persistent"
        anchor="left"
        open={isOpen}
        PaperProps={{
          sx: [drawerSx.drawerPaper, isMobile ? drawerSx.drawerPaperMobile : drawerSx.drawerPaperNotMobile],
        }}
        sx={[
          drawerSx.drawer,
          !isOpen
            ? theme => ({
                '&.MuiDrawer-docked': {
                  // On tablet, drawer "floats" over content.
                  // Explicitly set visibility hidden to allow events to pass through
                  visibility: 'hidden',
                  transition: theme.transitions.create('visibility', {
                    delay: theme.transitions.duration.leavingScreen,
                  }),
                },
              })
            : {},
        ]}
      >
        <Box height="100%" position="relative" display="flex" flexDirection="column">
          <Box sx={drawerSx.mobileToggleBox}>
            <IconButton onClick={() => setIsOpen(!isOpen)} size="large">
              <ChevronLeftIcon />
            </IconButton>
          </Box>
          {children}
        </Box>
      </Drawer>

      {!hideControl && (
        <Tooltip title={tooltipLabel} aria-label={tooltipLabel}>
          <Fab
            size="small"
            aria-label="Close Menu"
            sx={[
              drawerSx.fab,
              ...(isOpen ? [drawerSx.fabShift] : []),
              ...(isMobile && isOpen ? [drawerSx.fabHidden] : []),
            ]}
            onClick={handleClick}
          >
            {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </Fab>
        </Tooltip>
      )}
    </>
  );
};

export default CustomDrawer;
