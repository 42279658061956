import { ChevronLeft } from '@mui/icons-material';
import { Box, Button, DialogActions } from '@mui/material';

export interface DialogActionsWithBackActionProps {
  /**
   * If provided renders a back button
   */
  onBackClick?: () => void;
  /**
   * @default "Return to Selected"
   */
  backActionText?: string;
  onCancelClick: () => void;
  actions: React.ReactNode;
}

export const DialogActionsWithBackAction: React.FC<DialogActionsWithBackActionProps> = ({
  onBackClick,
  onCancelClick,
  actions,
  backActionText = 'Return to Selected',
}) => {
  return (
    <DialogActions sx={onBackClick ? { justifyContent: 'flex-start' } : undefined}>
      {onBackClick && (
        <Box display="flex" flex={1}>
          <Button startIcon={<ChevronLeft />} onClick={onBackClick}>
            {backActionText}
          </Button>
        </Box>
      )}
      <Button color="secondary" onClick={onCancelClick}>
        Cancel
      </Button>
      {actions}
    </DialogActions>
  );
};
