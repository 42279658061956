import { ImportAnswerGroupValueType } from '@circadian-risk/api-contract';
import {
  CompliantIcon,
  DeficientIcon,
  HStack,
  InfoBox,
  NotApplicableIcon,
  NotObservedIcon,
  VStack,
} from '@circadian-risk/presentational';
import { Box, Chip, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { CreateScheduledAssessmentFormSchema } from '../../../form';

const chipSx = { borderRadius: '5px' };

const importedAnswersBadgeByType: Record<ImportAnswerGroupValueType, React.ReactNode> = {
  compliant: <Chip color="primary" label="Compliant" icon={<CompliantIcon />} sx={chipSx} />,
  deficient: <Chip color="error" label="Deficient" icon={<DeficientIcon />} sx={chipSx} />,
  'n/a': <Chip color="secondary" label="N/A" icon={<NotApplicableIcon />} sx={chipSx} />,
  'n/o': <Chip color="secondary" label="N/O" icon={<NotObservedIcon />} sx={chipSx} />,
};

export const ImportedAnswersContent: React.FC = () => {
  const { watch } = useFormContext<CreateScheduledAssessmentFormSchema>();
  const importedAnswers = watch('importAnswers');

  return (
    <VStack>
      <Typography variant="subtitle1">Imported Answers:</Typography>
      {importedAnswers.type === 'fresh' ? (
        <InfoBox>No answers will be imported and all questions will require an answer</InfoBox>
      ) : (
        <HStack>
          {importedAnswers.value.map(v => {
            return <Box key={v}>{importedAnswersBadgeByType[v]}</Box>;
          })}
        </HStack>
      )}
    </VStack>
  );
};
