import { keyframes, styled } from '@mui/material';

const typing = keyframes({
  '0%': {
    opacity: 0.1,
    transform: 'translateY(-10px)',
  },
  '50%': {
    opacity: 1,
    transform: 'translateY(0)',
  },
  '100%': {
    opacity: 0.1,
    transform: 'translateY(10px)',
  },
});

const AITypingWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '> *': {
    margin: '0 2px',
    width: 4,
    height: 4,
    borderRadius: '50%',
    backgroundColor: theme.palette.text.secondary,
    animation: `${typing} 1s infinite`,
  },
  '> :nth-child(2)': {
    animationDelay: '0.1s',
  },
  '> :nth-child(3)': {
    animationDelay: '0.2s',
  },
  '> :nth-child(4)': {
    animationDelay: '0.3s',
  },
}));

export const AITypingAnimation = () => (
  <AITypingWrapper>
    <div />
    <div />
    <div />
    <div />
  </AITypingWrapper>
);
