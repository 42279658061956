import { SvgIcon, SvgIconProps } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AnimatedCheckmarkIconProps {}

const AnimatedCheckmarkSvg = () => (
  <svg
    style={{ padding: 2 }}
    viewBox="0 0 24 24"
    height="100%"
    width="100%"
    fill="none"
    stroke="currentColor"
    strokeWidth={3}
  >
    <motion.path
      initial={{ pathLength: 0 }}
      animate={{ pathLength: 1 }}
      transition={{ duration: 0.5, ease: 'circOut', delay: 0.2, bounce: 1 }}
      exit={{ opacity: 0, pathLength: 0 }}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5 13l4 4L19 7"
    />
  </svg>
);

export const AnimatedCheckmarkIcon: React.FC<SvgIconProps> = props => {
  return <SvgIcon {...props} inheritViewBox component={AnimatedCheckmarkSvg} />;
};
