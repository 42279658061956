import { AssignmentTab } from '@circadian-risk/assessment-components';
import { TabObject, TabsWithContent } from '@circadian-risk/presentational';
import maxBy from 'lodash/maxBy';

import { GroupsTabContent } from './GroupsTabContent';
import { TeamsTabContent } from './TeamsTabContent';
import { AssignmentGroupRow, AssignmentTeamRow, AssignmentUserRow } from './types';
import { UsersTabContent } from './UsersTabContent';

type AssignmentTabbedTableProps = {
  users: AssignmentUserRow[];
  teams: AssignmentTeamRow[];
  groups: AssignmentGroupRow[];
};

export const AssignmentTabbedTable: React.FC<AssignmentTabbedTableProps> = ({ users, groups, teams }) => {
  const tabs: TabObject<AssignmentTab>[] = [
    {
      value: 'user',
      customLabel: `USER (${users.length})`,
      content: <UsersTabContent users={users} />,
    },
    {
      value: 'userGroup',
      customLabel: `USER GROUPS (${groups.length})`,
      content: <GroupsTabContent groups={groups} />,
    },
    {
      value: 'teams',
      customLabel: `TEAMS (${teams.length})`,
      content: <TeamsTabContent teams={teams} />,
    },
  ];

  const aggregates: { tab: AssignmentTab; count: number }[] = [
    { tab: 'user', count: users.length },
    { tab: 'userGroup', count: groups.length },
    { tab: 'teams', count: teams.length },
  ];

  // Figure out the tab with the most items and select it by default
  const initialTabSelected = maxBy(aggregates, e => e.count)?.tab || 'user';

  return <TabsWithContent tabs={tabs} initialTabSelected={initialTabSelected} />;
};
