import { tsObject } from '@circadian-risk/shared';
import { Tooltip } from '@mui/material';
import React from 'react';

import { HStack } from '../Layouts';
import { OptionForConsiderationIcon } from '../OptionForConsiderationIcon';
import { QuestionAttachmentsIcon } from '../QuestionAttachmentsIcon';
import { QuestionDescriptionIcon } from '../QuestionDescriptionIcon';

export const QuestionMoreInfoStatusIconsMap = {
  files: {
    icon: QuestionAttachmentsIcon,
    label: 'Files',
  },
  description: {
    icon: QuestionDescriptionIcon,
    label: 'Description',
  },
  options: { icon: OptionForConsiderationIcon, label: 'Custom Option for Consideration' },
} as const;

export interface StatusIconsProps {
  activeFields: Record<keyof typeof QuestionMoreInfoStatusIconsMap, boolean>;
}

export const QuestionMoreInfoStatusIcons: React.FC<StatusIconsProps> = ({ activeFields }) => (
  <HStack width="unset">
    {tsObject.entries(activeFields).map(([name, enabled]) => {
      const Icon = QuestionMoreInfoStatusIconsMap[name].icon;

      return (
        <Tooltip title={QuestionMoreInfoStatusIconsMap[name].label} key={name}>
          <Icon fontSize="medium" color={enabled ? 'primary' : 'disabled'} />
        </Tooltip>
      );
    })}
  </HStack>
);
