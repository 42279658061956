import { FieldValues, useFormContext } from 'react-hook-form';

import { PropertyCheckboxMultiField, PropertyCheckboxMultiFieldProps, PropertyFieldProps } from '../../PropertyFields';
import { useCircadianFormContext } from '../CircadianFormContext';
import { FormField } from './types';
import { useErrorMessage } from './utils';

export type CheckboxMultiFFProps<FormData> = FormField<FormData> &
  Omit<PropertyFieldProps, 'control' | 'htmlName' | 'label'> & {
    options: Array<{ label: string; value: string }>;
    onChange?: PropertyCheckboxMultiFieldProps['onChange'];
  };

export function CheckboxMultiFF<FormData extends FieldValues>({
  formKey,
  boxProps,
  ...rest
}: CheckboxMultiFFProps<FormData>) {
  const { control, formState } = useFormContext<FormData>();
  const { ariaLabels, disabled } = useCircadianFormContext<FormData>();

  const errorMessage = useErrorMessage(formState.errors, formKey);

  return (
    <PropertyCheckboxMultiField
      {...{ disabled, control, errorMessage, ...rest }}
      label={ariaLabels[formKey]}
      htmlName={formKey}
      checkboxBoxProps={boxProps}
    />
  );
}
