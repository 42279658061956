import { Box, Typography } from '@mui/material';
import { PropertyDescriptionText } from '@web-app/components/Details/DetailListing';
import isEmpty from 'lodash/isEmpty';
import React from 'react';

export interface AssessmentSummaryExtraContentProps {
  description: string | null;
}

export const AssessmentSummaryExtraContent: React.FC<AssessmentSummaryExtraContentProps> = ({ description }) => {
  const text = !isEmpty(description)
    ? description
    : 'There is no content for this section. Select the edit button to fill in information';
  return (
    <Box>
      <Typography variant={'h6'}>Description</Typography>
      <Box my={1}>
        <PropertyDescriptionText value={text} />
      </Box>
    </Box>
  );
};
