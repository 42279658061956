import { createEnhancedStore } from '@circadian-risk/front-end-utils';

import { AssessmentFormStore } from './ScenarioAssessmentForm.types';

export const useAssessmentFormStore = createEnhancedStore<AssessmentFormStore>((set, get) => ({
  templates: [],
  relevantNodes: [],
  editing: false,
  usingPreviousAssessmentData: false,
  selectTemplate: templateId => {
    const selectedTemplate = get().templates.find(t => t.id === templateId);
    if (selectedTemplate) {
      set({
        selectedTemplate,
      });
    }
  },
  clean: () => {
    set({
      relevantNodes: [],
      templates: [],
    });
  },
}));
