import { createEnhancedStore, FCC } from '@circadian-risk/front-end-utils';
import { useUserSessionStore } from '@circadian-risk/stores';
import * as Sentry from '@sentry/react';
import { PromisePool } from '@supercharge/promise-pool';
import axios, { CancelTokenSource } from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';
import take from 'lodash/take';
import { useContext, useEffect } from 'react';
import { StateCreator } from 'zustand';
import { persist } from 'zustand/middleware';

import { FileManagerDatabaseContext } from '../database';
import { FileManagerDatabase, IFileItem, UploadEndpointInfo, UploadStatus } from '../types';

export type FileManagerContextId = string | number;

export interface FileManagerStore {
  uploadsEnabled: boolean;
  setUploadsEnabled: (enabled: boolean) => void;

  maxConcurrentUploads: number;
  setMaxConcurrentUploads: (value: number) => Promise<void>;

  db?: FileManagerDatabase;
  /**
   * Generic file upload handler callback should be injected through context
   */
  genericFileUpload?: FileManagerWrapperProps['genericFileUpload'];
  setDb: (db: FileManagerDatabase) => Promise<void>;

  files: IFileItem[];
  activeOrganizationId?: string;
  setActiveOrganizationId: (id: string) => void;
  /**
   * Providing a file filter will make sure to subscribe and pass
   * the filtered files
   * By default everything will come so be careful when fetching the files
   */
  activeFileFilter?: FileManagerFilter;
  /**
   * Sets the new file filter and re-evaluates the RxDB subscription
   *
   * @description Avoid using `setState` otherwise some side effects are not handled
   */
  setActiveFileFilter: (filter?: FileManagerFilter) => void;
  /**
   * Allows developers to re-trigger the subscriptions setup with its new configuration
   */
  resubscribe: () => void;

  addFile: (file: File, uploadEndpointInfo: UploadEndpointInfo) => Promise<void>;
  removeFile: (id: string) => Promise<boolean>;
  updateFile: (id: string, update: Partial<IFileItem>) => Promise<void>;
  getFileBlob: (id: string) => Promise<Blob | null>;
  clearCompletedFiles: () => Promise<void>;

  cancelMostRecentRequests: (amount: number) => void;

  fileLinkInfo: UploadEndpointInfo | null;
  setFileLinkInfo: (info: UploadEndpointInfo | null) => void;
}

export type FileManagerFilter = (file: IFileItem) => boolean;

const getPendingFilesToUpload = (files: IFileItem[], maxConcurrentUploads: number) => {
  const numberOfFilesToUpload = Math.max(
    maxConcurrentUploads - files.filter(e => e.status === UploadStatus.Uploading).length,
    0,
  );

  const pendingFilesToUpload = take(
    files.filter(e => e.status === UploadStatus.Pending),
    numberOfFilesToUpload,
  );

  return pendingFilesToUpload;
};

const createStateCreator: () => StateCreator<FileManagerStore> = () => {
  let currentlyUploading = false;

  return (set, get) => {
    let pollingInterval: number | null = null;

    const triggerUploads = async () => {
      const { updateFile, getFileBlob, maxConcurrentUploads, files, uploadsEnabled } = get();
      if (!uploadsEnabled || currentlyUploading) {
        return;
      }
      currentlyUploading = true;
      const pendingFilesToUpload: (IFileItem & { cancelTokenSource: CancelTokenSource })[] = getPendingFilesToUpload(
        files,
        maxConcurrentUploads,
      ).map(pf => {
        return {
          ...pf,
          cancelTokenSource: axios.CancelToken.source(),
        };
      });

      if (pendingFilesToUpload.length === 0) {
        currentlyUploading = false;
        return;
      }

      // This update could take a while
      for (const pf of pendingFilesToUpload) {
        await updateFile(pf.id, {
          status: UploadStatus.Uploading,
          uploadProgressInfo: {
            speed: 0,
            percentage: 0,
            loaded: 0,
            total: 0,
          },
          cancelToken: pf.cancelTokenSource,
        });
      }

      try {
        await PromisePool.withConcurrency(maxConcurrentUploads)
          .for(pendingFilesToUpload)
          .process(async pf => {
            const blob = await getFileBlob(pf.id);
            const apiCall = get().genericFileUpload;

            if (!apiCall) {
              throw new Error(
                `API is not defined, please make sure that "FileManagerWrapper" provides "genericFileUpload" callback`,
              );
            }

            if (!blob) {
              return updateFile(pf.id, {
                uploadProgressInfo: undefined,
                status: UploadStatus.Failed,
              });
            }

            if (!apiCall) {
              return updateFile(pf.id, {
                uploadProgressInfo: undefined,
                status: UploadStatus.Failed,
              });
            }

            try {
              const startTimestamp = Date.now();
              const success = await apiCall({
                file: pf,
                blob,
                cancelTokenSource: pf.cancelTokenSource,
                onUploadProgress: (p: ProgressEvent) => {
                  if (!p.lengthComputable) {
                    return;
                  }
                  const bytesPerMs = p.loaded / (Date.now() - startTimestamp);
                  const bytesPerSec = bytesPerMs * 1000;
                  const percent = (p.loaded / p.total) * 100;
                  void updateFile(pf.id, {
                    uploadProgressInfo: {
                      percentage: percent,
                      speed: bytesPerSec,
                      loaded: p.loaded,
                      total: p.total,
                    },
                  });
                },
              });

              // Mark as complete
              if (success) {
                await updateFile(pf.id, {
                  uploadProgressInfo: undefined,
                  status: UploadStatus.Completed,
                });
              }
            } catch (e) {
              if (axios.isCancel(e)) {
                await updateFile(pf.id, {
                  uploadProgressInfo: undefined,
                  status: UploadStatus.Pending,
                });
                return;
              }
              Sentry.captureException(e);
              await updateFile(pf.id, {
                uploadProgressInfo: undefined,
                status: UploadStatus.Failed,
              });
            }
          });
      } catch (err) {
        const user = useUserSessionStore.getState();
        const sentryEvent: Sentry.Event = {
          message: `${JSON.stringify(err)}`,
          level: 'error',
          user: {
            id: user.id,
            email: user.email,
          },
        };
        Sentry.captureEvent(sentryEvent);
      }
      currentlyUploading = false;
    };

    return {
      uploadsEnabled: false,
      maxConcurrentUploads: 1,
      files: [],
      activeFileFilter: undefined,
      fileLinkInfo: null,
      api: undefined,
      resubscribe: () => {
        const { db, setDb } = get();
        // Ask to subscribe again so that it can load new configurations if set
        if (db) {
          void setDb(db);
        }
      },
      setActiveOrganizationId: id => {
        set({ activeOrganizationId: id });
        get().resubscribe();
      },
      setActiveFileFilter: filter => {
        set({ activeFileFilter: filter });
        get().resubscribe();
      },
      setDb: async db => {
        const { db: lastDb } = get();
        // Unsubscribe from previous db
        if (lastDb) {
          lastDb.unsubscribe();
        }

        if (pollingInterval) {
          window.clearInterval(pollingInterval);
        }

        // Initialize DB
        set({
          db,
        });

        // Initialize Subscriptions
        db.subscribe(unfilteredFiles => {
          const { activeFileFilter, activeOrganizationId } = get();
          const fileEntities = (activeFileFilter ? unfilteredFiles.filter(activeFileFilter) : unfilteredFiles).filter(
            f => {
              if (!activeOrganizationId) {
                return false;
              }
              return f.uploadEndpointInfo.organizationId === activeOrganizationId;
            },
          );
          const promises: Promise<IFileItem[]> = Promise.all(
            fileEntities.map(async f => {
              const storeFile = get().files.find(sf => f.id === sf.id);

              let url: string | undefined;
              if (storeFile?.url) {
                url = storeFile.url;
              } else {
                const blob = await db.getBlob(f.id);
                if (blob) {
                  url = URL.createObjectURL(blob);
                }
              }

              return {
                id: f.id,
                insertedAt: f.insertedAt,
                name: f.name,
                size: f.size,
                status: f.status,
                type: f.type,
                uploadEndpointInfo: f.uploadEndpointInfo,

                // Merge uploading information
                cancelToken: storeFile?.cancelToken,
                uploadProgressInfo: storeFile?.uploadProgressInfo,
                url,
              };
            }),
          );

          void promises.then(nextFiles => {
            set({
              files: nextFiles.sort((a, b) => {
                return b.insertedAt - a.insertedAt;
              }),
            });
          });
        });

        pollingInterval = window.setInterval(() => {
          const { files, uploadsEnabled, maxConcurrentUploads } = get();
          if (
            getPendingFilesToUpload(files, maxConcurrentUploads).length > 0 &&
            uploadsEnabled &&
            !currentlyUploading
          ) {
            void triggerUploads();
          }
        }, 1000);

        // Update any existing uploading files back to pending
        const initialFiles = await db.get();
        initialFiles.forEach(f => {
          if (f.status === UploadStatus.Uploading) {
            void db.update(f.id, { status: UploadStatus.Pending });
          }
        });
      },

      addFile: async (file: File, uploadEndpointInfo: UploadEndpointInfo) => {
        const db = get().db!;
        return db.insert(file, uploadEndpointInfo);
      },

      getFileBlob: async (id: string) => {
        const db = get().db;
        if (!db) {
          return null;
        }
        const result = await db.getBlob(id);
        return result;
      },

      removeFile: async (id: string) => {
        const { db, files } = get();

        const success = await db!.remove(id);
        if (success) {
          const iFile = files.find(f => f.id === id);

          // Cancel any current upload that might be active
          if (iFile?.cancelToken) {
            iFile.cancelToken.cancel();
          }

          if (iFile?.url) {
            URL.revokeObjectURL(iFile.url);
          }
        }

        return success;
      },

      updateFile: async (id: string, update: Partial<IFileItem>) => {
        const db = get().db!;

        // Do a separate update on the store for upload info
        const storeUpdate = pick(update, ['uploadProgressInfo', 'cancelToken']);
        if (!isEmpty(storeUpdate)) {
          const files = cloneDeep(get().files);
          const found = files.find(f => f.id === id);
          merge(found, update);

          set({
            files,
          });
        }

        // Special handling when changing state from Uploading - cancel the request
        const file = get().files.find(f => f.id === id);

        if (
          update.status &&
          update.status !== UploadStatus.Uploading &&
          file?.status === UploadStatus.Uploading &&
          file.cancelToken
        ) {
          file.cancelToken.cancel();
        }

        const dbUpdate = omit(update, ['uploadProgressInfo', 'cancelToken', 'url']);
        await db.update(id, dbUpdate);
      },

      clearCompletedFiles: async () => {
        const { files, removeFile } = get();
        const completedFiles = files.filter(sf => sf.status === UploadStatus.Completed);
        for (const file of completedFiles) {
          await removeFile(file.id);
        }
      },

      setUploadsEnabled: enabled => {
        const { cancelMostRecentRequests, files } = get();
        set({ uploadsEnabled: enabled });
        if (!enabled) {
          const filesToCancelCount = files.filter(e => e.status === UploadStatus.Uploading).length;
          if (filesToCancelCount > 0) {
            cancelMostRecentRequests(filesToCancelCount);
          }
        }
      },

      setMaxConcurrentUploads: async value => {
        const { maxConcurrentUploads, files, cancelMostRecentRequests } = get();
        if (maxConcurrentUploads === value) {
          return;
        }
        const uploadingFilesCount = files.filter(e => e.status === UploadStatus.Uploading).length;
        const shouldCancelRequests = value < maxConcurrentUploads && uploadingFilesCount > value;
        set({ maxConcurrentUploads: value });

        // Re-evaluate new requests that might exist or canceling requests out of the limit
        if (shouldCancelRequests) {
          cancelMostRecentRequests(uploadingFilesCount - value);
        }
      },
      cancelMostRecentRequests: amount => {
        const newFiles = cloneDeep(get().files);
        let canceledCount = 0;
        orderBy(newFiles, ['size'], ['desc']).forEach(e => {
          if (e.status === UploadStatus.Uploading && canceledCount < amount) {
            e.uploadProgressInfo = undefined;
            e.status = UploadStatus.Pending;
            e.cancelToken?.cancel();
            canceledCount++;
          }
        });
        set({ files: newFiles });
      },

      setFileLinkInfo: info => {
        set({
          fileLinkInfo: info,
        });
      },
    };
  };
};

const persisted = persist(createStateCreator(), {
  name: 'file-manager',
  partialize: state => ({ uploadsEnabled: state.uploadsEnabled }),
});

export const createOrganizationFileFilter: (organizationId: string) => FileManagerFilter = organizationId => {
  const filter: FileManagerFilter = file => {
    return file.uploadEndpointInfo.organizationId === organizationId;
  };

  return filter;
};

export const createAssessmentFileFilter: (
  organizationId: string,
  assessmentId: string | number,
) => FileManagerFilter = (organizationId, assessmentId) => {
  const filter: FileManagerFilter = file => {
    if (!file.uploadEndpointInfo.metadata?.['assessmentId']) {
      return false;
    }
    return (
      file.uploadEndpointInfo.organizationId === organizationId &&
      String(file.uploadEndpointInfo.metadata['assessmentId']) === String(assessmentId)
    );
  };

  return filter;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useFileManagerStore = createEnhancedStore<FileManagerStore>(persisted as any, { byPassReset: true });

/**
 * Returns the files present in the file manager filtered down to the given organization id
 *
 * @param organizationId
 * @returns
 */
export const useFileManagerOrganizationFiles = (organizationId: string) =>
  useFileManagerStore(s => s.files.filter(f => f.uploadEndpointInfo.organizationId === organizationId));

type GenericFileUploadParams = {
  file: IFileItem;
  blob: Blob;
  cancelTokenSource: CancelTokenSource;
  onUploadProgress: ((progressEvent: ProgressEvent) => void) | undefined;
};

export interface FileManagerWrapperProps {
  /**
   * Handles the file uploading and returns an indication of success
   * @param params
   * @returns
   */
  genericFileUpload: (params: GenericFileUploadParams) => Promise<boolean>;
}

export const FileManagerWrapper: FCC<FileManagerWrapperProps> = ({ children, genericFileUpload }) => {
  const setDb = useFileManagerStore(state => state.setDb);
  const { database } = useContext(FileManagerDatabaseContext);

  useEffect(() => {
    useFileManagerStore.setState({ genericFileUpload });
  }, [genericFileUpload]);

  useEffect(() => {
    const setup = async () => {
      if (database) {
        void setDb(database);
      }
    };

    void setup();
  }, [database, setDb]);

  return <>{children}</>;
};
