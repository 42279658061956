import Papa from 'papaparse';
/**
 * Provides a ParserError for the Circadian Risk Location Hierarchy parser.
 *
 * Each of these provides information related to errors experienced during
 * the parsing process. These should be reviewed and action taken to resolve
 * any applicable errors.
 */
export class ParserError implements Papa.ParseError {
  /**
   * The type of error.
   *
   */
  public type: string;

  /**
   * A standardized error code.
   *
   */
  public code: string;

  /**
   * The human-readable details of this error.
   */
  public message: string;

  /**
   * The row index of which line in the input CSV that this error was
   * triggered.
   */
  public row: number;

  /**
   * Constructor allows to simplify populating our ParserError object
   * with a `ParseError` from the `papaparse` CSV parsing library.
   *
   * ## Usage
   *
   * ```typescript
   * data = Papa.parse(...);
   * data.errors.forEach(e => {
   *   const error = new ParserError(e);
   *   console.log(error);  // or add it to `result.errors`
   * });
   * ```
   *
   * @param error ParseError from papaparse
   */
  public constructor(error?: Papa.ParseError) {
    this.row = error?.row ?? -1;
    this.code = error?.code ?? 'missing code';
    this.type = error?.type ?? 'missing type';
    this.message = error?.message ?? 'missing message';
  }
}
