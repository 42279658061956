import { SUPPORT_EMAIL } from '@circadian-risk/data-utils';
import { EulaDialog, useDialog } from '@circadian-risk/presentational';
import { Button, Typography } from '@mui/material';
import { CenterMessage } from '@web-app/layouts/CenterMessagePage';
import React from 'react';

export interface EulaDialogProps {
  eulaText: string;
  onAcceptAgreement: () => void;
}
export const EulaPage: React.FC<EulaDialogProps> = ({ eulaText, onAcceptAgreement }) => {
  const { closeDialog, isOpen, openDialog } = useDialog();

  const handleAccept = () => {
    closeDialog();
    onAcceptAgreement();
  };

  return (
    <div>
      <CenterMessage title="Terms of Service Required">
        <Typography gutterBottom>
          The current user account has not accepted the most recent Terms of Service.
        </Typography>
        <Typography gutterBottom>
          You can accept the terms of service below, or contact{' '}
          <a href={`mailto:${SUPPORT_EMAIL}`}>Circadian Risk Client Success</a> for additional support.
        </Typography>
        <Button variant="text" onClick={openDialog}>
          Review Terms of Service
        </Button>
      </CenterMessage>
      <EulaDialog isOpen={isOpen} onAccept={handleAccept} onClose={closeDialog} eulaText={eulaText} />
    </div>
  );
};
