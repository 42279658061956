import { useTheme } from '@mui/material';
import {
  AgAxisLabelFormatterParams,
  AgBarSeriesOptions,
  AgCartesianChartOptions,
  AgColumnSeriesOptions,
} from 'ag-charts-community';
import { AgChartsReact } from 'ag-charts-react';
import { capitalCase } from 'change-case';

import { BaseChartProps } from '../BaseChartProps';
import { BarChartColumnLabelConfig, truncateLabel } from '../chart.helpers';
import { ChartPlaceholder } from '../ChartPlaceholder';
import { safeTooltipRenderer } from '../safeTooltipRenderer';
import { useAgChartStyles } from '../useAgChartStyles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type BarChartTooltipRendererParams<T = Record<string, any>> = Omit<
  Parameters<NonNullable<NonNullable<AgBarSeriesOptions['tooltip']>['renderer']>>[0],
  'datum'
> & { datum: T };

export interface StackedBarChartProps<T> extends Pick<BaseChartProps<string>, 'placeholderProps' | 'xName'> {
  data: T[];
  xKey: keyof T;
  yKeysConfig: (keyof T)[] | Record<keyof T, string>;
  fills: string[];
  categoryAxisLabelFormatter?: (val: AgAxisLabelFormatterParams['value']) => string;
  truncateCategoryAxisLabels?: boolean;
  numberLabelFormat?: string;
  numberAxisTickCount?: number;
  columnLabel?: BarChartColumnLabelConfig;
  orientation?: 'horizontal' | 'vertical';
  tooltipRenderer?: (params: BarChartTooltipRendererParams<T>) => JSX.Element;
  legend?: AgCartesianChartOptions['legend'];
  title?: AgCartesianChartOptions['title'];
  /**
   * @see https://www.ag-grid.com/react-charts/axes/#example-axis-label-rotation
   */
  disableLabelAutoRotate?: boolean;
  seriesFormatter?: AgBarSeriesOptions['formatter'];
  /**
   * If provided will override the default behavior that
   * capitalizes the given yKey
   * @param yKey
   */
  createYAxisName?: (yKey: string) => string;
}

export const StackedBarChart = <T,>({
  data,
  xKey,
  xName,
  yKeysConfig,
  fills,
  categoryAxisLabelFormatter,
  truncateCategoryAxisLabels,
  numberLabelFormat,
  numberAxisTickCount,
  columnLabel,
  tooltipRenderer,
  legend,
  orientation = 'horizontal',
  title,
  placeholderProps,
  disableLabelAutoRotate = false,
  seriesFormatter,
  createYAxisName,
}: StackedBarChartProps<T>) => {
  const { palette } = useTheme();
  const { highlightStyle, agChartTheme } = useAgChartStyles();

  if (!data?.length || data.length === 0) {
    return <ChartPlaceholder type="bar" {...placeholderProps} />;
  }

  const [yKeys, yNames]: [string[], string[]] = Array.isArray(yKeysConfig)
    ? [
        yKeysConfig as string[],
        (yKeysConfig as string[]).map(y => (createYAxisName ? createYAxisName(y) : capitalCase(y))),
      ]
    : [Object.keys(yKeysConfig), Object.values(yKeysConfig)];

  const strokes = fills.map(f => palette.utils.adjustColor(f, 0.9, 0.4));

  const series = yKeys.map<AgBarSeriesOptions | AgColumnSeriesOptions>((yKey, index) => ({
    type: orientation === 'horizontal' ? 'bar' : 'column',
    xKey: xKey as string,
    xName,
    yKey,
    yName: yNames[index],
    fill: fills[index],
    stroke: strokes[index],
    highlightStyle,
    formatter: seriesFormatter,
    label: {
      color: 'white',
      fontWeight: 'bold',
      formatter: ({ value }) => (value === 0 ? '' : `${value}`),
    },
    tooltip: {
      enabled: true,
      renderer: tooltipRenderer
        ? params => {
            const { datum, ...rest } = params;
            return safeTooltipRenderer(tooltipRenderer({ datum: datum as T, ...rest }));
          }
        : undefined,
    },
    columnLabel,
    stacked: true,
  }));

  const customLabelFormatter = ({ value }: AgAxisLabelFormatterParams) => {
    let finalValue = value;
    if (categoryAxisLabelFormatter) {
      finalValue = categoryAxisLabelFormatter(value);
    }
    if (truncateCategoryAxisLabels) {
      finalValue = truncateLabel(finalValue);
    }
    return finalValue;
  };

  const options: AgCartesianChartOptions = {
    title,
    data,
    theme: agChartTheme,
    series,
    legend: legend ?? {},
    padding: { top: 4, bottom: 4, left: 4, right: 4 },
    axes: [
      {
        type: 'category',
        position: orientation === 'horizontal' ? 'left' : 'bottom',
        label: {
          formatter: customLabelFormatter,
          autoRotate: !disableLabelAutoRotate,
        },
      },
      {
        type: 'number',
        position: orientation === 'horizontal' ? 'bottom' : 'left',
        tick: {
          count: numberAxisTickCount,
        },
        label: {
          format: numberLabelFormat,
          autoRotate: !disableLabelAutoRotate,
        },
      },
    ],
  };

  return <AgChartsReact options={options} />;
};
