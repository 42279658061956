import { FCC, useIsSmallScreen } from '@circadian-risk/front-end-utils';
import { VStack } from '@circadian-risk/presentational';
import { Box, Collapse, Stack } from '@mui/material';
import React from 'react';

import { TitleTypography } from '../TitleTypography';
import { CollapsibleHeader } from './CollapsibleHeader';
import { HeaderPhoto } from './HeaderPhoto';

export interface HeaderPageLayoutProps {
  title: React.ReactNode;
  titleIcon?: React.ReactNode;
  avatarSrc?: string;
  onPhotoClick?: () => void;
  actions?: React.ReactNode;
  headerContent?: React.ReactNode;
  roundAvatar?: boolean;
  noAvatar?: boolean;
}

export const HeaderPageLayout: FCC<HeaderPageLayoutProps> = ({
  title,
  titleIcon,
  onPhotoClick,
  avatarSrc,
  headerContent,
  actions,
  roundAvatar,
  noAvatar,
  children,
}) => {
  const smallScreen = useIsSmallScreen();

  return (
    <Box height="100%" width="100%" display="flex" flexDirection="column">
      <CollapsibleHeader scrollThreshold={200}>
        {({ minimized, compactSpacing }) => (
          <VStack spacing={2}>
            <Box display="flex" flexDirection="row" pt={smallScreen ? 2 : 0} alignItems={'center'}>
              {!noAvatar && (
                <Box pr={2} aria-label={'header image container'}>
                  <HeaderPhoto
                    variant={roundAvatar ? 'rounded' : 'squared'}
                    src={avatarSrc}
                    small={compactSpacing}
                    onClick={onPhotoClick}
                    disabled={!onPhotoClick}
                  />
                </Box>
              )}
              <Box
                display="flex"
                flexDirection="column"
                flex={1}
                gap={1}
                aria-label={'header details info'}
                justifyContent={smallScreen ? 'center' : undefined}
              >
                <Box display="flex" alignItems="flex-start">
                  <Box flexGrow={1}>
                    <TitleTypography aria-label={'name'}>
                      <span>
                        {titleIcon}
                        {title}
                      </span>
                    </TitleTypography>
                  </Box>
                  <Stack
                    flexGrow={0}
                    alignItems="center"
                    justifyContent="flex-end"
                    flexWrap="wrap"
                    direction="row"
                    gap={1}
                  >
                    {actions}
                  </Stack>
                </Box>
                <Collapse in={!minimized && !smallScreen}>{headerContent}</Collapse>
              </Box>
            </Box>
            <Collapse in={!minimized && smallScreen}>{headerContent}</Collapse>
          </VStack>
        )}
      </CollapsibleHeader>
      <Box height="100%" p={2}>
        {children}
      </Box>
    </Box>
  );
};
