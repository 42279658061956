import { GridApi } from '@ag-grid-community/core';
import { useQueryState } from '@circadian-risk/front-end-utils';
import { useCallback } from 'react';
import { useLatest, useUpdateEffect } from 'react-use';

import { useSimpleTableStore } from '../simpleTable.store';

export const useGridQuickFilterTextLinking = (disableDeeplinking: boolean) => {
  const [quickFilterTextUrlValue, setQuickFilterTextUrlValue] = useQueryState('quickFilterText', null);
  const [storedValue, updateStoredValue] = useSimpleTableStore(state => [
    state.quickFilterText,
    state.updateQuickFilterText,
  ]);

  const quickFilterTextUrlValueRef = useLatest(quickFilterTextUrlValue);

  useUpdateEffect(() => {
    if (!disableDeeplinking) {
      // Force to be null when its empty, so we remove the query from the url
      setQuickFilterTextUrlValue(storedValue === '' ? null : storedValue);
    }
  }, [storedValue]);

  const onGridReady = useCallback(
    (gridApi: GridApi) => {
      if (quickFilterTextUrlValueRef.current) {
        updateStoredValue(quickFilterTextUrlValueRef.current, gridApi);
      }
    },
    [quickFilterTextUrlValueRef, updateStoredValue],
  );

  return { onGridReady };
};
