import { Bold, GenericDialog, GenericDialogProps, LargeRadioButton, VStack } from '@circadian-risk/presentational';
import { Alert, Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { useUnmount } from 'react-use';

import { LibraryOption, OptionFromLibrary } from '../OptionFromLibrary';

type DialogProps = Pick<GenericDialogProps, 'isOpen' | 'onClose'>;

export type SelectOptionDialogProps = {
  options: (LibraryOption & { alreadyImported: boolean })[];
  onSave: (option: LibraryOption) => void;
} & DialogProps;

export const SelectOptionDialog: React.FC<SelectOptionDialogProps> = ({ isOpen, onClose, onSave, options }) => {
  const [selectedOptionId, setOptionId] = useState<number | undefined>();

  const handleSave = async () => {
    if (selectedOptionId) {
      onSave(options.find(o => o.id === selectedOptionId)!);
      onClose();
    }
  };

  useUnmount(() => setOptionId(undefined));

  return (
    <GenericDialog
      isOpen={isOpen}
      onClose={onClose}
      closeVerb="cancel"
      title="Select a consideration from Organization Library"
      actions={
        <Button variant="contained" disabled={!selectedOptionId} onClick={handleSave}>
          Save
        </Button>
      }
    >
      {!options.length && (
        <Alert severity="warning">
          There are no Options for Consideration that are applicable to this item. Both <Bold>Location</Bold> and{' '}
          <Bold>Item Category</Bold> must match
        </Alert>
      )}
      <VStack spacing={2}>
        {options.map(option => (
          <Box key={option.id} position="relative">
            <LargeRadioButton
              disabled={option.alreadyImported}
              checked={selectedOptionId === option.id}
              label={<OptionFromLibrary value={option} hideWrapper alreadyImported={option.alreadyImported} />}
              onClick={() => setOptionId(option.id)}
              value={String(option.id)}
              radioPosition="left"
              fullWidth
            />
          </Box>
        ))}
      </VStack>
    </GenericDialog>
  );
};
