import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDebounce, useLatest } from 'react-use';

/**
 * Similar to useState except it will call onChange handler after debounce timeout has elapsed
 * Any changes to the value passed in will update the the internal state of the hook and cancel the debounce
 * Useful for propagating debounced onChange events while keeping internal onChangeEvents for controlled text fields
 *
 * @param value
 * @param onChange
 * @param timeout
 */
export const useLatestDebounceState = <T,>(
  value: T,
  onChange: ((newValue: T) => void) | ((newValue: T) => Promise<void>),
  timeout = 1500,
): [T, React.Dispatch<React.SetStateAction<T>>, () => void] => {
  const [currentValue, setCurrentValue] = useState<T>(value);
  const isDirty = useRef(false);
  const latestValue = useLatest(currentValue);

  const [, cancel] = useDebounce(
    () => {
      // Prevent double submission when value is passed into the hook
      if (currentValue === value) {
        return;
      }

      isDirty.current = false;
      onChange(currentValue);
    },
    timeout,
    [currentValue],
  );

  useEffect(() => {
    if (isDirty.current === false && value !== latestValue.current) {
      setCurrentValue(value);
      cancel();
    }
  }, [value, cancel, latestValue]);

  const exposedSetter = useCallback(
    (value: T) => {
      isDirty.current = true;
      setCurrentValue(value);
    },
    [setCurrentValue],
  );

  return [currentValue as T, exposedSetter as React.Dispatch<React.SetStateAction<T>>, cancel];
};
