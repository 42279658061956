import { AddressConfig } from '../';

/**
 * These are the default AddressFields, which will be used if not overridden
 * by custom configuration values.
 */
export const DEFAULT_ADDRESS_FIELDS = {
  street1: ['street1', 'street'],
  street2: ['street2'],
  city: ['city'],
  state: ['state'],
  zip: ['zip', 'zipcode', 'zip code', 'postal', 'postal code'],
  country: ['country'],
};

/**
 * Address fields for mapping CSV columns to Address keys.
 *
 * @see DEFAULT_ADDRESS_FIELDS
 */
export type AddressFields = typeof DEFAULT_ADDRESS_FIELDS;

/**
 * Provides an AddressBuilder.
 *
 * This class is capable of building a proper AddressConfig for a particular
 * row in the Circadian Risk Location Hierarchy CSV.
 */
export class AddressBuilder {
  private readonly addressFields: AddressFields;

  /**
   * Create an AddressBuilder with optional `AddressFields`. If no
   * `AddressFields` are specified, the `DEFAULT_ADDRESS_FIELDS` will be used.
   *
   * @param addressFields `AddressFields` provide a map of CSV headers to
   *                      `AddressConfig` fields.
   *
   * @see DEFAULT_ADDRESS_FIELDS
   */
  public constructor(addressFields?: AddressFields) {
    this.addressFields = addressFields ?? DEFAULT_ADDRESS_FIELDS;
  }

  /**
   * Builds an appropriate `AddressConfig` for the current row in the CSV.
   *
   * @param headers List of ALL headers within the CSV metadata.
   * @param row Current row from the CSV
   */
  public build(headers: string[], row: unknown): AddressConfig {
    const address: AddressConfig = {};
    for (const header of headers) {
      const cellValue = (row as Record<string, string>)[header];

      if (cellValue && cellValue !== '') {
        for (const key of Object.keys(this.addressFields)) {
          if (this.addressFields[key as keyof AddressFields].includes(header.toLowerCase())) {
            address[key as keyof AddressConfig] = cellValue;
            break;
          }
        }
      }
    }
    return address;
  }
}
