import { FCC } from '@circadian-risk/front-end-utils';
import { createContext, useContext, useMemo } from 'react';

export interface MapTokenProviderProps {
  token: string | null;
}

export const MapTokenContext = createContext<MapTokenProviderProps>({
  token: null,
});

export const MapTokenProvider: FCC<MapTokenProviderProps> = ({ token, children }) => {
  const value = useMemo(() => ({ token }), [token]);
  return <MapTokenContext.Provider value={value}>{children}</MapTokenContext.Provider>;
};

export const useMapToken = () => useContext(MapTokenContext);
