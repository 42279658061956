import { useNotification } from '@circadian-risk/front-end-utils';
import { Button } from '@mui/material';
import React from 'react';

import { useVersionCheck } from './useVersionCheck';

const message = 'A new version of the application is available. Refresh the page to see the latest.';

const VersionCheckerRefreshAction = () => {
  const handleClick = () => {
    window.location.reload();
  };

  return (
    <Button color="info" onClick={handleClick}>
      Refresh
    </Button>
  );
};

export const VersionChecker: React.FC = React.memo(() => {
  const notification = useNotification();

  const handleNewVersion = () => {
    notification.display(message, {
      variant: 'warning',
      persist: true,
      action: <VersionCheckerRefreshAction />,
    });
  };
  useVersionCheck(handleNewVersion);
  return null;
});
