import React from 'react';

import { CustomOptionFlair, LibraryFlair } from '../../OptionForConsideration';
import { CustomWizardOptionFlair } from './CustomWizardOptionFlair';

export interface OptionSourceFlairProps {
  source: 'optionFromLibrary' | 'optionFromAssessment' | 'customOption';
  tooltip?: string;
  disabled?: boolean;
}

export const OptionSourceFlair: React.FC<OptionSourceFlairProps> = ({ source, tooltip, disabled }) => {
  switch (source) {
    case 'optionFromLibrary':
      return <LibraryFlair key="optionFromLibrary" {...{ tooltip, disabled }} />;
    case 'optionFromAssessment':
      return <CustomOptionFlair key="optionFromAssessment" {...{ tooltip, disabled }} />;
    default:
      return <CustomWizardOptionFlair key="customOption" {...{ tooltip, disabled }} />;
  }
};
