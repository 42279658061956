import './map-gesture-handling.css';

import includes from 'lodash/includes';
import type { MapMouseEvent } from 'mapbox-gl';
import React, { useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { MapRef, MapWheelEvent } from 'react-map-gl';
import { useLatest, useUnmount } from 'react-use';

export interface MapGestureHandlingProps {
  /**
   * @default 3000
   */
  overlayTimeout?: number;
  /**
   * @default true
   */
  interactive?: boolean;
  map: MapRef | null;
}

export const MapGestureHandling: React.FC<MapGestureHandlingProps> = ({
  overlayTimeout = 3000,
  interactive = true,
  map,
}) => {
  const overlayTimerRef = useRef<NodeJS.Timeout | null>();
  const overlayElementRef = useRef<HTMLDivElement | null>();
  const isOverlayVisibleRef = useRef<boolean>(false);
  const isMacOS = includes(navigator.platform.toUpperCase(), 'MAC');

  const overlayMessage = !isMobile
    ? `Use ${isMacOS ? 'cmd' : 'ctrl'} + scroll to zoom the map.`
    : 'Use two fingers to move the map.';

  const showScrollWarning = () => {
    if (!overlayElementRef.current || !map || isOverlayVisibleRef.current) {
      return;
    }

    overlayElementRef.current.style.top = '0';
    overlayElementRef.current.style.left = '0';
    overlayElementRef.current.style.width = '100%';
    overlayElementRef.current.style.height = '100%';
    overlayElementRef.current.style.display = 'flex';
    overlayElementRef.current.className = 'overlay-fade';
    const textElement = overlayElementRef.current.querySelector('div');
    if (textElement) {
      textElement.innerText = overlayMessage;
      map.getContainer().appendChild(overlayElementRef.current);
      isOverlayVisibleRef.current = true;
    }
  };

  const hideScrollWarning = () => {
    if (!overlayElementRef.current || !map || !isOverlayVisibleRef.current) {
      return;
    }

    try {
      overlayElementRef.current.className = '';
      map.getContainer().removeChild(overlayElementRef.current);
      isOverlayVisibleRef.current = false;
    } catch (e) {
      // Ignore
    }
  };

  const handleMapScrollWheel = (e: MapWheelEvent) => {
    if (!map || !interactive) {
      return;
    }
    const metaKeyPressed = e.originalEvent.metaKey;

    if ((isMacOS && metaKeyPressed) || (!isMacOS && e.originalEvent.ctrlKey)) {
      map.getMap().scrollZoom.enable();
      hideScrollWarning();
    } else {
      // If overlay is visible we refresh the timeout duration
      if (isOverlayVisibleRef.current) {
        if (overlayTimerRef.current) {
          clearTimeout(overlayTimerRef.current);
        }

        overlayTimerRef.current = setTimeout(() => {
          hideScrollWarning();
        }, overlayTimeout);
      } else {
        map.getMap().scrollZoom.disable();
        showScrollWarning();
        overlayTimerRef.current = setTimeout(() => {
          hideScrollWarning();
        }, overlayTimeout);
      }
    }
  };

  const handleOverlayElementScrollWheel = (event: WheelEvent) => {
    if ((isMacOS && event.metaKey) || (!isMacOS && event.ctrlKey)) {
      event.preventDefault();
      hideScrollWarning();
    } else {
      // Refresh the overlay timer
      if (overlayTimerRef.current) {
        clearTimeout(overlayTimerRef.current);
      }
      overlayTimerRef.current = setTimeout(() => {
        hideScrollWarning();
      }, overlayTimeout);
    }
  };

  const handleOverlayElementTouchStart = (event: TouchEvent) => {
    if (!map || !interactive) {
      return;
    }
    if (event.touches && event.touches.length <= 2) {
      if (overlayTimerRef.current) {
        clearTimeout(overlayTimerRef.current);
      }
      hideScrollWarning();

      map.getMap().dragPan.enable();
      event.preventDefault();
    }
  };

  const handleMapMoveStart = (event: MapMouseEvent) => {
    if (!map) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (event.originalEvent && 'touches' in event.originalEvent && 2 > (event.originalEvent as any).touches.length) {
      showScrollWarning();
      map.getMap().dragPan.disable();
      overlayTimerRef.current = setTimeout(() => {
        map?.getMap()?.dragPan?.enable();
        hideScrollWarning();
      }, overlayTimeout);
    }
  };

  useUnmount(() => {
    if (overlayElementRef.current) {
      overlayElementRef.current.removeEventListener('wheel', handleOverlayElementScrollWheel);
      overlayElementRef.current.removeEventListener('touchstart', handleOverlayElementTouchStart);
    }

    if (overlayTimerRef.current) {
      clearTimeout(overlayTimerRef.current);
    }

    if (map) {
      map.off('wheel', handleMapScrollWheel);
      map.off('movestart', handleMapScrollWheel);
    }
  });

  const createGestureHandling = () => {
    if (!map || !interactive) {
      return;
    }

    if (!overlayElementRef.current) {
      const newOverlay = document.createElement('div');
      newOverlay.id = 'scrolling-help-overlay';
      newOverlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
      newOverlay.style.position = 'absolute';
      newOverlay.style.display = 'none';
      newOverlay.style.zIndex = '1100';
      newOverlay.style.justifyContent = 'center';
      newOverlay.style.alignItems = 'center';
      newOverlay.setAttribute('aria-role', 'modal');
      newOverlay.setAttribute('aria-labelledby', 'overlay-content');

      const textBox = document.createElement('div');
      textBox.style.textAlign = 'center';
      textBox.style.color = '#ffffff';
      textBox.style.fontSize = '16px';
      textBox.style.fontFamily = 'Open Sans';
      textBox.innerText = '';
      textBox.id = 'overlay-content';

      newOverlay.appendChild(textBox);
      overlayElementRef.current = newOverlay;

      overlayElementRef.current.addEventListener('wheel', handleOverlayElementScrollWheel);
      overlayElementRef.current.addEventListener('touchstart', handleOverlayElementTouchStart);
    }

    map.on('wheel', handleMapScrollWheel);
    map.on('movestart', handleMapMoveStart);
  };

  const createGestureHandlingLatest = useLatest(createGestureHandling);

  useEffect(() => {
    if (map) {
      createGestureHandlingLatest.current();
    }
  }, [createGestureHandlingLatest, map]);

  return null;
};
