export const config = {
  WEBSITE_NAME: 'Circadian Risk (Ember)',
  API_HOST: 'https://ember-production-api.security-assessments.net',
  HASURA_ENDPOINT: 'https://hasura.security-assessments.net/v1/graphql',
  MAPBOX_ACCESS_TOKEN:
    'pk.eyJ1IjoiY2lyY2FkaWFuLXJpc2stcHJvZHVjdGlvbiIsImEiOiJja2d2aXpmeGQwMHVlMndwaTIzdjNwenM1In0.m7-fbqvi0RNYt3Eu731xew',
  SENTRY_URL: 'https://sentry.io/organizations/circadian-risk/issues/?project=5248055',
  SENTRY_DSN: 'https://6a5c6fcc887248bd9f58ade1d181eece@o264649.ingest.sentry.io/5248055',
  SENTRY_ENVIRONMENT: 'production',
  ENABLE_SESSION_REPLAY: true,
  APP_VERSION: process.env.NX_APP_VERSION,
  WHY_DID_YOU_RENDER: false,
  APOLLO_DEV_MODE: false,
  ASSESSMENT_APP_URL: 'https://perform.security-assessments.net',
  superTokensAppInfo: {
    apiDomain: 'https://ember-production-api.security-assessments.net',
    apiBasePath: '/supertokens_api',
    appName: 'Ember Web',
  },
  novu: {
    applicationIdentifier: 'UC_RnknNy0Ow',
    backendUrl: 'https://novu-api.security-assessments.net',
    socketUrl: 'https://novu-ws.security-assessments.net',
  },
  superTokensFrontendDomain: '.security-assessments.net',
  SIGN_UP_ENABLED: false,
} as const;
