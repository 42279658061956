import { ArrowBack } from '@mui/icons-material';
import { Button } from '@mui/material';
import { PropsFor } from '@mui/system';
import isFunction from 'lodash/isFunction';
import { Link, useHistory, useLocation } from 'react-router-dom';

export interface ReturnToProps {
  to: PropsFor<Link>['to'];
  label: string;
  /**
   * Optional promise to resolve before updating the location to `to`
   */
  beforePush?: () => Promise<void>;
}

/**
 * Convenience "Link" for a consistent "Return to x"
 */
export const ReturnTo: React.FC<ReturnToProps> = ({ label, to, beforePush }) => {
  const { push } = useHistory();
  const location = useLocation();
  const props = beforePush
    ? {
        onClick: async () => {
          await beforePush();
          push(isFunction(to) ? to(location) : to);
        },
      }
    : { to, component: Link };

  return (
    <Button {...props} startIcon={<ArrowBack />} aria-label={label}>
      {label}
    </Button>
  );
};
