import { CircadianRoute, ROUTES } from '@circadian-risk/routes';
import { USER_ROLE } from '@circadian-risk/shared';
import { lazyLoadWithVersionCheck } from '@web-app/router/lazyLoadWithVersionCheck';

import MainDashboardRoleWrapper from './v2/MainDashboardRoleWrapper';

const PrintableScenarioDashboardPage = lazyLoadWithVersionCheck(
  () => import('../../pages/dashboards/PrintableScenarioDashboardPage'),
);
const ScenarioDashboardPage = lazyLoadWithVersionCheck(() => import('../../pages/dashboards/ScenarioDashboardPage'));

export const dashboardRoutes: CircadianRoute[] = [
  {
    path: ROUTES.ROOT,
    component: MainDashboardRoleWrapper,
    isExact: true,
    pageTitle: 'Dashboard',
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN, USER_ROLE.MEMBER],
  },
  {
    path: ROUTES.NEW_SCENARIO_DASHBOARD,
    component: ScenarioDashboardPage,
    isExact: true,
    pageTitle: 'Scenario Dashboard',
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN, USER_ROLE.LOCATION_MANAGER],
  },
  {
    path: ROUTES.NEW_SCENARIO_DASHBOARD_PRINTABLE,
    component: PrintableScenarioDashboardPage,
    isExact: true,
    pageTitle: 'Print Scenario Dashboard',
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN, USER_ROLE.LOCATION_MANAGER],
  },
];
