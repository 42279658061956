import { useTreeData } from '@circadian-risk/front-end-utils';
import { HStack, LabelPosition, NavigationTreeView, NumberAndLabel, VStack } from '@circadian-risk/presentational';
import { Box, Divider, Typography } from '@mui/material';
import countBy from 'lodash/countBy';
import sumBy from 'lodash/sumBy';
import pluralize from 'pluralize';
import React from 'react';

import { LocationFilterRow } from '../types';
import { renderTree } from './renderTree';
import { useGetRelevantLocationIds } from './useGetRelevantLocationIds';

type ApplicableLocationsProps = {
  allLocations: LocationFilterRow[];
};

export const ApplicableLocations: React.FC<ApplicableLocationsProps> = ({ allLocations }) => {
  const relevantLocationIds = useGetRelevantLocationIds(allLocations);
  const root = allLocations.find(n => n.parent_id === null);
  if (!root) {
    throw new Error('Data should have a root node with a parent Id');
  }

  const filteredLocations = allLocations.filter(l => relevantLocationIds.includes(l.id));

  const locationTypeCounts = countBy(filteredLocations, e => e.layer.name);
  const totalItemsCount = sumBy(filteredLocations, e => e.itemsCount);
  const totalAssessmentsCount = sumBy(filteredLocations, e => e.assessmentsCount);

  const { tree } = useTreeData(filteredLocations, root);
  const expandedNodesIds = filteredLocations.map(l => l.id);

  // TODO(v-rrodrigues)[CR-4921]: tweak the layout a little bit based on the create scheduled assessment xp
  return (
    <VStack height="100%">
      <Typography>Applicable Locations</Typography>
      <Divider />
      <Box display="flex" flex={1}>
        <NavigationTreeView<LocationFilterRow>
          tree={tree}
          expandedNodes={expandedNodesIds}
          renderTree={renderTree}
          selectedNode={root.id}
          sx={{ width: '100%' }}
        />
      </Box>
      <Divider />
      <HStack width={'100%'}>
        {Object.keys(locationTypeCounts).map(locationType => (
          <NumberAndLabel
            variant="small"
            key={locationType}
            label={pluralize(locationType, locationTypeCounts[locationType])}
            labelPosition={LabelPosition.bottomLeft}
            num={locationTypeCounts[locationType]}
          />
        ))}
        <Divider />
        <HStack color="primary.main" width="100%">
          <NumberAndLabel
            variant="small"
            label={'Total Items'}
            labelPosition={LabelPosition.bottomLeft}
            num={totalItemsCount}
          />
          <NumberAndLabel
            variant="small"
            label={'Total Assessments'}
            labelPosition={LabelPosition.bottomLeft}
            num={totalAssessmentsCount}
          />
        </HStack>
      </HStack>
    </VStack>
  );
};
