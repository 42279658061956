import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  ColDef,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GetQuickFilterTextParams,
  Module,
} from '@ag-grid-community/core';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { useApolloClient } from '@apollo/client';
import { TypedNodes } from '@circadian-risk/client-graphql-hooks';
import {
  AlignCell,
  CellRendererParams,
  DataGrid,
  LayerTagsTableFieldName,
  LayerTagsValueGetter,
  LocationLayerTagCellRenderer,
  LocationLayerTagFieldData,
  TableActions,
  TextTooltipCellRenderer,
  useColumnState,
  useGrid,
  useGridFilter,
  ValueGetterParams,
} from '@circadian-risk/data-grid';
import { splitMpath } from '@circadian-risk/front-end-utils';
import { useLocationWizardModal } from '@circadian-risk/location-wizard';
import { CustomNoRowsToShow } from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { formatAddress, getLocationTableValidLocationIds, humanFormatDateTime, nestify } from '@circadian-risk/shared';
import { useOrganizationSessionStore } from '@circadian-risk/stores';
import { Box, BoxProps, Typography } from '@mui/material';
import { CircadianHeader, createCreateNewAction } from '@web-app/components/CircadianHeader/CircadianHeader';
import { LinkWithOrgId } from '@web-app/components/NavLinkWithActiveOrgId';
import { TablePage, TablePageContent } from '@web-app/layouts/TablePage';
import isNil from 'lodash/isNil';
import React, { useCallback } from 'react';

import { SmartLocationLinkRenderer } from '../../components/LocationLinkRenderer';
import { SpanErrorBoundary } from '../../components/SpanErrorBoundary';
import { SmartLocationLink } from '../../smart-components/SmartLocationLink';
import { LocationTreeNode } from './LocationDrawer/LocationTree';

export const locationTableAriaLabels = {
  NoRowsOverlay: 'no rows overlay',
};
const NoRowsOverlay: React.FC<{ rootNodeNotFound: boolean; onResetTable: () => void }> = ({
  rootNodeNotFound,
  onResetTable,
}) => {
  let content: React.ReactNode;
  if (!rootNodeNotFound) {
    content = <Typography variant="body2">Create your first location</Typography>;
  } else {
    content = <CustomNoRowsToShow onClearClick={onResetTable} />;
  }

  return <div aria-label={locationTableAriaLabels.NoRowsOverlay}>{content}</div>;
};

const dateFormatter: ColDef['valueFormatter'] = params => {
  return humanFormatDateTime(params.data[params.colDef.field as string], false);
};

interface RowData {
  name: string;
  address: string | null;
  layer: string;
  createdAt: Date;
  [LayerTagsTableFieldName]: LocationLayerTagFieldData[];

  mpath: string;
  nodeId: string;
  belongsToId?: string | null;
  layerId: string;

  selectable: boolean;
}

const LayerCellRenderer: React.FC<CellRendererParams<RowData>> = props => {
  const { layer, layerId, selectable } = props.data;
  const link = `${ROUTES.LAYERS}/${layerId}`;
  const disabled = !selectable;

  return (
    <AlignCell justifyContent="flex-start">
      <LinkWithOrgId
        href={link}
        sx={
          disabled
            ? theme => ({ pointerEvents: 'none', opacity: 0.5, color: `${theme.palette.text.disabled}!important` })
            : undefined
        }
      >
        <Box display="flex" alignItems="center">
          <Typography>{layer}</Typography>
        </Box>
      </LinkWithOrgId>
    </AlignCell>
  );
};

const BelongsToCellRenderer: React.FC<CellRendererParams<RowData>> = props => {
  const { belongsToId, selectable } = props.data;
  return belongsToId ? <SmartLocationLinkRenderer data={{ nodeId: belongsToId }} disabled={!selectable} /> : null;
};

const LocationNameRenderer: React.FC<{
  data: RowData;
  justifyContent?: BoxProps['justifyContent'];
  includeBreadCrumbs?: boolean;
}> = ({ data, includeBreadCrumbs, justifyContent = 'flex-start' }) => {
  const { nodeId, selectable } = data;

  return (
    <AlignCell justifyContent={justifyContent} mr={1}>
      <SpanErrorBoundary>
        <SmartLocationLink nodeId={nodeId} includeBreadCrumbs={includeBreadCrumbs} disabled={!selectable} />
      </SpanErrorBoundary>
    </AlignCell>
  );
};

const columnDefs: ColDef[] = [
  {
    headerName: 'Address',
    field: 'address',
    filter: 'agTextColumnFilter',
    cellRenderer: TextTooltipCellRenderer,
  },
  {
    headerName: 'Layer',
    field: 'layer',
    cellRenderer: LayerCellRenderer,
  },
  {
    headerName: 'Belongs To',
    field: 'belongsTo',
    hide: true,
    cellRenderer: BelongsToCellRenderer,
    cellClass: 'circadian-ag-text-wrap-column',
  },
  {
    headerName: 'Tags',
    field: LayerTagsTableFieldName,
    cellRenderer: LocationLayerTagCellRenderer,
    valueGetter: LayerTagsValueGetter,
  },
  {
    headerName: 'Created At',
    field: 'createdAt',
    valueFormatter: dateFormatter,
    filter: 'agDateColumnFilter',
  },
];

export const LocationTable: React.FC = () => {
  const { scoredLayerId, layersById, nodes } = useOrganizationSessionStore(({ scoredLayerId, layersById, nodes }) => ({
    scoredLayerId,
    layersById,
    nodes,
  }));

  const allowedNodeIds = useOrganizationSessionStore(state => state.allowedNodeIds);

  const openLocationWizard = useLocationWizardModal();
  const scoredLayer = layersById[scoredLayerId];
  const client = useApolloClient();

  const { onGridReady, resetTable, gridApi } = useGrid();
  const columnStateHook = useColumnState();
  const filterHook = useGridFilter();

  const onFirstDataRendered = useCallback(
    (e: FirstDataRenderedEvent) => {
      filterHook.onFirstDataRendered(e);
      columnStateHook.onFirstDataRendered(e);
    },
    [filterHook, columnStateHook],
  );

  const handleRefresh = useCallback(async () => {
    await client.refetchQueries({
      // TODO(jesse)[CR-4857]: Replace with cache update for locations
      include: [TypedNodes.GetAppWrapperDataDocument],
    });
  }, [client]);

  const handleCreateNew = useCallback(() => {
    openLocationWizard({ type: 'viewing' });
  }, [openLocationWizard]);

  // Form the tree
  const allNodes: LocationTreeNode[] = nodes.map(n => {
    return {
      id: n.id,
      parent_id: n.parent_id,
      name: n.name,
      selectable: allowedNodeIds.includes(n.id),
      depth: n.nodeDepth!,
      ordinal: n.ordinal,
    };
  });

  const rootNode = allNodes.find(n => n.parent_id === null);
  const treeData = rootNode ? nestify(allNodes, rootNode) : undefined;
  const setOfValidLocationIds = treeData ? getLocationTableValidLocationIds(treeData) : new Set<string>();

  const rowData = nodes
    .filter(e => setOfValidLocationIds.has(e.id))
    .map(location => {
      const layer = layersById[location.layer_id];
      const selectable = allowedNodeIds.includes(location.id);
      const row: RowData = {
        name: location.name,
        address: location.address ? formatAddress(location.address) : null,
        layer: layer.name,
        createdAt: new Date(location.created_at),
        layerTags: location.tags.map<LocationLayerTagFieldData>(tag => tag.type_name),
        nodeId: location.id,
        layerId: location.layer_id,
        belongsToId: location.parent_id,
        mpath: location.mpath,
        selectable,
      };
      return row;
    });

  const defaultColDef: ColDef = {
    flex: 1,
    resizable: true,
    filter: true,
    sortable: true,
  };

  const modules: Module[] = [ClientSideRowModelModule, SetFilterModule, ColumnsToolPanelModule, RowGroupingModule];

  // we need to truncate mpath in case not all nodes are available that are listed in mpath
  const getDataPath = (row: RowData) => splitMpath(row.mpath);

  const handleFilterChanged = useCallback(
    (e: FilterChangedEvent) => {
      filterHook.onFilterChanged(e);

      const isAnyFilterPresent = e.api.isAnyFilterPresent();

      if (isAnyFilterPresent) {
        // we want to expand all rows when user filters the table [CR-4901]
        e.api.expandAll();
      } else {
        // resetting back to default state
        e.api.refreshClientSideRowModel();
      }
    },
    [filterHook],
  );

  return (
    <TablePage>
      <CircadianHeader header="Locations" actions={[createCreateNewAction(handleCreateNew)]} />

      <TablePageContent>
        <TableActions onReset={resetTable} onRefresh={handleRefresh} gridApi={gridApi} />

        <DataGrid
          {...{
            rowData,
            modules,
            defaultColDef,
            onGridReady,
            onFirstDataRendered,
          }}
          pagination
          defaultColDef={defaultColDef}
          excludeChildrenWhenTreeDataFiltering
          onFilterChanged={handleFilterChanged}
          onSortChanged={columnStateHook.onColumnChange}
          onColumnVisible={columnStateHook.onColumnChange}
          disableStaticMarkup
          animateRows
          // tree data related props
          treeData
          groupDefaultExpanded={scoredLayer?.layerDepth ?? -1}
          gridOptions={{ getDataPath }}
          noRowsOverlayComponent={() => <NoRowsOverlay rootNodeNotFound={isNil(rootNode)} onResetTable={resetTable} />}
          autoGroupColumnDef={{
            headerName: 'Name',
            valueGetter: ({ data: { name } }: ValueGetterParams<RowData>) => name,
            flex: 2,
            getQuickFilterText: ({ data: { name, layerTags, layer, address } }: GetQuickFilterTextParams<RowData>) =>
              name + layer + address + layerTags.join(' '),
            cellRendererParams: {
              suppressCount: true,
              innerRenderer: LocationNameRenderer,
              cellClass: 'circadian-ag-text-wrap-column',
            },
          }}
          paginationAutoPageSize
          columnDefs={columnDefs}
        />
      </TablePageContent>
    </TablePage>
  );
};

export default LocationTable;
