import { AnswerSemanticMeaning, FCC } from '@circadian-risk/front-end-utils';
import { TouchFriendlyTooltip } from '@circadian-risk/presentational';

interface DisabledResponseButtonTooltipProps {
  displayToolTip: boolean;
  meaning: AnswerSemanticMeaning;
}

export const DisabledResponseButtonTooltip: FCC<DisabledResponseButtonTooltipProps> = ({
  displayToolTip,
  children,
  meaning,
}) => {
  if (displayToolTip) {
    const message = `'The response ${meaning} for this question is disabled because its parent item is marked as Not Present';`;
    return (
      <TouchFriendlyTooltip tooltipContent={message}>
        <span aria-label={`disabled ${meaning} response`}>{children}</span>
      </TouchFriendlyTooltip>
    );
  } else {
    return <>{children}</>;
  }
};
