import { createBadgeContent } from '@circadian-risk/file-manager';
import { FileList, FileListItem } from '@circadian-risk/form';
import {
  convertAttachmentFilesToLightboxImages,
  HStack,
  LightBoxProvider,
  QuestionMoreInfoStatusIcons,
  StandardsGroupWithDialog,
  VStack,
} from '@circadian-risk/presentational';
import { humanFormatDateTime } from '@circadian-risk/shared';
import { Box, Collapse, TextField, Typography } from '@mui/material';
import React, { useMemo } from 'react';

import { OptionsSection, OptionsSectionProps } from '../../../OptionsForConsideration';
import { useActiveFields, useMoreInfoState } from '../../hooks';
import { QuestionProps } from '../../question.types';
import { QuestionResponseCompact, QuestionResponseCompactProps } from '../../QuestionResponseCompact';
import { SmartAnsweredByAvatar } from '../SmartAnsweredByAvatar';
import { questionActiveFieldsAriaLabels } from './ariaLabels';
import { BottomRowWithLessMore } from './BottomRowWithLessMore';

export type QuestionActiveFieldsCompactProps = Pick<
  QuestionProps,
  'answeredBy' | 'answeredAt' | 'value' | 'files' | 'standards'
> &
  QuestionResponseCompactProps & {
    description?: string | null;
    options?: OptionsSectionProps['options'];
  };

export const QuestionActiveFieldsCompact: React.FC<QuestionActiveFieldsCompactProps> = ({
  value,
  answeredBy,
  answeredAt,
  files,
  description,
  standards,
  deficientResponse,
  text,
  isPartialComplianceEnabled,
  partialCompliance,
  options,
}) => {
  const lightboxImages = useMemo(() => convertAttachmentFilesToLightboxImages(files), [files]);

  const { activeFields } = useActiveFields(value, files.length, options ?? []);
  const { moreInfoOpen, toggle, hasMoreInfo } = useMoreInfoState({
    activeFields,
    standards,
  });

  return (
    <>
      <BottomRowWithLessMore
        open={moreInfoOpen}
        toggle={toggle}
        hasMoreInfo={hasMoreInfo}
        boxProps={{ 'aria-label': questionActiveFieldsAriaLabels.Compact }}
        leftSlot={
          <HStack noFullWidth flexWrap="wrap" justifyContent="center">
            <QuestionResponseCompact
              text={text}
              deficientResponse={deficientResponse}
              partialCompliance={partialCompliance}
              isPartialComplianceEnabled={isPartialComplianceEnabled}
            />

            {answeredAt && (
              <Box display="flex" flexBasis="24px" flex={1} flexWrap="wrap" gap={1}>
                <SmartAnsweredByAvatar answeredAt={answeredAt} answeredBy={answeredBy} />
                <Typography noWrap variant="body2" flex={1}>
                  {humanFormatDateTime(answeredAt, false)}
                </Typography>
              </Box>
            )}
          </HStack>
        }
        rightSlot={<QuestionMoreInfoStatusIcons activeFields={activeFields} />}
      />

      <Collapse in={moreInfoOpen}>
        <VStack pb={1}>
          <StandardsGroupWithDialog standards={standards ?? []} />
          {files.length > 0 && (
            <Box>
              <LightBoxProvider images={lightboxImages}>
                <FileList title="Photos and Files" dense>
                  {files.map(file => (
                    <FileListItem
                      key={file.id}
                      divider={false}
                      dense
                      {...file}
                      onDelete={undefined}
                      badgeContent={file.status ? createBadgeContent(file.status) : file.badgeContent}
                    />
                  ))}
                </FileList>
              </LightBoxProvider>
            </Box>
          )}
          <VStack>
            {description && (
              <TextField multiline fullWidth variant="standard" value={description} label="Description" disabled />
            )}
            {options && options.length > 0 && <OptionsSection options={options} />}
          </VStack>
        </VStack>
      </Collapse>
    </>
  );
};
