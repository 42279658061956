import { StackProps } from '@mui/material';
import React from 'react';

import { FormHStack } from './FormHStack';
import { FormVStack } from './FormVStack';

export interface FormStackProps {
  row?: boolean;
  children: React.ReactNode[];
  stackProps?: StackProps;
}

export const FormStack: React.FC<FormStackProps> = ({ row, children, ...stackProps }) => {
  return row ? <FormHStack {...stackProps} children={children} /> : <FormVStack {...stackProps} children={children} />;
};
