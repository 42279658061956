import { Box, SxProps } from '@mui/material';
import DOMPurify from 'dompurify';

const defaultSx: SxProps = {
  fontSize: '12px',
};

export interface PropertyDescriptionRichTextProps {
  value?: string | null;
  sx?: SxProps;
}

export const PropertyDescriptionRichText: React.FC<PropertyDescriptionRichTextProps> = ({ value, sx }) => {
  if (!value) {
    return null;
  }

  const mergedSx = {
    ...defaultSx,
    ...sx,
  };

  return <Box sx={mergedSx} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }} />;
};
