/**
 * Parses a given time string into an object with hour and minute properties.
 *
 * @param timeStr - expected format is hh:mm or HH:mm
 * @returns
 */
export const parseTime = (timeStr: string): { hour: number; minute: number } => {
  const timeRegex = /^(\d{1,2}):(\d{2})/;
  const match = timeStr.match(timeRegex);
  if (match) {
    const hour = Number(match[1]);
    const minute = Number(match[2]);
    return { hour, minute };
  } else {
    throw new Error('Invalid time format');
  }
};
