import { FormPaper, FormStack, FormTitle, generateCircadianForm, PasswordFF } from '@circadian-risk/form';
import { EulaDialog, OpenInNewTabLink, useDialog } from '@circadian-risk/presentational';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';

import { ReturnToSignIn } from '../components/ReturnToSignIn/ReturnToSignIn';
import { signUpFormAriaLabels } from './ariaLabels';
import { SignUpFormData, signUpFormSchema } from './types';

export interface SignUpFormProps {
  eulaText: string;
  onSubmit: (data: SignUpFormData) => Promise<void>;
}

const { useCircadianForm, Text, Checkbox, CircadianForm, SubmitButton } = generateCircadianForm(
  signUpFormSchema,
  signUpFormAriaLabels,
);

const defaultValues: SignUpFormData = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  acceptEULA: false,
  consentsToMarketingCommunication: false,
};

export const SignUpForm: React.FC<SignUpFormProps> = ({ eulaText, onSubmit }) => {
  const formMethods = useCircadianForm({ defaultValues });
  const { isOpen, closeDialog, openDialog } = useDialog();
  const { watch } = formMethods;
  const hasAcceptedEULA = watch('acceptEULA');

  useEffect(() => {
    hasAcceptedEULA && openDialog();
  }, [hasAcceptedEULA, openDialog]);

  const handleAcceptEula = () => {
    closeDialog();
  };

  const handleCancelEula = () => {
    formMethods.setValue('acceptEULA', false);
    closeDialog();
  };

  return (
    <CircadianForm formMethods={formMethods} onSubmit={onSubmit}>
      <FormPaper showBg>
        <FormStack>
          <FormTitle>Sign Up</FormTitle>

          <ReturnToSignIn />

          <FormStack row>
            <Text formKey="firstName" />
            <Text formKey="lastName" />
          </FormStack>

          <Text formKey="email" type="email" autoComplete="email" />

          <PasswordFF formKey="password" autoComplete="new-password" />

          <PasswordFF formKey="confirmPassword" autoComplete="new-password" />

          <Checkbox
            helperText="By opting into marketing communications, you will receive our monthly newsletters and be notified of new products & features."
            formKey="consentsToMarketingCommunication"
          />

          <Checkbox formKey="acceptEULA" />

          <SubmitButton color="success" />

          <Typography component={'div'} variant="body2" color={theme => theme.palette.text.secondary}>
            Curious to learn more about our platform?
            <br />
            Check out our{' '}
            <Box display="inline-flex">
              <OpenInNewTabLink
                external
                text="website"
                link="https://www.circadianrisk.com/?utm_source=Portal&utm_medium=Sign+Up&utm_campaign=Portal+Activation"
              />
            </Box>
          </Typography>
        </FormStack>
      </FormPaper>
      <EulaDialog isOpen={isOpen} onAccept={handleAcceptEula} onClose={handleCancelEula} eulaText={eulaText} />
    </CircadianForm>
  );
};
