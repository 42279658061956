import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import React from 'react';

export interface PopoverActionProps {
  name: string;
  icon: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  tooltip?: string;
}

export const PopoverAction: React.FC<PopoverActionProps> = ({ name, onClick, icon, tooltip, disabled }) => {
  return (
    <Tooltip title={tooltip ?? ''}>
      <span>
        <ListItemButton onClick={onClick} disabled={disabled} aria-label={name}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={name} />
        </ListItemButton>
      </span>
    </Tooltip>
  );
};
