import { useApiClient } from '@circadian-risk/api-client-provider';
import { FormStack, generateCircadianForm, passwordSchema } from '@circadian-risk/form';
import { FCC, useNotification } from '@circadian-risk/front-end-utils';
import { useUserSessionStore } from '@circadian-risk/stores';
import { Box } from '@mui/material';
import noop from 'lodash/noop';
import React from 'react';
import { z } from 'zod';

const needsSetPasswordSchema = z.object({
  currentPassword: passwordSchema,
  newPassword: passwordSchema,
  confirmPassword: passwordSchema,
});

type FormData = z.infer<typeof needsSetPasswordSchema>;

const { useCircadianForm, Password, GenericFormDialog } = generateCircadianForm(needsSetPasswordSchema, {
  form: 'reset password form',
  submit: 'Submit',
  currentPassword: 'Current Password',
  newPassword: 'New Password',
  confirmPassword: 'Confirm Password',
});

const inputFields: (keyof FormData)[] = ['currentPassword', 'newPassword', 'confirmPassword'];

export const NeedsSetPasswordBoundary: FCC = ({ children }) => {
  const [needsToSetPassword, id] = useUserSessionStore(state => [state.needsToSetPassword, state.id]);
  const { displaySuccess, displayError } = useNotification();
  const { tsRestClient } = useApiClient();

  const formMethods = useCircadianForm({
    values: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  });

  const onSubmit = async (data: FormData) => {
    try {
      await tsRestClient.auth.resetPassword({
        body: {
          userId: id,
          ...data,
        },
      });
      displaySuccess('Password has been changed.');
    } catch (e) {
      displayError(e);
    }
  };

  if (needsToSetPassword) {
    return (
      <GenericFormDialog
        isOpen
        onClose={noop}
        title="Reset Password"
        formProps={{ formMethods, onSubmit }}
        dialogProps={{
          'aria-label': 'reset password dialog',
          maxWidth: 'sm',
          PaperProps: { sx: { minWidth: '550px' } },
        }}
      >
        <FormStack>
          {inputFields.map(formKey => {
            return (
              <Box key={`password-input-${formKey}`}>
                <Password formKey={formKey} />
              </Box>
            );
          })}
        </FormStack>
      </GenericFormDialog>
    );
  }
  return <>{children}</>;
};
