import React from 'react';

import { useLocationWizardStore } from '../../locationWizard.store';
import { WizardLocation, WizardLocationLayer } from '../../types';
import { LocationForm } from '../LocationForm';
import { AddSubmitData, EditSubmitData } from '../LocationWizard/LocationWizard';
import { NoCreationAllowedMessage } from './NoCreationAllowedMessage';

export interface EditPanelProps {
  allLocations: WizardLocation[];
  layers: WizardLocationLayer[];
  layer: WizardLocationLayer | null;
  parentLocation: WizardLocation;

  createLocationRoute: (locationId: string) => string;
  onAddSubmit: (data: AddSubmitData) => Promise<void>;
  onEditSubmit: (data: EditSubmitData) => Promise<void>;
}

export const EditPanel: React.FC<EditPanelProps> = ({
  allLocations,
  layer,
  layers,
  parentLocation,
  createLocationRoute,
  onAddSubmit,
  onEditSubmit,
}) => {
  const { mode, changeMode } = useLocationWizardStore(({ mode, changeMode }) => ({ mode, changeMode }));

  const handleCancel = () => changeMode({ type: 'viewing' });

  if (mode.type === 'editing') {
    // If the mode is editing, we will always have the location and layer selected
    const editingLocation = allLocations.find(l => l.id === mode.editingId)!;
    const editingLayer = layers.find(l => l.id === editingLocation.layer_id)!;

    return (
      <LocationForm
        mode={{ type: 'editing', editingId: mode.editingId }}
        layer={editingLayer}
        parentLocation={parentLocation}
        createLocationRoute={createLocationRoute}
        defaultValues={{
          address: editingLocation?.address ?? null,
          locationName: editingLocation?.name ?? '',
          useCustomCoordinates: editingLocation?.useCustomCoordinates ?? false,
          lat: editingLocation?.coordinates?.lat,
          long: editingLocation?.coordinates?.long,
          alreadyHasFloorPlan: editingLocation?.hasFloorPlan ?? false,
        }}
        onSubmit={data =>
          onEditSubmit({ ...data, locationId: mode.editingId, layerId: editingLayer.id, parentId: parentLocation.id })
        }
        onCancel={handleCancel}
      />
    );
  } else {
    // can't create something that doesn't have a layer
    if (!layer) {
      return <NoCreationAllowedMessage />;
    }

    return (
      <LocationForm
        mode={{ type: 'adding' }}
        parentLocation={parentLocation}
        layer={layer}
        createLocationRoute={createLocationRoute}
        defaultValues={{
          address: null,
          locationName: mode.type === 'adding' && mode.locationName ? mode.locationName : '',
          useCustomCoordinates: false,
          alreadyHasFloorPlan: false,
        }}
        onSubmit={data => onAddSubmit({ ...data, layerId: layer.id, parentId: parentLocation.id })}
        onCancel={handleCancel}
      />
    );
  }
};
