import { useSuspenseQuery } from '@apollo/client';
import { ComponentWithNoDataFallback, ImportIcon, NoDataPlaceholderImage } from '@circadian-risk/presentational';
import { Button, IconButton } from '@mui/material';

import noScenariosImg from '../../assets/no-risk-score.svg';
import { GetInherentRiskCardDataDocument } from '../graphql/typed-nodes';
import { ImportScenarioInfoBanner } from '../ImportScenarioInfoBanner';
import { CardInfoBanner } from '../layout/CardInfoBanner';
import { HomeLayoutCard } from '../layout/HomeLayoutCard';
import { ScenarioProgressList } from './ScenarioProgressList';

export const homeSmartInherentRiskAriaLabels = {
  NoScenarioContent: 'no scenario content',
  NoDataBanner: 'no data banner',
  NoScenariosBanner: 'no scenarios banner',
  OverviewButton: 'Overview',
};

const noDataFallback = (
  <NoDataPlaceholderImage imgSrc={noScenariosImg} ariaLabel={homeSmartInherentRiskAriaLabels.NoScenarioContent} />
);

export interface SmartInherentRiskCardProps {
  organizationId: string;
  onScenarioClick: (id: number) => void;
  onOverviewClick: () => void;
  onImportScenariosClick: () => void;
}

export const HomeSmartInherentRiskCard: React.FC<SmartInherentRiskCardProps> = ({
  organizationId,
  onOverviewClick,
  onScenarioClick,
  onImportScenariosClick,
}) => {
  const { data } = useSuspenseQuery(GetInherentRiskCardDataDocument, {
    variables: {
      organizationId,
    },
  });

  const noScenarioState = data.scenario.length === 0;
  const noDataState = !noScenarioState && data.scenario.every(e => e.percentCompleteInherentRisk === 0);

  let banner: React.ReactNode;
  if (noScenarioState) {
    banner = (
      <ImportScenarioInfoBanner
        action="filling out Inherent Risk"
        onClick={onImportScenariosClick}
        ariaLabel={homeSmartInherentRiskAriaLabels.NoScenariosBanner}
      />
    );
  } else if (noDataState) {
    banner = (
      <CardInfoBanner
        content="Navigate to the Inherent Risk Overview page or click the scenario below complete a inherent risk score."
        ariaLabel={homeSmartInherentRiskAriaLabels.NoDataBanner}
        actionButton={
          <IconButton onClick={onOverviewClick}>
            <ImportIcon sx={theme => ({ color: theme.palette.common.white })} />
          </IconButton>
        }
      />
    );
  }

  return (
    <HomeLayoutCard
      title="Inherent Risk"
      action={
        <Button disabled={noScenarioState} onClick={onOverviewClick} variant="outlined" color="primary">
          Overview
        </Button>
      }
      banner={banner}
    >
      <ComponentWithNoDataFallback hasData={!noScenarioState} fallback={noDataFallback}>
        <ScenarioProgressList scenarios={data.scenario} onScenarioClick={onScenarioClick} />
      </ComponentWithNoDataFallback>
    </HomeLayoutCard>
  );
};
