import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React from 'react';

interface CircularProgressWithLabelProps {
  value: number;
  /**
   * @default 'large'
   */
  size?: 'small' | 'large';
}

export const CircularProgressWithLabel: React.FC<CircularProgressWithLabelProps> = ({ size = 'large', value }) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" color="primary" size={size === 'small' ? 27 : 40} value={value} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
          style={{
            fontSize: size === 'small' ? 8 : 12,
          }}
        >{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
};
