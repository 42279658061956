import { FCC } from '@circadian-risk/front-end-utils';
import { Dialog } from '@mui/material';
import React from 'react';

import { PropertyDescriptionRichText } from '../PropertyDescriptionRichText';
import { EulaDialogContent } from './EulaDialogContent';

export interface EulaDialogProps {
  eulaText: string;
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
}

export const EulaDialog: FCC<EulaDialogProps> = ({ eulaText, isOpen, onClose, onAccept }) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <EulaDialogContent onAccept={onAccept} onClose={onClose}>
        <PropertyDescriptionRichText value={eulaText} />
      </EulaDialogContent>
    </Dialog>
  );
};
