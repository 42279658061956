import { HStack, NodeIcon } from '@circadian-risk/presentational';
import { sortLocationsByOrdinalAndName } from '@circadian-risk/shared';
import { Typography } from '@mui/material';
import { TreeItem } from '@mui/x-tree-view';

import { LocationFilterRow } from '../types';
import { Leaf } from './Leaf';

export const renderTree = (node: LocationFilterRow) => {
  const sortedChildren = node.children ? sortLocationsByOrdinalAndName(node.children) : undefined;

  return (
    <TreeItem
      aria-label={node.name}
      key={node.id}
      nodeId={node.id}
      ContentComponent={Leaf}
      label={
        <HStack sx={{ color: theme => theme.palette.primary.main }}>
          <NodeIcon layerName={node.layer.name} />
          <Typography>{node.name}</Typography>
        </HStack>
      }
    >
      {sortedChildren && sortedChildren.map(n => renderTree(n))}
    </TreeItem>
  );
};
