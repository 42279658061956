import { ICellRendererParams } from '@ag-grid-community/core';
import { Box, Tooltip } from '@mui/material';
import React, { useImperativeHandle } from 'react';

export interface TextTooltipCellRendererRef {
  getReactContainerStyle: () => object;
}

export const TextTooltipCellRenderer = React.forwardRef<TextTooltipCellRendererRef, ICellRendererParams>(
  ({ value }, ref) => {
    useImperativeHandle(ref, () => ({
      getReactContainerStyle: () => {
        return {};
      },
    }));

    const text = value;

    return (
      <Tooltip title={text ?? ''} arrow>
        <Box component="span" display="inline-block" width="100%" overflow="hidden" textOverflow="ellipsis">
          {text}
        </Box>
      </Tooltip>
    );
  },
);

// Recommended usage: Use on columns with dynamic text content AND non essential to the user
// If essential, use VariableLinesColumnConfig
export const TextTooltipColumnConfig = {
  maxWidth: 400,
  cellRenderer: TextTooltipCellRenderer,
  filter: 'agTextColumnFilter',
};
