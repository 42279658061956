import { Enum_Item_Status_Enum } from '@circadian-risk/client-graphql-hooks';

/**
 * @deprecated Avoid using this as it should be available directly from the API
 * @param isDeficient
 * @param isPresent
 */
export const deprecatedCalculateGlobalItemStatus = (
  isDeficient: boolean,
  isPresent: boolean,
): Enum_Item_Status_Enum => {
  if (!isPresent) {
    return 'Needed';
  } else if (!isDeficient) {
    return 'Compliant';
  } else if (isDeficient) {
    return 'Deficient';
  } else {
    return 'Unknown';
  }
};
