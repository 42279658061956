import dayjs from 'dayjs';

const ordinalSuffixes = ['first', 'second', 'third', 'fourth', 'fifth'];

/**
 * Returns a human readable ordinal for the weekday of the given date
 * (e.g. "First Monday", "Second Tuesday", "Third Friday", etc.)
 *
 * @param date
 */
export const getWeekdayOrdinalFromDate = (date: Date | string): string => {
  const givenDate = dayjs(date);

  const currentDate = givenDate.date();
  const count = Math.ceil(currentDate / 7);

  return ordinalSuffixes[count - 1];
};
