import { sessionAccessPayloadSchema } from '@circadian-risk/shared';
import { useUserSessionStore } from '@circadian-risk/stores';
import Session from 'supertokens-web-js/recipe/session';
import { suspend } from 'suspend-react';

import { useSupertokensSession } from './useSupertokensSession';

/**
 * Returns whether the user has session
 * If a session is present, the user session data will be hydrated
 */
export const useSuspendedUserSessionHydration = () => {
  const { signOut } = useSupertokensSession();
  const { setSessionData, userId } = useUserSessionStore(state => ({
    setSessionData: state.setSessionData,
    userId: state.id,
  }));

  const hasSession = suspend(async () => {
    try {
      if (!(await Session.doesSessionExist())) {
        return false;
      }

      const payload = await Session.getAccessTokenPayloadSecurely();
      if (payload) {
        const result = sessionAccessPayloadSchema.safeParse(payload);
        if (!result.success) {
          // eslint-disable-next-line no-console
          console.warn('Failure parsing the session payload schema, logging out..');
          await signOut();
          return;
        }

        setSessionData(result.data);
      }
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.error(ex);
      return false;
    }

    return true;
  }, ['hasSession', userId]);

  return { hasSession };
};
