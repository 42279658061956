import { Typography, TypographyProps } from '@mui/material';
import React from 'react';

export const Strikethrough: React.FC<TypographyProps> = ({ children, ...typographyProps }) => (
  <Typography
    component="span"
    variant="inherit"
    fontSize="inherit"
    color="inherit"
    {...typographyProps}
    sx={{ textDecoration: 'line-through', textDecorationThickness: '0.125em', ...typographyProps.sx }}
  >
    {children}
  </Typography>
);
