import { useTheme } from '@mui/material';
import React, { useMemo } from 'react';

import { GenericRealWorldMarker } from './GenericRealWorldMarker';
import { RealWorldMarkerItem } from './interfaces';
import { createTooltipMarkerNameList } from './utils/createTooltipMarkerNameList.util';

interface Props {
  items: RealWorldMarkerItem[];
}
export const DefaultStackedItemsRenderer: React.FC<Props> = ({ items }) => {
  const tooltipText = useMemo(() => createTooltipMarkerNameList(items), [items]);
  const theme = useTheme();
  return (
    <GenericRealWorldMarker
      name={items.length.toString()}
      tooltipText={tooltipText}
      backgroundColor={theme.palette.secondary.main}
    />
  );
};
