import { Criticality } from '@circadian-risk/presentational';
import { Box, useTheme } from '@mui/material';
import React from 'react';

export type ItemAnswerCriticalityCellRendererProps = {
  criticality: Criticality;
  count: number;
};

export const ItemAnswerCriticalityCellRenderer: React.FC<ItemAnswerCriticalityCellRendererProps> = ({
  count,
  criticality,
}) => {
  const theme = useTheme();

  return (
    <Box display={'flex'} alignItems={'center'}>
      <Box mr={1} color={theme.palette.organizationColors.criticality[criticality]}>
        {count}
      </Box>
    </Box>
  );
};
