import { enum_organization_file_entity_enum } from '@circadian-risk/data-access-gql-querybuilder';
import { Enum_File_Type_Enum } from '@circadian-risk/graphql-types';
import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import { fileSchema } from './shared-schemas';

const c = initContract();

const uploadFileSchema = z.object({
  entityType: z.custom<enum_organization_file_entity_enum>(),
  fileType: z.custom<Enum_File_Type_Enum>().optional(),
  filename: z.string(),
  entityId: z.string(),
  fileId: z.string().optional(),
});

const imageResponseSchema = z.object({
  id: z.string(),
  filepath: z.string(),
  updated_at: z.string(),
  latest_image_path: z.string(),
  markup: z.object({}).nullish(),
  alternative_sizes: z.object({}).nullish(),
  original_filename: z.string(),
});

export type ImageResponse = z.infer<typeof imageResponseSchema>;

export type UploadFile = z.infer<typeof uploadFileSchema>;

export const filesContract = c.router(
  {
    genericUpload: {
      method: 'POST',
      path: '/:organizationId',
      description: 'Upload an image file',
      contentType: 'multipart/form-data',
      body: z.object({
        file: z.custom<File>(),
      }),
      query: uploadFileSchema,
      responses: {
        200: fileSchema,
      },
    },
    get: {
      method: 'GET',
      path: '/:organizationId/:id',
      pathParams: z.object({
        id: z.string().uuid(),
        organizationId: z.string().uuid(),
      }),
      responses: {
        200: z.object({}),
      },
    },
    delete: {
      method: 'DELETE',
      path: '/:organizationId/:fileId',
      pathParams: z.object({
        organizationId: z.string().uuid(),
        fileId: z.string().uuid(),
      }),
      body: z.object({}),
      responses: {
        200: z.object({}),
      },
    },
    removeAssessmentItemFile: {
      method: 'DELETE',
      path: '/:organizationId/assessment-item/:assessmentItemId/:fileId',
      pathParams: z.object({
        organizationId: z.string().uuid(),
        assessmentItemId: z.string().uuid(),
        fileId: z.string().uuid(),
      }),
      body: z.object({}),
      responses: {
        200: z.object({}),
      },
    },
    removeAssessmentAnswerFile: {
      method: 'DELETE',
      path: '/:organizationId/assessment-answer/:assessmentAnswerId/:fileId',
      pathParams: z.object({
        organizationId: z.string().uuid(),
        assessmentAnswerId: z.string().uuid(),
        fileId: z.string().uuid(),
      }),
      body: z.object({}),
      responses: {
        200: z.object({}),
      },
    },
    updateImageFile: {
      method: 'PUT',
      path: '/:organizationId/image/:id',
      contentType: 'multipart/form-data',
      pathParams: z.object({
        organizationId: z.string().uuid(),
        id: z.string().uuid(),
      }),
      body: z.object({
        file: z.custom<File>(),
        markup: z.string(),
      }),
      responses: {
        200: imageResponseSchema,
      },
    },
    rename: {
      method: 'PATCH',
      path: '/:organizationId/rename/:id',
      pathParams: z.object({
        organizationId: z.string().uuid(),
        id: z.string().uuid(),
      }),
      body: z.object({
        newName: z.string(),
      }),
      responses: {
        200: z.object({}),
      },
    },
  },
  {
    pathPrefix: '/files',
  },
);
