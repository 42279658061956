import isEmpty from 'lodash/isEmpty';
import SuperTokens from 'supertokens-web-js';
import type { AppInfoUserInput } from 'supertokens-web-js/lib/build/types';
import Session from 'supertokens-web-js/recipe/session';

import { logoutChannel } from './logout-broadcast-channel';
import { supertokensHandleEvent$ } from './supertokens-event-bus';

type Params = {
  superTokensAppInfo: AppInfoUserInput;
  superTokensFrontendDomain?: string;
};

export const initSupertokens = ({ superTokensAppInfo, superTokensFrontendDomain }: Params) => {
  SuperTokens.init({
    appInfo: superTokensAppInfo,
    recipeList: [
      Session.init({
        onHandleEvent: ctx => {
          if (ctx.action === 'SIGN_OUT') {
            void logoutChannel.postMessage(true);
          }

          // An invalid session should log out the user
          if (ctx.action === 'UNAUTHORISED' || ctx.action === 'API_INVALID_CLAIM') {
            supertokensHandleEvent$.next(ctx);
          }
        },
        sessionTokenFrontendDomain: !isEmpty(superTokensFrontendDomain) ? superTokensFrontendDomain : undefined,
      }),
    ],
  });
};
