import { Enum_Consideration_Type_Enum } from '@circadian-risk/graphql-types';
import { Typography, TypographyProps } from '@mui/material';
import capitalize from 'lodash/capitalize';
import React from 'react';

import { PrincipalActionIcon } from './PrincipalActionIcon';

export interface PrincipalActionWithIconProps extends Omit<TypographyProps, 'children'> {
  action: Enum_Consideration_Type_Enum;
}

export const PrincipalActionWithIcon: React.FC<PrincipalActionWithIconProps> = ({ action, ...typographyProps }) => {
  const allTypographyProps = {
    fontWeight: 'inherit',
    fontSize: typographyProps.variant ? undefined : 'inherit',
    ...typographyProps,
  };

  return (
    <Typography {...allTypographyProps} display="flex" gap={1} alignItems="center" component={'span'}>
      <PrincipalActionIcon action={action} fontSize="inherit" />
      <Typography {...allTypographyProps} component={'span'}>
        {capitalize(action)}
      </Typography>
    </Typography>
  );
};
