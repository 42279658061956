import { useApiClient } from '@circadian-risk/api-client-provider';
import { BulkAnswerReassess } from '@circadian-risk/api-contract';
import { useOrganizationId } from '@circadian-risk/stores';
import { OptionsUpdateDtoByQuestionId } from '@web-app/modules/Items/hooks/types';
import isEmpty from 'lodash/isEmpty';
import { useCallback } from 'react';

import { useSubmitImageMarkup } from '../LightBox';
import { MemoryFile, QuestionData, QuestionsFormData } from '.';

export const useUploadAndUpdateFilesForAnswer = () => {
  const { tsRestClient } = useApiClient();
  const organizationId = useOrganizationId();
  const submitImageMarkup = useSubmitImageMarkup();

  const handleRenameFiles = async (fileIdsToNames: Record<string, string>, organizationId: string) => {
    for (const [fileId, newName] of Object.entries(fileIdsToNames)) {
      await tsRestClient.files.rename({
        params: {
          organizationId,
          id: fileId,
        },
        body: { newName },
      });
    }
  };

  const handleCreateNewActiveAnswerFiles = async (
    newFiles: MemoryFile[],
    options: { organizationId: string; questionId: string; itemId: string },
  ) => {
    const { itemId, organizationId, questionId } = options;
    for (const { file, id } of newFiles ?? []) {
      await tsRestClient.files.genericUpload({
        params: {
          organizationId,
        },
        body: {
          file,
        },
        query: {
          filename: file.name,
          entityType: 'active_answers',
          entityId: JSON.stringify({ itemId, questionId }),
          fileId: id,
        },
      });
    }
  };

  const handleCreateNewAssessmentAnswerFiles = async (
    newFiles: MemoryFile[],
    options: { organizationId: string; answerId: string },
  ) => {
    const { organizationId, answerId } = options;
    for (const { file, id } of newFiles ?? []) {
      await tsRestClient.files.genericUpload({
        params: {
          organizationId,
        },
        body: {
          file,
        },
        query: {
          filename: file.name,
          entityType: 'answers',
          entityId: answerId,
          fileId: id,
        },
      });
    }
  };

  const handler = async (
    answer: QuestionData,
    options:
      | { type: 'activeAnswers'; organizationId: string; questionId: string; itemId: string }
      | { type: 'assessmentAnswers'; organizationId: string; answerId: string },
  ) => {
    // upload new files
    if (!isEmpty(answer.newFiles)) {
      if (options.type === 'activeAnswers') {
        const { questionId, itemId } = options;
        await handleCreateNewActiveAnswerFiles(answer.newFiles ?? [], { organizationId, itemId, questionId });
      } else {
        const { answerId } = options;
        await handleCreateNewAssessmentAnswerFiles(answer.newFiles ?? [], { organizationId, answerId });
      }
    }

    // upload marked-up images for the answer
    if (answer?.markupToUpload) {
      for (const imageOptions of Object.values(answer.markupToUpload)) {
        await submitImageMarkup(imageOptions);
      }
    }

    // rename already existing files
    if (answer?.filesToRename) {
      await handleRenameFiles(answer.filesToRename, organizationId);
    }
  };

  return handler;
};

/**
 * This is a helpful wrapper around ApiClient that can help update multiple active answers
 */
export const useBulkAnswersUpdate = () => {
  const organizationId = useOrganizationId();
  const { tsRestClient } = useApiClient();
  const uploadAndUpdateFilesForAnswer = useUploadAndUpdateFilesForAnswer();

  const handler = useCallback(
    async (itemId: string, data: QuestionsFormData, optionsUpdateDtos: OptionsUpdateDtoByQuestionId) => {
      const formQuestionAnswers = Object.entries(data);

      if (formQuestionAnswers.length > 0) {
        // Create answers
        await tsRestClient.organizationItems.bulkReassess({
          params: { organizationId },
          body: formQuestionAnswers.map<BulkAnswerReassess[0]>(([questionId, answer]) => {
            const options = optionsUpdateDtos[questionId];
            return {
              ...answer,
              questionId,
              itemId,
              flagged: answer.is_flagged,
              response: answer.response!,
              ...options,
            };
          }),
        });

        // Upload files for answers
        for (const questionIdAnswer of formQuestionAnswers) {
          const [questionId, answer] = questionIdAnswer;
          await uploadAndUpdateFilesForAnswer(answer, {
            type: 'activeAnswers',
            organizationId,
            questionId,
            itemId,
          });
        }
      }
    },
    [organizationId, tsRestClient.organizationItems, uploadAndUpdateFilesForAnswer],
  );

  return handler;
};
