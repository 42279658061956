import { ImportAnswerGroupValueType } from '@circadian-risk/api-contract';
import { VStack } from '@circadian-risk/presentational';
import { Box, Checkbox, FormControlLabel, FormHelperText, Radio, RadioGroup, Typography } from '@mui/material';
import React from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';

import { CreateScheduledAssessmentFormSchema } from '../../form';

const answerTypeOptions: { label: string; value: ImportAnswerGroupValueType }[] = [
  {
    label: 'Compliant',
    value: 'compliant',
  },
  {
    label: 'Deficient',
    value: 'deficient',
  },
  {
    label: 'N/A',
    value: 'n/a',
  },
  {
    label: 'N/O',
    value: 'n/o',
  },
];

export const CompletionCriteriaStep: React.FC = () => {
  const { control } = useFormContext<CreateScheduledAssessmentFormSchema>();
  return (
    <VStack>
      <Box>
        <Typography variant="subtitle1">Import Answers and Required Actions</Typography>
        <Typography variant="body2">
          Some of the questions from this template have already been answered at this location would you like to import
          the active answers into this assessment?
        </Typography>
      </Box>

      <Controller
        control={control}
        name={'importAnswers'}
        render={({ field: { onChange, value: ratification }, fieldState: { error } }) => {
          const valueError = error as unknown as { value?: FieldError; type?: FieldError };
          return (
            <VStack>
              <RadioGroup
                aria-label="import answers type"
                name="importAnswers"
                value={ratification.type}
                onChange={e => onChange(e.target.value === 'fresh' ? { type: 'fresh' } : { type: 'groups', value: [] })}
              >
                <FormControlLabel
                  value={'fresh'}
                  control={<Radio color="primary" />}
                  label={'Start fresh (no answers will be imported)'}
                />
                <FormControlLabel
                  value={'groups'}
                  control={<Radio color="primary" />}
                  label={'Previous answers will be imported'}
                />
              </RadioGroup>

              {ratification.type === 'groups' && (
                <VStack pl={4}>
                  <div>Selected groups will required assessor to review & ratify answers</div>
                  <VStack pl={1}>
                    {answerTypeOptions.map(option => {
                      const checked = ratification.value.includes(option.value);
                      return (
                        <FormControlLabel
                          key={option.value}
                          control={
                            <Checkbox
                              checked={checked}
                              onChange={() =>
                                onChange({
                                  type: 'groups',
                                  value: checked
                                    ? ratification.value.filter(e => e !== option.value)
                                    : [...ratification.value, option.value],
                                })
                              }
                            />
                          }
                          label={option.label}
                        />
                      );
                    })}
                  </VStack>
                </VStack>
              )}
              {valueError?.value?.message && <FormHelperText error>{valueError.value.message}</FormHelperText>}
            </VStack>
          );
        }}
      />
    </VStack>
  );
};
