import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  ColumnApi,
  ColumnEvent,
  ColumnPivotChangedEvent,
  ColumnVisibleEvent,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GetRowIdFunc,
  GridApi,
  GridSizeChangedEvent,
  Module,
  SideBarDef,
} from '@ag-grid-community/core';
import { AgGridReact, AgGridReactProps } from '@ag-grid-community/react';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import omit from 'lodash/omit';
import React, { useCallback, useMemo } from 'react';

import { DataGridStyles, DataGridStylesProps } from './DataGridStyles';
import { useColumnState, useGrid, useGridFilter, useGridQuickFilterTextLinking, usePivotState } from './hooks';

const sidebar: SideBarDef = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true,
        suppressSideButtons: true,
        suppressColumnFilter: true,
        suppressColumnSelectAll: true,
        suppressColumnExpandAll: true,
      },
    },
    'filters',
  ],
  defaultToolPanel: '',
};

const agPropsWithDefaults: (keyof AgGridReactProps)[] = [
  'onGridReady',
  'onFirstDataRendered',
  'onFilterChanged',
  'onSortChanged',
  'onColumnVisible',
  'onColumnRowGroupChanged',
  'onColumnValueChanged',
  'onColumnPivotModeChanged',
  'onColumnPivotChanged',
  'onGridSizeChanged',
];

type DeprecatedProps =
  | 'immutableData'
  | 'getRowNodeId'
  | 'reactUi'
  | 'getRowId'
  | 'frameworkComponent'
  | 'detailCellRendererFramework';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DataGridProps<T = any> = Omit<AgGridReactProps, DeprecatedProps> & {
  rowData?: T[];
  includeColumnsSelector?: boolean;
  disableDeepLinking?: boolean;
  disableDefaultFilterHook?: boolean;
  children?: React.ReactNode;
  /**
   * If your table needs to have additional access to the grid or column apis for advanced functionality
   * you can pass a callback here to capture them
   */
  onAdditionalGridReady?: (gridApi: GridApi, columnApi: ColumnApi) => void;
  onFilteredDataChanged?: (filteredData: T[]) => void;
  enableExcelExport?: boolean;
  tableWrapperProps?: DataGridStylesProps;
  getRowId?: GetRowIdFunc<T>;
};

export const DataGrid = <TData,>(props: DataGridProps<TData>) => {
  const {
    columnDefs,
    rowData,
    defaultColDef,
    modules,
    includeColumnsSelector = true,
    disableDeepLinking = false,
    disableDefaultFilterHook = false,
    onAdditionalGridReady,
    onFilteredDataChanged,
    children,
    enableExcelExport = false,
    tableWrapperProps,
    ...agGridReactProps
  } = props;

  const quickFilterTextLinking = useGridQuickFilterTextLinking(disableDeepLinking);
  // Get the subset of ag grid properties which we can actually pass to the
  // grid without overriding the default functionality provided by this component
  const validAgGridProps = omit(agGridReactProps, agPropsWithDefaults);
  const defaultOnGridReady = agGridReactProps.onGridReady;

  const handleOnReady = useCallback(
    (gridApi: GridApi, columnApi: ColumnApi) => {
      if (defaultOnGridReady) {
        defaultOnGridReady({ type: 'onGridReady', api: gridApi, columnApi, context: agGridReactProps.context });
      }
      if (onAdditionalGridReady) {
        onAdditionalGridReady(gridApi, columnApi);
      }

      quickFilterTextLinking.onGridReady(gridApi);
    },
    [agGridReactProps.context, defaultOnGridReady, onAdditionalGridReady, quickFilterTextLinking],
  );

  const { onGridReady } = useGrid(handleOnReady);

  const filterHook = useGridFilter();
  const columnStateHook = useColumnState();
  const pivotStateHook = usePivotState();

  const agGridModules: Module[] = useMemo(() => {
    return [
      ClientSideRowModelModule,
      SetFilterModule,
      FiltersToolPanelModule,
      ...(enableExcelExport ? [ExcelExportModule] : []),
      ...(includeColumnsSelector ? [ColumnsToolPanelModule] : []),
      ...(modules ?? []),
    ];
  }, [enableExcelExport, includeColumnsSelector, modules]);

  const agGridDefaultColDef = useMemo(() => ({ filter: true, resizable: true, ...defaultColDef }), [defaultColDef]);

  const onFirstDataRendered = useCallback(
    (e: FirstDataRenderedEvent) => {
      if (agGridReactProps.onFirstDataRendered) {
        agGridReactProps.onFirstDataRendered(e);
      }
      if (!disableDefaultFilterHook) {
        filterHook.onFirstDataRendered(e);
      }
      columnStateHook.onFirstDataRendered(e);
      pivotStateHook.onFirstDataRendered(e);
    },
    [agGridReactProps, columnStateHook, disableDefaultFilterHook, filterHook, pivotStateHook],
  );

  const onFilterChanged = useCallback(
    (e: FilterChangedEvent) => {
      if (!disableDeepLinking && agGridReactProps.onFilterChanged) {
        agGridReactProps.onFilterChanged(e);
      }

      if (!disableDefaultFilterHook) {
        filterHook.onFilterChanged(e);
      }

      if (onFilteredDataChanged) {
        const model = e.api.getModel();
        // rowsToDisplay is a public property but isn't part of the type definitions
        // As long as we're using the client side row model this should still be functional
        const filteredRows = (model as unknown as { rowsToDisplay: { data: TData }[] }).rowsToDisplay.map(x => x.data);
        onFilteredDataChanged(filteredRows);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [disableDeepLinking, disableDefaultFilterHook, filterHook],
  );

  const onSortChanged = useCallback(
    (e: ColumnEvent) => {
      if (disableDeepLinking) {
        return;
      }

      columnStateHook.onColumnChange(e);
    },
    [columnStateHook, disableDeepLinking],
  );

  const onColumnVisible = useCallback(
    (e: ColumnVisibleEvent) => {
      if (disableDeepLinking) {
        return;
      }

      columnStateHook.onColumnChange(e);
    },
    [columnStateHook, disableDeepLinking],
  );

  const onPivotChanged = useCallback(
    (e: ColumnPivotChangedEvent) => {
      if (disableDeepLinking) {
        return;
      }
      pivotStateHook.onPivotChange(e);
    },
    [pivotStateHook, disableDeepLinking],
  );

  const onGridSizeChanged = (event: GridSizeChangedEvent) => {
    event.columnApi.autoSizeAllColumns();
    if (agGridReactProps.onGridSizeChanged) {
      agGridReactProps.onGridSizeChanged(event);
    }
  };

  return (
    <DataGridStyles {...tableWrapperProps}>
      <AgGridReact<TData>
        rowModelType={'clientSide'}
        // TODO(v-rrodrigues)[CR-5012]: We can revisit this issue again
        // as Ag team seem to have worked around the "Resize loop observed" error
        suppressBrowserResizeObserver={true}
        columnDefs={columnDefs}
        rowData={rowData}
        modules={agGridModules}
        // we can enable some column properties by default for all tables
        defaultColDef={agGridDefaultColDef}
        pagination
        paginationPageSize={50}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        onFilterChanged={onFilterChanged}
        onSortChanged={onSortChanged}
        onColumnVisible={onColumnVisible}
        onColumnRowGroupChanged={onColumnVisible}
        onColumnValueChanged={onColumnVisible}
        onColumnPivotModeChanged={onPivotChanged}
        onColumnPivotChanged={onPivotChanged}
        onGridSizeChanged={onGridSizeChanged}
        disableStaticMarkup
        sideBar={sidebar}
        enableCellTextSelection
        excelStyles={
          enableExcelExport
            ? [
                {
                  id: 'header',
                  font: {
                    bold: true,
                  },
                },
              ]
            : undefined
        }
        {...validAgGridProps}
      >
        {children}
      </AgGridReact>
    </DataGridStyles>
  );
};
