import { Chip, ChipProps, Tooltip } from '@mui/material';
import startCase from 'lodash/startCase';
import React from 'react';

import { LocationTagIcon } from '../Icons';

export interface LocationTagChipProps {
  name: 'all' | string;
  tooltip?: string;
  chipProps?: ChipProps;
}

export const LocationTagChip: React.FC<LocationTagChipProps> = ({ name, tooltip, chipProps }) => {
  const nameLowerCase = name.toLocaleLowerCase();

  return (
    <Tooltip title={tooltip ?? ''}>
      <Chip
        size="small"
        icon={<LocationTagIcon />}
        label={startCase(nameLowerCase)}
        color={nameLowerCase === 'all' ? 'primary' : 'default'}
        variant="filled"
        {...chipProps}
      />
    </Tooltip>
  );
};
