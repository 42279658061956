import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';

// 1000 -> 1k, 10000 -> 1kk etc
const { format } = Intl.NumberFormat('en', { notation: 'compact' });

type CriticalityCircleCountProps = { value: number; criticality: '1' | '2' | '3' };

export const CriticalityCircleCount: React.FC<CriticalityCircleCountProps> = ({ value, criticality }) => {
  const theme = useTheme();
  const color = theme.palette.organizationColors.criticality[criticality];

  return (
    <Box
      width="24px"
      height="24px"
      color={value > 0 ? theme => theme.palette.getContrastText(color) : color}
      border={value === 0 ? `1px dashed ${color}` : undefined}
      bgcolor={value > 0 ? color : undefined}
      borderRadius={'50%'}
      display="flex"
      justifyContent={'center'}
      alignItems="center"
    >
      <Typography variant="caption">{value > 0 ? format(value) : '-'}</Typography>
    </Box>
  );
};
