import { createEnhancedStore } from '@circadian-risk/front-end-utils';
import { SessionAccessPayload } from '@circadian-risk/shared';
import * as Sentry from '@sentry/react';
import { StateCreator } from 'zustand';

export type OrganizationRow = {
  id: string;
  name: string;
  logo?: string;
  requires_eula?: boolean;
};

// This extra piece of data is being used in the web-app
// but in the future could potentially be read in the assessment app
// requiring its hydraton
type ExtraData = {
  location: string | null;
  company: string | null;
  bio: string | null;
  phone_number: string | null;
  certifications: string | null;
  needsToSetPassword: boolean;
  lastSignedInAt?: string | Date | null;
  title: string | null;
  firstName: string;
  lastName: string;
  avatarFilePath?: string;
  organizations: OrganizationRow[];
  /**
   * Alias for `x-hasura-org-id` claim which helps identifying
   * the user organization for redirection purposes
   */
  tokenDefaultOrgId?: string;
  loaded: boolean;
};

// This type represents the data that we can extract from the Session Access Payload
type PayloadData = Omit<SessionAccessPayload, 'jwt' | 'https://hasura.io/jwt/claims' | 'activationExpiry'> & {
  id: string;
};

export type UserSessionStore = PayloadData &
  ExtraData & {
    setOrganizations: (organizations: OrganizationRow[]) => void;
    setSessionData: (payload: SessionAccessPayload, extraData?: ExtraData) => void;
    resetSessionData: () => void;
    setNeedsToSignEula: (needsToSignEula: boolean) => void;
    // Computed field based when setSessionData is invoked and resetSessionData
    isAuthenticated: boolean;
  };

const defaultUser = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  subscriberHash: '',
  isSsoUser: false,
  avatarFilePath: undefined,
  isCircadianAdmin: false,
  isPendingEmailVerification: false,
  consentsToMarketingCommunication: false,
  location: null,
  company: null,
  bio: null,
  phone_number: null,
  certifications: null,
  needsToSetPassword: false,
  lastSignedInAt: null,
  title: null,
  organizations: [],
  isAuthenticated: false,
  needsToSignEula: false,
  loaded: false,
};

const stateCreator: StateCreator<UserSessionStore> = (set, _get) => ({
  ...defaultUser,
  setSessionData(payload, extraData) {
    const hasuraClaims = payload['https://hasura.io/jwt/claims'];
    const id = hasuraClaims['x-hasura-user-id'];
    Sentry.setUser({
      id,
      email: payload.email,
    });

    Sentry.setExtras({
      allowedRoles: hasuraClaims['x-hasura-allowed-roles'],
      defaultRole: hasuraClaims['x-hasura-default-role'],
    });

    set({
      isAuthenticated: true,
      id,
      email: payload.email,
      isCircadianAdmin: payload.isCircadianAdmin,
      isPendingEmailVerification: payload.isPendingEmailVerification,
      consentsToMarketingCommunication: payload.consentsToMarketingCommunication,
      isSsoUser: payload.isSsoUser,
      subscriberHash: payload.subscriberHash,
      tokenDefaultOrgId: payload['https://hasura.io/jwt/claims']['x-hasura-org-id'],
      ...extraData,
    });
  },
  setOrganizations(organizations) {
    set({ organizations });
  },
  resetSessionData() {
    // TODO(mikingtheviking): When being used app wide, invoke offline storage clearing [CR-2373]
    Sentry.getCurrentScope().setUser(null);
    set(defaultUser);
  },
  setNeedsToSignEula(needsToSignEula) {
    set({ needsToSignEula });
  },
});

export const useUserSessionStore = createEnhancedStore(stateCreator, { byPassReset: true });

export const useUserSessionId = () => useUserSessionStore(state => state.id);
export const useUserSessionEmail = () => useUserSessionStore(state => state.email);
