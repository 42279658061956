import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import {
  AgGridColumnTypes,
  AlignCell,
  CellRendererParams,
  ColDefOfType,
  DataGrid,
  headerTemplateVariableHeight,
  ValueGetterParams,
} from '@circadian-risk/data-grid';
import { splitMpath } from '@circadian-risk/front-end-utils';
import { NodeIcon, ProgressButton } from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { sortLocationsByOrdinalAndName } from '@circadian-risk/shared';
import FlagIcon from '@mui/icons-material/Flag';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import { Box, Chip, Tooltip, useTheme } from '@mui/material';
import NavLinkWithActiveOrgId from '@web-app/components/NavLinkWithActiveOrgId';
import isArray from 'lodash/isArray';
import isNumber from 'lodash/isNumber';
import pluralize from 'pluralize';
import React from 'react';

export interface DeficienciesByLocationRow {
  name: string;
  locationId: string;
  locationType: string;
  mpath: string;
  tags?: string[];
  flaggedCount?: number;
  ordinal: number;

  interviewPercentageComplete?: number;
  interviewLink?: string;

  inspectionPercentageComplete?: number;
  inspectionLink?: string;

  criticality1count?: number;
  criticality2count?: number;
  criticality3count?: number;
  interviewEnabled: boolean;
  inspectionEnabled: boolean;
}

interface TableContext {
  showLinks: DeficienciesByLocationGridProps['showLinks'];
}

const FlaggedCellRenderer: React.FC<CellRendererParams<DeficienciesByLocationRow>> = ({ data: { flaggedCount } }) => {
  if (!isNumber(flaggedCount)) {
    return null;
  }

  return (
    <AlignCell justifyContent="flex-end">
      <Chip
        size="small"
        icon={
          <FlagIcon
            sx={theme => ({ color: flaggedCount > 0 ? theme.palette.error.main : theme.palette.action.active })}
          />
        }
        label={flaggedCount}
        disabled={flaggedCount === 0}
      />
    </AlignCell>
  );
};

const TagsCellRenderer: React.FC<CellRendererParams<DeficienciesByLocationRow>> = ({ data: { tags } }) => {
  const theme = useTheme();
  if (!isArray(tags)) {
    return null;
  }

  const plural = pluralize('Tag', tags.length);
  const linkStyles: React.CSSProperties = {
    textDecoration: `underline ${theme.palette.info.main}`,
    color: theme.palette.info.main,
  };
  const tagsWithCommas = tags.join(', ');
  return (
    <Tooltip title={tagsWithCommas}>
      <span style={tags.length > 0 ? linkStyles : { color: theme.palette.action.disabled }}>
        {tags.length} {plural}
      </span>
    </Tooltip>
  );
};

const CriticalityCellRenderer: React.FC<CellRendererParams<DeficienciesByLocationRow> & { value: number }> = ({
  value: count,
}) => {
  const theme = useTheme();
  return (
    <AlignCell justifyContent="flex-end" color={count === 0 ? theme.palette.action.disabled : undefined}>
      <Box component="span" pr={0.5}>
        {count}
      </Box>
      <InsertChartOutlinedIcon />
    </AlignCell>
  );
};

const ProgressCellRenderer: React.FC<
  CellRendererParams<DeficienciesByLocationRow> & { mode: 'interview' | 'inspection' }
> = ({ value, mode, data }) => {
  if (
    (value == null && mode === 'interview' && !data.interviewEnabled) ||
    (value == null && mode === 'inspection' && !data.inspectionEnabled)
  ) {
    return null;
  }

  const modeText = mode === 'interview' ? 'Inspection Questionnaire' : 'Physical Inspection';
  const customTooltip = `Navigate to ${modeText} for ${data.name} (${data.locationType})`;
  const link = mode === 'inspection' ? data.inspectionLink : data.interviewLink;
  return (
    <Tooltip title={customTooltip}>
      <ProgressButton percentComplete={value} link={link} disableTooltip isExternalLink />
    </Tooltip>
  );
};

const LocationCellRenderer: React.FC<CellRendererParams<DeficienciesByLocationRow, TableContext>> = ({
  value,
  data,
  context,
}) => {
  const content = <Box ml={0.5}>{value}</Box>;
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <NodeIcon fontSize="small" layerName={data.locationType} />
      {context.showLinks ? (
        <NavLinkWithActiveOrgId to={`${ROUTES.NODES}/${data.locationId}`}>{content}</NavLinkWithActiveOrgId>
      ) : (
        content
      )}
    </Box>
  );
};

export interface DeficienciesByLocationGridProps {
  locations: DeficienciesByLocationRow[];
  criticalityLabels: Record<number, string>;
  showLinks: boolean;
}

export const DeficienciesByLocationGrid: React.FC<DeficienciesByLocationGridProps> = ({
  locations,
  criticalityLabels,
  showLinks,
}) => {
  const theme = useTheme();
  const { criticality: criticalityColors } = theme.palette.organizationColors;

  const columnDefs: ColDefOfType<DeficienciesByLocationRow>[] = [
    {
      headerName: 'Inspection Questionnaire Progress',
      field: 'interviewPercentageComplete',
      cellRenderer: ProgressCellRenderer,
      wrapText: true,
      flex: 0,
      headerComponentParams: { template: headerTemplateVariableHeight },
      cellRendererParams: { mode: 'interview' },
      maxWidth: 150,
    },
    {
      headerName: 'Physical Inspection Progress',
      field: 'inspectionPercentageComplete',
      cellRenderer: ProgressCellRenderer,
      wrapText: true,
      flex: 0,
      headerComponentParams: { template: headerTemplateVariableHeight },
      cellRendererParams: { mode: 'inspection' },
      maxWidth: 150,
    },
    {
      headerName: 'Tags',
      field: 'tags',
      cellRenderer: TagsCellRenderer,
      initialHide: true,
    },
    {
      headerName: criticalityLabels[1],
      field: 'criticality1count',
      cellStyle: { color: criticalityColors[1] },
      type: 'numeric',
      cellRenderer: CriticalityCellRenderer,
      maxWidth: 150,
      headerComponentParams: { template: headerTemplateVariableHeight },
    },
    {
      headerName: criticalityLabels[2],
      field: 'criticality2count',
      cellStyle: { color: criticalityColors[2] },
      type: 'numeric',
      cellRenderer: CriticalityCellRenderer,
      maxWidth: 150,
      headerComponentParams: { template: headerTemplateVariableHeight },
    },
    {
      headerName: criticalityLabels[3],
      field: 'criticality3count',
      cellStyle: { color: criticalityColors[3] },
      type: 'numeric',
      cellRenderer: CriticalityCellRenderer,
      maxWidth: 150,
      headerComponentParams: { template: headerTemplateVariableHeight },
    },
    {
      headerName: 'Flagged',
      field: 'flaggedCount',
      filter: 'agNumberColumnFilter',
      type: 'numeric',
      cellRenderer: FlaggedCellRenderer,
      width: 100,
    },
  ];

  const sortedLocations = sortLocationsByOrdinalAndName(locations);

  return (
    <DataGrid
      tableWrapperProps={{ id: 'deficiencies-by-location' }}
      columnDefs={columnDefs}
      rowData={sortedLocations}
      modules={[RowGroupingModule]}
      treeData={true}
      animateRows={true}
      groupDefaultExpanded={-1}
      domLayout="autoHeight"
      gridOptions={{
        getDataPath: (data: DeficienciesByLocationRow) => splitMpath(data.mpath),
      }}
      columnTypes={{
        numeric: AgGridColumnTypes.numeric,
      }}
      defaultColDef={{
        sortable: true,
      }}
      context={{
        showLinks,
      }}
      autoGroupColumnDef={{
        headerName: 'Locations',
        flex: 2,
        valueGetter: ({ data: { name } }: ValueGetterParams<DeficienciesByLocationRow>) => name,
        cellRendererParams: { suppressCount: true, innerRenderer: LocationCellRenderer },
      }}
    />
  );
};
