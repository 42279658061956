import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { alpha, SvgIconProps, Tooltip, Typography } from '@mui/material';
import React from 'react';

import { Bold, ColoredMeasure, HStack, InherentRiskIcon, Strikethrough, TouchFriendlyTooltip } from '../..';

export interface ScoreDiffProps {
  previous: ColoredMeasure<number | null>;
  current: ColoredMeasure<number | null>;
}

export const ScoreDiff: React.FC<ScoreDiffProps> = ({ current, previous }) => {
  let ChangeIcon: React.FC<SvgIconProps<'svg'>>;

  const newColor = current.color;
  const oldColor = previous.color;

  if (previous.value == null || current.value == null) {
    ChangeIcon = TrendingFlatIcon;
  } else if (current.value < previous.value) {
    ChangeIcon = TrendingDownIcon;
  } else if (previous.value < current.value) {
    ChangeIcon = TrendingUpIcon;
  } else {
    ChangeIcon = TrendingFlatIcon;
  }

  return (
    <HStack
      spacing={0.5}
      bgcolor={alpha(newColor, 0.1)}
      border={`1px solid ${newColor}`}
      width="max-content"
      borderRadius={2}
      px={1}
      sx={{ userSelect: 'none' }}
    >
      {previous.value != null && (
        <>
          <Tooltip title={previous.value}>
            <Typography sx={{ opacity: 0.5, cursor: 'help' }} variant="h6" color={oldColor}>
              <Strikethrough>
                <Bold weight="light">{previous.value}</Bold>
              </Strikethrough>
            </Typography>
          </Tooltip>
          <ChangeIcon htmlColor={newColor} sx={{ fontSize: '1.2em' }} />
        </>
      )}
      {current.value != null ? (
        <Typography variant="h6" color={newColor}>
          <Bold weight="bold">{current.value}</Bold>
        </Typography>
      ) : (
        <TouchFriendlyTooltip tooltipContent="Score can't be calculated unless all the measures for probability and severity have values">
          <InherentRiskIcon sx={{ my: 0.5 }} htmlColor={newColor} />
        </TouchFriendlyTooltip>
      )}
    </HStack>
  );
};
