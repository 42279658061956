import { FCC } from '@circadian-risk/front-end-utils';
import { Box, Card, Typography } from '@mui/material';
import React from 'react';

import { centerContentBoxProps } from './CenterContent.styles';

export const CenterMessage: FCC<{ title?: string }> = ({ title, children }) => {
  return (
    <Box {...centerContentBoxProps}>
      <Card>
        <Box p={4} display="flex" flexDirection="column" alignItems="center">
          {title && (
            <Box mb={2}>
              <Typography variant="h5">{title}</Typography>
            </Box>
          )}
          {children}
        </Box>
      </Card>
    </Box>
  );
};
