import { Box, FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';
import { Controller } from 'react-hook-form';

import { PropertyFieldProps } from '../types';

export interface PropertyTextFieldProps extends PropertyFieldProps {
  autoFocus?: boolean;
  autoComplete?: string;
  multiline?: boolean;
  rows?: number;
  placeholder?: string;
  maxLength?: number;
}

export const PropertyTextField: React.FC<PropertyTextFieldProps> = ({
  autoFocus,
  label,
  htmlName,
  control,
  disabled,
  rules,
  boxProps,
  multiline,
  rows,
  errorMessage,
  startAdornment,
  endAdornment,
  type,
  placeholder,
  autoComplete,
  onBlur: blurCallback,
  helperText,
  maxLength,
}) => {
  const id = `text-field-${htmlName}`;
  return (
    <Box {...boxProps}>
      <Controller
        control={control}
        name={htmlName}
        defaultValue={null}
        rules={rules}
        render={fieldProps => {
          const { onChange, value, onBlur } = fieldProps.field;
          const handleBlur = () => {
            onBlur();
            if (blurCallback) {
              blurCallback(value);
            }
          };

          const fieldError = errorMessage || fieldProps.fieldState.error?.message;

          const labelWithHelpText = label + (maxLength ? ` (${maxLength} characters max)` : '');
          return (
            <FormControl variant="outlined" fullWidth disabled={disabled} error={Boolean(fieldError)}>
              <InputLabel htmlFor={id} role="textbox">
                {labelWithHelpText}
              </InputLabel>
              <OutlinedInput
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={autoFocus}
                autoComplete={autoComplete}
                onBlur={handleBlur}
                id={id}
                name={htmlName}
                value={value ?? ''}
                onChange={onChange}
                type={type ?? 'text'}
                label={labelWithHelpText}
                data-name={label}
                multiline={multiline}
                rows={rows}
                endAdornment={endAdornment}
                startAdornment={startAdornment}
                placeholder={placeholder}
                inputProps={{ maxLength: maxLength ?? undefined }}
              />
              <FormHelperText>{fieldError ?? helperText}</FormHelperText>
              {maxLength && <FormHelperText>{maxLength} characters max</FormHelperText>}
            </FormControl>
          );
        }}
      />
    </Box>
  );
};
