import { FormPaper } from '@circadian-risk/form';
import { PageFormLayout } from '@circadian-risk/layout';
import { OpenInNewTabLink, VStack } from '@circadian-risk/presentational';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { ResponsiveIpadDemo } from '../components/ResponsiveIpadDemo/ResponsiveIpadDemo';
import { SignInForm, SignInFormProps } from './SignInForm';

export interface SignInPageProps extends SignInFormProps {
  signUpRoute: string;
  signUpEnabled: boolean;
}

export const SignInPage: React.FC<SignInPageProps> = ({ signUpRoute, signUpEnabled, ...singInFormProps }) => {
  return (
    <PageFormLayout
      leftContent={<ResponsiveIpadDemo />}
      form={
        <VStack spacing={2}>
          <SignInForm {...singInFormProps} />
          {signUpEnabled && (
            <FormPaper showBg sx={{ padding: theme => theme.spacing(4) }}>
              <VStack spacing={2}>
                <Typography align="center" variant="h6">
                  Don't have an account?
                </Typography>
                <RouterLink to={signUpRoute} style={{ width: '100%' }}>
                  <Button variant="contained" color="success" fullWidth>
                    Sign Up
                  </Button>
                </RouterLink>
                <Typography component="div" variant="body2" color={theme => theme.palette.text.secondary}>
                  Curious to learn more about our platform?
                  <br />
                  Check out our{' '}
                  <Box display="inline-flex">
                    <OpenInNewTabLink
                      external
                      text="website"
                      link="https://www.circadianrisk.com/?utm_source=Portal&utm_medium=Sign+In&utm_campaign=Portal+Activation"
                    />
                  </Box>
                </Typography>
              </VStack>
            </FormPaper>
          )}
        </VStack>
      }
    />
  );
};
