import { Box } from '@mui/material';
import React from 'react';

export interface AutoGridProps {
  children: React.ReactNode[];
  fillAvailableWidth?: boolean;
}

export const AutoGrid: React.FC<AutoGridProps> = ({ children, fillAvailableWidth = false }) => {
  return (
    <Box
      display={'grid'}
      flexWrap={'wrap'}
      gap={3}
      p={4}
      gridTemplateColumns={{
        xs: '1fr',
        sm: `repeat(auto-${fillAvailableWidth ? 'fit' : 'fill'}, minmax(350px, 1fr))`,
      }}
    >
      {children}
    </Box>
  );
};
