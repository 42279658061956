import { ScheduledAssessmentTimeHelpers } from '@circadian-risk/data-utils';
import dayjs from 'dayjs';

import { CreateAssessmentFormStepperConfig } from '../../stepsConfig';
import { AssessmentDetailsStepperSubtitle } from './AssessmentDetailsStepperSubtitle';
import { CadenceDetailsStep } from './CadenceDetailsStep';

export const cadenceDetailsStepConfig: CreateAssessmentFormStepperConfig = {
  title: 'Assessment Details',
  component: CadenceDetailsStep,
  stepper: {
    titleOverride: 'Details',
    subtitle: AssessmentDetailsStepperSubtitle,
  },
  validator: async ctx => {
    const timeHelpers = new ScheduledAssessmentTimeHelpers();
    const isValid = await ctx.trigger([
      'name',
      'description',
      'frequency',
      'dueAfter',
      'notifications',
      'timezone',
      'endsOn',
      'startsAtDate',
      'startsAtTime',
    ]);

    if (!isValid) {
      return false;
    }

    const { frequency, dueAfter, startsAtDate, endsOn } = ctx.getValues();

    if (endsOn.type === 'target_date' && dayjs(endsOn.value).isBefore(startsAtDate)) {
      ctx.setError('endsOn.value', { message: 'End on date must be after the start date' });
      return false;
    }

    if (timeHelpers.isDueAfterFrequencyTime(dueAfter, frequency)) {
      ctx.setError('dueAfter', { message: 'Due after must be shorter than the frequency' });
      return false;
    }

    return true;
  },
};
