import { useFuse, usePatternFuse } from '@circadian-risk/front-end-utils';
import { removeExtension } from '@circadian-risk/shared';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  SxProps,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import Fuse from 'fuse.js';
import React, { useMemo } from 'react';

import { HStack, ITEM_ICON_SIZES, ItemIconSizes, VStack } from '../../';
import { LibraryIcon } from './LibraryIcon';
import { LibIcon } from './types';

export interface IconSelectorProps {
  icons: LibIcon[];
  selectedId?: number;
  itemCategoryName?: string;
  maxRowsToShow?: number;
  sx?: SxProps<Theme>;
  onIconClear?: () => void;
  onIconSelect: (selectedIconId: number) => void;
}

const SUGGESTED_ICONS_LIMIT = 3;
const BLANK_SEARCH = '';
const ICON_ROW_HEIGHT = ITEM_ICON_SIZES.large.height;

export const IconSelector: React.FC<IconSelectorProps> = React.memo(
  ({ icons, selectedId, itemCategoryName, onIconSelect, onIconClear, sx, maxRowsToShow = 3 }) => {
    const searchFields: Fuse.FuseOptionKey<LibIcon>[] = useMemo(
      () => [
        { name: 'keywords', weight: 3, getFn: icon => icon.keywords ?? [] },
        { name: 'name', weight: 1, getFn: icon => removeExtension(icon.name) },
      ],
      [],
    );
    const options: Fuse.IFuseOptions<LibIcon> = { keys: searchFields, threshold: 0.3 };
    const {
      rows: displayedIcons,
      searchText,
      setSearchText,
    } = useFuse({
      list: icons,
      options,
    });

    const handleReset = () => setSearchText(BLANK_SEARCH);

    const sensitiveFuseOptions: Fuse.IFuseOptions<LibIcon> = useMemo(
      () => ({
        keys: searchFields,
        threshold: 0.6,
      }),
      [searchFields],
    );

    const { search: sensitiveMultiWordSearch } = usePatternFuse(icons, sensitiveFuseOptions);

    const suggestedIcons = useMemo(() => {
      if (!itemCategoryName) {
        return [];
      }
      return sensitiveMultiWordSearch({
        $or: itemCategoryName.split(' ').map(word => ({
          $or: [
            { $path: 'keywords', $val: word },
            { $path: 'name', $val: word },
          ],
        })),
      }).slice(0, SUGGESTED_ICONS_LIMIT);
    }, [itemCategoryName, sensitiveMultiWordSearch]);

    const isSearchActive = searchText !== BLANK_SEARCH;
    return (
      <Box sx={{ p: 2, ...sx }}>
        <Stack direction="column" width={'100%'} spacing={2}>
          <TextField
            fullWidth
            autoComplete="off"
            placeholder="Search for icon keywords..."
            size="medium"
            variant="standard"
            value={searchText}
            onChange={({ target: { value } }) => setSearchText(value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {isSearchActive && (
                    <Tooltip title="Reset">
                      <IconButton size="small" onClick={handleReset}>
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </InputAdornment>
              ),
            }}
          />
          {!isSearchActive && suggestedIcons.length > 0 && (
            <VStack spacing={1}>
              <Typography variant="body2" color="info.main">
                Suggested icons for "{itemCategoryName}":
              </Typography>
              <HStack justifyContent={'center'} spacing={0}>
                {suggestedIcons.map(icon => (
                  <LibraryIcon key={icon.id} size={ItemIconSizes.large} icon={icon} onIconSelect={onIconSelect} />
                ))}
              </HStack>
              <Divider />
            </VStack>
          )}
          {displayedIcons.length > 0 && (
            <HStack
              flexWrap={'wrap'}
              p={'1px'}
              spacing={0}
              maxHeight={ICON_ROW_HEIGHT * maxRowsToShow + ICON_ROW_HEIGHT / 2}
              overflow="auto"
            >
              {displayedIcons.map(icon => (
                <LibraryIcon
                  key={icon.id}
                  size={ItemIconSizes.medium}
                  icon={icon}
                  onIconSelect={onIconSelect}
                  selected={selectedId === icon.id}
                />
              ))}
            </HStack>
          )}
          {displayedIcons.length === 0 && (
            <Typography variant="body2" color="secondary" align="center">
              No results found, you can upload your own icon or contact support to request specific icons
            </Typography>
          )}
          {onIconClear && <Button onClick={onIconClear}>Clear Icon</Button>}
        </Stack>
      </Box>
    );
  },
);
