import Fuse from 'fuse.js';
import { useCallback, useMemo } from 'react';

export const usePatternFuse = <T,>(list: T[], options: Fuse.IFuseOptions<T>) => {
  const fuse = useMemo(() => {
    return new Fuse(list, options);
  }, [list, options]);

  const search = useCallback(
    (pattern?: string | Fuse.Expression) => {
      if (pattern) {
        return fuse.search(pattern).map(i => i.item);
      }
      return list;
    },
    [fuse, list],
  );

  return { fuseInstance: fuse, search };
};
