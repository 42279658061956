import { FCC } from '@circadian-risk/front-end-utils';
import { HEADER_HEIGHT } from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { USER_ROLE } from '@circadian-risk/shared';
import { useOrganizationSessionStore, useUserSessionStore } from '@circadian-risk/stores';
import Container from '@mui/material/Container';
import keyBy from 'lodash/keyBy';
import { useMemo } from 'react';
import { generatePath } from 'react-router-dom';

import Header from './Header';

export const AuthenticatedLayout: FCC = ({ children }) => {
  const organizationsById = useUserSessionStore(state => {
    return keyBy(state.organizations, e => e.id);
  });

  const [organizationId, userRole] = useOrganizationSessionStore(state => [state.id, state.role]);

  const returnRoute = useMemo(() => {
    if (userRole === USER_ROLE.MEMBER && organizationId) {
      return generatePath(ROUTES.ASSESSMENTS, { organizationId });
    }

    return ROUTES.ROOT;
  }, [organizationId, userRole]);

  const isCircadianAdmin = useUserSessionStore(state => state.isCircadianAdmin);

  return (
    <Container
      sx={{
        display: 'grid',
        gridTemplateRows: `${HEADER_HEIGHT}px 1fr`,
        minHeight: '100vh',
        minWidth: '100%',
        padding: 0,
        border: isCircadianAdmin ? '2px solid red' : undefined,
      }}
      disableGutters
    >
      <Header hasMenu organizationRecords={organizationsById} returnRoute={returnRoute} />
      {children}
    </Container>
  );
};
