import { generatePath, useHistory, useParams } from 'react-router-dom';

export const useNavigateWithParams = () => {
  const { push } = useHistory();
  const existingParams = useParams();

  const navigate = (path: string, params?: Record<string, string | number>) => {
    const newPath = generatePath(path, {
      ...existingParams,
      ...(params || {}),
    });

    push(newPath);
  };

  return { navigate };
};
