import { useGetLibraryIconsQuery, useGetOrgAreaTagsQuery } from '@circadian-risk/client-graphql-hooks';
import { imageSrc } from '@circadian-risk/front-end-utils';
import {
  HStack,
  IconSelectorPopover,
  ItemIcon,
  ItemIconSizes,
  readFileSelected,
  VStack,
} from '@circadian-risk/presentational';
import { useOrganizationId } from '@circadian-risk/stores';
import LinkIcon from '@mui/icons-material/Link';
import { Box, Button, Card, Collapse, FormLabel, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { z } from 'zod';

import { AutoCompleteWithCreateOption } from '../AutoCompleteWithCreateOption';
import { FileUploadDropzone } from '../FileAttachments';
import { generateCircadianForm } from '../FormBlocks';
import { FormBox } from '../FormBox';
import { convertIconsQueryToIcons } from './iconConverter';

const libraryIconSchema = z.object({
  type: z.literal('icon-library'),
  id: z.number(),
  src: z.string().optional(),
});

const customIconSchema = z.object({
  type: z.literal('custom'),
  iconFile: z.array(z.instanceof(File)).nullable().optional(),
  src: z.string().nullable().optional(),
});

export const itemCategorySchema = z.object({
  icon: z.union([libraryIconSchema, customIconSchema]).optional(),
  name: z.string().min(1, { message: 'Required' }),
  description: z.string().optional().nullable(),
  countermeasure: z.boolean().optional().nullable(),
  asset: z.boolean().optional().nullable(),
  selectedAreaTagId: z.number().optional().nullable(),
  newAreaTagName: z.string().optional().nullable(),
});

export const itemCategoryAriaLabels = {
  name: 'Name',
  icon: 'Icon',
  description: 'Description',
  countermeasure: 'Countermeasure',
  asset: 'Asset',
  selectedAreaTagId: 'Area Tag',
  newAreaTagName: 'New Area Tag',
  submit: 'Add Item Category',
  form: 'New item category form',
};

const {
  useCircadianForm: useItemCategoryForm,
  Text,
  Checkbox,
  CircadianForm,
  GenericFormDialog: ItemCategoryFormDialog,
  SubmitButton,
} = generateCircadianForm(itemCategorySchema, itemCategoryAriaLabels);

export { useItemCategoryForm, ItemCategoryFormDialog };

export type ItemCategoryFormData = z.infer<typeof itemCategorySchema>;

export interface ItemCategoryFormProps {
  initialFormData?: ItemCategoryFormData;
  onSubmit: (formData: ItemCategoryFormData) => void;
}

type LibraryIcon = z.infer<typeof libraryIconSchema>;
type CustomIcon = z.infer<typeof customIconSchema>;

interface ItemCategoryIconPickerProps {
  icon?: LibraryIcon | CustomIcon;
  onChange: (icon: ItemCategoryIconPickerProps['icon']) => void;
  itemCategoryName?: string;
}

const ItemCategoryIconPicker: React.FC<ItemCategoryIconPickerProps> = ({ icon, onChange, itemCategoryName }) => {
  const alt = itemCategoryName ?? 'Item Category';
  const [iconSelectorAnchorEl, setIconSelectorAnchorEl] = useState<HTMLDivElement | null>(null);
  const handleIconSelectorClick = (target: HTMLDivElement) => setIconSelectorAnchorEl(target);
  const handleIconSelectorClose = () => setIconSelectorAnchorEl(null);
  const [showUploadZone, setShowUploadZone] = useState<boolean>(false);

  const libraryIconsQuery = useGetLibraryIconsQuery();
  const icons = convertIconsQueryToIcons(libraryIconsQuery.data);

  const handleFileAdded = ([addedFile]: File[]) => {
    readFileSelected(addedFile, ({ target }) => {
      if (target) {
        onChange({
          type: 'custom',
          iconFile: [addedFile],
          src: target.result as string,
        });
      }
    });
  };

  return (
    <>
      <HStack>
        <ItemIcon
          alt={alt}
          iconSrc={imageSrc(icon?.src ?? null)}
          size={ItemIconSizes.extraLarge}
          onRootClick={handleIconSelectorClick}
        />

        <div>
          <Typography ml={1}>Select an icon or...</Typography>
          <Button disabled={showUploadZone} variant="text" onClick={() => setShowUploadZone(true)}>
            Upload your own
          </Button>
        </div>
      </HStack>
      <IconSelectorPopover
        icons={icons}
        anchorEl={iconSelectorAnchorEl}
        itemCategoryName={alt}
        selectedId={icon?.type === 'icon-library' ? icon.id : undefined}
        onClose={handleIconSelectorClose}
        onIconClear={() => {
          onChange(undefined);
          handleIconSelectorClose();
        }}
        onIconSelect={iconId => {
          onChange({
            id: iconId,
            type: 'icon-library',
            src: icons.find(i => i.id === iconId)!.src,
          });
          setShowUploadZone(false);
          handleIconSelectorClose();
        }}
      />
      <Collapse in={showUploadZone}>
        <FileUploadDropzone
          onFilesAdded={handleFileAdded}
          inputProps={{
            multiple: false,
            accept: '.svg,.png',
          }}
        />
      </Collapse>
    </>
  );
};

export const ItemCategoryFormFields: React.FC = () => {
  const { control, setValue, resetField, watch } = useFormContext<ItemCategoryFormData>();
  const itemCategoryName = watch('name');
  const organizationId = useOrganizationId();
  const areaTags = useGetOrgAreaTagsQuery({
    variables: {
      orgId: organizationId,
    },
  });

  const autocompleteOptions = (areaTags.data?.area_tags ?? []).map(areaTag => ({
    label: areaTag.name,
    value: areaTag.id,
  }));

  return (
    <VStack>
      <Controller
        name="icon"
        control={control}
        render={({ field }) => (
          <ItemCategoryIconPicker icon={field.value} onChange={field.onChange} itemCategoryName={itemCategoryName} />
        )}
      />
      <Text formKey="name" />
      <Text formKey="description" />
      <Box>
        <FormLabel>Type</FormLabel>
        <HStack>
          <Checkbox formKey="asset" />
          <Checkbox formKey="countermeasure" />
        </HStack>
      </Box>
      <AutoCompleteWithCreateOption
        label="Area Tag"
        options={autocompleteOptions}
        onSelect={value => {
          setValue('selectedAreaTagId', value);
        }}
        onCreate={value => {
          setValue('newAreaTagName', value);
        }}
        onClear={() => {
          resetField('selectedAreaTagId');
          resetField('newAreaTagName');
        }}
        popupIcon={<LinkIcon color="primary" />}
        newOptionPrompt="New Area Tag"
        textFieldProps={{
          placeholder: 'Select or Add Area Tag',
        }}
      />
    </VStack>
  );
};

export const ItemCategoryForm: React.FC<ItemCategoryFormProps> = ({ initialFormData, onSubmit }) => {
  const title = `${initialFormData ? 'Edit' : 'New'} Item Category`;

  const formMethods = useItemCategoryForm({
    values: initialFormData,
  });
  const {
    formState: { isDirty },
  } = formMethods;
  const history = useHistory();
  const handleCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Card>
      <CircadianForm
        {...{
          formMethods,
          onSubmit,
        }}
      >
        <FormBox p={2}>
          <Typography variant="h5">{title}</Typography>

          <ItemCategoryFormFields />

          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <Box mr={1}>
              <Button variant="outlined" onClick={handleCancel}>
                Cancel
              </Button>
            </Box>
            <SubmitButton variant="contained" color="primary" type="submit" disabled={!isDirty} />
          </Box>
        </FormBox>
      </CircadianForm>
    </Card>
  );
};
