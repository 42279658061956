import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, SxProps, Theme, Tooltip, Typography, TypographyProps, useTheme } from '@mui/material';
import noop from 'lodash/noop';
import React, { useEffect, useState } from 'react';

import { HStack } from '../..';

type IntputControlsProps = {
  onSave: () => void;
  onCancel: () => void;
  onEdit: () => void;
  isEditing: boolean;
  saveOnBlur?: boolean;
};

const InputControls: React.FC<IntputControlsProps> = ({ isEditing, saveOnBlur, onEdit, onCancel, onSave }) => {
  if (!isEditing) {
    return (
      <IconButton size="small" onClick={onEdit}>
        <EditIcon />
      </IconButton>
    );
  }

  // when the field saves on blur, there is no need to show save / cancel controls
  if (saveOnBlur) {
    return <Box minWidth={34} />;
  }

  return (
    <>
      <Tooltip title="Save Changes" color="primary">
        <IconButton onClick={onSave} size="small">
          <CheckIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Cancel">
        <IconButton size="small" onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export interface EditableTypographyProps {
  initialTitle: string;

  /**
   * Show this if there is no title yet
   */
  placeholder?: string;

  /**
   * If true then the user can save an empty title
   */
  allowEmpty?: boolean;

  /**
   * If enabled then the editing experience will be disabled
   * @default false
   */
  isReadOnly?: boolean;

  typographyProps?: Partial<TypographyProps>;

  /**
   * If true, will save when hitting Enter key
   */
  saveOnEnter?: boolean;
  /**
   * If true, will fire onSave when user blur the input. Component will not show save and cancel buttons
   */
  saveOnBlur?: boolean;

  /**
   * Callback invoked when the user clicks the "save" icon
   */
  onSave: (newTitle: string) => void;

  /**
   * This callback is invoked if the user presses "escape" or clicks "cancel" icon
   * @description This is a useful method to revert the value to the default value
   */
  onCancel?: () => void;

  onStartEditing?: () => void;
}

/**
 * Controlled component that allows to edit a text inline and save the changes or discard them.
 *
 * Note: when `initialTitle` change, the component will exit editing mode and discard user changes.
 */
export const EditableTypography: React.FC<EditableTypographyProps> = ({
  placeholder = 'Click to edit',
  initialTitle,
  isReadOnly,
  typographyProps,
  allowEmpty,
  saveOnEnter,
  saveOnBlur,
  onSave,
  onCancel,
  onStartEditing,
}) => {
  const typographyRef = React.useRef<HTMLSpanElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const theme = useTheme();

  const moveCursorToEnd = () => {
    const span = typographyRef.current;
    const windowSelection = window.getSelection();
    try {
      windowSelection && windowSelection.collapse(span, 1);
    } catch {
      // if errors, must be an empty field so we can ignore
      return;
    }
  };

  const handleCancelEditing = () => {
    setIsEditing(false);
    onCancel && onCancel();
    if (typographyRef.current) {
      typographyRef.current.innerText = initialTitle;
      typographyRef.current.blur();
    }
  };

  const handleSave = () => {
    setIsEditing(false);
    if (typographyRef.current) {
      const value = typographyRef.current.innerText.trim();
      const isDirty = value !== initialTitle;

      if (isDirty && (value || allowEmpty)) {
        onSave(value);
        typographyRef.current.innerText = value;
        typographyRef.current.blur();
      } else {
        handleCancelEditing();
      }
    }
  };

  const handleStartEditing = () => {
    setIsEditing(true);
    onStartEditing && onStartEditing();
    if (typographyRef.current) {
      typographyRef.current.focus();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    // Cancel the editing if user presses escape
    if (event.key === 'Escape') {
      // prevent that Modals for example capture escape
      event.preventDefault();
      event.stopPropagation();
      handleCancelEditing();
    }

    if (event.key === 'Enter' && saveOnEnter) {
      handleSave();
    }
  };

  useEffect(() => {
    onCancel && onCancel();
    handleCancelEditing();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only when new initialTitle changes we want to reset the editing state
  }, [initialTitle]);

  const borderSx: SxProps<Theme> = {
    borderRadius: theme.spacing(1),
    border: '1px solid transparent',
    padding: theme.spacing(0.5, 1),
    '&:hover': {
      border: !isEditing && !isReadOnly ? `1px dashed ${theme.palette.divider}` : undefined,
      cursor: !isReadOnly ? 'text' : undefined,
    },
    ...typographyProps?.sx,
  };

  const placeholderSx: SxProps<Theme> = {
    ':empty:before': {
      content: `"${placeholder}"`,
      color: theme.palette.text.disabled,
    },
  };

  return (
    <HStack noFullWidth spacing={0.5}>
      <Typography
        component="span"
        sx={{
          ...borderSx,
          ...placeholderSx,
        }}
        ref={typographyRef}
        contentEditable={!isReadOnly}
        dangerouslySetInnerHTML={{ __html: initialTitle }}
        onClick={handleStartEditing}
        onKeyDown={handleKeyDown}
        onBlur={saveOnBlur ? handleSave : noop}
        {...typographyProps}
      />
      {!isReadOnly && (
        <InputControls
          isEditing={isEditing}
          onCancel={handleCancelEditing}
          saveOnBlur={saveOnBlur}
          onEdit={() => {
            handleStartEditing();
            moveCursorToEnd();
          }}
          onSave={handleSave}
        />
      )}
    </HStack>
  );
};
