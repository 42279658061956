import { useFuse } from '@circadian-risk/front-end-utils';
import {
  BLANK_SEARCH,
  ButtonTooltip,
  ExpandableSearch,
  HStack,
  NodeIcon,
  ResponsiveIconButton,
} from '@circadian-risk/presentational';
import { Add, Edit } from '@mui/icons-material';
import { Box, IconButton, TableContainer, Typography } from '@mui/material';
import Fuse from 'fuse.js';
import React, { useEffect } from 'react';

import { ariaLabels } from '../../ariaLabels';
import { useLocationWizardStore } from '../../locationWizard.store';
import { Table, TableProps } from '../Table';
import { NoLocationsMessage } from './NoLocationsMessage';
import { NoSearchResults } from './NoSearchResults';

const HEADER_HEIGHT = 50;

export interface LocationViewerProps {
  locationName: string;
  titleLayerName: string;
  childLayerName: string;
  childChildLayerName: string | null;
  tableData: TableProps['data'];
  canEdit: boolean;
  onClickAdd: (search: string) => void;
  onTitleEdit: () => void;
}

export const LocationViewer: React.FC<LocationViewerProps> = ({
  canEdit,
  locationName,
  titleLayerName,
  childLayerName,
  childChildLayerName,
  tableData,
  onClickAdd,
  onTitleEdit,
}) => {
  const {
    rows: filteredTableData,
    searchText,
    setSearchText,
  } = useFuse({
    list: tableData,
    options: {
      threshold: 0.3,
      keys: [{ name: 'name', getFn: loc => loc.name }] as Fuse.FuseOptionKey<TableProps['data'][number]>[],
    },
  });

  const mode = useLocationWizardStore(state => state.mode.type);

  const handleClearSearch = () => setSearchText(BLANK_SEARCH);

  const handleAddNewClick = () => onClickAdd(searchText);

  // reset search when switching locations
  useEffect(handleClearSearch, [locationName, setSearchText]);

  const disabled = childLayerName === null || !canEdit || mode !== 'viewing';
  const tooltip = disabled
    ? `You can not add child locations to a ${titleLayerName} as it is at the button of your hierarchy`
    : `Create a new ${childLayerName}`;

  const rowsToDisplay = searchText === BLANK_SEARCH ? tableData : filteredTableData;
  const noData = tableData.length === 0;

  return (
    <Box display="flex" flexDirection="column" height="inherit" maxHeight="inherit" minHeight="inherit">
      <HStack
        px={2}
        py={1}
        bgcolor={theme => theme.palette.background.default}
        sx={{ overflowX: 'auto', overflowY: 'hidden', minHeight: HEADER_HEIGHT }}
      >
        {titleLayerName && (
          <>
            <NodeIcon layerName={titleLayerName} />
            <Typography variant="h5" component="h2" noWrap sx={{ minWidth: '4ch' }}>
              {locationName}
            </Typography>
            <ButtonTooltip title={`Edit ${locationName}`}>
              <IconButton onClick={onTitleEdit} disabled={disabled} aria-label={`Edit ${locationName}`}>
                <Edit />
              </IconButton>
            </ButtonTooltip>
          </>
        )}

        <Box id="spacer" flex={1} />

        <HStack noFullWidth minWidth="fit-content">
          {!noData && (
            <ExpandableSearch size="medium" value={searchText} onChange={setSearchText} disabled={disabled} />
          )}
          <ResponsiveIconButton
            icon={<Add />}
            onClick={handleAddNewClick}
            disabled={disabled}
            iconPosition="start"
            text={ariaLabels.ADD_NEW}
            tooltip={tooltip}
          />
        </HStack>
      </HStack>
      {rowsToDisplay.length > 0 ? (
        <TableContainer>
          <Table childLayerName={childLayerName} childChildLayerName={childChildLayerName} data={rowsToDisplay} />
        </TableContainer>
      ) : noData ? (
        <NoLocationsMessage
          layer={childLayerName}
          parentLayer={titleLayerName}
          parentName={locationName}
          onAddNewClick={handleAddNewClick}
        />
      ) : (
        <NoSearchResults search={searchText} onAddNewClick={handleAddNewClick} onClear={handleClearSearch} />
      )}
    </Box>
  );
};
