import {
  CellRendererParams,
  ColDefOfType,
  DataGrid,
  TableActions,
  useGrid,
  useGridFilter,
} from '@circadian-risk/data-grid';
import { imageSrc } from '@circadian-risk/front-end-utils';
import { TooltipAvatar } from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { OrganizationRow } from '@circadian-risk/stores';
import { Box, Typography } from '@mui/material';
import { TablePage, TablePageContent } from '@web-app/layouts/TablePage';
import React from 'react';
import { NavLink } from 'react-router-dom';

const OrgNameCellRenderer = ({ data }: CellRendererParams<OrganizationRow>) => {
  return (
    <Box display={'flex'} gap={1} alignItems={'center'}>
      <TooltipAvatar sx={{ borderRadius: 1 }} alt={data.name} image={imageSrc(data.logo) ?? ''} />
      <NavLink to={ROUTES.ROOT.replace(':organizationId', data.id)}>
        <Typography variant="body2">{data.name}</Typography>
      </NavLink>
    </Box>
  );
};

const defaultColDef: ColDefOfType<OrganizationRow> = {
  sortable: true,
  enableRowGroup: true,
  enablePivot: true,
  filter: 'agSetColumnFilter',
};

const columnDefs: ColDefOfType<OrganizationRow>[] = [
  {
    field: 'name',
    headerName: 'Name',
    cellRenderer: OrgNameCellRenderer,
    valueGetter: ({ data }) => {
      return data.name;
    },
  },
  {
    field: 'id',
  },
];

export interface SwitchOrganizationTableViewProps {
  rows: OrganizationRow[];
}

export const SwitchOrganizationTableView: React.FC<SwitchOrganizationTableViewProps> = ({ rows }) => {
  const { gridApi, onGridReady, resetTable } = useGrid();
  const filterHook = useGridFilter();

  return (
    <TablePage>
      <TablePageContent>
        <TableActions gridApi={gridApi} onReset={resetTable} />
        <DataGrid
          tableWrapperProps={{ height: '100%' }}
          onFirstDataRendered={filterHook.onFirstDataRendered}
          onFilterChanged={filterHook.onFilterChanged}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowData={rows}
          getRowId={r => r.data.id}
          onGridReady={onGridReady}
          pagination={false}
          // paginationAutoPageSize
          // pagination={true}
          domLayout="autoHeight"
        />
      </TablePageContent>
    </TablePage>
  );
};
