import { createEnhancedStore } from '@circadian-risk/front-end-utils';

interface DetailsLayoutStore {
  hasLeftColumn: boolean;
  hasRightColumn: boolean;

  leftColumnOpen: boolean;
  rightColumnOpen: boolean;

  onLeftChange?: (open: boolean) => void;
  onRightChange?: (open: boolean) => void;
  openLeft: (open?: boolean) => void;
  openRight: (open?: boolean) => void;
}

export const useDetailsLayoutStore = createEnhancedStore<DetailsLayoutStore>((set, get) => ({
  hasLeftColumn: false,
  hasRightColumn: false,
  leftColumnOpen: true,
  rightColumnOpen: true,

  onLeftChange: undefined,
  onRightChange: undefined,
  openLeft: newVal => {
    const onLeftChange = get()?.onLeftChange;
    const leftColumnOpen = newVal ?? !get().leftColumnOpen;

    onLeftChange && onLeftChange(leftColumnOpen);
    set({ leftColumnOpen });
  },
  openRight: newVal => {
    const onRightChange = get()?.onRightChange;
    const rightColumnOpen = newVal ?? !get().rightColumnOpen;

    onRightChange && onRightChange(rightColumnOpen);
    set({ rightColumnOpen });
  },
}));
