import { combineSx } from '@circadian-risk/front-end-utils';
import { Box, BoxProps, useTheme } from '@mui/material';
import clsx from 'clsx';
import { useRef } from 'react';

import { useAgGridVariables } from './utils';

export const DATA_GRID_STYLES_WRAPPER_CLASS = 'ag-theme-custom-styles-wrapper';

export interface DataGridStylesProps extends BoxProps {
  id?: string;
  agVariables?: Record<string, string>;
}

/**
 * Wrapper component for DataGrid component which
 * Applies theming and full height for the table
 */
export const DataGridStyles: React.FC<DataGridStylesProps> = ({
  id,
  children,
  className,
  agVariables,
  ...boxProps
}) => {
  const wrapperRef = useRef<HTMLElement>();
  const theme = useTheme();
  const agTableModeClass = theme.palette.mode === 'dark' ? 'ag-theme-alpine-dark' : 'ag-theme-material';

  useAgGridVariables(
    {
      '--ag-foreground-color': theme.palette.text.primary,
      '--ag-background-color': theme.palette.background.paper,
      '--ag-header-background-color': theme.palette.background.paper,
      '--ag-odd-row-background-color': theme.palette.background.paper,
      '--ag-control-panel-background-color': theme.palette.background.paper,
      '--ag-border-color': theme.palette.divider,
      ...agVariables,
    },
    wrapperRef,
  );

  const agSx: BoxProps['sx'] = {
    height: '100%',
    maxWidth: `calc(100vw - ${theme.spacing(4)})`, // iOS fix
    '& .ag-react-container': {
      height: '100% !important',
      alignItems: 'center',
      display: 'flex',
    },
  };

  return (
    <Box
      {...boxProps}
      id={id}
      flex={1}
      ref={wrapperRef}
      className={clsx(className, agTableModeClass, DATA_GRID_STYLES_WRAPPER_CLASS)}
      sx={combineSx(agSx, boxProps.sx)}
    >
      {children}
    </Box>
  );
};
