import { Typography, TypographyProps } from '@mui/material';
import isString from 'lodash/isString';
import React from 'react';

export const ConditionallyWrappedTypography: React.FC<TypographyProps> = ({ children, ...typographyProps }) => {
  return isString(children) ? (
    <Typography component="span" align="left" {...typographyProps}>
      {children}
    </Typography>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};
