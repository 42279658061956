import RecommendSharpIcon from '@mui/icons-material/RecommendSharp';
import { useTheme } from '@mui/material';
import React from 'react';

import { OptionForConsiderationFlair } from '../../OptionForConsideration';

export const SuggestedFlair: React.FC = () => {
  const theme = useTheme();
  return (
    <OptionForConsiderationFlair
      id="Suggested"
      Icon={RecommendSharpIcon}
      color={theme.palette.info.main}
      tooltip="This option was used by the assessor during the assessment."
      text="Suggested"
    />
  );
};
