import { createEnhancedStore, FCC } from '@circadian-risk/front-end-utils';
import { createContext, useContext } from 'react';
import { useStore } from 'zustand';

interface LayoutInterface {
  drawersVisibility: {
    [key: string]: boolean;
  };
  setDrawersVisibility: (key: string, value?: boolean) => void;
}

const layoutStore = createEnhancedStore<LayoutInterface>((set, get) => ({
  drawersVisibility: {},
  setDrawersVisibility: (key, value = !get().drawersVisibility[key] /** default to next toggle value */) => {
    set({ drawersVisibility: { ...get().drawersVisibility, [key]: value } });
  },
}));

export const LayoutContext = createContext<typeof layoutStore | null>(null);

export const LayoutStateProvider: FCC = ({ children }) => {
  return <LayoutContext.Provider value={layoutStore}>{children}</LayoutContext.Provider>;
};

export const useLayoutContext = () => {
  const layoutStore = useContext(LayoutContext);
  if (!layoutStore) {
    throw new Error('useLayoutContext must be used within a LayoutContextProvider');
  }

  return layoutStore;
};

export const useIsDrawerVisible = (key: string) => {
  return useStore(useLayoutContext(), state => state.drawersVisibility[key]);
};

export const useSetDrawerVisibility = () => {
  return useStore(useLayoutContext(), state => state.setDrawersVisibility);
};
