import { VStack } from '@circadian-risk/presentational';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Link, Typography } from '@mui/material';
import React from 'react';

export interface NoSearchResultsProps {
  search: string;
  onClear: () => void;
  onAddNewClick: () => void;
}

export const NoSearchResults: React.FC<NoSearchResultsProps> = ({ search, onClear, onAddNewClick }) => {
  return (
    <VStack justifyContent="center" alignItems="center" minHeight="inherit" height="inherit" maxHeight="inherit">
      <SearchIcon sx={{ fontSize: 96 }} fontSize="inherit" color="disabled" />
      <Typography variant="body2" color={theme => theme.palette.text.disabled} align="center">
        No locations match your search criteria.{' '}
        <Link sx={{ cursor: 'pointer' }} onClick={onClear} underline="hover">
          Clear search
        </Link>
      </Typography>
      <Button startIcon={<AddIcon />} variant="contained" onClick={onAddNewClick}>
        Create "{search}"
      </Button>
    </VStack>
  );
};
