import { LAYER_TAG_ALL } from '@circadian-risk/data-grid';
import { GlobalOrganizationContextNode, useOrganizationSessionStore } from '@circadian-risk/stores';
import { useMemo } from 'react';

import {
  LocationHierarchyPicker,
  LocationHierarchyPickerProps,
  LocationPickerOption,
} from '../../LocationHierarchyPicker';

/** These props will be filled in for you using what is available in the session store */
type OptionalPropKeys = 'options' | 'allowedNodeIds';
type OptionsProps = Pick<LocationHierarchyPickerProps, OptionalPropKeys>;

export type SmartLocationPickerProps = Omit<LocationHierarchyPickerProps, OptionalPropKeys> &
  Partial<OptionsProps> & {
    nodeFilter?: (node: GlobalOrganizationContextNode) => boolean;
  };

export const SmartLocationPicker: React.FC<SmartLocationPickerProps> = ({ nodeFilter, ...locationPickerProps }) => {
  const { nodes, nodesById, layersById, allowedNodeIds } = useOrganizationSessionStore(
    ({ nodes, nodesById, layersById, allowedNodeIds }) => ({
      nodes,
      nodesById,
      layersById,
      allowedNodeIds,
    }),
  );

  const actualAllowedNodes = useMemo(() => {
    if (!nodeFilter) {
      return allowedNodeIds;
    }
    return allowedNodeIds.filter(id => nodeFilter(nodesById[id]));
  }, [allowedNodeIds, nodeFilter, nodesById]);

  const options: LocationPickerOption[] = useMemo(() => {
    return nodes.map<LocationPickerOption>(n => ({
      id: n.id,
      layerTags: n.tags.filter(t => t.type_name !== LAYER_TAG_ALL).map(t => t.type_name),
      mpath: n.mpath,
      ordinal: n.ordinal,
      parentId: n.parent_id,
      name: n.name,
      layerName: layersById[n.layer_id].name,
      layerId: n.layer_id,
    }));
  }, [layersById, nodes]);

  return <LocationHierarchyPicker options={options} allowedNodeIds={actualAllowedNodes} {...locationPickerProps} />;
};
