import { makeFieldDiffPlugin } from '@circadian-risk/rxdb-utils';
import { addRxPlugin, createRxDatabase, RxDatabase } from 'rxdb';
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';
import { getRxStorageDexie } from 'rxdb/plugins/dexie';
import { getRxStorageMemory } from 'rxdb/plugins/memory';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration';
// import { addPouchPlugin, getRxStoragePouch } from 'rxdb/plugins/pouchdb';
// import pouchDbAdapterIdb from 'pouchdb-adapter-idb';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import { RxDBReplicationGraphQLPlugin } from 'rxdb/plugins/replication-graphql';
import { RxDBUpdatePlugin } from 'rxdb/plugins/update';

import { AssessmentItemCollection, assessmentItemSchema } from './schema';

addRxPlugin(RxDBReplicationGraphQLPlugin);
addRxPlugin(RxDBUpdatePlugin);
addRxPlugin(RxDBMigrationPlugin);
addRxPlugin(RxDBQueryBuilderPlugin);

const isInDevelopmentMode = () => process.env.NODE_ENV === 'development';

if (isInDevelopmentMode()) {
  try {
    addRxPlugin(RxDBDevModePlugin);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
}

export const UPDATED_FIELDS_STORAGE_KEY = 'updatedFields';
addRxPlugin(makeFieldDiffPlugin(UPDATED_FIELDS_STORAGE_KEY));

export type DatabaseCollections = {
  assessment_items: AssessmentItemCollection;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Database = RxDatabase<DatabaseCollections, any, any>;

export const createDatabaseForUser = async (userId: string, organizationId: string): Promise<Database> => {
  const databaseName = `ember_db_${userId}_${organizationId}`;
  const storage = process.env.NODE_ENV === 'test' ? getRxStorageMemory() : getRxStorageDexie();
  const db = await createRxDatabase<DatabaseCollections>({
    name: databaseName,
    storage,
    ignoreDuplicate: true,
  });

  await db.addCollections({
    assessment_items: {
      schema: assessmentItemSchema,
      migrationStrategies: {
        1: oldDoc => {
          oldDoc.assessment_id = oldDoc.scenario_assessment_id;
          delete oldDoc.scenario_assessment_id;

          return oldDoc;
        },
      },
    },
  });

  if (isInDevelopmentMode()) {
    // @ts-ignore
    window.rxdb = db;
  }

  return db;
};
