import { CreateAssessmentFormStepperConfig } from '../../stepsConfig';
import { UsersStep } from './UsersStep';

export const usersStepConfig: CreateAssessmentFormStepperConfig = {
  title: 'Assign Users',
  stepper: {
    titleOverride: 'Users',
  },
  component: UsersStep,
};
