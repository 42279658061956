import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import { fileSchema } from './shared-schemas/file.schema';

const c = initContract();
export const nodeMapboxContract = c.router(
  {
    uploadMap: {
      method: 'POST',
      path: '/:organizationId/:nodeId/upload-map',
      description: 'Upload Mapbox File (PDF or JPG)',
      contentType: 'multipart/form-data',
      pathParams: z.object({
        organizationId: z.string().uuid(),
        nodeId: z.string().uuid(),
      }),
      body: z.object({
        file: z.custom<File>(),
        filename: z.string().min(0),
      }),
      responses: {
        201: z.object({
          workflowId: z.string(),
          file: fileSchema,
        }),
      },
    },
  },
  {
    pathPrefix: '/node-maps',
  },
);
