import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';

import { config } from '../../../environments/environment';

const Attribution: React.FC = () => {
  return (
    <Typography
      component="footer"
      sx={theme => ({
        borderTop: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(1),
        textAlign: 'center',
      })}
    >
      <Box display={'flex'} flexDirection={'column'}>
        <div>Circadian Risk Inc. &copy; {dayjs().format('YYYY')}</div>
        <div>version: {config.APP_VERSION}</div>
      </Box>
    </Typography>
  );
};

export default Attribution;
