import { Box, TextField } from '@mui/material';
import isNil from 'lodash/isNil';
import { useState } from 'react';

import { VStack } from '../../../..';
import { clearOptionSelection, selectAllOptions, updateOptionState } from '../../filter.helpers';
import { FilterOption } from '../../filter.types';
import { filterGroupAriaLabels, FilterGroupProps } from '../../FilterGroup';
import { FilterOptionsAggregatesRef } from '../../hooks';
import { useFilterNavigation } from '../../hooks/useFilterNavigation';
import { SEARCH_ID } from '../constants';
import { FilterDropdownPopover } from '../FilterDropdownPopover';
import { FilterDropdownList } from './FilterDropdownList';
import { useFilterDropdownData } from './useFilterDropdownData';

export interface FilterDropdownProps extends Pick<FilterGroupProps, 'onSave'> {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  options: FilterOption[];
  defaultSelectedOption?: string;
  aggregates: FilterOptionsAggregatesRef;
}

export const FilterDropdown: React.FC<FilterDropdownProps> = ({
  onClose,
  anchorEl,
  options: unfilteredOptions,
  onSave,
  defaultSelectedOption,
  aggregates: aggregatesRef,
}) => {
  // Holds the dirty parent value, once the user chooses something we should keep this
  // "selected" parent
  const [dirtyParentValue, setDirtyParentValue] = useState<string | null>(null);
  const {
    childrenOptionsVisible,
    childrenRows,
    masterRows,
    searchPlaceholder,
    searchText,
    setSearchText,
    selectedOptionIndex,
    setSelectedOptionIndex,
  } = useFilterDropdownData({
    filterOptions: unfilteredOptions,
    aggregatesRef,
    defaultSelectedOption,
    dirtyParentValue,
  });

  const getAggregatesCount = (filterKey: string): number => {
    if (childrenOptionsVisible) {
      return aggregatesRef.current[filterKey];
    }

    const filterOption = masterRows.find(e => e.value === filterKey);
    if (!filterOption) {
      return 0;
    }

    return filterOption.children.filter(c => {
      const count = aggregatesRef.current[c.value] ?? 0;
      return count > 0;
    }).length;
  };

  const { index: navigationIndex, setIndex } = useFilterNavigation(
    childrenOptionsVisible ? childrenRows.length : masterRows.length,
  );

  const save = (parentValue: string, childrenValue: string) => {
    setDirtyParentValue(parentValue);
    onSave(
      updateOptionState({
        options: unfilteredOptions,
        parentValue,
        childrenValue,
      }),
    );
  };

  const handleToggleAllClick = () => {
    if (childrenOptionsVisible && !isNil(selectedOptionIndex)) {
      const parent = masterRows[selectedOptionIndex];
      const hasSomeSelected = childrenRows.some(o => o.selected);
      const args = { options: unfilteredOptions, parentValue: parent.value };

      // TODO(backlog)[CR-2093]
      setDirtyParentValue(parent.value);
      onSave(hasSomeSelected ? clearOptionSelection({ ...args, preventClosing: true }) : selectAllOptions(args));
    }
  };

  const onOptionClick = (filterValue: string, shouldClose: boolean) => {
    if (!childrenOptionsVisible) {
      const filterIndex = masterRows.findIndex(e => e.value === filterValue);
      setSelectedOptionIndex(filterIndex);
    } else {
      if (shouldClose) {
        onClose();
      }
      if (!isNil(selectedOptionIndex)) {
        const parent = masterRows[selectedOptionIndex];
        save(parent.value, filterValue);
      }
    }
  };

  return (
    <FilterDropdownPopover open anchorEl={anchorEl} onClose={onClose} PaperProps={{ sx: { borderRadius: 2 } }}>
      <Box aria-label={filterGroupAriaLabels.FilterDropdown} p={1} display="flex" flexDirection="column" minWidth={200}>
        <VStack>
          <TextField
            id={SEARCH_ID}
            InputProps={{ sx: { px: 1 } }}
            autoComplete={'off'}
            autoFocus
            fullWidth
            aria-label={filterGroupAriaLabels.SearchInput}
            placeholder={searchPlaceholder}
            size="small"
            variant="standard"
            value={searchText}
            onChange={({ target: { value } }) => setSearchText(value)}
          />
          <FilterDropdownList
            setNavIndex={setIndex}
            onSelectAllNoneClick={handleToggleAllClick}
            displayCheckbox={childrenOptionsVisible}
            hideAggregates={!childrenOptionsVisible}
            rows={childrenOptionsVisible ? childrenRows : masterRows}
            onOptionClick={onOptionClick}
            navigationIndex={navigationIndex}
            getAggregatesCount={getAggregatesCount}
          />
        </VStack>
      </Box>
    </FilterDropdownPopover>
  );
};
