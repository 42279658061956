import { PieChartProps, useAgChartStyles } from '@circadian-risk/presentational';
import { AssessmentState } from '@circadian-risk/shared';
import { useTheme } from '@mui/material';
import { AgChartOptions, AgPieSeriesOptions } from 'ag-charts-community';
import { AgChartsReact } from 'ag-charts-react';
import startCase from 'lodash/startCase';
import { useLatest } from 'react-use';

export interface AssessmentsChartProps {
  // key = status, value = count
  aggregates: Record<string, number>;
  onChartOptionClick: (state: string) => void;
}

const INNER_RADIUS_OFFSET = -30;

export type TooltipContentCallback = (value: number) => string | string;

const validStatus = ['NotStarted', 'InProgress', 'Complete'];

export const AssessmentsChart: React.FC<AssessmentsChartProps> = ({ aggregates, onChartOptionClick }) => {
  const theme = useTheme();
  const { palette } = theme;
  const onClickReffed = useLatest(onChartOptionClick);
  const { highlightStyle, agChartTheme } = useAgChartStyles();

  const colorMap: { [key in AssessmentState]: string } = {
    Abandoned: palette.error.dark,
    Blocked: palette.error.main,
    Complete: palette.auxColor,
    InProgress: palette.primary.light,
    InReview: palette.primary.light,
    NotStarted: palette.action.disabled,
    Locked: palette.action.disabled,
  };

  const data: PieChartProps['data'] = Object.keys(AssessmentState)
    .filter(state => validStatus.includes(state))
    .map(state => {
      const stateKey = state as AssessmentState;
      const count = aggregates[state];

      return {
        key: stateKey,
        label: startCase(stateKey),
        value: count,
        color: colorMap[stateKey],
      };
    });

  const handleClick = (key: string) => {
    if (onClickReffed.current) {
      onClickReffed.current(key);
    }
  };

  const tooltipContent = (val: number) => `${val} assessment(s)`;

  const pieSeriesOptions: AgPieSeriesOptions = {
    type: 'pie',
    angleKey: 'value',
    strokes: [theme.palette.action.hover],
    highlightStyle,
    innerRadiusOffset: INNER_RADIUS_OFFSET,
    cursor: 'pointer',
    fills: data.map(x => x.color),
    legendItemKey: 'label',
    tooltip: {
      renderer: params => {
        const datum: PieChartProps['data'][0] = params.datum;
        return {
          content: tooltipContent(+datum.value.toFixed(0)),
          title: datum.label,
        };
      },
    },
    listeners: {
      nodeClick: (props: { datum: PieChartProps['data'][0] }) => handleClick(props.datum.key),
    },
  };

  const options: AgChartOptions = {
    data,
    theme: agChartTheme,
    series: [pieSeriesOptions],
    padding: {
      bottom: 20,
    },
    legend: {
      position: 'bottom',
    },
  };

  return <AgChartsReact options={options} />;
};
