import { ROUTES } from '@circadian-risk/routes';
import { USER_ROLE } from '@circadian-risk/shared';
import { lazyLoadWithVersionCheck } from '@web-app/router/lazyLoadWithVersionCheck';
import React from 'react';

const ScenarioAssessmentTemplateDetailPage = lazyLoadWithVersionCheck(
  () => import('./ScenarioAssessmentTemplateDetailPage'),
);
const ScenarioAssessmentTemplatePage = lazyLoadWithVersionCheck(() => import('./ScenarioAssessmentTemplatePage'));
const ScenarioAssessmentTemplateSummaryTablePage = lazyLoadWithVersionCheck(
  () => import('./ScenarioAssessmentTemplateSummaryTablePage'),
);

export const scenarioAssessmentTemplateRoutes = () => [
  {
    path: ROUTES.ASSESSMENT_TEMPLATES,
    component: () => <ScenarioAssessmentTemplateSummaryTablePage />,
    pageTitle: 'Assessment Templates',
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN],
  },
  {
    path: ROUTES.SCENARIO_ASSESSMENT_TEMPLATES_NEW,
    component: ScenarioAssessmentTemplatePage,
    isExact: true,
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN],
  },
  {
    path: ROUTES.SCENARIO_ASSESSMENT_TEMPLATES_DETAIL,
    component: ScenarioAssessmentTemplateDetailPage,
    isExact: true,
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN],
  },
];
