import React, { createContext, useContext, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';

import { AriaLabels } from './CircadianForm';

export interface CircadianFormContextProps<FormData> {
  disabled?: boolean;
  ariaLabels: AriaLabels<FormData>;
}

export const CircadianFormContext = createContext<CircadianFormContextProps<FieldValues>>({
  disabled: false,
  ariaLabels: { form: '', submit: '' },
});

export const useCircadianFormContext = <FormData,>() => {
  const context = useContext<CircadianFormContextProps<FormData>>(
    CircadianFormContext as unknown as React.Context<CircadianFormContextProps<FormData>>,
  );
  if (!context) {
    throw new Error('useCircadianFormContext must be used under CircadianFormContextProvider');
  }
  return context;
};

export const CircadianFormContextProvider = <FormData,>({
  children,
  ...props
}: CircadianFormContextProps<FormData> & { children: React.ReactNode }) => {
  const value = useMemo(() => ({ ...props }), [props]);
  return <CircadianFormContext.Provider value={value}>{children}</CircadianFormContext.Provider>;
};
