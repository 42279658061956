import { useApiClient } from '@circadian-risk/api-client-provider';
import { useNotification } from '@circadian-risk/front-end-utils';
import { useOrganizationId } from '@circadian-risk/stores';

import { ItemCategoryFormData } from './ItemCategoryForm';

export const useItemCategoryIconMutations = () => {
  const organizationId = useOrganizationId();
  const { displayError } = useNotification();
  const { tsRestClient } = useApiClient();

  const handleClear = async (id: string) => {
    try {
      return await tsRestClient.itemCategories.clearIcon({
        params: {
          organizationId,
          id,
        },
        body: undefined,
      });
    } catch (ex) {
      displayError(ex);
    }

    return;
  };

  const handleUpdate = async (id: string, icon: ItemCategoryFormData['icon']) => {
    // if icon not provided, skip mutation
    if (icon === undefined) {
      return;
      // if icon is null, clear the icon
    } else if (icon === null) {
      await handleClear(id);
      return;
    }

    try {
      // handle different types of icons
      switch (icon?.type) {
        case 'custom': {
          const customIconFile = icon.iconFile;
          if (customIconFile) {
            const response = await tsRestClient.itemCategories.imageUpload({
              params: {
                organizationId,
                id,
              },
              body: {
                image: customIconFile[0],
              },
            });
            if (response.status === 201) {
              return response.body;
            }
          }
          break;
        }
        case 'icon-library': {
          await tsRestClient.itemCategories.updateIcon({
            params: {
              organizationId,
              id,
            },
            body: {
              iconId: icon.id,
            },
          });
          break;
        }

        default:
          return;
      }
    } catch (ex) {
      displayError(ex);
    }

    return;
  };

  return { updateIcon: handleUpdate, clearIcon: handleClear };
};
