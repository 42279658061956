import Fuse from 'fuse.js';
import { useMemo, useState } from 'react';
import { useDebounce } from 'react-use';

export type ChainableFilter<T> = (rows: T[]) => T[];

const BLANK_SEARCH = '';

export interface UseFuseResult<T> {
  /**
   * Filtered rows
   */
  rows: T[];
  searchText: string;
  setSearchText: (value: string) => void;
  fuse: Fuse<T>;
}

export interface UseFuseProps<T> {
  list: T[];
  options: Fuse.IFuseOptions<T>;
  /**
   * this filters will be applied after the rows get filtered
   * @description If we have 3 functions, the order will be respected
   * @example [func1, func2, func3] -> [func2 uses func1 result and func3 uses func2 result]
   */
  chainableFilters?: ChainableFilter<T>[];
  /**
   * @default 250
   */
  searchDebounce?: number;
}

export const useFuse = <T,>({
  list,
  options,
  searchDebounce = 250,
  chainableFilters,
}: UseFuseProps<T>): UseFuseResult<T> => {
  const [searchText, setSearchText] = useState(BLANK_SEARCH);
  const [filteredRows, setFilteredRows] = useState<T[]>(list);

  const fuse = useMemo(() => new Fuse(list, options), [list, options]);

  useDebounce(
    () => {
      const searchResults = searchText ? fuse.search(searchText).map(o => o.item) : list;
      setFilteredRows(searchResults);
    },
    searchDebounce,
    [searchText, list],
  );

  const rows = useMemo(() => {
    let finalRows = [...filteredRows];
    if (chainableFilters && chainableFilters.length > 0) {
      chainableFilters.forEach(cf => {
        finalRows = cf(finalRows);
      });
    }
    return finalRows;
  }, [chainableFilters, filteredRows]);

  return { rows, searchText, setSearchText, fuse };
};
