import { FirstDataRenderedEvent, GridApi } from '@ag-grid-community/core';
import { useCallback, useEffect } from 'react';

/**
 * Utility hook to provide a controlled api for AGGrid selection apis
 * Your grid must implement the getRowId function
 *
 * @param gridApi
 * @param selectedIds Array of string ids. Note that
 * @param onSelect Callback function whenever a row is selected. You should update selectedIds with this result
 */
export const useControlledGridSelection = <T,>(
  gridApi: GridApi | null,
  selectedIds: string[],
  onSelect?: (rowNode: T[]) => void,
) => {
  const syncSelection = useCallback(
    (selectedIds?: string[] | null) => {
      let safeSelectedIds: string[];
      if (!selectedIds) {
        safeSelectedIds = [];
      } else {
        safeSelectedIds = selectedIds;
      }
      if (gridApi) {
        gridApi.forEachNode(node => {
          if (node?.id) {
            safeSelectedIds.includes(node.id) ? node.setSelected(true) : node.setSelected(false);
          }
        });
      }
    },
    [gridApi],
  );

  useEffect(() => {
    syncSelection(selectedIds);
  }, [selectedIds, syncSelection]);

  const onFirstDataRendered = (_e: FirstDataRenderedEvent) => {
    syncSelection(selectedIds);
  };

  const onSelectionChanged = useCallback(() => {
    if (gridApi) {
      const selectedRows = gridApi.getSelectedRows();
      if (onSelect) {
        onSelect(selectedRows);
      }
    }
  }, [gridApi, onSelect]);

  const onRowDataUpdated = useCallback(() => {
    syncSelection(selectedIds);
  }, [selectedIds, syncSelection]);

  return {
    onFirstDataRendered,
    onSelectionChanged,
    onRowDataUpdated,
  };
};
