import { AlignCell, CellRendererParams } from '@circadian-risk/data-grid';
import { BoxProps } from '@mui/material';
import { SmartLocationLink } from '@web-app/smart-components/SmartLocationLink';
import omit from 'lodash/omit';

import { SpanErrorBoundary } from '../SpanErrorBoundary';
import { LocationLink, LocationLinkProps } from '.';

export interface LocationLinkRendererProps extends LocationLinkProps {
  /** @default flex-end */
  justifyContent?: BoxProps['justifyContent'];
}
export const LocationLinkRenderer = (props: LocationLinkRendererProps) => {
  return (
    <AlignCell justifyContent={props.justifyContent ?? 'flex-end'} mr={1}>
      <SpanErrorBoundary>
        <LocationLink {...omit(props, 'justifyContent')} />
      </SpanErrorBoundary>
    </AlignCell>
  );
};

export const SmartLocationLinkRenderer: React.FC<{
  data: { nodeId: string };
  justifyContent?: BoxProps['justifyContent'];
  includeBreadCrumbs?: boolean;
  disabled?: boolean;
}> = ({ data, includeBreadCrumbs, justifyContent = 'flex-start', disabled }) => {
  const { nodeId } = data;

  return (
    <AlignCell justifyContent={justifyContent} mr={1}>
      <SpanErrorBoundary>
        <SmartLocationLink nodeId={nodeId} includeBreadCrumbs={includeBreadCrumbs} disabled={disabled} />
      </SpanErrorBoundary>
    </AlignCell>
  );
};

type GroupableLocationLinkRendererData = {
  location_name: string;
  nodeId: string;
  layerName?: string;
  breadCrumbs?: { layerName: string; locationName: string }[];
};

/**
 * A location link cell renderer that is compatible with Ag Grid row grouping
 * Contains custom logic to ensure that the location link is shown properly in both
 * grouped and ungrouped scenarios
 *
 * @param params
 */
export const GroupableLocationLinkRenderer: React.FC<
  CellRendererParams<GroupableLocationLinkRendererData>
> = params => {
  const { data, node, column, value } = params;
  // If this column is intended for use with an aggregation function we need to short-circuit the
  // cell renderer in order to be able to properly see the results of the aggregate calculation
  // The 'aggregationActive' property helps us here but is marked as private so we need to ignore the error
  // @ts-expect-error
  if (column?.aggregationActive) {
    return value?.value ?? '';
  }

  const cellData: GroupableLocationLinkRendererData =
    node.group && node.field === 'nodeId' ? node.allLeafChildren[0].data : data;

  const { location_name, ...rest } = cellData ?? {};

  return (
    <AlignCell justifyContent={'flex-start'} mr={1}>
      <SpanErrorBoundary>
        <LocationLink data={{ ...rest }} value={location_name} />
      </SpanErrorBoundary>
    </AlignCell>
  );
};
