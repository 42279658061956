import { TypesafeCollection } from '@circadian-risk/rxdb-utils';
import { RxDocument, RxJsonSchema } from 'rxdb';

export type AssessmentItem = {
  id: string;
  assessment_id: string;
  node_id: string;
  name: string | null;
  coordinates: object | null;
  countermeasure: boolean | null;
  is_present: boolean | null;
  item_category_id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties: Record<string, any> | null;
  is_interview_item: boolean;
  updated_at: string;
  deleted_at: string | null;
  created_at: string;
  exterior: boolean | null;
  description: string | null;
  recommendation: string | null;
  recommendation_info: string | null;
  location_description: string | null;
  updatedFields: (keyof AssessmentItem)[];
};

export type AssessmentItemDocument = RxDocument<AssessmentItem>;

export type AssessmentItemCollection = TypesafeCollection<AssessmentItem>;

export const assessmentItemSchema: RxJsonSchema<AssessmentItem> = {
  title: 'assessment item schema',
  version: 1,
  keyCompression: true,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 36,
    },
    assessment_id: {
      type: 'string',
    },
    node_id: {
      type: 'string',
    },
    name: {
      type: ['string', 'null'],
    },
    coordinates: {
      type: ['object', 'null'],
    },
    countermeasure: {
      type: ['boolean', 'null'],
    },
    is_present: {
      type: 'boolean',
    },
    item_category_id: {
      type: 'string',
    },
    properties: {
      type: ['object', 'null'],
    },
    is_interview_item: {
      type: 'boolean',
    },
    updated_at: {
      type: 'string',
      maxLength: 20,
    },
    deleted_at: {
      type: ['string', 'null'],
    },
    created_at: {
      type: 'string',
    },
    exterior: {
      type: ['boolean', 'null'],
    },
    description: {
      type: ['string', 'null'],
    },
    recommendation: {
      type: ['string', 'null'],
    },
    recommendation_info: {
      type: ['string', 'null'],
    },
    location_description: {
      type: ['string', 'null'],
    },
    updatedFields: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
  },
  indexes: ['updated_at'],
};
