import { useState } from 'react';
import { useUpdateEffect } from 'react-use';

/**
 * useLatestState: useState with value that is resets when the updatedValue value changes, similar to useLatest but for useState instead of useRef
 * @param updatedValue
 */

export const useLatestState = <Value>(updatedValue: Value | (() => Value)) => {
  const [value, setValue] = useState(updatedValue);

  useUpdateEffect(() => {
    setValue(updatedValue);
  }, [updatedValue]);

  return [value, setValue] as const;
};
