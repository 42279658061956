import { useConfirmationDialog } from '@circadian-risk/presentational';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { alpha, Box, Button, ButtonGroup, Typography, useTheme } from '@mui/material';
import React, { useCallback } from 'react';

export interface ItemIsPresentToggleProps {
  isPresent: boolean | null;
  isPresentLabel?: string;
  isNotPresentLabel?: string;
  setIsPresent: (isPresent: boolean) => void;
  isPresentGlobally: boolean | null; // null when global item does not exist
  disabled?: boolean;
  isRequired?: boolean;
  disableConfirmation?: boolean;
}
export const ItemIsPresentToggle: React.FC<ItemIsPresentToggleProps> = ({
  isPresent,
  isPresentLabel,
  isNotPresentLabel,
  setIsPresent,
  isPresentGlobally,
  disabled,
  isRequired = true,
  disableConfirmation,
}) => {
  const { getConfirmation } = useConfirmationDialog();
  const theme = useTheme();

  const getButtonStyles = (color: string, isActive: boolean) => ({
    flexBasis: '175px',
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: alpha(color, 0.4),
    },
    ...(isActive
      ? {
          backgroundColor: color,
          color: theme.palette.getContrastText(color),
        }
      : {}),
  });

  const handlePresentClick = useCallback(() => {
    setIsPresent(true);
  }, [setIsPresent]);

  const handleNotPresentClick = useCallback(async () => {
    if (!disableConfirmation) {
      const confirmed = await getConfirmation({
        title: 'Are you sure you want to mark this item as "Not Present"?',
        message: (
          <Box display="flex" flexDirection="column" gap={theme.spacing(1)}>
            <Box>
              <Typography>
                Setting this item as Not Present will overwrite its current responses and descriptions with the
                assessment to:
              </Typography>
              <ul>
                <li>
                  <Typography>N/A (if the question is optional)</Typography>
                </li>
                <li>
                  <Typography>Deficient (if the question is required)</Typography>
                </li>
              </ul>
            </Box>
            <Typography>Would you like to continue this operation?</Typography>
          </Box>
        ),
        confirmButton: {
          text: 'Set as Not Present',
          style: {
            color: theme.palette.error.main,
          },
        },
      });
      if (!confirmed) {
        return;
      }
    }

    setIsPresent(false);
    // @sandasai can you fix this eslint warning?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getConfirmation, isPresentGlobally, setIsPresent, disableConfirmation]);

  return (
    <ButtonGroup disabled={disabled}>
      <Button
        sx={getButtonStyles(theme.palette.organizationColors.compliance.compliant, isPresent === true)}
        onClick={handlePresentClick}
        aria-label="present"
      >
        <CheckIcon sx={{ marginRight: theme.spacing(1) }} />
        {isPresentLabel ?? 'Present'}
      </Button>
      <Button
        color="secondary"
        sx={{
          ...(!isRequired
            ? getButtonStyles(theme.palette.organizationColors.compliance.unknown, isPresent === false)
            : getButtonStyles(theme.palette.organizationColors.compliance.deficient, isPresent === false)),
        }}
        onClick={handleNotPresentClick}
        aria-label="not present"
      >
        <CloseIcon sx={{ marginRight: theme.spacing(1) }} />
        {isNotPresentLabel ?? 'Not Present'}
      </Button>
    </ButtonGroup>
  );
};
