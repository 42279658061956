import { AssessmentTypeIcon, OpenInNewTabLink } from '@circadian-risk/presentational';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import {
  IconButton,
  IconButtonProps,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  PopoverProps,
} from '@mui/material';
import React, { useRef, useState } from 'react';

type Props = {
  iconButtonProps?: Partial<Omit<IconButtonProps, 'ref' | 'onClick'>>;
  popoverProps?: Partial<Omit<PopoverProps, 'open' | 'anchorEl' | 'onClose'>>;
};
export const HelpPopoverButton: React.FC<Props> = ({ iconButtonProps, popoverProps }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const handleClick = () => {
    setOpen(prev => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton sx={{ borderRadius: 0 }} ref={ref} onClick={handleClick} {...iconButtonProps}>
        <HelpCenterIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={ref.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        {...popoverProps}
      >
        <List>
          <ListItem>
            <ListItemIcon>
              <AssessmentTypeIcon type="Inspection Questionnaire" />
            </ListItemIcon>
            <ListItemText>
              <OpenInNewTabLink
                link="https://circadianrisk.atlassian.net/wiki/spaces/CS2/pages/785219606/Conducting+an+Assessment+-+Inspection+Questionnaire"
                text="How to conduct an Inspection Questionnaire"
              />
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <AssessmentTypeIcon type="Physical Inspection" />
            </ListItemIcon>
            <OpenInNewTabLink
              link="https://circadianrisk.atlassian.net/wiki/spaces/CS2/pages/824246299/Conducting+an+Assessment+-+Physical+Inspection"
              text="How to conduct a Physical Inspection"
            />
          </ListItem>
        </List>
      </Popover>
    </>
  );
};
