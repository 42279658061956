import { ProgressButton } from '@circadian-risk/presentational';
import { Box } from '@mui/material';
import React from 'react';

import { SmartLocationLink } from '../SmartLocationLink';
import { LocationTreeNode, PROGRESS_WIDTH } from './LocationTree';

export const LocationTreeNodeLabel: React.FC<LocationTreeNode> = props => {
  const {
    name,
    inspectionQuestionnaireProgress,
    physicalInspectionProgress,
    id: labelNodeId,
    inspectionEnabled,
    interviewEnabled,
    inspectionUrl,
    questionnaireUrl,
    disabledChips,
    sx,
  } = props;

  const selectable = true;

  return (
    <Box
      p={1}
      display="flex"
      flexDirection="row"
      alignItems="center"
      sx={[
        theme => ({
          '&:hover': {},
          transition: theme.transitions.create('background-color', {
            duration: theme.transitions.duration.shorter,
          }),
          // Apply non selectable element styles
          ...(!selectable ? { cursor: 'not-allowed', opacity: 0.4 } : {}),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      role="group"
      aria-label={`${name} info`}
      justifyContent="flex-start"
    >
      <Box display="flex" flex={1}>
        <SmartLocationLink nodeId={labelNodeId} />
      </Box>

      {selectable && (
        <>
          <Box width={PROGRESS_WIDTH} textAlign="center" flexBasis={PROGRESS_WIDTH} flexShrink={0} flexGrow={0}>
            {interviewEnabled && (
              <ProgressButton
                percentComplete={inspectionQuestionnaireProgress ?? null}
                aria-label={`go to inspection questionnaire for ${name}`}
                link={questionnaireUrl}
                disabled={disabledChips}
                isExternalLink
              />
            )}
          </Box>

          <Box width={PROGRESS_WIDTH} textAlign="center" flexBasis={PROGRESS_WIDTH} flexShrink={0} flexGrow={0}>
            {inspectionEnabled && (
              <ProgressButton
                percentComplete={physicalInspectionProgress ?? null}
                aria-label={`go to physical inspection for ${name}`}
                link={inspectionUrl}
                disabled={disabledChips}
                isExternalLink
              />
            )}
          </Box>
        </>
      )}
    </Box>
  );
};
