import { createCircadianTheme, ThemeWrapper } from '@circadian-risk/presentational';
import { useMediaQuery } from '@mui/material';
import React from 'react';

import { useCanUseFeatureFlag } from '../featureFlags/FeatureFlagBoundary';
import { useThemeStore } from './theme.store';

export const CrAppThemeWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [primaryColor, colorMode] = useThemeStore(state => [state.primaryColor, state.colorMode]);
  const { compliance, criticality, risk } = useThemeStore(state => state.organizationColors) ?? {};
  const { enabled: themeOverrideEnabled } = useCanUseFeatureFlag()('org_custom_theming');

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const preferredColorMode = prefersDarkMode ? 'dark' : 'light';
  const effectiveColorMode = colorMode === 'system' ? preferredColorMode : colorMode;

  const theme = React.useMemo(
    () =>
      createCircadianTheme({
        variant: effectiveColorMode,
        customPrimaryColor: themeOverrideEnabled ? primaryColor : undefined,
        userComplianceColors: themeOverrideEnabled ? compliance : undefined,
        userRiskColors: themeOverrideEnabled ? risk : undefined,
        userCriticalityColors: themeOverrideEnabled ? criticality : undefined,
      }),
    [effectiveColorMode, themeOverrideEnabled, primaryColor, compliance, risk, criticality],
  );

  return <ThemeWrapper theme={theme}>{children}</ThemeWrapper>;
};
