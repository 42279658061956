import { FCC } from '@circadian-risk/front-end-utils';
import noop from 'lodash/noop';
import React, { useCallback, useContext, useMemo, useState } from 'react';

import { AssessQuestion } from '../BulkReassess';
import { OneByOneReassessModal } from './OneByOneReassessModal';

export type OneByOneReassessModalResolution = 'close' | 'submitted' | 'bulkEdit' | 'back';

export interface OneByOneReassessModalProviderConfig {
  questions: AssessQuestion[];
  actionCallback(resolution: OneByOneReassessModalResolution): void;
  displayBack?: boolean;
}

export interface CloseTaskDialogState {
  openDialog: (config: OneByOneReassessModalProviderConfig) => void;
}

export const OneByOneReassessModalContext = React.createContext<CloseTaskDialogState>({
  openDialog: noop,
});

/**
 * High level component that can renders the One by One Reassessment dialog
 * Used with useOneByOneReassessModal hook
 */
export const OneByOneReassessModalProvider: FCC = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [resolution, setResolution] = useState<OneByOneReassessModalResolution>('close');
  const [dialogConfig, setDialogConfig] = useState<OneByOneReassessModalProviderConfig | null>(null);

  const openDialog = useCallback(
    (config: OneByOneReassessModalProviderConfig) => {
      setOpen(true);
      setDialogConfig(config);
    },
    [setOpen, setDialogConfig],
  );

  const resetDialog = () => {
    setOpen(false);
    setDialogConfig(null);
  };

  const handleClose = () => {
    setOpen(false);
    setResolution('close');
  };

  const handleExited = () => {
    resetDialog();
    if (dialogConfig) {
      dialogConfig.actionCallback(resolution);
    }
  };

  const handleSubmitted = () => {
    setOpen(false);
    setResolution('submitted');
  };

  const handleBulkEditClick = () => {
    setOpen(false);
    setResolution('bulkEdit');
  };

  const handleBackClick = () => {
    setOpen(false);
    setResolution('back');
  };

  const value = useMemo(() => ({ openDialog }), [openDialog]);

  return (
    <OneByOneReassessModalContext.Provider value={value}>
      <OneByOneReassessModal
        open={open}
        onExited={handleExited}
        onClose={handleClose}
        questions={dialogConfig?.questions ?? []}
        onSubmitted={handleSubmitted}
        onBulkEditClick={handleBulkEditClick}
        onBackClick={dialogConfig?.displayBack ? handleBackClick : undefined}
      />
      {children}
    </OneByOneReassessModalContext.Provider>
  );
};

/**
 * Higher Order Component that adds a OneByOneReassessModalContext
 */
export const withOneByOneReassessModalProvider = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  const Component = React.forwardRef((props: P, ref) => {
    return (
      <OneByOneReassessModalProvider>
        <WrappedComponent {...props} ref={ref} />
      </OneByOneReassessModalProvider>
    );
  });
  return Component;
};

export const useOneByOneReassessModal = (questions: AssessQuestion[]) => {
  const { openDialog } = useContext(OneByOneReassessModalContext);

  const initiateOneByOne = (
    questionsOverride?: AssessQuestion[],
    displayBack?: boolean,
  ): Promise<OneByOneReassessModalResolution> => {
    return new Promise(res => {
      openDialog({
        actionCallback: res,
        questions: questionsOverride ?? questions,
        displayBack,
      });
    });
  };

  return { initiateOneByOne };
};
