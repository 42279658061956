import { FormPaper, FormStack, generateCircadianForm } from '@circadian-risk/form';
import { Bold } from '@circadian-risk/presentational';
import { Mail } from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material';

import { ReturnToSignIn } from '../components/ReturnToSignIn/ReturnToSignIn';
import { EmailVerificationFormData, emailVerificationFormSchema } from './types';

export interface EmailVerificationFormProps {
  email: string;
  onSubmit: (data: EmailVerificationFormData) => void;
  signout: () => Promise<void>;
}

const { useCircadianForm, ariaLabels, Code, SubmitButton, CircadianForm } = generateCircadianForm(
  emailVerificationFormSchema,
  {
    form: 'email verification form',
    code: 'Code',
    submit: 'Submit',
  },
);

export const emailVerificationFormAriaLabels = ariaLabels;

export const EmailVerificationForm: React.FC<EmailVerificationFormProps> = ({
  email,
  onSubmit,
  signout: beforePush,
}) => {
  const theme = useTheme();
  const formMethods = useCircadianForm({ defaultValues: { code: '' } });

  return (
    <CircadianForm onSubmit={onSubmit} formMethods={formMethods}>
      <FormPaper showBg>
        <FormStack>
          <Box fontSize="96px" display="flex" justifyContent="center">
            <Mail fontSize="inherit" htmlColor={theme.palette.divider} />
          </Box>
          <Typography align="center">
            We sent a one time verification code to{' '}
            <Bold component="span" weight="medium">
              {email}
            </Bold>
          </Typography>

          <Code autoFocus formKey="code" onlyNumbers maxLength={6} />

          <SubmitButton color="success" />

          <Typography align="center">Verification window expires in 10 minutes</Typography>

          <ReturnToSignIn
            {...{
              label: 'Not your email?',
              beforePush,
            }}
          />
        </FormStack>
      </FormPaper>
    </CircadianForm>
  );
};
