import { CircadianRoute, ROUTES } from '@circadian-risk/routes';
import { USER_ROLE } from '@circadian-risk/shared';
import { lazyLoadWithVersionCheck } from '@web-app/router/lazyLoadWithVersionCheck';

const UsersPage = lazyLoadWithVersionCheck(() => import('./UsersPage'));

const filteredRoutes = (): CircadianRoute[] => [
  {
    component: UsersPage,
    path: ROUTES.USERS,
    isExact: true,
    pageTitle: 'Users',
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN],
  },
];

export const userRoutes = filteredRoutes;
