// font faces and ag-grid overrides
import './App.css';
// ag-grid styles
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-material.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';

// material-ui specific theming
import { CssBaseline, StyledEngineProvider, Theme, ThemeProvider } from '@mui/material';
import { useEffect } from 'react';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export interface ThemeWrapperProps {
  theme: Theme;
  children: React.ReactNode;
}

export const ThemeWrapper: React.FC<ThemeWrapperProps> = ({ theme, children }) => {
  const isDarkMode = theme.palette.mode === 'dark';

  // this allows all html elements to obey the color scheme, including scrollbars
  useEffect(() => {
    document.body.style.setProperty('color-scheme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeWrapper;
