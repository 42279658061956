import { CircadianRoute, ROUTES } from '@circadian-risk/routes';
import { USER_ROLE } from '@circadian-risk/shared';
import { lazyLoadWithVersionCheck } from '@web-app/router/lazyLoadWithVersionCheck';
import React from 'react';

const QuestionSetsTable = lazyLoadWithVersionCheck(() => import('./QuestionSetsTable'));

export const questionSetsRoutes = (): CircadianRoute[] => [
  {
    path: ROUTES.QUESTION_SETS,
    component: () => <QuestionSetsTable />,
    isPublic: false,
    isExact: true,
    pageTitle: 'Question Sets',
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN],
  },
];
