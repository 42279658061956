import { Box, BoxProps } from '@mui/material';
import React from 'react';

export interface IpadFrameProps extends BoxProps {
  children: React.ReactNode;
}

export const IpadFrame: React.FC<IpadFrameProps> = ({ children, ...boxProps }) => (
  <Box
    {...boxProps}
    sx={{
      display: 'flex',
      objectFit: 'fill',
      overflow: 'hidden',
      height: '100%',
      width: '100%',
      border: '20px black solid',
      borderRadius: '60px',
      backgroundColor: 'rgb(26, 26, 26)',
      boxShadow: '0px -13px 43px rgba(80, 131, 186, 0.9)',
      ...boxProps.sx,
    }}
  >
    {children}
  </Box>
);
