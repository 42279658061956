import capitalize from 'lodash/capitalize';
import pluralize from 'pluralize';

export const pluralizeResource = (str: string): string => {
  const pluralMap: { [key: string]: string } = {
    campus: 'campuses',
  };

  const isCapitalized = str[0].toUpperCase() === str[0];
  const end = pluralMap[str.toLowerCase()] || pluralize(str);

  if (isCapitalized) {
    return capitalize(end);
  }

  return end;
};
