import { AnswerSemanticMeaning } from '@circadian-risk/front-end-utils';
import MissingDeficientIcon from '@mui/icons-material/Block';
import UnknownIcon from '@mui/icons-material/HelpOutline';
import AcceptedIcon from '@mui/icons-material/Lock';
import PresentDeficientIcon from '@mui/icons-material/Warning';
import { SvgIconProps, useTheme } from '@mui/material';
import React from 'react';

import { CompliantIcon, DeficientIcon, NotApplicableIcon, NotObservedIcon } from './CommonIcons';

export type ResponseType = AnswerSemanticMeaning | 'present deficient' | 'missing deficient' | 'accepted' | 'unknown';
export interface ResponseIconProps extends SvgIconProps {
  response: ResponseType | null;
  className?: string;
}

export const ResponseIcon: React.FC<ResponseIconProps> = ({ response, ...iconProps }) => {
  const theme = useTheme();
  const colors = theme.palette.organizationColors.compliance;

  switch (response) {
    case 'compliant': {
      return <CompliantIcon htmlColor={colors.compliant} aria-label={'compliant'} {...iconProps} />;
    }
    case 'deficient': {
      return <DeficientIcon htmlColor={colors.deficient} aria-label={'deficient'} {...iconProps} />;
    }
    case 'n_a': {
      return <NotApplicableIcon htmlColor={colors.unknown} aria-label={'not applicable'} {...iconProps} />;
    }
    case 'n_o': {
      return <NotObservedIcon htmlColor={colors.unknown} aria-label={'not observed'} {...iconProps} />;
    }
    case 'present deficient': {
      return <PresentDeficientIcon htmlColor={colors.deficient} aria-label={'present deficient'} {...iconProps} />;
    }
    case 'missing deficient': {
      return <MissingDeficientIcon htmlColor={colors.deficient} aria-label={'missing deficient'} {...iconProps} />;
    }
    case 'accepted': {
      return <AcceptedIcon htmlColor={colors.accepted} aria-label={'accepted'} {...iconProps} />;
    }
    case 'unknown':
    default:
      return <UnknownIcon aria-label={'unknown'} />;
  }
};
