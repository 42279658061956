import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, BoxProps, SvgIconProps, TooltipProps, useTheme } from '@mui/material';
import React, { CSSProperties } from 'react';

import { TouchFriendlyTooltip, TouchFriendlyTooltipProps } from '../Tooltips';

interface StyleProps {
  tooltipMaxWidth?: number;
}

export interface InfoButtonWithTooltipProps extends StyleProps {
  tooltipContent: TooltipProps['title'];
  iconClassName?: string;
  color?: string;
  size?: SvgIconProps['fontSize'];
  tooltipProps?: TouchFriendlyTooltipProps['tooltipProps'];
  iconProps?: SvgIconProps;
  ariaLabel?: string;
  boxProps?: BoxProps;
}
const iconStyle: CSSProperties = { fontSize: '24px' };

export const InfoIconWithTooltip: React.FC<InfoButtonWithTooltipProps> = ({
  tooltipContent,
  iconClassName,
  iconProps,
  color,
  size = 'inherit',
  ariaLabel = 'info',
  tooltipProps,
  boxProps,
}) => {
  const theme = useTheme();
  const iconColor = color ?? theme.palette.secondary.main;

  return (
    <Box display="inline-flex" mx="0.25em" pl={2} component="span" {...boxProps}>
      <TouchFriendlyTooltip tooltipContent={tooltipContent} ariaLabel={ariaLabel} tooltipProps={tooltipProps}>
        <InfoOutlinedIcon
          style={iconStyle}
          fontSize={size}
          htmlColor={iconColor}
          className={iconClassName}
          {...iconProps}
        />
      </TouchFriendlyTooltip>
    </Box>
  );
};
