import { RefObject, useEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export interface ContentRect {
  width: number;
  height: number;
  top: number;
  right: number;
  left: number;
  bottom: number;
}

/**
 * Forked but improved from react-use
 * @see https://github.com/streamich/react-use/blob/master/src/useMeasureDirty.ts
 * @see https://github.com/streamich/react-use/pull/999#issuecomment-608648700
 */
export const useMeasureDirty = <T extends Element>(ref: RefObject<T | null | undefined>): ContentRect => {
  const frame = useRef(0);
  const [rect, set] = useState({
    width: 0,
    height: 0,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  });

  const observer = useRef(
    new ResizeObserver(entries => {
      const entry = entries[0];

      if (entry) {
        cancelAnimationFrame(frame.current);

        frame.current = requestAnimationFrame(() => {
          if (ref.current) {
            set(entry.contentRect);
          }
        });
      }
    }),
  );

  useEffect(() => {
    observer.current.disconnect();

    if (ref.current) {
      observer.current.observe(ref.current);
    }
  }, [ref]);

  return rect;
};
