import { useApolloClient } from '@apollo/client';
import { useApiClient } from '@circadian-risk/api-client-provider';
import { LAYER_TAG_ALL } from '@circadian-risk/data-grid';
import { useNotification } from '@circadian-risk/front-end-utils';
import { Scenario } from '@circadian-risk/graphql-types';
import { ROUTES } from '@circadian-risk/routes';
import { safeParseCoordinates } from '@circadian-risk/shared';
import { useOrganizationSessionStore } from '@circadian-risk/stores';
import React from 'react';
import { generatePath } from 'react-router-dom';

import {
  AddSubmitData,
  EditSubmitData,
  LocationWizard,
  LocationWizardProps,
} from './components/LocationWizard/LocationWizard';

export interface LocationWizardSmartProps {
  initialMode?: LocationWizardProps['initialMode'];
}

const cacheFieldsToEvict: (keyof Scenario)[] = [
  'relatedLocations',
  'percentCompleteInherentRisk',
  'relatedLocations_aggregate',
];

export const LocationWizardSmart: React.FC<LocationWizardSmartProps> = ({ initialMode }) => {
  const { tsRestClient } = useApiClient();
  const { cache } = useApolloClient();
  const { displaySuccess, displayError } = useNotification();
  const {
    id: organizationId,
    layers,
    locations,
    onNodesUpdated,
  } = useOrganizationSessionStore(({ nodes, layers, id, layersById, allowedNodeIds, onNodesUpdated }) => {
    return {
      locations: nodes.map<LocationWizardProps['locations'][number]>(node => ({
        ...node,
        layerName: layersById[node.layer_id].name,
        useCustomCoordinates: node.use_custom_coordinates,
        coordinates: safeParseCoordinates(node.coordinates),
        selectable: allowedNodeIds.includes(node.id),
        layerTags: node.tags.filter(t => t.type_name !== LAYER_TAG_ALL).map(t => t.type_name),
      })),
      layers,
      id,
      layersById,
      onNodesUpdated,
    };
  });

  const uploadFloorPlan = async (nodeId: string, file: File, filename: string) => {
    try {
      await tsRestClient.nodeMapbox.uploadMap({
        params: {
          organizationId,
          nodeId,
        },
        body: {
          filename,
          file,
        },
      });
    } catch (ex) {
      displayError(ex);
    }
  };

  const handleAddLocation = async (data: AddSubmitData) => {
    const { locationName: name, layerId, parentId, useCustomCoordinates, lat, long, address } = data;
    const coordinates = useCustomCoordinates && lat && long ? { lat, long } : undefined;

    const { status, body } = await tsRestClient.nodes.create({
      params: {
        organizationId,
      },
      body: {
        address,
        name,
        layerId,
        parentId,
        useCustomCoordinates,
        coordinates,
      },
    });

    if (status !== 201) {
      throw new Error('Failed creating a node');
    }

    const createdNode = body;

    if (data.floorPlan?.file) {
      await uploadFloorPlan(createdNode.id, data.floorPlan.file, data.floorPlan.name);
    }
    const { relatedScenarioIds } = createdNode;
    relatedScenarioIds.forEach(s => {
      const id = `scenario:${s}`;
      cacheFieldsToEvict.forEach(fieldName => {
        cache.evict({
          id,
          fieldName,
        });
      });
    });

    await onNodesUpdated();
    displaySuccess('Updated location');
  };

  const handleEditLocation = async (data: EditSubmitData) => {
    const { locationId: id, locationName: name, useCustomCoordinates, lat, long, address } = data;
    const coordinates = useCustomCoordinates && lat && long ? { lat, long } : undefined;

    await tsRestClient.nodes.update({
      params: {
        id,
        organizationId,
      },
      body: { address, name, useCustomCoordinates, coordinates },
    });

    if (data.floorPlan?.file) {
      await uploadFloorPlan(id, data.floorPlan.file, data.floorPlan.name);
    }

    await onNodesUpdated();
    displaySuccess('Updated location');
  };

  return (
    <LocationWizard
      initialMode={initialMode}
      layers={layers}
      locations={locations}
      onAddSubmit={handleAddLocation}
      onEditSubmit={handleEditLocation}
      getLocationDetailsRoute={locationId => generatePath(ROUTES.NODES_DETAIL, { organizationId, id: locationId })}
    />
  );
};
