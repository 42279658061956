import { useIsDarkMode } from '@circadian-risk/front-end-utils';
import { Typography } from '@mui/material';
import { CSSProperties } from 'react';
import ReactMapGl, { MapProps } from 'react-map-gl';
import { useUnmount } from 'react-use';

import { useMapboxStore } from '../Mapbox.store';
import { useMapToken } from '../MapTokenProvider';

const fullSizeMapStyle: CSSProperties = { height: '100%', width: '100%' };

type RootMapProps = Omit<MapProps, 'mapboxAccessToken' | 'id'> & {
  mapRefId: string;
};

export const RootMap: React.FC<RootMapProps> = ({ children, mapRefId, ...rest }) => {
  const [addRef, removeRef] = useMapboxStore(state => [state.addRef, state.removeRef]);
  const { token } = useMapToken();
  const isDarkMode = useIsDarkMode();

  const dynamicMapStyle = isDarkMode ? 'mapbox://styles/mapbox/dark-v10' : 'mapbox://styles/mapbox/light-v10';

  useUnmount(() => {
    removeRef(mapRefId);
  });

  if (!token) {
    return (
      <Typography textAlign={'center'}>
        No token provided for MapBox. Please provide the token using MapTokenProvider
      </Typography>
    );
  }

  return (
    <ReactMapGl
      ref={innerRef => {
        if (innerRef) {
          addRef(mapRefId, innerRef);
        }
      }}
      mapboxAccessToken={token}
      {...rest}
      fog={undefined}
      terrain={undefined}
      maxPitch={85}
      style={fullSizeMapStyle}
      mapStyle={rest.mapStyle ?? dynamicMapStyle}
      id={mapRefId}
    >
      {children}
    </ReactMapGl>
  );
};
