import { ListenScenarioDetailsForAssessmentSubscription } from '@circadian-risk/client-graphql-hooks';
import { buildTruncatedMpath, splitMpath } from '@circadian-risk/front-end-utils';
import { ActiveOrganizationData } from '@circadian-risk/stores';
import { assessmentAppUrlForWebApp } from '@web-app/lib/assessmentAppUrl';
import min from 'lodash/min';
import uniq from 'lodash/uniq';

import { AssessmentDeficienciesProps } from '../DeficienciesByLocation/AssessmentDeficiencies';
import { DeficienciesByLocationRow } from '../DeficienciesByLocation/DeficienciesByLocationGrid';

export const calculateDeficienciesByLocation = (
  organizationId: string,
  assessment: ListenScenarioDetailsForAssessmentSubscription['v2_assessments_by_pk'] | undefined,
  layers: ActiveOrganizationData['layersById'],
  nodes: ActiveOrganizationData['nodesById'],
): AssessmentDeficienciesProps['locations'] | undefined => {
  if (!assessment) {
    return;
  }

  const assessmentLocations = assessment.locations ?? [];
  const assessmentLayerIds = uniq(assessmentLocations.map(x => x.node.layer_id));
  const assessmentLayers = assessmentLayerIds.map(id => layers[id]);

  // What is the highest layer in the hierarchy available to this assessment?
  const topLayerDepth = min(assessmentLayers.map(layer => layers[layer.id].layerDepth)) ?? 0;

  const allRelevantNodeIds = uniq(
    assessmentLocations.flatMap(x => splitMpath(buildTruncatedMpath(x.node.mpath, topLayerDepth))),
  );

  const deficienciesByLocation: AssessmentDeficienciesProps['locations'] = assessmentLocations.map(location => {
    const {
      node: { id: locationId, mpath, layer_id, name, ordinal },
      progressAggregates,
      relevantItemCategories,
    } = location;

    const inspectionLink = assessmentAppUrlForWebApp({
      organizationId,
      assessmentId: assessment.id,
      location: {
        id: locationId,
        view: {
          type: 'inspection',
        },
      },
    });

    const interviewLink = assessmentAppUrlForWebApp({
      organizationId,
      assessmentId: assessment.id,
      location: {
        id: locationId,
        view: {
          type: 'questionnaire',
        },
      },
    });

    const locationDeficiencies = location.deficiencySummary?.[0];
    const locationLayer = layers[layer_id];

    const row: DeficienciesByLocationRow = {
      name,
      locationId,
      locationType: locationLayer.name,
      mpath: buildTruncatedMpath(mpath, topLayerDepth),
      criticality1count: locationDeficiencies?.low_count ?? 0,
      criticality2count: locationDeficiencies?.medium_count ?? 0,
      criticality3count: locationDeficiencies?.high_count ?? 0,
      flaggedCount: locationDeficiencies?.flagged_count ?? 0,
      inspectionPercentageComplete: progressAggregates?.physical_progress ?? undefined,
      interviewPercentageComplete: progressAggregates?.inspection_progress ?? undefined,
      inspectionLink,
      interviewLink,
      interviewEnabled: relevantItemCategories.some(x => x.item_type === 'interview'),
      inspectionEnabled: relevantItemCategories.some(x => x.item_type === 'inspection'),
      ordinal: ordinal ?? 1,
    };

    return row;
  });

  // Find all the nodes that belong in the sub-hierarchy for the purposes of displaying in the tree
  // view which weren't actually part of the nodes being assessed
  const missingFromList = allRelevantNodeIds.filter(
    nodeId => !deficienciesByLocation.some(deficiencyRow => deficiencyRow.locationId === nodeId),
  );

  const missingDeficiencyRows: DeficienciesByLocationRow[] = missingFromList.map(x => {
    const { name, id, mpath, layer_id, ordinal } = nodes[x];
    const locationLayer = layers[layer_id];
    return {
      name,
      locationId: id,
      locationType: locationLayer.name,
      mpath: buildTruncatedMpath(mpath, topLayerDepth),
      criticality1count: 0,
      criticality2count: 0,
      criticality3count: 0,
      flaggedCount: 0,
      inspectionPercentageComplete: undefined,
      interviewPercentageComplete: undefined,
      interviewEnabled: false,
      inspectionEnabled: false,
      ordinal: ordinal ?? 1,
    };
  });

  return deficienciesByLocation.concat(missingDeficiencyRows);
};
