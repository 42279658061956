import { Enum_Task_Status_Enum } from '@circadian-risk/graphql-types';
import { Equal, Expect } from '@circadian-risk/shared';
// import OverdueIcon from '@mui/icons-material/AssignmentLateTwoTone';
// import CancelIcon from '@mui/icons-material/CancelTwoTone';
// import BlockedIcon from '@mui/icons-material/PauseCircleTwoTone';
// import ApproveIcon from '@mui/icons-material/ThumbUpAltTwoTone';
// import ValidateIcon from '@mui/icons-material/VerifiedUserTwoTone';
import CompletedIcon from '@mui/icons-material/CheckCircleTwoTone';
import NotStartedIcon from '@mui/icons-material/CircleTwoTone';
import HourglassTopTwoToneIcon from '@mui/icons-material/HourglassTopTwoTone';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { sentenceCase } from 'change-case';
import fromPairs from 'lodash/fromPairs';
import { z } from 'zod';

// TODO(iprokopovich)[CR-3503]: add more statuses
export const TASK_STATUSES = [
  'NotStarted',
  // 'Blocked',
  // 'Overdue', // not in the enum
  // 'Validate',
  // 'Cancel',
  'InProgress',
  // 'Approve',
  'Completed',
] as const;
export const taskStatusSchema = z.enum(TASK_STATUSES);
export type TaskStatus = z.infer<typeof taskStatusSchema>;

export const taskStatusIconMap: Record<TaskStatus, OverridableComponent<SvgIconTypeMap>> = {
  NotStarted: NotStartedIcon,
  // Blocked: BlockedIcon,
  // Overdue: OverdueIcon,
  // Validate: ValidateIcon,
  // Cancel: CancelIcon,
  InProgress: HourglassTopTwoToneIcon,
  // Approve: ApproveIcon,
  Completed: CompletedIcon,
};

const pairs = TASK_STATUSES.map<[TaskStatus, string]>(status => [status, sentenceCase(status)]);
export const taskStatusLabelMap = fromPairs(pairs) as Record<TaskStatus, string>;

// TODO(iprokopovich)[CR-3503]: make sure overdue is properly typed
// "Overdue" is frontend only status and is not in the enum
// type TaskStatusDB = Enum_Task_Status_Enum | 'Overdue';
type TaskStatusDB = Enum_Task_Status_Enum;

// making sure the array is in sync with the enum
// eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
type expectArrayToMatchEnum = Expect<Equal<TaskStatusDB, TaskStatus>>;
