import { Tooltip } from '@mui/material';
import React from 'react';

import { ITEM_ICON_SIZES, ItemIcon, ItemIconSizes } from '../ItemIcon';
import { LightBoxGallery, LightboxGalleryImage, LightboxWithEditingProps } from '../LightBox';

export interface ItemThumbnailProps {
  name: string;
  iconSrc?: string;
  photos?: LightboxGalleryImage[];
  disableTooltip?: boolean;
  size?: ItemIconSizes;
  onImageProcessing?: LightboxWithEditingProps['onImageProcessing'];
}

export const ItemThumbnail: React.FC<ItemThumbnailProps> = ({
  name,
  photos,
  size,
  iconSrc,
  disableTooltip,
  onImageProcessing,
}) => {
  const iconSize = size ?? ItemIconSizes.medium;
  const iconDimensions = ITEM_ICON_SIZES[iconSize];

  if (photos && photos.length > 0) {
    const thumbnail = (
      <LightBoxGallery
        stack={photos.length > 1}
        onImageProcessing={onImageProcessing}
        thumbnailSize={iconDimensions.width}
        images={photos}
      />
    );

    const withTooltip = (
      <Tooltip arrow title={name}>
        <div>{thumbnail}</div>
      </Tooltip>
    );

    return disableTooltip ? thumbnail : withTooltip;
  }

  return <ItemIcon size={iconSize} alt={name} iconSrc={iconSrc} tooltipDisabled={disableTooltip} />;
};
