import { Enum_Feature_Flags_Enum } from '@circadian-risk/client-graphql-hooks';
import { HStack } from '@circadian-risk/presentational';
import { FeatureFlagBoundary, FeatureFlagInfo, useCanUseFeatureFlag } from '@circadian-risk/stores';
import AddIcon from '@mui/icons-material/Add';
import SpeedDialIconOverride from '@mui/icons-material/MoreHoriz';
import {
  Backdrop,
  Box,
  Button,
  ButtonProps,
  Card,
  Fab,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  styled,
  SxProps,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import omit from 'lodash/omit';
import React, { useState } from 'react';

import NavLinkWithActiveOrgId from '../NavLinkWithActiveOrgId';

const ADMIN_ONLY_FLAG = 'circadian-admin-only';

export type CircadianActionHeaderLayout = 'header' | 'below' | 'speed-dial';

export interface CircadianHeaderProps {
  header: string | React.ReactNode;
  actions?: CircadianHeaderAction[];
  actionsNode?: React.ReactNode;
  actionsLayout?: CircadianActionHeaderLayout;
  sx?: SxProps<Theme>;
}

export interface CircadianHeaderAction extends ButtonProps {
  icon: React.ReactNode;
  iconAtTheEnd?: boolean;
  name: string;
  tooltip?: string;
  disabled?: boolean;
  /**
   * Optional feature flag. To simply restrict to circadian admins, pass `circadian-admin-only`.
   */
  featureFlag?: Enum_Feature_Flags_Enum | typeof ADMIN_ONLY_FLAG;
  link?: string;
}

const fabPositionSx: SxProps<Theme> = theme => ({
  position: 'fixed',
  bottom: theme.spacing(8),
  right: theme.spacing(4),
  // currently we have app bar that the speed dial overlaps with
  zIndex: theme.zIndex.appBar + 2,
  [theme.breakpoints.up('sm')]: {
    right: theme.spacing(4),
  },
});

export const useStandardActionButtonLayout: () => CircadianActionHeaderLayout = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.down('lg'));
  if (isSmall) {
    return 'speed-dial';
  } else if (isMedium) {
    return 'below';
  }
  return 'header';
};

const PREFIX = 'CircadianHeader';
const classes = {
  fab: `${PREFIX}-fab`,
  staticTooltipLabel: `${PREFIX}-staticTooltipLabel`,
};
const StyledAddIcon = styled(AddIcon)(({ theme }) => ({
  [`& .${classes.fab}`]: {
    boxShadow: theme.shadows[5],
  },
  [`& .${classes.staticTooltipLabel}`]: {
    fontWeight: 700,
    textAlign: 'center',
    fontSize: '0.75rem',
    marginRight: theme.spacing(0.5),
    whiteSpace: 'nowrap',
    boxShadow: theme.shadows[5],
  },
}));

export const CircadianHeader = React.forwardRef<HTMLDivElement, CircadianHeaderProps>((props, ref) => {
  const theme = useTheme();
  const layout = useStandardActionButtonLayout();
  const { header, actions = [], actionsLayout = layout, actionsNode } = props;
  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const canUseFeatureFlag = useCanUseFeatureFlag();

  const buttonActions = actions.map((action, index) => {
    const buttonProps = omit(action, ['name', 'tooltip', 'icon', 'featureFlag', 'link', 'iconAtTheEnd']);
    let button: React.ReactNode = (
      <Button
        {...buttonProps}
        key={index}
        startIcon={action.iconAtTheEnd ? undefined : action.icon}
        endIcon={action.iconAtTheEnd ? action.icon : undefined}
      >
        {action.name}
      </Button>
    );
    if (action.link) {
      button = (
        <NavLinkWithActiveOrgId key={index} to={action.link}>
          {button}
        </NavLinkWithActiveOrgId>
      );
    }
    if (action.featureFlag) {
      button = (
        <FeatureFlagBoundary
          key={index}
          {...(action.featureFlag && action.featureFlag !== ADMIN_ONLY_FLAG ? { flagName: action.featureFlag } : {})}
        >
          {button}
        </FeatureFlagBoundary>
      );
    }
    return action.tooltip ? (
      <Tooltip key={index} title={action.tooltip}>
        <Box display={'inline-flex'}>{button}</Box>
      </Tooltip>
    ) : (
      button
    );
  });

  const speedDialActions = actions.filter(action => {
    let featureFlagInfo: FeatureFlagInfo | null = null;
    if (action.featureFlag) {
      featureFlagInfo = canUseFeatureFlag(action.featureFlag === ADMIN_ONLY_FLAG ? undefined : action.featureFlag);
    }
    if (featureFlagInfo === null) {
      return true;
    }
    return featureFlagInfo.enabled;
  });

  const renderFabButton = () => {
    const action = speedDialActions[0];
    let button: React.ReactNode = (
      <Fab sx={fabPositionSx} color="primary" variant="extended" onClick={action.onClick}>
        <Box display="flex" alignItems="center" gap={theme.spacing(0.5)}>
          {action.name}
          {action.icon}
        </Box>
      </Fab>
    );

    if (action.link) {
      button = <NavLinkWithActiveOrgId to={action.link}>{button}</NavLinkWithActiveOrgId>;
    }
    if (action.featureFlag) {
      button = (
        <FeatureFlagBoundary flagName={action.featureFlag === ADMIN_ONLY_FLAG ? undefined : action.featureFlag}>
          {button}
        </FeatureFlagBoundary>
      );
    }
    return button;
  };

  const handleToggle = () => {
    setSpeedDialOpen(state => !state);
  };

  return (
    <Box marginBottom={2} ref={ref}>
      <Card square sx={props.sx}>
        <Box
          flex={1}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          px={4}
          py={1}
          alignItems="center"
        >
          {isString(header) ? <Typography variant="h4">{header}</Typography> : header}
          <HStack noFullWidth>
            {actionsLayout === 'header' && (
              <Box
                display="flex"
                flexDirection="row"
                gap={theme.spacing(1)}
                alignItems="flex-end"
                sx={{
                  '& > button': {
                    whiteSpace: 'nowrap',
                  },
                }}
              >
                {buttonActions}
              </Box>
            )}
            {actionsNode}
          </HStack>
        </Box>
      </Card>

      {actionsLayout === 'below' && !isEmpty(actions) && (
        <Box
          display="flex"
          justifyContent="flex-end"
          flexDirection="row"
          flexWrap="wrap"
          gap={theme.spacing(1)}
          my={1}
          mx={2}
        >
          {buttonActions}
        </Box>
      )}

      <Backdrop open={speedDialOpen} sx={theme => ({ zIndex: theme.zIndex.appBar + 1 })} />

      {actionsLayout === 'speed-dial' && speedDialActions.length > 1 && (
        <SpeedDial
          ariaLabel="Actions"
          sx={fabPositionSx}
          icon={<SpeedDialIcon icon={<SpeedDialIconOverride />} />}
          onClick={handleToggle}
          open={speedDialOpen}
          direction="up"
        >
          {speedDialActions.map((action, index) => {
            let featureFlagInfo: FeatureFlagInfo | null = null;
            if (action.featureFlag) {
              featureFlagInfo = canUseFeatureFlag(
                action.featureFlag === ADMIN_ONLY_FLAG ? undefined : action.featureFlag,
              );
            }

            return (
              <SpeedDialAction
                key={index}
                icon={action.icon}
                tooltipTitle={featureFlagInfo?.tooltip ? `${action.name} - ${featureFlagInfo.tooltip}` : action.name}
                tooltipOpen={true}
                onClick={e => {
                  if (action.onClick && !action.disabled) {
                    action.onClick(e as unknown as React.MouseEvent<HTMLButtonElement>);
                  }
                  setSpeedDialOpen(false);
                }}
                FabProps={{
                  disabled: action.disabled,
                  'aria-label': action['aria-label'],
                  role: 'button',
                  style: featureFlagInfo?.color ? { border: `2px solid ${featureFlagInfo.color ?? 'red'}` } : undefined,
                }}
              />
            );
          })}
        </SpeedDial>
      )}
      {actionsLayout === 'speed-dial' && speedDialActions.length === 1 && renderFabButton()}
    </Box>
  );
});

export const createCreateNewAction = (
  onClick: React.MouseEventHandler<HTMLButtonElement>,
  name?: string,
): CircadianHeaderAction => {
  return {
    icon: <StyledAddIcon />,
    name: name ?? 'Create New',
    onClick,
    variant: 'contained',
    color: 'primary',
  };
};
