import { FieldValues, useFormContext } from 'react-hook-form';

import { PropertyRichTextField, PropertyRichTextFieldProps } from '../../PropertyFields';
import { useCircadianFormContext } from '../CircadianFormContext';
import { FormField } from './types';
import { useErrorMessage } from './utils';

export type RichTextFFProps<FormData> = FormField<FormData> &
  Omit<PropertyRichTextFieldProps, 'control' | 'htmlName' | 'label'>;

export function RichTextFF<FormData extends FieldValues>({ formKey, ...rest }: RichTextFFProps<FormData>) {
  const { control, formState } = useFormContext<FormData>();
  const { ariaLabels, disabled } = useCircadianFormContext<FormData>();

  const errorMessage = useErrorMessage(formState.errors, formKey);

  return (
    <PropertyRichTextField
      {...{
        disabled,
        control,
        errorMessage,
        ...rest,
      }}
      label={ariaLabels[formKey]}
      htmlName={formKey}
    />
  );
}
