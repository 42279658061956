import { Box, FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';
import isNil from 'lodash/isNil';
import { Controller } from 'react-hook-form';

import { PropertyFieldProps } from '../types';

export interface PropertyNumericFieldProps extends PropertyFieldProps {
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
}

export const PropertyNumericField: React.FC<PropertyNumericFieldProps> = ({
  label,
  htmlName,
  control,
  disabled,
  rules,
  errorMessage,
  boxProps,
  startAdornment,
  endAdornment,
  onBlur: blurCallback,
  size,
}) => {
  const id = `numeric-field-${htmlName}`;
  return (
    <Box {...boxProps}>
      <Controller
        control={control}
        name={htmlName}
        defaultValue={null}
        rules={rules}
        render={fieldProps => {
          const { value, onChange, onBlur } = fieldProps.field;

          const fieldError = errorMessage || fieldProps.fieldState.error?.message;

          const handleBlur = () => {
            onBlur();
            if (blurCallback) {
              blurCallback(value);
            }
          };

          const handleChange = (value: string) => {
            if (value === '') {
              onChange(null);
            } else {
              onChange(Number(value));
            }
          };

          return (
            <FormControl variant="outlined" fullWidth disabled={disabled} error={Boolean(fieldError)}>
              <InputLabel htmlFor={id}>{label}</InputLabel>
              <OutlinedInput
                id={id}
                name={htmlName}
                value={!isNil(value) ? Number(value) : ''}
                onChange={e => handleChange(e.target.value)}
                type="number"
                label={label}
                data-name={label}
                onBlur={handleBlur}
                startAdornment={startAdornment}
                endAdornment={endAdornment}
                size={size}
                inputProps={
                  // Gives it a little bit of padding left
                  // when an adornment is present
                  startAdornment
                    ? {
                        sx: {
                          pl: 1,
                        },
                      }
                    : {}
                }
              />
              {fieldError && <FormHelperText error>{fieldError}</FormHelperText>}
            </FormControl>
          );
        }}
      />
    </Box>
  );
};
