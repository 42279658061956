import { useNotification } from '@circadian-risk/front-end-utils';
import React from 'react';
import { FieldValues, FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';

import { CircadianFormContextProvider } from './CircadianFormContext';

export type AriaLabels<T> = Record<keyof T, string> & { form: string; submit: string };

type FormElProps = React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>;

export type CircadianFormProps<T extends FieldValues = FieldValues> = {
  /** please don't catch errors inside onSubmit, feel free to throw, circadianForm handles it for you */
  onSubmit: SubmitHandler<T>;
  ariaLabels: AriaLabels<T>;
  formMethods: UseFormReturn<T>;
  disabled?: boolean;
  children: React.ReactNode;
} & Partial<Omit<FormElProps, 'onSubmit' | 'aria-label'>>;

/**
 * @deprecated Use the returned `CircadianForm` from `generateCircadianForm` instead to automatically stich together the `id`
 */
export function CircadianForm<T extends FieldValues = FieldValues>({
  onSubmit,
  ariaLabels,
  formMethods,
  disabled,
  children,
  ...formElProps
}: CircadianFormProps<T>) {
  const { handleSubmit: hookFormSubmit } = formMethods;
  const { displayError } = useNotification();

  return (
    <FormProvider {...formMethods}>
      <CircadianFormContextProvider ariaLabels={ariaLabels} disabled={disabled}>
        <form
          aria-label={ariaLabels.form}
          {...formElProps}
          // this approach is taken intentionally in order to show correct state for `isSubmitSuccessful`
          onSubmit={async e => {
            try {
              await hookFormSubmit(onSubmit)(e);
            } catch (error) {
              displayError(error);
              // this is a dirty workaround to reset isSubmitting after RHF rendered isSubmitting obsolete
              // https://github.com/react-hook-form/react-hook-form/issues/9945#issuecomment-1452562041
              formMethods.reset(formMethods.getValues(), { keepDirty: true });
            }
          }}
        >
          {children}
        </form>
      </CircadianFormContextProvider>
    </FormProvider>
  );
}
