import { Box, BoxProps, useTheme } from '@mui/material';
import range from 'lodash/range';
import React from 'react';

import { HStack } from '../..';

export interface DotsProps extends Omit<BoxProps, 'onClick'> {
  length: number;
  selected: number;
  onClick: (index: number) => void;
}

export const Dots: React.FC<DotsProps> = ({ length, onClick, selected, ...boxProps }) => {
  const theme = useTheme();
  const disabledDotColor = theme.palette.divider;
  const activeDotColor = theme.palette.action.active;
  return (
    <HStack spacing={0} {...boxProps}>
      {range(length).map(index => (
        <Box
          onClick={() => onClick(index)}
          key={`${index}-${activeDotColor}`}
          sx={{
            p: 1,
            cursor: 'pointer',
            borderRadius: 5,
            ':hover': {
              bgcolor: theme.palette.action.hover,
            },
          }}
        >
          <Box
            width={10}
            height={10}
            borderRadius="5px"
            bgcolor={index === selected ? activeDotColor : disabledDotColor}
          />
        </Box>
      ))}
    </HStack>
  );
};
