import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import { booleanQueryParamSchema } from './shared-schemas/booleanQueryParam.schema';

const c = initContract();

const previewStatusSchema = z.object({
  status: z.enum(['pending', 'started', 'completed', 'failed']),
  url: z.string().nullable(),
});

const draftReportSchema = z.object({
  assessmentId: z.number(),
  reportId: z.number(),
});

const emptyBody = z.object({}).nullish();

export type PreviewStatus = z.infer<typeof previewStatusSchema>;
export type DraftReport = z.infer<typeof draftReportSchema>;

export const reportsContract = c.router(
  {
    resetField: {
      method: 'POST',
      path: '/:organizationId/:assessmentId/reset-field/:fieldId',
      pathParams: z.object({
        organizationId: z.string().uuid(),
        fieldId: z.coerce.number(),
        assessmentId: z.coerce.number(),
      }),
      body: emptyBody,
      responses: {
        201: z.object({
          field_value: z.record(z.string(), z.any()).nullable(),
          updated_at: z.string(),
          last_modified_by_id: z.string().nullable(),
        }),
      },
    },
    previewSection: {
      method: 'POST',
      path: '/:organizationId/:assessmentId/report/:reportId/preview-section/:sectionId',
      pathParams: z.object({
        organizationId: z.string().uuid(),
        assessmentId: z.coerce.number(),
        reportId: z.coerce.number(),
        sectionId: z.string(),
      }),
      body: emptyBody,
      query: z.object({
        skipCache: booleanQueryParamSchema.optional(),
      }),
      responses: {
        201: previewStatusSchema,
      },
    },
    createDraftReport: {
      method: 'POST',
      path: '/:organizationId/:assessmentId/report',
      pathParams: z.object({
        organizationId: z.string().uuid(),
        assessmentId: z.coerce.number(),
      }),
      body: emptyBody,
      responses: {
        201: draftReportSchema,
      },
    },
    produceReport: {
      method: 'POST',
      path: '/:organizationId/:assessmentId/report/:id',
      pathParams: z.object({
        organizationId: z.string().uuid(),
        assessmentId: z.coerce.number(),
        id: z.coerce.number(),
      }),
      query: z.object({
        skipCache: booleanQueryParamSchema.optional(),
      }),
      body: emptyBody,
      responses: {
        201: previewStatusSchema,
      },
    },
  },
  { pathPrefix: '/reports' },
);
