import { useNotification } from '@circadian-risk/front-end-utils';
import { useEffect } from 'react';

export const useGqlQueryErrorNotification = (error: unknown) => {
  const { displayError } = useNotification();

  useEffect(() => {
    if (error) {
      displayError(error);
    }
  }, [error, displayError]);
};
