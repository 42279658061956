import { TypedDocumentNode } from '@apollo/client';
import { FragmentDefinitionNode, OperationDefinitionNode } from 'graphql';
import unionBy from 'lodash/unionBy';

// TODO():[CR-5255] Fix the codegen to not generate duplicate fragment definitions
/**
 * Some codegen typed document nodes can have duplicate fragment definitions.
 * Using these documents with Apollo Client will result in an error.
 * This function removes duplicate definitions from a typed document node.
 *
 * @param document The document to remove duplicate definitions from.
 * @returns A new document with duplicate definitions removed.
 *
 * @example
 * ```
 * const document = gql`
 *  fragment UserIdentifier on User {
 *   id
 *  }
 *
 *  fragment UserInfo on User {
 *   ...UserIdentifier
 *   name
 *   email
 *  }
 *
 *  fragment UserPermission on User {
 *   ...UserIdentifier
 *   role
 *  }
 *
 *  query GetUser($id: ID!) {
 *   user(id: $id) {
 *    ...UserIdentifier
 *    ...UserInfo
 *    ...UserPermission
 *  }
 *
 * const uniqueDocument = removeDuplicateDefinition(document);
 * const result = useQuery(uniqueDocument, { variables });
 * ```
 * The result will not throw an error due to duplicate definitions.
 * Otherwise, the error would be `"Duplicated definition 'UserIdentifier'"`
 */

export const removeDuplicateDefinition = <
  Result extends Record<string, unknown>,
  Variables extends Record<string, unknown>,
>(
  document: TypedDocumentNode<Result, Variables>,
): TypedDocumentNode<Result, Variables> => {
  const definitions = document.definitions;
  const uniqueDefinitions = unionBy(definitions, definition =>
    [
      definition.kind,
      (definition as FragmentDefinitionNode)?.name?.value,
      (definition as OperationDefinitionNode)?.operation,
    ].join('-'),
  );

  return {
    ...document,
    definitions: uniqueDefinitions,
  };
};
