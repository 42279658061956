import { ColumnEvent, FirstDataRenderedEvent } from '@ag-grid-community/core';
import * as Sentry from '@sentry/react';
import isEqual from 'lodash/isEqual';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { compareAndSetModel } from '../utils';
import { PivotStateParam } from './constants';

interface PivotState {
  enabledPivot: boolean;
}

export const usePivotState = () => {
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const pivotStateParam = query.get(PivotStateParam);

  const onFirstDataRendered = ({ columnApi }: FirstDataRenderedEvent) => {
    if (!pivotStateParam) {
      return;
    }
    try {
      const pivotStateUrlObj = JSON.parse(pivotStateParam) as PivotState;

      const enabledPivot = columnApi.isPivotMode();
      const currentPivotState: PivotState = { enabledPivot };

      if (!isEqual(pivotStateUrlObj, currentPivotState)) {
        columnApi.setPivotMode(pivotStateUrlObj.enabledPivot);
      }
    } catch (ex) {
      Sentry.captureException(ex);
    }
  };

  const onPivotChange = useCallback(
    ({ columnApi }: ColumnEvent) => {
      const enabledPivot = columnApi.isPivotMode();
      const currentPivotState: PivotState = { enabledPivot };
      compareAndSetModel(PivotStateParam, history, pivotStateParam, JSON.stringify(currentPivotState));
    },
    [pivotStateParam, history],
  );

  return { onFirstDataRendered, onPivotChange };
};
