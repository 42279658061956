type UnknownArrayOrObject = unknown[] | Record<string, unknown>;

/**
 * Compares the dirty fields and the form data and returns only the dirty data
 * @param dirtyFields
 * @param allValues
 * @see https://github.com/react-hook-form/react-hook-form/discussions/1991#discussioncomment-351784
 * @returns
 */
export const getDirtyValues = (
  dirtyFields: UnknownArrayOrObject | boolean,
  allValues: UnknownArrayOrObject,
): UnknownArrayOrObject => {
  // NOTE: Recursive function.

  // If *any* item in an array was modified, the entire array must be submitted, because there's no
  // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }

  // Here, we have an object.
  return Object.fromEntries(
    // Typescript can't use a key on unknown, types could be improved
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Object.keys(dirtyFields).map(key => [key, getDirtyValues((dirtyFields as any)[key], (allValues as any)[key])]),
  );
};
