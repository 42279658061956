import { useTheme } from '@mui/material';
import { AgChartOptions, AgPieSeriesOptions } from 'ag-charts-community';
import { AgChartsReact } from 'ag-charts-react';
import isFunction from 'lodash/isFunction';
import { SizeMe } from 'react-sizeme';
import { useLatest } from 'react-use';

import { ChartPlaceholder } from './ChartPlaceholder';
import { useAgChartStyles } from './useAgChartStyles';

const INNER_RADIUS_OFFSET = -30;

export type TooltipContentCallback = (value: number) => string | string;
export interface PieChartProps {
  data: {
    key: string;
    label: string;
    value: number;
    color: string;
  }[];
  onClick?: (assessmentStatus: string) => void;
  legendPosition?: 'bottom' | 'top' | 'right' | 'left';
  padding?: AgChartOptions['padding'];
  tooltipContent?: TooltipContentCallback;
  includeValueInLabel?: boolean;
}

export const PieChart: React.FC<PieChartProps> = ({
  data,
  onClick,
  legendPosition,
  padding,
  tooltipContent,
  includeValueInLabel = false,
}) => {
  const theme = useTheme();
  const onClickReffed = useLatest(onClick);
  const { highlightStyle, agChartTheme } = useAgChartStyles();

  if (!data?.length || data.length === 0 || !data.some(d => d.value)) {
    return <ChartPlaceholder type="pie" />;
  }

  const handleClick = (key: string) => {
    if (onClickReffed.current) {
      onClickReffed.current(key);
    }
  };

  const pieSeriesOptions: AgPieSeriesOptions = {
    type: 'pie',
    angleKey: 'value',
    sectorLabelKey: 'label',
    legendItemKey: 'label',
    calloutLabelKey: 'label',
    calloutLabel: {
      formatter: ({ datum }) => {
        const relevantDataPoint = data.find(d => d.label === datum['label']);
        return includeValueInLabel ? `${datum['label']} (${relevantDataPoint?.value})` : datum['label'];
      },
    },
    strokes: [theme.palette.action.hover],
    highlightStyle,
    innerRadiusOffset: INNER_RADIUS_OFFSET,
    cursor: onClick ? 'pointer' : undefined,
    fills: data.map(x => x.color),
    tooltip: {
      renderer: params => {
        const datum: PieChartProps['data'][0] = params.datum;
        const content = isFunction(tooltipContent) ? tooltipContent(+datum.value.toFixed(0)) : tooltipContent;
        return {
          content,
          title: datum.label,
        };
      },
    },

    listeners: {
      nodeClick: (props: { datum: PieChartProps['data'][0] }) => handleClick(props.datum.key),
    },
  };

  const defaultOptions: AgChartOptions = {
    data,
    theme: agChartTheme,
    series: [pieSeriesOptions],
    padding: {
      left: 16,
      right: 16,
      bottom: 16,
      top: 16,
      ...padding,
    },
  };

  return (
    <SizeMe>
      {({ size }) => {
        const showLegend = size.width ? size.width > 450 : false;
        const dynamicOptions = {
          ...defaultOptions,
          legend: { enabled: showLegend, position: legendPosition ?? 'bottom' },
        };
        return <AgChartsReact options={{ ...dynamicOptions, autoSize: true }} />;
      }}
    </SizeMe>
  );
};
