import { BasicTable } from '@circadian-risk/presentational';
import capitalize from 'lodash/capitalize';
import pluralize from 'pluralize';
import React from 'react';

type TableRow = { name: string; locationLabel: React.ReactNode; count?: number; actions?: React.ReactNode };
export interface TableProps {
  childLayerName: string;
  childChildLayerName: string | null;
  data: TableRow[];
}

export const Table: React.FC<TableProps> = ({ childLayerName, childChildLayerName, data }) => {
  let headers;
  if (childChildLayerName) {
    headers = [
      {
        key: 'locationLabel',
        name: `${capitalize(childLayerName)} Name`,
      },
      {
        key: 'count',
        name: `${pluralize(capitalize(childChildLayerName))} Count`,
      },
      {
        key: 'actions',
        name: '',
      },
    ];
  } else {
    headers = [
      {
        key: 'locationLabel',
        name: `${capitalize(childLayerName)} Name`,
      },
      {
        key: 'actions',
        name: '',
      },
    ];
  }

  return <BasicTable stickyHeader headers={headers} data={data} />;
};
