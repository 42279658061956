import { AnswerSemanticMeaning, FCC } from '@circadian-risk/front-end-utils';
import { HStack, ResponseIcon } from '@circadian-risk/presentational';
import {
  Box,
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  useTheme,
} from '@mui/material';
import React from 'react';

const semanticSx: Record<AnswerSemanticMeaning, SxProps<Theme>> = {
  compliant: ({
    palette: {
      utils: { getBackgroundColor },
      organizationColors: { compliance },
    },
  }) => ({
    backgroundColor: getBackgroundColor(compliance.compliant),
  }),
  deficient: ({
    palette: {
      utils: { getBackgroundColor },
      organizationColors: { compliance },
    },
  }) => ({
    backgroundColor: getBackgroundColor(compliance.deficient),
  }),
  n_a: ({
    palette: {
      utils: { getBackgroundColor },
      organizationColors: { compliance },
    },
  }) => ({
    backgroundColor: getBackgroundColor(compliance.unknown),
  }),
  n_o: ({
    palette: {
      utils: { getBackgroundColor },
      organizationColors: { compliance },
    },
  }) => ({
    backgroundColor: getBackgroundColor(compliance.unknown),
  }),
};

const headerSx: SxProps<Theme> = theme => ({ fontWeight: theme.typography.fontWeightBold });

export interface AnswerSummaryTableProps {
  breakdown: Record<AnswerSemanticMeaning, number | null>;
  newCount: number;
  total: number;
}

export const AnswerSummaryTable: React.FC<AnswerSummaryTableProps> = props => {
  const { breakdown, newCount, total } = props;
  const theme = useTheme();

  const isEnabled = (category: AnswerSemanticMeaning) => breakdown[category] != null;

  const TableCellHeader: FCC<{ category: AnswerSemanticMeaning }> = ({ category, children }) => (
    <TableCell
      aria-disabled={!isEnabled(category)}
      sx={theme => ({ fontWeight: theme.typography.fontWeightMedium })}
      align="center"
      component="th"
    >
      <HStack flexWrap="wrap" justifyContent="center" spacing={0.5}>
        <ResponseIcon response={category} />
        {children}
      </HStack>
    </TableCell>
  );

  const TableCellValue: FCC<{ category: AnswerSemanticMeaning }> = ({ category, children }) => (
    <TableCell aria-disabled={!isEnabled(category)} sx={semanticSx[category]} align="center">
      {isEnabled(category) ? children : '-'}
    </TableCell>
  );

  return (
    <Box padding={2} bgcolor={theme.palette.background.default} borderRadius={2}>
      <TableContainer component={Paper}>
        <Table
          aria-label="simple table"
          sx={({ palette }) => ({
            '& > * > * > *': {
              borderLeft: `1px solid ${palette.divider}`,
            },
            '& * [aria-disabled=true]': {
              color: palette.text.disabled,
              backgroundColor: 'unset',
              '&.MuiSvgIcon-root': {
                color: palette.action.disabled,
              },
            },
          })}
          size="small"
        >
          <TableBody>
            <TableRow>
              <TableCell sx={headerSx} align="center" component="th" colSpan={4}>
                Imported and Needs Ratification
              </TableCell>
              <TableCell sx={headerSx} align="center" component="th" rowSpan={2}>
                New
              </TableCell>
              <TableCell sx={headerSx} align="center" component="th" rowSpan={2}>
                To Do
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellHeader category="compliant">Compliant</TableCellHeader>
              <TableCellHeader category="deficient">Deficient</TableCellHeader>
              <TableCellHeader category="n_a"> N/A</TableCellHeader>
              <TableCellHeader category="n_o">N/O</TableCellHeader>
            </TableRow>
            <TableRow>
              <TableCellValue category="compliant">{breakdown.compliant}</TableCellValue>
              <TableCellValue category="deficient">{breakdown.deficient}</TableCellValue>
              <TableCellValue category="n_a">{breakdown.n_a}</TableCellValue>
              <TableCellValue category="n_o">{breakdown.n_o}</TableCellValue>
              <TableCell align="center" component="th">
                {newCount}
              </TableCell>
              <TableCell
                sx={({ palette }) => ({
                  backgroundColor: palette.utils.getBackgroundColor(palette.warning.main),
                })}
                align="center"
                component="th"
              >
                {total}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
