import { Box, MenuItem, Typography } from '@mui/material';

import { useConfirmationDialog } from '../ConfirmationDialog';
import { ImportIcon } from '../Icons';
import { BulkEditOptionProps } from './BulkEditDropdown';

interface BulkEditReimportOptionProps extends BulkEditOptionProps {
  importableCount: number;
  itemName: string;
}

export const BulkEditReImportOption: React.FC<BulkEditReimportOptionProps> = ({
  onSelect,
  disabled,
  itemName,
  importableCount,
}) => {
  const { getConfirmation } = useConfirmationDialog();

  const handleMenuItemClick = async () => {
    const confirmed = await getConfirmation({
      title: 'Reimport All',
      message: (
        <>
          <Typography>
            By selecting this option you will import {importableCount} answers for this item ({itemName}) and discard
            any changes.
          </Typography>
          <br />
          <br />
          <Typography>Do you want to continue?</Typography>
        </>
      ),
      confirmButton: {
        text: `Import ${importableCount} Answers`,
      },
    });

    if (!confirmed) {
      return;
    }

    onSelect('reimportAll');
  };

  return (
    <MenuItem onClick={handleMenuItemClick} dense disabled={disabled}>
      <ImportIcon sx={{ width: 24, height: 24 }} />
      <Box ml={1} component="span" sx={{ textTransform: 'uppercase' }}>
        Import...
      </Box>
    </MenuItem>
  );
};
