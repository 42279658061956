const TRUNCATE_THRESHOLD = 250;

export const truncateLabel = (label: string, maxLength: number = TRUNCATE_THRESHOLD) => {
  return label.length > maxLength ? `${label.substring(0, maxLength).trimRight()}...` : label;
};

export const convertNumberToAlpha = (number: number): string => {
  const ucLetterA = 'A'.charCodeAt(0);
  const ucLetterZ = 'Z'.charCodeAt(0);
  const totalLetters = ucLetterZ - ucLetterA + 1;
  const ucShift = ucLetterA;

  let result = '';

  while (number >= 0) {
    const remainder = number % totalLetters;
    const newAlpha = String.fromCharCode(remainder + ucShift);
    result = newAlpha + result;
    number = Math.floor(number / totalLetters) - 1;
  }

  return result;
};
