import { SxProps } from '@mui/material';

export const masterDetailsSx: SxProps = {
  // this allows for no padding around the MasterDetails table
  '& .ag-details-row': {
    padding: '0px 0px 0px 60px !important',
  },
  '& .ag-layout-auto-height .ag-center-cols-clipper': {
    // this is in order to not have a large gap in details record
    // if you set this to 0, the "No Records To Show" message won't be visible
    minHeight: 45,
  },
};
