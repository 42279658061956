import { CircadianRoute, ROUTES } from '@circadian-risk/routes';
import { USER_ROLE } from '@circadian-risk/shared';
import { lazyLoadWithVersionCheck } from '@web-app/router/lazyLoadWithVersionCheck';

const ReportsPage = lazyLoadWithVersionCheck(() => import('./ReportsPage'));

export const reportsRoutes = (): CircadianRoute[] => [
  {
    pageTitle: 'Reports',
    path: ROUTES.REPORTS,
    component: ReportsPage,
    isExact: true,
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN],
    featureFlag: 'pivot_reports',
  },
];
