import { FormStack } from '@circadian-risk/form';
import { VStack } from '@circadian-risk/presentational';
import { Typography } from '@mui/material';

import { createScheduleAssessmentForm } from '../../form';
import { CadenceSequenceInfo } from './CadenceSequenceInfo';
import { DueAfterFormGroup } from './FormGroups/DueAfterFormGroup';
import { EndOnFormGroup } from './FormGroups/EndOnFormGroup';
import { FrequencyFormGroup } from './FormGroups/FrequencyFormGroup';
import { NotificationsFormGroup } from './FormGroups/NotificationsFormGroup';
import { ScheduleTimeFormGroup } from './FormGroups/ScheduleTimeFormGroup';

const { Text, TextArea } = createScheduleAssessmentForm;

export const CadenceDetailsStep: React.FC = () => {
  return (
    <FormStack>
      <Typography variant="h5">Cadence Details</Typography>
      <Text formKey="name" autoFocus />
      <TextArea formKey="description" />

      <VStack border={1} borderRadius={'4px'} borderColor={theme => theme.palette.secondary.light} p={1.5}>
        <ScheduleTimeFormGroup />
        <DueAfterFormGroup />
        <FrequencyFormGroup />
        <EndOnFormGroup />
        <CadenceSequenceInfo />
      </VStack>

      <NotificationsFormGroup />
    </FormStack>
  );
};
