/* eslint-disable simple-import-sort/imports */
import './wdyr';
import 'mapbox-gl/dist/mapbox-gl.css';

import * as Sentry from '@sentry/react';
import Debug from 'debug';
import { polyfill } from 'mobile-drag-drop';
import { scrollBehaviourDragImageTranslateOverride } from 'mobile-drag-drop/scroll-behaviour';
import { SnackbarProvider } from 'notistack';
import React, { CSSProperties } from 'react';
import smoothscroll from 'smoothscroll-polyfill';

import { NotificationProvider } from '@circadian-risk/front-end-utils';
import { config } from './environments/environment';
import { ErrorPage } from './layouts/Errors';
import MainRouter from './router';
import { CrAppThemeWrapper, reportErrorToSentry } from '@circadian-risk/stores';
import { VersionChecker } from './smart-components/VersionChecker';
import { API_BASE_URL } from './lib/constants';
import { SessionContextProvider } from '@circadian-risk/graphql-utils';
import { ApiClientContextProvider } from '@circadian-risk/api-client-provider';
import { ROUTES } from '@circadian-risk/routes';
import { MapTokenProvider } from '@circadian-risk/maps';
import { FileManagerDatabaseProvider } from '@circadian-risk/file-manager';
import { initSupertokens, useLogoutSubscription } from '@circadian-risk/authentication';
import { SplashScreenSuspense, DatePickerProvider } from '@circadian-risk/presentational';

export const sentryEnabled = Boolean(config.SENTRY_DSN) && process.env.NODE_ENV !== 'test';

polyfill({
  // use this to make use of the scroll behaviour
  dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride,
});

const snackbarStyles: CSSProperties = { pointerEvents: 'all' };

initSupertokens({
  superTokensAppInfo: config.superTokensAppInfo,
  superTokensFrontendDomain: config.superTokensFrontendDomain,
});

/**
 * When there's a new build the old chunks might not be available therefore
 * this handles that scenario and reloads the page
 *
 * Sample of the error
 *
 * @see https://sentry.io/organizations/circadian-risk/issues/2786989185/?environment=production&project=5248055&query=is%3Aunresolved
 */
const sentryFallback: Sentry.FallbackRender = ({ error }) => {
  if (error.name === 'ChunkLoadError') {
    window.location.reload();
    return <></>;
  }
  return <ErrorPage />;
};

const App: React.FC = () => {
  if (process.env.NODE_ENV === 'development') {
    Debug.enable('cr:*');
  }

  useLogoutSubscription();

  // this polyfill allows for smooth scrolling on unsupported browsers (e.g. Safari)
  smoothscroll.polyfill();

  const errorHandler = (error: unknown) => {
    if (sentryEnabled) {
      reportErrorToSentry(error);
    }
  };

  const sessionContextProviderValue = {
    onSessionExpiredRedirectTo: ROUTES.SIGN_IN,
  };

  return (
    <CrAppThemeWrapper>
      <Sentry.ErrorBoundary fallback={sentryFallback}>
        <SnackbarProvider preventDuplicate maxSnack={4} style={snackbarStyles}>
          <NotificationProvider onError={errorHandler}>
            <SplashScreenSuspense>
              <MapTokenProvider token={config.MAPBOX_ACCESS_TOKEN}>
                <ApiClientContextProvider baseUrl={API_BASE_URL}>
                  <VersionChecker />
                  <DatePickerProvider>
                    <FileManagerDatabaseProvider>
                      <SessionContextProvider value={sessionContextProviderValue}>
                        <MainRouter />
                      </SessionContextProvider>
                    </FileManagerDatabaseProvider>
                  </DatePickerProvider>
                </ApiClientContextProvider>
              </MapTokenProvider>
            </SplashScreenSuspense>
          </NotificationProvider>
        </SnackbarProvider>
      </Sentry.ErrorBoundary>
    </CrAppThemeWrapper>
  );
};

export default App;
