import { Box, useTheme } from '@mui/material';
import React from 'react';

import { CriticalityIcon } from '../Icons';
import { Criticality } from './CriticalityDropdownInput';

export interface CriticalityDropdownIconProps {
  criticality?: Criticality;
  maxCriticality: Criticality;
}

export const CriticalityDropdownIcon: React.FC<CriticalityDropdownIconProps> = React.memo(
  ({ criticality, maxCriticality }) => {
    const theme = useTheme();
    const { criticality: criticalityColors } = theme.palette.organizationColors;

    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CriticalityIcon
          color={criticality != null ? criticalityColors[criticality] : undefined}
          criticality={criticality}
          maxCriticality={maxCriticality}
        />
      </Box>
    );
  },
);
