import { FCC } from '@circadian-risk/front-end-utils';
import { zodResolver } from '@hookform/resolvers/zod';
import omit from 'lodash/omit';
import React, { useEffect } from 'react';
import {
  FieldValues,
  // eslint-disable-next-line no-restricted-imports
  useForm,
  UseFormProps,
  UseFormReturn,
} from 'react-hook-form';
import { z, ZodType } from 'zod';

import { AriaLabels, CircadianForm as CircadianFormBase, CircadianFormProps } from './CircadianForm';
import {
  AddressFF,
  AddressFFProps,
  AutocompleteFF,
  AutocompleteFFProps,
  CheckboxFF,
  CheckboxFFProps,
  CheckboxMultiFF,
  CheckboxMultiFFProps,
  CodeFF,
  CodeFFProps,
  DayFF,
  DayFFProps,
  FileFF,
  FileFFProps,
  LargeRadioFF,
  LargeRadioFFProps,
  NumericFF,
  NumericFFProps,
  PasswordFF,
  PasswordFFProps,
  RichTextFF,
  RichTextFFProps,
  SelectFF,
  SelectFFProps,
  TextAreaFF,
  TextAreaFFProps,
  TextFF,
  TextFFProps,
  ToggleFF,
  ToggleFFProps,
} from './FormFields';
import { DateFF, DateFFProps } from './FormFields/DateFF';
import type { LocationFFProps } from './FormFields/LocationFF';
import { TimeFF, TimeFFProps } from './FormFields/TimeFF';
import { GenericFormDialog as GenericFormDialogBase, GenericFormDialogProps } from './GenericFormDialog';
import { SubmitButton } from './SubmitButton';

// lazy loading this otherwise it gets imported way too many times even when form is not even shown on the page
// we should probably do this for every field
const LocationFF = React.lazy(() => import('./FormFields/LocationFF' /* webpackChunkName: "Location Form Field" */));

type CircadianFormOptions = {
  /**
   * If you are using this option, make sure you don't catch errors inside onSubmit passed to the CircadianForm
   * Otherwise form might think it is successful and reset when there is an error
   */
  resetFormOnSubmit: boolean;
};

type UseCircadianForm = <Z extends FieldValues>(
  zodSchema: ZodType<Z>,
  formProps?: UseFormProps<z.infer<ZodType<Z>>, unknown>,
  options?: CircadianFormOptions,
) => UseFormReturn<z.infer<ZodType<Z>>, unknown>;

/**
 * @deprecated It is recommended that you use `generateCircadianForm` instead for an improved Developer XP
 */
const useCircadianForm: UseCircadianForm = (zodSchema, formProps, options = { resetFormOnSubmit: false }) => {
  const formMethods = useForm<z.infer<typeof zodSchema>>({
    resolver: zodResolver(zodSchema),
    reValidateMode: 'onBlur',
    ...omit(formProps, 'resolver'),
  });

  const { formState, reset } = formMethods;
  const { isSubmitSuccessful } = formState;
  const { resetFormOnSubmit } = options;

  // It's recommended to reset inside useEffect after submission.
  // Important: don't catch errors inside onSubmit passed to the useCircadianForm
  // otherwise the form will reset thinking it was a success
  // https://react-hook-form.com/api/useform/reset
  useEffect(() => {
    if (isSubmitSuccessful && resetFormOnSubmit) {
      reset();
    }
  }, [isSubmitSuccessful, reset, resetFormOnSubmit]);

  return formMethods;
};

/**
 * use this function outside of your form component to generate type-safe
 *   - form field components (such as `Text`, `Checkbox`, `CheckboxMulti`, `File`, `Password`, ...)
 *   - `SubmitButton` with correct `form` identifier
 *   - `GenericFormDialog` with correct `id` on the form
 *   - `CircadianForm` with the correct `id`
 *   - `useCircadianForm` which is already setup to use the form schema and only requires `formProps`
 *   - `ariaLabels` which are enforced to be keyed correctly and can be exported from the form component file
 *
 *
 * @argument zodSchema is the Zod schema used to validate the form
 * @argument ariaLabels are the aria labels to use in the form
 *
 * @example
 * const schema = z.object({
 *   email: z.string().email(),
 *   password: z.string(),
 *   remember_me: z.boolean().optional(),
 * });
 *
 * const {
 *   useCircadianForm,
 *   ariaLabels,
 *   Text,
 *   Password,
 *   Checkbox,
 *   CircadianForm,
 *   SubmitButton
 * } = generateCircadianForm(schema, {
 *   form: "Login",
 *   email: 'Email Address',
 *   password:'Password',
 *   remember_me: 'Remember Me',
 *   submit: 'Login'
 * });
 *
 *
 * const LoginForm = () => {
 *   const formMethods = useCircadianForm({
 *     defaultValues: {
 *       email: '',
 *       password: '',
 *       remember_me: false
 *     },
 *   });
 *
 *   // ...
 *
 *   return (
 *     <CircadianForm {...{ formMethods, onSubmit }}>
 *       <FormPaper showBg>
 *         <FormStack>
 *           <FormTitle>{ariaLabels.form}</FormTitle>
 *           <FormStack row>
 *             <Text formKey="email" />
 *             <Text formKey="password" />
 *           </FormStack>
 *           <Checkbox formKey='remember_me' />
 *         </FormStack>
 *         <SubmitButton variant="contained" />
 *       </FormPaper>
 *     </CircadianForm>
 *   );
 * };
 */
export function generateCircadianForm<Z extends FieldValues>(
  zodSchema: ZodType<Z>,
  ariaLabels: AriaLabels<z.infer<ZodType<Z>>>,
) {
  type Form = z.infer<ZodType<Z>>;

  // TODO(marcus-sa)[CR-5684]: lazy load form field components
  const Address: React.FC<AddressFFProps<Form>> = AddressFF;
  const Autocomplete: React.FC<AutocompleteFFProps<Form>> = AutocompleteFF;
  const Checkbox: React.FC<CheckboxFFProps<Form>> = CheckboxFF;
  const CheckboxMulti: React.FC<CheckboxMultiFFProps<Form>> = CheckboxMultiFF;
  const Code: React.FC<CodeFFProps<Form>> = CodeFF;
  const Date: React.FC<DateFFProps<Form>> = DateFF;
  const Day: React.FC<DayFFProps<Form>> = DayFF;
  const File: React.FC<FileFFProps<Form>> = FileFF;
  const LargeRadio: React.FC<LargeRadioFFProps<Form>> = LargeRadioFF;
  const Numeric: React.FC<NumericFFProps<Form>> = NumericFF;
  const Password: React.FC<PasswordFFProps<Form>> = PasswordFF;
  const RichText: React.FC<RichTextFFProps<Form>> = RichTextFF;
  const Select: React.FC<SelectFFProps<Form>> = SelectFF;
  const Text: React.FC<TextFFProps<Form>> = TextFF;
  const TextArea: React.FC<TextAreaFFProps<Form>> = TextAreaFF;
  const Toggle: React.FC<ToggleFFProps<Form>> = ToggleFF;
  const Time: React.FC<TimeFFProps<Form>> = TimeFF;
  const Location: React.FC<LocationFFProps<Form>> = LocationFF;

  const GenericFormDialog: FCC<
    Omit<GenericFormDialogProps<Form>, 'formProps'> & {
      // Optional ariaLabels so that they can be overwrote
      formProps: Omit<GenericFormDialogProps<Form>['formProps'], 'ariaLabels'> &
        Partial<Pick<GenericFormDialogProps<Form>['formProps'], 'ariaLabels'>>;
    }
  > = ({ children, ...props }) => (
    <GenericFormDialogBase {...props} formProps={{ ariaLabels, ...props.formProps }}>
      {children}
    </GenericFormDialogBase>
  );

  const CircadianForm = (
    props: Omit<CircadianFormProps<Z>, 'id' | 'ariaLabels'> & {
      /**
       * Optional ariaLabels override
       */
      ariaLabels?: AriaLabels<z.infer<ZodType<Z>>>;
    },
  ) => <CircadianFormBase {...{ ariaLabels, ...props }} />;

  return {
    /**
     * It is important to provide defaultValues or values otherwise isDirty won't be accurate
     */
    useCircadianForm: (formProps: UseFormProps<Form, unknown>, options?: CircadianFormOptions) =>
      useCircadianForm(zodSchema, formProps, options),
    Address,
    ariaLabels,
    Autocomplete,
    Checkbox,
    CheckboxMulti,
    Code,
    Date,
    Day,
    File,
    GenericFormDialog,
    LargeRadio,
    Numeric,
    Password,
    RichText,
    Select,
    SubmitButton,
    Text,
    TextArea,
    Toggle,
    Time,
    Location,
    CircadianForm,
  };
}
