import get from 'lodash/get';
import { FieldErrors, FieldValues } from 'react-hook-form';

import { FormKeys } from './types';

export const useErrorMessage = <FormData extends FieldValues>(
  errors: FieldErrors<FormData>,
  formKey: FormKeys<FormData>,
): string | undefined => {
  const errorMessage = get(errors, formKey)?.message;
  return errorMessage ? String(errorMessage) : undefined;
};
