import { flushSync } from 'react-dom';
import { createRoot } from 'react-dom/client';

/**
 * Wrapper for when using a custom tooltip in Ag Charts which returns a safely stringified version of
 * the component
 */
export const safeTooltipRenderer = (element: JSX.Element | null) => {
  if (!element) return '';
  const div = document.createElement('div');
  const root = createRoot(div);
  flushSync(() => {
    root.render(element);
  });

  return div.innerHTML;
};
