import RepairIcon from '@mui/icons-material/Build';
import CustomIcon from '@mui/icons-material/Category';
import ReplaceIcon from '@mui/icons-material/CompareArrows';
import RemoveIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/LibraryAdd';
import MoveIcon from '@mui/icons-material/OpenWith';
import UpgradeIcon from '@mui/icons-material/Refresh';
import { Box, Collapse, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { CSSProperties, useRef } from 'react';
import { v4 } from 'uuid';

const recommendationOptions = [
  {
    label: 'Add',
    value: 'add',
    icon: <AddIcon />,
  },
  {
    label: 'Repair',
    value: 'repair',
    icon: <RepairIcon />,
  },
  {
    label: 'Move',
    value: 'move',
    icon: <MoveIcon />,
  },
  {
    label: 'Upgrade',
    value: 'upgrade',
    icon: <UpgradeIcon />,
  },
  {
    label: 'Remove',
    value: 'remove',
    icon: <RemoveIcon />,
  },
  {
    label: 'Replace',
    value: 'replace',
    icon: <ReplaceIcon />,
  },
  {
    label: 'Custom Recommendation',
    value: 'custom',
    icon: <CustomIcon />,
  },
];

interface RecommendationDropdownProps {
  recommendation?: string | null;
  onRecommendationChange: (newRecommendation: string | null) => void;
  recommendationInfo?: string | null;
  onRecommendationInfoChange?: (newRecommendationInfo: string | null) => void;

  disabled?: boolean;
  style?: CSSProperties;
  removeNoRecommendation?: boolean;
  fullWidth?: boolean;
  disableRecommendationInfo?: boolean;
}

export const RecommendationDropdown: React.FC<RecommendationDropdownProps> = ({
  recommendation,
  onRecommendationChange,
  recommendationInfo,
  onRecommendationInfoChange,
  disabled,
  style,
  removeNoRecommendation,
  fullWidth,
  disableRecommendationInfo,
}) => {
  const labelId = useRef(v4());
  const customLabelId = useRef(v4());

  return (
    <>
      <FormControl variant={disabled ? 'standard' : 'outlined'} fullWidth disabled={disabled}>
        <InputLabel id={labelId.current}>Recommendation</InputLabel>
        <Select
          sx={{ bgcolor: theme => theme.palette.background.paper }}
          variant={disabled ? 'standard' : 'outlined'}
          displayEmpty
          value={recommendation ?? ''}
          onChange={e => onRecommendationChange(e.target.value as string)}
          labelId={labelId.current}
          style={style}
          fullWidth={fullWidth}
          label={'Recommendation'}
        >
          {!removeNoRecommendation && (
            <MenuItem value={''}>
              <Box display="flex" flexDirection="row" alignItems="center">
                &nbsp;
              </Box>
            </MenuItem>
          )}

          {recommendationOptions.map(option => {
            return (
              <MenuItem value={option.value} key={option.value}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Box mr={1}>{option.icon}</Box>
                  <span>{option.label}</span>
                </Box>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {!disableRecommendationInfo && (
        <Collapse in={Boolean(recommendation)}>
          <Box mt={2}>
            <TextField
              label={recommendationOptions.find(e => e.value === recommendation)?.label}
              fullWidth
              multiline
              variant={disabled ? 'standard' : 'outlined'}
              name={customLabelId.current}
              value={recommendationInfo ?? ''}
              disabled={disabled}
              onChange={e => {
                if (!onRecommendationInfoChange) {
                  return;
                }
                onRecommendationInfoChange(e.target.value);
              }}
            />
          </Box>
        </Collapse>
      )}
    </>
  );
};
