import { VStack } from '@circadian-risk/presentational';
import { Box, Typography } from '@mui/material';
import React from 'react';

import { ImportedAnswersContent } from './components/ImportedAnswersContent';
import { LocationsContent } from './components/LocationsContent';
import { ScheduleContent } from './components/ScheduleContent';
import { ScheduledSmartAssignmentSummary } from './components/ScheduledSmartAssignmentSummary';

export const SummaryStep: React.FC = () => {
  return (
    <Box>
      <Typography variant="h5" mb={2}>
        Details
      </Typography>
      <VStack spacing={3}>
        <ScheduleContent />
        <ScheduledSmartAssignmentSummary />
        <LocationsContent />
        <ImportedAnswersContent />
      </VStack>
    </Box>
  );
};
