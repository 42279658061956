import { CameraAlt, LibraryAdd, Message } from '@mui/icons-material';
import { Box, Tooltip, useTheme } from '@mui/material';

import { CellRendererParams } from '../helpers';

export interface QuestionTooltipCellRow {
  recommendation?: string | null;
  description?: string | null;
  fileCount?: number;
}

export const QuestionTooltipCell: React.FC<CellRendererParams<QuestionTooltipCellRow>> = ({ data }) => {
  const theme = useTheme();
  const { fileCount, recommendation, description } = data;
  const hasFiles = fileCount != null && fileCount > 0;

  return (
    <Box
      sx={{
        paddingTop: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        color: 'black',
        '& svg': {
          marginRight: theme.spacing(1),
        },
      }}
    >
      {hasFiles && (
        <Tooltip title={`${fileCount} files`} placement="top">
          <CameraAlt />
        </Tooltip>
      )}
      {description && (
        <Tooltip title={description} placement="top">
          <Message />
        </Tooltip>
      )}
      {recommendation && (
        <Tooltip title={recommendation} placement="top">
          <LibraryAdd />
        </Tooltip>
      )}
    </Box>
  );
};
