import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment, TextField, TextFieldProps, Tooltip } from '@mui/material';
import debounce from 'lodash/debounce';
import React, { useRef, useState } from 'react';

export const BLANK_SEARCH = '';

export type ExpandableSearchProps = {
  /** @default medium */
  size?: 'small' | 'medium' | 'large';
  onChange: (search: string) => void;
} & Omit<TextFieldProps, 'onChange' | 'size'> &
  Required<Pick<TextFieldProps, 'value'>>;

export const ExpandableSearch: React.FC<ExpandableSearchProps> = ({
  onChange,
  size = 'medium',
  fullWidth,
  ...textFieldProps
}) => {
  const { value } = textFieldProps;
  const searchRef = useRef<HTMLInputElement>();

  const [focused, setFocused] = useState(Boolean(value));

  // got these values from Button component so they can match
  const height = {
    small: 32,
    medium: 36.5,
    large: 42.25,
  }[size];

  const dynamicWidth = !focused && value === BLANK_SEARCH ? 52 : 200;

  const handleBlur = debounce(() => {
    setFocused(false);
  }, 10);

  return (
    <TextField
      onFocus={() => setFocused(true)}
      onBlur={handleBlur}
      autoComplete="off"
      placeholder="Search..."
      variant="outlined"
      onChange={({ target: { value } }) => onChange(value)}
      inputRef={searchRef}
      InputProps={{
        sx: theme => ({
          minWidth: 52,
          transition: theme.transitions.create('width'),
          width: fullWidth ? '100%' : dynamicWidth,
          height,
          ':hover': {
            cursor: textFieldProps.disabled ? 'unset' : 'pointer',
          },
        }),
        startAdornment: (
          <InputAdornment position="start" onClick={() => searchRef.current?.focus()}>
            <SearchIcon fontSize={size === 'small' ? 'small' : 'medium'} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {value !== BLANK_SEARCH && (
              <Tooltip title="Reset">
                <IconButton size={size} onClick={() => onChange(BLANK_SEARCH)}>
                  <CloseIcon fontSize={size === 'small' ? 'small' : 'medium'} />
                </IconButton>
              </Tooltip>
            )}
          </InputAdornment>
        ),
      }}
      {...textFieldProps}
    />
  );
};
