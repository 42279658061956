import { Popover, PopoverProps } from '@mui/material';
import React from 'react';

import { IconSelector, LibIcon } from '../..';

export interface IconSelectorPopoverProps {
  icons: LibIcon[];
  anchorEl: PopoverProps['anchorEl'];
  itemCategoryName?: string;
  selectedId?: number;
  onClose: () => void;
  onIconSelect: (iconId: number) => void;
  onIconClear?: () => void;
}

export const IconSelectorPopover: React.FC<IconSelectorPopoverProps> = ({
  anchorEl,
  icons,
  itemCategoryName,
  selectedId,
  onIconSelect,
  onIconClear,
  onClose,
}) => {
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      transformOrigin={{
        horizontal: 'center',
        vertical: 'top',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <IconSelector
        sx={{ maxWidth: 400 }}
        icons={icons}
        itemCategoryName={itemCategoryName}
        selectedId={selectedId}
        onIconSelect={onIconSelect}
        onIconClear={onIconClear}
      />
    </Popover>
  );
};
