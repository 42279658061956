import { ICustomOption, ILibraryOption } from '@circadian-risk/assessment-components';
import { Enum_Consideration_Type_Enum } from '@circadian-risk/graphql-types';

type FreeformOption = {
  id: string;
  title?: string | null;
  type?: Enum_Consideration_Type_Enum | null;
  description?: string | null;
  created_at: string;
};

type InnerLibraryOption = {
  nodes?: { node_id: string }[];
  description?: string | null;
  name?: string | null;
  id: number;
  type?: Enum_Consideration_Type_Enum | null;
  photo?: { id: string; filepath: string } | null;
};

type LibraryOption = {
  id: number;
  created_at: string;
  libraryConsiderationOption?: InnerLibraryOption | null;
};

export const createAnswerOptions = (freeform_options: FreeformOption[], library_options: LibraryOption[]) => {
  const freeFormOptions = freeform_options.map<ICustomOption>(ffo => ({
    source: 'custom',
    id: ffo.id,
    title: ffo.title ?? '',
    type: ffo.type as Enum_Consideration_Type_Enum,
    description: ffo.description ?? '',
    createdAt: new Date(ffo.created_at),
  }));

  const libraryOptions = library_options.map<ILibraryOption>(lo => ({
    source: 'library',
    id: lo.id,
    type: lo.libraryConsiderationOption?.type ?? 'custom',
    locationIds: (lo.libraryConsiderationOption?.nodes ?? []).map(n => n.node_id),
    description: lo.libraryConsiderationOption?.description ?? undefined,
    title: lo.libraryConsiderationOption?.name ?? '',
    createdAt: new Date(lo.created_at),
    libraryId: lo.libraryConsiderationOption!.id,
    photoSrc: lo.libraryConsiderationOption?.photo?.filepath ?? undefined,
  }));

  return [...freeFormOptions, ...libraryOptions];
};
