import { IconButton, IconButtonProps, Tooltip } from '@mui/material';

interface IconButtonWithLabelProps extends IconButtonProps {
  label: string;
}

export const IconButtonWithLabel: React.FC<IconButtonWithLabelProps> = ({ label, ...props }) => (
  <Tooltip title={label}>
    <IconButton {...props} aria-label={label} />
  </Tooltip>
);
