import { Typography, TypographyProps } from '@mui/material';
import isString from 'lodash/isString';
import React from 'react';

export interface TypographyClampedProps extends TypographyProps {
  lines: number;
  /**
   * Controls whether to show native browser tooltip on hover via `title` attribute.
   */
  disableTooltip?: boolean;
}

/**
 * A Typography with additional prop `lines` that will not let the text grow more than given number of lines. The overflow will be displayed with ellipsis.
 */
export const TypographyClamped: React.FC<TypographyClampedProps> = ({
  children,
  disableTooltip,
  ...typographyProps
}) => {
  if (isString(children)) {
    typographyProps.title = children;
  }

  if (disableTooltip) {
    typographyProps.title = '';
  }

  return (
    <Typography
      {...typographyProps}
      sx={{
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitLineClamp: typographyProps.lines,
        WebkitBoxOrient: 'vertical',
        wordBreak: 'break-word',
        hyphens: 'auto',
        ...typographyProps.sx,
      }}
    >
      {children}
    </Typography>
  );
};
