// turn this into a react component if we end up keeping this
// source: https://blog.ag-grid.com/wrapping-column-header-text/
export const headerTemplateVariableHeight =
  '<div class="ag-cell-label-container" role="presentation">' +
  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
  '  </div>' +
  '</div>';
