export const CIRCADIAN_RISK_LIBRARY_ORG_ID = '00000000-0000-0000-0000-000000000000';

/**
 * One billion. This is a number team agreed that can be used as the upper bound for ranges such as:
 * - Number of Locations
 * - Number of Employees
 * - Number of Assessments
 */
export const MAX_NUMBER_FOR_RANGES = 1000000000;

export const SUPPORT_EMAIL = 'support@circadianrisk.com';

export type RiskThresholds = 'inadequate' | 'optimal' | 'borderline';
