import React from 'react';
import * as z from 'zod';

export enum DisplayTypes {
  address = 'address',
  avatar = 'avatar',
  assessmentsegments = 'assessmentsegments',
  boolean = 'boolean',
  chipgroup = 'chipgroup',
  currency = 'currency',
  custom = 'custom',
  date = 'date',
  datetime = 'datetime',
  icon = 'icon',
  image = 'image',
  keyvalue = 'keyvalue',
  numeric = 'numeric',
  password = 'password',
  string = 'text',
  text = 'text',
  time = 'time',
  users = 'users',
  toggle = 'toggle',
}

export enum AssessmentState {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  InReview = 'InReview',
  Complete = 'Complete',
  Blocked = 'Blocked',
  Abandoned = 'Abandoned',
  Locked = 'Locked',
}

export const AddressSchema = z.object({
  city: z.union([z.ostring(), z.null()]),
  country: z.union([z.ostring(), z.null()]),
  state: z.union([z.ostring(), z.null()]),
  street1: z.union([z.ostring(), z.null()]),
  street2: z.union([z.ostring(), z.null()]),
  zip: z.union([z.ostring(), z.null()]),
});

export type AddressConfig = z.infer<typeof AddressSchema>;
export type SimpleValue = string | number | boolean;
export type KeyValue = { [key: string]: SimpleValue };
export type DisplayValue = SimpleValue | File | AddressConfig | KeyValue | KeyValue[];

export interface File {
  id: string;
  filepath: string | null;
}

export type RenderableComponentType = Element | React.ElementType | React.ReactNode;
export type RenderableType = RenderableComponentType | string;

export enum USER_ROLE {
  APP_USER = 'app-user',
  CIRCADIAN_ADMIN = 'circadian-admin',
  ORGANIZATION_ADMIN = 'organization-admin',
  MEMBER = 'organization-member',
  LOCATION_MANAGER = 'location-manager',
}

export const ASSIGNABLE_USER_ROLES = [USER_ROLE.ORGANIZATION_ADMIN, USER_ROLE.MEMBER];

export type NonEmptyArr<T> = [T, ...T[]];
