import { Enum_Feature_Flags_Enum } from '@circadian-risk/graphql-types';
import { USER_ROLE } from '@circadian-risk/shared';
import isString from 'lodash/isString';
import { RouteProps } from 'react-router-dom';

export enum ROUTES_LOCAL {
  INDETERMINATE_ORG_SECTION = '/organization/:organizationId',
  SIGN_IN = '/login',
  SIGN_UP = '/sign-up',
  SSO = '/sso',
  RESET_PASSWORD = '/reset-password',
  FORGOT_PASSWORD = '/forgot-password',
  CREATE_ORG = '/create-org',
  ACTIVATE = '/activate',
  FAILED_LOGIN = '/failed-login',
  PROFILE = '/profile',
  ORGANIZATION_USER_PROFILE = '/organization/:organizationId/user/:id',
  ORGANIZATION_PROFILE = '/organization/:organizationId/profile',
  USER_PROFILE_EDIT = '/user/:id/edit',
  CHANGE_PASSWORD = '/change-password',
  WILDCARD = '*',
  DATAVIS = '/datavis',
  ROOT = '/organization/:organizationId/dashboard',
  ROOT2 = '/organization/:organizationId/dashboard2',
  UI_LIBRARY = '/ui-library',

  ASSESSMENTS = '/organization/:organizationId/assessments',
  NEW_PERFORM_ASSESSMENT_BASE = `/organization/:organizationId/assessments/:assessmentId/perform`,
  PERFORM_ASSESSMENT = `/organization/:organizationId/assessments/:assessmentId/perform/:nodeId`,
  ASSESSMENTS_NEW = '/organization/:organizationId/assessments/new',
  // TODO(v-rrodrigues)[CR-4920]
  ASSESSMENTS_NEW_SINGLE = '/organization/:organizationId/assessments/new/single',
  ASSESSMENTS_NEW_SCHEDULED = '/organization/:organizationId/assessments/new/scheduled',
  ASSESSMENTS_DETAIL = '/organization/:organizationId/assessments/:id',
  ASSESSMENTS_EDIT = '/organization/:organizationId/assessments/:id/edit',
  ASSESSMENTS_GENERATE_REPORT_LANDING = '/organization/:organizationId/assessments/:assessmentId/report',
  ASSESSMENTS_GENERATE_REPORT_BY_ID = '/organization/:organizationId/assessments/:assessmentId/report/:reportId',

  ITEM_CATEGORIES = '/organization/:organizationId/item-categories',
  ITEM_CATEGORIES_DETAIL = '/organization/:organizationId/item-categories/:id',
  ITEM_CATEGORIES_EDIT = '/organization/:organizationId/item-categories/:id/edit',
  ITEMS_DETAIL = '/organization/:organizationId/items/:id',
  INSPECTION_ITEMS = '/organization/:organizationId/inspection-items',
  INTERVIEW_ITEMS = '/organization/:organizationId/interview-items',
  LOCATION_ITEMS = '/organization/:organizationId/items',
  REPORTS = '/organization/:organizationId/reports',
  USER_GENERATED_REPORTS = '/organization/:organizationId/reports#user-generated-reports',
  PIVOT = '/organization/:organizationId/pivot',
  FILTERS = '/organization/:organizationId/location-filters',
  LOCATION_FILTERS_NEW = '/organization/:organizationId/location-filters/new',
  LOCATION_FILTERS_EDIT = '/organization/:organizationId/location-filters/:filterId',
  LAYERS = '/organization/:organizationId/layers',
  LAYER_DETAIL = '/organization/:organizationId/layers/:id',

  NODES = '/organization/:organizationId/locations',
  NODES_DETAIL = '/organization/:organizationId/locations/:id',
  NODES_SCENARIOS = '/organization/:organizationId/locations/:id/scenarios',

  NODES_INHERENT_RISK = '/organization/:organizationId/locations-inherent-risk',
  ROLES = '/organization/:organizationId/roles',
  USERS = '/organization/:organizationId/users',
  PERMISSIONS_OVERVIEW = '/organization/:organizationId/permissions-overview',
  ORGANIZATIONS = '/organization/:organizationId/organizations',
  ORGANIZATION_SWITCH = '/switch',
  ASSESSMENT_TEMPLATES = '/organization/:organizationId/assessment-templates',
  QUESTION_SETS = '/organization/:organizationId/question-sets',
  QUESTIONS = '/organization/:organizationId/questions',
  FILES = '/organization/:organizationId/files',

  SCENARIOS = '/organization/:organizationId/scenarios',
  SCENARIOS_DETAIL = '/organization/:organizationId/scenarios/:id',
  SCENARIOS_DETAIL_INHERENT_RISK = '/organization/:organizationId/scenarios/:id/inherent-risk',
  SCENARIOS_DETAIL_SETTINGS = '/organization/:organizationId/scenarios/:id/settings',
  SCENARIO_CATALOG_LIST = '/organization/:organizationId/scenario-catalog',
  SCENARIO_CATALOG_DETAILS = '/organization/:organizationId/scenario-catalog/:id',
  SCENARIO_QUESTION_SET = '/organization/:organizationId/scenarios/:id/question-sets/:questionSetId',

  INHERENT_RISK = '/organization/:organizationId/inherent-risk',
  DEFICIENCIES_BY_QUESTION = '/organization/:organizationId/deficiencies-by-question',

  ADMIN_DASHBOARD = '/admin/dashboard',
  ADMIN_LAYERS = '/admin/layers',
  ADMIN_NODES = '/admin/nodes',
  ADMIN_ROLES = '/admin/roles',
  ADMIN_USERS = '/admin/users',
  ADMIN_ORGANIZATIONS = '/admin/organizations',
  ADMIN_ORGANIZATION_IMPORT = '/admin/import',

  NEW_SCENARIO_DASHBOARD = '/organization/:organizationId/scenarios/:scenarioId/dashboard',
  NEW_SCENARIO_DASHBOARD_PRINTABLE = '/organization/:organizationId/scenarios/:scenarioId/dashboard/print',
  SCENARIO_ASSESSMENT_TEMPLATES_NEW = '/organization/:organizationId/scenario-assessment-templates/new',
  SCENARIO_ASSESSMENT_TEMPLATES = '/organization/:organizationId/scenario-assessment-templates',
  SCENARIO_ASSESSMENT_TEMPLATES_DETAIL = '/organization/:organizationId/scenario-assessment-templates/:id',

  TASKS = '/organization/:organizationId/tasks',
  TASKS_DETAILS = '/organization/:organizationId/tasks/:taskNo',
  TASKS_ACTION_DETAILS = '/organization/:organizationId/tasks/:taskNo/actions/:taskActionId',
  OPTIONS_FOR_CONSIDERATION = '/organization/:organizationId/options-for-consideration',
  PROJECTS = '/organization/:organizationId/projects',
  PROJECT = '/organization/:organizationId/projects/:projectId',
  SCHEDULED_ASSESSMENTS = '/organization/:organizationId/scheduled-assessments',
}

// External routes
export const ROUTES_EXTERNAL = {
  API_DOCS: (baseUrl: string) => `${baseUrl}/api/docs/`,
  INSTRUCTION_GUIDES:
    'https://circadianrisk.atlassian.net/wiki/spaces/CS2/pages/750518274/Instruction+Articles+for+Circadian+Risk+Software',
  RAISE_REQUEST: 'https://circadianrisk.atlassian.net/servicedesk/customer/portal/3/group/-1',
};

export const ROUTES = {
  ...ROUTES_LOCAL,
  ...ROUTES_EXTERNAL,
};

/**
 *
 * @param routeString any string
 *
 * results in, for example:
 * routeString '/buildings' -> output '/buildings'
 * routeString '/buildings/:id' -> output '/buildings' (desired match)
 * routeString '/buildings/:id/edit' -> output '/buildings' (desired match)
 * routeString '/' -> output '/'
 * routeString '/badRoute' -> output '/badRoute'
 */
const routeRootMatches = (routeString: string): boolean => {
  const routeRoot = routeString
    .replace(/\/((organization\/((:organizationId)|[\w-])+\/)|(admin)\/)/g, '')
    .split('/')
    .filter(x => Boolean(x))[0];
  const routeStringWithoutPrefix = routeString.replace(/\/organization\/[\w-]+\//g, '/organization/:organizationId/');
  const matcher = routeStringWithoutPrefix.split(routeRoot)[0].concat(routeRoot);
  return Object.values(ROUTES).includes(matcher || '');
};

export const isValidRoute = (potentialRoute: unknown): potentialRoute is typeof ROUTES => {
  if (potentialRoute === '/') {
    return true;
  }
  const isValid = isString(potentialRoute) && routeRootMatches(potentialRoute);
  return isValid;
};

export const getRouteForResource = (resourceName: string): ROUTES_LOCAL | undefined => {
  const uppercaseRouteKey = resourceName.toUpperCase() as 'NODES';
  if (ROUTES_LOCAL[uppercaseRouteKey]) {
    return ROUTES_LOCAL[uppercaseRouteKey];
  }
  if (resourceName === 'items') {
    return ROUTES_LOCAL.LOCATION_ITEMS;
  }
  if (resourceName === 'itemCategories') {
    return ROUTES_LOCAL.ITEM_CATEGORIES;
  }
  if (resourceName === 'assessmentTemplates') {
    return ROUTES_LOCAL.ASSESSMENT_TEMPLATES;
  }
  return;
};

export interface AuthenticatedRouteProps extends RouteProps {
  isPublic?: boolean;
  allowRoles?: string[];
}

export interface CircadianRoute {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any; // any renderable thing
  isExact?: boolean;
  isPublic?: boolean;
  path: ROUTES_LOCAL | string;
  pageTitle?: string;
  allowRoles?: USER_ROLE[];
  featureFlag?: Enum_Feature_Flags_Enum;
  isCircadianAdminOnly?: boolean;
}
