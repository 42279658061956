import { createContext, useContext } from 'react';

interface SessionContextProps {
  onSessionExpiredRedirectTo: string;
}

const sessionContext = createContext<SessionContextProps>({
  onSessionExpiredRedirectTo: '',
});

export const useSessionContext = () => useContext(sessionContext);

export const { Provider: SessionContextProvider } = sessionContext;
