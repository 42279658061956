import { screen, within } from "@testing-library/react";
import userEvent from "@testing-library/user-event";

/**
 * selectOptionByLabel selects an option in a select component from material ui
 * @param selectBox the select box element target by @testing-library byRole query with the role of 'combobox'
 * @param optionText the text / Regex pattern of the option label to select
 */
 export const selectOptionFromMuiSelectBox = (selectBox: HTMLElement, optionText: string | RegExp) => {
  userEvent.click(selectBox);
  const listboxControlId = selectBox.getAttribute('aria-controls');
  if (!listboxControlId) {
    throw new Error('Select box does not have aria-controls attribute to find associated listbox');
  }
  const listBoxes = screen.getAllByRole('listbox', { hidden: false });
  const listBox = listBoxes.find((lb) => lb.id === listboxControlId);
  if (!listBox) {
    throw new Error(`Could not find listbox with id ${listboxControlId}`);
  }
  userEvent.click(within(listBox).getByText(optionText));
}