import { Box, ButtonBase, ClickAwayListener, Tooltip, TooltipProps, useMediaQuery } from '@mui/material';
import React, { ReactElement, useCallback } from 'react';

export interface TouchFriendlyTooltipProps {
  tooltipContent: TooltipProps['title'] | undefined;
  children: ReactElement;
  tooltipProps?: Omit<TooltipProps, 'children' | 'title' | 'classes'>;
  ariaLabel?: string;
  disableWrappers?: boolean;
  tooltipMaxWidth?: number | string;
}

/**
 * Tooltip wrapper that will display content on hover for devices with ability to hover
 * but show tooltip on tap for devices without ability to hover (e.g iPad, iPhone)
 */
export const TouchFriendlyTooltip: React.FC<TouchFriendlyTooltipProps> = ({
  tooltipContent,
  tooltipProps,
  tooltipMaxWidth,
  children,
  ariaLabel = 'tooltip',
  disableWrappers,
}) => {
  const ariaProps = {
    role: 'tooltip',
    'aria-label': ariaLabel,
  };

  const hasHover = useMediaQuery('@media (hover: hover)');
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = useCallback(() => setOpen(false), []);
  const handleTooltipOpen = useCallback(() => setOpen(true), []);

  if (!tooltipContent) {
    return children;
  }

  const hoverContent = (
    <Tooltip
      arrow
      title={tooltipContent}
      placement={'top'}
      {...ariaProps}
      {...tooltipProps}
      slotProps={{
        tooltip: {
          sx: {
            maxWidth: tooltipMaxWidth ?? 300,
          },
        },
      }}
    >
      {disableWrappers ? (
        children
      ) : (
        <Box display="flex" component="span" justifyContent="center" alignItems="center">
          {children}
        </Box>
      )}
    </Tooltip>
  );

  const touchContent = (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        arrow
        open={open}
        title={tooltipContent}
        slotProps={{
          tooltip: {
            sx: {
              maxWidth: tooltipMaxWidth ?? 300,
            },
          },
        }}
        {...tooltipProps}
        {...ariaProps}
        placement={'top'}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        {disableWrappers ? (
          <Box {...children.props} onClick={handleTooltipOpen} role="tooltip">
            {children}
          </Box>
        ) : (
          <ButtonBase disableTouchRipple onClick={handleTooltipOpen} component="div" role="tooltip">
            {children}
          </ButtonBase>
        )}
      </Tooltip>
    </ClickAwayListener>
  );

  const content = hasHover ? hoverContent : touchContent;
  const contentWithWrapper = (
    <Box display="inline-flex" component="span">
      {content}
    </Box>
  );

  return disableWrappers ? content : contentWithWrapper;
};
