import { WeekdayType } from '@circadian-risk/api-contract';
import sortBy from 'lodash/sortBy';

/**
 * Mapping of abbreviated weekday names to a sorting order index
 * Useful for determining the next weekday in a sorted manner
 */
export const weekdayIndex: Record<WeekdayType, number> = {
  mon: 1,
  tues: 2,
  wed: 3,
  thur: 4,
  fri: 5,
  sat: 6,
  sun: 0,
};

/**
 * Mapping of weekday full names to their respective index (0-6)
 */
export const fullWeekdayMap: Record<string, number> = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};

export const weekNameByEnum: Record<WeekdayType, string> = {
  mon: 'Monday',
  tues: 'Tuesday',
  wed: 'Wednesday',
  thur: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday',
  sun: 'Sunday',
};

export const weekSortOrdinalByName: Record<WeekdayType, number> = {
  mon: 1,
  tues: 2,
  wed: 3,
  thur: 4,
  fri: 5,
  sat: 6,
  sun: 7,
};

export const sortWeekdays = (weekdays: WeekdayType[]) => {
  return sortBy(weekdays, weekday => weekSortOrdinalByName[weekday]);
};

export const getWeekdayOrdinal = (weekday: WeekdayType) => {
  return weekSortOrdinalByName[weekday];
};
