import { FCC } from '@circadian-risk/front-end-utils';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { useBackUpOrganizationIdFromLocation } from '@web-app/lib/appHelpers';
import has from 'lodash/has';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

const replaceWithActiveOrgId = (link: NavLinkProps['to'], activeOrgId: string): string | object => {
  if (isString(link)) {
    link = link.toString().replace(':organizationId', activeOrgId);
  } else if (isObject(link) && has(link, 'pathname')) {
    // @ts-ignore
    link.pathname = (link?.pathname ?? '').toString().replace(':organizationId', activeOrgId);
  }
  return link;
};

export interface NavLinkWithActiveOrgIdProps extends Omit<NavLinkProps, 'to'> {
  to?: NavLinkProps['to'];
}

type LinkWithOrgIdProps = {
  href: string;
  sx?: SxProps<Theme>;
  anchorProps?: NavLinkWithActiveOrgIdProps;
};

export const LinkWithOrgId: FCC<LinkWithOrgIdProps> = ({ children, href, sx, anchorProps }) => {
  const activeOrgId = useBackUpOrganizationIdFromLocation();
  const realTo = activeOrgId ? href.replace(':organizationId', activeOrgId) : href;

  // TODO(v-rrodrigues)[CR-4472]: React router v6 recommends switching to a normal <a> element
  return (
    <Box component="span" sx={sx}>
      <NavLink {...anchorProps} to={realTo}>
        {children}
      </NavLink>
    </Box>
  );
};

const NavLinkWithActiveOrgId: React.FC<NavLinkWithActiveOrgIdProps> = ({ ...props }) => {
  const activeOrgId = useBackUpOrganizationIdFromLocation();

  if (!props.to) {
    return <Typography>{props.children}</Typography>;
  }

  const realTo = activeOrgId ? replaceWithActiveOrgId(props.to, activeOrgId) : props.to;
  return <NavLink {...props} to={realTo} />;
};
export default NavLinkWithActiveOrgId;

export const useRouteWithActiveOrgId = (to: string) => {
  const activeOrgId = useBackUpOrganizationIdFromLocation();
  return activeOrgId ? replaceWithActiveOrgId(to, activeOrgId) : to;
};
