import { humanFormatDateTime } from '@circadian-risk/shared';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LockClockIcon from '@mui/icons-material/LockClock';
import ScheduleIcon from '@mui/icons-material/Schedule';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import WarningIcon from '@mui/icons-material/Warning';
import { Chip, SxProps, Theme, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';

interface TimeChipProps {
  completed: boolean;
  /**
   * Indicates if the assessment is locked and the user is an assessor/non-org admin
   * @default false
   */
  lockedAndAssessor?: boolean;
  dueDate: Date | string;
  onClick?: () => void;
  /**
   * @default false
   */
  includeTime?: boolean;
}

export const TimeChip: React.FC<TimeChipProps> = ({
  completed,
  lockedAndAssessor = false,
  dueDate,
  onClick,
  includeTime = false,
}) => {
  const dueDateDayJS = dayjs(dueDate);
  const todayDayJs = dayjs().endOf('day');
  const dateStr = humanFormatDateTime(dueDate, includeTime);
  const dayDiff = dueDateDayJS.diff(todayDayJs, 'days');

  let sx: SxProps<Theme>;
  let icon: React.ReactElement<unknown>;

  if (lockedAndAssessor) {
    icon = (
      <Tooltip
        placement="top"
        arrow
        title="This assessment is past its scheduled due date and can't be changed. To unlock please contact the Circadian Risk team or your administrator."
      >
        <LockClockIcon />
      </Tooltip>
    );
    sx = theme => ({
      backgroundColor: theme.palette.error.background,
      borderColor: theme.palette.error.light,
      color: theme.palette.error.light,
      '& .MuiChip-icon': {
        color: theme.palette.error.light,
      },
    });
  } else if (completed) {
    icon = <CheckCircleIcon />;
    sx = theme => ({
      backgroundColor: theme.palette.primary.background,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      '& .MuiChip-icon': {
        color: theme.palette.primary.main,
      },
    });
  } else if (dayDiff >= 7) {
    icon = <ScheduleIcon />;
    sx = theme => ({
      backgroundColor: theme.palette.info.background,
      borderColor: theme.palette.info.light,
      color: theme.palette.info.light,
      '& .MuiChip-icon': {
        color: theme.palette.info.light,
      },
    });
  } else if (dayDiff < 0) {
    icon = <WarningIcon />;
    sx = theme => ({
      backgroundColor: theme.palette.error.background,
      borderColor: theme.palette.error.light,
      color: theme.palette.error.light,
      '& .MuiChip-icon': {
        color: theme.palette.error.light,
      },
    });
  } else {
    icon = <TimelapseIcon />;
    sx = theme => ({
      backgroundColor: theme.palette.warning.background,
      borderColor: theme.palette.warning.main,
      color: theme.palette.warning.main,
      '& .MuiChip-icon': {
        color: theme.palette.warning.main,
      },
    });
  }

  return <Chip aria-label={'time chip'} icon={icon} variant="outlined" label={dateStr} onClick={onClick} sx={sx} />;
};
