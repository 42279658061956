import { formatBytes, MAX_FILE_SIZE_BYTES } from '@circadian-risk/shared';
import { CloudUpload } from '@mui/icons-material';
import { alpha, Box, SxProps, Theme, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import clsx from 'clsx';
import remove from 'lodash/remove';
import React, { InputHTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { v4 } from 'uuid';

import { FileAttachmentStatus } from '../FileAttachments';
import { FilesAddedHandler, FileUploadHandler } from '../FileAttachmentsUploader';
import { FileUploadRejections } from './FileUploadRejections';

export interface UploadingFile {
  id: string;
  filename: string;
  fileSize: number;
  status: FileAttachmentStatus;
  uploadProgress: number;
  onRemove?: () => void;
}

export type UploadingFileFile = UploadingFile & { file: File; cancelTokenSource?: CancelTokenSource };

export interface FileUploadDropzoneProps {
  className?: string;
  onFilesAdded?(file: File[]): void;
  onFileCancel?(fileId: string): void;
  maxSize?: number;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
}

export const useFileUploadDropzone = (
  uploadFile: FileUploadHandler,
  entityId?: string,
): [UploadingFile[], FilesAddedHandler, () => void] => {
  const [uploadingFiles, setUploadingFiles] = useState<UploadingFileFile[]>([]);

  const currentUploadingFiles = useRef<UploadingFileFile[]>([]);
  currentUploadingFiles.current = uploadingFiles;

  const clearUploadingFiles = () => {
    currentUploadingFiles.current.forEach(file => {
      if (file.cancelTokenSource) {
        file.cancelTokenSource.cancel();
      }
    });
  };

  useEffect(() => {
    // Reset if the entity is changed since we are uploading for a different file now
    setUploadingFiles([]);

    return clearUploadingFiles;
  }, [entityId, currentUploadingFiles]);

  const getUploadingFile = (id: string): UploadingFileFile | undefined => {
    const updatedFiles = [...currentUploadingFiles.current];
    return updatedFiles.find(f => f.id === id);
  };

  const onRemove = (id: string) => {
    const updatedFiles = [...currentUploadingFiles.current];
    // eslint-disable-next-line lodash/prefer-immutable-method
    const removed = remove(updatedFiles, f => f.id === id);
    removed.forEach(file => {
      if (file.cancelTokenSource) {
        file.cancelTokenSource.cancel();
      }
    });
    setUploadingFiles(updatedFiles);
  };

  const handleFilesAdded: FilesAddedHandler = async (filesToUpload: File[]) => {
    const files: UploadingFileFile[] = [...currentUploadingFiles.current];
    const filesToUploadFiles: UploadingFileFile[] = [];

    // Create uploading files
    for (const fileToUpload of filesToUpload) {
      const id = v4();
      const newFile: UploadingFileFile = {
        id, // temporary unique id until it gets loaded
        filename: fileToUpload.name,
        status: FileAttachmentStatus.Uploading,
        fileSize: fileToUpload.size,
        file: fileToUpload,
        uploadProgress: 1,
        cancelTokenSource: axios.CancelToken.source(),
        onRemove: () => onRemove(id),
      };
      filesToUploadFiles.push(newFile);
    }

    setUploadingFiles([...files, ...filesToUploadFiles]);

    // Trigger upload
    for (const newFile of filesToUploadFiles) {
      const config: AxiosRequestConfig = {
        onUploadProgress: (progressEvent: ProgressEvent) => {
          const uploadingFile = getUploadingFile(newFile.id);
          if (uploadingFile) {
            uploadingFile.uploadProgress = (progressEvent.loaded / progressEvent.total) * 100;
          }
          setUploadingFiles([...currentUploadingFiles.current]);
        },
        cancelToken: newFile.cancelTokenSource?.token,
      };

      try {
        await uploadFile(newFile.file, config);
        const updatedFiles = [...currentUploadingFiles.current];
        const uploadingFile = getUploadingFile(newFile.id);
        if (uploadingFile) {
          uploadingFile.status = FileAttachmentStatus.Available;
        }
        setUploadingFiles(updatedFiles);
      } catch (error) {
        Sentry.captureException(error);
        if (!axios.isCancel(error)) {
          const updatedFiles = [...currentUploadingFiles.current];
          const uploadingFile = getUploadingFile(newFile.id);
          if (uploadingFile) {
            uploadingFile.status = FileAttachmentStatus.UploadingFailed;
          }
          setUploadingFiles(updatedFiles);
        }
      }
    }
  };

  const clearFiles = () => {
    setUploadingFiles([]);
    clearUploadingFiles();
  };

  return [uploadingFiles, handleFilesAdded, clearFiles];
};

export const FileUploadDropzone: React.FC<FileUploadDropzoneProps> = ({
  className,
  onFilesAdded,
  maxSize = MAX_FILE_SIZE_BYTES, // 25MB,
  inputProps,
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (onFilesAdded) {
        onFilesAdded(acceptedFiles);
      }
    },
    [onFilesAdded],
  );

  const { getRootProps, getInputProps, isDragActive, fileRejections, isDragReject } = useDropzone({
    onDrop,
    accept: inputProps?.accept,
    maxSize,
    multiple: inputProps?.multiple,
  });

  const dragStyles: SxProps<Theme> = theme => ({
    backgroundColor: isDragReject ? alpha(theme.palette.error.main, 0.1) : alpha(theme.palette.primary.light, 0.1),
  });

  return (
    <Box className={clsx(className)}>
      <Box
        {...getRootProps()}
        sx={theme => ({
          height: '150px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          borderWidth: theme.spacing(0.25),
          borderStyle: 'dashed',
          transition: '.25s ease-in-out',
          color: theme.palette.text.secondary,
          '&:hover, &:focus': {
            backgroundColor: alpha(theme.palette.primary.light, 0.1),
            cursor: 'pointer',
            outlineWidth: 0,
          },
          ...(isDragActive ? dragStyles(theme) : {}),
        })}
      >
        <input {...getInputProps()} data-testid="file-upload-dropzone-input" aria-label={inputProps?.['aria-label']} />
        {isDragActive && <Typography>Drop {inputProps?.multiple ? 'files' : 'file'} here ...</Typography>}
        {!isDragActive && (
          <>
            <CloudUpload fontSize="large" />
            <Typography align="center" variant="button">
              Drag {inputProps?.multiple ? 'files' : 'a file'} here
              <br />
              or
              <br />
              Click to Browse {inputProps?.multiple ? 'Files' : 'a File'}
            </Typography>
          </>
        )}
      </Box>
      <Box mt={1}>
        {inputProps?.accept && (
          <Typography variant="overline" display="block">
            Accepted {inputProps?.multiple ? 'files' : 'file'}:{' '}
            <Box
              component="span"
              sx={theme => ({
                color: theme.palette.grey[700],
                backgroundColor: theme.palette.grey[100],
                padding: theme.spacing(0.5),
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
                borderRadius: theme.spacing(1),
              })}
            >
              {inputProps?.accept?.replace(/,/g, ', ') ?? 'Any'}
            </Box>
          </Typography>
        )}

        {maxSize && (
          <Typography variant="overline" display="block">
            Maximum upload file size: {formatBytes(maxSize)}
          </Typography>
        )}
      </Box>
      <FileUploadRejections fileRejections={fileRejections} maxSize={maxSize} />
    </Box>
  );
};
