/**
 * Turns a React component into one that receives the same props but as a nested data property.
 * This is useful for converting a React component into one that can be used as a cell renderer
 * in AG Grid tables
 *
 * @param Component
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertComponentToCellRenderer = <T extends Record<string, any>>(
  Component: React.FC<T>,
): React.FC<{ data: T }> => {
  const CellRenderer: React.FC<{ data: T }> = ({ data }) => {
    return <Component {...data} />;
  };

  return CellRenderer;
};
