import { Box, Theme } from '@mui/material';

import { UserAvatar } from '../UserAvatar';
import { BaseAvatar, BaseAvatarGroupProps, useVisibleAvatars } from './AvatarGroups.helpers';

const avatarGroupSx = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
  },
  avatar: (stacked: boolean | undefined) => (theme: Theme) => ({
    zIndex: 1,
    marginRight: stacked ? theme.spacing(-1.5) : 0,
    border: `2px solid ${theme.palette.background.default}`,
  }),
  miniAvatar: (theme: Theme) => ({
    marginRight: theme.spacing(0.5),
  }),
  extrasAvatar: {
    order: -1,
    zIndex: 0,
  },
  extrasWrapper: { display: 'flex', alignItems: 'center', paddingTop: '3px' },
};

export interface AvatarGroupProps extends BaseAvatarGroupProps {
  avatars: BaseAvatar[];
}

export const AvatarGroup: React.FC<AvatarGroupProps> = ({ avatars, max, isStacked, size = 40 }) => {
  const { visibleIcons, extras } = useVisibleAvatars(avatars, max);

  const hasExtras = Boolean(extras?.length && Array.isArray(extras));
  const extrasTooltip = (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {extras?.map((extra, i) => (
        <Box sx={avatarGroupSx.extrasWrapper} key={`extra-tooltip-${i}`}>
          <UserAvatar
            size={24}
            sx={avatarGroupSx.miniAvatar}
            image={extra.image}
            name={extra.name}
            lastSignedInAt={extra.lastSignedInAt}
          />
          {extra.name}
        </Box>
      ))}
    </>
  );
  const extraWithTooltip = hasExtras ? (
    <UserAvatar
      size={size}
      withTooltip
      name="extras"
      customFallback={`+${extras?.length}`}
      customTooltip={extrasTooltip}
      sx={[avatarGroupSx.avatar(isStacked), avatarGroupSx.extrasAvatar]}
    />
  ) : undefined;

  return (
    <Box sx={[avatarGroupSx.wrapper, { mr: isStacked ? 1.5 : 0 }]}>
      {visibleIcons.map((avatar, i) => {
        return (
          <UserAvatar
            key={`avatar-${i}`}
            withTooltip
            size={size}
            sx={avatarGroupSx.avatar(isStacked)}
            image={avatar.image}
            name={avatar.name}
            lastSignedInAt={avatar.lastSignedInAt}
          />
        );
      })}
      {extraWithTooltip}
    </Box>
  );
};
