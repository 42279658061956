import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Box, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { useState } from 'react';
import { Controller } from 'react-hook-form';

import { PropertyFieldProps } from '../types';

interface ToggleVisibilityProps {
  fieldName: string;
  isHidden: boolean;
  onSetIsHidden: (value: boolean) => void;
}

const ToggleVisibility: React.FC<ToggleVisibilityProps> = props => {
  const { fieldName, isHidden, onSetIsHidden } = props;
  return (
    <InputAdornment position="end">
      <IconButton
        edge="end"
        aria-label="Toggle password visibility"
        onClick={() => onSetIsHidden(!isHidden)}
        data-testid={`toggle-${fieldName}-visibility`}
        size="large"
      >
        {isHidden ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );
};

export interface PropertyPasswordFieldProps extends PropertyFieldProps {
  autoComplete?: string;
  autoFocus?: boolean;
}

export const PropertyPasswordField: React.FC<PropertyPasswordFieldProps> = ({
  label,
  htmlName,
  control,
  disabled,
  rules,
  boxProps,
  errorMessage,
  helperText,
  startAdornment,
  endAdornment,
  autoFocus,
  autoComplete,
  onBlur: blurCallback,
}) => {
  const id = `password-field-${htmlName}`;
  const [isHidden, setIsHidden] = useState(true);
  const hasError = Boolean(errorMessage);
  return (
    <Box {...boxProps}>
      <Controller
        control={control}
        name={htmlName}
        defaultValue={null}
        rules={rules}
        render={fieldProps => {
          const { onChange, value, onBlur } = fieldProps.field;
          const handleBlur = () => {
            onBlur();
            if (blurCallback) {
              blurCallback(value);
            }
          };
          return (
            <FormControl variant="outlined" fullWidth disabled={disabled} error={hasError}>
              <InputLabel htmlFor={id} role="textbox">
                {label}
              </InputLabel>
              <OutlinedInput
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={autoFocus}
                onBlur={handleBlur}
                id={id}
                name={htmlName}
                value={value ?? ''}
                onChange={onChange}
                label={label}
                data-name={label}
                type={isHidden ? 'password' : 'text'}
                autoComplete={autoComplete}
                endAdornment={
                  endAdornment ?? (
                    <ToggleVisibility fieldName={htmlName} isHidden={isHidden} onSetIsHidden={setIsHidden} />
                  )
                }
                startAdornment={startAdornment}
              />
              <FormHelperText error={hasError}>{errorMessage ?? helperText}</FormHelperText>
            </FormControl>
          );
        }}
      />
    </Box>
  );
};
