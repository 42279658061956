import { OverridableComponent } from '@mui/material/OverridableComponent';
import SvgIcon, { SvgIconProps, SvgIconTypeMap } from '@mui/material/SvgIcon';
import React from 'react';

export interface IconWrapperProps extends SvgIconProps {
  icon:
    | React.ReactNode
    | React.ElementType<unknown>
    | OverridableComponent<SvgIconTypeMap>
    | React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined;
        }
      >;
}

export const IconWrapper: React.FC<IconWrapperProps> = ({ icon, ...iconProps }) => (
  <SvgIcon inheritViewBox component={icon} {...iconProps} />
);
