import { DateFormat, humanFormatDateTime } from '@circadian-risk/shared';
import { LocationOnSharp } from '@mui/icons-material';
import { Card, Divider, SvgIconTypeMap, Typography, useTheme } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import { HStack, VStack } from '../../..';
import { NumberDiff } from '../../NumberDiff';
import { ScoreDiff } from '../../ScoreDiff';
import { Bold } from '../../Text';
import { TooltipAvatar, TooltipAvatarProps } from '../../TooltipAvatar';

/**
 * Colored measure is used to define a value and the color is should be shown in
 */
export interface ColoredMeasure<T> {
  /**
   * Color denoting the threshold this value represents
   */
  color: string;
  /**
   * The value of this measure
   */
  value: T;
}

/**
 * Measures are used to compute the overall measurement
 * (such as 'severity' and 'probability' are used to compute 'inherent risk').
 */
export interface Measures<T> {
  /**
   * The current value of the measure
   */
  value: T;
  /**
   * The optional previous value of the measure (`undefined` if no change)
   */
  previous?: T;
  /**
   * The icon to display for this measure
   */
  Icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>> & { muiName: string };
  /**
   * The human-readable label for this measure
   */
  label: string;
}

/**
 * DiffTooltip is used to display some quick-view information for a measurement diff.
 */
export interface DiffTooltipProps<T> {
  /**
   * Human-readable label identifying the Diff being shown
   *
   * ex: 'Inherent Risk'
   */
  label: string;

  /**
   * The location of the measurement
   */
  location?: string;

  /**
   * Old/previous measurement value
   *
   */
  old: ColoredMeasure<T>;

  /**
   * The new (current) measurement value
   */
  current: ColoredMeasure<T>;

  /**
   * When the update occurred
   */
  updatedAt: Date;

  /**
   *
   * We want to show the user's avatar, user-specific avatars are in web-app so cannot be imported here.
   */
  by: TooltipAvatarProps;

  /**
   * Optional array of measurements used in the
   */
  measures?: Set<Measures<T>>;
}

type DiffValue = number | null;

/**
 * Controlled text/icon out for for a measure which may have changed
 */
const MeasureDiffInfo = <T extends DiffValue>(measure: Measures<T>) => {
  const theme = useTheme();
  const SECONDARY_COLOR = theme.palette.text.secondary;
  const { Icon, label, value, previous } = measure;

  return (
    <VStack>
      <HStack>
        <Icon sx={{ color: SECONDARY_COLOR }} />
        <Typography>{label}</Typography>
        <NumberDiff current={value} previous={previous} />
      </HStack>
    </VStack>
  );
};

export const DiffTooltip = <T extends DiffValue>({
  by,
  label,
  current,
  old,
  updatedAt,
  measures,
  location,
}: DiffTooltipProps<T>) => {
  const theme = useTheme();
  const SECONDARY_COLOR = theme.palette.text.secondary;

  return (
    <Card
      sx={{
        width: 'max-content',
        borderRadius: 2,
        boxShadow: theme.shadows[3],
        p: 2,
      }}
    >
      <VStack spacing={1.5}>
        <VStack spacing={0.5}>
          <HStack spacing={2} justifyContent="space-between" alignItems="center">
            <Typography variant="h6" noWrap component={'span'}>
              <Bold>{label}</Bold>
            </Typography>
            <ScoreDiff current={current} previous={old} />
          </HStack>
          {location && (
            <HStack>
              <LocationOnSharp sx={{ color: SECONDARY_COLOR }} />
              <Typography variant="subtitle2" color={SECONDARY_COLOR}>
                {location}
              </Typography>
            </HStack>
          )}
        </VStack>

        <Divider />
        <VStack pl={2}>
          {measures && measures.size > 0 && (
            <>
              {Array.from(measures).map(measure => (
                <MeasureDiffInfo key={measure.label} {...measure} />
              ))}
            </>
          )}
        </VStack>

        <HStack justifyContent="flex-end">
          <Typography variant="caption" color={SECONDARY_COLOR}>
            {humanFormatDateTime(updatedAt, DateFormat.MONTH_DAY_YEAR_WITH_TIME_AND_TIMEZONE)}
          </Typography>
          <TooltipAvatar size={25} {...by} />
        </HStack>
      </VStack>
    </Card>
  );
};
