/* eslint-disable no-console */
/**
 * Disables the service workers during development if any is found
 *
 * @see https://github.com/facebook/create-react-app/blob/8fda779684916b3a67aebcaa89d4c08bbbdc7f81/packages/cra-template/template/@web-app/serviceWorker.js
 */
export const unregisterServiceWorkers = () => {
  if ('serviceWorker' in navigator) {
    console.log('Un-registering service workers');
    navigator.serviceWorker.ready
      .then(registration => {
        void registration.unregister();
      })
      .catch(error => {
        console.error(error.message);
      });
  }
};
