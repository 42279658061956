import { LocationPickerType } from '../../form';
import { CreateAssessmentFormStepperConfig } from '../../stepsConfig';
import { RootStep } from './RootStep';
import { RootStepStepperSubtitle } from './RootStepStepperSubtitle';

export const rootStepConfig: CreateAssessmentFormStepperConfig = {
  title: 'Choose a location',
  component: RootStep,
  stepper: {
    titleOverride: 'Choose a Location and Template',
    subtitle: RootStepStepperSubtitle,
  },
  validator: async ctx => {
    const validationResult = await ctx.trigger(['assessmentTemplateId', 'locationFilterId', 'locationsIds']);
    const values = ctx.getValues();

    if (!validationResult) {
      return false;
    }

    if (values.locationPickerType === LocationPickerType.Default) {
      if (values.locationsIds.length === 0) {
        ctx.setError('locationsIds', { message: 'At least one location is required' });
        return false;
      }
    } else {
      if (!values.locationFilterId) {
        ctx.setError('locationFilterId', { message: 'Location filter is required' });
        return false;
      }
    }

    return validationResult;
  },
};
