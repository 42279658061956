import { formatBytes } from '@circadian-risk/shared';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { FileRejection } from 'react-dropzone';

interface FileAttachmentRejectionsProps {
  fileRejections: FileRejection[];
  className?: string;
  maxSize?: number;
}

export const FileUploadRejections: React.FC<FileAttachmentRejectionsProps> = ({
  fileRejections,
  className,
  maxSize,
}) => {
  return (
    <Box className={className} sx={theme => ({ color: theme.palette.error.main })} mt={1}>
      {fileRejections.length > 0 && <Typography variant="body1">Rejected Files:</Typography>}
      <Box m={0} px={2} py={0} component="ul" sx={{ listStyleType: 'none', fontStyle: '12px' }}>
        {fileRejections.map(({ file, errors }) => {
          return (
            <Box key={file.name} component="li">
              <Typography variant="body2">
                {file.name} - {formatBytes(file.size)}
              </Typography>

              <Box component="ul">
                {errors.map(e => {
                  switch (e.code) {
                    case 'file-too-large': {
                      return (
                        <Box key={e.code} component="li">
                          <Typography variant="body2">
                            File exceeds the {maxSize ? `${formatBytes(maxSize)} ` : ''}limit. Please use a smaller
                            file.
                          </Typography>
                        </Box>
                      );
                    }
                    default: {
                      return (
                        <Box key={e.code} component="li">
                          <Typography variant="body2">{e.message}</Typography>
                        </Box>
                      );
                    }
                  }
                })}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
