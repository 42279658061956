import { Enum_Consideration_Type_Enum } from '@circadian-risk/graphql-types';
import { z } from 'zod';

const baseOFCSchema = z.object({
  title: z.string(),
  description: z.string().nullish(),
  type: z.custom<Enum_Consideration_Type_Enum>(),
});

export const freeFormOptionSchema = baseOFCSchema;
export const libraryOptionSchema = baseOFCSchema.extend({
  libraryId: z
    .number({ description: 'If libraryId is not defined it means it is a "Save to Library" action' })
    .optional(),
  locationIds: z.array(z.string().uuid()),
});

export type LibraryOption = z.infer<typeof libraryOptionSchema>;
export type FreeFormOption = z.infer<typeof freeFormOptionSchema>;

export const optionsSchema = z.object({
  virtualOptions: z
    .object({
      freeform: z.array(freeFormOptionSchema),
      library: z.array(libraryOptionSchema),
    })
    .optional(),
  optionsToDelete: z
    .object({
      freeformIds: z.array(z.string()).optional(),
      libraryIds: z.array(z.number()).optional(),
    })
    .optional(),
  freeformToUpdate: z
    .array(
      baseOFCSchema.extend({
        id: z.string().uuid(),
      }),
    )
    .optional(),
  freeformToSaveToLib: z
    .array(
      baseOFCSchema.extend({
        id: z.string().uuid(),
        locationIds: z.array(z.string().uuid()),
      }),
    )
    .optional(),
});

export type OfcOptionsSchema = z.infer<typeof optionsSchema>;
