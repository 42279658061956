import LayersClearIcon from '@mui/icons-material/LayersClear';
import { Box, MenuItem, Typography } from '@mui/material';

import { useConfirmationDialog } from '../ConfirmationDialog';
import { BulkEditOptionProps } from './BulkEditDropdown';

interface BulkEditRatifyOptionProps extends BulkEditOptionProps {
  ratifiableCount: number;
  itemName: string;
}

export const BulkEditRatifyOption: React.FC<BulkEditRatifyOptionProps> = ({
  onSelect,
  disabled,
  itemName,
  ratifiableCount,
}) => {
  const { getConfirmation } = useConfirmationDialog();

  const handleMenuItemClick = async () => {
    const confirmed = await getConfirmation({
      title: 'Ratify Remaining',
      message: (
        <>
          <Typography>
            By selecting this option you will ratify {ratifiableCount} answers for this item ({itemName})
          </Typography>
          <br />
          <br />
          <Typography>Do you want to continue?</Typography>
        </>
      ),
      confirmButton: {
        text: `Ratify ${ratifiableCount} Answers`,
      },
    });

    if (!confirmed) {
      return;
    }

    onSelect('ratifyRemaining');
  };

  return (
    <MenuItem onClick={handleMenuItemClick} dense disabled={disabled}>
      <LayersClearIcon />
      <Box ml={1} component="span" sx={{ textTransform: 'uppercase' }}>
        Ratify...
      </Box>
    </MenuItem>
  );
};
