import { imageSrc } from '@circadian-risk/front-end-utils';
import { OrganizationRow } from '@circadian-risk/stores';
import { Grid } from '@mui/material';
import React from 'react';

import OrganizationItem from './OrganizationItem';

export interface SwitchOrganizationCardViewProps {
  rows: OrganizationRow[];
}

export const SwitchOrganizationCardView: React.FC<SwitchOrganizationCardViewProps> = ({ rows }) => {
  return (
    <Grid container spacing={2} p={1}>
      {rows.map(({ id, name, logo }) => (
        <Grid item xl={2} lg={3} xs={12} sm={6} md={4} key={id}>
          <OrganizationItem id={id} key={id} name={name} logo={imageSrc(logo) ?? ''} />
        </Grid>
      ))}
    </Grid>
  );
};
