import { PropertyAutocompleteFieldProps } from '@circadian-risk/form';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { TemplateSelectOption } from '../../../../components/shared-types';
import { useRenderAssessmentTemplateOption } from '../../../../components/useRenderAssessmentTemplateOption';
import { validateTemplateIdSelect } from '../../../../components/validate-helpers';
import { createScheduleAssessmentForm, CreateScheduledAssessmentFormSchema } from '../../../form';
import { useAssessmentTemplates } from '../hooks/useAssessmentTemplates';

const { Autocomplete } = createScheduleAssessmentForm;

export const AssessmentTemplateFormField: React.FC = () => {
  const templates = useAssessmentTemplates();
  const { watch, getFieldState, setValue } = useFormContext<CreateScheduledAssessmentFormSchema>();
  const selectedTemplateId = watch('assessmentTemplateId');
  const isNameFieldDirty = getFieldState('name').isDirty;
  const currentName = watch('name');
  const templateOptions = useMemo(() => {
    return templates.map<TemplateSelectOption>(template => ({
      label: template.name,
      value: template.id,
      scoredLayerId: template.scoredLayerId,
      deprecatedTemplatesCount: template.deprecatedTemplatesCount,
    }));
  }, [templates]);

  const renderOption = useRenderAssessmentTemplateOption();
  const selectedTemplateName = templates.find(t => t.id === selectedTemplateId)?.name;

  useEffect(() => {
    // Define the name of the metadata using the template if it ain't dirty yet
    if (!isNameFieldDirty && selectedTemplateName && selectedTemplateName !== currentName) {
      setValue('name', selectedTemplateName, { shouldDirty: false });
    }
  }, [currentName, isNameFieldDirty, selectedTemplateName, setValue]);

  return (
    <Autocomplete
      formKey="assessmentTemplateId"
      options={templateOptions as unknown as PropertyAutocompleteFieldProps['options']}
      rules={{
        required: 'Required',
        validate: validateTemplateIdSelect,
      }}
      renderOption={renderOption}
    />
  );
};
