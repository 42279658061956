import YoutubeSearchedForIcon from '@mui/icons-material/YoutubeSearchedFor';
import { IconButton, Tooltip } from '@mui/material';

type Props = {
  onClick: () => void;
};

export const ResetViewportButton: React.FC<Props> = ({ onClick }) => {
  return (
    <Tooltip title={'Reset map zoom'} aria-label={'resets map zoom'}>
      <IconButton aria-label={'reset map zoom'} onClick={onClick} size="large">
        <YoutubeSearchedForIcon />
      </IconButton>
    </Tooltip>
  );
};
