import { FCC } from '@circadian-risk/front-end-utils';
import React, { Suspense } from 'react';

import { Background } from './Background';
import { SplashLogo } from './SplashLogo';

export const SplashScreenSuspense: FCC = ({ children }) => {
  return (
    <Suspense
      fallback={
        <Background>
          <SplashLogo />
        </Background>
      }
    >
      {children}
    </Suspense>
  );
};

export { SplashLogo };
