import { SUPPORT_EMAIL } from '@circadian-risk/data-utils';
import { Typography } from '@mui/material';
import React from 'react';

import { CenterMessage } from '../CenterMessagePage';

export const ErrorPage: React.FC = () => {
  return (
    <CenterMessage title="There was an error">
      <Typography variant="body1">
        Please refresh the page to try again.
        <br />
        <br />
        If the problem persists, try to return to the <a href="/">Home screen</a> or contact{' '}
        <a href={`mailto:${SUPPORT_EMAIL}`}>Circadian Risk Client Success</a> for additional support.
      </Typography>
    </CenterMessage>
  );
};
