import { ROUTES } from '@circadian-risk/routes';
import { List } from '@mui/material';
import {
  ListItemNav,
  ListItemTextNav,
  NavigationDrawer,
  NavigationDrawerContent,
  NavigationDrawerHeader,
} from '@web-app/layouts/NavigationDrawer';
import React from 'react';

export interface AssessmentsDrawerProps {
  selectedId: number;
  assessments: { id: number; name: string; link: string }[];
}

export const AssessmentsDrawer: React.FC<AssessmentsDrawerProps> = ({ assessments, selectedId }) => {
  return (
    <NavigationDrawer>
      <NavigationDrawerHeader title="Assessments" backLink={{ name: 'Table View', to: ROUTES.ASSESSMENTS }} />
      <NavigationDrawerContent>
        <List>
          {assessments.map(assessment => {
            return (
              <ListItemNav to={assessment.link} selected={assessment.id === selectedId} key={assessment.id}>
                <ListItemTextNav>{assessment.name}</ListItemTextNav>
              </ListItemNav>
            );
          })}
        </List>
      </NavigationDrawerContent>
    </NavigationDrawer>
  );
};
