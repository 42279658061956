import { useIsPhoneScreen } from '@circadian-risk/front-end-utils';
import { Paper, PaperProps, SxProps, Theme, useTheme } from '@mui/material';
import React from 'react';

import darkBgSrc from '../../../assets/pattern-dark-web.jpg';
import lightBgSrc from '../../../assets/pattern-light-web.jpg';
import { FORM_MIN_WIDTH } from '../form.config';

export interface FormPaperProps extends PaperProps {
  minWidth?: number;
  showBg?: boolean;
}

/**
 * Use this component to wrap forms to provide consistent
 * - padding
 * - minWidth
 * - background image
 * - background color to the inputs (if showBg is enabled)
 */
export const FormPaper: React.FC<FormPaperProps> = ({ minWidth = FORM_MIN_WIDTH, showBg, children, ...paperProps }) => {
  const theme = useTheme();
  const isPhoneScreen = useIsPhoneScreen();
  const isDarkMode = theme.palette.mode === 'dark';
  const bgSrc = isDarkMode ? darkBgSrc : lightBgSrc;
  const bgcolor = theme.palette.background.paper;

  const defaultBgStyles: SxProps<Theme> = {
    background: `url(${bgSrc})`,
    backgroundSize: 'cover',
    backgroundPosition: 'right 50% bottom 50%',
    '* > input': { bgcolor },
    [`* > .MuiInputBase-input`]: { bgcolor },
  };

  // only applying if bgImage is provided
  const bgStyles = showBg ? defaultBgStyles : {};

  return (
    <Paper
      {...paperProps}
      variant={isPhoneScreen ? 'outlined' : 'elevation'}
      sx={{
        minWidth: { xs: 'unset', sm: minWidth },
        border: isPhoneScreen ? 'none' : undefined,
        padding: 4,
        ...bgStyles,
        ...paperProps.sx,
      }}
    >
      {children}
    </Paper>
  );
};
