import { GridApi } from '@ag-grid-community/core';
import { createEnhancedStore } from '@circadian-risk/front-end-utils';
import { StateCreator } from 'zustand';

interface SimpleTableStore {
  quickFilterText: string;
  updateQuickFilterText: (value: string, gridApi?: GridApi | null) => void;
}

const stateCreator: StateCreator<SimpleTableStore> = (set, get) => ({
  quickFilterText: '',
  updateQuickFilterText: (value: string, gridApi?: GridApi | null) => {
    if (value !== get().quickFilterText) {
      set({ quickFilterText: value });
      if (gridApi) {
        setTimeout(() => {
          gridApi.setQuickFilter(value);
        }, 0);
      }
    }
  },
});

export const useSimpleTableStore = createEnhancedStore(stateCreator);
