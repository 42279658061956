import { passwordRegex } from '@circadian-risk/shared';
import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import { errorSchema, fileSchema } from './shared-schemas';

const c = initContract();

// TODO(v-rrodrigues)[CR-4234]: Use the existing from 4230 once it is merged
const passwordSchema = z
  .string()
  .min(5)
  .max(20)
  .refine(str => passwordRegex.test(str), { message: 'password too weak' });

const changePasswordSchema = z.object({
  oldPassword: passwordSchema,
  newPassword: passwordSchema,
});

export type ChangePassword = z.infer<typeof changePasswordSchema>;

export const meContract = c.router(
  {
    avatarUpload: {
      method: 'POST',
      path: '/avatar',
      description: 'Upload current User avatar image',
      contentType: 'multipart/form-data',
      body: z.object({
        avatar: z.custom<File>(),
      }),
      responses: {
        201: fileSchema,
      },
    },
    changePassword: {
      method: 'POST',
      path: '/change-password',
      description: 'Updates the current user password',
      body: changePasswordSchema,
      responses: {
        201: z.object({}),
        403: errorSchema,
        400: errorSchema,
      },
    },
    updateEmail: {
      method: 'PATCH',
      path: '/email',
      description: 'Updates the current user email',
      body: z.object({
        email: z.string().email(),
      }),
      responses: {
        201: z.object({
          email: z.string().email(),
        }),
      },
    },
  },
  {
    pathPrefix: '/me',
  },
);
