import { NoInfer } from '@circadian-risk/shared';
import pickBy from 'lodash/pickBy';
import UrlPattern from 'url-pattern';

export const getRouteParams = <Route extends string, ParamsKeys extends string>(
  routes: NoInfer<Route>[],
  pickParams?: ParamsKeys[],
) => {
  const patterns = routes.map(route => new UrlPattern(route));
  const location = window.location.pathname;

  let params = {} as { [key in ParamsKeys]?: string };

  patterns.forEach(pattern => {
    const newParams = pattern.match(location) as Record<ParamsKeys, string | undefined> | null;
    if (!newParams) return;
    params = { ...params, ...newParams };
  });

  if (pickParams) {
    params = pickBy(params, (_, key) => pickParams.includes(key as ParamsKeys)) as Record<
      ParamsKeys,
      string | undefined
    >;
  }

  return params;
};
