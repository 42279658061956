import { GenericRealWorldMarker } from '@circadian-risk/maps';
import { NodeIcon } from '@circadian-risk/presentational';
import { GlobalOrganizationContextNode, LayersById } from '@circadian-risk/stores';

import { LocationRealWorldMarkerItem } from './types';

export const createRealWorldItems = ({
  nodes,
  backgroundColor,
  borderColor,
  layersById,
  onRootClick,
}: {
  nodes: GlobalOrganizationContextNode[];
  backgroundColor: string;
  borderColor: string;
  layersById: LayersById;
  onRootClick: ({ target, node }: { target: HTMLDivElement; node: GlobalOrganizationContextNode }) => void;
}) => {
  return nodes
    .filter(n => n.coordinates)
    .map<LocationRealWorldMarkerItem>(n => ({
      id: n.id,
      name: n.name,
      content: (
        <GenericRealWorldMarker
          name={n.name}
          icon={<NodeIcon fontSize="small" layerName={layersById[n.layer_id].name} />}
          tooltipDisabled
          borderColor={borderColor}
          backgroundColor={backgroundColor}
          // Handler for the single marker
          onRootClick={target =>
            onRootClick({ target, node: { ...n, coordinates: n.coordinates as { lat: number; long: number } | null } })
          }
        />
      ),
      coordinates: [n.coordinates!.long, n.coordinates!.lat],
      // Required for filtering or even passing additional data to the map card
      metadata: {
        locationId: n.id,
        layerId: n.layer_id,
        layerName: layersById[n.layer_id].name,
        locationName: n.name,
        depth: n.nodeDepth,
      },
    }));
};
