import { TagsAutocomplete } from '@circadian-risk/presentational';
import { Box } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

import { PropertySelectFieldProps } from '../PropertyFields';

export interface TagsAutocompleteFieldProps extends Omit<PropertySelectFieldProps, 'options' | 'customRenderer'> {
  options: string[];
  customRenderer?: (option: string) => JSX.Element;
  helperText?: string;
}

export const TagsAutocompleteField: React.FC<TagsAutocompleteFieldProps> = props => {
  const { htmlName, control, rules, boxProps } = props;
  return (
    <Box {...boxProps}>
      <Controller
        {...props}
        control={control as Control<Record<string, string[]>>}
        name={htmlName}
        rules={rules}
        render={({ field }) => <TagsAutocomplete {...field} {...props} onBlur={() => field.onBlur()} />}
      />
    </Box>
  );
};
