import { useQuery } from '@apollo/client';
import { TypedNodes } from '@circadian-risk/client-graphql-hooks';
import { useMemo } from 'react';

import { ScenarioStatInput } from './types';

export const useScenarioStats = (organizationId: string, scenarioStatInput: ScenarioStatInput[]) => {
  const { data } = useQuery(TypedNodes.GetBaseScenariosDocument, {
    variables: {
      organizationId,
      scenarioIds: scenarioStatInput.map(({ scenario_id }) => scenario_id),
    },
  });

  const stats = useMemo(() => {
    if (!data || !data.scenario) return [];

    return data.scenario.map(({ description, id, lower_threshold, name, scenario_type, upper_threshold }) => {
      const statInput = scenarioStatInput.find(({ scenario_id }) => scenario_id === id);

      if (!statInput) throw Error(`Unable to find scenario stats`);

      return {
        id,
        name,
        complianceThreshold: {
          // Reverse the thresholds so that we can properly color code this based on the scenario tolerance
          low: 100 - (upper_threshold ?? 0),
          high: 100 - (lower_threshold ?? 0),
        },
        criticalityNumbers: statInput.criticalityNumbers,
        irrelevantQuestionsCount: 0,
        percentage: statInput.percentage,
        type: scenario_type,
        description: description ?? '',
        // TODO(miking-the-viking): This wasn't mapped to anything previously. Seems to be unused. [CR-5527]
        hasTemplateChanged: undefined,
      };
    });
  }, [data, scenarioStatInput]);
  return stats;
};
