import { ApolloClient, NormalizedCacheObject, Reference } from '@apollo/client';
import { Modifiers } from '@apollo/client/cache/core/types/common';
import { FCC } from '@circadian-risk/front-end-utils';
import { Query_Root } from '@circadian-risk/graphql-types';
import isObject from 'lodash/isObject';
import pick from 'lodash/pick';
import React, { createContext, useContext, useMemo } from 'react';

import { ApolloCacheEntityId, ModifyApolloCacheArgs, ValidQueryRoots } from './apolloClient.types';
import { ApolloClientFactoryOptions, defaultCache, typeSafePolicies } from './apolloClientFactory';

export type ApolloClientFactoryArgs = Pick<ApolloClientFactoryOptions, 'uri'>;

export type ApolloClientFactory = (options: ApolloClientFactoryArgs) => ApolloClient<NormalizedCacheObject>;

export interface ApolloClientFactoryContextProps {
  apolloClientFactory: ApolloClientFactory;
}

export const validateApolloCacheEntityId = <T extends keyof Query_Root>(entityId: ApolloCacheEntityId<T>) => {
  const { key, typeName } = entityId;
  const keyParts = isObject(key) ? key : { id: key };
  const expectedKeyFields = (typeSafePolicies as Record<string, { keyFields?: string[] }>)[typeName]?.keyFields ?? [
    'id',
  ];

  const hasRequiredKeyFields = expectedKeyFields.every(key => key in keyParts);
  if (!hasRequiredKeyFields) {
    throw new Error(
      `Incorrect keyFields specified for '${typeName}'. Expected '${expectedKeyFields}' but got '${Object.keys(
        keyParts,
      )}'`,
    );
  }

  const validatedKeyParts = pick(keyParts, expectedKeyFields);

  const cacheId = defaultCache.identify({
    __typename: typeName,
    ...validatedKeyParts,
  });

  if (!cacheId) {
    throw new Error(`Could not identify cacheId for '${typeName}'`);
  }

  return cacheId;
};

export const getRefFromEntityId = <T extends ValidQueryRoots>(entityId: ApolloCacheEntityId<T>): Reference => {
  const id = validateApolloCacheEntityId(entityId);
  return { __ref: id };
};

/**
 * @deprecated
 * Use {@link cacheUtils.updateFragment} instead
 * TODO(panchaldeep009): migrate `modifyApolloCache` usage to the new `cacheUtils.updateFragment` API [CR-5880]
 */
export function modifyApolloCache<T extends ValidQueryRoots>(args: ModifyApolloCacheArgs<T>) {
  const { modify, ...rest } = args;

  const cacheId = validateApolloCacheEntityId(rest);

  return defaultCache.modify({
    id: cacheId,
    fields: modify as unknown as Modifiers,
  });
}

export function clearEntityFromApolloCache<T extends ValidQueryRoots>(entityKey: ApolloCacheEntityId<T>) {
  const cacheId = validateApolloCacheEntityId(entityKey);
  return defaultCache.evict({ id: cacheId });
}

// @ts-expect-error Consumers can expect that this will be definitely set by a Provider
export const ApolloClientFactoryContext = createContext<ApolloClientFactoryContextProps>();

export const useApolloClientFactoryContext = () => useContext(ApolloClientFactoryContext);

export const ApolloClientFactoryContextProvider: FCC<ApolloClientFactoryContextProps> = ({
  children,
  apolloClientFactory,
}) => {
  const value = useMemo(() => ({ apolloClientFactory }), [apolloClientFactory]);
  return <ApolloClientFactoryContext.Provider value={value}>{children}</ApolloClientFactoryContext.Provider>;
};
