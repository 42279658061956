import { Box, Button, capitalize } from '@mui/material';
import React from 'react';

import { HStack } from '../..';
import { getThemeSpacing } from '../Theme';

export interface QuickFilterItem {
  text: string;
  value: string;
  icon?: React.ReactNode;
}

export interface QuickFiltersProps {
  items: QuickFilterItem[];
  selectedValue?: string | null;
  onClick?: (value: string) => void;
}

export const QuickFilters: React.FC<QuickFiltersProps> = ({ items, selectedValue, onClick }) => {
  return (
    <Box
      component="fieldset"
      sx={theme => ({
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: theme.spacing(0.5),
        display: 'inline-flex',
        alignItems: 'center',
        color: theme.palette.primary.light,
        marginTop: theme.spacing(-1),
        padding: `${getThemeSpacing(theme, 1) - 1}px`,
      })}
    >
      <Box component="legend" sx={theme => ({ ...theme.typography.caption })}>
        Quick Filters
      </Box>
      <HStack>
        {items.map(i => {
          const selected = selectedValue === i.value;
          return (
            <Button
              size="small"
              disableElevation
              disableRipple
              key={`qf-${i.text}`}
              aria-label={`quick filter ${i.value}`}
              variant={selected ? 'contained' : 'outlined'}
              color={selected ? 'primary' : 'info'}
              onClick={() => onClick?.(i.value)}
              startIcon={i.icon}
            >
              {capitalize(i.text)}
            </Button>
          );
        })}
      </HStack>
    </Box>
  );
};
