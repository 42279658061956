import { Box } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

import { AutoCompleteInput, AutoCompleteInputProps } from '../../AutoCompleteInput';
import { PropertyFieldProps } from '../types';

export type PropertyAutocompleteFieldProps = PropertyFieldProps &
  Pick<AutoCompleteInputProps, 'options' | 'acceptInvalid' | 'renderOption'>;

export const PropertyAutocompleteField: React.FC<PropertyAutocompleteFieldProps> = ({
  label,
  htmlName,
  control,
  options,
  disabled,
  rules,
  boxProps,
  errorMessage,
  acceptInvalid,
  renderOption,
}) => {
  return (
    <Box {...boxProps}>
      <Controller
        control={control}
        name={htmlName}
        rules={rules}
        render={fieldProps => {
          const { onBlur, onChange, value } = fieldProps.field;
          const autocompleteValue = acceptInvalid ? value : options.find(option => option.value === value) ?? null;
          return (
            <AutoCompleteInput
              value={autocompleteValue}
              onChange={onChange}
              options={options}
              label={label ? String(label) : ''}
              onBlur={onBlur}
              disabled={disabled}
              errors={errorMessage}
              acceptInvalid={acceptInvalid}
              renderOption={renderOption}
            />
          );
        }}
      />
    </Box>
  );
};
