import { useLatestDebounceState } from '@circadian-risk/front-end-utils';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, TextField } from '@mui/material';
import React, { useCallback } from 'react';

import { AnswerFormData, AnswerFormName } from '../AnswerFormData';

interface QuestionDescriptionProps {
  active?: boolean;
  disabled?: boolean;
  value?: string | null;
  onChange: (value?: string | null) => void;
  onDelete: () => void;
}

export const QuestionDescription: React.FC<QuestionDescriptionProps> = props => {
  const { value, onChange, disabled, active, onDelete } = props;

  const [internalDescription, setInternalDescription, cancelDebounceDescription] = useLatestDebounceState<
    AnswerFormData['description']
  >(value, onChange);

  const handleDeleteClick = useCallback(() => {
    cancelDebounceDescription();
    onDelete();
  }, [cancelDebounceDescription, onDelete]);

  return (
    <Box
      mt={2}
      display={active ? 'flex' : 'none'}
      flexDirection="row"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Box flex={1}>
        <TextField
          fullWidth
          multiline
          variant={disabled ? 'standard' : 'outlined'}
          value={internalDescription ?? ''}
          label="Description"
          name={AnswerFormName.description}
          disabled={disabled}
          onBlur={e => onChange(e.target.value)}
          onChange={e => {
            setInternalDescription(e.target.value);
          }}
        />
      </Box>
      <Box display={disabled ? 'none' : undefined}>
        <IconButton aria-label="delete" onClick={handleDeleteClick} size="large">
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
