import { useTheme } from '@mui/material';
import { motion, SVGMotionProps } from 'framer-motion';
import isFunction from 'lodash/isFunction';
import React, { useMemo } from 'react';

const LOGO_C_PATH = `M81.5,89.8c0.2,34.5,17.7,66.3,46.4,85.1c5.6-3.6,10.7-7.8,15.3-12.3l-5.6-10.9c-2.8,3-5.9,5.8-9.2,8.4
l-0.6,0.5l-0.7-0.5c-18.9-15.1-31-37.1-33.6-61.2l-0.1-0.8l0.8-0.2c4.7-1.4,9.4-2.5,14.2-3.3L103,83.8
C95.8,85.1,88.6,87.1,81.5,89.8z`;

const LOGO_R_PATH = `M115,97.8l25.7,50.5l3.4,6.6l2.2,4.4c2.9-3,5.6-6.3,8.1-9.7l-3.2-6.3l-17.6-34.5c1.6,0,3.3,0.2,4.9,0.3
l12.5,24.5l2.8,5.6l3.4,6.6c2.6-3.8,4.9-7.9,6.9-12.1l-3.1-6.1l-3.8-7.4l-4.5-8.9c2.3,0.5,4.6,1.1,6.9,1.6l11.2,3
c2.3-8.5,3.6-17.3,3.6-26.4c-14.8-5.5-30.3-8.5-46.3-8.5c-7,0-13.8,0.5-20.6,1.6l5.6,11c4.9-0.7,10-1,15-1c11.4,0,22.6,1.6,33.5,4.8
l0.8,0.2l-0.1,0.8c-0.1,0.9-0.2,1.9-0.3,2.8c-11-2.9-22.4-4.5-33.9-4.5h-0.3h0h0h0h0h0h-0.1h0.3h0h0h0h0h-0.1h0h0h0h0h0h0h0h0h0h0
h0.3h0h0h0h0h-0.1h0c-0.2,0-0.4,0-0.6,0C122.3,97.1,118.7,97.4,115,97.8z`;

const LOGO_C_COLOR = '#02122d';
const LOGO_R_COLOR = '#007af4';

interface AnimatedStrokeProps {
  length: number;
  totalLength: number;
  duration: number;
  pathProps: SVGMotionProps<SVGPathElement>;
}

const AnimatedStroke: React.FC<AnimatedStrokeProps> = ({ length, totalLength, duration, pathProps }) => (
  <motion.path
    stroke="#FFFFFF"
    fill="transparent"
    strokeWidth={5}
    strokeLinecap="round"
    strokeDasharray={`${length} ${totalLength - length}`}
    animate={{ strokeDashoffset: [0, totalLength] }}
    transition={{
      duration,
      repeat: Infinity,
      type: 'tween',
      ease: 'linear',
    }}
    {...pathProps}
  />
);

const ShiningStoke: React.FC<Omit<AnimatedStrokeProps, 'totalLength' | 'length'>> = ({ duration, pathProps }) => {
  const totalLength = useMemo(() => {
    const d = pathProps.d;
    // eslint-disable-next-line lodash/prefer-lodash-typecheck
    if (typeof d !== 'string') {
      return 0;
    }
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path.setAttribute('d', d);
    // Assert that total length is an actual function as during
    // tests jest-dom assertion throws as it mocks to an empty SVGElement {}
    if (path && isFunction(path.getTotalLength)) {
      const length = path.getTotalLength();
      path.remove();
      return length;
    }

    return 0;
  }, [pathProps]);

  return (
    <>
      <AnimatedStroke
        length={200}
        totalLength={totalLength}
        duration={duration}
        pathProps={{
          ...pathProps,
          strokeWidth: 1,
        }}
      />
      {(
        [
          [160, 1, 'blur4'],
          [40, 2, 'blur2'],
          [30, 3, 'blur2'],
          [25, 4, 'blur3'],
          [15, 6, 'blur1'],
          [10, 15, 'blur1'],
        ] as const
      ).map(([length, strokeWidth, filter], index) => (
        <AnimatedStroke
          key={index}
          totalLength={totalLength}
          duration={duration}
          length={length}
          pathProps={{
            strokeWidth,
            filter: `url(#${filter})`,
            ...pathProps,
          }}
        />
      ))}
    </>
  );
};

const ShiningStokeFilters = () => (
  <defs>
    {(
      [
        ['blur1', 20],
        ['blur2', 12],
        ['blur3', 3],
        ['blur4', 1],
      ] as const
    ).map(([id, stdDeviation]) => (
      <filter key={id} id={id} x="-100%" y="-100%" width="400%" height="400%">
        <feGaussianBlur in="SourceGraphic" stdDeviation={stdDeviation} />
      </filter>
    ))}
  </defs>
);

type SplashLogoProps = {
  width?: string | number;
  height?: string | number;
};

/**
 * Animated loading logo
 */
export const SplashLogo: React.FC<SplashLogoProps> = props => {
  const theme = useTheme();
  const strokeColors = useMemo(() => {
    if (theme.palette.mode === 'dark') {
      return {
        c: LOGO_R_COLOR,
        r: theme.palette.common.white,
      };
    }
    return {
      c: LOGO_C_COLOR,
      r: LOGO_R_COLOR,
    };
  }, [theme.palette.common.white, theme.palette.mode]);

  return (
    <motion.svg
      version="1.1"
      width="256px"
      height="256px"
      viewBox="0 0 256 256"
      {...props}
      initial={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 2 }}
    >
      <ShiningStokeFilters />
      <ShiningStoke duration={3} pathProps={{ stroke: strokeColors.c, d: LOGO_C_PATH }} />
      <ShiningStoke duration={4} pathProps={{ stroke: strokeColors.r, d: LOGO_R_PATH }} />
      <motion.path
        fill={LOGO_R_COLOR}
        animate={{ opacity: [0.2, 0.7, 0.2] }}
        transition={{ ease: 'easeInOut', duration: 1, repeat: Infinity }}
        d={LOGO_R_PATH}
      />
      <motion.path
        fill={LOGO_C_COLOR}
        animate={{ opacity: [0.2, 0.7, 0.2] }}
        transition={{ ease: 'easeInOut', duration: 1, repeat: Infinity }}
        d={LOGO_C_PATH}
      />
    </motion.svg>
  );
};
