export interface TreeNode {
  id: string;
  parent_id: string | null;
  children?: TreeNode[];
}

/**
 * Given a list of nodes and the current Node, returns the node with it's children attached as an array of nodes
 */
export const nestify = <T extends TreeNode>(allNodes: T[], currentNode: T): T => {
  const children = allNodes
    .filter(node => node.parent_id === currentNode.id)
    .map(child => nestify(allNodes, child))

  return {
    ...currentNode,
    children,
  };
};

/**
 * Searches the tree and returns the first node that fulfills the searchFn.
 * Returns null if there's no match
 */
export const searchTree = <T extends TreeNode>(node: T, searchFn: (node: T) => boolean): T | null => {
  if (searchFn(node)) {
    return node;
  }

  if (node.children) {
    for (const child of node.children) {
      const childResult = searchTree(child as T, searchFn);
      if (childResult) {
        return childResult;
      }
    }
    return null;
  } else {
    return null;
  }
};

/**
 * Helper function for searchTree. Works the same except it does not include the top level node
 */
export const searchTreeChildren = <T extends TreeNode>(node: T, searchFn: (node: T) => boolean): T | null => {
  if (node.children) {
    for (const child of node.children) {
      const childResult = searchTree(child as T, searchFn);
      if (childResult) {
        return childResult;
      }
    }
  }
  return null;
};

/**
 * Given a tree and a filter function, returns a new tree with only the nodes which match the filter function
 * and the intermediate nodes to reach the said nodes
 */
export const trimTree = <T extends TreeNode>(node: T, filterFn: (x: T) => boolean): T | null => {
  let newChildren: T[] | undefined = [];

  if (node.children) {
    for (const child of node.children) {
      const result = trimTree(child as T, filterFn);
      if (result) {
        newChildren.push(result);
      }
    }
  }

  if (newChildren.length === 0) {
    newChildren = undefined;
  }

  if (filterFn(node) || newChildren) {
    return { ...node, children: newChildren };
  } else {
    return null;
  }
};

/**
 * Returns a copied list of nodes from a given tree
 */
export const flattenTree = <T extends TreeNode>(node: T): T[] => {
  let nodes: T[] = [];

  if (node.children) {
    for (const child of node.children) {
      nodes = [...nodes, ...(flattenTree(child) as T[])];
    }
  }

  const nodeCopy = { ...node };
  nodeCopy.children = undefined;
  nodes.unshift(nodeCopy);

  return nodes;
};

/**
 * Given root node and other nodes, finds the lowest common ancestor node
 * Comparison is based on id. Assumes that the nodes are unique
 */
export const findLowestCommonAncestor = <T extends TreeNode>(root: T, ...nodes: T[]): T | null => {
  let lca: T | null = null;

  const findCommonAncestorSearch = (node: TreeNode, ...searchNodes: TreeNode[]): number => {
    let totalNumber = 0;

    // search children
    if (node.children) {
      for (const child of node.children) {
        totalNumber += findCommonAncestorSearch(child, ...searchNodes);
      }
    }

    const searchNodesIds = searchNodes.map(n => n.id);
    const currentFound = searchNodesIds.includes(node.id) ? 1 : 0;
    if (currentFound) {
      totalNumber += 1;
    }

    if (totalNumber === searchNodes.length && lca === null) {
      lca = node as T;
    }

    return totalNumber;
  };

  findCommonAncestorSearch(root, ...nodes);

  return lca;
};
