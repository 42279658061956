// https://stackoverflow.com/questions/60141960/typescript-key-value-relation-preserving-object-entries-type/75337277#75337277

type ValueOf<T> = T[keyof T];
type Entries<T> = [keyof T, ValueOf<T>][];

/**  Same as `Object.entries()` but with type inference */
function typeSafeObjectEntries<T extends object>(obj: T): Entries<T> {
  return Object.entries(obj) as Entries<T>;
}

/**  Same as `Object.keys()` but with type inference */
function typeSafeObjectKeys<T extends object>(obj: T) {
  return Object.keys(obj) as (keyof T)[];
}

/**  Same as `Object.values()` but with type inference */
function typeSafeObjectValues<T extends object>(obj: T) {
  return Object.values(obj) as ValueOf<T>[];
}

/**
 * type-safe replacement for `Object.{keys | values | entries}`
 */
export const tsObject = {
  keys: typeSafeObjectKeys,
  values: typeSafeObjectValues,
  entries: typeSafeObjectEntries,
};
