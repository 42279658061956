import { createEnhancedStore } from '@circadian-risk/front-end-utils';
import { StateCreator } from 'zustand';

interface FeatureFlagsStoreState {
  showFeatureFlagBoundaries: boolean;
}

const stateCreator: StateCreator<FeatureFlagsStoreState> = () => ({
  showFeatureFlagBoundaries: false,
});

export const useFeatureFlagsStore = createEnhancedStore(stateCreator, { byPassReset: true });

export const useShouldShowFeatureFlagBoundaries = () => useFeatureFlagsStore(state => state.showFeatureFlagBoundaries);
