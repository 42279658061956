import { Enum_Feature_Flags_Enum } from '@circadian-risk/client-graphql-hooks';
import { ROUTES, ROUTES_LOCAL } from '@circadian-risk/routes';
import { isIntersected, USER_ROLE } from '@circadian-risk/shared';
import { FeatureFlagToggleSmart } from '@circadian-risk/stores';
import { Code, ExploreOutlined, PlaylistAddCheckOutlined, SettingsOutlined } from '@mui/icons-material';
import HelpIcon from '@mui/icons-material/Help';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import { canAccessRoutePath } from '@web-app/router/RouteConfig';
import React from 'react';
import { GoGear, GoGraph } from 'react-icons/go';
import { MdDashboard, MdHome } from 'react-icons/md';

import { SideColorModeToggle } from './SideColorMode';
import { SideFileManager } from './SideFileManager';

export interface MenuGroupItem {
  name: string;
  link: ROUTES_LOCAL | string;
  icon?: JSX.Element;
  isExternal?: boolean;
  isActive?: boolean;
  /**
   * Allows to override the element but the ListItem aspect must be handled within this custom content
   * and not only return the inner content
   */
  customComponent?: React.ReactNode;
  /**
   * If provided it will intersect the featureFlags passed here with the organization enabled features
   * Please note that if the array is empty it will not be ignored and hide the item
   */
  featureFlags?: Enum_Feature_Flags_Enum[];
  /**
   * If provided it will display the following item based on the allowed roles
   * Please note that if the parent has different permissions it can interfere, use this
   * when the parent menu does not contain any allowRoles set
   */
  allowRoles?: USER_ROLE[];
}

export interface MenuGroup {
  groupName: string;
  groupIcon?: JSX.Element;
  items: MenuGroupItem[];
  isCollapsible?: boolean;
  isCollapsed?: boolean;
  /**
   * Allows to override the element but the ListItem aspect must be handled within this custom content
   * and not only return the inner content
   */
  customComponent?: React.ReactNode;
  /**
   * If provided it will filter down the routes for the given role list
   * Please note that if the array is empty it will not be ignored and hide the group
   */
  allowRoles?: USER_ROLE[];
  /**
   * If provided it will intersect the featureFlags passed here with the organization enabled features
   * Please note that if the array is empty it will not be ignored and hide the group
   */
  featureFlags?: Enum_Feature_Flags_Enum[];
}

const replaceOrgId = (route: string, orgId?: string | null) =>
  orgId ? route.replace(':organizationId', orgId ?? '') : route;

export type AdditionalNavigationItemRouteFilter = (route: { name: string; link: string }) => boolean;

const productionRoutes = ({
  organizationId,
  pathname,
  roles,
  isCircadianAdmin,
  additionalRouteFilter,
  enabledFeatures,
}: {
  organizationId?: string | null;
  pathname?: string;
  roles: string[];
  enabledFeatures: Enum_Feature_Flags_Enum[];
  isCircadianAdmin: boolean;
  additionalRouteFilter?: AdditionalNavigationItemRouteFilter;
}): MenuGroup[] => {
  const menuGroups: MenuGroup[] = [
    {
      groupName: 'Organization Overview',
      allowRoles: [USER_ROLE.ORGANIZATION_ADMIN],
      items: [
        {
          name: 'Organization Overview',
          link: replaceOrgId(ROUTES.ROOT, organizationId),
          icon: <MdHome size={22} />,
        },
      ],
    },
    {
      groupName: 'Locations',
      items: [
        {
          name: 'Locations',
          link: replaceOrgId(ROUTES.NODES, organizationId),
          icon: <ExploreOutlined />,
        },
      ],
    },
    {
      groupName: 'Assessment Dashboard',
      items: [
        {
          name: 'Assessment Dashboard',
          link: replaceOrgId(ROUTES.ASSESSMENTS, organizationId),
          icon: <PlaylistAddCheckOutlined />,
        },
      ],
    },
    {
      groupName: 'Risk Dashboard',
      items: [
        {
          name: 'Risk Dashboard',
          icon: <MdDashboard />,
          link: replaceOrgId(ROUTES.NEW_SCENARIO_DASHBOARD, organizationId),
        },
      ],
    },
    {
      groupName: 'Action Plan',
      groupIcon: <VerifiedUserOutlinedIcon />,
      isCollapsible: true,
      items: [
        {
          name: 'Items by Status',
          link: replaceOrgId(ROUTES.LOCATION_ITEMS, organizationId),
        },
        {
          name: 'Deficiencies',
          link: replaceOrgId(ROUTES.DEFICIENCIES_BY_QUESTION, organizationId),
        },
        {
          name: 'Projects',
          link: replaceOrgId(ROUTES.PROJECTS, organizationId),
        },
        {
          name: 'Tasks',
          link: replaceOrgId(ROUTES.TASKS, organizationId),
        },
        {
          name: 'Options for Consideration',
          link: replaceOrgId(ROUTES.OPTIONS_FOR_CONSIDERATION, organizationId),
        },
      ],
    },
    {
      groupName: 'Reports',
      groupIcon: <GoGraph />,
      isCollapsible: true,
      items: [
        {
          name: 'Reports',
          link: replaceOrgId(ROUTES.REPORTS, organizationId),
        },
        {
          name: 'Narrative Reports',
          link: replaceOrgId(ROUTES.USER_GENERATED_REPORTS, organizationId),
        },
        {
          name: 'Inherent Risk',
          link: replaceOrgId(ROUTES.INHERENT_RISK, organizationId),
        },
        {
          name: 'Filters',
          link: replaceOrgId(ROUTES.FILTERS, organizationId),
        },
      ],
    },
    {
      groupName: 'Organization Management',
      groupIcon: <SettingsOutlined />,
      allowRoles: [USER_ROLE.ORGANIZATION_ADMIN],
      isCollapsible: true,
      items: [
        {
          name: 'Scenarios',
          link: replaceOrgId(ROUTES.SCENARIOS, organizationId),
        },
        {
          name: 'Users',
          link: replaceOrgId(ROUTES.USERS, organizationId),
        },
        {
          name: 'Permissions',
          link: replaceOrgId(ROUTES.PERMISSIONS_OVERVIEW, organizationId),
        },
        {
          name: 'Item Categories',
          link: replaceOrgId(ROUTES.ITEM_CATEGORIES, organizationId),
        },
        {
          name: 'Files',
          link: replaceOrgId(ROUTES.FILES, organizationId),
        },
        {
          name: 'Assessment Templates',
          link: replaceOrgId(ROUTES.ASSESSMENT_TEMPLATES, organizationId),
        },
        {
          name: 'Scheduled Assessments',
          link: replaceOrgId(ROUTES.SCHEDULED_ASSESSMENTS, organizationId),
          featureFlags: ['scheduled_assessments'],
        },
      ],
    },
    {
      groupName: 'Settings',
      groupIcon: <GoGear />,
      isCollapsible: true,
      items: [
        {
          name: 'Files',
          link: replaceOrgId(ROUTES.FILES, organizationId),
          customComponent: <SideFileManager />,
        },
        {
          name: 'Dark Mode',
          link: '',
          customComponent: <SideColorModeToggle />,
        },
        ...(isCircadianAdmin
          ? [
              {
                name: 'Show Feature Flags',
                link: '',
                customComponent: <FeatureFlagToggleSmart />,
              },
            ]
          : []),
      ],
    },
    {
      groupName: 'Help',
      isCollapsible: true,
      groupIcon: <HelpIcon />,
      items: [
        {
          name: 'Instruction Guides',
          isExternal: true,
          link: ROUTES.INSTRUCTION_GUIDES,
        },
        {
          name: 'Raise a Request',
          isExternal: true,
          link: ROUTES.RAISE_REQUEST,
        },
      ],
    },
  ];

  return menuGroups
    .filter(menuGroup => (menuGroup.featureFlags ? isIntersected(menuGroup.featureFlags, enabledFeatures) : true))
    .filter(menuGroup => (menuGroup.allowRoles ? isIntersected(menuGroup.allowRoles, roles) : true))
    .map(menuGroup => {
      let menuGroupItems = menuGroup.items;
      menuGroupItems = menuGroupItems.filter(item => {
        const link = item.link.includes('#user-generated-reports') ? ROUTES.REPORTS : item.link;
        return canAccessRoutePath(link, isCircadianAdmin, roles, enabledFeatures);
      });

      if (additionalRouteFilter) {
        menuGroupItems = menuGroupItems.filter(additionalRouteFilter);
      }

      const filteredItems = menuGroupItems
        .filter(item => (item.featureFlags ? isIntersected(item.featureFlags, enabledFeatures) : true))
        .filter(item => (item.allowRoles ? isIntersected(item.allowRoles, roles) : true))
        .map(item => ({ ...item, isActive: pathname?.includes(item.link) }));

      return {
        ...menuGroup,
        items: filteredItems,
        isCollapsed: !filteredItems.some(item => item.isActive),
      };
    })
    .filter(menuGroup => menuGroup.items.length > 0);
};

export const devRoutes = ({
  pathname,
  roles,
  isCircadianAdmin,
  enabledFeatures,
  baseUrl,
}: {
  pathname?: string;
  userRole?: USER_ROLE;
  roles: string[];
  enabledFeatures: string[];
  isCircadianAdmin: boolean;
  baseUrl: string;
}): MenuGroup[] => {
  const devMenuGroups: MenuGroup[] = [
    {
      groupName: 'Dev Stuff',
      isCollapsible: true,
      groupIcon: <Code />,
      items: [
        {
          name: 'API docs',
          link: ROUTES.API_DOCS(baseUrl),
          isExternal: true,
        },
      ],
    },
  ];

  return devMenuGroups.map(menuGroup => ({
    ...menuGroup,
    items: menuGroup.items.filter(item => canAccessRoutePath(item.link, isCircadianAdmin, roles, enabledFeatures)),
    isCollapsed: !menuGroup.items.some(item => pathname?.includes(item.link)),
  }));
};

export const getNavigationGroups = ({
  organizationId,
  pathname,
  roles,
  isCircadianAdmin,
  enabledFeatures,
  additionalRouteFilter,
  baseUrl,
}: {
  organizationId?: string | null;
  pathname?: string;
  userRole?: string;
  roles: string[];
  isCircadianAdmin: boolean;
  additionalRouteFilter?: AdditionalNavigationItemRouteFilter;
  enabledFeatures: Enum_Feature_Flags_Enum[];
  baseUrl: string;
}): MenuGroup[] => [
  ...productionRoutes({ organizationId, pathname, roles, additionalRouteFilter, isCircadianAdmin, enabledFeatures }),
  ...(process.env.NODE_ENV === 'development'
    ? devRoutes({ isCircadianAdmin, pathname, roles, enabledFeatures, baseUrl })
    : []),
];
