import { UserCellRenderer } from '@circadian-risk/data-grid';
import { BasicTable, Bold } from '@circadian-risk/presentational';

import { commonTableProps } from '../../CreateScheduledAssessment/steps/SummaryStep/components/common-table-props';
import { AssignmentUserRow } from './types';

type UserTabContentProps = {
  users: AssignmentUserRow[];
};

type ColumnKey = 'name' | 'role' | 'email' | 'assignmentType';

type RowData = Record<ColumnKey, React.ReactNode>;

const headers: { key: ColumnKey; name: string }[] = [
  {
    key: 'name',
    name: 'Name',
  },
  {
    key: 'role',
    name: 'Role',
  },
  {
    key: 'email',
    name: 'Email',
  },
  {
    key: 'assignmentType',
    name: 'Assigned or Subscribed',
  },
];

export const UsersTabContent: React.FC<UserTabContentProps> = ({ users }) => {
  const rowData = users.map<RowData>(user => {
    return {
      name: (
        <UserCellRenderer
          data={{
            userName: user.displayName,
            avatar: { filepath: user.avatar },
            last_signed_in_at: user.lastSignedInAt,
          }}
        />
      ),
      role: user.role,
      email: user.email,
      assignmentType: <Bold>{user.assignmentType === 'assignee' ? 'Assigned' : 'Subscribed'}</Bold>,
    };
  });

  return <BasicTable data={rowData} headers={headers} {...commonTableProps} />;
};
