import { useListenScenarioDetailsForAssessmentSubscription } from '@circadian-risk/client-graphql-hooks';
import {
  createAssessmentFileFilter,
  IFileItem,
  UploadStatus,
  useFileManagerFilter,
  useFileManagerStore,
} from '@circadian-risk/file-manager';
import { useNotification } from '@circadian-risk/front-end-utils';
import { useGqlQueryErrorNotification } from '@circadian-risk/graphql-utils';
import { getThemeSpacing } from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { AssessmentState } from '@circadian-risk/shared';
import { useIsFeatureFlagEnabledForOrg } from '@circadian-risk/stores';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DashboardIcon from '@mui/icons-material/Dashboard';
import NotesIcon from '@mui/icons-material/Notes';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import TimelineIcon from '@mui/icons-material/Timeline';
import { Badge, Box, Card, CircularProgress, useTheme } from '@mui/material';
import { TabNav, TabNavConfig } from '@web-app/layouts/TabNav';
import { assessmentAppUrlForWebApp } from '@web-app/lib/assessmentAppUrl';
import { useCanAccessFeatureFlag } from '@web-app/lib/useCanAccessFeatureFlag';
import sumBy from 'lodash/sumBy';
import { useCallback, useMemo } from 'react';
import { generatePath, Redirect, Route, Switch } from 'react-router-dom';

import { ScenarioAssessmentEditPage } from '../AssessmentForm/LocationScenarioAssessmentForm';
import AssessmentReportGenerationLandingPage from './AssessmentReportGeneration/AssessmentReportGenerationLandingPage';
import AssessmentReportGenerationPage from './AssessmentReportGeneration/AssessmentReportGenerationPage';
import { ScenarioAssessmentHeader } from './components/ScenarioAssesmentHeader';
import { ScenarioAssessmentOverview } from './components/ScenarioAssessmentOverview';
import { extractCriticalityNumbersFromScore } from './components/ScenarioAssessmentOverview/helpers';
import { ScenarioAssessmentPendingFiles } from './components/ScenarioAssessmentPendingFiles';
import { ScenarioAssessmentResults } from './components/ScenarioAssessmentResults';
import { ScenarioAssessmentNarrative } from './components/ScenarioAsssessmentNarrative';
import { AssessmentItemsAnswersTable } from './smart-components/AssessmentItemsAnswersTable/AssessmentItemsAnswersTable';

export interface ScenarioAssessmentDetailsPageProps {
  organizationId: string;
  assessmentId: number;
}

export interface ScenariosTotalScore {
  low_deficiency_count: number;
  medium_deficiency_count: number;
  high_deficiency_count: number;

  control_percentage: number;
}

type TabId = 'overview' | 'results' | 'narrative' | 'edit' | 'report';

export const ScenarioAssessmentDetailsPageContent: React.FC<ScenarioAssessmentDetailsPageProps> = ({
  organizationId,
  assessmentId,
}) => {
  const theme = useTheme();
  const { displayWarning } = useNotification();
  const canAccessReportGenerator = useCanAccessFeatureFlag('report_generator');

  const { data, loading, error } = useListenScenarioDetailsForAssessmentSubscription({
    variables: {
      organizationId,
      assessmentId,
    },
  });

  useGqlQueryErrorNotification(error);

  const baseUrl = ROUTES.ASSESSMENTS_DETAIL.replace(':id', String(assessmentId)).replace(
    ':organizationId',
    organizationId,
  );

  const assessment = data?.v2_assessments_by_pk;
  const nodeId = assessment?.locations?.[0]?.node?.id;
  const questionnaireProgress = assessment?.progressAggregates?.inspection_progress ?? null;
  const physicalInspectionProgress = assessment?.progressAggregates?.physical_progress ?? null;
  const featureFlagSmootherUploads = useIsFeatureFlagEnabledForOrg('smoother_uploads');
  const pendingFilesCount = useFileManagerStore(
    state => state.files.filter(f => f.status !== UploadStatus.Completed).length,
  );

  const filter = useCallback(
    (f: IFileItem) => createAssessmentFileFilter(organizationId, assessmentId)(f),
    [assessmentId, organizationId],
  );

  useFileManagerFilter(filter);

  const assessmentUrl = useMemo(() => {
    const url = assessmentAppUrlForWebApp({
      organizationId,
      assessmentId,
      location: nodeId
        ? {
            id: nodeId,
          }
        : undefined,
    });

    return url;
  }, [organizationId, assessmentId, nodeId]);

  const assessmentState = assessment?.state as AssessmentState;
  const buttonLabel = useMemo(() => {
    if (!assessmentState) {
      return '';
    }

    if (assessmentState === 'NotStarted') {
      return 'Start';
    }

    if (assessmentState === 'InProgress') {
      return 'Continue';
    }

    if (assessmentState === 'Complete') {
      return 'View';
    }

    return '';
  }, [assessmentState]);

  const scenarioScores = useMemo(() => assessment?.scenarioScores ?? [], [assessment?.scenarioScores]);
  const totalControlPercentage = Math.floor(
    (sumBy(scenarioScores, e => e.effective_control) / sumBy(scenarioScores, e => e.max_control)) * 100,
  );

  const scenariosTotalScore = useMemo(
    () => ({
      low_deficiency_count: sumBy(scenarioScores, e => e.low_deficiency_count),
      medium_deficiency_count: sumBy(scenarioScores, e => e.medium_deficiency_count),
      high_deficiency_count: sumBy(scenarioScores, e => e.high_deficiency_count),
      control_percentage: isNaN(totalControlPercentage) ? 0 : totalControlPercentage,
    }),
    [scenarioScores, totalControlPercentage],
  );

  const scenarioStatInput = useMemo(() => {
    const mapped = scenarioScores.map(scenarioScore => {
      const { scenario_id, control_percentage } = scenarioScore;

      return {
        scenario_id,
        criticalityNumbers: extractCriticalityNumbersFromScore(scenarioScore),
        percentage: control_percentage ?? 0,
      };
    });
    return mapped;
  }, [scenarioScores]);

  if (!assessment || loading) {
    return <CircularProgress />;
  }

  const circadianTabNavProps: TabNavConfig<string> = {
    defaultTab: 'overview',
    baseUrl,
    tabContent: {
      overview: {
        label: 'Overview',
        icon: <DashboardIcon />,
        component: (
          <ScenarioAssessmentOverview
            {...{
              assessmentId,
              scenarioStatInput,
              scenariosTotalScore,
            }}
          />
        ),
      },
      narrative: {
        label: 'Narrative',
        icon: <NotesIcon />,
        component: <ScenarioAssessmentNarrative assessmentId={assessmentId} organizationId={organizationId} />,
      },
      results:
        assessmentState === AssessmentState.Complete
          ? {
              label: 'Results',
              icon: <TimelineIcon />,
              component: (
                <ScenarioAssessmentResults
                  {...{
                    assessment,
                    organizationId,
                    scenarioStatInput,
                    scenariosTotalScore,
                  }}
                />
              ),
            }
          : {
              label: 'Review + Close',
              icon: <PlaylistAddCheckIcon />,
              component: (
                <Card sx={{ width: '100%' }}>
                  <AssessmentItemsAnswersTable
                    assessmentId={assessment.id}
                    tableSx={{ height: 'calc(93vh - 300px)' }}
                  />
                </Card>
              ),
            },
    },
  };

  if (featureFlagSmootherUploads && assessmentState !== AssessmentState.Complete) {
    circadianTabNavProps.tabContent.pendingFiles = {
      label: 'Pending Files',
      icon: (
        <Badge badgeContent={pendingFilesCount > 0 ? pendingFilesCount : 0}>
          <AttachFileIcon />
        </Badge>
      ),
      component: <ScenarioAssessmentPendingFiles assessmentId={assessmentId} organizationId={organizationId} />,
    };
  }

  if (!assessment.is_opened) {
    displayWarning('This scheduled assessment is not opened yet');
    return <Redirect to={generatePath(ROUTES.ASSESSMENTS, { organizationId })} />;
  }

  return (
    <Switch>
      <Route path={`${baseUrl}`} exact render={() => <Redirect to={`${baseUrl}/overview`} />} />
      <Route
        path={`${baseUrl}/:tab`}
        render={({ match }) => {
          const tabOrPage = match.params.tab as TabId;
          if (tabOrPage === 'edit') {
            return (
              <Route
                path={`${baseUrl}/edit`}
                exact
                render={() => (
                  <ScenarioAssessmentEditPage organizationId={organizationId} assessmentId={assessmentId} />
                )}
              />
            );
          }

          if (tabOrPage === 'report' && canAccessReportGenerator) {
            return (
              <Switch>
                <Route
                  path={`${baseUrl}/report/:id`}
                  exact
                  render={() => <AssessmentReportGenerationPage assessmentId={assessmentId} />}
                />
                <Route
                  path={`${baseUrl}/report`}
                  exact
                  render={() => <AssessmentReportGenerationLandingPage assessmentId={assessmentId} />}
                />
              </Switch>
            );
          }

          return (
            <>
              <ScenarioAssessmentHeader
                assessmentName={assessment?.name ?? ''}
                assessmentUrl={assessmentUrl}
                buttonLabel={buttonLabel}
                physicalInspectionProgress={physicalInspectionProgress}
                questionnaireProgress={questionnaireProgress}
                assessmentState={assessmentState}
                assessmentId={assessmentId}
                organizationId={organizationId}
              />
              <Box mx={2}>
                {/* Apply margin for mobile tab nav */}
                <Box width={'100%'} mb={10}>
                  <TabNav {...circadianTabNavProps} top={64 + getThemeSpacing(theme, 2)} />
                </Box>
              </Box>
            </>
          );
        }}
      />
    </Switch>
  );
};

export const ScenarioAssessmentDetailsPage: React.FC<ScenarioAssessmentDetailsPageProps> = props => {
  return <ScenarioAssessmentDetailsPageContent {...props} />;
};
