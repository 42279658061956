import { useApiClient } from '@circadian-risk/api-client-provider';
import { useOrganizationId } from '@circadian-risk/stores';
import fileDownload from 'js-file-download';

export type CreateDownloadOrgFileHandlerParams = {
  fileId: string;
  name: string;
  /**
   * Indicates to fetch from the endpoint that utilizes Supertokens
   * @default false
   */
  useSessionBased?: boolean;
};

export const useDownloadOrgFile = () => {
  const { tsRestClient } = useApiClient();
  const organizationId = useOrganizationId();

  const handler = async ({ fileId, name }: CreateDownloadOrgFileHandlerParams) => {
    const { status, body } = await tsRestClient.files.get({
      params: {
        id: fileId,
        organizationId,
      },
      responseType: 'blob',
    });
    if (status === 200) {
      fileDownload(body as Blob, name);
    }
  };

  return handler;
};
