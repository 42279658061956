import { CreateAssessmentActions, CreateAssessmentLayout } from '@circadian-risk/assessment-components';
import { useNotification } from '@circadian-risk/front-end-utils';
import { Box, Card, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { useAssessmentFormStore } from './AssessmentFormStore';
import { CompletionConditionsStep } from './CompletionConditionsStep';
import { DetailsStep } from './DetailsStep';
import { FormStepper } from './FormStepper';
import { LocationsStep } from './LocationsStep';
import { RootLocationStep } from './RootLocationStep';
import { ScenarioFormData, Steps as FormStep, Steps } from './ScenarioAssessmentForm.types';
import { SmartUsersStepContent } from './SmartUsersStepContent';
import { SummaryStep } from './SummaryStep';

const cardCommonSx: SxProps<Theme> = theme => ({
  width: '100%',
  padding: theme.spacing(4),
  maxWidth: theme.breakpoints.values.md,
});

const getStepSubheader = (step: FormStep, scoredLayerName = 'Location') => {
  switch (step) {
    case FormStep.Details:
      return 'Assessment Details';
    case FormStep.RootSelect:
      return `Choose a location`;
    case FormStep.Locations:
      return `Review ${scoredLayerName} Details`;
    case FormStep.CompletionCriteria:
      return 'Import Answers and Required Actions';
    case FormStep.Users:
      return 'Assign Users';
    case FormStep.Summary:
      return `Summary`;
  }
};

export interface ScenarioAssessmentFormProps {
  onSubmit: () => void;
}

export const ScenarioAssessmentForm: React.FC<ScenarioAssessmentFormProps> = React.memo(({ onSubmit }) => {
  const history = useHistory();
  const { displayError } = useNotification();
  const [creatingAssessment, setCreatingAssessment] = useState(false);
  const { templates, editing } = useAssessmentFormStore(state => ({
    templates: state.templates,
    editing: state.editing,
  }));

  const [activeStep, setActiveStep] = useState<FormStep>(Steps.RootSelect);
  const { trigger, watch } = useFormContext<ScenarioFormData>();
  const selectedNodeIds = watch('locationIds');

  const theme = useTheme();
  const isSmallViewport = !useMediaQuery(theme.breakpoints.up('lg'));

  const handleCancel = useCallback(() => {
    return history.goBack();
  }, [history]);

  const handleBack = useCallback(() => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }, []);

  const handleNext = useCallback(async () => {
    if (activeStep === Steps.RootSelect) {
      const result = await trigger(['assessmentTemplateId', 'rootLocationId']);
      if (!result) {
        return false;
      }
    }

    if (activeStep === Steps.Details) {
      const result = await trigger(['name', 'description']);
      if (!result) {
        return false;
      }
    }

    if (activeStep === Steps.Locations) {
      const result = await trigger(['locationIds']);
      if (!result) {
        return false;
      }
    }

    if (activeStep === Steps.Summary) {
      setCreatingAssessment(true);
      try {
        await onSubmit();
      } catch (e) {
        displayError(e);
      } finally {
        setCreatingAssessment(false);
      }
      return;
    }

    setActiveStep(prevActiveStep => (prevActiveStep as number) + 1);
    return true;
  }, [activeStep, trigger, onSubmit, displayError]);

  const submitting = false;
  const disableNext = submitting || (activeStep === FormStep.Locations && selectedNodeIds.length === 0);

  return (
    <CreateAssessmentLayout
      title={`${editing ? 'Edit' : 'Create'} A Scenario Assessment`}
      subtitle={`Step ${activeStep + 1}: ${getStepSubheader(activeStep)}`}
      formStepper={<FormStepper activeStep={activeStep} vertical={!isSmallViewport} />}
      actions={
        <CreateAssessmentActions
          onCancel={handleCancel}
          back={{ onClick: handleBack, disabled: activeStep === 0 || submitting }}
          next={{
            onClick: handleNext,
            disabled: disableNext || creatingAssessment,
            title: activeStep === FormStep.Summary ? `${editing ? 'Update' : 'Create'} Assessment` : 'Next',
          }}
        />
      }
      activeStepView={
        <Box mb={8} display="flex" flexDirection="column" alignItems="center">
          <Card sx={[cardCommonSx, ...(activeStep !== Steps.RootSelect ? [{ display: 'none' }] : [])]}>
            <RootLocationStep templates={templates} />
          </Card>
          {activeStep === Steps.Details && (
            <Card sx={cardCommonSx}>
              <DetailsStep />
            </Card>
          )}

          {activeStep === Steps.Locations && (
            <Card sx={{ width: '100%' }}>
              <LocationsStep />
            </Card>
          )}

          {activeStep === Steps.CompletionCriteria && (
            <Card sx={cardCommonSx}>
              <CompletionConditionsStep />
            </Card>
          )}

          {activeStep === Steps.Users && (
            <Card sx={{ width: '100%' }}>
              <SmartUsersStepContent />
            </Card>
          )}

          {activeStep === Steps.Summary && (
            <Card sx={cardCommonSx}>
              <SummaryStep />
            </Card>
          )}
        </Box>
      }
    />
  );
});
