import { Box, useTheme } from '@mui/material';
import get from 'lodash/get';
import React from 'react';

import { CriticalityNumberAndIcon } from './CriticalityNumberAndIcon';
import { CriticalityNumberAndLabel } from './CriticalityNumberAndLabel';
import { CriticalityNumber } from './types';

export interface CriticalityNumbersProps {
  criticalityNumbers: CriticalityNumber[];
  variant?: 'mobile' | 'default';
  disableView?: boolean;
}
export const CriticalityNumbers: React.FC<CriticalityNumbersProps> = ({
  criticalityNumbers,
  variant = 'default',
  disableView,
}) => {
  const CriticalityNumberComponent = variant === 'mobile' ? CriticalityNumberAndIcon : CriticalityNumberAndLabel;
  const theme = useTheme();
  const { criticality: criticalityColors } = theme.palette.organizationColors;

  return (
    <Box display="flex" justifyContent="space-between">
      {criticalityNumbers.map((cn, i) => {
        const color = get(criticalityColors, cn.criticality, criticalityColors[1]);
        return (
          <React.Fragment key={`crit-${i}`}>
            <CriticalityNumberComponent {...cn} color={color} disableView={disableView} />
          </React.Fragment>
        );
      })}
    </Box>
  );
};
