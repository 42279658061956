import { ChainableFilter, useFuse } from '@circadian-risk/front-end-utils';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import { useMemo, useState } from 'react';

import { FilterOption } from '../../filter.types';
import { FilterOptionsAggregatesRef } from '../../hooks';

export type useFilterDropdownDataParams = {
  filterOptions: FilterOption<string>[];
  defaultSelectedOption?: string;
  aggregatesRef: FilterOptionsAggregatesRef;
  dirtyParentValue: string | null;
};

const fuzeOptions = { keys: ['label', 'searchValue'], threshold: 0.3 };

export const DEFAULT_SEARCH_PLACEHOLDER = 'Filter...';

export const useFilterDropdownData = ({
  filterOptions,
  defaultSelectedOption,
  aggregatesRef,
  dirtyParentValue,
}: useFilterDropdownDataParams) => {
  const list = useMemo(
    () =>
      filterOptions.filter(e => {
        // If we have an option selection we should keep the parent
        if (defaultSelectedOption === e.value || dirtyParentValue === e.value) {
          return true;
        }
        return !e.selected;
      }),
    [defaultSelectedOption, dirtyParentValue, filterOptions],
  );

  const chainableFilters = useMemo(() => {
    const filters: ChainableFilter<FilterOption>[] = [];

    filters.push(rows => {
      return rows.filter(r => {
        const count = aggregatesRef.current[r.value] ?? 0;
        return count > 0;
      });
    });

    return filters;
  }, [aggregatesRef]);

  const {
    rows: masterRows,
    searchText: masterSearchText,
    setSearchText: masterSetSearchText,
  } = useFuse<FilterOption<string>>({
    options: fuzeOptions,
    list,
    chainableFilters,
  });

  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number | null>(() => {
    if (defaultSelectedOption) {
      return masterRows.findIndex(o => o.value === defaultSelectedOption);
    }
    if (dirtyParentValue) {
      return masterRows.findIndex(o => o.value === dirtyParentValue);
    }

    return null;
  });
  const childrenOptionsVisible = !isNull(selectedOptionIndex);

  const childrenList = useMemo(
    () => (!isNull(selectedOptionIndex) ? masterRows[selectedOptionIndex]?.children ?? [] : []),
    [selectedOptionIndex, masterRows],
  );

  const {
    rows: childrenRows,
    searchText: childrenSearchText,
    setSearchText: childrenSetSearchText,
  } = useFuse<FilterOption<string>>({
    options: fuzeOptions,
    list: childrenList as FilterOption<string>[],
    chainableFilters,
  });

  const [setSearchText, searchText, optionsVisible] = childrenOptionsVisible
    ? ([childrenSetSearchText, childrenSearchText, childrenRows.length > 0] as const)
    : ([masterSetSearchText, masterSearchText, masterRows.length > 0] as const);

  const searchPlaceholder = !isNil(selectedOptionIndex)
    ? filterOptions[selectedOptionIndex]?.searchPlaceholder
    : undefined;

  return {
    childrenRows,
    masterRows,
    searchText,
    searchPlaceholder: searchPlaceholder ?? DEFAULT_SEARCH_PLACEHOLDER,
    setSearchText,
    childrenOptionsVisible,
    optionsVisible,
    selectedOptionIndex,
    setSelectedOptionIndex,
  };
};
