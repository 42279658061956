import {
  CreateScheduledAssessment,
  CustomFrequencyRepeatSchema,
  customRepeatUnionSchema,
} from '@circadian-risk/api-contract';
import { FormStack, generateCircadianForm } from '@circadian-risk/form';
import { HStack, VStack } from '@circadian-risk/presentational';
import { Avatar, Button, Typography } from '@mui/material';
import noop from 'lodash/noop';
import { useFormContext } from 'react-hook-form';

import { customRepeatFrequencyUnitOptions, weekdaysOptions } from './autocomplete-options';

const { GenericFormDialog, ariaLabels, useCircadianForm, Numeric, Select } = generateCircadianForm(
  customRepeatUnionSchema,
  {
    form: 'custom repeat form',
    submit: 'Save',
    value: '',
    repeatUnit: '',
  },
);

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: (formData: CustomFrequencyRepeatSchema) => void;
};

export const CustomRepeatDialog: React.FC<Props> = ({ open, onClose, onConfirm }) => {
  const { watch: parentWatch } = useFormContext<CreateScheduledAssessment>();
  const customFrequencyValues = parentWatch('frequency');

  const formMethods = useCircadianForm({
    mode: 'onSubmit',
    values: customFrequencyValues.type === 'custom' ? customFrequencyValues.options : undefined,
  });

  const { watch } = formMethods;
  const watchedWeekdays = watch('repeatOn') ?? [];
  const watchRepeatUnit = watch('repeatUnit');

  const isWeekdaySelected = (value: (typeof watchedWeekdays)[number]) => {
    return watchedWeekdays.includes(value);
  };

  const toggleWeekdaySelection = (value: (typeof watchedWeekdays)[number]) => {
    const newValues = [...watchedWeekdays];
    const index = newValues.findIndex(e => e === value);
    if (index !== -1) {
      newValues.splice(index, 1);
    } else {
      newValues.push(value);
    }

    formMethods.setValue('repeatOn', newValues, { shouldDirty: true });
  };

  const handleCancel = () => {
    const shouldClose = window.confirm('Your progress will be discarded. Are you sure you want to cancel?');
    if (shouldClose) {
      onClose();
    }
  };

  return (
    <GenericFormDialog
      title="Custom repeat"
      isOpen={open}
      onClose={noop}
      showCloseIcon={false}
      showCancel={false}
      actions={
        <Button color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
      }
      formProps={{
        formMethods,
        ariaLabels,
        onSubmit: onConfirm,
      }}
    >
      <FormStack>
        <HStack>
          <Typography>Repeat every</Typography>
          <Numeric formKey="value" />
          <Select formKey="repeatUnit" options={customRepeatFrequencyUnitOptions} />
        </HStack>
        <VStack>
          {watchRepeatUnit === 'week' && (
            <>
              <Typography>Repeat on</Typography>
              <HStack>
                {weekdaysOptions.map(wo => (
                  <Avatar
                    key={wo.value}
                    sx={theme => ({
                      backgroundColor: isWeekdaySelected(wo.value) ? theme.palette.primary.main : undefined,
                      cursor: 'pointer',
                    })}
                    onClick={() => toggleWeekdaySelection(wo.value)}
                  >
                    {wo.label[0].toUpperCase()}
                  </Avatar>
                ))}
              </HStack>
            </>
          )}
        </VStack>
      </FormStack>
    </GenericFormDialog>
  );
};
