import { useApiClient } from '@circadian-risk/api-client-provider';
import { useNotification } from '@circadian-risk/front-end-utils';
import { sessionAccessPayloadSchema } from '@circadian-risk/shared';
import { useOrganizationSessionStore, useUserSessionStore } from '@circadian-risk/stores';
import * as Sentry from '@sentry/react';
import { useCallback } from 'react';
import Session from 'supertokens-web-js/recipe/session';
import { clear } from 'suspend-react';

import { logoutChannel } from '../logout-broadcast-channel';
import { SignInPageProps } from '../SignInPage';

export const useSupertokensSession = (rememberEmailHandler?: (remember: boolean, email: string) => void) => {
  const { tsRestClient } = useApiClient();
  const { displayError } = useNotification();
  const resetOrganizationData = useOrganizationSessionStore(state => state.resetOrganizationData);
  const { resetSessionData, setSessionData } = useUserSessionStore(({ resetSessionData, setSessionData }) => ({
    setSessionData,
    resetSessionData,
  }));

  const signIn: SignInPageProps['onSubmit'] = async formData => {
    const { email, password, rememberEmail } = formData;

    try {
      const { status } = await tsRestClient.auth.login({
        body: {
          email,
          password,
        },
      });

      // Prevent continuing if the status is not ok
      if (status !== 201) {
        return;
      }

      // TODO(backlog)[CR-2327] Remove as soon as we have remember across both apps
      rememberEmailHandler?.(!!rememberEmail, email);
      if (rememberEmail) {
        // handleRememberPasswordOptions({ remember: true, email });
      } else {
        // handleRememberPasswordOptions({ remember: false });
      }

      // Clears suspend cache
      clear();

      const payload = await Session.getAccessTokenPayloadSecurely();
      if (payload) {
        const accessTokenPayload = sessionAccessPayloadSchema.parse(payload);
        setSessionData(accessTokenPayload);
      }
    } catch (ex) {
      // The exception might come from trying to read the Session payload
      // as tsRest client exceptions will automatically display an error message
      displayError(ex);
    }
  };

  const signOut = useCallback(async () => {
    try {
      await Session.signOut();
      resetSessionData();
      resetOrganizationData();
      // Clears suspense cache
      clear();
      void logoutChannel.postMessage(true);
    } catch (ex) {
      Sentry.captureException(ex);
      displayError(ex);
    }
  }, [displayError, resetOrganizationData, resetSessionData]);

  return {
    signIn,
    signOut,
  };
};
