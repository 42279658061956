import { FCC } from '@circadian-risk/front-end-utils';
import { Box, BoxProps, SxProps, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { MainContent } from '@web-app/components/Drawer';
import { layoutSx } from '@web-app/lib/layoutStyles';
import React from 'react';

import { ResponsiveDrawerProvider } from '../components/Drawer';

export const DetailsPage: FCC = ({ children }) => {
  return (
    <ResponsiveDrawerProvider>
      <Box display="flex">{children}</Box>
    </ResponsiveDrawerProvider>
  );
};

export const DetailsContents: FCC<{ sx?: SxProps<Theme> }> = ({ children, sx }) => {
  return <MainContent sx={sx}>{children}</MainContent>;
};

export interface DetailsHeaderProps {
  title: React.ReactNode;
  sx?: SxProps<Theme>;
  aboveHeader?: React.ReactNode;
  aboveHeaderProps?: Partial<BoxProps>;
}

export const DetailsHeader: FCC<DetailsHeaderProps> = ({ title, children, sx, aboveHeader, aboveHeaderProps }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      py={4}
      sx={[
        {
          flexWrap: isMobile ? 'wrap' : 'nowrap',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box display="flex" flexDirection="column" {...aboveHeaderProps}>
        {aboveHeader}
        <Typography component="h5" sx={layoutSx.pageHeaderText}>
          {title}
        </Typography>
      </Box>

      <Box flex={1} />
      <Box>{children}</Box>
    </Box>
  );
};
