import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import { Box, Button, Checkbox, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import orderBy from 'lodash/orderBy';
import { useRef } from 'react';
import { useKey, useKeyPressEvent } from 'react-use';

import { HStack } from '../../../..';
import { isSelectAllFocused } from '../../filter.helpers';
import { FilterIconType, FilterOption } from '../../filter.types';
import { SELECT_ALL_BUTTON_ID } from '../constants';
import { FilterIcon } from '../FilterIcon';

type RowWithOptionalIcon = Pick<FilterOption, 'value' | 'selected' | 'label'> & {
  icon?: FilterIconType;
};

interface FilterDropdownListProps {
  rows: RowWithOptionalIcon[];
  setNavIndex: (index: number) => void;
  onOptionClick: (value: string, shouldClose: boolean) => void;
  onSelectAllNoneClick: () => void;
  navigationIndex: number;
  displayCheckbox: boolean;
  getAggregatesCount: (filterKey: string) => number;
  /**
   * The logic is controlled through the parent by making sure
   * only "child" options have the aggregates displayed
   */
  hideAggregates: boolean;
}

export const FilterDropdownList: React.FC<FilterDropdownListProps> = ({
  rows,
  onOptionClick,
  navigationIndex,
  setNavIndex,
  displayCheckbox,
  onSelectAllNoneClick,
  getAggregatesCount,
  hideAggregates,
}) => {
  const selectedElRef = useRef<HTMLLIElement | null>(null);

  const scrollOptionIntoView = () => {
    if (selectedElRef.current) {
      selectedElRef.current?.scrollIntoView({
        block: 'center',
      });
    }
  };

  useKey('ArrowUp', scrollOptionIntoView);
  useKey('ArrowDown', scrollOptionIntoView);

  const someChecked = rows.some(({ selected }) => Boolean(selected));

  const sortedRows = orderBy(
    rows.map(r => ({
      ...r,
      aggregateCount: getAggregatesCount(r.value),
    })),
    e => [e.aggregateCount],
    ['desc'],
  );

  useKeyPressEvent('Enter', () => {
    if (isSelectAllFocused()) {
      return;
    }

    const currentOption = sortedRows[navigationIndex];
    onOptionClick(currentOption.value, false);
  });

  return (
    <List dense disablePadding sx={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '300px' }}>
      {displayCheckbox && (
        <Button
          id={SELECT_ALL_BUTTON_ID}
          size="small"
          fullWidth
          onClick={onSelectAllNoneClick}
          startIcon={someChecked ? <RemoveDoneIcon /> : <DoneAllIcon />}
        >
          Select {someChecked ? 'None' : 'All'}
        </Button>
      )}
      {sortedRows.map(({ value, selected, label, icon, aggregateCount }, index) => (
        <ListItem
          dense
          key={value}
          ref={navigationIndex === index ? selectedElRef : null}
          onMouseEnter={() => setNavIndex(index)}
          onClick={() => onOptionClick(value, true)}
          sx={theme => ({
            cursor: 'pointer',
            px: 1,
            borderRadius: 1,
            background: navigationIndex === index ? theme.palette.primary.background : undefined,
          })}
        >
          {displayCheckbox && (
            <ListItemIcon>
              <Checkbox
                tabIndex={-1}
                sx={{ p: 1, m: -1 }}
                size="small"
                checked={selected ?? false}
                onClick={e => {
                  // this prevents from clicking on the whole row when targeting checkbox
                  e.stopPropagation();
                  onOptionClick(value, false);
                }}
              />
            </ListItemIcon>
          )}
          {icon && (
            <ListItemIcon>
              <FilterIcon icon={icon} />
            </ListItemIcon>
          )}
          <ListItemText
            primary={
              <HStack spacing={2}>
                <Box>{label}</Box>
                {!hideAggregates && (
                  <Typography variant="caption" color={theme => theme.palette.text.secondary}>
                    {aggregateCount}
                  </Typography>
                )}
              </HStack>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};
