import { ColorModeToggleGroup, useIsFeatureFlagEnabledForOrg } from '@circadian-risk/stores';
import { ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material';

export const SideColorModeToggle = () => {
  const enabled = useIsFeatureFlagEnabledForOrg('dark_mode');

  if (!enabled) {
    return null;
  }

  return (
    <ListItem key="dark-mode-toggle">
      <ListItemText>Color Mode</ListItemText>
      <ListItemSecondaryAction>
        <ColorModeToggleGroup />
      </ListItemSecondaryAction>
    </ListItem>
  );
};
