import {
  HStack,
  ItemIconSizes,
  ItemThumbnail,
  LightboxGalleryImage,
  LightboxWithEditingProps,
  OpenInNewTabLink,
  OpenInNewTabLinkProps,
} from '../..';

export interface ItemWithLinkProps extends Pick<OpenInNewTabLinkProps, 'textTypographyProps'> {
  name: string;
  itemCategoryName?: string;
  iconSrc?: string;
  photos?: LightboxGalleryImage[];
  size?: ItemIconSizes;
  link?: string;
  onImageProcessing?: LightboxWithEditingProps['onImageProcessing'];
}

export const ItemWithLink: React.FC<ItemWithLinkProps> = props => {
  const { name, itemCategoryName, iconSrc, photos, size, link, onImageProcessing, textTypographyProps } = props;

  return (
    <HStack width="unset">
      <ItemThumbnail
        size={size}
        name={itemCategoryName ?? name}
        disableTooltip={!itemCategoryName}
        iconSrc={iconSrc}
        photos={photos}
        onImageProcessing={onImageProcessing}
      />
      {link ? <OpenInNewTabLink link={link} text={name} textTypographyProps={textTypographyProps} /> : name}
    </HStack>
  );
};
