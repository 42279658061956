import isNil from 'lodash/isNil';
import { useCallback, useEffect, useState } from 'react';

import { ActiveFieldsState, QuestionProps, SetActiveFieldCallback } from '../question.types';

export const useActiveFields = (
  value: QuestionProps['value'],
  fileCount: number,
  options: NonNullable<QuestionProps['optionsSectionProps']>['options'],
): { activeFields: ActiveFieldsState; setActiveField: SetActiveFieldCallback } => {
  const [activeFields, setActiveFields] = useState<ActiveFieldsState>({
    description: !isNil(value?.description ?? null),
    // This should always read the most updated and cannot evaluate the initial active fields
    options: options.length > 0,
    files: fileCount > 0,
  });

  // Keep fileCount in sync because it is non-editable as the others
  useEffect(() => {
    setActiveFields(prev => ({ ...prev, files: fileCount > 0 }));
  }, [fileCount]);

  useEffect(() => {
    setActiveFields(prev => ({ ...prev, options: options.length > 0 }));
  }, [options.length]);

  useEffect(() => {
    setActiveFields(prev => ({ ...prev, description: !isNil(value?.description ?? null) }));
  }, [value?.description]);

  const setActiveField = useCallback((name: keyof ActiveFieldsState, state: boolean) => {
    setActiveFields(prev => ({
      ...prev,
      [name]: state,
    }));
  }, []);

  return { activeFields, setActiveField };
};
