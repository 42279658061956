import { FCC } from '@circadian-risk/front-end-utils';
import { Tooltip } from '@mui/material';
import React from 'react';

import { useReportGenerationStore } from './reportGenerationStore';

const REPORT_PREVIEW_DISABLED_TOOLTIP = 'Please wait for your data selection to update';

export const ReportPreviewTooltipWrapper: FCC = ({ children }) => {
  const previewDisabled = useReportGenerationStore(state => state.previewDisabled);
  return previewDisabled ? (
    <Tooltip title={REPORT_PREVIEW_DISABLED_TOOLTIP} placement={'bottom'}>
      <span>{children}</span>
    </Tooltip>
  ) : (
    <>{children}</>
  );
};
