import { CircadianRoute, ROUTES } from '@circadian-risk/routes';
import { USER_ROLE } from '@circadian-risk/shared';
import { lazyLoadWithVersionCheck } from '@web-app/router/lazyLoadWithVersionCheck';

const ScheduledAssessmentsPage = lazyLoadWithVersionCheck(() => import('./ScheduledAssessmentsPage'));

export const scheduledAssessmentRoutes: CircadianRoute[] = [
  {
    path: ROUTES.SCHEDULED_ASSESSMENTS,
    isExact: true,
    component: ScheduledAssessmentsPage,
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN, USER_ROLE.LOCATION_MANAGER],
  },
];
