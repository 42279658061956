import { InfoBox } from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { useOrganizationId } from '@circadian-risk/stores';
import { Box, Button, useTheme } from '@mui/material';
import React, { useCallback } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import { sectionInfoPreviewText } from './helpers';

export interface RiskAnalysisInfoBoxProps {
  locationId?: string;
  disabled?: boolean;
}

export const RiskAnalysisInfoBox: React.FC<RiskAnalysisInfoBoxProps> = ({ locationId, disabled }) => {
  const theme = useTheme();
  const history = useHistory();
  const organizationId = useOrganizationId();
  const handleOpenLocationDetailsClick = useCallback(() => {
    if (!locationId) {
      return;
    }
    const path = `${generatePath(ROUTES.NODES, {
      organizationId,
    })}/${locationId}`;
    history.push(path);
  }, [history, organizationId, locationId]);

  return (
    <InfoBox disableTypographyWrapper>
      <Box aria-label={'executive summary info'} display={'flex'} alignItems={'center'}>
        <Box flex={1} color={theme.palette.info.dark}>
          Information in this section comes from the Location Details Page.&nbsp;{sectionInfoPreviewText}
        </Box>
        <Box ml={1}>
          <Button
            aria-label={'open location details'}
            variant="outlined"
            color="primary"
            onClick={handleOpenLocationDetailsClick}
            disabled={disabled}
          >
            <Box color={theme.palette.info.dark}>Open Location Details</Box>
          </Button>
        </Box>
      </Box>
    </InfoBox>
  );
};
