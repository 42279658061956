import { useIsDarkMode } from '@circadian-risk/front-end-utils';
import { useTheme } from '@mui/material';
import { AgChartTheme, AgChartThemeName, AgPieSeriesOptions } from 'ag-charts-community';

export const useAgChartStyles = () => {
  const theme = useTheme();
  const isDarkMode = useIsDarkMode();

  // we can customize chart theme object here
  const baseTheme: AgChartThemeName = isDarkMode ? 'ag-default-dark' : 'ag-default';
  const agChartTheme: AgChartTheme = {
    baseTheme,
    overrides: {
      common: {
        background: {
          visible: false,
        },
      },
    },
  };

  const highlightStyle: AgPieSeriesOptions['highlightStyle'] = {
    item: {
      fill: theme.palette.background.default,
      stroke: theme.palette.primary.main,
      strokeWidth: 1,
    },
    // this can be enabled to dim other series
    // series: {
    //   dimOpacity: 0.5,
    // },
  };
  return { highlightStyle, agChartTheme };
};
