import { generateCircadianForm } from '@circadian-risk/form';

import { createLocationFilterSchema } from './create-location-filter.schema';

export const createLocationFilterForm = generateCircadianForm(createLocationFilterSchema, {
  form: 'create location filter form',
  submit: 'Save Filter Criteria',
  name: 'Name',
  description: 'Description',
  baseLayerId: 'Choose base location layer',
  includeChildren: 'Include children',
  belongsToId: 'That Belongs to',
  tagIds: 'With layer tags',
});
