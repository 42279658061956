import fromPairs from 'lodash/fromPairs';
import isEqual from 'lodash/isEqual';

import { ObjectDiff } from './diff.types';

/**
 * Calculates the diff between two objects
 *
 * @param oldObj Source object
 * @param newObj Target object
 * @param excludeKeys Any keys to exclude from comparision. eg. updated_at
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const diffObject = <T extends Record<string, any>>(
  oldObj: T,
  newObj: T,
  excludeKeys: (keyof T)[] = [],
): ObjectDiff<T> => {
  const changedProperties = Object.keys(oldObj)
    .filter(k => !isEqual(newObj[k], oldObj[k]) && (newObj[k] != null || oldObj[k] != null))
    .filter(key => !excludeKeys.includes(key));

  return fromPairs(
    changedProperties.map(propKey => [propKey, { from: oldObj[propKey], to: newObj[propKey] }]),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as any;
};
