import { AssessmentItemStatuses } from '../..';

type Common = { status: AssessmentItemStatuses };

export type PlacingExistingItem = { itemId: string } & Common;

export type PlacingNewItem = { itemCategoryId: string } & Common;

export type PlacementInteractionData = PlacingExistingItem | PlacingNewItem;

export const isPlacingExistingItem = (data: PlacementInteractionData): data is PlacingExistingItem => {
  return Boolean((data as PlacingExistingItem)?.itemId);
};
