import { createContext, useContext, useRef } from 'react';
import { Mutate, StateCreator, StoreApi, UseBoundStore, useStore } from 'zustand';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { FCC } from '../react-types';

export type UseStoreFunc<T> = {
  (): T;
  <S>(selector: (state: T) => S): S;
};

export type Store<S> = UseBoundStore<Mutate<StoreApi<S>, [['zustand/subscribeWithSelector', never]]>>;

export const createZustandContext = <State,>() => {
  const StoreContext = createContext<StoreApi<State> | undefined>(undefined);

  const StoreProvider: FCC<{ initializer: StateCreator<State> }> = ({ children, initializer }) => {
    const storeRef = useRef<StoreApi<State> | undefined>();

    if (!storeRef.current) {
      storeRef.current = createWithEqualityFn(initializer, shallow);
    }

    return <StoreContext.Provider value={storeRef.current}>{children}</StoreContext.Provider>;
  };

  const useStoreApi = () => {
    const store = useContext(StoreContext);
    if (!store) {
      throw new Error('Missing StoreProvider');
    }
    return store;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const useStoreInContext = ((selector?: any) => {
    const api = useStoreApi();
    return useStore(api, selector);
  }) as UseStoreFunc<State>;

  return { StoreProvider, useStore: useStoreInContext, useStoreApi };
};
