import { useIsDarkMode } from '@circadian-risk/front-end-utils';
import { Box, CircularProgress, CircularProgressProps } from '@mui/material';

interface CircularProgressWithBgProps extends CircularProgressProps {
  progressBgColorWhite?: boolean;
}

const DEFAULT_SIZE = 24;
const DEFAULT_THICKNESS = 10;

export const CircularProgressWithBg: React.FC<CircularProgressWithBgProps> = ({
  progressBgColorWhite,
  ...circularProgressProps
}) => {
  const isDark = useIsDarkMode();

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: theme => theme.palette.grey[isDark ? 700 : 400],
          left: 0,
        }}
        size={DEFAULT_SIZE}
        thickness={DEFAULT_THICKNESS}
        {...circularProgressProps}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        sx={{
          color: theme => (progressBgColorWhite ? theme.palette.common.white : '#1a90ff'),
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
        }}
        size={DEFAULT_SIZE}
        thickness={DEFAULT_THICKNESS}
        {...circularProgressProps}
      />
    </Box>
  );
};
