import { Enum_Answer_Responses_Enum } from '@circadian-risk/graphql-types';
import { z } from 'zod';

import { createAssessmentBaseSchema } from './create-assessment-base-schema';

export const createSingleAssessmentSchema = createAssessmentBaseSchema.extend({
  assessmentTemplateId: z.number(),
  locationIds: z.array(z.string().uuid()),
  importActiveAnswers: z.boolean().optional(),
  ratificationRequired: z
    .object({
      compliant: z.boolean().nullish(),
      deficient: z.boolean().nullish(),
      n_a: z.boolean().nullish(),
      n_o: z.boolean().nullish(),
    })
    .nullish(),
  startDate: z.string().nullish(),
  dueDate: z.string().nullish(),
});

export const updateAssessmentSchema = createSingleAssessmentSchema.pick({
  name: true,
  description: true,
  startDate: true,
  dueDate: true,
  locationIds: true,
  timezone: true,
  assignment: true,
});

export const updateAssignmentSchema = createSingleAssessmentSchema.pick({ assignment: true });

export const reimportResponseSchema = z.array(
  z.object({
    is_flagged: z.boolean().nullable(),
    response: z.custom<Enum_Answer_Responses_Enum>().nullable(),
    partial_compliance_multiplier: z.number(),
  }),
);

export const pathParamsCommon = z.object({
  organizationId: z.string().uuid(),
  id: z.coerce.number(),
});

export type CreateSingleAssessment = z.output<typeof createSingleAssessmentSchema>;
export type UpdateAssessment = z.output<typeof updateAssessmentSchema>;
export type UpdateAssessmentAssignment = z.output<typeof updateAssignmentSchema>;
