import { CircadianRoute, ROUTES } from '@circadian-risk/routes';
import { USER_ROLE } from '@circadian-risk/shared';
import { lazyLoadWithVersionCheck } from '@web-app/router/lazyLoadWithVersionCheck';

const ItemsPage = lazyLoadWithVersionCheck(() => import('./ItemsPage'));
const LocationItemsPivotPage = lazyLoadWithVersionCheck(() => import('./LocationItemsPivotPage'));

export const additionalItemRoutes: CircadianRoute[] = [
  {
    path: ROUTES.LOCATION_ITEMS,
    isExact: true,
    component: ItemsPage,
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN, USER_ROLE.LOCATION_MANAGER],
  },
  {
    path: ROUTES.PIVOT,
    isExact: true,
    component: LocationItemsPivotPage,
  },
];
