import { SimpleItemIcon } from '@circadian-risk/presentational';
import {
  Card,
  CardContent,
  CardHeader,
  CardProps,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import orderBy from 'lodash/orderBy';
import React from 'react';

const MAX_ITEMS_TO_SHOW = 5;

const TOOLTIP_TEXT =
  'These are the top item categories in this scenario by total number of associated questions. You can see the question count for each item category in parentheses.';

interface ItemCategory {
  name: string;
  count: number;
  iconSrc?: string;
}
export interface SampleItemCategoriesCardProps extends Omit<CardProps, 'title'> {
  itemCategories: ItemCategory[];
  title?: JSX.Element & React.ReactNode;
}

export const SampleItemCategoriesCard: React.FC<SampleItemCategoriesCardProps> = ({
  itemCategories,
  title,
  ...cardProps
}) => {
  const orderedItemCategories = orderBy(itemCategories, ic => ic.count, 'desc');
  return (
    <Card {...cardProps}>
      <CardHeader
        sx={{ paddingBottom: 0 }}
        title={
          <Tooltip title={TOOLTIP_TEXT}>
            <Typography variant="h5">{title ?? 'Sample Item Categories'}</Typography>
          </Tooltip>
        }
      />
      <CardContent sx={{ paddingTop: 0 }}>
        <List
          dense
          sx={{
            mask: itemCategories.length > MAX_ITEMS_TO_SHOW ? 'linear-gradient(black 50%, transparent)' : undefined,
          }}
        >
          {orderedItemCategories.slice(0, MAX_ITEMS_TO_SHOW).map(ic => (
            <ListItem key={ic.name}>
              <ListItemIcon>
                <SimpleItemIcon alt={ic.name} src={ic.iconSrc} />
              </ListItemIcon>
              <ListItemText>
                {ic.name} ({ic.count})
              </ListItemText>
            </ListItem>
          ))}
        </List>
        <Typography align="center" color={({ palette }) => palette.text.secondary}>
          {itemCategories.length} Item Categories in Total
        </Typography>
      </CardContent>
    </Card>
  );
};
