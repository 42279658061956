import { MAX_NOTIFICATIONS } from '@circadian-risk/api-contract';
import { HStack, VStack } from '@circadian-risk/presentational';
import CloseIcon from '@mui/icons-material/Close';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Button, IconButton } from '@mui/material';
import { useFieldArray } from 'react-hook-form';

import { createScheduleAssessmentForm, useCreateScheduledAssessmentFormContext } from '../../../form';
import {
  dueSequenceOptions,
  notificationTimeUnitOptions,
  notificationTypeOptions,
  startSequenceOptions,
  triggerOptions,
} from '../autocomplete-options';

const { Select, Numeric } = createScheduleAssessmentForm;

export const NotificationsFormGroup: React.FC = () => {
  const { control, formState } = useCreateScheduledAssessmentFormContext();
  const {
    fields: notifications,
    append,
    remove,
    update,
  } = useFieldArray({
    control,
    name: 'notifications',
  });

  const handleAddNotification = () => {
    append({
      type: 'email',
      value: 1,
      unit: 'minute',
      when: 'before',
      trigger: 'start',
    });
  };

  return (
    <VStack alignItems={'baseline'}>
      {notifications.map((field, i) => {
        const sequenceOptions = field.trigger === 'start' ? startSequenceOptions : dueSequenceOptions;
        return (
          <HStack key={field.id}>
            <Select
              formKey={`notifications.${i}.type`}
              startAdornment={<NotificationsIcon color="secondary" />}
              label="Notification Type"
              options={notificationTypeOptions}
              boxProps={{ minWidth: 220 }}
            />
            <Numeric
              formKey={`notifications.${i}.value`}
              errorMessage={formState.errors.notifications?.[i]?.value?.message}
            />
            <Select formKey={`notifications.${i}.unit`} options={notificationTimeUnitOptions} label="" />
            <Select formKey={`notifications.${i}.when`} options={sequenceOptions} label="" />
            <Select
              formKey={`notifications.${i}.trigger`}
              options={triggerOptions}
              label=""
              onChangeCallback={c => {
                const option = { ...notifications[i] };
                const newTrigger = c === 'start' ? 'start' : 'due';
                // Reset to "before" as due does not support other options
                const when = newTrigger === 'due' ? 'before' : option.when;

                // Updating the item will force a re-rendering of the notification
                // item which will evaluate the sequenceOptions based on the new trigger
                update(i, {
                  ...option,
                  when,
                  trigger: newTrigger,
                });
              }}
            />
            <IconButton onClick={() => remove(i)}>
              <CloseIcon />
            </IconButton>
          </HStack>
        );
      })}
      <Button
        variant="text"
        color="primary"
        sx={{ textTransform: 'uppercase' }}
        onClick={handleAddNotification}
        disabled={notifications.length >= MAX_NOTIFICATIONS}
      >
        Add Notification
      </Button>
    </VStack>
  );
};
