import isError from 'lodash/isError';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';

type ServerError = {
  isAxiosError: boolean;
  response: {
    data: string | { message: string };
  };
};

const isServerError = (error: string | Error | object | unknown): error is ServerError => {
  return (error as ServerError).isAxiosError !== undefined;
};

export const extractErrorMessage = (error: string | Error | object | unknown): string => {
  if (isServerError(error)) {
    const data = error?.response?.data;
    // Attempt to safely parse data.message in case it's a JSON string
    try {
      const parsedError = JSON.parse((data as { message: string }).message);
      if (Array.isArray(parsedError) && isString(parsedError[0].message)) {
        return parsedError[0].message;
      }
    } catch {
      // If parsing throws an error it's okay because then it means It's either a normal string or nothing is defined
    }

    if (isString(data)) {
      return data;
    }
    return data?.message ?? 'Something went wrong';
  }

  if (isString(error)) {
    return error;
  }

  if (isError(error)) {
    return error.message;
  }

  if (isObject(error)) {
    return JSON.stringify(error);
  }

  return 'Error not found';
};
