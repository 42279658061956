import { Box } from '@mui/material';
import { BoxProps, styled } from '@mui/system';

const ContainerBox = styled(Box)(() => ({
  containerType: 'inline-size',
}));

/**
 * Use this when you want to style the Box when the container is larger than the specified width
 *
 * You can still pass any styles to the `sx` prop. Only provided styles will override the default styles
 *
 * @param containerMinWidth pass any styles that will only kick in when the container is larger than the specified width
 */
const MinWidthBox = styled(Box)<{ containerMinWidth: string; sxWhenLargerThanMin: BoxProps['sx'] }>(
  ({ containerMinWidth, sxWhenLargerThanMin }) => ({
    [`@container (min-width: ${containerMinWidth})`]: {
      ...sxWhenLargerThanMin,
    },
  }),
);

/**
 * Use this when you want to style the Box when the container is smaller than the specified width
 *
 * You can still pass any styles to the `sx` prop. Only provided styles will override the default styles
 *
 * @param containerMaxWidth pass any styles that will only kick in when the container is smaller than the specified width
 */
const MaxWidthBox = styled(Box)<{ containerMaxWidth: string; sxWhenSmallerThanMax: BoxProps['sx'] }>(
  ({ containerMaxWidth, sxWhenSmallerThanMax }) => ({
    [`@container (max-width: ${containerMaxWidth})`]: {
      ...sxWhenSmallerThanMax,
    },
  }),
);

/**
 * Container queries are not supported by MUI yet, but this is a workaround to use them with sx prop
 */
export const ContainerQueryBox = { Container: ContainerBox, MinWidth: MinWidthBox, MaxWidth: MaxWidthBox };
