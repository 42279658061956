import { createBreadCrumbs } from '@circadian-risk/front-end-utils';
import { useOrganizationSessionStore } from '@circadian-risk/stores';
import { Box } from '@mui/material';
import { LocationLink } from '@web-app/components/LocationLinkRenderer';
import React from 'react';

interface LocationLinkProps {
  nodeId: string;
  includeBreadCrumbs?: boolean;
  target?: string;
  disabled?: boolean;
}

export const SmartLocationLink: React.FC<LocationLinkProps> = ({
  disabled,
  nodeId,
  target,
  includeBreadCrumbs = true,
}) => {
  const { nodesById, layersById } = useOrganizationSessionStore(({ nodesById, layersById }) => ({
    nodesById,
    layersById,
  }));
  const node = nodesById[nodeId];
  const value = node?.name ?? 'Unknown Location';
  const layerName = node?.layer_id ? layersById[node?.layer_id].name : 'Unknown Layer';
  let breadCrumbs;
  if (node) {
    breadCrumbs = includeBreadCrumbs ? createBreadCrumbs(node.mpath, nodesById, layersById) : [];
  }

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <LocationLink
        value={value}
        data={{
          nodeId,
          layerName,
          breadCrumbs,
        }}
        target={target}
        disabled={disabled}
      />
    </Box>
  );
};
