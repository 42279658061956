import { LocationHierarchyPicker } from '@circadian-risk/form';
import { HStack, NodeIcon } from '@circadian-risk/presentational';
import { sortLocationsByOrdinalAndName } from '@circadian-risk/shared';
import { Typography } from '@mui/material';
import sortBy from 'lodash/sortBy';
import React, { useEffect, useMemo } from 'react';

import { useLocationWizardStore } from '../../locationWizard.store';
import { WizardLocation, WizardLocationLayer } from '../../types';
import { LocationFormData } from '../LocationForm';
import { EditPanel } from '../MainPanel/EditPanel';
import { ViewPanel } from '../MainPanel/ViewPanel';
import { Layout } from './Layout';

export type EditSubmitData = LocationFormData & { parentId: string; locationId: string; layerId: string };
export type AddSubmitData = LocationFormData & { parentId: string; layerId: string };

type InitialMode =
  | { type: 'editing'; locationId: string }
  | { type: 'adding'; parentLocationId?: string; locationName?: string }
  | { type: 'viewing'; locationId?: string };

export interface LocationWizardProps {
  layers: WizardLocationLayer[];
  locations: WizardLocation[];
  /** initial mode when opening the modal, helpful when wanting to open to a specific location */
  initialMode?: InitialMode;
  getLocationDetailsRoute: (locationId: string) => string;
  onEditSubmit: (data: EditSubmitData) => Promise<void>;
  onAddSubmit: (data: AddSubmitData) => Promise<void>;
}

export const LocationWizard: React.FC<LocationWizardProps> = ({
  layers,
  locations,
  initialMode,
  getLocationDetailsRoute,
  onEditSubmit,
  onAddSubmit,
}) => {
  const { changeMode, reset, activeId, setActiveId } = useLocationWizardStore(
    ({ changeMode, reset, activeId, setActiveId }) => ({ changeMode, reset, activeId, setActiveId }),
  );

  const rootLocation = locations.find(n => n.parent_id === null);
  const rootLocationId = rootLocation?.id;
  const selectableLocationId =
    rootLocation?.selectable && rootLocationId ? rootLocationId : locations.find(l => l.selectable)?.id ?? '';

  useEffect(() => {
    if (initialMode) {
      switch (initialMode.type) {
        case 'viewing':
          setActiveId(initialMode.locationId ?? selectableLocationId);
          changeMode({ type: 'viewing' });
          break;
        case 'editing':
          changeMode({ type: 'editing', editingId: initialMode.locationId });
          break;
        case 'adding':
          setActiveId(initialMode.parentLocationId ?? selectableLocationId);
          changeMode({ type: 'adding', locationName: initialMode.locationName });
          break;
      }
    } else {
      setActiveId(selectableLocationId);
    }
    return reset;
    // we don't want to reset the initial id while the user is in the location wizard
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!rootLocation) {
    throw new Error('Data should have a root node with a parent Id');
  }

  const sortedLayers = useMemo(() => sortBy(layers, l => l.layerDepth), [layers]);
  const { selectedLayer, selectedLocation } = useMemo(() => {
    const selectedLocation = locations.find(l => l.id === activeId) ?? rootLocation;
    const selectedLayer = sortedLayers.find(l => l.id === selectedLocation.layer_id) as WizardLocationLayer;
    return { selectedLocation, selectedLayer };
  }, [locations, rootLocation, sortedLayers, activeId]);

  // this can be null if the children are not allowed to be added
  const directChildLocations = locations.filter(l => l.parent_id === activeId).filter(l => l.selectable);
  const sortedDirectChildren = sortLocationsByOrdinalAndName(directChildLocations);

  const childLayer = selectedLayer ? sortedLayers.find(l => l.layerDepth === selectedLayer.layerDepth + 1) : null;
  const childChildLayer = childLayer ? sortedLayers.find(l => l.layerDepth === childLayer?.layerDepth + 1) : null;

  const handleEditSubmit = async (data: EditSubmitData) => {
    await onEditSubmit({ ...data, parentId: activeId });
    changeMode({ type: 'viewing' });
  };

  const handleAddSubmit = async (data: AddSubmitData) => {
    await onAddSubmit({ ...data, parentId: activeId });
    changeMode({ type: 'viewing' });
  };

  return (
    <Layout
      locationNavigator={
        <LocationHierarchyPicker
          height="fill-available"
          allowedNodeIds={locations.filter(l => l.selectable).map(l => l.id)}
          options={locations.map(l => ({
            id: l.id,
            layerTags: l.layerTags,
            mpath: l.mpath,
            ordinal: l.ordinal,
            parentId: l.parent_id,
            name: l.name,
            layerName: layers.find(layer => layer.id === l.layer_id)?.name ?? '',
            layerId: l.layer_id,
          }))}
          label=""
          hideSelectedLocationsSection
          value={[activeId]}
          multiple={false}
          onChange={newValue => setActiveId(newValue[0])}
        />
      }
      backPanel={
        <EditPanel
          parentLocation={selectedLocation}
          allLocations={locations}
          layers={layers}
          layer={childLayer ?? null}
          createLocationRoute={getLocationDetailsRoute}
          onAddSubmit={handleAddSubmit}
          onEditSubmit={handleEditSubmit}
        />
      }
      frontPanel={
        <ViewPanel
          parentLocation={selectedLocation}
          parentLayer={selectedLayer}
          tableLocations={sortedDirectChildren}
          layer={childLayer ?? null}
          childLayer={childChildLayer ?? null}
        />
      }
      legend={
        <>
          {sortedLayers.map(layer => (
            <HStack key={layer.name} noFullWidth>
              <NodeIcon layerName={layer.name} />
              <Typography>{layer.name}</Typography>
            </HStack>
          ))}
        </>
      }
    />
  );
};
