import { FilterChangedEvent, FirstDataRenderedEvent } from '@ag-grid-community/core';
import { AgGridReactProps } from '@ag-grid-community/react';
import * as Sentry from '@sentry/react';
import isEqual from 'lodash/isEqual';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { compareAndSetModel } from '../utils';
import { FilterSearchParam } from './constants';

/**
 * Used in conjunction with AgGridReact - Pass onFilterChanged handler to onFilterChanged prop
 */
export const useGridFilter = () => {
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const filterQueryParam = query.get(FilterSearchParam);

  const onFirstDataRendered: AgGridReactProps['onFirstDataRendered'] = useCallback(
    ({ api: gridApi, columnApi }: FirstDataRenderedEvent) => {
      columnApi.autoSizeAllColumns();
      if (!filterQueryParam) {
        return;
      }

      // Safely parse filterQuery parameters
      try {
        const filterObj = JSON.parse(filterQueryParam);
        if (!isEqual(filterObj, gridApi.getFilterModel())) {
          gridApi.setFilterModel(filterObj);
          gridApi.onFilterChanged();
        }
      } catch (ex) {
        Sentry.captureException(ex);
      }
    },
    [filterQueryParam],
  );

  const onFilterChanged = useCallback(
    ({ api }: FilterChangedEvent) => {
      const filterModel = api.getFilterModel();
      compareAndSetModel(FilterSearchParam, history, filterQueryParam, JSON.stringify(filterModel));
    },
    [filterQueryParam, history],
  );

  return {
    onFirstDataRendered,
    onFilterChanged,
  };
};
