import { Enum_Custom_Repeat_On_Type_Enum, Enum_Schedule_Unit_Enum } from '@circadian-risk/graphql-types';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { z } from 'zod';

import { createAssessmentBaseSchema } from './create-assessment-base-schema';

dayjs.extend(isSameOrAfter);

// We have to keep a copy of the enum as we don't have a field
// that consumes this enum
// Hasura doesn't generate the type if there isn't at least one field that uses it
type Enum_Weekday_Enum = 'mon' | 'tues' | 'wed' | 'thur' | 'fri' | 'sat' | 'sun';

const unitSchema = z.custom<Enum_Schedule_Unit_Enum>();
const weekdaySchema = z.custom<Enum_Weekday_Enum>();
const customRepeatFrequencyUnitSchema = z.custom<Enum_Custom_Repeat_On_Type_Enum>();
const positiveIntSchema = z.number().int().positive();

export const customRepeatUnionSchema = z.discriminatedUnion('repeatUnit', [
  z.object({ repeatUnit: z.literal('hour'), value: positiveIntSchema }),
  z.object({ repeatUnit: z.literal('day'), value: positiveIntSchema }),
  z.object({
    repeatUnit: z.literal('week'),
    value: positiveIntSchema,
    repeatOn: z.array(weekdaySchema).min(1),
  }),
  z.object({
    repeatUnit: z.literal('month'),
    value: positiveIntSchema,
  }),
  z.object({ repeatUnit: z.literal('year'), value: positiveIntSchema }),
]);

const customFrequencyBaseSchema = z.object({
  type: z.literal('custom'),
  options: customRepeatUnionSchema,
});

const frequencySchema = z.discriminatedUnion('type', [
  z.object({ type: z.literal('daily') }),
  z.object({ type: z.literal('weekly') }),
  z.object({ type: z.literal('monthly') }),
  z.object({ type: z.literal('weekday') }),
  z.object({ type: z.literal('annually') }),
  customFrequencyBaseSchema,
]);

const endsOnSchema = z.discriminatedUnion('type', [
  z.object({ type: z.literal('never'), value: z.null() }),
  z.object({ type: z.literal('after_completed_amount'), value: positiveIntSchema.max(100) }),
  z.object({ type: z.literal('target_date'), value: z.date().or(z.string()) }),
]);

const importAnswerGroupValueSchema = z.array(z.enum(['n/a', 'n/o', 'compliant', 'deficient']));

export const createScheduledAssessmentSchema = createAssessmentBaseSchema.extend({
  startsAtDate: z
    .date()
    .or(z.string())
    .refine(
      val => {
        const today = dayjs().startOf('day'); // Start of today (00:00:00)
        const value = dayjs(val).startOf('day'); // Start of the given date (00:00:00)

        // Ensure the date is not in the past
        return value.isSameOrAfter(today);
      },
      { message: 'Start date must not be in the past' },
    ),
  // Receives the time with hh:mm a format (12h-hour clock)
  startsAtTime: z.string(),
  endsOn: endsOnSchema,
  assessmentTemplateId: z.number(),
  // Locations(s) might go empty if a location filter is used instead.
  // The server will then read the applicable location(s) and store them in the database.
  locationsIds: z.array(z.string().uuid()),
  locationFilterId: z.number().nullish(),
  frequency: frequencySchema,
  dueAfter: z.object({
    value: positiveIntSchema,
    unit: unitSchema,
  }),
  timezone: z.string(),
  importAnswers: z.discriminatedUnion('type', [
    z.object({ type: z.literal('fresh') }),
    z.object({
      type: z.literal('groups'),
      value: importAnswerGroupValueSchema.min(1, { message: 'Please select at least one group to review & ratify' }),
    }),
  ]),
});

export const createScheduledAssessmentResponseSchema = z.object({
  id: z.number(),
});

export type CreateScheduledAssessmentResponse = z.infer<typeof createScheduledAssessmentResponseSchema>;
export type CreateScheduledAssessment = z.infer<typeof createScheduledAssessmentSchema>;
export type Frequency = z.infer<typeof frequencySchema>;
export type FrequencyType = Frequency['type'];
export type DueAfter = CreateScheduledAssessment['dueAfter'];
export type UnitType = z.infer<typeof unitSchema>;
export type WeekdayType = z.infer<typeof weekdaySchema>;
export type CustomFrequencySchema = z.infer<typeof customFrequencyBaseSchema>;
export type CustomFrequencyRepeatSchema = z.infer<typeof customRepeatUnionSchema>;
export type CustomRepeatFrequencyUnitSchema = z.infer<typeof customRepeatFrequencyUnitSchema>;
export type ImportAnswerGroupValueType = z.infer<typeof importAnswerGroupValueSchema>[0];
export type EndsOn = z.infer<typeof endsOnSchema>;
export type ImportAnswers = z.infer<typeof createScheduledAssessmentSchema>['importAnswers'];
