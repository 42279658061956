import { VStack } from '@circadian-risk/presentational';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, BoxProps, Button, Typography, useTheme } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import React from 'react';

interface FileListProps {
  onAddButtonClicked?: () => void;
  children?: React.ReactNode[];
  title?: string;
  dense?: boolean;
  wrapperProps?: Partial<BoxProps>;
}

const NoFilesMessage: React.FC<{ canAddFiles?: boolean }> = ({ canAddFiles }) => {
  if (canAddFiles) {
    return (
      <Typography variant="caption">
        No files have been uploaded yet.
        <br />
        Select add to attach a file.
      </Typography>
    );
  }

  return (
    <Typography aria-label={'no files uploaded'} variant="caption">
      No files have been uploaded
    </Typography>
  );
};

export const FileList: React.FC<FileListProps> = ({ onAddButtonClicked, children, wrapperProps, title, dense }) => {
  const theme = useTheme();

  return (
    <Box maxHeight="100%" minHeight={0} {...wrapperProps}>
      <Box
        borderBottom={`1px solid ${theme.palette.divider}`}
        minHeight={50}
        display="flex"
        alignItems="center"
        py={dense ? 0.5 : 1}
      >
        <Box flex={1} ml={dense ? undefined : 2}>
          <Typography>{title ?? 'Attachments'}</Typography>
        </Box>

        {onAddButtonClicked && (
          <Box mr={dense ? undefined : 1}>
            <Button onClick={onAddButtonClicked}>
              <Box mr={1}>
                <Typography variant="body2">Add a file</Typography>
              </Box>
              <AddCircleIcon fontSize="small" />
            </Button>
          </Box>
        )}
      </Box>
      <VStack
        spacing={dense ? 0.5 : 1}
        component="ul"
        display="flex"
        flexDirection="column"
        p={0}
        m={0}
        pt={1}
        sx={{ overflowY: 'auto' }}
      >
        {children}
        {isEmpty(children) && (
          <Box mx={dense ? 0 : 2} my={1}>
            <NoFilesMessage canAddFiles={Boolean(onAddButtonClicked)} />
          </Box>
        )}
      </VStack>
    </Box>
  );
};
