import isEmpty from 'lodash/isEmpty';

import { getRelevantLayerTagHeaders } from './getRelevantLayerTagHeaders';

/**
 * Filters layer tags where there's a cell value with a value defined
 * which marks it to be used
 * @returns
 */
export const getFilledLayerTags = (row: unknown, headers: string[], layerName: string) => {
  const layerTagHeaders = getRelevantLayerTagHeaders(headers, layerName);

  return layerTagHeaders
    .filter(l => {
      return !isEmpty((row as Record<string, string>)[l]);
    })
    .map(l => l.split(':').pop()!);
};
