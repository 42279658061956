import { imageSrc, imageSrcWithTimestamp } from '@circadian-risk/front-end-utils';
import { ImageMarkup } from '@circadian-risk/presentational';

import { FileAttachmentCommon } from '../FileUpload/FileUpload.types';
import { MarkupReadyFile, QueryFile, QueryImage } from './FileList.types';

/**
 * Returns correct image urls to be used with LightBox:
 * thumbnailUrl - path to tiny image. To be use as preview. If not available - will return larger size.
 * originalUrl  - path to original image. To be used with Image Editor
 * latestUrl    - path to latest image. Will return original image or marked up image if available
 *
 */
export const getImageUrls = (originalFilePath: string, image: QueryImage | null, timestamp: string | undefined) => {
  const latestUrl = image?.latest_image_path ?? originalFilePath;
  const thumbnailUrl = latestUrl;

  return {
    originalUrl: imageSrc(originalFilePath),
    latestUrl: imageSrcWithTimestamp(latestUrl, timestamp),
    thumbnailUrl: imageSrcWithTimestamp(thumbnailUrl, timestamp),
    markup: image?.markup ?? undefined,
  };
};

export type FileAttachmentWithMarkupReady = MarkupReadyFile & FileAttachmentCommon;

export const convertFileToLightboxReadyFile = (
  { file }: QueryFile,
  fileActions?: Pick<FileAttachmentCommon, 'onDelete' | 'onDownload' | 'onRename'>,
): FileAttachmentWithMarkupReady => {
  const { latestUrl, markup, originalUrl, thumbnailUrl } = getImageUrls(file.filepath, file.image, file.updated_at);
  return {
    id: file.id,
    name: file.original_filename ?? 'filename',
    url: latestUrl,
    originalUrl: originalUrl!,
    thumbnailUrl,
    timestamp: file.updated_at,
    markup: (markup as ImageMarkup) ?? undefined,
    ...fileActions,
  };
};

export const getFileUrl = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });
