import { useMeasureDirty } from '@circadian-risk/front-end-utils';
import { FlipCard, HStack } from '@circadian-risk/presentational';
import { Box, Paper, SxProps, Typography, useTheme } from '@mui/material';
import React, { useRef } from 'react';

import { useLocationWizardStore } from '../../locationWizard.store';

const LEGEND_HEIGHT = 40;
const SPACING = 3;

type MainPanelLayoutProps = Pick<LayoutProps, 'backPanel' | 'frontPanel'> & { sx?: SxProps };

const flipCardSx: SxProps = {
  minHeight: 'inherit',
  maxHeight: 'inherit',
  overflowY: 'hidden',
  height: '100%',
  width: '100%',
};

// extracted component to localize state
const MainPanelLayout: React.FC<MainPanelLayoutProps> = ({ backPanel, frontPanel, sx }) => {
  const mode = useLocationWizardStore(({ mode }) => mode);
  return (
    <FlipCard
      sx={sx}
      contentSx={flipCardSx}
      showSide={mode.type === 'viewing' ? 'front' : 'back'}
      frontContent={frontPanel}
      backContent={backPanel}
      axis="x"
    />
  );
};
export interface LayoutProps {
  locationNavigator: React.ReactNode;
  frontPanel: React.ReactNode;
  backPanel: React.ReactNode;
  legend: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ legend, locationNavigator, backPanel, frontPanel }) => {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>();
  const { width } = useMeasureDirty(ref);

  const breakPoint = 700;
  const rowLayout = width > breakPoint;

  const minPanelHeight = 280;
  const maxPanelHeight = rowLayout ? 'unset' : '35vh';

  const paperStyles: SxProps = {
    display: 'flex',
    height: rowLayout ? '100%' : 'unset',
    minHeight: minPanelHeight,
    maxHeight: maxPanelHeight,
  };

  const mainPanelStyles: SxProps = {
    flex: 6,
    minWidth: 300,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'inherit',
    width: '100%',
    ...paperStyles,
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      ref={ref}
      height={rowLayout ? '100%' : 'unset'}
      width={'100%'}
      gap={SPACING}
    >
      <HStack
        flex={1}
        spacing={2}
        flexDirection={rowLayout ? 'row' : 'column'}
        alignItems="stretch"
        height={rowLayout ? `calc(100% - ${LEGEND_HEIGHT}px - ${theme.spacing(SPACING)})` : 'unset'}
        minHeight={minPanelHeight}
      >
        <Paper variant="outlined" sx={{ flex: 4, ...paperStyles }}>
          <Box overflow="auto" flex={1} p={2}>
            {locationNavigator}
          </Box>
        </Paper>

        <MainPanelLayout
          sx={mainPanelStyles}
          frontPanel={
            <Paper sx={flipCardSx} variant="outlined">
              {frontPanel}
            </Paper>
          }
          backPanel={
            <Paper sx={flipCardSx} variant="outlined">
              {backPanel}
            </Paper>
          }
        />
      </HStack>

      <HStack spacing={4} justifyContent="center" color={theme => theme.palette.text.secondary}>
        <Typography>Organization Layers:</Typography>
        <HStack
          flexWrap="wrap"
          noFullWidth
          height={rowLayout ? LEGEND_HEIGHT : 'unset'}
          flexDirection={rowLayout ? 'row' : 'column'}
          alignItems={rowLayout ? 'center' : 'flex-start'}
        >
          {legend}
        </HStack>
      </HStack>
    </Box>
  );
};
