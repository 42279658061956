import Drawer, { DrawerProps } from '@web-app/components/Drawer';
import React, { CSSProperties } from 'react';

export interface NavigationDrawerProps extends Partial<DrawerProps> {
  style?: CSSProperties;
}

export const NavigationDrawer: React.FC<NavigationDrawerProps> = ({ children }) => {
  return <Drawer>{children}</Drawer>;
};
