/** aria-labels that are used within the sign up form. Useful for testing and accessibility */
export const signUpFormAriaLabels = {
  form: 'sign up form',
  submit: 'Submit',
  acceptEULA: 'Accept Terms of Service',
  firstName: 'First Name',
  lastName: 'Last Name',
  password: 'Password',
  confirmPassword: 'Confirm Password',
  email: 'Email',
  consentsToMarketingCommunication: 'Opt-in to marketing communication',
} as const;
