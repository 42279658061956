import '@pqina/pintura/pintura.css';

import { getEditorDefaults, ImageSource, PinturaDefaultImageWriterResult, Sticker, StickerGroup } from '@pqina/pintura';
import { PinturaEditor } from '@pqina/react-pintura';
import React from 'react';

import circadianLogoSvg from '../assets/logo.svg';
import poweredByCr from '../assets/powered-by-CR.jpg';

type PinturaUtils =
  | 'crop'
  | 'filter'
  | 'finetune'
  | 'annotate'
  | 'decorate'
  | 'frame'
  | 'redact'
  | 'resize'
  | 'sticker';

// we can also use ImageOptions from pintura. I asked developer to expose it but haven't tried using yet
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ImageMarkup = Record<string, any>;

export interface OnProcessResult {
  src: File;
  dest: File;
  imageState: ImageMarkup;
}

const stickers: Sticker[] | StickerGroup[] = [
  [
    'Circadian',
    [
      {
        src: circadianLogoSvg,
        alt: 'Circadian Risk Logo', // we can pull customer logo here if they provide one
      },
      {
        src: poweredByCr,
        alt: 'Powered By Circadian Risk',
        width: 589,
        height: 158,
      },
    ],
  ],
  ['Emoji', ['🎥', '🛡', '📍', '🧯', '☎️', '🚨', '🔕', '🔒', '🔐']],
];

export interface ImageEditorProps extends React.ComponentProps<typeof PinturaEditor> {
  initialMarkup?: ImageMarkup | null;
  src: ImageSource;
  onProcess?: (data: PinturaDefaultImageWriterResult) => void;
  onUpdate?: (newMarkup: ImageMarkup) => void;
}

export type PinturaRef = PinturaEditor;

export const ImageEditor: React.FC<ImageEditorProps> = ({ initialMarkup, ...pinturaProps }) => {
  const pinturaRef = React.useRef<PinturaRef>(null);
  const utils: PinturaUtils[] = ['annotate', 'sticker', 'crop'];

  const editorConfig = getEditorDefaults({
    utils,
    stickers,
    locale: { labelButtonExport: 'Save' },
    willRevert: async () => {
      const userAgreed = window.confirm('Are you sure you want to clear all edits?');
      return userAgreed;
    },
    willSetHistoryInitialState: initialHistory => {
      if (pinturaRef?.current?.editor != null && initialMarkup) {
        const { editor } = pinturaRef.current;
        editor.history.set([initialMarkup]);
      }
      return initialHistory;
    },
    // this is how we can add a custom button
    /* willRenderToolbar: toolbar => {
      // find the group of buttons to add our custom button to
      const buttonGroup = findNode('alpha-set', toolbar) as unknown as PinturaNode;

      // create a custom button
      const removeBackgroundButton = createNode('Button', 'mega-reset-button', {
        label: 'Reset All Edits',
        onclick: async () => {
          if (pinturaRef?.current?.editor != null) {
            const { editor } = pinturaRef.current;
            editor.history.set([{}]);
            const flatHistory: ImageMarkup[] = editor.history.getCollapsed();
            editor.history.write([{ ...flatHistory, }]);
            await editor.editImage(props.src, {} as ImageOptions);
          }
        },
      });

      // add the button to the toolbar
      appendNode(removeBackgroundButton, buttonGroup);

      return toolbar;
    } */
  });

  return <PinturaEditor ref={pinturaRef} {...editorConfig} {...pinturaProps} />;
};

export default ImageEditor;
