/* eslint-disable max-classes-per-file */
import _get from 'lodash/get';

import { AddressConfig } from '../';

/**
 * Provides a HierarchyRootNode which contains the root of the Location Hierarchy tree.
 *
 * This HierarchyRootNode is similar to the HierarchyNode type, but has some distinct differences
 * that are worth consideration:
 *
 *  - The `HierarchyRootNode` MUST be at the base of the tree.
 *  - All other `HierarchyNode`s can be related through the `HierarchyRootNode` children, as well
 *    as subsequent `HierarchyNode` children.
 *  - "There can only be ONE" `HierarchyRootNode` in a Location Hierarchy tree.
 *    Referencing the `HierarchyRootNode` by the name "Connor MacLeod" is not necessary
 *    but is permitted.
 */
export class HierarchyRootNode {
  /**
   * Contains a reference to the `HierarchyRootNode`s attached `HierarchyNode`. This allows it to
   * use existing functionality in the `HierarchyNode` as well.
   *
   * It is marked `private readonly` as this should not be output separately to
   * the user, but instead use the various methods to access it.
   */
  private readonly rootNode: HierarchyNode;

  /**
   * Contains metadata related to the HierarchyRootNode. Please see the `HierarchyNodeMeta` class
   * for details.
   */
  public meta: HierarchyNodeMeta;

  /**
   * Create a HierarchyRootNode based on the provided Node.
   *
   * @param node The underlying HierarchyNode that represents this HierarchyRootNode. By
   *             default, this should be the "Organization" location in the
   *             hierarchy, or whichever location is the highest in your custom
   *             hierarchy.
   */
  public constructor(node: HierarchyNode) {
    this.rootNode = node;
    this.meta = node.meta;
  }

  /**
   * Add a HierarchyNode to the HierarchyRootNode as a child. A HierarchyRootNode can have multiple
   * children.
   *
   * @param node The HierarchyNode object to add to this HierarchyRootNode.
   */
  public addChild(node: HierarchyNode) {
    this.rootNode.addChild(node);
  }

  /**
   * Get a HierarchyNode by its position within the hierarchy. This call is based on
   * starting at the `HierarchyRootNode`.  Additional `HierarchyNode`s provide their own
   * implementation of this method, and can be chained if desired.
   *
   * ## Example Usage
   *
   * ```typescript
   * parser = new Parser();
   * const data = parser.parse('/path/to/input-file.csv');
   * const root = data.tree;  // load the `HierarchyRootNode`
   *
   * // Assuming that this region is available in the Location Hierarchy,
   * // the following will `get` it from the parsed data:
   * const myCampus = root.get([ 'My Region A', 'My Campus 123' ]);
   * console.log(myCampus.toJSON());
   * ```
   *
   * @param nodePath Array of values (in order) to get a particular Node.
   */
  public get(nodePath: string[]) {
    return this.rootNode.get(nodePath);
  }

  public getChildren() {
    return this.rootNode.children;
  }

  public getRootNode() {
    return this.rootNode;
  }

  /**
   * Get the associated AddressConfig information for this `HierarchyRootNode`.
   *
   * This is an optional field, and based on the data parsing process the
   * `HierarchyRootNode` may or may not have an address assigned.
   */
  public get address(): AddressConfig | undefined {
    return this.rootNode.address;
  }

  /**
   * Set the AddressConfig information for this `HierarchyRootNode`.
   */
  public set address(a: AddressConfig | undefined) {
    this.rootNode.address = a;
  }

  /**
   * Get the `value` of this `HierarchyRootNode`. This corresponds to the input cell
   * data in the CSV spreadsheet.
   *
   * This value is also available at `meta.nodeName`.
   */
  public get value(): string {
    return this.rootNode.value;
  }
}

/**
 * Provides a HierarchyNode which is a child of either the `HierarchyRootNode` or another `HierarchyNode`.
 * It contains Location Hierarchy information and is connected via a tree
 * structure.
 */
export class HierarchyNode {
  /**
   * The name of this Node. (e.g. 'The Michigan Union', or 'Gonda Building', 'Midwest')
   */
  public readonly name: string;

  /**
   * The layer in the Location Hierarchy for this Node. (e.g. 'Region', 'Building')
   */
  public readonly layer: string;

  /**
   * Contains metadata related to the Node. Please see the `HierarchyNodeMeta` class for
   * additional details.
   */
  public meta: HierarchyNodeMeta;

  /**
   * Contains properties related to this node, such as address and other
   * information.
   */
  public properties?: NodeProperties;

  /**
   * Contains a list of `HierarchyNode`s that are direct descendents of this `HierarchyNode`.
   */
  public children?: Record<string, HierarchyNode>;

  /**
   * Create a HierarchyNode with the given metadata and properties.
   *
   * @param name
   * @param layer
   * @param meta Additional metadata about the Node
   * @param properties Properties attached to the Node
   */
  public constructor(name: string, layer: string, properties?: NodeProperties, meta?: HierarchyNodeMeta) {
    this.meta = meta ?? {};
    this.properties = properties ?? {};
    this.name = name;
    this.layer = layer;
  }

  /**
   * Add a HierarchyNode to this HierarchyNode as a child. A HierarchyNode may have multiple children.
   *
   * @param childNode The HierarchyNode object to add as a child to this Node.
   */
  public addChild(childNode: HierarchyNode) {
    if (this.children === undefined) {
      this.children = {};
    }
    // Avoid adding children nodes with a blank names as every Node should have a name
    if (childNode.name.length > 0) {
      childNode.meta.parent = this;
      this.children[childNode.name] = childNode;
    }
  }

  /**
   * Get a HierarchyNode by its position within the hierarchy. This call is based on
   * starting at this `HierarchyNode`. Additional `HierarchyNode`s provide their own
   * implementation of this method and can be chained if desired.
   *
   * ## Example Usage
   *
   * ```typescript
   * parser = new Parser();
   * const data = parser.parse('/path/to/input-file.csv');
   * const root = data.tree;  // load the `HierarchyRootNode`
   *
   * // Assuming that this region is available in the Location Hierarchy,
   * // the following will `get` it from the parsed data:
   * const myCampus = root.get([ 'My Region A', 'My Campus 123' ]);
   * console.log(myCampus.toJSON());
   *
   * // Because each HierarchyNode in the tree also exposes the `get()` method
   * // we can chain them as follows:
   * const myLevel = myCampus.get(['Building 112', 'Level ABC']);
   *
   * // Additionally, this also works (because, chaining!):
   * const myLevel = myCampus.get(['Building 112']).get(['Level ABC']);
   * ```
   *
   * Note that the call `myCampus.get(...);` is biased to the HierarchyNode itself,
   * so any calls will only follow the tree down from that Node.
   *
   * If the HierarchyNode is not available, this method will return `undefined`.
   *
   * @param nodePath Array of values (in order) to get a particular Node.
   */
  public get(nodePath: string[]): HierarchyNode | undefined {
    const tree: string[] = [];
    for (const nodeName of nodePath) {
      const depth = nodePath.indexOf(nodeName);
      if (nodeName !== '' || nodeName !== undefined) {
        if (depth > 0) {
          tree.push('children');
        }
        tree.push(nodeName);
      } else {
        return undefined;
      }
    }
    return _get(this.children, tree);
  }

  /**
   * Output the `HierarchyNode` and its decendents as a JavaScript Object Notation
   * (JSON) string.
   */
  public toJSON() {
    return JSON.stringify(this, null, 2);
  }

  /**
   * Get the associated AddressConfig information for this `HierarchyNode`.
   *
   * Because this is an optional field the `HierarchyNode` may or may not have an
   * address assigned.
   */
  public get address(): AddressConfig | undefined {
    if (this.properties === undefined) {
      return undefined;
    }
    return this.properties.address;
  }

  /**
   * Set the AddressConfig information for this `HierarchyNode`.
   */
  public set address(a: AddressConfig | undefined) {
    if (this.properties) {
      this.properties.address = a;
    } else {
      this.properties = { address: a };
    }
  }

  public get layerTags(): string[] {
    if (this.properties === undefined || this.properties.layerTags === undefined) {
      return [];
    }
    return this.properties.layerTags;
  }

  public set layerTags(tags: string[]) {
    if (this.properties) {
      this.properties.layerTags = tags;
    } else {
      this.properties = { layerTags: tags };
    }
  }

  /**
   * Get the `value` of this `HierarchyNode`. This corresponds to the input cell data in
   * the CSV spreadsheet.
   *
   * This value is also available at `meta.nodeName`.
   */
  public get value(): string {
    return this.name;
  }
}

/**
 * Provides NodeProperties for a given Node. These properties contain
 * additional information related to the Node.
 */
interface NodeProperties {
  /**
   * The AddressConfig for the Node. This is an optional field and is
   * (currently) only assigned to a single `HierarchyNode` for a particular CSV row.
   */
  address?: AddressConfig;
  layerTags?: string[];
}

/**
 * Provides HierarchyNodeMeta for a given Node. This metadata provides information about the Node.
 */
interface HierarchyNodeMeta {
  /**
   * Contains a reference to this `HierarchyNode`s parent `HierarchyNode`.
   */
  parent?: HierarchyNode;
}
