import { HStack } from '@circadian-risk/presentational';
import { percentageWithPadding } from '@circadian-risk/shared';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Button,
  lighten,
  LinearProgress,
  SxProps,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import pluralize from 'pluralize';

type InherentRiskProgressSize = 'small' | 'medium' | 'large';
export interface InherentRiskProgressProps {
  percentage: number;
  completedLocationsCount: number;
  incompleteLocationsCount: number;
  onClick?: () => void;
  /**
   * @default small
   */
  size?: InherentRiskProgressSize;
  /**
   * @default 'flat'
   */
  barVariant?: 'flat' | 'rounded';
}

const AlertTooltip = styled(({ className, ...props }: TooltipProps & { variant: 'success' | 'error' }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, variant }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette[variant].background,
    color: theme.palette.getContrastText(theme.palette[variant].background),
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    padding: theme.spacing(1),
  },
}));

const paddingMapping: Record<InherentRiskProgressSize, number> = {
  small: 0.5,
  medium: 1,
  large: 2,
};

export const InherentRiskProgress: React.FC<InherentRiskProgressProps> = ({
  completedLocationsCount,
  incompleteLocationsCount,
  percentage,
  size = 'small',
  barVariant = 'flat',
  onClick,
}) => {
  const theme = useTheme();
  const allComplete = percentage === 100;
  const message =
    (allComplete
      ? `${completedLocationsCount} ${pluralize('location', completedLocationsCount)} with completed`
      : `${incompleteLocationsCount} ${pluralize('location', incompleteLocationsCount)} with incomplete`) +
    ' inherent risk scores.';

  const icon = allComplete ? <CheckCircleOutlineIcon color="success" /> : <ErrorOutlineIcon color="error" />;

  const progressStyle: SxProps = {
    backgroundColor: lighten(theme.palette.secondary.light, 0.5),
    padding: paddingMapping[size],
    borderRadius: barVariant === 'rounded' ? '6px' : undefined,
  };

  // -1 indicates that there are some requirements that are not met for the IR to display progress
  if (percentage === -1) {
    return (
      <Button
        size="small"
        variant="outlined"
        onClick={onClick}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        Get Started
      </Button>
    );
  }

  return (
    <AlertTooltip
      title={
        <HStack spacing={2}>
          {icon}
          {message}
        </HStack>
      }
      placement="bottom-start"
      variant={allComplete ? 'success' : 'error'}
    >
      <LinearProgress
        value={percentageWithPadding(percentage, 7)}
        sx={progressStyle}
        variant={'determinate'}
        color={allComplete ? 'success' : 'primary'}
        onClick={onClick}
      />
    </AlertTooltip>
  );
};
