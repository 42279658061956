import { useApiClient } from '@circadian-risk/api-client-provider';
import { Box } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { doesSessionExist } from 'supertokens-web-js/recipe/session';

export const SsoCallback: React.FC = () => {
  const history = useHistory();
  const { tsRestClient } = useApiClient();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const code = searchParams.get('code') ?? '';
  const state = searchParams.get('state') ?? '';

  useEffect(() => {
    const handleSsoCallback = async () => {
      if (!code) {
        return;
      }

      await tsRestClient.auth.sso({
        body: {
          code,
          state,
        },
      });

      const hasSession = await doesSessionExist();
      if (hasSession) {
        history.push('/');
      } else {
        history.push('/login');
      }
    };

    handleSsoCallback();
  }, [code, state, tsRestClient, history]);

  return (
    <Box sx={{ display: 'grid', height: '100vh', placeItems: 'center' }}>
      <span>Validating Session...</span>
    </Box>
  );
};
