import { Select } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

/**
 * This is a wrapper around MUI Select input that can be used in the titles of cards
 */
export const TitleSelectInput = withStyles(theme => ({
  select: {
    ...theme.typography.h5,
  },
}))(Select);
