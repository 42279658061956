import { NonEmptyArr } from '@circadian-risk/shared';
import * as agCharts from 'ag-charts-community';
import { AgCartesianChartOptions, AgChartLegendPosition } from 'ag-charts-community';
import { AgChartsReact } from 'ag-charts-react';
import flatten from 'lodash/flatten';
import max from 'lodash/max';
import { useMemo, useRef } from 'react';

import { BaseChartProps } from '../BaseChartProps';
import { ChartPlaceholder } from '../ChartPlaceholder';
import {
  DiscreteLineSeries,
  discreteLineSeriesToAgSeriesAndData,
  MultiTimeSeriesTooltipRendererParams,
} from './DiscreteLineSeries';
import { ThresholdConfig, thresholdToAgSeriesAndData } from './ThresholdConfig';

/**
 * Type-guard utility function to return if a parameter is an array with non-zero length
 */
const isNonZeroLengthArray = <T,>(possiblyAnArrayOrEmpty?: Array<T>): possiblyAnArrayOrEmpty is NonEmptyArr<T> => {
  return !!possiblyAnArrayOrEmpty && possiblyAnArrayOrEmpty.length > 0;
};

export type XAxisLegendPosition = Extract<AgChartLegendPosition, 'bottom' | 'top'>;
export type YAxisLegendPosition = Extract<AgChartLegendPosition, 'left' | 'right'>;

const axesDefinitions = (
  xAxisPosition: XAxisLegendPosition,
  yAxisPosition: YAxisLegendPosition,
  yAxisTickInterval: number,
  yAxisLabelFormat: string,
): AgCartesianChartOptions['axes'] => [
  // X-Axis
  {
    type: 'time',
    position: xAxisPosition,
    tick: {
      // Mapping is applied to thresholds to ensure that the whole first month and last months are included.
      // This ensures that there are X-Axis ticks for at least 2 months.
      interval: agCharts.time.month,
    },
    label: {
      format: '%b %Y',
      autoRotate: true,
    },
  },
  // Y-Axis
  {
    type: 'number',
    position: yAxisPosition,
    label: {
      format: yAxisLabelFormat,
    },
    tick: {
      interval: yAxisTickInterval,
    },
  },
];

export interface MultiTimeSeriesChartProps<
  XAxisKey extends string,
  XAxisValue,
  YAxisKey extends string,
  YAxisValue extends number,
  OtherInfo extends Record<string, unknown>,
  ThresholdYAxisKeys extends string,
> extends BaseChartProps<XAxisKey> {
  /**
   * The series must be an array of separate data series being compared across time
   *
   */
  series: Array<DiscreteLineSeries<XAxisKey, XAxisValue, YAxisKey, YAxisValue, OtherInfo>>;

  /**
   * Will automatically format the series as a step function
   *
   * given (x1,y1) -> (x2,y2)
   *
   * it separates the x and y deltas by discrete x-delta and y-delta nodes to become:
   *
   * (x1,y1) -> (x2, y1) -> (x2, y2)
   *
   * In the context of a time-series chart, this equates to showing the time(x-axis) change before the value(y-axis) change.
   */
  step?: boolean;

  /**
   * Optional area series defining areas to shade
   */
  thresholds?: ThresholdConfig<XAxisKey, XAxisValue, ThresholdYAxisKeys, YAxisValue>;

  /**
   * Key in the row's object to retrieve the Y-Axis value
   */
  yKey: YAxisKey;

  /**
   * Position to place the legend, if not defined then no legend will be provieded
   */
  legendPosition?: AgChartLegendPosition;

  autoSize?: boolean;

  /**
   * xAxisPosition, defaults to `'bottom'`
   */
  xAxisPosition?: XAxisLegendPosition;

  /**
   * yAxisPosition, defaults to `'left'`
   */
  yAxisPosition?: YAxisLegendPosition;

  /**
   * Optional count of y-axis ticks
   *
   * @default 10
   */
  yAxisTickCount?: number;

  /**
   * Optional Y-Axis Label format override, defaults to '.0f'
   */
  yAxisLabelFormat?: string;

  /**
   * Customizable tooltip renderer
   */
  tooltipRenderer?: (
    params: MultiTimeSeriesTooltipRendererParams<XAxisValue, YAxisValue, OtherInfo, ThresholdYAxisKeys>,
  ) => JSX.Element | null;
}

/**
 * MultiTimeSeriesChart is used to present a Time Series line chart for one or more series of data across consistent X-Y Axis.
 *
 * It also supports defining a concurrent area chart for defining visually overlapping thresholds for the data points.
 */
export const MultiTimeSeriesChart = <
  XAxisKey extends string,
  XAxisValue,
  YAxisKey extends string,
  YAxisValue extends number,
  OtherInfo extends Record<string, any>,
  ThresholdYAxisKeys extends string,
>({
  series: seriesInput,
  placeholderProps,
  legendPosition,
  autoSize = true,
  xAxisPosition = 'bottom',
  yAxisPosition = 'left',
  yAxisTickCount = 10,
  yAxisLabelFormat = '.0f',
  xKey,
  yKey,
  thresholds,
  step = false,
  tooltipRenderer,
}: MultiTimeSeriesChartProps<XAxisKey, XAxisValue, YAxisKey, YAxisValue, OtherInfo, ThresholdYAxisKeys>) => {
  const chartRef = useRef<AgChartsReact>(null);

  // Find the largest values between thresholds and series to calculate the interval for provided yAxisTickCount
  const yAxisTickInterval = useMemo(() => {
    // Between the max inherent risk score and the max threshold we can determine a max value
    const maxIr = max(flatten(seriesInput.map(({ data }) => data)).map(({ inherentRisk }) => inherentRisk)) ?? 0;
    const maxThreshold =
      max(
        flatten(
          thresholds?.data.map(d => {
            const { [xKey]: _x, ...rest } = d;
            return Object.values(rest);
          }),
        ),
      ) ?? 0;

    // We need to divide maxIr by the intended yAxisTickCount to determine the tick interval
    const yTickInterval = Math.ceil(max([maxIr, maxThreshold]) / yAxisTickCount);

    return yTickInterval;
  }, [seriesInput, thresholds?.data, xKey, yAxisTickCount]);

  if (!isNonZeroLengthArray(seriesInput)) {
    return <ChartPlaceholder type="line" {...placeholderProps} />;
  }

  const axes = axesDefinitions(xAxisPosition, yAxisPosition, yAxisTickInterval, yAxisLabelFormat);

  const {
    series: lineSeries,
    data: lineData,
    oldestDate,
  } = discreteLineSeriesToAgSeriesAndData(xKey, yKey, seriesInput, step, tooltipRenderer, thresholds);

  // Need to set the theme for legend to pick up correct colors.
  // There may be a better way using the `legend` option, but this seems to be the suggestion on AgCharts Docs
  // https://www.ag-grid.com/react-charts/legend/
  // `thresholds` is optional, with variable length that needs to come before the series.
  const { data: thresholdData, series: thresholdSeries } = thresholds
    ? thresholdToAgSeriesAndData(xKey, thresholds, oldestDate)
    : { data: [], series: [] };

  const colors = [
    // thresholds need to be at the bottom of the z-index stack, make sure that the theme accounts for N thresholds before our series colors
    ...(thresholds?.thresholds ?? []).map(t => t.fill),
    // series strokes for the legend
    ...lineSeries.map(({ stroke }) => stroke),
  ];

  return (
    <AgChartsReact
      ref={chartRef}
      options={{
        theme: {
          palette: {
            fills: colors,
            strokes: colors,
          },
        },

        data: [...thresholdData, ...lineData],
        series: [...thresholdSeries, ...lineSeries],
        autoSize,
        legend: {
          enabled: !!legendPosition,
          position: legendPosition,
        },
        axes,
      }}
    />
  );
};
