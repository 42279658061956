import { FCC, useSmartUnsavedChanged } from '@circadian-risk/front-end-utils';
import {
  Button,
  // eslint-disable-next-line no-restricted-imports
  Dialog,
  // eslint-disable-next-line no-restricted-imports
  DialogActions,
  DialogActionsProps,
  // eslint-disable-next-line no-restricted-imports
  DialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitleProps,
} from '@mui/material';
import React from 'react';

import { DialogTitleWithCloseIcon } from './DialogTitleWithCloseIcon';

export interface GenericDialogProps {
  isOpen: boolean;
  isDirty?: boolean;
  onClose: () => void;
  dialogProps?: Partial<DialogProps>;
  dialogContentProps?: Partial<DialogContentProps>;
  actionsProps?: Partial<DialogActionsProps>;

  title?: DialogTitleProps['children'];
  actions?: React.ReactElement;
  closeVerb?: string;
  /** @default true */
  showCloseIcon?: boolean;
  /** @default true */
  showCancel?: boolean;
}

/**
 * Generic dialog with with blurred background and cancel button
 *
 * @note you can use `useDialog` hook to manage its state (i.e. `isOpen` or `onClose`)
 *
 * @important If you are rendering `CircadianForm` inside dialog, please use `GenericFormDialog` instead
 */
export const GenericDialog: FCC<GenericDialogProps> = ({
  isDirty = false,
  isOpen,
  onClose,
  dialogProps,
  dialogContentProps,
  actionsProps,

  title,
  actions,
  closeVerb,
  showCloseIcon = true,
  showCancel = true,

  children,
}) => {
  const handleDialogClose = useSmartUnsavedChanged(isDirty, onClose);

  return (
    <Dialog
      open={isOpen}
      onClose={handleDialogClose}
      {...dialogProps}
      sx={{ backdropFilter: 'blur(3px)', ...dialogProps?.sx }}
    >
      <DialogTitleWithCloseIcon showCloseIcon={showCloseIcon} onClose={handleDialogClose}>
        {title}
      </DialogTitleWithCloseIcon>
      <DialogContent {...dialogContentProps}>{children}</DialogContent>
      <DialogActions {...actionsProps}>
        {showCancel && (
          <Button color="secondary" onClick={handleDialogClose}>
            {closeVerb ?? 'Cancel'}
          </Button>
        )}
        {actions}
      </DialogActions>
    </Dialog>
  );
};
