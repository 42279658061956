import { AlignCell, CellRendererDataParams, ColDefOfType, UserCellRenderer } from '@circadian-risk/data-grid';
import { AvatarGroup } from '@circadian-risk/presentational';

import { GroupRow, TeamRow, UserRow } from './AssessmentAssignmentTabbedTable';

export const userColDefs: ColDefOfType<UserRow>[] = [
  {
    field: 'displayName',
    headerName: 'Name',
    sortable: true,
    filter: true,
    cellRenderer: ({ data: { displayName, avatar, lastSignedInAt } }: CellRendererDataParams<UserRow>) => {
      return (
        <UserCellRenderer
          data={{
            userName: displayName,
            last_signed_in_at: lastSignedInAt,
            avatar: avatar ? { filepath: avatar } : null,
          }}
        />
      );
    },
  },
  {
    field: 'email',
  },
  {
    field: 'role',
  },
];

export const userGroupsColDefs: ColDefOfType<GroupRow>[] = [
  {
    field: 'name',
    sortable: true,
    filter: true,
  },
  {
    field: 'users',
    sortable: false,
    filter: false,
    cellRenderer: ({ data: { users } }: CellRendererDataParams<GroupRow>) => {
      return (
        <AlignCell justifyContent="flex-start">
          <AvatarGroup
            isStacked
            max={6}
            avatars={users.map(u => ({
              name: u.displayName,
              lastSignedInAt: u.lastSignedInAt,
              image: u.avatar,
            }))}
          />
        </AlignCell>
      );
    },
  },
];

export const teamsColDefs: ColDefOfType<TeamRow>[] = [
  {
    field: 'name',
    sortable: true,
    filter: true,
  },
  {
    field: 'users',
    sortable: false,
    filter: false,
    cellRenderer: ({ data: { users } }: CellRendererDataParams<GroupRow>) => {
      return (
        <AlignCell justifyContent="flex-start">
          <AvatarGroup
            isStacked
            max={6}
            avatars={users.map(u => ({
              name: u.displayName,
              lastSignedInAt: u.lastSignedInAt,
              image: u.avatar,
            }))}
          />
        </AlignCell>
      );
    },
  },
];
