import { PropertyDescriptionRichText } from '@circadian-risk/presentational';
import { Box, Divider, Typography } from '@mui/material';
import isEmpty from 'lodash/isEmpty';

const renderSection = (title: string, content: string | null) => {
  const htmlInnerContent: string = !isEmpty(content)
    ? (content as string)
    : 'There is no content for this section. Select the edit button to fill in information';

  return (
    <Box>
      <Typography variant={'h6'}>{title}</Typography>
      <Box mt={1}>
        <PropertyDescriptionRichText value={htmlInnerContent} />
      </Box>
    </Box>
  );
};

export interface ExecutiveSummaryExtraContentProps {
  background: string | null;
  findings: string | null;
  recommendations: string | null;
}
export const ExecutiveSummaryExtraContent: React.FC<ExecutiveSummaryExtraContentProps> = ({
  background,
  findings,
  recommendations,
}) => {
  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box my={1}>{renderSection('Background', background)}</Box>
      <Divider />
      <Box my={1}>{renderSection('Findings', findings)}</Box>
      <Divider />
      <Box>{renderSection('Recommendations', recommendations)}</Box>
    </Box>
  );
};
