import { useApiClient } from '@circadian-risk/api-client-provider';
import { ImageProcessingOptions } from '@circadian-risk/presentational';
import { useOrganizationId } from '@circadian-risk/stores';
import { useCallback } from 'react';

export const useSubmitImageMarkup = () => {
  const { tsRestClient } = useApiClient();
  const organizationId = useOrganizationId();

  const handler = useCallback(
    async (options: ImageProcessingOptions) => {
      return await tsRestClient.files.updateImageFile({
        params: {
          organizationId,
          id: options.id,
        },
        body: {
          file: options.flattenedImage,
          markup: JSON.stringify(options.markup),
        },
      });
    },
    [organizationId, tsRestClient.files],
  );

  return handler;
};
