import { HStack, VStack } from '@circadian-risk/presentational';
import { InherentRiskProgress } from '@circadian-risk/scenario-components';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Divider, IconButton, Typography } from '@mui/material';

import { GetInherentRiskCardDataQuery } from '../graphql/operations';

interface ScenarioProgressListProps {
  scenarios: GetInherentRiskCardDataQuery['scenario'];
  onScenarioClick: (id: number) => void;
}

export const ScenarioProgressList: React.FC<ScenarioProgressListProps> = ({ scenarios, onScenarioClick }) => {
  return (
    <VStack>
      {scenarios.map(s => (
        <VStack key={s.id}>
          <Typography variant="body2" fontWeight={500}>
            {s.name}
          </Typography>
          <HStack justifyContent={'space-between'}>
            <Box width={'100%'}>
              <InherentRiskProgress
                onClick={() => onScenarioClick(s.id)}
                percentage={s.percentCompleteInherentRisk ?? 0}
                completedLocationsCount={s.completedLocationsAggregate?.aggregate?.count ?? 0}
                incompleteLocationsCount={s.incompletedLocationsAggregate?.aggregate?.count ?? 0}
                size="medium"
                barVariant="rounded"
              />
            </Box>
            <IconButton onClick={() => onScenarioClick(s.id)}>
              <ArrowForwardIosIcon color={'primary'} />
            </IconButton>
          </HStack>
          <Divider />
        </VStack>
      ))}
    </VStack>
  );
};
