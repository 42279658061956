import { passwordSchema } from '@circadian-risk/form';
import { z } from 'zod';

export const ALPHANUMERIC_REGEX = /^\w+$/;
export const ALPHANUMERIC_ERROR = 'Name can only contain alphanumeric values';

export const makeDisplayNameSchema = (fieldName: string) =>
  z.string().min(1, `${fieldName} is required`).regex(ALPHANUMERIC_REGEX, ALPHANUMERIC_ERROR);

export const signUpFormSchema = z
  .object({
    firstName: makeDisplayNameSchema('First name'),
    lastName: makeDisplayNameSchema('Last name'),
    email: z.string().email(),
    password: passwordSchema,
    confirmPassword: passwordSchema,
    consentsToMarketingCommunication: z.boolean(),
    acceptEULA: z.boolean().refine(Boolean, { message: 'You must accept Terms of Service in order to sign up' }),
  })
  .refine(({ password, confirmPassword }) => password === confirmPassword, {
    message: "Passwords don't match",
    path: ['confirmPassword'],
  });

export type SignUpFormData = z.infer<typeof signUpFormSchema>;
