import { Enum_Consideration_Type_Enum } from '@circadian-risk/graphql-types';
import { Equal, Expect } from '@circadian-risk/shared';
import RepairIcon from '@mui/icons-material/Build';
import ReplaceIcon from '@mui/icons-material/CompareArrows';
import RemoveIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/LibraryAdd';
import MoveIcon from '@mui/icons-material/OpenWith';
import UpgradeIcon from '@mui/icons-material/Refresh';
import CustomIcon from '@mui/icons-material/Tune';
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const PRINCIPAL_ACTIONS = ['add', 'repair', 'move', 'upgrade', 'remove', 'replace', 'custom'] as const;

// making sure the arrays are in sync
// eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
type expectArrayToMatchEnum = Expect<Equal<Enum_Consideration_Type_Enum, (typeof PRINCIPAL_ACTIONS)[number]>>;

export interface PrincipalActionIconProps extends SvgIconProps {
  action: Enum_Consideration_Type_Enum;
}

export const PrincipalActionIconMap: Record<Enum_Consideration_Type_Enum, typeof SvgIcon> = {
  add: AddIcon,
  repair: RepairIcon,
  move: MoveIcon,
  upgrade: UpgradeIcon,
  remove: RemoveIcon,
  replace: ReplaceIcon,
  custom: CustomIcon,
};

export const PrincipalActionIcon: React.FC<PrincipalActionIconProps> = ({ action, ...iconProps }) => {
  const Icon = PrincipalActionIconMap[action];
  return <Icon {...iconProps} />;
};
