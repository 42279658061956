import { NoInfer } from '@circadian-risk/shared';
import { useEffect, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';

import { getRouteParams } from './getRouteParams';

type History = ReturnType<typeof import('history').createBrowserHistory>;

export const createHistoryRouterHooks = (history: History) => {
  const useWatchRouteParams = <Route extends string, ParamsKeys extends string>(options: {
    routes: NoInfer<Route>[];
    paramsToWatch?: ParamsKeys[];
  }) => {
    const { routes, paramsToWatch } = options;

    const previousParams = useRef({} as { [key in ParamsKeys]?: string });

    const [params, setParams] = useState(() => getRouteParams(routes, paramsToWatch));

    useEffect(() => {
      return history.listen(() => {
        const newParams = getRouteParams(routes, paramsToWatch);
        if (isEqual(newParams, previousParams.current)) {
          return;
        }
        previousParams.current = newParams;
        setParams(newParams);
      });
    }, [paramsToWatch, routes]);

    return params;
  };

  return {
    useWatchRouteParams,
  };
};
