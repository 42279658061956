import { isImageExtension } from '@circadian-risk/front-end-utils';

import { LightboxImage } from './lightbox.types';

export type AttachmentFile = {
  id: string;
  name: string;
  url: string | undefined | null;
  markup?: LightboxImage['markup'];
  originalUrl?: LightboxImage['url'];
};

const isAnImageByName = (file: AttachmentFile) => isImageExtension(file.name);
const fileHasUrl = (file: AttachmentFile) => file.url != null && file.url !== '';

export const convertAttachmentFilesToLightboxImages = (files: AttachmentFile[]): LightboxImage[] => {
  return files
    .filter(f => fileHasUrl(f) && isAnImageByName(f))
    .map(f => ({ id: f.id, url: f.url!, markup: f?.markup, originalUrl: f.originalUrl }));
};
