import isBoolean from 'lodash/isBoolean';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';

import { DisplayValue } from './common';

export const makeValueString = (strOrObj: DisplayValue): string => {
  if (isString(strOrObj) || !strOrObj) {
    return strOrObj?.toString() || '';
  }

  if (isBoolean(strOrObj) || isNumber(strOrObj)) {
    return strOrObj.toString();
  }

  return Object.values(strOrObj).join(' ');
};
