import { Avatar, Box, Chip, Typography } from '@mui/material';
import { alpha, SxProps } from '@mui/material/styles';
import isString from 'lodash/isString';

export interface ChipData {
  id: string;
  label: string;
  icon?: string | JSX.Element;
  state?: 'disabled' | 'unselected' | 'selected';
  variant?: 'filled' | 'outlined';
}

const isChipData = (variableToCheck: unknown): variableToCheck is ChipData => {
  return Boolean(variableToCheck && (variableToCheck as ChipData).id && (variableToCheck as ChipData).label);
};

export const isChipDataArray = (variableToCheck: unknown): variableToCheck is ChipData[] => {
  const isArray = Array.isArray(variableToCheck);
  if (isArray) {
    return (variableToCheck as Array<ChipData>).every((currentChip: ChipData) => isChipData(currentChip));
  }
  return false;
};

export interface ChipGroupProps {
  size?: 'small' | 'medium';
  label?: string;
  fullWidth?: boolean;
  chipArray: ChipData[];
  onClick?: (name: string) => void;
}

const rootSx: SxProps = {
  padding: 0,
  margin: 0,
  listStyle: 'none',
  overflowX: 'auto',
  whiteSpace: 'nowrap',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

export const ChipGroup: React.FC<ChipGroupProps> = ({ onClick, size = 'medium', label, chipArray, fullWidth }) => {
  return (
    <Box
      sx={{
        ...rootSx,
        ...(fullWidth ? { width: '100%' } : {}),
      }}
    >
      {label && <Typography variant="overline">{label}</Typography>}
      <Box component="ul" sx={rootSx}>
        {chipArray.map(chip => {
          const dynamicProps: Record<string, React.ReactNode> = {};
          if (isString(chip.icon)) {
            dynamicProps['avatar'] = (
              <Avatar
                sx={theme => ({ backgroundColor: `${alpha(theme.palette.background.paper, 0.8)} !important` })}
                alt="Chip Icon"
                src={chip.icon}
              />
            );
          } else {
            dynamicProps['icon'] = chip.icon;
          }

          const chipLabel = (
            <span id={`${chip.id}-label`}>
              {chip.label}
              <Box component="span" position="absolute" sx={{ opacity: 0 }} left={-110000}>
                {chip.state}
              </Box>
            </span>
          );

          return (
            <Chip
              component="li"
              sx={theme => ({
                margin: theme.spacing(0.5),
                justifyContent: 'left',
                ...(fullWidth ? { width: '100%' } : {}),
              })}
              key={chip.id}
              id={`${chip.id}-chip`}
              label={chipLabel}
              aria-labelledby={`${chip.id}-label`}
              variant={chip.variant}
              disabled={chip.state === 'disabled'}
              color={chip.state === 'selected' ? 'primary' : undefined}
              size={size}
              onClick={onClick ? () => onClick(chip.label) : undefined}
              {...dynamicProps}
            />
          );
        })}
      </Box>
    </Box>
  );
};
