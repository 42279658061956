import React from 'react';

export interface ComponentWithFallbackProps {
  hasData: boolean;
  fallback: React.ReactNode;
  children: React.ReactNode;
}
/**
 * Component only renders children if hasData is true, otherwise renders fallback
 */
export const ComponentWithNoDataFallback: React.FC<ComponentWithFallbackProps> = ({ fallback, hasData, children }) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>{hasData ? children : fallback}</>
);
