import { createEnhancedStore } from '@circadian-risk/front-end-utils';
import { StateCreator } from 'zustand';

export type Mode =
  | { type: 'editing'; editingId: string }
  | { type: 'adding'; locationName?: string }
  | { type: 'viewing' };

export type ModeType = Mode['type'];

const DEFAULT_MODE: Mode = { type: 'viewing' };

export interface LocationWizardStore {
  activeId: string;
  setActiveId: (id: string) => void;
  mode: Mode;
  changeMode: (mode: Mode) => void;
  reset: () => void;
}

const stateCreator: StateCreator<LocationWizardStore> = set => ({
  activeId: '',
  setActiveId: activeId => set({ activeId }),
  mode: DEFAULT_MODE,
  changeMode: mode => set({ mode }),
  reset: () => set({ mode: DEFAULT_MODE }),
});

export const useLocationWizardStore = createEnhancedStore(stateCreator);
