import { ICustomOption, ILibraryOption } from '@circadian-risk/assessment-components';
import { QuestionOptionsRow } from '@web-app/modules/Items/hooks/types';
import { generateNumericMemoryId } from '@web-app/modules/Items/hooks/useVirtualOFC';

type CreateVisibleOptionsParams = {
  remoteOptions: Array<ILibraryOption | ICustomOption>;
  questionVirtualData: Omit<QuestionOptionsRow, 'visibleOptions'>;
};

export const createVisibleOptions = ({ remoteOptions, questionVirtualData }: CreateVisibleOptionsParams) => {
  const visibleOptions: Array<ILibraryOption | ICustomOption> = [];
  const isFreeformTransformedToLibrary = ({ id }: { id: string }) => {
    return questionVirtualData.freeformToSaveToLib?.some(fvl => fvl.id === id);
  };

  const filteredRemoteOptions = remoteOptions.filter(o => {
    if (o.source === 'custom') {
      // Exclude any row that is schedule to be removed or transformed onto library
      return !questionVirtualData.idsToRemove.freeform.includes(o.id) && !isFreeformTransformedToLibrary({ id: o.id });
    }
    return !questionVirtualData.idsToRemove.library.some(e => e.id === o.id);
  });

  for (const option of filteredRemoteOptions) {
    if (option.source === 'custom') {
      // Merges pending changes if exist
      const pendingChanges = (questionVirtualData.freeformUpdates ?? []).find(e => e.id === option.id);
      if (pendingChanges) {
        visibleOptions.push({
          ...option,
          title: pendingChanges.title,
          description: pendingChanges.description ?? undefined,
          type: pendingChanges.type,
        });
      } else {
        visibleOptions.push(option);
      }
    } else {
      visibleOptions.push(option);
    }
  }

  const freeforms = questionVirtualData.virtualOptions.freeform.filter(e => {
    return !isFreeformTransformedToLibrary(e);
  });

  freeforms.forEach(fo => {
    visibleOptions.push({
      id: fo.id,
      source: 'custom',
      createdAt: new Date(),
      description: fo.description ?? undefined,
      type: fo.type,
      title: fo.title,
    });
  });

  questionVirtualData.freeformToSaveToLib?.forEach(o => {
    visibleOptions.push({
      id: o.generatedLibraryId,
      libraryId: o.generatedLibraryId,
      source: 'library',
      createdAt: new Date(),
      description: o.description ?? undefined,
      type: o.type,
      title: o.title,
    });
  });

  questionVirtualData.virtualOptions.library.forEach(lo => {
    visibleOptions.push({
      id: lo.id,
      // Generate a unique id as a fallback for the "pending save to OFC" as the entity is not yet created
      libraryId: lo.libraryId ?? generateNumericMemoryId(),
      source: 'library',
      createdAt: new Date(),
      description: lo.description ?? undefined,
      type: lo.type,
      title: lo.title,
    });
  });

  return visibleOptions;
};
