import { CircadianRoute, ROUTES } from '@circadian-risk/routes';
import { lazyLoadWithVersionCheck } from '@web-app/router/lazyLoadWithVersionCheck';

const AdminDashboard = lazyLoadWithVersionCheck(
  () => import('./AdminDashboard' /* webpackChunkName: "CircadianAdminDashboard" */),
);

const adminRoutes = (): CircadianRoute[] => [
  { path: ROUTES.ADMIN_DASHBOARD, component: AdminDashboard, isExact: true, pageTitle: 'Admin' },
];

export default adminRoutes;
