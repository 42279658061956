import { VStack } from '@circadian-risk/presentational';
import { Box, Paper, Typography } from '@mui/material';
import React from 'react';

import { TitleTypography } from '../TitleTypography';
import { AutoGrid } from './AutoGrid';
// import { MasonryGrid } from './MasonryGrid';

export interface ListPageLayoutProps {
  title: string;
  titleIcon?: React.ReactNode;
  description?: string;
  headerContent?: React.ReactNode;
  children: React.ReactNode[];
  noContentComponent?: React.ReactNode;
}

export const ListPageLayout: React.FC<ListPageLayoutProps> = ({
  title,
  description,
  headerContent,
  titleIcon,
  noContentComponent,
  children,
}) => {
  const noEntries = noContentComponent ?? <Typography>No content available</Typography>;
  return (
    <Box height="100%" width="100%" display="flex" flexDirection="column">
      <Paper square>
        <VStack px={4} py={2}>
          <TitleTypography align="center">
            {title} {titleIcon}
          </TitleTypography>
          {description && (
            <Typography align="center" variant="body1" color="secondary">
              {description}
            </Typography>
          )}
          {headerContent}
        </VStack>
      </Paper>
      {children.length === 0 ? noEntries : <AutoGrid>{children}</AutoGrid>}
      {/* {children.length === 0 ? noEntries : <MasonryGrid>{children}</MasonryGrid>} */}
    </Box>
  );
};
