import { BasicTable } from '@circadian-risk/presentational';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React from 'react';

import { InherentRiskMeasure } from './types';

export interface ScenarioSummaryAccordionProps {
  probabilityMeasures: InherentRiskMeasure[];
  severityMeasures: InherentRiskMeasure[];
}

export const ScenarioSummaryAccordion: React.FC<ScenarioSummaryAccordionProps> = ({
  probabilityMeasures,
  severityMeasures,
}) => {
  return (
    <>
      <Accordion sx={{ width: '100%' }} defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Probability Measures</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <BasicTable
            headers={[
              {
                key: 'name',
                name: 'Name',
              },
              {
                key: 'description',
                name: 'Description',
              },
              {
                key: 'minValue',
                name: 'Min',
              },
              {
                key: 'maxValue',
                name: 'Max',
              },
            ]}
            data={probabilityMeasures}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ width: '100%' }} defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Severity Measures</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <BasicTable
            headers={[
              {
                key: 'name',
                name: 'Name',
              },
              {
                key: 'description',
                name: 'Description',
              },
              {
                key: 'minValue',
                name: 'Min',
              },
              {
                key: 'maxValue',
                name: 'Max',
              },
            ]}
            data={severityMeasures}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};
