import { useApolloClient } from '@apollo/client';
import { resetZustandStores } from '@circadian-risk/front-end-utils';
import { useThemeStore, useUserSessionStore } from '@circadian-risk/stores';
import { useCallback } from 'react';
import { clear } from 'suspend-react';

export const useClearSessionDependencies = () => {
  const apolloClient = useApolloClient();

  return useCallback(async () => {
    clear();
    resetZustandStores();
    useThemeStore.getState().reset();
    useUserSessionStore.getState().resetSessionData();
    await apolloClient.clearStore();
  }, [apolloClient]);
};
