import { CellRendererParams } from '@circadian-risk/data-grid';
import { Criticality, CriticalityDropdownInput, CriticalityDropdownInputProps } from '@circadian-risk/presentational';
import { useCallback } from 'react';

export interface CriticalityCellRendererEditorParams {
  value: number;
}

export const CriticalityCellRendererEditor: React.FC<
  CellRendererParams<CriticalityCellRendererEditorParams>
> = props => {
  const { setValue } = props;
  const handleChange: CriticalityDropdownInputProps['onChange'] = useCallback(
    (newValue: Criticality) => {
      if (setValue) {
        setValue(newValue);
      }
    },
    [setValue],
  );

  return (
    <CriticalityDropdownInput
      criticalityLabels={props.context.criticalityLabels}
      onChange={handleChange}
      value={props.value}
    />
  );
};
