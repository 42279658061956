import isPlainObject from 'lodash/isPlainObject';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseIsLinkTo = (str: string, row: any) => {
  let endStr = str;
  if (!str) {
    return null;
  }
  if (endStr.includes(':')) {
    const match = str.match(/:([\w\-_.]+)\/?/g) ?? [];
    match.forEach(matchField => {
      const fieldName = matchField.replace(/[:/]/g, '');
      let replacer = row[fieldName];

      if (fieldName.toString().includes('.')) {
        const [field1, field2] = fieldName.toString().split('.');
        if (field1) {
          const firstValue = row[field1];
          if (field2 && isPlainObject(firstValue) && Object.prototype.hasOwnProperty.call(firstValue, field2)) {
            replacer = firstValue[field2];
          } else {
            replacer = row[field1] || row[field2];
          }
        }
      }

      // allow for `false` and `0` values to display
      if (fieldName && replacer !== undefined) {
        endStr = endStr.replace(`:${fieldName}`, replacer.toString());
      }
    });
  }
  return endStr;
};
