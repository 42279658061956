import { convertAttachmentFilesToLightboxImages, LightBoxProvider, useDialog } from '@circadian-risk/presentational';
import { formatBytes } from '@circadian-risk/shared';
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Collapse,
  FormControlLabel,
  IconButton,
  Link,
  Popover,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import partition from 'lodash/partition';
import { useRef, useState } from 'react';

import { useFileManagerStore } from '../store';
import { fileCountsByStatusSelector, pendingFilesBytesSelector, uploadingBytesSelector } from '../store/selectors';
import { UploadStatus } from '../types';
import { FileManagerItem } from './FileManagerItem';

export interface FileManagerProps {
  noRemoveButton?: boolean;
}

export const FileManager: React.FC<FileManagerProps> = ({ noRemoveButton }) => {
  const theme = useTheme();

  const pendingFilesBytes = useFileManagerStore(pendingFilesBytesSelector);
  const [uploadsEnabled, setUploadsEnabled, clearCompletedFiles] = useFileManagerStore(state => [
    state.uploadsEnabled,
    state.setUploadsEnabled,
    state.clearCompletedFiles,
  ]);

  const { bytesPerSecond, remainingBytes, totalBytes } = useFileManagerStore(uploadingBytesSelector);

  const files = useFileManagerStore(state => state.files.map(({ id, name, type, url }) => ({ id, name, type, url })));

  const [notCompletedFiles, completedFiles] = useFileManagerStore(state =>
    partition(state.files, file => file.status !== UploadStatus.Completed),
  );

  const { completedFilesCount, notCompletedFilesCount, pendingFilesCount, uploadingFilesCount } =
    useFileManagerStore(fileCountsByStatusSelector);

  const timeRemaining: string = bytesPerSecond > 0 ? `${Math.round(remainingBytes / bytesPerSecond)}s` : '';
  const speed: string | null = bytesPerSecond === 0 ? null : `${formatBytes(bytesPerSecond)}/s`;
  const { isOpen, closeDialog, toggle } = useDialog();
  const [completedFilesExpanded, setCompletedFilesExpanded] = useState(false);
  const infoButtonRef = useRef<HTMLButtonElement>(null);

  const fileManagerLightboxImages = convertAttachmentFilesToLightboxImages(files);

  let header: string;
  if (uploadingFilesCount > 0) {
    header = `Uploading ${uploadingFilesCount} files (${totalBytes})`;
  } else if (pendingFilesCount > 0) {
    header = `${pendingFilesCount} files to be uploaded (${pendingFilesBytes})`;
  } else {
    header = 'File Manager';
  }

  const handleClearCompletedFiles = () => {
    void clearCompletedFiles();
  };

  const switchLabel = 'Enable Uploads';

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" px={2}>
        <Box display="flex" flexDirection="column">
          <Typography variant="h5">{header}</Typography>
          <Typography variant="body2" color="textSecondary">
            {uploadingFilesCount > 0 ? `Time Estimate: ${timeRemaining} (${speed ?? 'N/A'})` : <span>&nbsp;</span>}
          </Typography>
        </Box>
        <Box
          flex={1}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          gap={theme.spacing(4)}
          alignItems="center"
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <FormControlLabel
              control={
                <Switch
                  checked={uploadsEnabled}
                  onChange={() => setUploadsEnabled(!uploadsEnabled)}
                  name={switchLabel}
                  color="primary"
                />
              }
              label={switchLabel}
            />
            <IconButton onClick={toggle} ref={infoButtonRef} size="large">
              <InfoIcon />
            </IconButton>
            <Popover
              open={isOpen}
              anchorEl={infoButtonRef.current}
              onClose={closeDialog}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box p={2} maxWidth={425}>
                Enabling uploads will start the uploading process for any "Pending" files. Any Pending files will be
                persisted across different sessions, allowing you to start uploads when a strong internet connection is
                available.
              </Box>
            </Popover>
          </Box>
        </Box>
      </Box>
      <Box overflow="auto">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Box component="span" width="80px" height="1px">
                  &nbsp;
                </Box>
              </TableCell>
              <TableCell>File</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Used In</TableCell>

              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <LightBoxProvider images={fileManagerLightboxImages}>
              {notCompletedFilesCount === 0 && (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Typography textAlign="center" padding={4}>
                      There are no pending files to upload
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {notCompletedFiles.map(f => (
                <FileManagerItem
                  key={f.id}
                  {...f}
                  noRemoveButton={noRemoveButton || f.status === UploadStatus.Uploading}
                />
              ))}
              {completedFilesCount > 0 && (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Box>
                      <Box display="flex" flexDirection="row" alignItems="center" gap={theme.spacing(1)}>
                        <IconButton onClick={() => setCompletedFilesExpanded(value => !value)} size="large">
                          {completedFilesExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>

                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                          View Completed Uploads ({completedFilesCount})
                        </Typography>

                        <Link onClick={handleClearCompletedFiles} component="button" variant="body1" underline="hover">
                          Clear Completed Files
                        </Link>
                      </Box>
                      <Collapse in={completedFilesExpanded} timeout="auto" unmountOnExit>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>{/* File thumbnail */}</TableCell>
                              <TableCell>File</TableCell>
                              <TableCell>Size</TableCell>
                              <TableCell>Status</TableCell>
                              <TableCell>Used In</TableCell>
                              <TableCell>Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {completedFiles.map(f => (
                              <FileManagerItem key={f.id} {...f} />
                            ))}
                          </TableBody>
                        </Table>
                      </Collapse>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </LightBoxProvider>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};
