import { LightBoxWithConverterProvider, useDialog } from '@circadian-risk/presentational';
import { renameMemoryFile } from '@circadian-risk/shared';
import { useMemo } from 'react';
import { useFieldArray } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import { z } from 'zod';

import { withFieldsetControl } from '../../Fieldset';
import {
  convertFileToLightboxReadyFile,
  FileAttachmentWithMarkupReady,
  FileList,
  FileListItem,
  FileUploadModal,
  getFileUrl,
} from '../../FileAttachments';

export const propertyAttachmentsWithLightboxFieldSchema = z.array(
  z.object({
    id: z.string().uuid(),
    file: z.instanceof(File),
    markupReadyFile: z.custom<FileAttachmentWithMarkupReady>(),
  }),
);

export type PropertyAttachmentsWithLightboxField = z.infer<typeof propertyAttachmentsWithLightboxFieldSchema>;

type PropertyAttachmentsWithLightboxFieldProps = {
  label?: string;
  additionalAttachments?: FileAttachmentWithMarkupReady[];
};
/**
 * A form field for file attachments with image preview lightbox.
 */
export const PropertyAttachmentsWithLightboxField = withFieldsetControl<
  PropertyAttachmentsWithLightboxField,
  PropertyAttachmentsWithLightboxFieldProps
>(({ label, getName, control, additionalAttachments = [] }) => {
  const { isOpen, closeDialog, openDialog } = useDialog();

  const { append, remove, update, fields } = useFieldArray({
    control,
    name: getName(),
  });

  const markupReadyFiles = useMemo(() => fields.map(file => file.markupReadyFile), [fields]);

  const handleAddFile = async (file: File) => {
    const id = uuid();
    const fileUrl = await getFileUrl(file);
    append({
      file,
      id,
      markupReadyFile: convertFileToLightboxReadyFile({
        file: {
          id,
          filepath: fileUrl,
          original_filename: file.name,
          image: {
            latest_image_path: fileUrl,
            alternative_sizes: null,
          },
        },
      }),
    });
  };

  const attachmentsLists = [...(additionalAttachments ?? []), ...markupReadyFiles];

  return (
    <>
      <LightBoxWithConverterProvider files={attachmentsLists}>
        <FileList dense title={label ?? 'Attachments & Files'} onAddButtonClicked={openDialog}>
          {additionalAttachments.map(file => (
            <FileListItem key={file.id} divider={false} dense {...file} />
          ))}
          {markupReadyFiles.map((file, fileIndex) => (
            <FileListItem
              key={file.id}
              divider={false}
              dense
              {...file}
              onRename={newName => {
                const attachment = fields[fileIndex];
                const renamedFile = renameMemoryFile(attachment.file, newName);
                const newAttachment = {
                  ...attachment,
                  file: renamedFile,
                };
                newAttachment.markupReadyFile.name = newName;
                update(fileIndex, newAttachment);
              }}
              onDelete={() => {
                remove(fileIndex);
              }}
            />
          ))}
        </FileList>
      </LightBoxWithConverterProvider>
      <FileUploadModal
        open={isOpen}
        onClose={closeDialog}
        uploadDropzoneProps={{
          inputProps: {
            multiple: true,
          },
        }}
        onUploadFile={handleAddFile}
      />
    </>
  );
});
