import { alpha, ButtonBase, ButtonBaseProps, Card } from '@mui/material';
import { CardProps } from '@mui/material/Card';
import omit from 'lodash/omit';
import React, { memo, useCallback } from 'react';

export interface SelectableCardProps extends ButtonBaseProps {
  active: boolean;
  cardProps?: Partial<CardProps>;
}

export const SelectableCard: React.FC<SelectableCardProps> = memo(props => {
  const { active, disabled, onClick } = props;
  const buttonBaseProps = omit(props, ['active', 'cardProps']);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (onClick) {
        onClick(e);
      }
      e.currentTarget.blur();
    },
    [onClick],
  );

  return (
    <ButtonBase
      {...buttonBaseProps}
      disableRipple
      sx={[
        ({ palette }) => ({
          '&:focus > *': {
            boxShadow: `0 0 12px ${alpha(palette.info.light, 0.7)}`,
            borderColor: palette.info.main,
          },
        }),
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
      onClick={handleClick}
    >
      <Card
        sx={({ palette, transitions, spacing }) => ({
          transition: transitions.create(['border, box-shadow', 'color'], {
            duration: transitions.duration.shorter,
          }),
          border: `1px solid ${palette.grey[500]}`,
          '&:hover': {
            boxShadow: `0 0 12px ${alpha(palette.info.light, 0.7)}`,
            borderColor: palette.info.main,
            color: palette.info.main,
          },
          ...(active
            ? {
                margin: '-3px',
                borderWidth: spacing(0.5),
                borderColor: palette.info.main,
                color: palette.info.main,
              }
            : {}),
          opacity: disabled ? 0.5 : 1,
        })}
        {...props.cardProps}
      >
        {props.children}
      </Card>
    </ButtonBase>
  );
});
