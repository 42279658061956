import { ApolloError } from '@apollo/client';
import { SkeletonDataGrid, SkeletonDataGridProps } from '@circadian-risk/data-grid';
import { LoadingPage, LoadingPageProps } from '@circadian-risk/layout';
import { LayoutCard } from '@circadian-risk/presentational';
import { Skeleton } from '@mui/material';

import { ErrorFetchingDataCard, ErrorFetchingDataPage } from '../Errors';
import { SessionExpired } from '../SessionExpired';

export interface GqlPlaceholderProps {
  state: {
    loading: boolean;
    error: ApolloError | undefined;
  };
  /**
   * @default card
   */
  variant?: 'page' | 'card' | 'chart' | 'table';
  title?: string;
  loadingPageProps?: LoadingPageProps;
  loadingTableProps?: SkeletonDataGridProps;
  refetch?: () => void;
}

export const CardPlaceholder: React.FC<{ title?: string }> = ({ title }) => {
  return (
    <LayoutCard cardTitle={title}>
      <Skeleton height={300} variant="rectangular" />
    </LayoutCard>
  );
};

export const GqlCardPlaceholder: React.FC<GqlPlaceholderProps> = ({ state, title }) => {
  if (state.loading) {
    return <CardPlaceholder title={title} />;
  }

  if (state.error) {
    return <ErrorFetchingDataCard title={title} errorMessage={state.error.message} />;
  }

  return null;
};

export const GqlPagePlaceholder: React.FC<GqlPlaceholderProps> = ({ state, title, loadingPageProps }) => {
  if (state.loading) {
    return <LoadingPage {...loadingPageProps} />;
  }

  if (state.error) {
    if (state.error.message.includes('JWTExpired')) {
      return <SessionExpired />;
    }
    return <ErrorFetchingDataPage title={title} errorMessage={state.error.message} />;
  }

  return null;
};

export const GqlTablePlaceholder: React.FC<GqlPlaceholderProps> = ({ state, refetch, loadingTableProps }) => {
  if (state.loading) {
    return <SkeletonDataGrid {...loadingTableProps} />;
  }

  if (state.error) {
    return <SkeletonDataGrid error refetch={refetch} {...loadingTableProps} />;
  }

  return null;
};

export const GqlPlaceholder: React.FC<GqlPlaceholderProps> = ({ variant = 'card', ...placeholderProps }) => {
  if (placeholderProps.state.error || placeholderProps.state.loading) {
    switch (variant) {
      case 'page':
        return <GqlPagePlaceholder {...placeholderProps} />;

      case 'card':
        return <GqlCardPlaceholder {...placeholderProps} />;

      case 'table':
        return <GqlTablePlaceholder {...placeholderProps} />;

      default:
        return <GqlCardPlaceholder {...placeholderProps} />;
    }
  } else {
    return null;
  }
};
