import { fileSrc } from '@circadian-risk/front-end-utils';
import { Enum_Consideration_Type_Enum } from '@circadian-risk/graphql-types';
import { Box, Button, Chip, Collapse, SxProps, Typography, useTheme } from '@mui/material';
import capitalize from 'lodash/capitalize';
import React, { useEffect, useState } from 'react';

import {
  ChevronControls,
  getThemeSpacing,
  HStack,
  LightBoxGallery,
  LightboxGalleryImage,
  LocationWithIcon,
  PrincipalActionIcon,
  VStack,
} from '../..';

export interface OptionForConsiderationProps {
  title: string | React.ReactNode;
  principalAction?: Enum_Consideration_Type_Enum;
  description?: string;
  locations?: { id: string; name: string; layerName: string }[];
  sideAction?: React.ReactNode;
  bottomAction?: React.ReactNode;
  moreInfo?: React.ReactNode;
  flairs?: React.ReactNode[];
  hideWrapper?: boolean;
  forceShowMoreInfo?: boolean;
  photoSrc?: string;
  defaultExpanded?: boolean;
}

export const OptionForConsideration: React.FC<OptionForConsiderationProps> = ({
  title,
  sideAction,
  locations,
  description,
  principalAction,
  bottomAction,
  flairs,
  hideWrapper,
  moreInfo,
  photoSrc,
  forceShowMoreInfo,
  defaultExpanded,
}) => {
  const theme = useTheme();
  const cardPadding = getThemeSpacing(theme, 2);
  const offset = getThemeSpacing(theme, 1);
  const [showMoreInfo, setShowMoreInfo] = useState(defaultExpanded ?? false);

  useEffect(() => {
    if (defaultExpanded) return;
    setShowMoreInfo(Boolean(defaultExpanded || forceShowMoreInfo));
  }, [defaultExpanded, forceShowMoreInfo]);

  const wrapperProps: SxProps = {
    borderRadius: 2,
    border: `1px solid ${theme.palette.divider}`,
    bgcolor: 'background.paper',
    padding: `${cardPadding}px`,
    paddingY: 1.5,
  };

  const stackProps = hideWrapper ? {} : wrapperProps;

  const dynamicOffset = hideWrapper ? cardPadding + offset : offset;

  const floatSx: SxProps = {
    justifyContent: 'flex-end',
    float: 'right',
  };

  const src = fileSrc(photoSrc);
  const images: LightboxGalleryImage[] = src
    ? [
        {
          // Id should not matter in this context
          id: '1',
          url: src,
          thumbnailUrl: src,
        },
      ]
    : [];

  return (
    <VStack sx={stackProps} spacing={1} position="relative" aria-label={`option-for-consideration-${title}`}>
      <Box display="flow-root" paddingTop={flairs ? 1.5 : 0}>
        <HStack>
          {images.length > 0 && <LightBoxGallery stack={false} thumbnailSize={40} images={images} />}
          <Typography align="left" flex={1}>
            {title}
          </Typography>
          {sideAction && (
            <Box m={-1} pt={flairs ? 0.75 : 0} pr={1} sx={floatSx}>
              {sideAction}
            </Box>
          )}
        </HStack>

        <HStack noFullWidth flexWrap={'wrap'} spacing={1} rowGap={theme.spacing(0.25)} pt={2}>
          {principalAction && (
            <Chip
              size="small"
              label={capitalize(principalAction)}
              icon={<PrincipalActionIcon action={principalAction} />}
            />
          )}
          {locations &&
            locations.length > 0 &&
            locations.map(location => (
              <LocationWithIcon
                key={location.id}
                color="text.secondary"
                layerName={location.layerName}
                locationName={location.name}
              />
            ))}
        </HStack>
      </Box>

      {description && (
        <Typography variant="body2" color="text.secondary" align="left">
          {description}
        </Typography>
      )}

      <HStack justifyContent="center">
        {moreInfo && (
          <VStack>
            <Button
              size="small"
              onClick={() => setShowMoreInfo(prev => !prev)}
              endIcon={<ChevronControls variant="topDrawer" expanded={!showMoreInfo} />}
            >
              {showMoreInfo ? 'Hide' : 'View'}
            </Button>
          </VStack>
        )}

        {bottomAction && (
          <HStack flexWrap="wrap" justifyContent="center">
            {bottomAction}
          </HStack>
        )}
      </HStack>

      {moreInfo && (
        <Collapse in={showMoreInfo} unmountOnExit>
          {moreInfo}
        </Collapse>
      )}

      {flairs && flairs.length > 0 && (
        <HStack noFullWidth position="absolute" top={-dynamicOffset} right={-dynamicOffset}>
          {flairs}
        </HStack>
      )}
    </VStack>
  );
};
