import { initialQuestionValues, Question, QuestionProps } from '@circadian-risk/assessment-components';
import { FileAttachmentCommon, UploadStatus } from '@circadian-risk/file-manager';
import { MarkupReadyFile } from '@circadian-risk/form';
import { ImageProcessingOptions } from '@circadian-risk/presentational';
import { renameMemoryFile } from '@circadian-risk/shared';
import EditIcon from '@mui/icons-material/Edit';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Stack } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

import { FormQuestion, QuestionData } from './types';

const BADGE_ICON = '12px';
const ICON_STYLE = { width: BADGE_ICON, height: BADGE_ICON };

export interface QuestionFieldProps {
  uniqueId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  question: FormQuestion;
  disabled?: boolean;
  openFileModal: () => void;
  setUploadingFilesForId: (id: string) => void;
}

const getImageData = (
  file: Omit<MarkupReadyFile, 'thumbnailUrl' | 'name'>,
  markupToUpload: QuestionData['markupToUpload'],
): Omit<MarkupReadyFile, 'name'> & { hasNewMarkup: boolean } => {
  const { id } = file;
  let latestUrl = file.url;
  let thumbnailUrl = file.url;
  let markup = file.markup;
  let hasNewMarkup = false;
  if (markupToUpload && markupToUpload[id]) {
    const image = markupToUpload[id];
    latestUrl = URL.createObjectURL(image.flattenedImage);
    markup = image.markup;
    thumbnailUrl = latestUrl;
    hasNewMarkup = Boolean(image);
  }

  return {
    id,
    url: latestUrl,
    originalUrl: file.originalUrl,
    thumbnailUrl,
    markup,
    hasNewMarkup,
  };
};

const defaultValue: FormQuestion['initialValue'] = {
  ...initialQuestionValues,
  files: [],
  fileIdsToRemove: [],
  answered_at: '',
};

/**
 * Wrapper around Question component that can be used inside a React Hook Form
 */
export const QuestionField: React.FC<QuestionFieldProps> = ({
  uniqueId,
  control,
  question,
  disabled,
  openFileModal,
  setUploadingFilesForId,
}) => {
  return (
    <Controller
      control={control}
      name={uniqueId}
      defaultValue={defaultValue}
      render={({ field }) => {
        const value: QuestionData = field.value;
        const onChange: (newValue: QuestionData) => void = field.onChange;
        const handleChange: QuestionProps['onValueChange'] = (answerState, modifiedKeys) => {
          const newState = {
            ...value,
            ...answerState,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            answered_at: modifiedKeys.includes('response') ? new Date().toString() : (answerState as any).answered_at,
          };
          onChange(newState);
        };

        const handleImageProcessing = (newMarkup: ImageProcessingOptions) => {
          const { markupToUpload, ...rest } = value;
          onChange({
            ...rest,
            markupToUpload: {
              ...markupToUpload,
              [newMarkup.id]: newMarkup,
            },
          });
        };

        const handleRemoveExistingFile = (existingFileId: string) => {
          const { files, fileIdsToRemove, ...rest } = value;
          onChange({
            ...rest,
            files: files?.filter(f => f.id !== existingFileId),
            fileIdsToRemove: [...(fileIdsToRemove ?? []), existingFileId],
          });
        };

        const handleRenameExistingFile = (existingFileId: string, newName: string) => {
          const { filesToRename, files, ...rest } = value;

          const index = files?.findIndex(f => f.id === existingFileId);
          const newFilesToRename = { ...filesToRename };

          if (files && index != null && index !== -1) {
            files[index].name = newName;
            newFilesToRename[existingFileId] = newName;
            onChange({ ...rest, files, filesToRename: newFilesToRename });
          }
        };

        const handleRemoveMemoryFile = (id: string) => {
          const { newFiles, ...rest } = value;
          onChange({
            ...rest,
            newFiles: newFiles?.filter(f => f.id !== id),
          });
        };

        const handleRenameMemoryFile = (id: string, newFileName: string) => {
          const { newFiles, ...rest } = value;

          const index = newFiles?.findIndex(f => f.id === id);
          if (newFiles != null && index != null && index !== -1) {
            const targetFile = newFiles[index].file;
            newFiles[index].file = renameMemoryFile(targetFile, newFileName);
            onChange({ ...rest, newFiles });
          }
        };

        const existingFiles = (value?.files ?? []).map<FileAttachmentCommon & MarkupReadyFile>(file => {
          const imageData = getImageData(file, value.markupToUpload);
          let badge: JSX.Element | undefined;
          let status: UploadStatus | undefined;
          if (imageData.hasNewMarkup) {
            badge = <EditIcon sx={ICON_STYLE} />;
            status = UploadStatus.Uploading;
          }

          return {
            ...imageData,
            name: file.name,
            onDelete: disabled ? undefined : () => handleRemoveExistingFile(file.id),
            badgeContent: disabled ? undefined : badge,
            status: disabled ? status : undefined,
            onRename: disabled ? undefined : newName => handleRenameExistingFile(file.id, newName),
          };
        });

        const memoryFiles = (value?.newFiles ?? []).map<FileAttachmentCommon & MarkupReadyFile>(({ file, id }) => {
          const originalUrl = URL.createObjectURL(file);
          const imageData = getImageData({ id, originalUrl, url: originalUrl }, value.markupToUpload);
          let badge: JSX.Element = <FileUploadIcon sx={ICON_STYLE} />;
          if (imageData.hasNewMarkup) {
            badge = (
              <Stack direction="row">
                {badge}
                <EditIcon sx={ICON_STYLE} />
              </Stack>
            );
          }

          const row: FileAttachmentCommon & MarkupReadyFile = {
            ...imageData,
            name: file.name,
            status: disabled ? UploadStatus.Uploading : undefined,
            badgeContent: disabled ? undefined : badge,
            onDelete: disabled ? undefined : () => handleRemoveMemoryFile(id),
            onRename: disabled ? undefined : newName => handleRenameMemoryFile(id, newName),
          };
          return row;
        });

        const handleFileOpen = () => {
          openFileModal();
          setUploadingFilesForId(uniqueId);
        };

        const importedInfo = disabled
          ? {}
          : {
              importedAnsweredAt: question.initialValue!.answered_at,
              importedResponse: question.initialValue!.response,
            };

        const disabledProps: QuestionProps['disabled'] = {};
        if (question.itemIsMissing) {
          disabledProps.compliantAnswer = true;
        }
        if (!importedInfo) {
          disabledProps.import = true;
        }

        return (
          <Question
            criticality={question.criticality}
            questionText={question.questionText}
            badResponse={question.badResponse}
            onValueChange={handleChange}
            files={[...memoryFiles, ...existingFiles]}
            onAddFiles={handleFileOpen}
            onImageProcessing={handleImageProcessing}
            answeredAt={value?.answered_at}
            // TODO(iprokopovich)[CR-2227]: why is this permanently disabled?
            isPartialComplianceEnabled={false}
            disabled={disabled ? true : disabledProps}
            importedData={
              importedInfo
                ? {
                    answeredAt: importedInfo.importedAnsweredAt!,
                    response: importedInfo.importedResponse!,
                  }
                : undefined
            }
            value={value}
            standards={question.standards}
            optionsSectionProps={question.optionsSectionProps}
          />
        );
      }}
    />
  );
};
