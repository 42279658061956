import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Fab } from '@mui/material';

type CreateAssessmentActionsProps = {
  onCancel: () => void;
  back: {
    disabled?: boolean;
    onClick: () => void;
  };
  next: {
    title: string;
    disabled?: boolean;
    onClick: () => void;
  };
};

export const CreateAssessmentActions: React.FC<CreateAssessmentActionsProps> = ({ onCancel, back, next }) => {
  return (
    <Box position="relative" justifyContent={'center'} display="flex" mt={3} gap={2}>
      <Fab variant="extended" onClick={onCancel}>
        Cancel
      </Fab>
      <Fab variant="extended" onClick={back.onClick} disabled={back.disabled}>
        <ArrowBackIcon />
        Back
      </Fab>
      <Fab variant="extended" onClick={next.onClick} color="primary" disabled={next.disabled}>
        {next.title}
        <ArrowForwardIcon />
      </Fab>
    </Box>
  );
};
