import { FormControlLabel, Radio, RadioGroup, styled, Typography } from '@mui/material';

import { ItemTypeIcon } from '../ItemTypeIcon';
import { HStack, VStack } from '../Layouts';

const RadioLabel = styled(FormControlLabel)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: 'transparent',
  padding: theme.spacing(0.5, 2),
  paddingLeft: 0,
  margin: 0,
  ':has(input:checked)': {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.background,
  },
}));

export interface QuestionTypeSwitchProps {
  value?: 'interview' | 'inspection';
  onChange: (value: QuestionTypeSwitchProps['value']) => void;
}

export const QuestionTypeSwitch: React.VFC<QuestionTypeSwitchProps> = ({ value, onChange }) => (
  <VStack>
    <Typography variant="subtitle2">Question Type</Typography>
    <RadioGroup
      name="question-type"
      value={value ?? ''}
      onChange={e => onChange(e.target.value as QuestionTypeSwitchProps['value'])}
      row
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        columnGap: 2,
        rowGap: 1,
      }}
    >
      <RadioLabel
        value="interview"
        control={<Radio />}
        label={
          <HStack>
            <ItemTypeIcon type="interview" />
            <Typography variant="body2">Inspection Questionnaire</Typography>
          </HStack>
        }
      />
      <RadioLabel
        value="inspection"
        control={<Radio />}
        label={
          <HStack>
            <ItemTypeIcon type="inspection" />
            <Typography variant="body2">Physical Inspection</Typography>
          </HStack>
        }
      />
      <Typography variant="caption" sx={{ gridColumn: 'span 2' }}>
        <strong>Inspection Questionnaire</strong> - Used for checking compliance or standards related to the item
        category.
        <br />
        <strong>Physical Inspection</strong> - Questions that involve items to be physically investigated or observed.
      </Typography>
    </RadioGroup>
  </VStack>
);
