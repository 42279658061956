import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, styled, TextField } from '@mui/material';

const StyledTextField = styled(TextField)<{ visible: boolean }>(({ theme, visible }) => ({
  '.MuiInputBase-input': {
    width: visible ? 180 : 0,
    transition: 'width 0.3s ease-in-out',
    fontSize: theme.typography.body2.fontSize,
    '::placeholder': {
      fontSize: theme.typography.body2.fontSize,
    },
    ':focus': {
      width: 180,
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      ':focus': {
        width: '100%',
      },
    },
  },

  '.MuiInputBase-root': {
    paddingLeft: theme.spacing(1),
    paddingRight: 0,
  },
}));

export const ClearButton = styled(IconButton, { shouldForwardProp: () => false })<{ visible: boolean }>(
  ({ visible }) => ({
    padding: 0,
    width: visible ? 32 : 0,
    transition: 'width 0.4s ease-in-out',
    transitionDelay: '0.2s',
    pointerEvents: visible ? 'auto' : 'none',
    overflow: 'hidden',
  }),
);

interface QuestionSearchProps {
  searchText: string;
  setSearchText: (text: string) => void;
}

export const QuestionSearch: React.FC<QuestionSearchProps> = ({ searchText, setSearchText }) => {
  return (
    <StyledTextField
      placeholder="Search Questions"
      aria-label="Search Questions"
      value={searchText}
      onChange={e => setSearchText(e.target.value)}
      onClick={e => {
        e.currentTarget.querySelector('input')?.focus();
      }}
      visible={Boolean(searchText)}
      size="small"
      color="warning"
      InputProps={{
        startAdornment: <SearchIcon sx={{ mr: 1 }} color="warning" />,
        endAdornment: (
          <ClearButton size="small" visible={Boolean(searchText)} onClick={() => setSearchText('')}>
            <ClearIcon />
          </ClearButton>
        ),
      }}
    />
  );
};
