import { TaskStatus, taskStatusIconMap } from '@circadian-risk/front-end-utils';
import { SvgIconProps, useTheme } from '@mui/material';
import React from 'react';

export interface TaskStatusIconProps {
  status: TaskStatus;
}

export const TaskStatusIcon: React.FC<SvgIconProps & TaskStatusIconProps> = ({ status, ...iconProps }) => {
  const theme = useTheme();
  const Icon = taskStatusIconMap[status];
  const color = theme.palette.organizationColors.task[status];

  return <Icon htmlColor={color} {...iconProps} />;
};
