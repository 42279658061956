import { humanReadableAnswerResponses } from '@circadian-risk/assessment-components';
import {
  Assessment_Answers_Insert_Input,
  Enum_Answer_Responses_Enum,
  useGetAssessmentItemsQuery,
  useProvideAnswerMutation,
  useUpdateAnswerMutation,
} from '@circadian-risk/client-graphql-hooks';
import {
  CellRendererParams,
  ColDefOfType,
  FlagCellRenderer,
  FlagCellRendererProps,
  ItemTypeCellRenderer,
  RichTextCellRenderer,
  TextTooltipCellRenderer,
  TextTooltipColumnConfig,
  useGrid,
  ValueSetterParams,
  VariableLinesColumnConfig,
} from '@circadian-risk/data-grid';
import { isImageExtension, openExternalUrlInCurrentTab, useQueryState } from '@circadian-risk/front-end-utils';
import {
  Criticality,
  CustomNoRowsToShow,
  ItemWithLink,
  QuickFilterItem,
  QuickFilters,
  RefreshButton,
  ResponsiveIconButton,
} from '@circadian-risk/presentational';
import { AssessmentState, tsObject } from '@circadian-risk/shared';
import {
  FeatureFlagBoundary,
  useIsFeatureFlagEnabledForOrg,
  useOrganizationId,
  useOrganizationSessionStore,
} from '@circadian-risk/stores';
import ClearIcon from '@mui/icons-material/Clear';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Box, Button, SxProps, Theme, useTheme } from '@mui/material';
import {
  AnswerStatusRenderer,
  ItemAnswerCriticalityCellRenderer,
  ItemAnswerMasterDetailTable,
  PartialComplianceCellRenderer,
  QuestionAnswerResponseCell,
  ScenarioCellRenderer,
  useDetailCellClass,
} from '@web-app/components/ItemAnswerMasterDetail';
import { createCriticalityHeaderHook } from '@web-app/components/ItemAnswerMasterDetail/createCriticalityHeaderHook';
import { useSubmitImageMarkup } from '@web-app/components/LightBox';
import { LocationLinkRenderer } from '@web-app/components/LocationLinkRenderer';
import { assessmentAppUrlForWebApp } from '@web-app/lib/assessmentAppUrl';
import {
  AssessQuestion,
  useHandleReassessHelper,
  withBulkReassessModalProvider,
} from '@web-app/smart-components/BulkReassess';
import { FilesCellRenderer, FilesDialogProvider } from '@web-app/smart-components/FileAttachmentsModal';
import { withOneByOneReassessModalProvider } from '@web-app/smart-components/OneByOneReassess';
import orderBy from 'lodash/orderBy';
import { useMemo, useState } from 'react';
import { BiSelectMultiple } from 'react-icons/bi';
import { useLatest } from 'react-use';

import { getRowData } from './AssessmentItemsAnswersTable.helpers';
import { AssessmentItemDetailRowData, AssessmentItemMasterRowData, AssessmentItemTableContext, RowData } from './types';

const criticalityField: Record<
  Criticality,
  keyof Pick<RowData, 'lowCriticalityCount' | 'mediumCriticalityCount' | 'highCriticalityCount'>
> = {
  1: 'lowCriticalityCount',
  2: 'mediumCriticalityCount',
  3: 'highCriticalityCount',
};

const ItemNameCellRenderer: React.FC<CellRendererParams<RowData, AssessmentItemTableContext>> = ({ data, context }) => {
  const organizationId = useOrganizationId();
  const submitImageMarkup = useSubmitImageMarkup();
  const { itemName: item_name, nodeId, assessmentId, assessmentItemId, isInterviewItem } = data;

  const url = assessmentAppUrlForWebApp({
    organizationId,
    assessmentId,
    location: {
      id: nodeId,
      view: {
        type: isInterviewItem ? 'questionnaire' : 'inspection',
        itemId: assessmentItemId,
      },
    },
  });

  return (
    <ItemWithLink
      name={item_name}
      iconSrc={data.itemCategoryIconSrc}
      photos={data.files.filter(({ name }) => isImageExtension(name))}
      link={url}
      onImageProcessing={async options => {
        await submitImageMarkup(options);
        context.refetch();
      }}
    />
  );
};

export type AssessmentItemAnswerQuickFilter =
  | 'unanswered'
  | 'flagged'
  | 'deficient'
  | 'notObserved'
  | 'lowCriticalityCount'
  | 'mediumCriticalityCount'
  | 'highCriticalityCount';

const quickFilters: QuickFilterItem[] = [
  {
    text: 'Unanswered',
    value: 'unanswered',
  },
  {
    text: 'Flagged',
    value: 'flagged',
  },
  {
    text: 'Deficient',
    value: 'deficient',
  },
  {
    text: 'Not Observed',
    value: 'notObserved',
  },
  {
    text: 'Low',
    value: 'lowCriticalityCount',
  },
  {
    text: 'Medium',
    value: 'mediumCriticalityCount',
  },
  {
    text: 'High',
    value: 'highCriticalityCount',
  },
];

export interface ResponseAndCloseTabProps {
  assessmentId: number;
  tableSx?: SxProps<Theme>;
}

export const ASSESSMENT_ITEMS_QUICK_FILTER_KEY = 'qlink';
export const AssessmentItemsAnswersTableBase: React.FC<ResponseAndCloseTabProps> = ({ assessmentId, tableSx }) => {
  const { onGridReady, gridApi, resetFiltersAndResizeColumns } = useGrid();
  const [quickFilter, setQuickFilter] = useQueryState<AssessmentItemAnswerQuickFilter | null>(
    ASSESSMENT_ITEMS_QUICK_FILTER_KEY,
    null,
  );
  const criticalityLabels = useOrganizationSessionStore(state => state.criticalityLabels);
  const organizationId = useOrganizationId();
  const { nodesById, layersById } = useOrganizationSessionStore(({ nodesById, layersById }) => ({
    nodesById,
    layersById,
  }));
  const [provideAnswer] = useProvideAnswerMutation();
  const [updateAnswerMutation] = useUpdateAnswerMutation();
  const { data, refetch } = useGetAssessmentItemsQuery({
    variables: {
      organizationId,
      assessmentId,
    },
  });
  const [selectedAnswers, setSelectedAnswers] = useState<AssessmentItemDetailRowData[]>([]);
  const theme = useTheme();
  const detailsCellClass = useDetailCellClass();
  const isAssessmentInProgress = data?.v2_assessments_by_pk?.state === AssessmentState.InProgress ?? false;
  const assessFlagEnabled = useIsFeatureFlagEnabledForOrg('assessment_review_close_assess');

  const updateAnswerFlag = async (id: string, value: boolean) => {
    await updateAnswerMutation({
      variables: {
        id,
        updated: {
          is_flagged: value,
        },
      },
    });

    await refetch();
  };

  const createBulkOptions = (): AssessQuestion[] => {
    return selectedAnswers.map<AssessQuestion>(a => {
      const {
        questionId,
        questionText,
        assessmentItemId,
        itemIsMissing,
        nodeId,
        itemName,
        deficientResponse,
        itemCategoryId,
      } = a;

      return {
        questionText,
        deficientResponse: deficientResponse as Enum_Answer_Responses_Enum,
        compliantResponse: deficientResponse === 'yes' ? 'no' : 'yes',
        itemId: assessmentItemId,
        questionId,
        itemIsMissing,
        itemImgSrc: null,
        nodeId,
        itemCategoryId,
        itemName,
        context: 'assessment',
        initialValue: {
          response: a.response,
          description: a.description,
          flagged: a.is_flagged,
          partialCompliance: a.partialComplianceMultiplier ?? 0,
          is_flagged: a.is_flagged,
          partial_compliance_multiplier: a.partialComplianceMultiplier,
          answered_at: a.answered_at,
          files: a.files,
        },
        options: a.options,
      };
    });
  };

  const handleClearAll = () => {
    setSelectedAnswers([]);
    gridApi?.forEachDetailGridInfo(detailGridInfo => {
      detailGridInfo.api?.deselectAll();
    });
    gridApi?.deselectAll();
  };

  const onReassessFinish = async () => {
    handleClearAll();
    await refetch();
  };

  const handleReassess = useHandleReassessHelper({
    createQuestionsFactory: createBulkOptions,
    onReassessFinish,
    selectedAnswersCount: selectedAnswers.length,
  });

  const createFlaggedAnswer = async (row: AssessmentItemDetailRowData) => {
    const answerInsert: Assessment_Answers_Insert_Input = {
      question_id: row.questionId,
      response: null,
      is_flagged: true,
      description: row.description,
      partial_compliance_multiplier: row.partialComplianceMultiplier ?? undefined,
      assessment_item_id: row.assessmentItemId,
    };

    await provideAnswer({
      variables: {
        answer: answerInsert,
      },
    });
    await refetch();
  };

  const labels = tsObject.entries<Record<Criticality, string>>(criticalityLabels);
  const sortedLabels = orderBy(
    labels.map(([value, label]) => ({
      value,
      label,
    })),
    e => e.value,
    'desc',
  );

  const columnDefs: ColDefOfType<RowData>[] = useMemo(
    () => [
      {
        field: 'index',
        headerName: '#',
        maxWidth: 100,
        resizable: false,
      },
      {
        headerName: 'Status',
        field: 'tooltipLabel',
        cellClass: 'circadian-ag-text-wrap-column',
        cellRenderer: AnswerStatusRenderer,
        maxWidth: 120,
        resizable: false,
      },
      {
        field: 'item_id',
        headerName: '',
        valueFormatter: () => '',
        minWidth: 80,
        resizable: false,
        maxWidth: 80,
        cellRenderer: 'agGroupCellRenderer',
        filter: false,
        sortable: false,
      },
      {
        field: 'itemName',
        headerName: 'Item Name',
        filter: 'agTextColumnFilter',
        cellRenderer: ItemNameCellRenderer,
        // this cell renderer is quite complex and needs to be re-rendered if underlying data changes
        equals: () => false,
        sortable: true,
      },
      {
        field: 'itemType',
        headerName: 'Type',
        cellRenderer: ItemTypeCellRenderer,
        cellClass: 'circadian-ag-text-wrap-column',
        maxWidth: 100,
        resizable: false,
      },
      {
        field: 'notes',
        headerName: 'Notes',
        filter: 'agTextColumnFilter',
        sortable: true,
        cellRenderer: TextTooltipCellRenderer,
      },
      {
        field: 'description',
        headerName: 'Description',
        sortable: true,
        ...TextTooltipColumnConfig,
      },
      {
        field: 'filesCount',
        headerName: 'Files',
        cellRenderer: FilesCellRenderer,
        sortable: true,
      },
      ...sortedLabels.map(l => ({
        field: criticalityField[l.value],
        headerName: l.label,
        headerComponent: createCriticalityHeaderHook(l.value),
        cellRenderer: ({ value }: CellRendererParams<RowData>) => (
          <ItemAnswerCriticalityCellRenderer count={value} criticality={l.value} />
        ),
        sortable: true,
        filter: 'agNumberColumnFilter',
        maxWidth: 120,
      })),
      {
        field: 'locationName',
        headerName: 'Location',
        filter: 'agTextColumnFilter',
        cellClass: 'circadian-ag-text-wrap-column',
        cellRenderer: LocationLinkRenderer,
      },
    ],
    [sortedLabels],
  );

  const generatedFlaggedValueSetter = () => {
    return (params: ValueSetterParams<AssessmentItemDetailRowData>) => {
      const { id, isAnswerVirtual } = params.data;
      const { newValue } = params;

      if (isAnswerVirtual) {
        void createFlaggedAnswer(params.data);
      } else {
        void updateAnswerFlag(id!, newValue);
      }

      return false;
    };
  };

  const detailsGridColDefs: ColDefOfType<AssessmentItemDetailRowData>[] = [
    {
      field: 'is_flagged',
      headerName: '',
      cellClass: detailsCellClass,
      cellRenderer: (params: FlagCellRendererProps) => {
        return <FlagCellRenderer {...params} disabled={!isAssessmentInProgress} />;
      },
      maxWidth: 80,
      valueSetter: generatedFlaggedValueSetter(),
    },
    {
      field: 'questionText',
      headerName: 'Question',
      ...VariableLinesColumnConfig,
      cellRenderer: RichTextCellRenderer,
    },
    {
      field: 'response',
      headerName: 'Response',
      cellRenderer: QuestionAnswerResponseCell,
      filterParams: { values: humanReadableAnswerResponses },
    },
    {
      field: 'partialComplianceMultiplier',
      headerName: 'Partial Compliance',
      cellRenderer: PartialComplianceCellRenderer,
    },
    {
      field: 'description',
      headerName: 'Description',
      cellClass: detailsCellClass,
      ...TextTooltipColumnConfig,
    },
    {
      field: 'optionsCount',
      headerName: 'Options Count',
      filter: 'agNumberColumnFilter',
      cellClass: detailsCellClass,
    },
    {
      field: 'files',
      headerName: 'Files',
      cellRenderer: FilesCellRenderer,
    },
    {
      field: 'scenarios',
      headerName: 'Scenario',
      cellClass: detailsCellClass,
      filter: 'agNumberColumnFilter',
      cellRenderer: ScenarioCellRenderer,
      sortable: false,
    },
  ];

  if (isAssessmentInProgress && assessFlagEnabled) {
    detailsGridColDefs.unshift({
      field: 'answer_id',
      headerName: '',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      sortable: false,
      filter: false,
      resizable: false,
      pinned: 'left',
      maxWidth: 70,
      valueFormatter: () => '',
    });
  }

  const rowData = useMemo(() => {
    if (!data) {
      return [];
    }

    return getRowData(data.v2_assessments_by_pk!, assessmentId, nodesById, layersById, quickFilter);
  }, [assessmentId, data, layersById, nodesById, quickFilter]);

  const rowDataRef = useLatest(rowData);

  const onRefresh = async () => {
    await refetch();
  };

  const onResetTable = () => {
    setQuickFilter(null);
    resetFiltersAndResizeColumns();
  };

  const onOpenClick = (itemId: string) => {
    const item = rowData.find(e => e.item_id === itemId);
    if (!item) {
      return;
    }

    const url = assessmentAppUrlForWebApp({
      organizationId,
      assessmentId,
      location: {
        id: item.nodeId,
        view: {
          type: item.isInterviewItem ? 'questionnaire' : 'inspection',
          itemId: item.assessmentItemId,
        },
      },
    });

    openExternalUrlInCurrentTab(url);
  };

  const onOpenClickLatest = useLatest(onOpenClick);
  const context: AssessmentItemTableContext = {
    onOpenClick: onOpenClickLatest.current,
    refetch,
  };

  const handleSelectAll = () => {
    if (!rowDataRef.current || !gridApi) {
      return;
    }

    const answers = rowDataRef.current.reduce<AssessmentItemDetailRowData[]>((prev, curr) => {
      const rowAnswers = curr.answers.reduce<AssessmentItemDetailRowData[]>((answerArr, currRow) => {
        return [...answerArr, currRow as unknown as AssessmentItemDetailRowData];
      }, []);
      return [...prev, ...rowAnswers];
    }, []);

    setSelectedAnswers(answers);

    // Ensure that any opened detail grids are synced
    gridApi.forEachDetailGridInfo(gridInfo => {
      gridInfo.api?.selectAllFiltered();
    });
  };

  return (
    <Box
      aria-label={'assessment response close'}
      display={'flex'}
      flexDirection={'column'}
      height={'100%'}
      width={'100%'}
    >
      {isAssessmentInProgress && (
        <FeatureFlagBoundary flagName="assessment_review_close_assess">
          <Box
            px={2}
            py={1}
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            alignItems="center"
            gap={theme.spacing(1)}
          >
            <Box
              alignSelf="flex-end"
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              alignItems="center"
              gap={theme.spacing(1)}
            >
              <Button onClick={handleClearAll}>
                <span>Clear Selection&nbsp;</span>
                <ClearAllIcon />
              </Button>
              <Button variant="outlined" onClick={handleSelectAll}>
                <span>Select All&nbsp;</span>
                <BiSelectMultiple />
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleReassess}
                disabled={selectedAnswers.length === 0}
              >
                <span>
                  {selectedAnswers.length === 0 ? 'Answer' : `Answer ${selectedAnswers.length} questions`}&nbsp;
                </span>
                <PlaylistAddIcon />
              </Button>
            </Box>
          </Box>
        </FeatureFlagBoundary>
      )}

      <Box p={1} display="flex" flexDirection="row" flexWrap="wrap">
        <Box display={'flex'} flex={1}>
          <QuickFilters
            items={quickFilters}
            selectedValue={quickFilter}
            onClick={value => setQuickFilter(value as AssessmentItemAnswerQuickFilter)}
          />
        </Box>
        <Box display={'flex'} gap={1}>
          <ResponsiveIconButton
            ariaLabel={'clear filters'}
            iconPosition="start"
            icon={<ClearIcon />}
            text="Clear Filters"
            onClick={onResetTable}
            color="secondary"
            tooltip={'Resets all filters and table states'}
          />
          <RefreshButton onClick={onRefresh} />
        </Box>
      </Box>
      {/* Using fixed height since domLayout="autoHeight" doesn't work well with the detail grid */}
      <FilesDialogProvider refetch={refetch}>
        <Box display={'flex'} aria-label={'assessment items table'} sx={tableSx}>
          <ItemAnswerMasterDetailTable<AssessmentItemMasterRowData, AssessmentItemDetailRowData>
            rowData={rowData}
            handleGridReady={onGridReady}
            columnDefs={columnDefs}
            detailsColumnDefs={detailsGridColDefs}
            context={context}
            noRowsOverlayComponent={() => <CustomNoRowsToShow onClearClick={onResetTable} />}
            paginationAutoPageSize
            onAnswerSelectionChanged={setSelectedAnswers}
            keepDetailRows
            selectedAnswers={selectedAnswers}
          />
        </Box>
      </FilesDialogProvider>
    </Box>
  );
};

export const AssessmentItemsAnswersTable = withOneByOneReassessModalProvider(
  withBulkReassessModalProvider(AssessmentItemsAnswersTableBase),
);
