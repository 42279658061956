import { TaskStatus, taskStatusIconMap, taskStatusLabelMap } from '@circadian-risk/front-end-utils';
import { Chip, ChipProps, createTheme, ThemeProvider, useTheme } from '@mui/material';
import React, { forwardRef } from 'react';

export interface TaskStatusChipProps extends ChipProps {
  status: TaskStatus;
}

export const TaskStatusChip = forwardRef<HTMLDivElement, TaskStatusChipProps>(({ status, ...chipProps }, ref) => {
  const theme = useTheme();
  const Icon = taskStatusIconMap[status];
  const color = theme.palette.organizationColors.task[status];
  const bgcolor = theme.palette.utils.getBackgroundColor(color);

  return (
    <ThemeProvider theme={createTheme({ palette: { primary: { main: color } } })}>
      <Chip
        ref={ref}
        {...chipProps}
        sx={{ bgcolor, borderWidth: 2 }}
        color={'primary'}
        variant="outlined"
        label={taskStatusLabelMap[status]}
        icon={<Icon />}
      />
    </ThemeProvider>
  );
});
