import React from 'react';

import { TabsWithContentControlled, TabsWithContentControlledProps } from './TabsWithContentControlled';

export interface TabsWithContentProps<Tab extends string>
  extends Omit<TabsWithContentControlledProps<Tab>, 'activeTab'> {
  initialTabSelected?: Tab;
  responsive?: boolean;
  onChange?: (value: Tab) => void;
}

export const TabsWithContent = <Tab extends string>(props: TabsWithContentProps<Tab>) => {
  const { onChange, tabs } = props;
  const [activeTab, setActiveTab] = React.useState<Tab>(props.initialTabSelected ?? tabs[0].value);
  const handleSwitchTabs = (newActiveTab: Tab) => {
    setActiveTab(newActiveTab);
    onChange && onChange(newActiveTab);
  };

  return <TabsWithContentControlled {...props} activeTab={activeTab} onChange={handleSwitchTabs} />;
};
