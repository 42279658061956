import { Theme } from '@mui/material';

export const DEFAULT_DRAWER_WIDTH = 300;

export type DrawerStyleProps = {
  top?: number;
  drawerWidth?: number;
};

export const getDrawerSx = ({ top, drawerWidth }: DrawerStyleProps) => {
  return {
    drawer: {
      position: 'relative',
      width: `min(100vw, ${drawerWidth ?? DEFAULT_DRAWER_WIDTH}px)`,
      flexShrink: 0,
    },
    drawerClosed: (theme: Theme) => ({
      // On tablet, drawer "floats" over content.
      // Explicitly set visibility hidden to allow events to pass through
      visibility: 'hidden',
      transition: theme.transitions.create('visibility', {
        delay: theme.transitions.duration.leavingScreen,
      }),
    }),
    // https://github.com/mui-org/material-ui/issues/20446
    drawerPaper: (theme: Theme) => {
      const defaultTopPx = top ? `${top}px` : '56px';
      const xsTopPx = top ? `${top}px` : '48px';
      const smTopPx = top ? `${top}px` : '64px';

      return {
        [theme.breakpoints.up('sm')]: {
          width: drawerWidth ?? DEFAULT_DRAWER_WIDTH,
        },

        height: `calc(100% - ${defaultTopPx})`,
        marginTop: defaultTopPx,

        [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
          height: `calc(100% - ${xsTopPx})`,
          marginTop: xsTopPx,
        },

        [theme.breakpoints.up('sm')]: {
          height: `calc(100% - ${smTopPx})`,
          marginTop: smTopPx,
        },
      };
    },
    // https://github.com/mui-org/material-ui/issues/20446
    drawerPaperMobile: {
      width: '100%',
    },
    // https://github.com/mui-org/material-ui/issues/20446
    drawerPaperNotMobile: {
      width: drawerWidth ?? DEFAULT_DRAWER_WIDTH,
    },
    fab: (theme: Theme) => ({
      position: 'fixed',
      marginTop: theme.spacing(1),
      zIndex: theme.zIndex.drawer + 1,
      transform: 'translate(10%, 0)',
      transition: theme.transitions.create(['left', 'transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      left: 0,
    }),
    fabShift: (theme: Theme) => ({
      transition: theme.transitions.create(['left', 'transform'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      transform: 'translate(-50%, 0)',
      left: drawerWidth ?? DEFAULT_DRAWER_WIDTH,
    }),
    fabHidden: {
      display: 'none',
    },
    mobileToggleBox: (theme: Theme) => ({
      zIndex: 1,
      background: theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      borderBottom: `1px solid ${theme.palette.divider}`,
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    }),

    content: (theme: Theme) => ({
      flexGrow: 1,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }),
    contentShift: (theme: Theme) => ({
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  };
};
