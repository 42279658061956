import { FreeFormOption, LibraryOption, UpdateAnswer } from '@circadian-risk/api-contract';
import {
  CustomOption,
  ICustomOption,
  ILibraryOption,
  OptionsSectionActions,
  OptionsSectionProps,
  SaveOptionDialog,
} from '@circadian-risk/assessment-components';
import { SelectOptionDialogSmart } from '@web-app/components/Map/ItemContent/SelectOptionDialog.smart';
import { setQuestionData } from '@web-app/modules/Items/hooks/setQuestionData.helper';
import {
  OptionsDataByQuestionId,
  OptionsUpdateDtoByQuestionId,
  SaveToLibraryRow,
  UseVirtualOfcParams,
  VirtualFreeformOption,
  VirtualLibraryOption,
} from '@web-app/modules/Items/hooks/types';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import size from 'lodash/size';
import React, { useRef, useState } from 'react';
import { v4 } from 'uuid';

export const generateNumericMemoryId = () => new Date().getUTCMilliseconds();

export const useVirtualOFC = ({ locationId, itemCategoryId }: UseVirtualOfcParams) => {
  // Keeps the original immutable options
  const remoteOptionsByQuestionIdRef = useRef<Record<string, OptionsSectionProps['options']>>({});

  // Stores the virtual data and visible options information keyed by the question id
  const [dataByQuestion, setDataByQuestion] = useState<OptionsDataByQuestionId>({});

  const [selectedQuestion, setSelectedQuestion] = useState<{
    id: string;
    remoteOptions: OptionsSectionProps['options'];
  } | null>();

  const [savingOption, setSavingOption] = useState<{ questionId: string; option: CustomOption } | undefined>(undefined);

  const dataByQuestionValues = Object.values(dataByQuestion);
  const containsOptionsToRemove = dataByQuestionValues.some(
    d => d.idsToRemove.library.length > 0 || d.idsToRemove.freeform.length > 0,
  );

  const containsVirtualOptionsToCreate = dataByQuestionValues.some(
    d => d.virtualOptions.library.length > 0 || d.virtualOptions.freeform.some(v => !isEmpty(v.title)),
  );

  const containsFreeformUpdates = dataByQuestionValues.some(d => size(d.freeformUpdates) > 0);
  const containsFreeFormSaveToLib = dataByQuestionValues.some(
    d => d.freeformToSaveToLib && d.freeformToSaveToLib.length > 0,
  );

  const isDirty =
    containsVirtualOptionsToCreate || containsOptionsToRemove || containsFreeformUpdates || containsFreeFormSaveToLib;

  const getSelectedLibraryOptions = () => {
    if (!selectedQuestion) {
      return [];
    }

    const questionData = dataByQuestion[selectedQuestion.id];

    const isOptionQueuedToBeRemoved = (lo: ILibraryOption) => {
      if (!questionData) {
        return false;
      }
      return questionData.idsToRemove.library.some(e => e.id === lo.id);
    };

    const selectedQuestionRemoteOptions = selectedQuestion.remoteOptions.filter(
      e => e.source === 'library',
    ) as ILibraryOption[];

    const remoteLibraryOptionIds = selectedQuestionRemoteOptions
      .filter(o => !isOptionQueuedToBeRemoved(o))
      .map(o => o.libraryId);

    const virtualLibraryOptionIds = (questionData?.visibleOptions ?? [])
      .filter(e => e.source === 'library')
      .map(e => (e as ILibraryOption).libraryId);

    return [...remoteLibraryOptionIds, ...virtualLibraryOptionIds];
  };

  const getQuestionOptions = (
    questionOptions: OptionsSectionProps['options'],
    questionId: string,
  ): Array<ICustomOption | ILibraryOption> => {
    // Track the question options
    remoteOptionsByQuestionIdRef.current[questionId] = questionOptions;

    const data = dataByQuestion[questionId];
    if (data) {
      return data.visibleOptions;
    }

    return questionOptions;
  };

  const isRemoteOption = (questionId: string, optionId: string | number) => {
    const target = remoteOptionsByQuestionIdRef.current[questionId];
    return target && target.some(e => String(e.id) === String(optionId));
  };

  const modalNode = (
    <>
      {savingOption && (
        <SaveOptionDialog
          isOpen
          onClose={() => setSavingOption(undefined)}
          option={savingOption.option}
          initialLocation={locationId}
          onSave={(option, locationIds) => {
            const { questionId } = savingOption!;
            setQuestionData({
              questionId,
              dispatch: setDataByQuestion,
              remoteOptionsByQuestionIdRef,
              mutator: questionData => {
                const isExistingEntity = isRemoteOption(questionId, option.id);

                if (isExistingEntity) {
                  const saveToLibRow: SaveToLibraryRow = {
                    id: option.id,
                    title: option.title,
                    description: option.description ?? null,
                    type: option.type,
                    locationIds,
                    // Generate a unique id as a fallback for the "pending save to OFC" as the entity is not yet created
                    // it is going to be used to generate the visual library option and giving tracking to delete it
                    generatedLibraryId: generateNumericMemoryId(),
                  };

                  // Ensure any pending freeform update is deleted
                  questionData.freeformUpdates = (questionData.freeformUpdates ?? []).filter(e => e.id !== option.id);

                  // Transform the custom freeform and mark as saving to library
                  questionData.freeformToSaveToLib = [...questionData.freeformToSaveToLib, saveToLibRow];
                } else {
                  const uniqueId = generateNumericMemoryId();
                  const newLibraryOption: VirtualLibraryOption = {
                    // Remote options will provide its ID we'll simply save and not attempt to create on the server-side
                    id: uniqueId,
                    libraryId: uniqueId,
                    title: option.title,
                    description: option.description ?? null,
                    type: option.type,
                    locationIds,
                  };

                  questionData.virtualOptions.freeform = questionData.virtualOptions.freeform.filter(
                    f => f.id !== option.id,
                  );
                  questionData.virtualOptions.library.push(newLibraryOption);
                }

                return questionData;
              },
            });

            return Promise.resolve();
          }}
        />
      )}
      {selectedQuestion && (
        <SelectOptionDialogSmart
          locationId={locationId}
          itemCategoryId={itemCategoryId}
          isOpen={!!selectedQuestion}
          onClose={() => setSelectedQuestion(null)}
          onSave={libraryOption => {
            setQuestionData({
              questionId: selectedQuestion.id,
              remoteOptionsByQuestionIdRef,
              dispatch: setDataByQuestion,
              mutator: questionData => {
                const newOption: VirtualLibraryOption = {
                  id: libraryOption.id,
                  libraryId: libraryOption.id,
                  title: libraryOption.title!,
                  description: libraryOption.description ?? null,
                  type: libraryOption.type,
                  locationIds: libraryOption.locationIds ?? [],
                };

                const newVirtualOptions = { ...questionData.virtualOptions };
                const idsToRemove = { ...questionData.idsToRemove };
                const isRemoteEntity = remoteOptionsByQuestionIdRef.current[selectedQuestion.id].some(
                  e => e.source === 'library' && e.libraryId === libraryOption.id,
                );

                // If the user is simply adding an option that was there before we should only
                // clear any existing action
                if (isRemoteEntity) {
                  idsToRemove.library = idsToRemove.library.filter(f => f.libraryId !== libraryOption.id);
                } else {
                  newVirtualOptions.library = [...newVirtualOptions.library, newOption];
                }

                return {
                  ...questionData,
                  idsToRemove,
                  virtualOptions: newVirtualOptions,
                };
              },
            });
          }}
          selectedOptions={getSelectedLibraryOptions()}
        />
      )}
    </>
  );

  const createOfcActions = (
    questionId: string,
    remoteOptions: OptionsSectionProps['options'],
  ): OptionsSectionActions => {
    remoteOptionsByQuestionIdRef.current[questionId] = remoteOptions;
    return {
      onAddFromLibrary: () => {
        setSelectedQuestion({ id: questionId, remoteOptions });
      },
      onNewCustom: () => {
        setQuestionData({
          questionId,
          remoteOptionsByQuestionIdRef,
          dispatch: setDataByQuestion,
          mutator: questionData => {
            const newRow: VirtualFreeformOption = {
              id: v4(),
              title: '',
              description: null,
              type: 'custom',
            };

            return {
              ...questionData,
              virtualOptions: {
                ...questionData.virtualOptions,
                freeform: [...questionData.virtualOptions.freeform, newRow],
              },
            };
          },
        });
      },
      onRemove: option => {
        setQuestionData({
          questionId,
          dispatch: setDataByQuestion,
          remoteOptionsByQuestionIdRef,
          mutator: questionData => {
            const isExistingEntity = isRemoteOption(questionId, option.id);

            // If the option is remote (already existed) we should track to delete it
            if (isExistingEntity) {
              if (option.source === 'custom') {
                questionData.idsToRemove.freeform.push(option.id);
                questionData.freeformUpdates = (questionData.freeformUpdates ?? []).filter(f => f.id !== option.id);
                questionData.freeformToSaveToLib = questionData.freeformToSaveToLib.filter(f => f.id !== option.id);
              } else {
                questionData.idsToRemove.library.push({ id: option.id, libraryId: option.libraryId });
              }
            } else {
              if (option.source === 'custom') {
                questionData.virtualOptions.freeform = questionData.virtualOptions.freeform.filter(
                  vo => vo.id !== option.id,
                );
              } else {
                // Ensure if the library option was queued to be saved to lib that we clear
                const targetIndex = questionData.freeformToSaveToLib.findIndex(e => e.generatedLibraryId === option.id);
                if (targetIndex !== -1) {
                  questionData.freeformToSaveToLib.splice(targetIndex, 1);
                }

                questionData.virtualOptions.library = questionData.virtualOptions.library.filter(
                  vo => vo.id !== option.id,
                );
              }
            }

            return questionData;
          },
        });
      },
      onSaveToLib: option => {
        setSavingOption({ option, questionId });
      },
      onOptionChange: updatedOption => {
        setQuestionData({
          questionId,
          remoteOptionsByQuestionIdRef,
          dispatch: setDataByQuestion,
          mutator: questionData => {
            const isExistingEntity = isRemoteOption(questionId, updatedOption.id);
            if (isExistingEntity) {
              // Ensure we clear any previous queued updated
              questionData.freeformUpdates = (questionData.freeformUpdates ?? []).filter(
                f => f.id !== updatedOption.id,
              );
              // Push the latest version
              questionData.freeformUpdates.push(updatedOption);
            } else {
              const target = questionData.virtualOptions.freeform.find(e => e.id === updatedOption.id)!;
              if (target) {
                target.title = updatedOption.title;
                target.type = updatedOption.type;
                target.description = updatedOption.description ?? null;
              } else {
                questionData.virtualOptions.freeform.push(updatedOption);
              }
            }

            return questionData;
          },
        });
      },
    };
  };

  const reset = () => {
    setSavingOption(undefined);
    setSelectedQuestion(undefined);
    remoteOptionsByQuestionIdRef.current = {};
    setDataByQuestion({});
  };

  /**
   * Returns the update required to be sent the API endpoint keyed by the Question Id
   */
  const getFinalOptionsByQuestionId = (): OptionsUpdateDtoByQuestionId => {
    const final: OptionsUpdateDtoByQuestionId = {};

    const filterEmptyTitleFreeform = (option: VirtualFreeformOption) => {
      return !isEmpty(option.title);
    };

    Object.entries(dataByQuestion).forEach(([questionId, data]) => {
      final[questionId] = {
        virtualOptions: {
          library: data.virtualOptions.library.map<LibraryOption>(e => ({
            title: e.title,
            description: e.description,
            type: e.type,
            locationIds: e.locationIds,
          })),
          freeform: data.virtualOptions.freeform.filter(filterEmptyTitleFreeform).map<FreeFormOption>(e => ({
            title: e.title,
            description: e.description,
            type: e.type,
          })),
        },
        freeformToUpdate: data.freeformUpdates,
        freeformToSaveToLib: data.freeformToSaveToLib.map<NonNullable<UpdateAnswer['freeformToSaveToLib']>[0]>(e =>
          omit(e, 'generatedLibraryId'),
        ),
        optionsToDelete: {
          libraryIds: data.idsToRemove.library.map(e => e.id),
          freeformIds: data.idsToRemove.freeform,
        },
      };
    });

    return final;
  };

  return {
    getQuestionOptions,
    isDirty,
    modalNode,
    createOfcActions,
    getFinalOptionsByQuestionId,
    reset,
  };
};
