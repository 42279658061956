import { LocationAutocomplete, LocationOption } from '@circadian-risk/form';
import { createBreadCrumbs } from '@circadian-risk/front-end-utils';
import { useOrganizationSessionStore } from '@circadian-risk/stores';
import { Controller, useFormContext } from 'react-hook-form';

import { createLocationFilterForm } from '../create-location-filter.form';

const { ariaLabels } = createLocationFilterForm;

export const BelongsToFormField: React.FC = () => {
  const layersById = useOrganizationSessionStore(state => state.layersById);
  const nodesById = useOrganizationSessionStore(state => state.nodesById);
  const locations = useOrganizationSessionStore(state => state.nodes);
  const { watch, control } = useFormContext();

  const baseLayerIdWatch = watch('baseLayerId');
  return (
    <Controller
      name="belongsToId"
      control={control}
      render={({ field: { onChange, value } }) => {
        const layer = layersById[baseLayerIdWatch];
        const options = locations.filter(l => l.layer_id === layer?.parent_id);

        const mappedOptions = options.map<LocationOption>(e => {
          const layer = layersById[e.layer_id];
          return {
            id: e.id,
            name: e.name,
            layerId: layer.id,
            layerName: layer.name,
            breadCrumbs: createBreadCrumbs(e.mpath, nodesById, layersById),
          };
        });

        const selectedLocation = mappedOptions.find(l => l.id === value) ?? null;

        return (
          <LocationAutocomplete
            label={ariaLabels.belongsToId}
            value={selectedLocation}
            onChange={location => {
              const locationId = location[0]?.id ?? null;
              onChange(locationId);
            }}
            options={mappedOptions}
          />
        );
      }}
    />
  );
};
