import { RxCollection, RxJsonSchema } from 'rxdb';

import { FileEntity } from '../../types';

export type FileEntityCollection = RxCollection<FileEntity>;

export const fileEntitySchema: RxJsonSchema<FileEntity> = {
  title: 'file schema',
  // Ensure that version is updated for every schema change
  version: 4,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      maxLength: 20,
    },
    name: {
      type: ['string'],
    },
    insertedAt: {
      type: 'number',
    },
    size: {
      type: ['number'],
    },
    type: {
      type: ['string'],
    },
    _deleted: {
      type: ['boolean', 'null'],
    },
    status: {
      type: 'string',
      default: 'NA',
    },
    uploadEndpointInfo: {
      type: 'object',
      properties: {
        endpoint: {
          type: 'string',
        },
        entityId: {
          type: 'string',
        },
        entityType: {
          type: 'string',
        },
        path: {
          type: ['string', 'null'],
        },
        metadata: {
          type: ['object', 'null'],
        },
      },
    },
  },
  attachments: {},
};
