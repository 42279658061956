import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import {
  createScheduledAssessmentResponseSchema,
  createScheduledAssessmentSchema,
} from './scheduled-assessment-schemas';
import {
  createSingleAssessmentSchema,
  pathParamsCommon,
  reimportResponseSchema,
  updateAssessmentSchema,
  updateAssignmentSchema,
} from './single-assessment-schemas';

const c = initContract();

export const assessmentsContract = c.router(
  {
    createSingle: {
      method: 'POST',
      path: '/:organizationId/single',
      pathParams: z.object({
        organizationId: z.string().uuid(),
      }),
      description: 'Create a scenario assessment of type single',
      body: createSingleAssessmentSchema,
      responses: {
        201: z.object({
          assessmentId: z.number(),
          relatedScenarioIds: z.array(z.number()),
        }),
      },
    },
    createScheduled: {
      method: 'POST',
      path: '/:organizationId/scheduled',
      pathParams: z.object({
        organizationId: z.string().uuid(),
      }),
      description: 'Create a scenario assessment of type scheduled',
      body: createScheduledAssessmentSchema,
      responses: {
        201: createScheduledAssessmentResponseSchema,
      },
    },
    update: {
      method: 'PATCH',
      path: '/:organizationId/:id',
      pathParams: pathParamsCommon,
      description: 'Update a scenario assessment and the users assigned to it',
      body: updateAssessmentSchema,
      responses: {
        201: z.object({}),
      },
    },
    reimportAnswer: {
      method: 'POST',
      path: '/:organizationId/:id/reimport-answer',
      pathParams: pathParamsCommon,
      description: 'Reimports the current active answer given an assessment answer',
      body: z.object({
        answerId: z.string().uuid(),
      }),
      responses: {
        201: reimportResponseSchema,
      },
    },
    bulkReimportAnswer: {
      method: 'POST',
      path: '/:organizationId/:id/bulk-reimport-answer',
      pathParams: pathParamsCommon,
      description: 'Bulk Re-import the active answers given assessment answers',
      body: z.object({
        answerIds: z.array(z.string().uuid()),
      }),
      responses: {
        201: reimportResponseSchema,
      },
    },
    updateAssignment: {
      method: 'PUT',
      path: '/:organizationId/:id/assignment',
      pathParams: pathParamsCommon,
      description: 'Overrides the assessment assignment (users, teams and groups)',
      body: updateAssignmentSchema,
      responses: {
        201: z.object({}),
      },
    },
  },
  {
    pathPrefix: '/assessments',
  },
);
