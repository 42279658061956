import { useMount } from 'react-use';

import { logoutChannel } from '../logout-broadcast-channel';

const handleLogoutAction = () => {
  // Reloading the page will reset everything correctly
  // Implement individual cache reset will require accessing Apollo client
  // suspend-cache and other sources therefore this will make the tab just refresh
  // and re-evaluate the session status
  window.location.reload();
};

/**
 * Subscribes to the logout broadcast channel
 */
export const useLogoutSubscription = () => {
  useMount(() => {
    logoutChannel.addEventListener('message', handleLogoutAction);
    return () => {
      logoutChannel.removeEventListener('message', handleLogoutAction);
    };
  });
};
