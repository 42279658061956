import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Button } from '@mui/material';
import React from 'react';

export interface FilesCellProps {
  filesCount?: number;
  relatedFilesCount?: number;
  onClick?: () => void;
}
export const FilesCell: React.FC<FilesCellProps> = ({ filesCount = 0, relatedFilesCount = 0, onClick }) => {
  if (!filesCount && !relatedFilesCount) {
    return null;
  }

  return (
    <Button variant={'text'} onClick={onClick}>
      {filesCount + relatedFilesCount}
      <AttachFileIcon />
    </Button>
  );
};
