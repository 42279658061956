import { Box, BoxProps, Link } from '@mui/material';

interface CustomNoRowsToShowProps extends BoxProps {
  onClearClick: () => void;
}

export const CustomNoRowsToShow: React.FC<CustomNoRowsToShowProps> = ({ onClearClick, ...boxProps }) => {
  return (
    <Box
      component="span"
      sx={{
        pointerEvents: 'all',
      }}
      color={({ palette }) => palette.text.secondary}
      {...boxProps}
    >
      No items match your filter criteria.{' '}
      <Link onClick={onClearClick} sx={{ cursor: 'pointer' }} underline="hover">
        Clear Filters
      </Link>
    </Box>
  );
};
