import * as Types from './operations';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export const OrganizationsIdentifiersFragmentDoc = gql`
    fragment OrganizationsIdentifiers on organizations {
  __typename
  id
}
    `;
export const ThemeOverrideFileFragmentFragmentDoc = gql`
    fragment themeOverrideFileFragment on files {
  id
  filepath
}
    `;
export const NodeInfoFragmentDoc = gql`
    fragment NodeInfo on nodes {
  __typename
  id
  name
  path
  mpath
  layer_id
  parent_id
}
    `;
export const LocationProjectsFragmentDoc = gql`
    fragment LocationProjects on nodes {
  __typename
  id
  projects {
    __typename
    id
    name
  }
}
    `;
export const GlobalContextNodeInfoFragmentDoc = gql`
    fragment globalContextNodeInfo on nodes {
  __typename
  ...NodeInfo
  ...LocationProjects
  address
  coordinates
  created_at
  ordinal
  use_custom_coordinates
  layer {
    __typename
    id
    name
    parent_id
    mpath
  }
  nodeDepth
  nodes_aggregate {
    aggregate {
      count
    }
  }
  layerTags {
    layer_tag_id
    organization_id
    node_id
  }
  layerTeamUsers {
    user_id
    location_id
    layer_team_id
    organization_id
  }
  mapbox_map_id
}
    ${NodeInfoFragmentDoc}
${LocationProjectsFragmentDoc}`;
export const LayerTagFragmentDoc = gql`
    fragment LayerTag on layer_tag {
  __typename
  id
  layer_id
  type_name
  description
  locationLayerTags_aggregate {
    aggregate {
      count
    }
  }
  propertyGroups_aggregate {
    aggregate {
      count
    }
  }
}
    `;
export const LayerTagsFragmentDoc = gql`
    fragment LayerTags on layers {
  layerTags {
    ...LayerTag
  }
}
    ${LayerTagFragmentDoc}`;
export const OrgContextDataFragmentDoc = gql`
    fragment OrgContextData on organizations {
  __typename
  ...OrganizationsIdentifiers
  name
  scored_layer_id
  theme_overrides {
    id
    primary_color
    organization_colors
    dark_logo {
      ...themeOverrideFileFragment
    }
    light_logo {
      ...themeOverrideFileFragment
    }
  }
  scenarioRangeLabels {
    lower_range_label
    mid_range_label
    upper_range_label
  }
  criticalityLabels {
    label
    criticality_value
  }
  scenarios(where: {organization_id: {_eq: $organizationId}}) {
    id
    name
    scenario_type
  }
  nodes(
    where: {layer: {hierarchy_type: {_eq: 1}}}
    order_by: {ordinal: desc, name: asc}
  ) {
    ...globalContextNodeInfo
  }
  meta {
    organization_layer_id
    campus_layer_id
    building_layer_id
    level_layer_id
  }
  projects {
    id
    name
    description
    status
  }
  layers(where: {hierarchy_type: {_eq: 1}}) {
    __typename
    id
    layerDepth
    mpath
    name
    parent_id
    hierarchy_type
    ...LayerTags
    teams {
      id
      name
      layer_id
      organization_id
    }
    nodes_aggregate {
      aggregate {
        count
      }
    }
  }
}
    ${OrganizationsIdentifiersFragmentDoc}
${ThemeOverrideFileFragmentFragmentDoc}
${GlobalContextNodeInfoFragmentDoc}
${LayerTagsFragmentDoc}`;
export const UserFragmentDoc = gql`
    fragment User on users {
  __typename
  id
  email
  last_signed_in_at
  first_name
  last_name
  avatar {
    id
    filepath
  }
}
    `;
export const OrgUsersFragmentDoc = gql`
    fragment OrgUsers on organizations {
  users_organizations_organizations {
    __typename
    organizations_id
    users_id
    role
    allowAdminPasswordChange
    user {
      ...User
      created_at
      created_by_id
    }
  }
}
    ${UserFragmentDoc}`;
export const ImageFragmentDoc = gql`
    fragment image on files {
  id
  updated_at
  filepath
  original_filename
  image {
    latest_image_path
    alternative_sizes
    markup
  }
}
    `;
export const OrgItemCategoriesFragmentDoc = gql`
    fragment OrgItemCategories on organizations {
  item_categories {
    id
    name
    description
    file {
      ...image
      icon {
        id
      }
    }
  }
}
    ${ImageFragmentDoc}`;
export const UserGroupFragmentDoc = gql`
    fragment UserGroup on groups {
  __typename
  id
  organization_id
  name
}
    `;
export const UserGroupUserFragmentDoc = gql`
    fragment UserGroupUser on group_users {
  __typename
  user_id
  group_id
  organization_id
}
    `;
export const UserGroupWithUsersFragmentDoc = gql`
    fragment UserGroupWithUsers on groups {
  ...UserGroup
  users {
    ...UserGroupUser
  }
}
    ${UserGroupFragmentDoc}
${UserGroupUserFragmentDoc}`;
export const OrgUserGroupsFragmentDoc = gql`
    fragment OrgUserGroups on organizations {
  groups {
    ...UserGroupWithUsers
  }
}
    ${UserGroupWithUsersFragmentDoc}`;
export const ActionableTaskHistoryFragmentDoc = gql`
    fragment ActionableTaskHistory on actionable_task_history {
  organization_id
  task_no
  task_action_id
  col
  value_from
  value_to
  updated_at
  updated_by_id
}
    `;
export const ActiveAnswersKeyFieldsFragmentDoc = gql`
    fragment activeAnswersKeyFields on active_answers {
  __typename
  item_id
  question_id
}
    `;
export const ReassessableAnswerFragmentDoc = gql`
    fragment reassessableAnswer on active_answers {
  ...activeAnswersKeyFields
  last_answered_by_id
  last_updated_by_id
  is_flagged
  response
  description
  answered_at: last_answered_at
  files {
    id
    file {
      ...image
    }
  }
}
    ${ActiveAnswersKeyFieldsFragmentDoc}
${ImageFragmentDoc}`;
export const ConsiderationOptionNodeKeyFieldsFragmentDoc = gql`
    fragment considerationOptionNodeKeyFields on consideration_option_nodes {
  consideration_option_id
  organization_id
  node_id
}
    `;
export const ActiveAnswerOptionsFragmentDoc = gql`
    fragment activeAnswerOptions on active_answers {
  freeform_options {
    id
    type
    title
    description
    created_at
  }
  library_options {
    id
    created_at
    libraryConsiderationOption {
      id
      type
      organization_id
      description
      name
      initial_cost
      ongoing_cost
      time_estimate
      photo {
        id
        filepath
      }
      nodes {
        ...considerationOptionNodeKeyFields
      }
    }
  }
}
    ${ConsiderationOptionNodeKeyFieldsFragmentDoc}`;
export const ScenarioQuestionSetStandardIdentifiersFragmentDoc = gql`
    fragment ScenarioQuestionSetStandardIdentifiers on scenario_question_set_standards {
  scenario_question_set_id
  question_id
}
    `;
export const ScenarioQuestionSetStandardFragmentDoc = gql`
    fragment scenarioQuestionSetStandard on scenario_question_set_standards {
  ...ScenarioQuestionSetStandardIdentifiers
  organization_id
  standard_name
  section1
  section2
  section3
  relevant_excerpt
}
    ${ScenarioQuestionSetStandardIdentifiersFragmentDoc}`;
export const TaskAnswerInfoFragmentDoc = gql`
    fragment taskAnswerInfo on active_answers {
  ...activeAnswersKeyFields
  ...activeAnswerOptions
  partial_compliance_multiplier
  last_answered_by_id
  last_answered_at
  description
  is_flagged
  response
  criticality_value
  files {
    id
    file {
      ...image
    }
  }
  item {
    id
    name
    is_interview_item
    is_present
    item_category_id
    item_category {
      id
      name
      file {
        filepath
      }
    }
    node_id
    files {
      file {
        ...image
      }
    }
  }
  question {
    id
    question_text
    standards {
      ...scenarioQuestionSetStandard
    }
    deficient_answer
  }
}
    ${ActiveAnswersKeyFieldsFragmentDoc}
${ActiveAnswerOptionsFragmentDoc}
${ImageFragmentDoc}
${ScenarioQuestionSetStandardFragmentDoc}`;
export const ActiveAnswerInfoFragmentDoc = gql`
    fragment activeAnswerInfo on active_answers {
  ...activeAnswersKeyFields
  ...activeAnswerOptions
  organization_id
  is_flagged
  response
  description
  partial_compliance_multiplier
  criticality_value
  last_answered_by_id
  last_answered_at
  last_answered_by {
    id
    last_name
    first_name
    email
  }
}
    ${ActiveAnswersKeyFieldsFragmentDoc}
${ActiveAnswerOptionsFragmentDoc}`;
export const ActiveAnswerFlagInfoFragmentDoc = gql`
    fragment ActiveAnswerFlagInfo on active_answers {
  is_flagged
}
    `;
export const ActiveAnswerResponseUpdateFragmentDoc = gql`
    fragment ActiveAnswerResponseUpdate on active_answers {
  ...activeAnswersKeyFields
  response
  description
}
    ${ActiveAnswersKeyFieldsFragmentDoc}`;
export const ActiveAnswerResponseUpdateWithPartialComplianceFragmentDoc = gql`
    fragment ActiveAnswerResponseUpdateWithPartialCompliance on active_answers {
  ...activeAnswersKeyFields
  response
  description
  partial_compliance_multiplier
}
    ${ActiveAnswersKeyFieldsFragmentDoc}`;
export const ActiveAnswerMoreInfoAggregationFragmentDoc = gql`
    fragment ActiveAnswerMoreInfoAggregation on active_answers {
  ...activeAnswersKeyFields
  description
  files {
    id
  }
  freeform_options {
    id
  }
  library_options {
    id
  }
}
    ${ActiveAnswersKeyFieldsFragmentDoc}`;
export const ActiveAnswerQuestionTextFragmentDoc = gql`
    fragment ActiveAnswerQuestionText on active_answers {
  question {
    id
    question_text
  }
}
    `;
export const ActiveAnswerSuggestedLibraryOptionsFragmentDoc = gql`
    fragment ActiveAnswerSuggestedLibraryOptions on active_answers {
  library_options {
    id
  }
}
    `;
export const ActiveAnswerAttachmentsFragmentDoc = gql`
    fragment ActiveAnswerAttachments on active_answers {
  files {
    id
    file {
      ...image
    }
  }
}
    ${ImageFragmentDoc}`;
export const ActiveAnswerFreeformOptionsFragmentDoc = gql`
    fragment ActiveAnswerFreeformOptions on active_answers {
  freeform_options {
    id
    type
    title
    description
    created_at
  }
}
    `;
export const ActiveAnswerLibraryOptionsFragmentDoc = gql`
    fragment ActiveAnswerLibraryOptions on active_answers {
  library_options {
    id
    libraryConsiderationOption {
      id
      type
      time_estimate
      initial_cost
      ongoing_cost
      name
      description
      photo {
        ...image
      }
    }
  }
}
    ${ImageFragmentDoc}`;
export const ActiveAnswerCriticalityInfoFragmentDoc = gql`
    fragment ActiveAnswerCriticalityInfo on active_answers {
  criticality_value
}
    `;
export const ActiveAnswerResponseFragmentDoc = gql`
    fragment ActiveAnswerResponse on active_answers {
  is_flagged
  response
  last_answered_by_id
  last_answered_at
  partial_compliance_multiplier
  description
}
    `;
export const ActiveAnswerQuestionIsRequiredFragmentDoc = gql`
    fragment ActiveAnswerQuestionIsRequired on active_answers {
  isRequired
}
    `;
export const ActiveAnswerResponseForRemediationFragmentDoc = gql`
    fragment ActiveAnswerResponseForRemediation on active_answers {
  ...ActiveAnswerResponse
  ...ActiveAnswerQuestionIsRequired
}
    ${ActiveAnswerResponseFragmentDoc}
${ActiveAnswerQuestionIsRequiredFragmentDoc}`;
export const QuestionForReassessFragmentDoc = gql`
    fragment QuestionForReassess on questions {
  id
  question_text
  deficient_answer
}
    `;
export const ScenarioQuestionSetQuestionIdentifiersFragmentDoc = gql`
    fragment ScenarioQuestionSetQuestionIdentifiers on scenario_question_set_question {
  layer_id
  scenario_question_set_id
  layer_tag_id
  question_id
}
    `;
export const QuestionScenariosFragmentDoc = gql`
    fragment QuestionScenarios on questions {
  id
  scenarioQuestions(
    where: {questionSet: {primaryQuestionSets: {scenario_question_set_id: {_is_null: false}}}}
  ) {
    ...ScenarioQuestionSetQuestionIdentifiers
    questionSet {
      id
      scenario {
        id
        name
        scenario_type
      }
    }
  }
}
    ${ScenarioQuestionSetQuestionIdentifiersFragmentDoc}`;
export const ItemDetailsFragmentDoc = gql`
    fragment ItemDetails on items {
  id
  name
  status
  node_id
  item_category {
    id
    name
    file {
      id
      filepath
    }
  }
}
    `;
export const LocationChipFragmentDoc = gql`
    fragment LocationChip on nodes {
  __typename
  id
  name
  layer {
    __typename
    id
    name
  }
}
    `;
export const NodePathFragmentDoc = gql`
    fragment NodePath on nodes {
  __typename
  id
  path
}
    `;
export const ActiveAnswerRemediationInfoFragmentDoc = gql`
    fragment ActiveAnswerRemediationInfo on active_answers {
  ...activeAnswersKeyFields
  ...ActiveAnswerAttachments
  ...ActiveAnswerFreeformOptions
  ...ActiveAnswerLibraryOptions
  ...ActiveAnswerCriticalityInfo
  ...ActiveAnswerResponseForRemediation
  question {
    ...QuestionForReassess
    ...QuestionScenarios
  }
  item {
    id
    is_present
    node_id
    item_category_id
    ...ItemDetails
    node {
      ...LocationChip
      ...NodePath
    }
  }
}
    ${ActiveAnswersKeyFieldsFragmentDoc}
${ActiveAnswerAttachmentsFragmentDoc}
${ActiveAnswerFreeformOptionsFragmentDoc}
${ActiveAnswerLibraryOptionsFragmentDoc}
${ActiveAnswerCriticalityInfoFragmentDoc}
${ActiveAnswerResponseForRemediationFragmentDoc}
${QuestionForReassessFragmentDoc}
${QuestionScenariosFragmentDoc}
${ItemDetailsFragmentDoc}
${LocationChipFragmentDoc}
${NodePathFragmentDoc}`;
export const ActiveAnswerFreeformOptionFragmentDoc = gql`
    fragment ActiveAnswerFreeformOption on active_answers_freeform_consideration {
  id
  type
  title
  description
}
    `;
export const AnswersFilesIdentifiersFragmentDoc = gql`
    fragment AnswersFilesIdentifiers on answers_files {
  __typename
  answer_id
  file_id
}
    `;
export const ScheduledAssessmentScheduledRequiredFieldsFragmentDoc = gql`
    fragment ScheduledAssessmentScheduledRequiredFields on scheduled_assessment_schedule {
  __typename
  id
  organization_id
}
    `;
export const ScheduledAssessmentScheduledFrequencyFragmentDoc = gql`
    fragment ScheduledAssessmentScheduledFrequency on scheduled_assessment_schedule_frequency {
  __typename
  id
  repeat_on
  repeat_every_type
  repeat_every_value
  type
}
    `;
export const ScheduledAssessmentScheduledNotificationFragmentDoc = gql`
    fragment ScheduledAssessmentScheduledNotification on scheduled_assessment_schedule_notifications {
  __typename
  id
  organization_id
  scheduled_assessment_schedule_id
  value
  sequence
  type
  trigger
  unit
}
    `;
export const ScheduledAssessmentScheduleDetailsFragmentDoc = gql`
    fragment ScheduledAssessmentScheduleDetails on scheduled_assessment_schedule {
  ...ScheduledAssessmentScheduledRequiredFields
  description
  due_after_time
  due_after_unit
  assessment_template_id
  frequency {
    ...ScheduledAssessmentScheduledFrequency
  }
  notifications {
    ...ScheduledAssessmentScheduledNotification
  }
}
    ${ScheduledAssessmentScheduledRequiredFieldsFragmentDoc}
${ScheduledAssessmentScheduledFrequencyFragmentDoc}
${ScheduledAssessmentScheduledNotificationFragmentDoc}`;
export const AssessmentAssignedUserKeyFieldsFragmentDoc = gql`
    fragment AssessmentAssignedUserKeyFields on assessment_users {
  assessment_id
  organization_id
  user_id
}
    `;
export const AssessmentAssignedGroupKeyFieldsFragmentDoc = gql`
    fragment AssessmentAssignedGroupKeyFields on assessment_user_groups {
  organization_id
  user_group_id
  assessment_id
}
    `;
export const AssessmentAssignedTeamKeyFieldsFragmentDoc = gql`
    fragment AssessmentAssignedTeamKeyFields on assessment_teams {
  assessment_id
  layer_team_id
  organization_id
}
    `;
export const AssessmentSubscribersFragmentDoc = gql`
    fragment AssessmentSubscribers on v2_assessments {
  subscribedTeams {
    assessment_id
    organization_id
    layer_team_id
  }
  subscribedGroups {
    assessment_id
    organization_id
    user_group_id
  }
  subscribedUsers {
    user_id
    organization_id
    assessment_id
  }
}
    `;
export const V2AssessmentNodesIdentifiersFragmentDoc = gql`
    fragment V2AssessmentNodesIdentifiers on v2_assessment_nodes {
  __typename
  organization_id
  node_id
  assessment_id
}
    `;
export const AssessmentForDetailsFragmentDoc = gql`
    fragment AssessmentForDetails on v2_assessments {
  __typename
  id
  name
  background
  recommendations
  findings
  notes
  description
  state
  start_date
  due_date
  is_opened
  open_range
  schedule_id
  scheduleCadence {
    ...ScheduledAssessmentScheduleDetails
  }
  progressAggregates {
    physical_progress
    inspection_progress
  }
  import_active_answers
  is_n_a_ratification_required
  is_n_o_ratification_required
  is_compliant_ratification_required
  is_deficient_ratification_required
  assessment_template_id
  assessmentTemplate {
    id
    name
  }
  scenarioScores {
    scenario_id
    max_control
    effective_control
    low_deficiency_count
    medium_deficiency_count
    high_deficiency_count
    control_percentage
  }
  users(where: {user: {id: {_is_null: false}}}) {
    ...AssessmentAssignedUserKeyFields
  }
  assignedGroups {
    ...AssessmentAssignedGroupKeyFields
  }
  assignedTeams {
    ...AssessmentAssignedTeamKeyFields
  }
  ...AssessmentSubscribers
  locations {
    ...V2AssessmentNodesIdentifiers
    relevantItemCategories(distinct_on: item_type) {
      item_type
    }
    progressAggregates {
      physical_progress
      inspection_progress
    }
    deficiencySummary {
      low_count
      medium_count
      high_count
      flagged_count
    }
    node {
      __typename
      id
      name
      mpath
      ordinal
      layer_id
    }
  }
}
    ${ScheduledAssessmentScheduleDetailsFragmentDoc}
${AssessmentAssignedUserKeyFieldsFragmentDoc}
${AssessmentAssignedGroupKeyFieldsFragmentDoc}
${AssessmentAssignedTeamKeyFieldsFragmentDoc}
${AssessmentSubscribersFragmentDoc}
${V2AssessmentNodesIdentifiersFragmentDoc}`;
export const AssessmentAnswerOptionsFragmentDoc = gql`
    fragment assessmentAnswerOptions on assessment_answers {
  freeform_options {
    id
    type
    title
    description
    created_at
  }
  library_options {
    id
    created_at
    libraryConsiderationOption {
      id
      type
      organization_id
      type
      description
      photo {
        id
        filepath
      }
      name
      nodes {
        ...considerationOptionNodeKeyFields
      }
    }
  }
}
    ${ConsiderationOptionNodeKeyFieldsFragmentDoc}`;
export const ProvideAnswerInfoFragmentDoc = gql`
    fragment provideAnswerInfo on assessment_answers {
  __typename
  id
  response
  description
  is_flagged
  partial_compliance_multiplier
  answered_at
  answered_by_id
  question_id
  assessment_item_id
}
    `;
export const AssessmentAssigneesFragmentDoc = gql`
    fragment AssessmentAssignees on v2_assessments {
  users {
    ...AssessmentAssignedUserKeyFields
  }
  assignedGroups {
    ...AssessmentAssignedGroupKeyFields
  }
  assignedTeams {
    ...AssessmentAssignedTeamKeyFields
  }
}
    ${AssessmentAssignedUserKeyFieldsFragmentDoc}
${AssessmentAssignedGroupKeyFieldsFragmentDoc}
${AssessmentAssignedTeamKeyFieldsFragmentDoc}`;
export const AssessmentItemsFilesIdentifiersFragmentDoc = gql`
    fragment AssessmentItemsFilesIdentifiers on assessment_items_files {
  __typename
  assessment_item_id
  file_id
}
    `;
export const AssessmentNodeItemCategoryDetailsFragmentDoc = gql`
    fragment assessmentNodeItemCategoryDetails on assessment_node_item_categories {
  ordinal
  item_type
  is_required
  itemCategory {
    __typename
    id
    name
    file {
      __typename
      id
      filepath
    }
  }
  is_hidden_by_area_tags
}
    `;
export const CatalogScenarioMetaSourceIdentifiersFragmentDoc = gql`
    fragment CatalogScenarioMetaSourceIdentifiers on catalog_scenario_meta_source {
  __typename
  catalog_scenario_meta_id
  catalog_source_id
}
    `;
export const CommentCreatedByFragmentDoc = gql`
    fragment CommentCreatedBy on comments {
  created_by
}
    `;
export const CommentKeyFieldsFragmentDoc = gql`
    fragment CommentKeyFields on comments {
  id
}
    `;
export const CommentHeaderFragmentDoc = gql`
    fragment CommentHeader on comments {
  created_by
  created_at
  updated_at
}
    `;
export const CommentContentFragmentDoc = gql`
    fragment CommentContent on comments {
  content
}
    `;
export const CommentEntityFragmentDoc = gql`
    fragment CommentEntity on comments {
  entity_pk
  entity_type
}
    `;
export const CommentFragmentDoc = gql`
    fragment Comment on comments {
  parent_id
  ...CommentKeyFields
  ...CommentHeader
  ...CommentContent
  ...CommentEntity
}
    ${CommentKeyFieldsFragmentDoc}
${CommentHeaderFragmentDoc}
${CommentContentFragmentDoc}
${CommentEntityFragmentDoc}`;
export const CommentRepliesFragmentDoc = gql`
    fragment CommentReplies on comments {
  ...CommentKeyFields
  replies {
    parent_id
    ...Comment
  }
}
    ${CommentKeyFieldsFragmentDoc}
${CommentFragmentDoc}`;
export const ConsiderationOptionItemCategoriesIdentifiersFragmentDoc = gql`
    fragment ConsiderationOptionItemCategoriesIdentifiers on consideration_option_item_categories {
  __typename
  organization_id
  consideration_option_id
  item_category_id
}
    `;
export const ConsiderationOptionItemCategoryMutationReturnFragmentDoc = gql`
    fragment considerationOptionItemCategoryMutationReturn on consideration_option_item_categories_mutation_response {
  returning {
    ...ConsiderationOptionItemCategoriesIdentifiers
    considerationOption {
      organization_id
      id
      itemCategories {
        item_category_id
        organization_id
        consideration_option_id
      }
    }
  }
}
    ${ConsiderationOptionItemCategoriesIdentifiersFragmentDoc}`;
export const ConsiderationOptionNodeMutationReturnFragmentDoc = gql`
    fragment considerationOptionNodeMutationReturn on consideration_option_nodes_mutation_response {
  returning {
    ...considerationOptionNodeKeyFields
    considerationOption {
      organization_id
      id
      nodes {
        node_id
        organization_id
        consideration_option_id
      }
    }
  }
}
    ${ConsiderationOptionNodeKeyFieldsFragmentDoc}`;
export const ConsiderationOptionInfoFragmentDoc = gql`
    fragment considerationOptionInfo on consideration_options {
  id
  time_estimate
  initial_cost
  ongoing_cost
  description
  organization_id
  photo_id
  photo {
    id
    filepath
    original_filename
    image {
      __typename
      id
      latest_image_path
      alternative_sizes
      markup
    }
  }
  type
  name
  nodes {
    organization_id
    consideration_option_id
    node_id
  }
  itemCategories {
    consideration_option_id
    organization_id
    item_category_id
    itemCategory {
      id
      name
      file {
        id
        filepath
      }
    }
  }
}
    `;
export const LibraryConsiderationFragmentDoc = gql`
    fragment LibraryConsideration on consideration_options {
  id
  name
  type
  time_estimate
  initial_cost
  ongoing_cost
  description
}
    `;
export const FileNameFragmentDoc = gql`
    fragment FileName on files {
  id
  original_filename
}
    `;
export const FilePathFragmentDoc = gql`
    fragment FilePath on files {
  id
  filepath
}
    `;
export const FileThumbnailFragmentDoc = gql`
    fragment FileThumbnail on files {
  ...FileName
  image {
    latest_image_path
    alternative_sizes
  }
}
    ${FileNameFragmentDoc}`;
export const FileImageFragmentDoc = gql`
    fragment FileImage on files {
  id
  image {
    latest_image_path
    alternative_sizes
    markup
  }
}
    `;
export const FileWithImageDataFragmentDoc = gql`
    fragment FileWithImageData on files {
  id
  updated_at
  ...FileName
  ...FilePath
  ...FileThumbnail
  ...FileImage
}
    ${FileNameFragmentDoc}
${FilePathFragmentDoc}
${FileThumbnailFragmentDoc}
${FileImageFragmentDoc}`;
export const ItemIsPresentFragmentDoc = gql`
    fragment ItemIsPresent on items {
  id
  is_present
}
    `;
export const ItemNodeIdFragmentDoc = gql`
    fragment ItemNodeId on items {
  id
  node_id
}
    `;
export const ItemItemCategoryIdFragmentDoc = gql`
    fragment ItemItemCategoryId on items {
  id
  item_category_id
}
    `;
export const ItemAnswerAggregatesKeyFieldsFragmentDoc = gql`
    fragment itemAnswerAggregatesKeyFields on item_answer_aggregates {
  __typename
  item_id
}
    `;
export const ItemAnswerAggregatesFragmentDoc = gql`
    fragment itemAnswerAggregates on item_answer_aggregates {
  ...itemAnswerAggregatesKeyFields
  rollup_label
  deficiency_count
  high_criticality_deficiency_count
  medium_criticality_deficiency_count
  low_criticality_deficiency_count
  compliant_count
  accepted_deficiency_count
  untriaged_deficiency_count
  sum_deficiency_criticality_points
  non_accepted_deficiency_count
  sum_compliant_criticality_points
  sum_untriaged_criticality_points
  sum_accepted_criticality_points
  high_criticiality_untriaged_deficiency_count
  medium_criticiality_untriaged_deficiency_count
  low_criticiality_untriaged_deficiency_count
}
    ${ItemAnswerAggregatesKeyFieldsFragmentDoc}`;
export const ItemCategoryNameAndFilePathFragmentDoc = gql`
    fragment ItemCategoryNameAndFilePath on item_categories {
  id
  name
  file {
    id
    filepath
  }
}
    `;
export const ItemCategorySelectedQuestionsFragmentDoc = gql`
    fragment ItemCategorySelectedQuestions on item_categories {
  id
  questions {
    id
  }
}
    `;
export const QuestionFragmentDoc = gql`
    fragment Question on questions {
  id
  question_text
  question_type
  deficient_answer
  item_category_id
  updated_at
}
    `;
export const ItemCategoryAndQuestionsFragmentDoc = gql`
    fragment ItemCategoryAndQuestions on item_categories {
  id
  name
  questions {
    ...Question
  }
}
    ${QuestionFragmentDoc}`;
export const ItemCategoryPropertyConfigFragmentDoc = gql`
    fragment ItemCategoryPropertyConfig on item_category_property_config {
  id
  name
  description
  kind
  options
  ordinal
  group_id
}
    `;
export const ItemCategoryPropertyGroupFragmentDoc = gql`
    fragment ItemCategoryPropertyGroup on item_category_property_group {
  id
  name
  type: item_type
  description
  ordinal
  item_category_id
  configs(order_by: {ordinal: asc}) {
    ...ItemCategoryPropertyConfig
  }
}
    ${ItemCategoryPropertyConfigFragmentDoc}`;
export const ItemPropertyGroupValueIdentifiersFragmentDoc = gql`
    fragment ItemPropertyGroupValueIdentifiers on item_property_group_value {
  __typename
  item_id
  config_id
  organization_id
}
    `;
export const LocationLayerTagIdentifiersFragmentDoc = gql`
    fragment LocationLayerTagIdentifiers on location_layer_tag {
  __typename
  layer_tag_id
  organization_id
  node_id
}
    `;
export const LocationPropertyGroupValueIdentifiersFragmentDoc = gql`
    fragment LocationPropertyGroupValueIdentifiers on location_property_group_value {
  __typename
  location_id
  config_id
  organization_id
}
    `;
export const LocationScenarioAggregatesSnapshotsIdentifiersFragmentDoc = gql`
    fragment LocationScenarioAggregatesSnapshotsIdentifiers on location_scenario_aggregates_snapshots {
  __typename
  node_id
  scenario_id
  sys_period
}
    `;
export const LocationScenarioImpactValueIdentifiersFragmentDoc = gql`
    fragment LocationScenarioImpactValueIdentifiers on location_scenario_impact_value {
  __typename
  scenario_id
  location_id
  impact_measure_id
}
    `;
export const LocationScenarioInforIdentifiersFragmentDoc = gql`
    fragment LocationScenarioInforIdentifiers on location_scenario_info {
  __typename
  scenario_id
  node_id
}
    `;
export const LocationScenarioProbabilityValueIdentifiersFragmentDoc = gql`
    fragment LocationScenarioProbabilityValueIdentifiers on location_scenario_probability_value {
  __typename
  scenario_id
  location_id
  probability_measure_id
}
    `;
export const AssessmentNodeInformationFragmentDoc = gql`
    fragment assessmentNodeInformation on nodes {
  __typename
  id
  name
  mpath
  parent_id
  node_mapbox_map {
    __typename
    max_bounds
    style_url
  }
}
    `;
export const NodeLayerTeamUsersWithTeamFragmentDoc = gql`
    fragment NodeLayerTeamUsersWithTeam on nodes {
  __typename
  layerTeamUsers {
    __typename
    user_id
    location_id
    organization_id
    layer_team_id
    team {
      __typename
      id
      organization_id
      name
    }
  }
}
    `;
export const NodeLayerFragmentDoc = gql`
    fragment NodeLayer on nodes {
  __typename
  id
  layer {
    __typename
    id
    name
  }
}
    `;
export const OrganizationAuthStrategyIdentifiersFragmentDoc = gql`
    fragment OrganizationAuthStrategyIdentifiers on organization_auth_strategy {
  id
}
    `;
export const OrganizationNamesAndIdsFragmentDoc = gql`
    fragment OrganizationNamesAndIds on organizations {
  ...OrganizationsIdentifiers
  name
}
    ${OrganizationsIdentifiersFragmentDoc}`;
export const ItemCategoryFragmentDoc = gql`
    fragment ItemCategory on item_categories {
  id
  name
  asset
  countermeasure
  description
  file {
    filepath
    icon {
      id
    }
  }
  updated_at
  created_at
}
    `;
export const OrganizationItemCategoriesAndQuestionsFragmentDoc = gql`
    fragment OrganizationItemCategoriesAndQuestions on organizations {
  ...OrganizationsIdentifiers
  item_categories {
    ...ItemCategory
    questions {
      ...Question
    }
  }
}
    ${OrganizationsIdentifiersFragmentDoc}
${ItemCategoryFragmentDoc}
${QuestionFragmentDoc}`;
export const OrganizationProjectFieldsFragmentDoc = gql`
    fragment OrganizationProjectFields on organizations {
  projects {
    __typename
    id
    name
    location_id
  }
}
    `;
export const OrganizationProjectsFragmentDoc = gql`
    fragment OrganizationProjects on organizations {
  ...OrganizationsIdentifiers
  ...OrganizationProjectFields
}
    ${OrganizationsIdentifiersFragmentDoc}
${OrganizationProjectFieldsFragmentDoc}`;
export const PrimaryScenarioQuestionSetsIdentifiersFragmentDoc = gql`
    fragment PrimaryScenarioQuestionSetsIdentifiers on primary_scenario_question_sets {
  __typename
  scenario_id
  scenario_question_set_id
}
    `;
export const ProjectNameFragmentDoc = gql`
    fragment ProjectName on projects {
  id
  name
}
    `;
export const BasicProjectFragmentDoc = gql`
    fragment BasicProject on projects {
  __typename
  organization_id
  id
  name
  status
}
    `;
export const ProjectTableRowFragmentDoc = gql`
    fragment ProjectTableRow on projects {
  ...BasicProject
  description
  location_id
  tasks_aggregate {
    __typename
    aggregate {
      count
      sum_initial_cost: sum {
        initial_cost
      }
      sum_ongoing_cost: sum {
        ongoing_cost
      }
      sum_time_estimate: sum {
        time_estimate
      }
    }
  }
}
    ${BasicProjectFragmentDoc}`;
export const QuestionSetUploadJobFragmentDoc = gql`
    fragment QuestionSetUploadJob on question_set_upload_jobs {
  id
  name
  error_message
  warning_message
  status
  created_at
  updated_at
}
    `;
export const ItemCategoryQuestionsFragmentFragmentDoc = gql`
    fragment itemCategoryQuestionsFragment on questions {
  __typename
  id
  standard
  deficient_answer
  question_revisions(order_by: {created_at: desc}, limit: 1) {
    __typename
    id
    name
  }
}
    `;
export const QuestionTextFragmentDoc = gql`
    fragment QuestionText on questions {
  id
  question_text
}
    `;
export const QuestionDeficientAnswerFragmentDoc = gql`
    fragment QuestionDeficientAnswer on questions {
  id
  deficient_answer
}
    `;
export const QuestionStandardsFragmentDoc = gql`
    fragment QuestionStandards on questions {
  id
  standards {
    ...scenarioQuestionSetStandard
  }
}
    ${ScenarioQuestionSetStandardFragmentDoc}`;
export const ReportSectionFieldsIdentifiersFragmentDoc = gql`
    fragment ReportSectionFieldsIdentifiers on report_section_fields {
  __typename
  organization_id
  id
}
    `;
export const InherentRiskProgressFragmentDoc = gql`
    fragment inherentRiskProgress on scenario {
  id
  organization_id
  percentCompleteInherentRisk
  completedLocationsAggregate: relatedLocations_aggregate(
    where: {is_complete: {_eq: true}}
  ) {
    aggregate {
      count
    }
  }
  incompletedLocationsAggregate: relatedLocations_aggregate(
    where: {is_complete: {_eq: false}}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const ScenarioMeasureFragmentDoc = gql`
    fragment scenarioMeasure on scenario_inherent_risk_measures {
  id
  organization_id
  scenario_id
  name
  description
  type
  min_value
  max_value
  is_user_supplied_numeric
  valueLabels(order_by: {value: asc}) {
    id
    value
    name
  }
  locationsUsingMeasureCount
}
    `;
export const ScenarioLocationTypeIdentifiersFragmentDoc = gql`
    fragment ScenarioLocationTypeIdentifiers on scenario_location_type {
  __typename
  layer_id
  scenario_id
}
    `;
export const ScenarioQuestionSetItemCategoryIdentifiersFragmentDoc = gql`
    fragment ScenarioQuestionSetItemCategoryIdentifiers on scenario_question_set_item_categories {
  item_category_id
  scenario_question_set_id
}
    `;
export const ScenarioQuestionSetSelectedQuestionIdentifiersFragmentDoc = gql`
    fragment ScenarioQuestionSetSelectedQuestionIdentifiers on scenario_question_set_selected_questions {
  __typename
  question_id
  scenario_question_set_id
}
    `;
export const ScenarioQuestionSetItemCategorySelectedQuestionsFragmentDoc = gql`
    fragment ScenarioQuestionSetItemCategorySelectedQuestions on scenario_question_set_item_categories {
  ...ScenarioQuestionSetItemCategoryIdentifiers
  selectedQuestions {
    ...ScenarioQuestionSetSelectedQuestionIdentifiers
    ordinal
  }
}
    ${ScenarioQuestionSetItemCategoryIdentifiersFragmentDoc}
${ScenarioQuestionSetSelectedQuestionIdentifiersFragmentDoc}`;
export const ScenarioQuestionSetQuestionsCriticalityFragmentDoc = gql`
    fragment ScenarioQuestionSetQuestionsCriticality on scenario_question_set_question {
  criticality
  is_required
}
    `;
export const ScenarioQuestionSetItemCategorySelectedQuestionCriticalityFragmentDoc = gql`
    fragment ScenarioQuestionSetItemCategorySelectedQuestionCriticality on scenario_question_set_item_categories {
  ...ScenarioQuestionSetItemCategoryIdentifiers
  selectedQuestions {
    ...ScenarioQuestionSetSelectedQuestionIdentifiers
    questionCriticalities {
      ...ScenarioQuestionSetQuestionIdentifiers
      ...ScenarioQuestionSetQuestionsCriticality
    }
  }
}
    ${ScenarioQuestionSetItemCategoryIdentifiersFragmentDoc}
${ScenarioQuestionSetSelectedQuestionIdentifiersFragmentDoc}
${ScenarioQuestionSetQuestionIdentifiersFragmentDoc}
${ScenarioQuestionSetQuestionsCriticalityFragmentDoc}`;
export const ScenarioQuestionSetQuestionsRelationshipFragmentDoc = gql`
    fragment ScenarioQuestionSetQuestionsRelationship on scenario_question_set_question {
  parent_question_id
  parent_question_response_type
}
    `;
export const ScenarioQuestionSetItemCategorySelectedQuestionRelationshipFragmentDoc = gql`
    fragment ScenarioQuestionSetItemCategorySelectedQuestionRelationship on scenario_question_set_item_categories {
  ...ScenarioQuestionSetItemCategoryIdentifiers
  selectedQuestions {
    ...ScenarioQuestionSetSelectedQuestionIdentifiers
    questionCriticalities {
      ...ScenarioQuestionSetQuestionIdentifiers
      ...ScenarioQuestionSetQuestionsRelationship
    }
  }
}
    ${ScenarioQuestionSetItemCategoryIdentifiersFragmentDoc}
${ScenarioQuestionSetSelectedQuestionIdentifiersFragmentDoc}
${ScenarioQuestionSetQuestionIdentifiersFragmentDoc}
${ScenarioQuestionSetQuestionsRelationshipFragmentDoc}`;
export const ScenarioQuestionSetQuestionsOrdinalFragmentDoc = gql`
    fragment ScenarioQuestionSetQuestionsOrdinal on scenario_question_set_question {
  ordinal
}
    `;
export const ScenarioQuestionSetQuestionsFragmentDoc = gql`
    fragment ScenarioQuestionSetQuestions on scenario_question_set_question {
  ...ScenarioQuestionSetQuestionIdentifiers
  ...ScenarioQuestionSetQuestionsCriticality
  ...ScenarioQuestionSetQuestionsRelationship
  ...ScenarioQuestionSetQuestionsOrdinal
}
    ${ScenarioQuestionSetQuestionIdentifiersFragmentDoc}
${ScenarioQuestionSetQuestionsCriticalityFragmentDoc}
${ScenarioQuestionSetQuestionsRelationshipFragmentDoc}
${ScenarioQuestionSetQuestionsOrdinalFragmentDoc}`;
export const ScenarioQuestionSetItemCategoryQuestionsFragmentDoc = gql`
    fragment ScenarioQuestionSetItemCategoryQuestions on scenario_question_set_item_categories {
  ...ScenarioQuestionSetItemCategorySelectedQuestionCriticality
  ...ScenarioQuestionSetItemCategorySelectedQuestionRelationship
  selectedQuestions {
    ...ScenarioQuestionSetSelectedQuestionIdentifiers
    questionCriticalities {
      ordinal
      ...ScenarioQuestionSetQuestionIdentifiers
      ...ScenarioQuestionSetQuestions
    }
  }
}
    ${ScenarioQuestionSetItemCategorySelectedQuestionCriticalityFragmentDoc}
${ScenarioQuestionSetItemCategorySelectedQuestionRelationshipFragmentDoc}
${ScenarioQuestionSetSelectedQuestionIdentifiersFragmentDoc}
${ScenarioQuestionSetQuestionIdentifiersFragmentDoc}
${ScenarioQuestionSetQuestionsFragmentDoc}`;
export const ScenarioQuestionSetItemCategorySelectedQuestionOrdinalFragmentDoc = gql`
    fragment ScenarioQuestionSetItemCategorySelectedQuestionOrdinal on scenario_question_set_item_categories {
  ...ScenarioQuestionSetItemCategoryIdentifiers
  selectedQuestions {
    ...ScenarioQuestionSetSelectedQuestionIdentifiers
    questionCriticalities {
      ...ScenarioQuestionSetQuestionIdentifiers
      ...ScenarioQuestionSetQuestionsOrdinal
    }
  }
}
    ${ScenarioQuestionSetItemCategoryIdentifiersFragmentDoc}
${ScenarioQuestionSetSelectedQuestionIdentifiersFragmentDoc}
${ScenarioQuestionSetQuestionIdentifiersFragmentDoc}
${ScenarioQuestionSetQuestionsOrdinalFragmentDoc}`;
export const ScenarioQuestionSetItemCategoryOrdinalFragmentDoc = gql`
    fragment ScenarioQuestionSetItemCategoryOrdinal on scenario_question_set_item_categories {
  ordinal
}
    `;
export const ScenarioQuestionSetItemCategoryFragmentDoc = gql`
    fragment ScenarioQuestionSetItemCategory on scenario_question_set_item_categories {
  ...ScenarioQuestionSetItemCategoryIdentifiers
  ...ScenarioQuestionSetItemCategoryOrdinal
  selectedQuestions {
    ...ScenarioQuestionSetSelectedQuestionIdentifiers
    questionCriticalities {
      ...ScenarioQuestionSetQuestions
    }
  }
}
    ${ScenarioQuestionSetItemCategoryIdentifiersFragmentDoc}
${ScenarioQuestionSetItemCategoryOrdinalFragmentDoc}
${ScenarioQuestionSetSelectedQuestionIdentifiersFragmentDoc}
${ScenarioQuestionSetQuestionsFragmentDoc}`;
export const ScenarioQuestionSetQuestionsOrdinalAndOrdinalFragmentDoc = gql`
    fragment ScenarioQuestionSetQuestionsOrdinalAndOrdinal on scenario_question_set_question {
  ...ScenarioQuestionSetQuestionsOrdinal
  ...ScenarioQuestionSetQuestionsCriticality
}
    ${ScenarioQuestionSetQuestionsOrdinalFragmentDoc}
${ScenarioQuestionSetQuestionsCriticalityFragmentDoc}`;
export const ScenarioQuestionSetSelectedQuestionsFragmentDoc = gql`
    fragment ScenarioQuestionSetSelectedQuestions on scenario_question_set_selected_questions {
  ...ScenarioQuestionSetSelectedQuestionIdentifiers
  item_category_id
  ordinal
}
    ${ScenarioQuestionSetSelectedQuestionIdentifiersFragmentDoc}`;
export const ScenarioQuestionSetQuestionStandardFragmentDoc = gql`
    fragment ScenarioQuestionSetQuestionStandard on scenario_question_set_standards {
  ...ScenarioQuestionSetStandardIdentifiers
  standard_name
  relevant_excerpt
  section1
  section2
  section3
}
    ${ScenarioQuestionSetStandardIdentifiersFragmentDoc}`;
export const QuestionSetCategoriesFragmentDoc = gql`
    fragment questionSetCategories on scenario_question_sets {
  itemCategories {
    __typename
    scenario_question_set_id
    item_category_id
  }
}
    `;
export const SelectedCategoryQuestionsFragmentDoc = gql`
    fragment selectedCategoryQuestions on scenario_question_set_item_categories {
  selectedQuestions {
    __typename
    question_id
    scenario_question_set_id
  }
}
    `;
export const ScenarioQuestionSetAndScenarioInfoFragmentDoc = gql`
    fragment ScenarioQuestionSetAndScenarioInfo on scenario_question_sets {
  id
  name
  scenario {
    id
    name
    scenario_type
  }
}
    `;
export const ScenarioQuestionSetSelectedItemCategoriesFragmentDoc = gql`
    fragment ScenarioQuestionSetSelectedItemCategories on scenario_question_sets {
  itemCategories {
    item_category_id
    ordinal
  }
}
    `;
export const ScenarioQuestionSetSelectedItemCategoriesAndQuestionsFragmentDoc = gql`
    fragment ScenarioQuestionSetSelectedItemCategoriesAndQuestions on scenario_question_sets {
  itemCategories {
    item_category_id
    selectedQuestions {
      question_id
    }
  }
}
    `;
export const ScenarioQuestionSetCriticalitiesFragmentDoc = gql`
    fragment ScenarioQuestionSetCriticalities on scenario_question_sets {
  id
  name
  itemCategories {
    item_category_id
    scenario_question_set_id
    selectedQuestions {
      question_id
      scenario_question_set_id
      questionCriticalities {
        ...ScenarioQuestionSetQuestionIdentifiers
        ...ScenarioQuestionSetQuestionsCriticality
      }
    }
  }
}
    ${ScenarioQuestionSetQuestionIdentifiersFragmentDoc}
${ScenarioQuestionSetQuestionsCriticalityFragmentDoc}`;
export const ScenarioQuestionSetItemCategoryProgressFragmentDoc = gql`
    fragment ScenarioQuestionSetItemCategoryProgress on scenario_question_set_item_categories {
  selectedQuestions {
    question_id
    questionCriticalities {
      criticality
      is_required
    }
  }
}
    `;
export const ScenarioQuestionSetProgressFragmentDoc = gql`
    fragment ScenarioQuestionSetProgress on scenario_question_sets {
  itemCategories {
    item_category_id
    ...ScenarioQuestionSetItemCategoryProgress
  }
}
    ${ScenarioQuestionSetItemCategoryProgressFragmentDoc}`;
export const ScenarioIdentifiersFragmentDoc = gql`
    fragment ScenarioIdentifiers on scenario {
  id
  organization_id
}
    `;
export const ScenarioQuestionSetScenarioInfoFragmentDoc = gql`
    fragment ScenarioQuestionSetScenarioInfo on scenario_question_sets {
  scenario {
    ...ScenarioIdentifiers
    scenario_type
    name
    description
  }
}
    ${ScenarioIdentifiersFragmentDoc}`;
export const VirtualScenarioLayerTagsIdentifiersFragmentDoc = gql`
    fragment VirtualScenarioLayerTagsIdentifiers on virtual_scenario_layer_tags {
  layer_id
  layer_tag_id
}
    `;
export const ScenarioLayerTagsFragmentDoc = gql`
    fragment ScenarioLayerTags on virtual_scenario_layer_tags {
  ...VirtualScenarioLayerTagsIdentifiers
  layer_name
  layer_tag_name
}
    ${VirtualScenarioLayerTagsIdentifiersFragmentDoc}`;
export const ScenarioLocationTagsFragmentDoc = gql`
    fragment ScenarioLocationTags on scenario {
  possibleLayerTags {
    ...ScenarioLayerTags
  }
}
    ${ScenarioLayerTagsFragmentDoc}`;
export const ScenarioScoredLayersFragmentDoc = gql`
    fragment ScenarioScoredLayers on scenario {
  locationTypes {
    scenario_id
    layer_id
    locationType {
      name
    }
  }
}
    `;
export const ScenarioQuestionSetScoredLayerFragmentDoc = gql`
    fragment ScenarioQuestionSetScoredLayer on scenario_question_sets {
  scenario {
    ...ScenarioScoredLayers
  }
}
    ${ScenarioScoredLayersFragmentDoc}`;
export const ScenarioQuestionSetSelectedLocationTagsIdentifiersFragmentDoc = gql`
    fragment ScenarioQuestionSetSelectedLocationTagsIdentifiers on scenario_question_set_location_tags {
  layer_id
  layer_tag_id
  scenario_question_set_id
}
    `;
export const ScenarioQuestionSetSelectedLocationTagsFragmentDoc = gql`
    fragment ScenarioQuestionSetSelectedLocationTags on scenario_question_sets {
  selected_location_tags {
    ...ScenarioQuestionSetSelectedLocationTagsIdentifiers
  }
}
    ${ScenarioQuestionSetSelectedLocationTagsIdentifiersFragmentDoc}`;
export const ScenarioQuestionSetLocationTagsFragmentDoc = gql`
    fragment ScenarioQuestionSetLocationTags on scenario_question_sets {
  scenario {
    ...ScenarioLocationTags
  }
  ...ScenarioQuestionSetScoredLayer
  ...ScenarioQuestionSetSelectedLocationTags
}
    ${ScenarioLocationTagsFragmentDoc}
${ScenarioQuestionSetScoredLayerFragmentDoc}
${ScenarioQuestionSetSelectedLocationTagsFragmentDoc}`;
export const ScenarioQuestionSetFragmentDoc = gql`
    fragment scenarioQuestionSet on scenario_question_sets {
  id
  name
  created_at
  file {
    id
    original_filename
    filepath
  }
  isPrimary
  is_draft
  scenario {
    ...ScenarioIdentifiers
    scenario_type
  }
  assessmentTemplates {
    assessmentTemplate {
      id
      name
      description
      created_at
      assessments_aggregate {
        aggregate {
          count
        }
      }
    }
  }
}
    ${ScenarioIdentifiersFragmentDoc}`;
export const ScheduledAssessmentScheduleAssessmentFragmentDoc = gql`
    fragment ScheduledAssessmentScheduleAssessment on v2_assessments {
  __typename
  id
  organization_id
  scored_node_id
  open_range
}
    `;
export const ScheduledAssessmentScheduleFragmentDoc = gql`
    fragment ScheduledAssessmentSchedule on scheduled_assessment_schedule {
  ...ScheduledAssessmentScheduledRequiredFields
  name
  description
  status
  starts_at
  ends_on_type
  ends_on_value
  openedAssessments: assessments(
    limit: 1
    order_by: {created_at: desc}
    where: {state: {_eq: "InProgress"}, is_opened: {_eq: true}}
  ) {
    ...ScheduledAssessmentScheduleAssessment
  }
  assessmentTemplate {
    __typename
    id
    name
  }
  frequency {
    ...ScheduledAssessmentScheduledFrequency
  }
  notifications {
    ...ScheduledAssessmentScheduledNotification
  }
}
    ${ScheduledAssessmentScheduledRequiredFieldsFragmentDoc}
${ScheduledAssessmentScheduleAssessmentFragmentDoc}
${ScheduledAssessmentScheduledFrequencyFragmentDoc}
${ScheduledAssessmentScheduledNotificationFragmentDoc}`;
export const ScheduledAssessmentScheduleUserFragmentDoc = gql`
    fragment ScheduledAssessmentScheduleUser on scheduled_assessment_schedule_users {
  __typename
  organization_id
  scheduled_assessment_schedule_id
  user_id
  is_subscriber
}
    `;
export const ScheduledAssessmentScheduleGroupFragmentDoc = gql`
    fragment ScheduledAssessmentScheduleGroup on scheduled_assessment_schedule_groups {
  __typename
  organization_id
  scheduled_assessment_schedule_id
  group_id
  is_subscriber
}
    `;
export const ScheduledAssessmentScheduleTeamFragmentDoc = gql`
    fragment ScheduledAssessmentScheduleTeam on scheduled_assessment_schedule_teams {
  __typename
  organization_id
  scheduled_assessment_schedule_id
  layer_team_id
  is_subscriber
}
    `;
export const ScheduledAssessmentScheduleLocationFragmentDoc = gql`
    fragment ScheduledAssessmentScheduleLocation on scheduled_assessment_schedule_locations {
  __typename
  organization_id
  location_id
  scheduled_assessment_schedule_id
}
    `;
export const ScheduledAssessmentScheduleForFormFragmentDoc = gql`
    fragment ScheduledAssessmentScheduleForForm on scheduled_assessment_schedule {
  ...ScheduledAssessmentScheduledRequiredFields
  name
  description
  status
  starts_at
  ends_on_type
  ends_on_value
  due_after_time
  due_after_unit
  timezone
  assessment_template_id
  location_filter_id
  ratification_groups
  users {
    ...ScheduledAssessmentScheduleUser
  }
  groups {
    ...ScheduledAssessmentScheduleGroup
  }
  teams {
    ...ScheduledAssessmentScheduleTeam
  }
  locations {
    ...ScheduledAssessmentScheduleLocation
  }
  frequency {
    ...ScheduledAssessmentScheduledFrequency
  }
  notifications {
    ...ScheduledAssessmentScheduledNotification
  }
}
    ${ScheduledAssessmentScheduledRequiredFieldsFragmentDoc}
${ScheduledAssessmentScheduleUserFragmentDoc}
${ScheduledAssessmentScheduleGroupFragmentDoc}
${ScheduledAssessmentScheduleTeamFragmentDoc}
${ScheduledAssessmentScheduleLocationFragmentDoc}
${ScheduledAssessmentScheduledFrequencyFragmentDoc}
${ScheduledAssessmentScheduledNotificationFragmentDoc}`;
export const ActionDeficiencyIdentifiersFragmentDoc = gql`
    fragment ActionDeficiencyIdentifiers on action_deficiencies {
  item_id
  node_id
  organization_id
  question_id
  task_action_id
}
    `;
export const TaskActionDeficienciesFragmentDoc = gql`
    fragment TaskActionDeficiencies on task_actions {
  action_deficiencies {
    ...ActionDeficiencyIdentifiers
  }
}
    ${ActionDeficiencyIdentifiersFragmentDoc}`;
export const TaskActionSummaryFragmentDoc = gql`
    fragment TaskActionSummary on task_actions {
  initial_cost
  ongoing_cost
  time_estimate
  due_date
  quantity
  action_deficiencies {
    ...ActionDeficiencyIdentifiers
    active_answer {
      criticality_value
    }
  }
}
    ${ActionDeficiencyIdentifiersFragmentDoc}`;
export const TaskActionIdentifiersFragmentDoc = gql`
    fragment TaskActionIdentifiers on task_actions {
  id
}
    `;
export const TaskActionTitleAndDescriptionFragmentDoc = gql`
    fragment TaskActionTitleAndDescription on task_actions {
  title
  description
}
    `;
export const TaskActionStatusFragmentDoc = gql`
    fragment TaskActionStatus on task_actions {
  status
}
    `;
export const TaskActionDueDateFragmentDoc = gql`
    fragment TaskActionDueDate on task_actions {
  due_date
}
    `;
export const TaskActionForSingleActionTaskFragmentDoc = gql`
    fragment TaskActionForSingleActionTask on task_actions {
  ...TaskActionIdentifiers
  ...TaskActionTitleAndDescription
  ...TaskActionStatus
  ...TaskActionDueDate
}
    ${TaskActionIdentifiersFragmentDoc}
${TaskActionTitleAndDescriptionFragmentDoc}
${TaskActionStatusFragmentDoc}
${TaskActionDueDateFragmentDoc}`;
export const TaskActionDeficienciesIdentifiersFragmentDoc = gql`
    fragment TaskActionDeficienciesIdentifiers on task_actions {
  ...TaskActionIdentifiers
  action_deficiencies {
    ...ActionDeficiencyIdentifiers
  }
}
    ${TaskActionIdentifiersFragmentDoc}
${ActionDeficiencyIdentifiersFragmentDoc}`;
export const TaskTaskActionsStatusFragmentDoc = gql`
    fragment TaskTaskActionsStatus on tasks {
  task_actions {
    status
  }
}
    `;
export const TaskDetailsTaskActionsListFragmentDoc = gql`
    fragment TaskDetailsTaskActionsList on tasks {
  task_actions {
    id
    item_id
  }
}
    `;
export const TaskActionPropertiesFragmentDoc = gql`
    fragment TaskActionProperties on task_actions {
  initial_cost
  ongoing_cost
  time_estimate
  quantity
  type
}
    `;
export const TaskActionQuantityFragmentDoc = gql`
    fragment TaskActionQuantity on task_actions {
  quantity
}
    `;
export const TaskActionAssigneeFragmentDoc = gql`
    fragment TaskActionAssignee on task_actions {
  assignee_id
}
    `;
export const TaskActionPlanDetailsFragmentDoc = gql`
    fragment TaskActionPlanDetails on task_actions {
  ...TaskActionQuantity
  ...TaskActionDueDate
  ...TaskActionAssignee
}
    ${TaskActionQuantityFragmentDoc}
${TaskActionDueDateFragmentDoc}
${TaskActionAssigneeFragmentDoc}`;
export const TaskActionDeficienciesCriticalityFragmentDoc = gql`
    fragment TaskActionDeficienciesCriticality on task_actions {
  action_deficiencies {
    ...ActionDeficiencyIdentifiers
    active_answer {
      ...activeAnswersKeyFields
      criticality_value
    }
  }
}
    ${ActionDeficiencyIdentifiersFragmentDoc}
${ActiveAnswersKeyFieldsFragmentDoc}`;
export const TaskSummeryInfoFragmentDoc = gql`
    fragment TaskSummeryInfo on tasks {
  task_actions {
    id
    ...TaskActionProperties
    ...TaskActionPlanDetails
    ...TaskActionDeficienciesCriticality
  }
}
    ${TaskActionPropertiesFragmentDoc}
${TaskActionPlanDetailsFragmentDoc}
${TaskActionDeficienciesCriticalityFragmentDoc}`;
export const TaskIdentifiersFragmentDoc = gql`
    fragment TaskIdentifiers on tasks {
  __typename
  id
  organization_id
  task_no
}
    `;
export const TaskPlanFragmentDoc = gql`
    fragment TaskPlan on tasks {
  due_date
  initial_cost
  ongoing_cost
  time_estimate
}
    `;
export const TaskLocationFragmentDoc = gql`
    fragment TaskLocation on tasks {
  node_id
}
    `;
export const ItemCategoryDetailsFragmentDoc = gql`
    fragment ItemCategoryDetails on item_categories {
  id
  name
  file {
    id
    filepath
    icon {
      id
    }
  }
}
    `;
export const TaskItemCategoryFragmentDoc = gql`
    fragment TaskItemCategory on tasks {
  item_category_id
  itemCategory {
    ...ItemCategoryDetails
  }
}
    ${ItemCategoryDetailsFragmentDoc}`;
export const TaskStatusFragmentDoc = gql`
    fragment TaskStatus on tasks {
  status
}
    `;
export const TaskDueDateFragmentDoc = gql`
    fragment TaskDueDate on tasks {
  due_date
}
    `;
export const TaskProjectFragmentDoc = gql`
    fragment TaskProject on tasks {
  project_id
  node_id
}
    `;
export const TaskNameAndDescriptionFragmentDoc = gql`
    fragment TaskNameAndDescription on tasks {
  name
  description
}
    `;
export const TaskActionInfoFragmentDoc = gql`
    fragment TaskActionInfo on task_actions {
  ...TaskActionIdentifiers
  ...TaskActionTitleAndDescription
  ...TaskActionStatus
}
    ${TaskActionIdentifiersFragmentDoc}
${TaskActionTitleAndDescriptionFragmentDoc}
${TaskActionStatusFragmentDoc}`;
export const TaskActionOfcFragmentDoc = gql`
    fragment TaskActionOfc on task_actions {
  library_consideration_option_id
  freeform_consideration_option_id
}
    `;
export const TaskActionFragmentDoc = gql`
    fragment TaskAction on task_actions {
  ...TaskActionInfo
  ...TaskActionProperties
  ...TaskActionPlanDetails
  ...TaskActionOfc
  ...TaskActionDeficienciesCriticality
  node_id
  task_no
}
    ${TaskActionInfoFragmentDoc}
${TaskActionPropertiesFragmentDoc}
${TaskActionPlanDetailsFragmentDoc}
${TaskActionOfcFragmentDoc}
${TaskActionDeficienciesCriticalityFragmentDoc}`;
export const TaskActionItemFragmentDoc = gql`
    fragment TaskActionItem on task_actions {
  item_id
  item {
    ...ItemDetails
  }
}
    ${ItemDetailsFragmentDoc}`;
export const TaskDetailsTaskActionsFragmentDoc = gql`
    fragment TaskDetailsTaskActions on tasks {
  task_actions {
    ...TaskAction
    ...TaskActionItem
  }
}
    ${TaskActionFragmentDoc}
${TaskActionItemFragmentDoc}`;
export const TaskDetailsFragmentDoc = gql`
    fragment TaskDetails on tasks {
  ...TaskIdentifiers
  ...TaskPlan
  ...TaskLocation
  ...TaskItemCategory
  ...TaskStatus
  ...TaskDueDate
  ...TaskProject
  ...TaskNameAndDescription
  ...TaskDetailsTaskActions
  priority
  created_at
}
    ${TaskIdentifiersFragmentDoc}
${TaskPlanFragmentDoc}
${TaskLocationFragmentDoc}
${TaskItemCategoryFragmentDoc}
${TaskStatusFragmentDoc}
${TaskDueDateFragmentDoc}
${TaskProjectFragmentDoc}
${TaskNameAndDescriptionFragmentDoc}
${TaskDetailsTaskActionsFragmentDoc}`;
export const TaskSelectionListItemFragmentDoc = gql`
    fragment TaskSelectionListItem on tasks {
  ...TaskIdentifiers
  ...TaskNameAndDescription
  ...TaskItemCategory
  ...TaskStatus
  ...TaskProject
  ...TaskLocation
  task_actions {
    ...TaskActionInfo
    ...TaskActionItem
    ...TaskActionAssignee
  }
}
    ${TaskIdentifiersFragmentDoc}
${TaskNameAndDescriptionFragmentDoc}
${TaskItemCategoryFragmentDoc}
${TaskStatusFragmentDoc}
${TaskProjectFragmentDoc}
${TaskLocationFragmentDoc}
${TaskActionInfoFragmentDoc}
${TaskActionItemFragmentDoc}
${TaskActionAssigneeFragmentDoc}`;
export const SingleActionTaskFragmentDoc = gql`
    fragment SingleActionTask on tasks {
  task_no
  ...TaskNameAndDescription
  ...TaskStatus
  ...TaskDueDate
}
    ${TaskNameAndDescriptionFragmentDoc}
${TaskStatusFragmentDoc}
${TaskDueDateFragmentDoc}`;
export const TaskActionOfcDetailsFragmentDoc = gql`
    fragment TaskActionOfcDetails on task_actions {
  library_consideration_option_id
  libraryConsiderationOption {
    __typename
    id
    name
    description
    type
    photo {
      ...image
    }
  }
  freeform_consideration_option_id
  freeformConsiderationOption {
    __typename
    id
    title
    description
    type
  }
}
    ${ImageFragmentDoc}`;
export const ActiveAnswerQuestionInfoFragmentDoc = gql`
    fragment ActiveAnswerQuestionInfo on active_answers {
  question {
    ...Question
    ...QuestionScenarios
    standards {
      ...scenarioQuestionSetStandard
    }
  }
}
    ${QuestionFragmentDoc}
${QuestionScenariosFragmentDoc}
${ScenarioQuestionSetStandardFragmentDoc}`;
export const ActiveAnswerItemFragmentDoc = gql`
    fragment ActiveAnswerItem on active_answers {
  item {
    ...ItemDetails
    files {
      id
      file {
        ...image
      }
    }
  }
}
    ${ItemDetailsFragmentDoc}
${ImageFragmentDoc}`;
export const ActiveAnswerForTaskActionFragmentDoc = gql`
    fragment ActiveAnswerForTaskAction on active_answers {
  ...activeAnswersKeyFields
  ...activeAnswerOptions
  ...ActiveAnswerCriticalityInfo
  ...ActiveAnswerAttachments
  ...ActiveAnswerQuestionInfo
  ...ActiveAnswerResponse
  ...ActiveAnswerItem
}
    ${ActiveAnswersKeyFieldsFragmentDoc}
${ActiveAnswerOptionsFragmentDoc}
${ActiveAnswerCriticalityInfoFragmentDoc}
${ActiveAnswerAttachmentsFragmentDoc}
${ActiveAnswerQuestionInfoFragmentDoc}
${ActiveAnswerResponseFragmentDoc}
${ActiveAnswerItemFragmentDoc}`;
export const TaskActionDeficienciesAndActiveAnswerFragmentDoc = gql`
    fragment TaskActionDeficienciesAndActiveAnswer on task_actions {
  action_deficiencies {
    ...ActionDeficiencyIdentifiers
    active_answer {
      ...ActiveAnswerForTaskAction
    }
  }
}
    ${ActionDeficiencyIdentifiersFragmentDoc}
${ActiveAnswerForTaskActionFragmentDoc}`;
export const TaskActionTaskDetailsFragmentDoc = gql`
    fragment TaskActionTaskDetails on task_actions {
  task_no
  task {
    task_no
    ...TaskNameAndDescription
    ...TaskStatus
  }
}
    ${TaskNameAndDescriptionFragmentDoc}
${TaskStatusFragmentDoc}`;
export const TaskActionFilesIdentifiersFragmentDoc = gql`
    fragment TaskActionFilesIdentifiers on task_actions_files {
  __typename
  organization_id
  task_action_id
  file_id
}
    `;
export const TaskActionFilesFragmentDoc = gql`
    fragment TaskActionFiles on task_actions_files {
  ...TaskActionFilesIdentifiers
  file {
    ...image
  }
}
    ${TaskActionFilesIdentifiersFragmentDoc}
${ImageFragmentDoc}`;
export const TaskActionDetailsFilesFragmentDoc = gql`
    fragment TaskActionDetailsFiles on task_actions {
  id
  task_actions_files {
    ...TaskActionFiles
  }
}
    ${TaskActionFilesFragmentDoc}`;
export const TaskActionDetailsFragmentDoc = gql`
    fragment TaskActionDetails on task_actions {
  node_id
  ...TaskActionInfo
  ...TaskActionProperties
  ...TaskActionPlanDetails
  ...TaskActionAssignee
  ...TaskActionOfcDetails
  ...TaskActionDeficienciesAndActiveAnswer
  ...TaskActionItem
  ...TaskActionTaskDetails
  ...TaskActionDetailsFiles
}
    ${TaskActionInfoFragmentDoc}
${TaskActionPropertiesFragmentDoc}
${TaskActionPlanDetailsFragmentDoc}
${TaskActionAssigneeFragmentDoc}
${TaskActionOfcDetailsFragmentDoc}
${TaskActionDeficienciesAndActiveAnswerFragmentDoc}
${TaskActionItemFragmentDoc}
${TaskActionTaskDetailsFragmentDoc}
${TaskActionDetailsFilesFragmentDoc}`;
export const SingleActionTaskDetailsFragmentDoc = gql`
    fragment SingleActionTaskDetails on tasks {
  ...TaskIdentifiers
  ...TaskLocation
  ...TaskItemCategory
  ...TaskProject
  task_actions {
    ...TaskActionDetails
  }
}
    ${TaskIdentifiersFragmentDoc}
${TaskLocationFragmentDoc}
${TaskItemCategoryFragmentDoc}
${TaskProjectFragmentDoc}
${TaskActionDetailsFragmentDoc}`;
export const UserNameFragmentDoc = gql`
    fragment UserName on users {
  __typename
  id
  first_name
  last_name
  email
}
    `;
export const TemplateDetailsFragmentDoc = gql`
    fragment templateDetails on v2_assessment_templates {
  id
  name
  questionSets {
    scenario_question_set_id
  }
  physicalInspectionQuestions {
    question_id
    deficient_answer
    ordinal
    item_category_id
    item_category_name
    question_text
    layer_id
    criticality
    layer_tag_id
    is_required
  }
  inspectionQuestionnaireQuestions {
    question_id
    deficient_answer
    ordinal
    item_category_id
    item_category_name
    question_text
    layer_id
    criticality
    layer_tag_id
    is_required
  }
}
    `;
export const AssessmentTemplateInfoForAssessmentCreationFragmentDoc = gql`
    fragment assessmentTemplateInfoForAssessmentCreation on v2_assessment_templates {
  id
  organization_id
  name
  description
  scoredLayers {
    id
    name
  }
  allPreviousTemplates {
    id
  }
  questionSets {
    questionSet {
      id
      tags {
        layer_tag_id
        layer_tag_name
      }
      scenario {
        organization_id
        id
        name
        description
      }
    }
  }
  allAssessments: assessments_aggregate {
    aggregate {
      count
    }
  }
  inProgressAssessments: assessments_aggregate(
    where: {state: {_eq: "InProgress"}}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const VirtualAssessmentItemQuestionAnswerIdentifiersFragmentDoc = gql`
    fragment VirtualAssessmentItemQuestionAnswerIdentifiers on virtual_assessment_item_question_answer {
  __typename
  assessment_item_id
  question_id
}
    `;
export const VirtualAssessmentItemQuestionAnswerOptionsFragmentDoc = gql`
    fragment virtualAssessmentItemQuestionAnswerOptions on virtual_assessment_item_question_answer {
  freeform_options {
    id
    type
    title
    description
    created_at
  }
  library_options {
    id
    created_at
    libraryConsiderationOption {
      id
      type
      organization_id
      description
      name
      photo {
        id
        filepath
      }
      nodes {
        ...considerationOptionNodeKeyFields
      }
    }
  }
}
    ${ConsiderationOptionNodeKeyFieldsFragmentDoc}`;
export const VirtualAnswerQuestionOptionsFragmentDoc = gql`
    fragment virtualAnswerQuestionOptions on virtual_assessment_item_question_answer {
  freeform_options {
    id
    type
    title
    description
    created_at
  }
  library_options {
    id
    created_at
    libraryConsiderationOption {
      id
      type
      organization_id
      description
      name
      photo {
        id
        filepath
      }
      nodes {
        ...considerationOptionNodeKeyFields
      }
    }
  }
}
    ${ConsiderationOptionNodeKeyFieldsFragmentDoc}`;
export const VirtualLocationAnswersIdentifiersFragmentDoc = gql`
    fragment VirtualLocationAnswersIdentifiers on virtual_location_answers {
  __typename
  answer_id
}
    `;
export const LocationAnswersFragmentDoc = gql`
    fragment locationAnswers on virtual_location_answers {
  ...VirtualLocationAnswersIdentifiers
  item_id
  item_category_id
  item_category_name
  location_id
  location_name
  item_name
  question_id
  question_text
  description
  criticality_value
  response
  cost
  deficient_response
  is_deficient
  is_accepted
  is_flagged
  is_interview_item
  item_is_present
  item_status
  description
  partial_compliance_multiplier
  answered_at
  files {
    id
    file {
      ...image
    }
  }
  location {
    layer {
      name
    }
  }
  item {
    files(order_by: {file: {updated_at: desc}}) {
      file {
        ...image
      }
    }
    item_category {
      file {
        filepath
      }
    }
  }
  scenarioQuestionSets(
    where: {questionSet: {primaryQuestionSets: {scenario_question_set_id: {_is_null: false}}}}
  ) {
    ...ScenarioQuestionSetQuestionIdentifiers
    criticality
    is_required
    questionSet {
      id
      scenario {
        id
        name
        scenario_type
      }
      standards {
        ...scenarioQuestionSetStandard
      }
    }
  }
}
    ${VirtualLocationAnswersIdentifiersFragmentDoc}
${ImageFragmentDoc}
${ScenarioQuestionSetQuestionIdentifiersFragmentDoc}
${ScenarioQuestionSetStandardFragmentDoc}`;
export const VirtualLocationAnswerOptionsFragmentDoc = gql`
    fragment virtualLocationAnswerOptions on virtual_location_answers {
  freeform_options {
    id
    type
    title
    description
    created_at
  }
  library_options {
    id
    created_at
    libraryConsiderationOption {
      id
      type
      organization_id
      description
      name
      photo {
        id
        filepath
      }
      nodes {
        ...considerationOptionNodeKeyFields
      }
    }
  }
}
    ${ConsiderationOptionNodeKeyFieldsFragmentDoc}`;
export const LayerTeamFragmentDoc = gql`
    fragment LayerTeam on layer_teams {
  __typename
  id
  layer_id
  organization_id
  name
  description
}
    `;
export const LayerTeamsFragmentDoc = gql`
    fragment LayerTeams on layers {
  teams {
    ...LayerTeam
  }
}
    ${LayerTeamFragmentDoc}`;
export const LayerCoreFragmentDoc = gql`
    fragment LayerCore on layers {
  __typename
  id
}
    `;
export const LayerTagPropertyConfigFragmentDoc = gql`
    fragment LayerTagPropertyConfig on layer_tag_property_config {
  __typename
  id
  group_id
  name
  ordinal
  kind
  description
  options
  locationPropertyValues {
    config_id
    organization_id
    location_id
    value
  }
}
    `;
export const LayerTagPropertyGroupsAndAllValuesFragmentDoc = gql`
    fragment LayerTagPropertyGroupsAndAllValues on layer_tag_property_group {
  __typename
  id
  layer_tag_id
  organization_id
  name
  description
  ordinal
  configs {
    ...LayerTagPropertyConfig
  }
  layerTag {
    __typename
    id
    type_name
    description
  }
}
    ${LayerTagPropertyConfigFragmentDoc}`;
export const LayerPropertyGroupsFragmentDoc = gql`
    fragment LayerPropertyGroups on layers {
  propertyGroups {
    ...LayerTagPropertyGroupsAndAllValues
  }
}
    ${LayerTagPropertyGroupsAndAllValuesFragmentDoc}`;
export const LocationDetailCoreFragmentDoc = gql`
    fragment LocationDetailCore on nodes {
  __typename
  id
  organization_id
}
    `;
export const LocationDetailLayerCoreFragmentDoc = gql`
    fragment LocationDetailLayerCore on layers {
  __typename
  id
}
    `;
export const LocationDetailLayerTeamFragmentDoc = gql`
    fragment LocationDetailLayerTeam on layer_teams {
  __typename
  id
  organization_id
  name
  description
}
    `;
export const LocationDetailLayerTeamsFragmentDoc = gql`
    fragment LocationDetailLayerTeams on layers {
  teams {
    ...LocationDetailLayerTeam
  }
}
    ${LocationDetailLayerTeamFragmentDoc}`;
export const LocationDetailLayerFragmentDoc = gql`
    fragment LocationDetailLayer on nodes {
  layer {
    ...LocationDetailLayerCore
    ...LocationDetailLayerTeams
  }
}
    ${LocationDetailLayerCoreFragmentDoc}
${LocationDetailLayerTeamsFragmentDoc}`;
export const LocationDetailLayerTeamUserFragmentDoc = gql`
    fragment LocationDetailLayerTeamUser on location_team_users {
  __typename
  user_id
  organization_id
  location_id
  layer_team_id
}
    `;
export const LocationDetailLayerTeamUsersFragmentDoc = gql`
    fragment LocationDetailLayerTeamUsers on nodes {
  layerTeamUsers {
    ...LocationDetailLayerTeamUser
  }
}
    ${LocationDetailLayerTeamUserFragmentDoc}`;
export const LocationDetailUserManagerAssignmentFragmentDoc = gql`
    fragment LocationDetailUserManagerAssignment on location_manager_inherited_assignments {
  __typename
  location_id
  user_id
  organization_id
  type
}
    `;
export const LocationDetailUserManagerAssignmentsFragmentDoc = gql`
    fragment LocationDetailUserManagerAssignments on nodes {
  userManagerAssignments {
    ...LocationDetailUserManagerAssignment
  }
}
    ${LocationDetailUserManagerAssignmentFragmentDoc}`;
export const GetAppWrapperDataDocument = gql`
    query getAppWrapperData($organizationId: uuid!) {
  organizations_by_pk(id: $organizationId) {
    ...OrgContextData
    ...OrgUsers
    ...OrgUserGroups
    ...OrganizationProjectFields
    ...OrgItemCategories
  }
}
    ${OrgContextDataFragmentDoc}
${OrgUsersFragmentDoc}
${OrgUserGroupsFragmentDoc}
${OrganizationProjectFieldsFragmentDoc}
${OrgItemCategoriesFragmentDoc}`;

/**
 * __useGetAppWrapperDataQuery__
 *
 * To run a query within a React component, call `useGetAppWrapperDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppWrapperDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppWrapperDataQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetAppWrapperDataQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAppWrapperDataQuery, Types.GetAppWrapperDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAppWrapperDataQuery, Types.GetAppWrapperDataQueryVariables>(GetAppWrapperDataDocument, options);
      }
export function useGetAppWrapperDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAppWrapperDataQuery, Types.GetAppWrapperDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAppWrapperDataQuery, Types.GetAppWrapperDataQueryVariables>(GetAppWrapperDataDocument, options);
        }
export type GetAppWrapperDataQueryHookResult = ReturnType<typeof useGetAppWrapperDataQuery>;
export type GetAppWrapperDataLazyQueryHookResult = ReturnType<typeof useGetAppWrapperDataLazyQuery>;
export type GetAppWrapperDataQueryResult = Apollo.QueryResult<Types.GetAppWrapperDataQuery, Types.GetAppWrapperDataQueryVariables>;
export const InsertLayerTagDocument = gql`
    mutation insertLayerTag($layerId: uuid!, $name: citext!, $description: String) {
  insert_layer_tag_one(
    object: {layer_id: $layerId, type_name: $name, description: $description}
  ) {
    ...LayerTag
  }
}
    ${LayerTagFragmentDoc}`;
export type InsertLayerTagMutationFn = Apollo.MutationFunction<Types.InsertLayerTagMutation, Types.InsertLayerTagMutationVariables>;

/**
 * __useInsertLayerTagMutation__
 *
 * To run a mutation, you first call `useInsertLayerTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLayerTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLayerTagMutation, { data, loading, error }] = useInsertLayerTagMutation({
 *   variables: {
 *      layerId: // value for 'layerId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useInsertLayerTagMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertLayerTagMutation, Types.InsertLayerTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertLayerTagMutation, Types.InsertLayerTagMutationVariables>(InsertLayerTagDocument, options);
      }
export type InsertLayerTagMutationHookResult = ReturnType<typeof useInsertLayerTagMutation>;
export type InsertLayerTagMutationResult = Apollo.MutationResult<Types.InsertLayerTagMutation>;
export type InsertLayerTagMutationOptions = Apollo.BaseMutationOptions<Types.InsertLayerTagMutation, Types.InsertLayerTagMutationVariables>;
export const UpdateLayerTagDocument = gql`
    mutation updateLayerTag($id: Int!, $layerId: uuid!, $organizationId: uuid!, $input: layer_tag_set_input!) {
  update_layer_tag_by_pk(
    pk_columns: {id: $id, layer_id: $layerId, organization_id: $organizationId}
    _set: $input
  ) {
    __typename
    id
    type_name
    description
  }
}
    `;
export type UpdateLayerTagMutationFn = Apollo.MutationFunction<Types.UpdateLayerTagMutation, Types.UpdateLayerTagMutationVariables>;

/**
 * __useUpdateLayerTagMutation__
 *
 * To run a mutation, you first call `useUpdateLayerTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLayerTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLayerTagMutation, { data, loading, error }] = useUpdateLayerTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      layerId: // value for 'layerId'
 *      organizationId: // value for 'organizationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLayerTagMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateLayerTagMutation, Types.UpdateLayerTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateLayerTagMutation, Types.UpdateLayerTagMutationVariables>(UpdateLayerTagDocument, options);
      }
export type UpdateLayerTagMutationHookResult = ReturnType<typeof useUpdateLayerTagMutation>;
export type UpdateLayerTagMutationResult = Apollo.MutationResult<Types.UpdateLayerTagMutation>;
export type UpdateLayerTagMutationOptions = Apollo.BaseMutationOptions<Types.UpdateLayerTagMutation, Types.UpdateLayerTagMutationVariables>;
export const DeleteLayerTagDocument = gql`
    mutation deleteLayerTag($id: Int!, $layerId: uuid!, $organizationId: uuid!) {
  delete_layer_tag_by_pk(
    id: $id
    layer_id: $layerId
    organization_id: $organizationId
  ) {
    __typename
    id
  }
}
    `;
export type DeleteLayerTagMutationFn = Apollo.MutationFunction<Types.DeleteLayerTagMutation, Types.DeleteLayerTagMutationVariables>;

/**
 * __useDeleteLayerTagMutation__
 *
 * To run a mutation, you first call `useDeleteLayerTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLayerTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLayerTagMutation, { data, loading, error }] = useDeleteLayerTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      layerId: // value for 'layerId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useDeleteLayerTagMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteLayerTagMutation, Types.DeleteLayerTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteLayerTagMutation, Types.DeleteLayerTagMutationVariables>(DeleteLayerTagDocument, options);
      }
export type DeleteLayerTagMutationHookResult = ReturnType<typeof useDeleteLayerTagMutation>;
export type DeleteLayerTagMutationResult = Apollo.MutationResult<Types.DeleteLayerTagMutation>;
export type DeleteLayerTagMutationOptions = Apollo.BaseMutationOptions<Types.DeleteLayerTagMutation, Types.DeleteLayerTagMutationVariables>;
export const CreateLayerTeamDocument = gql`
    mutation createLayerTeam($layerId: uuid!, $name: String!, $description: String) {
  insert_layer_teams_one(
    object: {layer_id: $layerId, name: $name, description: $description}
  ) {
    ...LayerTeam
  }
}
    ${LayerTeamFragmentDoc}`;
export type CreateLayerTeamMutationFn = Apollo.MutationFunction<Types.CreateLayerTeamMutation, Types.CreateLayerTeamMutationVariables>;

/**
 * __useCreateLayerTeamMutation__
 *
 * To run a mutation, you first call `useCreateLayerTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLayerTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLayerTeamMutation, { data, loading, error }] = useCreateLayerTeamMutation({
 *   variables: {
 *      layerId: // value for 'layerId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateLayerTeamMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateLayerTeamMutation, Types.CreateLayerTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateLayerTeamMutation, Types.CreateLayerTeamMutationVariables>(CreateLayerTeamDocument, options);
      }
export type CreateLayerTeamMutationHookResult = ReturnType<typeof useCreateLayerTeamMutation>;
export type CreateLayerTeamMutationResult = Apollo.MutationResult<Types.CreateLayerTeamMutation>;
export type CreateLayerTeamMutationOptions = Apollo.BaseMutationOptions<Types.CreateLayerTeamMutation, Types.CreateLayerTeamMutationVariables>;
export const UpdateLayerTeamDocument = gql`
    mutation updateLayerTeam($layerTeamId: Int!, $organizationId: uuid!, $name: String!, $description: String) {
  update_layer_teams_by_pk(
    pk_columns: {id: $layerTeamId, organization_id: $organizationId}
    _set: {name: $name, description: $description}
  ) {
    ...LayerTeam
  }
}
    ${LayerTeamFragmentDoc}`;
export type UpdateLayerTeamMutationFn = Apollo.MutationFunction<Types.UpdateLayerTeamMutation, Types.UpdateLayerTeamMutationVariables>;

/**
 * __useUpdateLayerTeamMutation__
 *
 * To run a mutation, you first call `useUpdateLayerTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLayerTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLayerTeamMutation, { data, loading, error }] = useUpdateLayerTeamMutation({
 *   variables: {
 *      layerTeamId: // value for 'layerTeamId'
 *      organizationId: // value for 'organizationId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateLayerTeamMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateLayerTeamMutation, Types.UpdateLayerTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateLayerTeamMutation, Types.UpdateLayerTeamMutationVariables>(UpdateLayerTeamDocument, options);
      }
export type UpdateLayerTeamMutationHookResult = ReturnType<typeof useUpdateLayerTeamMutation>;
export type UpdateLayerTeamMutationResult = Apollo.MutationResult<Types.UpdateLayerTeamMutation>;
export type UpdateLayerTeamMutationOptions = Apollo.BaseMutationOptions<Types.UpdateLayerTeamMutation, Types.UpdateLayerTeamMutationVariables>;
export const DeleteLayerTeamDocument = gql`
    mutation deleteLayerTeam($id: Int!, $organizationId: uuid!) {
  delete_layer_teams_by_pk(id: $id, organization_id: $organizationId) {
    __typename
    id
    organization_id
  }
}
    `;
export type DeleteLayerTeamMutationFn = Apollo.MutationFunction<Types.DeleteLayerTeamMutation, Types.DeleteLayerTeamMutationVariables>;

/**
 * __useDeleteLayerTeamMutation__
 *
 * To run a mutation, you first call `useDeleteLayerTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLayerTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLayerTeamMutation, { data, loading, error }] = useDeleteLayerTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useDeleteLayerTeamMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteLayerTeamMutation, Types.DeleteLayerTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteLayerTeamMutation, Types.DeleteLayerTeamMutationVariables>(DeleteLayerTeamDocument, options);
      }
export type DeleteLayerTeamMutationHookResult = ReturnType<typeof useDeleteLayerTeamMutation>;
export type DeleteLayerTeamMutationResult = Apollo.MutationResult<Types.DeleteLayerTeamMutation>;
export type DeleteLayerTeamMutationOptions = Apollo.BaseMutationOptions<Types.DeleteLayerTeamMutation, Types.DeleteLayerTeamMutationVariables>;
export const DeleteLayerTagPropertyConfigDocument = gql`
    mutation deleteLayerTagPropertyConfig($id: bigint!) {
  delete_layer_tag_property_config_by_pk(id: $id) {
    __typename
    id
    group_id
  }
}
    `;
export type DeleteLayerTagPropertyConfigMutationFn = Apollo.MutationFunction<Types.DeleteLayerTagPropertyConfigMutation, Types.DeleteLayerTagPropertyConfigMutationVariables>;

/**
 * __useDeleteLayerTagPropertyConfigMutation__
 *
 * To run a mutation, you first call `useDeleteLayerTagPropertyConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLayerTagPropertyConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLayerTagPropertyConfigMutation, { data, loading, error }] = useDeleteLayerTagPropertyConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLayerTagPropertyConfigMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteLayerTagPropertyConfigMutation, Types.DeleteLayerTagPropertyConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteLayerTagPropertyConfigMutation, Types.DeleteLayerTagPropertyConfigMutationVariables>(DeleteLayerTagPropertyConfigDocument, options);
      }
export type DeleteLayerTagPropertyConfigMutationHookResult = ReturnType<typeof useDeleteLayerTagPropertyConfigMutation>;
export type DeleteLayerTagPropertyConfigMutationResult = Apollo.MutationResult<Types.DeleteLayerTagPropertyConfigMutation>;
export type DeleteLayerTagPropertyConfigMutationOptions = Apollo.BaseMutationOptions<Types.DeleteLayerTagPropertyConfigMutation, Types.DeleteLayerTagPropertyConfigMutationVariables>;
export const InsertLayerTagConfigDocument = gql`
    mutation insertLayerTagConfig($input: layer_tag_property_config_insert_input!) {
  insert_layer_tag_property_config_one(object: $input) {
    ...LayerTagPropertyConfig
  }
}
    ${LayerTagPropertyConfigFragmentDoc}`;
export type InsertLayerTagConfigMutationFn = Apollo.MutationFunction<Types.InsertLayerTagConfigMutation, Types.InsertLayerTagConfigMutationVariables>;

/**
 * __useInsertLayerTagConfigMutation__
 *
 * To run a mutation, you first call `useInsertLayerTagConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLayerTagConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLayerTagConfigMutation, { data, loading, error }] = useInsertLayerTagConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsertLayerTagConfigMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertLayerTagConfigMutation, Types.InsertLayerTagConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertLayerTagConfigMutation, Types.InsertLayerTagConfigMutationVariables>(InsertLayerTagConfigDocument, options);
      }
export type InsertLayerTagConfigMutationHookResult = ReturnType<typeof useInsertLayerTagConfigMutation>;
export type InsertLayerTagConfigMutationResult = Apollo.MutationResult<Types.InsertLayerTagConfigMutation>;
export type InsertLayerTagConfigMutationOptions = Apollo.BaseMutationOptions<Types.InsertLayerTagConfigMutation, Types.InsertLayerTagConfigMutationVariables>;
export const UpdateLayerTagConfigDocument = gql`
    mutation updateLayerTagConfig($id: bigint!, $updates: layer_tag_property_config_set_input!) {
  update_layer_tag_property_config_by_pk(pk_columns: {id: $id}, _set: $updates) {
    id
    kind
    name
    description
    ordinal
    options
  }
}
    `;
export type UpdateLayerTagConfigMutationFn = Apollo.MutationFunction<Types.UpdateLayerTagConfigMutation, Types.UpdateLayerTagConfigMutationVariables>;

/**
 * __useUpdateLayerTagConfigMutation__
 *
 * To run a mutation, you first call `useUpdateLayerTagConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLayerTagConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLayerTagConfigMutation, { data, loading, error }] = useUpdateLayerTagConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateLayerTagConfigMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateLayerTagConfigMutation, Types.UpdateLayerTagConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateLayerTagConfigMutation, Types.UpdateLayerTagConfigMutationVariables>(UpdateLayerTagConfigDocument, options);
      }
export type UpdateLayerTagConfigMutationHookResult = ReturnType<typeof useUpdateLayerTagConfigMutation>;
export type UpdateLayerTagConfigMutationResult = Apollo.MutationResult<Types.UpdateLayerTagConfigMutation>;
export type UpdateLayerTagConfigMutationOptions = Apollo.BaseMutationOptions<Types.UpdateLayerTagConfigMutation, Types.UpdateLayerTagConfigMutationVariables>;
export const BulkUpdateLayerTagPropertyConfigDocument = gql`
    mutation bulkUpdateLayerTagPropertyConfig($updates: [layer_tag_property_config_updates!]!) {
  update_layer_tag_property_config_many(updates: $updates) {
    returning {
      organization_id
      group_id
      id
      ordinal
    }
  }
}
    `;
export type BulkUpdateLayerTagPropertyConfigMutationFn = Apollo.MutationFunction<Types.BulkUpdateLayerTagPropertyConfigMutation, Types.BulkUpdateLayerTagPropertyConfigMutationVariables>;

/**
 * __useBulkUpdateLayerTagPropertyConfigMutation__
 *
 * To run a mutation, you first call `useBulkUpdateLayerTagPropertyConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateLayerTagPropertyConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateLayerTagPropertyConfigMutation, { data, loading, error }] = useBulkUpdateLayerTagPropertyConfigMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useBulkUpdateLayerTagPropertyConfigMutation(baseOptions?: Apollo.MutationHookOptions<Types.BulkUpdateLayerTagPropertyConfigMutation, Types.BulkUpdateLayerTagPropertyConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.BulkUpdateLayerTagPropertyConfigMutation, Types.BulkUpdateLayerTagPropertyConfigMutationVariables>(BulkUpdateLayerTagPropertyConfigDocument, options);
      }
export type BulkUpdateLayerTagPropertyConfigMutationHookResult = ReturnType<typeof useBulkUpdateLayerTagPropertyConfigMutation>;
export type BulkUpdateLayerTagPropertyConfigMutationResult = Apollo.MutationResult<Types.BulkUpdateLayerTagPropertyConfigMutation>;
export type BulkUpdateLayerTagPropertyConfigMutationOptions = Apollo.BaseMutationOptions<Types.BulkUpdateLayerTagPropertyConfigMutation, Types.BulkUpdateLayerTagPropertyConfigMutationVariables>;
export const UpdateLayerTagPropertyGroupDocument = gql`
    mutation updateLayerTagPropertyGroup($id: bigint!, $organizationId: uuid!, $updates: layer_tag_property_group_set_input!) {
  update_layer_tag_property_group_by_pk(
    pk_columns: {id: $id, organization_id: $organizationId}
    _set: $updates
  ) {
    id
    name
    description
  }
}
    `;
export type UpdateLayerTagPropertyGroupMutationFn = Apollo.MutationFunction<Types.UpdateLayerTagPropertyGroupMutation, Types.UpdateLayerTagPropertyGroupMutationVariables>;

/**
 * __useUpdateLayerTagPropertyGroupMutation__
 *
 * To run a mutation, you first call `useUpdateLayerTagPropertyGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLayerTagPropertyGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLayerTagPropertyGroupMutation, { data, loading, error }] = useUpdateLayerTagPropertyGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      organizationId: // value for 'organizationId'
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateLayerTagPropertyGroupMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateLayerTagPropertyGroupMutation, Types.UpdateLayerTagPropertyGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateLayerTagPropertyGroupMutation, Types.UpdateLayerTagPropertyGroupMutationVariables>(UpdateLayerTagPropertyGroupDocument, options);
      }
export type UpdateLayerTagPropertyGroupMutationHookResult = ReturnType<typeof useUpdateLayerTagPropertyGroupMutation>;
export type UpdateLayerTagPropertyGroupMutationResult = Apollo.MutationResult<Types.UpdateLayerTagPropertyGroupMutation>;
export type UpdateLayerTagPropertyGroupMutationOptions = Apollo.BaseMutationOptions<Types.UpdateLayerTagPropertyGroupMutation, Types.UpdateLayerTagPropertyGroupMutationVariables>;
export const DeleteLayerTagPropertyGroupDocument = gql`
    mutation deleteLayerTagPropertyGroup($organizationId: uuid!, $id: bigint!) {
  delete_layer_tag_property_group_by_pk(id: $id, organization_id: $organizationId) {
    id
  }
}
    `;
export type DeleteLayerTagPropertyGroupMutationFn = Apollo.MutationFunction<Types.DeleteLayerTagPropertyGroupMutation, Types.DeleteLayerTagPropertyGroupMutationVariables>;

/**
 * __useDeleteLayerTagPropertyGroupMutation__
 *
 * To run a mutation, you first call `useDeleteLayerTagPropertyGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLayerTagPropertyGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLayerTagPropertyGroupMutation, { data, loading, error }] = useDeleteLayerTagPropertyGroupMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLayerTagPropertyGroupMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteLayerTagPropertyGroupMutation, Types.DeleteLayerTagPropertyGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteLayerTagPropertyGroupMutation, Types.DeleteLayerTagPropertyGroupMutationVariables>(DeleteLayerTagPropertyGroupDocument, options);
      }
export type DeleteLayerTagPropertyGroupMutationHookResult = ReturnType<typeof useDeleteLayerTagPropertyGroupMutation>;
export type DeleteLayerTagPropertyGroupMutationResult = Apollo.MutationResult<Types.DeleteLayerTagPropertyGroupMutation>;
export type DeleteLayerTagPropertyGroupMutationOptions = Apollo.BaseMutationOptions<Types.DeleteLayerTagPropertyGroupMutation, Types.DeleteLayerTagPropertyGroupMutationVariables>;
export const InsertLayerTagPropertyGroupDocument = gql`
    mutation insertLayerTagPropertyGroup($input: layer_tag_property_group_insert_input!) {
  insert_layer_tag_property_group_one(object: $input) {
    ...LayerTagPropertyGroupsAndAllValues
  }
}
    ${LayerTagPropertyGroupsAndAllValuesFragmentDoc}`;
export type InsertLayerTagPropertyGroupMutationFn = Apollo.MutationFunction<Types.InsertLayerTagPropertyGroupMutation, Types.InsertLayerTagPropertyGroupMutationVariables>;

/**
 * __useInsertLayerTagPropertyGroupMutation__
 *
 * To run a mutation, you first call `useInsertLayerTagPropertyGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLayerTagPropertyGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLayerTagPropertyGroupMutation, { data, loading, error }] = useInsertLayerTagPropertyGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsertLayerTagPropertyGroupMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertLayerTagPropertyGroupMutation, Types.InsertLayerTagPropertyGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertLayerTagPropertyGroupMutation, Types.InsertLayerTagPropertyGroupMutationVariables>(InsertLayerTagPropertyGroupDocument, options);
      }
export type InsertLayerTagPropertyGroupMutationHookResult = ReturnType<typeof useInsertLayerTagPropertyGroupMutation>;
export type InsertLayerTagPropertyGroupMutationResult = Apollo.MutationResult<Types.InsertLayerTagPropertyGroupMutation>;
export type InsertLayerTagPropertyGroupMutationOptions = Apollo.BaseMutationOptions<Types.InsertLayerTagPropertyGroupMutation, Types.InsertLayerTagPropertyGroupMutationVariables>;
export const BulkUpdateLayerTagPropertyGroupDocument = gql`
    mutation bulkUpdateLayerTagPropertyGroup($updates: [layer_tag_property_group_updates!]!) {
  update_layer_tag_property_group_many(updates: $updates) {
    returning {
      __typename
      id
      name
      ordinal
      description
      organization_id
    }
  }
}
    `;
export type BulkUpdateLayerTagPropertyGroupMutationFn = Apollo.MutationFunction<Types.BulkUpdateLayerTagPropertyGroupMutation, Types.BulkUpdateLayerTagPropertyGroupMutationVariables>;

/**
 * __useBulkUpdateLayerTagPropertyGroupMutation__
 *
 * To run a mutation, you first call `useBulkUpdateLayerTagPropertyGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateLayerTagPropertyGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateLayerTagPropertyGroupMutation, { data, loading, error }] = useBulkUpdateLayerTagPropertyGroupMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useBulkUpdateLayerTagPropertyGroupMutation(baseOptions?: Apollo.MutationHookOptions<Types.BulkUpdateLayerTagPropertyGroupMutation, Types.BulkUpdateLayerTagPropertyGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.BulkUpdateLayerTagPropertyGroupMutation, Types.BulkUpdateLayerTagPropertyGroupMutationVariables>(BulkUpdateLayerTagPropertyGroupDocument, options);
      }
export type BulkUpdateLayerTagPropertyGroupMutationHookResult = ReturnType<typeof useBulkUpdateLayerTagPropertyGroupMutation>;
export type BulkUpdateLayerTagPropertyGroupMutationResult = Apollo.MutationResult<Types.BulkUpdateLayerTagPropertyGroupMutation>;
export type BulkUpdateLayerTagPropertyGroupMutationOptions = Apollo.BaseMutationOptions<Types.BulkUpdateLayerTagPropertyGroupMutation, Types.BulkUpdateLayerTagPropertyGroupMutationVariables>;
export const GetLayerDetailsDocument = gql`
    query getLayerDetails($layerId: uuid!) {
  layers_by_pk(id: $layerId) {
    ...LayerCore
    ...LayerTeams
    ...LayerTags
    ...LayerPropertyGroups
  }
}
    ${LayerCoreFragmentDoc}
${LayerTeamsFragmentDoc}
${LayerTagsFragmentDoc}
${LayerPropertyGroupsFragmentDoc}`;

/**
 * __useGetLayerDetailsQuery__
 *
 * To run a query within a React component, call `useGetLayerDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLayerDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLayerDetailsQuery({
 *   variables: {
 *      layerId: // value for 'layerId'
 *   },
 * });
 */
export function useGetLayerDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetLayerDetailsQuery, Types.GetLayerDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLayerDetailsQuery, Types.GetLayerDetailsQueryVariables>(GetLayerDetailsDocument, options);
      }
export function useGetLayerDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLayerDetailsQuery, Types.GetLayerDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLayerDetailsQuery, Types.GetLayerDetailsQueryVariables>(GetLayerDetailsDocument, options);
        }
export type GetLayerDetailsQueryHookResult = ReturnType<typeof useGetLayerDetailsQuery>;
export type GetLayerDetailsLazyQueryHookResult = ReturnType<typeof useGetLayerDetailsLazyQuery>;
export type GetLayerDetailsQueryResult = Apollo.QueryResult<Types.GetLayerDetailsQuery, Types.GetLayerDetailsQueryVariables>;
export const AddUsersToLocationTeamDocument = gql`
    mutation addUsersToLocationTeam($objects: [location_team_users_insert_input!]!) {
  insert_location_team_users(objects: $objects) {
    returning {
      ...LocationDetailLayerTeamUser
    }
  }
}
    ${LocationDetailLayerTeamUserFragmentDoc}`;
export type AddUsersToLocationTeamMutationFn = Apollo.MutationFunction<Types.AddUsersToLocationTeamMutation, Types.AddUsersToLocationTeamMutationVariables>;

/**
 * __useAddUsersToLocationTeamMutation__
 *
 * To run a mutation, you first call `useAddUsersToLocationTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUsersToLocationTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUsersToLocationTeamMutation, { data, loading, error }] = useAddUsersToLocationTeamMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddUsersToLocationTeamMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddUsersToLocationTeamMutation, Types.AddUsersToLocationTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddUsersToLocationTeamMutation, Types.AddUsersToLocationTeamMutationVariables>(AddUsersToLocationTeamDocument, options);
      }
export type AddUsersToLocationTeamMutationHookResult = ReturnType<typeof useAddUsersToLocationTeamMutation>;
export type AddUsersToLocationTeamMutationResult = Apollo.MutationResult<Types.AddUsersToLocationTeamMutation>;
export type AddUsersToLocationTeamMutationOptions = Apollo.BaseMutationOptions<Types.AddUsersToLocationTeamMutation, Types.AddUsersToLocationTeamMutationVariables>;
export const RemoveLocationTeamUserDocument = gql`
    mutation removeLocationTeamUser($locationId: uuid!, $teamId: Int!, $userId: uuid!) {
  delete_location_team_users(
    where: {location_id: {_eq: $locationId}, layer_team_id: {_eq: $teamId}, user_id: {_eq: $userId}}
  ) {
    returning {
      ...LocationDetailLayerTeamUser
    }
  }
}
    ${LocationDetailLayerTeamUserFragmentDoc}`;
export type RemoveLocationTeamUserMutationFn = Apollo.MutationFunction<Types.RemoveLocationTeamUserMutation, Types.RemoveLocationTeamUserMutationVariables>;

/**
 * __useRemoveLocationTeamUserMutation__
 *
 * To run a mutation, you first call `useRemoveLocationTeamUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLocationTeamUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLocationTeamUserMutation, { data, loading, error }] = useRemoveLocationTeamUserMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      teamId: // value for 'teamId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveLocationTeamUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveLocationTeamUserMutation, Types.RemoveLocationTeamUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveLocationTeamUserMutation, Types.RemoveLocationTeamUserMutationVariables>(RemoveLocationTeamUserDocument, options);
      }
export type RemoveLocationTeamUserMutationHookResult = ReturnType<typeof useRemoveLocationTeamUserMutation>;
export type RemoveLocationTeamUserMutationResult = Apollo.MutationResult<Types.RemoveLocationTeamUserMutation>;
export type RemoveLocationTeamUserMutationOptions = Apollo.BaseMutationOptions<Types.RemoveLocationTeamUserMutation, Types.RemoveLocationTeamUserMutationVariables>;
export const UpdateLocationManagersDocument = gql`
    mutation updateLocationManagers($organizationId: uuid!, $locationId: uuid!, $addedUsers: [location_manager_assignments_insert_input!]!, $removedUserIds: [uuid!]) {
  insert_location_manager_assignments(objects: $addedUsers) {
    affected_rows
    returning {
      organization_id
      user_id
      node_id
    }
  }
  delete_location_manager_assignments(
    where: {organization_id: {_eq: $organizationId}, node_id: {_eq: $locationId}, user_id: {_in: $removedUserIds}}
  ) {
    affected_rows
    returning {
      user_id
      organization_id
      node_id
    }
  }
}
    `;
export type UpdateLocationManagersMutationFn = Apollo.MutationFunction<Types.UpdateLocationManagersMutation, Types.UpdateLocationManagersMutationVariables>;

/**
 * __useUpdateLocationManagersMutation__
 *
 * To run a mutation, you first call `useUpdateLocationManagersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationManagersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationManagersMutation, { data, loading, error }] = useUpdateLocationManagersMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      locationId: // value for 'locationId'
 *      addedUsers: // value for 'addedUsers'
 *      removedUserIds: // value for 'removedUserIds'
 *   },
 * });
 */
export function useUpdateLocationManagersMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateLocationManagersMutation, Types.UpdateLocationManagersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateLocationManagersMutation, Types.UpdateLocationManagersMutationVariables>(UpdateLocationManagersDocument, options);
      }
export type UpdateLocationManagersMutationHookResult = ReturnType<typeof useUpdateLocationManagersMutation>;
export type UpdateLocationManagersMutationResult = Apollo.MutationResult<Types.UpdateLocationManagersMutation>;
export type UpdateLocationManagersMutationOptions = Apollo.BaseMutationOptions<Types.UpdateLocationManagersMutation, Types.UpdateLocationManagersMutationVariables>;
export const GetLocationDetailNewDocument = gql`
    query getLocationDetailNew($id: uuid!) {
  nodes_by_pk(id: $id) {
    ...LocationDetailCore
    ...LocationDetailLayer
    ...LocationDetailLayerTeamUsers
    ...LocationDetailUserManagerAssignments
  }
}
    ${LocationDetailCoreFragmentDoc}
${LocationDetailLayerFragmentDoc}
${LocationDetailLayerTeamUsersFragmentDoc}
${LocationDetailUserManagerAssignmentsFragmentDoc}`;

/**
 * __useGetLocationDetailNewQuery__
 *
 * To run a query within a React component, call `useGetLocationDetailNewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationDetailNewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationDetailNewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLocationDetailNewQuery(baseOptions: Apollo.QueryHookOptions<Types.GetLocationDetailNewQuery, Types.GetLocationDetailNewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLocationDetailNewQuery, Types.GetLocationDetailNewQueryVariables>(GetLocationDetailNewDocument, options);
      }
export function useGetLocationDetailNewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLocationDetailNewQuery, Types.GetLocationDetailNewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLocationDetailNewQuery, Types.GetLocationDetailNewQueryVariables>(GetLocationDetailNewDocument, options);
        }
export type GetLocationDetailNewQueryHookResult = ReturnType<typeof useGetLocationDetailNewQuery>;
export type GetLocationDetailNewLazyQueryHookResult = ReturnType<typeof useGetLocationDetailNewLazyQuery>;
export type GetLocationDetailNewQueryResult = Apollo.QueryResult<Types.GetLocationDetailNewQuery, Types.GetLocationDetailNewQueryVariables>;
export const UpdateScenarioMeasureDocument = gql`
    mutation updateScenarioMeasure($id: uuid!, $name: citext, $description: String, $valueLabels: [scenario_inherent_risk_values_insert_input!]!, $minValue: numeric, $maxValue: numeric) {
  update_scenario_inherent_risk_measures_by_pk(
    pk_columns: {id: $id}
    _set: {name: $name, description: $description, min_value: $minValue, max_value: $maxValue}
  ) {
    name
    description
  }
  insert_scenario_inherent_risk_values(
    objects: $valueLabels
    on_conflict: {constraint: uniq_measure_value, update_columns: [name]}
  ) {
    affected_rows
    returning {
      __typename
      id
      name
      value
    }
  }
}
    `;
export type UpdateScenarioMeasureMutationFn = Apollo.MutationFunction<Types.UpdateScenarioMeasureMutation, Types.UpdateScenarioMeasureMutationVariables>;

/**
 * __useUpdateScenarioMeasureMutation__
 *
 * To run a mutation, you first call `useUpdateScenarioMeasureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScenarioMeasureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScenarioMeasureMutation, { data, loading, error }] = useUpdateScenarioMeasureMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      valueLabels: // value for 'valueLabels'
 *      minValue: // value for 'minValue'
 *      maxValue: // value for 'maxValue'
 *   },
 * });
 */
export function useUpdateScenarioMeasureMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateScenarioMeasureMutation, Types.UpdateScenarioMeasureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateScenarioMeasureMutation, Types.UpdateScenarioMeasureMutationVariables>(UpdateScenarioMeasureDocument, options);
      }
export type UpdateScenarioMeasureMutationHookResult = ReturnType<typeof useUpdateScenarioMeasureMutation>;
export type UpdateScenarioMeasureMutationResult = Apollo.MutationResult<Types.UpdateScenarioMeasureMutation>;
export type UpdateScenarioMeasureMutationOptions = Apollo.BaseMutationOptions<Types.UpdateScenarioMeasureMutation, Types.UpdateScenarioMeasureMutationVariables>;
export const UpdateOrgProbabilityMeasureDocument = gql`
    mutation updateOrgProbabilityMeasure($organizationId: uuid!, $id: uuid!, $name: citext, $description: String, $valueLabels: [scenario_probability_value_labels_insert_input!]!, $minValue: numeric, $maxValue: numeric) {
  update_organization_scenario_probability_measure_by_pk(
    pk_columns: {id: $id, organization_id: $organizationId}
    _set: {name: $name, description: $description, min_value: $minValue, max_value: $maxValue}
  ) {
    name
    description
  }
  insert_scenario_probability_value_labels(
    objects: $valueLabels
    on_conflict: {constraint: scenario_probability_value_labels_pkey, update_columns: [name]}
  ) {
    affected_rows
  }
}
    `;
export type UpdateOrgProbabilityMeasureMutationFn = Apollo.MutationFunction<Types.UpdateOrgProbabilityMeasureMutation, Types.UpdateOrgProbabilityMeasureMutationVariables>;

/**
 * __useUpdateOrgProbabilityMeasureMutation__
 *
 * To run a mutation, you first call `useUpdateOrgProbabilityMeasureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgProbabilityMeasureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgProbabilityMeasureMutation, { data, loading, error }] = useUpdateOrgProbabilityMeasureMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      valueLabels: // value for 'valueLabels'
 *      minValue: // value for 'minValue'
 *      maxValue: // value for 'maxValue'
 *   },
 * });
 */
export function useUpdateOrgProbabilityMeasureMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateOrgProbabilityMeasureMutation, Types.UpdateOrgProbabilityMeasureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateOrgProbabilityMeasureMutation, Types.UpdateOrgProbabilityMeasureMutationVariables>(UpdateOrgProbabilityMeasureDocument, options);
      }
export type UpdateOrgProbabilityMeasureMutationHookResult = ReturnType<typeof useUpdateOrgProbabilityMeasureMutation>;
export type UpdateOrgProbabilityMeasureMutationResult = Apollo.MutationResult<Types.UpdateOrgProbabilityMeasureMutation>;
export type UpdateOrgProbabilityMeasureMutationOptions = Apollo.BaseMutationOptions<Types.UpdateOrgProbabilityMeasureMutation, Types.UpdateOrgProbabilityMeasureMutationVariables>;
export const UpdateOrgSeverityMeasureDocument = gql`
    mutation updateOrgSeverityMeasure($organizationId: uuid!, $id: uuid!, $name: citext, $description: String, $valueLabels: [scenario_impact_value_labels_insert_input!]!, $minValue: numeric, $maxValue: numeric) {
  update_organization_scenario_impact_measure_by_pk(
    pk_columns: {id: $id, organization_id: $organizationId}
    _set: {name: $name, description: $description, min_value: $minValue, max_value: $maxValue}
  ) {
    name
    description
  }
  insert_scenario_impact_value_labels(
    objects: $valueLabels
    on_conflict: {constraint: scenario_impact_value_labels_pkey, update_columns: [name]}
  ) {
    affected_rows
  }
}
    `;
export type UpdateOrgSeverityMeasureMutationFn = Apollo.MutationFunction<Types.UpdateOrgSeverityMeasureMutation, Types.UpdateOrgSeverityMeasureMutationVariables>;

/**
 * __useUpdateOrgSeverityMeasureMutation__
 *
 * To run a mutation, you first call `useUpdateOrgSeverityMeasureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgSeverityMeasureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgSeverityMeasureMutation, { data, loading, error }] = useUpdateOrgSeverityMeasureMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      valueLabels: // value for 'valueLabels'
 *      minValue: // value for 'minValue'
 *      maxValue: // value for 'maxValue'
 *   },
 * });
 */
export function useUpdateOrgSeverityMeasureMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateOrgSeverityMeasureMutation, Types.UpdateOrgSeverityMeasureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateOrgSeverityMeasureMutation, Types.UpdateOrgSeverityMeasureMutationVariables>(UpdateOrgSeverityMeasureDocument, options);
      }
export type UpdateOrgSeverityMeasureMutationHookResult = ReturnType<typeof useUpdateOrgSeverityMeasureMutation>;
export type UpdateOrgSeverityMeasureMutationResult = Apollo.MutationResult<Types.UpdateOrgSeverityMeasureMutation>;
export type UpdateOrgSeverityMeasureMutationOptions = Apollo.BaseMutationOptions<Types.UpdateOrgSeverityMeasureMutation, Types.UpdateOrgSeverityMeasureMutationVariables>;
export const DeleteOrgScenarioImpactMeasureDocument = gql`
    mutation deleteOrgScenarioImpactMeasure($id: uuid!, $organizationId: uuid!) {
  delete_organization_scenario_impact_measure_by_pk(
    id: $id
    organization_id: $organizationId
  ) {
    id
    organization_id
    name
  }
  delete_scenario_impact_value_labels(
    where: {impact_measure_id: {_eq: $id}, organization_id: {_eq: $organizationId}}
  ) {
    returning {
      organization_id
      impact_measure_id
    }
  }
}
    `;
export type DeleteOrgScenarioImpactMeasureMutationFn = Apollo.MutationFunction<Types.DeleteOrgScenarioImpactMeasureMutation, Types.DeleteOrgScenarioImpactMeasureMutationVariables>;

/**
 * __useDeleteOrgScenarioImpactMeasureMutation__
 *
 * To run a mutation, you first call `useDeleteOrgScenarioImpactMeasureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrgScenarioImpactMeasureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrgScenarioImpactMeasureMutation, { data, loading, error }] = useDeleteOrgScenarioImpactMeasureMutation({
 *   variables: {
 *      id: // value for 'id'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useDeleteOrgScenarioImpactMeasureMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteOrgScenarioImpactMeasureMutation, Types.DeleteOrgScenarioImpactMeasureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteOrgScenarioImpactMeasureMutation, Types.DeleteOrgScenarioImpactMeasureMutationVariables>(DeleteOrgScenarioImpactMeasureDocument, options);
      }
export type DeleteOrgScenarioImpactMeasureMutationHookResult = ReturnType<typeof useDeleteOrgScenarioImpactMeasureMutation>;
export type DeleteOrgScenarioImpactMeasureMutationResult = Apollo.MutationResult<Types.DeleteOrgScenarioImpactMeasureMutation>;
export type DeleteOrgScenarioImpactMeasureMutationOptions = Apollo.BaseMutationOptions<Types.DeleteOrgScenarioImpactMeasureMutation, Types.DeleteOrgScenarioImpactMeasureMutationVariables>;
export const DeleteOrgScenarioProbabilityMeasureDocument = gql`
    mutation deleteOrgScenarioProbabilityMeasure($id: uuid!, $organizationId: uuid!) {
  delete_organization_scenario_probability_measure_by_pk(
    id: $id
    organization_id: $organizationId
  ) {
    id
    organization_id
    name
  }
  delete_scenario_probability_value_labels(
    where: {probability_measure_id: {_eq: $id}, organization_id: {_eq: $organizationId}}
  ) {
    returning {
      organization_id
      probability_measure_id
    }
  }
}
    `;
export type DeleteOrgScenarioProbabilityMeasureMutationFn = Apollo.MutationFunction<Types.DeleteOrgScenarioProbabilityMeasureMutation, Types.DeleteOrgScenarioProbabilityMeasureMutationVariables>;

/**
 * __useDeleteOrgScenarioProbabilityMeasureMutation__
 *
 * To run a mutation, you first call `useDeleteOrgScenarioProbabilityMeasureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrgScenarioProbabilityMeasureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrgScenarioProbabilityMeasureMutation, { data, loading, error }] = useDeleteOrgScenarioProbabilityMeasureMutation({
 *   variables: {
 *      id: // value for 'id'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useDeleteOrgScenarioProbabilityMeasureMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteOrgScenarioProbabilityMeasureMutation, Types.DeleteOrgScenarioProbabilityMeasureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteOrgScenarioProbabilityMeasureMutation, Types.DeleteOrgScenarioProbabilityMeasureMutationVariables>(DeleteOrgScenarioProbabilityMeasureDocument, options);
      }
export type DeleteOrgScenarioProbabilityMeasureMutationHookResult = ReturnType<typeof useDeleteOrgScenarioProbabilityMeasureMutation>;
export type DeleteOrgScenarioProbabilityMeasureMutationResult = Apollo.MutationResult<Types.DeleteOrgScenarioProbabilityMeasureMutation>;
export type DeleteOrgScenarioProbabilityMeasureMutationOptions = Apollo.BaseMutationOptions<Types.DeleteOrgScenarioProbabilityMeasureMutation, Types.DeleteOrgScenarioProbabilityMeasureMutationVariables>;
export const UpdateActiveAnswerDocument = gql`
    mutation updateActiveAnswer($organizationId: uuid!, $itemId: uuid!, $questionId: uuid!, $input: active_answers_set_input!) {
  update_active_answers_by_pk(
    pk_columns: {organization_id: $organizationId, item_id: $itemId, question_id: $questionId}
    _set: $input
  ) {
    ...activeAnswersKeyFields
    is_flagged
    criticality_value
    item {
      answer_aggregates {
        ...itemAnswerAggregates
      }
    }
  }
}
    ${ActiveAnswersKeyFieldsFragmentDoc}
${ItemAnswerAggregatesFragmentDoc}`;
export type UpdateActiveAnswerMutationFn = Apollo.MutationFunction<Types.UpdateActiveAnswerMutation, Types.UpdateActiveAnswerMutationVariables>;

/**
 * __useUpdateActiveAnswerMutation__
 *
 * To run a mutation, you first call `useUpdateActiveAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActiveAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActiveAnswerMutation, { data, loading, error }] = useUpdateActiveAnswerMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      itemId: // value for 'itemId'
 *      questionId: // value for 'questionId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateActiveAnswerMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateActiveAnswerMutation, Types.UpdateActiveAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateActiveAnswerMutation, Types.UpdateActiveAnswerMutationVariables>(UpdateActiveAnswerDocument, options);
      }
export type UpdateActiveAnswerMutationHookResult = ReturnType<typeof useUpdateActiveAnswerMutation>;
export type UpdateActiveAnswerMutationResult = Apollo.MutationResult<Types.UpdateActiveAnswerMutation>;
export type UpdateActiveAnswerMutationOptions = Apollo.BaseMutationOptions<Types.UpdateActiveAnswerMutation, Types.UpdateActiveAnswerMutationVariables>;
export const UpdateActiveAnswerCriticalityDocument = gql`
    mutation updateActiveAnswerCriticality($organizationId: uuid!, $itemId: uuid!, $questionId: uuid!, $criticality: Int!) {
  update_active_answers_by_pk(
    pk_columns: {organization_id: $organizationId, item_id: $itemId, question_id: $questionId}
    _set: {criticality_value: $criticality}
  ) {
    ...activeAnswersKeyFields
    ...ActiveAnswerCriticalityInfo
  }
}
    ${ActiveAnswersKeyFieldsFragmentDoc}
${ActiveAnswerCriticalityInfoFragmentDoc}`;
export type UpdateActiveAnswerCriticalityMutationFn = Apollo.MutationFunction<Types.UpdateActiveAnswerCriticalityMutation, Types.UpdateActiveAnswerCriticalityMutationVariables>;

/**
 * __useUpdateActiveAnswerCriticalityMutation__
 *
 * To run a mutation, you first call `useUpdateActiveAnswerCriticalityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActiveAnswerCriticalityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActiveAnswerCriticalityMutation, { data, loading, error }] = useUpdateActiveAnswerCriticalityMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      itemId: // value for 'itemId'
 *      questionId: // value for 'questionId'
 *      criticality: // value for 'criticality'
 *   },
 * });
 */
export function useUpdateActiveAnswerCriticalityMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateActiveAnswerCriticalityMutation, Types.UpdateActiveAnswerCriticalityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateActiveAnswerCriticalityMutation, Types.UpdateActiveAnswerCriticalityMutationVariables>(UpdateActiveAnswerCriticalityDocument, options);
      }
export type UpdateActiveAnswerCriticalityMutationHookResult = ReturnType<typeof useUpdateActiveAnswerCriticalityMutation>;
export type UpdateActiveAnswerCriticalityMutationResult = Apollo.MutationResult<Types.UpdateActiveAnswerCriticalityMutation>;
export type UpdateActiveAnswerCriticalityMutationOptions = Apollo.BaseMutationOptions<Types.UpdateActiveAnswerCriticalityMutation, Types.UpdateActiveAnswerCriticalityMutationVariables>;
export const UpdateActiveAnswerIsFlaggedDocument = gql`
    mutation updateActiveAnswerIsFlagged($organizationId: uuid!, $itemId: uuid!, $questionId: uuid!, $is_flagged: Boolean!) {
  update_active_answers_by_pk(
    pk_columns: {organization_id: $organizationId, item_id: $itemId, question_id: $questionId}
    _set: {is_flagged: $is_flagged}
  ) {
    ...activeAnswersKeyFields
    ...ActiveAnswerFlagInfo
  }
}
    ${ActiveAnswersKeyFieldsFragmentDoc}
${ActiveAnswerFlagInfoFragmentDoc}`;
export type UpdateActiveAnswerIsFlaggedMutationFn = Apollo.MutationFunction<Types.UpdateActiveAnswerIsFlaggedMutation, Types.UpdateActiveAnswerIsFlaggedMutationVariables>;

/**
 * __useUpdateActiveAnswerIsFlaggedMutation__
 *
 * To run a mutation, you first call `useUpdateActiveAnswerIsFlaggedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActiveAnswerIsFlaggedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActiveAnswerIsFlaggedMutation, { data, loading, error }] = useUpdateActiveAnswerIsFlaggedMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      itemId: // value for 'itemId'
 *      questionId: // value for 'questionId'
 *      is_flagged: // value for 'is_flagged'
 *   },
 * });
 */
export function useUpdateActiveAnswerIsFlaggedMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateActiveAnswerIsFlaggedMutation, Types.UpdateActiveAnswerIsFlaggedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateActiveAnswerIsFlaggedMutation, Types.UpdateActiveAnswerIsFlaggedMutationVariables>(UpdateActiveAnswerIsFlaggedDocument, options);
      }
export type UpdateActiveAnswerIsFlaggedMutationHookResult = ReturnType<typeof useUpdateActiveAnswerIsFlaggedMutation>;
export type UpdateActiveAnswerIsFlaggedMutationResult = Apollo.MutationResult<Types.UpdateActiveAnswerIsFlaggedMutation>;
export type UpdateActiveAnswerIsFlaggedMutationOptions = Apollo.BaseMutationOptions<Types.UpdateActiveAnswerIsFlaggedMutation, Types.UpdateActiveAnswerIsFlaggedMutationVariables>;
export const BulkAcceptRiskDocument = gql`
    mutation bulkAcceptRisk($organizationId: uuid!, $questionIds: [uuid!]!, $itemIds: [uuid!]!, $justification: String!) {
  update_active_answers(
    where: {organization_id: {_eq: $organizationId}, question_id: {_in: $questionIds}, item_id: {_in: $itemIds}}
    _set: {accepted_risk_justification: $justification, is_accepted: true}
  ) {
    affected_rows
    returning {
      ...activeAnswersKeyFields
      is_accepted
      accepted_risk_justification
    }
  }
}
    ${ActiveAnswersKeyFieldsFragmentDoc}`;
export type BulkAcceptRiskMutationFn = Apollo.MutationFunction<Types.BulkAcceptRiskMutation, Types.BulkAcceptRiskMutationVariables>;

/**
 * __useBulkAcceptRiskMutation__
 *
 * To run a mutation, you first call `useBulkAcceptRiskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkAcceptRiskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkAcceptRiskMutation, { data, loading, error }] = useBulkAcceptRiskMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      questionIds: // value for 'questionIds'
 *      itemIds: // value for 'itemIds'
 *      justification: // value for 'justification'
 *   },
 * });
 */
export function useBulkAcceptRiskMutation(baseOptions?: Apollo.MutationHookOptions<Types.BulkAcceptRiskMutation, Types.BulkAcceptRiskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.BulkAcceptRiskMutation, Types.BulkAcceptRiskMutationVariables>(BulkAcceptRiskDocument, options);
      }
export type BulkAcceptRiskMutationHookResult = ReturnType<typeof useBulkAcceptRiskMutation>;
export type BulkAcceptRiskMutationResult = Apollo.MutationResult<Types.BulkAcceptRiskMutation>;
export type BulkAcceptRiskMutationOptions = Apollo.BaseMutationOptions<Types.BulkAcceptRiskMutation, Types.BulkAcceptRiskMutationVariables>;
export const UpdateActiveAssessmentFileNameDocument = gql`
    mutation updateActiveAssessmentFileName($id: uuid!, $name: String!) {
  update_active_assessment_files(
    where: {id: {_eq: $id}}
    _set: {original_filename: $name}
  ) {
    returning {
      id
      original_filename
    }
  }
}
    `;
export type UpdateActiveAssessmentFileNameMutationFn = Apollo.MutationFunction<Types.UpdateActiveAssessmentFileNameMutation, Types.UpdateActiveAssessmentFileNameMutationVariables>;

/**
 * __useUpdateActiveAssessmentFileNameMutation__
 *
 * To run a mutation, you first call `useUpdateActiveAssessmentFileNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActiveAssessmentFileNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActiveAssessmentFileNameMutation, { data, loading, error }] = useUpdateActiveAssessmentFileNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateActiveAssessmentFileNameMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateActiveAssessmentFileNameMutation, Types.UpdateActiveAssessmentFileNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateActiveAssessmentFileNameMutation, Types.UpdateActiveAssessmentFileNameMutationVariables>(UpdateActiveAssessmentFileNameDocument, options);
      }
export type UpdateActiveAssessmentFileNameMutationHookResult = ReturnType<typeof useUpdateActiveAssessmentFileNameMutation>;
export type UpdateActiveAssessmentFileNameMutationResult = Apollo.MutationResult<Types.UpdateActiveAssessmentFileNameMutation>;
export type UpdateActiveAssessmentFileNameMutationOptions = Apollo.BaseMutationOptions<Types.UpdateActiveAssessmentFileNameMutation, Types.UpdateActiveAssessmentFileNameMutationVariables>;
export const InsertAnswersFilesDocument = gql`
    mutation insertAnswersFiles($item: answers_files_insert_input!) {
  insert_answers_files_one(object: $item) {
    ...AnswersFilesIdentifiers
  }
}
    ${AnswersFilesIdentifiersFragmentDoc}`;
export type InsertAnswersFilesMutationFn = Apollo.MutationFunction<Types.InsertAnswersFilesMutation, Types.InsertAnswersFilesMutationVariables>;

/**
 * __useInsertAnswersFilesMutation__
 *
 * To run a mutation, you first call `useInsertAnswersFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertAnswersFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertAnswersFilesMutation, { data, loading, error }] = useInsertAnswersFilesMutation({
 *   variables: {
 *      item: // value for 'item'
 *   },
 * });
 */
export function useInsertAnswersFilesMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertAnswersFilesMutation, Types.InsertAnswersFilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertAnswersFilesMutation, Types.InsertAnswersFilesMutationVariables>(InsertAnswersFilesDocument, options);
      }
export type InsertAnswersFilesMutationHookResult = ReturnType<typeof useInsertAnswersFilesMutation>;
export type InsertAnswersFilesMutationResult = Apollo.MutationResult<Types.InsertAnswersFilesMutation>;
export type InsertAnswersFilesMutationOptions = Apollo.BaseMutationOptions<Types.InsertAnswersFilesMutation, Types.InsertAnswersFilesMutationVariables>;
export const DeleteAnswersFilesDocument = gql`
    mutation deleteAnswersFiles($answer_id: uuid!, $file_id: uuid!) {
  delete_answers_files_by_pk(answer_id: $answer_id, file_id: $file_id) {
    ...AnswersFilesIdentifiers
  }
}
    ${AnswersFilesIdentifiersFragmentDoc}`;
export type DeleteAnswersFilesMutationFn = Apollo.MutationFunction<Types.DeleteAnswersFilesMutation, Types.DeleteAnswersFilesMutationVariables>;

/**
 * __useDeleteAnswersFilesMutation__
 *
 * To run a mutation, you first call `useDeleteAnswersFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAnswersFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAnswersFilesMutation, { data, loading, error }] = useDeleteAnswersFilesMutation({
 *   variables: {
 *      answer_id: // value for 'answer_id'
 *      file_id: // value for 'file_id'
 *   },
 * });
 */
export function useDeleteAnswersFilesMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteAnswersFilesMutation, Types.DeleteAnswersFilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteAnswersFilesMutation, Types.DeleteAnswersFilesMutationVariables>(DeleteAnswersFilesDocument, options);
      }
export type DeleteAnswersFilesMutationHookResult = ReturnType<typeof useDeleteAnswersFilesMutation>;
export type DeleteAnswersFilesMutationResult = Apollo.MutationResult<Types.DeleteAnswersFilesMutation>;
export type DeleteAnswersFilesMutationOptions = Apollo.BaseMutationOptions<Types.DeleteAnswersFilesMutation, Types.DeleteAnswersFilesMutationVariables>;
export const AddNewAreaTagIdDocument = gql`
    mutation addNewAreaTagId($name: citext!) {
  insert_area_tags_one(object: {name: $name}) {
    id
  }
}
    `;
export type AddNewAreaTagIdMutationFn = Apollo.MutationFunction<Types.AddNewAreaTagIdMutation, Types.AddNewAreaTagIdMutationVariables>;

/**
 * __useAddNewAreaTagIdMutation__
 *
 * To run a mutation, you first call `useAddNewAreaTagIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewAreaTagIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewAreaTagIdMutation, { data, loading, error }] = useAddNewAreaTagIdMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddNewAreaTagIdMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddNewAreaTagIdMutation, Types.AddNewAreaTagIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddNewAreaTagIdMutation, Types.AddNewAreaTagIdMutationVariables>(AddNewAreaTagIdDocument, options);
      }
export type AddNewAreaTagIdMutationHookResult = ReturnType<typeof useAddNewAreaTagIdMutation>;
export type AddNewAreaTagIdMutationResult = Apollo.MutationResult<Types.AddNewAreaTagIdMutation>;
export type AddNewAreaTagIdMutationOptions = Apollo.BaseMutationOptions<Types.AddNewAreaTagIdMutation, Types.AddNewAreaTagIdMutationVariables>;
export const RemoveAssessmentLibraryOptionDocument = gql`
    mutation removeAssessmentLibraryOption($id: Int!) {
  delete_assessment_answer_library_consideration_by_pk(id: $id) {
    id
    answer_id
    organization_id
  }
}
    `;
export type RemoveAssessmentLibraryOptionMutationFn = Apollo.MutationFunction<Types.RemoveAssessmentLibraryOptionMutation, Types.RemoveAssessmentLibraryOptionMutationVariables>;

/**
 * __useRemoveAssessmentLibraryOptionMutation__
 *
 * To run a mutation, you first call `useRemoveAssessmentLibraryOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAssessmentLibraryOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAssessmentLibraryOptionMutation, { data, loading, error }] = useRemoveAssessmentLibraryOptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveAssessmentLibraryOptionMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveAssessmentLibraryOptionMutation, Types.RemoveAssessmentLibraryOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveAssessmentLibraryOptionMutation, Types.RemoveAssessmentLibraryOptionMutationVariables>(RemoveAssessmentLibraryOptionDocument, options);
      }
export type RemoveAssessmentLibraryOptionMutationHookResult = ReturnType<typeof useRemoveAssessmentLibraryOptionMutation>;
export type RemoveAssessmentLibraryOptionMutationResult = Apollo.MutationResult<Types.RemoveAssessmentLibraryOptionMutation>;
export type RemoveAssessmentLibraryOptionMutationOptions = Apollo.BaseMutationOptions<Types.RemoveAssessmentLibraryOptionMutation, Types.RemoveAssessmentLibraryOptionMutationVariables>;
export const AddOptionFromLibraryToAssessmentDocument = gql`
    mutation addOptionFromLibraryToAssessment($optionId: Int!, $answerId: uuid!) {
  insert_assessment_answer_library_consideration_one(
    object: {answer_id: $answerId, consideration_option_id: $optionId}
  ) {
    id
    updated_at
  }
}
    `;
export type AddOptionFromLibraryToAssessmentMutationFn = Apollo.MutationFunction<Types.AddOptionFromLibraryToAssessmentMutation, Types.AddOptionFromLibraryToAssessmentMutationVariables>;

/**
 * __useAddOptionFromLibraryToAssessmentMutation__
 *
 * To run a mutation, you first call `useAddOptionFromLibraryToAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOptionFromLibraryToAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOptionFromLibraryToAssessmentMutation, { data, loading, error }] = useAddOptionFromLibraryToAssessmentMutation({
 *   variables: {
 *      optionId: // value for 'optionId'
 *      answerId: // value for 'answerId'
 *   },
 * });
 */
export function useAddOptionFromLibraryToAssessmentMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddOptionFromLibraryToAssessmentMutation, Types.AddOptionFromLibraryToAssessmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddOptionFromLibraryToAssessmentMutation, Types.AddOptionFromLibraryToAssessmentMutationVariables>(AddOptionFromLibraryToAssessmentDocument, options);
      }
export type AddOptionFromLibraryToAssessmentMutationHookResult = ReturnType<typeof useAddOptionFromLibraryToAssessmentMutation>;
export type AddOptionFromLibraryToAssessmentMutationResult = Apollo.MutationResult<Types.AddOptionFromLibraryToAssessmentMutation>;
export type AddOptionFromLibraryToAssessmentMutationOptions = Apollo.BaseMutationOptions<Types.AddOptionFromLibraryToAssessmentMutation, Types.AddOptionFromLibraryToAssessmentMutationVariables>;
export const ProvideAnswerDocument = gql`
    mutation provideAnswer($answer: assessment_answers_insert_input!) {
  insert_assessment_answers_one(
    object: $answer
    on_conflict: {constraint: no_duplicate_questions_for_assessment_items, update_columns: [cost, description, response, is_flagged, partial_compliance_multiplier]}
  ) {
    ...provideAnswerInfo
  }
}
    ${ProvideAnswerInfoFragmentDoc}`;
export type ProvideAnswerMutationFn = Apollo.MutationFunction<Types.ProvideAnswerMutation, Types.ProvideAnswerMutationVariables>;

/**
 * __useProvideAnswerMutation__
 *
 * To run a mutation, you first call `useProvideAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProvideAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [provideAnswerMutation, { data, loading, error }] = useProvideAnswerMutation({
 *   variables: {
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useProvideAnswerMutation(baseOptions?: Apollo.MutationHookOptions<Types.ProvideAnswerMutation, Types.ProvideAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProvideAnswerMutation, Types.ProvideAnswerMutationVariables>(ProvideAnswerDocument, options);
      }
export type ProvideAnswerMutationHookResult = ReturnType<typeof useProvideAnswerMutation>;
export type ProvideAnswerMutationResult = Apollo.MutationResult<Types.ProvideAnswerMutation>;
export type ProvideAnswerMutationOptions = Apollo.BaseMutationOptions<Types.ProvideAnswerMutation, Types.ProvideAnswerMutationVariables>;
export const ProvidePartialAnswerDocument = gql`
    mutation providePartialAnswer($answer: assessment_answers_insert_input!, $updateColumns: [assessment_answers_update_column!]!) {
  insert_assessment_answers_one(
    object: $answer
    on_conflict: {constraint: no_duplicate_questions_for_assessment_items, update_columns: $updateColumns}
  ) {
    ...provideAnswerInfo
  }
}
    ${ProvideAnswerInfoFragmentDoc}`;
export type ProvidePartialAnswerMutationFn = Apollo.MutationFunction<Types.ProvidePartialAnswerMutation, Types.ProvidePartialAnswerMutationVariables>;

/**
 * __useProvidePartialAnswerMutation__
 *
 * To run a mutation, you first call `useProvidePartialAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProvidePartialAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [providePartialAnswerMutation, { data, loading, error }] = useProvidePartialAnswerMutation({
 *   variables: {
 *      answer: // value for 'answer'
 *      updateColumns: // value for 'updateColumns'
 *   },
 * });
 */
export function useProvidePartialAnswerMutation(baseOptions?: Apollo.MutationHookOptions<Types.ProvidePartialAnswerMutation, Types.ProvidePartialAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProvidePartialAnswerMutation, Types.ProvidePartialAnswerMutationVariables>(ProvidePartialAnswerDocument, options);
      }
export type ProvidePartialAnswerMutationHookResult = ReturnType<typeof useProvidePartialAnswerMutation>;
export type ProvidePartialAnswerMutationResult = Apollo.MutationResult<Types.ProvidePartialAnswerMutation>;
export type ProvidePartialAnswerMutationOptions = Apollo.BaseMutationOptions<Types.ProvidePartialAnswerMutation, Types.ProvidePartialAnswerMutationVariables>;
export const ProvideBulkAnswersDocument = gql`
    mutation provideBulkAnswers($answers: [assessment_answers_insert_input!]!) {
  insert_assessment_answers(
    objects: $answers
    on_conflict: {constraint: no_duplicate_questions_for_assessment_items, update_columns: [description, response]}
  ) {
    affected_rows
  }
}
    `;
export type ProvideBulkAnswersMutationFn = Apollo.MutationFunction<Types.ProvideBulkAnswersMutation, Types.ProvideBulkAnswersMutationVariables>;

/**
 * __useProvideBulkAnswersMutation__
 *
 * To run a mutation, you first call `useProvideBulkAnswersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProvideBulkAnswersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [provideBulkAnswersMutation, { data, loading, error }] = useProvideBulkAnswersMutation({
 *   variables: {
 *      answers: // value for 'answers'
 *   },
 * });
 */
export function useProvideBulkAnswersMutation(baseOptions?: Apollo.MutationHookOptions<Types.ProvideBulkAnswersMutation, Types.ProvideBulkAnswersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProvideBulkAnswersMutation, Types.ProvideBulkAnswersMutationVariables>(ProvideBulkAnswersDocument, options);
      }
export type ProvideBulkAnswersMutationHookResult = ReturnType<typeof useProvideBulkAnswersMutation>;
export type ProvideBulkAnswersMutationResult = Apollo.MutationResult<Types.ProvideBulkAnswersMutation>;
export type ProvideBulkAnswersMutationOptions = Apollo.BaseMutationOptions<Types.ProvideBulkAnswersMutation, Types.ProvideBulkAnswersMutationVariables>;
export const ProvidePartialBulkAnswersDocument = gql`
    mutation providePartialBulkAnswers($answers: [assessment_answers_insert_input!]!, $updateColumns: [assessment_answers_update_column!]!) {
  insert_assessment_answers(
    objects: $answers
    on_conflict: {constraint: no_duplicate_questions_for_assessment_items, update_columns: $updateColumns}
  ) {
    affected_rows
  }
}
    `;
export type ProvidePartialBulkAnswersMutationFn = Apollo.MutationFunction<Types.ProvidePartialBulkAnswersMutation, Types.ProvidePartialBulkAnswersMutationVariables>;

/**
 * __useProvidePartialBulkAnswersMutation__
 *
 * To run a mutation, you first call `useProvidePartialBulkAnswersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProvidePartialBulkAnswersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [providePartialBulkAnswersMutation, { data, loading, error }] = useProvidePartialBulkAnswersMutation({
 *   variables: {
 *      answers: // value for 'answers'
 *      updateColumns: // value for 'updateColumns'
 *   },
 * });
 */
export function useProvidePartialBulkAnswersMutation(baseOptions?: Apollo.MutationHookOptions<Types.ProvidePartialBulkAnswersMutation, Types.ProvidePartialBulkAnswersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProvidePartialBulkAnswersMutation, Types.ProvidePartialBulkAnswersMutationVariables>(ProvidePartialBulkAnswersDocument, options);
      }
export type ProvidePartialBulkAnswersMutationHookResult = ReturnType<typeof useProvidePartialBulkAnswersMutation>;
export type ProvidePartialBulkAnswersMutationResult = Apollo.MutationResult<Types.ProvidePartialBulkAnswersMutation>;
export type ProvidePartialBulkAnswersMutationOptions = Apollo.BaseMutationOptions<Types.ProvidePartialBulkAnswersMutation, Types.ProvidePartialBulkAnswersMutationVariables>;
export const UpdateAnswerDocument = gql`
    mutation updateAnswer($id: uuid!, $updated: assessment_answers_set_input) {
  update_assessment_answers_by_pk(pk_columns: {id: $id}, _set: $updated) {
    __typename
    id
    answered_at
    answered_by_id
    response
    description
    is_flagged
    answers_files {
      ...AnswersFilesIdentifiers
      file {
        __typename
        id
        filepath
      }
    }
  }
}
    ${AnswersFilesIdentifiersFragmentDoc}`;
export type UpdateAnswerMutationFn = Apollo.MutationFunction<Types.UpdateAnswerMutation, Types.UpdateAnswerMutationVariables>;

/**
 * __useUpdateAnswerMutation__
 *
 * To run a mutation, you first call `useUpdateAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnswerMutation, { data, loading, error }] = useUpdateAnswerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updated: // value for 'updated'
 *   },
 * });
 */
export function useUpdateAnswerMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateAnswerMutation, Types.UpdateAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateAnswerMutation, Types.UpdateAnswerMutationVariables>(UpdateAnswerDocument, options);
      }
export type UpdateAnswerMutationHookResult = ReturnType<typeof useUpdateAnswerMutation>;
export type UpdateAnswerMutationResult = Apollo.MutationResult<Types.UpdateAnswerMutation>;
export type UpdateAnswerMutationOptions = Apollo.BaseMutationOptions<Types.UpdateAnswerMutation, Types.UpdateAnswerMutationVariables>;
export const CreateAssessmentAnswerFreeformConsiderationDocument = gql`
    mutation createAssessmentAnswerFreeformConsideration($data: assessment_answers_freeform_consideration_insert_input!) {
  insert_assessment_answers_freeform_consideration_one(object: $data) {
    id
    organization_id
    answer_id
    description
    updated_at
    type
    title
  }
}
    `;
export type CreateAssessmentAnswerFreeformConsiderationMutationFn = Apollo.MutationFunction<Types.CreateAssessmentAnswerFreeformConsiderationMutation, Types.CreateAssessmentAnswerFreeformConsiderationMutationVariables>;

/**
 * __useCreateAssessmentAnswerFreeformConsiderationMutation__
 *
 * To run a mutation, you first call `useCreateAssessmentAnswerFreeformConsiderationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssessmentAnswerFreeformConsiderationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssessmentAnswerFreeformConsiderationMutation, { data, loading, error }] = useCreateAssessmentAnswerFreeformConsiderationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAssessmentAnswerFreeformConsiderationMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateAssessmentAnswerFreeformConsiderationMutation, Types.CreateAssessmentAnswerFreeformConsiderationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateAssessmentAnswerFreeformConsiderationMutation, Types.CreateAssessmentAnswerFreeformConsiderationMutationVariables>(CreateAssessmentAnswerFreeformConsiderationDocument, options);
      }
export type CreateAssessmentAnswerFreeformConsiderationMutationHookResult = ReturnType<typeof useCreateAssessmentAnswerFreeformConsiderationMutation>;
export type CreateAssessmentAnswerFreeformConsiderationMutationResult = Apollo.MutationResult<Types.CreateAssessmentAnswerFreeformConsiderationMutation>;
export type CreateAssessmentAnswerFreeformConsiderationMutationOptions = Apollo.BaseMutationOptions<Types.CreateAssessmentAnswerFreeformConsiderationMutation, Types.CreateAssessmentAnswerFreeformConsiderationMutationVariables>;
export const RemoveAssessmentFreeformOptionDocument = gql`
    mutation removeAssessmentFreeformOption($id: uuid!) {
  delete_assessment_answers_freeform_consideration_by_pk(id: $id) {
    id
    answer_id
    organization_id
  }
}
    `;
export type RemoveAssessmentFreeformOptionMutationFn = Apollo.MutationFunction<Types.RemoveAssessmentFreeformOptionMutation, Types.RemoveAssessmentFreeformOptionMutationVariables>;

/**
 * __useRemoveAssessmentFreeformOptionMutation__
 *
 * To run a mutation, you first call `useRemoveAssessmentFreeformOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAssessmentFreeformOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAssessmentFreeformOptionMutation, { data, loading, error }] = useRemoveAssessmentFreeformOptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveAssessmentFreeformOptionMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveAssessmentFreeformOptionMutation, Types.RemoveAssessmentFreeformOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveAssessmentFreeformOptionMutation, Types.RemoveAssessmentFreeformOptionMutationVariables>(RemoveAssessmentFreeformOptionDocument, options);
      }
export type RemoveAssessmentFreeformOptionMutationHookResult = ReturnType<typeof useRemoveAssessmentFreeformOptionMutation>;
export type RemoveAssessmentFreeformOptionMutationResult = Apollo.MutationResult<Types.RemoveAssessmentFreeformOptionMutation>;
export type RemoveAssessmentFreeformOptionMutationOptions = Apollo.BaseMutationOptions<Types.RemoveAssessmentFreeformOptionMutation, Types.RemoveAssessmentFreeformOptionMutationVariables>;
export const UpdateAssessmentAnswerFreeformConsiderationDocument = gql`
    mutation updateAssessmentAnswerFreeformConsideration($id: uuid!, $type: enum_consideration_type_enum!, $title: String!, $description: String) {
  update_assessment_answers_freeform_consideration_by_pk(
    pk_columns: {id: $id}
    _set: {type: $type, title: $title, description: $description}
  ) {
    id
    organization_id
    answer_id
    description
    updated_at
    type
    title
  }
}
    `;
export type UpdateAssessmentAnswerFreeformConsiderationMutationFn = Apollo.MutationFunction<Types.UpdateAssessmentAnswerFreeformConsiderationMutation, Types.UpdateAssessmentAnswerFreeformConsiderationMutationVariables>;

/**
 * __useUpdateAssessmentAnswerFreeformConsiderationMutation__
 *
 * To run a mutation, you first call `useUpdateAssessmentAnswerFreeformConsiderationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssessmentAnswerFreeformConsiderationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssessmentAnswerFreeformConsiderationMutation, { data, loading, error }] = useUpdateAssessmentAnswerFreeformConsiderationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateAssessmentAnswerFreeformConsiderationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateAssessmentAnswerFreeformConsiderationMutation, Types.UpdateAssessmentAnswerFreeformConsiderationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateAssessmentAnswerFreeformConsiderationMutation, Types.UpdateAssessmentAnswerFreeformConsiderationMutationVariables>(UpdateAssessmentAnswerFreeformConsiderationDocument, options);
      }
export type UpdateAssessmentAnswerFreeformConsiderationMutationHookResult = ReturnType<typeof useUpdateAssessmentAnswerFreeformConsiderationMutation>;
export type UpdateAssessmentAnswerFreeformConsiderationMutationResult = Apollo.MutationResult<Types.UpdateAssessmentAnswerFreeformConsiderationMutation>;
export type UpdateAssessmentAnswerFreeformConsiderationMutationOptions = Apollo.BaseMutationOptions<Types.UpdateAssessmentAnswerFreeformConsiderationMutation, Types.UpdateAssessmentAnswerFreeformConsiderationMutationVariables>;
export const InsertAssessmentItemDocument = gql`
    mutation insertAssessmentItem($item: assessment_items_insert_input!) {
  insert_assessment_items_one(object: $item) {
    __typename
    id
    name
    asset
    coordinates
    countermeasure
    description
    exterior
    is_present
    assessment_id
    node_id
    item_category_id
    is_interview_item
    location_description
  }
}
    `;
export type InsertAssessmentItemMutationFn = Apollo.MutationFunction<Types.InsertAssessmentItemMutation, Types.InsertAssessmentItemMutationVariables>;

/**
 * __useInsertAssessmentItemMutation__
 *
 * To run a mutation, you first call `useInsertAssessmentItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertAssessmentItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertAssessmentItemMutation, { data, loading, error }] = useInsertAssessmentItemMutation({
 *   variables: {
 *      item: // value for 'item'
 *   },
 * });
 */
export function useInsertAssessmentItemMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertAssessmentItemMutation, Types.InsertAssessmentItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertAssessmentItemMutation, Types.InsertAssessmentItemMutationVariables>(InsertAssessmentItemDocument, options);
      }
export type InsertAssessmentItemMutationHookResult = ReturnType<typeof useInsertAssessmentItemMutation>;
export type InsertAssessmentItemMutationResult = Apollo.MutationResult<Types.InsertAssessmentItemMutation>;
export type InsertAssessmentItemMutationOptions = Apollo.BaseMutationOptions<Types.InsertAssessmentItemMutation, Types.InsertAssessmentItemMutationVariables>;
export const UpdateAssessmentItemDocument = gql`
    mutation updateAssessmentItem($id: uuid!, $updated: assessment_items_set_input) {
  update_assessment_items_by_pk(pk_columns: {id: $id}, _set: $updated) {
    __typename
    id
    name
    asset
    coordinates
    countermeasure
    description
    exterior
    is_present
    assessment_id
    node_id
    deleted_at
    updated_by_id
    recommendation
    location_description
  }
}
    `;
export type UpdateAssessmentItemMutationFn = Apollo.MutationFunction<Types.UpdateAssessmentItemMutation, Types.UpdateAssessmentItemMutationVariables>;

/**
 * __useUpdateAssessmentItemMutation__
 *
 * To run a mutation, you first call `useUpdateAssessmentItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssessmentItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssessmentItemMutation, { data, loading, error }] = useUpdateAssessmentItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updated: // value for 'updated'
 *   },
 * });
 */
export function useUpdateAssessmentItemMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateAssessmentItemMutation, Types.UpdateAssessmentItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateAssessmentItemMutation, Types.UpdateAssessmentItemMutationVariables>(UpdateAssessmentItemDocument, options);
      }
export type UpdateAssessmentItemMutationHookResult = ReturnType<typeof useUpdateAssessmentItemMutation>;
export type UpdateAssessmentItemMutationResult = Apollo.MutationResult<Types.UpdateAssessmentItemMutation>;
export type UpdateAssessmentItemMutationOptions = Apollo.BaseMutationOptions<Types.UpdateAssessmentItemMutation, Types.UpdateAssessmentItemMutationVariables>;
export const SoftDeleteAssessmentItemDocument = gql`
    mutation softDeleteAssessmentItem($id: uuid!) {
  update_assessment_items_by_pk(
    pk_columns: {id: $id}
    _set: {deleted_at: "NOW()"}
  ) {
    __typename
    id
    deleted_at
  }
}
    `;
export type SoftDeleteAssessmentItemMutationFn = Apollo.MutationFunction<Types.SoftDeleteAssessmentItemMutation, Types.SoftDeleteAssessmentItemMutationVariables>;

/**
 * __useSoftDeleteAssessmentItemMutation__
 *
 * To run a mutation, you first call `useSoftDeleteAssessmentItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoftDeleteAssessmentItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [softDeleteAssessmentItemMutation, { data, loading, error }] = useSoftDeleteAssessmentItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSoftDeleteAssessmentItemMutation(baseOptions?: Apollo.MutationHookOptions<Types.SoftDeleteAssessmentItemMutation, Types.SoftDeleteAssessmentItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SoftDeleteAssessmentItemMutation, Types.SoftDeleteAssessmentItemMutationVariables>(SoftDeleteAssessmentItemDocument, options);
      }
export type SoftDeleteAssessmentItemMutationHookResult = ReturnType<typeof useSoftDeleteAssessmentItemMutation>;
export type SoftDeleteAssessmentItemMutationResult = Apollo.MutationResult<Types.SoftDeleteAssessmentItemMutation>;
export type SoftDeleteAssessmentItemMutationOptions = Apollo.BaseMutationOptions<Types.SoftDeleteAssessmentItemMutation, Types.SoftDeleteAssessmentItemMutationVariables>;
export const UpsertAssessmentItemDocument = gql`
    mutation upsertAssessmentItem($id: uuid!, $updateColumns: [assessment_items_update_column!]!) {
  insert_assessment_items_one(
    object: {id: $id}
    on_conflict: {constraint: assessment_items_pkey, update_columns: $updateColumns}
  ) {
    id
  }
}
    `;
export type UpsertAssessmentItemMutationFn = Apollo.MutationFunction<Types.UpsertAssessmentItemMutation, Types.UpsertAssessmentItemMutationVariables>;

/**
 * __useUpsertAssessmentItemMutation__
 *
 * To run a mutation, you first call `useUpsertAssessmentItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertAssessmentItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertAssessmentItemMutation, { data, loading, error }] = useUpsertAssessmentItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updateColumns: // value for 'updateColumns'
 *   },
 * });
 */
export function useUpsertAssessmentItemMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpsertAssessmentItemMutation, Types.UpsertAssessmentItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpsertAssessmentItemMutation, Types.UpsertAssessmentItemMutationVariables>(UpsertAssessmentItemDocument, options);
      }
export type UpsertAssessmentItemMutationHookResult = ReturnType<typeof useUpsertAssessmentItemMutation>;
export type UpsertAssessmentItemMutationResult = Apollo.MutationResult<Types.UpsertAssessmentItemMutation>;
export type UpsertAssessmentItemMutationOptions = Apollo.BaseMutationOptions<Types.UpsertAssessmentItemMutation, Types.UpsertAssessmentItemMutationVariables>;
export const SetAssessmentNodeAreaTagsDocument = gql`
    mutation setAssessmentNodeAreaTags($assessmentId: Int!, $nodeId: uuid!, $ids: [Int!]!) {
  notPresentAreaTags: update_assessment_node_area_tags(
    where: {_and: {assessment_id: {_eq: $assessmentId}, node_id: {_eq: $nodeId}, area_tag_id: {_nin: $ids}}}
    _set: {is_present: false}
  ) {
    returning {
      id
      area_tag_id
      is_present
    }
  }
  isPresentAreaTags: update_assessment_node_area_tags(
    where: {_and: {assessment_id: {_eq: $assessmentId}, node_id: {_eq: $nodeId}, area_tag_id: {_in: $ids}}}
    _set: {is_present: true}
  ) {
    returning {
      id
      area_tag_id
      is_present
    }
  }
}
    `;
export type SetAssessmentNodeAreaTagsMutationFn = Apollo.MutationFunction<Types.SetAssessmentNodeAreaTagsMutation, Types.SetAssessmentNodeAreaTagsMutationVariables>;

/**
 * __useSetAssessmentNodeAreaTagsMutation__
 *
 * To run a mutation, you first call `useSetAssessmentNodeAreaTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAssessmentNodeAreaTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAssessmentNodeAreaTagsMutation, { data, loading, error }] = useSetAssessmentNodeAreaTagsMutation({
 *   variables: {
 *      assessmentId: // value for 'assessmentId'
 *      nodeId: // value for 'nodeId'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useSetAssessmentNodeAreaTagsMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetAssessmentNodeAreaTagsMutation, Types.SetAssessmentNodeAreaTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetAssessmentNodeAreaTagsMutation, Types.SetAssessmentNodeAreaTagsMutationVariables>(SetAssessmentNodeAreaTagsDocument, options);
      }
export type SetAssessmentNodeAreaTagsMutationHookResult = ReturnType<typeof useSetAssessmentNodeAreaTagsMutation>;
export type SetAssessmentNodeAreaTagsMutationResult = Apollo.MutationResult<Types.SetAssessmentNodeAreaTagsMutation>;
export type SetAssessmentNodeAreaTagsMutationOptions = Apollo.BaseMutationOptions<Types.SetAssessmentNodeAreaTagsMutation, Types.SetAssessmentNodeAreaTagsMutationVariables>;
export const EditAssessmentUsersDocument = gql`
    mutation editAssessmentUsers($organizationId: uuid!, $assessmentId: Int!, $addedUsers: [assessment_users_insert_input!]!, $removedUsersIds: [uuid!]) {
  insert_assessment_users(objects: $addedUsers) {
    returning {
      assessment_id
      organization_id
      user_id
    }
  }
  delete_assessment_users(
    where: {assessment_id: {_eq: $assessmentId}, organization_id: {_eq: $organizationId}, user_id: {_in: $removedUsersIds}}
  ) {
    affected_rows
    returning {
      assessment_id
      organization_id
      user_id
    }
  }
}
    `;
export type EditAssessmentUsersMutationFn = Apollo.MutationFunction<Types.EditAssessmentUsersMutation, Types.EditAssessmentUsersMutationVariables>;

/**
 * __useEditAssessmentUsersMutation__
 *
 * To run a mutation, you first call `useEditAssessmentUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAssessmentUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAssessmentUsersMutation, { data, loading, error }] = useEditAssessmentUsersMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      assessmentId: // value for 'assessmentId'
 *      addedUsers: // value for 'addedUsers'
 *      removedUsersIds: // value for 'removedUsersIds'
 *   },
 * });
 */
export function useEditAssessmentUsersMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditAssessmentUsersMutation, Types.EditAssessmentUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditAssessmentUsersMutation, Types.EditAssessmentUsersMutationVariables>(EditAssessmentUsersDocument, options);
      }
export type EditAssessmentUsersMutationHookResult = ReturnType<typeof useEditAssessmentUsersMutation>;
export type EditAssessmentUsersMutationResult = Apollo.MutationResult<Types.EditAssessmentUsersMutation>;
export type EditAssessmentUsersMutationOptions = Apollo.BaseMutationOptions<Types.EditAssessmentUsersMutation, Types.EditAssessmentUsersMutationVariables>;
export const UpdateCommentContentDocument = gql`
    mutation UpdateCommentContent($id: uuid!, $content: String!) {
  update_comments_by_pk(pk_columns: {id: $id}, _set: {content: $content}) {
    id
    updated_at
    ...CommentContent
  }
}
    ${CommentContentFragmentDoc}`;
export type UpdateCommentContentMutationFn = Apollo.MutationFunction<Types.UpdateCommentContentMutation, Types.UpdateCommentContentMutationVariables>;

/**
 * __useUpdateCommentContentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentContentMutation, { data, loading, error }] = useUpdateCommentContentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useUpdateCommentContentMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCommentContentMutation, Types.UpdateCommentContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCommentContentMutation, Types.UpdateCommentContentMutationVariables>(UpdateCommentContentDocument, options);
      }
export type UpdateCommentContentMutationHookResult = ReturnType<typeof useUpdateCommentContentMutation>;
export type UpdateCommentContentMutationResult = Apollo.MutationResult<Types.UpdateCommentContentMutation>;
export type UpdateCommentContentMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCommentContentMutation, Types.UpdateCommentContentMutationVariables>;
export const ReplyToCommentDocument = gql`
    mutation ReplyToComment($reply: comments_insert_input!) {
  insert_comments_one(object: $reply) {
    parent_id
    ...Comment
  }
}
    ${CommentFragmentDoc}`;
export type ReplyToCommentMutationFn = Apollo.MutationFunction<Types.ReplyToCommentMutation, Types.ReplyToCommentMutationVariables>;

/**
 * __useReplyToCommentMutation__
 *
 * To run a mutation, you first call `useReplyToCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplyToCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replyToCommentMutation, { data, loading, error }] = useReplyToCommentMutation({
 *   variables: {
 *      reply: // value for 'reply'
 *   },
 * });
 */
export function useReplyToCommentMutation(baseOptions?: Apollo.MutationHookOptions<Types.ReplyToCommentMutation, Types.ReplyToCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ReplyToCommentMutation, Types.ReplyToCommentMutationVariables>(ReplyToCommentDocument, options);
      }
export type ReplyToCommentMutationHookResult = ReturnType<typeof useReplyToCommentMutation>;
export type ReplyToCommentMutationResult = Apollo.MutationResult<Types.ReplyToCommentMutation>;
export type ReplyToCommentMutationOptions = Apollo.BaseMutationOptions<Types.ReplyToCommentMutation, Types.ReplyToCommentMutationVariables>;
export const NewCommentDocument = gql`
    mutation NewComment($comment: comments_insert_input!) {
  insert_comments_one(object: $comment) {
    ...Comment
  }
}
    ${CommentFragmentDoc}`;
export type NewCommentMutationFn = Apollo.MutationFunction<Types.NewCommentMutation, Types.NewCommentMutationVariables>;

/**
 * __useNewCommentMutation__
 *
 * To run a mutation, you first call `useNewCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newCommentMutation, { data, loading, error }] = useNewCommentMutation({
 *   variables: {
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useNewCommentMutation(baseOptions?: Apollo.MutationHookOptions<Types.NewCommentMutation, Types.NewCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.NewCommentMutation, Types.NewCommentMutationVariables>(NewCommentDocument, options);
      }
export type NewCommentMutationHookResult = ReturnType<typeof useNewCommentMutation>;
export type NewCommentMutationResult = Apollo.MutationResult<Types.NewCommentMutation>;
export type NewCommentMutationOptions = Apollo.BaseMutationOptions<Types.NewCommentMutation, Types.NewCommentMutationVariables>;
export const CreateConsiderationOptionItemCategoriesDocument = gql`
    mutation createConsiderationOptionItemCategories($input: [consideration_option_item_categories_insert_input!]!) {
  insert_consideration_option_item_categories(objects: $input) {
    ...considerationOptionItemCategoryMutationReturn
  }
}
    ${ConsiderationOptionItemCategoryMutationReturnFragmentDoc}`;
export type CreateConsiderationOptionItemCategoriesMutationFn = Apollo.MutationFunction<Types.CreateConsiderationOptionItemCategoriesMutation, Types.CreateConsiderationOptionItemCategoriesMutationVariables>;

/**
 * __useCreateConsiderationOptionItemCategoriesMutation__
 *
 * To run a mutation, you first call `useCreateConsiderationOptionItemCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConsiderationOptionItemCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConsiderationOptionItemCategoriesMutation, { data, loading, error }] = useCreateConsiderationOptionItemCategoriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConsiderationOptionItemCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateConsiderationOptionItemCategoriesMutation, Types.CreateConsiderationOptionItemCategoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateConsiderationOptionItemCategoriesMutation, Types.CreateConsiderationOptionItemCategoriesMutationVariables>(CreateConsiderationOptionItemCategoriesDocument, options);
      }
export type CreateConsiderationOptionItemCategoriesMutationHookResult = ReturnType<typeof useCreateConsiderationOptionItemCategoriesMutation>;
export type CreateConsiderationOptionItemCategoriesMutationResult = Apollo.MutationResult<Types.CreateConsiderationOptionItemCategoriesMutation>;
export type CreateConsiderationOptionItemCategoriesMutationOptions = Apollo.BaseMutationOptions<Types.CreateConsiderationOptionItemCategoriesMutation, Types.CreateConsiderationOptionItemCategoriesMutationVariables>;
export const DeleteConsiderationOptionItemCategoriesDocument = gql`
    mutation deleteConsiderationOptionItemCategories($organizationId: uuid!, $considerationOptionId: Int!, $itemCategoryIds: [uuid!]!) {
  delete_consideration_option_item_categories(
    where: {organization_id: {_eq: $organizationId}, consideration_option_id: {_eq: $considerationOptionId}, item_category_id: {_in: $itemCategoryIds}}
  ) {
    ...considerationOptionItemCategoryMutationReturn
  }
}
    ${ConsiderationOptionItemCategoryMutationReturnFragmentDoc}`;
export type DeleteConsiderationOptionItemCategoriesMutationFn = Apollo.MutationFunction<Types.DeleteConsiderationOptionItemCategoriesMutation, Types.DeleteConsiderationOptionItemCategoriesMutationVariables>;

/**
 * __useDeleteConsiderationOptionItemCategoriesMutation__
 *
 * To run a mutation, you first call `useDeleteConsiderationOptionItemCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConsiderationOptionItemCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConsiderationOptionItemCategoriesMutation, { data, loading, error }] = useDeleteConsiderationOptionItemCategoriesMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      considerationOptionId: // value for 'considerationOptionId'
 *      itemCategoryIds: // value for 'itemCategoryIds'
 *   },
 * });
 */
export function useDeleteConsiderationOptionItemCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteConsiderationOptionItemCategoriesMutation, Types.DeleteConsiderationOptionItemCategoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteConsiderationOptionItemCategoriesMutation, Types.DeleteConsiderationOptionItemCategoriesMutationVariables>(DeleteConsiderationOptionItemCategoriesDocument, options);
      }
export type DeleteConsiderationOptionItemCategoriesMutationHookResult = ReturnType<typeof useDeleteConsiderationOptionItemCategoriesMutation>;
export type DeleteConsiderationOptionItemCategoriesMutationResult = Apollo.MutationResult<Types.DeleteConsiderationOptionItemCategoriesMutation>;
export type DeleteConsiderationOptionItemCategoriesMutationOptions = Apollo.BaseMutationOptions<Types.DeleteConsiderationOptionItemCategoriesMutation, Types.DeleteConsiderationOptionItemCategoriesMutationVariables>;
export const CreateConsiderationOptionNodesDocument = gql`
    mutation createConsiderationOptionNodes($input: [consideration_option_nodes_insert_input!]!) {
  insert_consideration_option_nodes(objects: $input) {
    ...considerationOptionNodeMutationReturn
  }
}
    ${ConsiderationOptionNodeMutationReturnFragmentDoc}`;
export type CreateConsiderationOptionNodesMutationFn = Apollo.MutationFunction<Types.CreateConsiderationOptionNodesMutation, Types.CreateConsiderationOptionNodesMutationVariables>;

/**
 * __useCreateConsiderationOptionNodesMutation__
 *
 * To run a mutation, you first call `useCreateConsiderationOptionNodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConsiderationOptionNodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConsiderationOptionNodesMutation, { data, loading, error }] = useCreateConsiderationOptionNodesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConsiderationOptionNodesMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateConsiderationOptionNodesMutation, Types.CreateConsiderationOptionNodesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateConsiderationOptionNodesMutation, Types.CreateConsiderationOptionNodesMutationVariables>(CreateConsiderationOptionNodesDocument, options);
      }
export type CreateConsiderationOptionNodesMutationHookResult = ReturnType<typeof useCreateConsiderationOptionNodesMutation>;
export type CreateConsiderationOptionNodesMutationResult = Apollo.MutationResult<Types.CreateConsiderationOptionNodesMutation>;
export type CreateConsiderationOptionNodesMutationOptions = Apollo.BaseMutationOptions<Types.CreateConsiderationOptionNodesMutation, Types.CreateConsiderationOptionNodesMutationVariables>;
export const DeleteConsiderationOptionNodesDocument = gql`
    mutation deleteConsiderationOptionNodes($organizationId: uuid!, $considerationOptionId: Int!, $nodeIds: [uuid!]!) {
  delete_consideration_option_nodes(
    where: {organization_id: {_eq: $organizationId}, consideration_option_id: {_eq: $considerationOptionId}, node_id: {_in: $nodeIds}}
  ) {
    ...considerationOptionNodeMutationReturn
  }
}
    ${ConsiderationOptionNodeMutationReturnFragmentDoc}`;
export type DeleteConsiderationOptionNodesMutationFn = Apollo.MutationFunction<Types.DeleteConsiderationOptionNodesMutation, Types.DeleteConsiderationOptionNodesMutationVariables>;

/**
 * __useDeleteConsiderationOptionNodesMutation__
 *
 * To run a mutation, you first call `useDeleteConsiderationOptionNodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConsiderationOptionNodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConsiderationOptionNodesMutation, { data, loading, error }] = useDeleteConsiderationOptionNodesMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      considerationOptionId: // value for 'considerationOptionId'
 *      nodeIds: // value for 'nodeIds'
 *   },
 * });
 */
export function useDeleteConsiderationOptionNodesMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteConsiderationOptionNodesMutation, Types.DeleteConsiderationOptionNodesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteConsiderationOptionNodesMutation, Types.DeleteConsiderationOptionNodesMutationVariables>(DeleteConsiderationOptionNodesDocument, options);
      }
export type DeleteConsiderationOptionNodesMutationHookResult = ReturnType<typeof useDeleteConsiderationOptionNodesMutation>;
export type DeleteConsiderationOptionNodesMutationResult = Apollo.MutationResult<Types.DeleteConsiderationOptionNodesMutation>;
export type DeleteConsiderationOptionNodesMutationOptions = Apollo.BaseMutationOptions<Types.DeleteConsiderationOptionNodesMutation, Types.DeleteConsiderationOptionNodesMutationVariables>;
export const UpdateConsiderationOptionDocument = gql`
    mutation updateConsiderationOption($organizationId: uuid!, $considerationOptionId: Int!, $input: consideration_options_set_input!) {
  update_consideration_options_by_pk(
    pk_columns: {id: $considerationOptionId, organization_id: $organizationId}
    _set: $input
  ) {
    ...considerationOptionInfo
  }
}
    ${ConsiderationOptionInfoFragmentDoc}`;
export type UpdateConsiderationOptionMutationFn = Apollo.MutationFunction<Types.UpdateConsiderationOptionMutation, Types.UpdateConsiderationOptionMutationVariables>;

/**
 * __useUpdateConsiderationOptionMutation__
 *
 * To run a mutation, you first call `useUpdateConsiderationOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConsiderationOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConsiderationOptionMutation, { data, loading, error }] = useUpdateConsiderationOptionMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      considerationOptionId: // value for 'considerationOptionId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConsiderationOptionMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateConsiderationOptionMutation, Types.UpdateConsiderationOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateConsiderationOptionMutation, Types.UpdateConsiderationOptionMutationVariables>(UpdateConsiderationOptionDocument, options);
      }
export type UpdateConsiderationOptionMutationHookResult = ReturnType<typeof useUpdateConsiderationOptionMutation>;
export type UpdateConsiderationOptionMutationResult = Apollo.MutationResult<Types.UpdateConsiderationOptionMutation>;
export type UpdateConsiderationOptionMutationOptions = Apollo.BaseMutationOptions<Types.UpdateConsiderationOptionMutation, Types.UpdateConsiderationOptionMutationVariables>;
export const DeleteConsiderationOptionDocument = gql`
    mutation deleteConsiderationOption($id: Int!, $organizationId: uuid!) {
  delete_consideration_options_by_pk(id: $id, organization_id: $organizationId) {
    organization_id
    id
  }
}
    `;
export type DeleteConsiderationOptionMutationFn = Apollo.MutationFunction<Types.DeleteConsiderationOptionMutation, Types.DeleteConsiderationOptionMutationVariables>;

/**
 * __useDeleteConsiderationOptionMutation__
 *
 * To run a mutation, you first call `useDeleteConsiderationOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConsiderationOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConsiderationOptionMutation, { data, loading, error }] = useDeleteConsiderationOptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useDeleteConsiderationOptionMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteConsiderationOptionMutation, Types.DeleteConsiderationOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteConsiderationOptionMutation, Types.DeleteConsiderationOptionMutationVariables>(DeleteConsiderationOptionDocument, options);
      }
export type DeleteConsiderationOptionMutationHookResult = ReturnType<typeof useDeleteConsiderationOptionMutation>;
export type DeleteConsiderationOptionMutationResult = Apollo.MutationResult<Types.DeleteConsiderationOptionMutation>;
export type DeleteConsiderationOptionMutationOptions = Apollo.BaseMutationOptions<Types.DeleteConsiderationOptionMutation, Types.DeleteConsiderationOptionMutationVariables>;
export const SaveAssessmentOptionToLibraryOfcDocument = gql`
    mutation saveAssessmentOptionToLibraryOFC($optionId: uuid!, $name: String!, $type: String!, $description: String, $answerId: uuid!, $locationIds: _uuid!, $itemCategoryId: uuid!) {
  fn_save_assessment_freeform_option_to_library(
    args: {_option_id: $optionId, _name: $name, _type: $type, _description: $description, _answer_id: $answerId, _location_ids: $locationIds, _item_category_id: $itemCategoryId}
  ) {
    ...considerationOptionInfo
  }
}
    ${ConsiderationOptionInfoFragmentDoc}`;
export type SaveAssessmentOptionToLibraryOfcMutationFn = Apollo.MutationFunction<Types.SaveAssessmentOptionToLibraryOfcMutation, Types.SaveAssessmentOptionToLibraryOfcMutationVariables>;

/**
 * __useSaveAssessmentOptionToLibraryOfcMutation__
 *
 * To run a mutation, you first call `useSaveAssessmentOptionToLibraryOfcMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAssessmentOptionToLibraryOfcMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAssessmentOptionToLibraryOfcMutation, { data, loading, error }] = useSaveAssessmentOptionToLibraryOfcMutation({
 *   variables: {
 *      optionId: // value for 'optionId'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      description: // value for 'description'
 *      answerId: // value for 'answerId'
 *      locationIds: // value for 'locationIds'
 *      itemCategoryId: // value for 'itemCategoryId'
 *   },
 * });
 */
export function useSaveAssessmentOptionToLibraryOfcMutation(baseOptions?: Apollo.MutationHookOptions<Types.SaveAssessmentOptionToLibraryOfcMutation, Types.SaveAssessmentOptionToLibraryOfcMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SaveAssessmentOptionToLibraryOfcMutation, Types.SaveAssessmentOptionToLibraryOfcMutationVariables>(SaveAssessmentOptionToLibraryOfcDocument, options);
      }
export type SaveAssessmentOptionToLibraryOfcMutationHookResult = ReturnType<typeof useSaveAssessmentOptionToLibraryOfcMutation>;
export type SaveAssessmentOptionToLibraryOfcMutationResult = Apollo.MutationResult<Types.SaveAssessmentOptionToLibraryOfcMutation>;
export type SaveAssessmentOptionToLibraryOfcMutationOptions = Apollo.BaseMutationOptions<Types.SaveAssessmentOptionToLibraryOfcMutation, Types.SaveAssessmentOptionToLibraryOfcMutationVariables>;
export const UpdateFileNameDocument = gql`
    mutation updateFileName($id: uuid!, $name: String!) {
  update_files_by_pk(pk_columns: {id: $id}, _set: {original_filename: $name}) {
    original_filename
    id
  }
}
    `;
export type UpdateFileNameMutationFn = Apollo.MutationFunction<Types.UpdateFileNameMutation, Types.UpdateFileNameMutationVariables>;

/**
 * __useUpdateFileNameMutation__
 *
 * To run a mutation, you first call `useUpdateFileNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFileNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFileNameMutation, { data, loading, error }] = useUpdateFileNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateFileNameMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateFileNameMutation, Types.UpdateFileNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateFileNameMutation, Types.UpdateFileNameMutationVariables>(UpdateFileNameDocument, options);
      }
export type UpdateFileNameMutationHookResult = ReturnType<typeof useUpdateFileNameMutation>;
export type UpdateFileNameMutationResult = Apollo.MutationResult<Types.UpdateFileNameMutation>;
export type UpdateFileNameMutationOptions = Apollo.BaseMutationOptions<Types.UpdateFileNameMutation, Types.UpdateFileNameMutationVariables>;
export const DeleteFilterDocument = gql`
    mutation deleteFilter($organizationId: uuid!, $filterId: Int!) {
  delete_filters_by_pk(id: $filterId, organization_id: $organizationId) {
    id
  }
}
    `;
export type DeleteFilterMutationFn = Apollo.MutationFunction<Types.DeleteFilterMutation, Types.DeleteFilterMutationVariables>;

/**
 * __useDeleteFilterMutation__
 *
 * To run a mutation, you first call `useDeleteFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFilterMutation, { data, loading, error }] = useDeleteFilterMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      filterId: // value for 'filterId'
 *   },
 * });
 */
export function useDeleteFilterMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteFilterMutation, Types.DeleteFilterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteFilterMutation, Types.DeleteFilterMutationVariables>(DeleteFilterDocument, options);
      }
export type DeleteFilterMutationHookResult = ReturnType<typeof useDeleteFilterMutation>;
export type DeleteFilterMutationResult = Apollo.MutationResult<Types.DeleteFilterMutation>;
export type DeleteFilterMutationOptions = Apollo.BaseMutationOptions<Types.DeleteFilterMutation, Types.DeleteFilterMutationVariables>;
export const SaveNodesFilterDocument = gql`
    mutation saveNodesFilter($filterObj: jsonb!, $name: String!, $isPrivate: Boolean!, $description: String) {
  insert_filters_one(
    object: {type: nodes, filter_obj: $filterObj, name: $name, description: $description, is_private_for_user: $isPrivate}
  ) {
    id
    organization_id
  }
}
    `;
export type SaveNodesFilterMutationFn = Apollo.MutationFunction<Types.SaveNodesFilterMutation, Types.SaveNodesFilterMutationVariables>;

/**
 * __useSaveNodesFilterMutation__
 *
 * To run a mutation, you first call `useSaveNodesFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveNodesFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveNodesFilterMutation, { data, loading, error }] = useSaveNodesFilterMutation({
 *   variables: {
 *      filterObj: // value for 'filterObj'
 *      name: // value for 'name'
 *      isPrivate: // value for 'isPrivate'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useSaveNodesFilterMutation(baseOptions?: Apollo.MutationHookOptions<Types.SaveNodesFilterMutation, Types.SaveNodesFilterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SaveNodesFilterMutation, Types.SaveNodesFilterMutationVariables>(SaveNodesFilterDocument, options);
      }
export type SaveNodesFilterMutationHookResult = ReturnType<typeof useSaveNodesFilterMutation>;
export type SaveNodesFilterMutationResult = Apollo.MutationResult<Types.SaveNodesFilterMutation>;
export type SaveNodesFilterMutationOptions = Apollo.BaseMutationOptions<Types.SaveNodesFilterMutation, Types.SaveNodesFilterMutationVariables>;
export const UpdateNodesFilterDocument = gql`
    mutation updateNodesFilter($organizationId: uuid!, $filterId: Int!, $filterObj: jsonb!, $name: String!, $isPrivate: Boolean!, $description: String) {
  update_filters_by_pk(
    pk_columns: {organization_id: $organizationId, id: $filterId}
    _set: {filter_obj: $filterObj, name: $name, description: $description, is_private_for_user: $isPrivate}
  ) {
    id
    name
    description
    filter_obj
    is_private_for_user
  }
}
    `;
export type UpdateNodesFilterMutationFn = Apollo.MutationFunction<Types.UpdateNodesFilterMutation, Types.UpdateNodesFilterMutationVariables>;

/**
 * __useUpdateNodesFilterMutation__
 *
 * To run a mutation, you first call `useUpdateNodesFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNodesFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNodesFilterMutation, { data, loading, error }] = useUpdateNodesFilterMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      filterId: // value for 'filterId'
 *      filterObj: // value for 'filterObj'
 *      name: // value for 'name'
 *      isPrivate: // value for 'isPrivate'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateNodesFilterMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateNodesFilterMutation, Types.UpdateNodesFilterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateNodesFilterMutation, Types.UpdateNodesFilterMutationVariables>(UpdateNodesFilterDocument, options);
      }
export type UpdateNodesFilterMutationHookResult = ReturnType<typeof useUpdateNodesFilterMutation>;
export type UpdateNodesFilterMutationResult = Apollo.MutationResult<Types.UpdateNodesFilterMutation>;
export type UpdateNodesFilterMutationOptions = Apollo.BaseMutationOptions<Types.UpdateNodesFilterMutation, Types.UpdateNodesFilterMutationVariables>;
export const CreateUserGroupDocument = gql`
    mutation createUserGroup($name: String!) {
  insert_groups_one(object: {name: $name}) {
    ...UserGroup
  }
}
    ${UserGroupFragmentDoc}`;
export type CreateUserGroupMutationFn = Apollo.MutationFunction<Types.CreateUserGroupMutation, Types.CreateUserGroupMutationVariables>;

/**
 * __useCreateUserGroupMutation__
 *
 * To run a mutation, you first call `useCreateUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserGroupMutation, { data, loading, error }] = useCreateUserGroupMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateUserGroupMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateUserGroupMutation, Types.CreateUserGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateUserGroupMutation, Types.CreateUserGroupMutationVariables>(CreateUserGroupDocument, options);
      }
export type CreateUserGroupMutationHookResult = ReturnType<typeof useCreateUserGroupMutation>;
export type CreateUserGroupMutationResult = Apollo.MutationResult<Types.CreateUserGroupMutation>;
export type CreateUserGroupMutationOptions = Apollo.BaseMutationOptions<Types.CreateUserGroupMutation, Types.CreateUserGroupMutationVariables>;
export const RenameUserGroupDocument = gql`
    mutation renameUserGroup($groupId: Int!, $organizationId: uuid!, $newName: String!) {
  update_groups_by_pk(
    pk_columns: {organization_id: $organizationId, id: $groupId}
    _set: {name: $newName}
  ) {
    ...UserGroup
  }
}
    ${UserGroupFragmentDoc}`;
export type RenameUserGroupMutationFn = Apollo.MutationFunction<Types.RenameUserGroupMutation, Types.RenameUserGroupMutationVariables>;

/**
 * __useRenameUserGroupMutation__
 *
 * To run a mutation, you first call `useRenameUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameUserGroupMutation, { data, loading, error }] = useRenameUserGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      organizationId: // value for 'organizationId'
 *      newName: // value for 'newName'
 *   },
 * });
 */
export function useRenameUserGroupMutation(baseOptions?: Apollo.MutationHookOptions<Types.RenameUserGroupMutation, Types.RenameUserGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RenameUserGroupMutation, Types.RenameUserGroupMutationVariables>(RenameUserGroupDocument, options);
      }
export type RenameUserGroupMutationHookResult = ReturnType<typeof useRenameUserGroupMutation>;
export type RenameUserGroupMutationResult = Apollo.MutationResult<Types.RenameUserGroupMutation>;
export type RenameUserGroupMutationOptions = Apollo.BaseMutationOptions<Types.RenameUserGroupMutation, Types.RenameUserGroupMutationVariables>;
export const DeleteUserGroupDocument = gql`
    mutation deleteUserGroup($groupId: Int!, $organizationId: uuid!) {
  delete_groups_by_pk(id: $groupId, organization_id: $organizationId) {
    ...UserGroup
  }
}
    ${UserGroupFragmentDoc}`;
export type DeleteUserGroupMutationFn = Apollo.MutationFunction<Types.DeleteUserGroupMutation, Types.DeleteUserGroupMutationVariables>;

/**
 * __useDeleteUserGroupMutation__
 *
 * To run a mutation, you first call `useDeleteUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserGroupMutation, { data, loading, error }] = useDeleteUserGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useDeleteUserGroupMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteUserGroupMutation, Types.DeleteUserGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteUserGroupMutation, Types.DeleteUserGroupMutationVariables>(DeleteUserGroupDocument, options);
      }
export type DeleteUserGroupMutationHookResult = ReturnType<typeof useDeleteUserGroupMutation>;
export type DeleteUserGroupMutationResult = Apollo.MutationResult<Types.DeleteUserGroupMutation>;
export type DeleteUserGroupMutationOptions = Apollo.BaseMutationOptions<Types.DeleteUserGroupMutation, Types.DeleteUserGroupMutationVariables>;
export const AddUsersToGroupDocument = gql`
    mutation addUsersToGroup($objects: [group_users_insert_input!]!) {
  insert_group_users(objects: $objects) {
    returning {
      ...UserGroupUser
    }
  }
}
    ${UserGroupUserFragmentDoc}`;
export type AddUsersToGroupMutationFn = Apollo.MutationFunction<Types.AddUsersToGroupMutation, Types.AddUsersToGroupMutationVariables>;

/**
 * __useAddUsersToGroupMutation__
 *
 * To run a mutation, you first call `useAddUsersToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUsersToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUsersToGroupMutation, { data, loading, error }] = useAddUsersToGroupMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddUsersToGroupMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddUsersToGroupMutation, Types.AddUsersToGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddUsersToGroupMutation, Types.AddUsersToGroupMutationVariables>(AddUsersToGroupDocument, options);
      }
export type AddUsersToGroupMutationHookResult = ReturnType<typeof useAddUsersToGroupMutation>;
export type AddUsersToGroupMutationResult = Apollo.MutationResult<Types.AddUsersToGroupMutation>;
export type AddUsersToGroupMutationOptions = Apollo.BaseMutationOptions<Types.AddUsersToGroupMutation, Types.AddUsersToGroupMutationVariables>;
export const RemoveGroupUserDocument = gql`
    mutation removeGroupUser($groupId: Int!, $organizationId: uuid!, $userId: uuid!) {
  delete_group_users(
    where: {group_id: {_eq: $groupId}, organization_id: {_eq: $organizationId}, user_id: {_eq: $userId}}
  ) {
    returning {
      ...UserGroupUser
    }
  }
}
    ${UserGroupUserFragmentDoc}`;
export type RemoveGroupUserMutationFn = Apollo.MutationFunction<Types.RemoveGroupUserMutation, Types.RemoveGroupUserMutationVariables>;

/**
 * __useRemoveGroupUserMutation__
 *
 * To run a mutation, you first call `useRemoveGroupUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGroupUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGroupUserMutation, { data, loading, error }] = useRemoveGroupUserMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveGroupUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveGroupUserMutation, Types.RemoveGroupUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveGroupUserMutation, Types.RemoveGroupUserMutationVariables>(RemoveGroupUserDocument, options);
      }
export type RemoveGroupUserMutationHookResult = ReturnType<typeof useRemoveGroupUserMutation>;
export type RemoveGroupUserMutationResult = Apollo.MutationResult<Types.RemoveGroupUserMutation>;
export type RemoveGroupUserMutationOptions = Apollo.BaseMutationOptions<Types.RemoveGroupUserMutation, Types.RemoveGroupUserMutationVariables>;
export const CreateItemCategoryDocument = gql`
    mutation createItemCategory($input: item_categories_insert_input!) {
  insert_item_categories_one(object: $input) {
    ...ItemCategory
  }
}
    ${ItemCategoryFragmentDoc}`;
export type CreateItemCategoryMutationFn = Apollo.MutationFunction<Types.CreateItemCategoryMutation, Types.CreateItemCategoryMutationVariables>;

/**
 * __useCreateItemCategoryMutation__
 *
 * To run a mutation, you first call `useCreateItemCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItemCategoryMutation, { data, loading, error }] = useCreateItemCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateItemCategoryMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateItemCategoryMutation, Types.CreateItemCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateItemCategoryMutation, Types.CreateItemCategoryMutationVariables>(CreateItemCategoryDocument, options);
      }
export type CreateItemCategoryMutationHookResult = ReturnType<typeof useCreateItemCategoryMutation>;
export type CreateItemCategoryMutationResult = Apollo.MutationResult<Types.CreateItemCategoryMutation>;
export type CreateItemCategoryMutationOptions = Apollo.BaseMutationOptions<Types.CreateItemCategoryMutation, Types.CreateItemCategoryMutationVariables>;
export const CreateItemCategoriesAndQuestionsDocument = gql`
    mutation createItemCategoriesAndQuestions($input: [item_categories_insert_input!]!) {
  insert_item_categories(
    objects: $input
    on_conflict: {constraint: unique_name_per_organization, update_columns: [name]}
  ) {
    returning {
      ...ItemCategory
      questions {
        ...Question
      }
    }
  }
}
    ${ItemCategoryFragmentDoc}
${QuestionFragmentDoc}`;
export type CreateItemCategoriesAndQuestionsMutationFn = Apollo.MutationFunction<Types.CreateItemCategoriesAndQuestionsMutation, Types.CreateItemCategoriesAndQuestionsMutationVariables>;

/**
 * __useCreateItemCategoriesAndQuestionsMutation__
 *
 * To run a mutation, you first call `useCreateItemCategoriesAndQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemCategoriesAndQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItemCategoriesAndQuestionsMutation, { data, loading, error }] = useCreateItemCategoriesAndQuestionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateItemCategoriesAndQuestionsMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateItemCategoriesAndQuestionsMutation, Types.CreateItemCategoriesAndQuestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateItemCategoriesAndQuestionsMutation, Types.CreateItemCategoriesAndQuestionsMutationVariables>(CreateItemCategoriesAndQuestionsDocument, options);
      }
export type CreateItemCategoriesAndQuestionsMutationHookResult = ReturnType<typeof useCreateItemCategoriesAndQuestionsMutation>;
export type CreateItemCategoriesAndQuestionsMutationResult = Apollo.MutationResult<Types.CreateItemCategoriesAndQuestionsMutation>;
export type CreateItemCategoriesAndQuestionsMutationOptions = Apollo.BaseMutationOptions<Types.CreateItemCategoriesAndQuestionsMutation, Types.CreateItemCategoriesAndQuestionsMutationVariables>;
export const UpdateItemCategoryDocument = gql`
    mutation updateItemCategory($id: uuid!, $input: item_categories_set_input!) {
  update_item_categories_by_pk(pk_columns: {id: $id}, _set: $input) {
    id
    updated_at
  }
}
    `;
export type UpdateItemCategoryMutationFn = Apollo.MutationFunction<Types.UpdateItemCategoryMutation, Types.UpdateItemCategoryMutationVariables>;

/**
 * __useUpdateItemCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateItemCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemCategoryMutation, { data, loading, error }] = useUpdateItemCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateItemCategoryMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateItemCategoryMutation, Types.UpdateItemCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateItemCategoryMutation, Types.UpdateItemCategoryMutationVariables>(UpdateItemCategoryDocument, options);
      }
export type UpdateItemCategoryMutationHookResult = ReturnType<typeof useUpdateItemCategoryMutation>;
export type UpdateItemCategoryMutationResult = Apollo.MutationResult<Types.UpdateItemCategoryMutation>;
export type UpdateItemCategoryMutationOptions = Apollo.BaseMutationOptions<Types.UpdateItemCategoryMutation, Types.UpdateItemCategoryMutationVariables>;
export const DeleteItemCategoryDocument = gql`
    mutation deleteItemCategory($id: uuid!) {
  delete_item_categories_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteItemCategoryMutationFn = Apollo.MutationFunction<Types.DeleteItemCategoryMutation, Types.DeleteItemCategoryMutationVariables>;

/**
 * __useDeleteItemCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteItemCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteItemCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteItemCategoryMutation, { data, loading, error }] = useDeleteItemCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteItemCategoryMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteItemCategoryMutation, Types.DeleteItemCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteItemCategoryMutation, Types.DeleteItemCategoryMutationVariables>(DeleteItemCategoryDocument, options);
      }
export type DeleteItemCategoryMutationHookResult = ReturnType<typeof useDeleteItemCategoryMutation>;
export type DeleteItemCategoryMutationResult = Apollo.MutationResult<Types.DeleteItemCategoryMutation>;
export type DeleteItemCategoryMutationOptions = Apollo.BaseMutationOptions<Types.DeleteItemCategoryMutation, Types.DeleteItemCategoryMutationVariables>;
export const UpdateItemCategoryAreaTagIdDocument = gql`
    mutation updateItemCategoryAreaTagId($id: uuid!, $area_tag_id: Int) {
  update_item_categories_by_pk(
    pk_columns: {id: $id}
    _set: {area_tag_id: $area_tag_id}
  ) {
    area_tag_id
    id
  }
}
    `;
export type UpdateItemCategoryAreaTagIdMutationFn = Apollo.MutationFunction<Types.UpdateItemCategoryAreaTagIdMutation, Types.UpdateItemCategoryAreaTagIdMutationVariables>;

/**
 * __useUpdateItemCategoryAreaTagIdMutation__
 *
 * To run a mutation, you first call `useUpdateItemCategoryAreaTagIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemCategoryAreaTagIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemCategoryAreaTagIdMutation, { data, loading, error }] = useUpdateItemCategoryAreaTagIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      area_tag_id: // value for 'area_tag_id'
 *   },
 * });
 */
export function useUpdateItemCategoryAreaTagIdMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateItemCategoryAreaTagIdMutation, Types.UpdateItemCategoryAreaTagIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateItemCategoryAreaTagIdMutation, Types.UpdateItemCategoryAreaTagIdMutationVariables>(UpdateItemCategoryAreaTagIdDocument, options);
      }
export type UpdateItemCategoryAreaTagIdMutationHookResult = ReturnType<typeof useUpdateItemCategoryAreaTagIdMutation>;
export type UpdateItemCategoryAreaTagIdMutationResult = Apollo.MutationResult<Types.UpdateItemCategoryAreaTagIdMutation>;
export type UpdateItemCategoryAreaTagIdMutationOptions = Apollo.BaseMutationOptions<Types.UpdateItemCategoryAreaTagIdMutation, Types.UpdateItemCategoryAreaTagIdMutationVariables>;
export const CreateItemCategoryPropertyConfigDocument = gql`
    mutation createItemCategoryPropertyConfig($input: item_category_property_config_insert_input!) {
  insert_item_category_property_config_one(object: $input) {
    ...ItemCategoryPropertyConfig
  }
}
    ${ItemCategoryPropertyConfigFragmentDoc}`;
export type CreateItemCategoryPropertyConfigMutationFn = Apollo.MutationFunction<Types.CreateItemCategoryPropertyConfigMutation, Types.CreateItemCategoryPropertyConfigMutationVariables>;

/**
 * __useCreateItemCategoryPropertyConfigMutation__
 *
 * To run a mutation, you first call `useCreateItemCategoryPropertyConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemCategoryPropertyConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItemCategoryPropertyConfigMutation, { data, loading, error }] = useCreateItemCategoryPropertyConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateItemCategoryPropertyConfigMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateItemCategoryPropertyConfigMutation, Types.CreateItemCategoryPropertyConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateItemCategoryPropertyConfigMutation, Types.CreateItemCategoryPropertyConfigMutationVariables>(CreateItemCategoryPropertyConfigDocument, options);
      }
export type CreateItemCategoryPropertyConfigMutationHookResult = ReturnType<typeof useCreateItemCategoryPropertyConfigMutation>;
export type CreateItemCategoryPropertyConfigMutationResult = Apollo.MutationResult<Types.CreateItemCategoryPropertyConfigMutation>;
export type CreateItemCategoryPropertyConfigMutationOptions = Apollo.BaseMutationOptions<Types.CreateItemCategoryPropertyConfigMutation, Types.CreateItemCategoryPropertyConfigMutationVariables>;
export const UpdateItemCategoryPropertyConfigDocument = gql`
    mutation updateItemCategoryPropertyConfig($id: bigint!, $organizationId: uuid!, $input: item_category_property_config_set_input) {
  update_item_category_property_config_by_pk(
    pk_columns: {id: $id, organization_id: $organizationId}
    _set: $input
  ) {
    id
    group_id
    name
    description
    options
  }
}
    `;
export type UpdateItemCategoryPropertyConfigMutationFn = Apollo.MutationFunction<Types.UpdateItemCategoryPropertyConfigMutation, Types.UpdateItemCategoryPropertyConfigMutationVariables>;

/**
 * __useUpdateItemCategoryPropertyConfigMutation__
 *
 * To run a mutation, you first call `useUpdateItemCategoryPropertyConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemCategoryPropertyConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemCategoryPropertyConfigMutation, { data, loading, error }] = useUpdateItemCategoryPropertyConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *      organizationId: // value for 'organizationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateItemCategoryPropertyConfigMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateItemCategoryPropertyConfigMutation, Types.UpdateItemCategoryPropertyConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateItemCategoryPropertyConfigMutation, Types.UpdateItemCategoryPropertyConfigMutationVariables>(UpdateItemCategoryPropertyConfigDocument, options);
      }
export type UpdateItemCategoryPropertyConfigMutationHookResult = ReturnType<typeof useUpdateItemCategoryPropertyConfigMutation>;
export type UpdateItemCategoryPropertyConfigMutationResult = Apollo.MutationResult<Types.UpdateItemCategoryPropertyConfigMutation>;
export type UpdateItemCategoryPropertyConfigMutationOptions = Apollo.BaseMutationOptions<Types.UpdateItemCategoryPropertyConfigMutation, Types.UpdateItemCategoryPropertyConfigMutationVariables>;
export const DeleteItemCategoryPropertyConfigDocument = gql`
    mutation deleteItemCategoryPropertyConfig($id: bigint!, $organizationId: uuid!) {
  delete_item_category_property_config_by_pk(
    id: $id
    organization_id: $organizationId
  ) {
    id
    group_id
  }
}
    `;
export type DeleteItemCategoryPropertyConfigMutationFn = Apollo.MutationFunction<Types.DeleteItemCategoryPropertyConfigMutation, Types.DeleteItemCategoryPropertyConfigMutationVariables>;

/**
 * __useDeleteItemCategoryPropertyConfigMutation__
 *
 * To run a mutation, you first call `useDeleteItemCategoryPropertyConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteItemCategoryPropertyConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteItemCategoryPropertyConfigMutation, { data, loading, error }] = useDeleteItemCategoryPropertyConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useDeleteItemCategoryPropertyConfigMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteItemCategoryPropertyConfigMutation, Types.DeleteItemCategoryPropertyConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteItemCategoryPropertyConfigMutation, Types.DeleteItemCategoryPropertyConfigMutationVariables>(DeleteItemCategoryPropertyConfigDocument, options);
      }
export type DeleteItemCategoryPropertyConfigMutationHookResult = ReturnType<typeof useDeleteItemCategoryPropertyConfigMutation>;
export type DeleteItemCategoryPropertyConfigMutationResult = Apollo.MutationResult<Types.DeleteItemCategoryPropertyConfigMutation>;
export type DeleteItemCategoryPropertyConfigMutationOptions = Apollo.BaseMutationOptions<Types.DeleteItemCategoryPropertyConfigMutation, Types.DeleteItemCategoryPropertyConfigMutationVariables>;
export const BulkUpdateItemCategoryPropertyConfigDocument = gql`
    mutation bulkUpdateItemCategoryPropertyConfig($updates: [item_category_property_config_updates!]!) {
  update_item_category_property_config_many(updates: $updates) {
    returning {
      organization_id
      group_id
      id
      ordinal
    }
  }
}
    `;
export type BulkUpdateItemCategoryPropertyConfigMutationFn = Apollo.MutationFunction<Types.BulkUpdateItemCategoryPropertyConfigMutation, Types.BulkUpdateItemCategoryPropertyConfigMutationVariables>;

/**
 * __useBulkUpdateItemCategoryPropertyConfigMutation__
 *
 * To run a mutation, you first call `useBulkUpdateItemCategoryPropertyConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateItemCategoryPropertyConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateItemCategoryPropertyConfigMutation, { data, loading, error }] = useBulkUpdateItemCategoryPropertyConfigMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useBulkUpdateItemCategoryPropertyConfigMutation(baseOptions?: Apollo.MutationHookOptions<Types.BulkUpdateItemCategoryPropertyConfigMutation, Types.BulkUpdateItemCategoryPropertyConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.BulkUpdateItemCategoryPropertyConfigMutation, Types.BulkUpdateItemCategoryPropertyConfigMutationVariables>(BulkUpdateItemCategoryPropertyConfigDocument, options);
      }
export type BulkUpdateItemCategoryPropertyConfigMutationHookResult = ReturnType<typeof useBulkUpdateItemCategoryPropertyConfigMutation>;
export type BulkUpdateItemCategoryPropertyConfigMutationResult = Apollo.MutationResult<Types.BulkUpdateItemCategoryPropertyConfigMutation>;
export type BulkUpdateItemCategoryPropertyConfigMutationOptions = Apollo.BaseMutationOptions<Types.BulkUpdateItemCategoryPropertyConfigMutation, Types.BulkUpdateItemCategoryPropertyConfigMutationVariables>;
export const CreateItemCategoryPropertyGroupDocument = gql`
    mutation createItemCategoryPropertyGroup($input: item_category_property_group_insert_input!) {
  insert_item_category_property_group_one(object: $input) {
    ...ItemCategoryPropertyGroup
  }
}
    ${ItemCategoryPropertyGroupFragmentDoc}`;
export type CreateItemCategoryPropertyGroupMutationFn = Apollo.MutationFunction<Types.CreateItemCategoryPropertyGroupMutation, Types.CreateItemCategoryPropertyGroupMutationVariables>;

/**
 * __useCreateItemCategoryPropertyGroupMutation__
 *
 * To run a mutation, you first call `useCreateItemCategoryPropertyGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemCategoryPropertyGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItemCategoryPropertyGroupMutation, { data, loading, error }] = useCreateItemCategoryPropertyGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateItemCategoryPropertyGroupMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateItemCategoryPropertyGroupMutation, Types.CreateItemCategoryPropertyGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateItemCategoryPropertyGroupMutation, Types.CreateItemCategoryPropertyGroupMutationVariables>(CreateItemCategoryPropertyGroupDocument, options);
      }
export type CreateItemCategoryPropertyGroupMutationHookResult = ReturnType<typeof useCreateItemCategoryPropertyGroupMutation>;
export type CreateItemCategoryPropertyGroupMutationResult = Apollo.MutationResult<Types.CreateItemCategoryPropertyGroupMutation>;
export type CreateItemCategoryPropertyGroupMutationOptions = Apollo.BaseMutationOptions<Types.CreateItemCategoryPropertyGroupMutation, Types.CreateItemCategoryPropertyGroupMutationVariables>;
export const DeleteItemCategoryPropertyGroupDocument = gql`
    mutation deleteItemCategoryPropertyGroup($id: bigint!, $organizationId: uuid!) {
  delete_item_category_property_group_by_pk(
    id: $id
    organization_id: $organizationId
  ) {
    id
    item_category_id
  }
}
    `;
export type DeleteItemCategoryPropertyGroupMutationFn = Apollo.MutationFunction<Types.DeleteItemCategoryPropertyGroupMutation, Types.DeleteItemCategoryPropertyGroupMutationVariables>;

/**
 * __useDeleteItemCategoryPropertyGroupMutation__
 *
 * To run a mutation, you first call `useDeleteItemCategoryPropertyGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteItemCategoryPropertyGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteItemCategoryPropertyGroupMutation, { data, loading, error }] = useDeleteItemCategoryPropertyGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useDeleteItemCategoryPropertyGroupMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteItemCategoryPropertyGroupMutation, Types.DeleteItemCategoryPropertyGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteItemCategoryPropertyGroupMutation, Types.DeleteItemCategoryPropertyGroupMutationVariables>(DeleteItemCategoryPropertyGroupDocument, options);
      }
export type DeleteItemCategoryPropertyGroupMutationHookResult = ReturnType<typeof useDeleteItemCategoryPropertyGroupMutation>;
export type DeleteItemCategoryPropertyGroupMutationResult = Apollo.MutationResult<Types.DeleteItemCategoryPropertyGroupMutation>;
export type DeleteItemCategoryPropertyGroupMutationOptions = Apollo.BaseMutationOptions<Types.DeleteItemCategoryPropertyGroupMutation, Types.DeleteItemCategoryPropertyGroupMutationVariables>;
export const UpdateItemCategoryPropertyGroupDocument = gql`
    mutation updateItemCategoryPropertyGroup($id: bigint!, $organizationId: uuid!, $input: item_category_property_group_set_input!) {
  update_item_category_property_group_by_pk(
    pk_columns: {id: $id, organization_id: $organizationId}
    _set: $input
  ) {
    id
    item_category_id
    name
    description
  }
}
    `;
export type UpdateItemCategoryPropertyGroupMutationFn = Apollo.MutationFunction<Types.UpdateItemCategoryPropertyGroupMutation, Types.UpdateItemCategoryPropertyGroupMutationVariables>;

/**
 * __useUpdateItemCategoryPropertyGroupMutation__
 *
 * To run a mutation, you first call `useUpdateItemCategoryPropertyGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemCategoryPropertyGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemCategoryPropertyGroupMutation, { data, loading, error }] = useUpdateItemCategoryPropertyGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      organizationId: // value for 'organizationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateItemCategoryPropertyGroupMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateItemCategoryPropertyGroupMutation, Types.UpdateItemCategoryPropertyGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateItemCategoryPropertyGroupMutation, Types.UpdateItemCategoryPropertyGroupMutationVariables>(UpdateItemCategoryPropertyGroupDocument, options);
      }
export type UpdateItemCategoryPropertyGroupMutationHookResult = ReturnType<typeof useUpdateItemCategoryPropertyGroupMutation>;
export type UpdateItemCategoryPropertyGroupMutationResult = Apollo.MutationResult<Types.UpdateItemCategoryPropertyGroupMutation>;
export type UpdateItemCategoryPropertyGroupMutationOptions = Apollo.BaseMutationOptions<Types.UpdateItemCategoryPropertyGroupMutation, Types.UpdateItemCategoryPropertyGroupMutationVariables>;
export const BulkUpdateItemCategoryPropertyGroupDocument = gql`
    mutation bulkUpdateItemCategoryPropertyGroup($updates: [item_category_property_group_updates!]!) {
  update_item_category_property_group_many(updates: $updates) {
    returning {
      organization_id
      item_category_id
      id
      ordinal
    }
  }
}
    `;
export type BulkUpdateItemCategoryPropertyGroupMutationFn = Apollo.MutationFunction<Types.BulkUpdateItemCategoryPropertyGroupMutation, Types.BulkUpdateItemCategoryPropertyGroupMutationVariables>;

/**
 * __useBulkUpdateItemCategoryPropertyGroupMutation__
 *
 * To run a mutation, you first call `useBulkUpdateItemCategoryPropertyGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateItemCategoryPropertyGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateItemCategoryPropertyGroupMutation, { data, loading, error }] = useBulkUpdateItemCategoryPropertyGroupMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useBulkUpdateItemCategoryPropertyGroupMutation(baseOptions?: Apollo.MutationHookOptions<Types.BulkUpdateItemCategoryPropertyGroupMutation, Types.BulkUpdateItemCategoryPropertyGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.BulkUpdateItemCategoryPropertyGroupMutation, Types.BulkUpdateItemCategoryPropertyGroupMutationVariables>(BulkUpdateItemCategoryPropertyGroupDocument, options);
      }
export type BulkUpdateItemCategoryPropertyGroupMutationHookResult = ReturnType<typeof useBulkUpdateItemCategoryPropertyGroupMutation>;
export type BulkUpdateItemCategoryPropertyGroupMutationResult = Apollo.MutationResult<Types.BulkUpdateItemCategoryPropertyGroupMutation>;
export type BulkUpdateItemCategoryPropertyGroupMutationOptions = Apollo.BaseMutationOptions<Types.BulkUpdateItemCategoryPropertyGroupMutation, Types.BulkUpdateItemCategoryPropertyGroupMutationVariables>;
export const UpsertItemCategoryPropertyValueDocument = gql`
    mutation upsertItemCategoryPropertyValue($input: item_property_group_value_insert_input!) {
  insert_item_property_group_value_one(
    object: $input
    on_conflict: {constraint: item_property_group_value_pkey, update_columns: [value]}
  ) {
    ...ItemPropertyGroupValueIdentifiers
    value
  }
}
    ${ItemPropertyGroupValueIdentifiersFragmentDoc}`;
export type UpsertItemCategoryPropertyValueMutationFn = Apollo.MutationFunction<Types.UpsertItemCategoryPropertyValueMutation, Types.UpsertItemCategoryPropertyValueMutationVariables>;

/**
 * __useUpsertItemCategoryPropertyValueMutation__
 *
 * To run a mutation, you first call `useUpsertItemCategoryPropertyValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertItemCategoryPropertyValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertItemCategoryPropertyValueMutation, { data, loading, error }] = useUpsertItemCategoryPropertyValueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertItemCategoryPropertyValueMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpsertItemCategoryPropertyValueMutation, Types.UpsertItemCategoryPropertyValueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpsertItemCategoryPropertyValueMutation, Types.UpsertItemCategoryPropertyValueMutationVariables>(UpsertItemCategoryPropertyValueDocument, options);
      }
export type UpsertItemCategoryPropertyValueMutationHookResult = ReturnType<typeof useUpsertItemCategoryPropertyValueMutation>;
export type UpsertItemCategoryPropertyValueMutationResult = Apollo.MutationResult<Types.UpsertItemCategoryPropertyValueMutation>;
export type UpsertItemCategoryPropertyValueMutationOptions = Apollo.BaseMutationOptions<Types.UpsertItemCategoryPropertyValueMutation, Types.UpsertItemCategoryPropertyValueMutationVariables>;
export const CreateItemOneDocument = gql`
    mutation createItemOne($object: items_insert_input!) {
  insert_items_one(object: $object) {
    __typename
    id
    name
    location_description
    description
    item_category_id
    node_id
  }
}
    `;
export type CreateItemOneMutationFn = Apollo.MutationFunction<Types.CreateItemOneMutation, Types.CreateItemOneMutationVariables>;

/**
 * __useCreateItemOneMutation__
 *
 * To run a mutation, you first call `useCreateItemOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItemOneMutation, { data, loading, error }] = useCreateItemOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useCreateItemOneMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateItemOneMutation, Types.CreateItemOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateItemOneMutation, Types.CreateItemOneMutationVariables>(CreateItemOneDocument, options);
      }
export type CreateItemOneMutationHookResult = ReturnType<typeof useCreateItemOneMutation>;
export type CreateItemOneMutationResult = Apollo.MutationResult<Types.CreateItemOneMutation>;
export type CreateItemOneMutationOptions = Apollo.BaseMutationOptions<Types.CreateItemOneMutation, Types.CreateItemOneMutationVariables>;
export const UpdateItemByIdDocument = gql`
    mutation updateItemById($id: uuid!, $locationDescription: String!, $name: String!, $description: String!, $itemCategoryId: uuid!, $nodeId: uuid!) {
  update_items_by_pk(
    pk_columns: {id: $id}
    _set: {location_description: $locationDescription, name: $name, description: $description, item_category_id: $itemCategoryId, node_id: $nodeId}
  ) {
    __typename
    id
    location_description
    item_category_id
    description
    name
    node_id
  }
}
    `;
export type UpdateItemByIdMutationFn = Apollo.MutationFunction<Types.UpdateItemByIdMutation, Types.UpdateItemByIdMutationVariables>;

/**
 * __useUpdateItemByIdMutation__
 *
 * To run a mutation, you first call `useUpdateItemByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemByIdMutation, { data, loading, error }] = useUpdateItemByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      locationDescription: // value for 'locationDescription'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      itemCategoryId: // value for 'itemCategoryId'
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useUpdateItemByIdMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateItemByIdMutation, Types.UpdateItemByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateItemByIdMutation, Types.UpdateItemByIdMutationVariables>(UpdateItemByIdDocument, options);
      }
export type UpdateItemByIdMutationHookResult = ReturnType<typeof useUpdateItemByIdMutation>;
export type UpdateItemByIdMutationResult = Apollo.MutationResult<Types.UpdateItemByIdMutation>;
export type UpdateItemByIdMutationOptions = Apollo.BaseMutationOptions<Types.UpdateItemByIdMutation, Types.UpdateItemByIdMutationVariables>;
export const UpdateGlobalItemDocument = gql`
    mutation updateGlobalItem($itemId: uuid!, $isPresent: Boolean!, $name: String, $notes: String, $locationDescription: String) {
  update_items_by_pk(
    pk_columns: {id: $itemId}
    _set: {is_present: $isPresent, location_description: $locationDescription, description: $notes, name: $name}
  ) {
    id
    location_description
    description
    is_present
    name
  }
}
    `;
export type UpdateGlobalItemMutationFn = Apollo.MutationFunction<Types.UpdateGlobalItemMutation, Types.UpdateGlobalItemMutationVariables>;

/**
 * __useUpdateGlobalItemMutation__
 *
 * To run a mutation, you first call `useUpdateGlobalItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGlobalItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGlobalItemMutation, { data, loading, error }] = useUpdateGlobalItemMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      isPresent: // value for 'isPresent'
 *      name: // value for 'name'
 *      notes: // value for 'notes'
 *      locationDescription: // value for 'locationDescription'
 *   },
 * });
 */
export function useUpdateGlobalItemMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateGlobalItemMutation, Types.UpdateGlobalItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateGlobalItemMutation, Types.UpdateGlobalItemMutationVariables>(UpdateGlobalItemDocument, options);
      }
export type UpdateGlobalItemMutationHookResult = ReturnType<typeof useUpdateGlobalItemMutation>;
export type UpdateGlobalItemMutationResult = Apollo.MutationResult<Types.UpdateGlobalItemMutation>;
export type UpdateGlobalItemMutationOptions = Apollo.BaseMutationOptions<Types.UpdateGlobalItemMutation, Types.UpdateGlobalItemMutationVariables>;
export const UpdateItemDocument = gql`
    mutation updateItem($item: items_set_input, $id: uuid!) {
  update_items_by_pk(pk_columns: {id: $id}, _set: $item) {
    __typename
    id
    name
    asset
    coordinates
    countermeasure
    description
    exterior
    is_present
  }
}
    `;
export type UpdateItemMutationFn = Apollo.MutationFunction<Types.UpdateItemMutation, Types.UpdateItemMutationVariables>;

/**
 * __useUpdateItemMutation__
 *
 * To run a mutation, you first call `useUpdateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemMutation, { data, loading, error }] = useUpdateItemMutation({
 *   variables: {
 *      item: // value for 'item'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateItemMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateItemMutation, Types.UpdateItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateItemMutation, Types.UpdateItemMutationVariables>(UpdateItemDocument, options);
      }
export type UpdateItemMutationHookResult = ReturnType<typeof useUpdateItemMutation>;
export type UpdateItemMutationResult = Apollo.MutationResult<Types.UpdateItemMutation>;
export type UpdateItemMutationOptions = Apollo.BaseMutationOptions<Types.UpdateItemMutation, Types.UpdateItemMutationVariables>;
export const UpdateItemIsPresentDocument = gql`
    mutation updateItemIsPresent($id: uuid!, $isPresent: Boolean!) {
  update_items_by_pk(pk_columns: {id: $id}, _set: {is_present: $isPresent}) {
    ...ItemIsPresent
  }
}
    ${ItemIsPresentFragmentDoc}`;
export type UpdateItemIsPresentMutationFn = Apollo.MutationFunction<Types.UpdateItemIsPresentMutation, Types.UpdateItemIsPresentMutationVariables>;

/**
 * __useUpdateItemIsPresentMutation__
 *
 * To run a mutation, you first call `useUpdateItemIsPresentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemIsPresentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemIsPresentMutation, { data, loading, error }] = useUpdateItemIsPresentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isPresent: // value for 'isPresent'
 *   },
 * });
 */
export function useUpdateItemIsPresentMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateItemIsPresentMutation, Types.UpdateItemIsPresentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateItemIsPresentMutation, Types.UpdateItemIsPresentMutationVariables>(UpdateItemIsPresentDocument, options);
      }
export type UpdateItemIsPresentMutationHookResult = ReturnType<typeof useUpdateItemIsPresentMutation>;
export type UpdateItemIsPresentMutationResult = Apollo.MutationResult<Types.UpdateItemIsPresentMutation>;
export type UpdateItemIsPresentMutationOptions = Apollo.BaseMutationOptions<Types.UpdateItemIsPresentMutation, Types.UpdateItemIsPresentMutationVariables>;
export const UpdateLayerDocument = gql`
    mutation updateLayer($id: uuid!, $name: String) {
  update_layers_by_pk(pk_columns: {id: $id}, _set: {name: $name}) {
    id
  }
}
    `;
export type UpdateLayerMutationFn = Apollo.MutationFunction<Types.UpdateLayerMutation, Types.UpdateLayerMutationVariables>;

/**
 * __useUpdateLayerMutation__
 *
 * To run a mutation, you first call `useUpdateLayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLayerMutation, { data, loading, error }] = useUpdateLayerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateLayerMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateLayerMutation, Types.UpdateLayerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateLayerMutation, Types.UpdateLayerMutationVariables>(UpdateLayerDocument, options);
      }
export type UpdateLayerMutationHookResult = ReturnType<typeof useUpdateLayerMutation>;
export type UpdateLayerMutationResult = Apollo.MutationResult<Types.UpdateLayerMutation>;
export type UpdateLayerMutationOptions = Apollo.BaseMutationOptions<Types.UpdateLayerMutation, Types.UpdateLayerMutationVariables>;
export const UpdateLayerNameByIdDocument = gql`
    mutation updateLayerNameById($id: uuid!, $name: String!) {
  update_layers_by_pk(pk_columns: {id: $id}, _set: {name: $name}) {
    __typename
    id
    name
    layer {
      id
      name
    }
  }
}
    `;
export type UpdateLayerNameByIdMutationFn = Apollo.MutationFunction<Types.UpdateLayerNameByIdMutation, Types.UpdateLayerNameByIdMutationVariables>;

/**
 * __useUpdateLayerNameByIdMutation__
 *
 * To run a mutation, you first call `useUpdateLayerNameByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLayerNameByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLayerNameByIdMutation, { data, loading, error }] = useUpdateLayerNameByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateLayerNameByIdMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateLayerNameByIdMutation, Types.UpdateLayerNameByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateLayerNameByIdMutation, Types.UpdateLayerNameByIdMutationVariables>(UpdateLayerNameByIdDocument, options);
      }
export type UpdateLayerNameByIdMutationHookResult = ReturnType<typeof useUpdateLayerNameByIdMutation>;
export type UpdateLayerNameByIdMutationResult = Apollo.MutationResult<Types.UpdateLayerNameByIdMutation>;
export type UpdateLayerNameByIdMutationOptions = Apollo.BaseMutationOptions<Types.UpdateLayerNameByIdMutation, Types.UpdateLayerNameByIdMutationVariables>;
export const InsertLocationLayerTagDocument = gql`
    mutation insertLocationLayerTag($obj: location_layer_tag_insert_input!) {
  insert_location_layer_tag_one(object: $obj) {
    ...LocationLayerTagIdentifiers
    layer_id
  }
}
    ${LocationLayerTagIdentifiersFragmentDoc}`;
export type InsertLocationLayerTagMutationFn = Apollo.MutationFunction<Types.InsertLocationLayerTagMutation, Types.InsertLocationLayerTagMutationVariables>;

/**
 * __useInsertLocationLayerTagMutation__
 *
 * To run a mutation, you first call `useInsertLocationLayerTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLocationLayerTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLocationLayerTagMutation, { data, loading, error }] = useInsertLocationLayerTagMutation({
 *   variables: {
 *      obj: // value for 'obj'
 *   },
 * });
 */
export function useInsertLocationLayerTagMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertLocationLayerTagMutation, Types.InsertLocationLayerTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertLocationLayerTagMutation, Types.InsertLocationLayerTagMutationVariables>(InsertLocationLayerTagDocument, options);
      }
export type InsertLocationLayerTagMutationHookResult = ReturnType<typeof useInsertLocationLayerTagMutation>;
export type InsertLocationLayerTagMutationResult = Apollo.MutationResult<Types.InsertLocationLayerTagMutation>;
export type InsertLocationLayerTagMutationOptions = Apollo.BaseMutationOptions<Types.InsertLocationLayerTagMutation, Types.InsertLocationLayerTagMutationVariables>;
export const RemoveLocationLayerTagDocument = gql`
    mutation removeLocationLayerTag($organizationId: uuid!, $nodeId: uuid!, $layerTagId: Int!) {
  delete_location_layer_tag_by_pk(
    organization_id: $organizationId
    layer_tag_id: $layerTagId
    node_id: $nodeId
  ) {
    ...LocationLayerTagIdentifiers
  }
}
    ${LocationLayerTagIdentifiersFragmentDoc}`;
export type RemoveLocationLayerTagMutationFn = Apollo.MutationFunction<Types.RemoveLocationLayerTagMutation, Types.RemoveLocationLayerTagMutationVariables>;

/**
 * __useRemoveLocationLayerTagMutation__
 *
 * To run a mutation, you first call `useRemoveLocationLayerTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLocationLayerTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLocationLayerTagMutation, { data, loading, error }] = useRemoveLocationLayerTagMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      nodeId: // value for 'nodeId'
 *      layerTagId: // value for 'layerTagId'
 *   },
 * });
 */
export function useRemoveLocationLayerTagMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveLocationLayerTagMutation, Types.RemoveLocationLayerTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveLocationLayerTagMutation, Types.RemoveLocationLayerTagMutationVariables>(RemoveLocationLayerTagDocument, options);
      }
export type RemoveLocationLayerTagMutationHookResult = ReturnType<typeof useRemoveLocationLayerTagMutation>;
export type RemoveLocationLayerTagMutationResult = Apollo.MutationResult<Types.RemoveLocationLayerTagMutation>;
export type RemoveLocationLayerTagMutationOptions = Apollo.BaseMutationOptions<Types.RemoveLocationLayerTagMutation, Types.RemoveLocationLayerTagMutationVariables>;
export const BulkUpsertPropertyGroupValueDocument = gql`
    mutation bulkUpsertPropertyGroupValue($input: [location_property_group_value_insert_input!]!) {
  insert_location_property_group_value(
    objects: $input
    on_conflict: {constraint: location_property_group_value_pkey, update_columns: [value]}
  ) {
    returning {
      ...LocationPropertyGroupValueIdentifiers
      value
    }
  }
}
    ${LocationPropertyGroupValueIdentifiersFragmentDoc}`;
export type BulkUpsertPropertyGroupValueMutationFn = Apollo.MutationFunction<Types.BulkUpsertPropertyGroupValueMutation, Types.BulkUpsertPropertyGroupValueMutationVariables>;

/**
 * __useBulkUpsertPropertyGroupValueMutation__
 *
 * To run a mutation, you first call `useBulkUpsertPropertyGroupValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpsertPropertyGroupValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpsertPropertyGroupValueMutation, { data, loading, error }] = useBulkUpsertPropertyGroupValueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpsertPropertyGroupValueMutation(baseOptions?: Apollo.MutationHookOptions<Types.BulkUpsertPropertyGroupValueMutation, Types.BulkUpsertPropertyGroupValueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.BulkUpsertPropertyGroupValueMutation, Types.BulkUpsertPropertyGroupValueMutationVariables>(BulkUpsertPropertyGroupValueDocument, options);
      }
export type BulkUpsertPropertyGroupValueMutationHookResult = ReturnType<typeof useBulkUpsertPropertyGroupValueMutation>;
export type BulkUpsertPropertyGroupValueMutationResult = Apollo.MutationResult<Types.BulkUpsertPropertyGroupValueMutation>;
export type BulkUpsertPropertyGroupValueMutationOptions = Apollo.BaseMutationOptions<Types.BulkUpsertPropertyGroupValueMutation, Types.BulkUpsertPropertyGroupValueMutationVariables>;
export const UpsertNodeAreaTagsDocument = gql`
    mutation upsertNodeAreaTags($input: [node_area_tags_insert_input!]!) {
  insert_node_area_tags(
    objects: $input
    on_conflict: {constraint: node_area_tags_pkey, update_columns: [is_present]}
  ) {
    returning {
      nodeAreaTags {
        area_tag_id
        is_present
        tag_name
        node_id
      }
    }
  }
}
    `;
export type UpsertNodeAreaTagsMutationFn = Apollo.MutationFunction<Types.UpsertNodeAreaTagsMutation, Types.UpsertNodeAreaTagsMutationVariables>;

/**
 * __useUpsertNodeAreaTagsMutation__
 *
 * To run a mutation, you first call `useUpsertNodeAreaTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertNodeAreaTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertNodeAreaTagsMutation, { data, loading, error }] = useUpsertNodeAreaTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertNodeAreaTagsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpsertNodeAreaTagsMutation, Types.UpsertNodeAreaTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpsertNodeAreaTagsMutation, Types.UpsertNodeAreaTagsMutationVariables>(UpsertNodeAreaTagsDocument, options);
      }
export type UpsertNodeAreaTagsMutationHookResult = ReturnType<typeof useUpsertNodeAreaTagsMutation>;
export type UpsertNodeAreaTagsMutationResult = Apollo.MutationResult<Types.UpsertNodeAreaTagsMutation>;
export type UpsertNodeAreaTagsMutationOptions = Apollo.BaseMutationOptions<Types.UpsertNodeAreaTagsMutation, Types.UpsertNodeAreaTagsMutationVariables>;
export const EditFeatureFlagDocument = gql`
    mutation editFeatureFlag($newFlagValues: [organization_feature_flags_insert_input!]!) {
  insert_organization_feature_flags(
    objects: $newFlagValues
    on_conflict: {constraint: organization_feature_flags_pkey, update_columns: [is_flag_enabled]}
  ) {
    returning {
      __typename
      is_flag_enabled
      flag_name
      organization_id
    }
  }
}
    `;
export type EditFeatureFlagMutationFn = Apollo.MutationFunction<Types.EditFeatureFlagMutation, Types.EditFeatureFlagMutationVariables>;

/**
 * __useEditFeatureFlagMutation__
 *
 * To run a mutation, you first call `useEditFeatureFlagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditFeatureFlagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editFeatureFlagMutation, { data, loading, error }] = useEditFeatureFlagMutation({
 *   variables: {
 *      newFlagValues: // value for 'newFlagValues'
 *   },
 * });
 */
export function useEditFeatureFlagMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditFeatureFlagMutation, Types.EditFeatureFlagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditFeatureFlagMutation, Types.EditFeatureFlagMutationVariables>(EditFeatureFlagDocument, options);
      }
export type EditFeatureFlagMutationHookResult = ReturnType<typeof useEditFeatureFlagMutation>;
export type EditFeatureFlagMutationResult = Apollo.MutationResult<Types.EditFeatureFlagMutation>;
export type EditFeatureFlagMutationOptions = Apollo.BaseMutationOptions<Types.EditFeatureFlagMutation, Types.EditFeatureFlagMutationVariables>;
export const CreateOrgSeverityMeasureDocument = gql`
    mutation createOrgSeverityMeasure($name: citext!, $description: String, $scenarioType: enum_scenario_type_enum!, $valueLabels: [scenario_impact_value_labels_insert_input!]!, $isNumeric: Boolean, $minValue: numeric, $maxValue: numeric) {
  insert_organization_scenario_impact_measure_one(
    object: {name: $name, description: $description, scenario_type: $scenarioType, is_user_supplied_numeric: $isNumeric, min_value: $minValue, max_value: $maxValue, valueLabels: {data: $valueLabels}}
  ) {
    id
    valueLabels {
      name
      value
    }
  }
}
    `;
export type CreateOrgSeverityMeasureMutationFn = Apollo.MutationFunction<Types.CreateOrgSeverityMeasureMutation, Types.CreateOrgSeverityMeasureMutationVariables>;

/**
 * __useCreateOrgSeverityMeasureMutation__
 *
 * To run a mutation, you first call `useCreateOrgSeverityMeasureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrgSeverityMeasureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrgSeverityMeasureMutation, { data, loading, error }] = useCreateOrgSeverityMeasureMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      scenarioType: // value for 'scenarioType'
 *      valueLabels: // value for 'valueLabels'
 *      isNumeric: // value for 'isNumeric'
 *      minValue: // value for 'minValue'
 *      maxValue: // value for 'maxValue'
 *   },
 * });
 */
export function useCreateOrgSeverityMeasureMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateOrgSeverityMeasureMutation, Types.CreateOrgSeverityMeasureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateOrgSeverityMeasureMutation, Types.CreateOrgSeverityMeasureMutationVariables>(CreateOrgSeverityMeasureDocument, options);
      }
export type CreateOrgSeverityMeasureMutationHookResult = ReturnType<typeof useCreateOrgSeverityMeasureMutation>;
export type CreateOrgSeverityMeasureMutationResult = Apollo.MutationResult<Types.CreateOrgSeverityMeasureMutation>;
export type CreateOrgSeverityMeasureMutationOptions = Apollo.BaseMutationOptions<Types.CreateOrgSeverityMeasureMutation, Types.CreateOrgSeverityMeasureMutationVariables>;
export const CreateOrgProbabilityMeasureDocument = gql`
    mutation createOrgProbabilityMeasure($name: citext!, $description: String, $scenarioType: enum_scenario_type_enum!, $valueLabels: [scenario_probability_value_labels_insert_input!]!, $isNumeric: Boolean, $minValue: numeric, $maxValue: numeric) {
  insert_organization_scenario_probability_measure_one(
    object: {name: $name, description: $description, scenario_type: $scenarioType, is_user_supplied_numeric: $isNumeric, min_value: $minValue, max_value: $maxValue, valueLabels: {data: $valueLabels}}
  ) {
    id
    valueLabels {
      name
      value
    }
  }
}
    `;
export type CreateOrgProbabilityMeasureMutationFn = Apollo.MutationFunction<Types.CreateOrgProbabilityMeasureMutation, Types.CreateOrgProbabilityMeasureMutationVariables>;

/**
 * __useCreateOrgProbabilityMeasureMutation__
 *
 * To run a mutation, you first call `useCreateOrgProbabilityMeasureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrgProbabilityMeasureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrgProbabilityMeasureMutation, { data, loading, error }] = useCreateOrgProbabilityMeasureMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      scenarioType: // value for 'scenarioType'
 *      valueLabels: // value for 'valueLabels'
 *      isNumeric: // value for 'isNumeric'
 *      minValue: // value for 'minValue'
 *      maxValue: // value for 'maxValue'
 *   },
 * });
 */
export function useCreateOrgProbabilityMeasureMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateOrgProbabilityMeasureMutation, Types.CreateOrgProbabilityMeasureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateOrgProbabilityMeasureMutation, Types.CreateOrgProbabilityMeasureMutationVariables>(CreateOrgProbabilityMeasureDocument, options);
      }
export type CreateOrgProbabilityMeasureMutationHookResult = ReturnType<typeof useCreateOrgProbabilityMeasureMutation>;
export type CreateOrgProbabilityMeasureMutationResult = Apollo.MutationResult<Types.CreateOrgProbabilityMeasureMutation>;
export type CreateOrgProbabilityMeasureMutationOptions = Apollo.BaseMutationOptions<Types.CreateOrgProbabilityMeasureMutation, Types.CreateOrgProbabilityMeasureMutationVariables>;
export const UpsertOrganizationThemeOverrideDocument = gql`
    mutation upsertOrganizationThemeOverride($override: organization_theme_overrides_insert_input!) {
  insert_organization_theme_overrides_one(
    object: $override
    on_conflict: {constraint: one_override_per_org, update_columns: [dark_logo_id, primary_color, light_logo_id, organization_colors, email_support_address, email_logo_id, address, phone, social_media_twitter_url, social_media_facebook_url, social_media_linkedin_url]}
  ) {
    dark_logo_id
    light_logo_id
    primary_color
    organization_colors
    id
    email_support_address
    email_logo_id
    phone
    address
    social_media_twitter_url
    social_media_facebook_url
    social_media_linkedin_url
  }
}
    `;
export type UpsertOrganizationThemeOverrideMutationFn = Apollo.MutationFunction<Types.UpsertOrganizationThemeOverrideMutation, Types.UpsertOrganizationThemeOverrideMutationVariables>;

/**
 * __useUpsertOrganizationThemeOverrideMutation__
 *
 * To run a mutation, you first call `useUpsertOrganizationThemeOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertOrganizationThemeOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertOrganizationThemeOverrideMutation, { data, loading, error }] = useUpsertOrganizationThemeOverrideMutation({
 *   variables: {
 *      override: // value for 'override'
 *   },
 * });
 */
export function useUpsertOrganizationThemeOverrideMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpsertOrganizationThemeOverrideMutation, Types.UpsertOrganizationThemeOverrideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpsertOrganizationThemeOverrideMutation, Types.UpsertOrganizationThemeOverrideMutationVariables>(UpsertOrganizationThemeOverrideDocument, options);
      }
export type UpsertOrganizationThemeOverrideMutationHookResult = ReturnType<typeof useUpsertOrganizationThemeOverrideMutation>;
export type UpsertOrganizationThemeOverrideMutationResult = Apollo.MutationResult<Types.UpsertOrganizationThemeOverrideMutation>;
export type UpsertOrganizationThemeOverrideMutationOptions = Apollo.BaseMutationOptions<Types.UpsertOrganizationThemeOverrideMutation, Types.UpsertOrganizationThemeOverrideMutationVariables>;
export const UpdateOrganizationThemeOverrideDocument = gql`
    mutation updateOrganizationThemeOverride($id: Int!, $orgId: uuid!, $object: organization_theme_overrides_set_input!) {
  update_organization_theme_overrides_by_pk(
    pk_columns: {id: $id, organization_id: $orgId}
    _set: $object
  ) {
    primary_color
    dark_logo_id
    light_logo_id
  }
}
    `;
export type UpdateOrganizationThemeOverrideMutationFn = Apollo.MutationFunction<Types.UpdateOrganizationThemeOverrideMutation, Types.UpdateOrganizationThemeOverrideMutationVariables>;

/**
 * __useUpdateOrganizationThemeOverrideMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationThemeOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationThemeOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationThemeOverrideMutation, { data, loading, error }] = useUpdateOrganizationThemeOverrideMutation({
 *   variables: {
 *      id: // value for 'id'
 *      orgId: // value for 'orgId'
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpdateOrganizationThemeOverrideMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateOrganizationThemeOverrideMutation, Types.UpdateOrganizationThemeOverrideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateOrganizationThemeOverrideMutation, Types.UpdateOrganizationThemeOverrideMutationVariables>(UpdateOrganizationThemeOverrideDocument, options);
      }
export type UpdateOrganizationThemeOverrideMutationHookResult = ReturnType<typeof useUpdateOrganizationThemeOverrideMutation>;
export type UpdateOrganizationThemeOverrideMutationResult = Apollo.MutationResult<Types.UpdateOrganizationThemeOverrideMutation>;
export type UpdateOrganizationThemeOverrideMutationOptions = Apollo.BaseMutationOptions<Types.UpdateOrganizationThemeOverrideMutation, Types.UpdateOrganizationThemeOverrideMutationVariables>;
export const InsertOrganizationThemeOverrideDocument = gql`
    mutation insertOrganizationThemeOverride($object: organization_theme_overrides_insert_input!) {
  insert_organization_theme_overrides_one(object: $object) {
    primary_color
    dark_logo_id
    light_logo_id
  }
}
    `;
export type InsertOrganizationThemeOverrideMutationFn = Apollo.MutationFunction<Types.InsertOrganizationThemeOverrideMutation, Types.InsertOrganizationThemeOverrideMutationVariables>;

/**
 * __useInsertOrganizationThemeOverrideMutation__
 *
 * To run a mutation, you first call `useInsertOrganizationThemeOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertOrganizationThemeOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertOrganizationThemeOverrideMutation, { data, loading, error }] = useInsertOrganizationThemeOverrideMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertOrganizationThemeOverrideMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertOrganizationThemeOverrideMutation, Types.InsertOrganizationThemeOverrideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertOrganizationThemeOverrideMutation, Types.InsertOrganizationThemeOverrideMutationVariables>(InsertOrganizationThemeOverrideDocument, options);
      }
export type InsertOrganizationThemeOverrideMutationHookResult = ReturnType<typeof useInsertOrganizationThemeOverrideMutation>;
export type InsertOrganizationThemeOverrideMutationResult = Apollo.MutationResult<Types.InsertOrganizationThemeOverrideMutation>;
export type InsertOrganizationThemeOverrideMutationOptions = Apollo.BaseMutationOptions<Types.InsertOrganizationThemeOverrideMutation, Types.InsertOrganizationThemeOverrideMutationVariables>;
export const UpsertOrgThresholdLabelsDocument = gql`
    mutation upsertOrgThresholdLabels($input: organization_threshold_labels_insert_input!) {
  insert_organization_threshold_labels_one(
    object: $input
    on_conflict: {constraint: organization_threshold_labels_pkey, update_columns: [lower_range_label, mid_range_label, upper_range_label]}
  ) {
    organization_id
    lower_range_label
    mid_range_label
    upper_range_label
  }
}
    `;
export type UpsertOrgThresholdLabelsMutationFn = Apollo.MutationFunction<Types.UpsertOrgThresholdLabelsMutation, Types.UpsertOrgThresholdLabelsMutationVariables>;

/**
 * __useUpsertOrgThresholdLabelsMutation__
 *
 * To run a mutation, you first call `useUpsertOrgThresholdLabelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertOrgThresholdLabelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertOrgThresholdLabelsMutation, { data, loading, error }] = useUpsertOrgThresholdLabelsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertOrgThresholdLabelsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpsertOrgThresholdLabelsMutation, Types.UpsertOrgThresholdLabelsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpsertOrgThresholdLabelsMutation, Types.UpsertOrgThresholdLabelsMutationVariables>(UpsertOrgThresholdLabelsDocument, options);
      }
export type UpsertOrgThresholdLabelsMutationHookResult = ReturnType<typeof useUpsertOrgThresholdLabelsMutation>;
export type UpsertOrgThresholdLabelsMutationResult = Apollo.MutationResult<Types.UpsertOrgThresholdLabelsMutation>;
export type UpsertOrgThresholdLabelsMutationOptions = Apollo.BaseMutationOptions<Types.UpsertOrgThresholdLabelsMutation, Types.UpsertOrgThresholdLabelsMutationVariables>;
export const UpdateOrganizationScoredLayerDocument = gql`
    mutation updateOrganizationScoredLayer($id: uuid!, $scored_layer_id: uuid!) {
  update_organizations_by_pk(
    pk_columns: {id: $id}
    _set: {scored_layer_id: $scored_layer_id}
  ) {
    __typename
    id
  }
}
    `;
export type UpdateOrganizationScoredLayerMutationFn = Apollo.MutationFunction<Types.UpdateOrganizationScoredLayerMutation, Types.UpdateOrganizationScoredLayerMutationVariables>;

/**
 * __useUpdateOrganizationScoredLayerMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationScoredLayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationScoredLayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationScoredLayerMutation, { data, loading, error }] = useUpdateOrganizationScoredLayerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      scored_layer_id: // value for 'scored_layer_id'
 *   },
 * });
 */
export function useUpdateOrganizationScoredLayerMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateOrganizationScoredLayerMutation, Types.UpdateOrganizationScoredLayerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateOrganizationScoredLayerMutation, Types.UpdateOrganizationScoredLayerMutationVariables>(UpdateOrganizationScoredLayerDocument, options);
      }
export type UpdateOrganizationScoredLayerMutationHookResult = ReturnType<typeof useUpdateOrganizationScoredLayerMutation>;
export type UpdateOrganizationScoredLayerMutationResult = Apollo.MutationResult<Types.UpdateOrganizationScoredLayerMutation>;
export type UpdateOrganizationScoredLayerMutationOptions = Apollo.BaseMutationOptions<Types.UpdateOrganizationScoredLayerMutation, Types.UpdateOrganizationScoredLayerMutationVariables>;
export const DeleteOrganizationDocument = gql`
    mutation deleteOrganization($id: uuid!) {
  delete_organizations_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteOrganizationMutationFn = Apollo.MutationFunction<Types.DeleteOrganizationMutation, Types.DeleteOrganizationMutationVariables>;

/**
 * __useDeleteOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationMutation, { data, loading, error }] = useDeleteOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteOrganizationMutation, Types.DeleteOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteOrganizationMutation, Types.DeleteOrganizationMutationVariables>(DeleteOrganizationDocument, options);
      }
export type DeleteOrganizationMutationHookResult = ReturnType<typeof useDeleteOrganizationMutation>;
export type DeleteOrganizationMutationResult = Apollo.MutationResult<Types.DeleteOrganizationMutation>;
export type DeleteOrganizationMutationOptions = Apollo.BaseMutationOptions<Types.DeleteOrganizationMutation, Types.DeleteOrganizationMutationVariables>;
export const UpdateOrganizationByIdDocument = gql`
    mutation updateOrganizationById($id: uuid!, $orgData: organizations_set_input!) {
  update_organizations_by_pk(pk_columns: {id: $id}, _set: $orgData) {
    __typename
    id
    name
    address
  }
}
    `;
export type UpdateOrganizationByIdMutationFn = Apollo.MutationFunction<Types.UpdateOrganizationByIdMutation, Types.UpdateOrganizationByIdMutationVariables>;

/**
 * __useUpdateOrganizationByIdMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationByIdMutation, { data, loading, error }] = useUpdateOrganizationByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      orgData: // value for 'orgData'
 *   },
 * });
 */
export function useUpdateOrganizationByIdMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateOrganizationByIdMutation, Types.UpdateOrganizationByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateOrganizationByIdMutation, Types.UpdateOrganizationByIdMutationVariables>(UpdateOrganizationByIdDocument, options);
      }
export type UpdateOrganizationByIdMutationHookResult = ReturnType<typeof useUpdateOrganizationByIdMutation>;
export type UpdateOrganizationByIdMutationResult = Apollo.MutationResult<Types.UpdateOrganizationByIdMutation>;
export type UpdateOrganizationByIdMutationOptions = Apollo.BaseMutationOptions<Types.UpdateOrganizationByIdMutation, Types.UpdateOrganizationByIdMutationVariables>;
export const CreateProjectDocument = gql`
    mutation createProject($name: citext!, $description: String, $locationId: uuid) {
  insert_projects_one(
    object: {name: $name, description: $description, location_id: $locationId}
  ) {
    ...ProjectTableRow
  }
}
    ${ProjectTableRowFragmentDoc}`;
export type CreateProjectMutationFn = Apollo.MutationFunction<Types.CreateProjectMutation, Types.CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateProjectMutation, Types.CreateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateProjectMutation, Types.CreateProjectMutationVariables>(CreateProjectDocument, options);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<Types.CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<Types.CreateProjectMutation, Types.CreateProjectMutationVariables>;
export const UpdateProjectStatusDocument = gql`
    mutation updateProjectStatus($organizationId: uuid!, $projectId: uuid!, $status: enum_project_status_enum) {
  update_projects_by_pk(
    pk_columns: {organization_id: $organizationId, id: $projectId}
    _set: {status: $status}
  ) {
    __typename
    id
    status
  }
}
    `;
export type UpdateProjectStatusMutationFn = Apollo.MutationFunction<Types.UpdateProjectStatusMutation, Types.UpdateProjectStatusMutationVariables>;

/**
 * __useUpdateProjectStatusMutation__
 *
 * To run a mutation, you first call `useUpdateProjectStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectStatusMutation, { data, loading, error }] = useUpdateProjectStatusMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      projectId: // value for 'projectId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateProjectStatusMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateProjectStatusMutation, Types.UpdateProjectStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateProjectStatusMutation, Types.UpdateProjectStatusMutationVariables>(UpdateProjectStatusDocument, options);
      }
export type UpdateProjectStatusMutationHookResult = ReturnType<typeof useUpdateProjectStatusMutation>;
export type UpdateProjectStatusMutationResult = Apollo.MutationResult<Types.UpdateProjectStatusMutation>;
export type UpdateProjectStatusMutationOptions = Apollo.BaseMutationOptions<Types.UpdateProjectStatusMutation, Types.UpdateProjectStatusMutationVariables>;
export const UpdateOrgQuestionCriticalityLabelsDocument = gql`
    mutation updateOrgQuestionCriticalityLabels($updates: [question_criticality_labels_updates!]!) {
  update_question_criticality_labels_many(updates: $updates) {
    returning {
      organization_id
      criticality_value
      label
    }
  }
}
    `;
export type UpdateOrgQuestionCriticalityLabelsMutationFn = Apollo.MutationFunction<Types.UpdateOrgQuestionCriticalityLabelsMutation, Types.UpdateOrgQuestionCriticalityLabelsMutationVariables>;

/**
 * __useUpdateOrgQuestionCriticalityLabelsMutation__
 *
 * To run a mutation, you first call `useUpdateOrgQuestionCriticalityLabelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgQuestionCriticalityLabelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgQuestionCriticalityLabelsMutation, { data, loading, error }] = useUpdateOrgQuestionCriticalityLabelsMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateOrgQuestionCriticalityLabelsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateOrgQuestionCriticalityLabelsMutation, Types.UpdateOrgQuestionCriticalityLabelsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateOrgQuestionCriticalityLabelsMutation, Types.UpdateOrgQuestionCriticalityLabelsMutationVariables>(UpdateOrgQuestionCriticalityLabelsDocument, options);
      }
export type UpdateOrgQuestionCriticalityLabelsMutationHookResult = ReturnType<typeof useUpdateOrgQuestionCriticalityLabelsMutation>;
export type UpdateOrgQuestionCriticalityLabelsMutationResult = Apollo.MutationResult<Types.UpdateOrgQuestionCriticalityLabelsMutation>;
export type UpdateOrgQuestionCriticalityLabelsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateOrgQuestionCriticalityLabelsMutation, Types.UpdateOrgQuestionCriticalityLabelsMutationVariables>;
export const AddNewQuestionDocument = gql`
    mutation addNewQuestion($question: questions_insert_input!) {
  insert_questions_one(object: $question) {
    ...Question
  }
}
    ${QuestionFragmentDoc}`;
export type AddNewQuestionMutationFn = Apollo.MutationFunction<Types.AddNewQuestionMutation, Types.AddNewQuestionMutationVariables>;

/**
 * __useAddNewQuestionMutation__
 *
 * To run a mutation, you first call `useAddNewQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewQuestionMutation, { data, loading, error }] = useAddNewQuestionMutation({
 *   variables: {
 *      question: // value for 'question'
 *   },
 * });
 */
export function useAddNewQuestionMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddNewQuestionMutation, Types.AddNewQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddNewQuestionMutation, Types.AddNewQuestionMutationVariables>(AddNewQuestionDocument, options);
      }
export type AddNewQuestionMutationHookResult = ReturnType<typeof useAddNewQuestionMutation>;
export type AddNewQuestionMutationResult = Apollo.MutationResult<Types.AddNewQuestionMutation>;
export type AddNewQuestionMutationOptions = Apollo.BaseMutationOptions<Types.AddNewQuestionMutation, Types.AddNewQuestionMutationVariables>;
export const UpdateReportFieldDocument = gql`
    mutation updateReportField($organizationId: uuid!, $id: Int!, $clientVersion: Int!, $value: jsonb!) {
  update_report_section_fields_by_pk(
    pk_columns: {organization_id: $organizationId, id: $id}
    _set: {client_version: $clientVersion, field_value: $value}
  ) {
    ...ReportSectionFieldsIdentifiers
    version
    client_version
    field_value
  }
}
    ${ReportSectionFieldsIdentifiersFragmentDoc}`;
export type UpdateReportFieldMutationFn = Apollo.MutationFunction<Types.UpdateReportFieldMutation, Types.UpdateReportFieldMutationVariables>;

/**
 * __useUpdateReportFieldMutation__
 *
 * To run a mutation, you first call `useUpdateReportFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportFieldMutation, { data, loading, error }] = useUpdateReportFieldMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      id: // value for 'id'
 *      clientVersion: // value for 'clientVersion'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateReportFieldMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateReportFieldMutation, Types.UpdateReportFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateReportFieldMutation, Types.UpdateReportFieldMutationVariables>(UpdateReportFieldDocument, options);
      }
export type UpdateReportFieldMutationHookResult = ReturnType<typeof useUpdateReportFieldMutation>;
export type UpdateReportFieldMutationResult = Apollo.MutationResult<Types.UpdateReportFieldMutation>;
export type UpdateReportFieldMutationOptions = Apollo.BaseMutationOptions<Types.UpdateReportFieldMutation, Types.UpdateReportFieldMutationVariables>;
export const UpdateReportSectionDocument = gql`
    mutation updateReportSection($organizationId: uuid!, $id: Int!, $input: report_sections_set_input!) {
  update_report_sections_by_pk(
    pk_columns: {organization_id: $organizationId, id: $id}
    _set: $input
  ) {
    id
    organization_id
    visible
    updated_at
  }
}
    `;
export type UpdateReportSectionMutationFn = Apollo.MutationFunction<Types.UpdateReportSectionMutation, Types.UpdateReportSectionMutationVariables>;

/**
 * __useUpdateReportSectionMutation__
 *
 * To run a mutation, you first call `useUpdateReportSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportSectionMutation, { data, loading, error }] = useUpdateReportSectionMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReportSectionMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateReportSectionMutation, Types.UpdateReportSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateReportSectionMutation, Types.UpdateReportSectionMutationVariables>(UpdateReportSectionDocument, options);
      }
export type UpdateReportSectionMutationHookResult = ReturnType<typeof useUpdateReportSectionMutation>;
export type UpdateReportSectionMutationResult = Apollo.MutationResult<Types.UpdateReportSectionMutation>;
export type UpdateReportSectionMutationOptions = Apollo.BaseMutationOptions<Types.UpdateReportSectionMutation, Types.UpdateReportSectionMutationVariables>;
export const InsertScenarioDocument = gql`
    mutation insertScenario($scenarioType: enum_scenario_type_enum!, $name: citext!, $description: String, $layerId: uuid!, $upper_threshold: Float, $lower_threshold: Float, $is_partial_compliance_allowed: Boolean!) {
  insert_scenario_one(
    object: {scenario_type: $scenarioType, name: $name, description: $description, locationTypes: {data: {layer_id: $layerId}}, upper_threshold: $upper_threshold, lower_threshold: $lower_threshold, is_partial_compliance_allowed: $is_partial_compliance_allowed}
  ) {
    id
    name
    description
    is_partial_compliance_allowed
    lower_threshold
    upper_threshold
    are_thresholds_enabled
  }
}
    `;
export type InsertScenarioMutationFn = Apollo.MutationFunction<Types.InsertScenarioMutation, Types.InsertScenarioMutationVariables>;

/**
 * __useInsertScenarioMutation__
 *
 * To run a mutation, you first call `useInsertScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertScenarioMutation, { data, loading, error }] = useInsertScenarioMutation({
 *   variables: {
 *      scenarioType: // value for 'scenarioType'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      layerId: // value for 'layerId'
 *      upper_threshold: // value for 'upper_threshold'
 *      lower_threshold: // value for 'lower_threshold'
 *      is_partial_compliance_allowed: // value for 'is_partial_compliance_allowed'
 *   },
 * });
 */
export function useInsertScenarioMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertScenarioMutation, Types.InsertScenarioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertScenarioMutation, Types.InsertScenarioMutationVariables>(InsertScenarioDocument, options);
      }
export type InsertScenarioMutationHookResult = ReturnType<typeof useInsertScenarioMutation>;
export type InsertScenarioMutationResult = Apollo.MutationResult<Types.InsertScenarioMutation>;
export type InsertScenarioMutationOptions = Apollo.BaseMutationOptions<Types.InsertScenarioMutation, Types.InsertScenarioMutationVariables>;
export const UpdateScenarioDocument = gql`
    mutation updateScenario($id: Int!, $organizationId: uuid!, $input: scenario_set_input!) {
  update_scenario_by_pk(
    pk_columns: {id: $id, organization_id: $organizationId}
    _set: $input
  ) {
    id
    name
    description
    is_partial_compliance_allowed
    lower_threshold
    upper_threshold
    are_thresholds_enabled
  }
}
    `;
export type UpdateScenarioMutationFn = Apollo.MutationFunction<Types.UpdateScenarioMutation, Types.UpdateScenarioMutationVariables>;

/**
 * __useUpdateScenarioMutation__
 *
 * To run a mutation, you first call `useUpdateScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScenarioMutation, { data, loading, error }] = useUpdateScenarioMutation({
 *   variables: {
 *      id: // value for 'id'
 *      organizationId: // value for 'organizationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScenarioMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateScenarioMutation, Types.UpdateScenarioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateScenarioMutation, Types.UpdateScenarioMutationVariables>(UpdateScenarioDocument, options);
      }
export type UpdateScenarioMutationHookResult = ReturnType<typeof useUpdateScenarioMutation>;
export type UpdateScenarioMutationResult = Apollo.MutationResult<Types.UpdateScenarioMutation>;
export type UpdateScenarioMutationOptions = Apollo.BaseMutationOptions<Types.UpdateScenarioMutation, Types.UpdateScenarioMutationVariables>;
export const CreateScenarioMeasureDocument = gql`
    mutation createScenarioMeasure($scenarioId: Int!, $organizationId: uuid!, $type: enum_inherent_risk_type_enum!, $name: citext!, $description: String, $valueLabels: [scenario_inherent_risk_values_insert_input!]!, $isNumeric: Boolean, $minValue: numeric, $maxValue: numeric) {
  insert_scenario_inherent_risk_measures_one(
    object: {name: $name, description: $description, type: $type, is_user_supplied_numeric: $isNumeric, min_value: $minValue, max_value: $maxValue, scenario_id: $scenarioId, organization_id: $organizationId, valueLabels: {data: $valueLabels}}
  ) {
    id
  }
}
    `;
export type CreateScenarioMeasureMutationFn = Apollo.MutationFunction<Types.CreateScenarioMeasureMutation, Types.CreateScenarioMeasureMutationVariables>;

/**
 * __useCreateScenarioMeasureMutation__
 *
 * To run a mutation, you first call `useCreateScenarioMeasureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScenarioMeasureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScenarioMeasureMutation, { data, loading, error }] = useCreateScenarioMeasureMutation({
 *   variables: {
 *      scenarioId: // value for 'scenarioId'
 *      organizationId: // value for 'organizationId'
 *      type: // value for 'type'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      valueLabels: // value for 'valueLabels'
 *      isNumeric: // value for 'isNumeric'
 *      minValue: // value for 'minValue'
 *      maxValue: // value for 'maxValue'
 *   },
 * });
 */
export function useCreateScenarioMeasureMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateScenarioMeasureMutation, Types.CreateScenarioMeasureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateScenarioMeasureMutation, Types.CreateScenarioMeasureMutationVariables>(CreateScenarioMeasureDocument, options);
      }
export type CreateScenarioMeasureMutationHookResult = ReturnType<typeof useCreateScenarioMeasureMutation>;
export type CreateScenarioMeasureMutationResult = Apollo.MutationResult<Types.CreateScenarioMeasureMutation>;
export type CreateScenarioMeasureMutationOptions = Apollo.BaseMutationOptions<Types.CreateScenarioMeasureMutation, Types.CreateScenarioMeasureMutationVariables>;
export const DeleteScenarioMeasureDocument = gql`
    mutation deleteScenarioMeasure($id: uuid!) {
  delete_scenario_inherent_risk_measures_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteScenarioMeasureMutationFn = Apollo.MutationFunction<Types.DeleteScenarioMeasureMutation, Types.DeleteScenarioMeasureMutationVariables>;

/**
 * __useDeleteScenarioMeasureMutation__
 *
 * To run a mutation, you first call `useDeleteScenarioMeasureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScenarioMeasureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScenarioMeasureMutation, { data, loading, error }] = useDeleteScenarioMeasureMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteScenarioMeasureMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteScenarioMeasureMutation, Types.DeleteScenarioMeasureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteScenarioMeasureMutation, Types.DeleteScenarioMeasureMutationVariables>(DeleteScenarioMeasureDocument, options);
      }
export type DeleteScenarioMeasureMutationHookResult = ReturnType<typeof useDeleteScenarioMeasureMutation>;
export type DeleteScenarioMeasureMutationResult = Apollo.MutationResult<Types.DeleteScenarioMeasureMutation>;
export type DeleteScenarioMeasureMutationOptions = Apollo.BaseMutationOptions<Types.DeleteScenarioMeasureMutation, Types.DeleteScenarioMeasureMutationVariables>;
export const UpsertProbabilityMeasureValueLabelsDocument = gql`
    mutation upsertProbabilityMeasureValueLabels($valueLabels: [scenario_probability_value_labels_insert_input!]!) {
  insert_scenario_probability_value_labels(
    objects: $valueLabels
    on_conflict: {constraint: scenario_probability_value_labels_pkey, update_columns: [name]}
  ) {
    returning {
      name
      value
    }
  }
}
    `;
export type UpsertProbabilityMeasureValueLabelsMutationFn = Apollo.MutationFunction<Types.UpsertProbabilityMeasureValueLabelsMutation, Types.UpsertProbabilityMeasureValueLabelsMutationVariables>;

/**
 * __useUpsertProbabilityMeasureValueLabelsMutation__
 *
 * To run a mutation, you first call `useUpsertProbabilityMeasureValueLabelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProbabilityMeasureValueLabelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProbabilityMeasureValueLabelsMutation, { data, loading, error }] = useUpsertProbabilityMeasureValueLabelsMutation({
 *   variables: {
 *      valueLabels: // value for 'valueLabels'
 *   },
 * });
 */
export function useUpsertProbabilityMeasureValueLabelsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpsertProbabilityMeasureValueLabelsMutation, Types.UpsertProbabilityMeasureValueLabelsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpsertProbabilityMeasureValueLabelsMutation, Types.UpsertProbabilityMeasureValueLabelsMutationVariables>(UpsertProbabilityMeasureValueLabelsDocument, options);
      }
export type UpsertProbabilityMeasureValueLabelsMutationHookResult = ReturnType<typeof useUpsertProbabilityMeasureValueLabelsMutation>;
export type UpsertProbabilityMeasureValueLabelsMutationResult = Apollo.MutationResult<Types.UpsertProbabilityMeasureValueLabelsMutation>;
export type UpsertProbabilityMeasureValueLabelsMutationOptions = Apollo.BaseMutationOptions<Types.UpsertProbabilityMeasureValueLabelsMutation, Types.UpsertProbabilityMeasureValueLabelsMutationVariables>;
export const SelectScenarioQuestionSetItemCategoryDocument = gql`
    mutation selectScenarioQuestionSetItemCategory($itemCategory: scenario_question_set_item_categories_insert_input!) {
  insert_scenario_question_set_item_categories_one(
    object: $itemCategory
    on_conflict: {constraint: scenario_question_set_item_categories_pkey, update_columns: []}
  ) {
    ...ScenarioQuestionSetItemCategory
    selectedQuestions {
      ...ScenarioQuestionSetSelectedQuestions
      standard {
        ...ScenarioQuestionSetQuestionStandard
      }
      questionCriticalities {
        ...ScenarioQuestionSetQuestions
      }
    }
  }
}
    ${ScenarioQuestionSetItemCategoryFragmentDoc}
${ScenarioQuestionSetSelectedQuestionsFragmentDoc}
${ScenarioQuestionSetQuestionStandardFragmentDoc}
${ScenarioQuestionSetQuestionsFragmentDoc}`;
export type SelectScenarioQuestionSetItemCategoryMutationFn = Apollo.MutationFunction<Types.SelectScenarioQuestionSetItemCategoryMutation, Types.SelectScenarioQuestionSetItemCategoryMutationVariables>;

/**
 * __useSelectScenarioQuestionSetItemCategoryMutation__
 *
 * To run a mutation, you first call `useSelectScenarioQuestionSetItemCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectScenarioQuestionSetItemCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectScenarioQuestionSetItemCategoryMutation, { data, loading, error }] = useSelectScenarioQuestionSetItemCategoryMutation({
 *   variables: {
 *      itemCategory: // value for 'itemCategory'
 *   },
 * });
 */
export function useSelectScenarioQuestionSetItemCategoryMutation(baseOptions?: Apollo.MutationHookOptions<Types.SelectScenarioQuestionSetItemCategoryMutation, Types.SelectScenarioQuestionSetItemCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SelectScenarioQuestionSetItemCategoryMutation, Types.SelectScenarioQuestionSetItemCategoryMutationVariables>(SelectScenarioQuestionSetItemCategoryDocument, options);
      }
export type SelectScenarioQuestionSetItemCategoryMutationHookResult = ReturnType<typeof useSelectScenarioQuestionSetItemCategoryMutation>;
export type SelectScenarioQuestionSetItemCategoryMutationResult = Apollo.MutationResult<Types.SelectScenarioQuestionSetItemCategoryMutation>;
export type SelectScenarioQuestionSetItemCategoryMutationOptions = Apollo.BaseMutationOptions<Types.SelectScenarioQuestionSetItemCategoryMutation, Types.SelectScenarioQuestionSetItemCategoryMutationVariables>;
export const RemoveScenarioQuestionSetItemCategoryDocument = gql`
    mutation removeScenarioQuestionSetItemCategory($itemCategoryId: uuid!, $scenarioQuestionSetId: Int!, $organizationId: uuid!) {
  delete_scenario_question_set_item_categories_by_pk(
    item_category_id: $itemCategoryId
    scenario_question_set_id: $scenarioQuestionSetId
    organization_id: $organizationId
  ) {
    ...ScenarioQuestionSetItemCategoryIdentifiers
  }
}
    ${ScenarioQuestionSetItemCategoryIdentifiersFragmentDoc}`;
export type RemoveScenarioQuestionSetItemCategoryMutationFn = Apollo.MutationFunction<Types.RemoveScenarioQuestionSetItemCategoryMutation, Types.RemoveScenarioQuestionSetItemCategoryMutationVariables>;

/**
 * __useRemoveScenarioQuestionSetItemCategoryMutation__
 *
 * To run a mutation, you first call `useRemoveScenarioQuestionSetItemCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveScenarioQuestionSetItemCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeScenarioQuestionSetItemCategoryMutation, { data, loading, error }] = useRemoveScenarioQuestionSetItemCategoryMutation({
 *   variables: {
 *      itemCategoryId: // value for 'itemCategoryId'
 *      scenarioQuestionSetId: // value for 'scenarioQuestionSetId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useRemoveScenarioQuestionSetItemCategoryMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveScenarioQuestionSetItemCategoryMutation, Types.RemoveScenarioQuestionSetItemCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveScenarioQuestionSetItemCategoryMutation, Types.RemoveScenarioQuestionSetItemCategoryMutationVariables>(RemoveScenarioQuestionSetItemCategoryDocument, options);
      }
export type RemoveScenarioQuestionSetItemCategoryMutationHookResult = ReturnType<typeof useRemoveScenarioQuestionSetItemCategoryMutation>;
export type RemoveScenarioQuestionSetItemCategoryMutationResult = Apollo.MutationResult<Types.RemoveScenarioQuestionSetItemCategoryMutation>;
export type RemoveScenarioQuestionSetItemCategoryMutationOptions = Apollo.BaseMutationOptions<Types.RemoveScenarioQuestionSetItemCategoryMutation, Types.RemoveScenarioQuestionSetItemCategoryMutationVariables>;
export const UpdateScenarioQuestionSetSelectedItemCategoriesDocument = gql`
    mutation updateScenarioQuestionSetSelectedItemCategories($updates: [scenario_question_set_item_categories_updates!]!) {
  update_scenario_question_set_item_categories_many(updates: $updates) {
    returning {
      ...ScenarioQuestionSetItemCategoryIdentifiers
    }
  }
}
    ${ScenarioQuestionSetItemCategoryIdentifiersFragmentDoc}`;
export type UpdateScenarioQuestionSetSelectedItemCategoriesMutationFn = Apollo.MutationFunction<Types.UpdateScenarioQuestionSetSelectedItemCategoriesMutation, Types.UpdateScenarioQuestionSetSelectedItemCategoriesMutationVariables>;

/**
 * __useUpdateScenarioQuestionSetSelectedItemCategoriesMutation__
 *
 * To run a mutation, you first call `useUpdateScenarioQuestionSetSelectedItemCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScenarioQuestionSetSelectedItemCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScenarioQuestionSetSelectedItemCategoriesMutation, { data, loading, error }] = useUpdateScenarioQuestionSetSelectedItemCategoriesMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateScenarioQuestionSetSelectedItemCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateScenarioQuestionSetSelectedItemCategoriesMutation, Types.UpdateScenarioQuestionSetSelectedItemCategoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateScenarioQuestionSetSelectedItemCategoriesMutation, Types.UpdateScenarioQuestionSetSelectedItemCategoriesMutationVariables>(UpdateScenarioQuestionSetSelectedItemCategoriesDocument, options);
      }
export type UpdateScenarioQuestionSetSelectedItemCategoriesMutationHookResult = ReturnType<typeof useUpdateScenarioQuestionSetSelectedItemCategoriesMutation>;
export type UpdateScenarioQuestionSetSelectedItemCategoriesMutationResult = Apollo.MutationResult<Types.UpdateScenarioQuestionSetSelectedItemCategoriesMutation>;
export type UpdateScenarioQuestionSetSelectedItemCategoriesMutationOptions = Apollo.BaseMutationOptions<Types.UpdateScenarioQuestionSetSelectedItemCategoriesMutation, Types.UpdateScenarioQuestionSetSelectedItemCategoriesMutationVariables>;
export const DeleteAllQuestionSetItemCategoriesDocument = gql`
    mutation deleteAllQuestionSetItemCategories($questionSetId: Int!) {
  delete_scenario_question_set_item_categories(
    where: {scenario_question_set_id: {_eq: $questionSetId}}
  ) {
    returning {
      ...ScenarioQuestionSetItemCategoryIdentifiers
      ordinal
    }
  }
}
    ${ScenarioQuestionSetItemCategoryIdentifiersFragmentDoc}`;
export type DeleteAllQuestionSetItemCategoriesMutationFn = Apollo.MutationFunction<Types.DeleteAllQuestionSetItemCategoriesMutation, Types.DeleteAllQuestionSetItemCategoriesMutationVariables>;

/**
 * __useDeleteAllQuestionSetItemCategoriesMutation__
 *
 * To run a mutation, you first call `useDeleteAllQuestionSetItemCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAllQuestionSetItemCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAllQuestionSetItemCategoriesMutation, { data, loading, error }] = useDeleteAllQuestionSetItemCategoriesMutation({
 *   variables: {
 *      questionSetId: // value for 'questionSetId'
 *   },
 * });
 */
export function useDeleteAllQuestionSetItemCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteAllQuestionSetItemCategoriesMutation, Types.DeleteAllQuestionSetItemCategoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteAllQuestionSetItemCategoriesMutation, Types.DeleteAllQuestionSetItemCategoriesMutationVariables>(DeleteAllQuestionSetItemCategoriesDocument, options);
      }
export type DeleteAllQuestionSetItemCategoriesMutationHookResult = ReturnType<typeof useDeleteAllQuestionSetItemCategoriesMutation>;
export type DeleteAllQuestionSetItemCategoriesMutationResult = Apollo.MutationResult<Types.DeleteAllQuestionSetItemCategoriesMutation>;
export type DeleteAllQuestionSetItemCategoriesMutationOptions = Apollo.BaseMutationOptions<Types.DeleteAllQuestionSetItemCategoriesMutation, Types.DeleteAllQuestionSetItemCategoriesMutationVariables>;
export const SelectScenarioQuestionSetItemCategoriesDocument = gql`
    mutation selectScenarioQuestionSetItemCategories($itemCategories: [scenario_question_set_item_categories_insert_input!]!) {
  insert_scenario_question_set_item_categories(
    objects: $itemCategories
    on_conflict: {constraint: scenario_question_set_item_categories_pkey, update_columns: [ordinal]}
  ) {
    returning {
      ...ScenarioQuestionSetItemCategory
      selectedQuestions {
        ...ScenarioQuestionSetSelectedQuestions
        standard {
          ...ScenarioQuestionSetQuestionStandard
        }
        questionCriticalities {
          ...ScenarioQuestionSetQuestions
        }
      }
    }
  }
}
    ${ScenarioQuestionSetItemCategoryFragmentDoc}
${ScenarioQuestionSetSelectedQuestionsFragmentDoc}
${ScenarioQuestionSetQuestionStandardFragmentDoc}
${ScenarioQuestionSetQuestionsFragmentDoc}`;
export type SelectScenarioQuestionSetItemCategoriesMutationFn = Apollo.MutationFunction<Types.SelectScenarioQuestionSetItemCategoriesMutation, Types.SelectScenarioQuestionSetItemCategoriesMutationVariables>;

/**
 * __useSelectScenarioQuestionSetItemCategoriesMutation__
 *
 * To run a mutation, you first call `useSelectScenarioQuestionSetItemCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectScenarioQuestionSetItemCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectScenarioQuestionSetItemCategoriesMutation, { data, loading, error }] = useSelectScenarioQuestionSetItemCategoriesMutation({
 *   variables: {
 *      itemCategories: // value for 'itemCategories'
 *   },
 * });
 */
export function useSelectScenarioQuestionSetItemCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<Types.SelectScenarioQuestionSetItemCategoriesMutation, Types.SelectScenarioQuestionSetItemCategoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SelectScenarioQuestionSetItemCategoriesMutation, Types.SelectScenarioQuestionSetItemCategoriesMutationVariables>(SelectScenarioQuestionSetItemCategoriesDocument, options);
      }
export type SelectScenarioQuestionSetItemCategoriesMutationHookResult = ReturnType<typeof useSelectScenarioQuestionSetItemCategoriesMutation>;
export type SelectScenarioQuestionSetItemCategoriesMutationResult = Apollo.MutationResult<Types.SelectScenarioQuestionSetItemCategoriesMutation>;
export type SelectScenarioQuestionSetItemCategoriesMutationOptions = Apollo.BaseMutationOptions<Types.SelectScenarioQuestionSetItemCategoriesMutation, Types.SelectScenarioQuestionSetItemCategoriesMutationVariables>;
export const UpdateQuestionSetLocationTagsDocument = gql`
    mutation updateQuestionSetLocationTags($data: scenario_question_set_location_tags_insert_input!) {
  insert_scenario_question_set_location_tags_one(
    object: $data
    on_conflict: {constraint: scenario_question_set_location_tags_pkey, update_columns: [layer_tag_id]}
  ) {
    ...ScenarioQuestionSetSelectedLocationTagsIdentifiers
    layer_tag {
      id
      layer_id
      type_name
    }
  }
}
    ${ScenarioQuestionSetSelectedLocationTagsIdentifiersFragmentDoc}`;
export type UpdateQuestionSetLocationTagsMutationFn = Apollo.MutationFunction<Types.UpdateQuestionSetLocationTagsMutation, Types.UpdateQuestionSetLocationTagsMutationVariables>;

/**
 * __useUpdateQuestionSetLocationTagsMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionSetLocationTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionSetLocationTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionSetLocationTagsMutation, { data, loading, error }] = useUpdateQuestionSetLocationTagsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateQuestionSetLocationTagsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateQuestionSetLocationTagsMutation, Types.UpdateQuestionSetLocationTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateQuestionSetLocationTagsMutation, Types.UpdateQuestionSetLocationTagsMutationVariables>(UpdateQuestionSetLocationTagsDocument, options);
      }
export type UpdateQuestionSetLocationTagsMutationHookResult = ReturnType<typeof useUpdateQuestionSetLocationTagsMutation>;
export type UpdateQuestionSetLocationTagsMutationResult = Apollo.MutationResult<Types.UpdateQuestionSetLocationTagsMutation>;
export type UpdateQuestionSetLocationTagsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateQuestionSetLocationTagsMutation, Types.UpdateQuestionSetLocationTagsMutationVariables>;
export const DeleteQuestionSetLocationTagsDocument = gql`
    mutation deleteQuestionSetLocationTags($layer_id: uuid!, $layer_tag_id: Int!, $organization_id: uuid!, $scenario_question_set_id: Int!) {
  delete_scenario_question_set_location_tags_by_pk(
    layer_id: $layer_id
    layer_tag_id: $layer_tag_id
    organization_id: $organization_id
    scenario_question_set_id: $scenario_question_set_id
  ) {
    ...ScenarioQuestionSetSelectedLocationTagsIdentifiers
  }
}
    ${ScenarioQuestionSetSelectedLocationTagsIdentifiersFragmentDoc}`;
export type DeleteQuestionSetLocationTagsMutationFn = Apollo.MutationFunction<Types.DeleteQuestionSetLocationTagsMutation, Types.DeleteQuestionSetLocationTagsMutationVariables>;

/**
 * __useDeleteQuestionSetLocationTagsMutation__
 *
 * To run a mutation, you first call `useDeleteQuestionSetLocationTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuestionSetLocationTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionSetLocationTagsMutation, { data, loading, error }] = useDeleteQuestionSetLocationTagsMutation({
 *   variables: {
 *      layer_id: // value for 'layer_id'
 *      layer_tag_id: // value for 'layer_tag_id'
 *      organization_id: // value for 'organization_id'
 *      scenario_question_set_id: // value for 'scenario_question_set_id'
 *   },
 * });
 */
export function useDeleteQuestionSetLocationTagsMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteQuestionSetLocationTagsMutation, Types.DeleteQuestionSetLocationTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteQuestionSetLocationTagsMutation, Types.DeleteQuestionSetLocationTagsMutationVariables>(DeleteQuestionSetLocationTagsDocument, options);
      }
export type DeleteQuestionSetLocationTagsMutationHookResult = ReturnType<typeof useDeleteQuestionSetLocationTagsMutation>;
export type DeleteQuestionSetLocationTagsMutationResult = Apollo.MutationResult<Types.DeleteQuestionSetLocationTagsMutation>;
export type DeleteQuestionSetLocationTagsMutationOptions = Apollo.BaseMutationOptions<Types.DeleteQuestionSetLocationTagsMutation, Types.DeleteQuestionSetLocationTagsMutationVariables>;
export const UpdateQuestionSetQuestionCriticalitiesDocument = gql`
    mutation updateQuestionSetQuestionCriticalities($criticalities: [scenario_question_set_question_insert_input!]!, $layersAndTagsCriticalitiesToRemoveExp: [scenario_question_set_question_bool_exp!]!, $scenarioQuestionSetId: Int!, $questionId: uuid!) {
  insert_scenario_question_set_question(
    objects: $criticalities
    on_conflict: {constraint: scenario_question_set_question_pkey, update_columns: [is_required, criticality, ordinal]}
  ) {
    returning {
      ...ScenarioQuestionSetQuestions
    }
  }
  delete_scenario_question_set_question(
    where: {_and: {scenario_question_set_id: {_eq: $scenarioQuestionSetId}, question_id: {_eq: $questionId}, _or: $layersAndTagsCriticalitiesToRemoveExp}}
  ) {
    returning {
      ...ScenarioQuestionSetQuestions
    }
  }
}
    ${ScenarioQuestionSetQuestionsFragmentDoc}`;
export type UpdateQuestionSetQuestionCriticalitiesMutationFn = Apollo.MutationFunction<Types.UpdateQuestionSetQuestionCriticalitiesMutation, Types.UpdateQuestionSetQuestionCriticalitiesMutationVariables>;

/**
 * __useUpdateQuestionSetQuestionCriticalitiesMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionSetQuestionCriticalitiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionSetQuestionCriticalitiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionSetQuestionCriticalitiesMutation, { data, loading, error }] = useUpdateQuestionSetQuestionCriticalitiesMutation({
 *   variables: {
 *      criticalities: // value for 'criticalities'
 *      layersAndTagsCriticalitiesToRemoveExp: // value for 'layersAndTagsCriticalitiesToRemoveExp'
 *      scenarioQuestionSetId: // value for 'scenarioQuestionSetId'
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useUpdateQuestionSetQuestionCriticalitiesMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateQuestionSetQuestionCriticalitiesMutation, Types.UpdateQuestionSetQuestionCriticalitiesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateQuestionSetQuestionCriticalitiesMutation, Types.UpdateQuestionSetQuestionCriticalitiesMutationVariables>(UpdateQuestionSetQuestionCriticalitiesDocument, options);
      }
export type UpdateQuestionSetQuestionCriticalitiesMutationHookResult = ReturnType<typeof useUpdateQuestionSetQuestionCriticalitiesMutation>;
export type UpdateQuestionSetQuestionCriticalitiesMutationResult = Apollo.MutationResult<Types.UpdateQuestionSetQuestionCriticalitiesMutation>;
export type UpdateQuestionSetQuestionCriticalitiesMutationOptions = Apollo.BaseMutationOptions<Types.UpdateQuestionSetQuestionCriticalitiesMutation, Types.UpdateQuestionSetQuestionCriticalitiesMutationVariables>;
export const UpdateBulkQuestionSetQuestionCriticalitiesDocument = gql`
    mutation updateBulkQuestionSetQuestionCriticalities($criticalities: [scenario_question_set_question_insert_input!]!) {
  insert_scenario_question_set_question(
    objects: $criticalities
    on_conflict: {constraint: scenario_question_set_question_pkey, update_columns: [is_required, criticality, ordinal]}
  ) {
    returning {
      ...ScenarioQuestionSetQuestions
    }
  }
}
    ${ScenarioQuestionSetQuestionsFragmentDoc}`;
export type UpdateBulkQuestionSetQuestionCriticalitiesMutationFn = Apollo.MutationFunction<Types.UpdateBulkQuestionSetQuestionCriticalitiesMutation, Types.UpdateBulkQuestionSetQuestionCriticalitiesMutationVariables>;

/**
 * __useUpdateBulkQuestionSetQuestionCriticalitiesMutation__
 *
 * To run a mutation, you first call `useUpdateBulkQuestionSetQuestionCriticalitiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBulkQuestionSetQuestionCriticalitiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBulkQuestionSetQuestionCriticalitiesMutation, { data, loading, error }] = useUpdateBulkQuestionSetQuestionCriticalitiesMutation({
 *   variables: {
 *      criticalities: // value for 'criticalities'
 *   },
 * });
 */
export function useUpdateBulkQuestionSetQuestionCriticalitiesMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateBulkQuestionSetQuestionCriticalitiesMutation, Types.UpdateBulkQuestionSetQuestionCriticalitiesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateBulkQuestionSetQuestionCriticalitiesMutation, Types.UpdateBulkQuestionSetQuestionCriticalitiesMutationVariables>(UpdateBulkQuestionSetQuestionCriticalitiesDocument, options);
      }
export type UpdateBulkQuestionSetQuestionCriticalitiesMutationHookResult = ReturnType<typeof useUpdateBulkQuestionSetQuestionCriticalitiesMutation>;
export type UpdateBulkQuestionSetQuestionCriticalitiesMutationResult = Apollo.MutationResult<Types.UpdateBulkQuestionSetQuestionCriticalitiesMutation>;
export type UpdateBulkQuestionSetQuestionCriticalitiesMutationOptions = Apollo.BaseMutationOptions<Types.UpdateBulkQuestionSetQuestionCriticalitiesMutation, Types.UpdateBulkQuestionSetQuestionCriticalitiesMutationVariables>;
export const UpdateQuestionSetQuestionCriticalityDocument = gql`
    mutation updateQuestionSetQuestionCriticality($criticality: scenario_question_set_question_insert_input!) {
  insert_scenario_question_set_question_one(
    object: $criticality
    on_conflict: {constraint: scenario_question_set_question_pkey, update_columns: [is_required, criticality, ordinal]}
  ) {
    ...ScenarioQuestionSetQuestions
  }
}
    ${ScenarioQuestionSetQuestionsFragmentDoc}`;
export type UpdateQuestionSetQuestionCriticalityMutationFn = Apollo.MutationFunction<Types.UpdateQuestionSetQuestionCriticalityMutation, Types.UpdateQuestionSetQuestionCriticalityMutationVariables>;

/**
 * __useUpdateQuestionSetQuestionCriticalityMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionSetQuestionCriticalityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionSetQuestionCriticalityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionSetQuestionCriticalityMutation, { data, loading, error }] = useUpdateQuestionSetQuestionCriticalityMutation({
 *   variables: {
 *      criticality: // value for 'criticality'
 *   },
 * });
 */
export function useUpdateQuestionSetQuestionCriticalityMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateQuestionSetQuestionCriticalityMutation, Types.UpdateQuestionSetQuestionCriticalityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateQuestionSetQuestionCriticalityMutation, Types.UpdateQuestionSetQuestionCriticalityMutationVariables>(UpdateQuestionSetQuestionCriticalityDocument, options);
      }
export type UpdateQuestionSetQuestionCriticalityMutationHookResult = ReturnType<typeof useUpdateQuestionSetQuestionCriticalityMutation>;
export type UpdateQuestionSetQuestionCriticalityMutationResult = Apollo.MutationResult<Types.UpdateQuestionSetQuestionCriticalityMutation>;
export type UpdateQuestionSetQuestionCriticalityMutationOptions = Apollo.BaseMutationOptions<Types.UpdateQuestionSetQuestionCriticalityMutation, Types.UpdateQuestionSetQuestionCriticalityMutationVariables>;
export const UpdateQuestionSetQuestionDocument = gql`
    mutation updateQuestionSetQuestion($key: scenario_question_set_question_pk_columns_input!, $question: scenario_question_set_question_set_input!) {
  update_scenario_question_set_question_by_pk(pk_columns: $key, _set: $question) {
    ...ScenarioQuestionSetQuestions
  }
}
    ${ScenarioQuestionSetQuestionsFragmentDoc}`;
export type UpdateQuestionSetQuestionMutationFn = Apollo.MutationFunction<Types.UpdateQuestionSetQuestionMutation, Types.UpdateQuestionSetQuestionMutationVariables>;

/**
 * __useUpdateQuestionSetQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionSetQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionSetQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionSetQuestionMutation, { data, loading, error }] = useUpdateQuestionSetQuestionMutation({
 *   variables: {
 *      key: // value for 'key'
 *      question: // value for 'question'
 *   },
 * });
 */
export function useUpdateQuestionSetQuestionMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateQuestionSetQuestionMutation, Types.UpdateQuestionSetQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateQuestionSetQuestionMutation, Types.UpdateQuestionSetQuestionMutationVariables>(UpdateQuestionSetQuestionDocument, options);
      }
export type UpdateQuestionSetQuestionMutationHookResult = ReturnType<typeof useUpdateQuestionSetQuestionMutation>;
export type UpdateQuestionSetQuestionMutationResult = Apollo.MutationResult<Types.UpdateQuestionSetQuestionMutation>;
export type UpdateQuestionSetQuestionMutationOptions = Apollo.BaseMutationOptions<Types.UpdateQuestionSetQuestionMutation, Types.UpdateQuestionSetQuestionMutationVariables>;
export const UpdateQuestionSetQuestionsRelationshipDocument = gql`
    mutation updateQuestionSetQuestionsRelationship($updates: [scenario_question_set_question_updates!]!) {
  update_scenario_question_set_question_many(updates: $updates) {
    returning {
      ...ScenarioQuestionSetQuestionIdentifiers
      ...ScenarioQuestionSetQuestionsRelationship
    }
  }
}
    ${ScenarioQuestionSetQuestionIdentifiersFragmentDoc}
${ScenarioQuestionSetQuestionsRelationshipFragmentDoc}`;
export type UpdateQuestionSetQuestionsRelationshipMutationFn = Apollo.MutationFunction<Types.UpdateQuestionSetQuestionsRelationshipMutation, Types.UpdateQuestionSetQuestionsRelationshipMutationVariables>;

/**
 * __useUpdateQuestionSetQuestionsRelationshipMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionSetQuestionsRelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionSetQuestionsRelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionSetQuestionsRelationshipMutation, { data, loading, error }] = useUpdateQuestionSetQuestionsRelationshipMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateQuestionSetQuestionsRelationshipMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateQuestionSetQuestionsRelationshipMutation, Types.UpdateQuestionSetQuestionsRelationshipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateQuestionSetQuestionsRelationshipMutation, Types.UpdateQuestionSetQuestionsRelationshipMutationVariables>(UpdateQuestionSetQuestionsRelationshipDocument, options);
      }
export type UpdateQuestionSetQuestionsRelationshipMutationHookResult = ReturnType<typeof useUpdateQuestionSetQuestionsRelationshipMutation>;
export type UpdateQuestionSetQuestionsRelationshipMutationResult = Apollo.MutationResult<Types.UpdateQuestionSetQuestionsRelationshipMutation>;
export type UpdateQuestionSetQuestionsRelationshipMutationOptions = Apollo.BaseMutationOptions<Types.UpdateQuestionSetQuestionsRelationshipMutation, Types.UpdateQuestionSetQuestionsRelationshipMutationVariables>;
export const DeleteBulkQuestionSetQuestionCriticalitiesDocument = gql`
    mutation deleteBulkQuestionSetQuestionCriticalities($exp: scenario_question_set_question_bool_exp!) {
  delete_scenario_question_set_question(where: $exp) {
    returning {
      ...ScenarioQuestionSetQuestions
    }
  }
}
    ${ScenarioQuestionSetQuestionsFragmentDoc}`;
export type DeleteBulkQuestionSetQuestionCriticalitiesMutationFn = Apollo.MutationFunction<Types.DeleteBulkQuestionSetQuestionCriticalitiesMutation, Types.DeleteBulkQuestionSetQuestionCriticalitiesMutationVariables>;

/**
 * __useDeleteBulkQuestionSetQuestionCriticalitiesMutation__
 *
 * To run a mutation, you first call `useDeleteBulkQuestionSetQuestionCriticalitiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBulkQuestionSetQuestionCriticalitiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBulkQuestionSetQuestionCriticalitiesMutation, { data, loading, error }] = useDeleteBulkQuestionSetQuestionCriticalitiesMutation({
 *   variables: {
 *      exp: // value for 'exp'
 *   },
 * });
 */
export function useDeleteBulkQuestionSetQuestionCriticalitiesMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteBulkQuestionSetQuestionCriticalitiesMutation, Types.DeleteBulkQuestionSetQuestionCriticalitiesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteBulkQuestionSetQuestionCriticalitiesMutation, Types.DeleteBulkQuestionSetQuestionCriticalitiesMutationVariables>(DeleteBulkQuestionSetQuestionCriticalitiesDocument, options);
      }
export type DeleteBulkQuestionSetQuestionCriticalitiesMutationHookResult = ReturnType<typeof useDeleteBulkQuestionSetQuestionCriticalitiesMutation>;
export type DeleteBulkQuestionSetQuestionCriticalitiesMutationResult = Apollo.MutationResult<Types.DeleteBulkQuestionSetQuestionCriticalitiesMutation>;
export type DeleteBulkQuestionSetQuestionCriticalitiesMutationOptions = Apollo.BaseMutationOptions<Types.DeleteBulkQuestionSetQuestionCriticalitiesMutation, Types.DeleteBulkQuestionSetQuestionCriticalitiesMutationVariables>;
export const DeleteQuestionSetQuestionCriticalityDocument = gql`
    mutation deleteQuestionSetQuestionCriticality($layer_id: uuid!, $layer_tag_id: Int!, $question_id: uuid!, $organization_id: uuid!, $scenario_question_set_id: Int!) {
  delete_scenario_question_set_question_by_pk(
    layer_id: $layer_id
    layer_tag_id: $layer_tag_id
    question_id: $question_id
    organization_id: $organization_id
    scenario_question_set_id: $scenario_question_set_id
  ) {
    ...ScenarioQuestionSetQuestions
  }
}
    ${ScenarioQuestionSetQuestionsFragmentDoc}`;
export type DeleteQuestionSetQuestionCriticalityMutationFn = Apollo.MutationFunction<Types.DeleteQuestionSetQuestionCriticalityMutation, Types.DeleteQuestionSetQuestionCriticalityMutationVariables>;

/**
 * __useDeleteQuestionSetQuestionCriticalityMutation__
 *
 * To run a mutation, you first call `useDeleteQuestionSetQuestionCriticalityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuestionSetQuestionCriticalityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionSetQuestionCriticalityMutation, { data, loading, error }] = useDeleteQuestionSetQuestionCriticalityMutation({
 *   variables: {
 *      layer_id: // value for 'layer_id'
 *      layer_tag_id: // value for 'layer_tag_id'
 *      question_id: // value for 'question_id'
 *      organization_id: // value for 'organization_id'
 *      scenario_question_set_id: // value for 'scenario_question_set_id'
 *   },
 * });
 */
export function useDeleteQuestionSetQuestionCriticalityMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteQuestionSetQuestionCriticalityMutation, Types.DeleteQuestionSetQuestionCriticalityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteQuestionSetQuestionCriticalityMutation, Types.DeleteQuestionSetQuestionCriticalityMutationVariables>(DeleteQuestionSetQuestionCriticalityDocument, options);
      }
export type DeleteQuestionSetQuestionCriticalityMutationHookResult = ReturnType<typeof useDeleteQuestionSetQuestionCriticalityMutation>;
export type DeleteQuestionSetQuestionCriticalityMutationResult = Apollo.MutationResult<Types.DeleteQuestionSetQuestionCriticalityMutation>;
export type DeleteQuestionSetQuestionCriticalityMutationOptions = Apollo.BaseMutationOptions<Types.DeleteQuestionSetQuestionCriticalityMutation, Types.DeleteQuestionSetQuestionCriticalityMutationVariables>;
export const DeleteLocationTagQuestionSetQuestionCriticalitiesDocument = gql`
    mutation deleteLocationTagQuestionSetQuestionCriticalities($layer_tag_id: Int!, $layer_id: uuid!, $organization_id: uuid!, $scenario_question_set_id: Int!) {
  delete_scenario_question_set_question(
    where: {layer_tag_id: {_eq: $layer_tag_id}, layer_id: {_eq: $layer_id}, scenario_question_set_id: {_eq: $scenario_question_set_id}, organization_id: {_eq: $organization_id}}
  ) {
    returning {
      ...ScenarioQuestionSetQuestions
    }
  }
}
    ${ScenarioQuestionSetQuestionsFragmentDoc}`;
export type DeleteLocationTagQuestionSetQuestionCriticalitiesMutationFn = Apollo.MutationFunction<Types.DeleteLocationTagQuestionSetQuestionCriticalitiesMutation, Types.DeleteLocationTagQuestionSetQuestionCriticalitiesMutationVariables>;

/**
 * __useDeleteLocationTagQuestionSetQuestionCriticalitiesMutation__
 *
 * To run a mutation, you first call `useDeleteLocationTagQuestionSetQuestionCriticalitiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationTagQuestionSetQuestionCriticalitiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationTagQuestionSetQuestionCriticalitiesMutation, { data, loading, error }] = useDeleteLocationTagQuestionSetQuestionCriticalitiesMutation({
 *   variables: {
 *      layer_tag_id: // value for 'layer_tag_id'
 *      layer_id: // value for 'layer_id'
 *      organization_id: // value for 'organization_id'
 *      scenario_question_set_id: // value for 'scenario_question_set_id'
 *   },
 * });
 */
export function useDeleteLocationTagQuestionSetQuestionCriticalitiesMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteLocationTagQuestionSetQuestionCriticalitiesMutation, Types.DeleteLocationTagQuestionSetQuestionCriticalitiesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteLocationTagQuestionSetQuestionCriticalitiesMutation, Types.DeleteLocationTagQuestionSetQuestionCriticalitiesMutationVariables>(DeleteLocationTagQuestionSetQuestionCriticalitiesDocument, options);
      }
export type DeleteLocationTagQuestionSetQuestionCriticalitiesMutationHookResult = ReturnType<typeof useDeleteLocationTagQuestionSetQuestionCriticalitiesMutation>;
export type DeleteLocationTagQuestionSetQuestionCriticalitiesMutationResult = Apollo.MutationResult<Types.DeleteLocationTagQuestionSetQuestionCriticalitiesMutation>;
export type DeleteLocationTagQuestionSetQuestionCriticalitiesMutationOptions = Apollo.BaseMutationOptions<Types.DeleteLocationTagQuestionSetQuestionCriticalitiesMutation, Types.DeleteLocationTagQuestionSetQuestionCriticalitiesMutationVariables>;
export const UnRelateChildQuestionsDocument = gql`
    mutation unRelateChildQuestions($layer_id: uuid!, $layer_tag_id: Int!, $parent_question_id: uuid!, $scenario_question_set_id: Int!) {
  update_scenario_question_set_question(
    where: {layer_id: {_eq: $layer_id}, layer_tag_id: {_eq: $layer_tag_id}, scenario_question_set_id: {_eq: $scenario_question_set_id}, parent_question_id: {_eq: $parent_question_id}}
    _set: {parent_question_id: null, parent_question_response_type: null}
  ) {
    returning {
      ...ScenarioQuestionSetQuestionIdentifiers
      ...ScenarioQuestionSetQuestionsRelationship
    }
  }
}
    ${ScenarioQuestionSetQuestionIdentifiersFragmentDoc}
${ScenarioQuestionSetQuestionsRelationshipFragmentDoc}`;
export type UnRelateChildQuestionsMutationFn = Apollo.MutationFunction<Types.UnRelateChildQuestionsMutation, Types.UnRelateChildQuestionsMutationVariables>;

/**
 * __useUnRelateChildQuestionsMutation__
 *
 * To run a mutation, you first call `useUnRelateChildQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnRelateChildQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unRelateChildQuestionsMutation, { data, loading, error }] = useUnRelateChildQuestionsMutation({
 *   variables: {
 *      layer_id: // value for 'layer_id'
 *      layer_tag_id: // value for 'layer_tag_id'
 *      parent_question_id: // value for 'parent_question_id'
 *      scenario_question_set_id: // value for 'scenario_question_set_id'
 *   },
 * });
 */
export function useUnRelateChildQuestionsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UnRelateChildQuestionsMutation, Types.UnRelateChildQuestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UnRelateChildQuestionsMutation, Types.UnRelateChildQuestionsMutationVariables>(UnRelateChildQuestionsDocument, options);
      }
export type UnRelateChildQuestionsMutationHookResult = ReturnType<typeof useUnRelateChildQuestionsMutation>;
export type UnRelateChildQuestionsMutationResult = Apollo.MutationResult<Types.UnRelateChildQuestionsMutation>;
export type UnRelateChildQuestionsMutationOptions = Apollo.BaseMutationOptions<Types.UnRelateChildQuestionsMutation, Types.UnRelateChildQuestionsMutationVariables>;
export const RemoveScenarioQuestionSetQuestionsDocument = gql`
    mutation removeScenarioQuestionSetQuestions($scenario_question_set_id: Int!, $remove_questions: [uuid!]!) {
  delete_scenario_question_set_selected_questions(
    where: {question_id: {_in: $remove_questions}, scenario_question_set_id: {_eq: $scenario_question_set_id}}
  ) {
    __typename
    returning {
      ...ScenarioQuestionSetSelectedQuestionIdentifiers
      item_category_id
      organization_id
    }
  }
}
    ${ScenarioQuestionSetSelectedQuestionIdentifiersFragmentDoc}`;
export type RemoveScenarioQuestionSetQuestionsMutationFn = Apollo.MutationFunction<Types.RemoveScenarioQuestionSetQuestionsMutation, Types.RemoveScenarioQuestionSetQuestionsMutationVariables>;

/**
 * __useRemoveScenarioQuestionSetQuestionsMutation__
 *
 * To run a mutation, you first call `useRemoveScenarioQuestionSetQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveScenarioQuestionSetQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeScenarioQuestionSetQuestionsMutation, { data, loading, error }] = useRemoveScenarioQuestionSetQuestionsMutation({
 *   variables: {
 *      scenario_question_set_id: // value for 'scenario_question_set_id'
 *      remove_questions: // value for 'remove_questions'
 *   },
 * });
 */
export function useRemoveScenarioQuestionSetQuestionsMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveScenarioQuestionSetQuestionsMutation, Types.RemoveScenarioQuestionSetQuestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveScenarioQuestionSetQuestionsMutation, Types.RemoveScenarioQuestionSetQuestionsMutationVariables>(RemoveScenarioQuestionSetQuestionsDocument, options);
      }
export type RemoveScenarioQuestionSetQuestionsMutationHookResult = ReturnType<typeof useRemoveScenarioQuestionSetQuestionsMutation>;
export type RemoveScenarioQuestionSetQuestionsMutationResult = Apollo.MutationResult<Types.RemoveScenarioQuestionSetQuestionsMutation>;
export type RemoveScenarioQuestionSetQuestionsMutationOptions = Apollo.BaseMutationOptions<Types.RemoveScenarioQuestionSetQuestionsMutation, Types.RemoveScenarioQuestionSetQuestionsMutationVariables>;
export const SelectScenarioQuestionSetQuestionsDocument = gql`
    mutation selectScenarioQuestionSetQuestions($questions: [scenario_question_set_selected_questions_insert_input!]!) {
  insert_scenario_question_set_selected_questions(
    objects: $questions
    on_conflict: {constraint: scenario_question_set_selected_questions_pkey, update_columns: [ordinal]}
  ) {
    returning {
      ...ScenarioQuestionSetSelectedQuestions
      standard {
        ...ScenarioQuestionSetQuestionStandard
      }
      questionCriticalities {
        ...ScenarioQuestionSetQuestions
      }
    }
  }
}
    ${ScenarioQuestionSetSelectedQuestionsFragmentDoc}
${ScenarioQuestionSetQuestionStandardFragmentDoc}
${ScenarioQuestionSetQuestionsFragmentDoc}`;
export type SelectScenarioQuestionSetQuestionsMutationFn = Apollo.MutationFunction<Types.SelectScenarioQuestionSetQuestionsMutation, Types.SelectScenarioQuestionSetQuestionsMutationVariables>;

/**
 * __useSelectScenarioQuestionSetQuestionsMutation__
 *
 * To run a mutation, you first call `useSelectScenarioQuestionSetQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectScenarioQuestionSetQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectScenarioQuestionSetQuestionsMutation, { data, loading, error }] = useSelectScenarioQuestionSetQuestionsMutation({
 *   variables: {
 *      questions: // value for 'questions'
 *   },
 * });
 */
export function useSelectScenarioQuestionSetQuestionsMutation(baseOptions?: Apollo.MutationHookOptions<Types.SelectScenarioQuestionSetQuestionsMutation, Types.SelectScenarioQuestionSetQuestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SelectScenarioQuestionSetQuestionsMutation, Types.SelectScenarioQuestionSetQuestionsMutationVariables>(SelectScenarioQuestionSetQuestionsDocument, options);
      }
export type SelectScenarioQuestionSetQuestionsMutationHookResult = ReturnType<typeof useSelectScenarioQuestionSetQuestionsMutation>;
export type SelectScenarioQuestionSetQuestionsMutationResult = Apollo.MutationResult<Types.SelectScenarioQuestionSetQuestionsMutation>;
export type SelectScenarioQuestionSetQuestionsMutationOptions = Apollo.BaseMutationOptions<Types.SelectScenarioQuestionSetQuestionsMutation, Types.SelectScenarioQuestionSetQuestionsMutationVariables>;
export const UpdateScenarioQuestionSetSelectedQuestionsDocument = gql`
    mutation updateScenarioQuestionSetSelectedQuestions($updates: [scenario_question_set_selected_questions_updates!]!) {
  update_scenario_question_set_selected_questions_many(updates: $updates) {
    returning {
      ...ScenarioQuestionSetSelectedQuestions
    }
  }
}
    ${ScenarioQuestionSetSelectedQuestionsFragmentDoc}`;
export type UpdateScenarioQuestionSetSelectedQuestionsMutationFn = Apollo.MutationFunction<Types.UpdateScenarioQuestionSetSelectedQuestionsMutation, Types.UpdateScenarioQuestionSetSelectedQuestionsMutationVariables>;

/**
 * __useUpdateScenarioQuestionSetSelectedQuestionsMutation__
 *
 * To run a mutation, you first call `useUpdateScenarioQuestionSetSelectedQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScenarioQuestionSetSelectedQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScenarioQuestionSetSelectedQuestionsMutation, { data, loading, error }] = useUpdateScenarioQuestionSetSelectedQuestionsMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateScenarioQuestionSetSelectedQuestionsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateScenarioQuestionSetSelectedQuestionsMutation, Types.UpdateScenarioQuestionSetSelectedQuestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateScenarioQuestionSetSelectedQuestionsMutation, Types.UpdateScenarioQuestionSetSelectedQuestionsMutationVariables>(UpdateScenarioQuestionSetSelectedQuestionsDocument, options);
      }
export type UpdateScenarioQuestionSetSelectedQuestionsMutationHookResult = ReturnType<typeof useUpdateScenarioQuestionSetSelectedQuestionsMutation>;
export type UpdateScenarioQuestionSetSelectedQuestionsMutationResult = Apollo.MutationResult<Types.UpdateScenarioQuestionSetSelectedQuestionsMutation>;
export type UpdateScenarioQuestionSetSelectedQuestionsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateScenarioQuestionSetSelectedQuestionsMutation, Types.UpdateScenarioQuestionSetSelectedQuestionsMutationVariables>;
export const UpdateQuestionSetQuestionStandardsDocument = gql`
    mutation updateQuestionSetQuestionStandards($input: scenario_question_set_standards_insert_input!) {
  insert_scenario_question_set_standards_one(
    on_conflict: {constraint: scenario_question_set_standards_pkey, update_columns: [standard_name, section1, section2, section3, relevant_excerpt]}
    object: $input
  ) {
    ...ScenarioQuestionSetStandardIdentifiers
    standard_name
    section3
    section2
    section1
    relevant_excerpt
  }
}
    ${ScenarioQuestionSetStandardIdentifiersFragmentDoc}`;
export type UpdateQuestionSetQuestionStandardsMutationFn = Apollo.MutationFunction<Types.UpdateQuestionSetQuestionStandardsMutation, Types.UpdateQuestionSetQuestionStandardsMutationVariables>;

/**
 * __useUpdateQuestionSetQuestionStandardsMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionSetQuestionStandardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionSetQuestionStandardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionSetQuestionStandardsMutation, { data, loading, error }] = useUpdateQuestionSetQuestionStandardsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQuestionSetQuestionStandardsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateQuestionSetQuestionStandardsMutation, Types.UpdateQuestionSetQuestionStandardsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateQuestionSetQuestionStandardsMutation, Types.UpdateQuestionSetQuestionStandardsMutationVariables>(UpdateQuestionSetQuestionStandardsDocument, options);
      }
export type UpdateQuestionSetQuestionStandardsMutationHookResult = ReturnType<typeof useUpdateQuestionSetQuestionStandardsMutation>;
export type UpdateQuestionSetQuestionStandardsMutationResult = Apollo.MutationResult<Types.UpdateQuestionSetQuestionStandardsMutation>;
export type UpdateQuestionSetQuestionStandardsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateQuestionSetQuestionStandardsMutation, Types.UpdateQuestionSetQuestionStandardsMutationVariables>;
export const DeleteQuestionSetQuestionStandardsDocument = gql`
    mutation deleteQuestionSetQuestionStandards($scenarioQuestionSetId: Int!, $questionId: uuid!, $organizationId: uuid!) {
  delete_scenario_question_set_standards_by_pk(
    organization_id: $organizationId
    question_id: $questionId
    scenario_question_set_id: $scenarioQuestionSetId
  ) {
    ...ScenarioQuestionSetQuestionStandard
  }
}
    ${ScenarioQuestionSetQuestionStandardFragmentDoc}`;
export type DeleteQuestionSetQuestionStandardsMutationFn = Apollo.MutationFunction<Types.DeleteQuestionSetQuestionStandardsMutation, Types.DeleteQuestionSetQuestionStandardsMutationVariables>;

/**
 * __useDeleteQuestionSetQuestionStandardsMutation__
 *
 * To run a mutation, you first call `useDeleteQuestionSetQuestionStandardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuestionSetQuestionStandardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionSetQuestionStandardsMutation, { data, loading, error }] = useDeleteQuestionSetQuestionStandardsMutation({
 *   variables: {
 *      scenarioQuestionSetId: // value for 'scenarioQuestionSetId'
 *      questionId: // value for 'questionId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useDeleteQuestionSetQuestionStandardsMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteQuestionSetQuestionStandardsMutation, Types.DeleteQuestionSetQuestionStandardsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteQuestionSetQuestionStandardsMutation, Types.DeleteQuestionSetQuestionStandardsMutationVariables>(DeleteQuestionSetQuestionStandardsDocument, options);
      }
export type DeleteQuestionSetQuestionStandardsMutationHookResult = ReturnType<typeof useDeleteQuestionSetQuestionStandardsMutation>;
export type DeleteQuestionSetQuestionStandardsMutationResult = Apollo.MutationResult<Types.DeleteQuestionSetQuestionStandardsMutation>;
export type DeleteQuestionSetQuestionStandardsMutationOptions = Apollo.BaseMutationOptions<Types.DeleteQuestionSetQuestionStandardsMutation, Types.DeleteQuestionSetQuestionStandardsMutationVariables>;
export const AddScenarioQuestionSetDocument = gql`
    mutation addScenarioQuestionSet($input: scenario_question_sets_insert_input!) {
  insert_scenario_question_sets_one(object: $input) {
    ...scenarioQuestionSet
  }
}
    ${ScenarioQuestionSetFragmentDoc}`;
export type AddScenarioQuestionSetMutationFn = Apollo.MutationFunction<Types.AddScenarioQuestionSetMutation, Types.AddScenarioQuestionSetMutationVariables>;

/**
 * __useAddScenarioQuestionSetMutation__
 *
 * To run a mutation, you first call `useAddScenarioQuestionSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddScenarioQuestionSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addScenarioQuestionSetMutation, { data, loading, error }] = useAddScenarioQuestionSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddScenarioQuestionSetMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddScenarioQuestionSetMutation, Types.AddScenarioQuestionSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddScenarioQuestionSetMutation, Types.AddScenarioQuestionSetMutationVariables>(AddScenarioQuestionSetDocument, options);
      }
export type AddScenarioQuestionSetMutationHookResult = ReturnType<typeof useAddScenarioQuestionSetMutation>;
export type AddScenarioQuestionSetMutationResult = Apollo.MutationResult<Types.AddScenarioQuestionSetMutation>;
export type AddScenarioQuestionSetMutationOptions = Apollo.BaseMutationOptions<Types.AddScenarioQuestionSetMutation, Types.AddScenarioQuestionSetMutationVariables>;
export const UpdateScenarioQuestionSetDocument = gql`
    mutation updateScenarioQuestionSet($id: Int!, $organization_id: uuid!, $input: scenario_question_sets_set_input!) {
  update_scenario_question_sets_by_pk(
    pk_columns: {organization_id: $organization_id, id: $id}
    _set: $input
  ) {
    ...scenarioQuestionSet
  }
}
    ${ScenarioQuestionSetFragmentDoc}`;
export type UpdateScenarioQuestionSetMutationFn = Apollo.MutationFunction<Types.UpdateScenarioQuestionSetMutation, Types.UpdateScenarioQuestionSetMutationVariables>;

/**
 * __useUpdateScenarioQuestionSetMutation__
 *
 * To run a mutation, you first call `useUpdateScenarioQuestionSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScenarioQuestionSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScenarioQuestionSetMutation, { data, loading, error }] = useUpdateScenarioQuestionSetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      organization_id: // value for 'organization_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScenarioQuestionSetMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateScenarioQuestionSetMutation, Types.UpdateScenarioQuestionSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateScenarioQuestionSetMutation, Types.UpdateScenarioQuestionSetMutationVariables>(UpdateScenarioQuestionSetDocument, options);
      }
export type UpdateScenarioQuestionSetMutationHookResult = ReturnType<typeof useUpdateScenarioQuestionSetMutation>;
export type UpdateScenarioQuestionSetMutationResult = Apollo.MutationResult<Types.UpdateScenarioQuestionSetMutation>;
export type UpdateScenarioQuestionSetMutationOptions = Apollo.BaseMutationOptions<Types.UpdateScenarioQuestionSetMutation, Types.UpdateScenarioQuestionSetMutationVariables>;
export const CloneScenarioQuestionSetDocument = gql`
    mutation cloneScenarioQuestionSet($name: String!, $questionSetId: Int!) {
  fn_clone_scenario_question_set(
    args: {_name: $name, _scenario_question_set_id: $questionSetId}
  ) {
    ...scenarioQuestionSet
  }
}
    ${ScenarioQuestionSetFragmentDoc}`;
export type CloneScenarioQuestionSetMutationFn = Apollo.MutationFunction<Types.CloneScenarioQuestionSetMutation, Types.CloneScenarioQuestionSetMutationVariables>;

/**
 * __useCloneScenarioQuestionSetMutation__
 *
 * To run a mutation, you first call `useCloneScenarioQuestionSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneScenarioQuestionSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneScenarioQuestionSetMutation, { data, loading, error }] = useCloneScenarioQuestionSetMutation({
 *   variables: {
 *      name: // value for 'name'
 *      questionSetId: // value for 'questionSetId'
 *   },
 * });
 */
export function useCloneScenarioQuestionSetMutation(baseOptions?: Apollo.MutationHookOptions<Types.CloneScenarioQuestionSetMutation, Types.CloneScenarioQuestionSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CloneScenarioQuestionSetMutation, Types.CloneScenarioQuestionSetMutationVariables>(CloneScenarioQuestionSetDocument, options);
      }
export type CloneScenarioQuestionSetMutationHookResult = ReturnType<typeof useCloneScenarioQuestionSetMutation>;
export type CloneScenarioQuestionSetMutationResult = Apollo.MutationResult<Types.CloneScenarioQuestionSetMutation>;
export type CloneScenarioQuestionSetMutationOptions = Apollo.BaseMutationOptions<Types.CloneScenarioQuestionSetMutation, Types.CloneScenarioQuestionSetMutationVariables>;
export const UpdateScheduledAssessmentStatusDocument = gql`
    mutation updateScheduledAssessmentStatus($scheduleId: Int!, $organizationId: uuid!, $status: enum_schedule_status_enum!) {
  update_scheduled_assessment_schedule_by_pk(
    pk_columns: {id: $scheduleId, organization_id: $organizationId}
    _set: {status: $status}
  ) {
    ...ScheduledAssessmentScheduledRequiredFields
    status
  }
}
    ${ScheduledAssessmentScheduledRequiredFieldsFragmentDoc}`;
export type UpdateScheduledAssessmentStatusMutationFn = Apollo.MutationFunction<Types.UpdateScheduledAssessmentStatusMutation, Types.UpdateScheduledAssessmentStatusMutationVariables>;

/**
 * __useUpdateScheduledAssessmentStatusMutation__
 *
 * To run a mutation, you first call `useUpdateScheduledAssessmentStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduledAssessmentStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduledAssessmentStatusMutation, { data, loading, error }] = useUpdateScheduledAssessmentStatusMutation({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *      organizationId: // value for 'organizationId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateScheduledAssessmentStatusMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateScheduledAssessmentStatusMutation, Types.UpdateScheduledAssessmentStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateScheduledAssessmentStatusMutation, Types.UpdateScheduledAssessmentStatusMutationVariables>(UpdateScheduledAssessmentStatusDocument, options);
      }
export type UpdateScheduledAssessmentStatusMutationHookResult = ReturnType<typeof useUpdateScheduledAssessmentStatusMutation>;
export type UpdateScheduledAssessmentStatusMutationResult = Apollo.MutationResult<Types.UpdateScheduledAssessmentStatusMutation>;
export type UpdateScheduledAssessmentStatusMutationOptions = Apollo.BaseMutationOptions<Types.UpdateScheduledAssessmentStatusMutation, Types.UpdateScheduledAssessmentStatusMutationVariables>;
export const UpdateTaskActionDocument = gql`
    mutation updateTaskAction($id: uuid!, $input: task_actions_set_input!) {
  update_task_actions_by_pk(pk_columns: {id: $id}, _set: $input) {
    __typename
    ...TaskAction
    status
    assignee_id
  }
}
    ${TaskActionFragmentDoc}`;
export type UpdateTaskActionMutationFn = Apollo.MutationFunction<Types.UpdateTaskActionMutation, Types.UpdateTaskActionMutationVariables>;

/**
 * __useUpdateTaskActionMutation__
 *
 * To run a mutation, you first call `useUpdateTaskActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskActionMutation, { data, loading, error }] = useUpdateTaskActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskActionMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateTaskActionMutation, Types.UpdateTaskActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateTaskActionMutation, Types.UpdateTaskActionMutationVariables>(UpdateTaskActionDocument, options);
      }
export type UpdateTaskActionMutationHookResult = ReturnType<typeof useUpdateTaskActionMutation>;
export type UpdateTaskActionMutationResult = Apollo.MutationResult<Types.UpdateTaskActionMutation>;
export type UpdateTaskActionMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTaskActionMutation, Types.UpdateTaskActionMutationVariables>;
export const UpdateTaskActionTitleAndDescriptionDocument = gql`
    mutation updateTaskActionTitleAndDescription($id: uuid!, $input: task_actions_set_input!) {
  update_task_actions_by_pk(pk_columns: {id: $id}, _set: $input) {
    ...TaskActionIdentifiers
    ...TaskActionTitleAndDescription
  }
}
    ${TaskActionIdentifiersFragmentDoc}
${TaskActionTitleAndDescriptionFragmentDoc}`;
export type UpdateTaskActionTitleAndDescriptionMutationFn = Apollo.MutationFunction<Types.UpdateTaskActionTitleAndDescriptionMutation, Types.UpdateTaskActionTitleAndDescriptionMutationVariables>;

/**
 * __useUpdateTaskActionTitleAndDescriptionMutation__
 *
 * To run a mutation, you first call `useUpdateTaskActionTitleAndDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskActionTitleAndDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskActionTitleAndDescriptionMutation, { data, loading, error }] = useUpdateTaskActionTitleAndDescriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskActionTitleAndDescriptionMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateTaskActionTitleAndDescriptionMutation, Types.UpdateTaskActionTitleAndDescriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateTaskActionTitleAndDescriptionMutation, Types.UpdateTaskActionTitleAndDescriptionMutationVariables>(UpdateTaskActionTitleAndDescriptionDocument, options);
      }
export type UpdateTaskActionTitleAndDescriptionMutationHookResult = ReturnType<typeof useUpdateTaskActionTitleAndDescriptionMutation>;
export type UpdateTaskActionTitleAndDescriptionMutationResult = Apollo.MutationResult<Types.UpdateTaskActionTitleAndDescriptionMutation>;
export type UpdateTaskActionTitleAndDescriptionMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTaskActionTitleAndDescriptionMutation, Types.UpdateTaskActionTitleAndDescriptionMutationVariables>;
export const UpdateTaskActionPlanDocument = gql`
    mutation updateTaskActionPlan($id: uuid!, $input: task_actions_set_input!) {
  update_task_actions_by_pk(pk_columns: {id: $id}, _set: $input) {
    ...TaskActionIdentifiers
    ...TaskActionPlanDetails
  }
}
    ${TaskActionIdentifiersFragmentDoc}
${TaskActionPlanDetailsFragmentDoc}`;
export type UpdateTaskActionPlanMutationFn = Apollo.MutationFunction<Types.UpdateTaskActionPlanMutation, Types.UpdateTaskActionPlanMutationVariables>;

/**
 * __useUpdateTaskActionPlanMutation__
 *
 * To run a mutation, you first call `useUpdateTaskActionPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskActionPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskActionPlanMutation, { data, loading, error }] = useUpdateTaskActionPlanMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskActionPlanMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateTaskActionPlanMutation, Types.UpdateTaskActionPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateTaskActionPlanMutation, Types.UpdateTaskActionPlanMutationVariables>(UpdateTaskActionPlanDocument, options);
      }
export type UpdateTaskActionPlanMutationHookResult = ReturnType<typeof useUpdateTaskActionPlanMutation>;
export type UpdateTaskActionPlanMutationResult = Apollo.MutationResult<Types.UpdateTaskActionPlanMutation>;
export type UpdateTaskActionPlanMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTaskActionPlanMutation, Types.UpdateTaskActionPlanMutationVariables>;
export const UpdateTaskActionPropertiesDocument = gql`
    mutation updateTaskActionProperties($id: uuid!, $input: task_actions_set_input!) {
  update_task_actions_by_pk(pk_columns: {id: $id}, _set: $input) {
    ...TaskActionIdentifiers
    ...TaskActionProperties
  }
}
    ${TaskActionIdentifiersFragmentDoc}
${TaskActionPropertiesFragmentDoc}`;
export type UpdateTaskActionPropertiesMutationFn = Apollo.MutationFunction<Types.UpdateTaskActionPropertiesMutation, Types.UpdateTaskActionPropertiesMutationVariables>;

/**
 * __useUpdateTaskActionPropertiesMutation__
 *
 * To run a mutation, you first call `useUpdateTaskActionPropertiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskActionPropertiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskActionPropertiesMutation, { data, loading, error }] = useUpdateTaskActionPropertiesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskActionPropertiesMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateTaskActionPropertiesMutation, Types.UpdateTaskActionPropertiesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateTaskActionPropertiesMutation, Types.UpdateTaskActionPropertiesMutationVariables>(UpdateTaskActionPropertiesDocument, options);
      }
export type UpdateTaskActionPropertiesMutationHookResult = ReturnType<typeof useUpdateTaskActionPropertiesMutation>;
export type UpdateTaskActionPropertiesMutationResult = Apollo.MutationResult<Types.UpdateTaskActionPropertiesMutation>;
export type UpdateTaskActionPropertiesMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTaskActionPropertiesMutation, Types.UpdateTaskActionPropertiesMutationVariables>;
export const UpdateTaskActionStatusDocument = gql`
    mutation updateTaskActionStatus($id: uuid!, $status: enum_task_status_enum!) {
  update_task_actions_by_pk(pk_columns: {id: $id}, _set: {status: $status}) {
    ...TaskActionIdentifiers
    ...TaskActionStatus
  }
}
    ${TaskActionIdentifiersFragmentDoc}
${TaskActionStatusFragmentDoc}`;
export type UpdateTaskActionStatusMutationFn = Apollo.MutationFunction<Types.UpdateTaskActionStatusMutation, Types.UpdateTaskActionStatusMutationVariables>;

/**
 * __useUpdateTaskActionStatusMutation__
 *
 * To run a mutation, you first call `useUpdateTaskActionStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskActionStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskActionStatusMutation, { data, loading, error }] = useUpdateTaskActionStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateTaskActionStatusMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateTaskActionStatusMutation, Types.UpdateTaskActionStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateTaskActionStatusMutation, Types.UpdateTaskActionStatusMutationVariables>(UpdateTaskActionStatusDocument, options);
      }
export type UpdateTaskActionStatusMutationHookResult = ReturnType<typeof useUpdateTaskActionStatusMutation>;
export type UpdateTaskActionStatusMutationResult = Apollo.MutationResult<Types.UpdateTaskActionStatusMutation>;
export type UpdateTaskActionStatusMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTaskActionStatusMutation, Types.UpdateTaskActionStatusMutationVariables>;
export const UpdateTaskActionsDocument = gql`
    mutation updateTaskActions($organizationId: uuid!, $taskNo: Int!, $input: task_actions_set_input!) {
  update_task_actions_many(
    updates: {_set: $input, where: {_and: {organization_id: {_eq: $organizationId}, task_no: {_eq: $taskNo}}}}
  ) {
    returning {
      ...TaskAction
      status
      assignee_id
      freeformConsiderationOption {
        id
        name: title
      }
      libraryConsiderationOption {
        id
        name
      }
    }
  }
}
    ${TaskActionFragmentDoc}`;
export type UpdateTaskActionsMutationFn = Apollo.MutationFunction<Types.UpdateTaskActionsMutation, Types.UpdateTaskActionsMutationVariables>;

/**
 * __useUpdateTaskActionsMutation__
 *
 * To run a mutation, you first call `useUpdateTaskActionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskActionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskActionsMutation, { data, loading, error }] = useUpdateTaskActionsMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      taskNo: // value for 'taskNo'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskActionsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateTaskActionsMutation, Types.UpdateTaskActionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateTaskActionsMutation, Types.UpdateTaskActionsMutationVariables>(UpdateTaskActionsDocument, options);
      }
export type UpdateTaskActionsMutationHookResult = ReturnType<typeof useUpdateTaskActionsMutation>;
export type UpdateTaskActionsMutationResult = Apollo.MutationResult<Types.UpdateTaskActionsMutation>;
export type UpdateTaskActionsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTaskActionsMutation, Types.UpdateTaskActionsMutationVariables>;
export const CreateTaskDocument = gql`
    mutation createTask($input: tasks_insert_input!) {
  insert_tasks_one(object: $input) {
    ...TaskIdentifiers
    ...TaskPlan
    created_at
    description
    name
    status
    task_actions {
      ...TaskActionIdentifiers
      item {
        answer_aggregates {
          ...itemAnswerAggregates
        }
      }
    }
  }
}
    ${TaskIdentifiersFragmentDoc}
${TaskPlanFragmentDoc}
${TaskActionIdentifiersFragmentDoc}
${ItemAnswerAggregatesFragmentDoc}`;
export type CreateTaskMutationFn = Apollo.MutationFunction<Types.CreateTaskMutation, Types.CreateTaskMutationVariables>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTaskMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateTaskMutation, Types.CreateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateTaskMutation, Types.CreateTaskMutationVariables>(CreateTaskDocument, options);
      }
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = Apollo.MutationResult<Types.CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<Types.CreateTaskMutation, Types.CreateTaskMutationVariables>;
export const UpdateTaskDocument = gql`
    mutation updateTask($organizationId: uuid!, $taskNo: Int!, $input: tasks_set_input!) {
  update_tasks_by_pk(
    pk_columns: {organization_id: $organizationId, task_no: $taskNo}
    _set: $input
  ) {
    ...TaskIdentifiers
    ...TaskPlan
    created_at
    description
    name
    priority
    status
    project_id
    task_actions {
      ...TaskAction
      status
      freeformConsiderationOption {
        id
        name: title
      }
      libraryConsiderationOption {
        id
        name
      }
      assignee {
        ...User
      }
      action_deficiencies {
        ...ActionDeficiencyIdentifiers
        active_answer {
          ...taskAnswerInfo
        }
      }
    }
  }
}
    ${TaskIdentifiersFragmentDoc}
${TaskPlanFragmentDoc}
${TaskActionFragmentDoc}
${UserFragmentDoc}
${ActionDeficiencyIdentifiersFragmentDoc}
${TaskAnswerInfoFragmentDoc}`;
export type UpdateTaskMutationFn = Apollo.MutationFunction<Types.UpdateTaskMutation, Types.UpdateTaskMutationVariables>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      taskNo: // value for 'taskNo'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateTaskMutation, Types.UpdateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateTaskMutation, Types.UpdateTaskMutationVariables>(UpdateTaskDocument, options);
      }
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<Types.UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTaskMutation, Types.UpdateTaskMutationVariables>;
export const UpdateTaskProjectDocument = gql`
    mutation updateTaskProject($taskNo: Int!, $organizationId: uuid!, $projectId: uuid) {
  update_tasks_by_pk(
    pk_columns: {organization_id: $organizationId, task_no: $taskNo}
    _set: {project_id: $projectId}
  ) {
    task_no
    project_id
  }
}
    `;
export type UpdateTaskProjectMutationFn = Apollo.MutationFunction<Types.UpdateTaskProjectMutation, Types.UpdateTaskProjectMutationVariables>;

/**
 * __useUpdateTaskProjectMutation__
 *
 * To run a mutation, you first call `useUpdateTaskProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskProjectMutation, { data, loading, error }] = useUpdateTaskProjectMutation({
 *   variables: {
 *      taskNo: // value for 'taskNo'
 *      organizationId: // value for 'organizationId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUpdateTaskProjectMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateTaskProjectMutation, Types.UpdateTaskProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateTaskProjectMutation, Types.UpdateTaskProjectMutationVariables>(UpdateTaskProjectDocument, options);
      }
export type UpdateTaskProjectMutationHookResult = ReturnType<typeof useUpdateTaskProjectMutation>;
export type UpdateTaskProjectMutationResult = Apollo.MutationResult<Types.UpdateTaskProjectMutation>;
export type UpdateTaskProjectMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTaskProjectMutation, Types.UpdateTaskProjectMutationVariables>;
export const UpdateTaskStatusDocument = gql`
    mutation updateTaskStatus($taskNo: Int!, $organizationId: uuid!, $status: enum_task_status_enum!) {
  update_tasks_by_pk(
    pk_columns: {organization_id: $organizationId, task_no: $taskNo}
    _set: {status: $status}
  ) {
    task_no
    status
  }
}
    `;
export type UpdateTaskStatusMutationFn = Apollo.MutationFunction<Types.UpdateTaskStatusMutation, Types.UpdateTaskStatusMutationVariables>;

/**
 * __useUpdateTaskStatusMutation__
 *
 * To run a mutation, you first call `useUpdateTaskStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskStatusMutation, { data, loading, error }] = useUpdateTaskStatusMutation({
 *   variables: {
 *      taskNo: // value for 'taskNo'
 *      organizationId: // value for 'organizationId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateTaskStatusMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateTaskStatusMutation, Types.UpdateTaskStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateTaskStatusMutation, Types.UpdateTaskStatusMutationVariables>(UpdateTaskStatusDocument, options);
      }
export type UpdateTaskStatusMutationHookResult = ReturnType<typeof useUpdateTaskStatusMutation>;
export type UpdateTaskStatusMutationResult = Apollo.MutationResult<Types.UpdateTaskStatusMutation>;
export type UpdateTaskStatusMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTaskStatusMutation, Types.UpdateTaskStatusMutationVariables>;
export const UpdateTaskDueDateDocument = gql`
    mutation updateTaskDueDate($taskNo: Int!, $organizationId: uuid!, $dueDate: date) {
  update_tasks_by_pk(
    pk_columns: {organization_id: $organizationId, task_no: $taskNo}
    _set: {due_date: $dueDate}
  ) {
    task_no
    due_date
  }
}
    `;
export type UpdateTaskDueDateMutationFn = Apollo.MutationFunction<Types.UpdateTaskDueDateMutation, Types.UpdateTaskDueDateMutationVariables>;

/**
 * __useUpdateTaskDueDateMutation__
 *
 * To run a mutation, you first call `useUpdateTaskDueDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskDueDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskDueDateMutation, { data, loading, error }] = useUpdateTaskDueDateMutation({
 *   variables: {
 *      taskNo: // value for 'taskNo'
 *      organizationId: // value for 'organizationId'
 *      dueDate: // value for 'dueDate'
 *   },
 * });
 */
export function useUpdateTaskDueDateMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateTaskDueDateMutation, Types.UpdateTaskDueDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateTaskDueDateMutation, Types.UpdateTaskDueDateMutationVariables>(UpdateTaskDueDateDocument, options);
      }
export type UpdateTaskDueDateMutationHookResult = ReturnType<typeof useUpdateTaskDueDateMutation>;
export type UpdateTaskDueDateMutationResult = Apollo.MutationResult<Types.UpdateTaskDueDateMutation>;
export type UpdateTaskDueDateMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTaskDueDateMutation, Types.UpdateTaskDueDateMutationVariables>;
export const UpdateTaskNameAndDescriptionDocument = gql`
    mutation updateTaskNameAndDescription($taskNo: Int!, $organizationId: uuid!, $input: tasks_set_input!) {
  update_tasks_by_pk(
    pk_columns: {organization_id: $organizationId, task_no: $taskNo}
    _set: $input
  ) {
    task_no
    ...TaskNameAndDescription
  }
}
    ${TaskNameAndDescriptionFragmentDoc}`;
export type UpdateTaskNameAndDescriptionMutationFn = Apollo.MutationFunction<Types.UpdateTaskNameAndDescriptionMutation, Types.UpdateTaskNameAndDescriptionMutationVariables>;

/**
 * __useUpdateTaskNameAndDescriptionMutation__
 *
 * To run a mutation, you first call `useUpdateTaskNameAndDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskNameAndDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskNameAndDescriptionMutation, { data, loading, error }] = useUpdateTaskNameAndDescriptionMutation({
 *   variables: {
 *      taskNo: // value for 'taskNo'
 *      organizationId: // value for 'organizationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskNameAndDescriptionMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateTaskNameAndDescriptionMutation, Types.UpdateTaskNameAndDescriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateTaskNameAndDescriptionMutation, Types.UpdateTaskNameAndDescriptionMutationVariables>(UpdateTaskNameAndDescriptionDocument, options);
      }
export type UpdateTaskNameAndDescriptionMutationHookResult = ReturnType<typeof useUpdateTaskNameAndDescriptionMutation>;
export type UpdateTaskNameAndDescriptionMutationResult = Apollo.MutationResult<Types.UpdateTaskNameAndDescriptionMutation>;
export type UpdateTaskNameAndDescriptionMutationOptions = Apollo.BaseMutationOptions<Types.UpdateTaskNameAndDescriptionMutation, Types.UpdateTaskNameAndDescriptionMutationVariables>;
export const ClientUpdateUserProfileByIdDocument = gql`
    mutation clientUpdateUserProfileById($id: uuid!, $update: users_set_input!) {
  update_users_by_pk(pk_columns: {id: $id}, _set: $update) {
    id
    consents_to_marketing_communication
  }
}
    `;
export type ClientUpdateUserProfileByIdMutationFn = Apollo.MutationFunction<Types.ClientUpdateUserProfileByIdMutation, Types.ClientUpdateUserProfileByIdMutationVariables>;

/**
 * __useClientUpdateUserProfileByIdMutation__
 *
 * To run a mutation, you first call `useClientUpdateUserProfileByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClientUpdateUserProfileByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clientUpdateUserProfileByIdMutation, { data, loading, error }] = useClientUpdateUserProfileByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useClientUpdateUserProfileByIdMutation(baseOptions?: Apollo.MutationHookOptions<Types.ClientUpdateUserProfileByIdMutation, Types.ClientUpdateUserProfileByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ClientUpdateUserProfileByIdMutation, Types.ClientUpdateUserProfileByIdMutationVariables>(ClientUpdateUserProfileByIdDocument, options);
      }
export type ClientUpdateUserProfileByIdMutationHookResult = ReturnType<typeof useClientUpdateUserProfileByIdMutation>;
export type ClientUpdateUserProfileByIdMutationResult = Apollo.MutationResult<Types.ClientUpdateUserProfileByIdMutation>;
export type ClientUpdateUserProfileByIdMutationOptions = Apollo.BaseMutationOptions<Types.ClientUpdateUserProfileByIdMutation, Types.ClientUpdateUserProfileByIdMutationVariables>;
export const CreateAssessmentTemplateDocument = gql`
    mutation createAssessmentTemplate($input: v2_assessment_templates_insert_input!) {
  insert_v2_assessment_templates_one(object: $input) {
    ...templateDetails
  }
}
    ${TemplateDetailsFragmentDoc}`;
export type CreateAssessmentTemplateMutationFn = Apollo.MutationFunction<Types.CreateAssessmentTemplateMutation, Types.CreateAssessmentTemplateMutationVariables>;

/**
 * __useCreateAssessmentTemplateMutation__
 *
 * To run a mutation, you first call `useCreateAssessmentTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssessmentTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssessmentTemplateMutation, { data, loading, error }] = useCreateAssessmentTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssessmentTemplateMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateAssessmentTemplateMutation, Types.CreateAssessmentTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateAssessmentTemplateMutation, Types.CreateAssessmentTemplateMutationVariables>(CreateAssessmentTemplateDocument, options);
      }
export type CreateAssessmentTemplateMutationHookResult = ReturnType<typeof useCreateAssessmentTemplateMutation>;
export type CreateAssessmentTemplateMutationResult = Apollo.MutationResult<Types.CreateAssessmentTemplateMutation>;
export type CreateAssessmentTemplateMutationOptions = Apollo.BaseMutationOptions<Types.CreateAssessmentTemplateMutation, Types.CreateAssessmentTemplateMutationVariables>;
export const DeprecateAssessmentTemplatesDocument = gql`
    mutation deprecateAssessmentTemplates($previousTemplateIds: [Int!]!, $nextTemplateId: Int!) {
  update_v2_assessment_templates(
    where: {id: {_in: $previousTemplateIds}}
    _set: {next_assessment_template_id: $nextTemplateId}
  ) {
    returning {
      organization_id
      id
      next_assessment_template_id
    }
  }
}
    `;
export type DeprecateAssessmentTemplatesMutationFn = Apollo.MutationFunction<Types.DeprecateAssessmentTemplatesMutation, Types.DeprecateAssessmentTemplatesMutationVariables>;

/**
 * __useDeprecateAssessmentTemplatesMutation__
 *
 * To run a mutation, you first call `useDeprecateAssessmentTemplatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeprecateAssessmentTemplatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deprecateAssessmentTemplatesMutation, { data, loading, error }] = useDeprecateAssessmentTemplatesMutation({
 *   variables: {
 *      previousTemplateIds: // value for 'previousTemplateIds'
 *      nextTemplateId: // value for 'nextTemplateId'
 *   },
 * });
 */
export function useDeprecateAssessmentTemplatesMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeprecateAssessmentTemplatesMutation, Types.DeprecateAssessmentTemplatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeprecateAssessmentTemplatesMutation, Types.DeprecateAssessmentTemplatesMutationVariables>(DeprecateAssessmentTemplatesDocument, options);
      }
export type DeprecateAssessmentTemplatesMutationHookResult = ReturnType<typeof useDeprecateAssessmentTemplatesMutation>;
export type DeprecateAssessmentTemplatesMutationResult = Apollo.MutationResult<Types.DeprecateAssessmentTemplatesMutation>;
export type DeprecateAssessmentTemplatesMutationOptions = Apollo.BaseMutationOptions<Types.DeprecateAssessmentTemplatesMutation, Types.DeprecateAssessmentTemplatesMutationVariables>;
export const UndeprecateAssessmentTemplateDocument = gql`
    mutation undeprecateAssessmentTemplate($organizationId: uuid!, $id: Int!) {
  update_v2_assessment_templates_by_pk(
    pk_columns: {organization_id: $organizationId, id: $id}
    _set: {next_assessment_template_id: null}
  ) {
    organization_id
    id
    next_assessment_template_id
  }
}
    `;
export type UndeprecateAssessmentTemplateMutationFn = Apollo.MutationFunction<Types.UndeprecateAssessmentTemplateMutation, Types.UndeprecateAssessmentTemplateMutationVariables>;

/**
 * __useUndeprecateAssessmentTemplateMutation__
 *
 * To run a mutation, you first call `useUndeprecateAssessmentTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndeprecateAssessmentTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undeprecateAssessmentTemplateMutation, { data, loading, error }] = useUndeprecateAssessmentTemplateMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUndeprecateAssessmentTemplateMutation(baseOptions?: Apollo.MutationHookOptions<Types.UndeprecateAssessmentTemplateMutation, Types.UndeprecateAssessmentTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UndeprecateAssessmentTemplateMutation, Types.UndeprecateAssessmentTemplateMutationVariables>(UndeprecateAssessmentTemplateDocument, options);
      }
export type UndeprecateAssessmentTemplateMutationHookResult = ReturnType<typeof useUndeprecateAssessmentTemplateMutation>;
export type UndeprecateAssessmentTemplateMutationResult = Apollo.MutationResult<Types.UndeprecateAssessmentTemplateMutation>;
export type UndeprecateAssessmentTemplateMutationOptions = Apollo.BaseMutationOptions<Types.UndeprecateAssessmentTemplateMutation, Types.UndeprecateAssessmentTemplateMutationVariables>;
export const SetNextAssessmentTemplateDocument = gql`
    mutation setNextAssessmentTemplate($organizationId: uuid!, $id: Int!, $nextTemplateId: Int) {
  update_v2_assessment_templates_by_pk(
    pk_columns: {organization_id: $organizationId, id: $id}
    _set: {next_assessment_template_id: $nextTemplateId}
  ) {
    organization_id
    id
    next_assessment_template_id
    nextAssessmentTemplate {
      id
      name
    }
  }
}
    `;
export type SetNextAssessmentTemplateMutationFn = Apollo.MutationFunction<Types.SetNextAssessmentTemplateMutation, Types.SetNextAssessmentTemplateMutationVariables>;

/**
 * __useSetNextAssessmentTemplateMutation__
 *
 * To run a mutation, you first call `useSetNextAssessmentTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNextAssessmentTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNextAssessmentTemplateMutation, { data, loading, error }] = useSetNextAssessmentTemplateMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      id: // value for 'id'
 *      nextTemplateId: // value for 'nextTemplateId'
 *   },
 * });
 */
export function useSetNextAssessmentTemplateMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetNextAssessmentTemplateMutation, Types.SetNextAssessmentTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetNextAssessmentTemplateMutation, Types.SetNextAssessmentTemplateMutationVariables>(SetNextAssessmentTemplateDocument, options);
      }
export type SetNextAssessmentTemplateMutationHookResult = ReturnType<typeof useSetNextAssessmentTemplateMutation>;
export type SetNextAssessmentTemplateMutationResult = Apollo.MutationResult<Types.SetNextAssessmentTemplateMutation>;
export type SetNextAssessmentTemplateMutationOptions = Apollo.BaseMutationOptions<Types.SetNextAssessmentTemplateMutation, Types.SetNextAssessmentTemplateMutationVariables>;
export const UpdateAssessmentTemplateDocument = gql`
    mutation updateAssessmentTemplate($organizationId: uuid!, $id: Int!, $input: v2_assessment_templates_set_input!) {
  update_v2_assessment_templates_by_pk(
    pk_columns: {organization_id: $organizationId, id: $id}
    _set: $input
  ) {
    organization_id
    id
    name
    description
    next_assessment_template_id
    nextAssessmentTemplate {
      id
      name
    }
  }
}
    `;
export type UpdateAssessmentTemplateMutationFn = Apollo.MutationFunction<Types.UpdateAssessmentTemplateMutation, Types.UpdateAssessmentTemplateMutationVariables>;

/**
 * __useUpdateAssessmentTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateAssessmentTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssessmentTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssessmentTemplateMutation, { data, loading, error }] = useUpdateAssessmentTemplateMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssessmentTemplateMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateAssessmentTemplateMutation, Types.UpdateAssessmentTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateAssessmentTemplateMutation, Types.UpdateAssessmentTemplateMutationVariables>(UpdateAssessmentTemplateDocument, options);
      }
export type UpdateAssessmentTemplateMutationHookResult = ReturnType<typeof useUpdateAssessmentTemplateMutation>;
export type UpdateAssessmentTemplateMutationResult = Apollo.MutationResult<Types.UpdateAssessmentTemplateMutation>;
export type UpdateAssessmentTemplateMutationOptions = Apollo.BaseMutationOptions<Types.UpdateAssessmentTemplateMutation, Types.UpdateAssessmentTemplateMutationVariables>;
export const StartAssessmentDocument = gql`
    mutation startAssessment($organizationId: uuid!, $assessmentId: Int!) {
  update_v2_assessments(
    where: {organization_id: {_eq: $organizationId}, id: {_eq: $assessmentId}, state: {_eq: "NotStarted"}}
    _set: {state: "InProgress"}
  ) {
    returning {
      __typename
      id
      name
      description
      updated_at
      created_at
      state
      due_date
      progressAggregates {
        physical_progress
        inspection_progress
      }
      userAccess {
        user_id
      }
      locations {
        ...V2AssessmentNodesIdentifiers
        node {
          id
          nodeDepth
        }
      }
    }
  }
}
    ${V2AssessmentNodesIdentifiersFragmentDoc}`;
export type StartAssessmentMutationFn = Apollo.MutationFunction<Types.StartAssessmentMutation, Types.StartAssessmentMutationVariables>;

/**
 * __useStartAssessmentMutation__
 *
 * To run a mutation, you first call `useStartAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startAssessmentMutation, { data, loading, error }] = useStartAssessmentMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      assessmentId: // value for 'assessmentId'
 *   },
 * });
 */
export function useStartAssessmentMutation(baseOptions?: Apollo.MutationHookOptions<Types.StartAssessmentMutation, Types.StartAssessmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.StartAssessmentMutation, Types.StartAssessmentMutationVariables>(StartAssessmentDocument, options);
      }
export type StartAssessmentMutationHookResult = ReturnType<typeof useStartAssessmentMutation>;
export type StartAssessmentMutationResult = Apollo.MutationResult<Types.StartAssessmentMutation>;
export type StartAssessmentMutationOptions = Apollo.BaseMutationOptions<Types.StartAssessmentMutation, Types.StartAssessmentMutationVariables>;
export const ReopenAssessmentDocument = gql`
    mutation reopenAssessment($organizationId: uuid!, $assessmentId: Int!) {
  update_v2_assessments(
    where: {organization_id: {_eq: $organizationId}, id: {_eq: $assessmentId}, state: {_eq: "Complete"}}
    _set: {state: "InProgress"}
  ) {
    returning {
      __typename
      id
      state
    }
  }
}
    `;
export type ReopenAssessmentMutationFn = Apollo.MutationFunction<Types.ReopenAssessmentMutation, Types.ReopenAssessmentMutationVariables>;

/**
 * __useReopenAssessmentMutation__
 *
 * To run a mutation, you first call `useReopenAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReopenAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reopenAssessmentMutation, { data, loading, error }] = useReopenAssessmentMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      assessmentId: // value for 'assessmentId'
 *   },
 * });
 */
export function useReopenAssessmentMutation(baseOptions?: Apollo.MutationHookOptions<Types.ReopenAssessmentMutation, Types.ReopenAssessmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ReopenAssessmentMutation, Types.ReopenAssessmentMutationVariables>(ReopenAssessmentDocument, options);
      }
export type ReopenAssessmentMutationHookResult = ReturnType<typeof useReopenAssessmentMutation>;
export type ReopenAssessmentMutationResult = Apollo.MutationResult<Types.ReopenAssessmentMutation>;
export type ReopenAssessmentMutationOptions = Apollo.BaseMutationOptions<Types.ReopenAssessmentMutation, Types.ReopenAssessmentMutationVariables>;
export const CompleteAssessmentDocument = gql`
    mutation completeAssessment($organizationId: uuid!, $assessmentId: Int!, $retrieveScenarioInfo: Boolean!) {
  update_v2_assessments(
    where: {organization_id: {_eq: $organizationId}, id: {_eq: $assessmentId}, state: {_eq: "InProgress"}}
    _set: {state: "Complete"}
  ) {
    returning {
      __typename
      id
      state
      locations {
        ...V2AssessmentNodesIdentifiers
        node {
          __typename
          id
          scenariosInfo @include(if: $retrieveScenarioInfo) {
            __typename
            scenario_id
            node_id
            control_percentage
            residual_score
            residual_percentage
          }
        }
      }
    }
  }
}
    ${V2AssessmentNodesIdentifiersFragmentDoc}`;
export type CompleteAssessmentMutationFn = Apollo.MutationFunction<Types.CompleteAssessmentMutation, Types.CompleteAssessmentMutationVariables>;

/**
 * __useCompleteAssessmentMutation__
 *
 * To run a mutation, you first call `useCompleteAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeAssessmentMutation, { data, loading, error }] = useCompleteAssessmentMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      assessmentId: // value for 'assessmentId'
 *      retrieveScenarioInfo: // value for 'retrieveScenarioInfo'
 *   },
 * });
 */
export function useCompleteAssessmentMutation(baseOptions?: Apollo.MutationHookOptions<Types.CompleteAssessmentMutation, Types.CompleteAssessmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CompleteAssessmentMutation, Types.CompleteAssessmentMutationVariables>(CompleteAssessmentDocument, options);
      }
export type CompleteAssessmentMutationHookResult = ReturnType<typeof useCompleteAssessmentMutation>;
export type CompleteAssessmentMutationResult = Apollo.MutationResult<Types.CompleteAssessmentMutation>;
export type CompleteAssessmentMutationOptions = Apollo.BaseMutationOptions<Types.CompleteAssessmentMutation, Types.CompleteAssessmentMutationVariables>;
export const UpdateAssessmentFieldDocument = gql`
    mutation updateAssessmentField($organizationId: uuid!, $assessmentId: Int!, $input: v2_assessments_set_input!) {
  update_v2_assessments_by_pk(
    pk_columns: {id: $assessmentId, organization_id: $organizationId}
    _set: $input
  ) {
    id
    organization_id
    findings
    notes
    recommendations
    background
  }
}
    `;
export type UpdateAssessmentFieldMutationFn = Apollo.MutationFunction<Types.UpdateAssessmentFieldMutation, Types.UpdateAssessmentFieldMutationVariables>;

/**
 * __useUpdateAssessmentFieldMutation__
 *
 * To run a mutation, you first call `useUpdateAssessmentFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssessmentFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssessmentFieldMutation, { data, loading, error }] = useUpdateAssessmentFieldMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      assessmentId: // value for 'assessmentId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssessmentFieldMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateAssessmentFieldMutation, Types.UpdateAssessmentFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateAssessmentFieldMutation, Types.UpdateAssessmentFieldMutationVariables>(UpdateAssessmentFieldDocument, options);
      }
export type UpdateAssessmentFieldMutationHookResult = ReturnType<typeof useUpdateAssessmentFieldMutation>;
export type UpdateAssessmentFieldMutationResult = Apollo.MutationResult<Types.UpdateAssessmentFieldMutation>;
export type UpdateAssessmentFieldMutationOptions = Apollo.BaseMutationOptions<Types.UpdateAssessmentFieldMutation, Types.UpdateAssessmentFieldMutationVariables>;
export const GetLocationManagersDocument = gql`
    query getLocationManagers($organizationId: uuid!, $locationId: uuid!) {
  location_manager_inherited_assignments(where: {location_id: {_eq: $locationId}}) {
    type
    user {
      ...User
    }
  }
  users(
    where: {users_organizations_organizations: {organizations_id: {_eq: $organizationId}}}
  ) {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetLocationManagersQuery__
 *
 * To run a query within a React component, call `useGetLocationManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationManagersQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetLocationManagersQuery(baseOptions: Apollo.QueryHookOptions<Types.GetLocationManagersQuery, Types.GetLocationManagersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLocationManagersQuery, Types.GetLocationManagersQueryVariables>(GetLocationManagersDocument, options);
      }
export function useGetLocationManagersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLocationManagersQuery, Types.GetLocationManagersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLocationManagersQuery, Types.GetLocationManagersQueryVariables>(GetLocationManagersDocument, options);
        }
export type GetLocationManagersQueryHookResult = ReturnType<typeof useGetLocationManagersQuery>;
export type GetLocationManagersLazyQueryHookResult = ReturnType<typeof useGetLocationManagersLazyQuery>;
export type GetLocationManagersQueryResult = Apollo.QueryResult<Types.GetLocationManagersQuery, Types.GetLocationManagersQueryVariables>;
export const GetPossibleAndCurrentLayerTagsDocument = gql`
    query getPossibleAndCurrentLayerTags($layerId: uuid!, $nodeId: uuid!) {
  possibleLocationLayerTags: layer_tag(
    where: {_and: {layer_id: {_eq: $layerId}, type_name: {_neq: "all"}}}
  ) {
    id
    type_name
    description
  }
  currentLocationLayerTags: location_layer_tag(
    where: {node_id: {_eq: $nodeId}, layerTag: {type_name: {_neq: "all"}}}
  ) {
    layer_id
    ...LocationLayerTagIdentifiers
    layerTag {
      id
      type_name
      description
      propertyGroups(order_by: {ordinal: asc}) {
        configs(order_by: {ordinal: asc}) {
          id
          kind
          name
          ordinal
          options
          locationPropertyValues(where: {location_id: {_eq: $nodeId}}) {
            config_id
            location_id
            organization_id
            value
          }
        }
      }
    }
  }
}
    ${LocationLayerTagIdentifiersFragmentDoc}`;

/**
 * __useGetPossibleAndCurrentLayerTagsQuery__
 *
 * To run a query within a React component, call `useGetPossibleAndCurrentLayerTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPossibleAndCurrentLayerTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPossibleAndCurrentLayerTagsQuery({
 *   variables: {
 *      layerId: // value for 'layerId'
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useGetPossibleAndCurrentLayerTagsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPossibleAndCurrentLayerTagsQuery, Types.GetPossibleAndCurrentLayerTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPossibleAndCurrentLayerTagsQuery, Types.GetPossibleAndCurrentLayerTagsQueryVariables>(GetPossibleAndCurrentLayerTagsDocument, options);
      }
export function useGetPossibleAndCurrentLayerTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPossibleAndCurrentLayerTagsQuery, Types.GetPossibleAndCurrentLayerTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPossibleAndCurrentLayerTagsQuery, Types.GetPossibleAndCurrentLayerTagsQueryVariables>(GetPossibleAndCurrentLayerTagsDocument, options);
        }
export type GetPossibleAndCurrentLayerTagsQueryHookResult = ReturnType<typeof useGetPossibleAndCurrentLayerTagsQuery>;
export type GetPossibleAndCurrentLayerTagsLazyQueryHookResult = ReturnType<typeof useGetPossibleAndCurrentLayerTagsLazyQuery>;
export type GetPossibleAndCurrentLayerTagsQueryResult = Apollo.QueryResult<Types.GetPossibleAndCurrentLayerTagsQuery, Types.GetPossibleAndCurrentLayerTagsQueryVariables>;
export const GetScenarioQuestionSetsDocument = gql`
    query getScenarioQuestionSets($organizationId: uuid!, $scenarioId: Int!) {
  layerTags: layer_tag(where: {organization_id: {_eq: $organizationId}}) {
    id
    organization_id
    type_name
    layer {
      id
      name
    }
  }
  scoredAggregates: fn_scenario_question_set_scored_aggregates(
    args: {_scenario_id: $scenarioId}
  ) {
    low_criticality_count
    medium_criticality_count
    high_criticality_count
    total_criticality_count
    id
  }
  scenario_by_pk(id: $scenarioId, organization_id: $organizationId) {
    __typename
    id
    scenario_type
    questionSets {
      ...scenarioQuestionSet
    }
  }
}
    ${ScenarioQuestionSetFragmentDoc}`;

/**
 * __useGetScenarioQuestionSetsQuery__
 *
 * To run a query within a React component, call `useGetScenarioQuestionSetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenarioQuestionSetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenarioQuestionSetsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      scenarioId: // value for 'scenarioId'
 *   },
 * });
 */
export function useGetScenarioQuestionSetsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetScenarioQuestionSetsQuery, Types.GetScenarioQuestionSetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetScenarioQuestionSetsQuery, Types.GetScenarioQuestionSetsQueryVariables>(GetScenarioQuestionSetsDocument, options);
      }
export function useGetScenarioQuestionSetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetScenarioQuestionSetsQuery, Types.GetScenarioQuestionSetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetScenarioQuestionSetsQuery, Types.GetScenarioQuestionSetsQueryVariables>(GetScenarioQuestionSetsDocument, options);
        }
export type GetScenarioQuestionSetsQueryHookResult = ReturnType<typeof useGetScenarioQuestionSetsQuery>;
export type GetScenarioQuestionSetsLazyQueryHookResult = ReturnType<typeof useGetScenarioQuestionSetsLazyQuery>;
export type GetScenarioQuestionSetsQueryResult = Apollo.QueryResult<Types.GetScenarioQuestionSetsQuery, Types.GetScenarioQuestionSetsQueryVariables>;
export const GetScenariosDocument = gql`
    query getScenarios($organizationId: uuid!, $includeLocationsAggregate: Boolean!) {
  organizations_by_pk(id: $organizationId) {
    scenarioRangeLabels {
      lower_range_label
      mid_range_label
      upper_range_label
    }
  }
  scenario(
    where: {organization_id: {_eq: $organizationId}}
    order_by: {updated_at: desc}
  ) {
    id
    name
    description
    scenario_type
    created_at
    updated_at
    is_partial_compliance_allowed
    ...inherentRiskProgress
    primaryQuestionSets {
      scenario_id
      scenario_question_set_id
      questionSet {
        id
        assessmentTemplates {
          assessment_template_id
        }
      }
    }
    locationsCompletedScenario: relatedLocations_aggregate(
      where: {completed_question_percentage: {_eq: 100}}
    ) @include(if: $includeLocationsAggregate) {
      aggregate {
        count
      }
    }
    locationsCompletedIR: relatedLocations_aggregate(
      where: {inherent_risk: {_is_null: false}}
    ) @include(if: $includeLocationsAggregate) {
      aggregate {
        count
      }
    }
    are_thresholds_enabled
  }
}
    ${InherentRiskProgressFragmentDoc}`;

/**
 * __useGetScenariosQuery__
 *
 * To run a query within a React component, call `useGetScenariosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenariosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenariosQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      includeLocationsAggregate: // value for 'includeLocationsAggregate'
 *   },
 * });
 */
export function useGetScenariosQuery(baseOptions: Apollo.QueryHookOptions<Types.GetScenariosQuery, Types.GetScenariosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetScenariosQuery, Types.GetScenariosQueryVariables>(GetScenariosDocument, options);
      }
export function useGetScenariosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetScenariosQuery, Types.GetScenariosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetScenariosQuery, Types.GetScenariosQueryVariables>(GetScenariosDocument, options);
        }
export type GetScenariosQueryHookResult = ReturnType<typeof useGetScenariosQuery>;
export type GetScenariosLazyQueryHookResult = ReturnType<typeof useGetScenariosLazyQuery>;
export type GetScenariosQueryResult = Apollo.QueryResult<Types.GetScenariosQuery, Types.GetScenariosQueryVariables>;
export const GetLocationFilterBuilderDataDocument = gql`
    query getLocationFilterBuilderData($organizationId: uuid!) {
  layerTags: layer_tag(
    where: {_and: [{organization_id: {_eq: $organizationId}}, {type_name: {_neq: "all"}}]}
  ) {
    layer_id
    type_name
    id
  }
  nodes(where: {organization_id: {_eq: $organizationId}}) {
    id
    name
    mpath
    nodeDepth
    deficiencies {
      criticality_value
    }
    items_aggregate {
      aggregate {
        count
      }
    }
    scenarioAssessmentNodes_aggregate {
      aggregate {
        count
      }
    }
  }
  organizations_by_pk(id: $organizationId) {
    scenarioTypeInfo {
      scenario_type
      scenarios {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetLocationFilterBuilderDataQuery__
 *
 * To run a query within a React component, call `useGetLocationFilterBuilderDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationFilterBuilderDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationFilterBuilderDataQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetLocationFilterBuilderDataQuery(baseOptions: Apollo.QueryHookOptions<Types.GetLocationFilterBuilderDataQuery, Types.GetLocationFilterBuilderDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLocationFilterBuilderDataQuery, Types.GetLocationFilterBuilderDataQueryVariables>(GetLocationFilterBuilderDataDocument, options);
      }
export function useGetLocationFilterBuilderDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLocationFilterBuilderDataQuery, Types.GetLocationFilterBuilderDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLocationFilterBuilderDataQuery, Types.GetLocationFilterBuilderDataQueryVariables>(GetLocationFilterBuilderDataDocument, options);
        }
export type GetLocationFilterBuilderDataQueryHookResult = ReturnType<typeof useGetLocationFilterBuilderDataQuery>;
export type GetLocationFilterBuilderDataLazyQueryHookResult = ReturnType<typeof useGetLocationFilterBuilderDataLazyQuery>;
export type GetLocationFilterBuilderDataQueryResult = Apollo.QueryResult<Types.GetLocationFilterBuilderDataQuery, Types.GetLocationFilterBuilderDataQueryVariables>;
export const GetBaseLayerInfoByIdDocument = gql`
    query getBaseLayerInfoById($id: uuid!, $organizationId: uuid!) {
  layers_by_pk(id: $id) {
    id
    name
    layer {
      id
      name
    }
  }
  layers(where: {organization_id: {_eq: $organizationId}}) {
    id
    name
  }
}
    `;

/**
 * __useGetBaseLayerInfoByIdQuery__
 *
 * To run a query within a React component, call `useGetBaseLayerInfoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseLayerInfoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseLayerInfoByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetBaseLayerInfoByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetBaseLayerInfoByIdQuery, Types.GetBaseLayerInfoByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetBaseLayerInfoByIdQuery, Types.GetBaseLayerInfoByIdQueryVariables>(GetBaseLayerInfoByIdDocument, options);
      }
export function useGetBaseLayerInfoByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBaseLayerInfoByIdQuery, Types.GetBaseLayerInfoByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetBaseLayerInfoByIdQuery, Types.GetBaseLayerInfoByIdQueryVariables>(GetBaseLayerInfoByIdDocument, options);
        }
export type GetBaseLayerInfoByIdQueryHookResult = ReturnType<typeof useGetBaseLayerInfoByIdQuery>;
export type GetBaseLayerInfoByIdLazyQueryHookResult = ReturnType<typeof useGetBaseLayerInfoByIdLazyQuery>;
export type GetBaseLayerInfoByIdQueryResult = Apollo.QueryResult<Types.GetBaseLayerInfoByIdQuery, Types.GetBaseLayerInfoByIdQueryVariables>;
export const GetFeatureFlagsDocument = gql`
    query getFeatureFlags($organizationId: uuid!) {
  available_flags: enum_feature_flags(order_by: {name: asc}) {
    name
    enabledOrgs: organizations_for_feature_flag(
      where: {is_flag_enabled: {_eq: true}}
    ) {
      organization_id
      flag_name
      is_flag_enabled
    }
  }
  organization_feature_flags(
    where: {organization_id: {_eq: $organizationId}}
    order_by: {flag_name: asc}
  ) {
    organization_id
    flag_name
    is_flag_enabled
  }
}
    `;

/**
 * __useGetFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useGetFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureFlagsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetFeatureFlagsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetFeatureFlagsQuery, Types.GetFeatureFlagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFeatureFlagsQuery, Types.GetFeatureFlagsQueryVariables>(GetFeatureFlagsDocument, options);
      }
export function useGetFeatureFlagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFeatureFlagsQuery, Types.GetFeatureFlagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFeatureFlagsQuery, Types.GetFeatureFlagsQueryVariables>(GetFeatureFlagsDocument, options);
        }
export type GetFeatureFlagsQueryHookResult = ReturnType<typeof useGetFeatureFlagsQuery>;
export type GetFeatureFlagsLazyQueryHookResult = ReturnType<typeof useGetFeatureFlagsLazyQuery>;
export type GetFeatureFlagsQueryResult = Apollo.QueryResult<Types.GetFeatureFlagsQuery, Types.GetFeatureFlagsQueryVariables>;
export const GetScenarioDashboardSharedDataDocument = gql`
    query getScenarioDashboardSharedData($organizationId: uuid!, $scenarioId: Int!, $rootNodeId: uuid!) {
  nodes(where: {organization_id: {_eq: $organizationId}}) {
    id
    name
    mpath
  }
  scenario(where: {organization_id: {_eq: $organizationId}}) {
    organization_id
    id
    name
    scenario_type
  }
  scenario_by_pk(id: $scenarioId, organization_id: $organizationId) {
    id
    name
    relevantLayer {
      layer_id
      scenario_id
    }
    primaryQuestionSets {
      scenario_id
      scenario_question_set_id
    }
    completedLocations(args: {node_id_input: $rootNodeId}) {
      id
    }
  }
}
    `;

/**
 * __useGetScenarioDashboardSharedDataQuery__
 *
 * To run a query within a React component, call `useGetScenarioDashboardSharedDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenarioDashboardSharedDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenarioDashboardSharedDataQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      scenarioId: // value for 'scenarioId'
 *      rootNodeId: // value for 'rootNodeId'
 *   },
 * });
 */
export function useGetScenarioDashboardSharedDataQuery(baseOptions: Apollo.QueryHookOptions<Types.GetScenarioDashboardSharedDataQuery, Types.GetScenarioDashboardSharedDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetScenarioDashboardSharedDataQuery, Types.GetScenarioDashboardSharedDataQueryVariables>(GetScenarioDashboardSharedDataDocument, options);
      }
export function useGetScenarioDashboardSharedDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetScenarioDashboardSharedDataQuery, Types.GetScenarioDashboardSharedDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetScenarioDashboardSharedDataQuery, Types.GetScenarioDashboardSharedDataQueryVariables>(GetScenarioDashboardSharedDataDocument, options);
        }
export type GetScenarioDashboardSharedDataQueryHookResult = ReturnType<typeof useGetScenarioDashboardSharedDataQuery>;
export type GetScenarioDashboardSharedDataLazyQueryHookResult = ReturnType<typeof useGetScenarioDashboardSharedDataLazyQuery>;
export type GetScenarioDashboardSharedDataQueryResult = Apollo.QueryResult<Types.GetScenarioDashboardSharedDataQuery, Types.GetScenarioDashboardSharedDataQueryVariables>;
export const GetScenarioInfoForAssessmentTemplateDocument = gql`
    query getScenarioInfoForAssessmentTemplate($organizationId: uuid!, $id: Int!) {
  scenario_by_pk(organization_id: $organizationId, id: $id) {
    id
    name
    primaryQuestionSets {
      scenario_id
      scenario_question_set_id
      questionSet {
        id
        questions(distinct_on: question_id) {
          ordinal
          criticality
          question {
            id
            item_category {
              id
              name
              file {
                filepath
              }
            }
            question_type
            question_text
            deficient_answer
          }
        }
      }
    }
  }
  question_criticality_labels(where: {organization_id: {_eq: $organizationId}}) {
    label
    criticality_value
  }
}
    `;

/**
 * __useGetScenarioInfoForAssessmentTemplateQuery__
 *
 * To run a query within a React component, call `useGetScenarioInfoForAssessmentTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenarioInfoForAssessmentTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenarioInfoForAssessmentTemplateQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetScenarioInfoForAssessmentTemplateQuery(baseOptions: Apollo.QueryHookOptions<Types.GetScenarioInfoForAssessmentTemplateQuery, Types.GetScenarioInfoForAssessmentTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetScenarioInfoForAssessmentTemplateQuery, Types.GetScenarioInfoForAssessmentTemplateQueryVariables>(GetScenarioInfoForAssessmentTemplateDocument, options);
      }
export function useGetScenarioInfoForAssessmentTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetScenarioInfoForAssessmentTemplateQuery, Types.GetScenarioInfoForAssessmentTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetScenarioInfoForAssessmentTemplateQuery, Types.GetScenarioInfoForAssessmentTemplateQueryVariables>(GetScenarioInfoForAssessmentTemplateDocument, options);
        }
export type GetScenarioInfoForAssessmentTemplateQueryHookResult = ReturnType<typeof useGetScenarioInfoForAssessmentTemplateQuery>;
export type GetScenarioInfoForAssessmentTemplateLazyQueryHookResult = ReturnType<typeof useGetScenarioInfoForAssessmentTemplateLazyQuery>;
export type GetScenarioInfoForAssessmentTemplateQueryResult = Apollo.QueryResult<Types.GetScenarioInfoForAssessmentTemplateQuery, Types.GetScenarioInfoForAssessmentTemplateQueryVariables>;
export const GetAuditLogsAndUsersDocument = gql`
    query getAuditLogsAndUsers {
  users {
    ...User
  }
  audit_logs(order_by: {created_at: desc}, limit: 500) {
    id
    meta
    event_id
    event_name
    performed_by_id
    affected_user_id
    created_at
    updated_at
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetAuditLogsAndUsersQuery__
 *
 * To run a query within a React component, call `useGetAuditLogsAndUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuditLogsAndUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuditLogsAndUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAuditLogsAndUsersQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetAuditLogsAndUsersQuery, Types.GetAuditLogsAndUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAuditLogsAndUsersQuery, Types.GetAuditLogsAndUsersQueryVariables>(GetAuditLogsAndUsersDocument, options);
      }
export function useGetAuditLogsAndUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAuditLogsAndUsersQuery, Types.GetAuditLogsAndUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAuditLogsAndUsersQuery, Types.GetAuditLogsAndUsersQueryVariables>(GetAuditLogsAndUsersDocument, options);
        }
export type GetAuditLogsAndUsersQueryHookResult = ReturnType<typeof useGetAuditLogsAndUsersQuery>;
export type GetAuditLogsAndUsersLazyQueryHookResult = ReturnType<typeof useGetAuditLogsAndUsersLazyQuery>;
export type GetAuditLogsAndUsersQueryResult = Apollo.QueryResult<Types.GetAuditLogsAndUsersQuery, Types.GetAuditLogsAndUsersQueryVariables>;
export const GetLocationScenarioAssessmentFormInfoEditDocument = gql`
    query getLocationScenarioAssessmentFormInfoEdit($organizationId: uuid!, $assessmentId: Int!) {
  v2_assessments_by_pk(organization_id: $organizationId, id: $assessmentId) {
    id
    name
    organization_id
    description
    start_date
    due_date
    state
    ...AssessmentAssignees
    ...AssessmentSubscribers
    locations {
      ...V2AssessmentNodesIdentifiers
      node {
        id
        mpath
      }
    }
    scored_node_id
    import_active_answers
    is_compliant_ratification_required
    is_deficient_ratification_required
    is_n_a_ratification_required
    is_n_o_ratification_required
    assessmentTemplate {
      ...assessmentTemplateInfoForAssessmentCreation
    }
  }
}
    ${AssessmentAssigneesFragmentDoc}
${AssessmentSubscribersFragmentDoc}
${V2AssessmentNodesIdentifiersFragmentDoc}
${AssessmentTemplateInfoForAssessmentCreationFragmentDoc}`;

/**
 * __useGetLocationScenarioAssessmentFormInfoEditQuery__
 *
 * To run a query within a React component, call `useGetLocationScenarioAssessmentFormInfoEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationScenarioAssessmentFormInfoEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationScenarioAssessmentFormInfoEditQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      assessmentId: // value for 'assessmentId'
 *   },
 * });
 */
export function useGetLocationScenarioAssessmentFormInfoEditQuery(baseOptions: Apollo.QueryHookOptions<Types.GetLocationScenarioAssessmentFormInfoEditQuery, Types.GetLocationScenarioAssessmentFormInfoEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLocationScenarioAssessmentFormInfoEditQuery, Types.GetLocationScenarioAssessmentFormInfoEditQueryVariables>(GetLocationScenarioAssessmentFormInfoEditDocument, options);
      }
export function useGetLocationScenarioAssessmentFormInfoEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLocationScenarioAssessmentFormInfoEditQuery, Types.GetLocationScenarioAssessmentFormInfoEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLocationScenarioAssessmentFormInfoEditQuery, Types.GetLocationScenarioAssessmentFormInfoEditQueryVariables>(GetLocationScenarioAssessmentFormInfoEditDocument, options);
        }
export type GetLocationScenarioAssessmentFormInfoEditQueryHookResult = ReturnType<typeof useGetLocationScenarioAssessmentFormInfoEditQuery>;
export type GetLocationScenarioAssessmentFormInfoEditLazyQueryHookResult = ReturnType<typeof useGetLocationScenarioAssessmentFormInfoEditLazyQuery>;
export type GetLocationScenarioAssessmentFormInfoEditQueryResult = Apollo.QueryResult<Types.GetLocationScenarioAssessmentFormInfoEditQuery, Types.GetLocationScenarioAssessmentFormInfoEditQueryVariables>;
export const GetAssessmentHeaderInfoDocument = gql`
    query getAssessmentHeaderInfo($organizationId: uuid!, $assessmentId: Int!) {
  reports(
    where: {organization_id: {_eq: $organizationId}, assessment_id: {_eq: $assessmentId}}
    limit: 1
  ) {
    id
    previews(
      where: {report_section_id: {_is_null: true}, status: {_eq: "completed"}}
      order_by: {created_at: desc}
      limit: 1
    ) {
      id
      organization_id
      file {
        id
        filepath
        created_at
      }
    }
  }
  v2_assessments_by_pk(organization_id: $organizationId, id: $assessmentId) {
    id
    name
    schedule_id
    scoredNode {
      id
      name
    }
    assessmentTemplate {
      id
      name
      latestTemplate {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetAssessmentHeaderInfoQuery__
 *
 * To run a query within a React component, call `useGetAssessmentHeaderInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentHeaderInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentHeaderInfoQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      assessmentId: // value for 'assessmentId'
 *   },
 * });
 */
export function useGetAssessmentHeaderInfoQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAssessmentHeaderInfoQuery, Types.GetAssessmentHeaderInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAssessmentHeaderInfoQuery, Types.GetAssessmentHeaderInfoQueryVariables>(GetAssessmentHeaderInfoDocument, options);
      }
export function useGetAssessmentHeaderInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAssessmentHeaderInfoQuery, Types.GetAssessmentHeaderInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAssessmentHeaderInfoQuery, Types.GetAssessmentHeaderInfoQueryVariables>(GetAssessmentHeaderInfoDocument, options);
        }
export type GetAssessmentHeaderInfoQueryHookResult = ReturnType<typeof useGetAssessmentHeaderInfoQuery>;
export type GetAssessmentHeaderInfoLazyQueryHookResult = ReturnType<typeof useGetAssessmentHeaderInfoLazyQuery>;
export type GetAssessmentHeaderInfoQueryResult = Apollo.QueryResult<Types.GetAssessmentHeaderInfoQuery, Types.GetAssessmentHeaderInfoQueryVariables>;
export const GetActionableTaskHistoryDocument = gql`
    query GetActionableTaskHistory($where: actionable_task_history_bool_exp!) {
  actionable_task_history(where: $where, order_by: {updated_at: desc}) {
    ...ActionableTaskHistory
  }
}
    ${ActionableTaskHistoryFragmentDoc}`;

/**
 * __useGetActionableTaskHistoryQuery__
 *
 * To run a query within a React component, call `useGetActionableTaskHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActionableTaskHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActionableTaskHistoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetActionableTaskHistoryQuery(baseOptions: Apollo.QueryHookOptions<Types.GetActionableTaskHistoryQuery, Types.GetActionableTaskHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetActionableTaskHistoryQuery, Types.GetActionableTaskHistoryQueryVariables>(GetActionableTaskHistoryDocument, options);
      }
export function useGetActionableTaskHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetActionableTaskHistoryQuery, Types.GetActionableTaskHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetActionableTaskHistoryQuery, Types.GetActionableTaskHistoryQueryVariables>(GetActionableTaskHistoryDocument, options);
        }
export type GetActionableTaskHistoryQueryHookResult = ReturnType<typeof useGetActionableTaskHistoryQuery>;
export type GetActionableTaskHistoryLazyQueryHookResult = ReturnType<typeof useGetActionableTaskHistoryLazyQuery>;
export type GetActionableTaskHistoryQueryResult = Apollo.QueryResult<Types.GetActionableTaskHistoryQuery, Types.GetActionableTaskHistoryQueryVariables>;
export const GetActiveAnswerDataForTaskCreatorDocument = gql`
    query getActiveAnswerDataForTaskCreator($where: active_answers_bool_exp!) {
  active_answers(where: $where, order_by: {last_answered_at: desc}) {
    ...activeAnswersKeyFields
    criticality_value
    description
    is_accepted
    is_deficient
    is_flagged
    last_answered_at
    last_answered_by_id
    partial_compliance_multiplier
    ...activeAnswerOptions
    files {
      file {
        ...image
      }
    }
    item {
      __typename
      id
      name
      is_present
      files(order_by: {file: {updated_at: desc}}) {
        file {
          ...image
        }
      }
      item_category {
        id
        file {
          filepath
        }
      }
    }
    question {
      __typename
      id
      deficient_answer
      question_text
      standards {
        ...scenarioQuestionSetStandard
      }
    }
    response
  }
}
    ${ActiveAnswersKeyFieldsFragmentDoc}
${ActiveAnswerOptionsFragmentDoc}
${ImageFragmentDoc}
${ScenarioQuestionSetStandardFragmentDoc}`;

/**
 * __useGetActiveAnswerDataForTaskCreatorQuery__
 *
 * To run a query within a React component, call `useGetActiveAnswerDataForTaskCreatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveAnswerDataForTaskCreatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveAnswerDataForTaskCreatorQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetActiveAnswerDataForTaskCreatorQuery(baseOptions: Apollo.QueryHookOptions<Types.GetActiveAnswerDataForTaskCreatorQuery, Types.GetActiveAnswerDataForTaskCreatorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetActiveAnswerDataForTaskCreatorQuery, Types.GetActiveAnswerDataForTaskCreatorQueryVariables>(GetActiveAnswerDataForTaskCreatorDocument, options);
      }
export function useGetActiveAnswerDataForTaskCreatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetActiveAnswerDataForTaskCreatorQuery, Types.GetActiveAnswerDataForTaskCreatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetActiveAnswerDataForTaskCreatorQuery, Types.GetActiveAnswerDataForTaskCreatorQueryVariables>(GetActiveAnswerDataForTaskCreatorDocument, options);
        }
export type GetActiveAnswerDataForTaskCreatorQueryHookResult = ReturnType<typeof useGetActiveAnswerDataForTaskCreatorQuery>;
export type GetActiveAnswerDataForTaskCreatorLazyQueryHookResult = ReturnType<typeof useGetActiveAnswerDataForTaskCreatorLazyQuery>;
export type GetActiveAnswerDataForTaskCreatorQueryResult = Apollo.QueryResult<Types.GetActiveAnswerDataForTaskCreatorQuery, Types.GetActiveAnswerDataForTaskCreatorQueryVariables>;
export const GetAnswersForItemDocument = gql`
    query getAnswersForItem($dynamicBoolExp: active_answers_bool_exp) {
  active_answers(where: $dynamicBoolExp) {
    ...activeAnswersKeyFields
    ...activeAnswerOptions
    description
    criticality_value
    response
    is_deficient
    is_accepted
    is_flagged
    partial_compliance_multiplier
    last_answered_at
    last_answered_by_id
    cost
    item {
      id
      name
      node_id
      is_present
      files {
        __typename
        id
        file {
          ...image
        }
      }
      item_category {
        id
      }
      ...ItemDetails
    }
    question {
      ...Question
      standards {
        ...scenarioQuestionSetStandard
      }
      scenarioQuestions(
        where: {questionSet: {primaryQuestionSets: {scenario_question_set_id: {_is_null: false}}}}
      ) {
        ...ScenarioQuestionSetQuestionIdentifiers
        criticality
        is_required
        standard {
          ...scenarioQuestionSetStandard
        }
        questionSet {
          id
          scenario {
            id
            name
            scenario_type
          }
        }
      }
      ...QuestionScenarios
    }
    action_deficiencies {
      ...ActionDeficiencyIdentifiers
      task_action {
        __typename
        ...TaskActionIdentifiers
        assignee_id
        task {
          ...TaskIdentifiers
          status
          name
        }
      }
    }
    files {
      __typename
      id
      file {
        ...image
      }
    }
  }
}
    ${ActiveAnswersKeyFieldsFragmentDoc}
${ActiveAnswerOptionsFragmentDoc}
${ImageFragmentDoc}
${ItemDetailsFragmentDoc}
${QuestionFragmentDoc}
${ScenarioQuestionSetStandardFragmentDoc}
${ScenarioQuestionSetQuestionIdentifiersFragmentDoc}
${QuestionScenariosFragmentDoc}
${ActionDeficiencyIdentifiersFragmentDoc}
${TaskActionIdentifiersFragmentDoc}
${TaskIdentifiersFragmentDoc}`;

/**
 * __useGetAnswersForItemQuery__
 *
 * To run a query within a React component, call `useGetAnswersForItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnswersForItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnswersForItemQuery({
 *   variables: {
 *      dynamicBoolExp: // value for 'dynamicBoolExp'
 *   },
 * });
 */
export function useGetAnswersForItemQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetAnswersForItemQuery, Types.GetAnswersForItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAnswersForItemQuery, Types.GetAnswersForItemQueryVariables>(GetAnswersForItemDocument, options);
      }
export function useGetAnswersForItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAnswersForItemQuery, Types.GetAnswersForItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAnswersForItemQuery, Types.GetAnswersForItemQueryVariables>(GetAnswersForItemDocument, options);
        }
export type GetAnswersForItemQueryHookResult = ReturnType<typeof useGetAnswersForItemQuery>;
export type GetAnswersForItemLazyQueryHookResult = ReturnType<typeof useGetAnswersForItemLazyQuery>;
export type GetAnswersForItemQueryResult = Apollo.QueryResult<Types.GetAnswersForItemQuery, Types.GetAnswersForItemQueryVariables>;
export const GetActiveAnswersDataForSingleTaskCreatorDocument = gql`
    query getActiveAnswersDataForSingleTaskCreator($where: active_answers_bool_exp!) {
  active_answers(where: $where, order_by: {last_answered_at: desc}) {
    ...activeAnswersKeyFields
    ...activeAnswerOptions
    ...ActiveAnswerResponse
    ...ActiveAnswerAttachments
    ...ActiveAnswerCriticalityInfo
    item {
      ...ItemDetails
    }
    question {
      ...Question
      ...QuestionStandards
      ...QuestionScenarios
    }
  }
}
    ${ActiveAnswersKeyFieldsFragmentDoc}
${ActiveAnswerOptionsFragmentDoc}
${ActiveAnswerResponseFragmentDoc}
${ActiveAnswerAttachmentsFragmentDoc}
${ActiveAnswerCriticalityInfoFragmentDoc}
${ItemDetailsFragmentDoc}
${QuestionFragmentDoc}
${QuestionStandardsFragmentDoc}
${QuestionScenariosFragmentDoc}`;

/**
 * __useGetActiveAnswersDataForSingleTaskCreatorQuery__
 *
 * To run a query within a React component, call `useGetActiveAnswersDataForSingleTaskCreatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveAnswersDataForSingleTaskCreatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveAnswersDataForSingleTaskCreatorQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetActiveAnswersDataForSingleTaskCreatorQuery(baseOptions: Apollo.QueryHookOptions<Types.GetActiveAnswersDataForSingleTaskCreatorQuery, Types.GetActiveAnswersDataForSingleTaskCreatorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetActiveAnswersDataForSingleTaskCreatorQuery, Types.GetActiveAnswersDataForSingleTaskCreatorQueryVariables>(GetActiveAnswersDataForSingleTaskCreatorDocument, options);
      }
export function useGetActiveAnswersDataForSingleTaskCreatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetActiveAnswersDataForSingleTaskCreatorQuery, Types.GetActiveAnswersDataForSingleTaskCreatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetActiveAnswersDataForSingleTaskCreatorQuery, Types.GetActiveAnswersDataForSingleTaskCreatorQueryVariables>(GetActiveAnswersDataForSingleTaskCreatorDocument, options);
        }
export type GetActiveAnswersDataForSingleTaskCreatorQueryHookResult = ReturnType<typeof useGetActiveAnswersDataForSingleTaskCreatorQuery>;
export type GetActiveAnswersDataForSingleTaskCreatorLazyQueryHookResult = ReturnType<typeof useGetActiveAnswersDataForSingleTaskCreatorLazyQuery>;
export type GetActiveAnswersDataForSingleTaskCreatorQueryResult = Apollo.QueryResult<Types.GetActiveAnswersDataForSingleTaskCreatorQuery, Types.GetActiveAnswersDataForSingleTaskCreatorQueryVariables>;
export const ActiveAnswersForBulkReassessDocument = gql`
    query ActiveAnswersForBulkReassess($where: active_answers_bool_exp!) {
  active_answers(where: $where) {
    ...activeAnswersKeyFields
    question {
      __typename
      id
      deficient_answer
    }
    item {
      __typename
      id
      is_present
    }
  }
}
    ${ActiveAnswersKeyFieldsFragmentDoc}`;

/**
 * __useActiveAnswersForBulkReassessQuery__
 *
 * To run a query within a React component, call `useActiveAnswersForBulkReassessQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveAnswersForBulkReassessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveAnswersForBulkReassessQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useActiveAnswersForBulkReassessQuery(baseOptions: Apollo.QueryHookOptions<Types.ActiveAnswersForBulkReassessQuery, Types.ActiveAnswersForBulkReassessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ActiveAnswersForBulkReassessQuery, Types.ActiveAnswersForBulkReassessQueryVariables>(ActiveAnswersForBulkReassessDocument, options);
      }
export function useActiveAnswersForBulkReassessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ActiveAnswersForBulkReassessQuery, Types.ActiveAnswersForBulkReassessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ActiveAnswersForBulkReassessQuery, Types.ActiveAnswersForBulkReassessQueryVariables>(ActiveAnswersForBulkReassessDocument, options);
        }
export type ActiveAnswersForBulkReassessQueryHookResult = ReturnType<typeof useActiveAnswersForBulkReassessQuery>;
export type ActiveAnswersForBulkReassessLazyQueryHookResult = ReturnType<typeof useActiveAnswersForBulkReassessLazyQuery>;
export type ActiveAnswersForBulkReassessQueryResult = Apollo.QueryResult<Types.ActiveAnswersForBulkReassessQuery, Types.ActiveAnswersForBulkReassessQueryVariables>;
export const ActiveAnswersForRemediationDocument = gql`
    query ActiveAnswersForRemediation($where: active_answers_bool_exp!) {
  active_answers(where: $where) {
    ...ActiveAnswerRemediationInfo
  }
}
    ${ActiveAnswerRemediationInfoFragmentDoc}`;

/**
 * __useActiveAnswersForRemediationQuery__
 *
 * To run a query within a React component, call `useActiveAnswersForRemediationQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveAnswersForRemediationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveAnswersForRemediationQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useActiveAnswersForRemediationQuery(baseOptions: Apollo.QueryHookOptions<Types.ActiveAnswersForRemediationQuery, Types.ActiveAnswersForRemediationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ActiveAnswersForRemediationQuery, Types.ActiveAnswersForRemediationQueryVariables>(ActiveAnswersForRemediationDocument, options);
      }
export function useActiveAnswersForRemediationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ActiveAnswersForRemediationQuery, Types.ActiveAnswersForRemediationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ActiveAnswersForRemediationQuery, Types.ActiveAnswersForRemediationQueryVariables>(ActiveAnswersForRemediationDocument, options);
        }
export type ActiveAnswersForRemediationQueryHookResult = ReturnType<typeof useActiveAnswersForRemediationQuery>;
export type ActiveAnswersForRemediationLazyQueryHookResult = ReturnType<typeof useActiveAnswersForRemediationLazyQuery>;
export type ActiveAnswersForRemediationQueryResult = Apollo.QueryResult<Types.ActiveAnswersForRemediationQuery, Types.ActiveAnswersForRemediationQueryVariables>;
export const GetActiveItemFilesDocument = gql`
    query getActiveItemFiles($itemId: uuid!) {
  active_item_files(where: {item_id: {_eq: $itemId}}) {
    item_id
    file {
      ...image
    }
  }
}
    ${ImageFragmentDoc}`;

/**
 * __useGetActiveItemFilesQuery__
 *
 * To run a query within a React component, call `useGetActiveItemFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveItemFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveItemFilesQuery({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useGetActiveItemFilesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetActiveItemFilesQuery, Types.GetActiveItemFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetActiveItemFilesQuery, Types.GetActiveItemFilesQueryVariables>(GetActiveItemFilesDocument, options);
      }
export function useGetActiveItemFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetActiveItemFilesQuery, Types.GetActiveItemFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetActiveItemFilesQuery, Types.GetActiveItemFilesQueryVariables>(GetActiveItemFilesDocument, options);
        }
export type GetActiveItemFilesQueryHookResult = ReturnType<typeof useGetActiveItemFilesQuery>;
export type GetActiveItemFilesLazyQueryHookResult = ReturnType<typeof useGetActiveItemFilesLazyQuery>;
export type GetActiveItemFilesQueryResult = Apollo.QueryResult<Types.GetActiveItemFilesQuery, Types.GetActiveItemFilesQueryVariables>;
export const GetAnswerFilesForAssessmentItemDocument = gql`
    query getAnswerFilesForAssessmentItem($assessmentItemId: uuid!) {
  answers_files(where: {answer: {assessment_item_id: {_eq: $assessmentItemId}}}) {
    ...AnswersFilesIdentifiers
    file {
      id
      filepath
      original_filename
      ...image
    }
  }
}
    ${AnswersFilesIdentifiersFragmentDoc}
${ImageFragmentDoc}`;

/**
 * __useGetAnswerFilesForAssessmentItemQuery__
 *
 * To run a query within a React component, call `useGetAnswerFilesForAssessmentItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnswerFilesForAssessmentItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnswerFilesForAssessmentItemQuery({
 *   variables: {
 *      assessmentItemId: // value for 'assessmentItemId'
 *   },
 * });
 */
export function useGetAnswerFilesForAssessmentItemQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAnswerFilesForAssessmentItemQuery, Types.GetAnswerFilesForAssessmentItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAnswerFilesForAssessmentItemQuery, Types.GetAnswerFilesForAssessmentItemQueryVariables>(GetAnswerFilesForAssessmentItemDocument, options);
      }
export function useGetAnswerFilesForAssessmentItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAnswerFilesForAssessmentItemQuery, Types.GetAnswerFilesForAssessmentItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAnswerFilesForAssessmentItemQuery, Types.GetAnswerFilesForAssessmentItemQueryVariables>(GetAnswerFilesForAssessmentItemDocument, options);
        }
export type GetAnswerFilesForAssessmentItemQueryHookResult = ReturnType<typeof useGetAnswerFilesForAssessmentItemQuery>;
export type GetAnswerFilesForAssessmentItemLazyQueryHookResult = ReturnType<typeof useGetAnswerFilesForAssessmentItemLazyQuery>;
export type GetAnswerFilesForAssessmentItemQueryResult = Apollo.QueryResult<Types.GetAnswerFilesForAssessmentItemQuery, Types.GetAnswerFilesForAssessmentItemQueryVariables>;
export const GetOrgAreaTagsDocument = gql`
    query getOrgAreaTags($orgId: uuid!) {
  area_tags(where: {organization_id: {_eq: $orgId}}) {
    name
    id
  }
}
    `;

/**
 * __useGetOrgAreaTagsQuery__
 *
 * To run a query within a React component, call `useGetOrgAreaTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgAreaTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgAreaTagsQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useGetOrgAreaTagsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetOrgAreaTagsQuery, Types.GetOrgAreaTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetOrgAreaTagsQuery, Types.GetOrgAreaTagsQueryVariables>(GetOrgAreaTagsDocument, options);
      }
export function useGetOrgAreaTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetOrgAreaTagsQuery, Types.GetOrgAreaTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetOrgAreaTagsQuery, Types.GetOrgAreaTagsQueryVariables>(GetOrgAreaTagsDocument, options);
        }
export type GetOrgAreaTagsQueryHookResult = ReturnType<typeof useGetOrgAreaTagsQuery>;
export type GetOrgAreaTagsLazyQueryHookResult = ReturnType<typeof useGetOrgAreaTagsLazyQuery>;
export type GetOrgAreaTagsQueryResult = Apollo.QueryResult<Types.GetOrgAreaTagsQuery, Types.GetOrgAreaTagsQueryVariables>;
export const GetAnswersFilesDocument = gql`
    query getAnswersFiles($answerId: uuid!) {
  assessment_answers_by_pk(id: $answerId) {
    answers_files {
      ...AnswersFilesIdentifiers
      file {
        id
        filepath
        original_filename
        ...image
      }
    }
  }
}
    ${AnswersFilesIdentifiersFragmentDoc}
${ImageFragmentDoc}`;

/**
 * __useGetAnswersFilesQuery__
 *
 * To run a query within a React component, call `useGetAnswersFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnswersFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnswersFilesQuery({
 *   variables: {
 *      answerId: // value for 'answerId'
 *   },
 * });
 */
export function useGetAnswersFilesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAnswersFilesQuery, Types.GetAnswersFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAnswersFilesQuery, Types.GetAnswersFilesQueryVariables>(GetAnswersFilesDocument, options);
      }
export function useGetAnswersFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAnswersFilesQuery, Types.GetAnswersFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAnswersFilesQuery, Types.GetAnswersFilesQueryVariables>(GetAnswersFilesDocument, options);
        }
export type GetAnswersFilesQueryHookResult = ReturnType<typeof useGetAnswersFilesQuery>;
export type GetAnswersFilesLazyQueryHookResult = ReturnType<typeof useGetAnswersFilesLazyQuery>;
export type GetAnswersFilesQueryResult = Apollo.QueryResult<Types.GetAnswersFilesQuery, Types.GetAnswersFilesQueryVariables>;
export const GetAssessmentsStatusByOrgIdDocument = gql`
    query getAssessmentsStatusByOrgId($organizationId: uuid!) {
  assessment_count_by_organization_and_status(
    where: {organization_id: {_eq: $organizationId}}
  ) {
    count
    state
  }
}
    `;

/**
 * __useGetAssessmentsStatusByOrgIdQuery__
 *
 * To run a query within a React component, call `useGetAssessmentsStatusByOrgIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentsStatusByOrgIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentsStatusByOrgIdQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetAssessmentsStatusByOrgIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAssessmentsStatusByOrgIdQuery, Types.GetAssessmentsStatusByOrgIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAssessmentsStatusByOrgIdQuery, Types.GetAssessmentsStatusByOrgIdQueryVariables>(GetAssessmentsStatusByOrgIdDocument, options);
      }
export function useGetAssessmentsStatusByOrgIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAssessmentsStatusByOrgIdQuery, Types.GetAssessmentsStatusByOrgIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAssessmentsStatusByOrgIdQuery, Types.GetAssessmentsStatusByOrgIdQueryVariables>(GetAssessmentsStatusByOrgIdDocument, options);
        }
export type GetAssessmentsStatusByOrgIdQueryHookResult = ReturnType<typeof useGetAssessmentsStatusByOrgIdQuery>;
export type GetAssessmentsStatusByOrgIdLazyQueryHookResult = ReturnType<typeof useGetAssessmentsStatusByOrgIdLazyQuery>;
export type GetAssessmentsStatusByOrgIdQueryResult = Apollo.QueryResult<Types.GetAssessmentsStatusByOrgIdQuery, Types.GetAssessmentsStatusByOrgIdQueryVariables>;
export const GetAssessmentItemsForSyncDocument = gql`
    query getAssessmentItemsForSync($assessmentId: Int!, $updatedAt: timestamptz!) {
  assessment_items(
    where: {assessment_id: {_eq: $assessmentId}, updated_at: {_gte: $updatedAt}}
  ) {
    id
    assessment_id
    node_id
    name
    coordinates
    countermeasure
    is_present
    item_category_id
    is_interview_item
    updated_at
    deleted_at
    created_at
    exterior
    description
    recommendation
    recommendation_info
    location_description
  }
}
    `;

/**
 * __useGetAssessmentItemsForSyncQuery__
 *
 * To run a query within a React component, call `useGetAssessmentItemsForSyncQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentItemsForSyncQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentItemsForSyncQuery({
 *   variables: {
 *      assessmentId: // value for 'assessmentId'
 *      updatedAt: // value for 'updatedAt'
 *   },
 * });
 */
export function useGetAssessmentItemsForSyncQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAssessmentItemsForSyncQuery, Types.GetAssessmentItemsForSyncQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAssessmentItemsForSyncQuery, Types.GetAssessmentItemsForSyncQueryVariables>(GetAssessmentItemsForSyncDocument, options);
      }
export function useGetAssessmentItemsForSyncLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAssessmentItemsForSyncQuery, Types.GetAssessmentItemsForSyncQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAssessmentItemsForSyncQuery, Types.GetAssessmentItemsForSyncQueryVariables>(GetAssessmentItemsForSyncDocument, options);
        }
export type GetAssessmentItemsForSyncQueryHookResult = ReturnType<typeof useGetAssessmentItemsForSyncQuery>;
export type GetAssessmentItemsForSyncLazyQueryHookResult = ReturnType<typeof useGetAssessmentItemsForSyncLazyQuery>;
export type GetAssessmentItemsForSyncQueryResult = Apollo.QueryResult<Types.GetAssessmentItemsForSyncQuery, Types.GetAssessmentItemsForSyncQueryVariables>;
export const GetAssessmentItemsFilesDocument = gql`
    query getAssessmentItemsFiles($assessmentItemId: uuid!) {
  assessment_items_by_pk(id: $assessmentItemId) {
    id
    node_id
    assessment_id
    is_interview_item
    files {
      file_id
      assessment_item_id
      file {
        id
        filepath
        original_filename
        ...image
        uploaded_user {
          id
          first_name
          last_name
        }
      }
    }
  }
}
    ${ImageFragmentDoc}`;

/**
 * __useGetAssessmentItemsFilesQuery__
 *
 * To run a query within a React component, call `useGetAssessmentItemsFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentItemsFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentItemsFilesQuery({
 *   variables: {
 *      assessmentItemId: // value for 'assessmentItemId'
 *   },
 * });
 */
export function useGetAssessmentItemsFilesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAssessmentItemsFilesQuery, Types.GetAssessmentItemsFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAssessmentItemsFilesQuery, Types.GetAssessmentItemsFilesQueryVariables>(GetAssessmentItemsFilesDocument, options);
      }
export function useGetAssessmentItemsFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAssessmentItemsFilesQuery, Types.GetAssessmentItemsFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAssessmentItemsFilesQuery, Types.GetAssessmentItemsFilesQueryVariables>(GetAssessmentItemsFilesDocument, options);
        }
export type GetAssessmentItemsFilesQueryHookResult = ReturnType<typeof useGetAssessmentItemsFilesQuery>;
export type GetAssessmentItemsFilesLazyQueryHookResult = ReturnType<typeof useGetAssessmentItemsFilesLazyQuery>;
export type GetAssessmentItemsFilesQueryResult = Apollo.QueryResult<Types.GetAssessmentItemsFilesQuery, Types.GetAssessmentItemsFilesQueryVariables>;
export const GetAssessmentSummariesByOrgIdDocument = gql`
    query getAssessmentSummariesByOrgId($organizationId: uuid!) {
  assessment_summaries(
    where: {organization_id: {_eq: $organizationId}, scenarioAssessment: {scored_node_id: {_is_null: false}}}
    order_by: {created_at: desc}
  ) {
    organization_id
    id
    name
    state
    start_date
    due_date
    created_at
    completed_at
    template_id
    template_name
    questionnaire_percent_progress
    template {
      questionSets {
        questionSet {
          id
          scenario_id
          scenario {
            id
            name
          }
        }
      }
    }
    scenarioAssessment {
      ...AssessmentAssignees
      is_opened
      open_range
      scheduleCadence {
        ...ScheduledAssessmentScheduledRequiredFields
        status
      }
      scoredNode {
        organization_id
        id
        name
        coordinates
        layer_id
        mpath
      }
    }
  }
}
    ${AssessmentAssigneesFragmentDoc}
${ScheduledAssessmentScheduledRequiredFieldsFragmentDoc}`;

/**
 * __useGetAssessmentSummariesByOrgIdQuery__
 *
 * To run a query within a React component, call `useGetAssessmentSummariesByOrgIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentSummariesByOrgIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentSummariesByOrgIdQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetAssessmentSummariesByOrgIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAssessmentSummariesByOrgIdQuery, Types.GetAssessmentSummariesByOrgIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAssessmentSummariesByOrgIdQuery, Types.GetAssessmentSummariesByOrgIdQueryVariables>(GetAssessmentSummariesByOrgIdDocument, options);
      }
export function useGetAssessmentSummariesByOrgIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAssessmentSummariesByOrgIdQuery, Types.GetAssessmentSummariesByOrgIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAssessmentSummariesByOrgIdQuery, Types.GetAssessmentSummariesByOrgIdQueryVariables>(GetAssessmentSummariesByOrgIdDocument, options);
        }
export type GetAssessmentSummariesByOrgIdQueryHookResult = ReturnType<typeof useGetAssessmentSummariesByOrgIdQuery>;
export type GetAssessmentSummariesByOrgIdLazyQueryHookResult = ReturnType<typeof useGetAssessmentSummariesByOrgIdLazyQuery>;
export type GetAssessmentSummariesByOrgIdQueryResult = Apollo.QueryResult<Types.GetAssessmentSummariesByOrgIdQuery, Types.GetAssessmentSummariesByOrgIdQueryVariables>;
export const GetAssessmentPermissionsDocument = gql`
    query getAssessmentPermissions($organizationId: uuid!) {
  assessment_users(where: {organization_id: {_eq: $organizationId}}) {
    user_id
    assessment {
      id
      name
      scoredNode {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetAssessmentPermissionsQuery__
 *
 * To run a query within a React component, call `useGetAssessmentPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentPermissionsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetAssessmentPermissionsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAssessmentPermissionsQuery, Types.GetAssessmentPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAssessmentPermissionsQuery, Types.GetAssessmentPermissionsQueryVariables>(GetAssessmentPermissionsDocument, options);
      }
export function useGetAssessmentPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAssessmentPermissionsQuery, Types.GetAssessmentPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAssessmentPermissionsQuery, Types.GetAssessmentPermissionsQueryVariables>(GetAssessmentPermissionsDocument, options);
        }
export type GetAssessmentPermissionsQueryHookResult = ReturnType<typeof useGetAssessmentPermissionsQuery>;
export type GetAssessmentPermissionsLazyQueryHookResult = ReturnType<typeof useGetAssessmentPermissionsLazyQuery>;
export type GetAssessmentPermissionsQueryResult = Apollo.QueryResult<Types.GetAssessmentPermissionsQuery, Types.GetAssessmentPermissionsQueryVariables>;
export const GetCommentsDocument = gql`
    query GetComments($entityType: enum_comment_entity_type_enum!, $entityPk: jsonb) {
  comments(where: {entity_type: {_eq: $entityType}, entity_pk: {_eq: $entityPk}}) {
    ...Comment
  }
}
    ${CommentFragmentDoc}`;

/**
 * __useGetCommentsQuery__
 *
 * To run a query within a React component, call `useGetCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentsQuery({
 *   variables: {
 *      entityType: // value for 'entityType'
 *      entityPk: // value for 'entityPk'
 *   },
 * });
 */
export function useGetCommentsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCommentsQuery, Types.GetCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCommentsQuery, Types.GetCommentsQueryVariables>(GetCommentsDocument, options);
      }
export function useGetCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCommentsQuery, Types.GetCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCommentsQuery, Types.GetCommentsQueryVariables>(GetCommentsDocument, options);
        }
export type GetCommentsQueryHookResult = ReturnType<typeof useGetCommentsQuery>;
export type GetCommentsLazyQueryHookResult = ReturnType<typeof useGetCommentsLazyQuery>;
export type GetCommentsQueryResult = Apollo.QueryResult<Types.GetCommentsQuery, Types.GetCommentsQueryVariables>;
export const GetLibraryOptionsByLocationIdDocument = gql`
    query getLibraryOptionsByLocationId($organizationId: uuid!, $locationPath: ltree!, $itemCategoryId: uuid!) {
  consideration_options(
    where: {_and: {organization_id: {_eq: $organizationId}, itemCategories: {item_category_id: {_eq: $itemCategoryId}}, nodes: {node: {path: {_ancestor: $locationPath}}}}}
  ) {
    ...considerationOptionInfo
  }
}
    ${ConsiderationOptionInfoFragmentDoc}`;

/**
 * __useGetLibraryOptionsByLocationIdQuery__
 *
 * To run a query within a React component, call `useGetLibraryOptionsByLocationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLibraryOptionsByLocationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLibraryOptionsByLocationIdQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      locationPath: // value for 'locationPath'
 *      itemCategoryId: // value for 'itemCategoryId'
 *   },
 * });
 */
export function useGetLibraryOptionsByLocationIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetLibraryOptionsByLocationIdQuery, Types.GetLibraryOptionsByLocationIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLibraryOptionsByLocationIdQuery, Types.GetLibraryOptionsByLocationIdQueryVariables>(GetLibraryOptionsByLocationIdDocument, options);
      }
export function useGetLibraryOptionsByLocationIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLibraryOptionsByLocationIdQuery, Types.GetLibraryOptionsByLocationIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLibraryOptionsByLocationIdQuery, Types.GetLibraryOptionsByLocationIdQueryVariables>(GetLibraryOptionsByLocationIdDocument, options);
        }
export type GetLibraryOptionsByLocationIdQueryHookResult = ReturnType<typeof useGetLibraryOptionsByLocationIdQuery>;
export type GetLibraryOptionsByLocationIdLazyQueryHookResult = ReturnType<typeof useGetLibraryOptionsByLocationIdLazyQuery>;
export type GetLibraryOptionsByLocationIdQueryResult = Apollo.QueryResult<Types.GetLibraryOptionsByLocationIdQuery, Types.GetLibraryOptionsByLocationIdQueryVariables>;
export const GetConsiderationOptionDocument = gql`
    query getConsiderationOption($organizationId: uuid!, $id: Int!) {
  consideration_options_by_pk(organization_id: $organizationId, id: $id) {
    ...considerationOptionInfo
  }
}
    ${ConsiderationOptionInfoFragmentDoc}`;

/**
 * __useGetConsiderationOptionQuery__
 *
 * To run a query within a React component, call `useGetConsiderationOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConsiderationOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConsiderationOptionQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetConsiderationOptionQuery(baseOptions: Apollo.QueryHookOptions<Types.GetConsiderationOptionQuery, Types.GetConsiderationOptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetConsiderationOptionQuery, Types.GetConsiderationOptionQueryVariables>(GetConsiderationOptionDocument, options);
      }
export function useGetConsiderationOptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetConsiderationOptionQuery, Types.GetConsiderationOptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetConsiderationOptionQuery, Types.GetConsiderationOptionQueryVariables>(GetConsiderationOptionDocument, options);
        }
export type GetConsiderationOptionQueryHookResult = ReturnType<typeof useGetConsiderationOptionQuery>;
export type GetConsiderationOptionLazyQueryHookResult = ReturnType<typeof useGetConsiderationOptionLazyQuery>;
export type GetConsiderationOptionQueryResult = Apollo.QueryResult<Types.GetConsiderationOptionQuery, Types.GetConsiderationOptionQueryVariables>;
export const GetConsiderationOptionsDocument = gql`
    query getConsiderationOptions($where: consideration_options_bool_exp!) {
  consideration_options: fn_get_consideration_options_for_user(
    where: $where
    order_by: {updated_at: desc}
  ) {
    ...considerationOptionInfo
  }
}
    ${ConsiderationOptionInfoFragmentDoc}`;

/**
 * __useGetConsiderationOptionsQuery__
 *
 * To run a query within a React component, call `useGetConsiderationOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConsiderationOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConsiderationOptionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetConsiderationOptionsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetConsiderationOptionsQuery, Types.GetConsiderationOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetConsiderationOptionsQuery, Types.GetConsiderationOptionsQueryVariables>(GetConsiderationOptionsDocument, options);
      }
export function useGetConsiderationOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetConsiderationOptionsQuery, Types.GetConsiderationOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetConsiderationOptionsQuery, Types.GetConsiderationOptionsQueryVariables>(GetConsiderationOptionsDocument, options);
        }
export type GetConsiderationOptionsQueryHookResult = ReturnType<typeof useGetConsiderationOptionsQuery>;
export type GetConsiderationOptionsLazyQueryHookResult = ReturnType<typeof useGetConsiderationOptionsLazyQuery>;
export type GetConsiderationOptionsQueryResult = Apollo.QueryResult<Types.GetConsiderationOptionsQuery, Types.GetConsiderationOptionsQueryVariables>;
export const GetLatestEulaDocument = gql`
    query getLatestEula {
  latest_eula {
    __typename
    agreement
  }
}
    `;

/**
 * __useGetLatestEulaQuery__
 *
 * To run a query within a React component, call `useGetLatestEulaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestEulaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestEulaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestEulaQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetLatestEulaQuery, Types.GetLatestEulaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLatestEulaQuery, Types.GetLatestEulaQueryVariables>(GetLatestEulaDocument, options);
      }
export function useGetLatestEulaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLatestEulaQuery, Types.GetLatestEulaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLatestEulaQuery, Types.GetLatestEulaQueryVariables>(GetLatestEulaDocument, options);
        }
export type GetLatestEulaQueryHookResult = ReturnType<typeof useGetLatestEulaQuery>;
export type GetLatestEulaLazyQueryHookResult = ReturnType<typeof useGetLatestEulaLazyQuery>;
export type GetLatestEulaQueryResult = Apollo.QueryResult<Types.GetLatestEulaQuery, Types.GetLatestEulaQueryVariables>;
export const GetMostRecentEulaDocument = gql`
    query getMostRecentEula {
  eulas(order_by: {created_at: desc}, limit: 1) {
    __typename
    id
    agreement
  }
}
    `;

/**
 * __useGetMostRecentEulaQuery__
 *
 * To run a query within a React component, call `useGetMostRecentEulaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMostRecentEulaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMostRecentEulaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMostRecentEulaQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetMostRecentEulaQuery, Types.GetMostRecentEulaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetMostRecentEulaQuery, Types.GetMostRecentEulaQueryVariables>(GetMostRecentEulaDocument, options);
      }
export function useGetMostRecentEulaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMostRecentEulaQuery, Types.GetMostRecentEulaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetMostRecentEulaQuery, Types.GetMostRecentEulaQueryVariables>(GetMostRecentEulaDocument, options);
        }
export type GetMostRecentEulaQueryHookResult = ReturnType<typeof useGetMostRecentEulaQuery>;
export type GetMostRecentEulaLazyQueryHookResult = ReturnType<typeof useGetMostRecentEulaLazyQuery>;
export type GetMostRecentEulaQueryResult = Apollo.QueryResult<Types.GetMostRecentEulaQuery, Types.GetMostRecentEulaQueryVariables>;
export const GetOrganizationFilesDocument = gql`
    query getOrganizationFiles($organizationId: uuid!) {
  files(
    where: {organization_id: {_eq: $organizationId}}
    order_by: {created_at: desc}
  ) {
    id
    ...image
    created_at
    filepath
    original_filename
    uploaded_user {
      ...User
    }
    entity_type
    entity_id
    organization_logo {
      id
      name
    }
    item_category {
      id
      name
    }
    user_avatar {
      id
      first_name
      last_name
    }
    answers_files {
      ...AnswersFilesIdentifiers
      answer {
        assessment_item {
          scenarioAssessment {
            id
            name
          }
        }
      }
    }
    node {
      id
      name
    }
    node_mapbox_maps_tiff_file {
      node {
        id
        name
      }
    }
    node_mapbox_maps_original_file {
      node {
        id
        name
      }
    }
    nodes_files {
      node {
        id
        name
      }
    }
    assessment_items_files {
      ...AssessmentItemsFilesIdentifiers
      assessment_item {
        scenarioAssessment {
          id
          name
        }
      }
    }
  }
}
    ${ImageFragmentDoc}
${UserFragmentDoc}
${AnswersFilesIdentifiersFragmentDoc}
${AssessmentItemsFilesIdentifiersFragmentDoc}`;

/**
 * __useGetOrganizationFilesQuery__
 *
 * To run a query within a React component, call `useGetOrganizationFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationFilesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetOrganizationFilesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetOrganizationFilesQuery, Types.GetOrganizationFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetOrganizationFilesQuery, Types.GetOrganizationFilesQueryVariables>(GetOrganizationFilesDocument, options);
      }
export function useGetOrganizationFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetOrganizationFilesQuery, Types.GetOrganizationFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetOrganizationFilesQuery, Types.GetOrganizationFilesQueryVariables>(GetOrganizationFilesDocument, options);
        }
export type GetOrganizationFilesQueryHookResult = ReturnType<typeof useGetOrganizationFilesQuery>;
export type GetOrganizationFilesLazyQueryHookResult = ReturnType<typeof useGetOrganizationFilesLazyQuery>;
export type GetOrganizationFilesQueryResult = Apollo.QueryResult<Types.GetOrganizationFilesQuery, Types.GetOrganizationFilesQueryVariables>;
export const GetItemCategoryDocument = gql`
    query getItemCategory($id: uuid!) {
  item_categories_by_pk(id: $id) {
    id
    name
    description
    countermeasure
    asset
    created_at
    area_tag_id
    file {
      id
      filepath
      icon {
        id
      }
    }
  }
}
    `;

/**
 * __useGetItemCategoryQuery__
 *
 * To run a query within a React component, call `useGetItemCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetItemCategoryQuery(baseOptions: Apollo.QueryHookOptions<Types.GetItemCategoryQuery, Types.GetItemCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetItemCategoryQuery, Types.GetItemCategoryQueryVariables>(GetItemCategoryDocument, options);
      }
export function useGetItemCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetItemCategoryQuery, Types.GetItemCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetItemCategoryQuery, Types.GetItemCategoryQueryVariables>(GetItemCategoryDocument, options);
        }
export type GetItemCategoryQueryHookResult = ReturnType<typeof useGetItemCategoryQuery>;
export type GetItemCategoryLazyQueryHookResult = ReturnType<typeof useGetItemCategoryLazyQuery>;
export type GetItemCategoryQueryResult = Apollo.QueryResult<Types.GetItemCategoryQuery, Types.GetItemCategoryQueryVariables>;
export const GetFilesWithImageMetadataDocument = gql`
    query GetFilesWithImageMetadata($ids: [uuid!]) {
  files(where: {id: {_in: $ids}}) {
    id
    ...image
  }
}
    ${ImageFragmentDoc}`;

/**
 * __useGetFilesWithImageMetadataQuery__
 *
 * To run a query within a React component, call `useGetFilesWithImageMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilesWithImageMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilesWithImageMetadataQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetFilesWithImageMetadataQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetFilesWithImageMetadataQuery, Types.GetFilesWithImageMetadataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFilesWithImageMetadataQuery, Types.GetFilesWithImageMetadataQueryVariables>(GetFilesWithImageMetadataDocument, options);
      }
export function useGetFilesWithImageMetadataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFilesWithImageMetadataQuery, Types.GetFilesWithImageMetadataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFilesWithImageMetadataQuery, Types.GetFilesWithImageMetadataQueryVariables>(GetFilesWithImageMetadataDocument, options);
        }
export type GetFilesWithImageMetadataQueryHookResult = ReturnType<typeof useGetFilesWithImageMetadataQuery>;
export type GetFilesWithImageMetadataLazyQueryHookResult = ReturnType<typeof useGetFilesWithImageMetadataLazyQuery>;
export type GetFilesWithImageMetadataQueryResult = Apollo.QueryResult<Types.GetFilesWithImageMetadataQuery, Types.GetFilesWithImageMetadataQueryVariables>;
export const GetFileWithImageDocument = gql`
    query GetFileWithImage($id: uuid!) {
  files_by_pk(id: $id) {
    ...FileWithImageData
  }
}
    ${FileWithImageDataFragmentDoc}`;

/**
 * __useGetFileWithImageQuery__
 *
 * To run a query within a React component, call `useGetFileWithImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileWithImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileWithImageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFileWithImageQuery(baseOptions: Apollo.QueryHookOptions<Types.GetFileWithImageQuery, Types.GetFileWithImageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFileWithImageQuery, Types.GetFileWithImageQueryVariables>(GetFileWithImageDocument, options);
      }
export function useGetFileWithImageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFileWithImageQuery, Types.GetFileWithImageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFileWithImageQuery, Types.GetFileWithImageQueryVariables>(GetFileWithImageDocument, options);
        }
export type GetFileWithImageQueryHookResult = ReturnType<typeof useGetFileWithImageQuery>;
export type GetFileWithImageLazyQueryHookResult = ReturnType<typeof useGetFileWithImageLazyQuery>;
export type GetFileWithImageQueryResult = Apollo.QueryResult<Types.GetFileWithImageQuery, Types.GetFileWithImageQueryVariables>;
export const GetNodeFilterDocument = gql`
    query getNodeFilter($id: Int!, $organizationId: uuid!) {
  filters_by_pk(id: $id, organization_id: $organizationId) {
    id
    name
    description
    filter_obj
    is_private_for_user
  }
}
    `;

/**
 * __useGetNodeFilterQuery__
 *
 * To run a query within a React component, call `useGetNodeFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNodeFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNodeFilterQuery({
 *   variables: {
 *      id: // value for 'id'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetNodeFilterQuery(baseOptions: Apollo.QueryHookOptions<Types.GetNodeFilterQuery, Types.GetNodeFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetNodeFilterQuery, Types.GetNodeFilterQueryVariables>(GetNodeFilterDocument, options);
      }
export function useGetNodeFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetNodeFilterQuery, Types.GetNodeFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetNodeFilterQuery, Types.GetNodeFilterQueryVariables>(GetNodeFilterDocument, options);
        }
export type GetNodeFilterQueryHookResult = ReturnType<typeof useGetNodeFilterQuery>;
export type GetNodeFilterLazyQueryHookResult = ReturnType<typeof useGetNodeFilterLazyQuery>;
export type GetNodeFilterQueryResult = Apollo.QueryResult<Types.GetNodeFilterQuery, Types.GetNodeFilterQueryVariables>;
export const GetFiltersTableDataDocument = gql`
    query getFiltersTableData {
  filters(order_by: {updated_at: desc}) {
    id
    is_private_for_user
    name
    description
    created_at
    updated_at
  }
}
    `;

/**
 * __useGetFiltersTableDataQuery__
 *
 * To run a query within a React component, call `useGetFiltersTableDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFiltersTableDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFiltersTableDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFiltersTableDataQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetFiltersTableDataQuery, Types.GetFiltersTableDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFiltersTableDataQuery, Types.GetFiltersTableDataQueryVariables>(GetFiltersTableDataDocument, options);
      }
export function useGetFiltersTableDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFiltersTableDataQuery, Types.GetFiltersTableDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFiltersTableDataQuery, Types.GetFiltersTableDataQueryVariables>(GetFiltersTableDataDocument, options);
        }
export type GetFiltersTableDataQueryHookResult = ReturnType<typeof useGetFiltersTableDataQuery>;
export type GetFiltersTableDataLazyQueryHookResult = ReturnType<typeof useGetFiltersTableDataLazyQuery>;
export type GetFiltersTableDataQueryResult = Apollo.QueryResult<Types.GetFiltersTableDataQuery, Types.GetFiltersTableDataQueryVariables>;
export const GetLibraryIconsDocument = gql`
    query getLibraryIcons {
  icons {
    id
    keywords
    file {
      filepath
      original_filename
      item_categories_aggregate {
        aggregate {
          count
        }
      }
    }
  }
}
    `;

/**
 * __useGetLibraryIconsQuery__
 *
 * To run a query within a React component, call `useGetLibraryIconsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLibraryIconsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLibraryIconsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLibraryIconsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetLibraryIconsQuery, Types.GetLibraryIconsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLibraryIconsQuery, Types.GetLibraryIconsQueryVariables>(GetLibraryIconsDocument, options);
      }
export function useGetLibraryIconsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLibraryIconsQuery, Types.GetLibraryIconsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLibraryIconsQuery, Types.GetLibraryIconsQueryVariables>(GetLibraryIconsDocument, options);
        }
export type GetLibraryIconsQueryHookResult = ReturnType<typeof useGetLibraryIconsQuery>;
export type GetLibraryIconsLazyQueryHookResult = ReturnType<typeof useGetLibraryIconsLazyQuery>;
export type GetLibraryIconsQueryResult = Apollo.QueryResult<Types.GetLibraryIconsQuery, Types.GetLibraryIconsQueryVariables>;
export const GetItemCategoryIconsDocument = gql`
    query getItemCategoryIcons($organizationId: uuid!) {
  item_categories(where: {organization_id: {_eq: $organizationId}}) {
    id
    file {
      filepath
    }
  }
}
    `;

/**
 * __useGetItemCategoryIconsQuery__
 *
 * To run a query within a React component, call `useGetItemCategoryIconsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemCategoryIconsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemCategoryIconsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetItemCategoryIconsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetItemCategoryIconsQuery, Types.GetItemCategoryIconsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetItemCategoryIconsQuery, Types.GetItemCategoryIconsQueryVariables>(GetItemCategoryIconsDocument, options);
      }
export function useGetItemCategoryIconsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetItemCategoryIconsQuery, Types.GetItemCategoryIconsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetItemCategoryIconsQuery, Types.GetItemCategoryIconsQueryVariables>(GetItemCategoryIconsDocument, options);
        }
export type GetItemCategoryIconsQueryHookResult = ReturnType<typeof useGetItemCategoryIconsQuery>;
export type GetItemCategoryIconsLazyQueryHookResult = ReturnType<typeof useGetItemCategoryIconsLazyQuery>;
export type GetItemCategoryIconsQueryResult = Apollo.QueryResult<Types.GetItemCategoryIconsQuery, Types.GetItemCategoryIconsQueryVariables>;
export const GetQuestionsByItemCategoryDocument = gql`
    query getQuestionsByItemCategory($id: uuid!) {
  item_categories_by_pk(id: $id) {
    id
    questions {
      question_text
      question_type
      deficient_answer
      id
    }
  }
}
    `;

/**
 * __useGetQuestionsByItemCategoryQuery__
 *
 * To run a query within a React component, call `useGetQuestionsByItemCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionsByItemCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionsByItemCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuestionsByItemCategoryQuery(baseOptions: Apollo.QueryHookOptions<Types.GetQuestionsByItemCategoryQuery, Types.GetQuestionsByItemCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetQuestionsByItemCategoryQuery, Types.GetQuestionsByItemCategoryQueryVariables>(GetQuestionsByItemCategoryDocument, options);
      }
export function useGetQuestionsByItemCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetQuestionsByItemCategoryQuery, Types.GetQuestionsByItemCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetQuestionsByItemCategoryQuery, Types.GetQuestionsByItemCategoryQueryVariables>(GetQuestionsByItemCategoryDocument, options);
        }
export type GetQuestionsByItemCategoryQueryHookResult = ReturnType<typeof useGetQuestionsByItemCategoryQuery>;
export type GetQuestionsByItemCategoryLazyQueryHookResult = ReturnType<typeof useGetQuestionsByItemCategoryLazyQuery>;
export type GetQuestionsByItemCategoryQueryResult = Apollo.QueryResult<Types.GetQuestionsByItemCategoryQuery, Types.GetQuestionsByItemCategoryQueryVariables>;
export const GetOrganizationItemCategoriesDocument = gql`
    query getOrganizationItemCategories($organizationId: uuid!) {
  item_categories(
    where: {organization_id: {_eq: $organizationId}}
    order_by: {name: asc}
  ) {
    __typename
    id
    name
    file {
      __typename
      filepath
    }
  }
}
    `;

/**
 * __useGetOrganizationItemCategoriesQuery__
 *
 * To run a query within a React component, call `useGetOrganizationItemCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationItemCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationItemCategoriesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetOrganizationItemCategoriesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetOrganizationItemCategoriesQuery, Types.GetOrganizationItemCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetOrganizationItemCategoriesQuery, Types.GetOrganizationItemCategoriesQueryVariables>(GetOrganizationItemCategoriesDocument, options);
      }
export function useGetOrganizationItemCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetOrganizationItemCategoriesQuery, Types.GetOrganizationItemCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetOrganizationItemCategoriesQuery, Types.GetOrganizationItemCategoriesQueryVariables>(GetOrganizationItemCategoriesDocument, options);
        }
export type GetOrganizationItemCategoriesQueryHookResult = ReturnType<typeof useGetOrganizationItemCategoriesQuery>;
export type GetOrganizationItemCategoriesLazyQueryHookResult = ReturnType<typeof useGetOrganizationItemCategoriesLazyQuery>;
export type GetOrganizationItemCategoriesQueryResult = Apollo.QueryResult<Types.GetOrganizationItemCategoriesQuery, Types.GetOrganizationItemCategoriesQueryVariables>;
export const GetItemCategoryQuestionsDocument = gql`
    query getItemCategoryQuestions($itemCategoryId: uuid!) {
  item_categories_by_pk(id: $itemCategoryId) {
    __typename
    id
    inspectionQuestions: questions(where: {question_type: {_eq: "inspection"}}) {
      ...itemCategoryQuestionsFragment
    }
    interviewQuestions: questions(where: {question_type: {_eq: "interview"}}) {
      ...itemCategoryQuestionsFragment
      layer_id
    }
  }
}
    ${ItemCategoryQuestionsFragmentFragmentDoc}`;

/**
 * __useGetItemCategoryQuestionsQuery__
 *
 * To run a query within a React component, call `useGetItemCategoryQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemCategoryQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemCategoryQuestionsQuery({
 *   variables: {
 *      itemCategoryId: // value for 'itemCategoryId'
 *   },
 * });
 */
export function useGetItemCategoryQuestionsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetItemCategoryQuestionsQuery, Types.GetItemCategoryQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetItemCategoryQuestionsQuery, Types.GetItemCategoryQuestionsQueryVariables>(GetItemCategoryQuestionsDocument, options);
      }
export function useGetItemCategoryQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetItemCategoryQuestionsQuery, Types.GetItemCategoryQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetItemCategoryQuestionsQuery, Types.GetItemCategoryQuestionsQueryVariables>(GetItemCategoryQuestionsDocument, options);
        }
export type GetItemCategoryQuestionsQueryHookResult = ReturnType<typeof useGetItemCategoryQuestionsQuery>;
export type GetItemCategoryQuestionsLazyQueryHookResult = ReturnType<typeof useGetItemCategoryQuestionsLazyQuery>;
export type GetItemCategoryQuestionsQueryResult = Apollo.QueryResult<Types.GetItemCategoryQuestionsQuery, Types.GetItemCategoryQuestionsQueryVariables>;
export const GetItemCategoryPropertiesDocument = gql`
    query getItemCategoryProperties($id: uuid!) {
  item_category_property_group(
    where: {item_category_id: {_eq: $id}}
    order_by: {ordinal: asc}
  ) {
    ...ItemCategoryPropertyGroup
  }
}
    ${ItemCategoryPropertyGroupFragmentDoc}`;

/**
 * __useGetItemCategoryPropertiesQuery__
 *
 * To run a query within a React component, call `useGetItemCategoryPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemCategoryPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemCategoryPropertiesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetItemCategoryPropertiesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetItemCategoryPropertiesQuery, Types.GetItemCategoryPropertiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetItemCategoryPropertiesQuery, Types.GetItemCategoryPropertiesQueryVariables>(GetItemCategoryPropertiesDocument, options);
      }
export function useGetItemCategoryPropertiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetItemCategoryPropertiesQuery, Types.GetItemCategoryPropertiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetItemCategoryPropertiesQuery, Types.GetItemCategoryPropertiesQueryVariables>(GetItemCategoryPropertiesDocument, options);
        }
export type GetItemCategoryPropertiesQueryHookResult = ReturnType<typeof useGetItemCategoryPropertiesQuery>;
export type GetItemCategoryPropertiesLazyQueryHookResult = ReturnType<typeof useGetItemCategoryPropertiesLazyQuery>;
export type GetItemCategoryPropertiesQueryResult = Apollo.QueryResult<Types.GetItemCategoryPropertiesQuery, Types.GetItemCategoryPropertiesQueryVariables>;
export const GetItemHistoryDocument = gql`
    query getItemHistory($itemId: uuid!) {
  item_history(
    where: {item_id: {_eq: $itemId}}
    order_by: {occurred_at: desc}
    limit: 10
  ) {
    __typename
    id
    action
    changed_by_user_id
    created_at
    data
    entityLabel
    occurred_at
    user {
      __typename
      id
      first_name
      last_name
      email
      avatar {
        id
        filepath
      }
    }
    type
  }
}
    `;

/**
 * __useGetItemHistoryQuery__
 *
 * To run a query within a React component, call `useGetItemHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemHistoryQuery({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useGetItemHistoryQuery(baseOptions: Apollo.QueryHookOptions<Types.GetItemHistoryQuery, Types.GetItemHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetItemHistoryQuery, Types.GetItemHistoryQueryVariables>(GetItemHistoryDocument, options);
      }
export function useGetItemHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetItemHistoryQuery, Types.GetItemHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetItemHistoryQuery, Types.GetItemHistoryQueryVariables>(GetItemHistoryDocument, options);
        }
export type GetItemHistoryQueryHookResult = ReturnType<typeof useGetItemHistoryQuery>;
export type GetItemHistoryLazyQueryHookResult = ReturnType<typeof useGetItemHistoryLazyQuery>;
export type GetItemHistoryQueryResult = Apollo.QueryResult<Types.GetItemHistoryQuery, Types.GetItemHistoryQueryVariables>;
export const GetItemDetailsAssessmentSummariesDocument = gql`
    query getItemDetailsAssessmentSummaries($itemId: uuid!) {
  items_by_pk(id: $itemId) {
    id
    history(order_by: {scenarioAssessment: {completed_at: desc}}) {
      is_deficient
      is_present
      scenarioAssessment {
        name
        completed_at
      }
      answers {
        question {
          standards {
            ...scenarioQuestionSetStandard
          }
          question_text
        }
        question_revision {
          name
        }
        response
        is_deficient
        description
        answers_files {
          ...AnswersFilesIdentifiers
        }
      }
    }
  }
}
    ${ScenarioQuestionSetStandardFragmentDoc}
${AnswersFilesIdentifiersFragmentDoc}`;

/**
 * __useGetItemDetailsAssessmentSummariesQuery__
 *
 * To run a query within a React component, call `useGetItemDetailsAssessmentSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemDetailsAssessmentSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemDetailsAssessmentSummariesQuery({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useGetItemDetailsAssessmentSummariesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetItemDetailsAssessmentSummariesQuery, Types.GetItemDetailsAssessmentSummariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetItemDetailsAssessmentSummariesQuery, Types.GetItemDetailsAssessmentSummariesQueryVariables>(GetItemDetailsAssessmentSummariesDocument, options);
      }
export function useGetItemDetailsAssessmentSummariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetItemDetailsAssessmentSummariesQuery, Types.GetItemDetailsAssessmentSummariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetItemDetailsAssessmentSummariesQuery, Types.GetItemDetailsAssessmentSummariesQueryVariables>(GetItemDetailsAssessmentSummariesDocument, options);
        }
export type GetItemDetailsAssessmentSummariesQueryHookResult = ReturnType<typeof useGetItemDetailsAssessmentSummariesQuery>;
export type GetItemDetailsAssessmentSummariesLazyQueryHookResult = ReturnType<typeof useGetItemDetailsAssessmentSummariesLazyQuery>;
export type GetItemDetailsAssessmentSummariesQueryResult = Apollo.QueryResult<Types.GetItemDetailsAssessmentSummariesQuery, Types.GetItemDetailsAssessmentSummariesQueryVariables>;
export const GetItemTasksDocument = gql`
    query getItemTasks($itemId: uuid!) {
  items_by_pk(id: $itemId) {
    __typename
    id
    answers {
      activeAnswer {
        ...activeAnswersKeyFields
        action_deficiencies {
          ...ActionDeficiencyIdentifiers
          task_action {
            ...TaskActionIdentifiers
            assignee_id
            task {
              ...TaskIdentifiers
              name
              status
            }
          }
        }
      }
    }
  }
}
    ${ActiveAnswersKeyFieldsFragmentDoc}
${ActionDeficiencyIdentifiersFragmentDoc}
${TaskActionIdentifiersFragmentDoc}
${TaskIdentifiersFragmentDoc}`;

/**
 * __useGetItemTasksQuery__
 *
 * To run a query within a React component, call `useGetItemTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemTasksQuery({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useGetItemTasksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetItemTasksQuery, Types.GetItemTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetItemTasksQuery, Types.GetItemTasksQueryVariables>(GetItemTasksDocument, options);
      }
export function useGetItemTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetItemTasksQuery, Types.GetItemTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetItemTasksQuery, Types.GetItemTasksQueryVariables>(GetItemTasksDocument, options);
        }
export type GetItemTasksQueryHookResult = ReturnType<typeof useGetItemTasksQuery>;
export type GetItemTasksLazyQueryHookResult = ReturnType<typeof useGetItemTasksLazyQuery>;
export type GetItemTasksQueryResult = Apollo.QueryResult<Types.GetItemTasksQuery, Types.GetItemTasksQueryVariables>;
export const GetItemDetailsDocument = gql`
    query getItemDetails($itemId: uuid!) {
  items_by_pk(id: $itemId) {
    __typename
    id
    name
    asset
    coordinates
    countermeasure
    description
    status
    node_id
    item_category {
      file {
        filepath
      }
    }
    answer_summaries(order_by: {most_recent_answered_at: desc_nulls_last}) {
      question_revision_id
      times_answered
      question_text
      most_recent_answered_at
      most_recent_response
      is_currently_deficient
      answers(order_by: {answer_details: {answered_at: desc_nulls_last}}) {
        answer_details {
          id
          is_accepted
          question {
            id
            deficient_answer
            question_text
          }
          is_deficient
          answered_at
          response
          answered_by_id
          description
          is_flagged
          question_revision_id
          answers_files {
            ...AnswersFilesIdentifiers
            file {
              id
              filepath
              original_filename
            }
          }
          partial_compliance_multiplier
        }
      }
    }
    exterior
    is_present
  }
}
    ${AnswersFilesIdentifiersFragmentDoc}`;

/**
 * __useGetItemDetailsQuery__
 *
 * To run a query within a React component, call `useGetItemDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemDetailsQuery({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useGetItemDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetItemDetailsQuery, Types.GetItemDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetItemDetailsQuery, Types.GetItemDetailsQueryVariables>(GetItemDetailsDocument, options);
      }
export function useGetItemDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetItemDetailsQuery, Types.GetItemDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetItemDetailsQuery, Types.GetItemDetailsQueryVariables>(GetItemDetailsDocument, options);
        }
export type GetItemDetailsQueryHookResult = ReturnType<typeof useGetItemDetailsQuery>;
export type GetItemDetailsLazyQueryHookResult = ReturnType<typeof useGetItemDetailsLazyQuery>;
export type GetItemDetailsQueryResult = Apollo.QueryResult<Types.GetItemDetailsQuery, Types.GetItemDetailsQueryVariables>;
export const GetItemByIdDocument = gql`
    query getItemById($id: uuid!) {
  items_by_pk(id: $id) {
    __typename
    id
    name
    created_at
    node {
      id
      name
    }
    location_description
    description
    coordinates
    status
    is_interview_item
    item_category {
      id
      name
      file {
        filepath
      }
    }
  }
}
    `;

/**
 * __useGetItemByIdQuery__
 *
 * To run a query within a React component, call `useGetItemByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetItemByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetItemByIdQuery, Types.GetItemByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetItemByIdQuery, Types.GetItemByIdQueryVariables>(GetItemByIdDocument, options);
      }
export function useGetItemByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetItemByIdQuery, Types.GetItemByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetItemByIdQuery, Types.GetItemByIdQueryVariables>(GetItemByIdDocument, options);
        }
export type GetItemByIdQueryHookResult = ReturnType<typeof useGetItemByIdQuery>;
export type GetItemByIdLazyQueryHookResult = ReturnType<typeof useGetItemByIdLazyQuery>;
export type GetItemByIdQueryResult = Apollo.QueryResult<Types.GetItemByIdQuery, Types.GetItemByIdQueryVariables>;
export const GetItemPageDetailsDocument = gql`
    query getItemPageDetails($id: uuid!) {
  items_by_pk(id: $id) {
    __typename
    id
    name
    created_at
    status
    coordinates
    location_description
    description
    is_present
    answer_aggregates {
      ...itemAnswerAggregatesKeyFields
      rollup_label
    }
    active_answers {
      ...activeAnswersKeyFields
      response
      is_deficient
      is_accepted
    }
    item_category {
      id
      name
      image_id
      file {
        filepath
      }
      propertyGroups(
        order_by: {ordinal: asc}
        where: {configs_aggregate: {count: {predicate: {_gte: 1}}}}
      ) {
        id
        ordinal
        item_category_id
        item_type
        organization_id
        description
        name
        configs(order_by: {ordinal: asc}) {
          id
          organization_id
          group_id
          ordinal
          name
          description
          kind
          options
          values(where: {item_id: {_eq: $id}}) {
            item_id
            config_id
            organization_id
            value
          }
        }
      }
    }
    node {
      id
      name
      node_mapbox_map {
        max_bounds
        style_url
      }
    }
  }
}
    ${ItemAnswerAggregatesKeyFieldsFragmentDoc}
${ActiveAnswersKeyFieldsFragmentDoc}`;

/**
 * __useGetItemPageDetailsQuery__
 *
 * To run a query within a React component, call `useGetItemPageDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemPageDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemPageDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetItemPageDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetItemPageDetailsQuery, Types.GetItemPageDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetItemPageDetailsQuery, Types.GetItemPageDetailsQueryVariables>(GetItemPageDetailsDocument, options);
      }
export function useGetItemPageDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetItemPageDetailsQuery, Types.GetItemPageDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetItemPageDetailsQuery, Types.GetItemPageDetailsQueryVariables>(GetItemPageDetailsDocument, options);
        }
export type GetItemPageDetailsQueryHookResult = ReturnType<typeof useGetItemPageDetailsQuery>;
export type GetItemPageDetailsLazyQueryHookResult = ReturnType<typeof useGetItemPageDetailsLazyQuery>;
export type GetItemPageDetailsQueryResult = Apollo.QueryResult<Types.GetItemPageDetailsQuery, Types.GetItemPageDetailsQueryVariables>;
export const GetItemsByOrgIdDocument = gql`
    query getItemsByOrgId($organizationId: uuid!) {
  items(
    where: {organization_id: {_eq: $organizationId}}
    order_by: {updated_at: desc}
  ) {
    __typename
    id
    name
    item_category {
      __typename
      id
      name
    }
    node {
      __typename
      id
      name
    }
    is_present
    is_deficient
    asset
    countermeasure
    created_at
    updated_at
  }
}
    `;

/**
 * __useGetItemsByOrgIdQuery__
 *
 * To run a query within a React component, call `useGetItemsByOrgIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemsByOrgIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemsByOrgIdQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetItemsByOrgIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetItemsByOrgIdQuery, Types.GetItemsByOrgIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetItemsByOrgIdQuery, Types.GetItemsByOrgIdQueryVariables>(GetItemsByOrgIdDocument, options);
      }
export function useGetItemsByOrgIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetItemsByOrgIdQuery, Types.GetItemsByOrgIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetItemsByOrgIdQuery, Types.GetItemsByOrgIdQueryVariables>(GetItemsByOrgIdDocument, options);
        }
export type GetItemsByOrgIdQueryHookResult = ReturnType<typeof useGetItemsByOrgIdQuery>;
export type GetItemsByOrgIdLazyQueryHookResult = ReturnType<typeof useGetItemsByOrgIdLazyQuery>;
export type GetItemsByOrgIdQueryResult = Apollo.QueryResult<Types.GetItemsByOrgIdQuery, Types.GetItemsByOrgIdQueryVariables>;
export const GetItemAndAnswersDocument = gql`
    query getItemAndAnswers($itemId: uuid!) {
  items_by_pk(id: $itemId) {
    id
    name
    location_description
    description
    is_present
    is_interview_item
    node_id
    node {
      id
      name
      layer_id
    }
    active_answers {
      ...activeAnswersKeyFields
      ...reassessableAnswer
      ...activeAnswerOptions
      question {
        id
        deficient_answer
        question_text
        standards {
          ...scenarioQuestionSetStandard
        }
      }
    }
    item_category {
      id
      name
      file {
        id
        filepath
      }
    }
    status
  }
}
    ${ActiveAnswersKeyFieldsFragmentDoc}
${ReassessableAnswerFragmentDoc}
${ActiveAnswerOptionsFragmentDoc}
${ScenarioQuestionSetStandardFragmentDoc}`;

/**
 * __useGetItemAndAnswersQuery__
 *
 * To run a query within a React component, call `useGetItemAndAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemAndAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemAndAnswersQuery({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useGetItemAndAnswersQuery(baseOptions: Apollo.QueryHookOptions<Types.GetItemAndAnswersQuery, Types.GetItemAndAnswersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetItemAndAnswersQuery, Types.GetItemAndAnswersQueryVariables>(GetItemAndAnswersDocument, options);
      }
export function useGetItemAndAnswersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetItemAndAnswersQuery, Types.GetItemAndAnswersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetItemAndAnswersQuery, Types.GetItemAndAnswersQueryVariables>(GetItemAndAnswersDocument, options);
        }
export type GetItemAndAnswersQueryHookResult = ReturnType<typeof useGetItemAndAnswersQuery>;
export type GetItemAndAnswersLazyQueryHookResult = ReturnType<typeof useGetItemAndAnswersLazyQuery>;
export type GetItemAndAnswersQueryResult = Apollo.QueryResult<Types.GetItemAndAnswersQuery, Types.GetItemAndAnswersQueryVariables>;
export const GetItemsBasedOnLocationsDocument = gql`
    query getItemsBasedOnLocations($organizationId: uuid!, $includeChildren: Boolean!, $locationIds: _uuid!, $dynamicBoolExp: items_bool_exp) {
  items: fn_get_items_by_location_ids_and_children(
    args: {org_id: $organizationId, location_ids: $locationIds, include_children: $includeChildren}
    order_by: [{name: asc}, {node: {name: asc}}]
    where: $dynamicBoolExp
  ) {
    __typename
    id
    item_category_id
    name
    is_interview_item
    node_id
    is_present
    status
    is_present
    relatedScenarios {
      id
    }
    item_category {
      __typename
      id
      name
      file {
        __typename
        id
        filepath
      }
    }
    files {
      file {
        __typename
        id
        updated_at
        filepath
        original_filename
        image {
          __typename
          id
          latest_image_path
          alternative_sizes
          markup
        }
      }
    }
    answer_aggregates {
      ...itemAnswerAggregates
    }
    ...ItemDetails
  }
}
    ${ItemAnswerAggregatesFragmentDoc}
${ItemDetailsFragmentDoc}`;

/**
 * __useGetItemsBasedOnLocationsQuery__
 *
 * To run a query within a React component, call `useGetItemsBasedOnLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemsBasedOnLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemsBasedOnLocationsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      includeChildren: // value for 'includeChildren'
 *      locationIds: // value for 'locationIds'
 *      dynamicBoolExp: // value for 'dynamicBoolExp'
 *   },
 * });
 */
export function useGetItemsBasedOnLocationsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetItemsBasedOnLocationsQuery, Types.GetItemsBasedOnLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetItemsBasedOnLocationsQuery, Types.GetItemsBasedOnLocationsQueryVariables>(GetItemsBasedOnLocationsDocument, options);
      }
export function useGetItemsBasedOnLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetItemsBasedOnLocationsQuery, Types.GetItemsBasedOnLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetItemsBasedOnLocationsQuery, Types.GetItemsBasedOnLocationsQueryVariables>(GetItemsBasedOnLocationsDocument, options);
        }
export type GetItemsBasedOnLocationsQueryHookResult = ReturnType<typeof useGetItemsBasedOnLocationsQuery>;
export type GetItemsBasedOnLocationsLazyQueryHookResult = ReturnType<typeof useGetItemsBasedOnLocationsLazyQuery>;
export type GetItemsBasedOnLocationsQueryResult = Apollo.QueryResult<Types.GetItemsBasedOnLocationsQuery, Types.GetItemsBasedOnLocationsQueryVariables>;
export const GetLayerHierarchyDocument = gql`
    query getLayerHierarchy {
  layers {
    __typename
    id
    name
    layerDepth
    parent_id
    nodes_aggregate {
      aggregate {
        count
      }
    }
    layerTags {
      __typename
      id
      type_name
      propertyGroups {
        configs_aggregate {
          aggregate {
            count
          }
        }
      }
      propertyGroups_aggregate {
        aggregate {
          count
        }
      }
    }
  }
}
    `;

/**
 * __useGetLayerHierarchyQuery__
 *
 * To run a query within a React component, call `useGetLayerHierarchyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLayerHierarchyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLayerHierarchyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLayerHierarchyQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetLayerHierarchyQuery, Types.GetLayerHierarchyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLayerHierarchyQuery, Types.GetLayerHierarchyQueryVariables>(GetLayerHierarchyDocument, options);
      }
export function useGetLayerHierarchyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLayerHierarchyQuery, Types.GetLayerHierarchyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLayerHierarchyQuery, Types.GetLayerHierarchyQueryVariables>(GetLayerHierarchyDocument, options);
        }
export type GetLayerHierarchyQueryHookResult = ReturnType<typeof useGetLayerHierarchyQuery>;
export type GetLayerHierarchyLazyQueryHookResult = ReturnType<typeof useGetLayerHierarchyLazyQuery>;
export type GetLayerHierarchyQueryResult = Apollo.QueryResult<Types.GetLayerHierarchyQuery, Types.GetLayerHierarchyQueryVariables>;
export const GetBaseLayerForScenariosDocument = gql`
    query getBaseLayerForScenarios($scenarioIds: jsonb!) {
  fn_get_layers_for_scenarios(args: {scenario_ids: $scenarioIds}) {
    organization_id
    id
    name
  }
}
    `;

/**
 * __useGetBaseLayerForScenariosQuery__
 *
 * To run a query within a React component, call `useGetBaseLayerForScenariosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseLayerForScenariosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseLayerForScenariosQuery({
 *   variables: {
 *      scenarioIds: // value for 'scenarioIds'
 *   },
 * });
 */
export function useGetBaseLayerForScenariosQuery(baseOptions: Apollo.QueryHookOptions<Types.GetBaseLayerForScenariosQuery, Types.GetBaseLayerForScenariosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetBaseLayerForScenariosQuery, Types.GetBaseLayerForScenariosQueryVariables>(GetBaseLayerForScenariosDocument, options);
      }
export function useGetBaseLayerForScenariosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBaseLayerForScenariosQuery, Types.GetBaseLayerForScenariosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetBaseLayerForScenariosQuery, Types.GetBaseLayerForScenariosQueryVariables>(GetBaseLayerForScenariosDocument, options);
        }
export type GetBaseLayerForScenariosQueryHookResult = ReturnType<typeof useGetBaseLayerForScenariosQuery>;
export type GetBaseLayerForScenariosLazyQueryHookResult = ReturnType<typeof useGetBaseLayerForScenariosLazyQuery>;
export type GetBaseLayerForScenariosQueryResult = Apollo.QueryResult<Types.GetBaseLayerForScenariosQuery, Types.GetBaseLayerForScenariosQueryVariables>;
export const GetLocationHistoryDocument = gql`
    query getLocationHistory($nodeId: uuid!) {
  location_history(
    where: {node_id: {_eq: $nodeId}}
    order_by: {occurred_at: desc}
    limit: 10
  ) {
    __typename
    id
    action
    changed_by_user_id
    created_at
    data
    entityLabel
    occurred_at
    user {
      __typename
      id
      first_name
      last_name
      email
      avatar {
        id
        filepath
      }
    }
    type
  }
}
    `;

/**
 * __useGetLocationHistoryQuery__
 *
 * To run a query within a React component, call `useGetLocationHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationHistoryQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useGetLocationHistoryQuery(baseOptions: Apollo.QueryHookOptions<Types.GetLocationHistoryQuery, Types.GetLocationHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLocationHistoryQuery, Types.GetLocationHistoryQueryVariables>(GetLocationHistoryDocument, options);
      }
export function useGetLocationHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLocationHistoryQuery, Types.GetLocationHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLocationHistoryQuery, Types.GetLocationHistoryQueryVariables>(GetLocationHistoryDocument, options);
        }
export type GetLocationHistoryQueryHookResult = ReturnType<typeof useGetLocationHistoryQuery>;
export type GetLocationHistoryLazyQueryHookResult = ReturnType<typeof useGetLocationHistoryLazyQuery>;
export type GetLocationHistoryQueryResult = Apollo.QueryResult<Types.GetLocationHistoryQuery, Types.GetLocationHistoryQueryVariables>;
export const GetLocationPropertiesDocument = gql`
    query getLocationProperties($locationId: uuid!) {
  location_layer_tag(where: {node_id: {_eq: $locationId}}) {
    ...LocationLayerTagIdentifiers
    layerTag {
      id
      type_name
      propertyGroups(order_by: {ordinal: asc}) {
        id
        name
        description
        ordinal
        configs(order_by: {ordinal: asc}) {
          id
          name
          ordinal
          options
          kind
          locationPropertyValues(where: {location_id: {_eq: $locationId}}) {
            location_id
            organization_id
            config_id
            value
          }
        }
      }
    }
  }
}
    ${LocationLayerTagIdentifiersFragmentDoc}`;

/**
 * __useGetLocationPropertiesQuery__
 *
 * To run a query within a React component, call `useGetLocationPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationPropertiesQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetLocationPropertiesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetLocationPropertiesQuery, Types.GetLocationPropertiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLocationPropertiesQuery, Types.GetLocationPropertiesQueryVariables>(GetLocationPropertiesDocument, options);
      }
export function useGetLocationPropertiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLocationPropertiesQuery, Types.GetLocationPropertiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLocationPropertiesQuery, Types.GetLocationPropertiesQueryVariables>(GetLocationPropertiesDocument, options);
        }
export type GetLocationPropertiesQueryHookResult = ReturnType<typeof useGetLocationPropertiesQuery>;
export type GetLocationPropertiesLazyQueryHookResult = ReturnType<typeof useGetLocationPropertiesLazyQuery>;
export type GetLocationPropertiesQueryResult = Apollo.QueryResult<Types.GetLocationPropertiesQuery, Types.GetLocationPropertiesQueryVariables>;
export const GetOrgPermissionsOverviewDocument = gql`
    query getOrgPermissionsOverview($organizationId: uuid!) {
  location_manager_inherited_assignments(
    where: {organization_id: {_eq: $organizationId}}
  ) {
    location_id
    location_name
    layer_name
    type
    user_id
  }
}
    `;

/**
 * __useGetOrgPermissionsOverviewQuery__
 *
 * To run a query within a React component, call `useGetOrgPermissionsOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgPermissionsOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgPermissionsOverviewQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetOrgPermissionsOverviewQuery(baseOptions: Apollo.QueryHookOptions<Types.GetOrgPermissionsOverviewQuery, Types.GetOrgPermissionsOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetOrgPermissionsOverviewQuery, Types.GetOrgPermissionsOverviewQueryVariables>(GetOrgPermissionsOverviewDocument, options);
      }
export function useGetOrgPermissionsOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetOrgPermissionsOverviewQuery, Types.GetOrgPermissionsOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetOrgPermissionsOverviewQuery, Types.GetOrgPermissionsOverviewQueryVariables>(GetOrgPermissionsOverviewDocument, options);
        }
export type GetOrgPermissionsOverviewQueryHookResult = ReturnType<typeof useGetOrgPermissionsOverviewQuery>;
export type GetOrgPermissionsOverviewLazyQueryHookResult = ReturnType<typeof useGetOrgPermissionsOverviewLazyQuery>;
export type GetOrgPermissionsOverviewQueryResult = Apollo.QueryResult<Types.GetOrgPermissionsOverviewQuery, Types.GetOrgPermissionsOverviewQueryVariables>;
export const GetUpdatedLocationsDocument = gql`
    query getUpdatedLocations($organizationId: uuid!, $updatedSince: timestamptz!) {
  nodes(
    where: {_and: [{organization_id: {_eq: $organizationId}}, {updated_at: {_gte: $updatedSince}}]}
  ) {
    ...globalContextNodeInfo
  }
}
    ${GlobalContextNodeInfoFragmentDoc}`;

/**
 * __useGetUpdatedLocationsQuery__
 *
 * To run a query within a React component, call `useGetUpdatedLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpdatedLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpdatedLocationsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      updatedSince: // value for 'updatedSince'
 *   },
 * });
 */
export function useGetUpdatedLocationsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUpdatedLocationsQuery, Types.GetUpdatedLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUpdatedLocationsQuery, Types.GetUpdatedLocationsQueryVariables>(GetUpdatedLocationsDocument, options);
      }
export function useGetUpdatedLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUpdatedLocationsQuery, Types.GetUpdatedLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUpdatedLocationsQuery, Types.GetUpdatedLocationsQueryVariables>(GetUpdatedLocationsDocument, options);
        }
export type GetUpdatedLocationsQueryHookResult = ReturnType<typeof useGetUpdatedLocationsQuery>;
export type GetUpdatedLocationsLazyQueryHookResult = ReturnType<typeof useGetUpdatedLocationsLazyQuery>;
export type GetUpdatedLocationsQueryResult = Apollo.QueryResult<Types.GetUpdatedLocationsQuery, Types.GetUpdatedLocationsQueryVariables>;
export const GetNodeDetailsByIdDocument = gql`
    query getNodeDetailsById($nodeId: uuid!) {
  nodes_by_pk(id: $nodeId) {
    id
    address
    layer_id
    parent_id
    created_at
    nodes_files_aggregate(where: {file_type: {_eq: generic}}) {
      aggregate {
        count
      }
    }
    mainPhoto: nodes_files(where: {file_type: {_eq: display_photo}}) {
      file {
        id
        filepath
      }
    }
    areaTags_aggregate(where: {is_present: {_eq: true}}) {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetNodeDetailsByIdQuery__
 *
 * To run a query within a React component, call `useGetNodeDetailsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNodeDetailsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNodeDetailsByIdQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useGetNodeDetailsByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetNodeDetailsByIdQuery, Types.GetNodeDetailsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetNodeDetailsByIdQuery, Types.GetNodeDetailsByIdQueryVariables>(GetNodeDetailsByIdDocument, options);
      }
export function useGetNodeDetailsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetNodeDetailsByIdQuery, Types.GetNodeDetailsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetNodeDetailsByIdQuery, Types.GetNodeDetailsByIdQueryVariables>(GetNodeDetailsByIdDocument, options);
        }
export type GetNodeDetailsByIdQueryHookResult = ReturnType<typeof useGetNodeDetailsByIdQuery>;
export type GetNodeDetailsByIdLazyQueryHookResult = ReturnType<typeof useGetNodeDetailsByIdLazyQuery>;
export type GetNodeDetailsByIdQueryResult = Apollo.QueryResult<Types.GetNodeDetailsByIdQuery, Types.GetNodeDetailsByIdQueryVariables>;
export const FindQualifiedChildrenDocument = gql`
    query findQualifiedChildren($organizationId: uuid!, $mpathCompare: String!, $layerTags: [Int!], $templateId: Int!) {
  nodes(
    where: {organization_id: {_eq: $organizationId}, layerTags: {layer_tag_id: {_in: $layerTags}}, mpath: {_ilike: $mpathCompare}}
  ) {
    id
    scenarioAssessmentNodes(
      where: {assessment: {completed_at: {_is_null: false}, assessment_template_id: {_eq: $templateId}}}
      order_by: {assessment: {completed_at: desc}}
    ) {
      organization_id
      node_id
      assessment_id
      assessment {
        id
        organization_id
        completed_at
        assessment_template_id
      }
    }
    node_mapbox_map {
      id
    }
    templateSummaryInfo(args: {assessment_template_id: $templateId}) {
      inspection_questionnaire_item_categories_count
      inspection_questionnaire_properties_count
      inspection_questionnaire_questions_count
      physical_inspection_item_categories_count
      physical_inspection_properties_count
      physical_inspection_questions_count
    }
  }
}
    `;

/**
 * __useFindQualifiedChildrenQuery__
 *
 * To run a query within a React component, call `useFindQualifiedChildrenQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindQualifiedChildrenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindQualifiedChildrenQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      mpathCompare: // value for 'mpathCompare'
 *      layerTags: // value for 'layerTags'
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useFindQualifiedChildrenQuery(baseOptions: Apollo.QueryHookOptions<Types.FindQualifiedChildrenQuery, Types.FindQualifiedChildrenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FindQualifiedChildrenQuery, Types.FindQualifiedChildrenQueryVariables>(FindQualifiedChildrenDocument, options);
      }
export function useFindQualifiedChildrenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FindQualifiedChildrenQuery, Types.FindQualifiedChildrenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FindQualifiedChildrenQuery, Types.FindQualifiedChildrenQueryVariables>(FindQualifiedChildrenDocument, options);
        }
export type FindQualifiedChildrenQueryHookResult = ReturnType<typeof useFindQualifiedChildrenQuery>;
export type FindQualifiedChildrenLazyQueryHookResult = ReturnType<typeof useFindQualifiedChildrenLazyQuery>;
export type FindQualifiedChildrenQueryResult = Apollo.QueryResult<Types.FindQualifiedChildrenQuery, Types.FindQualifiedChildrenQueryVariables>;
export const GetNodesForScenarioAssessmentCreationDocument = gql`
    query getNodesForScenarioAssessmentCreation($organizationId: uuid!, $scoredLayerIds: [uuid!]!) {
  nodes(
    where: {organization_id: {_eq: $organizationId}, layer_id: {_in: $scoredLayerIds}}
  ) {
    id
    name
    layerTags {
      layer_id
      layer_tag_id
      organization_id
      node_id
    }
  }
}
    `;

/**
 * __useGetNodesForScenarioAssessmentCreationQuery__
 *
 * To run a query within a React component, call `useGetNodesForScenarioAssessmentCreationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNodesForScenarioAssessmentCreationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNodesForScenarioAssessmentCreationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      scoredLayerIds: // value for 'scoredLayerIds'
 *   },
 * });
 */
export function useGetNodesForScenarioAssessmentCreationQuery(baseOptions: Apollo.QueryHookOptions<Types.GetNodesForScenarioAssessmentCreationQuery, Types.GetNodesForScenarioAssessmentCreationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetNodesForScenarioAssessmentCreationQuery, Types.GetNodesForScenarioAssessmentCreationQueryVariables>(GetNodesForScenarioAssessmentCreationDocument, options);
      }
export function useGetNodesForScenarioAssessmentCreationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetNodesForScenarioAssessmentCreationQuery, Types.GetNodesForScenarioAssessmentCreationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetNodesForScenarioAssessmentCreationQuery, Types.GetNodesForScenarioAssessmentCreationQueryVariables>(GetNodesForScenarioAssessmentCreationDocument, options);
        }
export type GetNodesForScenarioAssessmentCreationQueryHookResult = ReturnType<typeof useGetNodesForScenarioAssessmentCreationQuery>;
export type GetNodesForScenarioAssessmentCreationLazyQueryHookResult = ReturnType<typeof useGetNodesForScenarioAssessmentCreationLazyQuery>;
export type GetNodesForScenarioAssessmentCreationQueryResult = Apollo.QueryResult<Types.GetNodesForScenarioAssessmentCreationQuery, Types.GetNodesForScenarioAssessmentCreationQueryVariables>;
export const GetLocationItemsDocument = gql`
    query getLocationItems($nodeId: uuid!) {
  nodes_by_pk(id: $nodeId) {
    id
    items(where: {is_interview_item: {_eq: false}, coordinates: {_is_null: false}}) {
      id
      name
      coordinates
      is_deficient
      is_present
      status
      item_category {
        id
        name
        file {
          filepath
        }
      }
    }
  }
}
    `;

/**
 * __useGetLocationItemsQuery__
 *
 * To run a query within a React component, call `useGetLocationItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationItemsQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useGetLocationItemsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetLocationItemsQuery, Types.GetLocationItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLocationItemsQuery, Types.GetLocationItemsQueryVariables>(GetLocationItemsDocument, options);
      }
export function useGetLocationItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLocationItemsQuery, Types.GetLocationItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLocationItemsQuery, Types.GetLocationItemsQueryVariables>(GetLocationItemsDocument, options);
        }
export type GetLocationItemsQueryHookResult = ReturnType<typeof useGetLocationItemsQuery>;
export type GetLocationItemsLazyQueryHookResult = ReturnType<typeof useGetLocationItemsLazyQuery>;
export type GetLocationItemsQueryResult = Apollo.QueryResult<Types.GetLocationItemsQuery, Types.GetLocationItemsQueryVariables>;
export const GetNodeScenarioSummariesCountDocument = gql`
    query getNodeScenarioSummariesCount($nodeId: uuid!) {
  nodes_by_pk(id: $nodeId) {
    id
    scenarioSummaries_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetNodeScenarioSummariesCountQuery__
 *
 * To run a query within a React component, call `useGetNodeScenarioSummariesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNodeScenarioSummariesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNodeScenarioSummariesCountQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useGetNodeScenarioSummariesCountQuery(baseOptions: Apollo.QueryHookOptions<Types.GetNodeScenarioSummariesCountQuery, Types.GetNodeScenarioSummariesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetNodeScenarioSummariesCountQuery, Types.GetNodeScenarioSummariesCountQueryVariables>(GetNodeScenarioSummariesCountDocument, options);
      }
export function useGetNodeScenarioSummariesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetNodeScenarioSummariesCountQuery, Types.GetNodeScenarioSummariesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetNodeScenarioSummariesCountQuery, Types.GetNodeScenarioSummariesCountQueryVariables>(GetNodeScenarioSummariesCountDocument, options);
        }
export type GetNodeScenarioSummariesCountQueryHookResult = ReturnType<typeof useGetNodeScenarioSummariesCountQuery>;
export type GetNodeScenarioSummariesCountLazyQueryHookResult = ReturnType<typeof useGetNodeScenarioSummariesCountLazyQuery>;
export type GetNodeScenarioSummariesCountQueryResult = Apollo.QueryResult<Types.GetNodeScenarioSummariesCountQuery, Types.GetNodeScenarioSummariesCountQueryVariables>;
export const GetNodeAreaTagsDocument = gql`
    query getNodeAreaTags($nodeId: uuid!) {
  nodes_by_pk(id: $nodeId) {
    id
    allAreaTags {
      area_tag_id
      is_present
      node_id
      tag_name
    }
  }
}
    `;

/**
 * __useGetNodeAreaTagsQuery__
 *
 * To run a query within a React component, call `useGetNodeAreaTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNodeAreaTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNodeAreaTagsQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useGetNodeAreaTagsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetNodeAreaTagsQuery, Types.GetNodeAreaTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetNodeAreaTagsQuery, Types.GetNodeAreaTagsQueryVariables>(GetNodeAreaTagsDocument, options);
      }
export function useGetNodeAreaTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetNodeAreaTagsQuery, Types.GetNodeAreaTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetNodeAreaTagsQuery, Types.GetNodeAreaTagsQueryVariables>(GetNodeAreaTagsDocument, options);
        }
export type GetNodeAreaTagsQueryHookResult = ReturnType<typeof useGetNodeAreaTagsQuery>;
export type GetNodeAreaTagsLazyQueryHookResult = ReturnType<typeof useGetNodeAreaTagsLazyQuery>;
export type GetNodeAreaTagsQueryResult = Apollo.QueryResult<Types.GetNodeAreaTagsQuery, Types.GetNodeAreaTagsQueryVariables>;
export const GetLocationDeficienciesDocument = gql`
    query getLocationDeficiencies($nodeId: uuid!) {
  nodes_by_pk(id: $nodeId) {
    id
    deficiencies {
      criticality_value
      total_cost
      count
    }
  }
}
    `;

/**
 * __useGetLocationDeficienciesQuery__
 *
 * To run a query within a React component, call `useGetLocationDeficienciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationDeficienciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationDeficienciesQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useGetLocationDeficienciesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetLocationDeficienciesQuery, Types.GetLocationDeficienciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLocationDeficienciesQuery, Types.GetLocationDeficienciesQueryVariables>(GetLocationDeficienciesDocument, options);
      }
export function useGetLocationDeficienciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLocationDeficienciesQuery, Types.GetLocationDeficienciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLocationDeficienciesQuery, Types.GetLocationDeficienciesQueryVariables>(GetLocationDeficienciesDocument, options);
        }
export type GetLocationDeficienciesQueryHookResult = ReturnType<typeof useGetLocationDeficienciesQuery>;
export type GetLocationDeficienciesLazyQueryHookResult = ReturnType<typeof useGetLocationDeficienciesLazyQuery>;
export type GetLocationDeficienciesQueryResult = Apollo.QueryResult<Types.GetLocationDeficienciesQuery, Types.GetLocationDeficienciesQueryVariables>;
export const GetAnswersForLocationsDocument = gql`
    query getAnswersForLocations($locationIds: [uuid!]!, $includeChildren: Boolean!, $includeRelatedTasks: Boolean!) {
  nodes(where: {id: {_in: $locationIds}}) {
    answers(args: {include_children: $includeChildren}) {
      ...locationAnswers
      action_deficiencies @include(if: $includeRelatedTasks) {
        task_action {
          ...TaskActionIdentifiers
          task {
            ...TaskIdentifiers
            name
            status
          }
        }
      }
    }
  }
}
    ${LocationAnswersFragmentDoc}
${TaskActionIdentifiersFragmentDoc}
${TaskIdentifiersFragmentDoc}`;

/**
 * __useGetAnswersForLocationsQuery__
 *
 * To run a query within a React component, call `useGetAnswersForLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnswersForLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnswersForLocationsQuery({
 *   variables: {
 *      locationIds: // value for 'locationIds'
 *      includeChildren: // value for 'includeChildren'
 *      includeRelatedTasks: // value for 'includeRelatedTasks'
 *   },
 * });
 */
export function useGetAnswersForLocationsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAnswersForLocationsQuery, Types.GetAnswersForLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAnswersForLocationsQuery, Types.GetAnswersForLocationsQueryVariables>(GetAnswersForLocationsDocument, options);
      }
export function useGetAnswersForLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAnswersForLocationsQuery, Types.GetAnswersForLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAnswersForLocationsQuery, Types.GetAnswersForLocationsQueryVariables>(GetAnswersForLocationsDocument, options);
        }
export type GetAnswersForLocationsQueryHookResult = ReturnType<typeof useGetAnswersForLocationsQuery>;
export type GetAnswersForLocationsLazyQueryHookResult = ReturnType<typeof useGetAnswersForLocationsLazyQuery>;
export type GetAnswersForLocationsQueryResult = Apollo.QueryResult<Types.GetAnswersForLocationsQuery, Types.GetAnswersForLocationsQueryVariables>;
export const FindNodesDocument = gql`
    query findNodes($where: nodes_bool_exp!, $includeDescendants: Boolean!) {
  nodes(where: $where) {
    id
    descendants @include(if: $includeDescendants) {
      id
    }
  }
}
    `;

/**
 * __useFindNodesQuery__
 *
 * To run a query within a React component, call `useFindNodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindNodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindNodesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      includeDescendants: // value for 'includeDescendants'
 *   },
 * });
 */
export function useFindNodesQuery(baseOptions: Apollo.QueryHookOptions<Types.FindNodesQuery, Types.FindNodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FindNodesQuery, Types.FindNodesQueryVariables>(FindNodesDocument, options);
      }
export function useFindNodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FindNodesQuery, Types.FindNodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FindNodesQuery, Types.FindNodesQueryVariables>(FindNodesDocument, options);
        }
export type FindNodesQueryHookResult = ReturnType<typeof useFindNodesQuery>;
export type FindNodesLazyQueryHookResult = ReturnType<typeof useFindNodesLazyQuery>;
export type FindNodesQueryResult = Apollo.QueryResult<Types.FindNodesQuery, Types.FindNodesQueryVariables>;
export const GetLocationScenarioSummariesDocument = gql`
    query getLocationScenarioSummaries($organizationId: uuid!, $locationId: uuid!) {
  nodes(where: {organization_id: {_eq: $organizationId}, id: {_eq: $locationId}}) {
    organization {
      scenarioTypeInfo(order_by: {scenario_type: asc}) {
        scenario_type
        upper_threshold
        lower_threshold
        locationScenarios(where: {node_id: {_eq: $locationId}}) {
          organization_id
          max_score
          node_id
          scenario_id
          scenario_type
          scenario_name
          lower_threshold
          upper_threshold
          inherent_risk
          completed_question_percentage
          control_percentage
          residual_score
          is_complete
          completed_impact_values_count
          completed_probability_values_count
          total_impact_measures_count
          total_probability_measures_count
          impact_values_sum
          probability_values_sum
        }
      }
    }
    scenarioSummaries(order_by: {scenario_type: asc}) {
      scenario_id
      scenario {
        id
        upper_threshold
        lower_threshold
        description
        probabilityMeasures: inherentRiskMeasures(
          where: {type: {_eq: probability}}
          order_by: {name: asc}
        ) {
          ...scenarioMeasure
        }
        impactMeasures: inherentRiskMeasures(
          where: {type: {_eq: severity}}
          order_by: {name: asc}
        ) {
          ...scenarioMeasure
        }
        primaryQuestionSets {
          scenario_id
          scenario_question_set_id
          questionSet {
            id
            assessmentTemplates(order_by: {created_at: desc}) {
              assessmentTemplate {
                id
                name
                assessments(
                  where: {locations: {node_id: {_eq: $locationId}}}
                  order_by: {created_at: desc}
                ) {
                  id
                  name
                  created_at
                  state
                  start_date
                  due_date
                  completed_at
                  users_aggregate {
                    aggregate {
                      count
                    }
                  }
                }
              }
            }
          }
        }
      }
      probabilityValues {
        probabilityMeasure {
          id
          name
        }
        value
        label {
          name
        }
      }
      impactValues {
        impactMeasure {
          id
          name
        }
        value
        label {
          name
        }
      }
    }
  }
}
    ${ScenarioMeasureFragmentDoc}`;

/**
 * __useGetLocationScenarioSummariesQuery__
 *
 * To run a query within a React component, call `useGetLocationScenarioSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationScenarioSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationScenarioSummariesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetLocationScenarioSummariesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetLocationScenarioSummariesQuery, Types.GetLocationScenarioSummariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLocationScenarioSummariesQuery, Types.GetLocationScenarioSummariesQueryVariables>(GetLocationScenarioSummariesDocument, options);
      }
export function useGetLocationScenarioSummariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLocationScenarioSummariesQuery, Types.GetLocationScenarioSummariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLocationScenarioSummariesQuery, Types.GetLocationScenarioSummariesQueryVariables>(GetLocationScenarioSummariesDocument, options);
        }
export type GetLocationScenarioSummariesQueryHookResult = ReturnType<typeof useGetLocationScenarioSummariesQuery>;
export type GetLocationScenarioSummariesLazyQueryHookResult = ReturnType<typeof useGetLocationScenarioSummariesLazyQuery>;
export type GetLocationScenarioSummariesQueryResult = Apollo.QueryResult<Types.GetLocationScenarioSummariesQuery, Types.GetLocationScenarioSummariesQueryVariables>;
export const GetLocationProjectDocument = gql`
    query getLocationProject($locationId: uuid!) {
  nodes_by_pk(id: $locationId) {
    ...LocationProjects
  }
}
    ${LocationProjectsFragmentDoc}`;

/**
 * __useGetLocationProjectQuery__
 *
 * To run a query within a React component, call `useGetLocationProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationProjectQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetLocationProjectQuery(baseOptions: Apollo.QueryHookOptions<Types.GetLocationProjectQuery, Types.GetLocationProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLocationProjectQuery, Types.GetLocationProjectQueryVariables>(GetLocationProjectDocument, options);
      }
export function useGetLocationProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLocationProjectQuery, Types.GetLocationProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLocationProjectQuery, Types.GetLocationProjectQueryVariables>(GetLocationProjectDocument, options);
        }
export type GetLocationProjectQueryHookResult = ReturnType<typeof useGetLocationProjectQuery>;
export type GetLocationProjectLazyQueryHookResult = ReturnType<typeof useGetLocationProjectLazyQuery>;
export type GetLocationProjectQueryResult = Apollo.QueryResult<Types.GetLocationProjectQuery, Types.GetLocationProjectQueryVariables>;
export const GetOrganizationAuthStrategiesDocument = gql`
    query getOrganizationAuthStrategies($organizationId: uuid!) {
  organization_auth_strategy(where: {organization_id: {_eq: $organizationId}}) {
    ...OrganizationAuthStrategyIdentifiers
    strategy_name
    organization_id
  }
}
    ${OrganizationAuthStrategyIdentifiersFragmentDoc}`;

/**
 * __useGetOrganizationAuthStrategiesQuery__
 *
 * To run a query within a React component, call `useGetOrganizationAuthStrategiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationAuthStrategiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationAuthStrategiesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetOrganizationAuthStrategiesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetOrganizationAuthStrategiesQuery, Types.GetOrganizationAuthStrategiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetOrganizationAuthStrategiesQuery, Types.GetOrganizationAuthStrategiesQueryVariables>(GetOrganizationAuthStrategiesDocument, options);
      }
export function useGetOrganizationAuthStrategiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetOrganizationAuthStrategiesQuery, Types.GetOrganizationAuthStrategiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetOrganizationAuthStrategiesQuery, Types.GetOrganizationAuthStrategiesQueryVariables>(GetOrganizationAuthStrategiesDocument, options);
        }
export type GetOrganizationAuthStrategiesQueryHookResult = ReturnType<typeof useGetOrganizationAuthStrategiesQuery>;
export type GetOrganizationAuthStrategiesLazyQueryHookResult = ReturnType<typeof useGetOrganizationAuthStrategiesLazyQuery>;
export type GetOrganizationAuthStrategiesQueryResult = Apollo.QueryResult<Types.GetOrganizationAuthStrategiesQuery, Types.GetOrganizationAuthStrategiesQueryVariables>;
export const GetAllItemCategoriesDocument = gql`
    query getAllItemCategories($organizationId: uuid!) {
  organizations_by_pk(id: $organizationId) {
    id
    item_categories {
      ...ItemCategory
    }
  }
}
    ${ItemCategoryFragmentDoc}`;

/**
 * __useGetAllItemCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAllItemCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllItemCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllItemCategoriesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetAllItemCategoriesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAllItemCategoriesQuery, Types.GetAllItemCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAllItemCategoriesQuery, Types.GetAllItemCategoriesQueryVariables>(GetAllItemCategoriesDocument, options);
      }
export function useGetAllItemCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllItemCategoriesQuery, Types.GetAllItemCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAllItemCategoriesQuery, Types.GetAllItemCategoriesQueryVariables>(GetAllItemCategoriesDocument, options);
        }
export type GetAllItemCategoriesQueryHookResult = ReturnType<typeof useGetAllItemCategoriesQuery>;
export type GetAllItemCategoriesLazyQueryHookResult = ReturnType<typeof useGetAllItemCategoriesLazyQuery>;
export type GetAllItemCategoriesQueryResult = Apollo.QueryResult<Types.GetAllItemCategoriesQuery, Types.GetAllItemCategoriesQueryVariables>;
export const GetAllLayerTagsDocument = gql`
    query getAllLayerTags($organizationId: uuid!) {
  organizations_by_pk(id: $organizationId) {
    id
    layerTags {
      id
      layer_id
      type_name
      layer {
        name
      }
    }
  }
}
    `;

/**
 * __useGetAllLayerTagsQuery__
 *
 * To run a query within a React component, call `useGetAllLayerTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllLayerTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllLayerTagsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetAllLayerTagsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAllLayerTagsQuery, Types.GetAllLayerTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAllLayerTagsQuery, Types.GetAllLayerTagsQueryVariables>(GetAllLayerTagsDocument, options);
      }
export function useGetAllLayerTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllLayerTagsQuery, Types.GetAllLayerTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAllLayerTagsQuery, Types.GetAllLayerTagsQueryVariables>(GetAllLayerTagsDocument, options);
        }
export type GetAllLayerTagsQueryHookResult = ReturnType<typeof useGetAllLayerTagsQuery>;
export type GetAllLayerTagsLazyQueryHookResult = ReturnType<typeof useGetAllLayerTagsLazyQuery>;
export type GetAllLayerTagsQueryResult = Apollo.QueryResult<Types.GetAllLayerTagsQuery, Types.GetAllLayerTagsQueryVariables>;
export const GetAllQuestionsDocument = gql`
    query getAllQuestions($organizationId: uuid!) {
  organizations_by_pk(id: $organizationId) {
    id
    questions {
      ...Question
    }
  }
}
    ${QuestionFragmentDoc}`;

/**
 * __useGetAllQuestionsQuery__
 *
 * To run a query within a React component, call `useGetAllQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllQuestionsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetAllQuestionsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAllQuestionsQuery, Types.GetAllQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAllQuestionsQuery, Types.GetAllQuestionsQueryVariables>(GetAllQuestionsDocument, options);
      }
export function useGetAllQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllQuestionsQuery, Types.GetAllQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAllQuestionsQuery, Types.GetAllQuestionsQueryVariables>(GetAllQuestionsDocument, options);
        }
export type GetAllQuestionsQueryHookResult = ReturnType<typeof useGetAllQuestionsQuery>;
export type GetAllQuestionsLazyQueryHookResult = ReturnType<typeof useGetAllQuestionsLazyQuery>;
export type GetAllQuestionsQueryResult = Apollo.QueryResult<Types.GetAllQuestionsQuery, Types.GetAllQuestionsQueryVariables>;
export const GetOrganizationScenarioMeasuresDocument = gql`
    query getOrganizationScenarioMeasures($organizationId: uuid!) {
  organizations_by_pk(id: $organizationId) {
    scenarioTypeInfo {
      scenario_type
      max_score
      probabilityMeasures(order_by: {name: asc}) {
        id
        organization_id
        scenario_type
        name
        description
        min_value
        max_value
        is_user_supplied_numeric
        valueLabels {
          name
          value
        }
      }
      severityMeasures(order_by: {name: asc}) {
        id
        organization_id
        scenario_type
        name
        description
        min_value
        max_value
        is_user_supplied_numeric
        valueLabels(order_by: {value: asc}) {
          name
          value
        }
      }
    }
  }
}
    `;

/**
 * __useGetOrganizationScenarioMeasuresQuery__
 *
 * To run a query within a React component, call `useGetOrganizationScenarioMeasuresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationScenarioMeasuresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationScenarioMeasuresQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetOrganizationScenarioMeasuresQuery(baseOptions: Apollo.QueryHookOptions<Types.GetOrganizationScenarioMeasuresQuery, Types.GetOrganizationScenarioMeasuresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetOrganizationScenarioMeasuresQuery, Types.GetOrganizationScenarioMeasuresQueryVariables>(GetOrganizationScenarioMeasuresDocument, options);
      }
export function useGetOrganizationScenarioMeasuresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetOrganizationScenarioMeasuresQuery, Types.GetOrganizationScenarioMeasuresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetOrganizationScenarioMeasuresQuery, Types.GetOrganizationScenarioMeasuresQueryVariables>(GetOrganizationScenarioMeasuresDocument, options);
        }
export type GetOrganizationScenarioMeasuresQueryHookResult = ReturnType<typeof useGetOrganizationScenarioMeasuresQuery>;
export type GetOrganizationScenarioMeasuresLazyQueryHookResult = ReturnType<typeof useGetOrganizationScenarioMeasuresLazyQuery>;
export type GetOrganizationScenarioMeasuresQueryResult = Apollo.QueryResult<Types.GetOrganizationScenarioMeasuresQuery, Types.GetOrganizationScenarioMeasuresQueryVariables>;
export const GetOrganizationNamesAndIdsDocument = gql`
    query getOrganizationNamesAndIds {
  organizations {
    ...OrganizationNamesAndIds
  }
}
    ${OrganizationNamesAndIdsFragmentDoc}`;

/**
 * __useGetOrganizationNamesAndIdsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationNamesAndIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationNamesAndIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationNamesAndIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationNamesAndIdsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetOrganizationNamesAndIdsQuery, Types.GetOrganizationNamesAndIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetOrganizationNamesAndIdsQuery, Types.GetOrganizationNamesAndIdsQueryVariables>(GetOrganizationNamesAndIdsDocument, options);
      }
export function useGetOrganizationNamesAndIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetOrganizationNamesAndIdsQuery, Types.GetOrganizationNamesAndIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetOrganizationNamesAndIdsQuery, Types.GetOrganizationNamesAndIdsQueryVariables>(GetOrganizationNamesAndIdsDocument, options);
        }
export type GetOrganizationNamesAndIdsQueryHookResult = ReturnType<typeof useGetOrganizationNamesAndIdsQuery>;
export type GetOrganizationNamesAndIdsLazyQueryHookResult = ReturnType<typeof useGetOrganizationNamesAndIdsLazyQuery>;
export type GetOrganizationNamesAndIdsQueryResult = Apollo.QueryResult<Types.GetOrganizationNamesAndIdsQuery, Types.GetOrganizationNamesAndIdsQueryVariables>;
export const GetOrganizationByIdDocument = gql`
    query getOrganizationById($id: uuid!) {
  organizations_by_pk(id: $id) {
    id
    name
    address
    createdAt: created_at
    updatedAt: updated_at
    logo {
      filepath
    }
    theme_overrides {
      id
      primary_color
      dark_logo_id
      light_logo_id
      organization_colors
      dark_logo {
        ...themeOverrideFileFragment
      }
      light_logo {
        ...themeOverrideFileFragment
      }
      address
      email_support_address
      email_logo_id
      email_logo {
        ...themeOverrideFileFragment
      }
      phone
      social_media_twitter_url
      social_media_facebook_url
      social_media_linkedin_url
    }
  }
}
    ${ThemeOverrideFileFragmentFragmentDoc}`;

/**
 * __useGetOrganizationByIdQuery__
 *
 * To run a query within a React component, call `useGetOrganizationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetOrganizationByIdQuery, Types.GetOrganizationByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetOrganizationByIdQuery, Types.GetOrganizationByIdQueryVariables>(GetOrganizationByIdDocument, options);
      }
export function useGetOrganizationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetOrganizationByIdQuery, Types.GetOrganizationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetOrganizationByIdQuery, Types.GetOrganizationByIdQueryVariables>(GetOrganizationByIdDocument, options);
        }
export type GetOrganizationByIdQueryHookResult = ReturnType<typeof useGetOrganizationByIdQuery>;
export type GetOrganizationByIdLazyQueryHookResult = ReturnType<typeof useGetOrganizationByIdLazyQuery>;
export type GetOrganizationByIdQueryResult = Apollo.QueryResult<Types.GetOrganizationByIdQuery, Types.GetOrganizationByIdQueryVariables>;
export const GetAllItemCategoriesAndQuestionsDocument = gql`
    query getAllItemCategoriesAndQuestions($organizationId: uuid!) {
  organizations_by_pk(id: $organizationId) {
    ...OrganizationItemCategoriesAndQuestions
  }
}
    ${OrganizationItemCategoriesAndQuestionsFragmentDoc}`;

/**
 * __useGetAllItemCategoriesAndQuestionsQuery__
 *
 * To run a query within a React component, call `useGetAllItemCategoriesAndQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllItemCategoriesAndQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllItemCategoriesAndQuestionsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetAllItemCategoriesAndQuestionsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAllItemCategoriesAndQuestionsQuery, Types.GetAllItemCategoriesAndQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAllItemCategoriesAndQuestionsQuery, Types.GetAllItemCategoriesAndQuestionsQueryVariables>(GetAllItemCategoriesAndQuestionsDocument, options);
      }
export function useGetAllItemCategoriesAndQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllItemCategoriesAndQuestionsQuery, Types.GetAllItemCategoriesAndQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAllItemCategoriesAndQuestionsQuery, Types.GetAllItemCategoriesAndQuestionsQueryVariables>(GetAllItemCategoriesAndQuestionsDocument, options);
        }
export type GetAllItemCategoriesAndQuestionsQueryHookResult = ReturnType<typeof useGetAllItemCategoriesAndQuestionsQuery>;
export type GetAllItemCategoriesAndQuestionsLazyQueryHookResult = ReturnType<typeof useGetAllItemCategoriesAndQuestionsLazyQuery>;
export type GetAllItemCategoriesAndQuestionsQueryResult = Apollo.QueryResult<Types.GetAllItemCategoriesAndQuestionsQuery, Types.GetAllItemCategoriesAndQuestionsQueryVariables>;
export const GetProjectsForTableDocument = gql`
    query getProjectsForTable($organization_id: uuid!) {
  organizations_by_pk(id: $organization_id) {
    __typename
    id
    projects {
      ...ProjectTableRow
    }
  }
}
    ${ProjectTableRowFragmentDoc}`;

/**
 * __useGetProjectsForTableQuery__
 *
 * To run a query within a React component, call `useGetProjectsForTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsForTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsForTableQuery({
 *   variables: {
 *      organization_id: // value for 'organization_id'
 *   },
 * });
 */
export function useGetProjectsForTableQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProjectsForTableQuery, Types.GetProjectsForTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProjectsForTableQuery, Types.GetProjectsForTableQueryVariables>(GetProjectsForTableDocument, options);
      }
export function useGetProjectsForTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProjectsForTableQuery, Types.GetProjectsForTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProjectsForTableQuery, Types.GetProjectsForTableQueryVariables>(GetProjectsForTableDocument, options);
        }
export type GetProjectsForTableQueryHookResult = ReturnType<typeof useGetProjectsForTableQuery>;
export type GetProjectsForTableLazyQueryHookResult = ReturnType<typeof useGetProjectsForTableLazyQuery>;
export type GetProjectsForTableQueryResult = Apollo.QueryResult<Types.GetProjectsForTableQuery, Types.GetProjectsForTableQueryVariables>;
export const GetBasicProjectDocument = gql`
    query getBasicProject($organizationId: uuid!, $projectId: uuid!) {
  projects_by_pk(id: $projectId, organization_id: $organizationId) {
    ...BasicProject
  }
}
    ${BasicProjectFragmentDoc}`;

/**
 * __useGetBasicProjectQuery__
 *
 * To run a query within a React component, call `useGetBasicProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBasicProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBasicProjectQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetBasicProjectQuery(baseOptions: Apollo.QueryHookOptions<Types.GetBasicProjectQuery, Types.GetBasicProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetBasicProjectQuery, Types.GetBasicProjectQueryVariables>(GetBasicProjectDocument, options);
      }
export function useGetBasicProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBasicProjectQuery, Types.GetBasicProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetBasicProjectQuery, Types.GetBasicProjectQueryVariables>(GetBasicProjectDocument, options);
        }
export type GetBasicProjectQueryHookResult = ReturnType<typeof useGetBasicProjectQuery>;
export type GetBasicProjectLazyQueryHookResult = ReturnType<typeof useGetBasicProjectLazyQuery>;
export type GetBasicProjectQueryResult = Apollo.QueryResult<Types.GetBasicProjectQuery, Types.GetBasicProjectQueryVariables>;
export const GetQuestionSetsAggregateByOrgIdDocument = gql`
    query getQuestionSetsAggregateByOrgId($organizationId: uuid!) {
  question_sets(where: {organization_id: {_eq: $organizationId}}) {
    __typename
    name
    id
    created_at
    itemCategoriesCount
    inspection_questions: question_sets_questions_aggregate(
      where: {question: {question_type: {_eq: "inspection"}}}
    ) {
      aggregate {
        count
      }
    }
    interview_questions: question_sets_questions_aggregate(
      where: {question: {question_type: {_eq: "interview"}}}
    ) {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetQuestionSetsAggregateByOrgIdQuery__
 *
 * To run a query within a React component, call `useGetQuestionSetsAggregateByOrgIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionSetsAggregateByOrgIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionSetsAggregateByOrgIdQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetQuestionSetsAggregateByOrgIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetQuestionSetsAggregateByOrgIdQuery, Types.GetQuestionSetsAggregateByOrgIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetQuestionSetsAggregateByOrgIdQuery, Types.GetQuestionSetsAggregateByOrgIdQueryVariables>(GetQuestionSetsAggregateByOrgIdDocument, options);
      }
export function useGetQuestionSetsAggregateByOrgIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetQuestionSetsAggregateByOrgIdQuery, Types.GetQuestionSetsAggregateByOrgIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetQuestionSetsAggregateByOrgIdQuery, Types.GetQuestionSetsAggregateByOrgIdQueryVariables>(GetQuestionSetsAggregateByOrgIdDocument, options);
        }
export type GetQuestionSetsAggregateByOrgIdQueryHookResult = ReturnType<typeof useGetQuestionSetsAggregateByOrgIdQuery>;
export type GetQuestionSetsAggregateByOrgIdLazyQueryHookResult = ReturnType<typeof useGetQuestionSetsAggregateByOrgIdLazyQuery>;
export type GetQuestionSetsAggregateByOrgIdQueryResult = Apollo.QueryResult<Types.GetQuestionSetsAggregateByOrgIdQuery, Types.GetQuestionSetsAggregateByOrgIdQueryVariables>;
export const GetRecentUserActivityDocument = gql`
    query getRecentUserActivity {
  recent_user_activity {
    activity
    timestamp
  }
}
    `;

/**
 * __useGetRecentUserActivityQuery__
 *
 * To run a query within a React component, call `useGetRecentUserActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentUserActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentUserActivityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecentUserActivityQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetRecentUserActivityQuery, Types.GetRecentUserActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetRecentUserActivityQuery, Types.GetRecentUserActivityQueryVariables>(GetRecentUserActivityDocument, options);
      }
export function useGetRecentUserActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetRecentUserActivityQuery, Types.GetRecentUserActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetRecentUserActivityQuery, Types.GetRecentUserActivityQueryVariables>(GetRecentUserActivityDocument, options);
        }
export type GetRecentUserActivityQueryHookResult = ReturnType<typeof useGetRecentUserActivityQuery>;
export type GetRecentUserActivityLazyQueryHookResult = ReturnType<typeof useGetRecentUserActivityLazyQuery>;
export type GetRecentUserActivityQueryResult = Apollo.QueryResult<Types.GetRecentUserActivityQuery, Types.GetRecentUserActivityQueryVariables>;
export const GetReportPreviewUrlDocument = gql`
    query getReportPreviewUrl($organizationId: uuid!, $reportId: Int!) {
  report_previews(
    where: {organization_id: {_eq: $organizationId}, report_id: {_eq: $reportId}, report_section_id: {_is_null: true}}
    order_by: {started_at: desc}
    limit: 1
  ) {
    id
    organization_id
    status
    file {
      id
      filepath
    }
  }
}
    `;

/**
 * __useGetReportPreviewUrlQuery__
 *
 * To run a query within a React component, call `useGetReportPreviewUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportPreviewUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportPreviewUrlQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useGetReportPreviewUrlQuery(baseOptions: Apollo.QueryHookOptions<Types.GetReportPreviewUrlQuery, Types.GetReportPreviewUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetReportPreviewUrlQuery, Types.GetReportPreviewUrlQueryVariables>(GetReportPreviewUrlDocument, options);
      }
export function useGetReportPreviewUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetReportPreviewUrlQuery, Types.GetReportPreviewUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetReportPreviewUrlQuery, Types.GetReportPreviewUrlQueryVariables>(GetReportPreviewUrlDocument, options);
        }
export type GetReportPreviewUrlQueryHookResult = ReturnType<typeof useGetReportPreviewUrlQuery>;
export type GetReportPreviewUrlLazyQueryHookResult = ReturnType<typeof useGetReportPreviewUrlLazyQuery>;
export type GetReportPreviewUrlQueryResult = Apollo.QueryResult<Types.GetReportPreviewUrlQuery, Types.GetReportPreviewUrlQueryVariables>;
export const GetReportSectionPreviewUrlDocument = gql`
    query getReportSectionPreviewUrl($organizationId: uuid!, $reportSectionId: Int!) {
  report_previews(
    where: {organization_id: {_eq: $organizationId}, report_section_id: {_eq: $reportSectionId}}
    order_by: {started_at: desc}
    limit: 1
  ) {
    id
    organization_id
    status
    file {
      id
      filepath
    }
  }
}
    `;

/**
 * __useGetReportSectionPreviewUrlQuery__
 *
 * To run a query within a React component, call `useGetReportSectionPreviewUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportSectionPreviewUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportSectionPreviewUrlQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      reportSectionId: // value for 'reportSectionId'
 *   },
 * });
 */
export function useGetReportSectionPreviewUrlQuery(baseOptions: Apollo.QueryHookOptions<Types.GetReportSectionPreviewUrlQuery, Types.GetReportSectionPreviewUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetReportSectionPreviewUrlQuery, Types.GetReportSectionPreviewUrlQueryVariables>(GetReportSectionPreviewUrlDocument, options);
      }
export function useGetReportSectionPreviewUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetReportSectionPreviewUrlQuery, Types.GetReportSectionPreviewUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetReportSectionPreviewUrlQuery, Types.GetReportSectionPreviewUrlQueryVariables>(GetReportSectionPreviewUrlDocument, options);
        }
export type GetReportSectionPreviewUrlQueryHookResult = ReturnType<typeof useGetReportSectionPreviewUrlQuery>;
export type GetReportSectionPreviewUrlLazyQueryHookResult = ReturnType<typeof useGetReportSectionPreviewUrlLazyQuery>;
export type GetReportSectionPreviewUrlQueryResult = Apollo.QueryResult<Types.GetReportSectionPreviewUrlQuery, Types.GetReportSectionPreviewUrlQueryVariables>;
export const GetReportSectionFieldDocument = gql`
    query getReportSectionField($organizationId: uuid!, $id: Int!) {
  report_section_fields_by_pk(organization_id: $organizationId, id: $id) {
    ...ReportSectionFieldsIdentifiers
    field_name
    field_type
    field_value
    field_config
    version
    client_version
    has_default_value
    updated_at
    last_modified_by_id
  }
}
    ${ReportSectionFieldsIdentifiersFragmentDoc}`;

/**
 * __useGetReportSectionFieldQuery__
 *
 * To run a query within a React component, call `useGetReportSectionFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportSectionFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportSectionFieldQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReportSectionFieldQuery(baseOptions: Apollo.QueryHookOptions<Types.GetReportSectionFieldQuery, Types.GetReportSectionFieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetReportSectionFieldQuery, Types.GetReportSectionFieldQueryVariables>(GetReportSectionFieldDocument, options);
      }
export function useGetReportSectionFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetReportSectionFieldQuery, Types.GetReportSectionFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetReportSectionFieldQuery, Types.GetReportSectionFieldQueryVariables>(GetReportSectionFieldDocument, options);
        }
export type GetReportSectionFieldQueryHookResult = ReturnType<typeof useGetReportSectionFieldQuery>;
export type GetReportSectionFieldLazyQueryHookResult = ReturnType<typeof useGetReportSectionFieldLazyQuery>;
export type GetReportSectionFieldQueryResult = Apollo.QueryResult<Types.GetReportSectionFieldQuery, Types.GetReportSectionFieldQueryVariables>;
export const GetAssessmentReportsDocument = gql`
    query getAssessmentReports($organizationId: uuid!, $assessmentId: Int!) {
  reports(
    where: {organization_id: {_eq: $organizationId}, assessment_id: {_eq: $assessmentId}}
    limit: 1
  ) {
    id
    assessment_id
  }
}
    `;

/**
 * __useGetAssessmentReportsQuery__
 *
 * To run a query within a React component, call `useGetAssessmentReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentReportsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      assessmentId: // value for 'assessmentId'
 *   },
 * });
 */
export function useGetAssessmentReportsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAssessmentReportsQuery, Types.GetAssessmentReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAssessmentReportsQuery, Types.GetAssessmentReportsQueryVariables>(GetAssessmentReportsDocument, options);
      }
export function useGetAssessmentReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAssessmentReportsQuery, Types.GetAssessmentReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAssessmentReportsQuery, Types.GetAssessmentReportsQueryVariables>(GetAssessmentReportsDocument, options);
        }
export type GetAssessmentReportsQueryHookResult = ReturnType<typeof useGetAssessmentReportsQuery>;
export type GetAssessmentReportsLazyQueryHookResult = ReturnType<typeof useGetAssessmentReportsLazyQuery>;
export type GetAssessmentReportsQueryResult = Apollo.QueryResult<Types.GetAssessmentReportsQuery, Types.GetAssessmentReportsQueryVariables>;
export const GetAssessmentReportDocument = gql`
    query getAssessmentReport($organizationId: uuid!, $assessmentId: Int!, $id: Int!) {
  reports(
    where: {assessment_id: {_eq: $assessmentId}, organization_id: {_eq: $organizationId}, id: {_eq: $id}}
  ) {
    id
    name
    sections(order_by: {ordinal: asc}) {
      id
      report_section_template_id
      name
      ordinal
      visible
      fields(order_by: {ordinal: asc}) {
        organization_id
        id
        field_name
        field_type
        field_value
        field_config
        version
        client_version
        has_default_value
        last_modified_by_id
      }
    }
    assessment {
      id
      name
      scored_node_id
      recommendations
      background
      findings
      description
      state
    }
  }
}
    `;

/**
 * __useGetAssessmentReportQuery__
 *
 * To run a query within a React component, call `useGetAssessmentReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentReportQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      assessmentId: // value for 'assessmentId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssessmentReportQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAssessmentReportQuery, Types.GetAssessmentReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAssessmentReportQuery, Types.GetAssessmentReportQueryVariables>(GetAssessmentReportDocument, options);
      }
export function useGetAssessmentReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAssessmentReportQuery, Types.GetAssessmentReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAssessmentReportQuery, Types.GetAssessmentReportQueryVariables>(GetAssessmentReportDocument, options);
        }
export type GetAssessmentReportQueryHookResult = ReturnType<typeof useGetAssessmentReportQuery>;
export type GetAssessmentReportLazyQueryHookResult = ReturnType<typeof useGetAssessmentReportLazyQuery>;
export type GetAssessmentReportQueryResult = Apollo.QueryResult<Types.GetAssessmentReportQuery, Types.GetAssessmentReportQueryVariables>;
export const GetAssessmentReportsTableDocument = gql`
    query getAssessmentReportsTable($organizationId: uuid!) {
  reports(
    where: {organization_id: {_eq: $organizationId}}
    order_by: {updated_at: desc}
  ) {
    id
    assessment {
      id
      name
      scoredNode {
        name
        id
      }
    }
    last_updated_by_id
    updated_at
    previews(
      where: {report_section_id: {_is_null: true}, status: {_eq: "completed"}}
      order_by: {created_at: desc}
      limit: 1
    ) {
      id
      organization_id
      file {
        id
        filepath
        created_at
      }
    }
  }
}
    `;

/**
 * __useGetAssessmentReportsTableQuery__
 *
 * To run a query within a React component, call `useGetAssessmentReportsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentReportsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentReportsTableQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetAssessmentReportsTableQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAssessmentReportsTableQuery, Types.GetAssessmentReportsTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAssessmentReportsTableQuery, Types.GetAssessmentReportsTableQueryVariables>(GetAssessmentReportsTableDocument, options);
      }
export function useGetAssessmentReportsTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAssessmentReportsTableQuery, Types.GetAssessmentReportsTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAssessmentReportsTableQuery, Types.GetAssessmentReportsTableQueryVariables>(GetAssessmentReportsTableDocument, options);
        }
export type GetAssessmentReportsTableQueryHookResult = ReturnType<typeof useGetAssessmentReportsTableQuery>;
export type GetAssessmentReportsTableLazyQueryHookResult = ReturnType<typeof useGetAssessmentReportsTableLazyQuery>;
export type GetAssessmentReportsTableQueryResult = Apollo.QueryResult<Types.GetAssessmentReportsTableQuery, Types.GetAssessmentReportsTableQueryVariables>;
export const GetQuestionSetsByScenarioDocument = gql`
    query getQuestionSetsByScenario($organizationId: uuid!) {
  scenario(where: {organization_id: {_eq: $organizationId}}) {
    id
    name
    scenario_type
    description
    questionSets {
      id
      name
      isPrimary
    }
    relevantLayer {
      layer_id
      scenario_id
      organization_id
      locationType {
        id
        layerDepth
      }
    }
  }
}
    `;

/**
 * __useGetQuestionSetsByScenarioQuery__
 *
 * To run a query within a React component, call `useGetQuestionSetsByScenarioQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionSetsByScenarioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionSetsByScenarioQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetQuestionSetsByScenarioQuery(baseOptions: Apollo.QueryHookOptions<Types.GetQuestionSetsByScenarioQuery, Types.GetQuestionSetsByScenarioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetQuestionSetsByScenarioQuery, Types.GetQuestionSetsByScenarioQueryVariables>(GetQuestionSetsByScenarioDocument, options);
      }
export function useGetQuestionSetsByScenarioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetQuestionSetsByScenarioQuery, Types.GetQuestionSetsByScenarioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetQuestionSetsByScenarioQuery, Types.GetQuestionSetsByScenarioQueryVariables>(GetQuestionSetsByScenarioDocument, options);
        }
export type GetQuestionSetsByScenarioQueryHookResult = ReturnType<typeof useGetQuestionSetsByScenarioQuery>;
export type GetQuestionSetsByScenarioLazyQueryHookResult = ReturnType<typeof useGetQuestionSetsByScenarioLazyQuery>;
export type GetQuestionSetsByScenarioQueryResult = Apollo.QueryResult<Types.GetQuestionSetsByScenarioQuery, Types.GetQuestionSetsByScenarioQueryVariables>;
export const GetScenarioSettingsDataDocument = gql`
    query getScenarioSettingsData($scenarioId: Int!, $organizationId: uuid!) {
  scenario_by_pk(id: $scenarioId, organization_id: $organizationId) {
    id
    name
    scenario_type
    description
    is_partial_compliance_allowed
    are_thresholds_enabled
    lower_threshold
    upper_threshold
    highestScore
    catalog_scenario_meta_id
    questionSets(where: {is_draft: {_eq: false}}) {
      id
      name
    }
    primaryQuestionSets {
      scenario_id
      scenario_question_set_id
    }
    probabilityMeasures: inherentRiskMeasures(
      where: {type: {_eq: probability}}
      order_by: {name: asc}
    ) {
      ...scenarioMeasure
    }
    impactMeasures: inherentRiskMeasures(
      where: {type: {_eq: severity}}
      order_by: {name: asc}
    ) {
      ...scenarioMeasure
    }
    inherentRiskMeasures_aggregate {
      aggregate {
        count
      }
    }
  }
}
    ${ScenarioMeasureFragmentDoc}`;

/**
 * __useGetScenarioSettingsDataQuery__
 *
 * To run a query within a React component, call `useGetScenarioSettingsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenarioSettingsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenarioSettingsDataQuery({
 *   variables: {
 *      scenarioId: // value for 'scenarioId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetScenarioSettingsDataQuery(baseOptions: Apollo.QueryHookOptions<Types.GetScenarioSettingsDataQuery, Types.GetScenarioSettingsDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetScenarioSettingsDataQuery, Types.GetScenarioSettingsDataQueryVariables>(GetScenarioSettingsDataDocument, options);
      }
export function useGetScenarioSettingsDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetScenarioSettingsDataQuery, Types.GetScenarioSettingsDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetScenarioSettingsDataQuery, Types.GetScenarioSettingsDataQueryVariables>(GetScenarioSettingsDataDocument, options);
        }
export type GetScenarioSettingsDataQueryHookResult = ReturnType<typeof useGetScenarioSettingsDataQuery>;
export type GetScenarioSettingsDataLazyQueryHookResult = ReturnType<typeof useGetScenarioSettingsDataLazyQuery>;
export type GetScenarioSettingsDataQueryResult = Apollo.QueryResult<Types.GetScenarioSettingsDataQuery, Types.GetScenarioSettingsDataQueryVariables>;
export const GetScenarioByNameDocument = gql`
    query getScenarioByName($scenarioName: citext!, $organizationId: uuid!) {
  scenario(
    where: {name: {_eq: $scenarioName}, organization_id: {_eq: $organizationId}}
  ) {
    id
  }
}
    `;

/**
 * __useGetScenarioByNameQuery__
 *
 * To run a query within a React component, call `useGetScenarioByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenarioByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenarioByNameQuery({
 *   variables: {
 *      scenarioName: // value for 'scenarioName'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetScenarioByNameQuery(baseOptions: Apollo.QueryHookOptions<Types.GetScenarioByNameQuery, Types.GetScenarioByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetScenarioByNameQuery, Types.GetScenarioByNameQueryVariables>(GetScenarioByNameDocument, options);
      }
export function useGetScenarioByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetScenarioByNameQuery, Types.GetScenarioByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetScenarioByNameQuery, Types.GetScenarioByNameQueryVariables>(GetScenarioByNameDocument, options);
        }
export type GetScenarioByNameQueryHookResult = ReturnType<typeof useGetScenarioByNameQuery>;
export type GetScenarioByNameLazyQueryHookResult = ReturnType<typeof useGetScenarioByNameLazyQuery>;
export type GetScenarioByNameQueryResult = Apollo.QueryResult<Types.GetScenarioByNameQuery, Types.GetScenarioByNameQueryVariables>;
export const GetScenarioInherentRiskDetailsThresholdDataDocument = gql`
    query getScenarioInherentRiskDetailsThresholdData($scenarioId: Int!, $organizationId: uuid!) {
  scenario_by_pk(id: $scenarioId, organization_id: $organizationId) {
    id
    name
    lower_threshold
    upper_threshold
    highestScore
  }
}
    `;

/**
 * __useGetScenarioInherentRiskDetailsThresholdDataQuery__
 *
 * To run a query within a React component, call `useGetScenarioInherentRiskDetailsThresholdDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenarioInherentRiskDetailsThresholdDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenarioInherentRiskDetailsThresholdDataQuery({
 *   variables: {
 *      scenarioId: // value for 'scenarioId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetScenarioInherentRiskDetailsThresholdDataQuery(baseOptions: Apollo.QueryHookOptions<Types.GetScenarioInherentRiskDetailsThresholdDataQuery, Types.GetScenarioInherentRiskDetailsThresholdDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetScenarioInherentRiskDetailsThresholdDataQuery, Types.GetScenarioInherentRiskDetailsThresholdDataQueryVariables>(GetScenarioInherentRiskDetailsThresholdDataDocument, options);
      }
export function useGetScenarioInherentRiskDetailsThresholdDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetScenarioInherentRiskDetailsThresholdDataQuery, Types.GetScenarioInherentRiskDetailsThresholdDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetScenarioInherentRiskDetailsThresholdDataQuery, Types.GetScenarioInherentRiskDetailsThresholdDataQueryVariables>(GetScenarioInherentRiskDetailsThresholdDataDocument, options);
        }
export type GetScenarioInherentRiskDetailsThresholdDataQueryHookResult = ReturnType<typeof useGetScenarioInherentRiskDetailsThresholdDataQuery>;
export type GetScenarioInherentRiskDetailsThresholdDataLazyQueryHookResult = ReturnType<typeof useGetScenarioInherentRiskDetailsThresholdDataLazyQuery>;
export type GetScenarioInherentRiskDetailsThresholdDataQueryResult = Apollo.QueryResult<Types.GetScenarioInherentRiskDetailsThresholdDataQuery, Types.GetScenarioInherentRiskDetailsThresholdDataQueryVariables>;
export const GetRiskDashboardRelevantTasksDocument = gql`
    query getRiskDashboardRelevantTasks($organizationId: uuid!, $scenarioId: Int!, $rootNodeId: uuid!) {
  scenario_by_pk(id: $scenarioId, organization_id: $organizationId) {
    relevantTasks(args: {node_id_input: $rootNodeId}) {
      ...TaskIdentifiers
      location_id: node_id
      initial_cost
      status
      due_date
    }
  }
}
    ${TaskIdentifiersFragmentDoc}`;

/**
 * __useGetRiskDashboardRelevantTasksQuery__
 *
 * To run a query within a React component, call `useGetRiskDashboardRelevantTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRiskDashboardRelevantTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRiskDashboardRelevantTasksQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      scenarioId: // value for 'scenarioId'
 *      rootNodeId: // value for 'rootNodeId'
 *   },
 * });
 */
export function useGetRiskDashboardRelevantTasksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetRiskDashboardRelevantTasksQuery, Types.GetRiskDashboardRelevantTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetRiskDashboardRelevantTasksQuery, Types.GetRiskDashboardRelevantTasksQueryVariables>(GetRiskDashboardRelevantTasksDocument, options);
      }
export function useGetRiskDashboardRelevantTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetRiskDashboardRelevantTasksQuery, Types.GetRiskDashboardRelevantTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetRiskDashboardRelevantTasksQuery, Types.GetRiskDashboardRelevantTasksQueryVariables>(GetRiskDashboardRelevantTasksDocument, options);
        }
export type GetRiskDashboardRelevantTasksQueryHookResult = ReturnType<typeof useGetRiskDashboardRelevantTasksQuery>;
export type GetRiskDashboardRelevantTasksLazyQueryHookResult = ReturnType<typeof useGetRiskDashboardRelevantTasksLazyQuery>;
export type GetRiskDashboardRelevantTasksQueryResult = Apollo.QueryResult<Types.GetRiskDashboardRelevantTasksQuery, Types.GetRiskDashboardRelevantTasksQueryVariables>;
export const GetScenarioAssessmentDataDocument = gql`
    query getScenarioAssessmentData($organizationId: uuid!, $scenarioId: Int!, $locationPath: ltree!) {
  scenario_by_pk(organization_id: $organizationId, id: $scenarioId) {
    id
    locationTypes {
      layer_id
      scenario_id
    }
    primaryQuestionSets {
      scenario_id
      scenario_question_set_id
      questionSet {
        id
        assessmentTemplates(order_by: {created_at: asc}) {
          assessmentTemplate {
            id
            name
            assessments(
              where: {scored_node_id: {_is_null: false}, scoredNode: {path: {_descendant: $locationPath}}}
            ) {
              name
              due_date
              start_date
              scoredNode {
                layer_id
                id
                parent_id
              }
              state
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetScenarioAssessmentDataQuery__
 *
 * To run a query within a React component, call `useGetScenarioAssessmentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenarioAssessmentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenarioAssessmentDataQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      scenarioId: // value for 'scenarioId'
 *      locationPath: // value for 'locationPath'
 *   },
 * });
 */
export function useGetScenarioAssessmentDataQuery(baseOptions: Apollo.QueryHookOptions<Types.GetScenarioAssessmentDataQuery, Types.GetScenarioAssessmentDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetScenarioAssessmentDataQuery, Types.GetScenarioAssessmentDataQueryVariables>(GetScenarioAssessmentDataDocument, options);
      }
export function useGetScenarioAssessmentDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetScenarioAssessmentDataQuery, Types.GetScenarioAssessmentDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetScenarioAssessmentDataQuery, Types.GetScenarioAssessmentDataQueryVariables>(GetScenarioAssessmentDataDocument, options);
        }
export type GetScenarioAssessmentDataQueryHookResult = ReturnType<typeof useGetScenarioAssessmentDataQuery>;
export type GetScenarioAssessmentDataLazyQueryHookResult = ReturnType<typeof useGetScenarioAssessmentDataLazyQuery>;
export type GetScenarioAssessmentDataQueryResult = Apollo.QueryResult<Types.GetScenarioAssessmentDataQuery, Types.GetScenarioAssessmentDataQueryVariables>;
export const GetScenarioAndLocationResultsDocument = gql`
    query getScenarioAndLocationResults($organizationId: uuid!, $scenarioId: Int!) {
  scenario_by_pk(organization_id: $organizationId, id: $scenarioId) {
    id
    name
    description
    scenario_type
    is_partial_compliance_allowed
    are_thresholds_enabled
    lower_threshold
    upper_threshold
    percentCompleteInherentRisk
    description
    highestScore
    relatedAssessmentTemplates {
      __typename
      id
    }
    relatedAssessments {
      id
      state
    }
    questionSets {
      id
      name
    }
    primaryQuestionSets {
      scenario_id
      scenario_question_set_id
      questionSet {
        id
        name
      }
    }
    relevantLayer {
      layer_id
      scenario_id
      locationType {
        __typename
        parent_id
        id
        name
      }
    }
    relatedLocations {
      node_id
      scenario_id
      tasksCount
      aggregates {
        deficiency_count
      }
      location {
        parent {
          id
          name
          layer {
            name
          }
        }
      }
      location_name
      max_score
      inherent_risk
      residual_score
      probability_values_sum
      impact_values_sum
      control_percentage
      completed_question_percentage
      is_complete
    }
  }
}
    `;

/**
 * __useGetScenarioAndLocationResultsQuery__
 *
 * To run a query within a React component, call `useGetScenarioAndLocationResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenarioAndLocationResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenarioAndLocationResultsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      scenarioId: // value for 'scenarioId'
 *   },
 * });
 */
export function useGetScenarioAndLocationResultsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetScenarioAndLocationResultsQuery, Types.GetScenarioAndLocationResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetScenarioAndLocationResultsQuery, Types.GetScenarioAndLocationResultsQueryVariables>(GetScenarioAndLocationResultsDocument, options);
      }
export function useGetScenarioAndLocationResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetScenarioAndLocationResultsQuery, Types.GetScenarioAndLocationResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetScenarioAndLocationResultsQuery, Types.GetScenarioAndLocationResultsQueryVariables>(GetScenarioAndLocationResultsDocument, options);
        }
export type GetScenarioAndLocationResultsQueryHookResult = ReturnType<typeof useGetScenarioAndLocationResultsQuery>;
export type GetScenarioAndLocationResultsLazyQueryHookResult = ReturnType<typeof useGetScenarioAndLocationResultsLazyQuery>;
export type GetScenarioAndLocationResultsQueryResult = Apollo.QueryResult<Types.GetScenarioAndLocationResultsQuery, Types.GetScenarioAndLocationResultsQueryVariables>;
export const GetScenarioDetailsDocument = gql`
    query getScenarioDetails($organizationId: uuid!, $id: Int!) {
  scenario_by_pk(id: $id, organization_id: $organizationId) {
    id
    name
    scenario_type
    highestScore
    catalog_scenario_meta_id
    locationTypes {
      layer_id
      scenario_id
      locationType {
        id
        name
      }
    }
    possibleLayerTags {
      ...ScenarioLayerTags
    }
    probabilityMeasures: inherentRiskMeasures(
      where: {type: {_eq: probability}}
      order_by: {name: asc}
    ) {
      ...scenarioMeasure
    }
    impactMeasures: inherentRiskMeasures(
      where: {type: {_eq: severity}}
      order_by: {name: asc}
    ) {
      ...scenarioMeasure
    }
    catalog_scenario_meta_id
    ...inherentRiskProgress
  }
}
    ${ScenarioLayerTagsFragmentDoc}
${ScenarioMeasureFragmentDoc}
${InherentRiskProgressFragmentDoc}`;

/**
 * __useGetScenarioDetailsQuery__
 *
 * To run a query within a React component, call `useGetScenarioDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenarioDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenarioDetailsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetScenarioDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetScenarioDetailsQuery, Types.GetScenarioDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetScenarioDetailsQuery, Types.GetScenarioDetailsQueryVariables>(GetScenarioDetailsDocument, options);
      }
export function useGetScenarioDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetScenarioDetailsQuery, Types.GetScenarioDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetScenarioDetailsQuery, Types.GetScenarioDetailsQueryVariables>(GetScenarioDetailsDocument, options);
        }
export type GetScenarioDetailsQueryHookResult = ReturnType<typeof useGetScenarioDetailsQuery>;
export type GetScenarioDetailsLazyQueryHookResult = ReturnType<typeof useGetScenarioDetailsLazyQuery>;
export type GetScenarioDetailsQueryResult = Apollo.QueryResult<Types.GetScenarioDetailsQuery, Types.GetScenarioDetailsQueryVariables>;
export const GetBaseScenariosDocument = gql`
    query getBaseScenarios($organizationId: uuid!, $scenarioIds: [Int!]!) {
  scenario(
    where: {_and: [{organization_id: {_eq: $organizationId}}, {id: {_in: $scenarioIds}}]}
  ) {
    id
    name
    description
    lower_threshold
    upper_threshold
    scenario_type
  }
}
    `;

/**
 * __useGetBaseScenariosQuery__
 *
 * To run a query within a React component, call `useGetBaseScenariosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseScenariosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseScenariosQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      scenarioIds: // value for 'scenarioIds'
 *   },
 * });
 */
export function useGetBaseScenariosQuery(baseOptions: Apollo.QueryHookOptions<Types.GetBaseScenariosQuery, Types.GetBaseScenariosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetBaseScenariosQuery, Types.GetBaseScenariosQueryVariables>(GetBaseScenariosDocument, options);
      }
export function useGetBaseScenariosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBaseScenariosQuery, Types.GetBaseScenariosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetBaseScenariosQuery, Types.GetBaseScenariosQueryVariables>(GetBaseScenariosDocument, options);
        }
export type GetBaseScenariosQueryHookResult = ReturnType<typeof useGetBaseScenariosQuery>;
export type GetBaseScenariosLazyQueryHookResult = ReturnType<typeof useGetBaseScenariosLazyQuery>;
export type GetBaseScenariosQueryResult = Apollo.QueryResult<Types.GetBaseScenariosQuery, Types.GetBaseScenariosQueryVariables>;
export const GetStandardDocument = gql`
    query getStandard($organizationId: uuid!, $questionId: uuid!, $questionSetId: Int!) {
  scenario_question_set_standards_by_pk(
    organization_id: $organizationId
    question_id: $questionId
    scenario_question_set_id: $questionSetId
  ) {
    ...scenarioQuestionSetStandard
  }
}
    ${ScenarioQuestionSetStandardFragmentDoc}`;

/**
 * __useGetStandardQuery__
 *
 * To run a query within a React component, call `useGetStandardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStandardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStandardQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      questionId: // value for 'questionId'
 *      questionSetId: // value for 'questionSetId'
 *   },
 * });
 */
export function useGetStandardQuery(baseOptions: Apollo.QueryHookOptions<Types.GetStandardQuery, Types.GetStandardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStandardQuery, Types.GetStandardQueryVariables>(GetStandardDocument, options);
      }
export function useGetStandardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStandardQuery, Types.GetStandardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStandardQuery, Types.GetStandardQueryVariables>(GetStandardDocument, options);
        }
export type GetStandardQueryHookResult = ReturnType<typeof useGetStandardQuery>;
export type GetStandardLazyQueryHookResult = ReturnType<typeof useGetStandardLazyQuery>;
export type GetStandardQueryResult = Apollo.QueryResult<Types.GetStandardQuery, Types.GetStandardQueryVariables>;
export const GetScenarioQuestionSetDocument = gql`
    query getScenarioQuestionSet($id: Int!, $organization_id: uuid!) {
  scenario_question_sets_by_pk(id: $id, organization_id: $organization_id) {
    id
    name
    is_draft
    ...ScenarioQuestionSetScenarioInfo
    ...ScenarioQuestionSetLocationTags
    itemCategories {
      ...ScenarioQuestionSetItemCategory
    }
  }
}
    ${ScenarioQuestionSetScenarioInfoFragmentDoc}
${ScenarioQuestionSetLocationTagsFragmentDoc}
${ScenarioQuestionSetItemCategoryFragmentDoc}`;

/**
 * __useGetScenarioQuestionSetQuery__
 *
 * To run a query within a React component, call `useGetScenarioQuestionSetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenarioQuestionSetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenarioQuestionSetQuery({
 *   variables: {
 *      id: // value for 'id'
 *      organization_id: // value for 'organization_id'
 *   },
 * });
 */
export function useGetScenarioQuestionSetQuery(baseOptions: Apollo.QueryHookOptions<Types.GetScenarioQuestionSetQuery, Types.GetScenarioQuestionSetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetScenarioQuestionSetQuery, Types.GetScenarioQuestionSetQueryVariables>(GetScenarioQuestionSetDocument, options);
      }
export function useGetScenarioQuestionSetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetScenarioQuestionSetQuery, Types.GetScenarioQuestionSetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetScenarioQuestionSetQuery, Types.GetScenarioQuestionSetQueryVariables>(GetScenarioQuestionSetDocument, options);
        }
export type GetScenarioQuestionSetQueryHookResult = ReturnType<typeof useGetScenarioQuestionSetQuery>;
export type GetScenarioQuestionSetLazyQueryHookResult = ReturnType<typeof useGetScenarioQuestionSetLazyQuery>;
export type GetScenarioQuestionSetQueryResult = Apollo.QueryResult<Types.GetScenarioQuestionSetQuery, Types.GetScenarioQuestionSetQueryVariables>;
export const GetScenarioQuestionSetDeficienciesDocument = gql`
    query getScenarioQuestionSetDeficiencies($organizationId: uuid!, $questionSetId: Int!, $nodeIds: _uuid!) {
  scenario_question_sets_by_pk(
    organization_id: $organizationId
    id: $questionSetId
  ) {
    id
    scenarioAnswers(
      args: {node_ids: $nodeIds, include_children: true}
      where: {is_deficient: {_eq: true}}
    ) {
      answer_id
      criticality_value
      location_id
      location_name
      location_mpath
      item_category_id
      item_category_name
    }
  }
}
    `;

/**
 * __useGetScenarioQuestionSetDeficienciesQuery__
 *
 * To run a query within a React component, call `useGetScenarioQuestionSetDeficienciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenarioQuestionSetDeficienciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenarioQuestionSetDeficienciesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      questionSetId: // value for 'questionSetId'
 *      nodeIds: // value for 'nodeIds'
 *   },
 * });
 */
export function useGetScenarioQuestionSetDeficienciesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetScenarioQuestionSetDeficienciesQuery, Types.GetScenarioQuestionSetDeficienciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetScenarioQuestionSetDeficienciesQuery, Types.GetScenarioQuestionSetDeficienciesQueryVariables>(GetScenarioQuestionSetDeficienciesDocument, options);
      }
export function useGetScenarioQuestionSetDeficienciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetScenarioQuestionSetDeficienciesQuery, Types.GetScenarioQuestionSetDeficienciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetScenarioQuestionSetDeficienciesQuery, Types.GetScenarioQuestionSetDeficienciesQueryVariables>(GetScenarioQuestionSetDeficienciesDocument, options);
        }
export type GetScenarioQuestionSetDeficienciesQueryHookResult = ReturnType<typeof useGetScenarioQuestionSetDeficienciesQuery>;
export type GetScenarioQuestionSetDeficienciesLazyQueryHookResult = ReturnType<typeof useGetScenarioQuestionSetDeficienciesLazyQuery>;
export type GetScenarioQuestionSetDeficienciesQueryResult = Apollo.QueryResult<Types.GetScenarioQuestionSetDeficienciesQuery, Types.GetScenarioQuestionSetDeficienciesQueryVariables>;
export const GetScheduledAssessmentsTableDataByStatusDocument = gql`
    query getScheduledAssessmentsTableDataByStatus($whereExpr: scheduled_assessment_schedule_bool_exp!) {
  scheduled_assessment_schedule(where: $whereExpr, order_by: {created_at: desc}) {
    ...ScheduledAssessmentSchedule
  }
}
    ${ScheduledAssessmentScheduleFragmentDoc}`;

/**
 * __useGetScheduledAssessmentsTableDataByStatusQuery__
 *
 * To run a query within a React component, call `useGetScheduledAssessmentsTableDataByStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledAssessmentsTableDataByStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledAssessmentsTableDataByStatusQuery({
 *   variables: {
 *      whereExpr: // value for 'whereExpr'
 *   },
 * });
 */
export function useGetScheduledAssessmentsTableDataByStatusQuery(baseOptions: Apollo.QueryHookOptions<Types.GetScheduledAssessmentsTableDataByStatusQuery, Types.GetScheduledAssessmentsTableDataByStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetScheduledAssessmentsTableDataByStatusQuery, Types.GetScheduledAssessmentsTableDataByStatusQueryVariables>(GetScheduledAssessmentsTableDataByStatusDocument, options);
      }
export function useGetScheduledAssessmentsTableDataByStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetScheduledAssessmentsTableDataByStatusQuery, Types.GetScheduledAssessmentsTableDataByStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetScheduledAssessmentsTableDataByStatusQuery, Types.GetScheduledAssessmentsTableDataByStatusQueryVariables>(GetScheduledAssessmentsTableDataByStatusDocument, options);
        }
export type GetScheduledAssessmentsTableDataByStatusQueryHookResult = ReturnType<typeof useGetScheduledAssessmentsTableDataByStatusQuery>;
export type GetScheduledAssessmentsTableDataByStatusLazyQueryHookResult = ReturnType<typeof useGetScheduledAssessmentsTableDataByStatusLazyQuery>;
export type GetScheduledAssessmentsTableDataByStatusQueryResult = Apollo.QueryResult<Types.GetScheduledAssessmentsTableDataByStatusQuery, Types.GetScheduledAssessmentsTableDataByStatusQueryVariables>;
export const GetScheduledAssessmentScheduleByIdDocument = gql`
    query getScheduledAssessmentScheduleById($id: Int!, $organizationId: uuid!) {
  scheduled_assessment_schedule_by_pk(id: $id, organization_id: $organizationId) {
    ...ScheduledAssessmentScheduleForForm
  }
}
    ${ScheduledAssessmentScheduleForFormFragmentDoc}`;

/**
 * __useGetScheduledAssessmentScheduleByIdQuery__
 *
 * To run a query within a React component, call `useGetScheduledAssessmentScheduleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledAssessmentScheduleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledAssessmentScheduleByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetScheduledAssessmentScheduleByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetScheduledAssessmentScheduleByIdQuery, Types.GetScheduledAssessmentScheduleByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetScheduledAssessmentScheduleByIdQuery, Types.GetScheduledAssessmentScheduleByIdQueryVariables>(GetScheduledAssessmentScheduleByIdDocument, options);
      }
export function useGetScheduledAssessmentScheduleByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetScheduledAssessmentScheduleByIdQuery, Types.GetScheduledAssessmentScheduleByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetScheduledAssessmentScheduleByIdQuery, Types.GetScheduledAssessmentScheduleByIdQueryVariables>(GetScheduledAssessmentScheduleByIdDocument, options);
        }
export type GetScheduledAssessmentScheduleByIdQueryHookResult = ReturnType<typeof useGetScheduledAssessmentScheduleByIdQuery>;
export type GetScheduledAssessmentScheduleByIdLazyQueryHookResult = ReturnType<typeof useGetScheduledAssessmentScheduleByIdLazyQuery>;
export type GetScheduledAssessmentScheduleByIdQueryResult = Apollo.QueryResult<Types.GetScheduledAssessmentScheduleByIdQuery, Types.GetScheduledAssessmentScheduleByIdQueryVariables>;
export const GetTaskActionDetailsDocument = gql`
    query GetTaskActionDetails($id: uuid!) {
  task_actions_by_pk(id: $id) {
    ...TaskActionDetails
  }
}
    ${TaskActionDetailsFragmentDoc}`;

/**
 * __useGetTaskActionDetailsQuery__
 *
 * To run a query within a React component, call `useGetTaskActionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskActionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskActionDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTaskActionDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTaskActionDetailsQuery, Types.GetTaskActionDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTaskActionDetailsQuery, Types.GetTaskActionDetailsQueryVariables>(GetTaskActionDetailsDocument, options);
      }
export function useGetTaskActionDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTaskActionDetailsQuery, Types.GetTaskActionDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTaskActionDetailsQuery, Types.GetTaskActionDetailsQueryVariables>(GetTaskActionDetailsDocument, options);
        }
export type GetTaskActionDetailsQueryHookResult = ReturnType<typeof useGetTaskActionDetailsQuery>;
export type GetTaskActionDetailsLazyQueryHookResult = ReturnType<typeof useGetTaskActionDetailsLazyQuery>;
export type GetTaskActionDetailsQueryResult = Apollo.QueryResult<Types.GetTaskActionDetailsQuery, Types.GetTaskActionDetailsQueryVariables>;
export const GetTaskHistoryDocument = gql`
    query getTaskHistory($taskNo: Int!) {
  task_history(
    where: {task_no: {_eq: $taskNo}}
    order_by: {occurred_at: desc}
    limit: 10
  ) {
    __typename
    id
    action
    changed_by_user_id
    created_at
    data
    occurred_at
    type
  }
}
    `;

/**
 * __useGetTaskHistoryQuery__
 *
 * To run a query within a React component, call `useGetTaskHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskHistoryQuery({
 *   variables: {
 *      taskNo: // value for 'taskNo'
 *   },
 * });
 */
export function useGetTaskHistoryQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTaskHistoryQuery, Types.GetTaskHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTaskHistoryQuery, Types.GetTaskHistoryQueryVariables>(GetTaskHistoryDocument, options);
      }
export function useGetTaskHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTaskHistoryQuery, Types.GetTaskHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTaskHistoryQuery, Types.GetTaskHistoryQueryVariables>(GetTaskHistoryDocument, options);
        }
export type GetTaskHistoryQueryHookResult = ReturnType<typeof useGetTaskHistoryQuery>;
export type GetTaskHistoryLazyQueryHookResult = ReturnType<typeof useGetTaskHistoryLazyQuery>;
export type GetTaskHistoryQueryResult = Apollo.QueryResult<Types.GetTaskHistoryQuery, Types.GetTaskHistoryQueryVariables>;
export const GetTaskDocument = gql`
    query getTask($organizationId: uuid!, $taskNo: Int!) {
  tasks_by_pk(organization_id: $organizationId, task_no: $taskNo) {
    ...TaskIdentifiers
    ...TaskPlan
    created_at
    description
    name
    node_id
    project_id
    priority
    status
    itemCategory {
      id
      name
      file {
        filepath
      }
    }
    task_actions {
      ...TaskAction
      item_id
      status
      task_actions_files {
        ...TaskActionFiles
      }
      assignee_id
      freeformConsiderationOption {
        id
        name: title
        description
        type
      }
      libraryConsiderationOption {
        id
        name
        description
        type
        initial_cost
        ongoing_cost
        time_estimate
        photo {
          id
          filepath
        }
      }
      item {
        id
        name
        is_interview_item
        is_present
        item_category_id
        node_id
        files {
          file {
            ...image
          }
        }
      }
      action_deficiencies {
        ...ActionDeficiencyIdentifiers
        active_answer {
          ...taskAnswerInfo
        }
        question {
          id
          question_text
          standards {
            ...scenarioQuestionSetStandard
          }
          deficient_answer
        }
      }
    }
    node {
      id
      name
      layer {
        id
        name
      }
    }
  }
}
    ${TaskIdentifiersFragmentDoc}
${TaskPlanFragmentDoc}
${TaskActionFragmentDoc}
${TaskActionFilesFragmentDoc}
${ImageFragmentDoc}
${ActionDeficiencyIdentifiersFragmentDoc}
${TaskAnswerInfoFragmentDoc}
${ScenarioQuestionSetStandardFragmentDoc}`;

/**
 * __useGetTaskQuery__
 *
 * To run a query within a React component, call `useGetTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      taskNo: // value for 'taskNo'
 *   },
 * });
 */
export function useGetTaskQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTaskQuery, Types.GetTaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTaskQuery, Types.GetTaskQueryVariables>(GetTaskDocument, options);
      }
export function useGetTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTaskQuery, Types.GetTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTaskQuery, Types.GetTaskQueryVariables>(GetTaskDocument, options);
        }
export type GetTaskQueryHookResult = ReturnType<typeof useGetTaskQuery>;
export type GetTaskLazyQueryHookResult = ReturnType<typeof useGetTaskLazyQuery>;
export type GetTaskQueryResult = Apollo.QueryResult<Types.GetTaskQuery, Types.GetTaskQueryVariables>;
export const GetTasksDocument = gql`
    query getTasks($taskFilter: tasks_bool_exp!) {
  tasks(where: $taskFilter, order_by: [{task_no: desc}]) {
    ...TaskIdentifiers
    ...TaskPlan
    created_at
    description
    name
    node_id
    priority
    status
    project_id
    item_category_id
    task_actions {
      ...TaskActionIdentifiers
      ...TaskActionProperties
      ...TaskActionPlanDetails
      status
      task_actions_files {
        task_action_id
        file_id
      }
      action_deficiencies {
        ...ActionDeficiencyIdentifiers
        active_answer {
          item_id
          question_id
          criticality_value
          question {
            id
            question_text
          }
          files {
            file_id
            item_id
            question_id
          }
        }
      }
      __typename
    }
    created_by
    __typename
  }
}
    ${TaskIdentifiersFragmentDoc}
${TaskPlanFragmentDoc}
${TaskActionIdentifiersFragmentDoc}
${TaskActionPropertiesFragmentDoc}
${TaskActionPlanDetailsFragmentDoc}
${ActionDeficiencyIdentifiersFragmentDoc}`;

/**
 * __useGetTasksQuery__
 *
 * To run a query within a React component, call `useGetTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksQuery({
 *   variables: {
 *      taskFilter: // value for 'taskFilter'
 *   },
 * });
 */
export function useGetTasksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTasksQuery, Types.GetTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTasksQuery, Types.GetTasksQueryVariables>(GetTasksDocument, options);
      }
export function useGetTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTasksQuery, Types.GetTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTasksQuery, Types.GetTasksQueryVariables>(GetTasksDocument, options);
        }
export type GetTasksQueryHookResult = ReturnType<typeof useGetTasksQuery>;
export type GetTasksLazyQueryHookResult = ReturnType<typeof useGetTasksLazyQuery>;
export type GetTasksQueryResult = Apollo.QueryResult<Types.GetTasksQuery, Types.GetTasksQueryVariables>;
export const GetTaskSelectionListDocument = gql`
    query GetTaskSelectionList($taskFilter: tasks_bool_exp!) {
  tasks(where: $taskFilter, order_by: [{task_no: desc}]) {
    ...TaskSelectionListItem
  }
}
    ${TaskSelectionListItemFragmentDoc}`;

/**
 * __useGetTaskSelectionListQuery__
 *
 * To run a query within a React component, call `useGetTaskSelectionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskSelectionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskSelectionListQuery({
 *   variables: {
 *      taskFilter: // value for 'taskFilter'
 *   },
 * });
 */
export function useGetTaskSelectionListQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTaskSelectionListQuery, Types.GetTaskSelectionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTaskSelectionListQuery, Types.GetTaskSelectionListQueryVariables>(GetTaskSelectionListDocument, options);
      }
export function useGetTaskSelectionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTaskSelectionListQuery, Types.GetTaskSelectionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTaskSelectionListQuery, Types.GetTaskSelectionListQueryVariables>(GetTaskSelectionListDocument, options);
        }
export type GetTaskSelectionListQueryHookResult = ReturnType<typeof useGetTaskSelectionListQuery>;
export type GetTaskSelectionListLazyQueryHookResult = ReturnType<typeof useGetTaskSelectionListLazyQuery>;
export type GetTaskSelectionListQueryResult = Apollo.QueryResult<Types.GetTaskSelectionListQuery, Types.GetTaskSelectionListQueryVariables>;
export const GetTaskDetailsDocument = gql`
    query GetTaskDetails($organizationId: uuid!, $taskNo: Int!) {
  tasks_by_pk(organization_id: $organizationId, task_no: $taskNo) {
    ...TaskDetails
  }
}
    ${TaskDetailsFragmentDoc}`;

/**
 * __useGetTaskDetailsQuery__
 *
 * To run a query within a React component, call `useGetTaskDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskDetailsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      taskNo: // value for 'taskNo'
 *   },
 * });
 */
export function useGetTaskDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTaskDetailsQuery, Types.GetTaskDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTaskDetailsQuery, Types.GetTaskDetailsQueryVariables>(GetTaskDetailsDocument, options);
      }
export function useGetTaskDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTaskDetailsQuery, Types.GetTaskDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTaskDetailsQuery, Types.GetTaskDetailsQueryVariables>(GetTaskDetailsDocument, options);
        }
export type GetTaskDetailsQueryHookResult = ReturnType<typeof useGetTaskDetailsQuery>;
export type GetTaskDetailsLazyQueryHookResult = ReturnType<typeof useGetTaskDetailsLazyQuery>;
export type GetTaskDetailsQueryResult = Apollo.QueryResult<Types.GetTaskDetailsQuery, Types.GetTaskDetailsQueryVariables>;
export const GetTaskDetailsTaskActionsListDocument = gql`
    query GetTaskDetailsTaskActionsList($organizationId: uuid!, $taskNo: Int!) {
  tasks_by_pk(organization_id: $organizationId, task_no: $taskNo) {
    task_no
    ...TaskDetailsTaskActionsList
  }
}
    ${TaskDetailsTaskActionsListFragmentDoc}`;

/**
 * __useGetTaskDetailsTaskActionsListQuery__
 *
 * To run a query within a React component, call `useGetTaskDetailsTaskActionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskDetailsTaskActionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskDetailsTaskActionsListQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      taskNo: // value for 'taskNo'
 *   },
 * });
 */
export function useGetTaskDetailsTaskActionsListQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTaskDetailsTaskActionsListQuery, Types.GetTaskDetailsTaskActionsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTaskDetailsTaskActionsListQuery, Types.GetTaskDetailsTaskActionsListQueryVariables>(GetTaskDetailsTaskActionsListDocument, options);
      }
export function useGetTaskDetailsTaskActionsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTaskDetailsTaskActionsListQuery, Types.GetTaskDetailsTaskActionsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTaskDetailsTaskActionsListQuery, Types.GetTaskDetailsTaskActionsListQueryVariables>(GetTaskDetailsTaskActionsListDocument, options);
        }
export type GetTaskDetailsTaskActionsListQueryHookResult = ReturnType<typeof useGetTaskDetailsTaskActionsListQuery>;
export type GetTaskDetailsTaskActionsListLazyQueryHookResult = ReturnType<typeof useGetTaskDetailsTaskActionsListLazyQuery>;
export type GetTaskDetailsTaskActionsListQueryResult = Apollo.QueryResult<Types.GetTaskDetailsTaskActionsListQuery, Types.GetTaskDetailsTaskActionsListQueryVariables>;
export const GetSingleActionTaskDetailsDocument = gql`
    query GetSingleActionTaskDetails($organizationId: uuid!, $taskNo: Int!) {
  tasks_by_pk(organization_id: $organizationId, task_no: $taskNo) {
    ...SingleActionTaskDetails
  }
}
    ${SingleActionTaskDetailsFragmentDoc}`;

/**
 * __useGetSingleActionTaskDetailsQuery__
 *
 * To run a query within a React component, call `useGetSingleActionTaskDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleActionTaskDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleActionTaskDetailsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      taskNo: // value for 'taskNo'
 *   },
 * });
 */
export function useGetSingleActionTaskDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSingleActionTaskDetailsQuery, Types.GetSingleActionTaskDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSingleActionTaskDetailsQuery, Types.GetSingleActionTaskDetailsQueryVariables>(GetSingleActionTaskDetailsDocument, options);
      }
export function useGetSingleActionTaskDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSingleActionTaskDetailsQuery, Types.GetSingleActionTaskDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSingleActionTaskDetailsQuery, Types.GetSingleActionTaskDetailsQueryVariables>(GetSingleActionTaskDetailsDocument, options);
        }
export type GetSingleActionTaskDetailsQueryHookResult = ReturnType<typeof useGetSingleActionTaskDetailsQuery>;
export type GetSingleActionTaskDetailsLazyQueryHookResult = ReturnType<typeof useGetSingleActionTaskDetailsLazyQuery>;
export type GetSingleActionTaskDetailsQueryResult = Apollo.QueryResult<Types.GetSingleActionTaskDetailsQuery, Types.GetSingleActionTaskDetailsQueryVariables>;
export const GetOrganizationUsersDocument = gql`
    query getOrganizationUsers($organizationId: uuid!) {
  users(
    where: {users_organizations_organizations: {organizations_id: {_eq: $organizationId}}}
  ) {
    __typename
    id
    email
    first_name
    last_name
    last_signed_in_at
    created_at
    avatar {
      id
      filepath
    }
    users_organizations_organizations(
      where: {organizations_id: {_eq: $organizationId}}
    ) {
      role
    }
  }
}
    `;

/**
 * __useGetOrganizationUsersQuery__
 *
 * To run a query within a React component, call `useGetOrganizationUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationUsersQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetOrganizationUsersQuery(baseOptions: Apollo.QueryHookOptions<Types.GetOrganizationUsersQuery, Types.GetOrganizationUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetOrganizationUsersQuery, Types.GetOrganizationUsersQueryVariables>(GetOrganizationUsersDocument, options);
      }
export function useGetOrganizationUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetOrganizationUsersQuery, Types.GetOrganizationUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetOrganizationUsersQuery, Types.GetOrganizationUsersQueryVariables>(GetOrganizationUsersDocument, options);
        }
export type GetOrganizationUsersQueryHookResult = ReturnType<typeof useGetOrganizationUsersQuery>;
export type GetOrganizationUsersLazyQueryHookResult = ReturnType<typeof useGetOrganizationUsersLazyQuery>;
export type GetOrganizationUsersQueryResult = Apollo.QueryResult<Types.GetOrganizationUsersQuery, Types.GetOrganizationUsersQueryVariables>;
export const PrefetchCircadianUsersDocument = gql`
    query prefetchCircadianUsers {
  users(order_by: {email: asc}) {
    __typename
    id
    first_name
    last_name
    email
  }
}
    `;

/**
 * __usePrefetchCircadianUsersQuery__
 *
 * To run a query within a React component, call `usePrefetchCircadianUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrefetchCircadianUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrefetchCircadianUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrefetchCircadianUsersQuery(baseOptions?: Apollo.QueryHookOptions<Types.PrefetchCircadianUsersQuery, Types.PrefetchCircadianUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PrefetchCircadianUsersQuery, Types.PrefetchCircadianUsersQueryVariables>(PrefetchCircadianUsersDocument, options);
      }
export function usePrefetchCircadianUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PrefetchCircadianUsersQuery, Types.PrefetchCircadianUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PrefetchCircadianUsersQuery, Types.PrefetchCircadianUsersQueryVariables>(PrefetchCircadianUsersDocument, options);
        }
export type PrefetchCircadianUsersQueryHookResult = ReturnType<typeof usePrefetchCircadianUsersQuery>;
export type PrefetchCircadianUsersLazyQueryHookResult = ReturnType<typeof usePrefetchCircadianUsersLazyQuery>;
export type PrefetchCircadianUsersQueryResult = Apollo.QueryResult<Types.PrefetchCircadianUsersQuery, Types.PrefetchCircadianUsersQueryVariables>;
export const GetUserProfileDocument = gql`
    query getUserProfile($id: uuid!) {
  users_by_pk(id: $id) {
    id
    first_name
    last_name
    email
    avatar {
      filepath
    }
    bio
    certifications
    phone_number
    title
    location
    company
    consents_to_marketing_communication
  }
}
    `;

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserProfileQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserProfileQuery, Types.GetUserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserProfileQuery, Types.GetUserProfileQueryVariables>(GetUserProfileDocument, options);
      }
export function useGetUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserProfileQuery, Types.GetUserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserProfileQuery, Types.GetUserProfileQueryVariables>(GetUserProfileDocument, options);
        }
export type GetUserProfileQueryHookResult = ReturnType<typeof useGetUserProfileQuery>;
export type GetUserProfileLazyQueryHookResult = ReturnType<typeof useGetUserProfileLazyQuery>;
export type GetUserProfileQueryResult = Apollo.QueryResult<Types.GetUserProfileQuery, Types.GetUserProfileQueryVariables>;
export const GetProfileDocument = gql`
    query getProfile($userId: uuid!) {
  users_by_pk(id: $userId) {
    email
    avatar {
      filepath
    }
    first_name
    last_name
  }
}
    `;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetProfileQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProfileQuery, Types.GetProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfileQuery, Types.GetProfileQueryVariables>(GetProfileDocument, options);
      }
export function useGetProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileQuery, Types.GetProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfileQuery, Types.GetProfileQueryVariables>(GetProfileDocument, options);
        }
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<typeof useGetProfileLazyQuery>;
export type GetProfileQueryResult = Apollo.QueryResult<Types.GetProfileQuery, Types.GetProfileQueryVariables>;
export const GetUserByOrganizationIdForUsersTableDocument = gql`
    query getUserByOrganizationIdForUsersTable($organizationId: uuid!) {
  users(
    where: {users_organizations_organizations: {organizations_id: {_eq: $organizationId}}}
  ) {
    ...User
    createdAt: created_at
    users_organizations_organizations(
      where: {organizations_id: {_eq: $organizationId}}
    ) {
      role
    }
    users_organizations_organizations_aggregate {
      aggregate {
        count
      }
    }
    user_auth_strategies_aggregate {
      aggregate {
        count
      }
    }
    created_by {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetUserByOrganizationIdForUsersTableQuery__
 *
 * To run a query within a React component, call `useGetUserByOrganizationIdForUsersTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByOrganizationIdForUsersTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByOrganizationIdForUsersTableQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetUserByOrganizationIdForUsersTableQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserByOrganizationIdForUsersTableQuery, Types.GetUserByOrganizationIdForUsersTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserByOrganizationIdForUsersTableQuery, Types.GetUserByOrganizationIdForUsersTableQueryVariables>(GetUserByOrganizationIdForUsersTableDocument, options);
      }
export function useGetUserByOrganizationIdForUsersTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserByOrganizationIdForUsersTableQuery, Types.GetUserByOrganizationIdForUsersTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserByOrganizationIdForUsersTableQuery, Types.GetUserByOrganizationIdForUsersTableQueryVariables>(GetUserByOrganizationIdForUsersTableDocument, options);
        }
export type GetUserByOrganizationIdForUsersTableQueryHookResult = ReturnType<typeof useGetUserByOrganizationIdForUsersTableQuery>;
export type GetUserByOrganizationIdForUsersTableLazyQueryHookResult = ReturnType<typeof useGetUserByOrganizationIdForUsersTableLazyQuery>;
export type GetUserByOrganizationIdForUsersTableQueryResult = Apollo.QueryResult<Types.GetUserByOrganizationIdForUsersTableQuery, Types.GetUserByOrganizationIdForUsersTableQueryVariables>;
export const GetUserOrganizationsOrganizationsDocument = gql`
    query getUserOrganizationsOrganizations {
  users_organizations_organizations {
    __typename
    organization {
      id
      name
      organization_auth_strategies {
        id
        strategy_name
      }
    }
    users_id
    role
    allowAdminPasswordChange
    user {
      __typename
      ...User
      user_auth_strategies_aggregate {
        aggregate {
          count
        }
      }
      created_at
      created_by {
        ...User
      }
    }
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetUserOrganizationsOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetUserOrganizationsOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOrganizationsOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOrganizationsOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserOrganizationsOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetUserOrganizationsOrganizationsQuery, Types.GetUserOrganizationsOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserOrganizationsOrganizationsQuery, Types.GetUserOrganizationsOrganizationsQueryVariables>(GetUserOrganizationsOrganizationsDocument, options);
      }
export function useGetUserOrganizationsOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserOrganizationsOrganizationsQuery, Types.GetUserOrganizationsOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserOrganizationsOrganizationsQuery, Types.GetUserOrganizationsOrganizationsQueryVariables>(GetUserOrganizationsOrganizationsDocument, options);
        }
export type GetUserOrganizationsOrganizationsQueryHookResult = ReturnType<typeof useGetUserOrganizationsOrganizationsQuery>;
export type GetUserOrganizationsOrganizationsLazyQueryHookResult = ReturnType<typeof useGetUserOrganizationsOrganizationsLazyQuery>;
export type GetUserOrganizationsOrganizationsQueryResult = Apollo.QueryResult<Types.GetUserOrganizationsOrganizationsQuery, Types.GetUserOrganizationsOrganizationsQueryVariables>;
export const GetAssessmentNodeQuestionnaireDataDocument = gql`
    query getAssessmentNodeQuestionnaireData($organizationId: uuid!, $assessmentId: Int!, $nodeId: uuid!) {
  v2_assessment_nodes_by_pk(
    assessment_id: $assessmentId
    node_id: $nodeId
    organization_id: $organizationId
  ) {
    ...V2AssessmentNodesIdentifiers
    assessment {
      __typename
      id
      state
    }
    relevantInspectionCategories: relevantItemCategories(
      where: {item_type: {_eq: inspection}, is_hidden_by_area_tags: {_neq: true}}
    ) {
      ...assessmentNodeItemCategoryDetails
    }
    relevantQuestionnaireCategories: relevantItemCategories(
      where: {item_type: {_eq: interview}, is_hidden_by_area_tags: {_neq: true}}
    ) {
      ...assessmentNodeItemCategoryDetails
    }
    physicalInspectionQuestions(order_by: {ordinal: asc}) {
      organization_id
      node_id
      assessment_id
      item_category_id
      question_id
      question_text
      deficient_response
      criticality
      ordinal
      is_required
      standards {
        ...scenarioQuestionSetStandard
      }
    }
    assessmentItems(
      where: {deleted_at: {_is_null: true}, is_relevant: {_eq: true}, is_hidden_by_area_tags: {_neq: true}}
    ) {
      __typename
      id
      assessment_id
      name
      coordinates
      countermeasure
      is_present
      is_interview_item
      item_category_id
      exterior
      deleted_at
      created_at
      updated_by_id
      description
      recommendation
      recommendation_info
      location_description
      item {
        is_present
      }
      answers {
        __typename
        id
        question_id
        response
        description
        is_flagged
        answered_by_id
        answered_at
        partial_compliance_multiplier
        is_ratification_required
        imported_response
        imported_last_answered_at
        imported_item_id
        ...assessmentAnswerOptions
        activeAnswer {
          ...activeAnswerInfo
        }
      }
    }
    inspectionQuestionnaireItemsQuestionsAndAnswers {
      item_category_id
      __typename
      item_category_name
      question_id
      question_text
      deficient_response
      assessment_item_id
      answer_id
      response
      description
      ...virtualAssessmentItemQuestionAnswerOptions
      criticality_value
      is_flagged
      answered_at
      answered_by_id
      partial_compliance_multiplier
      is_required
      question {
        standards {
          ...scenarioQuestionSetStandard
        }
      }
      is_ratification_required
      imported_response
      imported_last_answered_at
      imported_item_id
      activeAnswer {
        ...activeAnswerInfo
      }
    }
  }
}
    ${V2AssessmentNodesIdentifiersFragmentDoc}
${AssessmentNodeItemCategoryDetailsFragmentDoc}
${ScenarioQuestionSetStandardFragmentDoc}
${AssessmentAnswerOptionsFragmentDoc}
${ActiveAnswerInfoFragmentDoc}
${VirtualAssessmentItemQuestionAnswerOptionsFragmentDoc}`;

/**
 * __useGetAssessmentNodeQuestionnaireDataQuery__
 *
 * To run a query within a React component, call `useGetAssessmentNodeQuestionnaireDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentNodeQuestionnaireDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentNodeQuestionnaireDataQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      assessmentId: // value for 'assessmentId'
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useGetAssessmentNodeQuestionnaireDataQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAssessmentNodeQuestionnaireDataQuery, Types.GetAssessmentNodeQuestionnaireDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAssessmentNodeQuestionnaireDataQuery, Types.GetAssessmentNodeQuestionnaireDataQueryVariables>(GetAssessmentNodeQuestionnaireDataDocument, options);
      }
export function useGetAssessmentNodeQuestionnaireDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAssessmentNodeQuestionnaireDataQuery, Types.GetAssessmentNodeQuestionnaireDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAssessmentNodeQuestionnaireDataQuery, Types.GetAssessmentNodeQuestionnaireDataQueryVariables>(GetAssessmentNodeQuestionnaireDataDocument, options);
        }
export type GetAssessmentNodeQuestionnaireDataQueryHookResult = ReturnType<typeof useGetAssessmentNodeQuestionnaireDataQuery>;
export type GetAssessmentNodeQuestionnaireDataLazyQueryHookResult = ReturnType<typeof useGetAssessmentNodeQuestionnaireDataLazyQuery>;
export type GetAssessmentNodeQuestionnaireDataQueryResult = Apollo.QueryResult<Types.GetAssessmentNodeQuestionnaireDataQuery, Types.GetAssessmentNodeQuestionnaireDataQueryVariables>;
export const GetAssessmentTemplateForAssessmentCreationDocument = gql`
    query getAssessmentTemplateForAssessmentCreation($organizationId: uuid!, $assessmentTemplateId: Int!) {
  v2_assessment_templates_by_pk(
    organization_id: $organizationId
    id: $assessmentTemplateId
  ) {
    ...assessmentTemplateInfoForAssessmentCreation
  }
}
    ${AssessmentTemplateInfoForAssessmentCreationFragmentDoc}`;

/**
 * __useGetAssessmentTemplateForAssessmentCreationQuery__
 *
 * To run a query within a React component, call `useGetAssessmentTemplateForAssessmentCreationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentTemplateForAssessmentCreationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentTemplateForAssessmentCreationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      assessmentTemplateId: // value for 'assessmentTemplateId'
 *   },
 * });
 */
export function useGetAssessmentTemplateForAssessmentCreationQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAssessmentTemplateForAssessmentCreationQuery, Types.GetAssessmentTemplateForAssessmentCreationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAssessmentTemplateForAssessmentCreationQuery, Types.GetAssessmentTemplateForAssessmentCreationQueryVariables>(GetAssessmentTemplateForAssessmentCreationDocument, options);
      }
export function useGetAssessmentTemplateForAssessmentCreationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAssessmentTemplateForAssessmentCreationQuery, Types.GetAssessmentTemplateForAssessmentCreationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAssessmentTemplateForAssessmentCreationQuery, Types.GetAssessmentTemplateForAssessmentCreationQueryVariables>(GetAssessmentTemplateForAssessmentCreationDocument, options);
        }
export type GetAssessmentTemplateForAssessmentCreationQueryHookResult = ReturnType<typeof useGetAssessmentTemplateForAssessmentCreationQuery>;
export type GetAssessmentTemplateForAssessmentCreationLazyQueryHookResult = ReturnType<typeof useGetAssessmentTemplateForAssessmentCreationLazyQuery>;
export type GetAssessmentTemplateForAssessmentCreationQueryResult = Apollo.QueryResult<Types.GetAssessmentTemplateForAssessmentCreationQuery, Types.GetAssessmentTemplateForAssessmentCreationQueryVariables>;
export const GetLocationScenarioAssessmentFormInfoDocument = gql`
    query getLocationScenarioAssessmentFormInfo($organizationId: uuid!) {
  v2_assessment_templates(where: {organization_id: {_eq: $organizationId}}) {
    ...assessmentTemplateInfoForAssessmentCreation
  }
}
    ${AssessmentTemplateInfoForAssessmentCreationFragmentDoc}`;

/**
 * __useGetLocationScenarioAssessmentFormInfoQuery__
 *
 * To run a query within a React component, call `useGetLocationScenarioAssessmentFormInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationScenarioAssessmentFormInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationScenarioAssessmentFormInfoQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetLocationScenarioAssessmentFormInfoQuery(baseOptions: Apollo.QueryHookOptions<Types.GetLocationScenarioAssessmentFormInfoQuery, Types.GetLocationScenarioAssessmentFormInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLocationScenarioAssessmentFormInfoQuery, Types.GetLocationScenarioAssessmentFormInfoQueryVariables>(GetLocationScenarioAssessmentFormInfoDocument, options);
      }
export function useGetLocationScenarioAssessmentFormInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLocationScenarioAssessmentFormInfoQuery, Types.GetLocationScenarioAssessmentFormInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLocationScenarioAssessmentFormInfoQuery, Types.GetLocationScenarioAssessmentFormInfoQueryVariables>(GetLocationScenarioAssessmentFormInfoDocument, options);
        }
export type GetLocationScenarioAssessmentFormInfoQueryHookResult = ReturnType<typeof useGetLocationScenarioAssessmentFormInfoQuery>;
export type GetLocationScenarioAssessmentFormInfoLazyQueryHookResult = ReturnType<typeof useGetLocationScenarioAssessmentFormInfoLazyQuery>;
export type GetLocationScenarioAssessmentFormInfoQueryResult = Apollo.QueryResult<Types.GetLocationScenarioAssessmentFormInfoQuery, Types.GetLocationScenarioAssessmentFormInfoQueryVariables>;
export const GetAssessmentTemplateSummaryForOrgDocument = gql`
    query getAssessmentTemplateSummaryForOrg($organizationId: uuid!) {
  v2_assessment_templates(
    where: {organization_id: {_eq: $organizationId}}
    order_by: {created_at: desc}
  ) {
    organization_id
    id
    name
    description
    created_at
    assessments_aggregate {
      aggregate {
        count
      }
    }
    previousAssessmentTemplates {
      id
      name
    }
    nextAssessmentTemplate {
      id
      name
    }
    latestTemplate {
      id
      name
    }
    allPreviousTemplates {
      id
      name
    }
  }
}
    `;

/**
 * __useGetAssessmentTemplateSummaryForOrgQuery__
 *
 * To run a query within a React component, call `useGetAssessmentTemplateSummaryForOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentTemplateSummaryForOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentTemplateSummaryForOrgQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetAssessmentTemplateSummaryForOrgQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAssessmentTemplateSummaryForOrgQuery, Types.GetAssessmentTemplateSummaryForOrgQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAssessmentTemplateSummaryForOrgQuery, Types.GetAssessmentTemplateSummaryForOrgQueryVariables>(GetAssessmentTemplateSummaryForOrgDocument, options);
      }
export function useGetAssessmentTemplateSummaryForOrgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAssessmentTemplateSummaryForOrgQuery, Types.GetAssessmentTemplateSummaryForOrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAssessmentTemplateSummaryForOrgQuery, Types.GetAssessmentTemplateSummaryForOrgQueryVariables>(GetAssessmentTemplateSummaryForOrgDocument, options);
        }
export type GetAssessmentTemplateSummaryForOrgQueryHookResult = ReturnType<typeof useGetAssessmentTemplateSummaryForOrgQuery>;
export type GetAssessmentTemplateSummaryForOrgLazyQueryHookResult = ReturnType<typeof useGetAssessmentTemplateSummaryForOrgLazyQuery>;
export type GetAssessmentTemplateSummaryForOrgQueryResult = Apollo.QueryResult<Types.GetAssessmentTemplateSummaryForOrgQuery, Types.GetAssessmentTemplateSummaryForOrgQueryVariables>;
export const GetAssessmentTemplateDetailsDocument = gql`
    query getAssessmentTemplateDetails($organizationId: uuid!, $id: Int!) {
  v2_assessment_templates_by_pk(organization_id: $organizationId, id: $id) {
    id
    name
    description
    created_at
    nextAssessmentTemplate {
      id
      name
      description
      questionSets {
        questionSet {
          id
          name
          isPrimary
          scenario {
            id
            name
          }
        }
      }
    }
    scored_layer_id
    previousAssessmentTemplates {
      id
      name
      description
    }
    questionSets {
      questionSet {
        id
        name
        isPrimary
        scenario {
          id
          name
        }
        file {
          id
          filepath
          original_filename
        }
        inspectionQuestionnaireAggregate: questions_aggregate(
          where: {question: {question_type: {_eq: "interview"}}}
          distinct_on: question_id
        ) {
          aggregate {
            count
          }
        }
        physicalInspectionAggregate: questions_aggregate(
          where: {question: {question_type: {_eq: "inspection"}}}
          distinct_on: question_id
        ) {
          aggregate {
            count
          }
        }
        tags {
          layer_id
          layer_name
          layer_tag_id
          layer_tag_name
        }
      }
    }
    assessments {
      id
      name
      state
      start_date
      due_date
      schedule_id
    }
    latestTemplate {
      id
      name
      description
      questionSets {
        questionSet {
          id
          name
          isPrimary
          scenario {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAssessmentTemplateDetailsQuery__
 *
 * To run a query within a React component, call `useGetAssessmentTemplateDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentTemplateDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentTemplateDetailsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssessmentTemplateDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAssessmentTemplateDetailsQuery, Types.GetAssessmentTemplateDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAssessmentTemplateDetailsQuery, Types.GetAssessmentTemplateDetailsQueryVariables>(GetAssessmentTemplateDetailsDocument, options);
      }
export function useGetAssessmentTemplateDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAssessmentTemplateDetailsQuery, Types.GetAssessmentTemplateDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAssessmentTemplateDetailsQuery, Types.GetAssessmentTemplateDetailsQueryVariables>(GetAssessmentTemplateDetailsDocument, options);
        }
export type GetAssessmentTemplateDetailsQueryHookResult = ReturnType<typeof useGetAssessmentTemplateDetailsQuery>;
export type GetAssessmentTemplateDetailsLazyQueryHookResult = ReturnType<typeof useGetAssessmentTemplateDetailsLazyQuery>;
export type GetAssessmentTemplateDetailsQueryResult = Apollo.QueryResult<Types.GetAssessmentTemplateDetailsQuery, Types.GetAssessmentTemplateDetailsQueryVariables>;
export const GetAllRelatedAssessmentTemplatesFromScenarioDocument = gql`
    query getAllRelatedAssessmentTemplatesFromScenario($scenarioIds: [Int!]!) {
  v2_assessment_templates(
    where: {questionSets: {questionSet: {scenario: {id: {_in: $scenarioIds}}}}}
  ) {
    id
    name
    next_assessment_template_id
    description
    questionSets {
      scenario_question_set_id
      organization_id
      questionSet {
        organization_id
        id
        isPrimary
        name
        scenario {
          organization_id
          id
          name
          description
        }
      }
    }
    latestTemplate {
      id
      name
    }
  }
}
    `;

/**
 * __useGetAllRelatedAssessmentTemplatesFromScenarioQuery__
 *
 * To run a query within a React component, call `useGetAllRelatedAssessmentTemplatesFromScenarioQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRelatedAssessmentTemplatesFromScenarioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRelatedAssessmentTemplatesFromScenarioQuery({
 *   variables: {
 *      scenarioIds: // value for 'scenarioIds'
 *   },
 * });
 */
export function useGetAllRelatedAssessmentTemplatesFromScenarioQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAllRelatedAssessmentTemplatesFromScenarioQuery, Types.GetAllRelatedAssessmentTemplatesFromScenarioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAllRelatedAssessmentTemplatesFromScenarioQuery, Types.GetAllRelatedAssessmentTemplatesFromScenarioQueryVariables>(GetAllRelatedAssessmentTemplatesFromScenarioDocument, options);
      }
export function useGetAllRelatedAssessmentTemplatesFromScenarioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllRelatedAssessmentTemplatesFromScenarioQuery, Types.GetAllRelatedAssessmentTemplatesFromScenarioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAllRelatedAssessmentTemplatesFromScenarioQuery, Types.GetAllRelatedAssessmentTemplatesFromScenarioQueryVariables>(GetAllRelatedAssessmentTemplatesFromScenarioDocument, options);
        }
export type GetAllRelatedAssessmentTemplatesFromScenarioQueryHookResult = ReturnType<typeof useGetAllRelatedAssessmentTemplatesFromScenarioQuery>;
export type GetAllRelatedAssessmentTemplatesFromScenarioLazyQueryHookResult = ReturnType<typeof useGetAllRelatedAssessmentTemplatesFromScenarioLazyQuery>;
export type GetAllRelatedAssessmentTemplatesFromScenarioQueryResult = Apollo.QueryResult<Types.GetAllRelatedAssessmentTemplatesFromScenarioQuery, Types.GetAllRelatedAssessmentTemplatesFromScenarioQueryVariables>;
export const GetRelatedAssessmentTemplatesDocument = gql`
    query getRelatedAssessmentTemplates($scenarioIds: [Int!]!, $scoredLayerId: uuid) {
  v2_assessment_templates(
    where: {questionSets: {questionSet: {scenario: {id: {_in: $scenarioIds}}}}, next_assessment_template_id: {_is_null: true}, scored_layer_id: {_eq: $scoredLayerId}}
  ) {
    id
    name
    next_assessment_template_id
    description
    scoredLayers {
      id
      layerDepth
    }
    scored_layer_id
    questionSets {
      scenario_question_set_id
      organization_id
      questionSet {
        organization_id
        id
        isPrimary
        name
        scenario {
          organization_id
          id
          name
          description
          locationTypes {
            organization_id
            scenario_id
            layer_id
            locationType {
              id
              layerDepth
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetRelatedAssessmentTemplatesQuery__
 *
 * To run a query within a React component, call `useGetRelatedAssessmentTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRelatedAssessmentTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRelatedAssessmentTemplatesQuery({
 *   variables: {
 *      scenarioIds: // value for 'scenarioIds'
 *      scoredLayerId: // value for 'scoredLayerId'
 *   },
 * });
 */
export function useGetRelatedAssessmentTemplatesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetRelatedAssessmentTemplatesQuery, Types.GetRelatedAssessmentTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetRelatedAssessmentTemplatesQuery, Types.GetRelatedAssessmentTemplatesQueryVariables>(GetRelatedAssessmentTemplatesDocument, options);
      }
export function useGetRelatedAssessmentTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetRelatedAssessmentTemplatesQuery, Types.GetRelatedAssessmentTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetRelatedAssessmentTemplatesQuery, Types.GetRelatedAssessmentTemplatesQueryVariables>(GetRelatedAssessmentTemplatesDocument, options);
        }
export type GetRelatedAssessmentTemplatesQueryHookResult = ReturnType<typeof useGetRelatedAssessmentTemplatesQuery>;
export type GetRelatedAssessmentTemplatesLazyQueryHookResult = ReturnType<typeof useGetRelatedAssessmentTemplatesLazyQuery>;
export type GetRelatedAssessmentTemplatesQueryResult = Apollo.QueryResult<Types.GetRelatedAssessmentTemplatesQuery, Types.GetRelatedAssessmentTemplatesQueryVariables>;
export const GetRelatedAssessmentTemplatesFromScenarioDocument = gql`
    query getRelatedAssessmentTemplatesFromScenario($scenarioIds: [Int!]!) {
  v2_assessment_templates(
    where: {questionSets: {questionSet: {scenario: {id: {_in: $scenarioIds}}}}, next_assessment_template_id: {_is_null: true}}
  ) {
    id
    name
    next_assessment_template_id
    description
    scored_layer_id
    questionSets {
      scenario_question_set_id
      organization_id
      questionSet {
        organization_id
        id
        isPrimary
        name
        scenario {
          organization_id
          id
          name
          description
          locationTypes {
            organization_id
            scenario_id
            layer_id
            locationType {
              id
              layerDepth
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetRelatedAssessmentTemplatesFromScenarioQuery__
 *
 * To run a query within a React component, call `useGetRelatedAssessmentTemplatesFromScenarioQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRelatedAssessmentTemplatesFromScenarioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRelatedAssessmentTemplatesFromScenarioQuery({
 *   variables: {
 *      scenarioIds: // value for 'scenarioIds'
 *   },
 * });
 */
export function useGetRelatedAssessmentTemplatesFromScenarioQuery(baseOptions: Apollo.QueryHookOptions<Types.GetRelatedAssessmentTemplatesFromScenarioQuery, Types.GetRelatedAssessmentTemplatesFromScenarioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetRelatedAssessmentTemplatesFromScenarioQuery, Types.GetRelatedAssessmentTemplatesFromScenarioQueryVariables>(GetRelatedAssessmentTemplatesFromScenarioDocument, options);
      }
export function useGetRelatedAssessmentTemplatesFromScenarioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetRelatedAssessmentTemplatesFromScenarioQuery, Types.GetRelatedAssessmentTemplatesFromScenarioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetRelatedAssessmentTemplatesFromScenarioQuery, Types.GetRelatedAssessmentTemplatesFromScenarioQueryVariables>(GetRelatedAssessmentTemplatesFromScenarioDocument, options);
        }
export type GetRelatedAssessmentTemplatesFromScenarioQueryHookResult = ReturnType<typeof useGetRelatedAssessmentTemplatesFromScenarioQuery>;
export type GetRelatedAssessmentTemplatesFromScenarioLazyQueryHookResult = ReturnType<typeof useGetRelatedAssessmentTemplatesFromScenarioLazyQuery>;
export type GetRelatedAssessmentTemplatesFromScenarioQueryResult = Apollo.QueryResult<Types.GetRelatedAssessmentTemplatesFromScenarioQuery, Types.GetRelatedAssessmentTemplatesFromScenarioQueryVariables>;
export const GetAssessmentListByOrgIdDocument = gql`
    query getAssessmentListByOrgId($organizationId: uuid!) {
  v2_assessments(
    where: {organization_id: {_eq: $organizationId}}
    order_by: {created_at: desc}
  ) {
    organization_id
    id
    name
  }
}
    `;

/**
 * __useGetAssessmentListByOrgIdQuery__
 *
 * To run a query within a React component, call `useGetAssessmentListByOrgIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentListByOrgIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentListByOrgIdQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetAssessmentListByOrgIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAssessmentListByOrgIdQuery, Types.GetAssessmentListByOrgIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAssessmentListByOrgIdQuery, Types.GetAssessmentListByOrgIdQueryVariables>(GetAssessmentListByOrgIdDocument, options);
      }
export function useGetAssessmentListByOrgIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAssessmentListByOrgIdQuery, Types.GetAssessmentListByOrgIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAssessmentListByOrgIdQuery, Types.GetAssessmentListByOrgIdQueryVariables>(GetAssessmentListByOrgIdDocument, options);
        }
export type GetAssessmentListByOrgIdQueryHookResult = ReturnType<typeof useGetAssessmentListByOrgIdQuery>;
export type GetAssessmentListByOrgIdLazyQueryHookResult = ReturnType<typeof useGetAssessmentListByOrgIdLazyQuery>;
export type GetAssessmentListByOrgIdQueryResult = Apollo.QueryResult<Types.GetAssessmentListByOrgIdQuery, Types.GetAssessmentListByOrgIdQueryVariables>;
export const GetAssessmentNarrativeDocument = gql`
    query getAssessmentNarrative($organizationId: uuid!, $assessmentId: Int!) {
  v2_assessments_by_pk(organization_id: $organizationId, id: $assessmentId) {
    id
    organization_id
    findings
    notes
    background
    recommendations
  }
}
    `;

/**
 * __useGetAssessmentNarrativeQuery__
 *
 * To run a query within a React component, call `useGetAssessmentNarrativeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentNarrativeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentNarrativeQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      assessmentId: // value for 'assessmentId'
 *   },
 * });
 */
export function useGetAssessmentNarrativeQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAssessmentNarrativeQuery, Types.GetAssessmentNarrativeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAssessmentNarrativeQuery, Types.GetAssessmentNarrativeQueryVariables>(GetAssessmentNarrativeDocument, options);
      }
export function useGetAssessmentNarrativeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAssessmentNarrativeQuery, Types.GetAssessmentNarrativeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAssessmentNarrativeQuery, Types.GetAssessmentNarrativeQueryVariables>(GetAssessmentNarrativeDocument, options);
        }
export type GetAssessmentNarrativeQueryHookResult = ReturnType<typeof useGetAssessmentNarrativeQuery>;
export type GetAssessmentNarrativeLazyQueryHookResult = ReturnType<typeof useGetAssessmentNarrativeLazyQuery>;
export type GetAssessmentNarrativeQueryResult = Apollo.QueryResult<Types.GetAssessmentNarrativeQuery, Types.GetAssessmentNarrativeQueryVariables>;
export const GetPreviousAssessmentAndTemplateInfoDocument = gql`
    query getPreviousAssessmentAndTemplateInfo($organizationId: uuid!, $assessmentId: Int!) {
  v2_assessments_by_pk(organization_id: $organizationId, id: $assessmentId) {
    id
    name
    organization_id
    description
    ...AssessmentAssignees
    ...AssessmentSubscribers
    locations {
      ...V2AssessmentNodesIdentifiers
      node {
        id
        mpath
      }
    }
    scored_node_id
  }
}
    ${AssessmentAssigneesFragmentDoc}
${AssessmentSubscribersFragmentDoc}
${V2AssessmentNodesIdentifiersFragmentDoc}`;

/**
 * __useGetPreviousAssessmentAndTemplateInfoQuery__
 *
 * To run a query within a React component, call `useGetPreviousAssessmentAndTemplateInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPreviousAssessmentAndTemplateInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPreviousAssessmentAndTemplateInfoQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      assessmentId: // value for 'assessmentId'
 *   },
 * });
 */
export function useGetPreviousAssessmentAndTemplateInfoQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPreviousAssessmentAndTemplateInfoQuery, Types.GetPreviousAssessmentAndTemplateInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPreviousAssessmentAndTemplateInfoQuery, Types.GetPreviousAssessmentAndTemplateInfoQueryVariables>(GetPreviousAssessmentAndTemplateInfoDocument, options);
      }
export function useGetPreviousAssessmentAndTemplateInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPreviousAssessmentAndTemplateInfoQuery, Types.GetPreviousAssessmentAndTemplateInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPreviousAssessmentAndTemplateInfoQuery, Types.GetPreviousAssessmentAndTemplateInfoQueryVariables>(GetPreviousAssessmentAndTemplateInfoDocument, options);
        }
export type GetPreviousAssessmentAndTemplateInfoQueryHookResult = ReturnType<typeof useGetPreviousAssessmentAndTemplateInfoQuery>;
export type GetPreviousAssessmentAndTemplateInfoLazyQueryHookResult = ReturnType<typeof useGetPreviousAssessmentAndTemplateInfoLazyQuery>;
export type GetPreviousAssessmentAndTemplateInfoQueryResult = Apollo.QueryResult<Types.GetPreviousAssessmentAndTemplateInfoQuery, Types.GetPreviousAssessmentAndTemplateInfoQueryVariables>;
export const GetScenarioAssessmentDetailsDocument = gql`
    query getScenarioAssessmentDetails($organizationId: uuid!, $assessmentId: Int!) {
  v2_assessments_by_pk(id: $assessmentId, organization_id: $organizationId) {
    id
    name
    description
    state
    start_date
    due_date
    import_active_answers
    is_compliant_ratification_required
    is_deficient_ratification_required
    is_n_a_ratification_required
    is_n_o_ratification_required
    locations {
      ...V2AssessmentNodesIdentifiers
      relevantItemCategories {
        item_type
        itemCategory {
          id
          areaTag {
            id
            name
          }
        }
      }
      progressAggregates {
        physical_progress
        inspection_progress
      }
      areaTags {
        id
        area_tag_id
        organization_id
        node_id
        is_present
        areaTag {
          id
          name
          organization_id
        }
      }
      node {
        ...assessmentNodeInformation
      }
    }
    assessmentTemplate {
      id
      questionSets {
        questionSet {
          id
          scenario {
            id
            is_partial_compliance_allowed
          }
        }
      }
    }
  }
}
    ${V2AssessmentNodesIdentifiersFragmentDoc}
${AssessmentNodeInformationFragmentDoc}`;

/**
 * __useGetScenarioAssessmentDetailsQuery__
 *
 * To run a query within a React component, call `useGetScenarioAssessmentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenarioAssessmentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenarioAssessmentDetailsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      assessmentId: // value for 'assessmentId'
 *   },
 * });
 */
export function useGetScenarioAssessmentDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetScenarioAssessmentDetailsQuery, Types.GetScenarioAssessmentDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetScenarioAssessmentDetailsQuery, Types.GetScenarioAssessmentDetailsQueryVariables>(GetScenarioAssessmentDetailsDocument, options);
      }
export function useGetScenarioAssessmentDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetScenarioAssessmentDetailsQuery, Types.GetScenarioAssessmentDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetScenarioAssessmentDetailsQuery, Types.GetScenarioAssessmentDetailsQueryVariables>(GetScenarioAssessmentDetailsDocument, options);
        }
export type GetScenarioAssessmentDetailsQueryHookResult = ReturnType<typeof useGetScenarioAssessmentDetailsQuery>;
export type GetScenarioAssessmentDetailsLazyQueryHookResult = ReturnType<typeof useGetScenarioAssessmentDetailsLazyQuery>;
export type GetScenarioAssessmentDetailsQueryResult = Apollo.QueryResult<Types.GetScenarioAssessmentDetailsQuery, Types.GetScenarioAssessmentDetailsQueryVariables>;
export const GetLocationAssessmentsDocument = gql`
    query getLocationAssessments($filterExp: v2_assessments_bool_exp) {
  v2_assessments(where: $filterExp) {
    id
    name
    state
    start_date
    due_date
    created_at
    completed_at
    is_opened
    open_range
    scheduleCadence {
      ...ScheduledAssessmentScheduledRequiredFields
      status
    }
    progressAggregates {
      inspection_progress
    }
    assessment_template_id
    assessmentTemplate {
      name
      questionSets {
        questionSet {
          id
          scenario_id
          scenario {
            name
            id
          }
        }
      }
    }
    ...AssessmentAssignees
  }
}
    ${ScheduledAssessmentScheduledRequiredFieldsFragmentDoc}
${AssessmentAssigneesFragmentDoc}`;

/**
 * __useGetLocationAssessmentsQuery__
 *
 * To run a query within a React component, call `useGetLocationAssessmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationAssessmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationAssessmentsQuery({
 *   variables: {
 *      filterExp: // value for 'filterExp'
 *   },
 * });
 */
export function useGetLocationAssessmentsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetLocationAssessmentsQuery, Types.GetLocationAssessmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLocationAssessmentsQuery, Types.GetLocationAssessmentsQueryVariables>(GetLocationAssessmentsDocument, options);
      }
export function useGetLocationAssessmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLocationAssessmentsQuery, Types.GetLocationAssessmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLocationAssessmentsQuery, Types.GetLocationAssessmentsQueryVariables>(GetLocationAssessmentsDocument, options);
        }
export type GetLocationAssessmentsQueryHookResult = ReturnType<typeof useGetLocationAssessmentsQuery>;
export type GetLocationAssessmentsLazyQueryHookResult = ReturnType<typeof useGetLocationAssessmentsLazyQuery>;
export type GetLocationAssessmentsQueryResult = Apollo.QueryResult<Types.GetLocationAssessmentsQuery, Types.GetLocationAssessmentsQueryVariables>;
export const GetAssessmentNodesForTreeViewDocument = gql`
    query getAssessmentNodesForTreeView($organizationId: uuid!, $assessmentId: Int!) {
  v2_assessments_by_pk(id: $assessmentId, organization_id: $organizationId) {
    id
    name
    state
    locations {
      ...V2AssessmentNodesIdentifiers
      relevantItemCategories(distinct_on: item_type) {
        item_type
      }
      progressAggregates {
        physical_progress
        inspection_progress
      }
      node {
        __typename
        id
        name
        mpath
        parent_id
      }
    }
  }
}
    ${V2AssessmentNodesIdentifiersFragmentDoc}`;

/**
 * __useGetAssessmentNodesForTreeViewQuery__
 *
 * To run a query within a React component, call `useGetAssessmentNodesForTreeViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentNodesForTreeViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentNodesForTreeViewQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      assessmentId: // value for 'assessmentId'
 *   },
 * });
 */
export function useGetAssessmentNodesForTreeViewQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAssessmentNodesForTreeViewQuery, Types.GetAssessmentNodesForTreeViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAssessmentNodesForTreeViewQuery, Types.GetAssessmentNodesForTreeViewQueryVariables>(GetAssessmentNodesForTreeViewDocument, options);
      }
export function useGetAssessmentNodesForTreeViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAssessmentNodesForTreeViewQuery, Types.GetAssessmentNodesForTreeViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAssessmentNodesForTreeViewQuery, Types.GetAssessmentNodesForTreeViewQueryVariables>(GetAssessmentNodesForTreeViewDocument, options);
        }
export type GetAssessmentNodesForTreeViewQueryHookResult = ReturnType<typeof useGetAssessmentNodesForTreeViewQuery>;
export type GetAssessmentNodesForTreeViewLazyQueryHookResult = ReturnType<typeof useGetAssessmentNodesForTreeViewLazyQuery>;
export type GetAssessmentNodesForTreeViewQueryResult = Apollo.QueryResult<Types.GetAssessmentNodesForTreeViewQuery, Types.GetAssessmentNodesForTreeViewQueryVariables>;
export const GetAssessmentItemsDocument = gql`
    query getAssessmentItems($organizationId: uuid!, $assessmentId: Int!) {
  v2_assessments_by_pk(id: $assessmentId, organization_id: $organizationId) {
    id
    name
    state
    description
    notes
    items(where: {deleted_at: {_is_null: true}, is_relevant: {_eq: true}}) {
      assessment_item_id: id
      item {
        id
        answer_aggregates {
          ...itemAnswerAggregatesKeyFields
          rollup_label
        }
      }
      name
      is_interview_item
      description: location_description
      notes: description
      is_present
      node_id
      is_deficient
      is_relevant
      is_hidden_by_area_tags
      files {
        file_id
        assessment_item_id
        file {
          id
          filepath
          original_filename
          ...image
        }
      }
      item_category {
        file {
          id
          filepath
          original_filename
        }
      }
      node {
        layer_id
        id
      }
      questions_and_answers(order_by: {ordinal: asc}) {
        id: answer_id
        item_id: assessment_item_id
        response
        is_flagged
        criticality_value
        deficient_response
        question_id
        item_category_id
        question_text
        response
        description
        ...virtualAnswerQuestionOptions
        partial_compliance_multiplier
        answered_at
        answers_files {
          ...AnswersFilesIdentifiers
          file {
            id
            filepath
            original_filename
            ...image
          }
        }
      }
    }
  }
}
    ${ItemAnswerAggregatesKeyFieldsFragmentDoc}
${ImageFragmentDoc}
${VirtualAnswerQuestionOptionsFragmentDoc}
${AnswersFilesIdentifiersFragmentDoc}`;

/**
 * __useGetAssessmentItemsQuery__
 *
 * To run a query within a React component, call `useGetAssessmentItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentItemsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      assessmentId: // value for 'assessmentId'
 *   },
 * });
 */
export function useGetAssessmentItemsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAssessmentItemsQuery, Types.GetAssessmentItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAssessmentItemsQuery, Types.GetAssessmentItemsQueryVariables>(GetAssessmentItemsDocument, options);
      }
export function useGetAssessmentItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAssessmentItemsQuery, Types.GetAssessmentItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAssessmentItemsQuery, Types.GetAssessmentItemsQueryVariables>(GetAssessmentItemsDocument, options);
        }
export type GetAssessmentItemsQueryHookResult = ReturnType<typeof useGetAssessmentItemsQuery>;
export type GetAssessmentItemsLazyQueryHookResult = ReturnType<typeof useGetAssessmentItemsLazyQuery>;
export type GetAssessmentItemsQueryResult = Apollo.QueryResult<Types.GetAssessmentItemsQuery, Types.GetAssessmentItemsQueryVariables>;
export const GetAssessmentLocationReportFilesClientSideDocument = gql`
    query getAssessmentLocationReportFilesClientSide($organizationId: uuid!, $assessmentId: Int!) {
  v2_assessments_by_pk(organization_id: $organizationId, id: $assessmentId) {
    scoredNode {
      id
      nodes_files(where: {file_type: {_eq: display_photo}}) {
        file {
          id
          filepath
          original_filename
        }
        file_type
      }
      node_mapbox_map {
        file {
          id
          filepath
          original_filename
        }
      }
    }
  }
}
    `;

/**
 * __useGetAssessmentLocationReportFilesClientSideQuery__
 *
 * To run a query within a React component, call `useGetAssessmentLocationReportFilesClientSideQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentLocationReportFilesClientSideQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentLocationReportFilesClientSideQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      assessmentId: // value for 'assessmentId'
 *   },
 * });
 */
export function useGetAssessmentLocationReportFilesClientSideQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAssessmentLocationReportFilesClientSideQuery, Types.GetAssessmentLocationReportFilesClientSideQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAssessmentLocationReportFilesClientSideQuery, Types.GetAssessmentLocationReportFilesClientSideQueryVariables>(GetAssessmentLocationReportFilesClientSideDocument, options);
      }
export function useGetAssessmentLocationReportFilesClientSideLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAssessmentLocationReportFilesClientSideQuery, Types.GetAssessmentLocationReportFilesClientSideQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAssessmentLocationReportFilesClientSideQuery, Types.GetAssessmentLocationReportFilesClientSideQueryVariables>(GetAssessmentLocationReportFilesClientSideDocument, options);
        }
export type GetAssessmentLocationReportFilesClientSideQueryHookResult = ReturnType<typeof useGetAssessmentLocationReportFilesClientSideQuery>;
export type GetAssessmentLocationReportFilesClientSideLazyQueryHookResult = ReturnType<typeof useGetAssessmentLocationReportFilesClientSideLazyQuery>;
export type GetAssessmentLocationReportFilesClientSideQueryResult = Apollo.QueryResult<Types.GetAssessmentLocationReportFilesClientSideQuery, Types.GetAssessmentLocationReportFilesClientSideQueryVariables>;
export const GetTemplateLocationQuestionsAndActiveAnswersDocument = gql`
    query getTemplateLocationQuestionsAndActiveAnswers($templateId: Int!, $nodeIds: jsonb) {
  fn_active_answers_summary(args: {template_id: $templateId, node_ids: $nodeIds}) {
    question_id
    response
    is_deficient
    node_id
    item {
      node_id
      item_category_id
      is_interview_item
    }
    question {
      deficient_answer
    }
  }
}
    `;

/**
 * __useGetTemplateLocationQuestionsAndActiveAnswersQuery__
 *
 * To run a query within a React component, call `useGetTemplateLocationQuestionsAndActiveAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateLocationQuestionsAndActiveAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateLocationQuestionsAndActiveAnswersQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      nodeIds: // value for 'nodeIds'
 *   },
 * });
 */
export function useGetTemplateLocationQuestionsAndActiveAnswersQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTemplateLocationQuestionsAndActiveAnswersQuery, Types.GetTemplateLocationQuestionsAndActiveAnswersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTemplateLocationQuestionsAndActiveAnswersQuery, Types.GetTemplateLocationQuestionsAndActiveAnswersQueryVariables>(GetTemplateLocationQuestionsAndActiveAnswersDocument, options);
      }
export function useGetTemplateLocationQuestionsAndActiveAnswersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTemplateLocationQuestionsAndActiveAnswersQuery, Types.GetTemplateLocationQuestionsAndActiveAnswersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTemplateLocationQuestionsAndActiveAnswersQuery, Types.GetTemplateLocationQuestionsAndActiveAnswersQueryVariables>(GetTemplateLocationQuestionsAndActiveAnswersDocument, options);
        }
export type GetTemplateLocationQuestionsAndActiveAnswersQueryHookResult = ReturnType<typeof useGetTemplateLocationQuestionsAndActiveAnswersQuery>;
export type GetTemplateLocationQuestionsAndActiveAnswersLazyQueryHookResult = ReturnType<typeof useGetTemplateLocationQuestionsAndActiveAnswersLazyQuery>;
export type GetTemplateLocationQuestionsAndActiveAnswersQueryResult = Apollo.QueryResult<Types.GetTemplateLocationQuestionsAndActiveAnswersQuery, Types.GetTemplateLocationQuestionsAndActiveAnswersQueryVariables>;
export const GetLocationAndChildrenDeficienciesDocument = gql`
    query getLocationAndChildrenDeficiencies($nodeId: uuid!, $includeChildren: Boolean = true) {
  answers: fn_get_location_and_children_answers_by_node_id(
    args: {node_id: $nodeId, include_children: $includeChildren}
    where: {is_deficient: {_eq: true}, _or: [{is_accepted: {_eq: false}}, {is_accepted: {_is_null: true}}]}
  ) {
    organization_id
    location_id
    location_name
    location_mpath
    item_id
    item_name
    item {
      item_category_id
      ...ItemDetails
    }
    is_interview_item
    item_category_id
    item_category_name
    answer_id
    question_text
    response
    is_deficient
    partial_compliance_multiplier
    description
    scenarioQuestionSets(where: {standard: {standard_name: {_is_null: false}}}) {
      ...ScenarioQuestionSetQuestionIdentifiers
      standard {
        ...scenarioQuestionSetStandard
      }
    }
    answered_at
    updated_at
    is_flagged
    criticality_value
    cost
    total_cost
    item_is_present
    question_id
    deficient_response
    files {
      file {
        ...image
      }
    }
    action_deficiencies_aggregate {
      aggregate {
        count
      }
    }
    question {
      scenarioQuestions {
        ...ScenarioQuestionSetQuestionIdentifiers
        questionSet {
          id
          scenario_id
        }
      }
    }
    ...virtualLocationAnswerOptions
  }
}
    ${ItemDetailsFragmentDoc}
${ScenarioQuestionSetQuestionIdentifiersFragmentDoc}
${ScenarioQuestionSetStandardFragmentDoc}
${ImageFragmentDoc}
${VirtualLocationAnswerOptionsFragmentDoc}`;

/**
 * __useGetLocationAndChildrenDeficienciesQuery__
 *
 * To run a query within a React component, call `useGetLocationAndChildrenDeficienciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationAndChildrenDeficienciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationAndChildrenDeficienciesQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *      includeChildren: // value for 'includeChildren'
 *   },
 * });
 */
export function useGetLocationAndChildrenDeficienciesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetLocationAndChildrenDeficienciesQuery, Types.GetLocationAndChildrenDeficienciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLocationAndChildrenDeficienciesQuery, Types.GetLocationAndChildrenDeficienciesQueryVariables>(GetLocationAndChildrenDeficienciesDocument, options);
      }
export function useGetLocationAndChildrenDeficienciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLocationAndChildrenDeficienciesQuery, Types.GetLocationAndChildrenDeficienciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLocationAndChildrenDeficienciesQuery, Types.GetLocationAndChildrenDeficienciesQueryVariables>(GetLocationAndChildrenDeficienciesDocument, options);
        }
export type GetLocationAndChildrenDeficienciesQueryHookResult = ReturnType<typeof useGetLocationAndChildrenDeficienciesQuery>;
export type GetLocationAndChildrenDeficienciesLazyQueryHookResult = ReturnType<typeof useGetLocationAndChildrenDeficienciesLazyQuery>;
export type GetLocationAndChildrenDeficienciesQueryResult = Apollo.QueryResult<Types.GetLocationAndChildrenDeficienciesQuery, Types.GetLocationAndChildrenDeficienciesQueryVariables>;
export const GetQuestionsForScenariosDocument = gql`
    query getQuestionsForScenarios($scenarioIds: jsonb!) {
  fn_get_questions_for_scenarios(args: {scenario_ids: $scenarioIds}) {
    scenario_id
    criticality
    deficient_answer
    is_required
    item_category_id
    item_category_name
    question_id
    question_text
    question_type
    layer_tag_id
    organization_id
    ordinal
    layer_id
  }
}
    `;

/**
 * __useGetQuestionsForScenariosQuery__
 *
 * To run a query within a React component, call `useGetQuestionsForScenariosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionsForScenariosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionsForScenariosQuery({
 *   variables: {
 *      scenarioIds: // value for 'scenarioIds'
 *   },
 * });
 */
export function useGetQuestionsForScenariosQuery(baseOptions: Apollo.QueryHookOptions<Types.GetQuestionsForScenariosQuery, Types.GetQuestionsForScenariosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetQuestionsForScenariosQuery, Types.GetQuestionsForScenariosQueryVariables>(GetQuestionsForScenariosDocument, options);
      }
export function useGetQuestionsForScenariosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetQuestionsForScenariosQuery, Types.GetQuestionsForScenariosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetQuestionsForScenariosQuery, Types.GetQuestionsForScenariosQueryVariables>(GetQuestionsForScenariosDocument, options);
        }
export type GetQuestionsForScenariosQueryHookResult = ReturnType<typeof useGetQuestionsForScenariosQuery>;
export type GetQuestionsForScenariosLazyQueryHookResult = ReturnType<typeof useGetQuestionsForScenariosLazyQuery>;
export type GetQuestionsForScenariosQueryResult = Apollo.QueryResult<Types.GetQuestionsForScenariosQuery, Types.GetQuestionsForScenariosQueryVariables>;
export const GetScenarioDeficiencyCategoryBreakdownDocument = gql`
    query getScenarioDeficiencyCategoryBreakdown($questionSetId: Int!, $locationId: uuid!) {
  fn_get_scenario_deficiency_category_breakdown(
    args: {_location_id: $locationId, _question_set_id: $questionSetId}
  ) {
    category_id
    category_name
    criticality
    deficiency_count
  }
}
    `;

/**
 * __useGetScenarioDeficiencyCategoryBreakdownQuery__
 *
 * To run a query within a React component, call `useGetScenarioDeficiencyCategoryBreakdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenarioDeficiencyCategoryBreakdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenarioDeficiencyCategoryBreakdownQuery({
 *   variables: {
 *      questionSetId: // value for 'questionSetId'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetScenarioDeficiencyCategoryBreakdownQuery(baseOptions: Apollo.QueryHookOptions<Types.GetScenarioDeficiencyCategoryBreakdownQuery, Types.GetScenarioDeficiencyCategoryBreakdownQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetScenarioDeficiencyCategoryBreakdownQuery, Types.GetScenarioDeficiencyCategoryBreakdownQueryVariables>(GetScenarioDeficiencyCategoryBreakdownDocument, options);
      }
export function useGetScenarioDeficiencyCategoryBreakdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetScenarioDeficiencyCategoryBreakdownQuery, Types.GetScenarioDeficiencyCategoryBreakdownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetScenarioDeficiencyCategoryBreakdownQuery, Types.GetScenarioDeficiencyCategoryBreakdownQueryVariables>(GetScenarioDeficiencyCategoryBreakdownDocument, options);
        }
export type GetScenarioDeficiencyCategoryBreakdownQueryHookResult = ReturnType<typeof useGetScenarioDeficiencyCategoryBreakdownQuery>;
export type GetScenarioDeficiencyCategoryBreakdownLazyQueryHookResult = ReturnType<typeof useGetScenarioDeficiencyCategoryBreakdownLazyQuery>;
export type GetScenarioDeficiencyCategoryBreakdownQueryResult = Apollo.QueryResult<Types.GetScenarioDeficiencyCategoryBreakdownQuery, Types.GetScenarioDeficiencyCategoryBreakdownQueryVariables>;
export const GetScenarioDeficiencyLocationBreakdownDocument = gql`
    query getScenarioDeficiencyLocationBreakdown($questionSetId: Int!, $locationId: uuid!) {
  fn_get_scenario_deficiency_location_breakdown(
    args: {_location_id: $locationId, _question_set_id: $questionSetId}
  ) {
    location_id
    location_name
    criticality
    deficiency_count
  }
}
    `;

/**
 * __useGetScenarioDeficiencyLocationBreakdownQuery__
 *
 * To run a query within a React component, call `useGetScenarioDeficiencyLocationBreakdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenarioDeficiencyLocationBreakdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenarioDeficiencyLocationBreakdownQuery({
 *   variables: {
 *      questionSetId: // value for 'questionSetId'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetScenarioDeficiencyLocationBreakdownQuery(baseOptions: Apollo.QueryHookOptions<Types.GetScenarioDeficiencyLocationBreakdownQuery, Types.GetScenarioDeficiencyLocationBreakdownQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetScenarioDeficiencyLocationBreakdownQuery, Types.GetScenarioDeficiencyLocationBreakdownQueryVariables>(GetScenarioDeficiencyLocationBreakdownDocument, options);
      }
export function useGetScenarioDeficiencyLocationBreakdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetScenarioDeficiencyLocationBreakdownQuery, Types.GetScenarioDeficiencyLocationBreakdownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetScenarioDeficiencyLocationBreakdownQuery, Types.GetScenarioDeficiencyLocationBreakdownQueryVariables>(GetScenarioDeficiencyLocationBreakdownDocument, options);
        }
export type GetScenarioDeficiencyLocationBreakdownQueryHookResult = ReturnType<typeof useGetScenarioDeficiencyLocationBreakdownQuery>;
export type GetScenarioDeficiencyLocationBreakdownLazyQueryHookResult = ReturnType<typeof useGetScenarioDeficiencyLocationBreakdownLazyQuery>;
export type GetScenarioDeficiencyLocationBreakdownQueryResult = Apollo.QueryResult<Types.GetScenarioDeficiencyLocationBreakdownQuery, Types.GetScenarioDeficiencyLocationBreakdownQueryVariables>;
export const GetScenarioLocationDeficiencySummaryDocument = gql`
    query getScenarioLocationDeficiencySummary($questionSetId: Int!, $locationId: uuid!) {
  fn_get_scenario_location_deficiency_summary(
    args: {_location_id: $locationId, _question_set_id: $questionSetId}
  ) {
    criticality
    deficiency_count
  }
}
    `;

/**
 * __useGetScenarioLocationDeficiencySummaryQuery__
 *
 * To run a query within a React component, call `useGetScenarioLocationDeficiencySummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenarioLocationDeficiencySummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenarioLocationDeficiencySummaryQuery({
 *   variables: {
 *      questionSetId: // value for 'questionSetId'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetScenarioLocationDeficiencySummaryQuery(baseOptions: Apollo.QueryHookOptions<Types.GetScenarioLocationDeficiencySummaryQuery, Types.GetScenarioLocationDeficiencySummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetScenarioLocationDeficiencySummaryQuery, Types.GetScenarioLocationDeficiencySummaryQueryVariables>(GetScenarioLocationDeficiencySummaryDocument, options);
      }
export function useGetScenarioLocationDeficiencySummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetScenarioLocationDeficiencySummaryQuery, Types.GetScenarioLocationDeficiencySummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetScenarioLocationDeficiencySummaryQuery, Types.GetScenarioLocationDeficiencySummaryQueryVariables>(GetScenarioLocationDeficiencySummaryDocument, options);
        }
export type GetScenarioLocationDeficiencySummaryQueryHookResult = ReturnType<typeof useGetScenarioLocationDeficiencySummaryQuery>;
export type GetScenarioLocationDeficiencySummaryLazyQueryHookResult = ReturnType<typeof useGetScenarioLocationDeficiencySummaryLazyQuery>;
export type GetScenarioLocationDeficiencySummaryQueryResult = Apollo.QueryResult<Types.GetScenarioLocationDeficiencySummaryQuery, Types.GetScenarioLocationDeficiencySummaryQueryVariables>;
export const GetActionableTaskHistoryStreamDocument = gql`
    subscription GetActionableTaskHistoryStream($where: actionable_task_history_bool_exp!, $from: timestamptz!) {
  actionable_task_history_stream(
    batch_size: 8
    cursor: {initial_value: {updated_at: $from}}
    where: $where
  ) {
    ...ActionableTaskHistory
  }
}
    ${ActionableTaskHistoryFragmentDoc}`;

/**
 * __useGetActionableTaskHistoryStreamSubscription__
 *
 * To run a query within a React component, call `useGetActionableTaskHistoryStreamSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetActionableTaskHistoryStreamSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActionableTaskHistoryStreamSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      from: // value for 'from'
 *   },
 * });
 */
export function useGetActionableTaskHistoryStreamSubscription(baseOptions: Apollo.SubscriptionHookOptions<Types.GetActionableTaskHistoryStreamSubscription, Types.GetActionableTaskHistoryStreamSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.GetActionableTaskHistoryStreamSubscription, Types.GetActionableTaskHistoryStreamSubscriptionVariables>(GetActionableTaskHistoryStreamDocument, options);
      }
export type GetActionableTaskHistoryStreamSubscriptionHookResult = ReturnType<typeof useGetActionableTaskHistoryStreamSubscription>;
export type GetActionableTaskHistoryStreamSubscriptionResult = Apollo.SubscriptionResult<Types.GetActionableTaskHistoryStreamSubscription>;
export const CommentStreamDocument = gql`
    subscription CommentStream($from: timestamptz!, $entityPk: jsonb!, $entityType: enum_comment_entity_type_enum!) {
  comments_stream(
    batch_size: 10
    cursor: {initial_value: {updated_at: $from}}
    where: {entity_pk: {_eq: $entityPk}, entity_type: {_eq: $entityType}}
  ) {
    ...Comment
  }
}
    ${CommentFragmentDoc}`;

/**
 * __useCommentStreamSubscription__
 *
 * To run a query within a React component, call `useCommentStreamSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCommentStreamSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentStreamSubscription({
 *   variables: {
 *      from: // value for 'from'
 *      entityPk: // value for 'entityPk'
 *      entityType: // value for 'entityType'
 *   },
 * });
 */
export function useCommentStreamSubscription(baseOptions: Apollo.SubscriptionHookOptions<Types.CommentStreamSubscription, Types.CommentStreamSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.CommentStreamSubscription, Types.CommentStreamSubscriptionVariables>(CommentStreamDocument, options);
      }
export type CommentStreamSubscriptionHookResult = ReturnType<typeof useCommentStreamSubscription>;
export type CommentStreamSubscriptionResult = Apollo.SubscriptionResult<Types.CommentStreamSubscription>;
export const ListenQuestionSetUploadUpdatesDocument = gql`
    subscription listenQuestionSetUploadUpdates($organizationId: uuid!) {
  question_set_upload_jobs(
    where: {organization_id: {_eq: $organizationId}}
    order_by: {created_at: desc}
  ) {
    ...QuestionSetUploadJob
  }
}
    ${QuestionSetUploadJobFragmentDoc}`;

/**
 * __useListenQuestionSetUploadUpdatesSubscription__
 *
 * To run a query within a React component, call `useListenQuestionSetUploadUpdatesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenQuestionSetUploadUpdatesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenQuestionSetUploadUpdatesSubscription({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useListenQuestionSetUploadUpdatesSubscription(baseOptions: Apollo.SubscriptionHookOptions<Types.ListenQuestionSetUploadUpdatesSubscription, Types.ListenQuestionSetUploadUpdatesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.ListenQuestionSetUploadUpdatesSubscription, Types.ListenQuestionSetUploadUpdatesSubscriptionVariables>(ListenQuestionSetUploadUpdatesDocument, options);
      }
export type ListenQuestionSetUploadUpdatesSubscriptionHookResult = ReturnType<typeof useListenQuestionSetUploadUpdatesSubscription>;
export type ListenQuestionSetUploadUpdatesSubscriptionResult = Apollo.SubscriptionResult<Types.ListenQuestionSetUploadUpdatesSubscription>;
export const ListenLocationUpdatedAtDocument = gql`
    subscription listenLocationUpdatedAt($organizationId: uuid!, $updatedSince: timestamptz!) {
  nodes(
    where: {organization_id: {_eq: $organizationId}, updated_at: {_gte: $updatedSince}}
    order_by: {updated_at: desc}
    limit: 1
  ) {
    id
    updated_at
  }
}
    `;

/**
 * __useListenLocationUpdatedAtSubscription__
 *
 * To run a query within a React component, call `useListenLocationUpdatedAtSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenLocationUpdatedAtSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenLocationUpdatedAtSubscription({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      updatedSince: // value for 'updatedSince'
 *   },
 * });
 */
export function useListenLocationUpdatedAtSubscription(baseOptions: Apollo.SubscriptionHookOptions<Types.ListenLocationUpdatedAtSubscription, Types.ListenLocationUpdatedAtSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.ListenLocationUpdatedAtSubscription, Types.ListenLocationUpdatedAtSubscriptionVariables>(ListenLocationUpdatedAtDocument, options);
      }
export type ListenLocationUpdatedAtSubscriptionHookResult = ReturnType<typeof useListenLocationUpdatedAtSubscription>;
export type ListenLocationUpdatedAtSubscriptionResult = Apollo.SubscriptionResult<Types.ListenLocationUpdatedAtSubscription>;
export const ListenLocationCreatedAtDocument = gql`
    subscription listenLocationCreatedAt($organizationId: uuid!, $updatedSince: timestamptz!) {
  nodes(
    where: {organization_id: {_eq: $organizationId}, updated_at: {_gte: $updatedSince}}
    order_by: {created_at: desc}
    limit: 1
  ) {
    id
    created_at
  }
}
    `;

/**
 * __useListenLocationCreatedAtSubscription__
 *
 * To run a query within a React component, call `useListenLocationCreatedAtSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenLocationCreatedAtSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenLocationCreatedAtSubscription({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      updatedSince: // value for 'updatedSince'
 *   },
 * });
 */
export function useListenLocationCreatedAtSubscription(baseOptions: Apollo.SubscriptionHookOptions<Types.ListenLocationCreatedAtSubscription, Types.ListenLocationCreatedAtSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.ListenLocationCreatedAtSubscription, Types.ListenLocationCreatedAtSubscriptionVariables>(ListenLocationCreatedAtDocument, options);
      }
export type ListenLocationCreatedAtSubscriptionHookResult = ReturnType<typeof useListenLocationCreatedAtSubscription>;
export type ListenLocationCreatedAtSubscriptionResult = Apollo.SubscriptionResult<Types.ListenLocationCreatedAtSubscription>;
export const ListenOrgEnabledFeatureFlagsDocument = gql`
    subscription listenOrgEnabledFeatureFlags($organizationId: uuid!) {
  organization_feature_flags(
    where: {organization_id: {_eq: $organizationId}, is_flag_enabled: {_eq: true}}
  ) {
    organization_id
    flag_name
  }
}
    `;

/**
 * __useListenOrgEnabledFeatureFlagsSubscription__
 *
 * To run a query within a React component, call `useListenOrgEnabledFeatureFlagsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenOrgEnabledFeatureFlagsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenOrgEnabledFeatureFlagsSubscription({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useListenOrgEnabledFeatureFlagsSubscription(baseOptions: Apollo.SubscriptionHookOptions<Types.ListenOrgEnabledFeatureFlagsSubscription, Types.ListenOrgEnabledFeatureFlagsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.ListenOrgEnabledFeatureFlagsSubscription, Types.ListenOrgEnabledFeatureFlagsSubscriptionVariables>(ListenOrgEnabledFeatureFlagsDocument, options);
      }
export type ListenOrgEnabledFeatureFlagsSubscriptionHookResult = ReturnType<typeof useListenOrgEnabledFeatureFlagsSubscription>;
export type ListenOrgEnabledFeatureFlagsSubscriptionResult = Apollo.SubscriptionResult<Types.ListenOrgEnabledFeatureFlagsSubscription>;
export const ListenOrganizationsAndLayersDocument = gql`
    subscription listenOrganizationsAndLayers {
  organizations {
    id
    name
    requires_eula
    is_actual_customer
    assessments_purchased
    meta {
      campus_layer_id
      building_layer_id
      organization_layer_id
    }
    scoredLayer {
      id
      name
    }
    layers {
      id
      name
      layerDepth
      mpath
      nodes_aggregate {
        aggregate {
          count
        }
      }
    }
  }
}
    `;

/**
 * __useListenOrganizationsAndLayersSubscription__
 *
 * To run a query within a React component, call `useListenOrganizationsAndLayersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenOrganizationsAndLayersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenOrganizationsAndLayersSubscription({
 *   variables: {
 *   },
 * });
 */
export function useListenOrganizationsAndLayersSubscription(baseOptions?: Apollo.SubscriptionHookOptions<Types.ListenOrganizationsAndLayersSubscription, Types.ListenOrganizationsAndLayersSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.ListenOrganizationsAndLayersSubscription, Types.ListenOrganizationsAndLayersSubscriptionVariables>(ListenOrganizationsAndLayersDocument, options);
      }
export type ListenOrganizationsAndLayersSubscriptionHookResult = ReturnType<typeof useListenOrganizationsAndLayersSubscription>;
export type ListenOrganizationsAndLayersSubscriptionResult = Apollo.SubscriptionResult<Types.ListenOrganizationsAndLayersSubscription>;
export const ListenScenarioDetailsForAssessmentDocument = gql`
    subscription listenScenarioDetailsForAssessment($organizationId: uuid!, $assessmentId: Int!) {
  v2_assessments_by_pk(id: $assessmentId, organization_id: $organizationId) {
    ...AssessmentForDetails
  }
}
    ${AssessmentForDetailsFragmentDoc}`;

/**
 * __useListenScenarioDetailsForAssessmentSubscription__
 *
 * To run a query within a React component, call `useListenScenarioDetailsForAssessmentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenScenarioDetailsForAssessmentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenScenarioDetailsForAssessmentSubscription({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      assessmentId: // value for 'assessmentId'
 *   },
 * });
 */
export function useListenScenarioDetailsForAssessmentSubscription(baseOptions: Apollo.SubscriptionHookOptions<Types.ListenScenarioDetailsForAssessmentSubscription, Types.ListenScenarioDetailsForAssessmentSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.ListenScenarioDetailsForAssessmentSubscription, Types.ListenScenarioDetailsForAssessmentSubscriptionVariables>(ListenScenarioDetailsForAssessmentDocument, options);
      }
export type ListenScenarioDetailsForAssessmentSubscriptionHookResult = ReturnType<typeof useListenScenarioDetailsForAssessmentSubscription>;
export type ListenScenarioDetailsForAssessmentSubscriptionResult = Apollo.SubscriptionResult<Types.ListenScenarioDetailsForAssessmentSubscription>;
export const ListenReportPreviewUrlDocument = gql`
    subscription listenReportPreviewUrl($organizationId: uuid!, $reportId: Int!) {
  report_previews(
    where: {organization_id: {_eq: $organizationId}, report_id: {_eq: $reportId}, report_section_id: {_is_null: true}}
    order_by: {requested_at: desc}
    limit: 1
  ) {
    id
    organization_id
    status
    file {
      id
      filepath
    }
    requested_at
    started_at
    completed_at
  }
}
    `;

/**
 * __useListenReportPreviewUrlSubscription__
 *
 * To run a query within a React component, call `useListenReportPreviewUrlSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenReportPreviewUrlSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenReportPreviewUrlSubscription({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useListenReportPreviewUrlSubscription(baseOptions: Apollo.SubscriptionHookOptions<Types.ListenReportPreviewUrlSubscription, Types.ListenReportPreviewUrlSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.ListenReportPreviewUrlSubscription, Types.ListenReportPreviewUrlSubscriptionVariables>(ListenReportPreviewUrlDocument, options);
      }
export type ListenReportPreviewUrlSubscriptionHookResult = ReturnType<typeof useListenReportPreviewUrlSubscription>;
export type ListenReportPreviewUrlSubscriptionResult = Apollo.SubscriptionResult<Types.ListenReportPreviewUrlSubscription>;
export const ListenReportSectionPreviewUrlDocument = gql`
    subscription listenReportSectionPreviewUrl($organizationId: uuid!, $reportSectionId: Int!) {
  report_previews(
    where: {organization_id: {_eq: $organizationId}, report_section_id: {_eq: $reportSectionId}}
    order_by: {requested_at: desc}
    limit: 1
  ) {
    id
    organization_id
    status
    file {
      id
      filepath
    }
    requested_at
    started_at
    completed_at
  }
}
    `;

/**
 * __useListenReportSectionPreviewUrlSubscription__
 *
 * To run a query within a React component, call `useListenReportSectionPreviewUrlSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenReportSectionPreviewUrlSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenReportSectionPreviewUrlSubscription({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      reportSectionId: // value for 'reportSectionId'
 *   },
 * });
 */
export function useListenReportSectionPreviewUrlSubscription(baseOptions: Apollo.SubscriptionHookOptions<Types.ListenReportSectionPreviewUrlSubscription, Types.ListenReportSectionPreviewUrlSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.ListenReportSectionPreviewUrlSubscription, Types.ListenReportSectionPreviewUrlSubscriptionVariables>(ListenReportSectionPreviewUrlDocument, options);
      }
export type ListenReportSectionPreviewUrlSubscriptionHookResult = ReturnType<typeof useListenReportSectionPreviewUrlSubscription>;
export type ListenReportSectionPreviewUrlSubscriptionResult = Apollo.SubscriptionResult<Types.ListenReportSectionPreviewUrlSubscription>;
export const ListenOrganizationDashboardDocument = gql`
    subscription listenOrganizationDashboard($organizationId: uuid!) {
  organizations_by_pk(id: $organizationId) {
    name
    logo {
      filepath
    }
    users_organizations_organizations_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useListenOrganizationDashboardSubscription__
 *
 * To run a query within a React component, call `useListenOrganizationDashboardSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenOrganizationDashboardSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenOrganizationDashboardSubscription({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useListenOrganizationDashboardSubscription(baseOptions: Apollo.SubscriptionHookOptions<Types.ListenOrganizationDashboardSubscription, Types.ListenOrganizationDashboardSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.ListenOrganizationDashboardSubscription, Types.ListenOrganizationDashboardSubscriptionVariables>(ListenOrganizationDashboardDocument, options);
      }
export type ListenOrganizationDashboardSubscriptionHookResult = ReturnType<typeof useListenOrganizationDashboardSubscription>;
export type ListenOrganizationDashboardSubscriptionResult = Apollo.SubscriptionResult<Types.ListenOrganizationDashboardSubscription>;
export const ListenNodeFilesDocument = gql`
    subscription listenNodeFiles($nodeId: uuid!) {
  nodes_files(where: {node_id: {_eq: $nodeId}, file_type: {_eq: generic}}) {
    file {
      id
      filepath
      original_filename
      ...image
      uploaded_user {
        id
        first_name
        last_name
      }
    }
  }
}
    ${ImageFragmentDoc}`;

/**
 * __useListenNodeFilesSubscription__
 *
 * To run a query within a React component, call `useListenNodeFilesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenNodeFilesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenNodeFilesSubscription({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useListenNodeFilesSubscription(baseOptions: Apollo.SubscriptionHookOptions<Types.ListenNodeFilesSubscription, Types.ListenNodeFilesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.ListenNodeFilesSubscription, Types.ListenNodeFilesSubscriptionVariables>(ListenNodeFilesDocument, options);
      }
export type ListenNodeFilesSubscriptionHookResult = ReturnType<typeof useListenNodeFilesSubscription>;
export type ListenNodeFilesSubscriptionResult = Apollo.SubscriptionResult<Types.ListenNodeFilesSubscription>;
export const LocationUpdateDocument = gql`
    subscription locationUpdate($locationId: uuid!) {
  node_mapbox_maps(where: {node: {id: {_eq: $locationId}}}) {
    id
    style_url
    max_bounds
  }
}
    `;

/**
 * __useLocationUpdateSubscription__
 *
 * To run a query within a React component, call `useLocationUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLocationUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationUpdateSubscription({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useLocationUpdateSubscription(baseOptions: Apollo.SubscriptionHookOptions<Types.LocationUpdateSubscription, Types.LocationUpdateSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.LocationUpdateSubscription, Types.LocationUpdateSubscriptionVariables>(LocationUpdateDocument, options);
      }
export type LocationUpdateSubscriptionHookResult = ReturnType<typeof useLocationUpdateSubscription>;
export type LocationUpdateSubscriptionResult = Apollo.SubscriptionResult<Types.LocationUpdateSubscription>;
export const WorkFlowsUpdateDocument = gql`
    subscription workFlowsUpdate($locationId: uuid!) {
  map_workflows_in_progress(where: {node_id: {_eq: $locationId}}) {
    organization_id
    node_id
    started_by_id
    workflow_step_name
  }
}
    `;

/**
 * __useWorkFlowsUpdateSubscription__
 *
 * To run a query within a React component, call `useWorkFlowsUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWorkFlowsUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkFlowsUpdateSubscription({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useWorkFlowsUpdateSubscription(baseOptions: Apollo.SubscriptionHookOptions<Types.WorkFlowsUpdateSubscription, Types.WorkFlowsUpdateSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.WorkFlowsUpdateSubscription, Types.WorkFlowsUpdateSubscriptionVariables>(WorkFlowsUpdateDocument, options);
      }
export type WorkFlowsUpdateSubscriptionHookResult = ReturnType<typeof useWorkFlowsUpdateSubscription>;
export type WorkFlowsUpdateSubscriptionResult = Apollo.SubscriptionResult<Types.WorkFlowsUpdateSubscription>;
export const ListenLocationAssignmentsDocument = gql`
    subscription listenLocationAssignments($userId: uuid!) {
  location_manager_inherited_assignments(where: {user_id: {_eq: $userId}}) {
    user_id
    location_id
  }
}
    `;

/**
 * __useListenLocationAssignmentsSubscription__
 *
 * To run a query within a React component, call `useListenLocationAssignmentsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenLocationAssignmentsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenLocationAssignmentsSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useListenLocationAssignmentsSubscription(baseOptions: Apollo.SubscriptionHookOptions<Types.ListenLocationAssignmentsSubscription, Types.ListenLocationAssignmentsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.ListenLocationAssignmentsSubscription, Types.ListenLocationAssignmentsSubscriptionVariables>(ListenLocationAssignmentsDocument, options);
      }
export type ListenLocationAssignmentsSubscriptionHookResult = ReturnType<typeof useListenLocationAssignmentsSubscription>;
export type ListenLocationAssignmentsSubscriptionResult = Apollo.SubscriptionResult<Types.ListenLocationAssignmentsSubscription>;
export const ListenVisibleOrgAssignmentsDocument = gql`
    subscription listenVisibleOrgAssignments($userId: uuid!) {
  users_by_pk(id: $userId) {
    needs_to_set_password
    last_signed_in_at
    first_name
    last_name
    bio
    certifications
    phone_number
    title
    location
    company
    avatar {
      filepath
    }
    latestEula: user_eulas(limit: 1, order_by: {eula_id: desc}) {
      eula_id
      accepted_at
    }
    users_organizations_organizations(order_by: {organization: {name: asc}}) {
      organization {
        id
        name
        requires_eula
        logo {
          filepath
        }
      }
    }
  }
}
    `;

/**
 * __useListenVisibleOrgAssignmentsSubscription__
 *
 * To run a query within a React component, call `useListenVisibleOrgAssignmentsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenVisibleOrgAssignmentsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenVisibleOrgAssignmentsSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useListenVisibleOrgAssignmentsSubscription(baseOptions: Apollo.SubscriptionHookOptions<Types.ListenVisibleOrgAssignmentsSubscription, Types.ListenVisibleOrgAssignmentsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.ListenVisibleOrgAssignmentsSubscription, Types.ListenVisibleOrgAssignmentsSubscriptionVariables>(ListenVisibleOrgAssignmentsDocument, options);
      }
export type ListenVisibleOrgAssignmentsSubscriptionHookResult = ReturnType<typeof useListenVisibleOrgAssignmentsSubscription>;
export type ListenVisibleOrgAssignmentsSubscriptionResult = Apollo.SubscriptionResult<Types.ListenVisibleOrgAssignmentsSubscription>;
export const ListenOrganizationNamesAndIdsDocument = gql`
    subscription listenOrganizationNamesAndIds {
  organizations {
    ...OrganizationNamesAndIds
  }
}
    ${OrganizationNamesAndIdsFragmentDoc}`;

/**
 * __useListenOrganizationNamesAndIdsSubscription__
 *
 * To run a query within a React component, call `useListenOrganizationNamesAndIdsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenOrganizationNamesAndIdsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenOrganizationNamesAndIdsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useListenOrganizationNamesAndIdsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<Types.ListenOrganizationNamesAndIdsSubscription, Types.ListenOrganizationNamesAndIdsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.ListenOrganizationNamesAndIdsSubscription, Types.ListenOrganizationNamesAndIdsSubscriptionVariables>(ListenOrganizationNamesAndIdsDocument, options);
      }
export type ListenOrganizationNamesAndIdsSubscriptionHookResult = ReturnType<typeof useListenOrganizationNamesAndIdsSubscription>;
export type ListenOrganizationNamesAndIdsSubscriptionResult = Apollo.SubscriptionResult<Types.ListenOrganizationNamesAndIdsSubscription>;
export const GetAssessmentUsersDocument = gql`
    subscription getAssessmentUsers($organizationId: uuid!, $assessmentId: Int!) {
  assessment_users(
    where: {organization_id: {_eq: $organizationId}, assessment_id: {_eq: $assessmentId}}
  ) {
    organization_id
    assessment_id
    user {
      id
      first_name
      last_name
      email
      title
      company
      last_signed_in_at
      bio
      users_organizations_organizations(
        where: {organizations_id: {_eq: $organizationId}}
      ) {
        role
      }
      avatar {
        id
        filepath
      }
    }
  }
}
    `;

/**
 * __useGetAssessmentUsersSubscription__
 *
 * To run a query within a React component, call `useGetAssessmentUsersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentUsersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentUsersSubscription({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      assessmentId: // value for 'assessmentId'
 *   },
 * });
 */
export function useGetAssessmentUsersSubscription(baseOptions: Apollo.SubscriptionHookOptions<Types.GetAssessmentUsersSubscription, Types.GetAssessmentUsersSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.GetAssessmentUsersSubscription, Types.GetAssessmentUsersSubscriptionVariables>(GetAssessmentUsersDocument, options);
      }
export type GetAssessmentUsersSubscriptionHookResult = ReturnType<typeof useGetAssessmentUsersSubscription>;
export type GetAssessmentUsersSubscriptionResult = Apollo.SubscriptionResult<Types.GetAssessmentUsersSubscription>;
export const GetLatestAssessmentItemTimestampDocument = gql`
    subscription getLatestAssessmentItemTimestamp($assessmentId: Int!) {
  assessment_items(
    where: {assessment_id: {_eq: $assessmentId}}
    order_by: {updated_at: desc}
    limit: 1
  ) {
    updated_at
  }
}
    `;

/**
 * __useGetLatestAssessmentItemTimestampSubscription__
 *
 * To run a query within a React component, call `useGetLatestAssessmentItemTimestampSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestAssessmentItemTimestampSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestAssessmentItemTimestampSubscription({
 *   variables: {
 *      assessmentId: // value for 'assessmentId'
 *   },
 * });
 */
export function useGetLatestAssessmentItemTimestampSubscription(baseOptions: Apollo.SubscriptionHookOptions<Types.GetLatestAssessmentItemTimestampSubscription, Types.GetLatestAssessmentItemTimestampSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.GetLatestAssessmentItemTimestampSubscription, Types.GetLatestAssessmentItemTimestampSubscriptionVariables>(GetLatestAssessmentItemTimestampDocument, options);
      }
export type GetLatestAssessmentItemTimestampSubscriptionHookResult = ReturnType<typeof useGetLatestAssessmentItemTimestampSubscription>;
export type GetLatestAssessmentItemTimestampSubscriptionResult = Apollo.SubscriptionResult<Types.GetLatestAssessmentItemTimestampSubscription>;