import { ChecklistIcon, HStack, InfoIconWithTooltip, LocationChip, VStack } from '@circadian-risk/presentational';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Collapse, Typography } from '@mui/material';
import isString from 'lodash/isString';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

export interface ScenarioOverviewDetailsListProps {
  expanded: boolean;
  hasRelevantLocations: boolean;
  hasInherentRiskComplete: boolean;
  hasAssessmentsComplete: boolean;
  hasAssessments: boolean;
  scenarioId: number;
  scenarioDetailsRoute: string;
  createAssessmentRoute: string;
  completeAssessmentRoute: string;
  relevantLayer: string;
  onLocationAdd: () => void;
}

export const overviewDetailsAriaLabels = {
  RelevantLocationsAction: 'create relevant location',
  InherentRiskAction: 'analyze inherent risk',
  CreateAssessmentAction: 'create assessment',
  CloseAssessmentAction: 'close assessment',
  ActionComplete: 'complete',
  ActionPending: 'pending',
  ActionLocked: 'locked',
};

type ActionData = {
  description: string | React.ReactNode;
  state: 'locked' | 'inProgress' | 'complete';
  ariaLabel: string;
  tooltip: string;
  actionLink?: string;
};

const generateCheckListOptions = ({
  hasInherentRiskComplete,
  hasAssessments,
  hasAssessmentsComplete,
  hasRelevantLocations,
  createAssessmentRoute,
  scenarioDetailsRoute,
  completeAssessmentRoute,
  relevantLayer,
  onLocationAdd,
}: Omit<ScenarioOverviewDetailsListProps, 'expanded'>): ActionData[] => {
  return [
    {
      description: (
        <Typography variant="body1" component={'div'}>
          <Button size="small" startIcon={<AddIcon />} onClick={() => onLocationAdd()}>
            Add
          </Button>{' '}
          at least one <LocationChip sx={{ mx: 0.5 }} size="small" layerName={relevantLayer} /> location to your
          organization
        </Typography>
      ),
      state: hasRelevantLocations ? 'complete' : 'inProgress',
      ariaLabel: overviewDetailsAriaLabels.RelevantLocationsAction,
      tooltip: `This scenario is applicable for locations of type ${relevantLayer}`,
    },
    {
      description: 'Complete Inherent Risk analysis',
      state: hasInherentRiskComplete ? 'complete' : hasRelevantLocations ? 'inProgress' : 'locked',
      ariaLabel: overviewDetailsAriaLabels.InherentRiskAction,
      actionLink: scenarioDetailsRoute,
      tooltip:
        'Inherent Risk helps determine the natural probability and severity of a scenario for each of your relevant locations. We recommend completing an inherent risk analysis for each of your applicable locations in order to be able to compare them objectively',
    },
    {
      description: 'Create an Assessment',
      state: hasAssessments ? 'complete' : hasRelevantLocations ? 'inProgress' : 'locked',
      ariaLabel: overviewDetailsAriaLabels.CreateAssessmentAction,
      actionLink: createAssessmentRoute,
      tooltip: hasInherentRiskComplete
        ? 'Assessments allow you to determine your residual risk'
        : 'We recommend completing inherent risk for your locations before creating an Assessment',
    },
    {
      description: 'Complete an Assessment',
      state: hasAssessmentsComplete ? 'complete' : hasAssessments ? 'inProgress' : 'locked',
      ariaLabel: overviewDetailsAriaLabels.CloseAssessmentAction,
      actionLink: completeAssessmentRoute,
      tooltip: hasAssessments
        ? 'Completing Assessments will populate the Risk chart below and allow you to objectively compare the residual risk of your locations'
        : 'You need to create an Assessment before you can complete one',
    },
  ];
};

const Action: React.FC<ActionData> = ({ state, tooltip, actionLink, description, ariaLabel }) => {
  const iconLabel = useMemo(() => {
    switch (state) {
      case 'complete':
        return overviewDetailsAriaLabels.ActionComplete;
      case 'inProgress':
        return overviewDetailsAriaLabels.ActionPending;
      case 'locked':
        return overviewDetailsAriaLabels.ActionLocked;
    }
  }, [state]);

  return (
    <HStack spacing={2} aria-label={ariaLabel} role="listitem">
      <ChecklistIcon iconLabel={iconLabel} locked={state === 'locked'} complete={state === 'complete'} />
      <HStack spacing={0}>
        {state === 'inProgress' && actionLink ? (
          <Link to={actionLink}>
            <Typography variant="body1" component="div">
              {description}
            </Typography>
          </Link>
        ) : isString(description) ? (
          <Typography variant="body1">{description}</Typography>
        ) : (
          description
        )}
        <InfoIconWithTooltip tooltipContent={tooltip} />
      </HStack>
    </HStack>
  );
};

export const ScenarioOverviewDetailsList: React.FC<ScenarioOverviewDetailsListProps> = ({
  expanded,
  ...checkListStates
}) => {
  const checkListOptions = generateCheckListOptions({ ...checkListStates });

  return (
    <Collapse in={expanded}>
      <Box p={3}>
        <VStack
          p={2}
          spacing={2}
          borderRadius={'12px'}
          border={'2px solid'}
          borderColor={theme => theme.palette.primary.main}
        >
          <HStack>
            <Typography variant="h5">Let's work towards a Residual Risk score</Typography>
          </HStack>
          <VStack>
            {checkListOptions.map((action, idx) => (
              <Action {...action} key={idx} />
            ))}
          </VStack>
        </VStack>
      </Box>
    </Collapse>
  );
};
