import { useMediaQuery, useTheme } from '@mui/material';

/**
 * Returns whether the breakpoint is down `md`
 */
export const useIsSmallScreen = () => {
  const { breakpoints } = useTheme();
  return useMediaQuery(breakpoints.down('md'));
};

/**
 * Returns whether the breakpoint is down `sm`
 */
export const useIsPhoneScreen = () => {
  const { breakpoints } = useTheme();
  return useMediaQuery(breakpoints.down('sm'));
};

/**
 * Returns whether the breakpoint is down `md` but not down `sm`
 */
export const useIsTabletScreen = () => {
  const isSmallScreen = useIsSmallScreen();
  const isPhoneScreen = useIsPhoneScreen();

  return isSmallScreen && !isPhoneScreen;
};

/**
 * Returns whether the breakpoint is up `xl`
 */
export const useIsXLScreen = () => {
  const { breakpoints } = useTheme();
  return useMediaQuery(breakpoints.up('xl'));
};
