import { useIsSmallScreen } from '@circadian-risk/front-end-utils';
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

type CreateAssessmentLayoutProps = {
  actions: React.ReactNode;
  title: React.ReactNode;
  subtitle: React.ReactNode;
  activeStepView: React.ReactNode;
  formStepper: React.ReactNode;
};

export const CreateAssessmentLayout: React.FC<CreateAssessmentLayoutProps> = ({
  actions,
  title,
  subtitle,
  activeStepView,
  formStepper,
}) => {
  const isSmallScreen = useIsSmallScreen();

  return (
    <Box p={2} height="100%" width="100%">
      <Grid container spacing={2} height={'100%'}>
        <Grid item xs={12} lg={2}>
          {!isSmallScreen && formStepper}
        </Grid>

        <Grid item xs={12} lg={8}>
          <Box textAlign="center" mb={4}>
            <Typography variant="h4">{title}</Typography>
            <Typography variant="h6">{subtitle}</Typography>
          </Box>
          {isSmallScreen && formStepper}

          {activeStepView}
        </Grid>
      </Grid>

      {actions}
    </Box>
  );
};
