import { useBeforeUnload } from "react-use";

import { BROWSER_UNSAVED_CHANGES_MESSAGE } from "../constants";

/**
 * Returns a callback that will check if is "dirty" and if so prompt the user to save or discard those
 * @param isDirty This state can be extracted from react-hook-form formState
 * @param onClose The actual callback to perform the close action
 * @returns 
 */
export const useSmartUnsavedChanged = (isDirty: boolean, onClose: () => void) => {
    useBeforeUnload(isDirty, BROWSER_UNSAVED_CHANGES_MESSAGE);

    const handleDialogClose = () => {
        if (!isDirty) {
            return onClose();
        }
        const confirm = window.confirm(BROWSER_UNSAVED_CHANGES_MESSAGE);
        if (confirm) {
            onClose();
        }
    };
    return handleDialogClose
}