import { CircadianRoute, ROUTES } from '@circadian-risk/routes';
import { USER_ROLE } from '@circadian-risk/shared';
import { lazyLoadWithVersionCheck } from '@web-app/router/lazyLoadWithVersionCheck';

const LayerDetails = lazyLoadWithVersionCheck(() => import('./LayerDetails'));
const LayerDetailForm = lazyLoadWithVersionCheck(() => import('./LayerDetailForm'));
const LayerHierarchyPage = lazyLoadWithVersionCheck(() => import('./LayersHierarchyPage'));

export const layerRoutes: CircadianRoute[] = [
  {
    path: `${ROUTES.LAYERS}/:id/edit`,
    component: () => <LayerDetailForm />,
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN],
  },
  {
    path: `${ROUTES.LAYERS}/:id`,
    component: () => <LayerDetails />,
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN],
  },
  {
    path: ROUTES.LAYERS,
    component: () => <LayerHierarchyPage />,
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN],
  },
];
