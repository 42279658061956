import { InfoIconWithTooltip, NodeIcon } from '@circadian-risk/presentational';
import { Autocomplete, Box, TextField, TextFieldProps } from '@mui/material';
import isString from 'lodash/isString';
import React from 'react';

import { AutocompleteListbox } from '../../..';

export interface LocationOption {
  id: string;
  name: string;
  layerId: string;
  layerName: string;
  breadCrumbs: { layerName: string; locationName: string }[];
}

export interface LocationAutocompleteProps {
  /**
   * Supports single and multiple value
   */
  value: LocationOption[] | LocationOption | null;
  /**
   * The callback will always treat the selected options as an array even
   * if the value is "single"
   * @param newValue
   */
  onChange: (newValue: LocationOption[]) => void;
  options: LocationOption[];
  disabled?: boolean;
  variant?: TextFieldProps['variant'];
  error?: boolean;
  label?: string;
}

export const LocationAutocomplete: React.FC<LocationAutocompleteProps> = ({
  value,
  onChange,
  options,
  disabled,
  variant = 'outlined',
  error,
  label = 'Locations',
}) => {
  const multipleOptions = Array.isArray(value);

  const renderStartAdornment = (innerAdornment: React.ReactNode) => {
    if (Array.isArray(value)) {
      return (
        <>
          <NodeIcon layerName={'building'} />
          {innerAdornment}
        </>
      );
    }

    if (!value) {
      return innerAdornment;
    }

    return (
      <>
        <NodeIcon layerName={value.layerName} sx={{ ml: 0.5 }} />
        {innerAdornment}
      </>
    );
  };

  return (
    <Autocomplete
      value={value}
      multiple={multipleOptions}
      freeSolo={multipleOptions}
      onChange={(_e, option) => {
        // Since we support single/multi value we need to make sure we always have an array of selected options
        onChange(
          Array.isArray(option) ? (option as unknown as LocationOption[]) : [option as unknown as LocationOption],
        );
      }}
      options={options}
      getOptionLabel={option => (isString(option) ? option : option.name)}
      renderInput={params => {
        return (
          <TextField
            {...params}
            label={label}
            variant={variant}
            error={error}
            InputProps={{
              ...params.InputProps,
              startAdornment: renderStartAdornment(params.InputProps.startAdornment),
            }}
          />
        );
      }}
      disabled={disabled}
      ListboxComponent={AutocompleteListbox as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
      renderOption={(liProps, option) => {
        const { name, layerName, breadCrumbs } = option;
        return (
          <li {...liProps}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <NodeIcon fontSize="small" layerName={layerName} />

              <Box component="span" ml={1}>
                {name}
              </Box>

              {layerName && layerName.toLowerCase() !== 'organization' && breadCrumbs?.length > 0 && (
                <InfoIconWithTooltip
                  tooltipContent={breadCrumbs.map(bc => (
                    <React.Fragment key={`breadcrumb-${bc.layerName}-${bc.locationName}`}>
                      {bc.layerName}: {bc.locationName}
                      <br />
                    </React.Fragment>
                  ))}
                />
              )}
            </Box>
          </li>
        );
      }}
    />
  );
};
