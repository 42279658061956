import { useFormContext } from 'react-hook-form';

import { CreateScheduledAssessmentFormSchema } from '../../../form';
import { useAssessmentTemplates } from './useAssessmentTemplates';

export const useSelectedTemplate = () => {
  const { watch } = useFormContext<CreateScheduledAssessmentFormSchema>();
  const assessmentTemplateId = watch('assessmentTemplateId');
  const templates = useAssessmentTemplates();
  const selectedTemplate = templates.find(template => template.id === assessmentTemplateId);
  return selectedTemplate;
};
