import { FileListItemProps } from '@circadian-risk/form';
import CachedIcon from '@mui/icons-material/Cached';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import { CSSProperties } from 'react';

import { UploadStatus } from '../types';

export const IMAGE_MIME_TYPES = ['image/png', 'image/jpg', 'image/jpeg'];

const badgeIconStyles: CSSProperties = {
  width: '12px',
  height: '12px',
};

export interface FileAttachmentCommon extends FileListItemProps {
  status?: UploadStatus;
  timestamp?: string;
  type?: string;
  markup?: Record<string, any> | null;
}

export const createBadgeContent = (status?: UploadStatus | null) => {
  switch (status) {
    case UploadStatus.Pending:
      return <HourglassEmptyIcon style={badgeIconStyles} />;
    case UploadStatus.Uploading:
      return <CachedIcon style={badgeIconStyles} />;
    case UploadStatus.Failed:
      return <SmsFailedIcon style={badgeIconStyles} />;
    default:
      return null;
  }
};
