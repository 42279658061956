import { imageSrc } from '@circadian-risk/front-end-utils';
import { AddAPhoto as AddPhotoIcon } from '@mui/icons-material';
import { Box, ButtonBase, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';

import defaultPlaceholderSrc from './header-photo-default.jpeg';

const SIZE_MULTIPLIER = 2.5;

const useStyles = makeStyles<Theme, { variant: HeaderPhotoProps['variant']; small?: boolean; hasImage?: boolean }>(
  theme => {
    const activeStyles = {
      '& $icon': {
        opacity: 1,
      },
      '& $image': {
        filter: 'brightness(50%)',
      },
      '& $imageContainer': {
        opacity: 0.8,
        backgroundColor: theme.palette.secondary.light,
      },
    };

    return {
      defaultBackground: {
        backgroundColor: theme.palette.background.paper,
      },
      image: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        filter: 'brightness(100%)',
        borderRadius: ({ variant }) => (variant === 'squared' ? theme.spacing(1) : '50%'),
      },
      centered: {
        justifyContent: 'center',
        alignItems: 'center',
      },
      imageContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
        borderRadius: ({ variant }) => (variant === 'squared' ? theme.spacing(1) : '50%'),
      },
      icon: {
        zIndex: 1,
        color: theme.palette.common.white,
        opacity: 0,
        position: 'absolute',
      },
      root: {
        width: ({ small }) => (small ? 100 : 84 * SIZE_MULTIPLIER),
        height: ({ small }) => (small ? 100 : 80 * SIZE_MULTIPLIER),
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: ({ variant }) => (variant === 'squared' ? theme.spacing(1) : '50%'),
        border: `2px solid ${theme.palette.common.white}`,
        boxShadow: `0 0 5px ${theme.palette.grey[700]}`,
        transition: theme.transitions.create(['width', 'height', 'margin'], {
          easing: theme.transitions.easing.easeIn,
          duration: theme.transitions.duration.standard,
        }),
        '&:focus': activeStyles,
        '@media (hover: hover)': {
          '&:hover': activeStyles,
        },
      },
    };
  },
);

export interface HeaderPhotoProps {
  src?: string;
  small?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  /** @default rounded */
  variant?: 'squared' | 'rounded';
  customPlaceholderSrc?: string;
}

/** @deprecated consider using HeaderPageLayout component or reach out to Design to discuss a new layout   */
export const HeaderPhoto: React.FC<HeaderPhotoProps> = ({
  customPlaceholderSrc,
  variant = 'rounded',
  src,
  small,
  onClick,
  disabled,
}) => {
  const imageSource = src ? imageSrc(src) : undefined;
  const classes = useStyles({ small, hasImage: Boolean(imageSource), variant });
  let finalSrc = imageSource;
  if (!finalSrc) {
    finalSrc = customPlaceholderSrc ?? defaultPlaceholderSrc;
  }
  return (
    // how to do we signal to devices without hover, that this is clickable?
    <ButtonBase className={classes['root']} focusRipple onClick={onClick} disabled={disabled}>
      <AddPhotoIcon className={classes['icon']} fontSize="large" />
      <Box
        className={clsx(classes['imageContainer'], {
          [classes['centered']]: !imageSource,
          [classes['defaultBackground']]: !imageSource,
        })}
      >
        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
        <img alt={'header photo'} aria-label={'header photo img'} className={classes['image']} src={finalSrc} />
      </Box>
    </ButtonBase>
  );
};
