import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import React from 'react';

import { ChipGroup } from '../ChipGroup';

export interface StandardsGroupProps {
  standards: string[];
  onClick?: (name: string) => void;
}

export const StandardsGroup: React.FC<StandardsGroupProps> = ({ standards, onClick }) => {
  return (
    <ChipGroup
      chipArray={standards.map(standardName => ({
        id: standardName,
        label: standardName,
        icon: <AssignmentTurnedInIcon />,
        variant: 'filled',
        state: 'selected',
      }))}
      onClick={onClick}
    />
  );
};
