import { InfoBox } from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { useOrganizationId } from '@circadian-risk/stores';
import { Box, Button, useTheme } from '@mui/material';
import React, { useCallback } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

export interface LocationInformationInfoBoxProps {
  locationId?: string;
  disabled?: boolean;
}
export const LocationInformationInfoBox: React.FC<LocationInformationInfoBoxProps> = ({ locationId, disabled }) => {
  const theme = useTheme();
  const history = useHistory();
  const organizationId = useOrganizationId();
  const handleOpenLocationDetails = useCallback(() => {
    if (!locationId) {
      return;
    }
    const path = `${generatePath(ROUTES.NODES, {
      organizationId,
    })}/${locationId}`;
    history.push(path);
  }, [locationId, organizationId, history]);

  return (
    <InfoBox disableTypographyWrapper>
      <Box aria-label={'executive summary info'} display={'flex'} alignItems={'center'}>
        <Box color={theme.palette.info.dark}>Information in this section comes from the Location Details.</Box>
        <Box ml={1}>
          <Button
            aria-label={'open location details'}
            variant="text"
            color="primary"
            onClick={handleOpenLocationDetails}
            disabled={disabled}
          >
            <Box color={theme.palette.info.dark}>Open Location Details</Box>
          </Button>
        </Box>
      </Box>
    </InfoBox>
  );
};
