import { Box, styled } from '@mui/material';
import { CSSProperties } from 'react';

interface ScrollAreaProps {
  maxHeight?: CSSProperties['maxHeight'];
  /**
   * Controls the behavior of overscrolling (dragging past the edge of the scroll area) on touch devices.
   * Set to none if you don't want to allow the bounce on touch devices
   * @default 'contain'
   */
  overscroll?: CSSProperties['overscrollBehavior'];
}

/**
 * make sure to pass max-height
 */
export const ScrollArea = styled(Box)<ScrollAreaProps>`
  overflow-x: auto;
  max-height: ${({ maxHeight }) => maxHeight};
  overscroll-behavior: ${({ overscroll }) => overscroll ?? 'contain'};
`;
