import {
  AssessmentAssignment,
  AssessmentAssignmentGroupRow,
  AssessmentAssignmentTeamRow,
  AssessmentAssignmentUserRow,
} from '@circadian-risk/api-contract';
import { AssessmentAssigneesFragment, AssessmentSubscribersFragment } from '@circadian-risk/client-graphql-hooks';

type Params = AssessmentAssigneesFragment & AssessmentSubscribersFragment;

export const createAssignmentFromQueryData = ({
  users,
  subscribedGroups,
  subscribedUsers,
  subscribedTeams,
  assignedGroups,
  assignedTeams,
}: Params): AssessmentAssignment => {
  const mappedAssignedUsers = users.map<AssessmentAssignmentUserRow>(u => ({
    id: u.user_id,
    type: 'assignee',
  }));

  const mappedSubscribedUsers = subscribedUsers.map<AssessmentAssignmentUserRow>(u => ({
    id: u.user_id,
    type: 'subscriber',
  }));

  const mappedAssignedGroups = assignedGroups.map<AssessmentAssignmentGroupRow>(ag => ({
    id: ag.user_group_id,
    type: 'assignee',
  }));

  const mappedSubscribedGroups = subscribedGroups.map<AssessmentAssignmentGroupRow>(ag => ({
    id: ag.user_group_id,
    type: 'subscriber',
  }));

  const mappedAssignedTeams = assignedTeams.map<AssessmentAssignmentTeamRow>(ag => ({
    id: ag.layer_team_id,
    type: 'assignee',
  }));

  const mappedSubscribedTeams = subscribedTeams.map<AssessmentAssignmentTeamRow>(ag => ({
    id: ag.layer_team_id,
    type: 'subscriber',
  }));

  return {
    user: [...mappedAssignedUsers, ...mappedSubscribedUsers],
    userGroup: [...mappedAssignedGroups, ...mappedSubscribedGroups],
    teams: [...mappedAssignedTeams, ...mappedSubscribedTeams],
  };
};
