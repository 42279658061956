import { CellRendererParams } from '@circadian-risk/data-grid';
import { Typography } from '@mui/material';
import React from 'react';

export type PartialComplianceCellRendererProps = CellRendererParams<{
  isDeficient: boolean;
  partialComplianceMultiplier?: number;
}>;
export const PartialComplianceCellRenderer: React.FC<PartialComplianceCellRendererProps> = ({ data }) => {
  const isPartialComplianceRelevant = Boolean(data.isDeficient && data.partialComplianceMultiplier);

  return isPartialComplianceRelevant ? (
    <Typography color="error">{`${data.partialComplianceMultiplier! * 100}%`}</Typography>
  ) : null;
};
