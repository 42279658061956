import { Layer_Tag, Layers } from '@circadian-risk/graphql-types';
import { HStack, LocationTagChip } from '@circadian-risk/presentational';
import React from 'react';

import { CellRendererParams, ValueGetterParams } from '../helpers';

export const LAYER_TAG_ALL = 'all';
export const LayerTagsTableFieldName = 'layerTags' as const;
export type LocationLayerTagFieldData = string;
export type LocationLayerTag = Pick<Layer_Tag, 'id' | 'type_name'> & { layer: Pick<Layers, 'name'> };
export type LocationLayerTagRow = { [LayerTagsTableFieldName]: LocationLayerTagFieldData[] };

/**
 * Cell renderer that will display value for location layer tag field
 */
export const LocationLayerTagCellRenderer: React.FC<CellRendererParams<LocationLayerTagRow>> = ({
  data: { layerTags },
}) => (
  <HStack>
    {layerTags
      // we don't want to display the 'all' value since every location has it
      .filter(tag => tag !== '' && tag.toLowerCase() !== LAYER_TAG_ALL)
      .map(tag => (
        <LocationTagChip key={tag} name={tag} />
      ))}
  </HStack>
);

/**
 * Simple value getter for LayerTags AgGrid column. Will filter out empty tags and 'all' tag
 */
export const LayerTagsValueGetter = ({ data }: ValueGetterParams<LocationLayerTagRow>) =>
  data.layerTags.filter(tag => tag !== LAYER_TAG_ALL && tag !== '');
