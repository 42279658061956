import { useTheme } from '@mui/material';

export interface RiskThresholds {
  lower: number | null;
  upper: number | null;
}

export const useRiskColors = ({ lower, upper }: RiskThresholds) => {
  const theme = useTheme();
  const { risk: riskColors } = theme.palette.organizationColors;

  const getColor = (value: number | null) => {
    let color = theme.palette.text.secondary;
    if (value == null) {
      color = theme.palette.text.secondary;
    } else if (lower && value < lower) {
      color = riskColors.low;
    } else if (upper && value > upper) {
      color = riskColors.high;
    } else if (lower && upper) {
      color = riskColors.medium;
    }
    return color;
  };

  return { getColor, riskColors };
};
