import { useMeasureDirty } from '@circadian-risk/front-end-utils';
import { HStack } from '@circadian-risk/presentational';
import { Box } from '@mui/material';
import { useRef } from 'react';

import { LessOrMore } from './LessOrMore';

const verySmallWidth = 310;
const largeWidth = 420;

export interface BottomRowWithLessMoreProps {
  open: boolean;
  hasMoreInfo: boolean;
  toggle: () => void;

  leftSlot?: React.ReactNode;
  rightSlot?: React.ReactNode;
  boxProps?: React.ComponentProps<typeof Box>;
}

export const BottomRowWithLessMore: React.FC<BottomRowWithLessMoreProps> = ({
  open,
  toggle,
  hasMoreInfo,
  leftSlot,
  rightSlot,
  boxProps,
}) => {
  const ref = useRef<HTMLDivElement>();
  const { width } = useMeasureDirty(ref);

  const commonProps = { display: 'flex', alignItems: 'center', gap: 1 };

  const isSmall = width < verySmallWidth;
  const isMedium = verySmallWidth < width && width < largeWidth;

  return (
    <Box {...commonProps} {...boxProps} ref={ref} flexWrap="wrap" flexDirection={isSmall ? 'column' : 'row'}>
      {leftSlot}
      <HStack
        {...commonProps}
        flex={1}
        flexWrap={isSmall ? 'wrap' : 'nowrap'}
        justifyContent={isSmall ? 'center' : 'flex-end'}
      >
        {rightSlot}
        {hasMoreInfo && <LessOrMore compact={isMedium} open={open} toggle={toggle} />}
      </HStack>
    </Box>
  );
};
