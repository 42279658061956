import { useResetCache } from '@circadian-risk/front-end-utils';
import { Box, BoxProps, ListSubheader, useMediaQuery, useTheme } from '@mui/material';
import React, { createContext, forwardRef, useCallback, useContext, useMemo } from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';

const OuterElementContext = createContext({});
const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <Box ref={ref} {...props} {...outerProps} />;
});

const HEIGHT_OF_ITEM_IN_MENU = 36;
const HEIGHT_OF_ITEM_AFTER_SELECTION = 48;
const LIST_SUB_HEADER_SIZE = 48;
const MAX_ITEMS_COUNT = 8;
const LISTBOX_PADDING = 8; // px

const renderRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  });
};

interface ListboxProps extends BoxProps {
  children?: React.ReactNode;
}

/**
 * Wrapper for virtualization a.k.a. "window technique"
 *
 * @see https://material-ui.com/components/autocomplete/#virtualization
 */
export const AutocompleteListbox = React.forwardRef<HTMLDivElement, ListboxProps>((props, ref) => {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const itemCount = itemData.length;
  const itemSize = useMemo(() => (smUp ? HEIGHT_OF_ITEM_IN_MENU : HEIGHT_OF_ITEM_AFTER_SELECTION), [smUp]);

  const getChildSize = useCallback(
    (child: React.ReactNode) => {
      if (React.isValidElement(child) && child.type === ListSubheader) {
        return LIST_SUB_HEADER_SIZE;
      }

      return itemSize;
    },
    [itemSize],
  );

  const getHeight = useCallback(() => {
    if (itemCount > MAX_ITEMS_COUNT) {
      return MAX_ITEMS_COUNT * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  }, [getChildSize, itemCount, itemData, itemSize]);

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={index => getChildSize(itemData[index])}
          overscanCount={10}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});
