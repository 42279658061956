import { useGetAssessmentListByOrgIdQuery } from '@circadian-risk/client-graphql-hooks';
import { useGqlQueryHandler } from '@circadian-risk/graphql-utils';
import { ROUTES } from '@circadian-risk/routes';
import { useStandardOrgRoleQueryPrecedence } from '@circadian-risk/stores';
import { AssessmentDetailsParams } from '@web-app/modules/Assessments/AssessmentDetails/AssessmentDetails.types';
import { AssessmentsDrawer } from '@web-app/modules/Assessments/AssessmentDetails/components/AssessmentsDrawer';
import React, { useCallback } from 'react';
import { generatePath, useParams } from 'react-router-dom';

export const SmartAssessmentsDrawer: React.FC = () => {
  const { id: assessmentId, organizationId } = useParams<AssessmentDetailsParams>();
  const context = useStandardOrgRoleQueryPrecedence();

  const { content } = useGqlQueryHandler(
    useGetAssessmentListByOrgIdQuery,
    {
      variables: {
        organizationId,
      },
      context,
    },
    useCallback(
      ({ data }) => {
        const assessments = data?.v2_assessments ?? [];

        const drawerList = assessments.map(assessment => ({
          link: generatePath(`${ROUTES.ASSESSMENTS}/:id`, {
            organizationId,
            id: assessment.id!,
          }),
          name: assessment.name ?? '',
          id: assessment.id!,
        }));

        return <AssessmentsDrawer assessments={drawerList} selectedId={Number(assessmentId)} />;
      },
      [assessmentId, organizationId],
    ),
    { isCallback: true },
  );

  return content;
};
