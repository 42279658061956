import { DateFormat } from '@circadian-risk/shared';
import { Box, InputAdornmentOwnProps } from '@mui/material';
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import React, { useCallback } from 'react';
import { Controller, ControllerRenderProps } from 'react-hook-form';

import { PropertyFieldProps } from '../types';

export type PropertyTimeFieldProps = PropertyFieldProps & {
  /**
   * @default end
   */
  adornmentPosition?: InputAdornmentOwnProps['position'];
  /**
   * Whether it should display ampm option
   * @default true
   */
  ampm?: TimePickerProps<dayjs.Dayjs>['ampm'];
  /**
   * Prevents the value from being cleared
   * @default true
   */
  clearable?: boolean;
};

export const PropertyTimeField: React.FC<PropertyTimeFieldProps> = ({
  label,
  htmlName,
  control,
  rules,
  boxProps,
  errorMessage,
  disabled,
  adornmentPosition = 'end',
  ampm = true,
  clearable = true,
  size,
}) => {
  const renderDatePicker = useCallback(
    ({ field: { onChange, value, onBlur } }: { field: ControllerRenderProps }) => {
      const changeProxy = (date?: dayjs.Dayjs | null) => {
        // Convert Dayjs date into the valid time format
        const newValue = date ? date.format(DateFormat.HOUR_AND_MINUTE_AM_PM) : null;
        onChange(newValue);
      };

      return (
        <TimePicker<dayjs.Dayjs>
          label={label}
          value={value ? dayjs(value, DateFormat.HOUR_AND_MINUTE_AM_PM) : null}
          onChange={date => changeProxy(date)}
          data-name={label}
          disabled={disabled}
          ampm={ampm}
          sx={{ width: '100%' }}
          slotProps={{
            field: { onBlur, clearable: false },
            textField: {
              error: Boolean(errorMessage),
              helperText: errorMessage,
              size,
              onKeyDown: clearable ? undefined : e => e.preventDefault(),
            },
            inputAdornment: {
              position: adornmentPosition,
            },
          }}
        />
      );
    },
    [label, disabled, ampm, errorMessage, size, clearable, adornmentPosition],
  );

  return (
    <Box {...boxProps}>
      <Controller control={control} name={htmlName} defaultValue={null} rules={rules} render={renderDatePicker} />
    </Box>
  );
};
