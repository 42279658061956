import { ButtonProps } from '@mui/material';
import noop from 'lodash/noop';
import React, { CSSProperties, useContext } from 'react';

export type ButtonConfig = {
  text: string;
  style?: CSSProperties;
  buttonProps?: ButtonProps;
};

export interface ConfirmationDialogConfig {
  title: string;
  message: string | JSX.Element;
  cancelButton?: ButtonConfig;
  confirmButton?: ButtonConfig;
  actionCallback(confirm: boolean): void;
}

export interface ConfirmationDialogState {
  openDialog: (config: ConfirmationDialogConfig) => void;
}

export const ConfirmationDialogContext = React.createContext<ConfirmationDialogState>({
  openDialog: noop,
});

/**
 * Hook that returns a getConfirmation function which you can use to asynchronously prompt the user
 * to make a confirmation in a dialog
 */
export const useConfirmationDialog = () => {
  const { openDialog } = useContext(ConfirmationDialogContext);

  const getConfirmation = ({ ...options }: Omit<ConfirmationDialogConfig, 'actionCallback'>): Promise<boolean> =>
    new Promise(res => {
      openDialog({ actionCallback: res, ...options });
    });

  return { getConfirmation };
};

export const DeleteConfirmationButtonConfig: ButtonConfig = {
  text: 'Delete',
  buttonProps: { color: 'error' },
};

export const ContinueConfirmationButtonConfig: ButtonConfig = {
  text: 'Continue',
};
