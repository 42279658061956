import { useTheme } from '@mui/material';
import React from 'react';

import { OptionsLibraryIcon } from '../../..';
import { OptionForConsiderationFlair } from '..';

export const LibraryFlair: React.FC<{ tooltip?: string; disabled?: boolean }> = ({
  tooltip = 'This option comes from your library. To edit it, navigate to the Options for Consideration page.',
  disabled,
}) => {
  const theme = useTheme();
  return (
    <OptionForConsiderationFlair
      id="Library Icon"
      Icon={OptionsLibraryIcon}
      color={disabled ? theme.palette.text.disabled : theme.palette.info.main}
      tooltip={tooltip}
    />
  );
};
