import { useState } from 'react';

type DropdownState = {
  anchorRef: HTMLElement | null;
  // Holds the key of the parent option that should be expanded by default
  defaultSelectedOption?: string;
};

export const useDropdownState = () => {
  const [{ anchorRef, defaultSelectedOption }, setDropdown] = useState<DropdownState>({
    anchorRef: null,
  });

  const closeDropdown = () => {
    setDropdown({ defaultSelectedOption: undefined, anchorRef: null });
  };

  const openDropdown = (anchorRef: HTMLElement | null, defaultSelectedOption?: string) => {
    setDropdown({
      anchorRef,
      defaultSelectedOption,
    });
  };

  return { anchorRef, defaultSelectedOption, openDropdown, closeDropdown };
};
