import { Bold, IpadCarousel, IPadCarouselProps, TextCycle, VStack } from '@circadian-risk/presentational';
import { Typography } from '@mui/material';
import React from 'react';

import performAssessmentScreen from '../../../assets/perform-assessment-demo.gif';
import riskDashboardScreenshot from '../../../assets/risk-dashboard-screenshot.jpg';
import scenarioCatalogScreenshot from '../../../assets/scenario-catalog-screenshot.jpg';

const iPadWidth = 400;
const iPadHeight = 300;
const changeWordsEvery = 3; // seconds

const TEXTS = [
  'Organizational',
  'Physical Security ',
  'Insider Threat',
  'Supply Chain',
  'Compliance',
  'Facilities',
  'Hazard',
  'Safety',
  'M & A',
];

const SCREENSHOTS: IPadCarouselProps['images'] = [
  {
    id: '1',
    description: 'Ready-to-use scenarios to assess all of your infrastructure',
    url: scenarioCatalogScreenshot,
  },
  {
    id: '2',
    description: 'Get started with your first assessment in minutes',
    url: performAssessmentScreen,
  },
  {
    id: '3',
    description: 'See your risk at a glance ',
    url: riskDashboardScreenshot,
  },
];

export interface IpadDemoProps {
  showTitle?: boolean;
}

export const ResponsiveIpadDemo: React.FC<IpadDemoProps> = () => {
  const dimensions = {
    width: {
      xs: iPadWidth / 1.5,
      md: iPadWidth * 1.2,
      lg: iPadWidth * 1.7,
      xl: iPadWidth * 2.2,
    },
    height: {
      xs: iPadHeight / 1.5,
      md: iPadHeight * 1.2,
      lg: iPadHeight * 1.7,
      xl: iPadHeight * 2.2,
    },
  };

  const dynamicWord = (
    <Bold color={theme => theme.palette.primary.main}>
      <TextCycle texts={TEXTS} speed={changeWordsEvery * 1000} />
    </Bold>
  );

  return (
    <VStack spacing={2} alignItems="center">
      <Typography
        variant="h4"
        component="h1"
        noWrap
        sx={{
          pl: 3,
          align: { xs: 'center', sm: 'left' },
          fontSize: theme => ({
            xs: theme.typography.body1.fontSize,
            md: theme.typography.h5.fontSize,
            lg: theme.typography.h4.fontSize,
          }),
        }}
      >
        Take control of your {dynamicWord} risks
      </Typography>

      <IpadCarousel
        images={SCREENSHOTS}
        frameProps={{
          minWidth: dimensions.width,
          minHeight: dimensions.height,
          maxWidth: dimensions.width,
          maxHeight: dimensions.height,
        }}
      />
    </VStack>
  );
};
