import { TextFieldProps } from '@mui/material';
import { FieldValues, Path, RegisterOptions, UseFormRegister } from 'react-hook-form';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form';

type MuiRegisterReturnType = Omit<UseFormRegisterReturn, 'ref'> & {
  inputRef: TextFieldProps['inputRef'];
};
/**
 * Returns `react-hook-form v7` input props and ìnputRef
 *
 * @param register
 * @param name
 * @param rules
 */
export const muiRegister = <T extends FieldValues>(
  register: UseFormRegister<T>,
  name: string,
  rules?: RegisterOptions,
): MuiRegisterReturnType => {
  const { ref, ...rest } = register(name as Path<T>, rules);
  return {
    ...rest,
    inputRef: ref,
  };
};
