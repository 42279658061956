import { FormStack, generateCircadianForm } from '@circadian-risk/form';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { z } from 'zod';

const valueSchema = z.string().nullable();
// The form is build based on the dynamic fields which ends up being a Record<FieldKey, DynamicValue>
const formSchema = z.record(z.string(), valueSchema);
type FormData = z.infer<typeof formSchema>;

export interface NarrativeEditDialogProps {
  title: string;
  open: boolean;
  onClose: () => void;
  onSave: (values: FormData) => Promise<boolean | null>;
  defaultValues: FormData;
  fields: { header: string; field: string }[];
}

const { useCircadianForm, RichText, GenericFormDialog } = generateCircadianForm(formSchema, {
  form: 'narrative edit dialog form',
  submit: 'Save',
});

export const NarrativeEditDialog: React.FC<NarrativeEditDialogProps> = ({
  title,
  onSave,
  defaultValues,
  fields,
  onClose,
  open,
}) => {
  const formMethods = useCircadianForm({
    values: defaultValues,
  });

  return (
    <GenericFormDialog
      isOpen={open}
      title={title}
      onClose={onClose}
      dialogProps={{ fullWidth: true, maxWidth: 'lg' }}
      formProps={{
        formMethods,
        onSubmit: onSave,
      }}
      closeVerb="Close"
      actionsProps={{ sx: { justifyContent: 'space-between' } }}
    >
      <FormStack>
        {fields.map(({ header, field }) => (
          <Box key={`narrative-edit-property-${field}`} mb={1}>
            <Box>
              <Typography variant={'h6'} color={theme => theme.palette.text.primary}>
                {header}
              </Typography>
              <RichText formKey={field} placeholder="enter your message here" />
            </Box>
          </Box>
        ))}
      </FormStack>
    </GenericFormDialog>
  );
};
