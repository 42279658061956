import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import TimelineIcon from '@mui/icons-material/Timeline';
import { Box, BoxProps, SvgIconTypeMap, Typography } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import sample from 'lodash/sample';
import React from 'react';

type ChartType = 'bubble' | 'pie' | 'bar' | 'line';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SvgIcon = OverridableComponent<SvgIconTypeMap<Record<string, any>, 'svg'>>;

const iconStyle: React.CSSProperties = { width: '100%', minHeight: '100%', maxWidth: '128px', maxHeight: '128px' };

const chartTypeIcons: Record<ChartType, SvgIcon> = {
  bar: InsertChartIcon,
  bubble: BubbleChartIcon,
  pie: DonutLargeIcon,
  line: TimelineIcon,
};

export const chartPlaceholderTexts = {
  loading: 'Loading...',
  error: "Chart can't be loaded, please contact support if this error persists",
};

export interface ChartPlaceholderProps extends BoxProps {
  title?: string;
  type?: ChartType;
  message?: string;
}

export const ChartPlaceholder: React.FC<ChartPlaceholderProps> = ({ title, type, message, ...rest }) => {
  const ChartIcon = type ? chartTypeIcons[type] : (sample(chartTypeIcons) as SvgIcon);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      width="100%"
      height="100%"
      p={2}
      minHeight="128px"
      {...rest}
    >
      {title && <Typography variant="h5">{title}</Typography>}
      <Box display="flex" flexGrow={1} justifyContent="center" p={1}>
        <ChartIcon color="disabled" style={iconStyle} />
      </Box>
      <Typography align="center" color="textSecondary">
        {message ?? 'No data available for this chart'}
      </Typography>
    </Box>
  );
};
