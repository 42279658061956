import { ROUTES } from '@circadian-risk/routes';
import { humanFormatDateTime } from '@circadian-risk/shared';
import { useOrganizationSessionStore } from '@circadian-risk/stores';
import { Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import NavLinkWithActiveOrgId from '@web-app/components/NavLinkWithActiveOrgId';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useAssessmentFormStore } from './AssessmentFormStore';
import { ScenarioFormData, Steps } from './ScenarioAssessmentForm.types';
import { useCompletionConditions } from './useCompletionConditions';

interface StepperProps {
  activeStep: Steps;
  vertical?: boolean;
}

const formatDateText = (startOrDue: 'start' | 'due', date: Date | string) => {
  return `${startOrDue === 'start' ? 'Start' : 'Due'}: ${humanFormatDateTime(date, false)}`;
};

export const FormStepper: React.FC<StepperProps> = ({ activeStep, vertical = false }) => {
  const { nodesById } = useOrganizationSessionStore(({ nodesById }) => ({ nodesById }));
  const { getValues, watch } = useFormContext<ScenarioFormData>();
  const { templates } = useAssessmentFormStore(state => ({ templates: state.templates }));
  const nodeIds = watch('locationIds');
  const countSelectedNodes = nodeIds.length;
  const watchAssignment = watch('assignment');
  const countSelectedUsers = watchAssignment.user.length;
  const countSelectedTeams = watchAssignment.teams.length;
  const countSelectedGroups = watchAssignment.userGroup.length;
  const containsSelection = countSelectedGroups > 0 || countSelectedTeams > 0 || countSelectedGroups > 0;

  const [savedFormValues, setSavedFormValues] = useState<Partial<ScenarioFormData>>();
  const template = savedFormValues?.assessmentTemplateId
    ? templates.find(t => t.id === savedFormValues.assessmentTemplateId)
    : null;

  const { importTotal, ratifyTotal, willImport } = useCompletionConditions();

  useEffect(() => {
    const values = getValues();
    setSavedFormValues(values);
  }, [activeStep, getValues]);

  return (
    <Stepper
      activeStep={activeStep}
      orientation={vertical ? 'vertical' : 'horizontal'}
      sx={{
        '&.MuiStepper-root': {
          background: 'transparent',
        },
      }}
    >
      <Step expanded>
        <StepLabel>Choose a Location and Template</StepLabel>
        {!isEmpty(savedFormValues) && (
          <StepContent>
            {
              <NavLinkWithActiveOrgId to={`${ROUTES.NODES}/${savedFormValues?.rootLocationId}`} target="_blank">
                {savedFormValues?.rootLocationId ? nodesById[savedFormValues?.rootLocationId]?.name : null}
              </NavLinkWithActiveOrgId>
            }
          </StepContent>
        )}
      </Step>
      <Step expanded>
        <StepLabel>Details</StepLabel>
        {!isEmpty(savedFormValues) && (
          <StepContent>
            <NavLinkWithActiveOrgId to={`${ROUTES.SCENARIO_ASSESSMENT_TEMPLATES}/${template?.id}`} target="_blank">
              {template?.name}
            </NavLinkWithActiveOrgId>
            <Typography variant="body2">{savedFormValues?.name}</Typography>
            <Typography variant="body2" color="textSecondary">
              {savedFormValues?.description}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {savedFormValues?.startDate ? formatDateText('start', savedFormValues?.startDate) : undefined}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {savedFormValues?.dueDate ? formatDateText('due', savedFormValues?.dueDate) : undefined}
            </Typography>
          </StepContent>
        )}
      </Step>
      <Step expanded>
        <StepLabel>Select Locations</StepLabel>
        {countSelectedNodes > 0 && (
          <StepContent>
            <Typography variant="body2">{countSelectedNodes} Locations Selected</Typography>
          </StepContent>
        )}
      </Step>
      <Step expanded>
        <StepLabel>Import Answers and Required Actions</StepLabel>
        {countSelectedNodes > 0 && (
          <StepContent>
            {willImport ? (
              <>
                <Typography variant="body2">{importTotal} Answers to Import</Typography>
                <Typography variant="body2">{ratifyTotal} Required to Ratify</Typography>
              </>
            ) : (
              <Typography variant="body2">No answers will be imported</Typography>
            )}
          </StepContent>
        )}
      </Step>
      <Step expanded>
        <StepLabel>Users</StepLabel>
        {containsSelection && (
          <StepContent>
            {countSelectedUsers > 0 && <Typography variant="body2">{countSelectedUsers} Users Assigned</Typography>}
            {countSelectedTeams > 0 && <Typography variant="body2">{countSelectedTeams} Teams Assigned</Typography>}
            {countSelectedGroups > 0 && <Typography variant="body2">{countSelectedGroups} Groups Assigned</Typography>}
          </StepContent>
        )}
      </Step>
      <Step expanded>
        <StepLabel>Summary</StepLabel>
      </Step>
    </Stepper>
  );
};
