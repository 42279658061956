import { useSubscribeApolloAuthError } from '@circadian-risk/apollo-utils';
import {
  logoutChannel,
  SESSION_EXPIRED_MESSAGE,
  useSupertokensSession,
  useSupertokensUnauthorized,
} from '@circadian-risk/authentication';
import { useListenVisibleOrgAssignmentsSubscription } from '@circadian-risk/client-graphql-hooks';
import { useNotification } from '@circadian-risk/front-end-utils';
import { ROUTES } from '@circadian-risk/routes';
import { sessionAccessPayloadSchema } from '@circadian-risk/shared';
import { OrganizationRow, useUserSessionStore } from '@circadian-risk/stores';
import { IStore, NovuProvider } from '@novu/notification-center';
import * as Sentry from '@sentry/react';
import { sentryEnabled } from '@web-app/App';
import { ErrorPage } from '@web-app/layouts/Errors';
import { useClearSessionDependencies } from '@web-app/lib/useClearSessionDependencies';
import { NavigationContent } from '@web-app/modules/AppLayout/NavigationWrapper';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Session from 'supertokens-web-js/recipe/session';

import { config } from '../environments/environment';
import { AdminSection } from './AdminSection';
import { EulaBoundary } from './EulaBoundary';
import { LoadingPage } from './LoadingPage';
import { NeedsSetPasswordBoundary } from './NeedsSetPasswordBoundary';
import { OrgSection } from './OrgSection';
import { orgScopedRoutes, routeConfig } from './RouteConfig';

const additionalAuthenticatedRoutePaths: string[] = [
  ROUTES.ORGANIZATION_SWITCH,
  ROUTES.ORGANIZATION_USER_PROFILE,
  ROUTES.PROFILE,
  ROUTES.USER_PROFILE_EDIT,
  ROUTES.CHANGE_PASSWORD,
  ROUTES.CREATE_ORG,
];

const novuMultiLayoutStores: IStore[] = [{ storeId: 'General' }];

const initialFetchingStrategy = { fetchUnseenCount: true, fetchNotifications: true };

const additionalAuthenticatedRoutes = routeConfig.filter(route =>
  additionalAuthenticatedRoutePaths.includes(route.path),
);

export const AuthenticatedSection: React.FC = () => {
  const { signOut } = useSupertokensSession();
  const { displayWarning } = useNotification();
  const clearSessionDependencies = useClearSessionDependencies();
  const { userId, subscriberHash, setSessionData, email, loaded } = useUserSessionStore(state => ({
    userId: state.id,
    subscriberHash: state.subscriberHash,
    setSessionData: state.setSessionData,
    resetSessionData: state.resetSessionData,
    email: state.email,
    consentsToMarketingCommunication: state.consentsToMarketingCommunication,
    loaded: state.loaded,
  }));

  useSupertokensUnauthorized(async () => {
    displayWarning(SESSION_EXPIRED_MESSAGE);
    await clearSessionDependencies();
    void logoutChannel.postMessage(true);
  });

  useSubscribeApolloAuthError(async data => {
    displayWarning(SESSION_EXPIRED_MESSAGE);
    await clearSessionDependencies();
    void logoutChannel.postMessage(true);

    if (sentryEnabled) {
      const { id: userId, email, tokenDefaultOrgId } = useUserSessionStore.getState();
      const sentryEvent: Sentry.Event = {
        message: `Apollo Authentication Error: ${data.motive}`,
        level: 'info',
        user: { id: userId, email },
        extra: {
          lastKnownJwt: data.lastKnownJwt,
          organizationId: tokenDefaultOrgId ?? 'no organization selected',
        },
      };

      Sentry.captureEvent(sentryEvent);
    }
  });

  const { loading } = useListenVisibleOrgAssignmentsSubscription({
    variables: {
      userId,
    },
    onData: async ({ data: { data } }) => {
      if (!data) {
        return;
      }

      const { users_by_pk: user } = data;
      if (user) {
        const {
          first_name,
          last_name,
          avatar,
          bio,
          title,
          location,
          company,
          phone_number,
          certifications,
          needs_to_set_password,
          last_signed_in_at: lastSignedInAt,
          users_organizations_organizations,
        } = user;

        const payload = await Session.getAccessTokenPayloadSecurely();
        const result = sessionAccessPayloadSchema.safeParse(payload);
        if (!result.success) {
          // eslint-disable-next-line no-console
          console.warn('Failure parsing the session payload schema, logging out..');
          await signOut();
          return;
        }

        setSessionData(result.data, {
          firstName: first_name ?? '',
          lastName: last_name ?? '',
          needsToSetPassword: needs_to_set_password,
          certifications,
          phone_number,
          bio,
          company,
          location,
          title,
          avatarFilePath: avatar?.filepath ?? undefined,
          lastSignedInAt,
          organizations: users_organizations_organizations.map<OrganizationRow>(({ organization }) => ({
            id: organization.id,
            name: organization.name,
            logo: organization.logo?.filepath ?? undefined,
            requires_eula: organization.requires_eula,
          })),
          loaded: true,
        });
      }
    },
  });

  // If the zustand store is not yet hydrated we should not be going forward
  if (loading || !loaded) {
    return <LoadingPage devMessage="Authenticated Section: loading user org associations" />;
  }

  return (
    <Sentry.ErrorBoundary
      fallback={() => <ErrorPage />}
      beforeCapture={scope => {
        scope.setUser({
          id: userId,
          email,
        });
      }}
    >
      <NovuProvider
        {...config.novu}
        subscriberId={userId}
        subscriberHash={subscriberHash}
        initialFetchingStrategy={initialFetchingStrategy}
        stores={novuMultiLayoutStores}
      >
        <NavigationContent>
          <div role="main">
            <NeedsSetPasswordBoundary>
              <EulaBoundary>
                <Switch>
                  <Route path="/organization">
                    <OrgSection orgScopedRoutes={orgScopedRoutes} />
                  </Route>
                  <Route path="/admin">
                    <AdminSection />
                  </Route>
                  {additionalAuthenticatedRoutes.map(route => {
                    const { component, path } = route;
                    return <Route key={path} exact path={path} component={component} />;
                  })}
                  <Route path="/" exact component={() => <span>Root</span>} />
                </Switch>
              </EulaBoundary>
            </NeedsSetPasswordBoundary>
          </div>
        </NavigationContent>
      </NovuProvider>
    </Sentry.ErrorBoundary>
  );
};
