import { imageSrc } from '@circadian-risk/front-end-utils';
import { File } from '@circadian-risk/shared';
import { SxProps, Theme, Tooltip, Typography } from '@mui/material';
import MuiAvatar, { AvatarProps } from '@mui/material/Avatar';
import initials from 'initials';
import isString from 'lodash/isString';
import React from 'react';

export interface TooltipAvatarProps {
  alt: string | string[];
  image?: string | null | File;
  size?: number;
  sx?: SxProps<Theme>;
  withTooltip?: boolean;
  customTooltip?: React.ReactNode;
  customFallback?: React.ReactNode;
  customAvatarContent?: React.ReactNode;
  variant?: 'square' | 'rounded' | 'circular';
}

export const TooltipAvatar: React.FC<TooltipAvatarProps> = ({
  image,
  alt,
  size,
  sx,
  withTooltip,
  customTooltip,
  customFallback,
  customAvatarContent,
  variant = 'circular',
}) => {
  const src = isString(image) ? imageSrc(image) : imageSrc(image?.filepath);
  const fallbackText = initials(alt).toString().slice(0, 2).toUpperCase() || undefined;
  const props: AvatarProps = {
    src,
    alt: isString(alt) ? alt : alt.toString(),
    sx: [
      {
        width: size,
        height: size,
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ],
    variant,
  };
  const avatarText = customFallback ?? fallbackText;

  const final = (
    <MuiAvatar {...props}>
      {customAvatarContent}
      {avatarText && !customAvatarContent && (
        <Typography style={{ fontSize: size && size / 3 }}>{avatarText}</Typography>
      )}
    </MuiAvatar>
  );

  const finalWithTooltip = (
    <Tooltip arrow title={customTooltip || alt}>
      {final}
    </Tooltip>
  );

  return withTooltip ? finalWithTooltip : final;
};
