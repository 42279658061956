import { Bold, VStack } from '@circadian-risk/presentational';
import { Typography } from '@mui/material';

export const CreateAssessmentFormGroupHeader: React.FC<{ title: string; subtitle?: string }> = ({
  title,
  subtitle,
}) => {
  return (
    <VStack spacing={0}>
      <Typography>
        <Bold>{title}</Bold>
      </Typography>
      {subtitle && (
        <Typography variant="caption" color="secondary">
          {subtitle}
        </Typography>
      )}
    </VStack>
  );
};
