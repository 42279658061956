/**
 * PageWrapper renders either our Header and Navigation (AuthenticatedLayout) around
 * a component that requires authentication, or it renders just the component and an Attribution
 * at the bottom.
 */

import { FCC } from '@circadian-risk/front-end-utils';
import { HEADER_HEIGHT } from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { useUserSessionStore } from '@circadian-risk/stores';
import { Box } from '@mui/material';
import { MenuGroup } from '@web-app/lib/getNavigationGroups';
import React from 'react';

import { AuthenticatedLayout } from './components/AuthenticatedLayout';
import Header from './components/Header/';
import { LayoutContextProvider } from './LayoutContextProvider';

interface PageWrapperProps {
  children: React.ReactNode;
  customHeader?: JSX.Element;
  navigationMenuGroups: MenuGroup[];
}

const PageWrapper: FCC<PageWrapperProps> = ({ children, customHeader, navigationMenuGroups }) => {
  const isAuthenticated = useUserSessionStore(state => state.isAuthenticated);

  if (!customHeader && isAuthenticated) {
    return (
      <LayoutContextProvider navigationMenuGroups={navigationMenuGroups}>
        <AuthenticatedLayout>{children}</AuthenticatedLayout>
      </LayoutContextProvider>
    );
  }

  return (
    <LayoutContextProvider navigationMenuGroups={navigationMenuGroups}>
      <Box display="grid" gridTemplateRows={`${HEADER_HEIGHT}px 1fr`} minHeight="100vh">
        {customHeader ?? <Header returnRoute={ROUTES.SIGN_IN} />}
        {children}
      </Box>
    </LayoutContextProvider>
  );
};

export default PageWrapper;
