import { Box, BoxProps } from '@mui/material';

export const FormBox: React.FC<BoxProps> = props => {
  return (
    <Box
      {...props}
      sx={theme => ({
        '& > :not(:first-of-type)': {
          marginTop: theme.spacing(1),
        },
      })}
    />
  );
};
