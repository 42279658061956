import { VStack } from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { useOrganizationId } from '@circadian-risk/stores';
import { Typography } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { generatePath, NavLink } from 'react-router-dom';

import { formatDateText } from '../../../components/helpers';
import { useCreateScheduledAssessmentFormContext } from '../../form';
import { useHumanReadableEndDate } from '../../hooks/useHumanReadableCadenceEndDate';
import { StepperSubtitleComponent } from '../../stepsConfig';
import { useSelectedTemplate } from '../RootStep/hooks/useSelectedTemplate';
import { useAssessmentStartDateWatch } from './hooks/useAssessmentStartDateWatch';

export const AssessmentDetailsStepperSubtitle: StepperSubtitleComponent = ({ activeStep }) => {
  const organizationId = useOrganizationId();
  const { watch } = useCreateScheduledAssessmentFormContext();
  const selectedTemplate = useSelectedTemplate();
  const name = watch('name');
  const description = watch('description');
  const startDate = useAssessmentStartDateWatch();
  const endDate = useHumanReadableEndDate();

  // If the user is still at the first step we shall not render anything
  if (activeStep === 0) {
    return null;
  }

  return (
    <VStack>
      {selectedTemplate && (
        <NavLink to={generatePath(ROUTES.SCENARIO_ASSESSMENT_TEMPLATES, { organizationId })} target="_blank">
          {selectedTemplate.name}
        </NavLink>
      )}
      {!isEmpty(name) && <Typography variant="body2">{name}</Typography>}
      {!isEmpty(description) && (
        <Typography variant="body2" color="textSecondary">
          {description}
        </Typography>
      )}
      {startDate && (
        <Typography variant="body2" color="textSecondary">
          {formatDateText('start', startDate)}
        </Typography>
      )}
      {endDate && (
        <Typography variant="body2" color="textSecondary">
          End: {endDate}
        </Typography>
      )}
    </VStack>
  );
};
