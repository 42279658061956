import { isImageExtension } from '@circadian-risk/front-end-utils';
import Avatar from '@mui/material/Avatar';
import path from 'path';
import { FiFile } from 'react-icons/fi';
import { SiAdobeacrobatreader, SiMicrosoftexcel, SiMicrosoftword } from 'react-icons/si';

const DEFAULT_LENGTH = 40;
export interface FileIconProps {
  filename: string;
  url?: string;
  width?: number;
  height?: number;
}

/**
 * Returns the correct icon/react element depending on the file extension
 *
 */
export const FileIcon: React.FC<FileIconProps> = ({ filename, url, width, height }) => {
  const fileExtension = path.extname(filename).toLowerCase();

  const iconStyle = { width: (width ?? DEFAULT_LENGTH) / 2, height: (height ?? DEFAULT_LENGTH) / 2 };

  if (isImageExtension(filename) && url) {
    return <Avatar role="img" src={url} variant="rounded" style={{ width, height }} />;
  }
  if (['.docx'].includes(fileExtension)) {
    return <SiMicrosoftword style={iconStyle} />;
  }
  if (['.xls', '.xlsx'].includes(fileExtension)) {
    return <SiMicrosoftexcel style={iconStyle} />;
  }
  if (fileExtension.includes('pdf')) {
    return <SiAdobeacrobatreader style={iconStyle} />;
  }
  return <FiFile style={iconStyle} />;
};
