import { createEnhancedStore } from '@circadian-risk/front-end-utils';

export interface AssessmentStore {
  drawerOpened: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
  toggleDrawer: () => void;
}

export const useAssessmentLayout = createEnhancedStore<AssessmentStore>((set, get) => ({
  drawerOpened: true,
  openDrawer: () => {
    set({ drawerOpened: true });
  },
  toggleDrawer: () => {
    set({ drawerOpened: !get().drawerOpened });
  },
  closeDrawer: () => {
    set({ drawerOpened: false });
  },
}));
