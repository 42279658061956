import {
  ImportedFlair,
  LibraryFlair,
  OptionForConsideration,
  OptionForConsiderationProps,
  useConfirmationDialog,
} from '@circadian-risk/presentational';
import { useOrganizationSessionStore } from '@circadian-risk/stores';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { IconButton } from '@mui/material';
import React from 'react';

import { CustomOption } from '../CustomBlockInput';

export type LibraryOption = Omit<CustomOption, 'id'> & { locationIds?: string[] } & {
  id: number;
  photoSrc?: string;
};

export interface OptionFromLibraryProps {
  value: Omit<LibraryOption, 'id'>;
  onRemove?: () => void;
  hideWrapper?: boolean;
  alreadyImported?: boolean;
}

export const OptionFromLibrary: React.FC<OptionFromLibraryProps> = ({
  value: { title, type, description, locationIds, photoSrc },
  onRemove,
  hideWrapper,
  alreadyImported,
}) => {
  const { getConfirmation } = useConfirmationDialog();

  const locations = useOrganizationSessionStore(({ nodesById, layersById }) => {
    return (locationIds ?? []).map(id => {
      const location = nodesById[id];
      const layer = layersById[location.layer_id];
      return {
        id,
        name: location.name,
        layerName: layer.name,
      };
    });
  });

  const flairs: OptionForConsiderationProps['flairs'] = [<LibraryFlair key="library-flair" />];
  if (alreadyImported) {
    flairs.unshift(<ImportedFlair key="already-imported" />);
  }

  const handleRemove = async () => {
    if (onRemove) {
      const confirmed = await getConfirmation({
        title: 'Unlink this option from the response?',
        message: 'You can add it again later using "Add from library" button',
        confirmButton: {
          text: 'Unlink',
          buttonProps: { color: 'error' },
        },
      });

      if (confirmed) {
        onRemove();
      }
    }
  };

  return (
    <OptionForConsideration
      description={description}
      locations={locations}
      principalAction={type}
      title={title}
      hideWrapper={hideWrapper}
      flairs={flairs}
      photoSrc={photoSrc}
      sideAction={
        onRemove && (
          <IconButton onClick={handleRemove}>
            <RemoveCircleIcon color="error" />
          </IconButton>
        )
      }
    />
  );
};
