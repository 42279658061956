import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { CreateScheduledAssessmentFormSchema } from '../form';
import { CreateAssessmentFormStepperConfig } from '../stepsConfig';

export const useCreateScheduledAssessmentNavigation = (
  onRedirectToSelection: () => void,
  steps: CreateAssessmentFormStepperConfig[],
  onSubmit: (data: CreateScheduledAssessmentFormSchema) => void,
) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const formContext = useFormContext<CreateScheduledAssessmentFormSchema>();

  const handleNext = async () => {
    const next = activeStep + 1;
    if (next >= steps.length) {
      // If we're on the last step we may submit instead
      await formContext.handleSubmit(
        data => onSubmit(data),
        // eslint-disable-next-line no-console
        err => console.log('validation failed', err),
      )();
      return;
    }

    const step = steps[activeStep];

    if (!step) {
      throw new Error(`Please make that step[${activeStep}] exists in the given steps`);
    }

    if (step.validator) {
      const validationSuccess = await step.validator(formContext);
      if (!validationSuccess) {
        return;
      }
    }

    setActiveStep(next);
  };

  const handleBack = () => {
    const next = activeStep - 1;

    if (next < 0) {
      onRedirectToSelection();
    } else {
      setActiveStep(next);
    }
  };

  return {
    activeStep,
    handleNext,
    handleBack,
  };
};
