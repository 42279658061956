import { Box } from '@mui/material';
import React from 'react';

import { UserCellProps, UserCellRenderer } from './UserCellRenderer';

export type SourceCellRendererParams = {
  data: UserCellProps & { userId: string };
  disableUserId?: boolean;
};

export const SourceCellRenderer: React.FC<SourceCellRendererParams> = ({ data, disableUserId = false }) => {
  if (!data.userName) {
    return <span>--</span>;
  }

  return (
    <Box display={'flex'} flexDirection={'column'} mt={2}>
      <Box display={'flex'} alignItems={'center'} height={15}>
        <UserCellRenderer data={data} />
      </Box>
      {!disableUserId && <Box>[id: {data.userId}]</Box>}
    </Box>
  );
};
