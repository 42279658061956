import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, Divider, List, ListItem, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import { MenuGroup, MenuGroupItem } from '@web-app/lib/getNavigationGroups';
import React, { useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';

interface ListCollapsibleProps {
  menuGroup: MenuGroup;
  isLastItem: boolean;
  collapsed: boolean;
  icon: JSX.Element | undefined;
  name: string;
}

export const ListCollapsible: React.FC<ListCollapsibleProps> = ({ menuGroup, collapsed, icon, name, isLastItem }) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);
  const collapseToggle = useCallback(() => setIsCollapsed(prev => !prev), []);
  const theme = useTheme();

  return (
    <Box color={theme.palette.text.primary}>
      <ListItem button onClick={collapseToggle}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={name} />
        {isCollapsed ? <ExpandMore /> : <ExpandLess />}
      </ListItem>
      <Collapse in={!isCollapsed} timeout="auto" unmountOnExit>
        <List disablePadding role={'group'}>
          {menuGroup.items.map((menuGroupItem: MenuGroupItem) => {
            if (menuGroupItem.customComponent) {
              return (
                <Box pl={5} key={menuGroupItem.name}>
                  {menuGroupItem.customComponent}
                </Box>
              );
            }

            const content = (
              <ListItem
                sx={theme => ({
                  paddingLeft: theme.spacing(7),
                  ...(menuGroupItem.isActive
                    ? { backgroundColor: theme.palette.action.focus, color: theme.palette.info.main }
                    : {}),
                })}
                role={'listitem'}
                aria-label={menuGroupItem.name}
              >
                {menuGroupItem.icon && <ListItemIcon>{menuGroupItem.icon}</ListItemIcon>}
                <ListItemText>{menuGroupItem.name}</ListItemText>
              </ListItem>
            );

            if (menuGroupItem.isExternal) {
              return (
                <a key={menuGroupItem.link} href={menuGroupItem.link} target={'_blank'} rel="noreferrer">
                  {content}
                </a>
              );
            }

            return (
              <NavLink key={menuGroupItem.link} to={menuGroupItem.link}>
                {content}
              </NavLink>
            );
          })}
        </List>
      </Collapse>
      {!isLastItem && <Divider />}
    </Box>
  );
};
