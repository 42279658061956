import {
  ApolloClient,
  ApolloClientOptions,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition, Observable } from '@apollo/client/utilities';
import { Query_Root } from '@circadian-risk/graphql-types';
import { SessionAccessPayload, sessionAccessPayloadSchema } from '@circadian-risk/shared';
import { Laika } from '@zendesk/laika/cjs/laika';
import fetch from 'cross-fetch';
import { createClient } from 'graphql-ws';
import { Subject } from 'rxjs';
import Session from 'supertokens-web-js/recipe/session';

import { TypeSafePolicies } from './cacheUtils';

export const typeSafePolicies = {
  active_answers: {
    keyFields: ['item_id', 'question_id'],
  },
  // TODO(miking-the-viking): all_node_area_tags keyFields not accessible by codegen? [CR-4071]
  all_node_area_tags: {
    keyFields: ['node_id', 'area_tag_id'],
  },
  answers_files: {
    keyFields: ['answer_id', 'file_id'],
  },
  assessment_items_files: {
    keyFields: ['assessment_item_id', 'file_id'],
  },
  catalog_scenario_meta_source: {
    keyFields: ['catalog_scenario_meta_id', 'catalog_source_id'],
  },
  catalog_scenario_meta: {
    fields: {
      sources: {
        merge(_existing, incoming) {
          return incoming;
        },
      },
    },
  },
  consideration_option_item_categories: {
    keyFields: ['organization_id', 'consideration_option_id', 'item_category_id'],
  },
  consideration_option_nodes: {
    keyFields: ['organization_id', 'consideration_option_id', 'node_id'],
  },
  item_answer_aggregates: {
    keyFields: ['item_id'],
  },
  item_property_group_value: {
    keyFields: ['item_id', 'config_id', 'organization_id'],
  },
  location_layer_tag: {
    keyFields: ['layer_tag_id', 'organization_id', 'node_id'],
  },
  location_property_group_value: {
    keyFields: ['location_id', 'config_id', 'organization_id'],
  },
  location_scenario_info: {
    keyFields: ['scenario_id', 'node_id'],
  },
  location_scenario_probability_value: {
    keyFields: ['scenario_id', 'location_id', 'probability_measure_id'],
  },
  location_scenario_aggregates_snapshots: {
    keyFields: ['node_id', 'scenario_id', 'sys_period'],
  },
  location_scenario_impact_value: {
    keyFields: ['scenario_id', 'location_id', 'impact_measure_id'],
  },
  primary_scenario_question_sets: {
    keyFields: ['scenario_id', 'scenario_question_set_id'],
  },
  scenario: {
    fields: {
      // Merges the scenario measure values refs
      locationImpactValues: {
        merge: (existing = [], incoming) => {
          return [...existing, ...incoming];
        },
      },
      locationProbabilityValues: {
        merge: (existing = [], incoming) => {
          return [...existing, ...incoming];
        },
      },
      questionSets: {
        merge: (_existing, incoming) => {
          return incoming;
        },
      },
    },
  },
  scenario_location_type: {
    keyFields: ['layer_id', 'scenario_id'],
  },
  scenario_question_set_location_tags: {
    keyFields: ['layer_id', 'layer_tag_id', 'scenario_question_set_id'],
  },
  scenario_question_set_item_categories: {
    keyFields: ['item_category_id', 'scenario_question_set_id'],
  },
  scenario_question_set_question: {
    keyFields: ['layer_id', 'scenario_question_set_id', 'layer_tag_id', 'question_id'],
  },
  scenario_question_set_selected_questions: {
    keyFields: ['question_id', 'scenario_question_set_id'],
  },
  scenario_question_set_standards: {
    keyFields: ['scenario_question_set_id', 'question_id'],
  },
  task_actions_files: {
    keyFields: ['task_action_id', 'file_id'],
  },
  tasks: {
    keyFields: ['task_no'],
    fields: {
      task_actions: {
        merge: (existing, incoming) => {
          // If there are existing task_actions and another operation
          // tries to set them to an empty array we should preserve existing
          if (existing?.length && !incoming.length) {
            return existing;
          }
          return incoming;
        },
      },
    },
  },
  v2_assessment_nodes: {
    keyFields: ['organization_id', 'node_id', 'assessment_id'],
  },
  v2_assessments: {
    fields: {
      locations: {
        merge: (existing = [], incoming) => {
          return [...existing, ...incoming];
        },
      },
      users: {
        merge: (_existing = [], incoming) => {
          // Mutations to assessment users only are performed in batch update
          // therefore the incoming values are the desired value
          return incoming;
        },
      },
      assignedTeams: {
        merge: (_existing = [], incoming) => {
          // Mutations to assessment teams only are performed in batch update
          // therefore the incoming values are the desired value
          return incoming;
        },
      },
      assignedGroups: {
        merge: (_existing = [], incoming) => {
          // Mutations to assessment groups only are performed in batch update
          // therefore the incoming values are the desired value
          return incoming;
        },
      },
      subscribedTeams: {
        merge: (_existing = [], incoming) => {
          // Mutations to assessment subscriber teams only are performed in batch update
          // therefore the incoming values are the desired value
          return incoming;
        },
      },
      subscribedGroups: {
        merge: (_existing = [], incoming) => {
          // Mutations to assessment subscriber groups only are performed in batch update
          // therefore the incoming values are the desired value
          return incoming;
        },
      },
      subscribedUsers: {
        merge: (_existing = [], incoming) => {
          // Mutations to assessment subscriber users only are performed in batch update
          // therefore the incoming values are the desired value
          return incoming;
        },
      },
    },
  },
  virtual_assessment_item_question_answer: {
    keyFields: ['assessment_item_id', 'question_id'],
  },
  virtual_location_answers: {
    keyFields: ['answer_id'],
  },
  virtual_scenario_layer_tags: {
    keyFields: ['layer_id', 'layer_tag_id'],
  },
  groups: {
    keyFields: ['organization_id', 'id'],
  },
  group_users: {
    keyFields: ['organization_id', 'group_id', 'user_id'],
  },
  layer_teams: {
    keyFields: ['organization_id', 'id'],
  },
  task_actions_history: {
    keyFields: ['id', 'history_id'],
  },
  location_team_users: {
    keyFields: ['location_id', 'user_id', 'organization_id', 'layer_team_id'],
  },
  assessment_user_subscribers: {
    keyFields: ['organization_id', 'user_id', 'assessment_id'],
  },
  assessment_group_subscribers: {
    keyFields: ['organization_id', 'user_group_id', 'assessment_id'],
  },
  assessment_team_subscribers: {
    keyFields: ['organization_id', 'layer_team_id', 'assessment_id'],
  },
  assessment_user_groups: {
    keyFields: ['organization_id', 'user_group_id', 'assessment_id'],
  },
  assessment_teams: {
    keyFields: ['organization_id', 'layer_team_id', 'assessment_id'],
  },
  action_deficiencies: {
    keyFields: ['task_action_id', 'question_id', 'item_id'],
  },
  scheduled_assessment_schedule: {
    keyFields: ['organization_id', 'id'],
  },
  scheduled_assessment_schedule_locations: {
    keyFields: ['organization_id', 'scheduled_assessment_schedule_id', 'location_id'],
  },
  scheduled_assessment_schedule_users: {
    keyFields: ['organization_id', 'scheduled_assessment_schedule_id', 'user_id'],
  },
  scheduled_assessment_schedule_groups: {
    keyFields: ['organization_id', 'scheduled_assessment_schedule_id', 'group_id'],
  },
  scheduled_assessment_schedule_teams: {
    keyFields: ['organization_id', 'scheduled_assessment_schedule_id', 'layer_team_id'],
  },
} satisfies TypeSafePolicies<Query_Root>;

// TODO(backlog)[CR-2335]: https://linear.app/circadian-risk/issue/CR-2335/refactor-apollo-lib-to-not-have-a-defaultcache-export
export const defaultCache = new InMemoryCache({
  typePolicies: typeSafePolicies,
});

// TODO(backlog)[CR-2335]
const defaultApolloClientOptions: ApolloClientOptions<NormalizedCacheObject>['defaultOptions'] = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  },
};

export type ApolloAuthErrorData = {
  motive: string;
  lastKnownJwt: string | undefined;
};

/**
 * This subject will be used to notify the application that an authentication error
 * It's important to note that this subject will be used to log out the user
 * and report to sentry (if enabled) the motive for further debugging
 */
export const apolloAuthError$ = new Subject<ApolloAuthErrorData>();

export type ApolloClientFactoryOptions = {
  uri: string;
  authOptions?: {
    jwt: string;
    hasuraRole?: string;
  };
  enableSubscriptions?: boolean;
  laika?: Laika;
  cacheInstance?: InMemoryCache;
} & Pick<ApolloClientOptions<NormalizedCacheObject>, 'defaultOptions'>;

export type SessionBasedClientFactory = Omit<ApolloClientFactoryOptions, 'auth'> & {
  /**
   * Given the hasuraRole parameter it will override the JWT claim "x-hasura-default-role"
   * This might be useful when we want to enforce that a token only has "app-user" permissions
   */
  hasuraRole?: string;
  /**
   * Decides whether connecting to the Apollo DevTools is enabled
   * @default true for dev environment
   */
  shouldConnectToDevTools?: boolean;
};

const createWsLink = (uri: string, hasuraRole?: string) => {
  const [protocol, endpoint] = uri.split('//');
  const wsUri = protocol.startsWith('https') ? `wss://${endpoint}` : `ws://${endpoint}`;
  let shouldRetryWsConnection = true;
  let lastKnownJwt: string | undefined;

  return new GraphQLWsLink(
    createClient({
      url: wsUri,
      retryAttempts: 5,
      connectionParams: async () => {
        const accessTokenPayload = await Session.getAccessTokenPayloadSecurely();
        const jwt = accessTokenPayload?.jwt;

        // This will be likely an error in the session or when we migrate
        // but if that happens, we should be able to unstuck the user
        // and be logged out. The websocket should not retry to connect
        if (!jwt) {
          apolloAuthError$.next({
            motive: 'No JWT found in the session',
            lastKnownJwt: undefined,
          });
          shouldRetryWsConnection = false;
          return {};
        }

        // Sync this variable which can be used to later on report to sentry
        lastKnownJwt = jwt;

        const result = sessionAccessPayloadSchema.safeParse(accessTokenPayload);
        const parsedRole = result.success
          ? result.data['https://hasura.io/jwt/claims']['x-hasura-default-role']
          : 'app-user';

        return {
          headers: {
            Authorization: `Bearer ${jwt}`,
            'x-hasura-role': hasuraRole ?? parsedRole,
          },
        };
      },
      /**
       * A quick reference to why we're evaluating retry
       * @see https://github.com/enisdenjo/graphql-ws/discussions/167
       */
      shouldRetry: () => {
        return shouldRetryWsConnection;
      },
      on: {
        closed: event => {
          const code = (event as CloseEvent).code;
          /**
           * 4403 = Forbidden server error thrown by Hasura if an invalid jwt is provided
           * 4005 =  Could not refresh session or No session exists
           */
          const isSessionRelated = [4403, 4005].includes(code);

          if (isSessionRelated) {
            Session.doesSessionExist()
              .then(hasSession => {
                shouldRetryWsConnection = hasSession;
              })
              .catch(ex => {
                // eslint-disable-next-line no-console
                console.error(ex);
                shouldRetryWsConnection = false;

                apolloAuthError$.next({
                  motive: 'Session no longer exists while trying to reconnect to WS',
                  lastKnownJwt,
                });
              });
          }
        },
      },
    }),
  );
};

export const sessionBasedClientFactory: (
  options: SessionBasedClientFactory,
) => ApolloClient<NormalizedCacheObject> = options => {
  const {
    uri,
    laika,
    cacheInstance = defaultCache,
    defaultOptions = defaultApolloClientOptions,
    enableSubscriptions = true,
    hasuraRole,
    shouldConnectToDevTools = process.env['NODE_ENV'] === 'development',
  } = options;

  let sessionJwt: SessionAccessPayload['jwt'] | undefined;
  let role: string | undefined;

  const syncJwtAndRole = async () => {
    const hasSession = await Session.doesSessionExist();
    if (!hasSession) {
      // No need to report any error message as onHandleEvent will be automatically
      // invoked
      return;
    }

    try {
      const payload = await Session.getAccessTokenPayloadSecurely();
      const result = sessionAccessPayloadSchema.safeParse(payload);
      if (result.success) {
        sessionJwt = result.data.jwt;
        role = result.data['https://hasura.io/jwt/claims']['x-hasura-default-role'];
      } else {
        throw new Error(result.error.message);
      }
    } catch (ex) {
      // If there's anything wrong with the session we should notify this subject
      // as it will handle logging out the user
      apolloAuthError$.next({
        motive: `An error was encountered while trying to get the session payload: ${ex.message}`,
        lastKnownJwt: sessionJwt,
      });
    }
  };

  const authLink = setContext(async (_request, { headers }) => {
    await syncJwtAndRole();
    const overrideRole = headers?.['x-hasura-role'];

    const newHeaders = {
      ...headers,
      Authorization: `Bearer ${sessionJwt}`,
      'x-hasura-role': overrideRole ?? hasuraRole ?? role,
    };

    return {
      headers: newHeaders,
    };
  });

  const retryErrorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (!graphQLErrors) {
      return;
    }

    const jwsInvalidSignatureError = graphQLErrors.find(e => e.message.includes('JWSInvalidSignature'));

    // Detecting a JWS signature error should trigger an auth error
    if (jwsInvalidSignatureError) {
      apolloAuthError$.next({
        motive: jwsInvalidSignatureError.message,
        lastKnownJwt: sessionJwt,
      });
      return;
    }

    const isJwtExpired = graphQLErrors.some(e => e.message.includes('JWTExpired'));
    if (isJwtExpired) {
      return new Observable(observer => {
        syncJwtAndRole().then(() => {
          operation.setContext(({ headers = {} }) => {
            return {
              headers: {
                ...headers,
                Authorization: `Bearer ${sessionJwt}`,
                'x-hasura-role': role,
              },
            };
          });

          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          };

          forward(operation).subscribe(subscriber);
        });
      }) as any;
    }

    return;
  });

  const authFlowLink = authLink.concat(retryErrorLink);

  const httpLink = authFlowLink.concat(
    createHttpLink({
      uri,
      fetch,
    }),
  );

  let finalLink = httpLink;

  // If subscriptions are enabled we need to set up a websocket and then split control
  // over the operations so that subscriptions will be handled by the WS Link
  if (enableSubscriptions) {
    const wsLink = createWsLink(uri, hasuraRole);
    finalLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
      },
      wsLink,
      httpLink,
    );
  }

  // If a Laika instance is provided....
  if (laika) {
    finalLink = ApolloLink.from([laika.createLink(), finalLink]);
  }

  return new ApolloClient({
    cache: cacheInstance,
    link: finalLink,
    defaultOptions,
    connectToDevTools: shouldConnectToDevTools,
  });
};

export const mswClientFactory: (
  options: SessionBasedClientFactory,
) => ApolloClient<NormalizedCacheObject> = options => {
  const { uri, cacheInstance = defaultCache, defaultOptions = defaultApolloClientOptions } = options;

  const httpLink = createHttpLink({
    uri,
    fetch,
  });

  return new ApolloClient({
    cache: cacheInstance,
    link: httpLink,
    defaultOptions,
    connectToDevTools: process.env['NODE_ENV'] === 'development',
  });
};
