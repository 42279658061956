import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Button, Menu, MenuItem, SxProps, Theme, useTheme } from '@mui/material';
import lodashGet from 'lodash/get';
import range from 'lodash/range';
import React, { CSSProperties, useState } from 'react';

import { CriticalityIcon } from '../Icons';
import { CriticalityDropdownIcon } from './CriticalityDropdownIcon';

const DEFAULT_MAX_CRITICALITY = 3;
const iconStyles: CSSProperties = { marginRight: -6, marginLeft: 6 };

export type Criticality = 1 | 2 | 3;

export interface CriticalityDropdownInputProps {
  onChange?: (value: Criticality) => void;
  value?: Criticality;
  maxCriticality?: Criticality;
  criticalityLabels?: Record<Criticality, React.ReactNode>;
  sx?: SxProps<Theme>;
  labelInButton?: boolean;
  disabled?: boolean;
  /**
   * @default 'desc'
   */
  order?: 'asc' | 'desc';
}

export const CriticalityDropdownInput: React.FC<CriticalityDropdownInputProps> = React.memo(
  ({
    disabled,
    onChange,
    value,
    criticalityLabels,
    maxCriticality = DEFAULT_MAX_CRITICALITY,
    sx,
    labelInButton,
    order = 'desc',
  }) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleChange = (criticalityValue: Criticality) => {
      onChange?.(criticalityValue);
      setAnchorEl(null);
    };

    const criticalityColors = theme.palette.organizationColors.criticality;
    const sortedRange = (order === 'desc' ? range(maxCriticality, 0) : range(1, maxCriticality + 1)) as Criticality[];

    return (
      <>
        <Button
          size="small"
          variant="outlined"
          onClick={event => setAnchorEl(event.currentTarget)}
          sx={sx}
          disabled={disabled}
        >
          <CriticalityDropdownIcon criticality={value} maxCriticality={maxCriticality} />
          {value && labelInButton && (
            <Box component="span" pl={1}>
              {lodashGet(criticalityLabels, value, value)}
            </Box>
          )}
          {!disabled && <ArrowDropDownIcon fontSize="small" style={iconStyles} />}
        </Button>
        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={() => setAnchorEl(null)}
        >
          {sortedRange.map(critNum => (
            <MenuItem key={critNum} onClick={() => handleChange(critNum)}>
              <CriticalityIcon
                color={criticalityColors[critNum]}
                criticality={critNum}
                maxCriticality={maxCriticality}
              />
              <Box component="span" pl={1}>
                {lodashGet(criticalityLabels, critNum, critNum)}
              </Box>
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  },
);
