import { AssessmentItemStatusEnum } from '../common-types';

/**
 * Calculates the status of an Inspection Item according to the old app
 */
export const getInspectionItemStatus = (options: {
  isPresent: boolean | null;
  completedAnswers: number;
  totalAnswers: number;
  deficientAnswers: number;
}) => {
  const { isPresent, completedAnswers, totalAnswers, deficientAnswers } = options;

  if (isPresent === null) {
    return AssessmentItemStatusEnum.NotStarted;
  }

  if (completedAnswers < totalAnswers) {
    return AssessmentItemStatusEnum.InProgress;
  }

  if (isPresent === false) {
    return AssessmentItemStatusEnum.IsMissing;
  }

  const isCompliant = deficientAnswers === 0 && completedAnswers === totalAnswers;
  if (isCompliant) {
    return AssessmentItemStatusEnum.IsCompliant;
  }

  if (completedAnswers === totalAnswers && deficientAnswers > 0) {
    return AssessmentItemStatusEnum.IsDeficient;
  }

  throw new Error('Could not calculate Status! Check your item');
};

/**
 * Calculates the status of an Inspection Item according to the rules in the assessment-app
 */
export const getInspectionItemStatusForAssessmentApp = (options: {
  isPresent: boolean | null;
  completedAnswers: number;
  totalAnswers: number;
  deficientAnswers: number;
}) => {
  const { isPresent, completedAnswers, totalAnswers, deficientAnswers } = options;

  if (isPresent === null) {
    return AssessmentItemStatusEnum.NotStarted;
  }

  if (isPresent === false) {
    return AssessmentItemStatusEnum.IsMissing;
  }

  if (deficientAnswers > 0) {
    return AssessmentItemStatusEnum.IsDeficient;
  }

  const isCompliant = deficientAnswers === 0 && completedAnswers === totalAnswers;
  if (isCompliant) {
    return AssessmentItemStatusEnum.IsCompliant;
  }

  if (completedAnswers < totalAnswers || totalAnswers === 0) {
    return AssessmentItemStatusEnum.InProgress;
  }

  throw new Error('Could not calculate Status! Check your item');
};
