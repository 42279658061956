import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { FormControl, InputLabel, TextareaAutosizeProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import React, { CSSProperties, TextareaHTMLAttributes, useRef } from 'react';

const PREFIX = 'TextAreaAutosizeField';

const classes = {
  hasError: `${PREFIX}-hasError`,
  errorLabel: `${PREFIX}-errorLabel`,
  label: `${PREFIX}-label`,
  textarea: `${PREFIX}-textarea`,
  wrapper: `${PREFIX}-wrapper`,
};

const StyledFormControl = styled(FormControl)(({ theme, disabled }) => {
  return {
    [`&.${classes.hasError}`]: {
      borderColor: `${theme.palette.error.main} !important`,
      color: `${theme.palette.error.main}`,
    },
    [`& .${classes.errorLabel}`]: {
      color: `${theme.palette.error.main} !important`,
    },
    [`& .${classes.label}`]: {
      backgroundColor: theme.palette.background.paper,
      color: disabled ? theme.palette.action.disabled : theme.palette.text.primary,
      paddingLeft: 4,
      paddingRight: 4,
    },
    [`& .${classes.textarea}`]: {
      border: `1px solid ${theme.palette.grey[400]}`,
      color: disabled ? theme.palette.action.disabled : theme.palette.text.primary,
      background: theme.palette.background.paper,
      borderRadius: 4,
      display: 'flex',
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.body1.fontSize,
      padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
      resize: disabled ? 'none' : 'vertical',
      '&:focus, &:hover': {
        borderColor: disabled ? theme.palette.action.disabled : theme.palette.primary.main,
        outline: 'none',
      },
      '&:focus ~ label': {
        color: disabled ? theme.palette.action.disabled : theme.palette.primary.main,
      },
    },
    [`&.${classes.wrapper}`]: {},
  };
});

const MINIMUM_ROW_HEIGHT = 1;

export interface TextAreaAutosizeFieldProps extends Partial<TextareaAutosizeProps> {
  label: React.ReactNode;
  id?: string;
  name: string;
  error?: boolean;
  /** @default true */
  fullWidth?: boolean;
  errorMessage?: React.ReactNode;
  value?: TextareaHTMLAttributes<string>['value'];
}

const DEFAULT_STYLE: CSSProperties = { minHeight: '100px' };

export const TextAreaAutosizeField: React.FC<TextAreaAutosizeFieldProps> = ({
  className,
  fullWidth = true,
  errorMessage,
  ...props
}) => {
  const defaultStyle = { ...DEFAULT_STYLE, ...(props.style ?? {}) };
  // Ensure the value is valid otherwise TextArea will complain
  const formattedValue = !props.value ? '' : props.value;
  const textAreaRef = useRef<HTMLTextAreaElement | undefined>();

  return (
    <StyledFormControl
      disabled={props.disabled}
      className={clsx(classes.wrapper, { [classes.hasError]: errorMessage })}
      fullWidth={fullWidth}
    >
      <TextareaAutosize
        ref={textAreaRef as React.Ref<HTMLTextAreaElement>}
        aria-label={String(props.label)}
        className={clsx(classes.textarea, className, {
          [classes.hasError]: errorMessage,
        })}
        id={props.id ?? props.name}
        minRows={MINIMUM_ROW_HEIGHT}
        {...props}
        value={formattedValue}
        style={defaultStyle}
      />
      <InputLabel
        htmlFor={props.id ?? props.name}
        classes={{
          root: clsx(classes.label, { [classes.hasError]: errorMessage }),
          error: classes.errorLabel,
        }}
        error={Boolean(errorMessage)}
        shrink
      >
        {props.label}
      </InputLabel>
      {errorMessage}
    </StyledFormControl>
  );
};
