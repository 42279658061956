export const isAssessmentComplete = (
  physicalInspectionProgress: number | null,
  questionnaireProgress: number | null,
) => {
  return (
    (physicalInspectionProgress === 100 && questionnaireProgress === 100) ||
    (physicalInspectionProgress === 100 && questionnaireProgress === null) ||
    (questionnaireProgress === 100 && physicalInspectionProgress === null)
  );
};
