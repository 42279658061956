import { humanFormatDateTime } from '@circadian-risk/shared';
import { Chip } from '@mui/material';
import isNumber from 'lodash/isNumber';
import pluralize from 'pluralize';
import React from 'react';

import { TaskStatusChip } from '../TaskStatusChip';
import { TaskActionPropertyIcon } from './TaskActionPropertyIcon';
import { TaskActionProperty } from './types';

const getPropertyLabel = (property: TaskActionProperty): string | null => {
  switch (property.type) {
    case 'taskAssignee':
      return property.value.fullName;
    case 'dueDate':
      return property.value ? humanFormatDateTime(property.value, false) : null;
    case 'timeEstimate':
      return `${property.value} ${pluralize('day', property.value ?? undefined)}`;
    default:
      return isNumber(property.value) ? property.value.toString() : property.value ?? null;
  }
};

export const TaskActionPropertyChip: React.FC<TaskActionProperty & { disabled?: boolean }> = property => {
  const disabled = property.disabled;

  if (property.type === 'status') {
    return <TaskStatusChip status={property.value} disabled={disabled} />;
  }
  const label = getPropertyLabel(property);

  if (label === null) {
    return null;
  }

  return (
    <Chip
      variant="outlined"
      sx={{
        bgcolor: 'background.default',
      }}
      icon={<TaskActionPropertyIcon property={property} />}
      {...{ label, disabled }}
    />
  );
};
