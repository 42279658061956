import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { styled, TextField } from '@mui/material';

import { ClearButton } from './QuestionSearch';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '.MuiInputBase-input': {
    transition: 'width 0.3s ease-in-out',
    fontSize: theme.typography.body2.fontSize,
    '::placeholder': {
      fontSize: theme.typography.body2.fontSize,
    },
  },

  '.MuiInputBase-root': {
    paddingLeft: theme.spacing(1),
    paddingRight: 0,
  },
}));

interface ItemSearchProps {
  searchText: string;
  setSearchText: (text: string) => void;
  label?: string;
  endAdornment?: React.ReactNode;
}

export const ItemsSearch: React.FC<ItemSearchProps> = ({ searchText, setSearchText, label, endAdornment }) => {
  return (
    <StyledTextField
      placeholder={label}
      aria-label={label}
      value={searchText}
      onChange={e => setSearchText(e.target.value)}
      onClick={e => {
        e.currentTarget.querySelector('input')?.focus();
      }}
      fullWidth
      size="small"
      InputProps={{
        startAdornment: <SearchIcon sx={{ mr: 1 }} color="primary" />,
        endAdornment: (
          <>
            <ClearButton size="small" visible={Boolean(searchText)} onClick={() => setSearchText('')}>
              <ClearIcon />
            </ClearButton>
            {endAdornment}
          </>
        ),
      }}
    />
  );
};
