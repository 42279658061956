import { USER_ROLE } from '@circadian-risk/shared';
import { useMemo } from 'react';

import { useOrganizationSessionStore } from './organizationSessionStore';

export const useOrgRoleQueryPrecedence = (...rolesByPrecedence: USER_ROLE[]) => {
  const [roles, userRole] = useOrganizationSessionStore(state => [state.roles, state.role]);

  const firstMatch = rolesByPrecedence.find(role => roles.includes(role));
  const queryRole = firstMatch ?? userRole;

  if (rolesByPrecedence.length === 0) {
    throw new Error('You must provide at least one precedence role');
  }

  const memoized = useMemo(() => {
    return {
      headers: {
        'x-hasura-role': queryRole,
      },
    };
  }, [queryRole]);

  return memoized;
};

export const useStandardOrgRoleQueryPrecedence = () =>
  useOrgRoleQueryPrecedence(USER_ROLE.ORGANIZATION_ADMIN, USER_ROLE.LOCATION_MANAGER, USER_ROLE.MEMBER);
