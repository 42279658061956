// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference types="@welldone-software/why-did-you-render" />
import * as whyDidYouRender from '@welldone-software/why-did-you-render';
import * as React from 'react';

import { config } from './environments/environment';

if (config.WHY_DID_YOU_RENDER) {
  whyDidYouRender.default(React, {
    trackAllPureComponents: true,
  });
}
