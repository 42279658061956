import { VStack } from '@circadian-risk/presentational';
import { Box, InputAdornmentOwnProps, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import React, { useCallback } from 'react';
import { Controller, ControllerFieldState, ControllerRenderProps } from 'react-hook-form';

import { PropertyFieldProps } from '../types';

export type PropertyDayFieldProps = PropertyFieldProps & {
  /**
   * @default end
   */
  adornmentPosition?: InputAdornmentOwnProps['position'];
};

export const PropertyDayField: React.FC<PropertyDayFieldProps> = ({
  label,
  htmlName,
  control,
  rules,
  boxProps,
  errorMessage,
  disabled,
  adornmentPosition = 'end',
}) => {
  const renderDatePicker = useCallback(
    ({
      field: { onChange, value, onBlur },
      fieldState,
    }: {
      field: ControllerRenderProps;
      fieldState: ControllerFieldState;
    }) => {
      const changeProxy = (date?: dayjs.Dayjs | null) => {
        // Convert Dayjs date into date
        const newValue = date ? +date.format('DD') : null;

        // If the date is invalid do not propagate the change
        if (newValue && Number.isNaN(newValue)) {
          return;
        }

        onChange(newValue);
      };

      const fieldError = errorMessage ?? fieldState.error?.message;

      // Picking "January" which contains 31 days
      // This will help us rendering all possible days in the month
      const normalizedValue = value ? dayjs().year(2024).month(0).date(value) : null;
      return (
        <DatePicker<dayjs.Dayjs>
          label={label}
          format="DD"
          value={normalizedValue}
          onChange={date => changeProxy(date)}
          data-name={label}
          disabled={disabled}
          sx={{ width: '100%' }}
          slotProps={{
            field: { onBlur },
            textField: {
              error: Boolean(fieldError),
              helperText: fieldError,
            },
            inputAdornment: {
              position: adornmentPosition,
            },
          }}
          // Set january if the value is null (date is hardcoded but only month is extracted)
          defaultCalendarMonth={dayjs('2024-01-01')}
          // Disable all months except January
          // this will prevent keyboard navigation to other months
          shouldDisableMonth={date => date.month() > 0}
          slots={{
            calendarHeader: () => (
              <VStack pt={1}>
                <Typography variant="h6" align="center">
                  Select a day
                </Typography>
                <Typography variant="caption" color="secondary" px={2}>
                  In some cases, selecting 30/31 will default to the last day of the month
                </Typography>
              </VStack>
            ),
          }}
        />
      );
    },
    [label, disabled, errorMessage, adornmentPosition],
  );

  return (
    <Box {...boxProps}>
      <Controller control={control} name={htmlName} defaultValue={null} rules={rules} render={renderDatePicker} />
    </Box>
  );
};
