import { Coordinates } from '@circadian-risk/shared';

export type HandlePointClick = (evt: { id: string; coordinates: [number, number] }) => void;
export type HandlePointChange = (markerId: string, coordinates: Coordinates) => void;

export const DEFAULT_MAPBOX_ZOOM = 17;
/*
 * On the `naturalEarth` projection, a default zoom of 0 is a bit too zoomed out. 1 looked better for most cases.
 */
export const NATURAL_EARTH_DEFAULT_ZOOM = 1;

export type PopoverMode = 'move' | 'select';
