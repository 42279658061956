/**
 * Parses a PostgreSQL tsrange string and returns an array of two Date objects (or null if they don't exist).
 *
 * @param {string} tsrange - The tsrange string to parse, e.g., '[2022-01-01 10:00:00, 2022-01-02 10:00:00)'.
 * @returns {(Date | null)[]} An array containing the start and end dates. Each element can be a Date object or null.
 *
 * @throws {Error} Throws an error if the tsrange format is invalid.
 *
 * @example
 * // returns [new Date('2022-01-01T10:00:00Z'), new Date('2022-01-02T10:00:00Z')]
 * parseTsRange('[2022-01-01 10:00:00, 2022-01-02 10:00:00)');
 *
 * @example
 * // returns [new Date('2022-01-01T10:00:00Z'), null]
 * parseTsRange('[2022-01-01 10:00:00, )');
 */
export const parseTsRange = (tszrange: string): [Date | null, Date | null] => {
  // Ensure the string is trimmed and check for brackets
  const trimmedRange = tszrange.trim();
  if (
    (!trimmedRange.startsWith('[') && !trimmedRange.startsWith('(')) ||
    (!trimmedRange.endsWith(']') && !trimmedRange.endsWith(')'))
  ) {
    throw new Error('Invalid tsrange format');
  }

  // Remove the outer brackets and split the string by the comma
  const rangeBody = trimmedRange.slice(1, -1).split(',');

  // Function to parse individual date strings
  const parseDate = (dateStr: string): Date | null => {
    return dateStr === '' ? null : new Date(dateStr);
  };

  // Extract start and end dates
  const startDate: Date | null = parseDate(rangeBody[0].trim());
  const endDate: Date | null = parseDate(rangeBody[1].trim());

  return [startDate, endDate];
};
