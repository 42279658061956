import ClearIcon from '@mui/icons-material/Clear';

import { ResponsiveIconButton } from './ResponsiveIconButton';

interface ResetTableButtonProps {
  onClick: () => void;
}

export const ResetTableButton: React.FC<ResetTableButtonProps> = ({ onClick }) => {
  return (
    <ResponsiveIconButton
      iconPosition="start"
      icon={<ClearIcon />}
      text="Reset Table"
      onClick={onClick}
      color="secondary"
      tooltip={'Resets all filters and table states'}
      ariaLabel={'reset table button'}
    />
  );
};
