import { ROUTES } from '@circadian-risk/routes';
import { AssessmentState, USER_ROLE } from '@circadian-risk/shared';
import { useOrganizationId } from '@circadian-risk/stores';
import { Box } from '@mui/material';
import {
  DetailDescription,
  DetailDescriptionContent,
  DetailDescriptionLabel,
  DetailDescriptionList,
  PropertyDescriptionDate,
  PropertyDescriptionText,
} from '@web-app/components/Details/DetailListing';
import NavLinkWithActiveOrgId from '@web-app/components/NavLinkWithActiveOrgId';
import { DetailsCard, DetailsEditMenuItem } from '@web-app/layouts/DetailsCard';
import { useHasRole } from '@web-app/lib/useHasRole';
import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import { AssessmentProgress } from './AssessmentProgress';
import { useAssessmentDetailsData } from './hooks/useAssessmentDetailsData';

export interface SingleAssessmentDetailsCardProps {
  assessmentId: number;
}

export const SingleAssessmentDetailsCard: React.FC<SingleAssessmentDetailsCardProps> = ({ assessmentId }) => {
  const {
    description,
    assessmentTemplate,
    state,
    start_date: startDate,
    due_date: endDate,
  } = useAssessmentDetailsData(assessmentId);
  const history = useHistory();
  const organizationId = useOrganizationId();
  const hasLocationManagerRole = useHasRole(USER_ROLE.LOCATION_MANAGER);

  const assessmentTemplateId = assessmentTemplate?.id;
  const assessmentTemplateName = assessmentTemplate?.name;

  const assessmentTemplateLink =
    assessmentTemplateId && assessmentTemplateName
      ? generatePath(ROUTES.SCENARIO_ASSESSMENT_TEMPLATES_DETAIL, {
          organizationId,
          id: assessmentTemplateId,
        })
      : null;

  const handleEditClick =
    hasLocationManagerRole && state !== AssessmentState.Complete
      ? () => {
          history.push(generatePath(ROUTES.ASSESSMENTS_EDIT, { id: assessmentId, organizationId }));
        }
      : undefined;

  const progressComponent = <AssessmentProgress assessmentId={assessmentId} />;

  return (
    <DetailsCard
      title="Assessment Details"
      menuItems={
        handleEditClick && (
          <div>
            <DetailsEditMenuItem onClick={handleEditClick}>Edit</DetailsEditMenuItem>
          </div>
        )
      }
    >
      <DetailDescriptionList>
        {state !== AssessmentState.NotStarted ? (
          <Box display="flex" flexWrap="wrap">
            <Box id="dates" display="flex" mb={1}>
              <DetailDescription pr={3}>
                <DetailDescriptionLabel>Start Date</DetailDescriptionLabel>
                <DetailDescriptionContent>
                  <PropertyDescriptionDate value={startDate} />
                </DetailDescriptionContent>
              </DetailDescription>

              <DetailDescription pr={3}>
                <DetailDescriptionLabel>End Date</DetailDescriptionLabel>
                <DetailDescriptionContent>
                  <PropertyDescriptionDate value={endDate} />
                </DetailDescriptionContent>
              </DetailDescription>
            </Box>
            {progressComponent}
          </Box>
        ) : (
          progressComponent
        )}

        <DetailDescription>
          <DetailDescriptionLabel>Description</DetailDescriptionLabel>
          <DetailDescriptionContent>
            <PropertyDescriptionText value={description} />
          </DetailDescriptionContent>
        </DetailDescription>

        {assessmentTemplateLink && (
          <DetailDescription>
            <DetailDescriptionLabel>Assessment Template</DetailDescriptionLabel>
            <NavLinkWithActiveOrgId to={assessmentTemplateLink}>{assessmentTemplateName}</NavLinkWithActiveOrgId>
          </DetailDescription>
        )}
      </DetailDescriptionList>
    </DetailsCard>
  );
};
