import { percentageWithPadding } from '@circadian-risk/shared';
import { Box, LinearProgress, LinearProgressProps, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import React from 'react';

export const LinearProgressThick = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 12,
      borderRadius: 20,
      border: `1px solid ${theme.palette.divider}`,
    },
    colorPrimary: {
      backgroundColor: theme.palette.primary.background,
    },
    bar: {
      borderRadius: 20,
    },
  }),
)(LinearProgress);

export const LinearProgressWithLabel = (props: LinearProgressProps & { value: number; label?: JSX.Element }) => {
  return (
    <Box display="flex" alignItems="center" width="100%">
      <Box width="100%" mr={1}>
        <LinearProgressThick variant="determinate" {...props} value={percentageWithPadding(props.value, 7)} />
      </Box>
      {props.label ?? (
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
        </Box>
      )}
    </Box>
  );
};
