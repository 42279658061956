import noop from 'lodash/noop';
import { createContext, useContext } from 'react';

interface FileDownloadContextProps {
  downloadOrgFile: (fileId: string, organizationId: string, fileName: string) => Promise<void>;
}

const fileDownloadContext = createContext<FileDownloadContextProps>({
  downloadOrgFile: async () => noop(),
});

export const useFileDownloadContext = () => useContext(fileDownloadContext);

export const { Provider: FileDownloadContextProvider } = fileDownloadContext;
