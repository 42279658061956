import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Button } from '@mui/material';
import React, { useRef } from 'react';

import { HStack, HStackProps } from '../..';
import { FilterOption, FilterOptionsAggregatesRef } from '.';
import { AddFilterButton, FilterDropdown, FilterOperatorOption, SelectedFilterChip } from './components';
import { CHIP_HEIGHT } from './components/constants';
import { clearOptionSelection, createDefaultChipState, createFilterOperator } from './filter.helpers';
import { useDropdownState } from './hooks/useDropdownState';

type GroupedSelectedOption = Pick<FilterOption, 'icon' | 'label' | 'value'> & {
  state: React.ReactNode;
  operator: React.ReactNode;
};

export const filterGroupAriaLabels = {
  SearchInput: 'search input',
  AddFilterIcon: 'add new filter icon',
  FilterOperator: 'filter operator',
  FilterDropdown: 'filter dropdown',
  ClearFilters: 'clear filters',
  AddFilterButton: 'add new filter button',
};

export interface FilterGroupProps {
  /**
   * @default "Add Filter"
   */
  buttonText?: string;
  /**
   * Returns all the new options once some filter changes
   * @param queryState
   * @returns
   */
  onSave: (newOptions: FilterOption[]) => void;
  options: FilterOption[];
  aggregates: FilterOptionsAggregatesRef;
  noWrapper?: boolean;
}
const ConditionalHStack: React.FC<HStackProps & { noWrapper?: boolean }> = ({ children, noWrapper, ...hstackProps }) =>
  // eslint-disable-next-line react/jsx-no-useless-fragment
  noWrapper ? <React.Fragment>{children}</React.Fragment> : <HStack {...hstackProps}>{children}</HStack>;

export const FilterGroup: React.FC<FilterGroupProps> = ({
  buttonText = 'Add Filter',
  options,
  onSave,
  aggregates,
  noWrapper,
}) => {
  const addFilterBtnRef = useRef<HTMLButtonElement | null>(null);
  const plusIconWrapperRef = useRef<HTMLSpanElement | null>(null);
  const { defaultSelectedOption, anchorRef, openDropdown, closeDropdown } = useDropdownState();

  const onChipDeleteClick = (parentValue: string) => {
    onSave(clearOptionSelection({ options, parentValue }));
  };

  const onAddFilterBtnClick = () => {
    openDropdown(plusIconWrapperRef.current);
  };

  const selectedOptions = options.filter(o => o.selected);
  const filtersApplied = selectedOptions.length > 0;

  const groupedSelectedOptions = selectedOptions.map<GroupedSelectedOption>(o => {
    const selectedChildren = o.children.filter(c => c.selected);
    const chipStateFactory = o.createChipState ?? createDefaultChipState;

    return {
      icon: o.icon,
      label: o.label,
      value: o.value,
      operator: (
        <FilterOperatorOption
          // Fallback in case operator is not stored (e.g: User might manipulate the URL query state)
          value={o.operator ?? createFilterOperator(selectedChildren.length)}
          possibleValues={[selectedChildren.length === 1 ? 'is' : 'isAnyOf', 'noneOf']}
          onOperatorChange={newOperator => {
            const newOptions = [...options];
            const target = newOptions.find(opt => opt.value === o.value);
            if (target) {
              target.operator = newOperator;
            }

            onSave(newOptions);
          }}
        />
      ),
      state: chipStateFactory({
        selectedChildrenCount: selectedChildren.length,
        singleSelectionOptionLabel: selectedChildren[0]?.label ?? '',
        filterOptionName: o.value,
      }),
    };
  });

  const allSelected = options.length === selectedOptions.length;

  return (
    <ConditionalHStack noFullWidth flexWrap="wrap" noWrapper={noWrapper}>
      <Button
        size="small"
        ref={addFilterBtnRef}
        startIcon={!filtersApplied && <FilterListIcon />}
        endIcon={filtersApplied && <CloseIcon />}
        variant="outlined"
        color="primary"
        sx={{ borderStyle: 'dashed', height: CHIP_HEIGHT }}
        aria-label={filtersApplied ? filterGroupAriaLabels.ClearFilters : filterGroupAriaLabels.AddFilterButton}
        onClick={() => {
          if (!filtersApplied) {
            openDropdown(addFilterBtnRef.current);
          } else {
            onSave(
              options.map(o => ({
                ...o,
                selected: false,
                operator: undefined,
                children: o.children.map(c => ({ ...c, selected: false })),
              })),
            );
          }
        }}
      >
        {filtersApplied ? 'Clear Filters' : buttonText}
      </Button>
      {filtersApplied && (
        <>
          {groupedSelectedOptions.map(so => (
            <SelectedFilterChip
              key={so.value}
              {...so}
              onChipDeleteClick={onChipDeleteClick}
              openDropdown={openDropdown}
              close={closeDropdown}
            />
          ))}
          <AddFilterButton ref={plusIconWrapperRef} onClick={onAddFilterBtnClick} disabled={allSelected} />
        </>
      )}
      {anchorRef && (
        <FilterDropdown
          anchorEl={anchorRef}
          onClose={closeDropdown}
          options={options}
          onSave={onSave}
          defaultSelectedOption={defaultSelectedOption}
          aggregates={aggregates}
        />
      )}
    </ConditionalHStack>
  );
};
