import { generateCircadianForm } from '@circadian-risk/form';

import { scenarioAssessmentFormSchema } from './ScenarioAssessmentForm/ScenarioAssessmentForm.types';

export const singleAssessmentForm = generateCircadianForm(scenarioAssessmentFormSchema, {
  assessmentTemplateId: 'Assessment Template',
  description: 'Description',
  form: 'Create an Assessment',
  importActiveAnswers: 'Import Answers',
  name: 'Name',
  dueDate: 'Due Date',
  startDate: 'Start Date',
  ratificationRequired: 'Ratify Options',
  rootLocationId: 'Location',
  locationIds: 'Selected Locations',
  assignment: 'Assignment Management',
  submit: 'Submit',
  notifications: 'Notifications',
});
