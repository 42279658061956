import { initialQuestionValues } from '@circadian-risk/assessment-components';
import { FileUploadModal } from '@circadian-risk/form';
import { useStructuralMemo } from '@circadian-risk/front-end-utils';
import { HStack, ItemIcon, useDialog, VStack } from '@circadian-risk/presentational';
import { Box, Typography } from '@mui/material';
import { QuestionsFormData } from '@web-app/components/QuestionField';
import { QuestionField } from '@web-app/components/QuestionField/QuestionField';
import { useVirtualOFC } from '@web-app/modules/Items/hooks';
import { OptionsUpdateDtoByQuestionId } from '@web-app/modules/Items/hooks/types';
import React, { MutableRefObject, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { v4 } from 'uuid';

import { AssessQuestion } from '../BulkReassess';
import { SmartLocationLink } from '../SmartLocationLink';
import { getNameIdentifierForItemQuestion } from './OneByOneReassessModal.types';

interface OneByOneReassessStepProps {
  itemId: string;
  itemName: string;
  itemImgSrc?: string | null;
  itemCategoryId: string;
  nodeId: string;
  questions: AssessQuestion[];
  optionsToUpdateRef: MutableRefObject<OptionsUpdateDtoByQuestionId>;
}

export const OneByOneReassessStep: React.FC<OneByOneReassessStepProps> = ({
  itemId,
  itemName,
  itemCategoryId,
  itemImgSrc,
  nodeId,
  questions,
  optionsToUpdateRef,
}) => {
  const { isOpen: isFileModalOpen, openDialog: openFileModal, closeDialog: closeFileModal } = useDialog();
  const [uploadingFilesForId, setUploadingFilesForId] = useState<string | undefined>();

  const { control, setValue, getValues } = useFormContext<QuestionsFormData>();

  const { modalNode, createOfcActions, getQuestionOptions, getFinalOptionsByQuestionId } = useVirtualOFC({
    locationId: nodeId,
    itemCategoryId,
  });

  const finalOptionsByQuestion = useStructuralMemo(getFinalOptionsByQuestionId());

  // Keep the options to update ref in sync as we need to signal the parent
  // when there are options to sync
  useEffect(() => {
    optionsToUpdateRef.current = {
      ...optionsToUpdateRef.current,
      ...finalOptionsByQuestion,
    };
  }, [finalOptionsByQuestion, optionsToUpdateRef]);

  const uploadFile = async (file: File) => {
    if (!uploadingFilesForId) {
      return;
    }
    const question = getValues()[uploadingFilesForId] ?? initialQuestionValues;
    if (!question) {
      return;
    }

    setValue(uploadingFilesForId, {
      ...question,
      newFiles: [...(question.newFiles ?? []), { id: v4(), file }],
    });
  };

  return (
    <>
      <VStack spacing={2}>
        <Box display={'flex'}>
          <SmartLocationLink nodeId={nodeId} target="_blank" />
        </Box>
        <HStack>
          <ItemIcon iconSrc={itemImgSrc} alt={itemName} />
          <Typography variant={'h5'}>{itemName}</Typography>
        </HStack>
        {questions.map(q => {
          const uniqueId = getNameIdentifierForItemQuestion(itemId, q.questionId);
          const options = getQuestionOptions(q.options, q.questionId);
          const actions = createOfcActions(q.questionId, q.options);
          return (
            <QuestionField
              key={uniqueId}
              control={control}
              openFileModal={openFileModal}
              setUploadingFilesForId={setUploadingFilesForId}
              uniqueId={uniqueId}
              // TODO(iprokopovich)[CR-4178]
              question={{
                ...q,
                badResponse: q.deficientResponse,
                questionText: q.questionText,
                optionsSectionProps: {
                  options,
                  actions,
                },
              }}
            />
          );
        })}
      </VStack>
      <FileUploadModal
        open={isFileModalOpen}
        onClose={closeFileModal}
        onUploadFile={file => uploadFile(file)}
        uploadDropzoneProps={{
          inputProps: {
            multiple: true,
          },
        }}
      />
      {modalNode}
    </>
  );
};
