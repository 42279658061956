import { FormPaper, FormStack, FormTitle, generateCircadianForm } from '@circadian-risk/form';
import { Bold } from '@circadian-risk/presentational';
import { Typography } from '@mui/material';

import {
  assessmentsCountOptions,
  employeeCountOptions,
  FREE_FORM_OTHER,
  industryOptions,
  locationCountOptions,
} from './selectOptions';
import {
  OrgCreationFormData,
  OrgCreationFormParsedData,
  orgCreationFormParsedDataSchema,
  orgCreationFormSchema,
} from './types';

const { ariaLabels, useCircadianForm, Text, LargeRadio, Select, SubmitButton, CircadianForm } = generateCircadianForm(
  orgCreationFormSchema,
  {
    form: 'Create an Organization',
    employeeCount: 'Employee Count',
    hierarchyType: 'Hierarchy Type',
    industry: 'Industry',
    freeFormIndustry: 'Custom Industry',
    locationsCount: 'Locations Count',
    numberOfAssessmentsPerYear: 'Assessments Count',
    orgName: 'Organization Name',
    submit: 'Create',
  },
);

export const orgCreationFormAriaLabels = ariaLabels;

export interface OrganizationCreationFormProps {
  onSubmit: (data: OrgCreationFormParsedData) => Promise<void>;
  disabled?: boolean;
}

export const OrganizationCreationForm: React.FC<OrganizationCreationFormProps> = ({ onSubmit, disabled = false }) => {
  const formMethods = useCircadianForm({
    defaultValues: {
      orgName: '',
      hierarchyType: 'basic',
      industry: '',
      freeFormIndustry: '',
      employeeCount: undefined,
      locationsCount: undefined,
      numberOfAssessmentsPerYear: undefined,
    },
  });

  const handleSubmit = async (formData: OrgCreationFormData) => {
    const parsed = orgCreationFormParsedDataSchema.parse(formData);
    if (parsed.industry === FREE_FORM_OTHER) {
      parsed.industry = parsed.freeFormIndustry ?? FREE_FORM_OTHER;
    }
    await onSubmit(parsed);
  };

  const watchIndustry = formMethods.watch('industry');

  return (
    <CircadianForm formMethods={formMethods} onSubmit={handleSubmit} disabled={disabled}>
      <FormPaper showBg>
        <FormStack>
          <FormTitle>{ariaLabels.form}</FormTitle>

          <Typography>
            Nice work setting up your user account! Before you dive into assessments, let's set up your organization and
            a location or two...
          </Typography>

          <Text formKey="orgName" />

          <LargeRadio
            formKey="hierarchyType"
            options={[
              {
                label: 'Basic',
                value: 'basic',
                description: (
                  <Typography variant="caption">Includes Organization, Campus, Building and Level</Typography>
                ),
              },
              {
                label: 'Basic + Region',
                value: 'plusRegion',
                description: (
                  <Typography variant="caption">
                    Includes Organization, <Bold>Region</Bold>, Campus, Building and Level
                  </Typography>
                ),
              },
            ]}
          />

          <Select formKey="industry" options={industryOptions.map(o => ({ value: o, label: o }))} />
          {watchIndustry === FREE_FORM_OTHER && <Text formKey="freeFormIndustry" />}

          <FormStack row>
            <Select formKey="employeeCount" options={employeeCountOptions} />
            <Select formKey="locationsCount" options={locationCountOptions} />
          </FormStack>

          <Select
            formKey="numberOfAssessmentsPerYear"
            helperText={'Anticipated number of assessments conducted per year'}
            options={assessmentsCountOptions}
          />

          <SubmitButton />

          <Typography variant="body2" color={theme => theme.palette.text.secondary}>
            If you are not trying to create a new organization, reach out to organization admin and ask to be added as a
            user.
          </Typography>
        </FormStack>
      </FormPaper>
    </CircadianForm>
  );
};
