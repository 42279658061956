import { CancelTokenSource } from 'axios';

export enum FileAttachmentStatus {
  Uploading = 'Uploading',
  Available = 'Available',
  UploadingFailed = 'Failed Upload',
}

export type FileAttachmentLink = {
  url?: string;
  text: string;
};

export interface FileAttachment {
  id: string;
  filename: string;
  status: FileAttachmentStatus;
  url?: string;
  uploadProgress?: number;
  uploadDate?: Date;
  uploadUser?: string;
  relatedEntityLink?: FileAttachmentLink;
  onDelete?(id: string): void;
  onUploadRetry?(id: string): void;
}

export type FileAttachmentFile = FileAttachment & { file: File; cancelTokenSource?: CancelTokenSource };
