import { ApolloError } from '@apollo/client';
import { isAxiosError } from '@circadian-risk/front-end-utils';
import * as Sentry from '@sentry/react';
import isString from 'lodash/isString';

import { useUserSessionStore } from './userSessionStore';

const isApolloError = (error: unknown): error is ApolloError => {
  return (error as ApolloError)?.name === 'ApolloError';
};

export const reportErrorToSentry = (error: unknown) => {
  // Taken from the pattern that we use already in the main app
  const { id: userId, email, tokenDefaultOrgId } = useUserSessionStore.getState();
  const organizationId = tokenDefaultOrgId;

  const sentryEvent: Sentry.Event = {
    message: `Snackbar Error: ${isString(error) ? error : JSON.stringify(error)}`,
    level: 'error',
    extra: {
      error,
    },
    user: { id: userId, email },
  };

  if ([400, 401, 403, 404].includes((error as { statusCode: number }).statusCode)) {
    sentryEvent.level = 'info';
  }

  // Override the metadata if it's an Axios error as we're able to safely extract more information
  if (isAxiosError(error)) {
    const responseStatus = error.response?.status;
    const url = error.config.url;
    sentryEvent.message = `Failed HTTP request to "${url}" with status code ${responseStatus}`;
    sentryEvent.extra = {
      response: {
        data: error.response?.data,
        status: error.response?.status,
      },
      request: {
        headers: error.config.headers,
        method: error.config.method,
        url,
      },
    };
  }

  if (isApolloError(error)) {
    sentryEvent.message = `GraphQL Error: ${error.message}`;
    sentryEvent.extra = {
      graphQLErrors: JSON.stringify(error.graphQLErrors),
      message: error.message,
      protocolErrors: JSON.stringify(error.protocolErrors),
      clientErrors: JSON.stringify(error.clientErrors),
      networkError: JSON.stringify(error.networkError),
    };
  }

  if (organizationId) {
    sentryEvent.extra = {
      ...sentryEvent.extra,
      organizationId,
    };
  }

  // these are not official JavaScript Error objects, so send as event instead
  Sentry.captureEvent(sentryEvent);
};
