import { Box } from '@mui/material';
import React from 'react';
import { z } from 'zod';

import { generateCircadianForm } from '../../FormBlocks';

interface FileListItemRenameModalProps {
  name: string;
  onRename: (newName: string) => void;
  open: boolean;
  onClose: () => void;
}

const formSchema = z.object({
  filename: z.string().min(1, { message: 'Name is required' }),
});

const { useCircadianForm, Text, GenericFormDialog } = generateCircadianForm(formSchema, {
  form: 'file item rename form',
  submit: 'Save',
  filename: 'File Name',
});
export const FileListItemRenameModal: React.FC<FileListItemRenameModalProps> = ({ name, onRename, open, onClose }) => {
  let fileExtension: string | null = null;
  let basename: string;
  const split = name.split('.');

  if (split.length === 1) {
    fileExtension = null;
    basename = name;
  } else {
    fileExtension = `.${split.pop() as string}`;
    basename = split.join('.');
  }

  const formMethods = useCircadianForm({
    values: { filename: basename },
  });

  return (
    <GenericFormDialog
      isOpen={open}
      onClose={onClose}
      formProps={{
        formMethods,
        onSubmit: ({ filename: name }) => {
          const newName = fileExtension ? `${name}${fileExtension}` : name;
          onRename(newName);
        },
      }}
      title="Change File Name"
    >
      <Box width="300px">
        <Text formKey="filename" />
      </Box>
    </GenericFormDialog>
  );
};
