import { LocationTagIcon } from '@circadian-risk/presentational';
import { useOrganizationSessionStore } from '@circadian-risk/stores';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import uniqBy from 'lodash/uniqBy';
import { Controller, useFormContext } from 'react-hook-form';

import { createLocationFilterForm } from '../create-location-filter.form';
import { CreateLocationFilterSchema } from '../create-location-filter.schema';

const { ariaLabels } = createLocationFilterForm;

export const TagsFormField: React.FC = () => {
  const { control, watch } = useFormContext<CreateLocationFilterSchema>();
  const baseLayerId = watch('baseLayerId');
  const tags = useOrganizationSessionStore(state => {
    const aggregatedTags = state.nodes.flatMap(n => n.tags.filter(t => t.type_name !== 'all'));
    const uniqueTags = uniqBy(aggregatedTags, t => t.id);
    return uniqueTags;
  });
  const relevantTags = tags.filter(tag => tag.layer_id === baseLayerId);

  return (
    <Box>
      <Controller
        control={control}
        name="tagIds"
        render={({ field: { value, onChange } }) => {
          const selectedValue = tags.filter(tag => value.includes(tag.id));
          return (
            <Autocomplete
              value={selectedValue}
              onChange={(_, v) => onChange(v.map(e => e.id))}
              multiple
              options={relevantTags}
              disabled={relevantTags.length === 0}
              disableCloseOnSelect
              getOptionLabel={option => option.type_name}
              limitTags={4}
              ChipProps={{
                icon: <LocationTagIcon />,
              }}
              renderInput={params => <TextField {...params} label={ariaLabels.tagIds} />}
            />
          );
        }}
      />
      {relevantTags.length === 0 && (
        <Typography variant="body2" color="textSecondary">
          Locations with current filter criteria have no tags associated with them
        </Typography>
      )}
    </Box>
  );
};
