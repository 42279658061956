import { USER_ROLE } from '@circadian-risk/shared';
import { useOrganizationSessionStore } from '@circadian-risk/stores';

/**
 * Hook that returns true or false based on whether a user has a given role in their possible roles
 *
 * @param role
 */
export const useHasRole = (role: USER_ROLE) => {
  const roles = useOrganizationSessionStore(state => state.roles);
  return roles.includes(role);
};
