import { SUPPORT_EMAIL } from '@circadian-risk/data-utils';
import { Link } from '@mui/material';

interface SupportEmailLinkProps {
  /**
   * Subject line of the email
   */
  subject: string;
  /**
   * Optional email body
   */
  body?: string;
  /**
   * Optional override, defaults to SUPPORT_EMAIL constant
   */
  email?: string;
  /**
   * Optional text override, defaults to email
   */
  text?: string;
}

export const SupportEmailLink: React.FC<SupportEmailLinkProps> = ({ subject, email = SUPPORT_EMAIL, text, body }) => {
  const textOverride = text ?? email;

  return (
    <Link href={`mailto:${email}?subject=${subject}${body ? `&body=${encodeURIComponent(body)}` : ''}`}>
      {textOverride}
    </Link>
  );
};
