import { Box, List, ListItem, Typography } from '@mui/material';
import React from 'react';

interface ScenarioListItemProps {
  id: number;
  name: string;
  scenario_type: string;
  description?: string | null;
}

const ScenarioListItem: React.FC<ScenarioListItemProps> = props => {
  const { name, scenario_type, description } = props;
  return (
    <ListItem>
      <Box>
        <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
          <strong>{name}</strong> [{scenario_type}]
        </Typography>
        <Typography variant="body2">{description}</Typography>
      </Box>
    </ListItem>
  );
};

export const ScenarioListing: React.FC<{ scenarios: ScenarioListItemProps[] }> = props => {
  const { scenarios } = props;
  return (
    <Box m={1}>
      <Typography variant="body1">
        {scenarios.length === 0 ? 'There are no scenarios for this organization.' : 'Scenarios for this Organization:'}
      </Typography>
      <List dense>
        {scenarios.map(sc => (
          <ScenarioListItem key={sc.id} {...sc} />
        ))}
      </List>
    </Box>
  );
};
