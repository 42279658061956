import { HStack, LocationWithIcon, VStack } from '@circadian-risk/presentational';
import AddIcon from '@mui/icons-material/Add';
import { Button, Typography } from '@mui/material';
import pluralize from 'pluralize';
import React from 'react';

import { ReactComponent as CitySvg } from './CityVector.svg';

export interface NoLocationsMessageProps {
  parentName: string;
  parentLayer: string;
  layer: string;
  onAddNewClick: () => void;
}

export const NoLocationsMessage: React.FC<NoLocationsMessageProps> = ({
  layer,
  parentLayer,
  parentName,
  onAddNewClick,
}) => {
  return (
    <VStack
      justifyContent="center"
      alignItems="center"
      minHeight="inherit"
      height="inherit"
      maxHeight="inherit"
      width="100%"
      spacing={2}
      padding={4}
    >
      <CitySvg width="30%" height="30%" opacity={0.8} />
      <HStack component="span" flexWrap="wrap" justifyContent="center">
        <LocationWithIcon layerName={parentLayer} locationName={parentName} />

        <Typography component="span" noWrap color={theme => theme.palette.text.secondary}>
          currently has no {pluralize(layer)}.
        </Typography>
      </HStack>
      <Button startIcon={<AddIcon />} variant="contained" onClick={onAddNewClick}>
        Add a New {layer}
      </Button>
    </VStack>
  );
};
