import { ApolloProvider } from '@apollo/client';
import { sessionBasedClientFactory } from '@circadian-risk/apollo-utils';
import { useSuspendedUserSessionHydration } from '@circadian-risk/authentication';
import { FCC } from '@circadian-risk/front-end-utils';
import { ROUTES } from '@circadian-risk/routes';
import { useUserSessionStore } from '@circadian-risk/stores';
import React, { useMemo } from 'react';
import { Redirect } from 'react-router-dom';

import { HASURA_URL } from '../lib/constants';

export const AppAuthRouteGuard: FCC = ({ children }) => {
  const isPendingEmailVerification = useUserSessionStore(state => state.isPendingEmailVerification);

  const { hasSession } = useSuspendedUserSessionHydration();

  const apolloClient = useMemo(() => {
    return sessionBasedClientFactory({
      uri: HASURA_URL,
      hasuraRole: 'app-user',
    });
  }, []);

  if (!hasSession) {
    return <Redirect to={ROUTES.SIGN_IN} />;
  }

  if (isPendingEmailVerification) {
    return <Redirect to={ROUTES.SIGN_UP} />;
  }

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
