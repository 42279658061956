import { ApolloProvider } from '@apollo/client';
import { sessionBasedClientFactory } from '@circadian-risk/apollo-utils';
import { USER_ROLE } from '@circadian-risk/shared';
import { useUserSessionStore } from '@circadian-risk/stores';
import { HASURA_URL } from '@web-app/lib/constants';
import React, { useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { routeConfig } from './RouteConfig';

const adminRoutes = routeConfig.filter(x => x.path.startsWith('/admin'));

export const AdminSection: React.FC = () => {
  const isCircadianAdmin = useUserSessionStore(state => state.isCircadianAdmin);

  const client = useMemo(() => {
    if (!isCircadianAdmin) {
      return null;
    }

    return sessionBasedClientFactory({
      uri: HASURA_URL,
      hasuraRole: USER_ROLE.CIRCADIAN_ADMIN,
    });
  }, [isCircadianAdmin]);

  if (!client) {
    // If the user got this far we've already verified that they're actually authenticated
    // so send them back to the app entry point
    return <Redirect to={'/'} />;
  }

  return (
    <ApolloProvider client={client}>
      <Switch>
        {adminRoutes.map(route => {
          const routeProps = {
            component: route.component,
            exact: route.isExact,
            path: route.path,
            isPublic: route.isPublic,
            allowRoles: route.allowRoles,
          };

          return <Route key={route.path} {...routeProps} />;
        })}
      </Switch>
    </ApolloProvider>
  );
};
