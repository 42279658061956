import { CircularProgress, InfoBox } from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { ScenarioTypeChip } from '@circadian-risk/scenario-components';
import { useOrganizationId } from '@circadian-risk/stores';
import InfoIcon from '@mui/icons-material/Info';
import RemoveIcon from '@mui/icons-material/RemoveCircle';
import {
  alpha,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { TitleSelectInput } from '@web-app/components/TitleSelectInput';
import React, { useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import { CriticalityNumbers } from './CriticalityNumbers';
import { ScenarioStatInput, ScenarioStats } from './types';
import { useScenarioStats } from './useScenarioStats';

export interface AssessmentCompliancePercentCardProps {
  overallStats: Pick<ScenarioStats, 'id' | 'percentage' | 'irrelevantQuestionsCount' | 'criticalityNumbers'>;
  scenarioStatInput: ScenarioStatInput[];
}

export const TOTAL_STATS_ID = -1;
const BASE_OVERALL_STATS = {
  id: TOTAL_STATS_ID,
  name: 'total',
  hasTemplateChanged: undefined,
  complianceThreshold: undefined,
};

export const AssessmentCompliancePercentCard: React.FC<AssessmentCompliancePercentCardProps> = ({
  overallStats,
  scenarioStatInput,
}) => {
  const organizationId = useOrganizationId();
  const theme = useTheme();
  const history = useHistory();
  const { risk: riskColors } = theme.palette.organizationColors;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const scenarios = useScenarioStats(organizationId, scenarioStatInput);

  const onlyOneScenario = scenarioStatInput.length === 1;

  const [selectedScenario, setSelectedScenario] = useState(
    onlyOneScenario ? scenarioStatInput[0].scenario_id : TOTAL_STATS_ID,
  );

  const overallStatsValue = {
    ...overallStats,
    ...BASE_OVERALL_STATS,
  };

  const statsArray = onlyOneScenario ? scenarios : [overallStatsValue, ...scenarios];
  const selectedScenarioStats = statsArray.find(s => s.id === selectedScenario);

  // If no scenarios, show loader
  if (!selectedScenarioStats) {
    return <CircularProgress />;
  }

  // eslint-disable-next-line max-len
  const percentageTooltip = `This Inspection Questionnaire includes ${selectedScenarioStats.irrelevantQuestionsCount} answers that were answered as Not Applicable or Not Observed. Questions with these responses are not included within this percentage.`;

  // if we want this to work with dark mode, we must use theme, and not import from constants in theme.ts
  let complianceColor = theme.palette.text.primary;
  if (selectedScenarioStats?.complianceThreshold) {
    const { complianceThreshold, percentage } = selectedScenarioStats;
    complianceColor =
      percentage < complianceThreshold.low
        ? riskColors.high
        : percentage > complianceThreshold.high
        ? riskColors.low
        : riskColors.medium;
  }

  const title = onlyOneScenario ? (
    <Typography variant="h5">{scenarios[0].name}</Typography>
  ) : (
    <TitleSelectInput
      value={selectedScenario}
      onChange={e => {
        setSelectedScenario(e.target.value as number);
      }}
    >
      <MenuItem value={TOTAL_STATS_ID}>Total Score</MenuItem>
      {scenarios.map(scenario => (
        <MenuItem key={`compliance-menu-item-${scenario.id}`} value={scenario.id}>
          {scenario.name}
        </MenuItem>
      ))}
    </TitleSelectInput>
  );

  const handleChipClick = ({ id }: { id: number; name: string }) => {
    if (!onlyOneScenario) {
      setSelectedScenario(id);
    } else {
      history.push(generatePath(ROUTES.SCENARIOS_DETAIL, { organizationId, id }));
    }
  };

  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        <Box
          id="main-content"
          aria-label={'assessment-compliance-content'}
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          flexWrap="wrap"
          justifyContent="center"
          alignItems="stretch"
        >
          <Box id="control-percentage" display="flex" flexDirection="column" flexGrow={0}>
            <Typography variant="h6" align={isMobile ? 'center' : 'left'} paragraph>
              Control Percentage
            </Typography>
            <Box
              id="compliance-score"
              display="flex"
              justifyContent="center"
              boxSizing="border-box"
              borderRadius="5px"
              border={`3px solid ${complianceColor}`}
              bgcolor={alpha(complianceColor, 0.05)}
              p={2}
            >
              <Typography variant="h1" align="center">
                <Box color={complianceColor} component="span">
                  {selectedScenarioStats.percentage}%
                </Box>
              </Typography>

              {selectedScenarioStats.irrelevantQuestionsCount > 0 ? (
                <Tooltip title={percentageTooltip}>
                  <InfoIcon htmlColor={complianceColor} />
                </Tooltip>
              ) : null}
            </Box>
          </Box>
          <Box
            id="criticality-breakdown"
            flexGrow={1}
            mt={isMobile ? 4 : undefined}
            ml={isMobile ? undefined : 4}
            mb={2}
          >
            <Typography align={isMobile ? 'center' : 'left'} variant="h6">
              Criticality Breakdown
            </Typography>
            <CriticalityNumbers
              criticalityNumbers={selectedScenarioStats.criticalityNumbers}
              variant={isMobile ? 'mobile' : 'default'}
              disableView
            />
          </Box>

          {selectedScenarioStats?.hasTemplateChanged && (
            <Box width="100%" mt={2}>
              <InfoBox>The template for this scenario has been updated since this assessment was closed</InfoBox>
            </Box>
          )}
        </Box>

        <Divider
          sx={theme => ({ marginLeft: theme.spacing(-2), marginRight: theme.spacing(-2), marginTop: theme.spacing(2) })}
        />
        <Box id="scenarios" mt={2}>
          <Typography>Questions in this assessment apply to the following scenarios:</Typography>
          <Box mt={1}>
            {scenarios.map(({ id, name, type, description }) => {
              const isScenarioSelected = selectedScenario === id;
              const canDeselect = !onlyOneScenario && isScenarioSelected;
              return (
                <ScenarioTypeChip
                  key={`${name}-${type}`}
                  name={name}
                  type={type}
                  description={description}
                  sx={theme => ({
                    margin: theme.spacing(0.5),
                  })}
                  color={isScenarioSelected ? 'primary' : 'default'}
                  onDelete={canDeselect ? () => setSelectedScenario(TOTAL_STATS_ID) : undefined}
                  deleteIcon={<RemoveIcon />}
                  onClick={() => handleChipClick({ id, name })}
                  size="medium"
                />
              );
            })}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
