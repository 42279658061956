import { HStack, OptionsLibraryIcon, VStack } from '@circadian-risk/presentational';
import { Add } from '@mui/icons-material';
import TextsmsIcon from '@mui/icons-material/Textsms';
import { Button, Typography, useTheme } from '@mui/material';
import sortBy from 'lodash/sortBy';
import React from 'react';

import { CustomOption } from '../CustomBlockInput';
import { Option, OptionProps } from './Option';

type Options = OptionProps['option'][];

export type OptionsSectionActions = {
  onRemove: (option: OptionProps['option']) => void;
  onOptionChange: (updatedOption: CustomOption) => void;
  onSaveToLib?: (option: CustomOption) => void;
  onNewCustom: () => void;
  onAddFromLibrary: () => void;
};

export type OptionsSectionProps = { options: Options; actions?: OptionsSectionActions };

export const OptionsSection: React.FC<OptionsSectionProps> = ({ options, actions }) => {
  const theme = useTheme();
  const readOnly = !actions;
  const showAddButton = !readOnly;
  const Icon = TextsmsIcon;

  return (
    <VStack>
      <HStack justifyContent="space-between" flexWrap="wrap">
        <HStack noFullWidth>
          <Icon htmlColor={theme.palette.action.active} />
          <Typography noWrap>Options for Consideration</Typography>
        </HStack>
        {actions?.onAddFromLibrary && (
          <Button variant="text" startIcon={<OptionsLibraryIcon />} onClick={actions.onAddFromLibrary}>
            Add from Library
          </Button>
        )}
      </HStack>
      <VStack noFullWidth borderLeft={`1px solid ${theme.palette.divider}`} pl={1} mx={0.25}>
        {sortBy(options, o => o.createdAt).map(option => (
          <Option key={option.id} option={option} actions={actions ? actions : undefined} />
        ))}
        {showAddButton && actions?.onNewCustom && (
          <Button onClick={actions.onNewCustom} startIcon={<Add />} size="small">
            Add another option
          </Button>
        )}
      </VStack>
    </VStack>
  );
};
