import { searchTreeChildren } from '@circadian-risk/shared';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton } from '@mui/material';
import React, { useContext } from 'react';

import { LocationTreeNode, TreeViewContext } from './LocationTree';

export const LocationTreeNodeIcon: React.FC<LocationTreeNode> = node => {
  const { id } = node;
  const context = useContext(TreeViewContext);

  if (!searchTreeChildren(node, n => n.selectable)) {
    return null;
  }

  const thisExpanded = context.expanded.includes(id);
  return (
    <IconButton size="small" onClick={() => context.onToggle(id)}>
      <ExpandMoreIcon
        sx={theme => ({
          transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shorter,
          }),
          transform: thisExpanded ? 'rotate(0deg)' : 'rotate(-90deg)',
        })}
      />
    </IconButton>
  );
};
