import { Coordinates } from '@circadian-risk/shared';
import React, { memo, useMemo } from 'react';
import { Marker } from 'react-map-gl';

import { DefaultStackedItemsRenderer } from './DefaultStackedItemsRenderer';
import { RealWorldMarkerItem } from './interfaces';
import { createStackedMarkers } from './utils/createStackedMarkers';

export interface RealWorldMarkerProps {
  items: RealWorldMarkerItem[];
  stackEnabled?: boolean;
  stackedItemsRenderer?: (items: RealWorldMarkerItem[], coordinates?: Coordinates) => React.ReactNode;
  searchFilter?: (predicate: RealWorldMarkerItem) => boolean;
}

export const RealWorldMarkerContainer: React.FC<RealWorldMarkerProps> = memo(
  ({ items, stackEnabled, stackedItemsRenderer, searchFilter }) => {
    const rows = useMemo(() => (searchFilter ? items.filter(searchFilter) : items), [items, searchFilter]);
    const stackedMarkers = useMemo(() => {
      if (stackEnabled) {
        return createStackedMarkers(rows);
      }
      return [];
    }, [stackEnabled, rows]);

    const memoizedMarkers = useMemo(() => {
      const markers = rows.map(({ coordinates, content: markerContent, id }) => {
        const [longitude, latitude] = coordinates;
        const stackedTarget = stackedMarkers.find(
          e => e.coordinates[0] === coordinates[0] && e.coordinates[1] === coordinates[1],
        );
        const isStacked = Boolean(stackedTarget);
        const stackedRenderer = () => {
          if (!stackedTarget) {
            return;
          }
          if (stackedItemsRenderer) {
            return stackedItemsRenderer(stackedTarget.items);
          }
          return <DefaultStackedItemsRenderer items={stackedTarget.items} />;
        };

        const content = !isStacked ? markerContent : stackedRenderer();
        return (
          // It appears that when using custom icons we get more accurate results when we set the anchor to 'top-left'.
          // If we ever change the rotation of the markers, we may also need to change the anchor
          // This sample was helpful see this working in a very simple setup:
          // https://github.com/visgl/react-map-gl/blob/master/examples/controls/@web-app/app.tsx
          <Marker key={id} longitude={longitude} latitude={latitude} anchor={'top-left'}>
            {content}
          </Marker>
        );
      });

      return markers;
    }, [rows, stackedMarkers, stackedItemsRenderer]);

    return <div id={`marker-realworld-${rows.length}`}>{memoizedMarkers}</div>;
  },
);
