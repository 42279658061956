import { CriticalityIcon } from '@circadian-risk/presentational';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';

import { CriticalityNumber } from './types';

const MAX_CRITICALITY = 3;

export const CriticalityNumberAndIcon: React.FC<CriticalityNumber & { color: string; disableView?: boolean }> = ({
  num,
  color,
  criticality,
  onClick,
  disableView,
}) => (
  <Box display="flex" flexDirection="column" alignItems="center">
    <Typography variant="h3">
      <Box color={color}>{num}</Box>
    </Typography>
    <Box mb={1}>
      <CriticalityIcon color={color} criticality={criticality} maxCriticality={MAX_CRITICALITY} />
    </Box>
    {!disableView && (
      <Button endIcon={<ChevronRight />} onClick={onClick} disabled={!onClick}>
        View
      </Button>
    )}
  </Box>
);
