import {
  GetAssessmentNodesForTreeViewQuery,
  useGetAssessmentNodesForTreeViewQuery,
} from '@circadian-risk/client-graphql-hooks';
import { useTreeData } from '@circadian-risk/front-end-utils';
import { useGqlQueryHandler } from '@circadian-risk/graphql-utils';
import { AssessmentState } from '@circadian-risk/shared';
import { useOrganizationSessionStore } from '@circadian-risk/stores';
import { Box } from '@mui/material';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { assessmentAppUrlForWebApp } from '@web-app/lib/assessmentAppUrl';
import React, { useMemo } from 'react';

import { LocationTreeNode, TreeViewContext } from './LocationTree';
import { renderTree } from './LocationTreeItem';

interface AssessmentLocationTreeContentsProps {
  data: GetAssessmentNodesForTreeViewQuery;
  organizationId: string;
  assessmentId: number;
}

const AssessmentLocationTreeContents: React.FC<AssessmentLocationTreeContentsProps> = props => {
  const { data, organizationId, assessmentId } = props;
  const state = data?.v2_assessments_by_pk?.state;
  const nodes = data?.v2_assessments_by_pk?.locations ?? [];
  const orgNodes = useOrganizationSessionStore(state => state.nodes);

  // Form the tree
  const allNodes: LocationTreeNode[] = orgNodes.map(n => {
    const selectable = nodes.find(an => an.node.id === n.id);
    const disabledChips = state === AssessmentState.NotStarted;

    return {
      id: n.id,
      parent_id: n.parent_id,
      name: n.name,
      inspectionQuestionnaireProgress: selectable?.progressAggregates?.inspection_progress,
      physicalInspectionProgress: selectable?.progressAggregates?.physical_progress,
      inspectionEnabled: Boolean(selectable?.relevantItemCategories.find(type => type.item_type === 'inspection')),
      interviewEnabled: Boolean(selectable?.relevantItemCategories.find(type => type.item_type === 'interview')),
      selectable: Boolean(selectable),
      disabledChips,
      depth: n.nodeDepth!,
      questionnaireUrl: assessmentAppUrlForWebApp({
        organizationId,
        assessmentId,
        location: {
          id: n.id,
          view: {
            type: 'questionnaire',
          },
        },
      }),
      inspectionUrl: assessmentAppUrlForWebApp({
        organizationId,
        assessmentId,
        location: {
          id: n.id,
          view: {
            type: 'inspection',
          },
        },
      }),
      ordinal: n.ordinal,
    };
  });

  const rootNode = allNodes.find(n => n.parent_id === null)!;

  const { onToggleExpandedId, expandedIds, tree } = useTreeData(allNodes, rootNode, leaves => leaves.map(l => l.id));

  const value = useMemo(
    () => ({ onToggle: onToggleExpandedId, expanded: expandedIds }),
    [onToggleExpandedId, expandedIds],
  );

  return (
    <Box>
      <Box>
        <TreeViewContext.Provider value={value}>
          <TreeView expanded={expandedIds}>{tree && renderTree(tree)}</TreeView>
        </TreeViewContext.Provider>
      </Box>
    </Box>
  );
};

interface AssessmentLocationTreeProps {
  organizationId: string;
  assessmentId: number;
}

export const AssessmentLocationTree: React.FC<AssessmentLocationTreeProps> = ({ organizationId, assessmentId }) => {
  const { content } = useGqlQueryHandler(
    useGetAssessmentNodesForTreeViewQuery,
    {
      variables: {
        organizationId,
        assessmentId,
      },
    },
    props => {
      return (
        <AssessmentLocationTreeContents data={props.data} assessmentId={assessmentId} organizationId={organizationId} />
      );
    },
  );

  return content;
};
