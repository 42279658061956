type NameIdentifier = string;

// TODO(backlog)[CR-4179]
const delimiter = 'zzz';

// Generate a identifier to use for uniquely identifying a question that needs to be answered for an item
export const getNameIdentifierForItemQuestion = (itemId: string, questionId: string): NameIdentifier => {
  return `${itemId}${delimiter}${questionId}`;
};

// Returns the itemId and questionId given a NameIdentifier
export const getItemIdQuestionIdFromNameIdentifier = (
  nameIdentifier: NameIdentifier,
): {
  itemId: string;
  questionId: string;
} => {
  const split = nameIdentifier.split(delimiter);

  return {
    itemId: split[0],
    questionId: split[1],
  };
};
