import { Box, FormControlLabel, FormControlLabelProps, FormHelperText, Switch, SwitchProps } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

import { PropertyFieldProps } from '../types';

export interface PropertyToggleFieldProps extends PropertyFieldProps {
  helperText?: string;
  switchProps?: Omit<SwitchProps, 'checked' | 'onChange' | 'disabled'>;
  onChangeCallback?: (value: boolean) => void;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
}
export const PropertyToggleField: React.FC<PropertyToggleFieldProps> = ({
  label,
  htmlName,
  control,
  disabled,
  rules,
  boxProps,
  helperText,
  endAdornment,
  switchProps,
  onChangeCallback,
  labelPlacement,
}) => {
  return (
    <Box {...boxProps}>
      <Controller
        control={control}
        name={htmlName}
        defaultValue={null}
        rules={rules}
        render={fieldProps => {
          const { value, onChange } = fieldProps.field;

          const changeProxy = () => {
            const newValue = !value;
            onChange(newValue);
            if (onChangeCallback) {
              onChangeCallback(newValue);
            }
          };

          return (
            <Box display="flex" flexDirection="row" alignItems="center">
              <FormControlLabel
                control={
                  <Switch
                    checked={Boolean(value)}
                    onChange={changeProxy}
                    disabled={disabled}
                    {...switchProps}
                    color="primary"
                  />
                }
                label={label as FormControlLabelProps['label']}
                labelPlacement={labelPlacement}
                data-name={label}
                disabled={disabled}
              />
              {endAdornment}
            </Box>
          );
        }}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </Box>
  );
};
