import { UserAvatar } from '@circadian-risk/presentational';
import { DELETED_USER_MESSAGE, displayNameOrEmail, humanFormatDateTime } from '@circadian-risk/shared';
import { useOrganizationUsers } from '@circadian-risk/stores';
import { useMemo } from 'react';

export interface SmartAnsweredByAvatarProps {
  answeredBy: string | null | undefined;
  answeredAt: string | null | undefined;
}

export const SmartAnsweredByAvatar: React.FC<SmartAnsweredByAvatarProps> = ({ answeredBy, answeredAt }) => {
  const users = useOrganizationUsers();

  const answeredByUser = useMemo(() => {
    if (!answeredBy) {
      return null;
    }
    const user = users.find(u => u.id === answeredBy);
    // In case the user is deleted then we display a message
    if (!user) {
      return {
        name: DELETED_USER_MESSAGE,
        avatar: undefined,
      };
    }
    return {
      name: displayNameOrEmail([user.first_name, user.last_name], user.email),
      avatar: user.avatar,
      lastSignedInAt: user.last_signed_in_at,
    };
  }, [answeredBy, users]);

  if (!answeredByUser || !answeredAt) {
    return null;
  }

  return (
    <UserAvatar
      name={answeredByUser.name}
      withTooltip
      customTooltip={`Answered at: ${humanFormatDateTime(answeredAt)} by: ${answeredByUser.name}`}
      lastSignedInAt={answeredByUser.lastSignedInAt}
      image={answeredByUser.avatar}
      size={24}
    />
  );
};
