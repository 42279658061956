import {
  Enum_Notification_Sequence_Type_Enum,
  Enum_Notification_Trigger_Enum,
  Enum_Notification_Type_Enum,
  Enum_Schedule_Notification_Time_Unit_Enum,
} from '@circadian-risk/graphql-types';
import { z } from 'zod';

const notificationTimeUnitSchema = z.custom<Enum_Schedule_Notification_Time_Unit_Enum>();
const notificationTypeSchema = z.custom<Enum_Notification_Type_Enum>();
const whenSchema = z.custom<Enum_Notification_Sequence_Type_Enum>();
const triggerSchema = z.custom<Enum_Notification_Trigger_Enum>();

export type NotificationType = z.infer<typeof notificationTypeSchema>;
export type WhenType = z.infer<typeof whenSchema>;
export type TriggerType = z.infer<typeof triggerSchema>;
export const MAX_NOTIFICATIONS = 5;

const createNotificationSchema = (type: NotificationType) => {
  return z.object({
    type: z.literal(type),
    value: z.number().int().positive(),
    unit: notificationTimeUnitSchema,
    when: whenSchema,
    trigger: triggerSchema,
  });
};

const notificationSchema = z.discriminatedUnion('type', [
  createNotificationSchema('email'),
  createNotificationSchema('push'),
]);

const assignmentTypeEnum = z.enum(['assignee', 'subscriber']);

export const createAssessmentBaseSchema = z.object({
  name: z.string().min(1, { message: 'Name is required' }),
  description: z.string().nullish(),
  notifications: z.array(notificationSchema).max(MAX_NOTIFICATIONS),
  assignment: z.object({
    user: z.array(
      z.object({
        id: z.string().uuid(),
        type: assignmentTypeEnum,
      }),
    ),
    userGroup: z.array(
      z.object({
        id: z.number(),
        type: assignmentTypeEnum,
      }),
    ),
    teams: z.array(
      z.object({
        id: z.number(),
        type: assignmentTypeEnum,
      }),
    ),
  }),
});

export type CreateAssessmentBaseSchema = z.output<typeof createAssessmentBaseSchema>;
export type AssessmentNotification = z.output<typeof notificationSchema>;
export type AssignmentType = z.output<typeof assignmentTypeEnum>;
export type AssessmentAssignment = CreateAssessmentBaseSchema['assignment'];
export type AssessmentAssignmentTeamRow = AssessmentAssignment['teams'][number];
export type AssessmentAssignmentGroupRow = AssessmentAssignment['userGroup'][number];
export type AssessmentAssignmentUserRow = AssessmentAssignment['user'][number];
export type NotificationTimeUnitType = z.output<typeof notificationTimeUnitSchema>;
