import { useEffect } from 'react';
import type { RecipeEvent } from 'supertokens-web-js/lib/build/recipe/session/types';

import { supertokensHandleEvent$ } from '../supertokens-event-bus';

/**
 * Convenient hook to subscribe to the `supertokensHandleEvent$` event bus.
 *
 * @see https://supertokens.com/docs/session/advanced-customizations/frontend-hooks/handle-event
 * @param cb
 */
export const useSupertokensHandleEvent = (cb: (ctx: RecipeEvent) => void) => {
  useEffect(() => {
    const sub = supertokensHandleEvent$.subscribe(e => {
      cb(e);
    });
    return () => {
      sub.unsubscribe();
    };
  }, [cb]);
};
