import { Box, BoxProps, styled } from '@mui/material';
import React, { useContext } from 'react';

import { FullHeightBox } from './FullHeightBox';
import { LayoutContext, LayoutStateProvider } from './LayoutContext';
import { LayoutSideDrawer } from './LayoutSideDrawer';

export type BoxComponent = (props: BoxProps) => React.ReactElement | null;

type LayoutType = BoxComponent & {
  Drawer: typeof LayoutSideDrawer;
  Content: BoxComponent;
  Header: BoxComponent;
  Footer: BoxComponent;
};

const LayoutBase = styled(FullHeightBox)({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: 'auto 1fr auto', // left drawer, content, right drawer
  gridTemplateRows: 'auto 1fr auto', // appBar, content, footer
});

export const Layout: LayoutType = boxProps => {
  const layoutContext = useContext(LayoutContext);

  if (layoutContext !== null) {
    return <LayoutBase {...boxProps} />;
  }

  return (
    <LayoutStateProvider>
      <LayoutBase {...boxProps} />
    </LayoutStateProvider>
  );
};

Layout.Drawer = styled(LayoutSideDrawer)(({ side }) => {
  return {
    gridColumnStart: side === 'left' ? 1 : -1,
    overflowX: 'hidden',
  };
});

Layout.Content = styled(FullHeightBox)({
  gridColumn: '2 / -2', // spans all columns except first and last
}) as BoxComponent;

Layout.Header = styled(Box)({
  gridRowStart: 1, // first row
  gridColumn: '1 / -1', // spans all columns
}) as BoxComponent;

Layout.Footer = styled(Box)({
  gridRowStart: 3, // last row
  gridColumn: '1 / -1', // spans all columns
}) as BoxComponent;
