import React from 'react';

import { useSelectedLocationIds } from '../../../hooks/useSelectedLocationIds';
import { UsersStepContent } from './UsersStepContent';

/**
 * Smart component around UserStepContent that provides the applicable location ids
 * extracted from the fragment
 */
export const UsersStep: React.FC = () => {
  const applicableLocationIds = useSelectedLocationIds();

  return <UsersStepContent locationIds={applicableLocationIds} />;
};
