import { ROUTES } from '@circadian-risk/routes';
import { USER_ROLE } from '@circadian-risk/shared';
import { lazyLoadWithVersionCheck } from '@web-app/router/lazyLoadWithVersionCheck';

const FilesPage = lazyLoadWithVersionCheck(() => import('./FilesPage'));

export const filesRoutes = () => [
  {
    path: ROUTES.FILES,
    component: FilesPage,
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN],
  },
];
