import DoneIcon from '@mui/icons-material/Done';
import { Box, Typography, useTheme } from '@mui/material';
import { motion } from 'framer-motion';

import { HStack, VStack } from '../Layouts';
import { Bold } from '../Text';

const MHStack = motion(HStack);
const MBox = motion(Box);

interface CollapsibleStepProps {
  number: number;
  title: React.ReactNode;
  description?: React.ReactNode;
  status?: 'active' | 'checked' | 'idle';
  checkedIcon?: React.FC;
  required?: boolean;
  color?: 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success';
  onClick?: (stepNumber: number, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const CollapsibleStep: React.FC<CollapsibleStepProps> = ({
  number,
  title,
  description,
  required,
  status = 'idle',
  checkedIcon: Icon = DoneIcon,
  onClick,
  color = 'primary',
}) => {
  const { palette } = useTheme();
  const colorPalette = palette[color];

  return (
    <MHStack
      alignItems="flex-start"
      spacing={2}
      sx={{
        p: 2,
        borderWidth: 0,
        borderLeftWidth: 4,
        borderStyle: 'solid',
        flex: 1,
        '&:hover': {
          cursor: 'pointer',
        },
      }}
      role="button"
      onClick={
        onClick
          ? e => {
              onClick(number, e);
            }
          : undefined
      }
      initial="idle"
      animate={status}
      variants={{
        idle: {
          backgroundColor: 'transparent',
          borderColor: palette.background.default,
          borderWidth: 0,
        },
        active: {
          backgroundColor: colorPalette.background,
          borderColor: colorPalette.main,
        },
        checked: {
          backgroundColor: colorPalette.main,
          borderColor: colorPalette.main,
          color: colorPalette.contrastText,
          flex: 0,
        },
      }}
      transition={{
        type: 'spring',
        stiffness: 500,
        damping: 30,
      }}
    >
      <MBox
        component={motion.div}
        layout="position"
        sx={{
          minWidth: 24,
          minHeight: 24,
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        variants={{
          idle: {
            backgroundColor: colorPalette.background,
            color: colorPalette.main,
          },
          active: {
            backgroundColor: colorPalette.main,
            color: colorPalette.contrastText,
          },
          checked: {
            backgroundColor: colorPalette.contrastText,
            color: colorPalette.main,
          },
        }}
      >
        {status === 'checked' ? <Icon sx={{ p: 0.25 }} /> : number}
      </MBox>

      <Box
        component={motion.div}
        sx={{
          width: 'auto',
          overflow: 'hidden',
        }}
        variants={{
          checked: {
            width: 0,
            opacity: 0,
          },
        }}
      >
        <VStack sx={{ minWidth: 'max-content' }} spacing={0}>
          <Typography>{title}</Typography>

          {required && (
            <Typography component={'span'} variant="body2" color={color === 'primary' ? 'secondary' : color}>
              <Bold>Required</Bold>
            </Typography>
          )}

          <Typography variant="body2" color="text.secondary" mt={1}>
            {description}
          </Typography>
        </VStack>
      </Box>
    </MHStack>
  );
};
