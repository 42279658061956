import { HStack, LargeRadioButton } from '@circadian-risk/presentational';
import { Box, FormControl, FormHelperText, FormLabel, RadioGroup } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

import { PropertySelectFieldProps } from '../PropertyFields';

/**
 * Custom Component Cheat-sheet
 *
 * A custom form field should be able to display / handle the following props / states :
 * - disabled
 * - helperText
 * - errorText
 * - label
 */

export interface LargeRadioOption {
  value: string;
  label: string;
  description?: string | React.ReactNode;
}

export type LargeRadioFieldProps = PropertySelectFieldProps<LargeRadioOption>;

export const LargeRadioField: React.FC<LargeRadioFieldProps> = props => {
  const { htmlName, control, rules, boxProps, helperText, options, errorMessage } = props;
  const isError = errorMessage != null;

  return (
    <Box {...boxProps}>
      <Controller
        {...props}
        control={control as Control<Record<string, string>>}
        name={htmlName}
        rules={rules}
        render={({ field }) => {
          const value = field.value ?? null;
          return (
            <FormControl disabled={props.disabled} error={isError} fullWidth>
              <FormLabel>{props.label}</FormLabel>
              <RadioGroup onChange={field.onChange} value={value}>
                <HStack spacing={2} alignItems="stretch" flexWrap="wrap">
                  {options.map(option => (
                    <LargeRadioButton
                      disabled={props.disabled ?? false}
                      key={option.value}
                      checked={field.value === option.value}
                      onClick={() => field.onChange(option.value)}
                      error={isError}
                      {...option}
                    />
                  ))}
                </HStack>
              </RadioGroup>
              <FormHelperText>{errorMessage ?? helperText}</FormHelperText>
            </FormControl>
          );
        }}
      />
    </Box>
  );
};
