import { useHistory, useLocation } from 'react-router-dom';
import { useLatest, useMount } from 'react-use';

/**
 * Utility hook for managing string state on the url query parameters
 */
export const useQueryState = <T extends (string | null) | string>(
  key: string,
  defaultValue: string | null,
): [T, (value: T) => void] => {
  const history = useHistory();
  const searchStr = useLocation().search;
  const urlParams = new URLSearchParams(searchStr);
  const state = urlParams.get(key) ?? defaultValue;

  const latestSearchStr = useLatest(searchStr);
  const latestState = useLatest(state);

  const setState = (value: string | null) => {
    const newUrlParams = new URLSearchParams(searchStr);
    if (value === null) {
      newUrlParams.delete(key);
    } else {
      newUrlParams.set(key, value);
    }
    history.push({
      pathname: window.location.pathname,
      search: newUrlParams.toString(),
    });
  };

  useMount(() => {
    const initializeHistory = () => {
      const newUrlParams = new URLSearchParams(latestSearchStr.current);

      if (latestState.current == null) {
        newUrlParams.delete(key);
      } else {
        newUrlParams.set(key, latestState.current);
      }

      const stringified = newUrlParams.toString();

      if (stringified && `?${newUrlParams.toString()}` !== latestSearchStr.current) {
        window.history.replaceState(null, '', `${window.location.pathname}?${newUrlParams.toString()}`);
      }
    };

    initializeHistory();
  });

  return [state as T, setState];
};
