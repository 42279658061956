import { FCC } from '@circadian-risk/front-end-utils';
import { Box, SxProps } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

const MotionBox = motion(Box);

const AnimatedCard: FCC<{ showingFront?: boolean; sx?: SxProps; axis: 'x' | 'y' }> = ({
  sx,
  showingFront,
  axis,
  children,
}) => {
  const angle = showingFront ? 90 : -90;
  const rotate = axis === 'x' ? 'rotateX' : 'rotateY';

  return (
    <MotionBox
      sx={sx}
      initial={{
        [rotate]: angle,
      }}
      animate={{
        [rotate]: 0,
      }}
      exit={{
        [rotate]: angle,
      }}
      transition={{
        duration: 0.15,
      }}
    >
      {children}
    </MotionBox>
  );
};

export interface FlipCardNewProps {
  frontContent: React.ReactNode;
  backContent: React.ReactNode;

  /**
   * Which side to show
   */
  showSide: 'front' | 'back';

  /**
   * The axis to flip on
   * @default 'x'
   */
  axis?: 'x' | 'y';
}

export const FlipCard: React.FC<FlipCardNewProps & { sx?: SxProps; contentSx?: SxProps }> = ({
  showSide: side,
  axis = 'x',
  frontContent,
  backContent,
  sx,
  contentSx,
}) => {
  const isFront = side === 'front';
  return (
    <Box
      sx={{
        position: 'relative',
        perspective: 1000,
        width: 'fit-content',
        ...sx,
      }}
    >
      <AnimatePresence initial={false} mode="wait">
        <AnimatedCard showingFront={isFront} key={side} axis={axis} sx={contentSx}>
          {isFront ? frontContent : backContent}
        </AnimatedCard>
      </AnimatePresence>
    </Box>
  );
};
