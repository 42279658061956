import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import React, { InputHTMLAttributes, memo, useState } from 'react';

import { FileAttachmentStatus } from '../FileAttachments';
import { FileUploadHandler } from '../FileAttachmentsUploader';
import { FileUploadDropzone, FileUploadDropzoneProps, useFileUploadDropzone } from './FileUploadDropzone';
import { FileUploadModalListItem } from './FileUploadModalListItem';

export interface FileUploadModalProps {
  open: boolean;
  title?: string;
  onClose: () => void;
  onUploadFile: FileUploadHandler;
  id?: string;
  accept?: InputHTMLAttributes<HTMLInputElement>['accept'];
  uploadDropzoneProps?: Partial<Omit<FileUploadDropzoneProps, 'onFilesAdded'>>;
  hideListing?: boolean;
  disableRemove?: boolean;
}

/**
 * File upload modal used as a modal for dropping and then immediately uploading files
 */
export const FileUploadModal: React.FC<FileUploadModalProps> = memo(
  ({ uploadDropzoneProps, title, onUploadFile, open, onClose, id, accept, disableRemove, hideListing = false }) => {
    const [warningDialogOpen, setWarningDialogOpen] = useState(false);
    const [uploadingFiles, handleFilesAdded, clearFiles] = useFileUploadDropzone(onUploadFile, id);

    let isUploading = false;

    for (const file of uploadingFiles) {
      if (file.status === FileAttachmentStatus.Uploading) {
        isUploading = true;
        break;
      }
    }

    const handleCloseRequest = () => {
      if (!isUploading) {
        onClose();
      } else {
        setWarningDialogOpen(true);
      }
    };

    const confirmCloseRequest = () => {
      setWarningDialogOpen(false);
      onClose();
    };

    return (
      <Dialog
        open={open}
        onClose={handleCloseRequest}
        TransitionProps={{
          onExited: clearFiles,
        }}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            {title ?? 'Upload File'}
            <IconButton
              size="small"
              onClick={handleCloseRequest}
              sx={{ position: 'absolute', top: '8px', right: '8px' }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box width={400} maxWidth={'100%'} mt={1}>
            <FileUploadDropzone
              {...uploadDropzoneProps}
              onFilesAdded={handleFilesAdded}
              inputProps={uploadDropzoneProps?.inputProps ? { ...uploadDropzoneProps.inputProps, accept } : { accept }}
            />
          </Box>
          {!hideListing &&
            uploadingFiles.map(file => (
              <FileUploadModalListItem key={file.id} {...file} onRemove={disableRemove ? undefined : file.onRemove} />
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRequest} color="secondary" disabled={!isUploading}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleCloseRequest} color="primary" disabled={isUploading}>
            Finish
          </Button>
        </DialogActions>

        <Dialog open={warningDialogOpen} onClose={() => setWarningDialogOpen(false)}>
          <DialogContent>
            <Typography variant="body2" maxWidth={'426px'}>
              A file upload is still in progress. Do you wish to close the dialog and cancel the upload?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={confirmCloseRequest} size="small">
              Yes, close & cancel upload
            </Button>
            <Button variant="contained" onClick={() => setWarningDialogOpen(false)} color="secondary" size="small">
              No, Stay & Continue
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
    );
  },
);
