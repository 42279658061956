import { useIsSmallScreen } from '@circadian-risk/front-end-utils';
import { HEADER_HEIGHT } from '@circadian-risk/presentational';
import { Paper, PaperProps } from '@mui/material';
import debounce from 'lodash/debounce';
import isFunction from 'lodash/isFunction';
import React, { useCallback, useEffect, useState } from 'react';

interface ChildrenProps {
  minimized: boolean;
  compactSpacing: boolean;
  toggleCollapse: () => void;
}

type ChildrenFn = (props: ChildrenProps) => React.ReactNode;
export interface CollapsibleHeaderProps extends Omit<PaperProps, 'ref' | 'children'> {
  children?: React.ReactNode | ChildrenFn;
  /**
   * @default 0
   */
  scrollThreshold?: number;
}

/** @deprecated
 * consider using HeaderPageLayout component or reach out to Design to discuss a new layout   */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CollapsibleHeader = React.forwardRef<any, CollapsibleHeaderProps>(
  ({ children, scrollThreshold = 0, ...rest }, ref) => {
    const smallScreen = useIsSmallScreen();
    const [minimized, setMinimized] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const compactSpacing = smallScreen || minimized;

    useEffect(() => {
      const handleScroll = () => {
        const currentScroll = window.scrollY;
        if (currentScroll > scrollThreshold && !minimized) {
          setMinimized(true);
          debounce(() => {
            window.scrollTo({ top: window.scrollY + 3, behavior: 'smooth' });
            setScrolled(true);
          }, 300)();
        } else if (currentScroll === 0 && minimized && scrolled) {
          setScrolled(false);
          setMinimized(false);
        }
      };

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    });

    const toggleCollapse = useCallback(() => {
      setMinimized(prev => !prev);
    }, []);

    const getSxOverride = () => {
      if (!rest.sx) {
        return [];
      }

      return Array.isArray(rest.sx) ? rest.sx : [rest.sx];
    };

    return (
      <Paper
        elevation={8}
        ref={ref}
        {...rest}
        sx={[
          theme => ({
            padding: theme.spacing(2),
            paddingLeft: theme.spacing(7),
            backgroundColor: theme.palette.background.paper,
            position: 'sticky',
            top: HEADER_HEIGHT,
            zIndex: 2,
          }),
          ...getSxOverride(),
        ]}
      >
        {children && isFunction(children)
          ? (children as ChildrenFn)({ minimized, compactSpacing, toggleCollapse })
          : children}
      </Paper>
    );
  },
);
