import { AssessmentAssignment } from '@circadian-risk/api-contract';
import {
  AssessmentAssignmentTabbedTable,
  getUpdatedAssignment,
  GroupRow,
  mapOrgUserToUserRow,
  TeamRow,
  UserRow,
} from '@circadian-risk/assessment-components';
import { GenericDialog } from '@circadian-risk/presentational';
import { useOrganizationSessionStore, useOrganizationUserGroups, useOrganizationUsers } from '@circadian-risk/stores';
import { Button, DialogContentText } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';

export const EDIT_ASSESSMENT_ASSIGNMENT_DIALOG_ARIA_LABEL = 'edit assessment assignment dialog';

export interface EditAssessmentAssignmentDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (assignment: AssessmentAssignment) => Promise<void>;
  assignment: AssessmentAssignment;
  locationIds: string[];
}
export const EditAssessmentAssignmentDialog: React.FC<EditAssessmentAssignmentDialogProps> = ({
  open,
  onClose,
  onSubmit,
  assignment,
  locationIds,
}) => {
  const [changedAssignment, setChangedAssignment] = useState<AssessmentAssignment>(assignment);
  const [submitting, setSubmitting] = useState(false);

  // Sync if the props change
  useEffect(() => {
    setChangedAssignment(assignment);
  }, [assignment]);

  const handleSave = async () => {
    setSubmitting(true);
    await onSubmit(changedAssignment);
    setSubmitting(false);
    onClose();
  };

  const orgUserGroups = useOrganizationUserGroups();
  const getRelevantLocationTeams = useOrganizationSessionStore(state => state.getRelevantLocationTeams);

  const teams = useMemo(() => {
    const data = getRelevantLocationTeams(...locationIds);
    return data
      .map<TeamRow>(team => ({
        id: team.id.toString(),
        name: team.name,
        users: team.users.map(mapOrgUserToUserRow),
      }))
      .filter(t => t.users.length > 0);
  }, [locationIds, getRelevantLocationTeams]);

  const groups = useMemo(() => {
    return orgUserGroups
      .map<GroupRow>(group => ({
        id: group.id.toString(),
        name: group.name,
        users: group.users.map(mapOrgUserToUserRow),
      }))
      .filter(g => g.users.length > 0);
  }, [orgUserGroups]);

  const users = useOrganizationUsers().map<UserRow>(ou => ({
    id: ou.id,
    displayName: ou.displayName,
    email: ou.email,
    role: ou.role,
  }));

  return (
    <GenericDialog
      title="Edit Assigned & Subscribed users"
      isOpen={open}
      onClose={onClose}
      dialogProps={{ fullWidth: true, maxWidth: 'md', 'aria-label': EDIT_ASSESSMENT_ASSIGNMENT_DIALOG_ARIA_LABEL }}
      showCancel={false}
      actions={
        <>
          <Button onClick={onClose} color="error">
            Cancel
          </Button>
          <Button onClick={handleSave} disabled={submitting}>
            Save Users
          </Button>
        </>
      }
    >
      <>
        <DialogContentText>Select or deselect users for assigning or subscribing to this assessment.</DialogContentText>
        <AssessmentAssignmentTabbedTable
          assignment={changedAssignment}
          users={users}
          groups={groups}
          teams={teams}
          onSelectionChange={selectionChange => {
            const updatedSelection = getUpdatedAssignment(changedAssignment, selectionChange);
            setChangedAssignment(updatedSelection);
          }}
        />
      </>
    </GenericDialog>
  );
};
