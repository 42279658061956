import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  CellClassParams,
  GetQuickFilterTextParams,
  IRowNode,
  SuppressNavigableCallbackParams,
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { CellRendererParams, DataGridStyles, useControlledGridSelection, useGrid } from '@circadian-risk/data-grid';
import { combineSx, splitMpath } from '@circadian-risk/front-end-utils';
import {
  Apart,
  HighlightsProps,
  HStack,
  LocationTagChip,
  NodeIcon,
  Search,
  SpanWithHighlights,
  VStack,
} from '@circadian-risk/presentational';
import { getLocationTableValidLocationIds, nestify } from '@circadian-risk/shared';
import ClearIcon from '@mui/icons-material/Clear';
import {
  BoxProps,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import { alpha, Box } from '@mui/system';
import keyBy from 'lodash/keyBy';
import React, { useEffect, useMemo, useState } from 'react';

import { SelectedLocationsViewer } from './SelectedLocationsViewer';
import { LocationPickerOption, RowData } from './types';

export type LocationHierarchyPickerProps = {
  /**
   * Array of selected location ids, if multiple is false, array will only have one element
   */
  value: string[] | null;
  /**
   * The callback will always treat the selected options as an array even
   * if the value is "single"
   * @param newValue
   */
  onChange: (newValue: string[]) => void;
  options: LocationPickerOption[];
  disabled?: boolean;
  errorMessage?: string;
  helperText?: string;
  label: string;
  multiple?: boolean;
  allowedNodeIds: string[];
  /** @default 400  */
  height?: BoxProps['height'];
  sx?: BoxProps['sx'];

  /**
   * hides the preview of all selected location at the bottom
   *
   * @note user won't have ability to unselect locations if you hide this section
   * @default false */
  hideSelectedLocationsSection?: boolean;
};

const getRowSearchableString = (data: RowData) => {
  const { name, layerName, layerTags, selectable } = data;
  if (selectable) {
    return name + layerName + layerTags.join(' ');
  } else {
    return '';
  }
};

const agGridModules = [ClientSideRowModelModule, SetFilterModule, RowGroupingModule];

export const LocationHierarchyPicker: React.FC<LocationHierarchyPickerProps> = ({
  value,
  onChange,
  options,
  disabled,
  errorMessage,
  helperText,
  multiple,
  allowedNodeIds,
  label,
  height = 400,
  hideSelectedLocationsSection,
  sx,
}) => {
  const selectedIds = value ?? [];
  const hasError = Boolean(errorMessage);

  const theme = useTheme();
  const [searchText, setSearchText] = useState('');
  const { onGridReady, gridApi } = useGrid<RowData>();

  const onSelect = (selection: RowData[]) => onChange(selection.map(o => o.id));

  const { onFirstDataRendered, onSelectionChanged } = useControlledGridSelection(gridApi, selectedIds, onSelect);

  const getDataPath = (data: RowData) => splitMpath(data.mpath);

  useEffect(() => {
    if (gridApi) {
      gridApi.setQuickFilter(searchText);
      gridApi.expandAll();
    }
  }, [gridApi, searchText]);

  const { optionsById, rowData } = useMemo(() => {
    const optionsById = keyBy(options, o => o.id);

    const allNodes = options.map<RowData>(o => ({
      ...o,
      parent_id: o.parentId,
      selectable: allowedNodeIds.includes(o.id),
    }));

    const rootNode = allNodes.find(n => n.parent_id === null);
    const treeData = rootNode ? nestify(allNodes, rootNode) : undefined;
    const setOfValidLocationIds = treeData ? getLocationTableValidLocationIds(treeData) : new Set<string>();
    const rowData = allNodes.filter(e => setOfValidLocationIds.has(e.id));

    return {
      optionsById,
      rowData,
    };
  }, [options, allowedNodeIds]);

  // Showing only relevant nodes when selection changes
  useEffect(() => {
    if (!gridApi || multiple || !value || searchText) {
      return;
    }
    const node = gridApi.getRowNode(value[0]);
    if (node) {
      // Collapse other expanded nodes
      gridApi.forEachNode(otherNode => {
        otherNode !== node && otherNode.expanded && otherNode.setExpanded(false);
      });

      // Ensure the clicked node is expanded
      gridApi.setRowNodeExpanded(node, true, true);
    }
  }, [value, multiple, gridApi, searchText]);

  const selectedLocations = (selectedIds ?? []).map(id => optionsById[id]);

  return (
    <FormControl disabled={disabled} error={hasError} fullWidth sx={combineSx(sx, { height })}>
      <VStack sx={{ height: 'inherit' }}>
        {label && <FormLabel>{label}</FormLabel>}
        <Search
          size="small"
          placeholder="Search a location or tag to select..."
          sx={{ display: disabled ? 'none' : undefined }}
          disabled={disabled}
          text={searchText}
          fullWidth
          onTextChange={search => setSearchText(search)}
          onKeyDown={e => {
            if (e.key === 'Escape') {
              e.preventDefault();
              setSearchText('');
            }
          }}
        />

        {/* AgGrid TreeData */}
        <Paper
          variant="outlined"
          sx={{
            height: 'inherit',
            pb: '1px',
            borderWidth: '1px',
            overflow: 'hidden',
            '.ag-header': {
              display: 'none',
            },
          }}
        >
          <DataGridStyles
            // makes the hierarchy indent smaller
            agVariables={{
              '--ag-grid-size': '1px',
              '--ag-row-height': 'calc(var(--ag-grid-size) * 20)',
              '--ag-selected-row-background-color': alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity,
              ),
            }}
          >
            <AgGridReact<RowData>
              rowModelType={'clientSide'}
              treeData
              suppressBrowserResizeObserver
              modules={agGridModules}
              excludeChildrenWhenTreeDataFiltering
              rowSelection={disabled ? undefined : multiple ? 'multiple' : 'single'}
              isRowSelectable={({ data }: IRowNode<RowData>) => data?.selectable ?? false}
              onGridReady={onGridReady}
              rowData={rowData}
              disableStaticMarkup
              pagination={false}
              onFirstDataRendered={onFirstDataRendered}
              onSelectionChanged={onSelectionChanged}
              gridOptions={{ getDataPath }}
              animateRows
              suppressAutoSize={true}
              suppressRowClickSelection={multiple}
              getRowId={({ data }) => data.id}
              // set -1 to force all to expand, will need testing to see what is helpful
              groupDefaultExpanded={-1}
              autoGroupColumnDef={{
                wrapText: true,
                autoHeight: true,
                headerCheckboxSelection: multiple,
                hide: true,
                flex: 1,
                headerName: 'Locations',
                resizable: false,
                suppressMenu: true,
                filter: 'agSetColumnFilter',
                suppressNavigable: ({ data }: SuppressNavigableCallbackParams<RowData>) => !data?.selectable,
                valueGetter: ({ data }) => data?.name,
                getQuickFilterText: ({ data }: GetQuickFilterTextParams<RowData>) => getRowSearchableString(data),
                cellStyle: ({ data }: CellClassParams<RowData>) => ({
                  cursor: data?.selectable ? 'pointer' : 'default',
                }),
                cellRendererParams: {
                  checkbox: !disabled && multiple,
                  suppressCount: true,
                  disabled: true,
                  innerRenderer: ({ data, api }: CellRendererParams<RowData>) => {
                    if (!data) {
                      return null;
                    }
                    const { layerName, name, layerTags, selectable } = data;

                    const quickFilterText = api.getQuickFilter()?.toLocaleLowerCase();
                    const fullName = getRowSearchableString(data);

                    const isActiveSearch = !!quickFilterText;

                    const searchIndices = ((): HighlightsProps[] | undefined => {
                      if (!quickFilterText) {
                        return undefined;
                      }

                      const searchIndex = fullName.toLocaleLowerCase().indexOf(quickFilterText);
                      if (searchIndex === -1) {
                        return undefined;
                      }

                      return [{ indices: [[searchIndex, searchIndex + quickFilterText.length - 1]] }];
                    })();

                    const rowDisabled = !selectable || disabled;
                    const isRowMatch = isActiveSearch && !!searchIndices;
                    const isRowMiss = isActiveSearch && !isRowMatch;

                    return (
                      <HStack>
                        <NodeIcon color={rowDisabled ? 'disabled' : undefined} fontSize="small" layerName={layerName} />

                        <Typography
                          sx={{ py: 0.5 }}
                          id={data.id}
                          color={theme => {
                            const { text } = theme.palette;
                            if (rowDisabled) {
                              return text.disabled;
                            }
                            if (quickFilterText) {
                              return searchIndices ? text.primary : text.disabled;
                            }
                            return text.primary;
                          }}
                        >
                          <SpanWithHighlights highlights={searchIndices}>{name}</SpanWithHighlights>
                        </Typography>

                        <HStack spacing={0.5} noFullWidth flexWrap={'wrap'} py={0.5}>
                          {layerTags.map(tag => {
                            const disabledChip = disabled || rowDisabled || isRowMiss;
                            const isMatch =
                              isActiveSearch && !disabledChip && tag.toLocaleLowerCase().includes(quickFilterText);

                            return (
                              <LocationTagChip
                                key={tag}
                                name={tag}
                                chipProps={{
                                  disabled: disabledChip,
                                  color: isMatch ? 'primary' : undefined,
                                }}
                              />
                            );
                          })}
                        </HStack>

                        {/* This is just for testing and accessibility purposes  */}
                        {!multiple && (
                          <Box sx={{ width: 0, height: 0, overflow: 'hidden' }}>
                            <input
                              type={'radio'}
                              checked={selectedIds.includes(data.id)}
                              hidden={rowDisabled}
                              aria-disabled={rowDisabled}
                              aria-labelledby={data.id}
                              onChange={() => onChange([data.id])}
                            />
                          </Box>
                        )}
                      </HStack>
                    );
                  },
                },
              }}
            />
          </DataGridStyles>
        </Paper>

        {/* selected locations */}
        {selectedLocations.length > 0 && !hideSelectedLocationsSection && (
          <VStack>
            <FormLabel>Selected Locations:</FormLabel>
            <Apart alignItems={'center'} gap={'2px'}>
              <SelectedLocationsViewer locations={selectedLocations} />
              <IconButton disabled={disabled} onClick={() => gridApi?.deselectAll()}>
                <ClearIcon />
              </IconButton>
            </Apart>
          </VStack>
        )}

        {/* Helper text will turn into error color since we wrap everything with FormControl */}
        <FormHelperText>{errorMessage ?? helperText}</FormHelperText>
      </VStack>

      {/* This is just for testing and accessibility purposes  */}
      <input type="hidden" value={selectedIds.join(',')} disabled={disabled} aria-label={label} readOnly />
    </FormControl>
  );
};
