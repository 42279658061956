import { CreateAssessmentFormGroupHeader } from '@circadian-risk/assessment-components';
import { FormStack } from '@circadian-risk/form';
import { Bold, HStack } from '@circadian-risk/presentational';
import { Typography } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { singleAssessmentForm } from '../single-assessment-form';
import { ScenarioFormData } from './ScenarioAssessmentForm.types';

const { Text, Date } = singleAssessmentForm;
export const DetailsStep: React.FC = React.memo(() => {
  const { watch } = useFormContext<ScenarioFormData>();
  const dueDateWatch = watch('dueDate');
  const startDateWatch = watch('startDate');

  return (
    <FormStack>
      <Typography variant="h5">Details</Typography>

      <Text formKey={'name'} />
      <Text formKey={'description'} multiline rows={3} />

      <CreateAssessmentFormGroupHeader title="Assessment Schedule" />

      <HStack spacing={4}>
        <HStack>
          <Date
            formKey="startDate"
            adornmentPosition="start"
            maxDate={dueDateWatch}
            datePickerProps={{
              disablePast: true,
            }}
          />
          <Typography>
            <Bold>to</Bold>
          </Typography>
          <Date formKey="dueDate" adornmentPosition="start" minDate={startDateWatch} />
        </HStack>
      </HStack>
    </FormStack>
  );
});
