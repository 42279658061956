import { nestify, TreeNode } from '@circadian-risk/shared';
import uniq from 'lodash/uniq';
import { useCallback, useState } from 'react';

export const useTreeData = <T extends TreeNode>(
  leaves: T[],
  root: T,
  initiallyExpanded?: (leaves: T[]) => string[],
) => {
  const [expandedIds, setExpandedIds] = useState<string[]>(initiallyExpanded ? initiallyExpanded(leaves) : []);
  const [selectedId, setSelectedId] = useState<string>('');

  const onToggleExpandedId = useCallback(
    (id: string) => {
      const index = expandedIds.findIndex(expandedNodeId => expandedNodeId === id);
      const copy = [...expandedIds];
      if (index === -1) {
        copy.push(id);
      } else {
        copy.splice(index, 1);
      }
      setExpandedIds(copy);
    },
    [expandedIds, setExpandedIds],
  );

  const onSelectedId = useCallback(
    (id: string) => {
      setSelectedId(id);
    },
    [setSelectedId],
  );

  const addExpandedLeafIds = useCallback(
    (ids: string[]) => {
      setExpandedIds(prev => uniq([...prev, ...ids]));
    },
    [setExpandedIds],
  );

  const tree = nestify(leaves, root);

  return {
    expandedIds,
    setExpandedIds,
    onToggleExpandedId,
    selectedId,
    onSelectedId,
    addExpandedLeafIds,
    tree,
  };
};
