import { LocationHierarchyPicker } from '@circadian-risk/form';
import { HStack, VStack } from '@circadian-risk/presentational';
import { GlobalOrganizationContextNode, useOrganizationSessionStore } from '@circadian-risk/stores';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Box, Chip, Typography } from '@mui/material';
import noop from 'lodash/noop';
import uniqBy from 'lodash/uniqBy';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useSelectedLocationIds } from '../../../../hooks/useSelectedLocationIds';
import { findParentsRecursively } from '../../../../LocationFilter/ApplicableLocations/helpers';
import { CreateScheduledAssessmentFormSchema } from '../../../form';
import { useLocationFilters } from '../../RootStep/hooks/useLocationFilters';

export const LocationsContent: React.FC = () => {
  const layersById = useOrganizationSessionStore(state => state.layersById);
  const { watch } = useFormContext<CreateScheduledAssessmentFormSchema>();
  const filterId = watch('locationFilterId');
  const applicableLocationIds = useSelectedLocationIds();
  const selectedFilter = useLocationFilters().find(f => f.id === filterId);
  const locations = useOrganizationSessionStore(state => {
    // If a location filter is not selected, we'll need to find the parents of the selected locations
    const shouldIncludeParents = !filterId;
    const filteredLocations = state.nodes.filter(n => applicableLocationIds.includes(n.id));
    if (!shouldIncludeParents) {
      return filteredLocations;
    }
    const parents = filteredLocations.flatMap(l => {
      return findParentsRecursively<GlobalOrganizationContextNode>(state.nodes, l);
    });
    return uniqBy([...parents, ...filteredLocations], l => l.id);
  });

  return (
    <VStack>
      <HStack>
        <Typography variant="subtitle1">Locations</Typography>
        {selectedFilter && <Chip label={selectedFilter.name} icon={<FilterAltIcon />} />}
      </HStack>
      <Box height={'100%'} display="flex">
        <LocationHierarchyPicker
          allowedNodeIds={applicableLocationIds}
          options={locations.map(l => {
            return {
              id: l.id,
              layerTags: l.tags.map(t => t.id.toString()),
              mpath: l.mpath,
              ordinal: l.ordinal,
              parentId: l.parent_id,
              name: l.name,
              layerName: layersById[l.layer_id]?.name ?? '',
              layerId: l.layer_id,
            };
          })}
          label=""
          hideSelectedLocationsSection
          value={null}
          onChange={noop}
        />
      </Box>
    </VStack>
  );
};
