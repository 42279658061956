import { Box, BoxProps } from '@mui/material';
import React, { forwardRef } from 'react';

export interface VStackProps extends Omit<BoxProps, 'gap'> {
  spacing?: BoxProps['gap'];
  noFullWidth?: boolean;
  children: React.ReactNode;
}

export const VStack: React.FC<VStackProps> = forwardRef(({ children, noFullWidth, spacing = 1, ...boxProps }, ref) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width={noFullWidth ? undefined : '100%'}
      gap={spacing}
      ref={ref}
      {...boxProps}
    >
      {children}
    </Box>
  );
});
