import { ExpandLess, ExpandMore } from '@mui/icons-material';
import LoopIcon from '@mui/icons-material/Loop';
import { Button, Collapse, Typography } from '@mui/material';
import React, { useState } from 'react';

import { HStack, VStack, VStackProps } from '../..';

const SHOW_MAX = 2;

export interface InfiniteHistoryProps {
  children: React.ReactNode[];
  /**
   * How many history cards to show before adding "Show More" button.
   * @default 2
   */
  maxShow?: number;
  onFetchMore?: () => Promise<void>;
  boxProps?: VStackProps;
}

export const InfiniteHistory: React.FC<InfiniteHistoryProps> = ({
  maxShow = SHOW_MAX,
  onFetchMore,
  children,
  boxProps,
}) => {
  const [showMore, setShowMore] = useState(false);

  const displayChildren = children.slice(0, maxShow);
  const hiddenChildren = children.slice(maxShow, undefined);
  const hasMoreToShow = maxShow < children.length;

  const showFetchMore = showMore || !hasMoreToShow;

  const ShowHideIcon = showMore ? ExpandLess : ExpandMore;

  const handleCollapseToggle = () => setShowMore(prev => !prev);

  const handleFetchMore = async () => {
    if (onFetchMore) {
      await onFetchMore();
      setShowMore(true);
    }
  };

  if (!children || children.length === 0) {
    return (
      <Typography align="center" color="text.secondary">
        No records to display
      </Typography>
    );
  }

  return (
    <VStack {...boxProps}>
      {displayChildren}
      <Collapse in={showMore}>
        <VStack>{hiddenChildren}</VStack>
      </Collapse>

      <HStack justifyContent="center">
        {hasMoreToShow && (
          <Button startIcon={<ShowHideIcon />} onClick={handleCollapseToggle}>
            {showMore ? 'Show Less' : 'Show More'}
          </Button>
        )}

        {onFetchMore && showFetchMore && (
          <Button startIcon={<LoopIcon />} onClick={handleFetchMore}>
            Load More
          </Button>
        )}
      </HStack>
    </VStack>
  );
};
