import { Frequency, WeekdayType } from '@circadian-risk/api-contract';
import { sortWeekdays, weekNameByEnum } from '@circadian-risk/data-utils';
import { DateFormat, getWeekdayFromDate, humanFormatDateTime } from '@circadian-risk/shared';
import dayjs from 'dayjs';
import pluralize from 'pluralize';

const DEFAULT_MESSAGE = 'Custom frequency is not yet created';

const pluralizeIfTheresMoreThanOne = (word: string, value?: number) => {
  return value ? pluralize(word, value, value > 1) : word;
};

const isSubset = (subset: string[], set: string[]) => subset.every(value => set.includes(value));

export const frequencyFormatters = {
  hourly: (time: string, value?: number) => `Every ${pluralizeIfTheresMoreThanOne('hour', value)} at ${time}`,
  daily: (time: string, value?: number) => `Every ${pluralizeIfTheresMoreThanOne('day', value)} at ${time}`,
  monthly: (time: string, selectedDay: number, value?: number) => {
    return `Every ${pluralizeIfTheresMoreThanOne('month', value)} on ${selectedDay} at ${time}`;
  },
  yearly: (startDate: string | Date, value?: number) => {
    return `Every ${pluralizeIfTheresMoreThanOne('year', value)} on ${humanFormatDateTime(startDate, false)}`;
  },
  weekly: (weekday: string, time: string) => {
    return `Every ${weekday} at ${time}`;
  },
  weekday: (time: string) => {
    return `Every weekday at ${time}`;
  },
  customWeekly: (repeatOn: WeekdayType[], value: number) => {
    const pluralizedWeek = pluralizeIfTheresMoreThanOne('week', value);
    if (repeatOn.length === 7) {
      return `Every ${pluralizedWeek} every day`;
    } else if (isSubset(repeatOn, ['sun', 'sat']) && repeatOn.length === 2) {
      return `Every ${pluralizedWeek} every weekend`;
    } else if (isSubset(repeatOn, ['mon', 'tues', 'wed', 'thur', 'fri']) && repeatOn.length === 5) {
      return `Every ${pluralizedWeek} every weekday`;
    }

    // Sort the weekdays by their order in the week
    const weekendsList = sortWeekdays(repeatOn).map(e => weekNameByEnum[e]);

    // Remove the last element and add 'and' before it
    const last = weekendsList.pop()!;

    return `Every ${pluralizedWeek} on ${weekendsList.join(', ') + ' and ' + last}`;
  },
};

export const formatCustomFrequency = (frequency: Frequency, startDate: Date | string, startTime: string) => {
  if (frequency.type !== 'custom') {
    return '';
  }

  const { repeatUnit, value } = frequency.options;

  if (value <= 0) {
    return DEFAULT_MESSAGE;
  }

  switch (repeatUnit) {
    case 'hour':
      return frequencyFormatters.hourly(startTime, value);
    case 'day':
      return frequencyFormatters.daily(startTime, value);
    case 'month':
      return frequencyFormatters.monthly(startTime, value);
    case 'year':
      return frequencyFormatters.yearly(startDate, value);
    case 'week':
      return frequencyFormatters.customWeekly(frequency.options.repeatOn, frequency.options.value);
    default:
      return DEFAULT_MESSAGE;
  }
};

/**
 * Formats the frequency of the cadence to a human readable format
 * @param frequency
 * @param startsAt Expects the start date of the cadence with the time
 * @returns
 */
export const formatCadenceFrequency = (frequency: Frequency, startsAt: Date): string => {
  const start = dayjs(startsAt);
  const startTime = start.format(DateFormat.HOUR_AND_MINUTE_AM_PM);
  const startDate = start.toDate();

  if (frequency.type === 'custom') {
    return formatCustomFrequency(frequency, startDate, startTime);
  }

  const weekday = getWeekdayFromDate(startDate);
  const day = dayjs(startDate).date();

  switch (frequency.type) {
    case 'daily':
      return frequencyFormatters.daily(startTime);
    case 'monthly':
      return frequencyFormatters.monthly(startTime, day);
    case 'annually':
      return frequencyFormatters.yearly(startDate);
    case 'weekly':
      return frequencyFormatters.weekly(weekday, startTime);
    case 'weekday':
      return frequencyFormatters.weekday(startTime);
    default:
      return '';
  }
};
