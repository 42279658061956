import { useSuspenseQuery } from '@apollo/client';
import { createBreadCrumbs } from '@circadian-risk/front-end-utils';
import { useOrganizationId, useOrganizationSessionStore } from '@circadian-risk/stores';
import sortBy from 'lodash/sortBy';
import { useMemo } from 'react';

import { GetLocationFilterAggregatesDataDocument } from '../../graphql/typed-nodes';
import { LocationFilterRow } from '../types';
import { ApplicableLocations } from './ApplicableLocations';

export const SmartApplicableLocations: React.FC = () => {
  const organizationId = useOrganizationId();
  const { layersById, nodesById, nodes } = useOrganizationSessionStore(({ nodesById, layersById, nodes }) => ({
    layersById,
    nodesById,
    nodes,
  }));

  const { data } = useSuspenseQuery(GetLocationFilterAggregatesDataDocument, {
    variables: { organizationId },
  });

  const allLocations = useMemo(() => {
    const mappedNodes = nodes.map<LocationFilterRow>(l => {
      const layer = layersById[l.layer_id];
      const basicNodeData = nodesById[l.id];
      const aggregateData = data.nodes.find(n => n.id === l.id);

      return {
        id: l.id,
        name: basicNodeData.name,
        ordinal: basicNodeData.ordinal,
        nodeDepth: basicNodeData.nodeDepth,
        nodeId: l.id,
        layer: {
          id: layer!.id,
          name: layer!.name,
        },
        assessmentsCount: aggregateData?.scenarioAssessmentNodes_aggregate?.aggregate?.count ?? 0,
        itemsCount: aggregateData?.items_aggregate.aggregate?.count ?? 0,
        breadCrumbs: createBreadCrumbs(l.mpath, nodesById, layersById),
        tagIds: l.tags.map(t => t.id),
        parent_id: basicNodeData.parent_id,
      };
    });
    return sortBy(mappedNodes, e => e.nodeDepth);
  }, [data.nodes, layersById, nodes, nodesById]);

  return <ApplicableLocations allLocations={allLocations} />;
};
