import { CircularProgress, LoadingPlaceholderProps } from '@circadian-risk/presentational';
import { Box, BoxProps } from '@mui/material';
import React from 'react';

export interface LoadingPageProps {
  loadingPageProps?: BoxProps;
  placeholderProps?: LoadingPlaceholderProps;
}
export const LoadingPage: React.FC<LoadingPageProps> = ({ placeholderProps, loadingPageProps }) => {
  return (
    <Box height="100vh" {...loadingPageProps}>
      <CircularProgress {...placeholderProps} />
    </Box>
  );
};
