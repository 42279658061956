import isEqual from 'lodash/isEqual';
import { useHistory } from 'react-router-dom';

export const compareAndSetModel = (
  key: string,
  history: ReturnType<typeof useHistory>,
  previousModel: string | null,
  nextModel: string | null,
) => {
  if (nextModel && nextModel !== '{}' && !isEqual(previousModel, nextModel)) {
    const { pathname, search } = history.location;

    const newUrlSearchParams = new URLSearchParams(search);

    newUrlSearchParams.set(key, nextModel);

    window.history.replaceState(null, '', `${pathname}?${newUrlSearchParams.toString()}`);
    // this is the old way we used to do it using react router:
    // history.replace(`${pathname}?${newUrlSearchParams.toString()}`, null);
  }
};
