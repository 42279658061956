export type BuildAssessmentAppUrlWithLocationParams = {
  organizationId: string;
  assessmentId: number | string;
  location?: {
    id: string;
    /**
     * Defines the type of assessment item (whether it is questionnaire or inspection)
     */
    view?: {
      type: 'questionnaire' | 'inspection';
      itemId?: string;
    };
  };
  /**
   * If true, will allow user to return to the page they came from by clicking back button in Assessment App
   */
  addBackLink?: boolean;
};

const buildBaseUrl = (appUrl: string, organizationId: string, assessmentId: number | string) => {
  return `${appUrl}/app/organization/${organizationId}/assessments/${assessmentId}`;
};

const withBackLink = (url: string) => {
  const urlWithBackLink = new URL(url);
  urlWithBackLink.searchParams.set('backLink', window.location.href);
  return urlWithBackLink.toString();
};

export const buildAssessmentAppUrl = (params: BuildAssessmentAppUrlWithLocationParams, appUrl: string): string => {
  const { organizationId, assessmentId, location, addBackLink = true } = params;

  let url = buildBaseUrl(appUrl, organizationId, assessmentId);

  if (location) {
    url = `${url}/${location.id}`;

    if (location.view) {
      url = `${url}/${location.view.type}`;

      if (location.view.itemId) {
        url = `${url}/${location.view.itemId}`;
      }
    }
  }

  return addBackLink ? withBackLink(url) : url;
};
