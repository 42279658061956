import { NoInfer } from '@circadian-risk/shared';
import { useRef, useState } from 'react';

interface PromiseOptions<T> {
  onResolve?: (value: T) => void;
  onReject?: (error: unknown, retry: () => void) => void;
}

interface PromiseEntry<T> extends PromiseOptions<T> {
  promise: () => Promise<T>;
}

interface PromiseQueueState {
  inProgress: boolean;
  count: number;
  failed: boolean;
}

export class PromiseQueue {
  private _state: PromiseQueueState = {
    inProgress: false,
    count: 0,
    failed: false,
  };

  private queue: Set<PromiseEntry<unknown>> = new Set();

  constructor(private onUpdateState?: (state: PromiseQueueState) => void) {}

  get state() {
    return this._state;
  }

  set state(value: PromiseQueueState) {
    this._state = value;
    this.onUpdateState?.(value);
  }

  add<T>(promise: () => Promise<T>, options: NoInfer<PromiseOptions<T>> = {}) {
    const entry = { promise, ...options } as PromiseEntry<T>;
    this.queue.add(entry as PromiseEntry<unknown>);
    this.state.count = this.queue.size;

    if (!this.state.inProgress) {
      this.execute();
    }
  }

  private execute = async () => {
    this.state.inProgress = true;
    const nextEntry = this.queue.values().next().value;

    if (!nextEntry) {
      this.state.inProgress = false;
      return;
    }

    try {
      const value = await nextEntry.promise();
      nextEntry.onResolve?.(value);
      this.queue.delete(nextEntry);
      this.execute();
    } catch (error) {
      this.state.failed = true;
      nextEntry.onReject?.(error, this.execute);
    } finally {
      this.state.count = this.queue.size;
      this.state.inProgress = false;
    }
  };
}

export const usePromiseQueue = (watchQueState = false) => {
  const [state, setState] = useState<PromiseQueueState>({
    inProgress: false,
    count: 0,
    failed: false,
  });

  const queue = useRef(new PromiseQueue(watchQueState ? setState : undefined));

  return [queue.current, state] as const;
};
