import { FCC, useNotification } from '@circadian-risk/front-end-utils';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CopyIcon from '@mui/icons-material/Link';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Card,
  CardProps,
  IconButton,
  Menu,
  MenuItem,
  MenuItemProps,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import copy from 'copy-to-clipboard';
import React, { useRef, useState } from 'react';

interface DetailsCardActionsProps {
  menuItems?: React.ReactNode;
  small?: boolean;
}

export const DetailsCardActions: React.FC<DetailsCardActionsProps> = ({ menuItems, small }) => {
  const [moreDetailsOpen, setMoreDetailsOpen] = useState(false);
  const moreRef = useRef(null);
  const { displaySuccess } = useNotification();

  return (
    <Box>
      <Tooltip title="Copy URL to Clipboard">
        <IconButton
          size={small ? 'small' : 'medium'}
          onClick={() => {
            copy(window.location.href);
            displaySuccess('URL Copied to Clipboard');
          }}
        >
          <CopyIcon />
        </IconButton>
      </Tooltip>
      {menuItems && (
        <>
          <IconButton size={small ? 'small' : 'medium'} onClick={() => setMoreDetailsOpen(true)} ref={moreRef}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            open={moreDetailsOpen}
            anchorEl={moreRef.current}
            onClose={() => setMoreDetailsOpen(false)}
            onClick={() => setMoreDetailsOpen(false)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            {menuItems}
          </Menu>
        </>
      )}
    </Box>
  );
};

interface DetailsCardProps extends Omit<CardProps, 'title'> {
  title: React.ReactNode;
  menuItems?: React.ReactNode;
  className?: string;
  headerChildren?: React.ReactNode;
}

export const DetailsCard: FCC<DetailsCardProps> = ({
  children,
  menuItems,
  title,
  className,
  headerChildren,
  ...rest
}) => {
  return (
    <Card className={className} {...rest}>
      <Box px={2} py={1}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box display={'flex'} justifyContent={'flex-start'} flex={1}>
            <Typography variant="h5">{title}</Typography>
          </Box>

          <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
            <DetailsCardActions menuItems={menuItems} />
            {headerChildren}
          </Box>
        </Box>
        {children}
      </Box>
    </Card>
  );
};
interface DetailsMenuItemProps {
  onClick?: MenuItemProps['onClick'];
}

const menuItemSx: SxProps<Theme> = theme => ({
  fontFamily: 'Saira, sans-serif',
  minWidth: '100px',
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
});
export const DetailsEditMenuItem: FCC<DetailsMenuItemProps> = ({ onClick }) => {
  return (
    <MenuItem onClick={onClick} sx={menuItemSx} disableGutters>
      <Typography variant="button">Edit</Typography>
      <EditIcon />
    </MenuItem>
  );
};

export const DetailsRemoveMenuItem: FCC<DetailsMenuItemProps> = ({ onClick }) => {
  return (
    <MenuItem onClick={onClick} sx={menuItemSx} disableGutters>
      <Typography variant="button" color="error">
        Delete
      </Typography>

      <DeleteIcon color="error" />
    </MenuItem>
  );
};

export const DetailsMenuItem: FCC<DetailsMenuItemProps> = ({ onClick, children }) => {
  return (
    <MenuItem onClick={onClick} sx={menuItemSx} disableGutters>
      {children}
    </MenuItem>
  );
};
