import { HStack } from '@circadian-risk/presentational';
import { Box, Paper } from '@mui/material';
import isString from 'lodash/isString';
import React from 'react';

import { TitleTypography } from '../TitleTypography';

export interface TablePageLayoutProps {
  title: React.ReactNode;
  headerActions?: React.ReactNode;
  table: React.ReactNode;
}

export const TablePageLayout: React.VFC<TablePageLayoutProps> = ({ table, title, headerActions }) => {
  return (
    <Box height="100%" width="100%" display="flex" flexDirection="column" gap={2}>
      <Paper square>
        <HStack px={4} py={2} justifyContent="space-between">
          {!isString(title) ? (
            title
          ) : (
            <>
              <TitleTypography>{title}</TitleTypography>
              {headerActions}
            </>
          )}
        </HStack>
      </Paper>

      <Box px={2} pb={2} flex={1} height="100%" width="100%">
        {table}
      </Box>
    </Box>
  );
};
