import { FCC } from '@circadian-risk/front-end-utils';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Box, IconButton, Popover, SvgIconProps, SxProps, Theme, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';

interface InfoButtonPopoverProps {
  text?: string;
  title?: string;
  size?: 'small' | 'medium';
  iconProps?: SvgIconProps;
  iconButtonSx?: SxProps<Theme>;
}

export const InfoButtonPopover: FCC<InfoButtonPopoverProps> = ({
  text,
  title,
  size = 'small',
  iconProps,
  children,
  iconButtonSx,
}) => {
  const [infoOpen, setInfoOpen] = useState(false);
  const infoButtonRef = useRef(null);

  return (
    <>
      <IconButton onClick={() => setInfoOpen(!infoOpen)} ref={infoButtonRef} size={size} sx={iconButtonSx}>
        <InfoIcon {...iconProps} />
      </IconButton>
      <Popover
        slotProps={{
          paper: {
            sx: {
              maxWidth: 350,
            },
          },
        }}
        open={infoOpen}
        anchorEl={infoButtonRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => setInfoOpen(false)}
      >
        <Box p={2}>
          {title && (
            <Typography paddingBottom={1} variant="h5">
              {title}
            </Typography>
          )}
          {text && <Typography variant="body2">{text}</Typography>}
          {children}
        </Box>
      </Popover>
    </>
  );
};
