import { ROUTES } from '@circadian-risk/routes';
import { USER_ROLE } from '@circadian-risk/shared';
import { useOrganizationSessionStore } from '@circadian-risk/stores';
import { generatePath, Redirect } from 'react-router-dom';

import { AssessorDashboard } from './AssessorDashboard';
import { MainDashboardGql } from './MainDashboardGql';

const MainDashboardRoleWrapper = () => {
  const [organizationId, userRole, roles] = useOrganizationSessionStore(state => [state.id, state.role, state.roles]);

  if (userRole === USER_ROLE.MEMBER) {
    if (roles.includes(USER_ROLE.LOCATION_MANAGER)) {
      return <Redirect to={generatePath(ROUTES.ASSESSMENTS, { organizationId })} />;
    }
    return <AssessorDashboard />;
  } else {
    return <MainDashboardGql />;
  }
};
export default MainDashboardRoleWrapper;
