import { splitMpath } from './hierarchyRecordsHelpers';

type NodesById = Record<string, { id: string; name: string; layer_id: string }>;
type LayersById = Record<string, { name: string }>;

export const createBreadCrumbs = (mpath: string, nodesById: NodesById, layersById: LayersById) => {
  const nodes = splitMpath(mpath).map((nodeId: string) => nodesById[nodeId]);
  return nodes.map(node => ({
    id: node.id,
    locationName: node.name,
    layerName: layersById[node.layer_id]?.name ?? 'Unknown Layer',
  }));
};
