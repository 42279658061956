import { Enum_Consideration_Type_Enum } from '@circadian-risk/graphql-types';
import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();

export const createConsiderationOptionSchema = z.object({
  name: z.string().min(1).max(200),
  description: z.string().nullable(),
  initialCost: z.number().positive().nullable(),
  ongoingCost: z.number().positive().nullable(),
  timeEstimate: z.number().positive().nullable(),
  type: z.custom<Enum_Consideration_Type_Enum>(),
  nodeIds: z.array(z.string().uuid()).min(1, { message: 'You must pick a location' }),
  itemCategoryIds: z.array(z.string().uuid()),
});

export type CreateConsiderationOption = z.infer<typeof createConsiderationOptionSchema>;

export const considerationOptionsContract = c.router(
  {
    create: {
      method: 'POST',
      path: '/:organizationId',
      pathParams: z.object({
        organizationId: z.string().uuid(),
      }),
      body: createConsiderationOptionSchema,
      responses: {
        201: z.object({
          id: z.number(),
          name: z.string(),
          description: z.string().nullable(),
          initial_cost: z.number().nullable(),
          ongoing_cost: z.number().nullable(),
          time_estimate: z.number().nullable(),
          type: z.custom<Enum_Consideration_Type_Enum>(),
        }),
      },
    },
  },
  {
    pathPrefix: '/consideration-options',
  },
);
