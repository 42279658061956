import { DEFAULT_ADDRESS_FIELDS } from './address-builder';
import { DEFAULT_LOCATION_HIERARCHY_HEADERS } from './hierarchy-csv-parser';

/**
 * Returns the relevant layer tag headers for a given layer name
 * The returned name is formatted as `LayerName:Tag`
 *
 * @param headers
 * @param layerName
 * @returns
 */
export const getRelevantLayerTagHeaders = (headers: string[], layerName: string): string[] => {
  // Find the last address header that may be present
  const addressHeaders = Object.values(DEFAULT_ADDRESS_FIELDS).flatMap(v => v);
  const lastAddressHeader = addressHeaders[addressHeaders.length - 1];

  let startingIndex = -1;
  startingIndex = headers.findIndex(h => h.toLowerCase() === lastAddressHeader.toLowerCase());

  // If the last address header is not found then we just need to lookup for the default hierarchy headers
  if (startingIndex === -1) {
    startingIndex = headers.findIndex(
      h =>
        h.toLowerCase() ===
        DEFAULT_LOCATION_HIERARCHY_HEADERS[DEFAULT_LOCATION_HIERARCHY_HEADERS.length - 1].toLowerCase(),
    );
  }

  // Find all relevant tag headers after the starting index (excluding itself)
  const relevantLayerTagHeaders = headers.slice(startingIndex + 1, headers.length);

  return relevantLayerTagHeaders.filter(l => l.split(':')[0].toLowerCase() === layerName.toLowerCase());
};
