import { Box, BoxProps, useTheme } from '@mui/material';

type Weight = 'medium' | 'bold' | 'light';

export const Bold: React.FC<{ weight?: Weight } & BoxProps> = ({ weight, ...boxProps }) => {
  const { typography } = useTheme();

  const fontWeights: Record<Weight, string> = {
    medium: typography.fontWeightMedium as string,
    bold: typography.fontWeightBold as string,
    light: typography.fontWeightLight as string,
  };
  return <Box component="span" fontWeight={weight ? fontWeights[weight] : fontWeights.medium} {...boxProps} />;
};
