import { Box, BoxProps, Card, CardProps } from '@mui/material';
import React from 'react';

export type TablePageContentProps = BoxProps & { cardRef?: CardProps['ref'] };

export const TablePageContent: React.FC<TablePageContentProps> = props => {
  const { children, cardRef, ...boxProps } = props;

  return (
    <Box px={2} pb={2} flex={1} {...boxProps}>
      <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }} ref={cardRef}>
        {children}
      </Card>
    </Box>
  );
};
