import { createSlot } from '@circadian-risk/front-end-utils';
import { GenericDialog, GenericDialogProps } from '@circadian-risk/presentational';
import React, { useEffect } from 'react';
import { FieldValues } from 'react-hook-form';
import { v4 } from 'uuid';

import { CircadianForm, CircadianFormProps } from './CircadianForm';
import { SubmitButton, SubmitButtonProps } from './SubmitButton';

export type GenericFormDialogProps<T extends FieldValues = FieldValues> = Omit<GenericDialogProps, 'isDirty'> & {
  children: React.ReactNode;
  /** please don't catch errors inside onSubmit, feel free to throw, circadianForm handles it for you */
  formProps: Omit<CircadianFormProps<T>, 'children'>;
  /** @default true */
  resetOnClose?: boolean;
  /** @default true */
  closeOnSuccess?: boolean;
  skipDirtyCheck?: SubmitButtonProps['skipDirtyCheck'];
};

const Portal = createSlot();

/**
 * @deprecated Instead of importing GenericFormDialog directly, generateCircadianForm returns a wrapped version with correct types
 *
 * This is small but powerful abstraction for a common patter to render a form inside of a dialog
 * The reason this is needed is to have Actions have access to the FormContext while rendering inside DialogActions
 * Additionally, it provides a uniform UX for forms inside dialogs:
 * - form is reset when dialog is closed
 * - dialog is closed when form is submitted successfully (otherwise it will stay open)
 * - submit and cancel buttons already rendered for you
 * - submit button is disabled when form is not dirty or is submitting
 *
 * @important Never render more than one FormDialog at a time otherwise Portal might not work correctly
 *
 * @param resetOnClose if true, form will be reset when dialog is closed @default true
 * @param closeOnSuccess if true, dialog will be closed when form is submitted successfully @default true
 *
 */
export const GenericFormDialog = <T extends FieldValues = FieldValues>({
  formProps,
  resetOnClose = true,
  closeOnSuccess = true,
  skipDirtyCheck,
  ...props
}: GenericFormDialogProps<T>) => {
  // this ID is important to link form and submit button since they are rendered in different trees due to use of Portal
  const id = v4();
  const { isOpen, onClose } = props;
  const { reset, formState } = formProps.formMethods;
  const { isSubmitSuccessful, isDirty } = formState;

  // resetting the form when dialog is closed
  useEffect(() => {
    if (resetOnClose && !isOpen) {
      reset();
    }
  }, [resetOnClose, reset, isOpen]);

  // closing the dialog when form is submitted successfully
  useEffect(() => {
    if (closeOnSuccess && isSubmitSuccessful) {
      onClose();
    }
  }, [closeOnSuccess, onClose, isSubmitSuccessful]);

  return (
    <GenericDialog {...props} actions={<Portal.Outlet />} isDirty={isDirty}>
      <CircadianForm {...{ ...formProps, id }}>
        {props.children}
        {/* Notice, in HTML tree, actions will be rendered inside DialogAction via Portal.Outlet,
            but they will still have access to the FormContext inside the react tree */}
        <Portal>
          {props.actions}
          <SubmitButton form={id} skipDirtyCheck={skipDirtyCheck} />
        </Portal>
      </CircadianForm>
    </GenericDialog>
  );
};
