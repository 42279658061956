import isString from 'lodash/isString';
import React, { HTMLProps, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { v4 } from 'uuid';

import { createEnhancedStore } from './zustand';

type Slot = React.FC<{ children: React.ReactNode }> & {
  Outlet: React.FC<HTMLProps<HTMLDivElement>>;
};

export const createSlot = (outletId?: string) => {
  const useRootId = createEnhancedStore<string | null>(() => null);

  const Outlet: React.FC<HTMLProps<HTMLDivElement>> = props => {
    const id = useMemo(() => outletId ?? v4(), []);

    useEffect(() => {
      return () => {
        useRootId.setState(null);
      };
    }, []);
    return <div {...props} ref={() => useRootId.setState(id)} id={id} />;
  };

  const SlotComponent: Slot = ({ children }) => {
    const id = useRootId();
    if (!isString(id)) {
      return null;
    }

    const root = document.getElementById(id);

    if (!root) {
      throw new Error(`Root element with id ${id} not found`);
    }

    return ReactDOM.createPortal(children, root);
  };

  SlotComponent.Outlet = Outlet;

  return SlotComponent;
};
