// Source from: react-multi-highlight

import { getRangesFromConfig, toHTML } from './textRange';
import { IHighlighterProps } from './type';

export const Highlighter: React.FC<IHighlighterProps> = ({ config, text }) => {
  const { splitIndexes, rangeToConfig } = getRangesFromConfig(text, Array.isArray(config) ? config : [config]);
  const fragments = toHTML(text, splitIndexes, rangeToConfig);
  return <>{fragments}</>;
};
