import { AnswerSummaryBar } from '@circadian-risk/assessment-components';
import React from 'react';

import { useCompletionConditions } from '../LocationScenarioAssessmentForm/ScenarioAssessmentForm/useCompletionConditions';

export const AnswerSummaryBarSmart: React.FC = () => {
  const {
    importTotal: importedTotal,
    ratifyTotal,
    newQuestionsCount,
    totalQuestionsCount,
    willImport,
  } = useCompletionConditions();

  return (
    <AnswerSummaryBar
      willImport={willImport}
      importedTotal={importedTotal}
      ratifyTotal={ratifyTotal}
      newQuestionsCount={newQuestionsCount}
      totalQuestionsCount={totalQuestionsCount}
    />
  );
};
