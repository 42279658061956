import {
  ColDef,
  GetDetailRowDataParams as AgGetDetailRowDataParams,
  ICellRendererParams,
  IDetailCellRendererParams,
  ValueFormatterParams as AgValueFormatterParams,
  ValueGetterParams as AgValueGetterParams,
  ValueSetterParams as AgValueSetterParams,
} from '@ag-grid-community/core';

/**
 * Provides type safety when building custom cell renderers
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CellRendererParams<T extends Record<string, any>, U = any> = Omit<
  ICellRendererParams,
  'data' | 'context' | 'colDef'
> & {
  data: T;
  context: U;
  colDef: ColDefOfType<T>;
};

/**
 * Useful for building cell renderers that only rely on the data from the row and don't need typed access
 * to any other AG grid properties
 */
export type CellRendererDataParams<T> = {
  data: T;
};

type WithTypedData<SourceType extends { data: unknown }, DataType> = Omit<SourceType, 'data'> & { data: DataType };

export type ValueGetterParams<T> = WithTypedData<AgValueGetterParams, T>;

export type ValueSetterParams<T> = WithTypedData<AgValueSetterParams, T>;

export type GetDetailRowDataParams<T> = WithTypedData<AgGetDetailRowDataParams, T>;

export type ValueFormatterParams<T> = WithTypedData<AgValueFormatterParams, T>;

export type GetDetailRowData<T> = (params: GetDetailRowDataParams<T>) => void;

export type DetailCellRendererParams<T> = Partial<Omit<IDetailCellRendererParams, 'getDetailRowData'>> & {
  getDetailRowData: GetDetailRowData<T>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ColDefOfType<T extends Record<string, any>> = Omit<ColDef, 'field' | 'valueFormatter'> & {
  field?: keyof T & string;
  valueFormatter?: string | ((params: ValueFormatterParams<T>) => string) | undefined;
};

/**
 * A place to do common column types to reuse across tables.
 *
 * Example: we want numeric filter across all numeric column and right alignment of cells
 */
export const AgGridColumnTypes: Record<string, ColDef> = {
  numeric: {
    filter: 'agNumberColumnFilter',
    headerClass: 'ag-right-aligned-header',
    cellClass: 'ag-right-aligned-cell',
  },
};
