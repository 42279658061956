import { EndsOn } from '@circadian-risk/api-contract';
import { DateFormat } from '@circadian-risk/shared';
import dayjs from 'dayjs';
import pluralize from 'pluralize';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatEndsOn = (type: EndsOn['type'], value: any | null) => {
  if (type === 'never') {
    return 'Never';
  } else if (type === 'target_date') {
    return dayjs(value).format(DateFormat.MONTH_DAY_YEAR_RAW);
  } else {
    const total = parseInt(value!);
    return `After ${pluralize('assessment', total, total > 0)}`;
  }
};
