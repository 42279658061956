import { percentageWithPadding } from '@circadian-risk/shared';
import { Box, SvgIcon, SvgIconProps, Tooltip, useTheme } from '@mui/material';
import React from 'react';

import { CircularProgressWithBg } from './CircularProgressWithBg';

interface CircularProgressNoAnimationProps {
  value: number;
  disableTooltip?: boolean;
  /**
   * If progress is not yet 100% and the value is set to true then the filling color
   * will be white
   *
   * @default false
   */
  progressBgColorWhite?: boolean;
  size?: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AnimatedCheckmarkIconProps {}

const CompleteIconSvg = () => (
  <svg
    style={{ padding: 2 }}
    viewBox="0 0 24 24"
    height="100%"
    width="100%"
    fill="none"
    stroke="inherit"
    strokeWidth={3}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
  </svg>
);

export const CheckmarkIcon: React.FC<SvgIconProps> = props => {
  return <SvgIcon {...props} inheritViewBox component={CompleteIconSvg} />;
};

export const CircularProgressNoAnimation: React.FC<CircularProgressNoAnimationProps> = ({
  value,
  disableTooltip,
  progressBgColorWhite,
  size = 24,
}) => {
  const percentage = Math.round(value);
  const theme = useTheme();

  const dimensions = { width: size, height: size };

  const tooltipTitle = disableTooltip ? '' : `${percentage}% Complete`;
  return (
    <Tooltip title={tooltipTitle} placement="top" arrow>
      <Box
        display="inline-flex"
        justifyContent="center"
        alignItems="center"
        width={dimensions.width}
        height={dimensions.height}
        position={'relative'}
      >
        {percentage !== 100 ? (
          <CircularProgressWithBg
            size={size}
            value={percentageWithPadding(percentage)}
            progressBgColorWhite={progressBgColorWhite}
          />
        ) : (
          <Box
            sx={{
              bgcolor: progressBgColorWhite ? theme.palette.common.white : theme.palette.success.main,
              stroke: progressBgColorWhite ? theme.palette.secondary.dark : theme.palette.common.white,
              borderRadius: '50%',
              width: size,
              height: size,
              display: 'flex',
            }}
          >
            <CheckmarkIcon
              sx={{
                width: size,
                height: size,
              }}
            />
          </Box>
        )}
      </Box>
    </Tooltip>
  );
};
