export type FormattableUser = {
  first_name?: string | null | undefined;
  last_name?: string | null | undefined;
  email: string;
};

export const USER_NOT_AVAILABLE_MESSAGE = 'User removed or not available';

/** Attempts to format the user name based on the available parts */
export const tryFormatUserName = (user: FormattableUser | null | undefined) => {
  if (!user) {
    return USER_NOT_AVAILABLE_MESSAGE;
  }

  const { first_name = '', last_name = '', email } = user;

  if (first_name && last_name) {
    return `${first_name} ${last_name}`;
  } else if (first_name && !last_name) {
    return first_name;
  } else if (last_name && !first_name) {
    return last_name;
  }

  return email;
};
