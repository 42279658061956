import DownloadIcon from '@mui/icons-material/Download';
import { Box, Divider, Link, Typography } from '@mui/material';
import startCase from 'lodash/startCase';
import React from 'react';

import { ScenarioSummaryAccordion } from './ScenarioSummaryAccordion';
import { InherentRiskMeasure } from './types';

export interface ScenarioSummaryProps {
  name: string;
  description?: string | null;
  scenarioType: string;
  questionSetName: string;
  onQuestionSetLinkClick?: () => void;
  probabilityMeasures: InherentRiskMeasure[];
  severityMeasures: InherentRiskMeasure[];
}

export const ScenarioSummary: React.FC<ScenarioSummaryProps> = props => {
  const {
    name,
    description,
    scenarioType,
    questionSetName,
    onQuestionSetLinkClick,
    probabilityMeasures,
    severityMeasures,
  } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <Typography variant="h6">{name}</Typography>
      <Typography variant="body1">{startCase(scenarioType)}</Typography>
      <Typography variant="body2">{description}</Typography>

      <Link
        component="button"
        variant="body1"
        onClick={onQuestionSetLinkClick}
        sx={{ display: 'flex', alignItems: 'center', my: 0.5 }}
      >
        <span>{questionSetName}&nbsp;</span>
        <DownloadIcon />
      </Link>

      <Divider />

      <Box width="100%" p={2} borderRadius={2} bgcolor="background.default">
        <ScenarioSummaryAccordion probabilityMeasures={probabilityMeasures} severityMeasures={severityMeasures} />
      </Box>
    </Box>
  );
};
