import { z } from 'zod';

// iprokopovich: I tried to make this into an object but native input options can't hold objects
const selectSchema = z.string();

export const orgCreationFormSchema = z.object({
  orgName: z.string().min(1, 'Organization Name is required'),
  hierarchyType: z.union([z.literal('basic'), z.literal('plusRegion')]),
  industry: z.string().min(1, 'Required'),
  freeFormIndustry: z.string().optional(),
  employeeCount: z.string(),
  locationsCount: z.string(),
  numberOfAssessmentsPerYear: z.string(),
});

const rangeOptionSchema = z.object({
  min: z.number(),
  max: z.number(),
});

export const stringifiedRangeOptionSchema = z.string().transform(val => rangeOptionSchema.parse(JSON.parse(val)));

export interface CountRangeOption {
  label: string;
  value: z.infer<typeof selectSchema>;
}

export type OrgCreationFormData = z.infer<typeof orgCreationFormSchema>;

export const orgCreationFormParsedDataSchema = orgCreationFormSchema
  .omit({
    employeeCount: true,
    locationsCount: true,
    numberOfAssessmentsPerYear: true,
  })
  .extend({
    employeeCount: stringifiedRangeOptionSchema,
    locationsCount: stringifiedRangeOptionSchema,
    numberOfAssessmentsPerYear: stringifiedRangeOptionSchema,
  });

export type OrgCreationFormParsedData = z.infer<typeof orgCreationFormParsedDataSchema>;
