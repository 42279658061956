import {
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadProps,
  TableProps,
  TableRow,
  Theme,
} from '@mui/material';
import React from 'react';

interface Header {
  name: React.ReactNode;
  key: string;
}

export interface BasicTableProps extends TableProps {
  headers: Header[];
  data: Record<string, React.ReactNode>[];
  tableHeadProps?: Partial<TableHeadProps>;
  tableHeaderSx?: SxProps<Theme>;
}

export const BasicTable: React.FC<BasicTableProps> = props => {
  const { headers, data, tableHeadProps, tableHeaderSx, ...rest } = props;
  return (
    <Table size="small" {...rest}>
      <TableHead {...tableHeadProps}>
        <TableRow>
          {headers.map(header => {
            const { key, name } = header;
            return (
              <TableCell key={key} sx={tableHeaderSx}>
                {name}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((rowData, i) => {
          return (
            <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              {headers.map(header => {
                const { key } = header;
                return <TableCell key={`${i}-${key}`}>{rowData[key]}</TableCell>;
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
