import { FrequencyType } from '@circadian-risk/api-contract';
import { CreateAssessmentFormGroupHeader } from '@circadian-risk/assessment-components';
import { AutocompleteOption } from '@circadian-risk/form';
import { HStack, useDialog } from '@circadian-risk/presentational';
import { getWeekdayFromDate, getWeekdayOrdinalFromDate } from '@circadian-risk/shared';
import EditIcon from '@mui/icons-material/Edit';
import RepeatIcon from '@mui/icons-material/Repeat';
import { IconButton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import { createScheduleAssessmentForm, useCreateScheduledAssessmentFormContext } from '../../../form';
import { CustomRepeatDialog } from '../CustomRepeatDialog';
import { useGetCustomFrequencyLabel } from '../hooks/useGetCustomFrequencyLabel';
import { useResetFrequencyOnChange } from '../hooks/useResetFrequencyOnChange';

const { Select } = createScheduleAssessmentForm;

export const FrequencyFormGroup: React.FC = () => {
  const { isOpen: isCustomRepeatOpen, openDialog: openCustomRepeat, closeDialog: closeCustomRepeat } = useDialog();
  const resetFrequency = useResetFrequencyOnChange(openCustomRepeat);
  const frequencyLabel = useGetCustomFrequencyLabel();
  const { watch, setValue } = useCreateScheduledAssessmentFormContext();
  const isCustomSelected = watch('frequency.type') === 'custom';
  const startDateWatch = watch('startsAtDate');

  const frequencyOptions = useMemo(() => {
    const weekday = getWeekdayFromDate(startDateWatch);
    return [
      {
        label: 'Daily',
        value: 'daily',
      },
      {
        label: `Weekly on ${weekday}`,
        value: 'weekly',
      },
      {
        label: `Monthly on the ${getWeekdayOrdinalFromDate(startDateWatch)} ${weekday}`,
        value: 'monthly',
      },
      {
        label: `Annually on ${dayjs(startDateWatch).format('MM/DD')}`,
        value: 'annually',
      },
      {
        label: 'Weekdays (Monday to Friday)',
        value: 'weekday',
      },
      {
        label: 'Custom',
        value: 'custom',
      },
    ] satisfies AutocompleteOption<FrequencyType>[];
  }, [startDateWatch]);

  return (
    <>
      <CreateAssessmentFormGroupHeader title="Frequency" subtitle="What cadence should the assessment repeat on?" />

      <HStack spacing={2}>
        <Select
          options={frequencyOptions}
          formKey="frequency.type"
          boxProps={{ minWidth: 320, width: isCustomSelected ? undefined : '100%' }}
          startAdornment={<RepeatIcon color="secondary" />}
          placeholder="How frequent is this assessment due?"
          onChangeCallback={value => {
            const typedValue = value as FrequencyType | null;
            resetFrequency(typedValue);
          }}
          size="small"
        />
        {isCustomSelected && (
          <HStack>
            <Typography color="secondary">{frequencyLabel}</Typography>
            <IconButton onClick={openCustomRepeat}>
              <EditIcon />
            </IconButton>
            <CustomRepeatDialog
              open={isCustomRepeatOpen}
              onClose={closeCustomRepeat}
              onConfirm={formData => {
                closeCustomRepeat();
                setValue(
                  'frequency',
                  {
                    type: 'custom',
                    options: formData,
                  },
                  { shouldDirty: true },
                );
              }}
            />
          </HStack>
        )}
      </HStack>
    </>
  );
};
