import { useApiClient } from '@circadian-risk/api-client-provider';
import { useNotification } from '@circadian-risk/front-end-utils';
import { PageFormLayout } from '@circadian-risk/layout';
import { useUserSessionStore } from '@circadian-risk/stores';
import { AxiosError } from 'axios';
import React from 'react';

import { ResponsiveIpadDemo } from '../components/ResponsiveIpadDemo/ResponsiveIpadDemo';
import { EmailVerificationForm, EmailVerificationFormProps } from './EmailVerificationForm';
import { EmailVerificationFormData } from './types';

export type EmailVerificationPageProps = {
  email: string;
  onVerificationResponse: (response: { isPendingEmailVerification: boolean }) => void;
} & Pick<EmailVerificationFormProps, 'signout'>;

export const EmailVerificationPage: React.FC<EmailVerificationPageProps> = ({
  onVerificationResponse,
  email,
  signout,
}) => {
  const { tsRestClient } = useApiClient();
  const { displayError } = useNotification();
  const resetSessionData = useUserSessionStore(state => state.resetSessionData);

  const onSubmit = async (formData: EmailVerificationFormData) => {
    try {
      const { status, body } = await tsRestClient.auth.verifyEmail({
        body: {
          verificationCode: formData.code,
        },
      });

      if (status === 201) {
        onVerificationResponse(body);
      }
    } catch (ex) {
      // if we're getting 403 it means we no longer have a valid session
      if (ex instanceof AxiosError && ex.response?.status === 403) {
        displayError('Session has expired');
        resetSessionData();
        return;
      }
      displayError(ex);
    }
  };

  return (
    <PageFormLayout
      leftContent={<ResponsiveIpadDemo />}
      form={
        <EmailVerificationForm
          {...{
            onSubmit,
            email,
            signout,
          }}
        />
      }
    />
  );
};
