import * as Sentry from '@sentry/react';
import axios from 'axios';
import ms from 'ms';
import { useEffect } from 'react';

import { config } from '../../environments/environment';

const POLL_INTERVAL = ms('5m');

/**
 * Checks to see if the current app version in memory matches the latest known server artifact
 * @returns
 */
export const checkIsAppVersionOutOfSync = async () => {
  try {
    const { data } = await axios.get(`/version.txt?${new Date().getTime()}`);

    if (String(data).trim() !== config.APP_VERSION) {
      return true;
    }
    return false;
  } catch (err) {
    Sentry.captureEvent({
      message: `Failed to fetch version.txt file: ${(err as Record<string, string>)?.message}`,
      level: 'warning',
    });
    // Instead of throwing we'll just return false to avoid breaking the app
    // and report to Sentry that we failed to fetch the version.txt file
    // This may have been caused by a network issue or a new deployment
    return false;
  }
};

export const useVersionCheck = (callback: () => void) => {
  useEffect(() => {
    let isCheckingVersion = false;

    const checkVersion = async () => {
      isCheckingVersion = true;

      const isAppVersionOutOfSync = await checkIsAppVersionOutOfSync();
      if (isAppVersionOutOfSync) {
        callback();
      }

      isCheckingVersion = false;
    };

    const interval = setInterval(() => {
      if (!isCheckingVersion) {
        void checkVersion();
      }
    }, POLL_INTERVAL);

    // Invoke a version check immediately on application startup to see if there's
    // something new instead of waiting for the POLL_INTERVAL to elapse for the first check
    void checkVersion();

    return () => {
      clearInterval(interval);
    };
  }, [callback]);
};
