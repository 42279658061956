import { CreateAssessmentFormGroupHeader } from '@circadian-risk/assessment-components';
import { Bold, HStack } from '@circadian-risk/presentational';
import { Typography } from '@mui/material';

import { createScheduleAssessmentForm } from '../../../form';
import { TimezoneFormField } from './TimezoneFormField';

const { Date, Time } = createScheduleAssessmentForm;

export const ScheduleTimeFormGroup: React.FC = () => {
  return (
    <>
      <CreateAssessmentFormGroupHeader
        title="Assessment Schedule"
        subtitle="When should each assessment start and at what time?"
      />

      <HStack spacing={4}>
        <HStack>
          <Date
            formKey="startsAtDate"
            adornmentPosition="start"
            datePickerProps={{
              disablePast: true,
            }}
            size="small"
          />
          <Typography>
            <Bold>at</Bold>
          </Typography>

          <Time formKey="startsAtTime" adornmentPosition="start" clearable={false} size="small" />
        </HStack>

        <TimezoneFormField />
      </HStack>
    </>
  );
};
