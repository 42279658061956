import './question-container-query.css';

import { getThemeSpacing, HighlightsProps, HStack, QuestionText, VStack } from '@circadian-risk/presentational';
import { Card, Stack, SxProps, Theme } from '@mui/material';
import clsx from 'clsx';

// this is an attempt to use container queries while there is no support inside MUI
const containerClassName = 'circadian-question-component';
const containerStyle: SxProps = { containerType: 'inline-size' };

export interface QuestionLayoutProps {
  isActionRequired: boolean;
  questionText: string;
  quickActions: React.ReactNode;
  middleRow?: React.ReactNode;
  bottomRow: React.ReactNode;
  hasLeftAction: boolean;
  highlights?: HighlightsProps[];
  sx?: SxProps<Theme>;
}

export const QuestionLayout: React.FC<QuestionLayoutProps> = ({
  isActionRequired,
  quickActions,
  questionText,
  middleRow,
  bottomRow,
  hasLeftAction,
  highlights,
  sx,
}) => {
  return (
    <Card
      variant="outlined"
      aria-label="question card"
      sx={[
        theme => ({
          padding: theme.spacing(2),
          paddingBottom: theme.spacing(1),
          ...(isActionRequired
            ? {
                // subtracting 1 due to the card having it's own left border by default
                marginLeft: `${-(getThemeSpacing(theme, 0.5) - 1)}px`,
                borderLeft: `${theme.spacing(0.5)} solid ${theme.palette.warning.light}`,
              }
            : {}),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <VStack sx={containerStyle}>
        <Stack
          className={clsx(!hasLeftAction && containerClassName)}
          marginTop={hasLeftAction ? -0.5 : undefined}
          gap={1}
          flexDirection="column"
        >
          <HStack pb={1} alignItems="start">
            <QuestionText question={questionText} highlights={highlights} flex={1} pt={1} />
            {quickActions}
          </HStack>
        </Stack>

        {middleRow}
        {bottomRow}
      </VStack>
    </Card>
  );
};
