import { Enum_Answer_Responses_Enum } from '@circadian-risk/graphql-types';
import { CriticalityDropdownInput, CriticalityIcon } from '@circadian-risk/presentational';
import { useTheme } from '@mui/material';
import dayjs from 'dayjs';
import isBoolean from 'lodash/isBoolean';
import isNil from 'lodash/isNil';
import React, { useCallback, useMemo } from 'react';
import isEqual from 'react-fast-compare';

import { AnswerFormData } from './AnswerFormData';
import {
  QuestionActiveFieldsCompact,
  QuestionActiveFieldsCompactProps,
  QuestionActiveFieldsInteractive,
  QuestionActiveFieldsInteractiveProps,
  QuestionQuickActions,
} from './components';
import { QuestionLayout } from './layout/QuestionLayout';
import { QuestionProps } from './question.types';
import { QuestionResponseGroup } from './QuestionResponseGroup';
import { resolveDisabledStates } from './utils';

const datesAreEqual = (date1?: string | null, date2?: string | null) => {
  if (!date1 || !date2) {
    return false;
  }

  return dayjs(date1).isSame(date2);
};

export const initialQuestionValues: Required<AnswerFormData> = {
  is_flagged: null,
  description: null,
  response: null,
  partial_compliance_multiplier: null,
};

const QuestionWrapper: React.FC<QuestionProps> = ({
  importedData,
  badResponse,
  disabled,
  value = initialQuestionValues,
  onValueChange,
  onReImport,
  onCloseClick,
  isActionRequired = false,
  checkbox,
  compactResponse = false,
  criticality,
  onAddFiles,
  onImageProcessing,
  files = [],
  answeredAt,
  answeredBy,
  questionText,
  isPartialComplianceEnabled,
  standards = [],
  optionsSectionProps,
  onCriticalityChange,
  answeredBySlot,
  highlights,
  actions,
  questionRequired,
  itemIsPresent,
}) => {
  const theme = useTheme();

  const handleChange = useCallback(
    (newValue: Partial<AnswerFormData>) => {
      if (!onValueChange) {
        return;
      }
      onValueChange({ ...value, ...newValue }, Object.keys(newValue) as (keyof AnswerFormData)[]);
    },
    [onValueChange, value],
  );

  const onFlaggedChange = useCallback((value: boolean | null) => handleChange({ is_flagged: value }), [handleChange]);
  const disabledStates = useMemo(() => resolveDisabledStates(disabled), [disabled]);

  const disableImportMessage =
    isBoolean(disabled) || isBoolean(disabled?.import) ? undefined : disabled?.import?.message;

  const showImportedResponse = !isNil(importedData?.response) && datesAreEqual(importedData?.answeredAt, answeredAt);

  const onResponseChange = useCallback(
    (newResponse: Enum_Answer_Responses_Enum | null | undefined) => {
      handleChange({
        response: newResponse,
      });
    },
    [handleChange],
  );

  const onPartialComplianceChange = useCallback(
    (newPartialCompliance: number | null | undefined) => {
      handleChange({
        partial_compliance_multiplier: newPartialCompliance,
      });
    },
    [handleChange],
  );
  const isReadOnly = isBoolean(disabled) && disabled;
  const {
    provideAnswer: provideAnswerDisabled,
    compliantAnswer: isCompliantAnswerDisabled,
    addMoreInfo: addMoreInfoDisabled,
    criticality: criticalityDisabled,
  } = disabledStates;

  const responseShown = showImportedResponse ? importedData?.response : value?.response;

  const activeFieldsCompactProps: QuestionActiveFieldsCompactProps = {
    value,
    description: value?.description,
    answeredAt,
    answeredBy,
    files,
    standards,
    deficientResponse: badResponse,
    partialCompliance: value?.partial_compliance_multiplier,
    isPartialComplianceEnabled: isPartialComplianceEnabled ?? false,
    text: responseShown ?? null,
    options: optionsSectionProps?.options,
  };

  const interactiveFieldsProps: QuestionActiveFieldsInteractiveProps = {
    disabledStates: {
      addFiles: disabledStates.addFiles,
      description: disabledStates.values.description,
      ofcWithTooltip: disabledStates.ofcWithTooltip,
    },
    standards,
    handleChange,
    onAddFiles,
    onImageProcessing,
    addMoreInfoDisabled,
    optionsSectionProps: {
      options: optionsSectionProps.options,
      actions: isReadOnly ? undefined : optionsSectionProps.actions,
    },
    files,
    value,
  };

  const criticalityComponent = !criticality ? null : onCriticalityChange ? (
    <CriticalityDropdownInput value={criticality} onChange={onCriticalityChange} disabled={criticalityDisabled} />
  ) : (
    <CriticalityIcon
      color={theme.palette.organizationColors.criticality[criticality as 1 | 2 | 3]}
      criticality={criticality}
      maxCriticality={3}
    />
  );

  const hasLeftAction = Boolean(checkbox) || Boolean(onCloseClick);

  return (
    <QuestionLayout
      isActionRequired={Boolean(!isReadOnly && isActionRequired)}
      questionText={questionText}
      hasLeftAction={hasLeftAction}
      highlights={highlights}
      quickActions={
        <QuestionQuickActions
          onFlaggedChange={onFlaggedChange}
          onCloseClick={onCloseClick}
          onReImport={onReImport}
          checkbox={checkbox}
          disableImportMessage={disableImportMessage}
          isFlagged={value?.is_flagged ?? false}
          importDisabled={disabledStates.import}
          flagDisabled={disabledStates.values.is_flagged}
          criticalityComponent={criticalityComponent}
          actions={actions}
        />
      }
      middleRow={
        compactResponse ? null : (
          <QuestionResponseGroup
            deficientResponse={badResponse}
            isImported={showImportedResponse}
            answeredBy={answeredBy}
            answeredAt={answeredAt}
            response={responseShown}
            partialCompliance={value?.partial_compliance_multiplier}
            isPartialComplianceEnabled={isPartialComplianceEnabled ?? false}
            onResponseChange={onResponseChange}
            onPartialComplianceChange={onPartialComplianceChange}
            disabled={provideAnswerDisabled}
            isCompliantAnswerDisabled={isCompliantAnswerDisabled}
            questionRequired={questionRequired}
            itemIsPresent={itemIsPresent}
            answeredBySlot={answeredBySlot}
          />
        )
      }
      bottomRow={
        compactResponse ? (
          <QuestionActiveFieldsCompact {...activeFieldsCompactProps} />
        ) : (
          <QuestionActiveFieldsInteractive {...interactiveFieldsProps} />
        )
      }
      sx={{
        width: '100%',
      }}
    />
  );
};

export const Question = React.memo(QuestionWrapper, isEqual);
