/**
 * Calculates a percentage rounded down to the nearest whole number for display purposes
 *
 * @param numerator
 * @param denominator
 * @returns
 */
export const calculateWholePercentage = (numerator: number, denominator: number) =>
  Math.floor((numerator / denominator) * 100);

/**
 * Helper to prevent percentage numbers from getting too close to 0 or 100.
 * This is useful for small progress bars / circles when value is too close to 0 or 100 and it is hard to tell if there is any progress or if it is almost complete.
 *
 * @param percentage number between 0 and 100
 * @param padding how big of a gap to leave, recommended is 15% for small circles,  7% for linear progress
 */
export const percentageWithPadding = (percentage: number, padding = 15) => {
  const lowerBound = padding;
  const upperBound = 100 - padding;

  if (percentage === 0 || percentage === 100) return percentage;
  if (percentage < lowerBound) return lowerBound;
  if (percentage > upperBound) return upperBound;
  return percentage;
};
