import { CreateAssessmentFormStepperConfig } from '../../stepsConfig';
import { LocationsStep } from './LocationsStep';
import { LocationsStepStepperSubtitle } from './LocationsStepStepperSubtitle';

export const locationsStepConfig: CreateAssessmentFormStepperConfig = {
  title: 'Review location details',
  component: LocationsStep,
  stepper: {
    titleOverride: 'Select Locations',
    subtitle: LocationsStepStepperSubtitle,
  },
  validator: _ctx => {
    // TODO(v-rrodrigues)[CR-5282]: Implement the proper validations
    return Promise.resolve(true);
  },
};
