import { combineSx } from '@circadian-risk/front-end-utils';
import { Box, BoxProps, lighten } from '@mui/material';
import initials from 'initials';
import React from 'react';

import { FONT_SIZES } from './helpers';
import { ITEM_ICON_SIZES, ItemIconSizes } from './ItemIcon';

export interface SimpleItemIconProps {
  alt: string;
  src?: string;
  /**
   * @default 'ItemIconSizes.medium'
   */
  size?: ItemIconSizes;
  onClick?: (target: React.MouseEvent<HTMLDivElement>) => void;
}

/**
 * Similar to ItemIcon but with fewer props
 * Created this as ItemIcon was difficult to use inside AgGrid cell renderer due to internal useRef usage
 */
export const SimpleItemIcon: React.FC<SimpleItemIconProps> = ({ alt, onClick, src, size = ItemIconSizes.medium }) => {
  const contentText = initials(alt).toString().slice(0, 2);

  const imageSx: BoxProps['sx'] = theme => ({
    width: '70%',
    height: '70%',
    filter: `drop-shadow(${ITEM_ICON_SIZES[size].width}px 0 0px ${theme.palette.common.white})`,
    position: 'relative',
  });

  return (
    <Box
      onClick={onClick}
      sx={theme => {
        const isClickable = onClick != null;
        const primaryColor = theme.palette.common.white;
        const secondaryColor = theme.palette.primary.main;

        return {
          userSelect: 'none',
          backgroundColor: secondaryColor,
          color: primaryColor,
          cursor: isClickable ? 'pointer' : 'initial',
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          overflow: 'hidden',
          transition: theme.transitions.create('background-color'),
          fontSize: FONT_SIZES[size],
          ...ITEM_ICON_SIZES[size],
          minWidth: ITEM_ICON_SIZES[size].width,
          '&:hover': {
            backgroundColor: isClickable ? lighten(secondaryColor, 0.4) : secondaryColor,
          },
        };
      }}
    >
      <Box sx={combineSx(!!src && imageSx)}>
        {src ? (
          <img
            style={{
              left: `-${ITEM_ICON_SIZES[size].width}px`,
              position: 'absolute',
              top: 0,
            }}
            src={src}
            width="100%"
            height="100%"
            alt=""
          />
        ) : (
          contentText.toUpperCase()
        )}
      </Box>
    </Box>
  );
};
