import CachedIcon from '@mui/icons-material/Cached';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningIcon from '@mui/icons-material/Warning';
import { useTheme } from '@mui/material';
import React from 'react';

export type SaveStatus = 'inProgress' | 'success' | 'error';

export const SaveProgressIcon: React.FC<{ status: SaveStatus; className?: string }> = props => {
  const theme = useTheme();
  const { status, className } = props;
  return (
    <>
      {status === 'inProgress' && <CachedIcon className={className} htmlColor={theme.palette.grey[400]} />}
      {status === 'success' && <CheckCircleOutlineIcon className={className} htmlColor={theme.palette.success.main} />}
      {status === 'error' && <WarningIcon className={className} htmlColor={theme.palette.error.main} />}
    </>
  );
};
