import { DELETED_USER_MESSAGE } from '@circadian-risk/shared';

import { imageSrc } from '../fileSrc';

/**
 * Default Avatar for an unknown user
 */
export const DEFAULT_BY_USER = {
  alt: DELETED_USER_MESSAGE,
} as const;

/**
 * Given a userId and list of users, will output a valid `by` object for the user's Avatar (if found).
 *
 * Defaults to the text 'NA'
 */
export const avatarByUserId = <
  T extends {
    id: string;
    first_name: string | null;
    last_name: string | null;
    avatar: {
      filepath: string;
    } | null;
  },
>(
  userId: string | null,
  users: T[],
): { alt: string; image?: string } => {
  const user = users.find(u => u.id === userId);
  return user
    ? {
        alt: `${user.first_name} ${user.last_name}`,
        image: imageSrc(user.avatar?.filepath),
      }
    : DEFAULT_BY_USER;
};
