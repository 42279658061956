import { LocationWithIcon } from '@circadian-risk/presentational';
import { Button } from '@mui/material';
import React from 'react';

import { ariaLabels } from '../../ariaLabels';
import { useLocationWizardStore } from '../../locationWizard.store';
import { WizardLocation, WizardLocationLayer } from '../../types';
import { LocationViewer } from './LocationViewer';
import { NoCreationAllowedMessage } from './NoCreationAllowedMessage';

export interface ViewPanelProps {
  parentLocation: WizardLocation;
  parentLayer: WizardLocationLayer;
  tableLocations: WizardLocation[];
  layer: WizardLocationLayer | null;
  childLayer: WizardLocationLayer | null;
}

export const ViewPanel: React.FC<ViewPanelProps> = ({
  tableLocations,
  parentLocation,
  parentLayer,
  layer,
  childLayer,
}) => {
  const { changeMode, setActiveId, mode } = useLocationWizardStore(({ changeMode, setActiveId, mode }) => ({
    changeMode,
    setActiveId,
    mode,
  }));

  if (!layer) {
    return <NoCreationAllowedMessage />;
  }

  return (
    <LocationViewer
      canEdit={parentLocation.selectable}
      locationName={parentLocation.name}
      titleLayerName={parentLayer.name}
      childLayerName={layer.name}
      childChildLayerName={childLayer?.name ?? null}
      onClickAdd={(search: string) => changeMode({ type: 'adding', locationName: search })}
      onTitleEdit={() => changeMode({ type: 'editing', editingId: parentLocation.id })}
      tableData={tableLocations.map(l => ({
        name: l.name,
        locationLabel: (
          <LocationWithIcon
            variant="body1"
            locationName={l.name}
            layerName={l.layerName}
            onClick={() => setActiveId(l.id)}
          />
        ),
        count: l.childrenCount,
        actions: (
          <Button disabled={mode.type !== 'viewing'} onClick={() => changeMode({ type: 'editing', editingId: l.id })}>
            {ariaLabels.EDIT}
          </Button>
        ),
      }))}
    />
  );
};
