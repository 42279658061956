import { filterOutNullish } from '@circadian-risk/shared';
import { Typography } from '@mui/material';
import { sentenceCase } from 'change-case';
import React from 'react';

import { HStack, VStack } from '../Layouts';
import { AcceptedFlair, OptionForConsideration } from '../OptionForConsideration';
import { TaskActionProperty, TaskActionPropertyChip } from '../TaskActionPropertyIcon';
import { OptionSourceFlair, OptionSourceFlairProps } from './components/OptionSourceFlair';
import { SuggestedFlair } from './components/SuggestedFlair';

export interface TaskOptionForConsiderationProps {
  id: string | number;
  title: string;
  description: string | null;
  source: OptionSourceFlairProps['source'];
  properties: TaskActionProperty[];
  photoSrc?: string | null;
  isSuggested: boolean;
  isAccepted: boolean;
  actions?: React.ReactNode;
  defaultExpanded?: boolean;
}

export const TaskOptionForConsideration: React.FC<TaskOptionForConsiderationProps> = ({
  title,
  description,
  isAccepted,
  actions,
  source,
  properties,
  isSuggested,
  photoSrc,
  defaultExpanded,
}) => {
  const isCustomOption = source === 'customOption';

  return (
    <OptionForConsideration
      photoSrc={photoSrc ?? undefined}
      title={title}
      bottomAction={actions}
      flairs={[
        isSuggested && <SuggestedFlair key="suggested" />,
        isAccepted && <AcceptedFlair key="accepted" />,
        <OptionSourceFlair key={source} source={source} />,
      ]}
      defaultExpanded={defaultExpanded}
      moreInfo={
        isCustomOption ? undefined : (
          <VStack spacing={2} p={1}>
            <Typography color="text.secondary">{description ?? ''}</Typography>
            {filterOutNullish(properties).map(property => (
              <HStack key={property.type}>
                <Typography sx={{ minWidth: 130 }}>{sentenceCase(property.type)}</Typography>
                <TaskActionPropertyChip {...property} />
              </HStack>
            ))}
          </VStack>
        )
      }
    />
  );
};
