import { LoadingButton } from '@mui/lab';
import { ButtonProps } from '@mui/material';
import React from 'react';
import { useFormState } from 'react-hook-form';

import { useCircadianFormContext } from './CircadianFormContext';

export type SubmitButtonProps = Omit<ButtonProps, 'children'> & {
  /**
   * Set to true to allow the form to be submitted when no changes have been made.
   *
   * This is only helpful if you are providing some defaults that are ok to submit without changes.
   * @default false
   */
  skipDirtyCheck?: boolean;
};

/**
 * @deprecated Instead of importing the SubmitButton directly, useCircadianForm returns SubmitButton
 */
export const SubmitButton: React.FC<SubmitButtonProps> = buttonProps => {
  const { ariaLabels, disabled } = useCircadianFormContext<FormData>();

  const { isDirty, isSubmitting } = useFormState();

  const { skipDirtyCheck, ...props } = buttonProps;

  const isUnchangedOrBypassed = skipDirtyCheck ? false : !isDirty;

  return (
    <LoadingButton
      loading={isSubmitting}
      variant="contained"
      type="submit"
      disabled={isSubmitting || isUnchangedOrBypassed || disabled}
      {...props}
    >
      {ariaLabels.submit}
    </LoadingButton>
  );
};
