import { useControlledGridSelection, useGrid } from '@circadian-risk/data-grid';
import { ReviewLocationDetailsGrid, ReviewLocationDetailsRow } from '@circadian-risk/scheduled-assessments';
import { Box } from '@mui/material';

interface FormLocationSelectorProps {
  data: ReviewLocationDetailsRow[];
  disabled?: boolean;
  selectedLocations: string[];
  onSelect?: (locations: ReviewLocationDetailsRow[]) => void;
}

export const FormLocationSelector: React.FC<FormLocationSelectorProps> = ({
  disabled,
  selectedLocations,
  onSelect,
  data,
}) => {
  const { onGridReady, gridApi } = useGrid();
  const { onFirstDataRendered, onSelectionChanged, onRowDataUpdated } = useControlledGridSelection(
    gridApi,
    selectedLocations,
    onSelect,
  );

  return (
    <Box height="calc(100vh - 275px)" minHeight="600px">
      <ReviewLocationDetailsGrid
        rows={data}
        dataGridProps={{
          onGridReady,
          onFirstDataRendered,
          onSelectionChanged,
          onRowDataUpdated,
          rowSelection: 'multiple',
          context: {
            disabled,
          },
        }}
      />
    </Box>
  );
};
