import { VStack } from '@circadian-risk/presentational';
import { Typography } from '@mui/material';
import React from 'react';

export const AssignmentHeader: React.FC = () => {
  return (
    <VStack>
      <Typography variant="subtitle1">Assign or Subscribe Users</Typography>
      <Typography color="secondary" variant="caption">
        Assigned users are subscribed by default. Subscribers will only receive notification on assessment activity but
        are not responsible for completing assessments.
      </Typography>
    </VStack>
  );
};
