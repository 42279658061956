import { getThemeSpacing } from '@circadian-risk/presentational';
import { searchTree, sortLocationsByOrdinalAndName } from '@circadian-risk/shared';
import { alpha } from '@mui/material';
import { TreeItem } from '@mui/x-tree-view';
import React from 'react';

import { LocationTreeNode } from './LocationTree';
import { LocationTreeNodeIcon } from './LocationTreeNodeIcon';
import { LocationTreeNodeLabel } from './LocationTreeNodeLabel';

export const renderTree = (node: LocationTreeNode) => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return <LocationTreeItem key={node.id} {...node} />;
};

export const LocationTreeItem: React.FC<LocationTreeNode> = node => {
  if (!node.selectable && (!node.children || node.children.length === 0)) {
    return null;
  }

  if (!searchTree(node, n => n.selectable) && !node.selectable) {
    return null;
  }

  const sortedChildren = node.children ? sortLocationsByOrdinalAndName(node.children) : undefined;
  const renderedChildren =
    sortedChildren &&
    sortedChildren.map(n => {
      return renderTree(n);
    });

  return (
    <TreeItem
      key={node.id}
      nodeId={node.id}
      icon={<LocationTreeNodeIcon {...node} />}
      label={
        <LocationTreeNodeLabel
          {...node}
          sx={{
            backgroundColor: 'transparent !important',
            paddingLeft: 0,
            '&:hover': {
              backgroundColor: 'transparent',
            },
            overflow: 'hidden',
          }}
        />
      }
      sx={theme => ({
        '&.MuiTreeItem-content': {
          paddingLeft: `${node.depth * 8 + getThemeSpacing(theme, 1)}px`,
          paddingRight: -getThemeSpacing(theme, 0.5),
          '&:hover': {
            backgroundColor: alpha(theme.palette.secondary.main, 0.16),
          },
          transition: theme.transitions.create('background-color', {
            duration: theme.transitions.duration.shorter,
          }),
          cursor: 'initial',
          justifyContent: 'flex-end',
        },
      })}
      role="group"
      aria-label={node.name}
    >
      {renderedChildren}
    </TreeItem>
  );
};
