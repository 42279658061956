import { DateFormat, humanFormatDateTime } from '@circadian-risk/shared';
import dayjs from 'dayjs';
import { useFormContext } from 'react-hook-form';

import { CreateScheduledAssessmentFormSchema } from '../../../form';

export const useAssessmentStartDateWatch = () => {
  const { watch } = useFormContext<CreateScheduledAssessmentFormSchema>();
  const watchedDate = watch('startsAtDate');
  const watchedTime = watch('startsAtTime');
  const baseDate = dayjs(watchedDate).format(DateFormat.YEAR_MONTH_DAY);
  const startDate = dayjs(`${baseDate} ${watchedTime}`);

  return humanFormatDateTime(startDate.toDate());
};
