import { useSuspenseQuery } from '@apollo/client';
import { ComponentWithNoDataFallback, NoDataPlaceholderImage } from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { useOrganizationSessionStore } from '@circadian-risk/stores';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { Button, Typography } from '@mui/material';
import countBy from 'lodash/countBy';
import size from 'lodash/size';
import { generatePath, Link } from 'react-router-dom';

import noAssessmentDataImg from '../../assets/empty-assessment.svg';
import { GetAssessmentCardDataDocument } from '../graphql/typed-nodes';
import { ImportScenarioInfoBanner } from '../ImportScenarioInfoBanner';
import { CardInfoBanner } from '../layout/CardInfoBanner';
import { HomeLayoutCard } from '../layout/HomeLayoutCard';
import { AssessmentsChart, AssessmentsChartProps } from './AssessmentsChart';

export const homeSmartAssessmentRiskAriaLabels = {
  NoDataBanner: 'no assessment data banner',
  NoDataPlaceholder: 'no assessment placeholder',
  NoMatchingLocationsBanner: 'no matching locations banner',
  AddLocation: 'Add Location',
  CreateAssessment: 'Create Assessment',
};

export const NoAssessmentsToShowPlaceholder: React.FC = () => (
  <NoDataPlaceholderImage
    ariaLabel={homeSmartAssessmentRiskAriaLabels.NoDataPlaceholder}
    imgSrc={noAssessmentDataImg}
  />
);

const noDataFallback = <NoAssessmentsToShowPlaceholder />;

interface HomeSmartAssessmentCardProps {
  organizationId: string;
  onCreateAssessmentClick: () => void;
  onImportScenariosClick: () => void;
  onChartOptionClick: AssessmentsChartProps['onChartOptionClick'];
  onAddLocationClick: () => void;
}

export const HomeSmartAssessmentCard: React.FC<HomeSmartAssessmentCardProps> = ({
  organizationId,
  onCreateAssessmentClick,
  onImportScenariosClick,
  onChartOptionClick,
  onAddLocationClick,
}) => {
  const nodes = useOrganizationSessionStore(state => state.nodes);

  const { data } = useSuspenseQuery(GetAssessmentCardDataDocument, {
    variables: { organizationId },
  });

  const noDataState = size(data.scenario) === 0;
  const aggregates = countBy(data.v2_assessments, e => e.state);
  const hasMatchingNode = data.scenario.some(s => nodes.some(n => n.layer_id === s.relevantLayer?.layer_id));

  // If there are scenarios and assessments we should make sure that
  // there's a node with the scenario relevant layer
  const displayAddLocation = !noDataState && !hasMatchingNode;
  let banner: React.ReactNode;
  if (noDataState) {
    banner = (
      <ImportScenarioInfoBanner
        action="creating an Assessment"
        onClick={onImportScenariosClick}
        ariaLabel={homeSmartAssessmentRiskAriaLabels.NoDataBanner}
      />
    );
  } else if (displayAddLocation) {
    banner = (
      <CardInfoBanner
        content={
          "You don't have any locations that match scenarios in your Organization. Click the Add Location button on the Locations card"
        }
        ariaLabel={homeSmartAssessmentRiskAriaLabels.NoMatchingLocationsBanner}
      />
    );
  }

  const actionTitle = displayAddLocation
    ? homeSmartAssessmentRiskAriaLabels.AddLocation
    : homeSmartAssessmentRiskAriaLabels.CreateAssessment;
  const actionOnClick = displayAddLocation ? onAddLocationClick : onCreateAssessmentClick;
  const noDataOrMatchingNode = !hasMatchingNode || noDataState || Object.keys(aggregates).length === 0;

  return (
    <HomeLayoutCard
      title={
        <Typography variant="h5" component="h2">
          <Link to={generatePath(ROUTES.ASSESSMENTS, { organizationId })}>Assessments</Link>
        </Typography>
      }
      banner={banner}
      action={
        <Button
          disabled={noDataState}
          startIcon={<PostAddIcon />}
          onClick={actionOnClick}
          variant="outlined"
          color="primary"
        >
          {actionTitle}
        </Button>
      }
    >
      <ComponentWithNoDataFallback hasData={!noDataOrMatchingNode} fallback={noDataFallback}>
        <AssessmentsChart aggregates={aggregates} onChartOptionClick={onChartOptionClick} />
      </ComponentWithNoDataFallback>
    </HomeLayoutCard>
  );
};
