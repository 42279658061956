import React from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';

import { useCircadianFormContext } from '../../FormBlocks/CircadianFormContext';
import { PropertyNumericField, PropertyNumericFieldProps } from '../../PropertyFields';
import { FormField } from './types';
import { useErrorMessage } from './utils';

export type NumericFFProps<FormData> = FormField<FormData> &
  Omit<PropertyNumericFieldProps, 'control' | 'htmlName' | 'label'> & {
    /**
     * Overrides the label defined at ariaLabels
     * @default `ariaLabels[formKey]`
     */
    label?: string;
  };

export function NumericFF<FormData extends FieldValues>({ formKey, ...rest }: NumericFFProps<FormData>) {
  const { control, formState } = useFormContext<FormData>();
  const { ariaLabels, disabled } = useCircadianFormContext<FormData>();

  const errorMessage = useErrorMessage(formState.errors, formKey);

  return (
    <PropertyNumericField
      {...{
        disabled,
        control,
        errorMessage,
        ...rest,
      }}
      label={rest.label ?? ariaLabels[formKey]}
      htmlName={formKey}
    />
  );
}
