import { useCompleteFragment } from '@circadian-risk/apollo-utils';
import { useOrganizationId } from '@circadian-risk/stores';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import { useMemo } from 'react';

import { AssessmentTemplateData } from '../../../../components/shared-types';
import { AssessmentTemplatesFragmentDoc } from '../../../../graphql/typed-nodes';

export const useAssessmentTemplates = () => {
  const organizationId = useOrganizationId();
  const { assessmentTemplates } = useCompleteFragment({
    fragment: AssessmentTemplatesFragmentDoc,
    key: { id: organizationId },
  });

  // TODO(v-rrodrigues):[CR-5417]: re-use this across single and scheduled assessment xp
  const parsedTemplates = useMemo(() => {
    const deprecatedTemplateIds = flatten(
      assessmentTemplates.map(e => (e.allPreviousTemplates ?? []).map(pt => pt.id)),
    );

    return assessmentTemplates
      .filter(template => !deprecatedTemplateIds.includes(template.id))
      .map(template => {
        if (!template || isEmpty(template)) {
          return undefined;
        }

        const { scoredLayers, id, name } = template;
        if (!scoredLayers || !scoredLayers.length || scoredLayers.length > 1) {
          return undefined;
        }

        return {
          id,
          name,
          scoredLayerId: scoredLayers[0].id,
          scenarios: template.questionSets.map(qs => {
            const scenario = qs.questionSet!.scenario!;
            return {
              id: scenario.id,
              name: scenario.name,
              description: scenario.description,
            };
          }),
          tags: template.questionSets.flatMap(x => {
            return x.questionSet?.tags ?? [];
          }),
          totalAssessments: template.allAssessments.aggregate!.count,
          totalAssessmentsInProgress: template.inProgressAssessments.aggregate!.count,
          deprecatedTemplatesCount: size(template.allPreviousTemplates),
        };
      })
      .filter(Boolean) as AssessmentTemplateData[];
  }, [assessmentTemplates]);

  return parsedTemplates;
};
