import { humanFormatDateTime } from '@circadian-risk/shared';
import { Tooltip } from '@mui/material';
import React from 'react';

export const DateCellRendererWithTooltip: React.FC<{ value: string | Date }> = ({ value }) => {
  return (
    <Tooltip title={humanFormatDateTime(value, true)} placement={'top'}>
      <span>{humanFormatDateTime(value, false)}</span>
    </Tooltip>
  );
};
