import { Tooltip, TooltipProps } from '@mui/material';

/**
 * Useful so that even disabled buttons can have tooltips otherwise nothing might pop up and MUI will throw an error
 */
export const ButtonTooltip: React.FC<Omit<TooltipProps, 'title'> & { title?: TooltipProps['title'] }> = ({
  title,
  children,
  ...tooltipProps
}) => {
  if (!title) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <Tooltip title={title} {...tooltipProps}>
      <span>{children}</span>
    </Tooltip>
  );
};
