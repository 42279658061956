import { CircadianRoute, ROUTES } from '@circadian-risk/routes';
import { USER_ROLE } from '@circadian-risk/shared';
import { lazyLoadWithVersionCheck } from '@web-app/router/lazyLoadWithVersionCheck';
import React from 'react';

const ItemPage = lazyLoadWithVersionCheck(() => import('./ItemDetails/ItemPage'));

export const itemRoutes = (): CircadianRoute[] => {
  return [
    {
      path: ROUTES.ITEMS_DETAIL,
      component: () => <ItemPage />,
      isExact: true,
      pageTitle: 'Items | Item Details',
      allowRoles: [USER_ROLE.ORGANIZATION_ADMIN, USER_ROLE.LOCATION_MANAGER],
    },
  ];
};
