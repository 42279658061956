import { AgBarSeriesOptions } from 'ag-charts-community';

const TRUNCATE_THRESHOLD = 15;

export const truncateLabel = (label: string) => {
  return label.length > TRUNCATE_THRESHOLD ? `${label.substring(0, TRUNCATE_THRESHOLD).trimRight()}...` : label;
};

export type BarChartColumnLabelConfig = Partial<AgBarSeriesOptions['label']>;

export const barChartColumnLabelConfig: BarChartColumnLabelConfig = {
  formatter: ({ value }) => (value ? `${value.toFixed(0)}` : ''),
  color: 'white',
  fontWeight: 'bold',
};
