import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();
export const iconsContract = c.router(
  {
    upload: {
      method: 'POST',
      path: '/upload',
      description: 'Upload an icon with keywords',
      contentType: 'multipart/form-data',
      body: z.object({
        file: z.custom<File>(),
      }),
      query: z.object({
        keywords: z.array(z.string()).optional(),
      }),
      responses: {
        200: z.object({
          iconId: z.number(),
        }),
      },
    },
    updateKeywords: {
      method: 'PATCH',
      path: '/:id/keywords',
      pathParams: z.object({
        id: z.coerce.number(),
      }),
      description: 'Update keywords of an icon',
      body: z.object({
        keywords: z.array(z.string()),
      }),
      responses: {
        204: z.object({}),
      },
    },
    delete: {
      method: 'DELETE',
      path: '/:id',
      pathParams: z.object({
        id: z.coerce.number(),
      }),
      description: 'Deletes an icon from the library',
      body: z.object({}),
      responses: {
        200: z.object({}),
      },
    },
  },
  {
    pathPrefix: '/icons',
  },
);
