import { ColDef } from '@ag-grid-community/core';
import { CellRendererParams, ColDefOfType, DataGrid, DataGridSearch, useGrid } from '@circadian-risk/data-grid';
import { DataGridLayout } from '@circadian-risk/layout';
import { useMemo } from 'react';

import { AssignmentType, AssignOrSubscribeCellRenderer } from './AssignOrSubscribeCellRenderer';

type BaseRow = { id: string };
export type AssignmentTableProps<T extends BaseRow> = {
  rows: T[];
  colDefs: ColDefOfType<T>[];
  assignedIds: string[];
  subscribedIds: string[];
  /**
   * If the callback is not present, a label will be displayed instead
   * @param id
   * @param type
   * @returns
   */
  onSelect?: (id: string, type: AssignmentType) => void;
  /**
   * Indicates if the grid should utilize domLayout="autoHeight"
   * @default false
   */
  gridAutoHeight?: boolean;
};

export const AssignmentTable = <T extends BaseRow>({
  rows,
  colDefs,
  assignedIds,
  subscribedIds,
  onSelect,
  gridAutoHeight = false,
}: AssignmentTableProps<T>) => {
  const { onGridReady, gridApi } = useGrid();

  const colDefsWithTypeToggle = useMemo(() => {
    const assignOrSubscribeColDef: ColDef = {
      headerName: onSelect ? 'Assign or Subscribe' : 'Assigned or Subscribed',
      headerCheckboxSelection: false,
      // having a min width will allow the column to always be visible as the buttons can be resized based on the variant
      minWidth: 250,
      cellRenderer: ({ data }: CellRendererParams<T>) => {
        const isSubscribed = subscribedIds.includes(data.id);
        const isAssigned = assignedIds.includes(data.id);
        const selectedType = isSubscribed ? 'subscriber' : isAssigned ? 'assignee' : undefined;

        return (
          <AssignOrSubscribeCellRenderer
            selectedType={selectedType}
            onSelect={
              onSelect
                ? type => {
                    onSelect(data.id, type);
                  }
                : undefined
            }
          />
        );
      },
    };

    return [...colDefs, assignOrSubscribeColDef];
  }, [assignedIds, colDefs, onSelect, subscribedIds]);

  return (
    <DataGridLayout
      actions={<DataGridSearch gridApi={gridApi} />}
      grid={
        <DataGrid<T>
          disableDeepLinking
          disableDefaultFilterHook
          paginationAutoPageSize
          onGridReady={onGridReady}
          rowData={rows}
          columnDefs={colDefsWithTypeToggle}
          suppressRowClickSelection
          getRowId={({ data }) => (data as T).id}
          domLayout={gridAutoHeight ? 'autoHeight' : undefined}
        />
      }
      gridWrapperBoxProps={!gridAutoHeight ? { height: '500px' } : undefined}
    />
  );
};
