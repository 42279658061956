import { MenuWithTrigger } from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { USER_ROLE } from '@circadian-risk/shared';
import { useOrganizationSessionStore } from '@circadian-risk/stores';
import { Box, IconButtonProps, SxProps, Theme } from '@mui/material';
import includes from 'lodash/includes';
import { HiOutlineSwitchHorizontal } from 'react-icons/hi';
import { MdBusiness } from 'react-icons/md';
import { useLocation } from 'react-router-dom';

import { LinkWithOrgId } from '../../../NavLinkWithActiveOrgId';
import { AccountMenuProps } from './AccountMenu';

const menuButtonSx: SxProps<Theme> = theme => ({
  display: 'inline-block',
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  width: '100%',
});

const triggerButtonIconProps: Partial<IconButtonProps> = {
  sx: {
    borderRadius: '10px',
  },
};

export interface OrganizationMenuProps {
  organizationRecords: AccountMenuProps['organizationRecords'];
}

const OrganizationMenu: React.FC<OrganizationMenuProps> = ({ organizationRecords }) => {
  const organizationSwitcherVisible = Object.values(organizationRecords).length > 1;

  const [organizationId, organizationName, userRole] = useOrganizationSessionStore(state => [
    state.id,
    state.name,
    state.role,
  ]);

  const location = useLocation();

  if (
    includes([ROUTES.ORGANIZATION_USER_PROFILE, ROUTES.USER_PROFILE_EDIT, ROUTES.CHANGE_PASSWORD], location.pathname)
  ) {
    return null;
  }

  const organizationDisplay = (
    <Box data-testid="header-org-name" display="flex" alignItems="flex-end" justifyContent="space-between">
      <Box
        component="span"
        sx={theme => ({
          ...theme.typography.subtitle1,
          color: theme.palette.primary.contrastText,
          marginRight: theme.spacing(1),
        })}
      >
        {organizationName || 'No Organization'}
      </Box>
    </Box>
  );

  const orgMenuItems = [];

  if (organizationId && userRole === USER_ROLE.ORGANIZATION_ADMIN) {
    orgMenuItems.push(
      <LinkWithOrgId sx={menuButtonSx} href={ROUTES.ORGANIZATION_PROFILE}>
        <Box justifyContent={'center'} alignItems={'center'} display={'flex'}>
          <MdBusiness />
          <Box pl={1.5}>Organization Details</Box>
        </Box>
      </LinkWithOrgId>,
    );
  }

  if (organizationSwitcherVisible) {
    orgMenuItems.push(
      <LinkWithOrgId sx={menuButtonSx} href={ROUTES.ORGANIZATION_SWITCH}>
        <Box justifyContent={'center'} alignItems={'center'} display={'flex'}>
          <HiOutlineSwitchHorizontal />
          <Box pl={1.5}>Switch Organization</Box>
        </Box>
      </LinkWithOrgId>,
    );
  }

  if (!orgMenuItems.length) {
    return organizationDisplay;
  }

  return (
    <MenuWithTrigger
      menuId="organizationMenu"
      triggerButtonIcon={organizationDisplay}
      triggerButtonIconProps={triggerButtonIconProps}
      items={orgMenuItems.map(opt => ({
        component: opt,
        shouldCloseOnClick: true,
      }))}
      triggerAriaLabel="Toggle Organization Menu"
    />
  );
};

export default OrganizationMenu;
