import { SupportEmailLink } from '@circadian-risk/front-end-utils';
import { HStack, InfoIconWithTooltip, VStack } from '@circadian-risk/presentational';
import { LoadingButton } from '@mui/lab';
import { Box, Button, darken, DialogActions, DialogContent, Typography, useTheme } from '@mui/material';
import { useState } from 'react';

export const closeDialogContentsAriaLabels = {
  ClientSuccessContactInformation: 'client success contact information',
  OrgAdminOnlyCanReOpen: 'org admin re-open permission warning',
  ClientSuccessInfoTooltip: 'client success information tooltip',
  IncompleteItemsAlert: 'incomplete items alert',
  PendingFilesWarning: 'pending files warning',
};

const TooltipContent: React.FC<{ reopenAssessmentsEnabled: boolean }> = ({ reopenAssessmentsEnabled }) => (
  <Typography variant="body2">
    You can always make changes outside of assessments, but closed assessments represent a snapshot of compliance on a
    specific date.
    {!reopenAssessmentsEnabled && (
      <span aria-label={closeDialogContentsAriaLabels.ClientSuccessContactInformation}>
        If you need to re-open an assessment, please get in touch with a client success coordinator via{' '}
        <SupportEmailLink subject="Help Reopening Closed Assessment" />
      </span>
    )}
  </Typography>
);

interface CloseAssessmentDialogProps {
  canClose: boolean;
  hasPending?: boolean;
  PendingNotice: React.FC;
  canReopenAssessments: boolean;
  handleCancel: () => void;
  handleCloseAssessment: () => Promise<void>;
  IncompleteItemsNotice: React.FC;
}

export const CloseAssessmentDialog: React.FC<CloseAssessmentDialogProps> = ({
  canClose,
  canReopenAssessments,
  hasPending,
  handleCancel,
  handleCloseAssessment,
  PendingNotice,
  IncompleteItemsNotice,
}) => {
  const theme = useTheme();
  const [isClosingAssessment, setIsClosingAssessment] = useState(false);

  const handleCloseAssessmentClick = async () => {
    setIsClosingAssessment(true);
    await handleCloseAssessment();
    setIsClosingAssessment(false);
  };

  return (
    <>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={theme.spacing(2)}>
          {!hasPending && (
            <Box
              border={theme => `1px solid ${theme.palette.text.secondary}`}
              borderRadius={4}
              padding={2}
              bgcolor={theme => darken(theme.palette.background.paper, 0.03)}
            >
              <VStack textAlign={'center'}>
                <Typography variant="h5" mb={2}>
                  Closing an Assessment
                </Typography>

                <Typography variant="body1">Are you sure you want to close out this assessment?</Typography>
                <HStack spacing={0} justifyContent="center">
                  <Typography variant="body2">You will not be able to make any edits once closed.</Typography>
                  <InfoIconWithTooltip
                    ariaLabel={closeDialogContentsAriaLabels.ClientSuccessInfoTooltip}
                    tooltipContent={<TooltipContent {...{ reopenAssessmentsEnabled: canReopenAssessments }} />}
                    boxProps={{
                      sx: { padding: 0 },
                    }}
                  />
                </HStack>

                {canReopenAssessments && (
                  <Typography
                    aria-label={closeDialogContentsAriaLabels.OrgAdminOnlyCanReOpen}
                    variant="caption"
                    sx={{ fontStyle: 'italic' }}
                    mt={2}
                  >
                    Only an organization admin can re-open the assessment
                  </Typography>
                )}
              </VStack>
            </Box>
          )}

          {!canClose && <IncompleteItemsNotice />}

          {hasPending && <PendingNotice />}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <LoadingButton
          color="primary"
          variant="contained"
          disabled={!canClose || hasPending}
          onClick={handleCloseAssessmentClick}
          loading={isClosingAssessment}
        >
          Confirm Close Assessment
        </LoadingButton>
      </DialogActions>
    </>
  );
};
