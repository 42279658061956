import { AssessmentItemStatuses, debug } from '@circadian-risk/front-end-utils';
import { Enum_Item_Status_Enum } from '@circadian-risk/graphql-types';
import { TooltipStatusLabels } from '@circadian-risk/shared';
import { Box, Tooltip } from '@mui/material';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { BoxProps } from '@mui/system';
import { capitalCase } from 'change-case';
import capitalize from 'lodash/capitalize';
import React from 'react';

import { ResponseIcon, ResponseIconProps, ResponseType } from '../Icons';
import { CircularProgressWithBg } from '../Progress';

const colorSx = {
  deficient: (theme: Theme) => ({
    color: theme.palette.organizationColors.compliance.deficient,
  }),
  unknown: (theme: Theme) => ({
    color: theme.palette.organizationColors.compliance.unknown,
  }),
  accepted: (theme: Theme) => ({
    color: theme.palette.organizationColors.compliance.accepted,
  }),
  compliant: (theme: Theme) => ({
    color: theme.palette.organizationColors.compliance.compliant,
  }),
};

export interface ItemStatusProps {
  status: AssessmentItemStatuses;
  required?: boolean;
  percentComplete?: number;
  boxProps?: BoxProps;
  invertColors?: boolean;
}

const ProgressIndicator = ({
  percentComplete,
  label,
  invertColors,
}: {
  percentComplete: number;
  label: string;
  invertColors?: boolean;
}) => (
  <Tooltip title={label}>
    <Box p={0.25} aria-label={label}>
      <CircularProgressWithBg size={20} value={percentComplete} progressBgColorWhite={invertColors} />
    </Box>
  </Tooltip>
);

export const ItemStatus: React.FC<{ status: Enum_Item_Status_Enum; displayText?: boolean }> = ({
  status,
  displayText,
}) => {
  let icon: JSX.Element;
  let sx: SxProps<Theme> = {};

  switch (status) {
    case 'Compliant': {
      icon = <ResponseIcon response={'compliant'} />;
      sx = colorSx.compliant;
      break;
    }
    case 'Deficient': {
      icon = <ResponseIcon response={'deficient'} />;
      sx = colorSx.deficient;
      break;
    }
    case 'Needed': {
      icon = <ResponseIcon response={'missing deficient'} />;
      sx = colorSx.deficient;
      break;
    }
    default: {
      icon = <ResponseIcon response={'unknown'} />;
      break;
    }
  }

  if (displayText) {
    return (
      <Box display="flex" sx={sx} alignItems={'center'}>
        <Box display={'flex'}>{icon}</Box>
        <Box display={'flex'} ml={0.5}>
          {capitalize(status)}
        </Box>
      </Box>
    );
  }
  return (
    <Tooltip title={status}>
      <span>{icon}</span>
    </Tooltip>
  );
};

export const TooltipStatusIcon: React.FC<{
  status: TooltipStatusLabels;
  displayText?: boolean;
  iconProps?: Omit<ResponseIconProps, 'response'>;
}> = ({ status, displayText, iconProps }) => {
  let normalizedClassStatus: string = status;
  // convert present/missing deficient to deficient only
  if (status.includes('deficient')) {
    normalizedClassStatus = 'deficient';
  } else if (status === 'not observed' || status === 'not applicable') {
    normalizedClassStatus = 'unknown';
  }

  const statusLabel = capitalCase(status);

  let response = status as ResponseType;
  if (status === 'not applicable') {
    response = 'n_a';
  }
  if (status === 'not observed') {
    response = 'n_o';
  }

  const Icon = <ResponseIcon response={response} {...iconProps} />;

  if (displayText) {
    return (
      <Box display="flex" alignItems={'center'}>
        <Box display={'flex'}>{Icon}</Box>
        <Box
          display={'flex'}
          ml={1}
          sx={colorSx[normalizedClassStatus as 'deficient' | 'deficient' | 'unknown' | 'accepted' | 'compliant']}
        >
          {statusLabel}
        </Box>
      </Box>
    );
  }

  return (
    <Tooltip title={statusLabel}>
      <Box display="flex" alignItems="center">
        {Icon}
      </Box>
    </Tooltip>
  );
};

export const AssessmentItemStatus: React.FC<ItemStatusProps> = ({
  status,
  percentComplete,
  boxProps,
  invertColors,
}) => {
  const theme = useTheme();

  const responseIconProps = invertColors
    ? {
        htmlColor: theme.palette.getContrastText(theme.palette.primary.main),
      }
    : {};

  if (status === 'inProgress' && Number.isNaN(percentComplete)) {
    debug.extend('ItemStatus')(`Items must have a valid percentComplete if their status is \`in_progress\` 
      received: percentComplete: ${percentComplete} of type: ${typeof percentComplete}`);
  }

  switch (status) {
    case 'isCompliant': {
      const isCompliantLabel = '100% Compliant';
      return (
        <Tooltip title={isCompliantLabel}>
          <Box {...boxProps}>
            <ResponseIcon response={'compliant'} {...responseIconProps} />
          </Box>
        </Tooltip>
      );
    }
    case 'isMissing': {
      const isMissingLabel = 'Needed';
      return (
        <Tooltip title={isMissingLabel}>
          <Box {...boxProps}>
            <ResponseIcon response={'missing deficient'} {...responseIconProps} />
          </Box>
        </Tooltip>
      );
    }
    case 'isDeficient': {
      const isDeficientLabel = 'Deficient';
      return (
        <Tooltip title={isDeficientLabel}>
          <Box {...boxProps}>
            <ResponseIcon response={'deficient'} {...responseIconProps} />
          </Box>
        </Tooltip>
      );
    }
    case 'notStarted': {
      const notStartedLabel = 'Not Started';
      return (
        <Box>
          <ProgressIndicator percentComplete={0} label={notStartedLabel} invertColors={invertColors} />
        </Box>
      );
    }
    case 'inProgress': {
      const inProgressLabel = `${percentComplete}% complete`;
      return (
        <Box>
          <ProgressIndicator
            percentComplete={percentComplete ?? 0}
            label={inProgressLabel}
            invertColors={invertColors}
          />
        </Box>
      );
    }
    case 'notNeeded': {
      const notNeededLabel = 'Not Needed';
      return (
        <Tooltip title={notNeededLabel}>
          <Box {...boxProps}>
            <ResponseIcon response={'n_o'} {...responseIconProps} />
          </Box>
        </Tooltip>
      );
    }
  }
};
