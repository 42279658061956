import pouchDbAdapterIdb from 'pouchdb-adapter-idb';
import { addRxPlugin, createRxDatabase, removeRxDatabase, RxDatabase } from 'rxdb';
import { RxDBAttachmentsPlugin } from 'rxdb/plugins/attachments';
import { addPouchPlugin, getRxStoragePouch } from 'rxdb/plugins/pouchdb';

import { UploadStatus } from '../types';
import { FileEntityCollection, fileEntitySchema } from './schema';

export type DatabaseCollections = {
  fileentity: FileEntityCollection;
};

const DATABASE_NAME = 'file_manager';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Database = RxDatabase<DatabaseCollections, any, any>;

addPouchPlugin(pouchDbAdapterIdb);

addRxPlugin(RxDBAttachmentsPlugin);

export const diskSpaceAvailable = async (): Promise<StorageEstimate | undefined> => {
  if (navigator.storage && navigator.storage.estimate) {
    const quota = await navigator.storage.estimate();
    return quota;
  }
  return;
};

export const createDb = async (): Promise<Database> => {
  await diskSpaceAvailable();
  const databaseName = DATABASE_NAME;
  const storage = getRxStoragePouch('idb');
  const db = await createRxDatabase<DatabaseCollections>({
    name: databaseName,
    storage,
    ignoreDuplicate: true,
  });

  await db.addCollections({
    fileentity: {
      schema: fileEntitySchema,
      migrationStrategies: {
        1: oldDoc => {
          oldDoc.uploadEndpointInfo = {
            endpoint: 'test',
            entityId: 'test',
            entityType: 'test',
          };
          return oldDoc;
        },
        2: oldDoc => {
          oldDoc.uploadEndpointInfo.path = null;
          return oldDoc;
        },
        3: oldDoc => {
          if (oldDoc.uploadEndpointInfo) {
            oldDoc.uploadEndpointInfo.metadata = null;
          }
          return oldDoc;
        },
        4: oldDoc => {
          // Migrate entityType assessment-items -> assessment_items
          if (oldDoc.uploadEndpointInfo) {
            if (oldDoc.uploadEndpointInfo.entityType === 'assessment-items') {
              oldDoc.uploadEndpointInfo.entityType = 'assessment_items';
            }
          }
          return oldDoc;
        },
      },
    },
  });

  // Clear out any completed files
  const docs = await db.fileentity.find().where('status').eq(UploadStatus.Completed).exec();
  for (const doc of docs) {
    await doc.remove();
  }

  return db;
};

export const destroyDb = async () => {
  const databaseName = DATABASE_NAME;
  const storage = getRxStoragePouch('idb');
  await removeRxDatabase(databaseName, storage);
};
