import { Enum_Answer_Responses_Enum } from '@circadian-risk/client-graphql-hooks';
import { ExtractStrict } from '@circadian-risk/shared';

/**
 * Item Status Calculation
 */
export type AssessmentItemStatuses =
  | 'notStarted'
  | 'isMissing'
  | 'isDeficient'
  | 'isCompliant'
  | 'inProgress'
  | 'notNeeded';

type AssessmentItemStatusEnum = {
  [K in Capitalize<AssessmentItemStatuses>]: Uncapitalize<K>;
};

export type AnswerSemanticMeaning =
  | 'compliant'
  | 'deficient'
  | ExtractStrict<Enum_Answer_Responses_Enum, 'n_a' | 'n_o'>;

export const AssessmentItemStatusEnum: AssessmentItemStatusEnum = {
  NotStarted: 'notStarted',
  IsMissing: 'isMissing',
  InProgress: 'inProgress',
  IsCompliant: 'isCompliant',
  IsDeficient: 'isDeficient',
  NotNeeded: 'notNeeded',
};
