import { AnswerSemanticMeaning } from '@circadian-risk/front-end-utils';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { Box, Button, Divider, Menu, MenuItem, useTheme } from '@mui/material';
import React, { useRef, useState } from 'react';

import { BulkActionIcon, ResponseIcon } from '../Icons';

export type BulkEditOption = AnswerSemanticMeaning | 'clear' | 'ratifyRemaining' | 'reimportAll';

export interface BulkEditOptionProps {
  onSelect: (value: BulkEditOption) => void;
  disabled?: boolean;
}

interface BulkEditDropdownProps extends BulkEditOptionProps {
  /**
   * If an item is missing "Clear Data" and "Compliant response" are disabled
   */
  isItemMissing?: boolean;
  otherOptions?: React.ReactNode[];
}

export const BulkEditDropdown: React.FC<BulkEditDropdownProps> = ({
  onSelect,
  disabled,
  otherOptions,
  isItemMissing,
}) => {
  const theme = useTheme();
  const complianceColors = theme.palette.organizationColors.compliance;
  const bulkEditButtonRef = useRef<HTMLButtonElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuItemClick = (option: BulkEditOption) => {
    onSelect(option);
    setMenuOpen(false);
  };

  return (
    <>
      <Button
        startIcon={<BulkActionIcon />}
        onClick={() => setMenuOpen(true)}
        disabled={disabled}
        ref={bulkEditButtonRef}
        variant="outlined"
        size="small"
        sx={{ whiteSpace: 'nowrap' }}
      >
        Bulk Edit
      </Button>

      <Menu
        anchorEl={bulkEditButtonRef.current}
        keepMounted
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => handleMenuItemClick('compliant')} dense disabled={isItemMissing}>
          <ResponseIcon response="compliant" />
          <Box ml={1} color={complianceColors.compliant} component="span">
            Compliant
          </Box>
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('deficient')} dense>
          <ResponseIcon response="deficient" />
          <Box ml={1} color={complianceColors.deficient} component="span">
            Deficient
          </Box>
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('n_a')} dense>
          <ResponseIcon response="n_a" />
          <Box ml={1} color={complianceColors.unknown} component="span">
            Not Applicable
          </Box>
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('n_o')} dense>
          <ResponseIcon response="n_o" />
          <Box ml={1} color={complianceColors.unknown} component="span">
            Not Observed
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => handleMenuItemClick('clear')}
          dense
          sx={theme => ({
            color: theme.palette.warning.main,
          })}
          disabled={isItemMissing}
        >
          <DeleteSweepIcon />
          <Box ml={1} component="span" sx={{ textTransform: 'uppercase' }}>
            Clear Data
          </Box>
        </MenuItem>
        {otherOptions}
      </Menu>
    </>
  );
};
