import { AssessmentNotification, DueAfter } from '@circadian-risk/api-contract';
import { VStack } from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { ScheduleDetails } from '@circadian-risk/scheduled-assessments';
import { DateFormat, parseTsRange, USER_ROLE } from '@circadian-risk/shared';
import { useOrganizationId } from '@circadian-risk/stores';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { IconButton } from '@mui/material';
import { DetailsCard } from '@web-app/layouts/DetailsCard';
import { useHasRole } from '@web-app/lib/useHasRole';
import { convertFrequencyFragmentToZodSchema } from '@web-app/pages/scheduled-assessments/convertFrequencyFragmentToZodSchema';
import dayjs from 'dayjs';
import { generatePath, useHistory } from 'react-router-dom';

import { AssessmentProgress } from './AssessmentProgress';
import { useAssessmentDetailsData } from './hooks/useAssessmentDetailsData';

const dateFormat = `${DateFormat.MONTH_DAY_YEAR_RAW}, ${DateFormat.HOUR_AND_MINUTE_AM_PM}`;

type ScheduledAssessmentDetailsCardProps = {
  assessmentId: number;
};

export const ScheduledAssessmentDetailsCard: React.FC<ScheduledAssessmentDetailsCardProps> = ({ assessmentId }) => {
  const organizationId = useOrganizationId();
  const { open_range, assessmentTemplate, assessment_template_id, scheduleCadence } =
    useAssessmentDetailsData(assessmentId);
  const isOrgAdmin = useHasRole(USER_ROLE.ORGANIZATION_ADMIN);
  const history = useHistory();

  if (!scheduleCadence) {
    throw new Error('Schedule configuration is missing');
  }

  const { description, frequency: rawFrequency, notifications, due_after_time, due_after_unit } = scheduleCadence;

  const reminders = notifications.map<AssessmentNotification>(n => ({
    type: n.type,
    unit: n.unit,
    value: n.value,
    when: n.sequence,
    trigger: n.trigger,
  }));

  const [startDate, endDate] = parseTsRange(open_range);
  const frequency = convertFrequencyFragmentToZodSchema(rawFrequency);
  const dueAfter: DueAfter = {
    value: due_after_time,
    unit: due_after_unit,
  };

  const handleRedirectToScheduledPage = () => {
    history.push(generatePath(ROUTES.SCHEDULED_ASSESSMENTS, { organizationId }));
  };

  return (
    <DetailsCard
      title="Assessment Details"
      headerChildren={
        isOrgAdmin && (
          <IconButton>
            <PendingActionsIcon onClick={handleRedirectToScheduledPage} />
          </IconButton>
        )
      }
    >
      <VStack spacing={2}>
        <ScheduleDetails
          description={description}
          startsAt={dayjs(startDate).format(dateFormat)}
          dueAt={dayjs(endDate).format(dateFormat)}
          frequency={frequency}
          dueAfter={dueAfter}
          assessmentTemplate={{
            id: assessment_template_id,
            name: assessmentTemplate?.name ?? '',
          }}
          reminders={reminders}
        />
        <AssessmentProgress assessmentId={assessmentId} />
      </VStack>
    </DetailsCard>
  );
};
