import { InfoBox } from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { useOrganizationId } from '@circadian-risk/stores';
import { Box, Button, useTheme } from '@mui/material';
import React, { useCallback } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import { sectionInfoPreviewText } from './helpers';

export interface AssessmentSummaryInfoBoxProps {
  disabled?: boolean;
  assessmentId?: number;
  assessmentState?: string;
}

export const AssessmentSummaryInfoBox: React.FC<AssessmentSummaryInfoBoxProps> = ({
  disabled,
  assessmentId,
  assessmentState,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const organizationId = useOrganizationId();

  const handleOpenLocationDetailsClick = useCallback(() => {
    if (!assessmentId) {
      return;
    }
    const path = `${generatePath(ROUTES.ASSESSMENTS_EDIT, {
      organizationId,
      id: assessmentId,
    })}`;

    history.push(path);
  }, [history, organizationId, assessmentId]);

  return (
    <InfoBox disableTypographyWrapper>
      <Box aria-label={'assessment summary info'} display={'flex'} alignItems={'center'}>
        <Box flex={1} color={theme.palette.info.dark}>
          Information in this section comes from the Assessment Directly.&nbsp;
          {sectionInfoPreviewText}
        </Box>
        {assessmentState !== 'Complete' && (
          <Box ml={1}>
            <Button
              aria-label={'edit assessment'}
              variant="outlined"
              color="primary"
              onClick={handleOpenLocationDetailsClick}
              disabled={disabled}
            >
              <Box color={theme.palette.info.dark}>Edit Assessment</Box>
            </Button>
          </Box>
        )}
      </Box>
    </InfoBox>
  );
};
