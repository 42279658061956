import React from 'react';

import { CustomBlockInput } from '../CustomBlockInput';
import { OptionFromLibrary } from '../OptionFromLibrary';
import { OptionsSectionActions } from './OptionsSection';
import { ICustomOption, ILibraryOption } from './types';

export interface OptionProps {
  option: ICustomOption | ILibraryOption;
  actions?: Pick<OptionsSectionActions, 'onRemove' | 'onOptionChange' | 'onSaveToLib'>;
}

export const Option: React.FC<OptionProps> = ({ option, actions }) => {
  if (option.source === 'custom') {
    return (
      <CustomBlockInput
        key={option.id}
        value={option}
        actions={
          actions
            ? {
                onChange: actions.onOptionChange,
                onRemove: () => actions.onRemove(option),
                onSaveToLib: actions.onSaveToLib,
              }
            : undefined
        }
      />
    );
  } else {
    return (
      <OptionFromLibrary
        key={option.id}
        value={option}
        onRemove={actions?.onRemove ? () => actions.onRemove(option) : undefined}
      />
    );
  }
};
