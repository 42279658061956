const trimFunction = (value: string) => value.trim();

export const PAPA_PARSE_CONFIG = {
  header: true,
  newline: '\n',
  skipEmptyLines: true,

  // Addresses issue with Excel on Mac not quoting columns and having an extra space after "Zip "
  transformHeader: trimFunction,
  transform: trimFunction,
};
