import { useApiClient } from '@circadian-risk/api-client-provider';
import { useNotification } from '@circadian-risk/front-end-utils';
import { PageFormLayout } from '@circadian-risk/layout';
import React from 'react';

import { ResponsiveIpadDemo } from '../components/ResponsiveIpadDemo/ResponsiveIpadDemo';
import { SignUpForm } from './SignUpForm';
import { SignUpFormData } from './types';

export interface SignUpPageProps {
  eulaText: string;
  onRegistrationSuccess: () => void;
}

export const SignUpPage: React.FC<SignUpPageProps> = ({ eulaText, onRegistrationSuccess }) => {
  const { tsRestClient } = useApiClient();
  const { displayError } = useNotification();

  const handleSubmit = async (formData: SignUpFormData) => {
    const { password, consentsToMarketingCommunication, email, firstName, lastName } = formData;
    try {
      await tsRestClient.auth.register({
        body: {
          password,
          consentsToMarketingCommunication,
          email,
          firstName,
          lastName,
        },
      });

      onRegistrationSuccess();
    } catch (ex) {
      displayError(ex);
    }
  };

  return (
    <PageFormLayout
      leftContent={<ResponsiveIpadDemo />}
      form={<SignUpForm onSubmit={handleSubmit} eulaText={eulaText} />}
    />
  );
};
