import * as Sentry from '@sentry/react';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';

import { FilterOperator } from '..';

export const FILTER_TYPE_MISMATCH_ERROR = (value: unknown) =>
  `Type mismatch during filter pass, {type=${typeof value}, value=${value}}. \n The value should either be a string or a number` as const;

const warnTypeMismatch = (value: unknown) => {
  Sentry.captureMessage(FILTER_TYPE_MISMATCH_ERROR(value));
};

export const evaluateOperator = (
  operator: FilterOperator,
  filterValues: string[],
  value: unknown,
  targetValue?: unknown,
): boolean => {
  if (isString(value) || isNumber(value)) {
    const exists = filterValues.includes(String(value));
    return operator === 'noneOf' ? !exists : exists;
  }

  warnTypeMismatch(targetValue ?? value);
  return false;
};

export const processFilter = (operator: FilterOperator, targetValue: unknown, filterValues: string[]): boolean => {
  if (Array.isArray(targetValue)) {
    return targetValue.some(e => evaluateOperator(operator, filterValues, e, targetValue));
  }

  // Handle simple field such as "scenario_id"
  return evaluateOperator(operator, filterValues, targetValue);
};
