import { GridApi } from '@ag-grid-community/core';
import { FCC } from '@circadian-risk/front-end-utils';
import { HStack, RefreshButton, ResetTableButton } from '@circadian-risk/presentational';
import { Box, SxProps, Theme } from '@mui/material';
import React, { MutableRefObject, ReactPortal, useState } from 'react';
import ReactDOM from 'react-dom';
import { useMount } from 'react-use';

import { DataGridSearch } from './DataGridSearch';

export interface TableActionsProps {
  quickLinks?: React.ReactNode;
  gridApi: GridApi | null;
  onRefresh?: () => void;
  onReset?: () => void;
  /**
   * Forwards the disable to the underlying input
   * but still displays the input in a disabled state
   * @default false
   */
  disableSearch?: boolean;
  title?: React.ReactNode;
  /**
   * Hiding the search will automatically disable
   * @default false
   */
  hideSearch?: boolean;
  rootSx?: SxProps<Theme>;
  /**
   * AutoFocus on search when first rendered
   * @default false
   */
  autoFocusSearch?: boolean;
  /**
   * In very specific use cases, this allows the action to be rendered somewhere else
   * using `React.Portal`
   */
  containerPortal?: MutableRefObject<Element | null>;
  /**
   * This flag indicates whether we should wait until the containerPortal is defined (on mount)
   * @default false
   */
  usePortal?: boolean;
}

export const TableActions: FCC<TableActionsProps> = ({
  quickLinks,
  gridApi,
  onReset,
  onRefresh,
  children,
  autoFocusSearch,
  disableSearch = false,
  hideSearch = false,
  title,
  rootSx,
  containerPortal,
  usePortal,
}) => {
  const [portal, setPortal] = useState<ReactPortal | null>(null);

  const component = (
    <HStack
      aria-label={'table actions'}
      justifyContent={'flex-start'}
      alignContent={'center'}
      flexWrap="wrap"
      p={2}
      sx={rootSx}
    >
      {title}
      <Box display={'flex'} flex={1} gap={2} flexWrap="wrap">
        {!hideSearch && (
          <DataGridSearch autoFocus={autoFocusSearch} gridApi={gridApi} disabled={disableSearch || hideSearch} />
        )}
        {quickLinks}
      </Box>
      {onRefresh && <RefreshButton onClick={onRefresh} />}
      {onReset && <ResetTableButton onClick={onReset} />}
      {children}
    </HStack>
  );

  useMount(() => {
    if (usePortal && containerPortal?.current) {
      setPortal(ReactDOM.createPortal(component, containerPortal.current));
    }
  });

  if (usePortal) {
    return portal;
  }

  return component;
};
