import { VStack } from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { useOrganizationId, useOrganizationSessionStore } from '@circadian-risk/stores';
import { useFormContext } from 'react-hook-form';
import { generatePath, NavLink } from 'react-router-dom';

import { CreateScheduledAssessmentFormSchema } from '../../form';
import { useLocationFilters } from './hooks/useLocationFilters';

export const RootStepStepperSubtitle: React.FC = () => {
  const organizationId = useOrganizationId();
  const { watch } = useFormContext<CreateScheduledAssessmentFormSchema>();
  const locationFilterIdWatch = watch('locationFilterId');
  const selectedFilter = useLocationFilters().find(filter => filter.id === locationFilterIdWatch);
  const locationIdsWatch = watch('locationsIds');
  const selectedLocations = useOrganizationSessionStore(state => {
    return locationIdsWatch.map(id => state.nodesById[id]);
  });

  if (selectedFilter) {
    return (
      <NavLink
        to={generatePath(ROUTES.LOCATION_FILTERS_EDIT, { organizationId, filterId: selectedFilter.id })}
        target="_blank"
      >
        {selectedFilter.name}
      </NavLink>
    );
  }

  if (selectedLocations.length) {
    return (
      <VStack>
        {selectedLocations.map(sl => (
          <NavLink key={sl.id} to={generatePath(ROUTES.NODES_DETAIL, { organizationId, id: sl.id })} target="_blank">
            {sl.name}
          </NavLink>
        ))}
      </VStack>
    );
  }

  return null;
};
