import { combineSx } from '@circadian-risk/front-end-utils';
import { Modal, ModalProps } from '@mui/material';
import React from 'react';

export interface GenericModalProps extends ModalProps {
  open: boolean;
  onClose?: () => void;
}

const defaultModalSx: ModalProps['sx'] = {
  margin: 'auto',
  width: {
    xs: '100vw',
    sm: 'calc(100vw - 64px)',
  },
  height: {
    xs: '100vh',
    sm: 'calc(100vh - 64px)',
  },
  borderRadius: 2,
  overflow: 'hidden',
};

/**
 * A generic modal with a backdrop filter that is more flexible than the GenericDialog (i.e. doesn't have a title or actions)
 *
 * @important `GenericDialog` should be used for most cases, it is rare that you would need to use this directly
 */
export const GenericModal = React.forwardRef<HTMLDivElement, GenericModalProps>((props, ref) => {
  return (
    <Modal
      {...props}
      ref={ref}
      sx={combineSx(defaultModalSx, props?.sx)}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(3px)',
          },
        },
      }}
    />
  );
});
