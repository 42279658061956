import { Box, BoxProps, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useInterval } from 'react-use';

import { VStack } from '../..';
import { Dots } from './Dots';
import { IpadFrame } from './IpadFrame';

const changePictureEvery = 10; // seconds

interface CarouselImage {
  id: string;
  url: string;
  description?: string;
}

export interface IPadCarouselProps extends BoxProps {
  images: CarouselImage[];
  frameProps?: BoxProps;
}

export const IpadCarousel: React.FC<IPadCarouselProps> = ({ images, frameProps, ...boxProps }) => {
  const defaultDelay = changePictureEvery * 1000;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [delay, setDelay] = useState<number | null>(defaultDelay);
  const description = images[currentIndex].description;

  const handleNext = () => {
    setCurrentIndex(prev => (prev + 1) % images.length);
  };

  const stopAutoTransitions = () => setDelay(null);

  const handleNextClick = () => {
    handleNext();
    stopAutoTransitions();
  };

  const handleDotsClick = (index: number) => {
    setCurrentIndex(index);
    stopAutoTransitions();
  };

  useInterval(handleNext, delay);

  return (
    <VStack spacing={2} alignItems={'center'} {...boxProps}>
      <IpadFrame {...frameProps} sx={{ position: 'relative', ...frameProps?.sx }}>
        {images.map((image, index) => {
          const isVisible = currentIndex === index;
          return (
            <Box
              key={image.id}
              onClick={handleNextClick}
              sx={{
                cursor: 'pointer',
                position: 'absolute',
                opacity: isVisible ? 1 : 0,
                transition: theme => theme.transitions.create(['opacity']),
                backgroundSize: 'cover',
                backgroundPositionY: 'center',
                backgroundPositionX: 'center',
                backgroundImage: `url(${image.url})`,
                width: '100%',
                height: '100%',
              }}
            />
          );
        })}
      </IpadFrame>
      <Typography align="center">{description}</Typography>
      <Dots
        length={images.length}
        selected={currentIndex}
        onClick={handleDotsClick}
        display="flex"
        justifyContent="center"
      />
    </VStack>
  );
};
