import { Box, Typography } from '@mui/material';
import React from 'react';

interface ChoiceCardProps {
  icon: React.ReactNode;
  label: string;
}

const CARD_SIZE = 128;
const ICON_SIZE = 32;

/**
 * Handy card with Icon and Label that can be used with SelectableBox
 */
export const ChoiceCard: React.FC<ChoiceCardProps> = ({ icon, label }) => {
  return (
    <Box
      p={2}
      width={CARD_SIZE}
      height={CARD_SIZE}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      bgcolor="background.paper"
    >
      <Box fontSize={ICON_SIZE}>{icon}</Box>
      <Typography variant="h6">{label}</Typography>
    </Box>
  );
};
