import { DarkModeTwoTone, LightModeTwoTone, SettingsBrightnessTwoTone } from '@mui/icons-material';
import { ButtonBase, ClickAwayListener, styled, Tooltip } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

const Wrapper = styled('div', { shouldForwardProp: propName => propName !== 'expanded' })<{
  size: number;
  expanded: boolean;
}>`
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 8px;
  width: max-content;
  height: max-content;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  overflow: hidden;
  position: relative;
  z-index: ${({ theme, expanded }) => (expanded ? theme.zIndex.drawer + 20 : undefined)};
`;

const OptionList = styled('div')<{ direction: Direction }>(({ direction }) => ({
  display: 'flex',
  flexDirection: direction === 'row' ? 'row' : 'column',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
}));

const HoverHighlighter = styled('div')<{ size: number }>(({ size, theme }) => {
  const boxSize = size - 1;
  return {
    position: 'absolute',
    width: boxSize,
    height: boxSize,
    borderRadius: 8,
    backgroundColor: theme.palette.divider,
    pointerEvents: 'none',
    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    opacity: 1,
  };
});

const Option = styled(ButtonBase)<{ size: number }>(({ size }) => ({
  width: size - 2,
  height: size - 2,
  borderRadius: 8,
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  ':hover': {
    transform: 'scale(1.2)',
  },
  ':active': {
    transform: 'scale(1.5)',
  },
}));

const modes = [
  {
    name: 'light',
    icon: <LightModeTwoTone color="warning" />,
    tooltip: 'Set to light color mode',
  },
  {
    name: 'system',
    icon: <SettingsBrightnessTwoTone color="primary" />,
    tooltip: 'Use system color mode',
  },
  {
    name: 'dark',
    icon: <DarkModeTwoTone color="secondary" />,
    tooltip: 'Set to dark color mode',
  },
] as const;

type Direction = 'row' | 'column';
type Mode = (typeof modes)[number]['name'];

interface ColorModeSwitchProps {
  expendDirection?: Direction;
  size?: number;
  mode?: Mode;
  onChange?: (mode: Mode) => void;
}

export const ColorModeSwitch: React.FC<ColorModeSwitchProps> = ({
  expendDirection = 'column',
  size = 40,
  mode: currentMode = 'light',
  onChange,
}) => {
  const [expended, setExpended] = useState(false);

  const defaultModeIndex = useMemo(() => {
    return modes.findIndex(mode => mode.name === currentMode);
  }, [currentMode]);

  const [selectedOption, setSelectedOption] = useState<number>(defaultModeIndex);
  const [hoveredOption, setHoveredOption] = useState<number>(defaultModeIndex);

  useEffect(() => {
    if (defaultModeIndex) {
      setSelectedOption(defaultModeIndex);
    }
  }, [defaultModeIndex, onChange, selectedOption]);

  const changeSelectedOption = (newSelectedOption: number) => {
    if (!expended) {
      setExpended(true);
      return;
    }
    setSelectedOption(newSelectedOption);
    setExpended(false);
    if (onChange) {
      onChange(modes[newSelectedOption].name);
    }
  };

  const optionListStyle: React.CSSProperties = useMemo(() => {
    const x = expendDirection === 'row' ? 'marginLeft' : 'marginTop';
    const y = expendDirection === 'row' ? 'marginRight' : 'marginBottom';
    if (expended) {
      return {
        [x]: 0,
        [y]: 0,
      };
    }
    const clipSize = -(size - 2);
    return {
      [x]: selectedOption * clipSize,
      [y]: (2 - selectedOption) * clipSize,
    };
  }, [expendDirection, expended, selectedOption, size]);

  const hoverHighlighterStyle: React.CSSProperties = useMemo(() => {
    const x = expendDirection === 'row' ? 'left' : 'top';
    if (!expended) {
      return {
        [x]: 0,
        opacity: 0,
      };
    }
    return {
      [x]: hoveredOption * (size - 1),
    };
  }, [expendDirection, expended, hoveredOption, size]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setExpended(false);
        if (onChange) {
          onChange(modes[selectedOption].name);
        }
      }}
    >
      <Wrapper size={size} expanded={expended}>
        <HoverHighlighter size={size} style={hoverHighlighterStyle} />
        <OptionList direction={expendDirection} style={optionListStyle}>
          {modes.map((mode, index) => (
            <Tooltip
              title={expended ? mode.tooltip : 'Change color mode'}
              key={mode.name}
              onOpen={() => setHoveredOption(index)}
              placement={expendDirection === 'row' ? 'bottom' : 'right'}
            >
              <Option size={size} aria-label={mode.tooltip} onClick={() => changeSelectedOption(index)} disableRipple>
                {mode.icon}
              </Option>
            </Tooltip>
          ))}
        </OptionList>
      </Wrapper>
    </ClickAwayListener>
  );
};
