import BeenhereIcon from '@mui/icons-material/Beenhere';
import FilterListIcon from '@mui/icons-material/FilterList';
import LockIcon from '@mui/icons-material/Lock';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Theme, Typography, useTheme } from '@mui/material';
import pluralize from 'pluralize';
import React from 'react';

import { HStack, VStack } from '../..';
import { InfoIconWithTooltip } from '../InfoIconWithTooltip';
import { Bold } from '../Text';
import { TouchFriendlyTooltip } from '../Tooltips';
import { ControlPoints } from './ControlPoints';

export type ItemAnswerAggregateStates = 'untriaged' | 'deficient' | 'compliant' | 'accepted';

export type ItemAnswerAggregatesCardProps = {
  state: ItemAnswerAggregateStates;
  deficiencyCount: number | null;
  sumRelevantControlPoints: number;
  filterActiveCount?: number;
  criticalityCount: {
    high: number;
    medium: number;
    low: number;
  };
};

const infoByState: Record<
  ItemAnswerAggregateStates,
  {
    description: string;
    icon: React.ReactNode;
    bgColor: 'error' | 'warning' | 'primary';
    textColor: (theme: Theme) => string;
    tooltip: string;
  }
> = {
  untriaged: {
    description: 'Deficiencies to triage',
    icon: <WarningIcon color="error" />,
    bgColor: 'error',
    textColor: theme => theme.palette.error.main,
    tooltip:
      'Untriaged deficiencies represent problems that have not yet had a remediation task associated with them. In order to earn the associated control points, create and complete tasks that correct the deficiencies',
  },
  compliant: {
    description: 'Compliant Control points',
    icon: <BeenhereIcon color="primary" />,
    bgColor: 'primary',
    textColor: theme => theme.palette.primary.main,
    tooltip:
      'Compliant responses award control points which help to improve the residual risk of scenarios for your locations',
  },
  deficient: {
    description: 'Deficiencies',
    icon: <WarningIcon color="error" />,
    bgColor: 'error',
    textColor: theme => theme.palette.error.main,
    tooltip: 'In order to earn the associated control points, create and complete tasks that correct the deficiencies',
  },
  accepted: {
    description: 'Accepted Deficiencies',
    icon: <LockIcon color="warning" />,
    bgColor: 'warning',
    textColor: theme => theme.palette.warning.main,
    tooltip:
      'Accepting the risk for deficiencies means that your are acknowledging a problem but deciding not to correct it at this time. Control points cannot be earned for these responses. However, you can determine to unaccept and correct them in the future',
  },
};

export const filterCountLabel = (count: number) => `With ${count} ${pluralize('Filter', count)} applied`;
export const deficientTriageCardAriaLabels = {
  ActiveFiltersIcon: 'active filters applied',
};

export const ItemAnswerAggregatesCard: React.FC<ItemAnswerAggregatesCardProps> = ({
  state,
  deficiencyCount,
  sumRelevantControlPoints,
  filterActiveCount,
  criticalityCount,
}) => {
  const theme = useTheme();

  const info = infoByState[state];

  const isCompliant = state === 'compliant';

  const leftTextColor = info.textColor(theme);
  const infoIcon = <InfoIconWithTooltip tooltipContent={info.tooltip} />;
  const bgColor = theme.palette[info.bgColor];
  const displayFilterCount = Boolean(filterActiveCount);

  return (
    <Box width="max-content" p={1} bgcolor={bgColor.background} border={`1px solid ${bgColor.light}`} borderRadius={2}>
      <HStack>
        <VStack spacing={0}>
          <HStack>
            <Box display="flex">{info.icon}</Box>
            <Typography variant="h5" color={leftTextColor}>
              <Bold>{isCompliant ? sumRelevantControlPoints : deficiencyCount}</Bold>
            </Typography>
            {displayFilterCount && (
              <TouchFriendlyTooltip
                tooltipContent={filterCountLabel(filterActiveCount ?? 0)}
                ariaLabel={deficientTriageCardAriaLabels.ActiveFiltersIcon}
                tooltipProps={{ placement: 'top' }}
              >
                <FilterListIcon htmlColor={leftTextColor} />
              </TouchFriendlyTooltip>
            )}
            {isCompliant && infoIcon}
          </HStack>
          <Typography variant="body2" color={leftTextColor} noWrap>
            {info.description}
          </Typography>
        </VStack>

        {!isCompliant && (
          <>
            <Box mx={1}>
              <Typography variant="h5" color={theme => theme.palette.text.secondary}>
                =
              </Typography>
            </Box>

            <VStack spacing={0}>
              <ControlPoints
                flex={1}
                total={sumRelevantControlPoints}
                criticalityCounts={criticalityCount}
                tooltip={info.tooltip}
              />
              <Typography variant="body2" color={theme => theme.palette.text.secondary} noWrap>
                {state === 'accepted' ? 'Control points lost' : 'Potential Control Points'}
              </Typography>
            </VStack>
          </>
        )}
      </HStack>
    </Box>
  );
};
