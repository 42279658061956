import { Node_Mapbox_Maps } from '@circadian-risk/client-graphql-hooks';
import { isPlacingExistingItem, PlacementInteractionData } from '@circadian-risk/front-end-utils';
import { Coordinates } from '@circadian-risk/shared';
import { useCallback, useEffect } from 'react';
import { MapRef } from 'react-map-gl';

import {
  DEFAULT_MAPBOX_ZOOM,
  Map,
  MarkerPoint,
  OnDeleteItem,
  OnItemSave,
  OnUpdateItem,
  PopoverMode,
  useMapboxStore,
  useMapStyles,
} from '../..';

export const ASSESSMENT_MAP_ID = 'assessmentMap';

// PR is for PushOrReplace string types
interface AssessmentProps<PR extends string> {
  isReadOnly: boolean;
  //   usePerformAssessmentDataStore
  onUpdateItem: OnUpdateItem;
  onDeleteItem: OnDeleteItem;
  onItemSave: OnItemSave;
  setPlacementInteractionData: (data: PlacementInteractionData | undefined) => void;
  placementInteractionData: PlacementInteractionData | undefined;
  popoverMode: PopoverMode;
  setPopoverMode: (mode: PopoverMode) => void;
  //   useAssessmentRoutingStore
  setDetailsActive: (active: boolean) => void;
  selectedItemId: string | null | undefined;
  setSelectedItemId: (
    id: string | null | undefined,
    pushOrReplace?: PR | undefined,
    detailsActive?: boolean | undefined,
  ) => void;
  panToMarkerCoordinates?: (mapRef: MapRef, coordinates: Coordinates) => void;
  pointsList: MarkerPoint<any>[]; // MarkerPoint<MapItem>[];
}

type GuaranteedProperties<T extends Record<string, unknown>> = {
  [K in keyof T]: Exclude<T[K], null>;
};

export type AssessmentMapProps<PR extends string> = GuaranteedProperties<
  Pick<Node_Mapbox_Maps, 'style_url' | 'max_bounds'>
> &
  AssessmentProps<PR>;

export const AssessmentMap = <PR extends string>({
  max_bounds,
  style_url,
  isReadOnly,
  onDeleteItem,
  onItemSave,
  onUpdateItem,
  placementInteractionData,
  popoverMode,
  setPlacementInteractionData,
  setPopoverMode,
  selectedItemId,
  setDetailsActive,
  setSelectedItemId,
  pointsList,
  panToMarkerCoordinates,
}: AssessmentMapProps<PR>) => {
  const mapRef = useMapboxStore(state => state.refs.get(ASSESSMENT_MAP_ID));
  const mapStyles = useMapStyles();

  const defaultPanToMarkerCoordinates = useCallback(
    (coords: [number, number]) => {
      if (panToMarkerCoordinates && mapRef) {
        panToMarkerCoordinates(mapRef, coords);
        return;
      }
      mapRef?.panTo(coords, { duration: 1500, essential: true, easing: t => t });
    },
    [mapRef, panToMarkerCoordinates],
  );

  // Pans the camera to the marker coordinates when an item is selected
  useEffect(() => {
    if (!selectedItemId || pointsList.length === 0) {
      return;
    }

    const coords = pointsList.find(e => e.id === selectedItemId)?.coordinates;
    if (coords) {
      defaultPanToMarkerCoordinates(coords);
    }
  }, [selectedItemId, pointsList, panToMarkerCoordinates, defaultPanToMarkerCoordinates]);

  const selectedPlacingItemCategoryId =
    placementInteractionData && !isPlacingExistingItem(placementInteractionData)
      ? placementInteractionData.itemCategoryId
      : undefined;

  const selectedPlacingItemId =
    placementInteractionData && isPlacingExistingItem(placementInteractionData)
      ? placementInteractionData.itemId
      : undefined;

  const onMapClickOrTap = useCallback(
    (coords: Coordinates) => {
      if (selectedPlacingItemCategoryId) {
        void onItemSave({ coordinates: { x: coords[0], y: coords[1] } }, selectedPlacingItemCategoryId);
        setPlacementInteractionData(undefined);
        setDetailsActive(true);
      }

      if (selectedPlacingItemId) {
        onUpdateItem(selectedPlacingItemId, { coordinates: { x: coords[0], y: coords[1] } });
        setDetailsActive(true);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [panToMarkerCoordinates, selectedPlacingItemCategoryId, selectedPlacingItemId, setPlacementInteractionData],
  );

  return (
    <Map
      mapId={ASSESSMENT_MAP_ID}
      initialActivePoint={selectedItemId}
      selectedId={selectedItemId}
      setSelectedId={id => {
        // TODO(miking-the-viking): investigate why we need to manually force blur on active element [CR-4974]
        // clicking on a map marker should natively cause the activeElement to lose focus.
        // Refer to https://linear.app/circadian-risk/issue/CR-4971/location-description-and-notes-sections-for-items-dont-apply-updates
        // to see why this was introduced.
        (document.activeElement as HTMLElement)?.blur();
        setSelectedItemId(id);
      }}
      markerPoints={pointsList}
      mapStyle={style_url}
      maxBounds={max_bounds as [[number, number], [number, number]]}
      initialViewState={{ zoom: DEFAULT_MAPBOX_ZOOM }}
      panToMarkerCoordinates={defaultPanToMarkerCoordinates}
      onItemSave={onItemSave}
      onUpdateItem={onUpdateItem}
      onDeleteItem={onDeleteItem}
      onMapClickOrTap={onMapClickOrTap}
      setDetailsActive={setDetailsActive}
      setMapActive={setDetailsActive}
      isReadOnly={isReadOnly}
      popoverMode={popoverMode}
      setPopoverMode={setPopoverMode}
      mapStyles={mapStyles}
    />
  );
};
