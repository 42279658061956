/**
 * More documentation about Circadian Theme is here:
 * https://docs.google.com/document/d/1QLRwSyXn3jkVDvuEE0yaWHZD9icaXieSPowz3Y4cQIY/edit#
 *
 * INFO:    This is currently WIP and need some TLC from Vanya.
 *          Ask him if you have any questions.
 *
 * WARNING: When choosing to use a color in our app, try to think of its
 *          function rather than its absolute color.
 *
 *          It is important as most colors are dynamically calculated based on two factors:
 *            1. Is it dark or light mode?
 *            2. Does organization have overrides for this color?
 *
 *          Examples:
 *            1. use `palette.divider` vs `palette.grey[500]`)
 *            2. use `palette.text.primary` vs `#000 or black`)
 *            3. use `palette.organizationColor.risk.low` vs `palette.error.main or red`)
 *
 * TIP:     It is helpful to use a color highlighting plugin
 *          when editing this file (e.g. Colorize or Flutter Color)
 */
import { TaskStatus } from '@circadian-risk/front-end-utils';
import { createTheme, darken, lighten, Theme, ThemeOptions } from '@mui/material/styles';

export interface CircadianColors {
  criticality: Record<'1' | '2' | '3', string>;
  risk: Record<'low' | 'medium' | 'high', string>;
  compliance: Record<'compliant' | 'deficient' | 'unknown' | 'accepted', string>;
  task: Record<TaskStatus, string>;
}

interface Utils {
  /** If true, primary color is dark enough to need white text or logos to be legible */
  isPrimaryColorDark: boolean;
  /** If dark mode is active, returns a slightly lighter color */
  getDynamicColor: (color: string) => string;
  /** Return a good complimentary background for a given color */
  getBackgroundColor: (color: string) => string;
  /** Return an adjusted color suitable for outlines and borders against paper */
  getOutlineColor: (color: string) => string;
  adjustColor: (color: string, dark: number, light: number) => string;
  /** Return complimentary hover color that should work for dark and light colors */
  getHoverColor: (color: string) => string;
}
declare module '@mui/material/styles/createPalette' {
  interface PaletteColor {
    background: string;
  }
  interface SimplePaletteColorOptions {
    background?: string;
  }

  interface Palette {
    organizationColors: CircadianColors;
    auxColor: string;
    utils: Utils;
  }
  interface PaletteOptions {
    organizationColors?: CircadianColors;
    auxColor?: string;
    utils?: Utils;
  }
}

/**
 * Humans struggle to read long line of text, usually more than 80 characters is too long,
 * use this to contain the width of different components
 */
export const MAX_WIDTH_READABLE_CONTENT = 800;

/**
 * Returns a numeric representation of spacing, since MUI v5 theme.spacing returns a string with px
 *
 * @see https://github.com/mui/material-ui/issues/29086
 * @param theme
 * @param value
 */
export const getThemeSpacing = (theme: Theme, value: number) => Number(theme.spacing(value).slice(0, -2));

const defaultSpacing = 8;

export const HEADER_HEIGHT = 64;
export const FOOTER_HEIGHT = 42;

// hex values for commonly used colors (not dynamic, please don't export)
const grey = '#767676';
const info = '#0076D3';
const error = '#EA1717';
const warning = '#FF9800';
const success = '#449F47';
const circadianBlue = '#0B3662';
const black = '#0A0A0A';
const white = '#fff';
const defaultBackground = '#ECEFF1';

const background = {
  paper: white,
  default: defaultBackground,
  darker: darken(defaultBackground, 0.1),
  darkest: darken(defaultBackground, 0.2),
};

// these are static values that won't change based on user preferences (i.e. Dark Mode, Device Dimensions)
// NOTE: if you are setting colors, please do so inside createCircadianTheme
const defaultThemeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: ['Open Sans', 'Saira', 'sans-serif'].join(','),
    h1: { fontFamily: 'Saira, sans-serif' },
    h2: { fontFamily: 'Saira, sans-serif' },
    h3: { fontFamily: 'Saira, sans-serif' },
    h4: { fontFamily: 'Saira, sans-serif' },
    h5: { fontFamily: 'Saira, sans-serif' },
    h6: { fontFamily: 'Saira, sans-serif' },
    button: { fontFamily: 'Saira, sans-serif' },
  },
  /**
   * for each override, please link here to a discussion or explain why it is needed
   *
   * whenver you notice that you need to change the styles of a MUI componet,
   * see if this is something that is needed to be applied globally so that all components look the same
   */
  components: {
    MuiButton: {
      styleOverrides: { root: { textDecoration: 'none', '&:hover': { textDecoration: 'none' } } },
    },
    // list icons by default take up way too much space
    MuiListItemIcon: {
      styleOverrides: { root: { minWidth: defaultSpacing * 5 } },
    },
    MuiCardHeader: {
      styleOverrides: { action: { padding: defaultSpacing * 0.5 } },
    },
    /**
     * Default value is set to false from MUI but its slower as stated in the documentation
     * therefore we enable globally to disable SSR in useMediaQuery so it just renders once
     *
     * @see https://mui.com/components/use-media-query/#client-side-only-rendering
     */
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    /**
     * There is a known bug that clips labels of inputs
     * remove this once the issue is fixed: https://github.com/mui/material-ui/issues/31185
     */
    MuiDialogContent: {
      styleOverrides: { root: { paddingTop: `${defaultSpacing}px !important` } },
    },
  },
};

export interface CreateCircadianThemeProps {
  variant: 'light' | 'dark';
  userRiskColors?: Partial<CircadianColors['risk']>;
  userCriticalityColors?: Partial<CircadianColors['criticality']>;
  userComplianceColors?: Partial<CircadianColors['compliance']>;
  customPrimaryColor?: string;
}

export const createCircadianTheme = (props?: CreateCircadianThemeProps) => {
  const { variant, userRiskColors, userCriticalityColors, userComplianceColors, customPrimaryColor } = props ?? {
    variant: 'light',
  };

  const defaultTheme = createTheme({ palette: { mode: variant } });
  const isDarkColor = (color: string) => defaultTheme.palette.getContrastText(color) === white;

  const isDarkMode = variant === 'dark';
  const primaryColor = customPrimaryColor ?? info;
  const isPrimaryColorDark = isDarkColor(primaryColor);

  // helper functions, revisit when dark mode discussion happens
  const getDynamicColor = (color: string) => (isDarkMode ? lighten(color, 0.2) : color);
  const getBackgroundColor = (color: string) => (isDarkMode ? darken(color, 0.8) : lighten(color, 0.9));
  const getOutlineColor = (color: string) => (isDarkMode ? lighten(color, 0.7) : darken(color, 0.6));
  const getHoverColor = (color: string) => (isDarkColor(color) ? lighten(color, 0.15) : darken(color, 0.1));
  const adjustColor = (color: string, dark: number, light: number) =>
    isDarkMode ? darken(color, dark) : lighten(color, light);

  const utils = {
    isPrimaryColorDark,
    getDynamicColor,
    getBackgroundColor,
    getOutlineColor,
    getHoverColor,
    adjustColor,
  };

  // calculating dynamic colors based on palette type (D = dynamic)
  const greyD = getDynamicColor(grey);
  const infoD = getDynamicColor(info);
  const primaryD = getDynamicColor(primaryColor);
  const errorD = getDynamicColor(error);
  const warningD = getDynamicColor(warning);
  const successD = getDynamicColor(success);
  const textPrimaryD = isDarkMode ? 'rgba(255,255,255, 0.75)' : 'rgba(0, 0, 0, 0.87)';
  const textSecondaryD = isDarkMode ? 'rgba(255,255,255, 0.35)' : 'rgba(0, 0, 0, 0.54)';

  // organization specific colors (whitelabeling can go here)
  const riskColors: CircadianColors['risk'] = { low: infoD, medium: warningD, high: errorD, ...userRiskColors };
  const criticalityColors: CircadianColors['criticality'] = {
    1: greyD,
    2: warningD,
    3: errorD,
    ...userCriticalityColors,
  };
  const complianceColors: CircadianColors['compliance'] = {
    compliant: infoD,
    deficient: errorD,
    unknown: greyD,
    accepted: warningD,
    ...userComplianceColors,
  };

  // TODO(iprokopovich)[CR-3503]: uncomment when we are ready to use new statuses
  const taskColors: CircadianColors['task'] = {
    NotStarted: greyD,
    // Blocked: warningD,
    Completed: successD,
    // Overdue: errorD,
    // Validate: getDynamicColor(purple[500]),
    // Cancel: greyD,
    // Approve: getDynamicColor(cyan[500]),
    InProgress: primaryD,
  };

  return createTheme({
    ...defaultThemeOptions,
    palette: {
      mode: variant,
      common: { black, white },
      utils,
      primary: {
        main: primaryD,
        background: getBackgroundColor(primaryColor),
      },
      secondary: {
        main: greyD,
        background: getBackgroundColor(grey),
      },
      warning: {
        main: warningD,
        background: getBackgroundColor(warning),
      },
      error: {
        main: errorD,
        background: getBackgroundColor(error),
      },
      // TODO(iprokopovich)[CR-3636]: replace info with new metallic color
      info: {
        main: infoD,
        background: getBackgroundColor(info),
      },
      grey: { 500: greyD },
      success: {
        main: successD,
        background: getBackgroundColor(success),
      },
      background: {
        // 0.8 and 0.7 were just nice looking values when walking through dark mode with George
        default: isDarkMode ? darken(primaryD, 0.8) : background.default,
        paper: isDarkMode ? darken(primaryD, 0.7) : background.paper,
      },
      organizationColors: {
        risk: riskColors,
        criticality: criticalityColors,
        compliance: complianceColors,
        task: taskColors,
      },
      auxColor: customPrimaryColor ? darken(customPrimaryColor, 0.7) : circadianBlue,
      text: {
        primary: textPrimaryD,
        secondary: textSecondaryD,
      },
    },
    components: {
      ...defaultThemeOptions.components,
      MuiLink: {
        styleOverrides: {
          root: { color: primaryD },
        },
      },
      // this is used to remove blue link styles from the main side drawer
      MuiList: {
        styleOverrides: {
          root: {
            '& a, & a:visited': {
              color: textPrimaryD,
              display: 'inline-flex',
              width: '100%',
              '&:hover': {
                backgroundColor: defaultTheme.palette.action.hover,
                textDecoration: 'none',
              },
            },
          },
        },
      },
      // Miles and Vanya discussed that we should not show shadow for our buttons
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
      },
      MuiDivider: {
        styleOverrides: {
          textAlignLeft: {
            ':before': {
              width: 0,
            },
            '.MuiDivider-wrapper': {
              paddingLeft: 0,
            },
          },
          textAlignRight: {
            ':after': {
              width: 0,
            },
            '.MuiDivider-wrapper': {
              paddingRight: 0,
            },
          },
        },
      },
      // these are global css overrides that will take effect across cosmos and application
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            a: {
              color: primaryD,
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              },
              '&:visited, &:active': {
                color: primaryD,
              },
            },
          },
        },
      },
    },
  });
};
