import { CloseAssessmentDialog, closeDialogContentsAriaLabels } from '@circadian-risk/assessment-components';
import { UploadStatus, useFileManagerStore } from '@circadian-risk/file-manager';
import { HStack, VStack } from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { useIsFeatureFlagEnabledForOrg } from '@circadian-risk/stores';
import { WarningOutlined } from '@mui/icons-material';
import { Box, Button, styled, Typography } from '@mui/material';
import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import {
  ASSESSMENT_ITEMS_QUICK_FILTER_KEY,
  AssessmentItemAnswerQuickFilter,
} from '../smart-components/AssessmentItemsAnswersTable/AssessmentItemsAnswersTable';

const AlertText = styled(Typography)`
  color: ${({ theme }) => theme.palette.error.main};
  text-align: 'center';
`;

const WarningIcon = styled(WarningOutlined)`
  color: ${({ theme }) => theme.palette.warning.main};
`;

const IncompleteItemsNotice: React.FC<{
  handleRedirectToAssessmentItems: () => void;
}> = ({ handleRedirectToAssessmentItems }) => {
  return (
    <VStack aria-label={closeDialogContentsAriaLabels.IncompleteItemsAlert}>
      <AlertText>This assessment has unanswered questions or unclassified items</AlertText>
      <Button variant="text" onClick={handleRedirectToAssessmentItems}>
        What items are incomplete?
      </Button>
    </VStack>
  );
};

const PendingFilesWarning: React.FC<{
  viewPendingFiles: () => void;
}> = ({ viewPendingFiles }) => {
  return (
    <HStack spacing={2} aria-label={closeDialogContentsAriaLabels.PendingFilesWarning}>
      <Box>
        <WarningIcon fontSize="large" />
      </Box>
      <VStack>
        <Typography variant="body1">
          You have files that are not yet uploaded. These files must either be uploaded or removed before the assessment
          can be completed.
        </Typography>
        <Typography>
          <Button onClick={viewPendingFiles}>Click here </Button>
          to view the files.
        </Typography>
      </VStack>
    </HStack>
  );
};

export interface AssessmentCloseDialogContentsProps {
  organizationId: string;
  assessmentId: string;
  canClose: boolean;
  handleCancel: () => void;
  handleCloseAssessment: () => Promise<void>;
}

export const AssessmentCloseDialogContents: React.FC<AssessmentCloseDialogContentsProps> = ({
  organizationId,
  assessmentId,
  canClose,
  handleCancel,
  handleCloseAssessment,
}) => {
  const history = useHistory();
  const featureFlagSmootherUploads = useIsFeatureFlagEnabledForOrg('smoother_uploads');
  const pendingFilesCount = useFileManagerStore(
    state => state.files.filter(f => f.status !== UploadStatus.Completed).length,
  );

  const canReopenAssessments = useIsFeatureFlagEnabledForOrg('reopen_assessments');

  const hasPendingFiles = featureFlagSmootherUploads && pendingFilesCount > 0;
  const detailsPath = generatePath(`${ROUTES.ASSESSMENTS_DETAIL}`, {
    organizationId,
    id: assessmentId,
  });
  const pendingFilesPath = `${detailsPath}/pendingFiles`;
  const viewPendingFiles = () => {
    handleCancel();
    history.push(pendingFilesPath);
  };
  const handleRedirectToAssessmentItems = () => {
    const basePath = generatePath(ROUTES.ASSESSMENTS_DETAIL, {
      organizationId,
      id: assessmentId,
    });

    const params = new URLSearchParams();
    const quickFilter: AssessmentItemAnswerQuickFilter = 'unanswered';
    params.set(ASSESSMENT_ITEMS_QUICK_FILTER_KEY, quickFilter);

    handleCancel();
    history.push(`${basePath}/results?${params.toString()}`);
  };

  return (
    <CloseAssessmentDialog
      {...{
        canClose,
        canReopenAssessments,
        handleCancel,
        handleCloseAssessment,
        hasPending: hasPendingFiles,
        PendingNotice: () => <PendingFilesWarning {...{ viewPendingFiles }} />,
        IncompleteItemsNotice: () => <IncompleteItemsNotice {...{ handleRedirectToAssessmentItems }} />,
      }}
    />
  );
};
