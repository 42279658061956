import uniqBy from 'lodash/uniqBy';

import { ActiveOrganizationData, RelevantTeam } from '../types/active-organization';

export const getRelevantTeams = (
  { nodes, layersById, users: orgUsers }: Pick<ActiveOrganizationData, 'nodes' | 'layersById' | 'users'>,
  locationIds: string[],
) => {
  const relevantLocations = nodes.filter(n => locationIds.includes(n.id));
  const relevantLayerIds = relevantLocations.map(n => n.layer_id);
  const relevantLayerTeams = uniqBy(
    relevantLayerIds.flatMap(layerId => layersById[layerId].teams),
    e => e.id,
  );

  // Build the relevant users for each team
  const teamUsersById = relevantLocations
    .flatMap(rl => rl.teamsAndUserIds)
    .reduce((list, e) => {
      if (!list[e.teamId]) {
        list[e.teamId] = [];
      }

      // Add the user if he doesn't exist yet
      if (!list[e.teamId].includes(e.userId)) {
        list[e.teamId].push(e.userId);
      }

      return list;
    }, {} as Record<number, string[]>);

  const data = relevantLayerTeams.map<RelevantTeam>(team => {
    const relevantUserIds = teamUsersById[team.id] || [];

    const teamUsers = orgUsers.filter(ou => relevantUserIds.includes(ou.id));

    return {
      id: team.id,
      name: team.name,
      users: teamUsers,
    };
  });

  return uniqBy(data, e => e.id);
};
