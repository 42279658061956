import { Frequency } from '@circadian-risk/api-contract';
import { Bold, HStack, VStack } from '@circadian-risk/presentational';
import { DateFormat, getWeekdayFromDate, getWeekdayOrdinalFromDate } from '@circadian-risk/shared';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { Box, Divider, Typography } from '@mui/material';
import dayjs from 'dayjs';
import pluralize from 'pluralize';

import { formatCustomFrequency, formatEndsOn } from '../../../helpers';
import { useCreateScheduledAssessmentFormContext } from '../../form';
import { useAssessmentStartDateWatch } from './hooks/useAssessmentStartDateWatch';

const underlined = (text: React.ReactNode) => (
  <Bold>
    <u>{text}</u>
  </Bold>
);

const formatYourSchedule = (frequency: Frequency, startsAt: Date): string => {
  const start = dayjs(startsAt);
  const startTime = start.format(DateFormat.HOUR_AND_MINUTE_AM_PM);
  const startDate = start.toDate();

  const weekday = getWeekdayFromDate(startDate);

  switch (frequency.type) {
    case 'daily':
      return 'daily';
    case 'monthly':
      return `monthly on the ${getWeekdayOrdinalFromDate(startsAt)} ${weekday}`;
    case 'annually':
      return `annually on ${start.format(DateFormat.MONTH_DAY)}`;
    case 'weekly':
      return `weekly on ${weekday}`;
    case 'weekday':
      return 'every weekday';
    case 'custom':
      return formatCustomFrequency(frequency, startDate, startTime);
    default:
      return `Unknown frequency`;
  }
};

export const CadenceSequenceInfo: React.FC = () => {
  const { watch } = useCreateScheduledAssessmentFormContext();
  const fullStartDate = useAssessmentStartDateWatch();
  const startTimeWatched = watch('startsAtTime');
  const dueAmountWatched = watch('dueAfter.value');
  const dueUnitWatched = watch('dueAfter.unit');
  const endsOnWatched = watch('endsOn');
  const frequencyWatched = watch('frequency');

  const startDate = dayjs(fullStartDate).format('MM/DD/YYYY');
  const ending = formatEndsOn(endsOnWatched.type, endsOnWatched.value).toLowerCase();
  const frequency = formatYourSchedule(frequencyWatched, dayjs(fullStartDate).toDate());

  return (
    <VStack>
      <Divider />
      <Typography variant="subtitle2">Your schedule</Typography>
      <Box
        bgcolor={theme => theme.palette.background.default}
        borderRadius={'4px'}
        borderColor={theme => theme.palette.divider}
        border={'1px solid black'}
        p={1}
      >
        <HStack>
          <PendingActionsIcon />
          <Typography variant="subtitle2">
            Scheduled Assessments start on {underlined(startDate)} at {underlined(startTimeWatched)} and are due{' '}
            {underlined(pluralize(dueUnitWatched, dueAmountWatched, true))} later. This will repeat{' '}
            {underlined(frequency)} and end {underlined(ending)}.
          </Typography>
        </HStack>
      </Box>
    </VStack>
  );
};
