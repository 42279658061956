import { CreateAssessmentFormGroupHeader } from '@circadian-risk/assessment-components';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Box } from '@mui/material';

import { createScheduleAssessmentForm, useCreateScheduledAssessmentFormContext } from '../../../form';
import { dueAfterUnitOptions } from '../autocomplete-options';

const { Numeric, Select } = createScheduleAssessmentForm;

export const DueAfterFormGroup = () => {
  const { formState } = useCreateScheduledAssessmentFormContext();
  const dueAfterError = formState.errors.dueAfter?.message;

  return (
    <>
      <CreateAssessmentFormGroupHeader
        title="Due after"
        subtitle="How long will each assessment have to be completed before it is due?"
      />

      <Box display="grid" gridTemplateColumns={'117px 178px'} gap={2}>
        <Numeric
          formKey="dueAfter.value"
          startAdornment={<AccessTimeIcon color="secondary" />}
          errorMessage={dueAfterError}
          label="Time"
          size="small"
        />
        <Select options={dueAfterUnitOptions} formKey="dueAfter.unit" label="Unit" size="small" />
      </Box>
    </>
  );
};
