import { VStack } from '@circadian-risk/presentational';
import { Typography } from '@mui/material';
import React from 'react';

import { ScheduleDetails } from '../../../../components/ScheduleDetails';
import { DetailsTextGroup } from '../../../../components/ScheduleDetails/DetailsTextGroup';
import { useCreateScheduledAssessmentFormContext } from '../../../form';
import { useHumanReadableEndDate } from '../../../hooks/useHumanReadableCadenceEndDate';
import { useAssessmentStartDateWatch } from '../../CadenceDetailsStep/hooks/useAssessmentStartDateWatch';
import { useSelectedTemplate } from '../../RootStep/hooks/useSelectedTemplate';

export const ScheduleContent: React.FC = () => {
  const { getValues } = useCreateScheduledAssessmentFormContext();
  const { dueAfter, frequency, name, description, notifications } = getValues();
  const template = useSelectedTemplate()!;
  const startsAt = useAssessmentStartDateWatch();
  const endDate = useHumanReadableEndDate();

  if (!template) {
    return null;
  }

  return (
    <VStack>
      <Typography variant="subtitle1">Schedule</Typography>
      <DetailsTextGroup title="Name" value={name} />

      <ScheduleDetails
        description={description ?? ''}
        startsAt={startsAt}
        dueAt={endDate}
        frequency={frequency}
        dueAfter={dueAfter}
        assessmentTemplate={template}
        reminders={notifications}
      />
    </VStack>
  );
};
