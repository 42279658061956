import { useRelevantLocations } from '@circadian-risk/assessment-components';
import { InfoBox, VStack } from '@circadian-risk/presentational';
import { useOrganizationSessionStore } from '@circadian-risk/stores';

import { MissingRelevantLocationsAlert } from '../../../components/MissingRelevantLocationsAlert';
import { ScenarioLinks } from '../../../components/ScenarioLinks';
import { ScenarioLinkInfo } from '../../../components/shared-types';
import { useSelectedTemplate } from './hooks/useSelectedTemplate';

export const LocationsAdditionalInformation: React.FC = () => {
  const selectedTemplate = useSelectedTemplate();
  const relevantLocations = useRelevantLocations(selectedTemplate?.scoredLayerId);
  const hasNoRelevantLocation = relevantLocations.length === 0;

  const scoredLayerId = selectedTemplate?.scoredLayerId;
  const scoredLayer = useOrganizationSessionStore(store => (!scoredLayerId ? null : store.layersById[scoredLayerId]));

  const infoMessage =
    selectedTemplate && selectedTemplate.totalAssessments > 0
      ? `${selectedTemplate.name} has been used to assess locations ${selectedTemplate.totalAssessments} times. ${selectedTemplate.totalAssessmentsInProgress} of these assessments are in progress.`
      : null;

  const selectedScenarios = (selectedTemplate?.scenarios ?? []).map<ScenarioLinkInfo>(sc => ({
    id: sc.id,
    name: sc.name,
  }));

  return (
    <VStack>
      {hasNoRelevantLocation && scoredLayer && <MissingRelevantLocationsAlert scoredLayerName={scoredLayer.name} />}
      {selectedScenarios.length > 0 && <ScenarioLinks scenarios={selectedScenarios} />}
      {infoMessage && <InfoBox>{infoMessage}</InfoBox>}
    </VStack>
  );
};
