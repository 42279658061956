import { Enum_Item_Status_Enum } from '@circadian-risk/graphql-types';
import { ItemStatus } from '@circadian-risk/presentational';
import React from 'react';

export interface ComplianceStatusCell {
  value: Enum_Item_Status_Enum;
}

export const ComplianceStatusCell: React.FC<ComplianceStatusCell> = ({ value: status }) => (
  <ItemStatus status={status} displayText />
);
