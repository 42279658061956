import { TooltipAvatar } from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { Box, ButtonBase, Paper, Typography } from '@mui/material';
import { useHistoryPushForActiveOrg } from '@web-app/lib/appHelpers';

import { authUserSx } from './styles';

export interface SwitchOrganizationProps {
  id: string;
  name: string;
  logo: string;
}

const OrganizationItem: React.FC<SwitchOrganizationProps> = ({ id, name, logo }) => {
  const history = useHistoryPushForActiveOrg();
  const handleClick = () => {
    history.push(ROUTES.ROOT.replace(':organizationId', id));
  };

  return (
    <ButtonBase focusRipple onClick={handleClick} sx={authUserSx.paperWrapper}>
      <Paper sx={authUserSx.paper} elevation={2} variant="elevation">
        <Box display={'flex'} flexDirection="row" gap={1} alignItems="center">
          <Box display={'flex'} height={'100%'}>
            <TooltipAvatar sx={authUserSx.orgLogo} alt={name} image={logo} size={80} />
          </Box>
          <Box display={'flex'}>
            <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
              {name}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </ButtonBase>
  );
};

export default OrganizationItem;
