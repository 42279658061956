import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { formatCustomFrequency } from '../../../../helpers/frequency-formatters';
import { CreateScheduledAssessmentFormSchema } from '../../../form';

export const useGetCustomFrequencyLabel = () => {
  const { watch } = useFormContext<CreateScheduledAssessmentFormSchema>();
  const frequency = watch('frequency');
  const startDate = watch('startsAtDate');
  const startTime = watch('startsAtTime');

  const label = useMemo(() => {
    return formatCustomFrequency(frequency, startDate, startTime);
  }, [frequency, startDate, startTime]);

  return label;
};
