import { FCC } from '@circadian-risk/front-end-utils';
import { useMediaQuery, useTheme } from '@mui/material';
import noop from 'lodash/noop';
import React, { useMemo, useState } from 'react';

export interface DrawerContextState {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export const DrawerContext = React.createContext<DrawerContextState>({
  isOpen: false,
  setIsOpen: noop,
});

export const ResponsiveDrawerProvider: FCC = ({ children }) => {
  const theme = useTheme();
  const startDrawerOpen = useMediaQuery(theme.breakpoints.up('md'));
  const [isOpen, setIsOpen] = useState(startDrawerOpen);

  const value = useMemo(() => {
    return {
      isOpen,
      setIsOpen,
    };
  }, [isOpen]);

  return <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>;
};
