import { Apart, HStack } from '@circadian-risk/presentational';
import { Add, Check } from '@mui/icons-material';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';

import { AutocompleteOption } from '../AutoCompleteInput';
import type { AutocompleteCreateOption, AutoCompleteValue, AutoCompleteWithCreateProps } from './types';
import { isCreateOption } from './types';

const getOptionLabel = (option: AutocompleteOption<AutoCompleteValue> | AutocompleteCreateOption) => {
  if (isCreateOption(option)) {
    return option.input;
  }
  return option.label;
};

export const AutoCompleteWithCreateOption = <V extends AutoCompleteValue>({
  value,
  onSelect,
  options,
  label,
  errors,
  newOptionPrompt,
  renderLabel,
  onCreate,
  onClear,
  textFieldProps: TextFieldProps,
  ...props
}: AutoCompleteWithCreateProps<V>) => {
  const [inputValue, setInputValue] = useState<string>('');

  const createOption: AutocompleteCreateOption | undefined = useMemo(() => {
    const existingLabel = options.find(option => option.label === inputValue);
    if (!onCreate || !inputValue || existingLabel) {
      return undefined;
    }
    return {
      input: inputValue,
      isNew: true,
    };
  }, [inputValue, onCreate, options]);

  const autocompleteOptions = useMemo(() => {
    if (!createOption) {
      return options;
    }
    return [...options, createOption];
  }, [createOption, options]);

  const selectedOption = useMemo(() => {
    if (!value) {
      return undefined;
    }
    if (value === createOption?.input) {
      return createOption;
    }
    return options.find(option => option.value === value);
  }, [createOption, options, value]);

  const handleChange = useCallback(
    (_: React.SyntheticEvent, option: AutocompleteOption<V> | AutocompleteCreateOption | null, reason?: string) => {
      if (reason === 'clear') {
        onSelect?.(undefined);
        onClear?.();
        return;
      }
      if (isCreateOption(option)) {
        onSelect?.(undefined);
        onCreate?.(option.input);
        return;
      }
      onSelect?.(option?.value ?? undefined);
    },
    [onSelect, onClear, onCreate],
  );

  const handleInputChange = useCallback((_: React.ChangeEvent<unknown>, newInputValue: string) => {
    setInputValue(newInputValue);
  }, []);

  return (
    <Autocomplete<AutocompleteOption<V> | AutocompleteCreateOption, false, false, false>
      fullWidth
      {...props}
      value={selectedOption}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      options={autocompleteOptions}
      renderInput={params => (
        <TextField
          variant="outlined"
          {...TextFieldProps}
          {...params}
          label={label}
          error={Boolean(errors)}
          helperText={errors}
        />
      )}
      renderOption={(props, option, state) => {
        const labelText = getOptionLabel(option);
        const label = renderLabel ? renderLabel(labelText) : labelText;
        const selectedCheck = state.selected ? <Check color="primary" /> : null;

        if (option === createOption) {
          return (
            <Box component="li" {...props}>
              <Apart>
                <HStack spacing={1}>
                  <Add color="primary" />
                  <Typography color="primary">{newOptionPrompt ?? 'Add'}</Typography>
                  {label}
                </HStack>
                {selectedCheck}
              </Apart>
            </Box>
          );
        }
        return (
          <Box component="li" {...props}>
            <Apart>
              {label}
              {selectedCheck}
            </Apart>
          </Box>
        );
      }}
      getOptionLabel={getOptionLabel}
    />
  );
};

export type { AutoCompleteWithCreateProps };
