import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, TextField, Tooltip, Typography, TypographyProps, useTheme } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { HStack } from '../..';

export const editableTitleAriaLabels = {
  EditingIcon: 'edit title',
  Input: 'title input field',
  CheckIcon: 'save icon',
  CloseIcon: 'close icon',
  Container: 'edit container',
};

export interface EditableTitleProps {
  title: string;
  /**
   * The callback is forward to the underlying input therefore it is invoked each
   * time the input text changes
   * @description This is required since the component is "controlled"
   * @param title
   * @returns
   */
  onChange: (title: string) => void;
  /**
   * This callback is invoked if the user presses "escape" or clicks "close"
   * @description This is a useful method to revert the value to the default value
   */
  onCancel: () => void;
  /**
   * If enabled then the editing experience will be disabled
   * @default false
   */
  isReadOnly?: boolean;
  /**
   * Renderer invoked once the component is not in "edit mode"
   * @description The default renderer content is a typography component with the title
   *
   * @param title
   * @returns
   */
  titleRenderer?: (title: string) => React.ReactNode;

  helperText?: React.ReactNode;
  error?: boolean;

  typographyProps?: Partial<TypographyProps>;
}

export interface EditableTitleRef {
  startEditing: () => void;
  /**
   * Stop editing is not going to "discard" changes, it is useful to programmatically
   * close the input and get back to the title mode
   */
  stopEditing: () => void;
}

export const EditableTitle = forwardRef<EditableTitleRef, EditableTitleProps>(
  ({ isReadOnly, title, onChange, titleRenderer, onCancel, helperText, error, typographyProps }, ref) => {
    const [isEditing, setIsEditing] = useState(false);
    const theme = useTheme();

    const cancelEditing = () => {
      setIsEditing(false);
      onCancel();
    };

    const stopEditing = () => {
      setIsEditing(false);
    };

    useImperativeHandle(
      ref,
      () =>
        ({
          startEditing: () => setIsEditing(true),
          stopEditing,
        } as EditableTitleRef),
    );

    // Handle special use case where if the component is at "editing mode"
    // but the props change, we should close the input
    useEffect(() => {
      if (isEditing && isReadOnly) {
        setIsEditing(false);
      }
    }, [isEditing, isReadOnly]);

    const toggleEditing = () => {
      if (!isEditing && !isReadOnly) {
        setIsEditing(!isEditing);
      }
    };

    return (
      <Box display="flex" justifyContent="space-between" mb={1}>
        <Box
          sx={{
            display: 'flex',
            cursor: isReadOnly ? 'default' : 'pointer',
            alignItems: 'center',
            borderRadius: theme.spacing(1),
            border: '1px solid transparent',
            my: isReadOnly ? undefined : '-1px',
            '&:hover': {
              border: !isEditing && !isReadOnly ? `1px dashed ${theme.palette.divider}` : undefined,
            },
          }}
          aria-label={editableTitleAriaLabels.Container}
          role="button"
          tabIndex={0}
          onClick={toggleEditing}
        >
          <Typography component="span" {...typographyProps}>
            {!isEditing ? (
              <HStack alignItems="center" sx={{ userSelect: isReadOnly ? undefined : 'none' }}>
                {titleRenderer ? titleRenderer(title) : <span>{title}</span>}
                {!isReadOnly && (
                  <IconButton size="small" onClick={toggleEditing}>
                    <EditIcon aria-label={editableTitleAriaLabels.EditingIcon} />
                  </IconButton>
                )}
              </HStack>
            ) : (
              <HStack>
                <TextField
                  sx={{ '.MuiInput-underline': { width: `${title.length}ch`, font: 'inherit', pt: '-1px' } }}
                  aria-label={editableTitleAriaLabels.Input}
                  variant="standard"
                  value={title}
                  autoFocus={isEditing}
                  onChange={e => onChange(e.target.value)}
                  helperText={helperText}
                  error={error}
                  onKeyDown={e => {
                    // Cancel the editing if user presses escape
                    if (e.key === 'Escape') {
                      // prevent that Modals for example capture escape
                      e.preventDefault();
                      e.stopPropagation();
                      cancelEditing();
                    }

                    if (e.key === 'Enter') {
                      stopEditing();
                    }
                  }}
                />
                <Tooltip title="Save Changes">
                  <IconButton onClick={stopEditing} size="small">
                    <CheckIcon aria-label={editableTitleAriaLabels.CheckIcon} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Cancel">
                  <IconButton onClick={cancelEditing} size="small">
                    <CloseIcon aria-label={editableTitleAriaLabels.CloseIcon} />
                  </IconButton>
                </Tooltip>
              </HStack>
            )}
          </Typography>
        </Box>
      </Box>
    );
  },
);
