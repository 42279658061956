import { createSingleAssessmentSchema } from '@circadian-risk/api-contract';
import { ReviewLocationDetailsRow } from '@circadian-risk/scheduled-assessments';
import { TreeNode } from '@circadian-risk/shared';
import { z } from 'zod';

export enum Steps {
  RootSelect,
  Details,
  Locations,
  CompletionCriteria,
  Users,
  Summary,
}

export const scenarioAssessmentFormSchema = createSingleAssessmentSchema.extend({
  rootLocationId: z.string().optional(),
  startDate: z.date().nullable(),
  dueDate: z.date().nullable(),
});

export type ScenarioFormData = z.infer<typeof scenarioAssessmentFormSchema>;

export interface NodeTemplateSummaryInfo {
  inspection_questionnaire_questions_count: number;
  inspection_questionnaire_item_categories_count: number;
  inspection_questionnaire_properties_count: number;
  physical_inspection_questions_count: number;
  physical_inspection_item_categories_count: number;
  physical_inspection_properties_count: number;
}

export interface TagInfo {
  layer_tag_id: number;
  layer_tag_name: string;
}

export interface TemplateData {
  id: number;
  name: string;
  scenarios: {
    id: number;
    name: string;
    description?: string | null;
  }[];
  tags: TagInfo[];
  totalAssessments: number;
  totalAssessmentsInProgress: number;
  scoredLayerId: string;
  deprecatedTemplatesCount: number;
}

export interface QualifiedNode extends NodeTemplateSummaryInfo {
  id: string;
  assessmentLastCompletedAt: Date | null;
  hasMap: boolean;
}

export type ScenarioLinkInfo = { id: number; name: string };

export type AssessmentFormStore = {
  templates: TemplateData[];
  relevantNodes: ReviewLocationDetailsRow[];
  editing: boolean;
  clean: () => void;
  usingPreviousAssessmentData: boolean;
  selectTemplate: (templateId: number) => void;
  selectedTemplate?: TemplateData;
};

export interface ITreeItem extends TreeNode {
  name: string;
  layerName: string;
  children?: ITreeItem[];
  selected: boolean;
}
