import {
  CircularProgressWithLabel,
  ResponsiveIconButton,
  Thumbnail,
  useLightbox,
} from '@circadian-risk/presentational';
import { formatBytes } from '@circadian-risk/shared';
import DoneIcon from '@mui/icons-material/Done';
import PauseIcon from '@mui/icons-material/Pause';
import RefreshIcon from '@mui/icons-material/Refresh';
import RemoveIcon from '@mui/icons-material/Remove';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, TableCell, TableRow, useTheme } from '@mui/material';
import startCase from 'lodash/startCase';
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import { FileManagerContext } from '../context';
import { useFileManagerStore } from '../store/useFileManagerStore';
import { IFileItem, LinkEntityUrlParamKey, UploadStatus } from '../types';

type FileManagerItemProps = IFileItem & {
  noRemoveButton?: boolean;
};

export const FileManagerItem: React.FC<FileManagerItemProps> = props => {
  const { id, name, size, status, uploadProgressInfo, uploadEndpointInfo, noRemoveButton, url } = props;
  const uploadingPercentage = uploadProgressInfo?.percentage;
  const theme = useTheme();
  const imgSrc = url;
  const [updateFile, removeFile] = useFileManagerStore(s => [s.updateFile, s.removeFile]);

  const statusColor: Record<UploadStatus, string> = {
    Pending: theme.palette.warning.main,
    Failed: theme.palette.error.main,
    NA: theme.palette.primary.main,
    Completed: theme.palette.primary.main,
    Uploading: theme.palette.primary.main,
  };

  const { handleOpen } = useLightbox();

  const fileManagerContext = useContext(FileManagerContext);

  const handleRestart = async () => {
    await updateFile(id, {
      status: UploadStatus.Pending,
    });
  };

  const handleRemove = async () => {
    await removeFile(id);
  };

  const handleLinkClick = () => {
    if (fileManagerContext) {
      fileManagerContext.setIsOpen(false);
      fileManagerContext.onFileClicked(props);
    }
  };

  let linkPath = uploadEndpointInfo.path;

  // Dirty check to identify if the link is a redirect to the companion app
  // This will removed as part of CR-5756
  const isExternalLink = linkPath?.includes('app/organization');

  if (linkPath !== null && !isExternalLink) {
    const url = new URL(linkPath, window.location.href);
    url.searchParams.set(LinkEntityUrlParamKey, `${uploadEndpointInfo.entityType}_${uploadEndpointInfo.entityId}`);
    linkPath = `${url.pathname}/${url.search}`;
  }

  const entityTypeName = startCase(uploadEndpointInfo.entityType);

  return (
    <TableRow key={id}>
      <TableCell sx={{ width: '64px', overflow: 'hidden' }}>
        <Thumbnail name={name} src={imgSrc} onImageClick={() => handleOpen(id)} />
      </TableCell>
      <TableCell component="th" scope="row">
        {name}
      </TableCell>
      <TableCell>{formatBytes(size)}</TableCell>
      <TableCell sx={{ width: '150px', color: statusColor }}>
        <Box display="flex" flexDirection="row" alignItems="center" gap={theme.spacing(1)} width="100%">
          <span>{status}</span>
          {status === UploadStatus.Uploading && uploadingPercentage && (
            <CircularProgressWithLabel value={uploadingPercentage === 100 ? 99 : uploadingPercentage} size="small" />
          )}
          {status === UploadStatus.Pending && <PauseIcon />}
          {status === UploadStatus.Completed && <DoneIcon />}
          {status === UploadStatus.Failed && <WarningIcon />}
        </Box>
      </TableCell>
      <TableCell>
        {linkPath && !isExternalLink && (
          <NavLink to={linkPath} onClick={handleLinkClick}>
            {entityTypeName}
          </NavLink>
        )}
        {linkPath && isExternalLink && <a href={linkPath}>{entityTypeName}</a>}
      </TableCell>
      <TableCell>
        <Box display="flex" flexDirection="row" flexWrap="wrap" gap={theme.spacing(1)}>
          {status === UploadStatus.Failed && (
            <ResponsiveIconButton
              iconPosition={'start'}
              icon={<RefreshIcon />}
              text={'Restart'}
              onClick={handleRestart}
              color="primary"
              variant={'outlined'}
            />
          )}
          {status !== UploadStatus.Completed && !noRemoveButton && (
            <ResponsiveIconButton
              iconPosition={'start'}
              icon={<RemoveIcon />}
              text={'Remove'}
              onClick={handleRemove}
              color="primary"
              variant={'outlined'}
            />
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};
