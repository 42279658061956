import { ROUTES } from '@circadian-risk/routes';
import { useOrganizationId } from '@circadian-risk/stores';
import { Warning } from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { generatePath } from 'react-router-dom';

import NavLinkWithActiveOrgId from '../../../../components/NavLinkWithActiveOrgId';

export interface AssessorBioExtraContentProps {
  assessmentId?: number;
}

export const AssessorBioExtraContent: React.FC<AssessorBioExtraContentProps> = ({ assessmentId }) => {
  const theme = useTheme();
  const organizationId = useOrganizationId();

  return (
    <Box aria-label={'assessor bio extra content'}>
      <Box mb={2}>
        <Typography variant={'body2'}>
          Select the user biographies to include in the report. Missing information can be filled out by each user on
          their profile page.
        </Typography>
        <Typography variant={'body2'}>
          Users can be added to this list through the Assign User flow on the{' '}
          <NavLinkWithActiveOrgId
            aria-label={'assessor assessment details'}
            to={generatePath(ROUTES.ASSESSMENTS_DETAIL, { id: assessmentId!, organizationId })}
          >
            assessment details page
          </NavLinkWithActiveOrgId>
        </Typography>
      </Box>
      <Box
        display="flex"
        borderRadius="5px"
        py={1}
        px={2}
        alignItems="center"
        border={`1px solid ${theme.palette.warning.main}`}
      >
        <Box display={'flex'} color={theme.palette.warning.main} width={'100%'}>
          <Box display={'flex'} alignItems={'center'}>
            <Warning style={{ color: theme.palette.warning.main }} display={'flex'} />
          </Box>
          <Box ml={1} width={'100%'}>
            <Box
              aria-label={'assessor bio warning info'}
              display={'flex'}
              alignItems={'center'}
              color={theme.palette.warning.dark}
            >
              This section will not be included in the report if no users are selected
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
