import { Button, IconButton } from '@mui/material';

import { ChevronControls } from '../ChevronControls';

interface LessOrMoreProps {
  compact: boolean;
  open: boolean;
  toggle: () => void;
}

export const LessOrMoreButton: React.FC<LessOrMoreProps> = ({ compact, open, toggle }) => {
  return compact ? (
    <IconButton onClick={toggle} size="small">
      <ChevronControls variant="bottomDrawer" expanded={open} />
    </IconButton>
  ) : (
    <Button onClick={toggle} endIcon={<ChevronControls variant="bottomDrawer" expanded={open} />} sx={{ width: 85 }}>
      {open ? 'Less' : 'More'}
    </Button>
  );
};
