import { fileSrc, isImageExtension } from '@circadian-risk/front-end-utils';
import { LightboxImage, LightBoxProvider } from '@circadian-risk/presentational';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { InputHTMLAttributes, useState } from 'react';
import { Controller } from 'react-hook-form';
import { v4 } from 'uuid';

import { PropertyFieldProps } from '../../PropertyFields';
import { useFileDownloadContext } from '../FileDownloadContext';
import { FileListItem, FileUploadDropzone } from '../FileUpload';

export interface PropertyFileFieldControlledProps extends PropertyFieldProps {
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  uploadFn?: (file: File) => Promise<{
    id?: string;
    name?: string;
    url: string;
  }>;
  deleteFn?: () => Promise<void>;
  organizationId: string;
  /**
   * Allows to disable download feature
   * @default false
   */
  disableDownload?: boolean;
}

export type PropertyFile = {
  id: string;
  name: string;
  url: string;
  file?: File;
};

export const PropertyFileFieldControlled: React.FC<PropertyFileFieldControlledProps> = ({
  htmlName,
  control,
  rules,
  boxProps,
  inputProps,
  errorMessage,
  uploadFn,
  deleteFn,
  organizationId,
  disableDownload = false,
  label,
  endAdornment,
}) => {
  const { downloadOrgFile } = useFileDownloadContext();

  return (
    <Box {...boxProps}>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" my={0.5} mx={1.75}>
        <Typography variant="body1">{label}</Typography>
        {endAdornment}
      </Box>
      <Controller
        control={control}
        name={htmlName}
        rules={rules}
        render={fieldProps => {
          const { onChange } = fieldProps.field;
          const value: PropertyFile = fieldProps.field.value;
          const handleFilesAdded = async (files: File[]) => {
            // Assume only one file uploaded
            const file = files[0];

            if (!file) {
              return;
            }

            let newValue: PropertyFile = {
              id: v4(),
              name: file.name,
              url: URL.createObjectURL(file),
              file,
            };

            if (uploadFn) {
              const result = await uploadFn(file);
              newValue = {
                ...newValue,
                ...result,
              };
            }
            onChange(newValue);
          };

          let lightboxImages: LightboxImage[] = [];
          if (value && isImageExtension(value.name)) {
            const fileUrl = fileSrc(value.url);
            const lightboxImage: LightboxImage = { id: value.id, url: fileUrl ?? '' };
            lightboxImages = [lightboxImage];
          }

          return (
            <>
              <FileUploadDropzone onFilesAdded={handleFilesAdded} inputProps={inputProps} />
              <LightBoxProvider images={lightboxImages}>
                {value && (
                  <FileListItem
                    id={value.id}
                    name={value.name}
                    url={fileSrc(value.url) ?? ''}
                    thumbnailUrl={fileSrc(value.url) ?? null}
                    divider={false}
                    onDelete={deleteFn}
                    onDownload={
                      !disableDownload ? () => downloadOrgFile(value.id, organizationId, value.name) : undefined
                    }
                  />
                )}
              </LightBoxProvider>
            </>
          );
        }}
      />
      {errorMessage && (
        <Typography variant="body2" color="error">
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};

export interface PropertyFileFieldProps extends PropertyFieldProps {
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  addInsteadOfReplace?: boolean;
}

// Can only be used as a uncontrolled component
export const PropertyFileField: React.FC<PropertyFileFieldProps> = ({
  htmlName,
  control,
  rules,
  boxProps,
  inputProps,
  errorMessage,
  addInsteadOfReplace,
}) => {
  const [files, setFiles] = useState<File[]>([]);

  return (
    <Box {...boxProps}>
      <Controller
        control={control}
        name={htmlName}
        defaultValue={[]}
        rules={rules}
        render={fieldProps => {
          const { onChange } = fieldProps.field;

          return (
            <>
              <FileUploadDropzone
                onFilesAdded={addedFiles => {
                  const newFiles = addInsteadOfReplace ? [...addedFiles, ...files] : addedFiles;
                  setFiles(newFiles);
                  onChange(newFiles);
                }}
                inputProps={inputProps}
              />
              {files.map(file => {
                return (
                  <Box display="flex" flexDirection="row" alignItems="center" key={`${file.name}-${file.size}`}>
                    <Tooltip title="Remove">
                      <IconButton
                        size="small"
                        onClick={() => {
                          const newFiles = [...files];
                          newFiles.splice(newFiles.indexOf(file), 1);
                          setFiles(newFiles);
                          onChange(newFiles);
                        }}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    </Tooltip>
                    <Typography variant="body2">{file.name}</Typography>
                  </Box>
                );
              })}
            </>
          );
        }}
      />
      {errorMessage && (
        <Typography variant="body2" color="error">
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};
