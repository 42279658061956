import { FCC } from '@circadian-risk/front-end-utils';
import { Box, Button, ButtonProps, useTheme } from '@mui/material';
import React from 'react';

export type ButtonWithInlineChildrenProps = ButtonProps;

export const ButtonWithInlineChildren: FCC<ButtonWithInlineChildrenProps> = ({ children, ...props }) => {
  const theme = useTheme();
  return (
    <Button variant="outlined" {...props}>
      <Box display="flex" flexDirection="row" alignItems="center" gap={theme.spacing(0.5)}>
        {children}
      </Box>
    </Button>
  );
};
