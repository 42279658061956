import { Bold } from '@circadian-risk/presentational';
import { Typography } from '@mui/material';

type Props = {
  title: React.ReactNode;
  value: React.ReactNode;
};

export const DetailsTextGroup: React.FC<Props> = ({ title, value }) => {
  return (
    <Bold>
      <Typography variant="subtitle2">{title}</Typography>
      <Typography variant="body2">{value}</Typography>
    </Bold>
  );
};
