import { Box, BoxProps, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';

import { CheckboxMultiField, CheckboxMultiFieldProps } from '../../CheckboxMultiField';
import { PropertyFieldProps } from '../types';

export type PropertyCheckboxMultiFieldProps = PropertyFieldProps & {
  options: CheckboxMultiFieldProps['options'];
  onChange?: (value: string[]) => void;
  checkboxBoxProps?: BoxProps;
};

export const PropertyCheckboxMultiField: React.FC<PropertyCheckboxMultiFieldProps> = ({
  label,
  htmlName,
  control,
  options,
  disabled,
  rules,
  boxProps,
  endAdornment,
  onChange: propsOnChange,
  checkboxBoxProps,
  errorMessage,
}) => {
  return (
    <Box {...boxProps}>
      <Controller
        control={control}
        name={htmlName}
        defaultValue={null}
        rules={rules}
        render={fieldProps => {
          const { value, onChange } = fieldProps.field;
          const onChangeProxy = (newValue: string[]) => {
            if (propsOnChange) {
              propsOnChange(newValue);
            }
            onChange(newValue);
          };
          return (
            <>
              <CheckboxMultiField
                value={value}
                label={label}
                onChange={onChangeProxy}
                options={options}
                data-name={label}
                disabled={disabled}
                boxProps={checkboxBoxProps}
                endAdornment={
                  <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
                    {endAdornment}
                  </Box>
                }
              />
              {errorMessage && <Typography color="error">{errorMessage}</Typography>}
            </>
          );
        }}
      />
    </Box>
  );
};
