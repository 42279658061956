import { useApiClient } from '@circadian-risk/api-client-provider';
import { BulkAnswerReassess } from '@circadian-risk/api-contract';
import { useProvideAnswerMutation } from '@circadian-risk/client-graphql-hooks';
import { useNotification } from '@circadian-risk/front-end-utils';
import { useOrganizationId } from '@circadian-risk/stores';
import { ChevronLeft } from '@mui/icons-material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
  useTheme,
} from '@mui/material';
import { QuestionsFormData, useUploadAndUpdateFilesForAnswer } from '@web-app/components/QuestionField';
import { OptionsUpdateDtoByQuestionId } from '@web-app/modules/Items/hooks/types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useBeforeUnload } from 'react-use';

import { AssessQuestion } from '../BulkReassess';
import { getItemIdQuestionIdFromNameIdentifier, getNameIdentifierForItemQuestion } from './OneByOneReassessModal.types';
import { OneByOneReassessStep } from './OneByOneReassessStep';

export interface OneByOneReassessModalProps {
  open: boolean;
  onClose: () => void;
  onExited?: () => void;

  questions: AssessQuestion[];
  onSubmitted: () => void;
  onBulkEditClick: () => void;
  onBackClick?: () => void;
}

type AnswerFileInfo = {
  answerId: string;
  itemId: string;
  questionId: string;
};

const UNSAVED_CHANGES_MESSAGE =
  'You have unsaved changes. Press Cancel to return to the form. Press OK to discard changes.';

const getInitialFormValues = (questions: AssessQuestion[]) => {
  const initialValues: QuestionsFormData = {};
  questions.forEach(q => {
    const uniqueId = getNameIdentifierForItemQuestion(q.itemId, q.questionId);
    initialValues[uniqueId] = q.initialValue ?? {};
  });
  return initialValues;
};

export const OneByOneReassessModalContent: React.FC<OneByOneReassessModalProps> = props => {
  const { open, onClose, questions, onSubmitted, onBulkEditClick, onBackClick } = props;
  const theme = useTheme();
  const organizationId = useOrganizationId();
  const optionsToUpdateRef = useRef<OptionsUpdateDtoByQuestionId>({});

  const values = getInitialFormValues(questions);
  // TODO(miking-the-viking): use generateCircadianForm [CR-4778]
  const methods = useForm<QuestionsFormData>({
    shouldUnregister: true,
    values,
  });

  const { reset, getValues } = methods;
  const { displaySuccess, displayError } = useNotification();
  const [stepErrorMessage, setStepErrorMessage] = useState<string | null>(null);
  const [stepItemIndex, setStepItemIndex] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [provideAnswer] = useProvideAnswerMutation();
  const { tsRestClient } = useApiClient();
  const handleUploadAndUpdateFilesForAnswer = useUploadAndUpdateFilesForAnswer();

  const questionItems = useMemo(() => {
    const byItem: (Omit<AssessQuestion, 'questions'> & {
      questions: AssessQuestion[];
    })[] = [];

    for (const question of questions) {
      const foundItem = byItem.find(i => i.itemId === question.itemId);
      if (!foundItem) {
        byItem.push({
          ...question,
          questions: [question],
        });
      } else {
        foundItem.questions.push(question);
      }
    }
    return byItem;
  }, [questions]);

  useEffect(() => {
    if (open) {
      reset(getInitialFormValues(questions));
      setStepItemIndex(0);
    }
  }, [reset, open, questions]);

  const handleSubmit = async () => {
    setSubmitting(true);
    const formQuestionAnswers = Object.entries(getValues());

    const reassessFormQuestionAnswers: typeof formQuestionAnswers = [];
    const assessmentFormQuestionAnswers: typeof formQuestionAnswers = [];

    // Split based on context
    formQuestionAnswers.forEach(entry => {
      const [uniqueId] = entry;
      const { questionId } = getItemIdQuestionIdFromNameIdentifier(uniqueId);
      const originalQuestion = questions.find(q => q.questionId === questionId)!;
      if (originalQuestion.context === 'reassess') {
        reassessFormQuestionAnswers.push(entry);
      }
      if (originalQuestion.context === 'assessment') {
        assessmentFormQuestionAnswers.push(entry);
      }
    });

    // Process reassessFormQuestionAnswers
    if (reassessFormQuestionAnswers.length > 0) {
      const answers = reassessFormQuestionAnswers.map<BulkAnswerReassess[0]>(entry => {
        const [uniqueId, answer] = entry;
        const { questionId, itemId } = getItemIdQuestionIdFromNameIdentifier(uniqueId);

        const { description, is_flagged, answered_at, fileIdsToRemove } = answer;
        const options = optionsToUpdateRef.current[questionId];

        return {
          questionId,
          itemId,
          response: answer.response!,
          description,
          flagged: is_flagged,
          answered_at,
          fileIdsToRemove: fileIdsToRemove ?? [],
          ...options,
        };
      });

      try {
        // Create answers
        await tsRestClient.organizationItems.bulkReassess({
          params: { organizationId },
          body: answers,
        });

        // Upload files for answers
        for (const questionIdAnswer of formQuestionAnswers) {
          const [uniqueId, answer] = questionIdAnswer;
          const { questionId, itemId } = getItemIdQuestionIdFromNameIdentifier(uniqueId);
          await handleUploadAndUpdateFilesForAnswer(answer, {
            type: 'activeAnswers',
            organizationId,
            itemId,
            questionId,
          });
        }
      } catch (ex) {
        displayError(ex);
      }
    }

    // Process assessmentFormQuestionAnswers
    if (assessmentFormQuestionAnswers.length > 0) {
      const promises = assessmentFormQuestionAnswers.map(async entry => {
        const [uniqueId, answer] = entry;
        const { questionId, itemId } = getItemIdQuestionIdFromNameIdentifier(uniqueId);

        const result = await provideAnswer({
          variables: {
            answer: {
              assessment_item_id: itemId,
              question_id: questionId,
              response: answer.response,
              description: answer.description,
              is_flagged: answer.is_flagged,
            },
          },
        });

        if (!result.data?.insert_assessment_answers_one) {
          return null;
        }

        return {
          answerId: result.data?.insert_assessment_answers_one?.id,
          itemId: result.data?.insert_assessment_answers_one.assessment_item_id,
          questionId: result.data?.insert_assessment_answers_one?.question_id,
        };
      });
      const answerQuestions = await Promise.all(promises);

      const newAnswers: AnswerFileInfo[] = answerQuestions.filter(aq => Boolean(aq)) as AnswerFileInfo[];

      // Upload files for assessment answers
      for (const questionIdAnswer of formQuestionAnswers) {
        const [uniqueId, answer] = questionIdAnswer;
        const { questionId, itemId } = getItemIdQuestionIdFromNameIdentifier(uniqueId);

        const newAnswer = newAnswers.find(a => {
          return a.questionId === questionId && a.itemId === itemId;
        })!;

        await handleUploadAndUpdateFilesForAnswer(answer, {
          type: 'assessmentAnswers',
          organizationId,
          answerId: newAnswer.answerId,
        });
      }
    }

    setSubmitting(false);
    displaySuccess(`New answers provided for ${questionItems.length} questions`);
    onSubmitted();
  };

  const isLastStep = stepItemIndex === questionItems.length - 1;

  const handleBack = () => {
    if (stepItemIndex !== 0) {
      setStepItemIndex(prevStep => prevStep - 1);
      setStepErrorMessage(null);
    }
  };

  const areQuestionFilledForCurrentStep = () => {
    // Need to evaluate whether all questions on this step have been answered
    const currentStepItemId = questionItems[stepItemIndex].itemId;
    const currentValues = getValues();

    const filledOutAllQuestions = Object.entries(currentValues)
      .filter(entry => {
        const [uniqueId] = entry;
        const { itemId } = getItemIdQuestionIdFromNameIdentifier(uniqueId);
        return itemId === currentStepItemId;
      })
      .every(entry => {
        const [, answer] = entry;
        return Boolean(answer?.response);
      });

    return filledOutAllQuestions;
  };

  const handleNext = async () => {
    if (!areQuestionFilledForCurrentStep()) {
      setStepErrorMessage('You must provide a response for all questions');
      return;
    } else if (isLastStep) {
      try {
        await handleSubmit();
      } catch (err) {
        displayError(err as Error);
      }
    } else {
      setStepItemIndex(prevStep => {
        return prevStep + 1;
      });
    }
    setStepErrorMessage(null);
  };

  useBeforeUnload(open, UNSAVED_CHANGES_MESSAGE);

  return (
    <>
      <DialogTitle title="one by one reassess">
        <Box display={'flex'} alignItems={'center'}>
          <Box display={'flex'} flexGrow={1}>
            {questionItems.length > 0 ? `Reassess ${questionItems.length} Items` : 'Reassess Item'}
          </Box>
          {questions.length > 1 && (
            <Button color="primary" onClick={onBulkEditClick} endIcon={<ArrowForwardIcon />}>
              Bulk Reassess
            </Button>
          )}
        </Box>
      </DialogTitle>
      <DialogContent>
        <FormProvider {...methods}>
          {questionItems.map((item, i) => {
            return (
              <Box
                key={item.itemId}
                display={i !== stepItemIndex ? 'none' : 'block'}
                sx={theme => ({
                  '& > :not(:first-of-type)': {
                    marginTop: theme.spacing(2),
                  },
                })}
              >
                <OneByOneReassessStep {...item} optionsToUpdateRef={optionsToUpdateRef} />
              </Box>
            );
          })}
        </FormProvider>
        {stepErrorMessage && (
          <Box mt={1}>
            <Typography variant="body2" color="error">
              {stepErrorMessage}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box flex={1} display="flex" flexDirection="row">
          <Box flex={1} gap={2} display="flex" flexDirection="row" justifyContent="flex-start">
            {onBackClick && (
              <Button startIcon={<ChevronLeft />} onClick={onBackClick}>
                Return to Selected
              </Button>
            )}
            <Button onClick={onClose} disabled={submitting} variant={'outlined'}>
              Cancel
            </Button>
          </Box>
          <Box
            flex={1}
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
            gap={theme.spacing(1)}
          >
            <Button type="submit" onClick={handleBack} variant="text" disabled={stepItemIndex === 0}>
              Previous
            </Button>
            {questionItems.length > 0 && (
              <Typography variant="caption" color="secondary">{`${stepItemIndex + 1} of ${
                questionItems.length
              } items`}</Typography>
            )}

            {isLastStep ? (
              <Button type="submit" onClick={handleNext} variant={'contained'} disabled={submitting}>
                Save
              </Button>
            ) : (
              <Button
                type="submit"
                onClick={handleNext}
                variant={'text'}
                endIcon={<NavigateNextIcon />}
                disabled={submitting}
              >
                Next
              </Button>
            )}
          </Box>
        </Box>
      </DialogActions>
      {submitting && <LinearProgress />}
    </>
  );
};

/** @deprecated: Use One By One Reassess Modal from @circadian-risk/components */
export const OneByOneReassessModal: React.FC<OneByOneReassessModalProps> = props => {
  const { open, onClose, onExited } = props;

  return (
    <Dialog
      open={open}
      onClose={() => {
        const response = window.confirm(UNSAVED_CHANGES_MESSAGE);
        if (response && onClose) {
          onClose();
        }
      }}
      TransitionProps={{ onExited }}
      fullWidth
      maxWidth={'sm'}
    >
      <OneByOneReassessModalContent {...props} />
    </Dialog>
  );
};
