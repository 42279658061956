import { Box } from '@mui/material';
import React from 'react';

import { centerContentBoxProps } from '../CenterContent.styles';
import { ErrorFetchingDataCard } from './ErrorFetchingDataCard';
import { ErrorMessageCardProps } from './ErrorMessageCard';

interface ErrorFetchingDataPageProps extends ErrorMessageCardProps {
  errorMessage?: string;
}

/**
 *
 * Use this if you want to replace the whole page with error display
 *
 * if you are rendering an error inside a card, please use <ErrorFetchingDataCard />
 */
export const ErrorFetchingDataPage: React.FC<ErrorFetchingDataPageProps> = props => {
  return (
    <Box {...centerContentBoxProps}>
      <ErrorFetchingDataCard {...props} />
    </Box>
  );
};
