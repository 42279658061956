import { GridApi } from '@ag-grid-community/core';
import { ExpandableSearch } from '@circadian-risk/presentational';
import { useEffect, useState } from 'react';
import { useDebounce, useUnmount, useUpdateEffect } from 'react-use';

import { useSimpleTableStore } from '../simpleTable.store';

const SEARCH_ID = 'org-switcher-input';

export interface DataGridSearchProps {
  gridApi: GridApi | null;
  disabled?: boolean;
  autoFocus?: boolean;
  debounceSearchInterval?: number;
}

export const DataGridSearch: React.FC<DataGridSearchProps> = ({
  gridApi,
  autoFocus,
  disabled,
  debounceSearchInterval = 750,
}) => {
  const [value, setValue] = useSimpleTableStore(state => [state.quickFilterText, state.updateQuickFilterText]);
  const [internalSearch, setInternalSearch] = useState(value);

  useUpdateEffect(() => {
    // Sync changes from the store (which might come later) into internal state
    // to make sure things don't get overwritten
    setInternalSearch(value);
  }, [value]);

  // Cleanup the value once it unmounts
  useUnmount(() => {
    setValue('', gridApi);
  });

  useEffect(() => {
    if (autoFocus) {
      document.getElementById(SEARCH_ID)?.focus();
    }
  }, [autoFocus]);

  useDebounce(
    () => {
      if (value !== internalSearch) {
        setValue(internalSearch, gridApi);
      }
    },
    debounceSearchInterval,
    [internalSearch, value],
  );

  return (
    <ExpandableSearch
      id={SEARCH_ID}
      aria-label={'simple table search input'}
      placeholder={'Search...'}
      sx={{
        maxWidth: 200,
      }}
      value={internalSearch}
      size={'medium'}
      disabled={disabled}
      onChange={text => {
        if (text !== value) {
          setInternalSearch(text);
        }
      }}
    />
  );
};
