import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import uniqBy from 'lodash/uniqBy';
import React, { useState } from 'react';

import { PopperTooltip } from '../PopperTooltip';
import { StandardsGroup } from '../StandardsGroup';
import { StandardsDialog, StandardsDialogProps } from './StandardsDialog';

export interface IStandard {
  name: string;
  section1: string | null;
  section2: string | null;
  section3: string | null;
  relevantExcerpt: string | null;
}

export type QuestionStandard = IStandard & {
  questionId: string;
  scenarioQuestionSetId: number;
};

export interface StandardsGroupWithDialogProps {
  standards: IStandard[];
  /**
   * Useful when used within a cell rendered so that once
   * there is more than 1 standard it be more flexible
   *
   * @default false
   */
  enableDynamicPopover?: boolean;
}

export const StandardsGroupWithDialog: React.FC<StandardsGroupWithDialogProps> = ({
  standards,
  enableDynamicPopover = false,
}) => {
  const uniqueStandards = uniqBy(standards, s => s.name);
  const [dialogInfo, setDialogInfo] = useState<Omit<StandardsDialogProps, 'open' | 'onClose'> | undefined>();

  const handleStandardClick = (name: string) => {
    setDialogInfo(uniqueStandards.find(s => s.name === name));
  };

  const shouldRenderPopperTooltip = Boolean(enableDynamicPopover && uniqueStandards.length > 1);
  const shouldRenderInline = Boolean((enableDynamicPopover && uniqueStandards.length === 1) || !enableDynamicPopover);

  return (
    <>
      {shouldRenderInline && (
        <StandardsGroup standards={uniqueStandards.map(s => s.name)} onClick={handleStandardClick} />
      )}
      {shouldRenderPopperTooltip && (
        <PopperTooltip variant={'persistent'} trigger={<StandardsGroup standards={[`+${uniqueStandards.length}`]} />}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell variant="head">Standard Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {uniqueStandards.map(({ name }) => {
                  return (
                    <TableRow
                      key={name}
                      onClick={() => setDialogInfo(uniqueStandards.find(s => s.name === name))}
                      sx={{ cursor: 'pointer', ':hover': { opacity: '0.7' } }}
                    >
                      <TableCell>{name}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </PopperTooltip>
      )}
      <StandardsDialog
        open={Boolean(dialogInfo)}
        name={dialogInfo?.name ?? ''}
        onClose={() => setDialogInfo(undefined)}
        section1={dialogInfo?.section1 ?? null}
        section2={dialogInfo?.section2 ?? null}
        section3={dialogInfo?.section3 ?? null}
        relevantExcerpt={dialogInfo?.relevantExcerpt ?? null}
      />
    </>
  );
};
