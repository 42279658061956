import * as Sentry from '@sentry/react';

const IGNORED_SENTRY_ERRORS = [
  'JWTExpired',
  'Foreign key violation',
  'Uniqueness violation',
  /field '(.+)' not found in type: '(.+)'/,
];

type Config = {
  APP_VERSION: string | undefined;
  SENTRY_DSN: string;
  SENTRY_ENVIRONMENT: string;
  ENABLE_SESSION_REPLAY: boolean;
  tracesSampleRate?: number;
};

export const initializeSentry = (config: Config) => {
  // Enables replay for staging only now
  const sentryReplayOptions = config.ENABLE_SESSION_REPLAY
    ? {
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1.0,
        integrations: [
          Sentry.replayIntegration({
            beforeErrorSampling: error => {
              const isIgnored = IGNORED_SENTRY_ERRORS.some(ignoredError => {
                return new RegExp(ignoredError).test(error.message ?? '');
              });
              // Returning false will ignore the error therefore we want to
              // ensure that errors that are non-ignored are sent to Sentry
              return !isIgnored;
            },
          }),
        ],
      }
    : {};

  const integrations = [...(sentryReplayOptions.integrations ?? []), Sentry.browserTracingIntegration()];

  /**
   * (v-rrodrigues) Ignoring error as suggested in Sentry forum topic, it seems
   * a false positive
   *
   * @todo (v-rrodrigues) Review if we should ignore the chunk error
   * @see https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402/5
   */
  Sentry.init({
    release: config.APP_VERSION,
    dsn: config.SENTRY_DSN,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    environment: config.SENTRY_ENVIRONMENT,
    tracesSampleRate: config.tracesSampleRate,
    integrations,
    ...sentryReplayOptions,
  });
};
