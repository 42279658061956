import isString from 'lodash/isString';
import { SnackbarMessage } from 'notistack';

export const formatDisplayMessage = (message: SnackbarMessage): SnackbarMessage => {
  if (message && isString(message) && message.length > 250) {
    return `${message.substring(0, 250)}...`;
  }

  return message;
};
