import { displayNameOrEmail } from '@circadian-risk/shared';
import { PLACEHOLDER_STAFF_IMAGES } from '@circadian-risk/shared/test-utils';
import { faker } from '@faker-js/faker';
import dayjs from 'dayjs';
import { Factory } from 'fishery';

import { OrganizationUser } from './active-organization';

type OrganizationUserFactoryTransientParams = {
  user: keyof typeof PLACEHOLDER_STAFF_IMAGES;
};

export const OrganizationUserFactory = Factory.define<OrganizationUser, OrganizationUserFactoryTransientParams>(
  ({ params, transientParams: { user } }) => {
    const firstName = faker.name.firstName();
    const lastName = faker.name.lastName();
    const email = faker.internet.email();
    return {
      __typename: 'users',
      email: faker.internet.email(),
      first_name: firstName,
      last_name: lastName,
      id: faker.string.uuid(),
      last_signed_in_at: dayjs(faker.date.recent()).toISOString(),
      role: faker.helpers.arrayElement(['org-admin', 'org-member']),
      created_at: dayjs(faker.date.past()).toISOString(),
      created_by_id: null,
      displayName: displayNameOrEmail([firstName, lastName], email),
      allowAdminPasswordChange: false,
      ...params,
      avatar: {
        filepath:
          PLACEHOLDER_STAFF_IMAGES[
            (user ||
              faker.helpers.arrayElement(
                Object.keys(PLACEHOLDER_STAFF_IMAGES),
              )) as keyof typeof PLACEHOLDER_STAFF_IMAGES
          ] ?? faker.image.avatar(),
        id: faker.string.uuid(),
        ...params.avatar,
      },
    };
  },
);
