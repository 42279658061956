import React from 'react';
import { generatePath, NavLink } from 'react-router-dom';

export interface LinkCellRendererProps {
  link: string;
  value: React.ReactNode;
  organizationId: string;
}

export const LinkCellRenderer: React.FC<LinkCellRendererProps> = ({ link, value, organizationId }) => {
  const to = generatePath(link, {
    organizationId,
  });
  return <NavLink to={to}>{value}</NavLink>;
};
