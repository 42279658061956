import { Enum_Scenario_Type_Enum } from '@circadian-risk/graphql-types';
import { Chip, Tooltip } from '@mui/material';
import { ChipProps } from '@mui/material/Chip';
import capitalize from 'lodash/capitalize';

import { ScenarioIcon } from '../ScenarioIcon';

export interface ScenarioTypeChipProps extends ChipProps {
  type: Enum_Scenario_Type_Enum;
  name?: string;
  description?: string;
}

export const ScenarioTypeChip: React.FC<ScenarioTypeChipProps> = ({ type, name, description, ...chipProps }) => {
  return (
    <Tooltip arrow title={description ?? ''}>
      <Chip size="small" icon={<ScenarioIcon type={type} />} label={name ?? capitalize(type)} {...chipProps} />
    </Tooltip>
  );
};
