import merge from 'lodash/merge';
import noop from 'lodash/noop';
import { v4 } from 'uuid';

import { FileManagerDatabase, IFileItem, SubscribeHandler, UploadEndpointInfo, UploadStatus } from '../types';

export const mockBlob = new Blob();

export interface MockFileManagerDatabase extends FileManagerDatabase {
  getFiles: () => IFileItem[];
}

export const createFileManagerMockDatabase = async (): Promise<MockFileManagerDatabase> => {
  let files: IFileItem[] = [];

  let subscribeHandler: SubscribeHandler;
  return {
    getBlob: (_id: string) => {
      return Promise.resolve(mockBlob);
    },
    subscribe: handler => {
      subscribeHandler = handler;
    },
    insert: (file: File, uploadEndpointInfo: UploadEndpointInfo) => {
      files.push({
        id: v4(),
        size: file.size,
        name: file.name,
        type: file.type,
        insertedAt: new Date().getTime(),
        status: UploadStatus.Pending,
        uploadEndpointInfo,
      });
      if (subscribeHandler) {
        subscribeHandler(files);
      }
      return Promise.resolve();
    },
    remove: (id: string) => {
      const index = files.findIndex(f => f.id === id);
      if (index >= 0) {
        files.splice(index, 1);
        if (subscribeHandler) {
          subscribeHandler(files);
        }
        return Promise.resolve(true);
      }
      return Promise.resolve(false);
    },
    unsubscribe: noop,
    update: (id, update) => {
      const file = files.find(f => f.id === id);
      merge(file, update);
      if (subscribeHandler) {
        subscribeHandler(files);
      }
      return Promise.resolve();
    },
    get: () => {
      return Promise.resolve(files);
    },
    getFiles: () => files,
    removeAll: () => {
      files = [];
      return Promise.resolve(true);
    },
  };
};
