import { PropertyFile } from '@circadian-risk/form';
import { fileSrc } from '@circadian-risk/front-end-utils';
import { PropertyDescriptionRichText, Thumbnail, UserAvatar } from '@circadian-risk/presentational';
import { ReportFieldKind } from '@circadian-risk/shared';
import { Box, Button, Card, CardActions, CardContent, Divider, Typography, useTheme } from '@mui/material';
import {
  PropertyDescriptionCheckbox,
  PropertyDescriptionDate,
  PropertyDescriptionDropdown,
  PropertyDescriptionText,
  PropertyDescriptionToggle,
} from '@web-app/components/Details/DetailListing';
import React from 'react';

const FileDescriptionRenderer: React.FC<{ value?: PropertyFile }> = ({ value }) => {
  if (!value) {
    return null;
  }
  const { name, url } = value;
  return (
    <>
      <Thumbnail name={name} src={fileSrc(url)} />
      <Typography variant="body2">{name}</Typography>
    </>
  );
};

const kindToComponent: Record<ReportFieldKind, React.FC<{ value: any }>> = {
  [ReportFieldKind.Text]: PropertyDescriptionText,
  [ReportFieldKind.Date]: PropertyDescriptionDate,
  [ReportFieldKind.Numeric]: PropertyDescriptionText,
  [ReportFieldKind.Dropdown]: PropertyDescriptionDropdown,
  [ReportFieldKind.Checkbox]: PropertyDescriptionCheckbox,
  [ReportFieldKind.MultipleCheckbox]: PropertyDescriptionCheckbox,
  [ReportFieldKind.Toggle]: PropertyDescriptionToggle,
  [ReportFieldKind.RichText]: PropertyDescriptionRichText,
  file: FileDescriptionRenderer,
  [ReportFieldKind.FileSelection]: FileDescriptionRenderer,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [ReportFieldKind.UserSelection]: (props: any) => <span>{props.value}</span>,
};

export type ReportSectionFieldConflict = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  kind: string;
  relativeTime?: string;
  user?: {
    name: string;
    imgSrc?: string | null;
    lastSignedInAt?: string | Date | null;
  } | null;
};

export interface ResolveFieldConflictProps {
  current: ReportSectionFieldConflict;
  latest: ReportSectionFieldConflict;
  onUseCurrent: () => void;
  onUseLatest: () => void;
}

const ConflictDetails: React.FC<ReportSectionFieldConflict> = props => {
  const theme = useTheme();
  const latest = props;
  const KindComponent = kindToComponent[latest.kind as ReportFieldKind];

  return (
    <>
      {latest.user && (
        <Box display="flex" flexDirection="row" alignItems="center" gap={theme.spacing(1)}>
          <UserAvatar
            name={latest.user.name}
            image={latest.user.imgSrc}
            size={32}
            lastSignedInAt={latest.user.lastSignedInAt}
          />
          <Typography variant="body1">{latest.user.name}</Typography>
        </Box>
      )}
      <Box ml={5} display="flex" flexDirection="column" gap={theme.spacing(1)} my={1}>
        <KindComponent value={latest.value} />
        <Typography variant="caption">{latest.relativeTime}</Typography>
      </Box>
    </>
  );
};

export const ResolveFieldConflict: React.FC<ResolveFieldConflictProps> = props => {
  const { current, latest, onUseCurrent, onUseLatest } = props;
  return (
    <Card>
      <CardContent>
        <Typography variant="h6">Resolve Field Conflict</Typography>
        <Typography variant="body2">
          This field has been edited elsewhere. Please select which version to keep:
        </Typography>
        <Box display="flex" flexDirection="row" my={1}>
          <Box flex={1} mr={1}>
            <ConflictDetails {...current} />
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box flex={1} ml={1}>
            <ConflictDetails {...latest} />
          </Box>
        </Box>
      </CardContent>
      <CardActions>
        <Box display="flex" flexDirection="row" width="100%">
          <Box flex={1}>
            <Button size="small" color="primary" onClick={onUseCurrent}>
              Use my value
            </Button>
          </Box>
          <Box flex={1}>
            <Button size="small" color="primary" onClick={onUseLatest}>
              Use latest value
            </Button>
          </Box>
        </Box>
      </CardActions>
    </Card>
  );
};
