import { Box, BoxProps, useTheme } from '@mui/material';
import isNumber from 'lodash/isNumber';
import range from 'lodash/range';
import React from 'react';

interface CriticalityIconProps {
  maxCriticality: number;
  criticality?: number;
  color?: string;
  boxProps?: Partial<BoxProps>;
}

/**
 * Basically a dot plot that is used as a meter of criticality
 *
 * Note: criticality =< maxCriticality
 * maxCriticality > 2
 */
export const CriticalityIcon: React.FC<CriticalityIconProps> = ({ criticality, maxCriticality, color, boxProps }) => {
  const theme = useTheme();
  const activeDotColor = color ?? theme.palette.action.selected;
  return (
    <Box display="flex" justifyContent="space-between" minWidth={14 * maxCriticality} {...boxProps}>
      {range(maxCriticality).map(index => {
        let dotColor: string;
        if (isNumber(criticality) && index < criticality) {
          dotColor = activeDotColor;
        } else {
          dotColor = theme.palette.action.disabled;
        }
        return <Box key={`${index}-${activeDotColor}`} width={10} height={10} borderRadius="5px" bgcolor={dotColor} />;
      })}
    </Box>
  );
};
