import { AssessmentTypeIcon } from '@circadian-risk/presentational';
import { Avatar, Tooltip } from '@mui/material';
import React, { CSSProperties } from 'react';

import { CellRendererParams } from '../helpers';
import { AlignCell } from './AlignCell';

const iconStyles: CSSProperties = {
  color: 'white',
};

export const ItemTypeCellRenderer: React.FC<CellRendererParams<{ itemType: string }>> = ({ data: { itemType } }) => {
  const isPhysical = itemType.includes('Physical');
  const avatarStyles: CSSProperties = {
    backgroundColor: isPhysical ? '#82A5B8' : '#83B882',
    height: '32px',
    width: '32px',
  };

  return (
    <AlignCell>
      <Tooltip title={itemType} placement={'right'}>
        <Avatar style={avatarStyles}>
          <AssessmentTypeIcon
            type={isPhysical ? 'Physical Inspection' : 'Inspection Questionnaire'}
            fontSize="inherit"
            style={iconStyles}
          />
        </Avatar>
      </Tooltip>
    </AlignCell>
  );
};
