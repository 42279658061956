import { useApiClient } from '@circadian-risk/api-client-provider';
import {
  Assessment_Answers_Update_Column,
  Enum_Answer_Responses_Enum,
  useProvidePartialAnswerMutation,
} from '@circadian-risk/client-graphql-hooks';
import { PropertySelectField, PropertySelectFieldProps, PropertyTextField } from '@circadian-risk/form';
import { AnswerSemanticMeaning, useNotification } from '@circadian-risk/front-end-utils';
import { DialogActionsWithBackAction, ResponseIcon } from '@circadian-risk/presentational';
import { useOrganizationId } from '@circadian-risk/stores';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, Dialog, DialogContent, DialogTitle, LinearProgress, Typography, useTheme } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import { AssessQuestion } from './BulkReassessModal.types';

export const DISABLED_COMPLIANT_RESPONSE_TEXT =
  'Questions cannot be set as compliant, because at least one selected item is marked as missing';

export interface BulkReassessModalProps {
  open: boolean;
  onClose: () => void;
  onExited?: () => void;
  onOneByOne?: () => void;
  questions: AssessQuestion[];
  onSubmitted?: (option: AnswerSemanticMeaning) => void;
  isCompliantResponseDisabled: boolean;
  onBackClick?: () => void;
}

export interface BulkReassessFormData {
  response: AnswerSemanticMeaning;
  description?: string;
}

type BulkOption = { value: AnswerSemanticMeaning; label: React.ReactNode; icon: JSX.Element };

/** @deprecated: Use Bulk Reassess Modal from @circadian-risk/components */
export const BulkReassessModal: React.FC<BulkReassessModalProps> = ({
  open,
  onClose,
  onExited,
  onOneByOne,
  questions,
  onSubmitted,
  isCompliantResponseDisabled,
  onBackClick,
}) => {
  const theme = useTheme();
  const organizationId = useOrganizationId();
  // TODO(miking-the-viking): use generateCircadianForm [CR-4775]
  const { control, watch, handleSubmit, reset } = useForm<BulkReassessFormData>();
  const [submitting, setSubmitting] = useState(false);
  const { displayError, displaySuccess } = useNotification();
  const [providePartialAnswer] = useProvidePartialAnswerMutation();
  const { tsRestClient } = useApiClient();

  const disableCompliantResponse = isCompliantResponseDisabled || questions.some(x => x.itemIsMissing);

  const baseBulkOptions: BulkOption[] = [
    {
      label: 'Compliant',
      value: 'compliant',
      icon: <ResponseIcon response={'compliant'} />,
    },
    {
      label: 'Deficient',
      value: 'deficient',
      icon: <ResponseIcon response={'deficient'} />,
    },
    {
      label: 'Not Applicable',
      value: 'n_a',
      icon: <ResponseIcon response={'n_a'} />,
    },
    {
      label: 'Not Observed',
      value: 'n_o',
      icon: <ResponseIcon response={'n_o'} />,
    },
  ];

  const onFormSubmit = async (formData: BulkReassessFormData) => {
    if (submitting) {
      return;
    }
    setSubmitting(true);

    const assessmentQuestions: AssessQuestion[] = questions.filter(q => q.context === 'assessment');
    const reassessQuestions: AssessQuestion[] = questions.filter(q => q.context === 'reassess');

    // Process Reassess
    if (reassessQuestions.length > 0) {
      const promises = await reassessQuestions.map(async q => {
        let response: string;
        if (formData.response === 'n_a' || formData.response === 'n_o') {
          response = formData.response;
        } else if (formData.response === 'compliant') {
          response = q.compliantResponse;
        } else {
          response = q.deficientResponse;
        }

        return {
          questionId: q.questionId,
          itemId: q.itemId,
          response,
          description: formData.description ?? undefined,
        };
      });

      const answers = await Promise.all(promises);

      try {
        await tsRestClient.organizationItems.bulkReassess({
          params: { organizationId },
          body: answers,
        });
      } catch (err) {
        displayError(err as Error);
      }
    }

    // Process Assessment
    if (assessmentQuestions.length > 0) {
      const assessmentPromises = assessmentQuestions.map(async q => {
        let response: Enum_Answer_Responses_Enum;
        if (formData.response === 'n_a' || formData.response === 'n_o') {
          response = formData.response;
        } else if (formData.response === 'compliant') {
          response = q.compliantResponse;
        } else {
          response = q.deficientResponse;
        }
        const updateColumns: Assessment_Answers_Update_Column[] = ['response'];
        if (formData.description) {
          updateColumns.push('description');
        }
        await providePartialAnswer({
          variables: {
            answer: {
              assessment_item_id: q.itemId,
              question_id: q.questionId,
              response,
              description: formData.description ?? undefined,
            },
            updateColumns,
          },
        });
      });
      await Promise.all(assessmentPromises);
    }

    onClose();
    displaySuccess('New Answers Provided');
    reset();

    if (onSubmitted) {
      onSubmitted(formData.response);
    }
    setSubmitting(false);
  };

  const watchResponse = watch('response');

  const options = disableCompliantResponse ? baseBulkOptions.filter(x => x.value !== 'compliant') : baseBulkOptions;

  const customOptionRenderer = useCallback((option: BulkOption) => {
    return (
      <Box display={'flex'} alignItems={'center'}>
        <Box display={'flex'}>{option.icon}</Box>
        <Box ml={1}>{option.label}</Box>
      </Box>
    );
  }, []);

  const handleBackClick = () => {
    onClose();
    onBackClick?.();
  };

  return (
    <Dialog open={open} onClose={onClose} TransitionProps={{ onExited }} fullWidth maxWidth="sm">
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box display={'flex'} flexGrow={1}>
              <Typography variant="h5" title="bulk reassess">
                Bulk Reassess
              </Typography>
            </Box>
            {onOneByOne && (
              <Button color="primary" onClick={onOneByOne} endIcon={<ArrowForwardIcon />}>
                Reassess 1 by 1
              </Button>
            )}
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography>Provide the responses for {questions.length} questions</Typography>
          <Box display="flex" flexDirection="column" gap={theme.spacing(2)} my={2}>
            <PropertySelectField
              control={control}
              htmlName="response"
              label="Response"
              options={options}
              customRenderer={customOptionRenderer as PropertySelectFieldProps['customRenderer']}
              helperText={disableCompliantResponse ? DISABLED_COMPLIANT_RESPONSE_TEXT : undefined}
            />
            <PropertyTextField control={control} htmlName="description" label="Description" multiline rows={3} />
          </Box>

          <Typography>
            Reassessing answers allows you to provide a new response. You can decide to reassess in bulk, or reassess
            each answer one by one.
          </Typography>
        </DialogContent>
        <DialogActionsWithBackAction
          onCancelClick={onClose}
          onBackClick={onBackClick ? handleBackClick : undefined}
          actions={
            <Button type="submit" variant="contained" color="primary" disabled={!watchResponse}>
              Save
            </Button>
          }
        />
      </form>
      {submitting && <LinearProgress />}
    </Dialog>
  );
};
