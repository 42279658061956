import { GetLibraryIconsQuery } from '@circadian-risk/client-graphql-hooks';
import { LibIcon } from '@circadian-risk/presentational';
import { z } from 'zod';

const keywordsSchema = z.array(z.string());

// this is consumed in multiple places in the app so I moved this out of the module
// if you can think of a better place for this logic to live, feel free to move
export const convertIconsQueryToIcons = (data?: GetLibraryIconsQuery): LibIcon[] =>
  data?.icons.map(({ id, file, keywords }) => {
    const zodKw = keywordsSchema.safeParse(keywords);
    return {
      id,
      name: file?.original_filename ?? 'Icon',
      src: file?.filepath ?? '',
      keywords: zodKw.success ? zodKw.data : [],
      useCount: file?.item_categories_aggregate.aggregate?.count ?? undefined,
    };
  }) ?? [];
