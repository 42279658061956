import { InfoIconWithTooltip, NodeIcon } from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { Box } from '@mui/material';
import React from 'react';

import { LinkWithOrgId } from '../NavLinkWithActiveOrgId';

export interface LocationLinkProps {
  data: {
    nodeId: string;
    layerName?: string;
    breadCrumbs?: { layerName: string; locationName: string }[];
  };
  value: string;
  target?: string;
  disabled?: boolean;
}

export const LocationLink = ({ disabled, data, value: locationName, target }: LocationLinkProps) => {
  const layerName = data?.layerName;
  const breadCrumbs = data?.breadCrumbs;

  const showTooltip = breadCrumbs && layerName && layerName.toLowerCase() !== 'organization' && breadCrumbs?.length > 0;

  return (
    <>
      <LinkWithOrgId
        anchorProps={{
          'aria-label': 'location link',
          target,
          'aria-disabled': disabled,
        }}
        sx={
          disabled
            ? theme => ({
                width: 'max-content',
                pointerEvents: 'none',
                opacity: 0.5,
                color: `${theme.palette.text.disabled}!important`,
              })
            : { width: 'max-content' }
        }
        href={data?.nodeId ? `${ROUTES.NODES}/${data?.nodeId}` : ''}
      >
        <Box display="flex" alignItems={'center'}>
          {layerName && <NodeIcon fontSize="small" layerName={layerName} />}
          <Box ml={0.5}>{locationName}</Box>
        </Box>
      </LinkWithOrgId>
      {showTooltip && !disabled && (
        <InfoIconWithTooltip
          boxProps={{ pl: 1 }}
          ariaLabel={'Location Hierarchy'}
          tooltipContent={breadCrumbs!.map(bc => (
            <React.Fragment key={`breadcrumb-${bc.layerName}-${bc.locationName}`}>
              {bc.layerName}: {bc.locationName}
              <br />
            </React.Fragment>
          ))}
        />
      )}
    </>
  );
};
