import { useApiClient } from '@circadian-risk/api-client-provider';
import { useNotification } from '@circadian-risk/front-end-utils';
import { Box, Typography } from '@mui/material';
import { useMount } from 'react-use';

export interface SSOPageProps {
  onSuccess: () => void;
  onFailure: () => void;
}

export const SSOPage: React.FC<SSOPageProps> = ({ onSuccess, onFailure }) => {
  const { tsRestClient } = useApiClient();
  const { displayError } = useNotification();

  useMount(async () => {
    try {
      const { status } = await tsRestClient.auth.ssoExchange();

      if (status === 201) {
        onSuccess();
      } else {
        displayError('Failed to initialize session');
        onFailure();
      }
    } catch (ex) {
      displayError(ex);
    }
  });

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <Box>
        <Typography>Validating Session...</Typography>
      </Box>
    </Box>
  );
};
