import { HStack } from '@circadian-risk/presentational';
import { Button, Divider, Typography } from '@mui/material';

export type AssignmentType = 'assignee' | 'subscriber' | undefined;

type Props = {
  selectedType: AssignmentType;
  /**
   * If the callback is not present, a label will be displayed instead
   * @param type
   * @returns
   */
  onSelect?: (type: AssignmentType) => void;
};

export const AssignOrSubscribeCellRenderer: React.FC<Props> = ({ selectedType, onSelect }) => {
  // If we're in a read-only mode we should only display a label
  if (!onSelect) {
    // It is unlikely that the type is undefined but in that case we shall
    // not render anything
    if (!selectedType) {
      return null;
    }
    return (
      <Typography variant="subtitle2" color="primary">
        {selectedType === 'assignee' ? 'Assigned' : 'Subscribed'}
      </Typography>
    );
  }

  return (
    <HStack>
      <Button
        variant={selectedType === 'assignee' ? 'contained' : 'text'}
        onClick={() => onSelect(selectedType === 'assignee' ? undefined : 'assignee')}
      >
        {selectedType === 'assignee' ? 'Assigned' : 'Assign'}
      </Button>
      <Divider orientation="vertical" color="primary" />
      <Button
        variant={selectedType === 'subscriber' ? 'contained' : 'text'}
        onClick={() => onSelect(selectedType === 'subscriber' ? undefined : 'subscriber')}
      >
        {selectedType === 'subscriber' ? 'Subscribed' : 'Subscribe'}
      </Button>
    </HStack>
  );
};
