import { passwordRegex } from '@circadian-risk/shared';
import { z } from 'zod';

export const strongPasswordValidator = (value: string) => Boolean(passwordRegex.exec(value));

const WEAK_PASSWORD_MESSAGE = 'Password too weak, please use lowercase and uppercase letters, numbers, and symbols';

export const passwordSchema = z
  .string()
  .min(5, 'Password must be at least 5 characters long')
  .max(20, 'Password should not be over 20 characters')
  .refine(strongPasswordValidator, { message: WEAK_PASSWORD_MESSAGE });