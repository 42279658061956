import { ROUTES } from '@circadian-risk/routes';
import { useOrganizationSessionStore, useUserSessionStore } from '@circadian-risk/stores';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import * as Sentry from '@sentry/react';
import { debug } from '@web-app/lib/helpers';
import React, { useEffect } from 'react';
import { useLastLocation } from 'react-router-last-location';

import NavLinkWithActiveOrgId from '../components/NavLinkWithActiveOrgId';
import { config } from '../environments/environment';

const sentryEnabled = Boolean(config.SENTRY_DSN) && process.env.NODE_ENV !== 'test';

const NotFound: React.FC = () => {
  const [isAuthenticated, userId] = useUserSessionStore(state => [state.isAuthenticated, state.id]);
  const organizationId = useOrganizationSessionStore(state => state.id);
  const lastLocation = useLastLocation();

  debug.extend('NotFound')('You landed on the wildcard for unknown routes');
  useEffect(() => {
    if (sentryEnabled) {
      Sentry.captureEvent({
        message: '404 Hit',
        level: 'info',
        user: {
          id: isAuthenticated ? userId : 'Not authenticated',
        },
        extra: {
          isAuthenticated,
          organizationId,
          lastLocation,
          current404Path: window.location.href,
          referrer: document.referrer,
        },
      });
    }
  }, [isAuthenticated, lastLocation, organizationId, userId]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexWrap="wrap"
      height={300}
      maxWidth={200}
      margin="0 auto"
    >
      <Typography component="h1" sx={theme => ({ fontSize: theme.typography.h1.fontSize })}>
        404
      </Typography>
      <Typography component="h2">Page Not Found</Typography>
      <NavLinkWithActiveOrgId
        to={{
          pathname: isAuthenticated ? ROUTES.ROOT : ROUTES.SIGN_IN,
          state: { preventLastLocation: true },
        }}
      >
        <Button color="primary" variant="contained">
          {isAuthenticated ? 'Back to Dashboard' : 'Go to Login'}
        </Button>
      </NavLinkWithActiveOrgId>
    </Box>
  );
};

export default NotFound;
