import { Box, InputAdornmentOwnProps } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import React, { useCallback } from 'react';
import { Controller, ControllerRenderProps } from 'react-hook-form';

import { PropertyFieldProps } from '../types';

export type PropertyDateFieldProps = PropertyFieldProps & {
  minDate?: dayjs.Dayjs | Date | string | null;
  maxDate?: dayjs.Dayjs | Date | string | null;
  onChangeCallback?: (date: Date | null) => void;
  /**
   * @default end
   */
  adornmentPosition?: InputAdornmentOwnProps['position'];
  /**
   * Provides a convenient way to override/provide the underlying DatePicker props
   */
  datePickerProps?: Omit<DatePickerProps<dayjs.Dayjs>, 'value' | 'onChange' | 'label' | 'minDate' | 'maxDate'>;
};

export const PropertyDateField: React.FC<PropertyDateFieldProps> = ({
  label,
  htmlName,
  control,
  rules,
  boxProps,
  errorMessage,
  disabled,
  minDate,
  maxDate,
  onChangeCallback,
  adornmentPosition = 'end',
  datePickerProps,
  size,
}) => {
  const renderDatePicker = useCallback(
    ({ field: { onChange, value, onBlur } }: { field: ControllerRenderProps }) => {
      const changeProxy = (date?: dayjs.Dayjs | null) => {
        // Convert Dayjs date into date
        const newValue = date ? date.toDate() : null;

        // If the date is invalid do not propagate the change
        if (newValue && Number.isNaN(newValue.getTime())) {
          return;
        }

        onChange(newValue);
        if (onChangeCallback) {
          onChangeCallback(newValue);
        }
      };

      return (
        <DatePicker<dayjs.Dayjs>
          label={label}
          format="MM/DD/YYYY"
          value={value ? dayjs(value) : null}
          onChange={date => changeProxy(date)}
          data-name={label}
          disabled={disabled}
          minDate={minDate ? dayjs(minDate) : undefined}
          maxDate={maxDate ? dayjs(maxDate) : undefined}
          sx={{ width: '100%' }}
          slotProps={{
            field: { onBlur },
            textField: {
              error: Boolean(errorMessage),
              helperText: errorMessage,
              size,
            },
            inputAdornment: {
              position: adornmentPosition,
            },
            ...datePickerProps?.slotProps,
          }}
          {...datePickerProps}
        />
      );
    },
    [label, disabled, minDate, maxDate, errorMessage, size, adornmentPosition, datePickerProps, onChangeCallback],
  );

  return (
    <Box {...boxProps}>
      <Controller control={control} name={htmlName} defaultValue={null} rules={rules} render={renderDatePicker} />
    </Box>
  );
};
