import React from 'react';

import { RealWorldMarkerItem } from '../interfaces';

export const createTooltipMarkerNameList = (items: RealWorldMarkerItem[]) => {
  return (
    <>
      {items.map(e => (
        <React.Fragment key={`tooltip-marker-${e.id}`}>
          <span>{e.name}</span>
          <br />
        </React.Fragment>
      ))}
    </>
  );
};
