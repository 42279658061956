import { imageSrc } from '@circadian-risk/front-end-utils';
import { useCanUseFeatureFlag } from '@circadian-risk/stores';
import circadianLightModeLogo from '@web-app/assets/logo.svg';
import circadianDarkModeLogo from '@web-app/assets/logo-block-white.svg';
import circadianLightModeLogoWithText from '@web-app/assets/logo-horizontal-color.svg';
import circadianDarkModeLogoWithText from '@web-app/assets/logo-horizontal-white.svg';
import React, { ImgHTMLAttributes } from 'react';

type LogoVariantType = 'light' | 'dark';
export interface LogoCommonProps {
  /** @default light */
  logoVariant?: LogoVariantType;
  withText?: boolean;
  customLightLogoSrc?: string;
  customDarkLogoSrc?: string;
}

interface Props extends ImgHTMLAttributes<HTMLImageElement>, LogoCommonProps {}

const Logo: React.FC<Props> = ({ withText, logoVariant, customLightLogoSrc, customDarkLogoSrc, ...imgProps }) => {
  const { enabled: themeOverrideEnabled } = useCanUseFeatureFlag()('org_custom_theming');

  const getDarkModeLogo = () => {
    if (themeOverrideEnabled && customDarkLogoSrc) {
      return imageSrc(customDarkLogoSrc);
    }
    return withText ? circadianDarkModeLogoWithText : circadianDarkModeLogo;
  };

  const getLightModeLogo = () => {
    if (themeOverrideEnabled && customLightLogoSrc) {
      return imageSrc(customLightLogoSrc);
    }
    return withText ? circadianLightModeLogoWithText : circadianLightModeLogo;
  };

  const logoSrc = logoVariant === 'dark' ? getDarkModeLogo() : getLightModeLogo();

  return (
    <img
      src={logoSrc}
      {...imgProps}
      alt="Company Logo"
      height="100%"
      width="auto"
      referrerPolicy={imgProps.referrerPolicy}
    />
  );
};

export default Logo;
