import { ItemIcon, ItemIconProps, ItemIconSizes } from '@circadian-risk/presentational';
import { Coordinates } from '@circadian-risk/shared';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { CSSProperties } from 'react';
import { Marker } from 'react-map-gl';

import { MarkerPointLayerProps } from './MarkerPointLayer';

type MarkerFromPoint = {
  id: string;
  coordinates: Coordinates;
  draggable: boolean;
  sx: SxProps;
  handlePointClick: MarkerPointLayerProps['handlePointClick'];
  handlePointChange: MarkerPointLayerProps['handlePointChange'];
  itemIconProps?: Partial<ItemIconProps>;
  title: string;
  iconImageUrl?: ItemIconProps['iconSrc'];
};

export const markerFromPoint = ({
  id,
  coordinates,
  draggable,
  title,
  sx,
  iconImageUrl,
  handlePointClick,
  handlePointChange,
  itemIconProps = {},
}: MarkerFromPoint) => {
  const itemIconStyle: CSSProperties = { cursor: 'pointer' };
  if (draggable) {
    itemIconStyle.cursor = 'move';
    itemIconStyle.zIndex = 9999;
  }

  return (
    coordinates && (
      <Marker
        draggable={draggable}
        key={`map-point-${id}`}
        onDragEnd={({ lngLat }) => {
          handlePointChange(id, [lngLat.lng, lngLat.lat]);
        }}
        latitude={coordinates[1]}
        longitude={coordinates[0]}
      >
        <Box onClick={() => handlePointClick({ id, coordinates })} sx={sx} role="presentation">
          <ItemIcon
            alt={title ?? id}
            iconSrc={iconImageUrl}
            {...itemIconProps}
            size={ItemIconSizes.large}
            style={itemIconStyle}
            teardrop
          />
        </Box>
      </Marker>
    )
  );
};
