import { Chip, ChipProps } from '@mui/material';
import React from 'react';

import { NodeIcon } from '../..';

export interface LocationChipProps extends Partial<ChipProps> {
  layerName: string;
  locationName?: string;
}

export const LocationChip: React.FC<LocationChipProps> = ({ layerName, locationName, ...chipProps }) => (
  <Chip icon={<NodeIcon layerName={layerName} />} label={locationName ?? layerName} {...chipProps} />
);
