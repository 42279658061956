import { useQuery } from '@apollo/client';
import { useApiClient } from '@circadian-risk/api-client-provider';
import { TypedNodes } from '@circadian-risk/client-graphql-hooks';
import { FCC } from '@circadian-risk/front-end-utils';
import { sessionAccessPayloadSchema } from '@circadian-risk/shared';
import { useUserSessionStore } from '@circadian-risk/stores';
import ms from 'ms';
import React from 'react';
import { useInterval } from 'react-use';
import Session from 'supertokens-web-js/recipe/session';

import { EulaPage } from './EulaPage';

export interface EulaBoundaryProps {
  children: React.ReactNode;
}

export const EulaBoundary: FCC = ({ children }) => {
  const { tsRestClient } = useApiClient();
  const needsToSignEula = useUserSessionStore(state => state.needsToSignEula);
  const setNeedsToSignEula = useUserSessionStore(state => state.setNeedsToSignEula);

  useInterval(async () => {
    const result = await tsRestClient.eula.checkEula();
    if (result.status === 200) {
      const { needsToSignEula } = result.body;
      setNeedsToSignEula(needsToSignEula);
    }
    // Every hour we can check to see if there happens to be a new EULA. This will automatically
    // be checked as well any time the user signs in or switches orgs
  }, ms('1h'));

  const { data, loading } = useQuery(TypedNodes.GetMostRecentEulaDocument, {
    skip: !needsToSignEula,
    context: {
      headers: {
        'x-hasura-role': 'app-user',
      },
    },
  });

  if (!needsToSignEula) {
    return <>{children}</>;
  }

  if (!data || loading) {
    return <div></div>;
  }

  const latestEula = data.eulas[0];

  const handleAcceptAgreementClick = async () => {
    if (!latestEula) return;

    await tsRestClient.eula.signEula({ body: { eulaId: latestEula.id } });
    const updatedSession = await Session.getAccessTokenPayloadSecurely();
    const parsedSession = sessionAccessPayloadSchema.parse(updatedSession);
    setNeedsToSignEula(parsedSession.needsToSignEula);
  };

  if (needsToSignEula) {
    return (
      <EulaPage onAcceptAgreement={handleAcceptAgreementClick} eulaText={latestEula?.agreement ?? 'Eula not found'} />
    );
  }

  return null;
};
