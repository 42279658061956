import React, { useEffect } from 'react';
import { useMap } from 'react-map-gl';

export interface RemovePoiProps {
  mapId: string;
}

export const RemovePoi: React.FC<RemovePoiProps> = ({ mapId }) => {
  const maps = useMap();
  const map = maps ? maps[mapId] : null;

  useEffect(() => {
    if (map) {
      if (map.isStyleLoaded() && map.getLayer('poi-label')) {
        map.getMap().removeLayer('poi-label');
      }
    }
  }, [map]);

  return null;
};
