import { AgAreaSeriesOptions } from 'ag-charts-community';
import dayjs, { Dayjs } from 'dayjs';

const toStartOfMonthDay = (day: Dayjs) => day.startOf('month').startOf('day');

/**
 * Used to define an Area-Series chart for thresholds
 */
export interface ThresholdConfig<XKey extends string, XValue, YKeys extends string, YValue extends number> {
  thresholds: Array<{
    /**
     * Threshold key
     */
    key: YKeys;
    /**
     * Color to fill with
     */
    fill: string;
  }>;
  data: Array<Record<XKey, XValue> & Record<YKeys, YValue>>;
  fillOpacity?: number;
}

/**
 * Given a ThresholdConfig, ignore the xKey and
 * return an array of the keys ordered from smallest value to greatest
 *
 * ```
 * // given
 * {
 *   time: new Date(),
 *   inadequate: 100,
 *   borderline: 50,
 *   optimal: 20
 * }
 *
 * // returns
 * ['optimal', 'borderline', 'inadequate']
 * ```
 */
export const orderedThresholdKeys = <XKey extends string, XValue, YKeys extends string, YValue extends number>(
  xKey: XKey,
  data: ThresholdConfig<XKey, XValue, YKeys, YValue>['data'][number],
) => {
  const { [xKey]: _xKeyIgnored, ...thresholds } = data;

  const thresholdKeys = Object.keys(thresholds) as (keyof typeof thresholds)[];

  // map measures into an ordered list of thresholds
  const orderedThresholds = thresholdKeys.sort((m1, m2) => {
    const a = thresholds[m1];
    const b = thresholds[m2];

    return a - b;
  });

  return orderedThresholds;
};

/**
 * An area chart sums all the values for the total area, this is not desireable for how thresholds are displayed.
 * This method will break down the thresholds as if they were sums of an area chart
 *
 * Given optimal(10), borderline(20), inadequate(30), by default an area graph will show that data as a total of 60 as their value is their distinct y-area (10 + 20 + 30)
 *
 * However we want the maximum value to be the max of inadequate (30), so these thresholds need to be summarized as their diffs from the previous values:
 *
 * optimal(10), borderline(10), inadequate(10) so that they sum to 30 and all only have a y-area of 10
 */
const summarizeThresholdData = <XKey extends string, XValue, YKeys extends string, YValue extends number>(
  xKey: XKey,
  data: ThresholdConfig<XKey, XValue, YKeys, YValue>['data'],
  oldestDate?: Date,
) => {
  return data.map((thresholdConfig, index) => {
    const { [xKey]: xValue, ...thresholds } = thresholdConfig;

    const modifiedThresholds = {} as { [key in YKeys]: YValue };

    // map measures into an ordered list of thresholds
    const orderedThresholds = orderedThresholdKeys<XKey, XValue, YKeys, YValue>(xKey, thresholdConfig);

    // now iteratively remove the sum of the previous values
    let sum = 0;

    orderedThresholds.forEach(measure => {
      const actualDiffValue = thresholds[measure] - sum;
      sum = sum + actualDiffValue;

      modifiedThresholds[measure] = actualDiffValue as YValue;
    });

    // if this is the first threshold in the chronological threshold array,
    // then push the date back to encompass the very first of its month.
    // This will to force Ag-Chart to render that month's label in the "month" tick frequency
    let startOfMonthDay = dayjs(xValue);
    switch (index) {
      case 0:
        // For the first data point, we need the oldest between oldestDate and startOfMonthDay

        if (oldestDate && startOfMonthDay.isAfter(oldestDate)) {
          startOfMonthDay = dayjs(oldestDate);
        }
        startOfMonthDay = toStartOfMonthDay(startOfMonthDay);
        break;
      case data.length - 1:
        startOfMonthDay = toStartOfMonthDay(startOfMonthDay).add(1, 'months');
        break;
      default:
        break;
    }

    return { ...thresholdConfig, [xKey]: startOfMonthDay.toDate(), ...modifiedThresholds };
  });
};

/**
 * The threshold is actually just an area series that is shown concurrently with the other line series data.
 *
 * This maps the config and other parameters to an AgGrid-compatible Area Series Options type
 */
export const thresholdToAgSeriesAndData = <XKey extends string, XValue, YKeys extends string, YValue extends number>(
  xKey: XKey,
  { thresholds, data: dataInput, fillOpacity = 0.3 }: ThresholdConfig<XKey, XValue, YKeys, YValue>,
  oldestDate?: Date,
): {
  series: Array<AgAreaSeriesOptions>;
  data: Record<XKey | YKeys, XValue | YValue>[];
} => {
  const series = thresholds.map(({ key, fill }) => ({
    type: 'area' as const,
    stacked: true,
    showInLegend: false,
    xKey,
    yKey: key,
    fill,
    fillOpacity,
  }));

  const data = summarizeThresholdData<XKey, XValue, YKeys, YValue>(xKey, dataInput, oldestDate);

  return { data, series };
};
