import { percentageWithPadding } from '@circadian-risk/shared';
import { Box, Tooltip, useTheme } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import React, { CSSProperties } from 'react';

import { AnimatedCheckmarkIcon } from './AnimatedCheckmarkIcon';
import { CircularProgressWithBg } from './CircularProgressWithBg';

const absolutePosition: CSSProperties = { position: 'absolute' };

interface PercentCircularProgressProps {
  value: number;
  disableTooltip?: boolean;
  /**
   * If progress is not yet 100% and the value is set to true then the filling color
   * will be white
   *
   * @default false
   */
  progressBgColorWhite?: boolean;
  size?: number;
}

export const PercentCircularProgress: React.FC<PercentCircularProgressProps> = ({
  size = 24,
  value,
  disableTooltip,
  progressBgColorWhite,
}) => {
  const percentage = Math.round(value);
  const theme = useTheme();

  const dimensions = { width: size, height: size };
  const iconStyles: CSSProperties = { width: size, height: size };

  const tooltipTitle = disableTooltip ? '' : `${percentage}% Complete`;

  return (
    <Tooltip title={tooltipTitle} placement="top" arrow>
      <Box
        display="inline-flex"
        justifyContent="center"
        alignItems="center"
        width={dimensions.width}
        height={dimensions.height}
        position={'relative'}
      >
        <AnimatePresence>
          <Box
            component={motion.div}
            key={'Progress'}
            sx={{ ...absolutePosition }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <CircularProgressWithBg
              size={size}
              value={percentageWithPadding(percentage)}
              progressBgColorWhite={progressBgColorWhite}
            />
          </Box>

          {percentage === 100 && (
            <Box
              key={'Complete'}
              component={motion.div}
              sx={{
                ...absolutePosition,
                ...iconStyles,
                display: 'flex',
                top: 0,
                left: 0,
                borderRadius: 100,
              }}
              initial={{ opacity: 1, scale: 0, rotate: 540 }}
              animate={{
                opacity: 1,
                scale: 1,
                rotate: 0,
                backgroundColor: progressBgColorWhite ? theme.palette.common.white : theme.palette.success.main,
                color: progressBgColorWhite ? theme.palette.success.main : theme.palette.common.white,
              }}
              exit={{ scale: 0 }}
            >
              <AnimatedCheckmarkIcon sx={{ top: 0, left: 0, ...iconStyles, ...absolutePosition }} />
              <Box
                component={motion.div}
                sx={{
                  top: 0,
                  left: 0,
                  zIndex: -1,
                  borderRadius: 50,
                  backgroundColor: progressBgColorWhite ? theme.palette.common.white : theme.palette.success.main,
                  ...iconStyles,
                  ...absolutePosition,
                }}
                initial={{ opacity: 1, scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ type: 'spring' }}
              />
            </Box>
          )}
        </AnimatePresence>
      </Box>
    </Tooltip>
  );
};
