import { alpha, ListItem, ListItemProps } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';

/**
 * A Material UI ListItem as a link
 */
export const ListItemNav: React.FC<ListItemProps & { to: string; selected?: boolean }> = props => {
  const { to, children, selected, sx } = props;
  const history = useHistory();

  return (
    <ListItem
      button
      disableRipple
      onClick={() => history.push(to)}
      classes={props.classes}
      sx={[
        theme => ({
          '& a:hover': {
            backgroundColor: 'transparent',
          },
          '&.Mui-selected': {
            backgroundColor: alpha(theme.palette.primary.light, 0.16),
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      selected={selected}
    >
      {children}
    </ListItem>
  );
};
