import { Box, Card, CardHeader, useTheme } from '@mui/material';
import sumBy from 'lodash/sumBy';
import React from 'react';

import { CriticalityNumberAndLabel } from '../AssessmentCompliancePercentCard/CriticalityNumberAndLabel';
import { DeficienciesByLocationGrid, DeficienciesByLocationGridProps } from './DeficienciesByLocationGrid';

export interface AssessmentDeficienciesProps {
  criticalityLabels: DeficienciesByLocationGridProps['criticalityLabels'];
  locations: DeficienciesByLocationGridProps['locations'];
  showLinks: boolean;
  onFlaggedClick?: () => void;
}

export const AssessmentDeficiencies: React.FC<AssessmentDeficienciesProps> = React.memo(
  ({ locations, criticalityLabels, showLinks, onFlaggedClick }) => {
    const theme = useTheme();
    const { criticality: critColors } = theme.palette.organizationColors;

    const crit1Count = sumBy(locations, l => l.criticality1count ?? 0);
    const crit2Count = sumBy(locations, l => l.criticality2count ?? 0);
    const crit3Count = sumBy(locations, l => l.criticality3count ?? 0);
    const flaggedCount = sumBy(locations, l => l.flaggedCount ?? 0);
    const totalCount = crit1Count + crit2Count + crit3Count;

    return (
      <Card aria-label={'assessment-deficiencies'}>
        <CardHeader title="Total Deficiencies in Assessment" />
        <Box display="flex" justifyContent="space-around" flexWrap="wrap" px={4} py={4}>
          <Box display="flex" justifyContent="center" flex={1}>
            <CriticalityNumberAndLabel
              color={theme.palette.text.primary}
              label="Total Deficiencies"
              num={totalCount}
              disableView
            />
          </Box>

          <Box display="flex" justifyContent="space-between" gap={theme.spacing(2)} flex={1}>
            <CriticalityNumberAndLabel
              color={critColors[1]}
              label={criticalityLabels[1]}
              num={crit1Count}
              disableView
            />
            <CriticalityNumberAndLabel
              color={critColors[2]}
              label={criticalityLabels[2]}
              num={crit2Count}
              disableView
            />
            <CriticalityNumberAndLabel
              color={critColors[3]}
              label={criticalityLabels[3]}
              num={crit3Count}
              disableView
            />

            <CriticalityNumberAndLabel
              color={theme.palette.text.secondary}
              label="Flagged"
              num={flaggedCount}
              onClick={onFlaggedClick}
              disableView
            />
          </Box>
        </Box>

        <DeficienciesByLocationGrid locations={locations} criticalityLabels={criticalityLabels} showLinks={showLinks} />
      </Card>
    );
  },
);
