import { FCC } from '@circadian-risk/front-end-utils';
import { MenuGroup } from '@web-app/lib/getNavigationGroups';
import React, { useContext } from 'react';

interface LayoutState {
  navigationMenuGroups: MenuGroup[];
}

const defaultLayoutState: LayoutState = {
  navigationMenuGroups: [],
};

const LayoutContext = React.createContext(defaultLayoutState);

export const useLayoutContext = () => useContext(LayoutContext);

export const LayoutContextProvider: FCC<LayoutState> = ({ children, ...props }) => {
  return <LayoutContext.Provider value={props}>{children}</LayoutContext.Provider>;
};
