import { FCC } from '@circadian-risk/front-end-utils';
import CloseIcon from '@mui/icons-material/Close';
import { Box, DialogTitle, IconButton } from '@mui/material';
import React from 'react';

export type DialogTitleWithCloseIconProps = { showCloseIcon?: boolean; onClose: () => void };

export const DialogTitleWithCloseIcon: FCC<DialogTitleWithCloseIconProps> = ({ showCloseIcon, onClose, children }) => {
  const Wrapper = children ? DialogTitle : React.Fragment;

  if (!showCloseIcon) {
    return <Wrapper>{children}</Wrapper>;
  } else {
    return (
      <Wrapper>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {children}
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Wrapper>
    );
  }
};
