import { CenterMessagePage } from '@circadian-risk/layout';
import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { useSessionContext } from './SessionContext';

interface SessionExpiredProps {
  redirectMs?: number | null;
}

export const SessionExpired: React.FC<SessionExpiredProps> = ({ redirectMs = 5000 }) => {
  const history = useHistory();
  const { onSessionExpiredRedirectTo } = useSessionContext();

  useEffect(() => {
    let timeoutFn: number;
    if (redirectMs && redirectMs > 0) {
      timeoutFn = window.setTimeout(() => {
        history.push(onSessionExpiredRedirectTo);
      }, redirectMs);
    }
    return () => {
      window.clearTimeout(timeoutFn);
    };
    // Only run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CenterMessagePage title="Your session has expired...">
      <Typography variant="body1">
        You will be directed to the <Link to={onSessionExpiredRedirectTo}>Sign In Page</Link>
      </Typography>
    </CenterMessagePage>
  );
};
