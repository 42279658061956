import { useApiClient } from '@circadian-risk/api-client-provider';
import { useUpdateFileNameMutation } from '@circadian-risk/client-graphql-hooks';
import { FileList, FileListItem, getImageUrls, MarkupReadyFile, useFileDownloadContext } from '@circadian-risk/form';
import { useNotification } from '@circadian-risk/front-end-utils';
import {
  convertAttachmentFilesToLightboxImages,
  HStack,
  ImageProcessingOptions,
  ItemIconSizes,
  ItemWithLink,
  LightBoxProvider,
  VStack,
} from '@circadian-risk/presentational';
import { useOrganizationId, useStandardOrgRoleQueryPrecedence } from '@circadian-risk/stores';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { CollapsibleCard } from '@web-app/components/CollapsibleCard/CollapsibleCard';
import { useSubmitImageMarkup } from '@web-app/components/LightBox';
import sortBy from 'lodash/sortBy';

import { SmartLocationLink } from '../SmartLocationLink';
import { useFilesDialog } from '.';

export interface Answer {
  questionText: string;
  files: MarkupReadyFile[];
}

export interface FilesDialogData {
  files?: MarkupReadyFile[];
  answers?: Answer[];

  itemName?: string;
  locationId?: string;
  dialogTitle?: string;
  itemCategoryName?: string;
  itemCategoryIconSrc?: string;
}

export interface FilesDialogProps extends FilesDialogData {
  open: boolean;
  subtitle?: string;
  onClose: () => void;
  onImageProcessingDone?: (newImageData: MarkupReadyFile) => void;
  enableImageEditing?: boolean;
}

export const FilesDialog: React.FC<FilesDialogProps> = ({
  open,
  files = [],
  answers = [],
  onClose,
  enableImageEditing,
  onImageProcessingDone,
  subtitle,
  locationId,
  dialogTitle,
  itemName,
  itemCategoryName,
  itemCategoryIconSrc,
}) => {
  const organizationId = useOrganizationId();
  const context = useStandardOrgRoleQueryPrecedence();
  const [updateFileName] = useUpdateFileNameMutation({ context });
  const { displayError, displaySuccess } = useNotification();
  const { downloadOrgFile } = useFileDownloadContext();
  const { tsRestClient } = useApiClient();
  const submitImageMarkup = useSubmitImageMarkup();

  const { onFileChange, onFileDelete } = useFilesDialog();

  const tryOrFailWithNotification = async (
    action: () => Promise<unknown>,
    onSuccess?: () => void,
    successMessage?: string,
    failMessage?: string,
  ) => {
    try {
      await action();
      successMessage && displaySuccess(successMessage);
      onSuccess && onSuccess();
    } catch (error) {
      displayError(failMessage ?? error);
    }
  };

  const handleFileDelete = async (fileId: string) => {
    const action = () => tsRestClient.files.delete({ params: { organizationId, fileId }, body: {} });
    const onSuccess = () => onFileDelete(fileId);
    await tryOrFailWithNotification(action, onSuccess, 'Successfully deleted');
  };

  const handleFileRename = async (id: string, name: string) => {
    const action = () => updateFileName({ variables: { id, name } });
    const onSuccess = () => onFileChange(id, { name });
    await tryOrFailWithNotification(action, onSuccess, 'Successfully renamed');
  };

  const handleImageProcessing = async (options: ImageProcessingOptions) => {
    const { status, body } = await submitImageMarkup(options);

    if (status === 200 && onImageProcessingDone) {
      const { latestUrl, markup, originalUrl, thumbnailUrl } = getImageUrls(
        body.filepath,
        { latest_image_path: body.latest_image_path, markup: body.markup, alternative_sizes: {} },
        body.updated_at,
      );

      onImageProcessingDone({
        id: body.id,
        name: body.original_filename,
        url: latestUrl,
        markup,
        originalUrl: originalUrl!,
        thumbnailUrl,
      });
    }
  };

  const filesSorted = sortBy(files, f => f.name);

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ style: { minWidth: 500, minHeight: 250 } }}>
      <DialogTitle>
        {dialogTitle ?? (
          <HStack>
            <HStack flex={1}>
              <ItemWithLink
                name={itemName ?? 'Item'}
                itemCategoryName={itemCategoryName}
                iconSrc={itemCategoryIconSrc}
                size={ItemIconSizes.large}
              />{' '}
              - Files
            </HStack>
            {locationId && (
              <Typography variant="caption">
                <SmartLocationLink nodeId={locationId} />
              </Typography>
            )}
          </HStack>
        )}
      </DialogTitle>

      <DialogContent>
        <VStack spacing={4}>
          <LightBoxProvider
            images={convertAttachmentFilesToLightboxImages(filesSorted)}
            onImageProcessing={enableImageEditing ? handleImageProcessing : undefined}
          >
            <FileList title={subtitle ?? 'File Attachments'}>
              {filesSorted.map(file => (
                <FileListItem
                  key={file.id}
                  id={file.id}
                  name={file.name}
                  url={file.url}
                  thumbnailUrl={file.thumbnailUrl ?? null}
                  onDownload={() => downloadOrgFile(file.id, organizationId, file.name)}
                  onRename={name => handleFileRename(file.id, name)}
                  onDelete={() => handleFileDelete(file.id)}
                  divider={false}
                />
              ))}
            </FileList>
          </LightBoxProvider>

          {answers && answers.some(answer => answer.files?.length > 0) && (
            <CollapsibleCard title="Files attached to related answers" collapsed={Boolean(files.length)} elevation={4}>
              <VStack spacing={2}>
                {answers
                  .filter(answer => answer.files?.length > 0)
                  .map(answer => {
                    const sortedAnswerFiles = sortBy(answer.files, f => f.name);
                    return (
                      <LightBoxProvider
                        key={answer.questionText}
                        images={convertAttachmentFilesToLightboxImages(sortedAnswerFiles ?? [])}
                        onImageProcessing={enableImageEditing ? handleImageProcessing : undefined}
                      >
                        <FileList title={answer.questionText}>
                          {sortedAnswerFiles.map(file => (
                            <FileListItem
                              key={file.id}
                              id={file.id}
                              name={file.name}
                              url={file.url}
                              thumbnailUrl={file.thumbnailUrl ?? null}
                              onDownload={() => downloadOrgFile(file.id, organizationId, file.name)}
                              onRename={name => handleFileRename(file.id, name)}
                              onDelete={() => handleFileDelete(file.id)}
                              divider={false}
                            />
                          ))}
                        </FileList>
                      </LightBoxProvider>
                    );
                  })}
              </VStack>
            </CollapsibleCard>
          )}
        </VStack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
