import { OpenInNewTabLink, VStack } from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { useOrganizationId } from '@circadian-risk/stores';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { generatePath } from 'react-router-dom';

import { ScenarioLinkInfo } from './shared-types';

interface ScenarioLinksProps {
  scenarios?: ScenarioLinkInfo[] | null;
  /** @default false */
  disableTitle?: boolean;
}

export const ScenarioLinks: React.FC<ScenarioLinksProps> = ({ scenarios, disableTitle }) => {
  const organizationId = useOrganizationId();

  if (!scenarios) {
    return null;
  }

  return (
    <Box display={'flex'} alignContent={'center'} alignItems={'center'}>
      <VStack spacing={1}>
        {!disableTitle && <Typography sx={{ display: 'inline-flex', marginRight: '2px' }}>Scenarios:</Typography>}
        {scenarios.map(sc => (
          <OpenInNewTabLink
            key={sc.id}
            text={sc.name}
            link={generatePath(ROUTES.SCENARIOS_DETAIL, { organizationId, id: sc.id })}
            spanProps={{
              width: '100%',
            }}
          />
        ))}
      </VStack>
    </Box>
  );
};
