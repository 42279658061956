import { LocationChip, LocationWithIcon, Stackable, VStack } from '@circadian-risk/presentational';
import { Chip, Tooltip } from '@mui/material';

import { LocationPickerOption } from './types';

const maxLocations = 4;

const splitResults = (locations: LocationPickerOption[]) => {
  const limit = maxLocations - 1;
  let visibleLocations = locations.slice(0, limit).reverse().filter(Boolean);
  let extras = locations.slice(limit);

  if (extras.length === 1) {
    visibleLocations = locations.reverse();
    extras = [];
  }

  return {
    visibleLocations,
    extras,
  };
};

export const SelectedLocationsViewer: React.FC<{ locations: LocationPickerOption[] }> = ({ locations }) => {
  const { visibleLocations, extras } = splitResults(locations);

  const locationChips: React.ReactNode[] = [];

  visibleLocations.forEach(location => {
    locationChips.push(
      <LocationChip
        color="secondary"
        variant="outlined"
        sx={{ bgcolor: 'background.paper' }}
        key={location.id}
        layerName={location.layerName}
        locationName={location.name}
      />,
    );
  });

  if (extras.length > 0) {
    locationChips.push(
      <Tooltip
        key={'extras'}
        title={
          <VStack>
            {extras.map(l => (
              <LocationWithIcon key={l.id} variant="caption" layerName={l.layerName} locationName={l.name} />
            ))}
          </VStack>
        }
      >
        <Chip
          variant="outlined"
          sx={{ bgcolor: 'background.default', cursor: 'pointer' }}
          label={`+${extras.length}`}
        />
      </Tooltip>,
    );
  }

  return <Stackable sx={{ borderRadius: 4 }}>{locationChips}</Stackable>;
};
