import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { SvgIconProps } from '@mui/material';

export const DRAWER_VARIANTS = ['sideDrawer', 'bottomDrawer', 'topDrawer', 'treeLeaf'] as const;

type Variant = (typeof DRAWER_VARIANTS)[number];

const rotateDegByVariant: Record<Variant, { expanded: number; default: number }> = {
  sideDrawer: {
    expanded: 90,
    default: -90,
  },
  bottomDrawer: {
    expanded: 0,
    default: 180,
  },
  topDrawer: {
    expanded: 180,
    default: 0,
  },
  treeLeaf: {
    expanded: 180,
    default: 90,
  },
};

export interface ChevronControlsProps {
  variant: Variant;
  iconProps?: SvgIconProps;
  expanded: boolean;
}
export const ChevronControls: React.FC<ChevronControlsProps> = ({ expanded, variant, iconProps }) => {
  const rotateDeg = rotateDegByVariant[variant];
  return (
    <ExpandLessIcon
      {...iconProps}
      sx={{
        transition: theme =>
          theme.transitions.create('transform', {
            duration: theme.transitions.duration.shorter,
          }),
        transform: `rotate(${expanded ? rotateDeg.expanded : rotateDeg.default}deg)`,
      }}
    />
  );
};
