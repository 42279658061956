import { FCC } from '@circadian-risk/front-end-utils';
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useUnmount } from 'react-use';

import { createDatabaseForUser, Database } from './database';

export interface DatabaseContextProps {
  database: Database;
}

// @ts-expect-error
export const DatabaseContext = createContext<DatabaseContextProps>();

export const useDatabaseContext = () => useContext(DatabaseContext);

export interface DatabaseProviderProps {
  userId: string;
  organizationId: string;
}

export const DatabaseProvider: FCC<DatabaseProviderProps> = ({ children, userId, organizationId }) => {
  const [database, setDatabase] = useState<Database | undefined>(undefined);
  const isMounted = useRef(true);

  useUnmount(() => (isMounted.current = false));

  useEffect(() => {
    const provideDb = async () => {
      const db = await createDatabaseForUser(userId, organizationId);

      // @ts-expect-error
      window.myDb = db;

      if (isMounted.current) {
        setDatabase(db);
      }
    };

    void provideDb();
  }, [userId, organizationId]);

  const value = useMemo(() => ({ database }), [database]);

  if (!value.database) {
    return <span>Initializing DB...</span>;
  }

  const safeValue = value as DatabaseContextProps;

  return <DatabaseContext.Provider value={safeValue}>{children}</DatabaseContext.Provider>;
};
