import { useGetAssessmentLocationReportFilesClientSideQuery } from '@circadian-risk/client-graphql-hooks';
import {
  FileListItem,
  FileUploadDropzone,
  PropertyFile,
  PropertyFileFieldControlledProps,
  useFileDownloadContext,
} from '@circadian-risk/form';
import { fileSrc } from '@circadian-risk/front-end-utils';
import { useGqlQueryHandler } from '@circadian-risk/graphql-utils';
import { convertAttachmentFilesToLightboxImages, LightBoxProvider } from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { FileOption, fileOptionSchema } from '@circadian-risk/shared';
import { Alert, Box, Button, ButtonGroup, SxProps, Typography } from '@mui/material';
import NavLinkWithActiveOrgId from '@web-app/components/NavLinkWithActiveOrgId';
import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useRef, useState } from 'react';
import { useController } from 'react-hook-form';
import { useEffectOnce } from 'react-use';
import { v4 } from 'uuid';

type CurrentFileForOption = {
  name: string;
  id: string;
  url: string;
};

export interface PropertyFileSelectionFieldProps extends PropertyFileFieldControlledProps {
  options: (FileOption & { currentFile?: CurrentFileForOption })[];
  organizationId: string;
  locationId: string;
}

export interface PropertyFileSelectionFieldWrapperProps extends PropertyFileFieldControlledProps {
  options: FileOption[];
  organizationId: string;
  assessmentId: number;
}

export type PropertyFileSelectionValue = (PropertyFile & { selectedOption: string }) | null;

const alertSx: SxProps = {
  alignItems: 'center',
};

export const PropertyFileSelectionField = (props: PropertyFileSelectionFieldProps) => {
  const {
    htmlName,
    disabled,
    options,
    control,
    locationId,
    boxProps,
    inputProps,
    errorMessage,
    uploadFn,
    organizationId,
    label,
    endAdornment,
    deleteFn,
  } = props;
  const { field } = useController({
    name: htmlName,
    control,
    rules: { required: true },
  });

  const initialFieldValue = useRef<PropertyFileSelectionValue | undefined>();
  const fieldValue: PropertyFileSelectionValue | undefined = field.value;
  const { downloadOrgFile } = useFileDownloadContext();

  const [manualFile, setManualFile] = useState<PropertyFileSelectionValue>(
    fieldValue?.selectedOption === 'manual' ? fieldValue : null,
  );
  const [selectedOption, setSelectedOption] = useState<string | null>(fieldValue ? fieldValue.selectedOption : null);

  useEffectOnce(() => {
    initialFieldValue.current = fieldValue;
  });

  useEffect(() => {
    if (fieldValue === null) {
      setManualFile(null);
    }
  }, [fieldValue]);

  const onButtonChange = (newSelectedOption: string | null) => {
    setSelectedOption(newSelectedOption);
    if (newSelectedOption === 'manual') {
      field.onChange(manualFile);
      return;
    }
    if (newSelectedOption === null) {
      field.onChange(null);
      return;
    }
    const option = options.find(o => o.value === newSelectedOption)!;
    const parsedOption = fileOptionSchema.parse(option);
    field.onChange({
      id: parsedOption.id,
      name: parsedOption.name,
      url: parsedOption.url,
      selectedOption: newSelectedOption,
    });
  };

  const handleFilesAdded = async (files: File[]) => {
    // Assume only one file uploaded
    const file = files[0];
    let newValue: PropertyFileSelectionValue = {
      id: v4(),
      name: file.name,
      url: URL.createObjectURL(file),
      selectedOption: selectedOption!,
      file,
    };

    if (uploadFn) {
      const result = await uploadFn(file);
      newValue = {
        ...newValue,
        ...result,
      };
    }
    field.onChange(newValue);
    setManualFile(newValue);
  };

  const selectedOptionObject = options.find(o => o.value === fieldValue?.selectedOption);

  const renderAdditionalInfo = () => {
    if (selectedOption !== 'location-photo' && selectedOption !== 'floor-plan') {
      return null;
    }

    const handleUseCurrentFile = () => {
      const currentFile = selectedOptionObject!.currentFile!;
      field.onChange({
        id: currentFile.id,
        name: currentFile.name,
        url: currentFile.url,
        selectedOption,
      });
    };

    return (
      <>
        {selectedOptionObject?.currentFile && selectedOptionObject.currentFile.id !== fieldValue?.id && (
          <Alert severity="info" icon={null} variant="outlined" sx={alertSx}>
            <Box display="flex" flexDirection="row" alignItems="center" gap={'8px'}>
              <Box>This location's {selectedOptionObject?.label} has been updated</Box>
              <Button size="small" onClick={handleUseCurrentFile} aria-label="use new file">
                Use New file
              </Button>
            </Box>
          </Alert>
        )}
        {!fieldValue?.id && !selectedOptionObject?.currentFile && (
          <Alert severity="warning" icon={null} variant="outlined" sx={alertSx}>
            <Box display="flex" flexDirection="row" alignItems="center" gap={'8px'}>
              <Box>There is no {selectedOptionObject?.label} for this location</Box>
              <NavLinkWithActiveOrgId to={`${ROUTES.NODES}/${locationId}`} target="_blank">
                <Button size="small">Open Location Details</Button>
              </NavLinkWithActiveOrgId>
            </Box>
          </Alert>
        )}
      </>
    );
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start" {...boxProps}>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" my={0.5} mx={1.75}>
        <Typography variant="body1">{label}</Typography>
        {endAdornment}
      </Box>
      <ButtonGroup
        disableElevation
        disableRipple
        variant="contained"
        disabled={disabled}
        sx={theme => ({ border: `1px solid ${theme.palette.grey[600]}` })}
      >
        {options.map(option => {
          return (
            <Button
              key={option.value}
              onClick={() => {
                onButtonChange(selectedOption === option.value ? null : option.value);
              }}
              color={selectedOption === option.value ? 'primary' : undefined}
              aria-label={option.label}
              aria-current={selectedOption === option.value}
            >
              {option.label}
            </Button>
          );
        })}
      </ButtonGroup>
      <Box mt={1}>
        {errorMessage && (
          <Typography variant="body2" color="error">
            {errorMessage}
          </Typography>
        )}
        {selectedOption === 'manual' && <FileUploadDropzone onFilesAdded={handleFilesAdded} inputProps={inputProps} />}
        {fieldValue?.id && (
          <LightBoxProvider
            images={convertAttachmentFilesToLightboxImages([
              {
                id: fieldValue.id,
                name: fieldValue.name,
                url: fileSrc(fieldValue.url)!,
              },
            ])}
          >
            <FileListItem
              id={fieldValue.id}
              name={fieldValue.name}
              url={fileSrc(fieldValue.url)!}
              thumbnailUrl={fileSrc(fieldValue.url)!}
              divider={false}
              onDownload={() => downloadOrgFile(fieldValue.id, organizationId, fieldValue.name)}
              onDelete={selectedOption === 'manual' && deleteFn ? deleteFn : undefined}
            />
          </LightBoxProvider>
        )}
      </Box>
      {renderAdditionalInfo()}
    </Box>
  );
};

type FileOptionWithCurrentOption = (FileOption & { currentFile?: CurrentFileForOption }) | undefined;

export const PropertyFileSelectionFieldWrapper: React.FC<PropertyFileSelectionFieldWrapperProps> = props => {
  const { control, htmlName } = props;
  const { field } = useController({
    name: htmlName,
    control,
    rules: { required: true },
  });

  const initialFieldValue = useRef<PropertyFileSelectionValue | undefined>();
  useEffectOnce(() => {
    initialFieldValue.current = field.value;
  });
  const initialSelectedOption = initialFieldValue.current?.selectedOption;

  const { organizationId, assessmentId } = props;

  const { content } = useGqlQueryHandler(
    useGetAssessmentLocationReportFilesClientSideQuery,
    {
      variables: {
        assessmentId,
        organizationId,
      },
    },
    result => {
      const location = result.data.v2_assessments_by_pk?.scoredNode;

      if (!location) {
        return <div>Loading</div>;
      }

      let currentLocationPhotoFile;
      let currentFloorplanFile: CurrentFileForOption | undefined;

      const locationPhotoFile = location.nodes_files[0]?.file;
      if (location.nodes_files[0]) {
        currentLocationPhotoFile = {
          id: locationPhotoFile.id,
          name: locationPhotoFile.original_filename!,
          url: locationPhotoFile.filepath!,
        };
      }

      const floorplanFile = location.node_mapbox_map?.file;
      if (floorplanFile) {
        currentFloorplanFile = {
          id: floorplanFile.id,
          name: floorplanFile.original_filename!,
          url: floorplanFile.filepath!,
        };
      }

      const modifiedOptions = cloneDeep(props.options);

      // Config from report_section_fields could be potentially not up to date
      const mapping = {
        'location-photo': {
          currentFile: currentLocationPhotoFile,
          configFile: locationPhotoFile,
        },
        'floor-plan': {
          currentFile: currentFloorplanFile,
          configFile: floorplanFile,
        },
      };

      for (const [key, fileConfigurations] of Object.entries(mapping)) {
        const option: FileOptionWithCurrentOption = modifiedOptions.find(o => o.value === key);
        if (fileConfigurations.currentFile && option) {
          option.id = fileConfigurations.currentFile.id;
          option.name = fileConfigurations.currentFile.name;
          option.url = fileConfigurations.currentFile.url;

          if (initialSelectedOption === key) {
            // Keep track of current file to allow user to update
            option.currentFile = fileConfigurations.currentFile;
          }
        }
      }
      return <PropertyFileSelectionField {...props} options={modifiedOptions} locationId={location.id} />;
    },
  );

  return content;
};
