import { createEnhancedStore } from '@circadian-risk/front-end-utils';
import { MapRef } from 'react-map-gl';
import { StateCreator } from 'zustand';

// Building zoom level looks more than enough for custom maps
// After testing the maps are usually between 15-16
const BUILDING_ZOOM_LEVEL = 15;

export interface MapboxStore {
  /**
   * Resets the map viewport
   *
   * If you're using a real world map please refer to {@link useRealWorldMapReset}
   * @param mapId
   * @returns
   */
  resetViewport: (mapId: string) => void;
  removeRef: (mapId: string) => void;
  addRef: (mapId: string, ref: MapRef) => void;
  refs: Map<string, MapRef>;
}

const stateCreator: StateCreator<MapboxStore> = (set, get) => ({
  refs: new Map(),
  addRef: (mapId, ref) => {
    const { refs } = get();
    if (refs.has(mapId)) {
      return;
    }
    refs.set(mapId, ref);
    set({ refs });
  },
  removeRef: mapId => {
    const { refs } = get();
    if (!refs.has(mapId)) {
      return;
    }
    refs.delete(mapId);
    set({ refs });
  },
  resetViewport: mapId => {
    const { refs } = get();
    const targetMap = refs.get(mapId);
    if (targetMap) {
      targetMap.setCenter({ lat: 0, lon: 0 });
      targetMap.setZoom(BUILDING_ZOOM_LEVEL);
    }
  },
});

export const useMapboxStore = createEnhancedStore(stateCreator);
