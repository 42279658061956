import { Box, BoxProps } from '@mui/material';
import React from 'react';

export interface PageFormLayoutProps {
  leftContent?: React.ReactNode;
  backgroundGraphics?: React.ReactNode;
  form: React.ReactNode;
  layout?: {
    /**
     * If page has a header, set this to false, otherwise the page will be 100% of viewport height
     * @default true */
    fullHeight?: boolean;
    formMaxWidth?: number;
  };
}

export const PageFormLayout: React.FC<PageFormLayoutProps & BoxProps> = ({
  form,
  backgroundGraphics,
  leftContent,
  layout,
  ...boxProps
}) => {
  const formMaxWidth = layout?.formMaxWidth ?? 400;
  const fullHeight = layout?.fullHeight ?? true;

  const background_zIndex = 1;
  const form_zIndex = background_zIndex + 1;

  return (
    <Box
      p={{ xs: 1, sm: 4 }}
      marginX="auto"
      display="flex"
      height="100%"
      width="100%"
      minHeight={fullHeight ? '100dvh' : '100%'}
      flexDirection={{ xs: 'column', md: 'row' }}
      maxWidth={theme => theme.breakpoints.values.xl}
      position="relative"
      justifyContent="center"
      alignItems="center"
      gap={8}
      {...boxProps}
    >
      {leftContent && <Box sx={{ order: { xs: 2, md: 1 } }}>{leftContent}</Box>}
      {backgroundGraphics && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            zIndex: background_zIndex,
            left: 50,
            position: { xs: 'initial', md: 'fixed', lg: 'initial' },
            minWidth: { xs: 'unset', md: 500 },
            width: { xs: '80vw', md: 'unset' },
            maxWidth: { xs: 'unset', md: '40vw' },
            height: { xs: '300px', md: '70vh' },
            maxHeight: { xs: 'unset' },
          }}
        >
          {backgroundGraphics}
        </Box>
      )}
      <Box
        sx={{
          zIndex: form_zIndex,
          order: { xs: 1, md: 2 },
          flexGrow: { xs: 'unset', md: 1 },
          width: { xs: '100%', sm: 'unset' },
          maxWidth: { xs: '100%', sm: formMaxWidth },
        }}
        order={{ xs: 1, md: 2 }}
      >
        {form}
      </Box>
    </Box>
  );
};
