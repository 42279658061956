import { ItemIcon, ItemIconProps } from '@circadian-risk/presentational';
import { useTheme } from '@mui/material';
import React, { CSSProperties } from 'react';

export type GenericRealWorldMarkerProps = Partial<ItemIconProps> & { name: string };

const flexDisplayStyle: CSSProperties = { display: 'flex' };

export const GenericRealWorldMarker: React.FC<GenericRealWorldMarkerProps> = props => {
  const theme = useTheme();
  return (
    <ItemIcon
      {...props}
      alt={props.name}
      borderSize={props.borderSize ?? 2}
      borderColor={props.borderColor ?? theme.palette.info.main}
      hasBorder={props.hasBorder ?? true}
      hasShadow={props.hasShadow ?? false}
      backgroundColor={props.backgroundColor ?? theme.palette.info.dark}
      teardrop={props.teardrop ?? true}
      style={flexDisplayStyle}
    />
  );
};
