import { LicenseManager } from '@ag-grid-enterprise/core';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { AG_GRID_LICENSE_KEY, initializeSentry } from '@circadian-risk/front-end-utils';
import { createRoot } from 'react-dom/client';

import App from './App';
import { config } from './environments/environment';
import { unregisterServiceWorkers } from './serviceWorker';

LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);

if (config.APOLLO_DEV_MODE) {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

if (config.SENTRY_DSN) {
  initializeSentry(config);
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  // TODO(v-rrodrigues)[CR-4472]: once we get react router v6 we can re-enable strict mode
  // <StrictMode>
  <App />,
  // </StrictMode>,
);

unregisterServiceWorkers();
