import { ListenScenarioDetailsForAssessmentSubscription } from '@circadian-risk/client-graphql-hooks';
import { USER_ROLE } from '@circadian-risk/shared';
import { useOrganizationSessionStore } from '@circadian-risk/stores';
import { Card, Grid } from '@mui/material';
import React, { useMemo } from 'react';

import { useHasRole } from '../../../../../lib/useHasRole';
import { ScenariosTotalScore } from '../../ScenarioAssessmentDetailsPage';
import { AssessmentItemsAnswersTable } from '../../smart-components/AssessmentItemsAnswersTable/AssessmentItemsAnswersTable';
import { AssessmentCompliancePercentCard } from '../AssessmentCompliancePercentCard';
import { TOTAL_STATS_ID } from '../AssessmentCompliancePercentCard/AssessmentCompliancePercentCard';
import { ScenarioStatInput } from '../AssessmentCompliancePercentCard/types';
import { AssessmentDeficiencies } from '../DeficienciesByLocation/AssessmentDeficiencies';
import { extractCriticalityNumbersFromScore } from '../ScenarioAssessmentOverview/helpers';
import { calculateDeficienciesByLocation } from './helpers';

export interface ScenarioAssessmentResultsProps {
  scenariosTotalScore: ScenariosTotalScore;
  scenarioStatInput: ScenarioStatInput[];
  organizationId: string;
  assessment: ListenScenarioDetailsForAssessmentSubscription['v2_assessments_by_pk'];
}

export const ScenarioAssessmentResults: React.FC<ScenarioAssessmentResultsProps> = ({
  scenariosTotalScore,
  scenarioStatInput,
  organizationId,
  assessment: assessmentParam,
}) => {
  const assessment = assessmentParam!;
  const { layersById, nodesById, criticalityLabels } = useOrganizationSessionStore(
    ({ layersById, nodesById, criticalityLabels }) => ({ layersById, nodesById, criticalityLabels }),
  );
  const calculatedDeficienciesByLocation = useMemo(
    () => calculateDeficienciesByLocation(organizationId, assessment, layersById, nodesById),
    [assessment, layersById, nodesById, organizationId],
  );

  // This can be improved in the future to double check the specific
  // access to the assessment in question
  const hasLocationManagerRole = useHasRole(USER_ROLE.LOCATION_MANAGER);

  const overallStats = useMemo(
    () => ({
      id: TOTAL_STATS_ID,
      irrelevantQuestionsCount: 0,
      percentage: scenariosTotalScore.control_percentage,
      criticalityNumbers: extractCriticalityNumbersFromScore(scenariosTotalScore),
    }),
    [scenariosTotalScore],
  );

  return (
    <Grid aria-label={'assessment-results'} item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AssessmentCompliancePercentCard {...{ scenarioStatInput, overallStats }} />
        </Grid>
        {calculatedDeficienciesByLocation && (
          <Grid item xs={12}>
            <AssessmentDeficiencies
              locations={calculatedDeficienciesByLocation}
              criticalityLabels={criticalityLabels}
              showLinks={hasLocationManagerRole}
            />
          </Grid>
        )}

        {assessment && (
          <Grid item xs={12}>
            <Card sx={{ width: '100%' }}>
              <AssessmentItemsAnswersTable
                assessmentId={assessment.id}
                tableSx={{ height: 'calc(50vh - 200px)', minHeight: '400px' }}
              />
            </Card>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
