import { Typography } from '@mui/material';
import capitalize from 'lodash/capitalize';
import { useFormContext } from 'react-hook-form';

import { CreateScheduledAssessmentFormSchema } from '../../form';

export const CompletionCriteriaStepStepperSubtitle: React.FC = () => {
  const { watch } = useFormContext<CreateScheduledAssessmentFormSchema>();
  const watchImportAnswers = watch('importAnswers');
  const content =
    watchImportAnswers.type === 'fresh'
      ? 'No answers will be imported'
      : watchImportAnswers.value.length > 0
      ? `${watchImportAnswers.value.map(capitalize).join(', ')}`
      : 'Nothing is selected';

  return <Typography variant="body2">{content}</Typography>;
};
