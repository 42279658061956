import { Box, Button, ButtonBase, ClickAwayListener, Paper, Popper, PopperPlacementType } from '@mui/material';
import React from 'react';

export interface PersistentPopoverProps {
  trigger: React.ReactNode;
  placement?: PopperPlacementType;
  zIndex?: number;
  hideCloseButton?: boolean;
  children?: React.ReactNode;
}
export const PersistentPopover: React.FC<PersistentPopoverProps> = ({
  trigger,
  children,
  placement = 'top',
  hideCloseButton,
  zIndex,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleTriggerClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <Box>
        <ButtonBase onClick={handleTriggerClick} disableTouchRipple aria-label="trigger popover" component="div">
          {trigger}
        </ButtonBase>
        <Popper open={open} placement={placement} anchorEl={anchorEl} style={{ zIndex: zIndex ?? undefined }}>
          <Paper elevation={8}>
            <Box>{children}</Box>
            {!hideCloseButton && (
              <Box p={1}>
                <Button aria-label="close" size="small" variant="text" onClick={() => setAnchorEl(null)}>
                  Close
                </Button>
              </Box>
            )}
          </Paper>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};
