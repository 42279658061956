import { ICellRendererParams } from '@ag-grid-community/core';
import { Box } from '@mui/material';
import React, { useImperativeHandle } from 'react';

const padding = '12px';

export interface VariableLinesCellRendererRef {
  getReactContainerStyle: () => object;
}

export const VariableLinesCellRenderer = React.forwardRef<VariableLinesCellRendererRef, ICellRendererParams>(
  ({ value }, ref) => {
    useImperativeHandle(ref, () => ({
      getReactContainerStyle: () => {
        return {};
      },
    }));

    const text = value;

    return (
      <Box
        component="span"
        style={{
          display: 'inline-block',
          overflowWrap: 'anywhere',
          paddingTop: padding,
          paddingBottom: padding,
          lineHeight: '1rem',
        }}
      >
        {text}
      </Box>
    );
  },
);

// Does not perfectly fit with paginationAutoPageSize because row heights can be dynamic
// Recommended usage: Use on columns with dynamic text content AND essential to the user.
// If not essential, use TextTooltipColumnConfig
export const VariableLinesColumnConfig = {
  autoHeight: true,
  maxWidth: 500,
  minWidth: 300,
  wrapText: true,
  filter: 'agTextColumnFilter',
};
