import { Typography, TypographyProps } from '@mui/material';
import React from 'react';

import { HighlightsProps, SpanWithHighlights } from '../Text';

export interface QuestionTextProps extends TypographyProps {
  /**
   * A string of text that can have markdown in it.
   */
  question: string;
  highlights?: HighlightsProps[];
}

export const QuestionText: React.FC<QuestionTextProps> = ({ question: prompt, highlights, ...props }) => {
  return (
    <Typography align="left" variant="body1" aria-label="question prompt" {...props}>
      {highlights ? <SpanWithHighlights highlights={highlights}>{prompt}</SpanWithHighlights> : prompt}
    </Typography>
  );
};
