import { AutoCompleteInput } from '@circadian-risk/form';
import { GenericDialog, HStack, VStack } from '@circadian-risk/presentational';
import { Button, Typography } from '@mui/material';
import React, { useState } from 'react';

import { getCurrentUserTimezone } from '../helpers';

type TimezonePickerDialogProps = {
  value: string;
  onChange: (value: string) => void;
  isOpen: boolean;
  onClose: () => void;
  timezones: { label: string; value: string }[];
};

export const TimezonePickerDialog: React.FC<TimezonePickerDialogProps> = ({
  isOpen,
  onChange,
  onClose,
  value,
  timezones,
}) => {
  const [modifiedValue, setModifiedValue] = useState(value);
  return (
    <GenericDialog
      title="Cadence time zone"
      isOpen={isOpen}
      onClose={onClose}
      showCancel={false}
      actions={
        <HStack>
          <Button sx={{ textTransform: 'uppercase' }} onClick={() => setModifiedValue(getCurrentUserTimezone())}>
            Use current time zone
          </Button>

          <HStack justifyContent={'end'}>
            <Button color="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onChange(modifiedValue);
                onClose();
              }}
            >
              Save
            </Button>
          </HStack>
        </HStack>
      }
    >
      <VStack>
        <Typography>A cadence has to start and end in the same time zone.</Typography>
        <AutoCompleteInput
          value={modifiedValue}
          options={timezones}
          label="Time zone"
          acceptInvalid={false}
          disableClearable
          onChange={newValue => {
            // The user has to always contain a timezone therefore we should only propagate if he has changed
            if (newValue && newValue !== modifiedValue) {
              setModifiedValue(newValue);
            }
          }}
        />
      </VStack>
    </GenericDialog>
  );
};
