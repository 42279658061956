import { Enum_Feature_Flags_Enum } from '@circadian-risk/graphql-types';
import { Box, useTheme } from '@mui/material';
import React from 'react';

import { useOrganizationSessionStore } from '../organizationSessionStore';
import { useUserSessionStore } from '../userSessionStore';
import { useShouldShowFeatureFlagBoundaries } from './featureFlags.store';

type ComponentProps<T extends React.ComponentType = typeof React.Fragment> = {
  Component?: T;
  children?: React.ReactNode;
} & React.ComponentProps<T>;

export type FeatureFlagBoundaryProps<T extends React.ComponentType = typeof React.Fragment> = {
  flagName?: Enum_Feature_Flags_Enum;
  /** @default true */
  displayLegend?: boolean;
} & ComponentProps<T>;

/**
 * Conditionally shows children based on current user's organization and privileges
 * If no feature flag is provided, children will only be shown to Circadian Admin users
 */
export const FeatureFlagBoundary = <T extends React.ComponentType = typeof React.Fragment>(
  props: FeatureFlagBoundaryProps<T>,
) => {
  const theme = useTheme();
  const { children, flagName, displayLegend = true, Component = React.Fragment, ...rest } = props;

  const isCircadianAdmin = useUserSessionStore(state => state.isCircadianAdmin);
  const enabledFeatures = useOrganizationSessionStore(store => store.enabledFeatures);
  const showFeatureFlagBoundaries = useShouldShowFeatureFlagBoundaries();

  const featureEnabledForOrg = flagName ? enabledFeatures.includes(flagName) : false;

  const fieldSetStyles = displayLegend ? { padding: theme.spacing(1) } : {};

  if (!isCircadianAdmin && !featureEnabledForOrg) {
    return null;
  }

  if (featureEnabledForOrg) {
    const wrapWithBoundary = isCircadianAdmin && showFeatureFlagBoundaries;
    return wrapWithBoundary ? (
      <Component {...rest}>
        <Box
          sx={theme => ({
            borderColor: theme.palette.success.dark,
            borderRadius: theme.spacing(1),
            border: '1px solid',
            ...fieldSetStyles,
          })}
        >
          {displayLegend && (
            <Box
              component="legend"
              sx={theme => ({ color: theme.palette.success.dark })}
              role="note"
              aria-label="feature flag enabled"
            >
              Feature Flag Enabled - {flagName}
            </Box>
          )}
          {children}
        </Box>
      </Component>
    ) : (
      <Component {...rest}>{children}</Component>
    );
  }

  if (showFeatureFlagBoundaries) {
    return (
      <Component {...rest}>
        <Box
          sx={theme => ({
            borderColor: theme.palette.error.main,
            borderRadius: theme.spacing(1),
            border: '1px solid',
            ...fieldSetStyles,
          })}
        >
          {displayLegend && (
            <Box
              component="legend"
              sx={theme => ({ color: theme.palette.error.main, ...fieldSetStyles })}
              role="note"
              aria-label="circadian admin only"
            >
              Circadian Admin Only - {flagName ?? 'unnamed flag'}
            </Box>
          )}
          {children}
        </Box>
      </Component>
    );
  }

  return null;
};

export const useIsFeatureFlagEnabledForOrg = (flagName?: Enum_Feature_Flags_Enum) => {
  const enabledFeatures = useOrganizationSessionStore(store => store.enabledFeatures);
  return flagName ? enabledFeatures.includes(flagName) : false;
};

export type FeatureFlagInfo = {
  enabled: boolean;
  tooltip: string | null;
  color?: string;
};

/**
 * Feature flag hook that defaults to `true` when the user is a `circadian-admin`
 */
export const useCanUseFeatureFlag = () => {
  const theme = useTheme();
  const isCircadianAdmin = useUserSessionStore(state => state.isCircadianAdmin);
  const enabledFeatures = useOrganizationSessionStore(store => store.enabledFeatures);

  const canUseFeatureFlag = (flagName?: Enum_Feature_Flags_Enum): FeatureFlagInfo => {
    const flagEnabled = flagName ? enabledFeatures.includes(flagName) : false;

    if (isCircadianAdmin) {
      return {
        enabled: true,
        tooltip: flagEnabled
          ? `Feature Flag Enabled - ${flagName}`
          : `Circadian Admin Only - ${flagName ?? 'unnamed flag'}`,
        color: flagEnabled ? theme.palette.success.dark : theme.palette.error.main,
      };
    }

    if (flagEnabled) {
      return {
        enabled: true,
        tooltip: null,
      };
    } else {
      return {
        enabled: false,
        tooltip: null,
      };
    }
  };

  return canUseFeatureFlag;
};
