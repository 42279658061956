export interface HierarchicalRecord {
  id: string;
  mpath: string;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

/**
 * Splits mpath into array of IDs
 *
 * @param mpath
 */
export const splitMpath = (mpath: string) => mpath.split('.').filter(nodeId => nodeId);

export const normalizeRecords = <T extends { id: string }>(records: T[]) => {
  return records.reduce<Record<string, T>>(
    (prev, curr) => ({
      ...prev,
      [curr.id]: curr,
    }),
    {},
  );
};

/**
 * Splits mpath into an array of nodeIds. mpath can not be an empty string
 *
 * @param allNodes
 * @param mpath
 */
export const getRecordsListFromMpath = (allNodes: HierarchicalRecord[], mpath: string) => {
  const normalizedAllNodes = normalizeRecords(allNodes);
  const nodesList = splitMpath(mpath).map((nodeId: string) => normalizedAllNodes[nodeId]);
  return nodesList;
};

// Generates mpath relative to the nodeId
// Example: arguments mpath: uuid1.uuid2.uuid3.uuid4. nodeId: uuid3  Outputs ->  uuid3.uuid4
export const getNormalizedMpath = (mpath: string, nodeId: string) => {
  const index = mpath.indexOf(nodeId);
  if (index === -1) {
    throw new Error(`Attempting to get relative mpath of ${mpath} using nodeId ${nodeId}`);
  }
  return mpath.slice(index);
};

export const buildTruncatedMpath = (mpath: string, topLayerDepth: number) => {
  return mpath.split('.').slice(topLayerDepth).join('.');
};
