import {
  AgCartesianSeriesMarkerFormat,
  AgCartesianSeriesMarkerFormatterParams,
  AgCartesianSeriesTooltipRendererParams,
  AgLineSeriesOptions,
} from 'ag-charts-community';
import dayjs from 'dayjs';
import flatten from 'lodash/flatten';

import { safeTooltipRenderer } from '../safeTooltipRenderer';
import { ThresholdConfig } from './ThresholdConfig';

/**
 * A Discrete Series represents a single time series line set in the MultiTimeSeriesChart
 *
 * `xKey`, and `yKey` are added programmatically based on the chart settings
 * `yName` is mapped with convenience label `seriesLabel`
 *
 */
export type DiscreteLineSeries<
  XAxisKey extends string,
  XAxisValue,
  YAxisKey extends string,
  YAxisValue,
  OtherData extends Record<string, unknown>,
> = Omit<AgLineSeriesOptions, 'xKey' | 'yKey' | 'yName' | 'data'> & {
  seriesLabel: string;
  data: Array<GenericSeriesData<XAxisKey, XAxisValue, YAxisKey, YAxisValue, OtherData>>;
  /**
   * Color override, otherwise will draw from the default list
   */
  color?: string;
};

export type MultiTimeSeriesTooltipRendererParams<
  XAxisValue,
  YAxisValue extends number,
  OtherData extends Record<string, unknown>,
  ThresholdYAxisKeys extends string,
> = Omit<AgCartesianSeriesTooltipRendererParams, 'datum' | 'xKey' | 'yKey'> & {
  previousNode?: DiscreteLineSeries<string, XAxisValue, string, YAxisValue, OtherData>['data'][number] | null;
  datum: DiscreteLineSeries<string, XAxisValue, string, YAxisValue, OtherData>['data'][number];
  xKey: string;
  yKey: string;
  thresholds?: ThresholdConfig<string, XAxisValue, ThresholdYAxisKeys, YAxisValue>;
};

/**
 * Generic for easily defining series data objects in a type-safe manner
 * ```
 * {
 *   [XAxisKey]: XAxisValue;
 *   [YAxisKey]: YAxisValue
 * }
 * ```
 */
type GenericSeriesData<
  XAxisKey extends string,
  XAxisValue,
  YAxisKey extends string,
  YAxisValue,
  OtherData extends Record<string, unknown>,
> = {
  [x in XAxisKey]: XAxisValue;
} & {
  [y in YAxisKey]: YAxisValue | undefined;
} & OtherData;

/**
 * 10 distinct colors as per https://linear.app/circadian-risk/issue/PN-250/trend-analysis-chart-color-selection,
 * we are not supporting more than 10 lines concurrently.
 */
const DEFAULT_LINE_COLORS = [
  '#BA2410',
  '#317B22',
  '#1C52C8',
  '#D9BE0D',
  '#EFA3D7',
  '#5E3490',
  '#5A451B',
  '#26C8E8',
  '#D34503',
  '#4D9983',
];

/**
 * Given a DiscreteLineSeries, separate the x and y values into discrete steps, where the x changes first then the y
 * (x1,y1) -> (x2,y2)  ===>  (x1,y1) -> (x2, y1) -> (x2, y2)
 * Note: This makes a copy of the data and does not change the original object.
 */
export const seriesToStepFunction = <
  XAxisKey extends string,
  XAxisValue,
  YAxisKey extends string,
  OtherData extends Record<string, any>,
  YAxisValue,
>(
  yKey: YAxisKey,
  series: Array<DiscreteLineSeries<XAxisKey, XAxisValue, YAxisKey, YAxisValue, OtherData>>,
) => {
  return series.map(seriesParams => {
    const steppedData: any[] = [];
    seriesParams.data.forEach((thisItem, index) => {
      // if the first node, keep it, but do not add discrete x-diff step
      if (index === 0) {
        steppedData.push(thisItem);
        return;
      }
      const previousItem = seriesParams.data[index - 1];
      // Make a copy of this item which has the new xValue (date) and then use yValue from the previous item
      steppedData.push({ ...thisItem, [yKey]: previousItem[yKey] });
      // Now add this item as normal and this will effectively create a vertical line segment for this xValue
      steppedData.push(thisItem);
    });
    return {
      ...seriesParams,
      data: steppedData,
    };
  });
};

/**
 * Takes an array of DiscreteLineSeries data and returns a series config and data
 */
export const discreteLineSeriesToAgSeriesAndData = <
  XAxisKey extends string,
  XAxisValue,
  YAxisKey extends string,
  YAxisValue extends number,
  OtherData extends Record<XAxisKey | YAxisKey, unknown>,
  ThresholdYAxisKeys extends string,
>(
  xKey: XAxisKey,
  yKey: YAxisKey,
  discreteSeriesData: Array<DiscreteLineSeries<XAxisKey, XAxisValue, YAxisKey, YAxisValue, OtherData>>,
  step: boolean,
  tooltipRenderer?: (
    params: MultiTimeSeriesTooltipRendererParams<XAxisValue, YAxisValue, OtherData, ThresholdYAxisKeys>,
  ) => JSX.Element | null,
  thresholds?: ThresholdConfig<XAxisKey, XAxisValue, ThresholdYAxisKeys, YAxisValue>,
) => {
  let oldestDate = dayjs();

  const seriesConfigs = (step ? seriesToStepFunction(yKey, discreteSeriesData) : discreteSeriesData).map(
    ({ seriesLabel, data: dataInput, ...discreteSeries }, index) => {
      // The `data` for a series should already be chronological, can trust the first to be the oldest date
      const date = dayjs(dataInput[0][xKey]);

      if (date.isBefore(oldestDate)) {
        oldestDate = date;
      }

      const findNodeIndex = (datum: OtherData) =>
        dataInput.findIndex(seriesData => seriesData[xKey] === datum[xKey] && seriesData[yKey] === datum[yKey]);

      const hasNoDifferentXValues = (datum: OtherData) => {
        const nodeIndex = findNodeIndex(datum);
        if (nodeIndex === -1) {
          return false;
        }
        const nextNode = nodeIndex < dataInput.length - 1 ? dataInput[nodeIndex + 1] : null;
        return nextNode && nextNode[xKey] === datum[xKey];
      };

      const safeKey = seriesLabel.replace(/\./g, '');
      const data = dataInput.map(d => ({
        ...d,
        [safeKey]: d[yKey],
      }));

      const series = {
        ...discreteSeries,
        type: 'line' as const,
        xKey,
        yKey: safeKey,
        yName: seriesLabel,
        stroke: discreteSeries.color ?? DEFAULT_LINE_COLORS[index] ?? undefined,
        marker: {
          formatter: (params: AgCartesianSeriesMarkerFormatterParams<OtherData>): AgCartesianSeriesMarkerFormat => {
            const { datum } = params;
            if (step && hasNoDifferentXValues(datum)) {
              // this node is not the real diff, do not show a marker
              return {
                size: 0,
              };
            }
            return {
              stroke: discreteSeries.color ?? DEFAULT_LINE_COLORS[index] ?? undefined,
              fill: discreteSeries.color ?? DEFAULT_LINE_COLORS[index] ?? undefined,
            };
          },
        },

        tooltip: {
          renderer: tooltipRenderer
            ? (params: MultiTimeSeriesTooltipRendererParams<XAxisValue, YAxisValue, OtherData, ThresholdYAxisKeys>) => {
                const { datum, ...rest } = params;
                if (step && hasNoDifferentXValues(datum)) {
                  return '';
                }

                const thisNodeIndex = findNodeIndex(datum);
                const thisNodeData = dataInput[thisNodeIndex];
                const previousNodeIndex = thisNodeIndex - (step ? 2 : 1);

                // If the first node, there is no previous one
                const previousNode = thisNodeIndex === 0 ? null : dataInput[previousNodeIndex];
                const render = tooltipRenderer({
                  datum: {
                    ...thisNodeData,
                    ...datum,
                  },
                  ...rest,
                  thresholds,
                  previousNode,
                });

                return safeTooltipRenderer(render);
              }
            : undefined,
        },
      };

      return { series, data };
    },
  );

  const series = seriesConfigs.map(s => s.series);
  const data = flatten(seriesConfigs.map(s => s.data));

  return {
    data,
    series,
    oldestDate: oldestDate.toDate(),
  };
};
