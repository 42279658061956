import { InfoBox, NodeIcon, VStack } from '@circadian-risk/presentational';
import { AssignmentSummary } from '@circadian-risk/scheduled-assessments';
import { nestify, trimTree } from '@circadian-risk/shared';
import { useOrganizationSessionStore } from '@circadian-risk/stores';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Grid, List, Popover, Typography } from '@mui/material';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import {
  DetailDescription,
  DetailDescriptionContent,
  DetailDescriptionLabel,
  DetailDescriptionList,
  PropertyDescriptionDate,
  PropertyDescriptionText,
} from '@web-app/components/Details/DetailListing';
import slice from 'lodash/slice';
import sortBy from 'lodash/sortBy';
import React, { CSSProperties, memo, useCallback, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { AnswerSummaryBarSmart } from '../../components/AnswerSummaryBarSmart';
import { useAssessmentFormStore } from './AssessmentFormStore';
import { ITreeItem, ScenarioFormData } from './ScenarioAssessmentForm.types';
import { ScenarioLinks } from './ScenarioLinks';
import { useSelectedScenarios } from './useSelectedScenarios';

const linkStyles: CSSProperties = { textDecoration: 'underline', cursor: 'pointer' };

export const SummaryStep: React.FC = memo(() => {
  const { templates } = useAssessmentFormStore(state => ({
    templates: state.templates,
  }));
  const { watch } = useFormContext<ScenarioFormData>();
  const watchTemplateId = watch('assessmentTemplateId');
  const assignment = watch('assignment');
  const selectedScenarios = useSelectedScenarios(watchTemplateId, templates);
  const watchValues = watch();
  const { nodesById, layersById, nodes } = useOrganizationSessionStore(({ nodesById, layersById, nodes }) => ({
    nodesById,
    layersById,
    nodes,
  }));

  const selectedLocationsItems = watchValues.locationIds.map(id => {
    const location = nodesById[id];
    return {
      location,
      layer: layersById[location.layer_id],
    };
  });

  const seeMoreButtonRef = useRef<HTMLSpanElement | null>(null);
  const [seeMoreLocationsVisible, setSeeMoreLocationsVisible] = useState(false);

  const handleMoreLocationsClick = useCallback(() => {
    setSeeMoreLocationsVisible(true);
  }, []);

  const [expanded, setExpanded] = useState<string[]>([...nodes.map(e => e.id)]);
  const rootLocationId: ScenarioFormData['rootLocationId'] = watchValues.rootLocationId;

  const handleToggle = useCallback((_: unknown, nodeIds: string[]) => {
    setExpanded(nodeIds);
  }, []);

  const createRootItem = useCallback(
    (onlyTop5: boolean): ITreeItem => {
      const sortedNodes = sortBy(selectedLocationsItems, e => e.location.nodeDepth);
      const filteredNodes = onlyTop5 ? slice(sortedNodes, 1, 5) : sortedNodes;

      let limitIterator = 0;
      const mappedNodes = nodes.map(e => ({
        ...e,
        layerName: layersById[e.layer_id].name,
        selected: watchValues.locationIds.includes(e.id),
      }));
      const root = mappedNodes.find(n => n.id === rootLocationId)!;
      let rootNestified = nestify(mappedNodes, root);
      const trimmed = trimTree(rootNestified, e => {
        // Limit the nodes we see
        if (onlyTop5 && limitIterator >= 3) {
          return false;
        }

        const exists = filteredNodes.some(x => x.location.id === e.id);
        if (exists) {
          limitIterator++;
        }
        return exists;
      });
      if (trimmed) {
        rootNestified = trimmed;
      }

      return rootNestified;
    },
    [rootLocationId, selectedLocationsItems, nodes, layersById, watchValues.locationIds],
  );

  const renderTree = useCallback((node: ITreeItem) => {
    const Label = (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          alignContent: 'center',
          opacity: !node.selected ? 0.4 : 1,
        }}
      >
        <Box>
          <NodeIcon layerName={node.layerName} />
        </Box>
        <Box marginLeft={'8px'}>{node.name}</Box>
      </Box>
    );

    return (
      <TreeItem key={node.id} nodeId={node.id} label={Label}>
        {Array.isArray(node.children) ? node.children.map(renderTree) : null}
      </TreeItem>
    );
  }, []);

  return (
    <>
      <VStack spacing={2}>
        <Grid container>
          <Grid item xs={8}>
            <Typography variant="h6">Details</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DetailDescriptionList>
                  <DetailDescription>
                    <DetailDescriptionLabel>Name</DetailDescriptionLabel>
                    <DetailDescriptionContent>
                      <PropertyDescriptionText value={watchValues.name} />
                    </DetailDescriptionContent>
                  </DetailDescription>
                  <DetailDescription>
                    <DetailDescriptionLabel>Description</DetailDescriptionLabel>
                    <DetailDescriptionContent>
                      <PropertyDescriptionText value={watchValues.description} />
                    </DetailDescriptionContent>
                  </DetailDescription>
                </DetailDescriptionList>
              </Grid>
              <Grid item xs={12}>
                <DetailDescriptionList>
                  <DetailDescription>
                    <DetailDescriptionLabel>Start Date</DetailDescriptionLabel>
                    <DetailDescriptionContent>
                      <PropertyDescriptionDate value={watchValues.startDate} />
                    </DetailDescriptionContent>
                  </DetailDescription>
                  <DetailDescription>
                    <DetailDescriptionLabel>Due Date</DetailDescriptionLabel>
                    <DetailDescriptionContent>
                      <PropertyDescriptionDate value={watchValues.dueDate} />
                    </DetailDescriptionContent>
                  </DetailDescription>
                </DetailDescriptionList>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid item>
              <Typography variant={'h6'}>Scenarios</Typography>
              <ScenarioLinks scenarios={selectedScenarios} disableTitle />
            </Grid>
            <Grid item>
              <Typography variant={'h6'}>Locations</Typography>
              <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={handleToggle}
                disableSelection
              >
                {rootLocationId && renderTree(createRootItem(true))}
              </TreeView>
            </Grid>
            <Grid item>
              <Typography
                ref={seeMoreButtonRef}
                variant={'caption'}
                style={linkStyles}
                onClick={handleMoreLocationsClick}
              >
                see all...
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <div>
          <AssignmentSummary assignment={assignment} locationIds={watchValues.locationIds} />
        </div>

        <div>
          <Typography variant="h6">Question Summary</Typography>
          {watchValues.importActiveAnswers ? (
            <AnswerSummaryBarSmart />
          ) : (
            <InfoBox>No answers will be imported and all questions will require an answer</InfoBox>
          )}
        </div>
      </VStack>

      <Popover
        open={seeMoreLocationsVisible}
        onClose={() => {
          setSeeMoreLocationsVisible(false);
        }}
        anchorEl={seeMoreButtonRef.current as Element | null}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <Box width={'100%'} height={'100%'} minHeight={'100px'} maxHeight={'250px'} padding={'10px'}>
          <Typography variant={'h6'}>
            <b>Locations</b>
          </Typography>
          <List>
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              expanded={expanded}
              onNodeToggle={handleToggle}
              disableSelection
            >
              {rootLocationId && renderTree(createRootItem(false))}
            </TreeView>
          </List>
        </Box>
      </Popover>
    </>
  );
});
