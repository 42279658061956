import { FCC } from '@circadian-risk/front-end-utils';
import { render, RenderOptions } from '@testing-library/react';
import React, { ReactElement } from 'react';

import { createCircadianTheme, ThemeWrapper } from '..';

const testTheme = createCircadianTheme({
  variant: 'light',
});

const AllTheProviders: FCC = ({ children }) => {
  return <ThemeWrapper theme={testTheme}>{children}</ThemeWrapper>;
};

/**
 * Borrowed from here: https://testing-library.com/docs/react-testing-library/setup/#custom-render
 *
 * Use when testing components that rely on our custom theme and use custom theme variables like `organizationColors`
 */
const customRender = (ui: ReactElement, options?: Omit<RenderOptions, 'wrapper'>) =>
  render(ui, { wrapper: AllTheProviders, ...options });

export * from '@testing-library/react';
export { customRender as render };
