import { ChevronControls } from '@circadian-risk/presentational';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { TreeItemContentProps, useTreeItem } from '@mui/x-tree-view';
import clsx from 'clsx';
import React from 'react';

export const Leaf = React.forwardRef(function CustomContent(props: TreeItemContentProps, ref) {
  const { classes, className, label, nodeId, expansionIcon } = props;

  const {
    disabled: leafDisabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const disabled = leafDisabled;

  const theme = useTheme();

  const handleExpandClick = (e: React.MouseEvent<unknown>) => {
    preventSelection(e as React.MouseEvent<HTMLDivElement, MouseEvent>);
    e.stopPropagation();
    handleExpansion(e as React.MouseEvent<HTMLDivElement, MouseEvent>);
  };

  return (
    <Box
      onClick={handleSelection}
      ref={ref as React.Ref<HTMLDivElement>}
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.disabled]: disabled,
        [classes.selected]: selected,
        [classes.focused]: focused,
      })}
      sx={{
        width: '100%',
        minHeight: 36,
        transition: theme.transitions.create(['background-color'], {
          duration: theme.transitions.duration.shorter,
        }),
      }}
    >
      {expansionIcon && (
        <IconButton tabIndex={-1} onClick={handleExpandClick} sx={{ pointerEvents: 'all' }}>
          <ChevronControls variant="treeLeaf" expanded={expanded} />
        </IconButton>
      )}
      {!expansionIcon && <Box width={44} />}

      <Typography
        component="div"
        className={classes.label}
        color={({ palette }) => {
          const { text, primary } = palette;
          if (selected) {
            return primary.main;
          }
          return disabled ? text.disabled : text.primary;
        }}
      >
        {label}
      </Typography>
    </Box>
  );
});
