import { UserRow } from '@circadian-risk/assessment-components';
import {
  GetLocationScenarioAssessmentFormInfoQuery,
  useFindQualifiedChildrenLazyQuery,
} from '@circadian-risk/client-graphql-hooks';
import { getNormalizedMpath, useStructuralMemo } from '@circadian-risk/front-end-utils';
import { ReviewLocationDetailsRow } from '@circadian-risk/scheduled-assessments';
import { flattenTree, nestify, trimTree, USER_ROLE } from '@circadian-risk/shared';
import {
  GlobalOrganizationContextNode,
  OrganizationUser,
  useOrganizationId,
  useOrganizationSessionStore,
  useOrgRoleQueryPrecedence,
} from '@circadian-risk/stores';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import size from 'lodash/size';
import { useEffect, useMemo } from 'react';

import { QualifiedNode, TemplateData } from './ScenarioAssessmentForm/ScenarioAssessmentForm.types';

export const getRelevantNodes = (
  nodes: GlobalOrganizationContextNode[],
  rootLocationId: string,
  qualifiedChildren: QualifiedNode[],
): ReviewLocationDetailsRow[] => {
  const qualifiedChildrenById = keyBy(qualifiedChildren, e => e.id);

  if (!rootLocationId || Object.keys(qualifiedChildrenById).length === 0) {
    return [];
  }

  const rootNode = nodes.find(n => n.id === rootLocationId)!;
  let rootNestified = nestify(nodes, rootNode);

  const trimmed = trimTree(rootNestified, ({ id }) => Boolean(qualifiedChildrenById[id]));
  if (!trimmed) {
    return [];
  }

  rootNestified = trimmed;
  const flattened = flattenTree(trimmed).map(n => {
    return {
      ...n,
      relativeMpath: getNormalizedMpath(n.mpath, rootLocationId),
      disabled: n.id === rootLocationId,
      selectable: Boolean(qualifiedChildrenById[n.id]),
      lastAssessed: qualifiedChildrenById[n.id]?.assessmentLastCompletedAt,
      questionnaireItems: qualifiedChildrenById[n.id]?.inspection_questionnaire_item_categories_count ?? null,
      questionnaireQuestions: qualifiedChildrenById[n.id]?.inspection_questionnaire_questions_count ?? null,
      questionnaireProperties: qualifiedChildrenById[n.id]?.inspection_questionnaire_properties_count ?? null,
      inspectionItems: qualifiedChildrenById[n.id]?.physical_inspection_item_categories_count ?? null,
      inspectionQuestions: qualifiedChildrenById[n.id]?.physical_inspection_questions_count ?? null,
      inspectionProperties: qualifiedChildrenById[n.id]?.physical_inspection_properties_count ?? null,
      hasMap: qualifiedChildrenById[n.id]?.hasMap,
    };
  });

  return flattened;
};

export const useRelevantNodes = (
  rootLocationId: string | undefined,
  template: TemplateData | undefined,
): [ReviewLocationDetailsRow[], boolean] => {
  const { nodesById, nodes } = useOrganizationSessionStore(({ nodesById, nodes }) => ({ nodesById, nodes }));
  const organizationId = useOrganizationId();
  const queryContext = useOrgRoleQueryPrecedence(USER_ROLE.ORGANIZATION_ADMIN, USER_ROLE.LOCATION_MANAGER);
  const [findQualifiedChildren, result] = useFindQualifiedChildrenLazyQuery();

  const structuralTemplate = useStructuralMemo(template);
  useEffect(() => {
    const fetchChildren = async () => {
      if (!rootLocationId || !structuralTemplate) {
        return;
      }
      const node = nodesById[rootLocationId];
      await findQualifiedChildren({
        variables: {
          organizationId,
          mpathCompare: `${node.mpath}%`,
          layerTags: structuralTemplate?.tags.map(t => t.layer_tag_id),
          templateId: structuralTemplate?.id,
        },
        context: queryContext,
      });
    };
    void fetchChildren();
  }, [findQualifiedChildren, nodesById, organizationId, queryContext, rootLocationId, structuralTemplate]);

  const isReady = Boolean(result.data?.nodes);

  const relevantNodes: ReviewLocationDetailsRow[] = useMemo(() => {
    if (!result.data || !rootLocationId) {
      return [];
    }
    const qualifiedChildren = result.data.nodes.map(n => {
      const completedAt =
        n.scenarioAssessmentNodes.length > 0 ? new Date(n.scenarioAssessmentNodes[0].assessment!.completed_at!) : null;
      return {
        id: n.id,
        assessmentLastCompletedAt: completedAt,
        hasMap: Boolean(n.node_mapbox_map),
        ...n.templateSummaryInfo![0],
      };
    });
    return getRelevantNodes(nodes, rootLocationId, qualifiedChildren);
  }, [nodes, result.data, rootLocationId]);
  return [relevantNodes, isReady];
};

export const mapOrganizationUserToUserRow = (orgUsers: OrganizationUser[]): UserRow[] => {
  return orgUsers.map<UserRow>(u => ({
    id: u.id,
    displayName: u.displayName,
    avatar: u.avatar?.filepath,
    lastSignedInAt: u.last_signed_in_at,
    email: u.email,
    role: u.role,
  }));
};

export const parseTemplate = (
  template: GetLocationScenarioAssessmentFormInfoQuery['v2_assessment_templates'][0],
): TemplateData | undefined => {
  if (!template || isEmpty(template)) {
    return undefined;
  }

  const { scoredLayers } = template;
  if (!scoredLayers || !scoredLayers.length || scoredLayers.length > 1) {
    return undefined;
  }

  return {
    id: template.id,
    name: template.name,
    scoredLayerId: scoredLayers[0].id,
    scenarios: template.questionSets.map(qs => {
      const scenario = qs.questionSet!.scenario!;
      return {
        id: scenario.id,
        name: scenario.name,
        description: scenario.description,
      };
    }),
    tags: template.questionSets.flatMap(x => {
      return x.questionSet?.tags ?? [];
    }),
    totalAssessments: template.allAssessments.aggregate!.count,
    totalAssessmentsInProgress: template.inProgressAssessments.aggregate!.count,
    deprecatedTemplatesCount: size(template.allPreviousTemplates),
  };
};

/**
 * Parses the templates and returns only the non deprecated
 * @param assessmentTemplates
 */
export const parseTemplates = (
  assessmentTemplates: GetLocationScenarioAssessmentFormInfoQuery['v2_assessment_templates'],
): TemplateData[] => {
  const deprecatedTemplateIds = flatten(assessmentTemplates.map(e => (e.allPreviousTemplates ?? []).map(pt => pt.id)));
  return assessmentTemplates
    .filter(template => !deprecatedTemplateIds.includes(template.id))
    .map(parseTemplate)
    .filter(Boolean) as TemplateData[];
};
