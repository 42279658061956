import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import { fileSchema } from './shared-schemas/file.schema';

const c = initContract();

const commonPathParamsSchema = z.object({
  organizationId: z.string().uuid(),
  id: z.string().uuid(),
});

export const itemCategoriesContract = c.router(
  {
    iconAutoSelect: {
      method: 'POST',
      path: '/:organizationId/icon-auto-select',
      pathParams: z.object({
        organizationId: z.string().uuid(),
      }),
      body: z.object({
        itemCategoryIds: z.array(z.string().uuid()),
      }),
      responses: {
        201: z.record(z.string(), z.number().nullable()),
      },
    },
    clearIcon: {
      method: 'PATCH',
      path: '/:organizationId/:id/clear-icon',
      pathParams: commonPathParamsSchema,
      body: z.object({}).nullish(),
      responses: {
        200: z.object({ updatedAt: z.string() }),
      },
    },
    updateIcon: {
      method: 'PATCH',
      path: '/:organizationId/:id/update-icon',
      pathParams: commonPathParamsSchema,
      body: z.object({ iconId: z.number() }),
      responses: {
        204: z.object({}).nullish(),
      },
    },
    imageUpload: {
      method: 'POST',
      path: '/:organizationId/:id/image-upload',
      pathParams: commonPathParamsSchema,
      contentType: 'multipart/form-data',
      body: z.object({
        image: z.custom<File>(),
      }),
      responses: {
        201: fileSchema,
      },
    },
  },
  { pathPrefix: '/item-categories' },
);
