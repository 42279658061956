import { SelectOptionDialog, SelectOptionDialogProps } from '@circadian-risk/assessment-components';
import { TypedNodes, useGetLibraryOptionsByLocationIdQuery } from '@circadian-risk/client-graphql-hooks';
import { QueryHandlerComponentProps, QueryWrapper } from '@circadian-risk/graphql-utils';
import {
  useOrganizationId,
  useOrganizationSessionStore,
  useStandardOrgRoleQueryPrecedence,
} from '@circadian-risk/stores';

const convertQueryToOptions = (
  data: QueryHandlerComponentProps<typeof useGetLibraryOptionsByLocationIdQuery>['data'],
  selectedOptions: number[],
): SelectOptionDialogProps['options'] =>
  data.consideration_options.map(co => ({
    id: co.id,
    title: co.name,
    type: co.type ?? 'repair',
    description: co.description ?? undefined,
    alreadyImported: selectedOptions.includes(co.id),
    locationIds: co.nodes.map(({ node_id }) => node_id),
    photoSrc: co.photo?.filepath,
  }));

type SelectOptionDialogSmartProps = { locationId: string; itemCategoryId: string; selectedOptions: number[] } & Pick<
  SelectOptionDialogProps,
  'isOpen' | 'onClose' | 'onSave'
>;

export const SelectOptionDialogSmart = ({
  locationId,
  itemCategoryId,
  selectedOptions,
  ...dialogProps
}: SelectOptionDialogSmartProps) => {
  const context = useStandardOrgRoleQueryPrecedence();
  const organizationId = useOrganizationId();
  const locationPath = useOrganizationSessionStore(store => store.nodesById[locationId]?.path);

  return (
    <QueryWrapper
      node={TypedNodes.GetLibraryOptionsByLocationIdDocument}
      context={context}
      variant={{ type: 'page' }}
      variables={{ organizationId, itemCategoryId, locationPath }}
    >
      {({ data }) => <SelectOptionDialog options={convertQueryToOptions(data, selectedOptions)} {...dialogProps} />}
    </QueryWrapper>
  );
};
