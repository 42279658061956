import {
  useGetUpdatedLocationsQuery,
  useListenLocationCreatedAtSubscription,
  useListenLocationUpdatedAtSubscription,
} from '@circadian-risk/client-graphql-hooks';
import { useOrganizationId, useOrganizationSessionStore } from '@circadian-risk/stores';
import React from 'react';

export const LocationSyncProvider: React.FC = () => {
  const updatedSince = new Date().toISOString();
  const onNodesUpdated = useOrganizationSessionStore(state => state.onNodesUpdated);

  const organizationId = useOrganizationId();
  const { refetch } = useGetUpdatedLocationsQuery({
    skip: true,
  });

  useListenLocationUpdatedAtSubscription({
    variables: {
      organizationId,
      updatedSince,
    },
    onData: ({ data: subscriptionData }) => {
      const { data } = subscriptionData;
      if (data) {
        const timestamp = data.nodes?.[0]?.updated_at;
        if (timestamp) {
          void refetch({
            organizationId,
            updatedSince: timestamp,
          });
        }
      }
    },
  });

  useListenLocationCreatedAtSubscription({
    variables: {
      organizationId,
      updatedSince,
    },
    onData: ({ data: subscriptionData }) => {
      const { data } = subscriptionData;
      if (data) {
        const timestamp = data.nodes?.[0]?.created_at;
        if (timestamp) {
          void onNodesUpdated();
        }
      }
    },
  });

  return null;
};
