import { generateSelectRangeOptions } from '@circadian-risk/form';

export const FREE_FORM_OTHER = 'Other';

export const industryOptions = [
  'Banking and Finance',
  'Chemical',
  'Commercial & Residential Property Management',
  'Defense and Aerospace',
  'Energy',
  'Food and Agriculture',
  'Government',
  'Healthcare',
  'Hospitality: Hotels, Casinos, & Cruise Lines',
  'Manufacturing',
  'Mining',
  'Museums and Arts',
  'Non-Profit & Philanthropic ',
  'Petrochemical',
  'Pharmaceutical ',
  'Retail',
  'Schools & Universities',
  'Software and Technology',
  'Sporting Arenas & Entertainment',
  'Transportation',
  'Utilities & Public Works',
  FREE_FORM_OTHER
] as const;

export const employeeCountOptions = generateSelectRangeOptions([
  [1, 1],
  [2, 5],
  [6, 10],
  [11, 25],
  [26, 100],
  [101, 500],
  [501, 1000],
  [1001, 5000],
  [5001, 10000],
  [10000, 100000],
  [100000, 'infinite'],
]);

export const locationCountOptions = generateSelectRangeOptions([
  [1, 1],
  [2, 5],
  [6, 10],
  [11, 25],
  [26, 100],
  [101, 500],
  [501, 1000],
  [1001, 5000],
  [5001, 10000],
  [10000, 'infinite'],
]);

export const assessmentsCountOptions = generateSelectRangeOptions([
  [1, 1],
  [2, 5],
  [6, 10],
  [11, 25],
  [26, 100],
  [101, 500],
  [501, 1000],
  [1000, 'infinite'],
]);
