import { CircadianRoute, ROUTES } from '@circadian-risk/routes';
import { USER_ROLE } from '@circadian-risk/shared';
import { lazyLoadWithVersionCheck } from '@web-app/router/lazyLoadWithVersionCheck';

const PermissionsOverviewPage = lazyLoadWithVersionCheck(() => import('./PermissionsOverviewPage'));

export const permissionsRoutes: CircadianRoute[] = [
  {
    component: PermissionsOverviewPage,
    path: ROUTES.PERMISSIONS_OVERVIEW,
    isExact: false,
    pageTitle: 'Permissions Overview',
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN],
  },
];
