import {
  Enum_Answer_Responses_Enum,
  Enum_Item_Status_Enum,
  GetAssessmentItemsQuery,
} from '@circadian-risk/client-graphql-hooks';
import { convertFileToLightboxReadyFile } from '@circadian-risk/form';
import { createBreadCrumbs } from '@circadian-risk/front-end-utils';
import { TooltipStatusLabels } from '@circadian-risk/shared';
import { LayersById, NodesById } from '@circadian-risk/stores';
import { createAnswerOptions } from '@web-app/pages/items/helpers/createAnswerOptions';
import lowerCase from 'lodash/lowerCase';
import sumBy from 'lodash/sumBy';

import { AssessmentItemAnswerQuickFilter } from './AssessmentItemsAnswersTable';
import { AssessmentItemDetailRowData, RowData } from './types';

const ROW_DATA_FILTERS: AssessmentItemAnswerQuickFilter[] = [
  'lowCriticalityCount',
  'mediumCriticalityCount',
  'highCriticalityCount',
];

const filterRowDataWithCriticality = (rows: RowData[], quickFilter: AssessmentItemAnswerQuickFilter) => {
  const filteredRows = rows.filter(row => {
    return (
      row[quickFilter as 'lowCriticalityCount' | 'mediumCriticalityCount' | 'highCriticalityCount'] > 0 &&
      row.isDeficient &&
      row.isRelevant &&
      row.isPresent
    );
  });

  const quickFilterCode = {
    lowCriticalityCount: 1,
    mediumCriticalityCount: 2,
    highCriticalityCount: 3,
  };

  // Ensure the criticality matches the criteria we're looking for on the item detail rows (answers)
  filteredRows.forEach(row => {
    row.answers = (row.answers as AssessmentItemDetailRowData[]).filter(a => {
      return (
        a.criticalityValue ===
        quickFilterCode[quickFilter as 'lowCriticalityCount' | 'mediumCriticalityCount' | 'highCriticalityCount']
      );
    });
  });

  return filteredRows;
};

const createQuickAnswerFilter = (quickFilter?: AssessmentItemAnswerQuickFilter | null) => {
  const quickAnswerFilter = (
    answer: NonNullable<
      NonNullable<GetAssessmentItemsQuery['v2_assessments_by_pk']>['items'][0]['questions_and_answers']
    >[0],
  ): boolean => {
    // Ignore some specific item filters
    if (!quickFilter || ROW_DATA_FILTERS.includes(quickFilter)) {
      return true;
    }

    const answerResponse = answer.response as Enum_Answer_Responses_Enum;

    if (quickFilter === 'unanswered') {
      return !answerResponse;
    }
    if (quickFilter === 'flagged') {
      return Boolean(answer.is_flagged);
    }
    if (quickFilter === 'deficient') {
      const isDeficient = answer.deficient_response === answer.response;
      return isDeficient;
    }
    if (quickFilter === 'notObserved') {
      return Boolean(answerResponse && answerResponse === 'n_o');
    }

    return false;
  };

  return quickAnswerFilter;
};

export const getRowData = (
  data: GetAssessmentItemsQuery['v2_assessments_by_pk'],
  assessmentId: number,
  nodesById: NodesById,
  layersById: LayersById,
  quickFilter?: AssessmentItemAnswerQuickFilter | null,
) => {
  if (!data) {
    return [];
  }

  const { items } = data;
  const rows = items.map((i, index) => {
    let lowCriticalityCount = 0;
    let mediumCriticalityCount = 0;
    let highCriticalityCount = 0;

    const itemId = i.assessment_item_id;
    const answers = (i.questions_and_answers ?? [])
      .filter(createQuickAnswerFilter(quickFilter))
      .map<AssessmentItemDetailRowData>(answer => {
        const {
          id,
          question_text,
          is_flagged,
          criticality_value,
          response,
          partial_compliance_multiplier,
          answers_files,
          description,
          deficient_response,
          question_id,
          answered_at,
          item_category_id,
        } = answer;

        const isDeficient = deficient_response === response;

        if (isDeficient) {
          if (criticality_value === 1) {
            lowCriticalityCount++;
          } else if (criticality_value === 2) {
            mediumCriticalityCount++;
          } else if (criticality_value === 3) {
            highCriticalityCount++;
          }
        }

        const answerResponse = response as Enum_Answer_Responses_Enum | null;
        const options = createAnswerOptions(answer.freeform_options, answer.library_options);

        return {
          isAnswerVirtual: !id,
          item_id: itemId,
          itemCategoryId: item_category_id,
          criticalityValue: criticality_value,
          assessmentItemId: i.assessment_item_id,
          id, // If the answer is virtual, this could be undefined
          answer_id: `${question_id}_${itemId}`, // Used as the identifier id AGGrid in ItemMasterDetails
          questionId: question_id,
          questionText: question_text ?? '',
          response: answerResponse,
          is_flagged: is_flagged ?? false,
          isDeficient,
          partialComplianceMultiplier: partial_compliance_multiplier,
          scenarios: [],
          files: answers_files.map(file => convertFileToLightboxReadyFile(file)),
          description: description ?? '',
          itemIsMissing: !i.is_present,
          nodeId: i.node_id,
          itemName: i.name ?? '',
          deficientResponse: deficient_response,
          answered_at: answered_at ?? '',
          itemCategoryIconSrc: i.item_category.file?.filepath ?? undefined,
          options,
          optionsCount: options.length,
        };
      });

    const location = nodesById[i.node.id];

    const tooltipLabel = (i.item?.answer_aggregates?.rollup_label ?? 'unknown') as TooltipStatusLabels;

    const row: RowData = {
      index: index + 1,
      item_id: itemId,
      assessmentId,
      isInterviewItem: i.is_interview_item,
      assessmentItemId: i.assessment_item_id,
      itemName: i.name ?? '',
      description: i.description ?? '',
      notes: i.notes ?? '',
      answers,
      tooltipLabel,
      status: lowerCase(tooltipLabel) as Enum_Item_Status_Enum,
      lowCriticalityCount,
      mediumCriticalityCount,
      highCriticalityCount,
      itemType: i.is_interview_item ? 'Inspection Questionnaire Item' : 'Physical Inspection Item',
      files: i.files.map(file => convertFileToLightboxReadyFile(file)),
      filesCount: i.files.length + sumBy(answers, a => a.files.length),
      nodeId: location.id,
      layerName: layersById[location.layer_id].name,
      locationName: location.name,
      breadCrumbs: createBreadCrumbs(location.mpath, nodesById, layersById),
      isUnanswered: answers.some(a => !a.response),
      isFlagged: answers.some(a => a.is_flagged),
      isDeficient: i.is_deficient ?? false,
      isPresent: i.is_present ?? false,
      isRelevant: i.is_relevant ?? false,
      isNotObserved: answers.every(a => a.response === 'n_o'),
      itemCategoryIconSrc: i.item_category?.file?.filepath ?? undefined,
    };
    return row;
  });

  if (quickFilter && ROW_DATA_FILTERS.includes(quickFilter)) {
    return filterRowDataWithCriticality(rows, quickFilter);
  }

  // Filter down to items with answers if a quick filter is applied
  return quickFilter ? rows.filter(e => e.answers.length > 0) : rows;
};
