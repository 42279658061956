import { createEnhancedStore } from '@circadian-risk/front-end-utils';
import noop from 'lodash/noop';
import { StateCreator } from 'zustand';

import { getRelevantTeams } from './helpers/getRelevantTeams';
import { ActiveOrganizationData, OrganizationUser } from './types/active-organization';

// This piece of data is required for the web-app to function as expected
type OrgExtraData = ActiveOrganizationData;

export type OrganizationUserGroupWithUser = {
  id: number;
  name: string;
  // TODO(v-rrodrigues)[CR-5724]: Removal of users from zustand store in favor of reading directly from the Apollo cache
  users: OrganizationUser[];
};

export type OrganizationSessionStore = OrgExtraData & {
  id: string;
  name: string;
  role: string;
  roles: string[];
  avatarFilePath?: string;
  setOrganizationData: (data: { id: string; name: string; role: string; roles: string[] }) => void;
  resetOrganizationData: () => void;
};

export const defaultOrg = {
  id: '',
  name: '',
  role: 'organization-member',
  roles: ['organization-member'],

  // Extra piece of data related to the main web application

  enabledFeatures: [],
  finishedFetchingFeatures: false,
  allowedNodeIds: [],
  // TODO(v-rrodrigues)[CR-5724]: Removal of users from zustand store in favor of reading directly from the Apollo cache
  users: [],
  groups: [],
  thresholdLabels: {
    low: 'Optimal',
    medium: 'Borderline',
    high: 'Inadequate',
  },
  scenarios: [],
};

const stateCreator: StateCreator<OrganizationSessionStore> = (set, get, _get) => ({
  ...defaultOrg,
  nodes: [],
  layers: [],
  rootNodeId: '',
  nodesById: {},
  layersById: {},
  scoredLayerId: '',
  criticalityLabels: {},
  semanticLayerMapping: {},
  // on*Updated events will be lazily initialized
  onLayersUpdated: noop as ActiveOrganizationData['onLayersUpdated'],
  onNodesUpdated: noop as ActiveOrganizationData['onNodesUpdated'],
  getLayerByNodeId: (nodeId: string) => {
    const layerId = get().nodesById[nodeId]?.layer_id;
    return layerId ? get().layersById[layerId] : undefined;
  },
  getRelevantLocationTeams: (...locationIds: string[]) => {
    const { nodes, layersById, users: orgUsers } = get();
    return getRelevantTeams({ nodes, layersById, users: orgUsers }, locationIds);
  },
  setOrganizationData(data) {
    set(data);
  },
  resetOrganizationData() {
    set({ ...defaultOrg });
  },
});

export const useOrganizationSessionStore = createEnhancedStore(stateCreator, { byPassReset: true });

export const useOrganizationId = () => useOrganizationSessionStore(state => state.id);

// TODO(v-rrodrigues)[CR-5724]: Removal of users from zustand store in favor of reading directly from the Apollo cache
export const useOrganizationUsers = (): OrganizationUser[] => useOrganizationSessionStore(state => state.users);

// TODO(v-rrodrigues)[CR-5724]: Removal of users from zustand store in favor of reading directly from the Apollo cache
export const useOrganizationUserGroups = (): OrganizationUserGroupWithUser[] =>
  useOrganizationSessionStore(state => {
    return state.groups.map<OrganizationUserGroupWithUser>(group => ({
      id: group.id,
      name: group.name,
      // Filters the users that are not found in the organization users making sure
      // that the returned list is of actual users
      users: group.userIds.map(userId => state.users.find(u => u.id === userId) as OrganizationUser).filter(Boolean),
    }));
  });

export const useOrganizationRole = () => useOrganizationSessionStore(state => state.role);

export const useOrganizationThresholdLabels = () => useOrganizationSessionStore(state => state.thresholdLabels);

export const useIsOnlyOrganizationMember = () => {
  const roles = useOrganizationSessionStore(state => state.roles);
  return roles.length === 1 && roles[0] === 'organization-member';
};
