import { FormPaper } from '@circadian-risk/form';
import { PageFormLayout } from '@circadian-risk/layout';
import { VStack } from '@circadian-risk/presentational';
import { Box, Typography } from '@mui/material';
import React from 'react';

import { ReactComponent as Logo } from '../../assets/LogoWithText.svg';
import { ResponsiveIpadDemo } from '../components/ResponsiveIpadDemo/ResponsiveIpadDemo';
import { ReturnToSignIn } from '../components/ReturnToSignIn/ReturnToSignIn';
import { ForgotPasswordForm, ForgotPasswordFormProps } from './ForgotPasswordForm/ForgotPasswordForm';

export interface ForgotPasswordPageProps extends ForgotPasswordFormProps {
  /**
   * expiresAt indicates if the form has been submitted and the expiry of the token
   */
  expiresAt?: Date | null;
}

export const FORGOT_PASSWORD_RESET_INSTRUCTIONS = `We sent password reset instructions to`;

const ForgotPasswordSuccessful = ({ email }: Pick<ForgotPasswordFormProps, 'email'>) => (
  <FormPaper showBg>
    <Box display="flex" justifyContent="center" p={2}>
      {/* TODO(iprokopovich): [CR-1507] move logo component to presentational and use it here */}
      <Logo width="210px" height="105px" />
    </Box>

    <VStack alignItems="flex-start" spacing={3}>
      <VStack spacing={0}>
        <Typography>{FORGOT_PASSWORD_RESET_INSTRUCTIONS}</Typography>
        <Typography
          sx={theme => ({
            marginTop: theme.spacing(1),
            fontWeight: theme.typography.fontWeightBold,
            background: 'transparent',
          })}
        >
          {email}
        </Typography>
      </VStack>
      <ReturnToSignIn email={email} />
    </VStack>
  </FormPaper>
);

export const ForgotPasswordPage: React.FC<ForgotPasswordPageProps> = ({ expiresAt, ...forgotPasswordFormProps }) => {
  const Component = expiresAt ? ForgotPasswordSuccessful : ForgotPasswordForm;
  const form = (
    <VStack spacing={2}>
      <Component {...forgotPasswordFormProps} />
    </VStack>
  );

  return <PageFormLayout leftContent={<ResponsiveIpadDemo />} form={form} />;
};
