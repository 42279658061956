import { checkIsAppVersionOutOfSync } from '@web-app/smart-components/VersionChecker/useVersionCheck';
import { lazy } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const lazyLoadWithVersionCheck = (componentImport: any) =>
  lazy(async () => {
    try {
      const component = await componentImport();

      return component;
    } catch (error) {
      const isAppVersionOutOfSync = await checkIsAppVersionOutOfSync();
      if (isAppVersionOutOfSync) {
        return window.location.reload();
      }

      throw error;
    }
  });
