import { LightboxImage } from '@circadian-risk/presentational';

export enum UploadStatus {
  NA = 'NA',
  Uploading = 'Uploading',
  Pending = 'Pending',
  Completed = 'Completed',
  Failed = 'Failed',
}

export interface FileListItemProps {
  id: string;
  name: string;
  url: string | undefined | null;
  thumbnailUrl?: string | null;
  onDownload?: (() => void) | (() => Promise<void>);
  onDelete?: () => void;
  onRename?: (name: string) => void;
  divider?: boolean;
  dense?: boolean;
  badgeContent?: React.ReactNode;
}

export interface FileAttachmentCommon extends FileListItemProps {
  status?: UploadStatus;
  timestamp?: string;
  type?: string;
  markup?: Record<string, any> | null;
  originalUrl?: LightboxImage['url'];
}
