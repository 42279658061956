import { generateCircadianForm } from '@circadian-risk/form';
import { Enum_Consideration_Type_Enum } from '@circadian-risk/graphql-types';
import {
  CustomOptionFlair,
  HStack,
  OptionForConsideration,
  PRINCIPAL_ACTIONS,
  useConfirmationDialog,
  VStack,
} from '@circadian-risk/presentational';
import SaveIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { alpha, Button, IconButton, TextField, useTheme } from '@mui/material';
import isEqual from 'lodash/isEqual';
import React, { useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useCustomCompareEffect } from 'react-use';
import { z } from 'zod';

import { OFC_TITLE_MAX_CHARS } from '../constants';
import { PrincipalActionDropdown } from '../PrincipalActionDropdown';
import { CustomOption } from '.';

const PRINCIPAL_ACTIONS_ENUM = PRINCIPAL_ACTIONS as readonly [
  Enum_Consideration_Type_Enum,
  ...Enum_Consideration_Type_Enum[],
];

type Actions = {
  onChange: (option: CustomOption) => void;
  onSaveToLib?: (option: CustomOption) => void;
  onRemove: () => void;
};

export type CustomBlockInputProps =
  | { value: CustomOption; actions?: Actions }
  | { value?: CustomOption; actions: Actions };

const formSchema = z.object({
  title: z.string(),
  description: z.string().optional(),
  type: z.enum(PRINCIPAL_ACTIONS_ENUM).default(PRINCIPAL_ACTIONS[0]),
});

const { useCircadianForm, SubmitButton, CircadianForm } = generateCircadianForm(formSchema, {
  form: 'New Option for Consideration',
  title: 'Title',
  description: 'Description',
  type: 'Type',
  submit: 'save',
});

export const CustomBlockInput: React.FC<CustomBlockInputProps> = ({ value, actions }) => {
  const [editing, setEditing] = useState(Boolean(actions && !value?.title));
  const theme = useTheme();

  const formMethods = useCircadianForm({
    defaultValues: {
      title: value?.title,
      description: value?.description,
      type: value?.type || PRINCIPAL_ACTIONS[0],
    },
  });

  const {
    setValue,
    formState: { isDirty, isValid },
    getValues,
  } = formMethods;

  useCustomCompareEffect(
    () => {
      setValue('title', value?.title ?? '');
      setValue('description', value?.description ?? '');
      setValue('type', value?.type || PRINCIPAL_ACTIONS[0]);
      if (editing && !isDirty && value?.title) {
        setEditing(false);
      }
    },
    [value],
    (previousValue, nextValue) => isEqual(previousValue, nextValue),
  );

  const readOnly = actions === undefined;

  const { getConfirmation } = useConfirmationDialog();

  const containerRef = useRef<HTMLDivElement>();

  const { onChange, onRemove, onSaveToLib } = actions ?? {};

  const handleRemove = async () => {
    if (onRemove) {
      const { title } = getValues();
      if (!title) {
        onRemove();
        return;
      }

      const confirmed = await getConfirmation({
        title: 'Remove this option from the response?',
        message: 'This action cannot be undone.',
        confirmButton: {
          text: 'remove',
          buttonProps: { color: 'error' },
        },
      });

      if (confirmed) {
        onRemove();
      }
    }
  };

  const handleOnSubmit = (formValues: Partial<z.infer<typeof formSchema>>) => {
    onChange?.({
      id: value?.id ?? '',
      title: formValues.title ?? '',
      description: formValues.description ?? '',
      type: formValues.type ?? PRINCIPAL_ACTIONS[0],
    });
    setEditing(false);
  };

  const handleSaveToLib = () => {
    const formValues = getValues();
    onSaveToLib?.({
      id: value?.id ?? '',
      title: formValues.title ?? '',
      description: formValues.description ?? '',
      type: formValues.type ?? PRINCIPAL_ACTIONS[0],
    });
  };

  if ((readOnly || !editing) && value) {
    return (
      <OptionForConsideration
        principalAction={value.type}
        title={value.title}
        description={value.description}
        bottomAction={
          !readOnly && onSaveToLib ? (
            <Button
              disabled={!value.title}
              variant="text"
              startIcon={<LibraryAddCheckIcon />}
              size="small"
              onClick={handleSaveToLib}
            >
              Save to library?
            </Button>
          ) : null
        }
        sideAction={
          !readOnly && (
            <HStack>
              <IconButton onClick={() => setEditing(true)}>{<EditIcon />}</IconButton>
              <IconButton color="error" onClick={handleRemove}>
                <RemoveCircleIcon />
              </IconButton>
            </HStack>
          )
        }
        flairs={[<CustomOptionFlair key="custom" />]}
      />
    );
  }

  return (
    <CircadianForm formMethods={formMethods} onSubmit={handleOnSubmit}>
      <VStack
        ref={containerRef}
        sx={{
          outlineOffset: -1,
          outline: `1.5px solid ${alpha(theme.palette.action.active, 0)}`,
          transition: theme.transitions.create('outline', { duration: theme.transitions.duration.shortest }),
          ':focus-within': { outlineColor: theme.palette.action.active },
        }}
        borderRadius={2}
        border={`1px solid ${theme.palette.divider}`}
        bgcolor="background.paper"
        p={2}
        pb={1}
        spacing={1}
      >
        <HStack justifyContent="space-between" alignItems="flex-start">
          <Controller
            name="title"
            control={formMethods.control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <TextField
                inputRef={ref}
                {...field}
                label="Title"
                multiline
                variant="standard"
                disabled={readOnly}
                fullWidth
                inputProps={{ maxLength: OFC_TITLE_MAX_CHARS }}
              />
            )}
          />
          <Controller
            name="type"
            control={formMethods.control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <PrincipalActionDropdown
                handleChange={onChange}
                value={value}
                readOnly={readOnly}
                formControlProps={{
                  fullWidth: false,
                  sx: { minWidth: 120 },
                }}
              />
            )}
          />
          <IconButton onClick={handleRemove}>
            <RemoveCircleIcon color="error" />
          </IconButton>
        </HStack>
        <Controller
          name="description"
          control={formMethods.control}
          render={({ field: { ref, ...field } }) => (
            <TextField
              inputRef={ref}
              {...field}
              label="Description"
              placeholder="Provide more details"
              multiline
              variant="standard"
              disabled={readOnly}
            />
          )}
        />
        <SubmitButton disabled={!isDirty || !isValid} variant="text" startIcon={<SaveIcon />} size="small" />
      </VStack>
    </CircadianForm>
  );
};
