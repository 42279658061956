import { FCC } from '@circadian-risk/front-end-utils';
import Mark from 'mark.js';
import React from 'react';

import { useTextHighlight } from './useTextHighlight';

export interface HighlightTextProps {
  text: string | string[];
  markOptions?: Mark.MarkOptions;
  scrollToMatch?: boolean;
}

export const HighlightText: FCC<HighlightTextProps> = ({ text, markOptions = {}, scrollToMatch = false, children }) => {
  const containerRef = useTextHighlight(text, markOptions, scrollToMatch);
  return <div ref={containerRef}>{children}</div>;
};
