import PersonOffIcon from '@mui/icons-material/PersonOff';

import { TooltipAvatar, TooltipAvatarProps } from '../TooltipAvatar';

export interface UserAvatarProps extends Omit<TooltipAvatarProps, 'alt'> {
  name: string;
  /**
   * If the date is set to `null` its considered that the user has never signed in
   * which will make the icon/tooltip being differently rendered
   *
   * @description Name property is used in the tooltip
   */
  lastSignedInAt?: string | Date | null;
}

/**
 * Renders the avatar component but having the fallback for users who haven't signed in yet
 */
export const UserAvatar: React.FC<UserAvatarProps> = ({ name, lastSignedInAt, ...rest }) => {
  const tooltip = lastSignedInAt === null ? `${name} has never signed in` : rest?.customTooltip;
  const customAvatarContent =
    lastSignedInAt === null ? <PersonOffIcon sx={rest.sx} aria-label={'person off icon'} /> : undefined;
  return <TooltipAvatar alt={name} {...rest} customTooltip={tooltip} customAvatarContent={customAvatarContent} />;
};
