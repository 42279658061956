import { Box, Stack, StackProps } from '@mui/material';
import React from 'react';

export interface FormHStackProps {
  stackProps?: StackProps;
  children: React.ReactNode[];
}

export const FormHStack: React.FC<FormHStackProps> = ({ stackProps, children }) => {
  return (
    // it is important to alignItems to the start since some fields might not display error or helper text
    // https://mui.com/material-ui/react-text-field/#helper-text
    <Stack gap={2} direction="row" alignItems="flex-start" {...stackProps}>
      {children.map((formField, index) => (
        <Box key={index} display="flex" flex={1} flexDirection="column" alignItems="stretch">
          {formField}
        </Box>
      ))}
    </Stack>
  );
};
