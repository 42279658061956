import { CreateAssessmentBaseSchema } from '@circadian-risk/api-contract';
import { Box } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { AssessmentAssignmentTabbedTable, GroupRow, TeamRow, UserRow } from '../../AssessmentAssignmentTabbedTable';
import { getUpdatedAssignment } from '../helpers';
import { AssignmentHeader } from './AssignmentHeader';

type AssignmentManagementProps = {
  groups: GroupRow[];
  teams: TeamRow[];
  users: UserRow[];
};

export const AssignmentManagement: React.FC<AssignmentManagementProps> = ({ teams, groups, users }) => {
  const { control } = useFormContext<CreateAssessmentBaseSchema>();

  return (
    <Box>
      <AssignmentHeader />
      <Controller
        control={control}
        name="assignment"
        render={({ field: { value, onChange } }) => {
          return (
            <AssessmentAssignmentTabbedTable
              assignment={value}
              onSelectionChange={selectionChange => {
                const updatedSelection = getUpdatedAssignment(value, selectionChange);
                onChange(updatedSelection);
              }}
              groups={groups}
              teams={teams}
              users={users}
            />
          );
        }}
      />
    </Box>
  );
};
