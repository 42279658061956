import { Box, BoxProps, CircularProgress as MuiCircularProgress } from '@mui/material';
import React from 'react';

export type LoadingPlaceholderProps = BoxProps;

export const CircularProgress: React.FC<LoadingPlaceholderProps> = ({ ...boxProps }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
      bgcolor="background.default"
      {...boxProps}
    >
      <MuiCircularProgress />
    </Box>
  );
};
