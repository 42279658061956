import { ImportIcon, TouchFriendlyTooltip } from '@circadian-risk/presentational';
import FlagIcon from '@mui/icons-material/Flag';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Box, Checkbox, IconButton, Tooltip, useTheme } from '@mui/material';
import isNull from 'lodash/isNull';

import { QuestionProps } from '../question.types';
import { getEnabledOrDisabledColor } from '../utils';
import { TOOLTIPS } from '../utils/texts';
import { QuestionQuickActionsLayout } from './QuestionQuickActionsLayout';

export const questionQuickActionsAriaLabels = {
  ReImport: 're import answer',
};

export type QuestionQuickActionsProps = Pick<QuestionProps, 'onReImport'> & {
  onFlaggedChange?: (state: boolean) => void;
  onCloseClick?: () => void;
  disableImportMessage?: string | null;
  flagDisabled: boolean;
  importDisabled: boolean;
  isFlagged: boolean | null;
  criticalityComponent: React.ReactNode;
  checkbox?: QuestionProps['checkbox'];
  actions?: React.ReactNode;
};

export const QuestionQuickActions: React.FC<QuestionQuickActionsProps> = ({
  onReImport,
  onFlaggedChange,
  onCloseClick,
  disableImportMessage,
  flagDisabled,
  importDisabled,
  isFlagged,
  criticalityComponent,
  checkbox,
  actions,
}) => {
  const theme = useTheme();

  const hideFlagButton = flagDisabled && isNull(isFlagged);
  const showFlagButton = !hideFlagButton;

  const hasLeftAction = Boolean(onCloseClick) || Boolean(checkbox);

  const flagColor = isFlagged ? theme.palette.error.main : theme.palette.action.active;

  const leftActions = (
    <>
      {hasLeftAction ? (
        <>
          {onCloseClick && (
            <Tooltip title={TOOLTIPS.removeQuestion} enterDelay={1000}>
              <IconButton color={'error'} onClick={onCloseClick}>
                <RemoveCircleIcon />
              </IconButton>
            </Tooltip>
          )}

          {checkbox && (
            <Tooltip title={TOOLTIPS.checkbox} enterDelay={1000}>
              <Checkbox {...checkbox} />
            </Tooltip>
          )}
        </>
      ) : null}
      {actions}
    </>
  );
  const rightActions = (
    <>
      {criticalityComponent}

      {onReImport && (
        <TouchFriendlyTooltip tooltipContent={disableImportMessage ? disableImportMessage : 'Import'}>
          <IconButton
            size="small"
            color="primary"
            disabled={importDisabled}
            onClick={importDisabled ? undefined : onReImport}
            aria-label={questionQuickActionsAriaLabels.ReImport}
          >
            <ImportIcon />
          </IconButton>
        </TouchFriendlyTooltip>
      )}

      {showFlagButton && (
        <Box display="flex" justifyContent={'center'}>
          <IconButton
            size="small"
            disabled={flagDisabled}
            onClick={flagDisabled ? undefined : () => onFlaggedChange && onFlaggedChange(!isFlagged)}
            aria-label="flag question"
          >
            <FlagIcon htmlColor={getEnabledOrDisabledColor(theme, flagColor)} />
          </IconButton>
        </Box>
      )}
    </>
  );

  return <QuestionQuickActionsLayout leftActions={leftActions} rightActions={rightActions} />;
};
