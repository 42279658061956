import { useEffect } from 'react';

const setCssVariables = (variables: [string, string][], htmlElement: HTMLElement) => {
  variables.forEach(([varName, varVal]) => {
    htmlElement.style.setProperty(varName, varVal);
  });
};

/**
 * Sets the css variables for all the ag-grid instances and resets them when the component is unmounted
 */
export const useAgGridVariables = (
  variables: Record<string, string>,
  containerRef: React.MutableRefObject<HTMLElement> | React.MutableRefObject<undefined>,
) => {
  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const newCssVariables = Object.entries(variables);
    setCssVariables(newCssVariables, containerRef.current);
  }, [containerRef, variables]);
};
