import { CircadianRoute, ROUTES } from '@circadian-risk/routes';
import { USER_ROLE } from '@circadian-risk/shared';
import { lazyLoadWithVersionCheck } from '@web-app/router/lazyLoadWithVersionCheck';

const LocationFilterBuilderPage = lazyLoadWithVersionCheck(() => import('./LocationFilterBuilderPage'));

const FiltersTablePage = lazyLoadWithVersionCheck(() => import('./FiltersTablePage'));

export const filterRoutes: CircadianRoute[] = [
  {
    path: ROUTES.FILTERS,
    isExact: true,
    component: FiltersTablePage,
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN],
    featureFlag: 'advanced_filters',
  },
  {
    pageTitle: 'New Location Filter',
    path: ROUTES.LOCATION_FILTERS_NEW,
    component: LocationFilterBuilderPage,
    isExact: true,
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN],
    featureFlag: 'advanced_filters',
  },
  {
    pageTitle: 'Edit Location Filter',
    path: ROUTES.LOCATION_FILTERS_EDIT,
    component: LocationFilterBuilderPage,
    isExact: true,
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN],
    featureFlag: 'advanced_filters',
  },
];
