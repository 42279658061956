import { CheckboxProps } from '@mui/material';
import { FieldValues, useFormContext } from 'react-hook-form';

import { PropertyCheckboxField, PropertyFieldProps } from '../../PropertyFields';
import { useCircadianFormContext } from '../CircadianFormContext';
import { FormField } from './types';
import { useErrorMessage } from './utils';

export type CheckboxFFProps<FormData> = FormField<FormData> &
  Omit<PropertyFieldProps, 'control' | 'htmlName' | 'label'> & {
    /**
     * If customLabel is present it will override label and this
     * is useful when we want to leverage the usage of Icons instead of text
     * Aria-label will be set instead of a normal text to provide the necessary accessability
     */
    customLabel?: React.ReactNode;
    size?: CheckboxProps['size'];
  };

export function CheckboxFF<FormData extends FieldValues>({ formKey, customLabel, ...rest }: CheckboxFFProps<FormData>) {
  const { control, formState } = useFormContext<FormData>();
  const { ariaLabels, disabled } = useCircadianFormContext<FormData>();
  const errorMessage = useErrorMessage(formState.errors, formKey);

  return (
    <PropertyCheckboxField
      {...{
        disabled,
        control,
        errorMessage,
        ...rest,
      }}
      label={customLabel ?? ariaLabels[formKey]}
      htmlName={formKey}
      ariaLabel={customLabel ? ariaLabels[formKey] : undefined}
    />
  );
}
