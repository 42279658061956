import { Box } from '@mui/material';
import React from 'react';

import { ItemIcon, ItemIconProps, ItemIconSizes } from '../..';
import { IconSelectorProps } from './IconSelector';
import { LibIcon } from './types';

export const LibraryIcon: React.FC<
  Pick<IconSelectorProps, 'onIconSelect'> & { icon: LibIcon; selected?: boolean } & Omit<ItemIconProps, 'icon' | 'alt'>
> = ({ onIconSelect, selected, icon, ...itemIconProps }) => (
  <Box
    onClick={() => onIconSelect(icon.id)}
    sx={{
      p: 1,
      borderRadius: 2,
      backgroundColor: theme => (selected ? theme.palette.action.selected : 'unset'),
      outline: selected ? theme => `1px ${theme.palette.action.active} solid` : 'unset',
      ':hover': {
        cursor: 'pointer',
        backgroundColor: theme => theme.palette.action.selected,
        transition: theme => theme.transitions.create(['background-color']),
      },
    }}
  >
    <ItemIcon size={ItemIconSizes.medium} alt={icon.name} iconSrc={icon.src} {...itemIconProps} />
  </Box>
);
