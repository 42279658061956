import { useApiClient } from '@circadian-risk/api-client-provider';
import { useGetReportSectionFieldQuery } from '@circadian-risk/client-graphql-hooks';
import { generateCircadianForm } from '@circadian-risk/form';
import { useNotification } from '@circadian-risk/front-end-utils';
import { useConfirmationDialog } from '@circadian-risk/presentational';
import { displayNameOrEmail, ReportFieldKind, ReportSectionTemplateField } from '@circadian-risk/shared';
import { useOrganizationId, useOrganizationUsers, useUserSessionStore } from '@circadian-risk/stores';
import { Box, Button, InputAdornment, Link, Popover, Tooltip, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import { LeavingPrompt } from '@web-app/components/LeavingPrompt';
import { SaveProgressIcon, SaveStatus } from '@web-app/components/SaveProgressIcon';
import { PropertyFileSelectionFieldWrapper } from '@web-app/smart-components/SmartPropertyFields/PropertyFileSelectionField';
import { PropertyUserSelectionFieldWrapper } from '@web-app/smart-components/SmartPropertyFields/PropertyUserSelectionField';
import dayjs from 'dayjs';
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import React, { useCallback, useRef, useState } from 'react';
import { useDebounce, useUpdateEffect } from 'react-use';
import { z } from 'zod';

import { useReportGenerationStore } from './reportGenerationStore';
import { ReportSectionFieldConflict, ResolveFieldConflict } from './ResolveFieldConflict';

const formSchema = z.record(z.string(), z.any());

const { useCircadianForm, ariaLabels, Text, Date, Numeric, Toggle, RichText, CheckboxMulti, File, CircadianForm } =
  generateCircadianForm(formSchema, {
    form: 'report field form',
    submit: 'Save',
  });

interface ReportFieldFormProps {
  field: ReportSectionTemplateField & {
    version: number;
    hasDefaultValue: boolean;
    placeholder?: string;
    labelType?: 'name' | 'description';
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (val: any, latestVersion?: number) => Promise<void>;
  onReset?: (fieldId: number) => Promise<void>;
  disabled?: boolean;
}
export const ReportFieldForm: React.FC<ReportFieldFormProps> = ({ field, onChange, disabled, onReset }) => {
  const { id, kind, name, value, version, hasDefaultValue, description, placeholder, labelType = 'name' } = field;

  const { refetch: getReportSectionField } = useGetReportSectionFieldQuery({ fetchPolicy: 'network-only', skip: true });
  const organizationId = useOrganizationId();
  const assessmentId = useReportGenerationStore(state => state.assessmentId);
  const user = useUserSessionStore(state => {
    const typedUser: ReportSectionFieldConflict['user'] = {
      name: displayNameOrEmail([state.firstName, state.lastName], state.email),
      imgSrc: state.avatarFilePath,
      lastSignedInAt: state.lastSignedInAt,
    };
    return typedUser;
  });

  const { tsRestClient } = useApiClient();
  const users = useOrganizationUsers();
  const [fieldStatus, setFieldStatus] = useState<SaveStatus | undefined>(undefined);
  const [conflict, setConflict] = useState<(ReportSectionFieldConflict & { client_version: number }) | null>(null);
  const [conflictPopperOpen, setConflictPopperOpen] = useState(false);
  const { getConfirmation } = useConfirmationDialog();
  const { displayError } = useNotification();
  const idString = id.toString();
  const resolveRef = useRef<HTMLSpanElement>(null);
  const isReset = useRef(false);

  const formMethods = useCircadianForm({
    values: {
      [idString]: value,
    },
  });

  const { watch, setValue, formState, resetField, control } = formMethods;

  const formValue = watch(idString);

  useUpdateEffect(() => {
    if (formValue) {
      setFieldStatus('inProgress');
    }
  }, [formValue]);

  const saveProgress = async () => {
    if (isReset.current) {
      isReset.current = false;
    } else if (formValue !== undefined && formValue !== value) {
      setFieldStatus('inProgress');
      try {
        await onChange(formValue);
        setFieldStatus('success');
      } catch (err) {
        const result = await getReportSectionField({
          id,
          organizationId,
        });

        const latest = result.data?.report_section_fields_by_pk;
        if (latest && latest?.client_version !== version) {
          const lastModifiedByUser = users.find(u => u.id === latest?.last_modified_by_id);

          setConflict({
            kind,
            value: latest.field_value,
            client_version: latest.client_version,
            relativeTime: dayjs(latest.updated_at).fromNow(),
            user: lastModifiedByUser
              ? {
                  name: displayNameOrEmail(
                    [lastModifiedByUser.first_name, lastModifiedByUser.last_name],
                    lastModifiedByUser.email,
                  ),
                  imgSrc: lastModifiedByUser.avatar?.filepath,
                  lastSignedInAt: lastModifiedByUser.last_signed_in_at,
                }
              : null,
          });
        }
        setFieldStatus('error');
      }
    }
  };

  const [isReady, cancel] = useDebounce(saveProgress, 2000, [formValue, isReset.current]);

  let endAdornment: React.ReactNode;
  if (fieldStatus) {
    endAdornment = fieldStatus && (
      <InputAdornment position="end">
        <SaveProgressIcon status={fieldStatus} />
      </InputAdornment>
    );
  }

  const handleBlur = () => {
    cancel();
    void saveProgress();
  };

  // Mutates aria labels and defines the dynamic key
  ariaLabels[idString] = labelType === 'name' ? name : description ?? '';

  const baseProps = {
    formKey: idString,
    onBlur: handleBlur,
    endAdornment,
    disabled,
    placeholder,
  };

  const uploadFile = async (file: File) => {
    const { status, body } = await tsRestClient.files.genericUpload({
      params: {
        organizationId,
      },
      body: {
        file,
      },
      query: {
        entityType: 'report_section_fields',
        entityId: String(id),
        filename: file.name,
      },
    });

    if (status !== 200) {
      throw new Error('Failed uploading a new file');
    }

    return {
      id: body.id,
      url: body.filepath!,
    };
  };

  const deleteFile = async () => {
    if (formValue.selectedOption !== 'manual') {
      return;
    }
    try {
      await tsRestClient.files.delete({
        params: {
          organizationId,
          fileId: formValue.id,
        },
        body: {},
      });
      setValue(idString, null);
    } catch (err) {
      displayError(err);
    }
  };

  const renderField = () => {
    switch (field.kind) {
      case ReportFieldKind.Text:
        return <Text {...baseProps} />;
      case ReportFieldKind.Date:
        return <Date {...baseProps} />;
      case ReportFieldKind.Numeric:
        return <Numeric {...baseProps} />;
      case ReportFieldKind.MultipleCheckbox:
        return (
          <CheckboxMulti
            {...omit(baseProps, 'onBlur')}
            options={field.options}
            onChange={newValue => {
              setValue(name, newValue);
              void saveProgress();
            }}
          />
        );
      case ReportFieldKind.Toggle:
        return <Toggle {...baseProps} />;
      case ReportFieldKind.RichText:
        return <RichText {...baseProps} />;
      case ReportFieldKind.File:
        return <File {...baseProps} organizationId={organizationId} uploadFn={uploadFile} />;
      case ReportFieldKind.FileSelection:
        return (
          <PropertyFileSelectionFieldWrapper
            {...omit(baseProps, 'ref')}
            organizationId={organizationId}
            assessmentId={assessmentId}
            uploadFn={uploadFile}
            deleteFn={deleteFile}
            options={field.options}
            control={control}
            htmlName={idString}
            label={ariaLabels[idString]}
          />
        );
      case ReportFieldKind.UserSelection:
        return (
          <PropertyUserSelectionFieldWrapper
            {...baseProps}
            control={control}
            organizationId={organizationId}
            assessmentId={assessmentId}
            htmlName={idString}
            label={ariaLabels[idString]}
          />
        );
      default:
        return null;
    }
  };

  const handleResolveConflict = useCallback(() => {
    setConflictPopperOpen(state => !state);
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleConflict = async (newValue: any) => {
    // Get latest version again on submit
    const result = await getReportSectionField({
      id,
      organizationId,
    });
    const currentClientVersion = result.data.report_section_fields_by_pk!.client_version;
    try {
      await onChange(newValue, currentClientVersion + 1);
      setFieldStatus('success');
      setConflictPopperOpen(false);
      setConflict(null);

      resetField(idString);
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const handleReset = async () => {
    if (!onReset) {
      return;
    }

    const confirmed = await getConfirmation({
      title: 'Reset Field',
      message: `Are you sure you want to reset ${name}?`,
    });
    if (!confirmed) {
      return;
    }

    await onReset(id);
    const updated = await getReportSectionField({
      organizationId,
      id,
    });
    setValue(idString, updated.data.report_section_fields_by_pk?.field_value);
    setConflict(null);
    setConflictPopperOpen(false);
    setFieldStatus('success');
    isReset.current = true;
  };

  return (
    <CircadianForm formMethods={formMethods} ariaLabels={ariaLabels} onSubmit={noop}>
      <LeavingPrompt
        when={isReady() === false && formState.isDirty}
        message="Your progress is being saved. Are you sure you want to leave?"
      />
      {renderField()}
      {hasDefaultValue && (
        <Tooltip title="Reset the value of this field to the default provided by the system">
          <Button role="button" aria-label="reset" size="small" onClick={handleReset}>
            Reset
          </Button>
        </Tooltip>
      )}
      {conflict && (
        <Box>
          <Typography variant="caption" color="error">
            There was a conflict while saving this field.&nbsp;
          </Typography>
          <span ref={resolveRef}>
            <Link variant="caption" component="button" onClick={handleResolveConflict} underline="hover">
              Resolve conflict
            </Link>
          </span>

          <Popover
            open={conflictPopperOpen}
            onClose={() => setConflictPopperOpen(false)}
            anchorEl={resolveRef.current}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <ResolveFieldConflict
              current={{
                kind,
                value: formValue,
                user,
              }}
              latest={conflict}
              onUseCurrent={() => handleConflict(formValue)}
              onUseLatest={() => handleConflict(conflict.value)}
            />
          </Popover>
        </Box>
      )}
    </CircadianForm>
  );
};
