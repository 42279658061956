import { AutocompleteProps, TextFieldProps } from '@mui/material';

import { AutocompleteOption } from '../AutoCompleteInput';

export type AutoCompleteValue = string | number;

export type AutocompleteCreateOption = {
  input: string;
  isNew: true;
};

export const isCreateOption = (
  option: AutocompleteOption<AutoCompleteValue> | AutocompleteCreateOption | null,
): option is AutocompleteCreateOption => option !== null && 'isNew' in option && option.isNew;

type BaseAutoCompleteInputProps = Pick<
  AutocompleteProps<any, false, false, false>,
  'disabled' | 'loading' | 'loadingText' | 'fullWidth' | 'className' | 'sx' | 'popupIcon' | 'size'
>;

export interface AutoCompleteWithCreateProps<Value extends AutoCompleteValue> extends BaseAutoCompleteInputProps {
  value?: Value;
  onSelect?: (value?: Value | undefined) => void;
  onCreate?: (inputValue: string) => void;
  onClear?: () => void;
  options: AutocompleteOption<Value>[];
  label: string;
  errors?: string | string[];
  newOptionPrompt?: string;
  renderLabel?: (label: string) => React.ReactNode;
  textFieldProps?: Partial<TextFieldProps>;
}
