import { GenericRealWorldMarker, RealWorldMap, ResetRealWorldMapButton } from '@circadian-risk/maps';
import {
  ComponentWithNoDataFallback,
  HStack,
  LabelPosition,
  NoDataPlaceholderImage,
  NumberAndLabel,
  VStack,
} from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { useOrganizationId, useOrganizationSessionStore } from '@circadian-risk/stores';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, PopoverProps, Typography, useTheme } from '@mui/material';
import countBy from 'lodash/countBy';
import keys from 'lodash/keys';
import sortBy from 'lodash/sortBy';
import { useCallback, useMemo, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';

import noDataImg from '../../assets/no-location.svg';
import { CardInfoBanner } from '../layout/CardInfoBanner';
import { HomeLayoutCard } from '../layout/HomeLayoutCard';
import { createRealWorldItems } from './createRealWorldItems.helper';
import { LocationRealWorldPopover, LocationRealWorldPopoverProps } from './LocationRealWorldPopover';
import { LocationRealWorldMarkerItem, LocationRealWorldMetadata } from './types';

export const locationCardAriaLabels = {
  NoLocationsInfo: 'no locations',
  NoDataPlaceholder: 'no locations placeholder',
};

const noDataFallback = (
  <NoDataPlaceholderImage imgSrc={noDataImg} ariaLabel={locationCardAriaLabels.NoDataPlaceholder} />
);

export interface HomeLocationsCardProps extends Pick<LocationRealWorldPopoverProps, 'createLocationRoute'> {
  onAddLocationClick: () => void;
}

export const HomeLocationsCard: React.FC<HomeLocationsCardProps> = ({ onAddLocationClick, createLocationRoute }) => {
  const theme = useTheme();
  const organizationId = useOrganizationId();
  const { nodes, layersById } = useOrganizationSessionStore(
    // Return the nodes without the organization node
    ({ nodes, layersById }) => ({ nodes: nodes.filter(n => n.nodeDepth && n.nodeDepth > 0), layersById }),
  );

  const noDataState = nodes.length === 0;
  const [realWorldCardInfo, setRealWorldCardInfo] = useState<
    | {
        anchorEl: PopoverProps['anchorEl'];
        rows: LocationRealWorldMetadata[];
      }
    | undefined
  >(undefined);

  const stackedItemsRenderer = useCallback(
    (items: LocationRealWorldMarkerItem[]) => {
      return (
        <GenericRealWorldMarker
          name={items.length.toString()}
          tooltipDisabled
          backgroundColor={theme.palette.secondary.background}
          borderColor={theme.palette.secondary.light}
          borderSize={3}
          contentSx={{
            color: theme.palette.text.primary,
            fontSize: theme.typography.subtitle1.fontSize,
          }}
          onRootClick={target =>
            setRealWorldCardInfo({
              anchorEl: target as Element,
              rows: items.map(i => ({
                locationId: i.metadata!.locationId,
                locationName: i.metadata!.locationName,
                layerName: i.metadata!.layerName,
                depth: i.metadata!.depth,
              })),
            })
          }
        />
      );
    },
    [
      theme.palette.secondary.background,
      theme.palette.secondary.light,
      theme.palette.text.primary,
      theme.typography.subtitle1.fontSize,
    ],
  );

  const realWorldItems = useMemo(() => {
    return createRealWorldItems({
      nodes,
      backgroundColor: theme.palette.primary.dark,
      borderColor: theme.palette.primary.light,
      layersById,
      onRootClick: ({ target, node }) => {
        setRealWorldCardInfo({
          anchorEl: target as Element,
          rows: [
            {
              layerName: layersById[node.layer_id].name,
              locationId: node.id,
              locationName: node.name,
              depth: node.nodeDepth,
            },
          ],
        });
      },
    });
  }, [layersById, nodes, theme.palette.primary.dark, theme.palette.primary.light]);

  const aggregatesByLayer = useMemo(() => {
    const sorted: Record<string, number> = {};
    const aggregatesByLayerId = countBy(nodes, e => e.layer_id);
    const sortedKeys = sortBy(keys(aggregatesByLayerId), layerId => layersById[layerId].layerDepth);

    sortedKeys.forEach(layerId => {
      const layer = layersById[layerId];
      sorted[layer.name] = aggregatesByLayerId[layerId];
    });

    return sorted;
  }, [layersById, nodes]);

  return (
    <HomeLayoutCard
      title={
        <Typography variant="h5" component="h2">
          <HStack noFullWidth>
            <Link to={generatePath(ROUTES.NODES, { organizationId })}>Locations</Link>
            <Link to={generatePath(ROUTES.LAYERS, { organizationId })}>(Hierarchy)</Link>
          </HStack>
        </Typography>
      }
      action={
        <HStack justifyContent={'end'}>
          <ResetRealWorldMapButton />
          <Button startIcon={<AddIcon />} color="primary" variant="outlined" onClick={onAddLocationClick}>
            Add Locations
          </Button>
        </HStack>
      }
      banner={
        noDataState && (
          <CardInfoBanner
            ariaLabel={locationCardAriaLabels.NoLocationsInfo}
            content="Add a location from the location wizard to get started."
          />
        )
      }
    >
      <ComponentWithNoDataFallback hasData={!noDataState} fallback={noDataFallback}>
        <VStack justifyContent={'space-between'} height="100%">
          <Box flex={1}>
            <RealWorldMap items={realWorldItems} stackEnabled stackedItemsRenderer={stackedItemsRenderer} />
          </Box>

          <HStack>
            {Object.entries(aggregatesByLayer).map(([layerName, total]) => (
              <VStack key={layerName} justifyContent="center" alignItems={'center'}>
                <NumberAndLabel
                  labelPosition={LabelPosition.bottomCenter}
                  variant="small"
                  label={layerName}
                  num={total}
                />
              </VStack>
            ))}
          </HStack>
        </VStack>
      </ComponentWithNoDataFallback>

      {realWorldCardInfo && (
        <LocationRealWorldPopover
          open
          onClose={() => setRealWorldCardInfo(undefined)}
          {...realWorldCardInfo}
          createLocationRoute={createLocationRoute}
        />
      )}
    </HomeLayoutCard>
  );
};
