import { HEADER_HEIGHT } from '@circadian-risk/presentational';
import { useOrganizationSessionStore, useThemeStore } from '@circadian-risk/stores';
import { Typography } from '@mui/material';
import React from 'react';

import Logo, { LogoCommonProps } from '../../../Logo';
import NavLinkWithActiveOrgId from '../../../NavLinkWithActiveOrgId';

interface Props extends LogoCommonProps {
  returnRoute: string;
}

const CustomerLogo: React.FC<Props> = ({ returnRoute, withText, logoVariant }) => {
  const [customLightLogoSrc, customDarkLogoSrc, finishedFetchingTheme] = useThemeStore(state => [
    state.lightLogoSrc,
    state.darkLogoSrc,
    state.finishedFetchingTheme,
  ]);

  const finishedFetchingFeatures = useOrganizationSessionStore(state => state.finishedFetchingFeatures);

  // having to do this here feel very wrong but this is needed
  // to not show Circadian Logo to customers who have white labeling enabled
  // a more correct solution is discussed here: https://linear.app/circadian-risk/issue/CR-507#comment-5bde1d7a
  if (!finishedFetchingTheme || !finishedFetchingFeatures) {
    return null;
  }

  return (
    <Typography
      noWrap
      sx={theme => ({ height: HEADER_HEIGHT * 0.55, paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) })}
      component="h1"
    >
      <NavLinkWithActiveOrgId to={returnRoute}>
        <Logo
          withText={withText}
          logoVariant={logoVariant}
          aria-label="Return to Dashboard"
          customLightLogoSrc={customLightLogoSrc}
          customDarkLogoSrc={customDarkLogoSrc}
        />
      </NavLinkWithActiveOrgId>
    </Typography>
  );
};

export default CustomerLogo;
