import { StateCreator } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

type ResetFn = () => void;
const resetStateTracker = new Set<ResetFn>();

export const resetZustandStores = () => {
  resetStateTracker.forEach(fn => fn());
};

/**
 * Returns a zustand store hook that automatically enhances the store with devtools if running
 * in a development environment and registers a reset function for cleanup in
 * logout/switch organization actions
 *
 * @description This might be not handy for stores within zustand contexts
 *
 * @param stateCreator The store creation function
 * @param {bypassReset} If skipped the store will not reset in `resetZustandStores` helper
 *
 * @returns {any} A ready to use zustand store hook
 */
export const createEnhancedStore = <T>(stateCreator: StateCreator<T>, options?: { byPassReset: boolean }) => {
  const byPassReset = options?.byPassReset ?? false;
  stateCreator = devtools(stateCreator, { enabled: process.env['NODE_ENV'] === 'development' }) as StateCreator<T>;

  const store = createWithEqualityFn(subscribeWithSelector(stateCreator), shallow);
  if (!byPassReset) {
    const initialState = store.getState();
    resetStateTracker.add(() => {
      store.setState(initialState, true);
    });
  }
  return store;
};
