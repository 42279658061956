import { CircularProgress } from '@circadian-risk/presentational';
import { Box } from '@mui/material';
import React, { useEffect } from 'react';

import { debug } from '../lib/helpers';

const log = debug.extend('LoadingPage');

export interface LoadingPageProps {
  devMessage?: string;
}

export const LoadingPage: React.FC<LoadingPageProps> = ({ devMessage }) => {
  useEffect(() => {
    if (devMessage) {
      log(devMessage);
    }
  }, [devMessage]);

  return (
    <Box height="100vh">
      <CircularProgress />
    </Box>
  );
};
