import RefreshIcon from '@mui/icons-material/Refresh';

import { ResponsiveIconButton } from './ResponsiveIconButton';

interface RefreshButtonProps {
  onClick: () => void;
}

export const RefreshButton: React.FC<RefreshButtonProps> = ({ onClick }) => {
  return (
    <ResponsiveIconButton
      iconPosition="start"
      icon={<RefreshIcon />}
      text="Refresh"
      onClick={onClick}
      color="secondary"
      tooltip={'Get latest data from the server'}
      ariaLabel={'refresh table button'}
    />
  );
};
