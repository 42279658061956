type ImageSrcFunc = {
  (param: string): string | undefined;
  (param: string | null | undefined): string | undefined;
};

// this is using ts fn overloading, see thread here:
// https://circadianrisk.slack.com/archives/C8BBG61EE/p1648650679171489?thread_ts=1648649772.136299&cid=C8BBG61EE
export const imageSrc: ImageSrcFunc = (filename?: string | null, apiHost?: string) => {
  if (filename != null) {
    const isExternal = /https/.exec(filename);
    const isHostedLocally = /public/.exec(filename);
    if (isExternal) {
      return String(filename);
    } else if (isHostedLocally) {
      return `${apiHost ?? '//localhost:3000'}${filename.replace('public', '')}`;
    }
    return String(filename);
  }
  return undefined;
};

export const fileSrc = imageSrc;
