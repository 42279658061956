import { GridApi, IRowNode } from '@ag-grid-community/core';
import { useStructuralMemo } from '@circadian-risk/front-end-utils';
import { useEffect } from 'react';
import { useLatest } from 'react-use';

import { FilterModel, FilterObject } from '../filter.types';
import { doesFilterPass } from './useFilteredData';

/**
 * Generic hook that attaches external filter to Ag grid
 * @param gridApi
 * @param filterModel
 * @param keyMapper
 * @param allShouldMatch
 */
export function useFilterBuilderAgGridExternalFilter<Row extends object, FilterKey extends string = string>(
  gridApi: GridApi | null,
  filterModel: FilterModel<FilterKey>,
  keyMapper?: Record<FilterKey, keyof Row>,
  allShouldMatch = true,
) {
  // Levering a structural dependency is the best approach for a deeply nested object coming
  // from the filter build as new references are always created which makes the effect going into a loop
  // the useLatest hook usage is handy to just simply fetch the most updated value
  const filterLatest = useLatest(filterModel);
  const structural = useStructuralMemo(filterModel);

  useEffect(() => {
    if (gridApi) {
      const entries = Object.entries(filterLatest.current) as [string, unknown] as [FilterKey, FilterObject][];
      const doesExternalFilterPass = (node: IRowNode<Row>): boolean => {
        return doesFilterPass(node.data as Row, entries, keyMapper, allShouldMatch);
      };

      gridApi.setIsExternalFilterPresent(() => Object.keys(filterLatest.current).length > 0);
      gridApi.setDoesExternalFilterPass(doesExternalFilterPass);

      gridApi.onFilterChanged();
    }
  }, [gridApi, structural, keyMapper, filterLatest, allShouldMatch]);
}
