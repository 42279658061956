import { useTheme } from '@mui/material';
import React from 'react';

import { OptionCustomIcon } from '../../..';
import { OptionForConsiderationFlair } from '..';

export const CustomOptionFlair: React.FC<{ tooltip?: string; disabled?: boolean }> = ({
  tooltip = 'This option was created by an assessor during the assessment and does not exists in the library.',
  disabled,
}) => {
  const theme = useTheme();

  return (
    <OptionForConsiderationFlair
      id={'Custom Icon'}
      Icon={OptionCustomIcon}
      color={disabled ? theme.palette.text.disabled : theme.palette.warning.main}
      tooltip={tooltip}
    />
  );
};
