import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useLatest, useMount } from 'react-use';
/**
 * Work-around utility hook for managing string state on the url query parameters
 * by returning a react state
 */
export const useReactiveQueryState = <T extends (string | null) | string>(
  key: string,
  defaultValue: string | null,
): [T, (value: T) => void] => {
  const history = useHistory();
  const searchStr = useLocation().search;
  const urlParams = new URLSearchParams(searchStr);
  const state = urlParams.get(key) ?? defaultValue;
  // This state is required to force re-rendering and return the updated value otherwise
  // state could get stale
  const [reactiveState, setReactiveState] = useState(state);

  const latestSearchStr = useLatest(searchStr);
  const latestState = useLatest(state);

  const setState = (value: string | null) => {
    const newUrlParams = new URLSearchParams(searchStr);
    if (value === null) {
      newUrlParams.delete(key);
      setReactiveState(null);
    } else {
      newUrlParams.set(key, value);
      setReactiveState(value);
    }
    history.push({
      pathname: window.location.pathname,
      search: newUrlParams.toString(),
    });
  };

  useMount(() => {
    const initializeHistory = () => {
      const newUrlParams = new URLSearchParams(latestSearchStr.current);

      if (latestState.current == null) {
        newUrlParams.delete(key);
        setReactiveState(null);
      } else {
        newUrlParams.set(key, latestState.current);
        setReactiveState(latestState.current);
      }

      const stringified = newUrlParams.toString();

      if (stringified && `?${newUrlParams.toString()}` !== latestSearchStr.current) {
        window.history.replaceState(null, '', `${window.location.pathname}?${newUrlParams.toString()}`);
      }
    };

    initializeHistory();
  });

  return [reactiveState as T, setState];
};
