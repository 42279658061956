import { BasicTableProps } from '@circadian-risk/presentational';

// Shared basic table styling props for user assignment tables
export const commonTableProps: Pick<BasicTableProps, 'tableHeadProps' | 'tableHeaderSx'> = {
  tableHeadProps: {
    sx: theme => ({ background: theme.palette.secondary.background }),
  },
  tableHeaderSx: theme => ({
    color: theme.palette.text.secondary,
  }),
};
