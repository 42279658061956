import { FCC } from '@circadian-risk/front-end-utils';
import { useOrganizationSessionStore, useUserSessionStore } from '@circadian-risk/stores';
import { getNavigationGroups } from '@web-app/lib/getNavigationGroups';
import { useLocation } from 'react-router-dom';

import PageWrapper from '../../components/Layout';
import { config } from '../../environments/environment';
import headerSelector from './headerSelector';

const baseUrl = config.API_HOST;

export const NavigationContent: FCC = props => {
  const [userRole, roles, activeOrgId, enabledFeatures] = useOrganizationSessionStore(state => [
    state.role,
    state.roles,
    state.id,
    state.enabledFeatures,
  ]);

  const isCircadianAdmin = useUserSessionStore(state => state.isCircadianAdmin);

  const location = useLocation();
  const { pathname } = location;

  const navigationGroups = getNavigationGroups({
    organizationId: activeOrgId,
    pathname,
    userRole,
    roles,
    isCircadianAdmin,
    enabledFeatures,
    baseUrl,
  });

  return (
    <PageWrapper {...props} customHeader={headerSelector(pathname)} navigationMenuGroups={navigationGroups}>
      {props.children}
    </PageWrapper>
  );
};
