import { ImportIcon } from '@circadian-risk/presentational';
import { IconButton } from '@mui/material';

import { CardInfoBanner } from './layout/CardInfoBanner';

interface ImportScenarioInfoBannerProps {
  onClick: () => void;
  ariaLabel: string;
  action: string;
}

export const ImportScenarioInfoBanner: React.FC<ImportScenarioInfoBannerProps> = ({ onClick, ariaLabel, action }) => {
  return (
    <CardInfoBanner
      content={`Before ${action}, you must first import a scenario by clicking here`}
      ariaLabel={ariaLabel}
      actionButton={
        <IconButton onClick={onClick}>
          <ImportIcon sx={theme => ({ color: theme.palette.common.white })} />
        </IconButton>
      }
    />
  );
};
