import { TreeNode } from '@circadian-risk/shared';
import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import noop from 'lodash/noop';
import React from 'react';

export type TreeViewContextState = {
  expanded: string[];
  onToggle: (nodeId: string) => void;
};

export const TreeViewContext = React.createContext<TreeViewContextState>({
  onToggle: noop,
  expanded: [],
});

export interface LocationTreeNode extends TreeNode {
  name: string;
  children?: LocationTreeNode[];
  inspectionQuestionnaireProgress?: number | null;
  physicalInspectionProgress?: number | null;
  inspectionEnabled: boolean;
  interviewEnabled: boolean;
  selectable: boolean;
  depth: number;
  questionnaireUrl: string;
  inspectionUrl: string;
  ordinal: number | null;
  disabledChips: boolean;
  sx?: SxProps<Theme>;
}

export const PROGRESS_WIDTH = 80;
