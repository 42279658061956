import { LicenseManager } from '@ag-grid-enterprise/core';
import { AG_GRID_LICENSE_KEY, NotificationProvider } from '@circadian-risk/front-end-utils';
import { Box } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { useValue } from 'react-cosmos/fixture';

import { ColorModeSwitch, createCircadianTheme, ThemeWrapper } from '..';
import { VStack } from '../lib/Layouts';
import { useCosmosColorMode } from './useCosmosColorMode';

// this will be executed once the file is imported, as our cosmos apps will be decorator
// using this "CosmosDecorator" we'll have ag grid license set
LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);

interface CosmosDecoratorProps {
  simple?: boolean;
  children: React.ReactNode;
}

export const CosmosDecorator: React.FC<CosmosDecoratorProps> = ({ children, simple = false }) => {
  const [isSimple] = useValue('simple', { defaultValue: simple });
  const [effectiveColorMode, colorMode, setColorMode] = useCosmosColorMode();

  const crTheme = React.useMemo(() => createCircadianTheme({ variant: effectiveColorMode }), [effectiveColorMode]);

  return (
    <ThemeWrapper theme={crTheme}>
      <SnackbarProvider preventDuplicate>
        <NotificationProvider>
          {isSimple ? (
            children
          ) : (
            <VStack p={2} spacing={2}>
              <ColorModeSwitch mode={colorMode} onChange={setColorMode} expendDirection="row" />
              <Box>{children}</Box>
            </VStack>
          )}
        </NotificationProvider>
      </SnackbarProvider>
    </ThemeWrapper>
  );
};
