import { useRelevantLocations } from '@circadian-risk/assessment-components';
import {
  LocationHierarchyPickerProps,
  PropertyAutocompleteField,
  PropertyAutocompleteFieldProps,
  SmartLocationPicker,
  useCircadianFormContext,
} from '@circadian-risk/form';
import { useNotification } from '@circadian-risk/front-end-utils';
import { useLocationWizardModal } from '@circadian-risk/location-wizard';
import { HStack, InfoBox, LocationChip, NodeIcon, VStack } from '@circadian-risk/presentational';
import { useOrganizationSessionStore } from '@circadian-risk/stores';
import { Alert, Box, Button, Typography } from '@mui/material';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import { HTMLAttributes, memo, useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useAssessmentFormStore } from './AssessmentFormStore';
import { ScenarioFormData, TemplateData } from './ScenarioAssessmentForm.types';
import { ScenarioLinks } from './ScenarioLinks';
import { useSelectedScenarios } from './useSelectedScenarios';

interface TemplateSelectOption {
  value: number;
  label: string;
  scoredLayerId: string;
  deprecatedTemplatesCount: number;
}

interface Props {
  templates: TemplateData[];
}

export const RootLocationStep: React.FC<Props> = memo(({ templates }) => {
  const { editing, selectedTemplate } = useAssessmentFormStore(state => {
    const { selectedTemplate, editing } = state;
    return {
      editing,
      selectedTemplate,
    };
  });

  const scoredLayerId = selectedTemplate?.scoredLayerId;

  const relevantLocations = useRelevantLocations(scoredLayerId);

  const scoredLayer = useOrganizationSessionStore(store => (!scoredLayerId ? null : store.layersById[scoredLayerId]));

  const { displayError } = useNotification();

  const { nodesById, layersById } = useOrganizationSessionStore(({ nodesById, layersById }) => ({
    nodesById,
    layersById,
  }));

  const scoredLocationOptions = relevantLocations.map<LocationHierarchyPickerProps['options'][number]>(n => {
    return {
      id: n.id,
      layerId: n.layer_id,
      layerName: layersById[n.layer_id].name,
      layerTags: n.tags.map(t => t.type_name),
      mpath: n.mpath,
      name: n.name,
      ordinal: n.ordinal,
      parentId: n.parent_id,
    };
  });

  const hasNoRelevantLocation = scoredLocationOptions.length === 0;

  const {
    control,
    setValue,
    watch,
    formState: { errors, touchedFields },
  } = useFormContext<ScenarioFormData>();

  const openLocationWizard = useLocationWizardModal();

  const { ariaLabels } = useCircadianFormContext<ScenarioFormData>();

  const watchTemplateId = watch('assessmentTemplateId');
  const rootLocationId = watch('rootLocationId');

  const initialLocationName = useMemo(
    () => (rootLocationId ? nodesById[rootLocationId].name : null),
    [rootLocationId, nodesById],
  );

  useEffect(() => {
    if (watchTemplateId && !editing) {
      const template = templates.find(t => t.id === watchTemplateId);

      if (!template) {
        return;
      }

      const scenarioNames = template.scenarios.map(sc => sc.name).join(', ');

      if (!touchedFields.name && initialLocationName && scenarioNames) {
        setValue('name', `${scenarioNames} - ${initialLocationName ?? ''}`, { shouldValidate: true });
      }

      if (!touchedFields.description) {
        const scenarioDescriptions = template.scenarios
          .filter(sc => !isEmpty(sc.description))
          .map(sc => sc.description)
          .join('. ');

        setValue('description', scenarioDescriptions, { shouldValidate: true });
      }
    }

    if (!watchTemplateId) {
      setValue('rootLocationId', undefined);
    }
  }, [
    editing,
    initialLocationName,
    watchTemplateId,
    templates,
    setValue,
    touchedFields.name,
    touchedFields.description,
  ]);

  const templateSelectOptions = useMemo(() => {
    return templates.map<TemplateSelectOption>(template => ({
      label: template.name,
      value: template.id,
      scoredLayerId: template.scoredLayerId,
      deprecatedTemplatesCount: template.deprecatedTemplatesCount,
    }));
  }, [templates]);

  const validateTemplateIdSelect = (value: number | null) => {
    return value ? true : 'Required';
  };

  let infoMessage: string | null = null;
  if (watchTemplateId) {
    const watchedTemplate = templates.find(t => t.id === watchTemplateId);
    if (watchedTemplate && watchedTemplate.totalAssessments > 0) {
      infoMessage = watchedTemplate
        ? `${watchedTemplate.name} has been used to assess locations ${watchedTemplate.totalAssessments} times. ${watchedTemplate.totalAssessmentsInProgress} of these assessments are in progress.`
        : null;
    }
  }
  const selectedScenarios = useSelectedScenarios(watchTemplateId, templates);

  const renderOptionWithScoredLayerId = ({
    boxProps,
    label,
    layerName,
    deprecatedTemplatesCount = 0,
  }: {
    boxProps: HTMLAttributes<HTMLLIElement>;
    label: string;
    layerName: string;
    deprecatedTemplatesCount?: number;
  }) => {
    return (
      <Box component="li" {...boxProps}>
        <HStack>
          <NodeIcon layerName={layerName} />
          <Typography variant="body2">{label}</Typography>
          {deprecatedTemplatesCount > 0 && (
            <Typography variant={'caption'} fontStyle={'italic'}>
              {deprecatedTemplatesCount} deprecated template(s)
            </Typography>
          )}
        </HStack>
      </Box>
    );
  };

  return (
    <VStack spacing={2}>
      <Box>
        <Typography variant="h5">Location and Template</Typography>
        <Typography variant="body1">Choose a location and template</Typography>
      </Box>

      <PropertyAutocompleteField
        label={ariaLabels.assessmentTemplateId}
        htmlName="assessmentTemplateId"
        control={control}
        options={templateSelectOptions as unknown as PropertyAutocompleteFieldProps['options']}
        rules={{ required: 'Required', validate: validateTemplateIdSelect }}
        errorMessage={errors.assessmentTemplateId?.message}
        disabled={editing}
        renderOption={(props, option) => {
          const typedOption = option as unknown as TemplateSelectOption;
          const layer = layersById[typedOption.scoredLayerId];
          return renderOptionWithScoredLayerId({
            boxProps: props,
            label: option.label,
            layerName: layer.name,
            deprecatedTemplatesCount: typedOption.deprecatedTemplatesCount,
          });
        }}
      />

      <Controller
        name="rootLocationId"
        control={control}
        rules={{
          required: 'Required',
          validate: value => {
            const node = nodesById[value ?? ''];
            if (!node) {
              displayError(`Node id: ${value} not found`);
              return false;
            }
            if (!watchTemplateId) {
              return false;
            }

            const nodeTagIds = node.tags.map(t => t.id);
            const template = templates.find(template => template.id === watchTemplateId);
            if (!template) {
              return false;
            }
            const templateTagIds = template.tags.map(t => t.layer_tag_id);

            if (intersection(templateTagIds, nodeTagIds).length === 0) {
              return 'Location does not have tags that match the tags for the selected assessment template.';
            }
            return true;
          },
        }}
        render={({ field }) => (
          <SmartLocationPicker
            value={field.value ? [field.value] : null}
            multiple={false}
            disabled={!watchTemplateId || editing}
            label={ariaLabels.rootLocationId}
            onChange={newValue => field.onChange(newValue[0] ?? null)}
            allowedNodeIds={scoredLocationOptions.map(o => o.id)}
            errorMessage={errors.rootLocationId?.message}
            helperText={!watchTemplateId ? 'Select an assessment template to see available locations.' : undefined}
          />
        )}
      />

      {hasNoRelevantLocation && scoredLayer && (
        <Alert
          severity="warning"
          action={
            <Button color="warning" onClick={() => openLocationWizard({ type: 'adding' })}>
              Create Location(s)
            </Button>
          }
        >
          You need at least one <LocationChip sx={{ mx: 0.5 }} size="small" layerName={scoredLayer.name} /> in order to
          leverage this template
        </Alert>
      )}

      <ScenarioLinks scenarios={selectedScenarios} />

      {infoMessage && <InfoBox>{infoMessage}</InfoBox>}
    </VStack>
  );
});
