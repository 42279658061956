import { AnswerSemanticMeaning } from '@circadian-risk/front-end-utils';
import { Enum_Answer_Responses_Enum } from '@circadian-risk/graphql-types';
import { ResponseIcon } from '@circadian-risk/presentational';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Button, ButtonGroup, darken, lighten, SxProps, Theme, useTheme } from '@mui/material';
import React, { useMemo } from 'react';

import { QuestionProps } from '../question.types';
import { getAccessibleResponseAndMeaning, getHumanReadableResponse } from '../utils';
import { DisabledResponseButtonTooltip } from './DisabledResponseButtonTooltip';

export const getStripeFill = (color: string, stripeColor: string, hoverColor: string, width = 10) => {
  const stripedBackground = `repeating-linear-gradient(135deg,
    ${color}, 
    ${color} ${width}px, 
    ${stripeColor} ${width}px, 
    ${stripeColor} ${width * 2}px
  )`;

  const stripedBackgroundHover = `repeating-linear-gradient(135deg,
    ${hoverColor}, 
    ${hoverColor} ${width}px, 
    ${color} ${width}px, 
    ${color} ${width * 2}px
  )`;

  return {
    stripedBackground,
    stripedBackgroundHover,
  };
};

export interface ResponseButtonProps extends Pick<QuestionProps, 'questionRequired' | 'itemIsPresent'> {
  text: Enum_Answer_Responses_Enum;
  meaning: AnswerSemanticMeaning;
  selected?: boolean;
  disabled?: boolean;
  isPartialComplianceEnabled?: boolean;
  /**
   * If `questionRequired` and `itemIsPresent` are provided it will take presedence
   * applying the new business logic
   */
  isCompliantAnswerDisabled?: boolean;
  partialCompliance?: number | null;
  isImported?: boolean;
  compact?: boolean;

  onClick?: () => void;
  onPartialComplianceClick?: () => void;
}

export const ResponseButton = React.forwardRef<HTMLDivElement, ResponseButtonProps>((props, ref) => {
  const {
    text,
    selected,
    disabled,
    meaning,
    partialCompliance,
    isPartialComplianceEnabled,
    isImported,
    compact,
    onClick,
    onPartialComplianceClick,
    questionRequired,
    itemIsPresent,
    isCompliantAnswerDisabled,
  } = props;

  const buttonIcon = <ResponseIcon response={meaning} />;

  const shouldDisableWithTooltip: boolean = useMemo(() => {
    if (itemIsPresent === undefined || questionRequired === undefined) {
      return meaning === 'compliant' && Boolean(isCompliantAnswerDisabled);
    }

    if (itemIsPresent) return false;

    // If a question is optional, all responses should be available except the compliant answer
    if (!questionRequired && meaning === 'compliant') {
      return true;
    }

    // If a question is required, all responses should be blocked
    return questionRequired;
  }, [isCompliantAnswerDisabled, itemIsPresent, meaning, questionRequired]);

  const shouldShowPartialCompliance = isPartialComplianceEnabled && selected && meaning === 'deficient';
  const buttonText = getHumanReadableResponse(text);
  const accessibleResponseAndMeaning = getAccessibleResponseAndMeaning(meaning, text);
  const {
    palette: {
      organizationColors: { compliance },
      getContrastText,
    },
  } = useTheme();

  let buttonColor: string;
  switch (meaning) {
    case 'compliant':
      buttonColor = compliance.compliant;
      break;
    case 'deficient':
      buttonColor = compliance.deficient;
      break;
    case 'n_a':
    case 'n_o':
      buttonColor = compliance.unknown;
      break;
  }

  const contrastColor = getContrastText(buttonColor);
  const hoverColor = darken(buttonColor, 0.2);
  const stripeColor = lighten(buttonColor, 0.3);
  const { stripedBackground, stripedBackgroundHover } = getStripeFill(buttonColor, stripeColor, hoverColor);

  const buttonSx: SxProps<Theme> = ({ palette, transitions }) => ({
    border: `1px solid ${buttonColor}`,
    color: buttonColor,
    fontWeight: 500,
    transition: transitions.create(['color', 'background-color', 'border'], {
      duration: transitions.duration.short,
    }),
    '& .Mui-disabled': {
      border: `1px solid ${palette.action.disabled}`,
      opacity: palette.action.disabledOpacity,
      color: palette.text.disabled,
      '& .MuiSvgIcon-root': {
        color: palette.action.disabled,
      },
    },
  });
  const buttonSelectedSx: SxProps<Theme> = selected
    ? ({ palette }) => ({
        color: contrastColor,
        backgroundColor: buttonColor,
        background: isImported ? stripedBackground : undefined,
        '& .MuiSvgIcon-root': {
          color: contrastColor,
        },
        '&:hover': {
          border: `1px solid ${hoverColor}`,
          backgroundColor: hoverColor,
          background: isImported ? stripedBackgroundHover : undefined,
        },
        '&:disabled': {
          border: `1px solid ${buttonColor}`,
          color: contrastColor,
          backgroundColor: buttonColor,
          opacity: palette.action.disabledOpacity,
          '& .MuiSvgIcon-root': {
            color: contrastColor,
          },
        },
      })
    : {};

  const innerButton = (
    <Button
      onClick={onClick}
      role="option"
      startIcon={buttonIcon}
      aria-selected={selected}
      aria-label={accessibleResponseAndMeaning}
      sx={[{ flex: 1 }, buttonSx, buttonSelectedSx]}
    >
      <Box component="span" whiteSpace="nowrap">
        {buttonText}
        {shouldShowPartialCompliance && <span>:&nbsp;{`${(partialCompliance ?? 0) * 100}% Compliant`}</span>}
      </Box>
    </Button>
  );

  const partialComplianceButton = (
    <Button
      onClick={onPartialComplianceClick}
      size="small"
      color="primary"
      aria-label={'Partial Compliance Options'}
      sx={[buttonSx, buttonSelectedSx]}
    >
      <ArrowDropDownIcon />
    </Button>
  );

  return (
    <DisabledResponseButtonTooltip displayToolTip={shouldDisableWithTooltip && !disabled} meaning={meaning}>
      <ButtonGroup
        ref={ref}
        component="div"
        disableElevation
        disabled={Boolean(disabled) || shouldDisableWithTooltip}
        sx={theme => ({
          display: 'flex',
          flex: compact ? undefined : 1,
          width: compact ? undefined : '100%',
          [theme.breakpoints.up('sm')]: {
            flex: 0,
          },
        })}
      >
        {innerButton}
        {shouldShowPartialCompliance && !disabled && partialComplianceButton}
      </ButtonGroup>
    </DisabledResponseButtonTooltip>
  );
});
