import { CircadianRoute, ROUTES } from '@circadian-risk/routes';
import { lazyLoadWithVersionCheck } from '@web-app/router/lazyLoadWithVersionCheck';

const OrganizationDetails = lazyLoadWithVersionCheck(() => import('./OrganizationDetails'));

export const organizationRoutes: () => CircadianRoute[] = () => [
  {
    path: ROUTES.ORGANIZATION_PROFILE,
    component: OrganizationDetails,
  },
];
