import { BoxProps } from '@mui/material';
import isArrayLike from 'lodash/isArrayLike';
import isObjectLike from 'lodash/isObjectLike';
import size from 'lodash/size';

type SxProp = BoxProps['sx'];
export const combineSx = (...sxProps: (SxProp | undefined | null | boolean)[]): SxProp => {
  const filterOutEmpty = sxProps.filter(sxProp => {
    if (sxProp === true || sxProp === false) {
      return false;
    }

    if (!sxProp) {
      return false;
    }

    const canHaveLength = isArrayLike(sxProp) || isObjectLike(sxProp);
    const empty = canHaveLength ? size(sxProp) === 0 : false;
    if (empty) {
      return false;
    }

    return true;
  }) as SxProp[];

  return filterOutEmpty.reduce((prev, currentValue) => {
    return [...(Array.isArray(prev) ? prev : [prev]), ...(Array.isArray(currentValue) ? currentValue : [currentValue])];
  }, [] as NonNullable<SxProp>);
};
