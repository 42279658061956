/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { Control, FieldPath, FieldPathByValue, FieldValues } from 'react-hook-form';

// eslint-disable-next-line no-restricted-syntax
type PARENT_NAME = 'FIELD_SET_PARENT_NAME';

type InternalControl<Value> = Control<{
  [K in PARENT_NAME]: Value;
}>;

type GetName<Value> = {
  <
    K extends Exclude<Value, null | undefined> extends FieldValues
      ? FieldPath<Exclude<Value, null | undefined>>
      : string,
  >(
    key: K,
  ): `${PARENT_NAME}.${K}`;
  (): PARENT_NAME;
};

interface FieldsetComponent<Value, AdditionalProps extends {}> {
  (
    props: AdditionalProps & {
      control: InternalControl<Value>;
      getName: GetName<Value>;
    },
  ): React.ReactNode;
}

interface FieldsetReturnComponent<Value, AdditionalProps extends {}> {
  <F extends FieldValues>(
    props: { control: Control<F> } & AdditionalProps &
      (F extends Value ? { name?: FieldPathByValue<F, Value> } : { name: FieldPathByValue<F, Value> }) & {},
  ): React.ReactNode;

  /* When using fieldset, inside of another fieldset */
  (props: { control: InternalControl<Value>; name: PARENT_NAME } & AdditionalProps): React.ReactNode;
}

export const withFieldsetControl = <Value, AdditionalProps extends {} = {}>(
  Component: FieldsetComponent<Value, AdditionalProps>,
) => {
  const FieldsetComponent = (({ name, ...props }: any) => {
    const getName = (key?: string) => {
      if (key) {
        return name ? `${name}.${key}` : key;
      }
      return name;
    };
    return <Component {...(props as any)} getName={getName} key={name} />;
  }) as FieldsetReturnComponent<Value, AdditionalProps>;

  return FieldsetComponent;
};
