import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useTheme } from '@mui/material';

import { OptionForConsiderationFlair } from '../../OptionForConsideration';

export const CustomWizardOptionFlair: React.FC<{ tooltip?: string; disabled?: boolean }> = ({ tooltip, disabled }) => {
  const theme = useTheme();
  return (
    <OptionForConsiderationFlair
      key={'option-choice'}
      color={disabled ? theme.palette.text.disabled : theme.palette.secondary.main}
      Icon={BorderColorIcon}
      id="custom"
      tooltip={tooltip ?? 'You have chosen to provide your own option for this task action'}
    />
  );
};
