import { AssignmentManagement, GroupRow, mapOrgUserToUserRow, UserRow } from '@circadian-risk/assessment-components';
import { useOrganizationSessionStore, useOrganizationUserGroups, useOrganizationUsers } from '@circadian-risk/stores';
import { useMemo } from 'react';

type UsersStepContentProps = {
  locationIds: string[];
};

export const UsersStepContent: React.FC<UsersStepContentProps> = ({ locationIds }) => {
  const orgUserGroups = useOrganizationUserGroups();
  const getRelevantLocationTeams = useOrganizationSessionStore(state => state.getRelevantLocationTeams);

  const teams = useMemo(() => {
    const data = getRelevantLocationTeams(...locationIds);
    return data
      .map(team => ({
        id: team.id.toString(),
        name: team.name,
        users: team.users.map(mapOrgUserToUserRow),
      }))
      .filter(t => t.users.length > 0);
  }, [locationIds, getRelevantLocationTeams]);

  const groups = useMemo(() => {
    return orgUserGroups
      .map<GroupRow>(group => ({
        id: group.id.toString(),
        name: group.name,
        users: group.users.map(mapOrgUserToUserRow),
      }))
      .filter(g => g.users.length > 0);
  }, [orgUserGroups]);

  const users = useOrganizationUsers().map<UserRow>(ou => ({
    id: ou.id,
    displayName: ou.displayName,
    email: ou.email,
    role: ou.role,
  }));

  return <AssignmentManagement teams={teams} groups={groups} users={users} />;
};
