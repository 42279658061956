import { Box } from '@mui/material';
import isString from 'lodash/isString';
import React from 'react';
import { Controller } from 'react-hook-form';

import { TextAreaAutosizeField, TextAreaAutosizeFieldProps } from '../../TextAreaAutosizeField';
import { PropertyFieldProps } from '../types';

type PropertyTextAreaFieldProps = PropertyFieldProps & {
  textAreaAutosizeProps?: Partial<TextAreaAutosizeFieldProps>;
};

export const PropertyTextAreaField: React.FC<PropertyTextAreaFieldProps> = ({
  label,
  htmlName,
  control,
  rules,
  boxProps,
  errorMessage,
  disabled,
  textAreaAutosizeProps,
  onBlur: propsOnBlur,
}) => {
  return (
    <Box {...boxProps}>
      <Controller
        control={control}
        name={htmlName}
        defaultValue={null}
        rules={rules}
        render={fieldProps => {
          const { value, onChange, onBlur } = fieldProps.field;
          const handleBlur = () => {
            onBlur();
            if (propsOnBlur) {
              propsOnBlur(value);
            }
          };
          return (
            <TextAreaAutosizeField
              label={label}
              name={htmlName}
              disabled={disabled}
              onBlur={handleBlur}
              errorMessage={errorMessage}
              value={value ?? ''}
              onChange={onChange}
              placeholder={isString(label) ? label : undefined}
              {...textAreaAutosizeProps}
            />
          );
        }}
      />
    </Box>
  );
};
