import { TreeNode } from '@circadian-risk/shared';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SxProps, Theme } from '@mui/material';
import { TreeView } from '@mui/x-tree-view/TreeView';
import React, { useCallback, useEffect, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

export interface NavigationTreeViewProps<T extends TreeNode> {
  tree: T;
  renderTree: (node: T) => JSX.Element;
  onSelect?: (nodeId: string) => void;
  selectedNode?: string;
  expandedNodes?: string[];
  sx?: SxProps<Theme>;
  disableSelection?: boolean;
}

export const NavigationTreeView = <T extends TreeNode>({
  tree,
  renderTree,
  onSelect,
  disableSelection,
  expandedNodes = [],
  selectedNode,
  sx,
}: NavigationTreeViewProps<T>) => {
  const [expanded, setExpanded] = useState<string[]>(expandedNodes);
  const [selected, setSelected] = useState<string | undefined>(selectedNode);

  const handleToggle = useCallback((_event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  }, []);

  const handleSelect = useCallback(
    (_event: React.SyntheticEvent, nodeId: string) => {
      onSelect && onSelect(nodeId);
      setSelected(nodeId);
    },
    [onSelect],
  );

  useDeepCompareEffect(() => {
    setExpanded(expandedNodes);
  }, [expandedNodes]);

  useEffect(() => {
    setSelected(selectedNode);
  }, [selectedNode]);

  return (
    <TreeView
      sx={sx}
      expanded={expanded}
      selected={selected}
      disableSelection={disableSelection}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      onNodeToggle={handleToggle}
      onNodeSelect={handleSelect}
    >
      {renderTree(tree)}
    </TreeView>
  );
};
