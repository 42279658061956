import { Nodes_Insert_Input } from '@circadian-risk/graphql-types';
import { AddressConfig, customCoordinatesSchema } from '@circadian-risk/shared';
import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import { booleanQueryParamSchema } from './shared-schemas/booleanQueryParam.schema';

const c = initContract();

const updateNodeOrdinalSchema = z.array(
  z.object({
    id: z.string().uuid(),
    ordinal: z.number(),
  }),
);

const baseNodeSchema = z.object({
  address: z.custom<AddressConfig>().nullish(),
  useCustomCoordinates: z.boolean().optional(),
  coordinates: customCoordinatesSchema.optional(),
  metadata: z.object({}).optional(),
  ordinal: z.number().optional(),
});

const updateNodeSchema = baseNodeSchema.extend({
  name: z.string().optional(),
  layerId: z.string().uuid().optional(),
  parentId: z.string().uuid().optional(),
});

const createNodeSchema = baseNodeSchema.extend({
  name: z.string(),
  layerId: z.string().uuid(),
  parentId: z.string().uuid(),
});

const createNodeResponseSchema = z.object({
  id: z.string(),
  relatedScenarioIds: z.array(z.number()),
});

const hierarchySchema = z.object({
  id: z.string(),
  name: z.string(),
  depth: z.number(),
});

const nodeImportMetaSchema = z.record(
  z.string(),
  z.object({
    before: z.number(),
    updated: z.number(),
    inserted: z.number(),
  }),
);

const hierarchyImportResponseSchema = z.object({
  meta: nodeImportMetaSchema,
  records: z.array(z.custom<Nodes_Insert_Input>()),
});

export type UpdateNodeOrdinal = z.infer<typeof updateNodeOrdinalSchema>;
export type UpdateNode = z.infer<typeof updateNodeSchema>;
export type CreateNode = z.infer<typeof createNodeSchema>;
export type CreateNodeResponse = z.infer<typeof createNodeResponseSchema>;
export type HierarchyDepth = z.infer<typeof hierarchySchema>;
export type HierarchyImportResponse = z.infer<typeof hierarchyImportResponseSchema>;
export type NodeImportMeta = z.infer<typeof nodeImportMetaSchema>;

export const nodesContract = c.router(
  {
    updateOrdinals: {
      method: 'POST',
      path: '/:organizationId/ordinals',
      pathParams: z.object({
        organizationId: z.string().uuid(),
      }),
      description: "Update several nodes' `ordinal` column",
      body: updateNodeOrdinalSchema,
      responses: {
        201: z.object({
          success: z.boolean(),
        }),
      },
    },
    update: {
      method: 'PATCH',
      path: '/:organizationId/:id',
      pathParams: z.object({
        organizationId: z.string().uuid(),
        id: z.string().uuid(),
      }),
      body: updateNodeSchema,
      responses: {
        204: z.object({
          id: z.string(),
        }),
      },
    },
    create: {
      method: 'POST',
      path: '/:organizationId',
      pathParams: z.object({
        organizationId: z.string().uuid(),
      }),
      body: createNodeSchema,
      responses: {
        201: createNodeResponseSchema,
      },
    },
    getHierarchy: {
      method: 'GET',
      path: '/:organizationId/hierarchy',
      pathParams: z.object({
        organizationId: z.string().uuid(),
      }),
      description: 'Returns organization hierarchy with depth',
      responses: {
        200: z.array(hierarchySchema),
      },
    },
    exportHierarchy: {
      method: 'GET',
      path: '/:organizationId/hierarchy/export',
      pathParams: z.object({
        organizationId: z.string().uuid(),
      }),
      description: 'Exports organization hierarchy to CSV',
      responses: {
        200: z.object({}),
      },
    },
    hierarchyImport: {
      method: 'POST',
      path: '/:organizationId/hierarchy/import',
      pathParams: z.object({
        organizationId: z.string().uuid(),
      }),
      query: z.object({
        preview: booleanQueryParamSchema
          .default('true')
          .describe('Indicates whether the import should perform real changes to the database or act as a dry run'),
      }),
      description:
        'Upload organization location hierarchy CSV (database changes will only occur if preview is set to false)',
      contentType: 'multipart/form-data',
      body: z.object({
        file: z.custom<File>(),
      }),
      responses: {
        200: hierarchyImportResponseSchema,
      },
    },
    delete: {
      method: 'DELETE',
      path: '/:id',
      pathParams: z.object({
        id: z.string().uuid(),
      }),
      body: z.object({}),
      responses: {
        200: z.object({
          id: z.string(),
        }),
        500: z.object({
          message: z.string(),
        }),
      },
    },
  },
  {
    pathPrefix: '/nodes',
  },
);
