import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ColumnApi, GridReadyEvent, SelectionChangedEvent } from '@ag-grid-community/core';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { DataGrid, DetailCellRendererParams, masterDetailsSx, useGrid, useGridFilter } from '@circadian-risk/data-grid';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import uniq from 'lodash/uniq';
import { useRef } from 'react';
import { useLatest } from 'react-use';

import {
  BaseItemAnswerDetailRowData,
  BaseItemAnswerMasterRowData,
  ItemAnswerMasterDetailTableProps,
} from './item-answer-master-detail.types';

const PREFIX = 'ItemAnswerMasterDetailTable';

const classes = {
  masterDetailHeader: `${PREFIX}-masterDetailHeader`,
  masterDetailCell: `${PREFIX}-masterDetailCell`,
};

export const useDetailCellClass = () => {
  return clsx(classes.masterDetailCell, 'circadian-ag-text-wrap-column');
};

const ItemAnswerMasterDetailTableInner = <
  MasterRowData extends BaseItemAnswerMasterRowData<DetailRowData>,
  DetailRowData extends BaseItemAnswerDetailRowData,
>({
  onAnswerSelectionChanged,
  rowData,
  columnDefs,
  detailsColumnDefs,
  context,
  handleGridReady: customGridReady,
  selectedAnswers: selectedAnswerIds,
  extraDetailGridOptions,
  ...rest
}: ItemAnswerMasterDetailTableProps<MasterRowData, DetailRowData>) => {
  const filterHook = useGridFilter();
  const { onGridReady } = useGrid();
  const columnApiRef = useRef<ColumnApi | null>(null);
  const latestSelectedAnswerIds = useLatest(selectedAnswerIds);

  const handleDetailsGridSelectionChanged = (_itemId: string, e: SelectionChangedEvent) => {
    const detailRowSelected: DetailRowData[] = [];
    const detailRowNotSelected: DetailRowData[] = [];
    e.api.forEachNode(n => {
      if (n.isSelected()) {
        detailRowSelected.push(n.data);
      } else {
        detailRowNotSelected.push(n.data);
      }
    });

    if (onAnswerSelectionChanged) {
      const newSelection = uniq(
        [...latestSelectedAnswerIds.current, ...detailRowSelected].filter(id => !detailRowNotSelected.includes(id)),
      );
      onAnswerSelectionChanged(newSelection);
    }
  };

  const handleGridReady = (event: GridReadyEvent) => {
    columnApiRef.current = event.columnApi;
    onGridReady(event);
    if (customGridReady) {
      customGridReady(event);
    }
    setTimeout(() => {
      event.columnApi.autoSizeAllColumns(false);
    }, 0);
  };

  const makeDetailCellRendererParams = (masterRow: { data: MasterRowData }) => {
    const detailCellRendererParams: Partial<DetailCellRendererParams<MasterRowData>> = {
      refreshStrategy: 'rows',
      detailGridOptions: {
        ...extraDetailGridOptions,
        enableCellTextSelection: true,
        onGridReady: e => {
          if (extraDetailGridOptions?.onGridReady) {
            extraDetailGridOptions.onGridReady(e);
          }
          e.columnApi.autoSizeAllColumns(false);
          e.api.forEachNode(n => {
            if (latestSelectedAnswerIds.current.includes(n.data.answer_id)) {
              n.setSelected(true, true);
            }
          });
        },
        onSelectionChanged: (e: SelectionChangedEvent) => handleDetailsGridSelectionChanged(masterRow.data.item_id, e),
        columnDefs: detailsColumnDefs,
        rowSelection: 'multiple',
        suppressRowClickSelection: true,
        defaultColDef: {
          flex: 1,
          resizable: true,
          sortable: true,
          filter: true,
          headerClass: classes.masterDetailHeader,
          cellClass: classes.masterDetailCell,
        },
        getRowId: ({ data }: { data: BaseItemAnswerDetailRowData }) => {
          return data.answer_id;
        },
        context,
      },
      getDetailRowData: params => {
        if (params.data.getAnswersLazily) {
          params.data.getAnswersLazily().then(answers => params.successCallback(answers));
        } else {
          params.successCallback(params.data.answers);
        }
      },
    };

    return detailCellRendererParams;
  };

  return (
    <DataGrid<MasterRowData>
      tableWrapperProps={{ sx: masterDetailsSx }}
      onGridReady={handleGridReady}
      animateRows
      defaultColDef={{ flex: 1, resizable: true }}
      masterDetail
      modules={[ClientSideRowModelModule, MasterDetailModule]}
      columnDefs={columnDefs}
      rowData={rowData}
      detailRowAutoHeight
      context={context}
      detailCellRendererParams={(masterGridParams: { data: MasterRowData }) => {
        return makeDetailCellRendererParams(masterGridParams);
      }}
      onFilterChanged={filterHook.onFilterChanged}
      onFirstDataRendered={filterHook.onFirstDataRendered}
      paginationPageSize={20}
      keepDetailRows
      keepDetailRowsCount={100}
      getRowId={({ data }) => (data as MasterRowData).item_id}
      {...rest}
    />
  );
};

const StyledItemAnswerMasterDetailTableInner = styled(ItemAnswerMasterDetailTableInner)(({ theme }) => ({
  [`& .${classes.masterDetailHeader}`]: {
    backgroundColor: theme.palette.background.default,
  },

  [`& .${classes.masterDetailCell}`]: {
    backgroundColor: theme.palette.background.default,
    '& .ag-react-container': {
      width: '100%',
    },
  },
}));

export const ItemAnswerMasterDetailTable = <
  MasterRowData extends BaseItemAnswerMasterRowData<DetailRowData>,
  DetailRowData extends BaseItemAnswerDetailRowData,
>({
  ...props
}: ItemAnswerMasterDetailTableProps<MasterRowData, DetailRowData>) => {
  return <StyledItemAnswerMasterDetailTableInner {...(props as any)} />;
};
