import { ApolloProvider } from '@apollo/client';
import { sessionBasedClientFactory } from '@circadian-risk/apollo-utils';
import { USER_ROLE } from '@circadian-risk/shared';
import { HASURA_URL } from '@web-app/lib/constants';
import React, { useMemo } from 'react';

/**
 * Higher Order Component that adds a ConfirmationDialogProvider
 */
export const withLocationManagerApolloClient = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  const Component = React.forwardRef((props: P, ref) => {
    const apolloClient = useMemo(() => {
      return sessionBasedClientFactory({
        uri: HASURA_URL,
        hasuraRole: USER_ROLE.LOCATION_MANAGER,
      });
    }, []);

    return (
      <ApolloProvider client={apolloClient}>
        <WrappedComponent {...props} ref={ref} />
      </ApolloProvider>
    );
  });
  return Component;
};
