import { alpha, Box, BoxProps, Theme, useTheme } from '@mui/material';
import { motion, useInView, useScroll, useTransform } from 'framer-motion';
import { CSSProperties, useRef } from 'react';

const shadowHeight = 2;

type Shape = 'linear' | 'radial';

const getGradient = (side: 'top' | 'bottom', shape: Shape, color: string) => {
  const gradient = shape === 'linear' ? 'linear-gradient' : 'radial-gradient';
  const direction = side === 'top' ? '180deg' : '0deg';
  const shadow = alpha(color, 0.3);
  const transparent = alpha(color, 0);

  switch (shape) {
    case 'linear':
      return `${gradient}(${direction}, ${shadow}, ${transparent})`;

    case 'radial': {
      const position = side === 'top' ? '50% 0' : '50% 100%';
      return `${gradient}(farthest-side at ${position}, ${shadow}, ${transparent})`;
    }
  }
};

const getShadowStyle = (
  theme: Theme,
  side: 'top' | 'bottom',
  shape: 'linear' | 'radial',
  hasScrollbars: boolean,
): CSSProperties => {
  const { spacing, palette, transitions } = theme;

  return {
    zIndex: 1,
    display: hasScrollbars ? undefined : 'none',
    top: side === 'top' ? ' 0' : 'auto',
    bottom: side === 'bottom' ? '0' : 'auto',
    position: 'sticky',
    pointerEvents: 'none',
    width: '100%',
    height: spacing(shadowHeight),
    marginBottom: side === 'bottom' ? spacing(-shadowHeight) : '0',
    marginTop: side === 'top' ? spacing(-shadowHeight) : '0',
    transition: transitions.create('opacity', { duration: transitions.duration.shortest }),
    background: getGradient(side, shape, palette.text.primary),
  };
};

type Props = BoxProps & { shape?: Shape };

/**
 *
 * Allows showing top and bottom shadows when the scroll area is scrollable for better end user experience.
 *
 * Inspired by: https://www.qovery.com/blog/adding-elegant-shadows-with-react-to-invite-users-to-scroll
 * Reworked to use framer-motion and avoiding re-renders
 *
 * Performance note: there are no re-renders when you scroll or when the content changes thanks to how framer-motion MotionValues work.
 */
export const ScrollAreaShadow: React.FC<Props> = ({ children, shape = 'radial', ...boxProps }) => {
  const theme = useTheme();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const childrenFullyVisible = useInView(contentRef, { amount: 'all' });
  const hasScrollbars = !childrenFullyVisible;

  const { scrollYProgress } = useScroll({ container: wrapperRef });
  const rubberBandPoint = 0.05; // 5% of the scrollable area
  const topOpacity = useTransform(scrollYProgress, [0, rubberBandPoint, 1], [0, 0.5, 1]);
  const bottomOpacity = useTransform(scrollYProgress, [0, 1 - rubberBandPoint, 1], [1, 0.5, 0]);

  const topShadowStyle = getShadowStyle(theme, 'top', shape, hasScrollbars);
  const bottomShadowStyle = getShadowStyle(theme, 'bottom', shape, hasScrollbars);

  return (
    <Box ref={wrapperRef} overflow="auto" position="relative" {...boxProps}>
      {/* top shadow */}
      <motion.div style={{ opacity: topOpacity, ...topShadowStyle }} />

      <div ref={contentRef}>{children}</div>

      {/* bottom shadow */}
      <motion.div style={{ opacity: bottomOpacity, ...bottomShadowStyle }} />
    </Box>
  );
};
