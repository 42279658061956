import { Box, Breadcrumbs, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import React from 'react';

import { IStandard } from './StandardsGroupWithDialog';

export interface StandardsDialogProps extends IStandard {
  open: boolean;
  onClose?: () => void;
}

export const StandardsDialog: React.FC<StandardsDialogProps> = ({
  open,
  onClose,
  name,
  section1,
  section2,
  section3,
  relevantExcerpt,
}) => {
  const breadcrumbs = [section1, section2, section3].filter(Boolean);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle color={'primary'}>{name}</DialogTitle>
      <DialogContent>
        <Breadcrumbs separator={'>'} aria-label={'standards breadcrumbs'}>
          {breadcrumbs.map((label, i) => (
            <Typography key={`standard-${label}`} color="text.secondary" aria-label={`standard section ${i + 1}`}>
              {label}
            </Typography>
          ))}
        </Breadcrumbs>
        <Box mt={breadcrumbs.length > 0 ? 2 : undefined}>
          <Typography fontWeight={700} variant={'body1'}>
            RELEVANT EXCERPT
          </Typography>
          <Typography
            aria-label={'revelant excerpt'}
            variant={'body2'}
            dangerouslySetInnerHTML={{
              __html: relevantExcerpt ? DOMPurify.sanitize(relevantExcerpt) : '<i>No relevant excerpt provided.</i>',
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color={'secondary'} onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
