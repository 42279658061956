import { useGetAssessmentUsersSubscription } from '@circadian-risk/client-graphql-hooks';
import { PropertyFieldProps } from '@circadian-risk/form';
import { tryFormatUserName } from '@circadian-risk/shared';
import { Box, Typography } from '@mui/material';
import {
  ShowColumnOption,
  UsersGridSelectorControlled,
  UsersRowData,
} from '@web-app/components/UserGridSelector/UserGridSelectorControlled';
import React, { useMemo } from 'react';
import { useController } from 'react-hook-form';

interface PropertyUserSelectionFieldProps extends PropertyFieldProps {
  users: UsersRowData[];
}

const showColumns: ShowColumnOption[] = ['userName', 'email', 'role', 'title', 'company', 'bio'];

export const PropertyUserSelectionField: React.FC<PropertyUserSelectionFieldProps> = props => {
  const { htmlName, control, users } = props;

  const { field } = useController({
    name: htmlName,
    control,
    rules: { required: true },
  });

  const handleSelectUsers = (selectedUsers: UsersRowData[]) => {
    field.onChange(selectedUsers.map(u => u.id));
  };

  return (
    <UsersGridSelectorControlled
      users={users}
      selectedUserIds={field.value}
      showColumns={showColumns}
      onSelect={handleSelectUsers}
    />
  );
};

interface PropertyUserSelectionFieldWrapperProps extends PropertyFieldProps {
  organizationId: string;
  assessmentId: number;
}

export const PropertyUserSelectionFieldWrapper: React.FC<PropertyUserSelectionFieldWrapperProps> = props => {
  const { control, htmlName, label, endAdornment } = props;
  const { organizationId, assessmentId } = props;
  // TODO(v-rrodrigues)[CR-5569]
  const { data } = useGetAssessmentUsersSubscription({ variables: { assessmentId, organizationId } });
  const users = useMemo(() => {
    if (!data?.assessment_users) {
      return [];
    }

    return data.assessment_users
      .filter(({ user }) => user)
      .map<UsersRowData>(({ user }) => {
        const { id, email, title, company, bio, avatar, last_signed_in_at } = user;
        return {
          id,
          email,
          userName: tryFormatUserName(user),
          role: user.users_organizations_organizations[0].role,
          title,
          company,
          bio,
          avatarImgSrc: avatar?.filepath,
          avatar,
          last_signed_in_at,
        };
      });
  }, [data]);

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" my={0.5} mx={1.75}>
        <Typography variant="body1">{label}</Typography>
        {endAdornment}
      </Box>
      <PropertyUserSelectionField label={'Assessor Biographies'} htmlName={htmlName} control={control} users={users} />
    </>
  );
};
