import React from 'react';

import { ErrorMessageCard, ErrorMessageCardProps } from './ErrorMessageCard';

export type ErrorFetchingDataCardProps = ErrorMessageCardProps;

/**
 * This is just a wrapper around ErrorMessageCard with default error messages
 */
export const ErrorFetchingDataCard: React.FC<ErrorFetchingDataCardProps> = props => {
  return <ErrorMessageCard {...props} title={props.title ?? 'Error Fetching Data'} />;
};
