import { CriticalityNumber } from '../AssessmentCompliancePercentCard/types';

export const extractCriticalityNumbersFromScore = (score: {
  low_deficiency_count: number;
  medium_deficiency_count: number;
  high_deficiency_count: number;
}): CriticalityNumber[] => {
  const { low_deficiency_count, medium_deficiency_count, high_deficiency_count } = score;

  return [
    {
      label: 'Low',
      criticality: 1,
      num: low_deficiency_count,
    },
    {
      label: 'Medium',
      criticality: 2,
      num: medium_deficiency_count,
    },
    {
      label: 'High',
      criticality: 3,
      num: high_deficiency_count,
    },
  ];
};
