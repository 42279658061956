import { CriticalityIcon, HStack, VStack } from '@circadian-risk/presentational';
import { useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

export const createCriticalityHeaderHook = (criticality: number) => {
  // see: https://www.ag-grid.com/react-data-grid/component-header/
  return (props: any) => {
    const theme = useTheme();
    const criticalityColor = theme.palette.organizationColors.criticality[String(criticality) as '1' | '2' | '3'];

    const [ascSort, setAscSort] = useState('inactive');
    const [descSort, setDescSort] = useState('inactive');
    const [noSort, setNoSort] = useState('inactive');
    const refButton = useRef(null);

    const onMenuClicked = () => {
      props.showColumnMenu(refButton.current);
    };

    const onSortChanged = () => {
      setAscSort(props.column.isSortAscending() ? 'active' : 'inactive');
      setDescSort(props.column.isSortDescending() ? 'active' : 'inactive');
      setNoSort(!props.column.isSortAscending() && !props.column.isSortDescending() ? 'active' : 'inactive');
    };

    const onSortRequested = (order: any, event: any) => {
      props.setSort(order, event.shiftKey);
    };

    useEffect(() => {
      props.column.addEventListener('sortChanged', onSortChanged);
      onSortChanged();
    }, []);

    let menu = null;
    if (props.enableMenu) {
      menu = (
        <div ref={refButton} className="customHeaderMenuButton" onClick={() => onMenuClicked()}>
          <i className={`fa ${props.menuIcon}`}></i>
        </div>
      );
    }

    let sort = null;
    if (props.enableSorting) {
      sort = (
        <div style={{ display: 'inline-block' }}>
          <div
            onClick={event => onSortRequested('asc', event)}
            onTouchEnd={event => onSortRequested('asc', event)}
            className={`customSortDownLabel ${ascSort}`}
          >
            <i className="fa fa-long-arrow-alt-down"></i>
          </div>
          <div
            onClick={event => onSortRequested('desc', event)}
            onTouchEnd={event => onSortRequested('desc', event)}
            className={`customSortUpLabel ${descSort}`}
          >
            <i className="fa fa-long-arrow-alt-up"></i>
          </div>
          <div
            onClick={event => onSortRequested('', event)}
            onTouchEnd={event => onSortRequested('', event)}
            className={`customSortRemoveLabel ${noSort}`}
          >
            <i className="fa fa-times"></i>
          </div>
        </div>
      );
    }

    return (
      <HStack>
        {menu}
        <VStack justifyContent={'center'} alignItems={'center'}>
          <span>{props.displayName}</span>
          <CriticalityIcon
            maxCriticality={3}
            criticality={Number(criticality)}
            color={criticalityColor}
            boxProps={{ minWidth: 0, justifyContent: 'center', gap: 1 }}
          />
        </VStack>
        {sort}
      </HStack>
    );
  };
};
