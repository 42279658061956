import keyBy from 'lodash/keyBy';

import { tsObject } from '../type-safe-object-entries';

/**
 * Convenience utility for fixtures across the application to use images we can relate to.
 */
export const PLACEHOLDER_STAFF_IMAGES = {
  DAN: 'https://www.circadianrisk.com/images/_team/Dan-Young-1-600x494.jpg',
  EVAN: 'https://www.circadianrisk.com/images/Evan-Thomas-600x494.jpg',
  JESSE: 'https://www.circadianrisk.com/images/_team/image-1-2.jpg',
  LYNN: 'https://www.circadianrisk.com/images/Lynn-Stinson_2022-11-15-215013_deda.jpeg',
  MICHAEL: 'https://www.circadianrisk.com/images/Michael-2.jpg',
  MIKE: 'https://www.circadianrisk.com/images/PXL_20220930_150616086.PORTRAIT.jpg',
  MILES: 'https://www.circadianrisk.com/images/Miles-Macklin.jpeg',
  PAUL: 'https://www.circadianrisk.com/images/_team/Paul-M-600x494.jpg',
  VANYA: 'https://www.circadianrisk.com/images/_team/image-1-1.jpg',
};

export const PLACEHOLDER_STAFF = tsObject.entries(PLACEHOLDER_STAFF_IMAGES).map(([name, imageSrc], index) => ({
  id: String(index),
  name,
  imageSrc,
  displayName: name,
}));

export const PLACEHOLDER_STAFF_BY_ID = keyBy(PLACEHOLDER_STAFF, s => s.id);
