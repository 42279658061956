import { CircadianRoute, ROUTES } from '@circadian-risk/routes';
import { USER_ROLE } from '@circadian-risk/shared';
import { lazyLoadWithVersionCheck } from '@web-app/router/lazyLoadWithVersionCheck';
import React from 'react';

import LocationTable from './LocationTable';

const LocationDetailWrapper = lazyLoadWithVersionCheck(() => import('./LocationDetails/LocationDetailWrapper'));

export const nodeRoutes = (): CircadianRoute[] => {
  return [
    {
      path: `${ROUTES.NODES}/:id`,
      component: () => <LocationDetailWrapper />,
      pageTitle: 'Locations',
      allowRoles: [USER_ROLE.ORGANIZATION_ADMIN, USER_ROLE.LOCATION_MANAGER],
    },
    {
      path: ROUTES.NODES,
      component: () => <LocationTable />,
      pageTitle: 'Locations',
      allowRoles: [USER_ROLE.ORGANIZATION_ADMIN, USER_ROLE.LOCATION_MANAGER],
    },
  ];
};
