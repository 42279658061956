import { initContract } from '@ts-rest/core';

import {
  aiContract,
  assessmentsContract,
  authContract,
  considerationOptionsContract,
  eulaContract,
  filesContract,
  iconsContract,
  itemCategoriesContract,
  layersContract,
  meContract,
  nodeMapboxContract,
  nodesContract,
  organizationItemsContract,
  organizationsContract,
  reportsContract,
  scenarioCatalogsContract,
  scenariosContract,
} from './domain-contracts';

const c = initContract();

export const contract = c.router(
  {
    auth: authContract,
    layers: layersContract,
    eula: eulaContract,
    itemCategories: itemCategoriesContract,
    nodes: nodesContract,
    considerationOptions: considerationOptionsContract,
    nodeMapbox: nodeMapboxContract,
    scenarioCatalogs: scenarioCatalogsContract,
    organizationItems: organizationItemsContract,
    scenarios: scenariosContract,
    me: meContract,
    organizations: organizationsContract,
    ai: aiContract,
    reports: reportsContract,
    assessments: assessmentsContract,
    icons: iconsContract,
    files: filesContract,
  },
  {
    pathPrefix: '/api/v2',
  },
);
