import AddIcon from '@mui/icons-material/Add';
import HideSourceIcon from '@mui/icons-material/HideSource';
import { alpha, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { useRef, useState } from 'react';

import { FilterOperator } from '../filter.types';
import { ChipButton } from './ChipButton';
import { FilterDropdownPopover } from './FilterDropdownPopover';

const operatorHumanReadable: Record<FilterOperator, string> = {
  is: 'is',
  isAnyOf: 'is any of',
  noneOf: 'is not',
};

const operatorIcons: Record<FilterOperator, React.ReactNode> = {
  is: <AddIcon />,
  isAnyOf: <AddIcon />,
  noneOf: <HideSourceIcon />,
};

export interface FilterOperatorOptionProps {
  possibleValues: FilterOperator[];
  value: FilterOperator;
  onOperatorChange: (operator: FilterOperator) => void;
}

export const FilterOperatorOption: React.FC<FilterOperatorOptionProps> = ({
  value,
  possibleValues,
  onOperatorChange,
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLButtonElement | null>(null);

  return (
    <>
      <ChipButton
        ref={ref}
        onClick={() => setOpen(!open)}
        // aria-label={filterGroupAriaLabels.FilterOperator}
        sx={{
          color: theme => theme.palette.action.active,
          textTransform: 'uppercase',
          bgcolor: theme => alpha(theme.palette.text.secondary, 0.02),
        }}
      >
        {operatorHumanReadable[value]}
      </ChipButton>
      <FilterDropdownPopover anchorEl={ref.current} open={open} onClose={() => setOpen(false)}>
        <List dense sx={{ py: 0, borderRadius: 2, p: 1 }}>
          {possibleValues.map(pv => (
            <ListItemButton
              dense
              key={pv}
              onClick={() => {
                setOpen(false);
                onOperatorChange(pv);
              }}
            >
              <ListItemIcon>{operatorIcons[pv]}</ListItemIcon>
              <ListItemText primary={operatorHumanReadable[pv]} />
            </ListItemButton>
          ))}
        </List>
      </FilterDropdownPopover>
    </>
  );
};
