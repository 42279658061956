import React from 'react';

import { FilesDialogData, useFilesDialog } from '..';
import { FilesCell } from './FilesCell';

export type FilesCellRendererProps = { data: FilesDialogData };
export const FilesCellRenderer: React.FC<FilesCellRendererProps> = ({ data }) => {
  const { setData } = useFilesDialog();
  const { files = [], answers = [] } = data;

  const relatedFiles = answers.flatMap(a => a.files);
  const relatedFilesCount = relatedFiles.length > 0 ? relatedFiles.length : 0;

  const handleClick = () => {
    setData(data);
  };

  return <FilesCell filesCount={files.length} relatedFilesCount={relatedFilesCount} onClick={handleClick} />;
};
