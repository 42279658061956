import { useMemo } from 'react';

/**
 * Creates a memoized value that is structurally compared based on its JSON representation
 *
 * @param input
 */
export const useStructuralMemo = <T,>(input: T): T => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => input, [JSON.stringify(input)]);
};
