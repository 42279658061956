import { useMemo } from 'react';

import { FilterModel, FilterObject } from '..';
import { processFilter } from './utils';

export type FilteredDataParams<Row, FilterKey extends string> = {
  rows: Row[];
  filterModel: FilterModel<FilterKey>;
  /**
   * Maps the filterModel key to the row key field
   *
   * @description **Important** The mapped field should only contain a string/number or an array of string/number value(s)
   * @example { locations: location_id }: The mapped field within the row which can be a number/string
   * { locations: locationIds }: The mapped field is a list of string or numbers but not nested objects
   * @default `It will attempt to read a property with the same exact field as the key`
   */
  keyMapper?: Record<FilterKey, keyof Row>;
  /**
   * If passed as true it will make sure that every filter group matches
   * otherwise if the value is set to false it just requires at least one
   * Think of "true" as "AND" operator and "false" as "OR"
   * @default true
   */
  allShouldMatch?: boolean;
};

export function doesFilterPass<Row extends object, FilterKey extends string = string>(
  row: Row,
  entries: Array<[FilterKey, FilterObject]>,
  keyMapper?: Record<FilterKey, keyof Row>,
  allShouldMatch?: boolean,
) {
  let matches = 0;
  // Read the filter model
  for (const [key, { operator, values }] of entries) {
    const mappedKey = keyMapper?.[key] ?? (key as string as keyof Row);
    const targetValue = row[mappedKey];

    const filterValid = processFilter(operator, targetValue, values);

    // If we are strict about all filters matching, it should break the loop and not return this row
    if (allShouldMatch) {
      if (!filterValid) {
        return false;
      }
      matches++;
    } else if (!allShouldMatch && filterValid) {
      return true;
    }
  }

  // If all entries match just pass
  return matches === entries.length;
}

export const useFilteredData = <Row extends object, FilterKey extends string = string>({
  rows,
  filterModel,
  keyMapper,
  allShouldMatch = true,
}: FilteredDataParams<Row, FilterKey>): Row[] => {
  const filteredData = useMemo(() => {
    const entries = Object.entries(filterModel) as [string, unknown] as [FilterKey, FilterObject][];
    return rows.filter(r => {
      return doesFilterPass<Row, FilterKey>(r, entries, keyMapper, allShouldMatch);
    });
  }, [filterModel, rows, keyMapper, allShouldMatch]);

  return filteredData;
};
