import { useEffect } from 'react';

import { FileManagerFilter, useFileManagerStore } from '../store';

export const useFileManagerFilter = (filter: FileManagerFilter) => {
  const setFilter = useFileManagerStore(state => state.setActiveFileFilter);

  useEffect(() => {
    setFilter(filter);
    return () => {
      setFilter(undefined);
    };
  }, [filter, setFilter]);
};
