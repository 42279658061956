import { styled } from '@mui/styles';

const Foreground = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1000,
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backdropFilter: 'blur(2px)',
});

const OverlyContainer = styled('div')({
  position: 'relative',
});

interface OverlayProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'content'> {
  content?: React.ReactNode;
  visible?: boolean;
}

export const Overlay: React.FC<OverlayProps> = ({ children, content, visible = true, ...foregroundProps }) => (
  <OverlyContainer>
    {children}
    {visible && <Foreground {...foregroundProps}>{content}</Foreground>}
  </OverlyContainer>
);
