import groupBy from 'lodash/groupBy';
import * as z from 'zod';

const basePropertyConfig = z
  .object({
    name: z.string(),
    description: z.string().optional(),
  })
  .passthrough();

export enum ReportFieldKind {
  Dropdown = 'dropdown',
  Checkbox = 'checkbox',
  Toggle = 'toggle',
  Date = 'date',
  Text = 'text',
  Numeric = 'numeric',
  RichText = 'rich-text',
  File = 'file',
  FileSelection = 'file-selection',
  UserSelection = 'user-selection',
  MultipleCheckbox = 'multiple-checkbox',
}

export const fileOptionSchema = z.object({
  label: z.string(),
  value: z.string(),
  id: z.string().optional(),
  name: z.string().optional(),
  url: z.string().optional(),
});

export const fileOptionsSchema = z.array(fileOptionSchema);
export type FileOption = z.infer<typeof fileOptionSchema>;
export type FileOptions = z.infer<typeof fileOptionsSchema>;

export const fileSelectionValueSchema = z.object({
  id: z.string(),
  name: z.string(),
  url: z.string(),
  selectedOption: z.string(),
});

export const multipleCheckboxValueSchema = z
  .array(
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  )
  .min(2);

/**
 * Validates that an object matches one of the supported dynamic properties
 */
export const propertyConfigSchema = z.union([
  basePropertyConfig.extend({
    kind: z.literal('dropdown'),
    options: z.array(z.string()).min(2),
  }),
  basePropertyConfig.extend({
    kind: z.literal('checkbox'),
    options: z.array(z.string()).min(2),
  }),
  basePropertyConfig.extend({
    kind: z.literal('multiple-checkbox'),
    options: multipleCheckboxValueSchema,
  }),
  basePropertyConfig.extend({
    kind: z.literal('toggle'),
  }),
  basePropertyConfig.extend({
    kind: z.literal('date'),
  }),
  basePropertyConfig.extend({
    kind: z.literal('text'),
  }),
  basePropertyConfig.extend({
    kind: z.literal('numeric'),
  }),
  basePropertyConfig.extend({
    kind: z.literal('rich-text'),
  }),
  basePropertyConfig.extend({
    kind: z.literal('file'),
  }),
  basePropertyConfig.extend({
    kind: z.literal('file-selection'),
    options: fileOptionsSchema,
  }),
  basePropertyConfig.extend({
    kind: z.literal('user-selection'),
  }),
]);

/**
 * Validates an array of dynamic property objects
 */
export const propertiesConfigSchema = z.array(propertyConfigSchema).refine(
  data => {
    const grouped = groupBy(data, e => e.name);
    return Object.keys(grouped).length === data.length;
  },
  { message: 'name must be unique across all properties' },
);

export type PropertyConfig = z.infer<typeof propertyConfigSchema>;

export type PropertiesConfig = z.infer<typeof propertiesConfigSchema>;

export type PropertyConfigKind = PropertyConfig['kind'];

export const nodePropertySchema = z.object({
  name: z.string(),
  value: z.any(),
});

export const nodePropertiesSchema = z.array(nodePropertySchema);

export type NodeProperty = z.infer<typeof nodePropertySchema>;
export type NodeProperties = z.infer<typeof nodePropertiesSchema>;

export const basePropertySchema = z.object({
  name: z.string().nonempty(),
  value: z.any(),
});

export const basePropertiesSchema = z.array(basePropertySchema);

export const fileValueSchema = z.object({ id: z.string(), name: z.string(), url: z.string() });
