import { PropertyFieldProps } from '@circadian-risk/form';
import { Box } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

import { PrincipalActionDropdown, PrincipalActionDropdownProps } from './PrincipalActionDropdown';

export type PropertyPrincipalActionDropdownProps = Omit<PropertyFieldProps, 'label'> &
  Pick<PrincipalActionDropdownProps, 'variant'>;

export const PropertyPrincipalActionDropdown: React.FC<PropertyPrincipalActionDropdownProps> = ({
  htmlName,
  control,
  disabled,
  rules,
  boxProps,
  errorMessage,
  variant,
}) => {
  return (
    <Box {...boxProps}>
      <Controller
        control={control}
        name={htmlName}
        defaultValue={null}
        rules={rules}
        render={fieldProps => {
          const { onChange, value } = fieldProps.field;
          return (
            <PrincipalActionDropdown
              handleChange={onChange}
              value={value}
              readOnly={disabled}
              variant={variant}
              formControlProps={{ error: Boolean(errorMessage) }}
            />
          );
        }}
      />
    </Box>
  );
};
