import { alpha, ButtonBase, ButtonBaseProps, Card } from '@mui/material';
import omit from 'lodash/omit';
import React, { memo, useCallback } from 'react';

const DEFAULT_BORDER_WIDTH = 1;
const SELECTED_BORDER_WIDTH = 2;

export interface SelectableCardProps extends ButtonBaseProps {
  active: boolean;
}

/**
 * SelectableBox renders a "box-like" component such as SelectableCard but without the boxes and stuff
 */
export const SelectableBox: React.FC<SelectableCardProps> = memo(props => {
  const { active, disabled, onClick } = props;
  const buttonBaseProps = omit(props, 'active');

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (onClick) {
        onClick(e);
      }
      e.currentTarget.blur();
    },
    [onClick],
  );

  return (
    <ButtonBase
      {...buttonBaseProps}
      disableRipple
      sx={[
        ({ palette }) => ({
          '&:focus > *': {
            boxShadow: `0 0 12px ${alpha(palette.info.light, 0.7)}`,
            borderColor: palette.info.main,
          },
        }),
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
      onClick={handleClick}
    >
      <Card
        sx={({ transitions, palette }) => ({
          width: '-webkit-fill-available',
          transition: transitions.create(['border, box-shadow', 'color'], {
            duration: transitions.duration.shorter,
          }),
          borderWidth: active ? SELECTED_BORDER_WIDTH : DEFAULT_BORDER_WIDTH,
          borderStyle: 'solid',
          borderColor: active ? palette.info.main : palette.grey[500],
          '&:hover': {
            boxShadow: `0 0 12px ${alpha(palette.info.light, 0.7)}`,
            borderColor: palette.info.main,
            color: palette.info.main,
          },
          color: active ? palette.info.main : undefined,
          opacity: disabled ? 0.5 : 1,
        })}
        elevation={0}
      >
        {props.children}
      </Card>
    </ButtonBase>
  );
});
