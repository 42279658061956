import { QuestionStandard } from '@circadian-risk/presentational';
import dequal from 'fast-deep-equal';
import size from 'lodash/size';
import { useState } from 'react';
import { useCustomCompareEffect } from 'react-use';

import { ActiveFieldsState } from '../question.types';

type MoreInfoStateParams = {
  activeFields: ActiveFieldsState;
  standards?: QuestionStandard[];
};

const getState = ({ activeFields, standards = [] }: MoreInfoStateParams) => {
  return Object.values(activeFields).some(enabled => enabled) || size(standards) > 0;
};

export const useMoreInfoState = (params?: MoreInfoStateParams) => {
  const hasMoreInfo = params ? getState(params) : false;
  const [moreInfoOpen, setMoreInfoOpen] = useState(hasMoreInfo);

  // Sync the effects based on the given parameters
  useCustomCompareEffect(
    () => {
      if (!params) {
        return;
      }
      setMoreInfoOpen(getState(params));
    },
    [params],
    dequal,
  );

  const toggle = () => setMoreInfoOpen(prev => !prev);
  return { moreInfoOpen, toggle, setMoreInfoOpen, hasMoreInfo };
};
