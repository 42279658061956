import { OrganizationUser } from '@circadian-risk/stores';

import { UserRow } from './AssessmentAssignmentTabbedTable';

export const mapOrgUserToUserRow = (ou: OrganizationUser): UserRow => ({
  id: ou.id,
  displayName: ou.displayName,
  avatar: ou.avatar?.filepath,
  lastSignedInAt: ou.last_signed_in_at,
  email: ou.email,
  role: ou.role,
});
