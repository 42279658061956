import { LayoutCard } from '@circadian-risk/presentational';
import { Box } from '@mui/material';

interface HomeLayoutCardProps {
  title: React.ReactNode;
  action: React.ReactNode;
  banner?: React.ReactNode;
  children: React.ReactNode;
}

export const HomeLayoutCard: React.FC<HomeLayoutCardProps> = ({ title, action, banner, children }) => {
  return (
    <LayoutCard cardTitle={title} afterTitle={action}>
      <Box p={2}>
        {banner}
        <Box mt={banner ? 2 : undefined} overflow="auto" height={'350px'}>
          {children}
        </Box>
      </Box>
    </LayoutCard>
  );
};
