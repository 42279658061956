import { CircadianRoute, ROUTES } from '@circadian-risk/routes';
import { USER_ROLE } from '@circadian-risk/shared';
import { lazyLoadWithVersionCheck } from '@web-app/router/lazyLoadWithVersionCheck';

const ItemCategoryDetailPage = lazyLoadWithVersionCheck(() => import('./Details/ItemCategoryDetailsPage'));
const ItemCategoryEditPage = lazyLoadWithVersionCheck(() => import('./Form/ItemCategoryEditPage'));
const ItemCategoryTable = lazyLoadWithVersionCheck(() => import('./Table/ItemCategoryTable'));

export const itemCategoryRoutes = (): CircadianRoute[] => [
  {
    path: ROUTES.ITEM_CATEGORIES,
    component: ItemCategoryTable,
    isExact: true,
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN],
    pageTitle: 'Item Categories',
  },
  {
    path: ROUTES.ITEM_CATEGORIES_DETAIL,
    component: ItemCategoryDetailPage,
    isExact: true,
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN],
    pageTitle: 'Item Categories',
  },
  {
    path: ROUTES.ITEM_CATEGORIES_EDIT,
    component: ItemCategoryEditPage,
    isExact: true,
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN],
    pageTitle: 'Edit Item Category',
  },
];
