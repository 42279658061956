import { Box, Paper, SxProps, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import React, { CSSProperties } from 'react';

export interface SideDrawerProps {
  open: boolean;
  drawerWidth: CSSProperties['width'];
  fullScreen: boolean;
  side: 'left' | 'right';
  type: 'permanent' | 'temporary';
  children: React.ReactNode;
}

const AnimatedBox = motion(Paper);

export const SideDrawer: React.FC<SideDrawerProps> = ({ open, side, type, drawerWidth, fullScreen, children }) => {
  const theme = useTheme();
  const borderStyle = `2px solid ${theme.palette.divider}`;
  const sideSx: SxProps = side === 'left' ? { left: 0 } : { right: 0 };
  const border: SxProps = side === 'left' ? { borderRight: borderStyle } : { borderLeft: borderStyle };
  const positionSx: SxProps = {
    ...sideSx,
    top: 0,
    bottom: 0,
    position: type === 'permanent' ? 'initial' : 'absolute',
    overflow: 'hidden',
    display: 'flex',
  };

  const borderSx: SxProps = type === 'permanent' ? border : {};

  const effectiveWidth: CSSProperties['width'] = fullScreen ? '100vw' : drawerWidth;

  return (
    <AnimatedBox
      square
      elevation={type === 'permanent' ? 0 : 4}
      sx={{
        ...positionSx,
        ...borderSx,
        zIndex: type === 'permanent' ? 1 : theme.zIndex.drawer,
        position: type === 'permanent' ? 'initial' : 'absolute',
      }}
      initial={false}
      animate={open ? 'visible' : 'hidden'}
      transition={{ duration: 0.4 }}
      variants={{
        hidden: {
          width: 0,
        },
        visible: {
          width: effectiveWidth,
        },
      }}
    >
      <Box sx={{ ...positionSx, width: effectiveWidth }}>{children}</Box>
    </AnimatedBox>
  );
};
