import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import { useApiClient } from '@circadian-risk/api-client-provider';
import {
  useListenReportSectionPreviewUrlSubscription,
  useUpdateReportFieldMutation,
  useUpdateReportSectionMutation,
} from '@circadian-risk/client-graphql-hooks';
import { FCC, fileSrc, useNotification, useQueryState } from '@circadian-risk/front-end-utils';
import { InfoBox } from '@circadian-risk/presentational';
import { propertyConfigSchema } from '@circadian-risk/shared';
import { useOrganizationId } from '@circadian-risk/stores';
import InfoIconOutlined from '@mui/icons-material/InfoOutlined';
import { Box, Card, CircularProgress, Switch, Tab, Tabs, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { sectionInfoPreviewText } from './helpers';
import { PreviewReportContent } from './PreviewReportContent';
import { ReportFieldForm } from './ReportFieldForm';
import { useReportGenerationStore } from './reportGenerationStore';
import { ReportPreviewTooltipWrapper } from './ReportPreviewTooltipWrapper';

interface TabPanelProps {
  value: string;
  name: string;
  'aria-label': string;
}

const TabPanel: FCC<TabPanelProps> = props => {
  const { children, name, value } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== name}
      id={`report-tabpanel-${name}`}
      aria-labelledby={`report-tab-${name}`}
      aria-label={props['aria-label']}
    >
      {value === name && <Box>{children}</Box>}
    </div>
  );
};

export interface SectionFormProps {
  id: string;
  template: { id: string; name: string };
  reverseContent?: boolean;
}

export const SectionForm: React.FC<SectionFormProps> = ({ id, template, reverseContent }) => {
  const organizationId = useOrganizationId();
  const [assessmentId, reportId, previewCancelToken] = useReportGenerationStore(state => [
    state.assessmentId,
    state.reportId,
    state.previewRequestCancelTokenSource,
  ]);
  const [selectedTab, setSelectedTab] = useQueryState<string>('tab', 'edit');
  const section = useReportGenerationStore(
    state => state.sections.find(s => s.template.id === state.activeSectionTemplateId)!,
  );
  const previewDisabled = useReportGenerationStore(state => state.previewDisabled);
  const reportPreviewQuery = useListenReportSectionPreviewUrlSubscription({
    variables: {
      organizationId,
      reportSectionId: Number(id),
    },
  });
  const reportPreviewId = reportPreviewQuery.data?.report_previews[0]?.id ?? null;
  const reportPreviewStatus = reportPreviewQuery.data?.report_previews[0]?.status;
  const reportPreviewStartedAt = reportPreviewQuery.data?.report_previews[0]?.started_at;
  const reportPreviewCompleteAt = reportPreviewQuery.data?.report_previews[0]?.completed_at;

  let fileUrl = reportPreviewQuery.data?.report_previews[0]?.file?.filepath;
  fileUrl = fileSrc(fileUrl);

  const theme = useTheme();

  const isPreviewDisabled = !section.visible || previewDisabled;
  const triggerPreviewRequest = useReportGenerationStore(state => state.triggerPreviewRequest);
  const { displayError } = useNotification();
  const { tsRestClient } = useApiClient();

  const [updateReportField] = useUpdateReportFieldMutation();
  const [updateReportSection] = useUpdateReportSectionMutation();

  const [previewIdKey, setPreviewIdKey] = useState<number | null>(reportPreviewId);
  useEffect(() => {
    setPreviewIdKey(reportPreviewId);
  }, [reportPreviewId]);

  const handlePreviewClick = useCallback(
    async (skipCache: 'true' | 'false' = 'false') => {
      // First assume that we don't have a cached report available
      setPreviewIdKey(null);
      try {
        const result = await triggerPreviewRequest(async cancelToken => {
          const { status, body } = await tsRestClient.reports.previewSection({
            params: {
              organizationId,
              assessmentId,
              reportId,
              sectionId: template.id,
            },
            query: {
              skipCache,
            },
            body: {},
            cancelToken,
          });

          return status === 201 ? { status: body.status } : undefined;
        });

        if (result?.status === 'completed') {
          // We have a cached report available already, restore the previewIdKey
          setPreviewIdKey(reportPreviewId);
        }
      } catch (err) {
        displayError(err as Error);
      }
    },
    [
      triggerPreviewRequest,
      organizationId,
      assessmentId,
      reportId,
      template.id,
      tsRestClient,
      reportPreviewId,
      displayError,
    ],
  );

  const onRegenerateReport = async () => {
    await handlePreviewClick('true');
  };

  const handleVisibleChange = async (_e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    try {
      await updateReportSection({
        variables: {
          id: Number(id),
          organizationId,
          input: {
            visible: checked,
          },
        },
      });
    } catch (err) {
      displayError(err as Error);
    }
  };

  const handleOnReset = useCallback(
    async (fieldId: number) => {
      try {
        await tsRestClient.reports.resetField({
          params: {
            organizationId,
            fieldId,
            assessmentId,
          },
          body: {},
        });
      } catch (ex) {
        displayError(ex);
      }
    },
    [assessmentId, displayError, organizationId, tsRestClient.reports],
  );

  const handleTabChange = async (_: unknown, newValue: string) => {
    setSelectedTab(newValue);
    if (newValue === 'preview') {
      await handlePreviewClick();
    }
  };

  const renderPreviewOnly = () => {
    if (section.customPreviewInfo) {
      return section.customPreviewInfo;
    }

    return (
      <InfoBox disableTypographyWrapper InfoIconComponent={<InfoIconOutlined color={'primary'} display={'flex'} />}>
        <Box display={'flex'} alignItems={'center'}>
          <Box color={theme.palette.info.dark}>
            This section of the report is automatically generated. {sectionInfoPreviewText}
          </Box>
        </Box>
      </InfoBox>
    );
  };

  // Reset tab if template changes and cleanup previous preview
  useUpdateEffect(() => {
    setSelectedTab('edit');
  }, [template.id]);

  return (
    <Card>
      <Box my={1} mx={2}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="h5">{template.name}</Typography>
          <Switch checked={section.visible} onChange={handleVisibleChange} name="visible" color="primary" />
        </Box>
        <Box mt={3}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="report tabs"
            centered
            variant="fullWidth"
            indicatorColor="primary"
          >
            <Tab label="Edit" aria-label={'edit tab'} value="edit" />
            <Tab
              label={
                <ReportPreviewTooltipWrapper>
                  <span>Preview</span>
                </ReportPreviewTooltipWrapper>
              }
              // Required to enable tooltip pointer events when disabled
              style={{ pointerEvents: 'auto' }}
              aria-label={'preview tab'}
              disabled={isPreviewDisabled}
              value="preview"
            />
          </Tabs>
        </Box>
        <TabPanel value={selectedTab} name="edit" aria-label={'edit tab panel'}>
          <Box
            display="flex"
            flexDirection={reverseContent ? 'column-reverse' : 'column'}
            gap={theme.spacing(2)}
            mt={3}
          >
            {section.isPreviewOnly && renderPreviewOnly()}
            {section.fields.map(field => {
              const { id, value, version, hasDefaultValue, placeholder, labelType } = field;
              const parseResult = propertyConfigSchema.safeParse(field.config);
              if (!parseResult.success) {
                return null;
              }

              return (
                <ReportFieldForm
                  key={id}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={async (val: any, latestVersion?: number) => {
                    useReportGenerationStore.setState({ previewDisabled: true });
                    await updateReportField({
                      variables: {
                        organizationId,
                        id,
                        value: val,
                        clientVersion: latestVersion ? latestVersion : version + 1,
                      },
                    });
                    useReportGenerationStore.setState({ previewDisabled: false });
                  }}
                  onReset={handleOnReset}
                  disabled={!section.visible}
                  field={{
                    ...parseResult.data,
                    id,
                    value,
                    hasDefaultValue,
                    version,
                    placeholder,
                    labelType: labelType ?? 'name',
                  }}
                />
              );
            })}
            {section.extraContent && section.extraContent}
          </Box>
        </TabPanel>
        <TabPanel value={selectedTab} name="preview" aria-label={'preview tab panel'}>
          <Box mt={3}>
            {/* If the preview cancel token is set then a request is in flight*/}
            {previewCancelToken && <CircularProgress />}
            <PreviewReportContent
              fileUrl={previewIdKey ? fileUrl : null}
              status={reportPreviewStatus}
              startedAt={reportPreviewStartedAt}
              completedAt={reportPreviewCompleteAt}
              onRegenerateReport={onRegenerateReport}
            />
          </Box>
        </TabPanel>
      </Box>
    </Card>
  );
};
