import { ParserError, ParseResult } from '@circadian-risk/shared';
import { Box, SxProps, Theme } from '@mui/material';
import React from 'react';

export interface ParsedFileProps {
  parsed: ParseResult;
}

const errorSx: SxProps<Theme> = {
  border: '1px solid red',
  padding: 10,
  minWidth: 200,
  maxWidth: 350,
  margin: '0 auto',
};

export const FileParseErrors: React.FC<ParsedFileProps> = ({ parsed }) => {
  const { errors, summary } = parsed;

  if (!summary) {
    return (
      <Box sx={errorSx}>
        {errors.map((e, index) => (
          <div key={`${e.code}${index}`}>{e.message}</div>
        ))}
      </Box>
    );
  }

  const rowsTotal = `${summary.rowsParsed} rows will be imported`;

  return (
    <div>
      {errors.length === 0 && <p>{rowsTotal}</p>}
      {errors.length > 0 && (
        <Box sx={errorSx}>
          {errors.map((error: ParserError, index: number) => {
            return (
              <div key={index}>
                <div>There is an error in row: {error.row}</div>
                <div>{error.message}</div>
              </div>
            );
          })}
        </Box>
      )}
    </div>
  );
};
