import { useCanUseFeatureFlag, useThemeStore } from '@circadian-risk/stores';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, IconButton, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import React, { useCallback, useState } from 'react';

import AccountMenu from './AccountMenu';
import CustomerLogo from './CustomerLogo';
import LeftDrawer from './LeftDrawer';

export interface HeaderProps {
  hasMenu?: boolean;
  organizationRecords?: { [id: string]: unknown };
  returnRoute: string;
}

const Header: React.FC<HeaderProps> = ({ hasMenu, organizationRecords, returnRoute }) => {
  const { palette, breakpoints } = useTheme();
  const isSmallViewPort = useMediaQuery(breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const primaryColor = useThemeStore(state => state.primaryColor);
  const { enabled: themeOverrideEnabled } = useCanUseFeatureFlag()('org_custom_theming');
  const appBarColor = themeOverrideEnabled && primaryColor ? primaryColor : palette.auxColor;

  const handleClose = useCallback(() => {
    setDrawerOpen(false);
  }, []);

  const handleToggleMenu = useCallback(() => {
    setDrawerOpen(!drawerOpen);
  }, [drawerOpen]);

  const logo = (
    <CustomerLogo
      logoVariant={palette.utils.isPrimaryColorDark ? 'dark' : 'light'}
      withText={!isSmallViewPort}
      returnRoute={returnRoute}
    />
  );

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: appBarColor,
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-start' }}>
          {hasMenu && (
            <IconButton
              onClick={handleToggleMenu}
              color="inherit"
              role={'button'}
              aria-label={'toggle navigation'}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          )}
          {hasMenu && <LeftDrawer open={drawerOpen} onClose={handleClose} />}
          {!isSmallViewPort && logo}
        </Box>
        {isSmallViewPort && logo}
        {hasMenu && organizationRecords && (
          <AccountMenu organizationRecords={organizationRecords} isSmallViewPort={isSmallViewPort} />
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
