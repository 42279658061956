import { CreateAssessmentBaseSchema } from '@circadian-risk/api-contract';
import { InfoBox, VStack } from '@circadian-risk/presentational';
import { useOrganizationSessionStore, useOrganizationUserGroups, useOrganizationUsers } from '@circadian-risk/stores';
import { Typography } from '@mui/material';
import keyBy from 'lodash/keyBy';

import { AssignmentTabbedTable } from './AssignmentTabbedTable';
import { AssignmentGroupRow, AssignmentTeamRow, AssignmentUserRow } from './types';

type AssignmentSummaryProps = {
  assignment: CreateAssessmentBaseSchema['assignment'];
  /**
   * Application locations
   * In case of a single assessment, the root location id is enough
   */
  locationIds: string[];
};

export const AssignmentSummary: React.FC<AssignmentSummaryProps> = ({ assignment, locationIds }) => {
  const assignedUsersById = keyBy(assignment.user, u => u.id);
  const assignedGroupById = keyBy(assignment.userGroup, u => u.id);
  const assignedTeamById = keyBy(assignment.teams, u => u.id);

  const users = useOrganizationUsers()
    .filter(ou => !!assignedUsersById[ou.id])
    .map<AssignmentUserRow>(u => {
      return {
        id: u.id,
        displayName: u.displayName,
        email: u.email,
        role: u.role,
        avatar: u.avatar?.filepath,
        assignmentType: assignedUsersById[u.id].type,
      };
    });

  const groups = useOrganizationUserGroups()
    .filter(g => !!assignedGroupById[g.id])
    .map<AssignmentGroupRow>(group => {
      return {
        id: String(group.id),
        name: group.name,
        assignmentType: assignedGroupById[group.id].type,
        users: group.users.map(u => ({
          id: u.id,
          displayName: u.displayName,
          email: u.email,
          role: u.role,
          avatar: u.avatar?.filepath,
        })),
      };
    });

  const teams = useOrganizationSessionStore(state => {
    return state
      .getRelevantLocationTeams(...locationIds)
      .filter(t => !!assignedTeamById[t.id])
      .map<AssignmentTeamRow>(team => {
        return {
          id: String(team.id),
          name: team.name,
          assignmentType: assignedTeamById[team.id].type,
          users: team.users.map(u => ({
            id: u.id,
            displayName: u.displayName,
            email: u.email,
            role: u.role,
            avatar: u.avatar?.filepath,
          })),
        };
      });
  });

  const isAssignedEmpty = users.length === 0 && teams.length === 0 && groups.length === 0;

  return (
    <VStack>
      <Typography variant="subtitle1">Assigned Users</Typography>
      {isAssignedEmpty ? (
        <InfoBox> No users have been assigned</InfoBox>
      ) : (
        <AssignmentTabbedTable users={users} teams={teams} groups={groups} />
      )}
    </VStack>
  );
};
