import { FormControlLabel, ListItem, ListItemSecondaryAction, ListItemText, Switch } from '@mui/material';
import React from 'react';

import { useFeatureFlagsStore, useShouldShowFeatureFlagBoundaries } from './featureFlags.store';

export const FeatureFlagToggleSmart: React.FC = () => {
  const showFeatureFlagBoundaries = useShouldShowFeatureFlagBoundaries();

  return (
    <ListItem key="show-feature-flags">
      <ListItemText>Show Feature Flags</ListItemText>
      <ListItemSecondaryAction>
        <FormControlLabel
          style={{ marginLeft: 0, marginRight: 0 }}
          control={
            <Switch
              checked={showFeatureFlagBoundaries}
              onChange={() => useFeatureFlagsStore.setState({ showFeatureFlagBoundaries: !showFeatureFlagBoundaries })}
              name={'Show Feature Flags'}
              color="primary"
            />
          }
          label={''}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};
