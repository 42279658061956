import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box,
  IconButton,
  Link as MuiLink,
  LinkProps,
  Tooltip,
  TooltipProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { BoxProps } from '@mui/system';

import { HStack } from '../..';

// TODO(iprokopovich)[CR-4198]: create eslint rule maybe to forbid usage of target="_blank" in the app with a hint to use this component
export interface OpenInNewTabLinkProps {
  link: string;
  text?: string;
  /** @deprecated this one no longer is needed, make sure that is true and remove this prop */
  external?: boolean;
  tooltip?: TooltipProps['title'];
  textTypographyProps?: Partial<TypographyProps>;
  linkProps?: LinkProps;
  spanProps?: BoxProps;
}

const LinkComponent: React.FC<{ link: string; external?: boolean; children: React.ReactNode } & LinkProps> = ({
  link,
  children,
  ...linkProps
}) => (
  <MuiLink {...linkProps} href={link} target="_blank" rel="noreferrer noopener">
    {children}
  </MuiLink>
);

/**
 * Whenever you need to have a link open in a new tab, please use this component
 * There are security concerns when opening external link without usage of "noreferrer":
 * https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
 */
export const OpenInNewTabLink: React.FC<OpenInNewTabLinkProps> = ({
  link,
  text,
  tooltip,
  textTypographyProps,
  linkProps,
  spanProps,
}) => {
  return (
    <LinkComponent link={link} {...linkProps}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <Tooltip title={tooltip ?? ''}>
          <Box component="span" {...spanProps}>
            {text ? (
              <HStack component="span" spacing={0.5} overflow="hidden">
                <Typography variant="body2" {...textTypographyProps} noWrap>
                  {text}
                </Typography>
                <OpenInNewIcon fontSize="inherit" />
              </HStack>
            ) : (
              <IconButton size="small" color="inherit">
                <OpenInNewIcon />
              </IconButton>
            )}
          </Box>
        </Tooltip>
      </Box>
    </LinkComponent>
  );
};
