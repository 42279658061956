import { Enum_Answer_Responses_Enum } from '@circadian-risk/graphql-types';
import { HStack } from '@circadian-risk/presentational';
import { Box, Menu, MenuItem, PopoverOrigin, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';

import { SmartAnsweredByAvatar } from './components';
import { ResponseButton, ResponseButtonProps } from './components/ResponseButton';
import { QuestionProps } from './question.types';

const complianceOptions = [
  {
    value: 0,
    label: '0% Compliant',
  },
  {
    value: 0.25,
    label: '25% Compliant',
  },
  {
    value: 0.5,
    label: '50% Compliant',
  },
  {
    value: 0.75,
    label: '75% Compliant',
  },
];

const anchorOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'right' };
const transformOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'center' };
export interface QuestionResponseGroupProps extends Pick<QuestionProps, 'questionRequired' | 'itemIsPresent'> {
  response?: Enum_Answer_Responses_Enum | null;
  isImported?: boolean;
  deficientResponse: Enum_Answer_Responses_Enum;
  answeredAt?: string | null;
  answeredBy?: string | null;
  partialCompliance?: number | null;
  onResponseChange: (response: Enum_Answer_Responses_Enum | null) => void;
  onPartialComplianceChange: (value: number) => void;
  /**
   * @default true
   */
  isPartialComplianceEnabled?: boolean;
  /**
   * If `questionRequired` and `itemIsPresent` are provided it will utilize the new business logic
   */
  isCompliantAnswerDisabled?: boolean;

  /**
   * @default false
   */
  disabled?: boolean;
  answeredBySlot?: React.ReactNode;
}

const YES: Enum_Answer_Responses_Enum = 'yes';
const NO: Enum_Answer_Responses_Enum = 'no';

export const QuestionResponseGroup: React.FC<QuestionResponseGroupProps> = ({
  response,
  isImported,
  deficientResponse,
  answeredAt,
  answeredBy,
  partialCompliance,
  onResponseChange,
  onPartialComplianceChange,
  isPartialComplianceEnabled,
  disabled,
  answeredBySlot,
  questionRequired,
  itemIsPresent,
  isCompliantAnswerDisabled,
}) => {
  const [complianceOpen, setComplianceOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement | null>(null);

  const handleResponseClick = (value: Enum_Answer_Responses_Enum) => {
    if (isImported) {
      onResponseChange(value);
    } else {
      // If we're receiving itemIsPresent prop with a falsy value
      // we shall enable the new business logic that prevents
      // toggling to unset the answer if the question is optional
      if (itemIsPresent === false && value === response) {
        return;
      }
      value === response ? onResponseChange(null) : onResponseChange(value);
    }
  };

  const commonButtonProps: Omit<ResponseButtonProps, 'text' | 'meaning' | 'onClick' | 'selected'> = {
    disabled,
    isCompliantAnswerDisabled,
    partialCompliance,
    isPartialComplianceEnabled,
    onPartialComplianceClick: () => setComplianceOpen(!complianceOpen),
    isImported,
    itemIsPresent,
    questionRequired,
  };

  return (
    <>
      <HStack
        justifyContent={'space-between'}
        overflow={'auto'}
        sx={theme => ({
          [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-start',
          },
        })}
        spacing={1}
        role="listbox"
      >
        <ResponseButton
          text={YES}
          onClick={() => handleResponseClick(YES)}
          selected={response === YES}
          meaning={deficientResponse === YES ? 'deficient' : 'compliant'}
          ref={deficientResponse === YES ? anchorRef : undefined}
          {...commonButtonProps}
        />
        <ResponseButton
          text={NO}
          onClick={() => handleResponseClick(NO)}
          selected={response === NO}
          meaning={deficientResponse === NO ? 'deficient' : 'compliant'}
          ref={deficientResponse === NO ? anchorRef : undefined}
          {...commonButtonProps}
        />
        <ResponseButton
          text="n_a"
          onClick={() => handleResponseClick('n_a')}
          selected={response === 'n_a'}
          meaning="n_a"
          {...commonButtonProps}
        />
        <ResponseButton
          text="n_o"
          onClick={() => handleResponseClick('n_o')}
          selected={response === 'n_o'}
          meaning="n_o"
          {...commonButtonProps}
        />
      </HStack>

      {isPartialComplianceEnabled && (
        <Menu
          anchorEl={anchorRef.current}
          open={Boolean(complianceOpen)}
          onClose={() => setComplianceOpen(false)}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
        >
          {complianceOptions.map(option => (
            <MenuItem
              key={option.value}
              selected={partialCompliance === option.value}
              onClick={() => {
                setComplianceOpen(!complianceOpen);
                onPartialComplianceChange(option.value);
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      )}

      {answeredBySlot}

      {Boolean(answeredAt && !answeredBySlot) && (
        <Box display={'flex'} alignItems={'center'}>
          <Typography variant="caption" align="center">
            Answered by:
          </Typography>
          <Box ml={1}>
            <SmartAnsweredByAvatar answeredAt={answeredAt} answeredBy={answeredBy} />
          </Box>
        </Box>
      )}
    </>
  );
};
