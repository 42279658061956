import AddIcon from '@mui/icons-material/Add';
import { IconButton, IconButtonProps, Tooltip } from '@mui/material';
import { forwardRef } from 'react';

import { filterGroupAriaLabels } from '../FilterGroup';

export const AddFilterButton = forwardRef<HTMLSpanElement, IconButtonProps>((props, ref) => {
  return (
    <Tooltip title="Add filter">
      <span ref={ref} aria-label={filterGroupAriaLabels.AddFilterIcon}>
        <IconButton size="small" {...props}>
          <AddIcon color="secondary" />
        </IconButton>
      </span>
    </Tooltip>
  );
});
