import React from 'react';
import { Prompt } from 'react-router-dom';
import { useBeforeUnload } from 'react-use';

interface LeavingPromptProps {
  when: boolean;
  message: string;
}

export const LeavingPrompt: React.FC<LeavingPromptProps> = ({ when, message }) => {
  // useBeforeUnload message won't work on certain (most) browsers
  // https://bugs.chromium.org/p/chromium/issues/detail?id=587940
  useBeforeUnload(when, message);
  return <Prompt when={when} message={message} />;
};
