import * as fs from 'fs';
import isObject from 'lodash/isObject';
import * as path from 'path';

import { AddressConfig, AssessmentState, File } from './types';

export const passwordRegex = /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
// update: March 24, 2020 Vanya updated the Regex to a new one, see the discussion:
// https://circadianrisk.slack.com/archives/C8BBG61EE/p1585095873014100
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isFile = (variableToCheck: unknown): variableToCheck is File => {
  return (
    isObject(variableToCheck) &&
    Boolean((variableToCheck as { id: string })?.id) &&
    Boolean((variableToCheck as { filename: string }).filename)
  );
};

export const writeJson = (jsonPath: string, data: string) => {
  if (!fs.existsSync(jsonPath)) {
    const dirPath = path.dirname(jsonPath);
    fs.mkdirSync(dirPath, { recursive: true });
  }
  try {
    fs.writeFileSync(jsonPath, data, {});
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn('Error writing to json file: ', err);
  }
};

export const getHumanReadableSize = (size: number) => {
  if (size < 1024) {
    return `${size} bytes`;
  } else if (size >= 1024 && size < 1048576) {
    return (size / 1024).toFixed(1) + 'KB';
  } else {
    return (size / 1048576).toFixed(1) + 'MB';
  }
};

export const getHumanReadableAssessmentState = (assessmentState: AssessmentState) => {
  switch (assessmentState) {
    case AssessmentState.Abandoned:
      return 'Abandoned';
    case AssessmentState.Blocked:
      return 'Blocked';
    case AssessmentState.Complete:
      return 'Complete';
    case AssessmentState.InProgress:
      return 'In Progress';
    case AssessmentState.InReview:
      return 'In Review';
    case AssessmentState.NotStarted:
      return 'Not Started';
    default:
      return assessmentState;
  }
};

export type DeepNonNullable<T> = {
  [K in keyof T]-?: NonNullable<T[K]>;
};

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export type ExtractStrict<Type, Union extends Type> = Extract<Type, Union>;

/**
 * Treats an object as non-nullable. This is most useful when dealing with Maybe types in GraphQL responses
 * where the developer knows that it is not possible for a particular object to be null.
 *
 * With great power comes great responsibility. If you use this incorrectly it is possible to introduce a run time
 * error if the object does end up actually being null
 *
 * @param input The object to be treated as non nullable
 */
export const asNonNullableObject = <T extends Record<string, unknown>>(input: T): DeepNonNullable<T> => {
  return input as DeepNonNullable<T>;
};

/**
 * Treats a value as non-nullable. This is most useful when dealing with Maybe types in GraphQL responses
 * where the developer knows that it is not possible for a particular value to be null.
 *
 * With great power comes great responsibility. If you use this incorrectly it is possible to introduce a run time
 * error if the value does end up actually being null
 *
 * @param input The object to be treated as non nullable
 */
export const asNonNullable = <T>(input: T) => {
  return input as NonNullable<T>;
};

/**
 * return concatenated string of names with spaces in-between
 * if array is empty or elements in the array are falsy it will return empty string
 *
 * ['Vanya', 'Parkour'] => 'Vanya Parkour'
 * ['Paul'] => 'Paul'
 * [''] => null
 * [null, undefined] => null
 *
 * @param names array of names to concatenate
 */
export const displayName = (names: Array<string | undefined | null>) => {
  const combined = names.filter(Boolean).join(' ').trim();
  return combined === '' ? null : combined;
};

export const displayNameOrEmail = (names: Array<string | undefined | null>, email: string): string => {
  return displayName(names) ?? email;
};

/**
 * Returns a textual representation of an item status based on whether it is deficient and or present
 *
 * @param isDeficient
 * @param isPresent
 */
export const calculateItemStatusLabel = (
  isDeficient: boolean,
  isPresent: boolean,
): 'missing' | 'compliant' | 'deficient' => {
  if (!isPresent) {
    return 'missing';
  } else if (!isDeficient) {
    return 'compliant';
  } else {
    return 'deficient';
  }
};

export const formatAddress = (address?: AddressConfig, withNewlines = false): string => {
  const { street1, street2, city, state, country, zip } = address ?? {};

  const cityStateCountry = [city, state, country].filter(Boolean).join(', ');
  const joinedByCommas = [street1, street2, cityStateCountry].filter(Boolean).join(withNewlines ? '\n' : ', ');

  return [joinedByCommas, zip].filter(Boolean).join(' ').trim();
};

/**
 * Helper function to assert that a switch statement is exhaustive, pass whatever you switch on to this function in the default case
 */
export const throwIfUnhandledCase = (switchCase: never): never => {
  throw new Error(`Reached an unreachable case: ${switchCase} in a switch statement`);
};
