import { ListItemText, ListItemTextProps } from '@mui/material';
import React from 'react';

/**
 * Styled version of MUI's ListItemText for the navigation drawer
 */
export const ListItemTextNav: React.FC<ListItemTextProps> = props => {
  return (
    <ListItemText
      {...props}
      className={props.className}
      sx={[{ fontSize: '14px' }, ...(Array.isArray(props.sx) ? props.sx : [props.sx])]}
      disableTypography
    />
  );
};
