import { z } from 'zod';

export enum AssessmentReportSectionTemplateId {
  Cover = 'cover',
  TableOfContents = 'table-of-contents',
  StatementOfLiability = 'statement-of-liability',
  StatementFromAssessor = 'statement-from-assessor',
  ExecutiveSummary = 'executive-summary',
  LocationInformation = 'location-information',
  RiskAnalysis = 'risk-analysis',
  AssessmentSummary = 'assessment-summary',
  InspectionQuestionnaire = 'inspection-questionnaire',
  PhysicalInspection = 'physical-inspection',
  AssessorBiographies = 'assessor-biographies',
  References = 'references',
}

export const AssessmentReportSectionTemplatesSchemas = {
  [AssessmentReportSectionTemplateId.Cover]: z.object({
    'company-name': z.string(),
    'assessment-name': z.string(),
    info: z.string().nullable(),
  }),
  [AssessmentReportSectionTemplateId.TableOfContents]: z.object({}),
  [AssessmentReportSectionTemplateId.StatementOfLiability]: z.object({
    statement: z.string().nullable(),
  }),
  [AssessmentReportSectionTemplateId.StatementFromAssessor]: z.object({
    statement: z.string().nullable(),
  }),
};
