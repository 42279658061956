import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { FragmentDefinitionNode } from 'graphql';

export const getFragmentDefinition = <T extends TypedDocumentNode>(fragment: T, fragmentName?: string) => {
  if (fragment.definitions) {
    const def = fragment.definitions.find(d => {
      if (d.kind === 'FragmentDefinition') {
        return d.typeCondition.name.value === fragmentName;
      }
      return false;
    });

    if (def) {
      return def as FragmentDefinitionNode;
    }
  }

  const [def] = fragment.definitions;

  if (def?.kind === 'FragmentDefinition') {
    return def as FragmentDefinitionNode;
  }

  throw new Error('Could not find fragment definition in document');
};
