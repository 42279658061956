import { CircadianRoute, ROUTES } from '@circadian-risk/routes';
import { USER_ROLE } from '@circadian-risk/shared';
import { lazyLoadWithVersionCheck } from '@web-app/router/lazyLoadWithVersionCheck';
import React from 'react';

import AssessmentDetailsWrapperPage from './AssessmentDetails/AssessmentDetailsWrapperPage';

const ScenarioAssessmentNewPage = lazyLoadWithVersionCheck(
  () => import('./AssessmentForm/LocationScenarioAssessmentForm/ScenarioAssessmentNewPage'),
);

const CreateAssessmentSelectTypePage = lazyLoadWithVersionCheck(() => import('./CreateAssessmentSelectTypePage'));

const AssessmentSummary = lazyLoadWithVersionCheck(() => import('./AssessmentSummary/AssessmentSummary'));

const PerformAssessment = lazyLoadWithVersionCheck(
  () => import('./PerformAssessment' /* webpackChunkName: "PerformAssessment" */),
);

const CreateScheduledAssessmentPageWrapper = lazyLoadWithVersionCheck(
  () => import('./CreateScheduledAssessmentsPageWrapper'),
);

export const assessmentRoutes = (): CircadianRoute[] => [
  {
    path: ROUTES.NEW_PERFORM_ASSESSMENT_BASE,
    isExact: false,
    component: PerformAssessment,
    pageTitle: 'Assessments',
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN, USER_ROLE.MEMBER],
  },
  {
    path: ROUTES.ASSESSMENTS,
    component: () => <AssessmentSummary />,
    pageTitle: 'Assessments',
    allowRoles: [USER_ROLE.MEMBER, USER_ROLE.ORGANIZATION_ADMIN],
    isExact: true,
  },
  {
    path: ROUTES.ASSESSMENTS_NEW,
    component: () => <CreateAssessmentSelectTypePage />,
    pageTitle: 'Assessments',
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN, USER_ROLE.LOCATION_MANAGER],
    isExact: true,
  },
  {
    path: ROUTES.ASSESSMENTS_NEW_SINGLE,
    component: () => <ScenarioAssessmentNewPage />,
    pageTitle: 'Assessments',
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN, USER_ROLE.LOCATION_MANAGER],
    isExact: true,
  },
  {
    path: ROUTES.ASSESSMENTS_NEW_SCHEDULED,
    component: () => <CreateScheduledAssessmentPageWrapper />,
    pageTitle: 'Scheduled Assessments',
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN, USER_ROLE.LOCATION_MANAGER],
    isExact: true,
    featureFlag: 'scheduled_assessments',
  },
  {
    path: ROUTES.ASSESSMENTS_DETAIL,
    component: () => <AssessmentDetailsWrapperPage />,
    pageTitle: 'Assessments',
    allowRoles: [USER_ROLE.MEMBER, USER_ROLE.ORGANIZATION_ADMIN],
  },
];
