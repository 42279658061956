import { Box, BoxProps } from '@mui/material';
import React from 'react';

/**
 * Cell wrapper that will center the cell content
 * It accepts any box props so you could pass justifyContent="flex-end" to align it to the right for example
 */
export const AlignCell: React.FC<BoxProps> = ({ children, ...props }) => (
  <Box
    display="flex"
    alignItems="center"
    component="span"
    justifyContent="center"
    width="fill-available"
    height={'100%'}
    {...props}
  >
    {children}
  </Box>
);
