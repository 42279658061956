import * as z from 'zod';

import { propertiesConfigSchema, PropertyConfig } from '../data/report-dynamic-fields.utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ReportSectionTemplateField = PropertyConfig & { id: number; value: any; hasDefaultValue: boolean };

const reportSectionTemplateInputSchema = z.object({
  id: z.string(),
  name: z.string(),
  fields: propertiesConfigSchema,
});

export type ReportSectionTemplate = z.infer<typeof reportSectionTemplateInputSchema>;
