import { Popover, PopoverProps } from '@mui/material';

export const FilterDropdownPopover: React.FC<PopoverProps> = ({ children, ...rest }) => {
  return (
    <Popover
      keepMounted={false}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      {...rest}
    >
      {children}
    </Popover>
  );
};
