import { Box, Typography } from '@mui/material';
import { z } from 'zod';

import { FormBox } from '../../FormBox';
import { PropertyTextField } from '../PropertyTextField';
import { PropertyFieldProps } from '../types';

export type PropertyAddressFieldProps = Omit<PropertyFieldProps, 'rules'>;

const optionalNullableStringSchema = z.string().optional().nullable();

export const propertyAddressSchema = z
  .object({
    street1: optionalNullableStringSchema,
    street2: optionalNullableStringSchema,
    city: optionalNullableStringSchema,
    state: optionalNullableStringSchema,
    zip: optionalNullableStringSchema,
    country: optionalNullableStringSchema,
  })
  .nullable();

export type PropertyAddress = z.infer<typeof propertyAddressSchema>;

/**
 * A collection of property text fields
 */
export const PropertyAddressField: React.FC<PropertyAddressFieldProps> = ({
  label,
  htmlName,
  control,
  disabled,
  boxProps,
  errorMessage,
  onBlur,
}) => {
  return (
    <Box {...boxProps}>
      <Box mb={1}>
        <Typography variant="body1">{label}</Typography>
      </Box>
      <FormBox>
        <PropertyTextField
          disabled={disabled}
          label="Street 1"
          control={control}
          htmlName={`${htmlName}.street1`}
          onBlur={onBlur}
        />
        <PropertyTextField
          disabled={disabled}
          label="Street 2"
          control={control}
          htmlName={`${htmlName}.street2`}
          onBlur={onBlur}
        />
        <Box display="flex" flexDirection="row">
          <Box flex={2} mr={1}>
            <PropertyTextField
              disabled={disabled}
              label="City"
              control={control}
              htmlName={`${htmlName}.city`}
              onBlur={onBlur}
            />
          </Box>
          <Box flex={1} mr={1}>
            <PropertyTextField
              disabled={disabled}
              label="State"
              control={control}
              htmlName={`${htmlName}.state`}
              onBlur={onBlur}
            />
          </Box>
          <Box flex={1}>
            <PropertyTextField
              disabled={disabled}
              label="Zip"
              control={control}
              htmlName={`${htmlName}.zip`}
              onBlur={onBlur}
            />
          </Box>
        </Box>
        <PropertyTextField
          disabled={disabled}
          label="Country"
          control={control}
          htmlName={`${htmlName}.country`}
          onBlur={onBlur}
        />
        <Typography variant="body2" color="error">
          {errorMessage}
        </Typography>
      </FormBox>
    </Box>
  );
};
