import { useListenOrgEnabledFeatureFlagsSubscription } from '@circadian-risk/client-graphql-hooks';
import { useOrganizationSessionStore } from '@circadian-risk/stores';

type Props = { organizationId: string };

export const FeatureFlagsProvider: React.FC<Props> = ({ organizationId }) => {
  useListenOrgEnabledFeatureFlagsSubscription({
    variables: {
      organizationId,
    },
    onData: ({ data: subscriptionData }) => {
      const { data } = subscriptionData;
      if (data) {
        useOrganizationSessionStore.setState({
          enabledFeatures: data.organization_feature_flags.map(flag => flag.flag_name),
          finishedFetchingFeatures: true,
        });
      }
    },
  });

  return null;
};
