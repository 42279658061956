// eslint-disable-next-line max-classes-per-file
import axios from 'axios';
import fs from 'fs';
import Papa from 'papaparse';

import { PAPA_PARSE_CONFIG } from '../';
import { AddressBuilder, AddressFields, DEFAULT_ADDRESS_FIELDS } from './address-builder';
import { getFilledLayerTags } from './getFilledLayerTags';
import { HierarchyNode, HierarchyRootNode } from './hierarchy-node';
import { ParserError } from './parser-error';

/**
 * Default headers for Circadian Risk Location Hierarchies.
 */
export const DEFAULT_LOCATION_HIERARCHY_HEADERS = ['Organization', 'Region', 'Campus', 'Building', 'Level'] as const;

/**
 * Returns a ParserError if the current row contains an orphan. An example of
 * an orphaned row is (assuming the default Circadian Risk Location Hierarchy):
 *
 * Organization -> Region -> NO CAMPUS -> Building -> Level
 *
 * Because there is no Campus, the Building and Level are orphaned nodes in the
 * tree. In this case, an error will be returned from this function.
 *
 * If the current row has no orphaned nodes, this function will return
 * `undefined`.
 *
 * @param headers Array of headers from the Parser.headers
 * @param row Row of data from the CSV
 * @param layerIndex Current layer index, usually the column header from the CSV
 * @param layerName Current layer name, usually corresponds to the cell contents in the CSV
 * @param rowIndex Current row index
 */
function skipOrphanedRows(
  headers: string[],
  row: unknown,
  layerIndex: number,
  layerName: string,
  rowIndex: number,
): ParserError | undefined {
  // check for orphaned row cells
  if (
    (row as Record<string, string>)[layerName] !== '' &&
    (row as Record<string, string>)[headers[layerIndex - 1]] === ''
  ) {
    return new ParserError({
      code: 'OrphanedNode',
      message: 'Unable to parse CSV; orphaned Node detected. Please make sure that hierarchy is properly defined.',
      row: rowIndex,
      type: 'Malformed',
    });
  }
  return undefined;
}

/**
 * Provides a summary for the Circadian Risk Location Hierarchy parser.
 */
export class HierarchyCSVParserSummary {
  /**
   * The number of rows that have been successfully parsed.
   */
  public rowsParsed: number;

  /**
   * The number of rows that have been ignored during parsing. Take note of any
   * errors indicated within the `result.errors` as it may give additional
   * information regarding any failed rows.
   */
  public rowsIgnored: number;

  /**
   * The total number of rows in the input CSV.
   */
  public rowCount: number;

  /**
   * Create a new Summary for the Location Hierarchy result set.
   */
  public constructor() {
    this.rowsParsed = 0;
    this.rowsIgnored = 0;
    this.rowCount = 0;
  }
}

/**
 * Provides a Result which contains the Location Hierarchy tree, as well as
 * additional information (errors, summary) related to the data parsing
 * process.
 */
export class ParseResult {
  /**
   * Errors during parsing of the Location Hierarchy data.
   */
  public readonly errors: ParserError[];

  /**
   * Summary of the parsing process.
   */
  public readonly summary: HierarchyCSVParserSummary;

  /**
   * The Location Hierarchy tree. Within this property there can only be ONE
   * Root Node and all related nodes are connected via its children.
   *
   * By default, this will reference the Organization location in the
   * hierarchy.
   */
  public readonly tree: HierarchyRootNode | undefined;

  /**
   * Create a Result with the given tree, errors, and summary.
   *
   * @param tree The base of the tree: the root node. There can only be one of these per Result.
   * @param errors A list of ParserError(s). Can be empty if no errors were experienced during parsing.
   * @param summary A summary of the data parsing process.
   */
  public constructor(tree: HierarchyRootNode | undefined, errors: ParserError[], summary: HierarchyCSVParserSummary) {
    this.tree = tree;
    this.summary = summary;
    this.errors = errors;
  }

  /**
   * Get a Node by its position within the hierarchy.
   *
   * ## Example Usage
   *
   * ```typescript
   * parser = new Parser();
   * const data = parser.parse('/path/to/input-file.csv');
   *
   * // Assuming that this region is available in the Location Hierarchy,
   * // the following will `get` it from the parsed data:
   * const myRegion = data.get([ 'My Organization', 'My Region A' ]);
   * console.log(myRegion.toJSON());
   * ```
   *
   * @param s Array of values (in order) to get a particular Node.
   */
  public get(s: string[]) {
    return this.tree!.get(s);
  }
}

/**
 * Returns a new Result with appropriate errors and summary for rejecting the
 * CSV parsing process.
 *
 * @param csv ParseResult from Papa parse
 * @param errors List of existing ParserError errors
 * @param summary Current parser Summary object
 */
function rejectForParsingErrors(
  csv: Papa.ParseResult<unknown>,
  errors: ParserError[],
  summary: HierarchyCSVParserSummary,
) {
  summary.rowsParsed = 0;
  summary.rowsIgnored = csv.data.length;
  summary.rowCount = csv.data.length;

  return new ParseResult(undefined, errors, summary);
}

/**
 * Provides a Parser for parsing CSV files.
 *
 * By default the Parser is configured to use the header names specified in
 * the `DEFAULT_HEADERS` array. If desired, these can be overridden to allow for
 * additional locations hierarchy (recommended 8 max).
 *
 * ## Example Usage
 *
 * ### Basic
 *
 * ```typescript
 * import Parser from './csv';
 *
 * const inputFile = '/path/to/file.csv';
 * const parser = new Parser();
 * const result = parser.parse(inputFile);
 *
 * console.log(result.tree.toJSON());
 *
 * // If using the `DEFAULT_HEADERS` these are available:
 * const org = result.tree;
 * const region = org.get(['Region 1']);
 * const campus = region.get(['Campus 1.1']);
 * const building = campus.get(['Building 1.1.1.1']);
 * const level = building.get(['Level 1.2.3.4']);
 * ```
 *
 * ### Custom Headers / Layers
 *
 * ```typescript
 * import Parser from './csv';
 *
 * // NOTE: ORDER MATTERS - these determine the hierarchy of the output data.
 * const myCustomHeaders = ['Franchise', 'District', 'Locale'];
 * const parser = new Parser({
 *   headers: myCustomHeaders,
 * });
 *
 * const inputFile = '/path/to/file/custom-layer-headers.csv';
 * const result = parser.parse(inputFile);
 *
 * console.log(result.tree.toJSON());
 * ```
 *
 */
export class HierarchyCSVParser {
  /**
   * The headers to read from the CSV.
   *
   * Specify a list of headers to parse from the imported CSV file.
   *
   * Each layer can be represented by one or more Node(s) in the output JS
   * tree.
   *
   * NOTE: Order is IMPORTANT! These should be listed in descending order from
   * the broadest to most specific -
   * (e.g. [ Organization, Region, Campus, Building, Level ] )
   *
   * If not specified, the default Circadian Risk headers (in the default order)
   * will be used.
   *
   * @see DEFAULT_LOCATION_HIERARCHY_HEADERS
   */

  private readonly headers: string[];

  // requiredHeaders: string[];

  // requiredFields: string[];

  // layer fields become requiredHeaders ['Organization', 'Region', ...]
  // layerFields: string[];

  /**
   * Custom address fields to use when parsing the CSV.
   *
   * @see DEFAULT_ADDRESS_FIELDS
   */
  private readonly addressFields: AddressFields;

  /**
   * Create a CSV Parser with optional arguments.
   *
   * @param args The arguments for the CSV parser.
   */
  public constructor(args?: ParserArgs) {
    this.headers = args?.headers ?? [...DEFAULT_LOCATION_HIERARCHY_HEADERS];
    this.addressFields = args?.addressFields ?? DEFAULT_ADDRESS_FIELDS;
  }

  /**
   * Validate CSV headers to match existing layers hierarchy
   *
   * @param parsedCSV PapaParse result
   */
  public validate(parsedCSV: Papa.ParseResult<unknown>): ParserError[] {
    const errors: ParserError[] = [];

    const fields = parsedCSV.meta.fields ?? [];

    const headersIntersection = fields.filter(x => this.headers.includes(x));

    if (fields.length === 0) {
      // blank CSV
      const message = `Required headers not found.`;

      errors.push({ row: 0, message, type: 'HeadersMissing', code: 'NoHeaders' });
    } else if (parsedCSV.data.length === 0) {
      // No data in CSV
      const message = `There are no data rows found in CSV uploaded.`;

      errors.push({ row: 0, message, type: 'NoData', code: 'NoData' });
    } else if (headersIntersection.length < this.headers.length) {
      // missing required headers
      const headersDifference = this.headers.filter(x => !fields.includes(x));
      const issue = 'missing';
      const message = `The following headers of the uploaded file are ${issue}: ${headersDifference.join(', ')}.`;

      errors.push({ row: 0, message, type: 'FieldMismatch', code: 'HeadersMissing' });
    } else if (fields.slice(0, this.headers.length).join() !== this.headers.join()) {
      const message = `Wrong headers order.`;

      errors.push({ row: 0, message, type: 'FieldMismatch', code: 'WrongHeadersOrder' });
    }

    return errors;
  }

  /**
   * Parse the specified CSV file.  Each row in the input CSV will be output
   * as a node and attached to its parent layer.
   *
   * @param path A path to a CSV file.  If a URL is provided, it must use the
   * `http:` or `https:` protocol.
   * If a file descriptor is provided, the underlying file will _not_ be closed
   * automatically.
   */
  public async parse(path: string): Promise<ParseResult> {
    let csvData = '';
    if (path.startsWith('http:', 0) || path.startsWith('https:', 0)) {
      const resp = await axios.get(path, {
        headers: {
          Accept: 'text/csv',
        },
        responseType: 'text',
      });
      csvData = resp.data;
    } else {
      csvData = fs.readFileSync(path).toString();
    }
    return this.parseData(csvData);
  }

  /**
   * Parse the specified CSV data.  Each row in the input CSV will be output
   * as a node and attached to its parent layer.
   *
   * @param data Data in CSV format.
   */
  public async parseData(data: string): Promise<ParseResult> {
    let rootNodeName: string | undefined;
    let rootNode: HierarchyNode;

    const summary = new HierarchyCSVParserSummary();
    const addressBuilder = new AddressBuilder(this.addressFields);

    const parsedCSV = Papa.parse(data, PAPA_PARSE_CONFIG);

    // Reject because of a PapaParse parsing errors
    if (parsedCSV.errors.length > 0) {
      return rejectForParsingErrors(parsedCSV, parsedCSV.errors, summary);
    }

    // Validate and reject because of validation errors
    const errors: ParserError[] = this.validate(parsedCSV);
    if (errors.length > 0) {
      return rejectForParsingErrors(parsedCSV, errors, summary);
    }

    const metaFields = parsedCSV.meta.fields ?? [];

    for (const [rowIndex, row] of parsedCSV.data.entries()) {
      let rowHasUnrecoverableError = false;
      const address = addressBuilder.build(metaFields, row);

      // Determine which layer should have the address assigned.
      // This is being done _before_ the loop below so that we can
      // attach the address to the node when we build the tree.
      const getPath: string[] = this.headers.slice(1, this.headers.length).reduce((path: string[], keyName: string) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const cellValue = row[keyName];
        if (cellValue !== '') {
          path.push(cellValue);
        }
        return path;
      }, []);

      const associatedLayerDepth = getPath.length;

      // Set up root node (e.g. 'Organization')
      const rootLayerFieldName = this.headers[0];
      if (rootNodeName === undefined) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        rootNodeName = row[rootLayerFieldName];
        rootNode = new HierarchyNode(rootNodeName ?? 'fallback', rootLayerFieldName);

        // If addressAssociatedLayerDepth is 0, just attach the address to the rootNode
        if (associatedLayerDepth === 0) {
          rootNode.address = address;
          rootNode.layerTags = getFilledLayerTags(row, metaFields, 'Organization');
        }
      }

      // Address Setup complete. Next, we need to eliminate any rows with
      // orphaned locations. (e.g. Level and Building, but no Campus should
      // cause the row to be rejected straight away).
      for (const [layerDepth, layerName] of this.headers.entries()) {
        const err = skipOrphanedRows(this.headers, row, layerDepth, layerName, rowIndex);

        if (err) {
          errors.push(err);
          rowHasUnrecoverableError = true;
          summary.rowsIgnored++;
          summary.rowsParsed--;
        }
      }

      for (const [layerDepth, layerName] of this.headers.entries()) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const nodeName = row[layerName];

        if (layerDepth > 0 && !rowHasUnrecoverableError) {
          const getPathToNode = getPath.slice(0, layerDepth);
          const getPathToParent = getPath.slice(0, layerDepth - 1);

          // Skip blank node names if it is not a root node
          if (nodeName.length > 0 || getPathToParent.length === 0) {
            // get/create new node to attach to hierarchy tree
            let newNode = rootNode!.get(getPathToNode);
            if (newNode === undefined) {
              newNode = new HierarchyNode(nodeName, layerName);
            }

            if (associatedLayerDepth === layerDepth) {
              newNode.address = address;
              newNode.layerTags = getFilledLayerTags(row, metaFields, layerName);
            }

            // If path to parent is empty, attach directly to the rootNode
            const parentNode = getPathToParent.length === 0 ? rootNode! : rootNode!.get(getPathToParent);
            parentNode!.addChild(newNode);
          }
        }
      }

      summary.rowCount++;
      summary.rowsParsed++;
    }

    // If the rootNode has no children (is undefined), then we should not
    // return a tree.
    if (rootNode!.children === undefined) {
      return new ParseResult(undefined, errors, summary);
    }

    const tree = new HierarchyRootNode(rootNode!);
    return new ParseResult(tree, errors, summary);
  }
}

/**
 * The set of arguments for customizing the Circadian Risk Location Hierarchy
 * CSV Parser.
 */
export interface ParserArgs {
  /**
   * Specify a list of headers to parse from the imported CSV file.
   *
   * Each layer can be represented by one or more Node(s) in the output JS
   * tree.
   *
   * NOTE: Order is IMPORTANT! These should be listed in descending order from
   * the broadest to most specific -
   * (e.g. Organization, Region, Campus, Building, Level)
   *
   * If not specified, the default Circadian Risk headers (in the default order)
   * will be used.
   *
   * @see DEFAULT_LOCATION_HIERARCHY_HEADERS
   */
  headers?: string[];

  /**
   * Specify custom address headers if desired.
   *
   * If not specified, the default Circadian Risk address fields will be used
   * to populate the address information for the appropriate layer.
   *
   * @see DEFAULT_ADDRESS_FIELDS
   */
  addressFields?: AddressFields;
}
