import { SUPPORT_EMAIL } from '@circadian-risk/data-utils';
import { HStack } from '@circadian-risk/presentational';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Card, CardContent, CardHeader, Link, Typography } from '@mui/material';
import React from 'react';

export interface ErrorMessageCardProps {
  title?: string;
  errorMessage?: string;
}

export const ErrorMessageCard: React.FC<ErrorMessageCardProps> = ({ title, errorMessage }) => {
  const handleReload = () => window.location.reload();
  return (
    <Card>
      <CardHeader
        title={
          <Typography color="error" variant="h5">
            <HStack>
              <WarningIcon /> {title ?? 'Error'}
            </HStack>
          </Typography>
        }
      />
      <CardContent>
        <Typography gutterBottom>
          Please{' '}
          <Link href="#" onClick={handleReload} underline="hover">
            reload the page
          </Link>{' '}
          to try again.
        </Typography>
        <Typography>
          If the problem persists, try to return to the <a href="/">Home screen</a> or contact{' '}
          <a href={`mailto:${SUPPORT_EMAIL}`}>Circadian Risk Client Success</a> for additional support.
        </Typography>
        {errorMessage && (
          <Box pt={3}>
            <Typography gutterBottom>Error Message:</Typography>
            <Typography color="error">{errorMessage}</Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
