import ActionRequiredIcon from '@mui/icons-material/Assignment';
import CloseIcon from '@mui/icons-material/Close';
import FlagIcon from '@mui/icons-material/Flag';
import UnansweredIcon from '@mui/icons-material/LensOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Button, Divider, Popover, Typography } from '@mui/material';
import union from 'lodash/union';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FaStarOfLife } from 'react-icons/fa';
import { MdOutlineVerified as UnratifiedIcon } from 'react-icons/md';
import { z } from 'zod';

import { HStack } from '../..';
import { NoCoordinatesIcon, ResponseIcon } from '../Icons';
import { FilterButton } from './FilterButton';
import { FilterOption } from './FilterOption';

const ResponseFilters = [
  {
    key: 'unanswered',
    label: 'No Answer',
  },
  {
    key: 'compliant',
    label: 'Compliant Answers',
  },
  {
    key: 'deficient',
    label: 'Deficient Answers',
  },
  {
    key: 'n_a',
    label: 'N/A Answers',
  },
  {
    key: 'n_o',
    label: 'N/O Answers',
  },
] as const;

const QuestionFilters = [
  {
    key: 'action_required',
    label: 'Action Required',
    icon: <ActionRequiredIcon color="warning" />,
  },
  {
    key: 'flagged',
    label: 'Flagged Questions',
    icon: <FlagIcon color="error" />,
  },
  {
    key: 'unratified',
    label: 'Unratified Answers',
    icon: <UnratifiedIcon fontSize={22} />,
  },
  {
    key: 'unplaced',
    label: 'Unplaced Items',
    icon: <NoCoordinatesIcon />,
  },
] as const;

const filters = [...ResponseFilters, ...QuestionFilters].map(f => f.key);
const readonlyFilters = filters as [(typeof filters)[number], ...typeof filters];

export const questionFilterSchema = z.enum(readonlyFilters);
export type QuestionFilterTypes = z.infer<typeof questionFilterSchema>;

export interface QuestionFilterDropdownProps {
  defaultSelectedFilters: QuestionFilterTypes[];
  globalFilters?: QuestionFilterTypes[];
  onChange: (newFilters: QuestionFilterTypes[]) => void;
  hiddenFilters?: QuestionFilterTypes[];
  isOutdated?: boolean;
}

export const QuestionFilterDropdown: React.VFC<QuestionFilterDropdownProps> = ({
  defaultSelectedFilters,
  onChange,
  hiddenFilters,
  globalFilters,
  isOutdated,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<QuestionFilterTypes[]>(defaultSelectedFilters);

  const handleSelect = (filter: QuestionFilterTypes) => {
    setSelectedFilters(prev => {
      const newFilters = prev.includes(filter) ? prev.filter(f => f !== filter) : [...prev, filter];
      onChange(newFilters);
      return newFilters;
    });
  };

  const appliedFilters = useMemo(() => union(selectedFilters, globalFilters ?? []), [globalFilters, selectedFilters]);

  useEffect(() => {
    if (!open) {
      setSelectedFilters(defaultSelectedFilters);
    }
  }, [open, defaultSelectedFilters]);

  const handleClear = () => {
    setSelectedFilters([]);
    onChange([]);
    setOpen(false);
  };

  const handleReApply = () => {
    setOpen(false);
    onChange(selectedFilters);
  };

  const toggleFilterDropdown = () => {
    setOpen(prev => !prev);
  };

  const filterButtonRef = useRef(null);
  return (
    <>
      <FilterButton
        ref={filterButtonRef}
        onClick={toggleFilterDropdown}
        outdated={isOutdated}
        count={appliedFilters.length}
      />
      <Popover
        open={open}
        anchorEl={filterButtonRef.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box px={2} py={1} display="flex" flexDirection="column" width={230}>
          {QuestionFilters.filter(filter => !hiddenFilters?.includes(filter.key)).map(filter => {
            const { key, label, icon } = filter;
            return (
              <FilterOption
                key={key}
                checked={appliedFilters.includes(key)}
                onSelect={() => handleSelect(key)}
                name={label}
                icon={icon}
                appliedGlobally={globalFilters?.includes(key)}
              />
            );
          })}
          <Divider />
          {ResponseFilters.filter(filter => !hiddenFilters?.includes(filter.key)).map(filter => {
            const { key, label } = filter;
            const icon = key === 'unanswered' ? <UnansweredIcon /> : <ResponseIcon response={key} />;

            return (
              <FilterOption
                key={key}
                checked={appliedFilters.includes(key)}
                onSelect={() => handleSelect(key)}
                name={label}
                icon={icon}
                appliedGlobally={globalFilters?.includes(key)}
              />
            );
          })}
          <Divider />
          <HStack justifyContent="center" mt={1}>
            <Button
              startIcon={<CloseIcon />}
              size="small"
              onClick={handleClear}
              disabled={!defaultSelectedFilters.length}
            >
              Clear
            </Button>
            {isOutdated && (
              <Button startIcon={<RefreshIcon />} color="primary" size="small" onClick={handleReApply}>
                Re Apply
              </Button>
            )}
          </HStack>
          {isOutdated && (
            <Typography variant="caption" color="textSecondary" mt={1}>
              <FaStarOfLife /> Filter results have changed. Tap Re-apply to view latest results.
            </Typography>
          )}
        </Box>
      </Popover>
    </>
  );
};
