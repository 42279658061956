import { OptionsSectionProps } from '@circadian-risk/assessment-components';
import { createVisibleOptions } from '@web-app/modules/Items/hooks/createVisibleOptions.helper';
import { OptionsDataByQuestionId, QuestionOptionsRow } from '@web-app/modules/Items/hooks/types';
import omit from 'lodash/omit';
import React from 'react';

/**
 * A wrapper that allows to easily mutate a question data ensuring that it exists first
 * and synchronizing the visibleQuestions after each operation
 * @param questionId
 * @param remoteOptionsByQuestionIdRef
 * @param dispatch
 * @param mutator
 */
export const setQuestionData = ({
  questionId,
  remoteOptionsByQuestionIdRef,
  dispatch,
  mutator,
}: {
  questionId: string;
  remoteOptionsByQuestionIdRef: React.MutableRefObject<Record<string, OptionsSectionProps['options']>>;
  dispatch: React.Dispatch<React.SetStateAction<OptionsDataByQuestionId>>;
  // A callback that passes the target data row and the return value will be used to update
  mutator: (state: Omit<QuestionOptionsRow, 'visibleOptions'>) => Omit<QuestionOptionsRow, 'visibleOptions'>;
}) => {
  dispatch(prev => {
    const newData = { ...prev };
    if (!newData[questionId]) {
      newData[questionId] = {
        freeformUpdates: [],
        idsToRemove: { freeform: [], library: [] },
        virtualOptions: { freeform: [], library: [] },
        visibleOptions: [],
        freeformToSaveToLib: [],
      };
    }

    newData[questionId] = {
      ...newData[questionId],
      // Mutator will return the new question data
      ...mutator(omit(newData[questionId], 'visibleOptions')),
    };

    // Sync visible options
    newData[questionId].visibleOptions = createVisibleOptions({
      remoteOptions: remoteOptionsByQuestionIdRef.current[questionId] ?? [],
      questionVirtualData: newData[questionId],
    });

    return newData;
  });
};
