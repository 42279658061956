import { AnswerSemanticMeaning, FCC } from '@circadian-risk/front-end-utils';
import noop from 'lodash/noop';
import React, { useCallback, useContext, useMemo, useState } from 'react';

import { BulkReassessModal } from './BulkReassessModal';
import { AssessQuestion } from './BulkReassessModal.types';

export type BulkReassessOption = AnswerSemanticMeaning | 'close' | 'oneByOne' | 'back';

export type InitializeBulkEditParams = {
  questionsOverride?: AssessQuestion[];
  /**
   * @default false
   */
  disableCompliantResponse?: boolean;
  displayBack?: boolean;
};

export interface BulkReassessModalProviderConfig {
  questions: AssessQuestion[];
  actionCallback(confirm: BulkReassessOption): void;
  isCompliantResponseDisabled: boolean;
  displayBack?: boolean;
}

export interface CloseTaskDialogState {
  openDialog: (config: BulkReassessModalProviderConfig) => void;
}

export const BulkReassessModalContext = React.createContext<CloseTaskDialogState>({
  openDialog: noop,
});

/**
 * High level component that can renders the Bulk Reassessment dialog
 * Used with useBulkReassessModal hook
 */
export const BulkReassessModalProvider: FCC = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [option, setOption] = useState<BulkReassessOption>('close');
  const [dialogConfig, setDialogConfig] = useState<BulkReassessModalProviderConfig | null>(null);

  const openDialog = useCallback((config: BulkReassessModalProviderConfig) => {
    setOpen(true);
    if (config.questions.length === 1) {
      setOption('oneByOne');
    }
    setDialogConfig(config);
  }, []);

  const resetDialog = useCallback(() => {
    setOpen(false);
    setDialogConfig(null);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setOption('close');
  }, []);

  const handleExited = useCallback(() => {
    resetDialog();
    if (dialogConfig) {
      dialogConfig.actionCallback(option);
    }
  }, [dialogConfig, option, resetDialog]);

  const handleSubmitted = useCallback((bulkOption: AnswerSemanticMeaning) => {
    setOpen(false);
    setOption(bulkOption);
  }, []);

  const handleOneByOne = useCallback(() => {
    setOpen(false);
    setOption('oneByOne');
  }, []);

  const handleBackClick = useCallback(() => {
    setOpen(false);
    setOption('back');
  }, []);

  const providerValue: CloseTaskDialogState = useMemo(() => ({ openDialog }), [openDialog]);

  return (
    <BulkReassessModalContext.Provider value={providerValue}>
      <BulkReassessModal
        open={open}
        onExited={handleExited}
        onClose={handleClose}
        questions={dialogConfig?.questions ?? []}
        onSubmitted={handleSubmitted}
        onOneByOne={handleOneByOne}
        isCompliantResponseDisabled={dialogConfig?.isCompliantResponseDisabled ?? false}
        onBackClick={dialogConfig?.displayBack ? handleBackClick : undefined}
      />
      {children}
    </BulkReassessModalContext.Provider>
  );
};

/**
 * Higher Order Component that adds a BulkReassessModalContext
 */
export const withBulkReassessModalProvider = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  const Component = React.forwardRef((props: P, ref) => {
    return (
      <BulkReassessModalProvider>
        <WrappedComponent {...props} ref={ref} />
      </BulkReassessModalProvider>
    );
  });
  return Component;
};

export const useBulkReassessModal = (questions: AssessQuestion[]) => {
  const { openDialog } = useContext(BulkReassessModalContext);

  const initiateBulkEdit = (params?: InitializeBulkEditParams): Promise<BulkReassessOption> => {
    const { questionsOverride, disableCompliantResponse = false, displayBack } = params ?? {};
    return new Promise(res => {
      openDialog({
        actionCallback: res,
        questions: questionsOverride ?? questions,
        isCompliantResponseDisabled: disableCompliantResponse,
        displayBack,
      });
    });
  };

  return { initiateBulkEdit };
};
