import { useApiClient } from '@circadian-risk/api-client-provider';
import {
  GetLocationScenarioAssessmentFormInfoEditQuery,
  useGetLocationScenarioAssessmentFormInfoEditQuery,
} from '@circadian-risk/client-graphql-hooks';
import { useNotification, useStructuralMemo } from '@circadian-risk/front-end-utils';
import { ROUTES } from '@circadian-risk/routes';
import { AssessmentState, DateFormat, USER_ROLE } from '@circadian-risk/shared';
import { useOrgRoleQueryPrecedence } from '@circadian-risk/stores';
import { LinearProgress } from '@mui/material';
import { useHistoryPushForActiveOrg } from '@web-app/lib/appHelpers';
import dayjs from 'dayjs';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Prompt, Redirect } from 'react-router-dom';

import { createAssignmentFromQueryData } from './helpers/createAssignmentFromQueryData';
import { ScenarioAssessmentForm } from './ScenarioAssessmentForm';
import { useAssessmentFormStore } from './ScenarioAssessmentForm/AssessmentFormStore';
import { singleAssessmentForm } from './single-assessment-form';
import { parseTemplates, useRelevantNodes } from './utils';

const { useCircadianForm, CircadianForm } = singleAssessmentForm;
export type CreateAssessmentContext = {
  organizationId: string;
};

interface ScenarioAssessmentEditPageProps {
  organizationId: string;
  assessmentId: number;
}

interface ScenarioAssessmentEditContentProps {
  assessment: NonNullable<GetLocationScenarioAssessmentFormInfoEditQuery['v2_assessments_by_pk']>;
  organizationId: string;
}

const ScenarioAssessmentEditContent: React.FC<ScenarioAssessmentEditContentProps> = memo(
  ({ assessment, organizationId }) => {
    const history = useHistoryPushForActiveOrg();
    const { tsRestClient } = useApiClient();
    const [submitting, setSubmitting] = useState(false);
    const { displayError, displaySuccess } = useNotification();

    const [template] = useMemo(() => parseTemplates([assessment.assessmentTemplate!]), [assessment.assessmentTemplate]);
    const [relevantNodesResult] = useRelevantNodes(assessment.scored_node_id!, template);

    const structuralRelevantNodes = useStructuralMemo(relevantNodesResult);

    const { usingPreviousAssessmentData } = useAssessmentFormStore(state => ({
      usingPreviousAssessmentData: state.usingPreviousAssessmentData,
    }));

    const assignment = useMemo(() => {
      return createAssignmentFromQueryData({
        users: assessment.users,
        subscribedUsers: assessment.subscribedUsers,
        subscribedGroups: assessment.subscribedGroups,
        subscribedTeams: assessment.subscribedTeams,
        assignedGroups: assessment.assignedGroups,
        assignedTeams: assessment.assignedTeams,
      });
    }, [
      assessment.assignedGroups,
      assessment.assignedTeams,
      assessment.subscribedGroups,
      assessment.subscribedTeams,
      assessment.subscribedUsers,
      assessment.users,
    ]);

    const methods = useCircadianForm({
      values: {
        name: assessment.name,
        description: assessment.description,
        startDate: assessment.start_date ? dayjs(assessment.start_date).toDate() : null,
        dueDate: assessment.due_date ? dayjs(assessment.due_date).toDate() : null,
        assessmentTemplateId: assessment.assessmentTemplate!.id,
        rootLocationId: assessment.scored_node_id || undefined,
        locationIds: assessment.locations.map(n => n.node.id),
        assignment,
        importActiveAnswers: assessment.import_active_answers,
        ratificationRequired: {
          compliant: assessment.is_compliant_ratification_required,
          deficient: assessment.is_deficient_ratification_required,
          n_a: assessment.is_n_a_ratification_required,
          n_o: assessment.is_n_o_ratification_required,
        },
        // TODO(v-rrodrigues)[CR-5317]
        notifications: [],
      },
      shouldUnregister: false,
    });

    const { setValue, getValues } = methods;

    useEffect(() => {
      useAssessmentFormStore.setState({
        templates: [template],
        editing: true,
        selectedTemplate: template,
        relevantNodes: structuralRelevantNodes,
      });
    }, [assessment.assessmentTemplate, structuralRelevantNodes, template]);

    useEffect(() => {
      if (!usingPreviousAssessmentData) {
        setValue(
          'locationIds',
          structuralRelevantNodes.map(n => n.id),
        );
      }
    }, [setValue, structuralRelevantNodes, usingPreviousAssessmentData]);

    const onSubmit = useCallback(async () => {
      try {
        setSubmitting(true);
        const formData = getValues();
        await tsRestClient.assessments.update({
          params: {
            organizationId,
            id: assessment.id,
          },
          body: {
            locationIds: formData.locationIds,
            name: formData.name,
            description: formData.description,
            startDate: formData.startDate ? dayjs(formData.startDate).format(DateFormat.YEAR_MONTH_DAY) : null,
            dueDate: formData.dueDate ? dayjs(formData.dueDate).format(DateFormat.YEAR_MONTH_DAY) : null,
            assignment: formData.assignment,
          },
        });

        displaySuccess('Successfully updated Assessment');
        history.push(`${ROUTES.ASSESSMENTS}/${assessment.id}`);
      } catch (err) {
        displayError(err);
      } finally {
        setSubmitting(false);
      }
    }, [assessment.id, displayError, displaySuccess, getValues, history, organizationId, tsRestClient.assessments]);

    return (
      <CircadianForm formMethods={methods} onSubmit={onSubmit}>
        {submitting && <LinearProgress />}
        <ScenarioAssessmentForm onSubmit={onSubmit} />
        <Prompt
          when={!submitting}
          message="This assessment is not yet created. If you leave this page, your changes will not be saved."
        />
      </CircadianForm>
    );
  },
);

export const ScenarioAssessmentEditPage: React.FC<ScenarioAssessmentEditPageProps> = memo(
  ({ organizationId, assessmentId }) => {
    const context = useOrgRoleQueryPrecedence(USER_ROLE.ORGANIZATION_ADMIN, USER_ROLE.LOCATION_MANAGER);

    const { data, loading, error } = useGetLocationScenarioAssessmentFormInfoEditQuery({
      variables: {
        organizationId,
        assessmentId,
      },
      fetchPolicy: 'network-only',
      context,
    });

    if (loading) {
      return <LinearProgress />;
    }

    if (!data?.v2_assessments_by_pk || error) {
      return null;
    }

    if (data.v2_assessments_by_pk?.state === AssessmentState.Complete) {
      return <Redirect to={`${ROUTES.ASSESSMENTS}/${assessmentId}`} />;
    }

    return <ScenarioAssessmentEditContent organizationId={organizationId} assessment={data.v2_assessments_by_pk} />;
  },
);
