import { EndsOn } from '@circadian-risk/api-contract';
import { CreateAssessmentFormGroupHeader } from '@circadian-risk/assessment-components';
import { HStack, VStack } from '@circadian-risk/presentational';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Controller } from 'react-hook-form';

import { createScheduleAssessmentForm, useCreateScheduledAssessmentFormContext } from '../../../form';
import { useAssessmentStartDateWatch } from '../hooks/useAssessmentStartDateWatch';

const { Date: DateInput, Numeric } = createScheduleAssessmentForm;

export const EndOnFormGroup: React.FC = () => {
  const { control, setValue } = useCreateScheduledAssessmentFormContext();
  const startDate = useAssessmentStartDateWatch();

  const options = [
    {
      value: 'never',
      label: 'Never',
    },
    {
      value: 'target_date',
      label: 'on',
      input: (
        <DateInput
          formKey="endsOn.value"
          label="on date"
          size="small"
          minDate={startDate}
          datePickerProps={{ disablePast: true }}
        />
      ),
    },
    {
      value: 'after_completed_amount',
      label: 'After',
      input: <Numeric formKey="endsOn.value" label="Number of Assessments" size="small" />,
    },
  ];

  return (
    <>
      <CreateAssessmentFormGroupHeader title="End on" subtitle="When should scheduled assessments end?" />
      <Controller
        control={control}
        name={'endsOn.type'}
        render={({ field }) => {
          return (
            <RadioGroup
              onChange={c => {
                const typedValue = c.target.value as EndsOn['type'];

                if (typedValue === 'never') {
                  setValue('endsOn.value', null);
                } else if (typedValue === 'target_date') {
                  setValue('endsOn.value', new Date().toISOString());
                } else {
                  setValue('endsOn.value', 1);
                }

                field.onChange(c);
              }}
              value={field.value}
            >
              <VStack spacing={1} flexWrap="wrap">
                {options.map(option => {
                  const isSelected = field.value === option.value;
                  return (
                    <HStack spacing={0} key={option.value}>
                      <FormControlLabel
                        value={option.value}
                        control={<Radio />}
                        label={
                          <HStack>
                            {option.label}
                            {isSelected && <>{option.input}</>}
                          </HStack>
                        }
                      />
                    </HStack>
                  );
                })}
              </VStack>
            </RadioGroup>
          );
        }}
      />
    </>
  );
};
