import { FCC } from '@circadian-risk/front-end-utils';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import isString from 'lodash/isString';
import React, { CSSProperties, useCallback, useMemo, useState } from 'react';

import { ConfirmationDialogConfig, ConfirmationDialogContext } from './ConfirmationDialogContext';
/**
 * High level component that can render a confirmation dialog
 * Used with useConfirmationDialog hook
 */
export const ConfirmationDialogProvider: FCC = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [dialogConfig, setDialogConfig] = useState<ConfirmationDialogConfig | null>(null);

  const openDialog = useCallback(
    (config: ConfirmationDialogConfig) => {
      setOpen(true);
      setDialogConfig(config);
    },
    [setOpen, setDialogConfig],
  );

  const resetDialog = () => {
    setOpen(false);
    setDialogConfig(null);
  };

  const onDismiss = () => {
    setConfirmed(false);
    setOpen(false);
  };

  const onConfirm = () => {
    setConfirmed(true);
    setOpen(false);
  };

  const handleClosed = () => {
    resetDialog();
    if (dialogConfig) {
      dialogConfig.actionCallback(confirmed);
    }
  };

  const cancelButtonStyles: CSSProperties | undefined = useMemo(() => {
    return dialogConfig?.cancelButton?.style ?? undefined;
  }, [dialogConfig]);

  const confirmButtonStyles: CSSProperties | undefined = useMemo(() => {
    return dialogConfig?.confirmButton?.style ?? undefined;
  }, [dialogConfig]);

  const dialogContent = useMemo(() => {
    const message = dialogConfig?.message;
    if (!message) {
      return null;
    }

    if (isString(message)) {
      return <DialogContentText>{message}</DialogContentText>;
    }

    return message;
  }, [dialogConfig?.message]);

  const value = useMemo(() => ({ openDialog }), [openDialog]);

  return (
    <ConfirmationDialogContext.Provider value={value}>
      <Dialog open={open} TransitionProps={{ onExited: handleClosed }} onClose={onDismiss}>
        <DialogTitle>{dialogConfig?.title}</DialogTitle>
        <DialogContent>{dialogContent}</DialogContent>
        <DialogActions>
          <Button onClick={onDismiss} style={cancelButtonStyles} {...dialogConfig?.cancelButton?.buttonProps}>
            {dialogConfig?.cancelButton?.text ?? 'Cancel'}
          </Button>
          <Button
            onClick={onConfirm}
            variant="text"
            color="primary"
            style={confirmButtonStyles}
            {...dialogConfig?.confirmButton?.buttonProps}
          >
            {dialogConfig?.confirmButton?.text ?? 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
      {children}
    </ConfirmationDialogContext.Provider>
  );
};
