/* eslint-disable @typescript-eslint/no-explicit-any */

import { ColDef, ValueFormatterParams } from '@ag-grid-community/core';
import { getHumanReadableAssessmentState, humanFormatDateTime } from '@circadian-risk/shared';
import * as z from 'zod';

type ColumnValueFormatter = ColDef['valueFormatter'];

const noDecimalPlacesCurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export const formatCurrencyNoDecimalPlaces = (input: number) => noDecimalPlacesCurrencyFormatter.format(input);

const makeColumnValueFormatter =
  (fn: (colVal: any) => any): ColumnValueFormatter =>
  (params: ValueFormatterParams) => {
    const colValue = params.data[params.colDef.field as string];
    if (colValue === null) {
      return null;
    }
    return fn(colValue);
  };

/**
 * Valueformatter to display a column as currency data
 */
export const currencyValueFormatter = makeColumnValueFormatter(formatCurrencyNoDecimalPlaces);

const percentageValidator = z.number().min(0).max(100);

/**
 * Valueformatter to display a column as percentage %
 */
export const percentageValueFormatter = (params: ValueFormatterParams) => {
  const colValue = params.data[params.colDef.field as string];
  return percentageValidator.safeParse(colValue).success ? `${colValue}%` : colValue;
};

/**
 * ValueFormatter you can use with a date column to present it in a human readable way
 */
export const dateFormatter = makeColumnValueFormatter(colVal => {
  if (!colVal) {
    return '';
  }
  return humanFormatDateTime(colVal, false);
});

export const assessmentStatusFormatter = makeColumnValueFormatter(colVal => {
  if (!colVal) {
    return '';
  }
  return getHumanReadableAssessmentState(colVal);
});
