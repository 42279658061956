import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  CardProps,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';

const MAX_ITEMS_TO_SHOW = 5;

export interface SampleQuestionsCardProps extends Omit<CardProps, 'title'> {
  title?: JSX.Element & React.ReactNode;
  hasQuestionSet: boolean;
  questions: string[];
}

export const SampleQuestionsCard: React.FC<SampleQuestionsCardProps> = ({
  title = 'Sample Questions',
  questions,
  hasQuestionSet,
  ...cardProps
}) => {
  const noQuestionSetWarning = <Alert severity="warning">This scenario does not have a primary question set</Alert>;
  const cardContent = (
    <>
      <List
        dense
        sx={{
          mask: questions.length > MAX_ITEMS_TO_SHOW ? 'linear-gradient(black 60%, transparent)' : undefined,
        }}
      >
        {questions.slice(0, 5).map(question => (
          <ListItem key={question}>
            <ListItemText>{question}</ListItemText>
          </ListItem>
        ))}
      </List>
      <Typography align="center" color={theme => theme.palette.text.secondary}>
        {questions.length} Questions in Total
      </Typography>
    </>
  );
  return (
    <Card {...cardProps}>
      <CardHeader sx={{ paddingBottom: 0 }} title={title} />
      <CardContent>{hasQuestionSet ? cardContent : noQuestionSetWarning}</CardContent>
    </Card>
  );
};
