import { FCC } from '@circadian-risk/front-end-utils';
import { styled } from '@mui/material';
import React from 'react';

import { HStack, HStackProps } from '../Layouts';

type Color = 'primary' | 'secondary' | 'error' | 'success' | 'warning' | 'info';

export interface SelectableListItemProps extends Omit<HStackProps, 'children'> {
  selected?: boolean;
  selectable?: boolean;
  hasError?: boolean;
  color?: Color;
}

const SelectableListItemWrapper = styled(HStack)<{ color?: Color }>(({ theme, color = 'primary' }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.5, 2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  borderLeft: `0 solid ${theme.palette[color].main}`,
  width: 'fill-available',
  transition: theme.transitions.create(['background-color', 'border-left'], {
    duration: theme.transitions.duration.shortest,
  }),

  '&[data-selectable=true]': {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette[color].background,
    },
    '&[data-selected=true]': {
      backgroundColor: theme.palette[color].background,
      borderLeftWidth: 4,
      '&:hover': {
        backgroundColor: theme.palette.utils.getHoverColor(theme.palette[color].background),
      },
    },
  },

  '&[data-error=true]': {
    backgroundColor: theme.palette.error.background,
  },
}));

export const SelectableListItem: FCC<SelectableListItemProps> = ({
  children,
  selected,
  selectable,
  hasError,
  ...props
}) => {
  return (
    <SelectableListItemWrapper
      data-selectable={selectable}
      data-selected={selected}
      data-error={hasError}
      {...props}
      color={hasError ? 'error' : props.color}
    >
      {children}
    </SelectableListItemWrapper>
  );
};
