import { CircadianRoute, ROUTES } from '@circadian-risk/routes';
import { USER_ROLE } from '@circadian-risk/shared';
import { lazyLoadWithVersionCheck } from '@web-app/router/lazyLoadWithVersionCheck';

const InherentRiskTablePage = lazyLoadWithVersionCheck(() => import('./InherentRiskTablePage'));
const ScenarioDetailsPage = lazyLoadWithVersionCheck(() => import('./ScenarioDetailsPage'));
const ScenariosTablePage = lazyLoadWithVersionCheck(() => import('./ScenariosTablePage'));

const ScenarioCatalogListPage = lazyLoadWithVersionCheck(() => import('./ScenarioCatalogListPage'));
const ScenarioCatalogDetailsPage = lazyLoadWithVersionCheck(() => import('./ScenarioCatalogDetailsPage'));
const NewQuestionSet = lazyLoadWithVersionCheck(() => import('./NewQuestionSet'));

export const scenarioRoutes = (): CircadianRoute[] => [
  {
    path: ROUTES.SCENARIOS,
    component: ScenariosTablePage,
    isExact: true,
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN, USER_ROLE.LOCATION_MANAGER],
  },
  {
    path: ROUTES.SCENARIO_QUESTION_SET,
    component: NewQuestionSet,
    isExact: true,
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN],
  },
  {
    path: ROUTES.SCENARIOS_DETAIL,
    component: ScenarioDetailsPage,
    isExact: false,
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN, USER_ROLE.LOCATION_MANAGER],
  },
  {
    path: ROUTES.INHERENT_RISK,
    component: InherentRiskTablePage,
    isExact: true,
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN],
  },
  {
    path: ROUTES.SCENARIO_CATALOG_LIST,
    component: ScenarioCatalogListPage,
    isExact: true,
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN],
    featureFlag: 'scenario_catalog',
  },
  {
    path: ROUTES.SCENARIO_CATALOG_DETAILS,
    component: ScenarioCatalogDetailsPage,
    isExact: true,
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN],
    featureFlag: 'scenario_catalog',
  },
];
