import { z } from 'zod';

export const createLocationFilterSchema = z.object({
  name: z.string().min(1),
  description: z.string().nullable(),
  baseLayerId: z.string(),
  belongsToId: z.string().nullable(),
  includeChildren: z.boolean(),
  tagIds: z.array(z.number()),
});

export type CreateLocationFilterSchema = z.output<typeof createLocationFilterSchema>;
