import { debug, FCC } from '@circadian-risk/front-end-utils';
import { LoadingPage } from '@circadian-risk/layout';
import * as Sentry from '@sentry/react';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { Subscription } from 'rxjs';
import { v4 } from 'uuid';

import { useFileManagerStore } from '../store/useFileManagerStore';
import { FileEntity, FileManagerDatabase, IFileItem, UploadEndpointInfo, UploadStatus } from '../types';
import { createDb } from './database';
import { createFileManagerMockDatabase } from './mock-database';

const log = debug.extend('FileManagerDBProvider');

export interface FileManagerDatabaseContextProps {
  database?: FileManagerDatabase;
}

// @ts-expect-error Context complains about the type since it is also undefined but will be lazily defined
export const FileManagerDatabaseContext = createContext<FileManagerDatabaseContextProps>();

export const createRXDBFileManagerDatabase = async (): Promise<FileManagerDatabase> => {
  const db = await createDb();

  const query = db.fileentity.find();

  let subscription: Subscription | undefined;

  const get: FileManagerDatabase['get'] = async () => {
    const documents = await db.fileentity.find().exec();
    return documents.map(e => {
      return {
        id: e.id,
        insertedAt: e.insertedAt,
        name: e.name,
        size: e.size,
        status: e.status,
        type: e.type,
        uploadEndpointInfo: e.uploadEndpointInfo,
      };
    });
  };

  const subscribe: FileManagerDatabase['subscribe'] = handler => {
    subscription = query.$.subscribe(entities => {
      const handlerEntities: IFileItem[] = entities
        // Filter out entities for which attachments are not attached yet due to async attachment and subscription cycle
        .filter(e => {
          return !isEmpty(e.allAttachments());
        })
        .map(e => ({
          id: e.id,
          insertedAt: e.insertedAt,
          name: e.name,
          size: e.size,
          status: e.status,
          type: e.type,
          uploadEndpointInfo: e.uploadEndpointInfo,
        }));

      handler(handlerEntities);
    });
  };

  const insert: FileManagerDatabase['insert'] = async (file: File, uploadEndpointInfo: UploadEndpointInfo) => {
    const id = v4();
    const metadata: FileEntity = {
      id,
      size: file.size,
      name: file.name,
      type: file.type,
      insertedAt: new Date().getTime(),
      status: UploadStatus.Pending,
      uploadEndpointInfo,
    };
    const doc = await db.fileentity.insert(metadata);

    await doc.putAttachment({
      id: v4(),
      data: file,
      type: file.type,
    });
  };

  const remove = async (id: string) => {
    const doc = await db.fileentity
      .findOne({
        selector: {
          id,
        },
      })
      .exec();

    if (doc) {
      return doc.remove();
    }
    return Promise.resolve(true);
  };

  const update = async (id: string, input: Partial<IFileItem>) => {
    const doc = await db.fileentity
      .findOne({
        selector: {
          id,
        },
      })
      .exec();

    await doc?.atomicUpdate(oldData => {
      merge(oldData, input);
      return oldData;
    });
  };

  const getBlob: FileManagerDatabase['getBlob'] = async (id: string) => {
    const doc = await db.fileentity
      .findOne({
        selector: {
          id,
        },
      })
      .exec();

    if (doc) {
      const [first] = doc.allAttachments();
      if (first) {
        return (await first.getData()) as Blob;
      }
    }
    return null;
  };

  const unsubscribe: FileManagerDatabase['unsubscribe'] = async () => {
    subscription?.unsubscribe();
  };

  const removeAll = async () => {
    const allFileEntities = await db.fileentity.find().exec();
    const idsToRemove = allFileEntities.map(doc => doc.id);
    const result = await db.fileentity.bulkRemove(idsToRemove);
    return result.error.length > 0;
  };

  return {
    get,
    subscribe,
    insert,
    remove,
    update,
    getBlob,
    unsubscribe,
    removeAll,
  };
};

export const FileManagerDatabaseProvider: FCC = ({ children }) => {
  const [database, setDatabase] = useState<FileManagerDatabase | undefined>(undefined);

  const provideDb = useCallback(async () => {
    let db: FileManagerDatabase;
    if (process.env['NODE_ENV'] === 'test') {
      db = await createFileManagerMockDatabase();
    } else {
      db = await createRXDBFileManagerDatabase();
    }

    log('Setting DB for useFileManagerStore');

    await useFileManagerStore.getState().setDb(db);
    setDatabase(db);
  }, []);

  useEffect(() => {
    void provideDb().catch(e => {
      Sentry.captureException(e);
    });
  }, [provideDb]);

  const value = useMemo(() => ({ database }), [database]);

  if (!database) {
    return <LoadingPage />;
  }

  const safeValue = value as FileManagerDatabaseContextProps;

  return <FileManagerDatabaseContext.Provider value={safeValue}>{children}</FileManagerDatabaseContext.Provider>;
};
