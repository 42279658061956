import { useDialog, VStack } from '@circadian-risk/presentational';
import { Button, Typography } from '@mui/material';
import React from 'react';

import { getCurrentUserTimezone } from '../helpers';
import { timezones } from './timezoneList';
import { TimezonePickerDialog } from './TimezonePickerDialog';

const mappedTimezones = timezones.map(e => ({
  label: `(${e.abbrev}) ${e.name}`,
  value: e.name!,
}));

export type TimezonePickerProps = {
  /**
   * If nothing is provided the component will populate with users current timezone
   * @default current timezone
   */
  selectedTimezone?: string;
  onChange: (value: string) => void;
};

export const TimezonePicker: React.FC<TimezonePickerProps> = ({ selectedTimezone, onChange }) => {
  const { isOpen, openDialog, closeDialog } = useDialog();
  const timezoneId = selectedTimezone ?? getCurrentUserTimezone();

  const timezoneWithFallback = mappedTimezones.find(e => e.value === timezoneId);

  return (
    <VStack alignItems={'baseline'}>
      <Typography variant="caption" color="secondary">
        {timezoneWithFallback?.label ?? 'Timezone not found'}
      </Typography>
      <Button size="small" variant="text" color="primary" sx={{ textTransform: 'uppercase' }} onClick={openDialog}>
        Change time zone
      </Button>
      <TimezonePickerDialog
        value={timezoneId}
        onChange={onChange}
        onClose={closeDialog}
        isOpen={isOpen}
        timezones={mappedTimezones}
      />
    </VStack>
  );
};
