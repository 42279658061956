import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Tooltip } from '@mui/material';
import React from 'react';

import { CellRendererParams } from '../helpers';

export interface EditButtonCellRendererComponentProps {
  id: string;
  [key: string]: string | number | null | undefined;
}

export const EditButtonCellRendererComponent: React.FC<
  CellRendererParams<EditButtonCellRendererComponentProps>
> = props => {
  const handleEditClick = (_e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const colKey = props.column?.getId();
    const thisRow = props.api.getRowNode(props.data.id);
    const rowIndex = thisRow?.rowIndex;

    if (rowIndex !== null && colKey && rowIndex !== undefined) {
      props.api.startEditingCell({
        rowIndex,
        colKey,
      });
    }
  };

  return (
    <Tooltip title="Edit">
      <IconButton size="small" onClick={handleEditClick}>
        <EditIcon />
      </IconButton>
    </Tooltip>
  );
};
