import { Coordinates } from "@circadian-risk/shared";

import { RealWorldMarkerItem } from "../interfaces";
import { isSameCoordinatesFilter } from "./isSameCoordinatesFilter.util";

export const createStackedMarkers = (rows: RealWorldMarkerItem[]) => {
    const stacks: { coordinates: Coordinates; items: RealWorldMarkerItem[] }[] = [];

    for (const row of rows) {
        const itemsOnSameCoordinates = rows.filter(
            e => e.id !== row.id && isSameCoordinatesFilter({ coordinates1: e.coordinates, coordinates2: row.coordinates }),
        );
        if (itemsOnSameCoordinates.length > 0) {
            const target = stacks.find(
                e => e.coordinates[0] === row.coordinates[0] && e.coordinates[1] === row.coordinates[1],
            );
            if (!target) {
                stacks.push({ coordinates: row.coordinates, items: [row, ...itemsOnSameCoordinates] });
            }
        }
    }
    return stacks;
};