import { DueAfter, Frequency, WeekdayType } from '@circadian-risk/api-contract';
import { DateFormat, parseTime } from '@circadian-risk/shared';
import dayjs from 'dayjs';
import timezonePlugin from 'dayjs/plugin/timezone';
import utcPlugin from 'dayjs/plugin/utc';
import { match, P } from 'ts-pattern';

import { fullWeekdayMap, getWeekdayOrdinal, sortWeekdays, weekdayIndex } from './weekdays-utils';

dayjs.extend(utcPlugin);
dayjs.extend(timezonePlugin);

// Constants
const HOURS_IN_DAY = 24;
const DAYS_IN_WEEK = 7;
const AVERAGE_DAYS_IN_MONTH = 30.44;
const DAYS_IN_YEAR = 365;
const WEEKDAYS_IN_WEEK = 5;

type CalculateAssessmentStartDateParams = {
  /**
   * The baseline date in UTC from which to calculate the next frequency time.
   *
   */
  baseDate: dayjs.Dayjs | string | Date;
  /**
   * The start time of the cadence in the user's timezone.
   */
  cadenceStartsAt: string;
  /**
   * User's timezone
   */
  timezone: string;
  /**
   * The frequency of the assessment (daily, monthly, weekday, weekly, annually, custom).
   */
  frequency: Frequency;
  /**
   * The last time the assessment was opened, used for certain frequency calculations.
   */
  lastOpenedAssessmentAt?: string;
};

export class ScheduledAssessmentTimeHelpers {
  /**
   * Calculates the smallest interval between the chosen weekdays
   *
   * @param repeatOn
   * @returns Smallest interval in hours
   */
  public convertCustomRepeatOnToHours(repeatOn: string[]): number {
    const sorted = sortWeekdays(repeatOn as WeekdayType[]).map(getWeekdayOrdinal);

    const periods: number[] = [];

    // Calculate the difference between each weekday
    // This enable us to query the smallest interval between the chosen weekdays
    if (sorted.length > 1) {
      sorted.forEach((current, i) => {
        const next = sorted[i + 1];
        if (!next) {
          return;
        }
        const diff = next - current;
        periods.push(diff);
      });
    }

    const smallestIntervalInDays = periods.length > 0 ? Math.min(...periods) : 1;
    const value = smallestIntervalInDays * HOURS_IN_DAY;

    return value;
  }
  private getTimeFromStartsAt(startsAt: string) {
    const time = dayjs(startsAt).format('HH:mm');
    return parseTime(time);
  }

  /**
   * Returns the weekday from the startsAt date
   * @param startsAt
   * @returns
   */
  private getWeekdayFromStartsAt(startsAt: string) {
    return dayjs(startsAt).format(DateFormat.FULL_WEEKDAY).toLowerCase();
  }
  private calculateNextDayDate(dateAtUserTimezone: dayjs.Dayjs, cadenceStartsAt: string, daysToAdd = 1) {
    const { hour, minute } = this.getTimeFromStartsAt(cadenceStartsAt);
    return dateAtUserTimezone.add(daysToAdd, 'day').hour(hour).minute(minute).second(0);
  }

  private calculateNextYearDate(cadenceStartDate: string, timezone: string, yearsToAdd = 1) {
    const { hour, minute } = this.getTimeFromStartsAt(cadenceStartDate);
    return dayjs(cadenceStartDate).tz(timezone).add(yearsToAdd, 'year').hour(hour).minute(minute).second(0);
  }

  private calculateNextAvailableWeekdayDate(
    dateAtUserTimezone: dayjs.Dayjs,
    cadenceStartsAt: string,
    weeksToAdd: number,
    repeatOn: WeekdayType[],
    lastOpenedAssessmentAt?: string,
  ) {
    const { hour, minute } = this.getTimeFromStartsAt(cadenceStartsAt);

    // If the base date is a Sunday, we need to convert it to 7 to match the weekdaySortIndexByEnum
    const currentWeekDay = dateAtUserTimezone.day() === 0 ? 7 : dateAtUserTimezone.day();
    const sortedWeekdayIndexes = sortWeekdays(repeatOn);

    if (repeatOn.length === 0) {
      throw new Error('repeatOn is required for custom weekly frequency');
    }

    // Calculate how many days we need to add to get the next weekday
    // If there are no weekdays left in the current week, we need to find the next weekday in the next week
    // therefore -1 is returned
    const nextWeekday = sortedWeekdayIndexes.find(weekday => getWeekdayOrdinal(weekday) > currentWeekDay);
    let daysToAdd = -1;
    if (nextWeekday) {
      daysToAdd = getWeekdayOrdinal(nextWeekday) - currentWeekDay;
    }

    // If we have a last generated assessment, we need to check if we are on the same week
    if (
      lastOpenedAssessmentAt &&
      dayjs(lastOpenedAssessmentAt).isSame(dateAtUserTimezone, 'week') &&
      daysToAdd !== -1
    ) {
      return dateAtUserTimezone.add(daysToAdd, 'day').hour(hour).minute(minute).second(0);
    }

    // Check if there's any weekday left in the current week
    if (daysToAdd !== -1) {
      return dateAtUserTimezone.add(daysToAdd, 'day').hour(hour).minute(minute).second(0);
    }

    // Select the first weekday possible in N weeks from now
    const firstWeekday = weekdayIndex[sortedWeekdayIndexes[0]];
    return dateAtUserTimezone.add(weeksToAdd, 'week').day(firstWeekday).hour(hour).minute(minute).second(0);
  }

  /**
   * Converts the frequency to hours (lowest unit)
   * This helps to compare the frequency at the lowest unit without losing precision
   *
   * @param frequency - Frequency object to be converted
   * @returns Frequency in hours
   */
  public convertFrequencyToHours(frequency: Frequency): number {
    const value = match(frequency)
      .returnType<number>()
      .with({ type: 'daily' }, () => HOURS_IN_DAY)
      .with({ type: 'annually' }, () => DAYS_IN_YEAR * HOURS_IN_DAY)
      .with({ type: 'monthly' }, () => AVERAGE_DAYS_IN_MONTH * HOURS_IN_DAY)
      .with({ type: 'weekly' }, () => DAYS_IN_WEEK * HOURS_IN_DAY)
      .with({ type: 'weekday' }, () => (DAYS_IN_WEEK / WEEKDAYS_IN_WEEK) * HOURS_IN_DAY)
      .with({ type: 'custom', options: P.select() }, options => {
        return match(options)
          .with({ repeatUnit: 'hour' }, ({ value }) => value)
          .with({ repeatUnit: 'day' }, ({ value }) => value * HOURS_IN_DAY)
          .with({ repeatUnit: 'week' }, ({ repeatOn }) => {
            return this.convertCustomRepeatOnToHours(repeatOn);
          })
          .with({ repeatUnit: 'month' }, ({ value }) => value * AVERAGE_DAYS_IN_MONTH * HOURS_IN_DAY)
          .with({ repeatUnit: 'year' }, ({ value }) => value * DAYS_IN_YEAR * HOURS_IN_DAY)
          .exhaustive();
      })
      .with(P._, () => {
        throw new Error('Unsupported frequency type');
      })
      .exhaustive();

    return Math.floor(value);
  }

  /**
   * Converts the dueAfter down to the lowest unit (hours)
   * This helps to compare the dueAfter at the lowest unit without losing precision.
   *
   * @param dueAfter - DueAfter object to be converted
   * @returns DueAfter in hours
   */
  public convertDueAfterToHours(dueAfter: DueAfter): number {
    const value = match(dueAfter)
      .returnType<number>()
      .with({ unit: 'hour' }, ({ value }) => value)
      .with({ unit: 'day' }, ({ value }) => value * HOURS_IN_DAY)
      .with({ unit: 'week' }, ({ value }) => value * DAYS_IN_WEEK * HOURS_IN_DAY)
      .with(P._, () => {
        throw new Error('Unsupported unit type');
      })
      .exhaustive();

    return Math.floor(value);
  }

  /**
   * Validates if the dueAfter period is longer than the frequency period.
   *
   * @param dueAfter - How long the assessment should stay open
   * @param frequency - Frequency of the assessment
   * @returns True if dueAfter is longer than frequency, otherwise false
   */
  public isDueAfterFrequencyTime(dueAfter: DueAfter, frequency: Frequency): boolean {
    const frequencyInHours = this.convertFrequencyToHours(frequency);
    const dueAfterInHours = this.convertDueAfterToHours(dueAfter);

    return dueAfterInHours > frequencyInHours;
  }

  /**
   * Calculates the assessment due date based on the start date, frequency, and dueAfter.
   *
   * @param startDate - Assumed that it is converted to the desired timezone
   * @param dueAfter - How long the assessment should stay open
   * @returns ISO8061 date string
   */
  public calculateAssessmentDueDate(startDate: Date | string | dayjs.Dayjs, dueAfter: DueAfter) {
    const dueDate = dayjs(startDate);
    return dueDate.add(dueAfter.value, dueAfter.unit).toISOString();
  }

  public calculateNextMonthDateFromStartsAt(
    baseDateAtUserTimezone: dayjs.Dayjs,
    cadenceStartsAt: string,
    monthsToAdd = 1,
  ) {
    const { hour, minute } = this.getTimeFromStartsAt(cadenceStartsAt);
    const selectedDay = dayjs(cadenceStartsAt).date();
    let nextMonthDate = baseDateAtUserTimezone.add(monthsToAdd, 'month');
    const nextMonthDays = nextMonthDate.daysInMonth();

    // Adjusts to the last day available if the selected day is greater than the days in the next month
    if (selectedDay > nextMonthDays) {
      nextMonthDate = nextMonthDate.endOf('month');
    } else {
      nextMonthDate = nextMonthDate.date(selectedDay);
    }

    return nextMonthDate.hour(hour).minute(minute).second(0);
  }

  /**
   * Finds the next frequency time date based on the frequency and chosen timezone.
   * The date is returned in UTC timezone.
   *
   * @param params {@link CalculateAssessmentStartDateParams}
   * @returns ISO8601 string representing the next frequency time date in UTC.
   */
  public calculateAssessmentStartDate({
    baseDate,
    frequency,
    cadenceStartsAt,
    timezone,
    lastOpenedAssessmentAt,
  }: CalculateAssessmentStartDateParams): string {
    // Parses the baseline date to an ISO string
    // Guarantees that the date is in UTC
    // otherwise dayjs picks your local timezone by default
    const baseAtUTC = dayjs.utc(baseDate).toISOString();

    // Converts the baseline date from UTC to the user's timezone
    const dateAtUserTimezone = dayjs.tz(baseAtUTC, timezone);

    const finalDate = match(frequency)
      .returnType<dayjs.Dayjs>()
      .with({ type: 'daily' }, () => {
        return this.calculateNextDayDate(dateAtUserTimezone, cadenceStartsAt);
      })
      .with({ type: 'monthly' }, () => {
        return this.calculateNextMonthDateFromStartsAt(dateAtUserTimezone, cadenceStartsAt);
      })
      .with({ type: 'weekday' }, () => {
        const { hour, minute } = this.getTimeFromStartsAt(cadenceStartsAt);
        // Get today's date and day of the week (0: Sunday, 1: Monday, ..., 6: Saturday)
        const todayIndex = dayjs().day();

        // Calculate the number of days to add to get the next weekday
        let daysToAdd;
        if (todayIndex === fullWeekdayMap['fri']) {
          daysToAdd = 3; // Next Monday
        } else if (todayIndex === fullWeekdayMap['sat']) {
          daysToAdd = 2;
        } else {
          // Next day (e.g: we're on a weekday or Sunday)
          daysToAdd = 1;
        }

        // Calculate the next occurrence date
        const nextDate = dateAtUserTimezone.add(daysToAdd, 'day').hour(hour).minute(minute).second(0);

        // Return the next date
        return nextDate;
      })
      .with({ type: 'weekly' }, () => {
        const { hour, minute } = this.getTimeFromStartsAt(cadenceStartsAt);
        const weekday = this.getWeekdayFromStartsAt(cadenceStartsAt);
        const mappedWeekday = fullWeekdayMap[weekday];
        return dateAtUserTimezone.add(1, 'week').day(mappedWeekday).hour(hour).minute(minute).second(0);
      })
      .with({ type: 'annually' }, () => {
        return this.calculateNextYearDate(cadenceStartsAt, timezone);
      })
      .with({ type: 'custom', options: P.select() }, options => {
        return match(options)
          .returnType<dayjs.Dayjs>()
          .with({ repeatUnit: 'hour' }, ({ value }) => {
            return dateAtUserTimezone.add(value, 'hour').minute(0).second(0);
          })
          .with({ repeatUnit: 'day' }, ({ value }) => {
            return this.calculateNextDayDate(dateAtUserTimezone, cadenceStartsAt, value);
          })
          .with({ repeatUnit: 'week' }, ({ repeatOn, value }) => {
            return this.calculateNextAvailableWeekdayDate(
              dateAtUserTimezone,
              cadenceStartsAt,
              value,
              repeatOn,
              lastOpenedAssessmentAt,
            );
          })
          .with({ repeatUnit: 'month' }, ({ value }) => {
            return this.calculateNextMonthDateFromStartsAt(dateAtUserTimezone, cadenceStartsAt, value);
          })
          .with({ repeatUnit: 'year' }, ({ value }) => {
            return this.calculateNextYearDate(cadenceStartsAt, timezone, value);
          })
          .exhaustive();
      })
      .exhaustive();

    return finalDate.toISOString();
  }
}
