import { Box, Typography } from '@mui/material';
import sumBy from 'lodash/sumBy';
import React from 'react';

import { TouchFriendlyTooltip } from '../Tooltips';

export interface FractionBarProps {
  items: {
    count: number;
    color: string;
    tooltip?: string;
    label?: string;
    textColor?: string;
  }[];
  width?: number | string;
}

export const FractionBar: React.FC<FractionBarProps> = ({ items, width }) => {
  const totalCount = sumBy(items, i => i.count);

  return (
    <Box
      width={width}
      sx={theme => ({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: `${theme.shape.borderRadius}px`,
        border: `1px solid ${theme.palette.text.primary}`,
        '& > :not(:first-of-type)': {
          borderLeft: `1px solid ${theme.palette.text.primary}`,
        },
      })}
    >
      {items.map(item => {
        if (item.count <= 0) {
          return null;
        }

        const percentage = (item.count / totalCount) * 100;
        const key = JSON.stringify(item);

        return (
          <React.Fragment key={key}>
            <TouchFriendlyTooltip
              tooltipMaxWidth={500}
              disableWrappers
              tooltipContent={item.tooltip ? <Typography variant="subtitle1">{item.tooltip}</Typography> : undefined}
            >
              <Box
                height="50px"
                display="flex"
                flexDirection={'row'}
                alignItems="center"
                justifyContent={'center'}
                key={item.tooltip}
                width={`${percentage}%`}
                textAlign={'center'}
                // this is important to prevent item from disappearing if its percentage is tiny
                minWidth={'fit-content'}
                px={1}
                sx={theme => {
                  const itemBr = theme.shape.borderRadius - 1;
                  return {
                    fontFamily: theme.typography.h6.fontFamily,
                    fontSize: theme.typography.subtitle1.fontSize,

                    '&:hover': {
                      cursor: 'pointer',
                      opacity: '80%',
                    },
                    '&:first-of-type': {
                      borderTopLeftRadius: itemBr,
                      borderBottomLeftRadius: itemBr,
                    },
                    '&:last-child': {
                      borderTopRightRadius: itemBr,
                      borderBottomRightRadius: itemBr,
                    },
                    '&:not(:first-of-type)': {
                      borderLeft: `1px solid ${theme.palette.text.primary}`,
                    },
                    background: item.color,
                    color: item.textColor ?? theme.palette.getContrastText(item.color),
                  };
                }}
              >
                <span>{item.label ?? item.count}</span>
              </Box>
            </TouchFriendlyTooltip>
          </React.Fragment>
        );
      })}
    </Box>
  );
};
