import { Box } from '@mui/material';

import { SideDrawer, SideDrawerProps } from '../../SideDrawer';
import { useIsDrawerVisible } from './LayoutContext';

interface LayoutSideDrawerProps extends Omit<SideDrawerProps, 'open' | 'fullScreen'> {
  name: string;
}

export const LayoutSideDrawer: React.FC<LayoutSideDrawerProps> = ({ children, side, name, drawerWidth, type }) => {
  const open = useIsDrawerVisible(name);
  return (
    <SideDrawer side={side} open={open} drawerWidth={drawerWidth} fullScreen={false} type={type}>
      <Box minWidth={drawerWidth}>{children}</Box>
    </SideDrawer>
  );
};
