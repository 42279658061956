import React from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';

import { PropertyPasswordField, PropertyPasswordFieldProps } from '../../PropertyFields';
import { useCircadianFormContext } from '../CircadianFormContext';
import { FormField } from './types';
import { useErrorMessage } from './utils';

export type PasswordFFProps<FormData> = FormField<FormData> &
  Omit<PropertyPasswordFieldProps, 'control' | 'htmlName' | 'label'>;

export function PasswordFF<FormData extends FieldValues>({ formKey, ...rest }: PasswordFFProps<FormData>) {
  const { control, formState } = useFormContext<FormData>();
  const { ariaLabels, disabled } = useCircadianFormContext<FormData>();

  const errorMessage = useErrorMessage(formState.errors, formKey);

  return (
    <PropertyPasswordField
      {...{
        disabled,
        control,
        errorMessage,
        ...rest,
      }}
      label={ariaLabels[formKey]}
      htmlName={formKey}
    />
  );
}
