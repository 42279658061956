import { AutocompleteOption } from '@circadian-risk/form';
import { HStack, NodeIcon } from '@circadian-risk/presentational';
import { useOrganizationSessionStore } from '@circadian-risk/stores';
import { Box, Typography } from '@mui/material';
import { HTMLAttributes } from 'react';

import { TemplateSelectOption } from './shared-types';

const renderOptionWithScoredLayerId = ({
  boxProps,
  label,
  layerName,
  deprecatedTemplatesCount = 0,
}: {
  boxProps: HTMLAttributes<HTMLLIElement>;
  label: string;
  layerName: string;
  deprecatedTemplatesCount?: number;
}) => {
  return (
    <Box component="li" {...boxProps}>
      <HStack>
        <NodeIcon layerName={layerName} />
        <Typography variant="body2">{label}</Typography>
        {deprecatedTemplatesCount > 0 && (
          <Typography variant={'caption'} fontStyle={'italic'}>
            {deprecatedTemplatesCount} deprecated template(s)
          </Typography>
        )}
      </HStack>
    </Box>
  );
};

export const useRenderAssessmentTemplateOption = () => {
  const layersById = useOrganizationSessionStore(state => state.layersById);

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, option: AutocompleteOption) => {
    const typedOption = option as unknown as TemplateSelectOption;
    const layer = layersById[typedOption.scoredLayerId];
    return renderOptionWithScoredLayerId({
      boxProps: props,
      label: option.label,
      layerName: layer.name,
      deprecatedTemplatesCount: typedOption.deprecatedTemplatesCount,
    });
  };

  return renderOption;
};
