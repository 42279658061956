import {
  AssessmentItemStatusEnum,
  AssessmentItemStatuses,
  getQuestionnaireItemStatus,
} from '@circadian-risk/front-end-utils';
import { AssessmentPerformanceItem } from '@web-app/modules/Assessments/PerformAssessment/PerformAssessmentData.helpers';

import { debug } from './common';

export const translateQuestionnaireItemStatus = (
  item: AssessmentPerformanceItem,
  isRequired?: boolean,
): AssessmentItemStatuses => {
  try {
    return getQuestionnaireItemStatus({
      isPresent: item.is_present,
      completedAnswers: item.answers.filter(a => !a.isWaitingForResponse).length,
      totalAnswers: item.answers.length,
      deficientAnswers: item.answers.filter(a => a.response === a.deficient_response).length,
      isRequired,
    });
  } catch (e) {
    debug.extend('translateItemStatus')('Could not calculate Status! Check your item', item);
    return AssessmentItemStatusEnum.NotStarted;
  }
};
