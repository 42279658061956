import { FCC } from '@circadian-risk/front-end-utils';
import { useDialog } from '@circadian-risk/presentational';
import isString from 'lodash/isString';
import noop from 'lodash/noop';
import React, { createContext, useCallback, useContext, useState } from 'react';

import { LocationWizardProps } from './components/LocationWizard/LocationWizard';
import { LocationWizardSmartModal } from './LocationWizardModal.smart';

const LocationWizardModalContext = createContext<(mode: LocationWizardProps['initialMode']) => void>(noop);

export const useLocationWizardModal = () => {
  const context = useContext(LocationWizardModalContext);
  if (!context) {
    throw new Error('useLocationWizardModal must be used under LocationWizardProvider');
  }
  return context;
};

export const LocationWizardModalProvider: FCC = ({ children }) => {
  const { isOpen, openDialog, closeDialog } = useDialog();
  const [initialMode, setInitialMode] = useState<LocationWizardProps['initialMode']>();

  const openLocationWizard = useCallback(
    (initialMode: LocationWizardProps['initialMode']) => {
      // explanation for why we need to verify it is a string
      // https://github.com/circadian-risk/project-ember-web/pull/3425#discussion_r985853296
      setInitialMode(initialMode && isString(initialMode.type) ? initialMode : undefined);
      openDialog();
    },
    [openDialog],
  );

  return (
    <LocationWizardModalContext.Provider value={openLocationWizard}>
      {children}
      <LocationWizardSmartModal isOpen={isOpen} onClose={closeDialog} initialMode={initialMode} />
    </LocationWizardModalContext.Provider>
  );
};
