import { ColumnApi, GridApi, GridReadyEvent } from '@ag-grid-community/core';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useSimpleTableStore } from '../simpleTable.store';
import { ColStateParam, FilterSearchParam, PivotStateParam, SortSearchParam } from './constants';

export type GridOnReady = (gridApi: GridApi, columnApi: ColumnApi) => void;
/**
 * Used in conjunction with AgGridReact - Pass onGridReady handler to onGridReady prop
 * @todo (v-rrodrigues): Revisit the types
 * @param onReady Optional callback to use the AGGrid apis immmediately
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useGrid = <T extends Record<string, any>>(onReady?: GridOnReady) => {
  const gridApiRef = useRef<GridApi | null>(null);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const gridColumnApiRef = useRef<ColumnApi | null>(null);
  const updateQuickFilterText = useSimpleTableStore(state => state.updateQuickFilterText);
  const history = useHistory();

  const resetColumnState = useCallback(() => {
    if (gridColumnApiRef.current) {
      gridColumnApiRef.current.resetColumnState();

      // TODO(iprokopovich)[CR-4918]: This will throw "ResizeObserver loop completed with undelivered notifications" if the screen width is small
      setTimeout(() => {
        if (gridColumnApiRef.current) {
          gridColumnApiRef.current.autoSizeAllColumns();
        }
      }, 0);
    }
  }, []);

  const resetFilters = useCallback(() => {
    if (gridApiRef.current) {
      const api = gridApiRef.current;
      api.setFilterModel(null);
      api.onFilterChanged();
    }
  }, []);

  const resetSelected = useCallback(() => {
    if (gridApiRef.current) {
      gridApiRef.current.deselectAll();
    }
  }, []);

  const clearUrlQueryStates = useCallback(() => {
    const newUrlSearchParams = new URLSearchParams(history.location.search);
    newUrlSearchParams.delete(ColStateParam);
    newUrlSearchParams.delete(FilterSearchParam);
    newUrlSearchParams.delete(SortSearchParam);
    newUrlSearchParams.delete(PivotStateParam);
    history.replace({ search: newUrlSearchParams.toString() });
  }, [history]);

  const resetTable = useCallback(() => {
    resetFilters();
    resetColumnState();
    resetSelected();
    clearUrlQueryStates();
    // Clear the quick filter text
    updateQuickFilterText('', gridApiRef.current);
  }, [resetFilters, resetColumnState, resetSelected, clearUrlQueryStates, updateQuickFilterText]);

  const resetFiltersAndResizeColumns = useCallback(() => {
    if (gridApiRef.current) {
      const api = gridColumnApiRef.current;
      resetTable();
      resetFilters();
      api?.autoSizeAllColumns();
    }
  }, [resetTable, resetFilters]);

  const onGridReady = useCallback(
    ({ api, columnApi }: GridReadyEvent) => {
      gridApiRef.current = api;
      gridColumnApiRef.current = columnApi;
      setGridApi(api);

      if (onReady) {
        onReady(api, columnApi);
      }
    },
    [onReady],
  );

  const setColumnFilter = useCallback(
    (
      column: Extract<keyof T, string>,
      columnFilter: Record<string, unknown> | null,
      options?: {
        overwriteFilter?: boolean;
      },
    ) => {
      if (gridApiRef.current) {
        const { overwriteFilter = false } = options ?? {};
        const api = gridApiRef.current;
        const existingFilterModel = api.getFilterModel();
        const instance = api.getFilterInstance(column);

        if (overwriteFilter) {
          api.setFilterModel({
            [column]: columnFilter,
          });

          return;
        }

        if (instance) {
          instance.setModel(columnFilter);
        } else {
          api.setFilterModel({
            ...existingFilterModel,
            [column]: columnFilter,
          });
        }

        api.onFilterChanged();
      }
    },
    [],
  );

  /**
   * Temporary workaround introduced in CR-5514 as part of Ag grid support team feedback
   * TODO(v-rrodrigues)[CR-5013]: Ag grid v31 exposes onGridDestroy
   * @see https://ag-grid.zendesk.com/hc/en-us/requests/31123
   */
  useEffect(() => {
    const t = setInterval(() => {
      const gridApi = gridApiRef.current;
      // Inspect the private property destroyCalled
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((gridApi as any)?.destroyCalled) {
        gridApiRef.current = null;
        gridColumnApiRef.current = null;
      }
    }, 500);

    return () => {
      // When unsubscribed, it works just fine
      clearTimeout(t);
    };
  }, []);

  return {
    gridApi,
    gridColumnApi: gridColumnApiRef.current,
    onGridReady,
    resetFilters,
    resetColumnState,
    resetTable,
    setColumnFilter,
    resetFiltersAndResizeColumns,
  };
};
