import DarkModeTwoToneIcon from '@mui/icons-material/DarkModeTwoTone';
import LightModeTwoToneIcon from '@mui/icons-material/LightModeTwoTone';
import SettingsBrightnessTwoToneIcon from '@mui/icons-material/SettingsBrightnessTwoTone';
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import React from 'react';

import { ColorModePreferences, useThemeStore } from '../themeStore/theme.store';

export const ColorModeToggleGroup: React.FC = () => {
  const [colorMode, setColorMode] = useThemeStore(state => [state.colorMode, state.setColorMode]);

  const MODES: Record<ColorModePreferences, ColorModePreferences> = {
    light: 'light',
    dark: 'dark',
    system: 'system',
  };

  return (
    <ToggleButtonGroup
      size="small"
      value={colorMode}
      exclusive
      onChange={(_, newValue) => newValue && setColorMode(newValue)}
      aria-label="color mode toggle"
    >
      <ToggleButton value={MODES.light} aria-label="light mode">
        <Tooltip title="Using light mode always">
          <LightModeTwoToneIcon />
        </Tooltip>
      </ToggleButton>
      <ToggleButton value={MODES.system} aria-label="system preferred mode" color="primary">
        <Tooltip title="Color scheme in the app will follow your devices preferences">
          <SettingsBrightnessTwoToneIcon />
        </Tooltip>
      </ToggleButton>
      <ToggleButton value={MODES.dark} aria-label="dark mode">
        <Tooltip title="Using dark mode always">
          <DarkModeTwoToneIcon />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
