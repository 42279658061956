import { useApiClient } from '@circadian-risk/api-client-provider';
import { useGetAssessmentReportsQuery } from '@circadian-risk/client-graphql-hooks';
import { useNotification } from '@circadian-risk/front-end-utils';
import { QueryHandlerComponentProps, useGqlQueryHandler } from '@circadian-risk/graphql-utils';
import { ROUTES } from '@circadian-risk/routes';
import { useOrganizationId } from '@circadian-risk/stores';
import React, { useEffect } from 'react';
import { generatePath, Redirect, useHistory } from 'react-router-dom';

const generateAssessmentReportUrl = (organizationId: string, assessmentId: number, reportId: number) =>
  generatePath(ROUTES.ASSESSMENTS_GENERATE_REPORT_BY_ID, {
    organizationId,
    assessmentId,
    reportId,
  });

const AssessmentReportGenerationLandingContent: React.FC<
  QueryHandlerComponentProps<typeof useGetAssessmentReportsQuery> & { assessmentId: number }
> = ({ data, assessmentId }) => {
  const organizationId = useOrganizationId();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { push } = useHistory();
  const { tsRestClient } = useApiClient();
  const { displayError } = useNotification();

  useEffect(() => {
    if (!data.reports.length) {
      void (async () => {
        const { status, body } = await tsRestClient.reports.createDraftReport({
          params: {
            organizationId,
            assessmentId,
          },
          body: {},
        });

        if (status === 201) {
          push(generateAssessmentReportUrl(organizationId, body.assessmentId, body.reportId));
        }
      })();
    }
  }, [assessmentId, data, displayError, organizationId, push, tsRestClient.reports]);

  if (data.reports.length) {
    const report = data.reports[0];
    const route = generateAssessmentReportUrl(organizationId, report.assessment_id!, report.id);
    return <Redirect to={route} />;
  }

  return <span>creating</span>;
};

interface AssessmentReportGenerationLandingPageProps {
  assessmentId: number;
}
const AssessmentReportGenerationLandingPage: React.FC<AssessmentReportGenerationLandingPageProps> = ({
  assessmentId,
}) => {
  const organizationId = useOrganizationId();

  const { content } = useGqlQueryHandler(
    useGetAssessmentReportsQuery,
    {
      variables: {
        organizationId,
        assessmentId,
      },
      fetchPolicy: 'network-only',
    },
    props => <AssessmentReportGenerationLandingContent {...props} assessmentId={assessmentId} />,
  );

  return content;
};

export default AssessmentReportGenerationLandingPage;
