/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FormStack, generateCircadianForm, propertyAddressSchema, PropertyFile } from '@circadian-risk/form';
import { HStack, LocationWithIcon, OpenInNewTabLink, ScrollAreaShadow } from '@circadian-risk/presentational';
import { latitudeSchema, longitudeSchema } from '@circadian-risk/shared';
import { Alert, AlertTitle, Button, Collapse, DialogActions, DialogTitle, SxProps } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormState } from 'react-hook-form';
import { z } from 'zod';

import { helperTexts } from '../../ariaLabels';
import { Mode, useLocationWizardStore } from '../../locationWizard.store';
import { WizardLocation, WizardLocationLayer } from '../../types';

const formStyles: React.CSSProperties = {
  height: 'inherit',
  maxHeight: 'inherit',
  minHeight: 'inherit',
  display: 'flex',
  flexDirection: 'column',
};

const FormSchema = z.object({
  locationName: z.string().min(1, "Location name can't be empty"),
  address: propertyAddressSchema,
  useCustomCoordinates: z.boolean(),
  lat: latitudeSchema.optional(),
  long: longitudeSchema.optional(),
  floorPlan: z.custom<PropertyFile>().optional(),
  alreadyHasFloorPlan: z.boolean().optional(),
});

export type LocationFormData = z.infer<typeof FormSchema>;

export interface LocationFormProps {
  mode: Mode & { type: 'editing' | 'adding' };
  layer: WizardLocationLayer;
  parentLocation: WizardLocation;
  defaultValues: LocationFormData;
  createLocationRoute: (locationId: string) => string;
  onSubmit: (data: LocationFormData) => void;
  onCancel: () => void;
}

const { useCircadianForm, ariaLabels, Text, Numeric, Address, Checkbox, File, SubmitButton, CircadianForm } =
  generateCircadianForm(FormSchema, {
    form: 'Edit Location',
    locationName: 'Location Name',
    address: 'Address',
    useCustomCoordinates: 'Use custom coordinates',
    lat: 'Latitude',
    long: 'Longitude',
    floorPlan: 'Floor Plan',
    alreadyHasFloorPlan: 'Has Floor Plan',
    submit: 'Save',
  });

export const FormAriaLabels = ariaLabels;

const ellipsisStyle: SxProps = {
  whiteSpace: 'nowrap',
  '*:last-child': { overflow: 'hidden', whiteSpace: 'nowrap', minWidth: 0, textOverflow: 'ellipsis' },
};

export const LocationForm: React.FC<LocationFormProps> = ({
  mode,
  layer,
  parentLocation,
  defaultValues,
  createLocationRoute,
  onSubmit,
  onCancel,
}) => {
  const { alreadyHasFloorPlan } = defaultValues;
  const hasAddress = defaultValues.address != null && Object.values(defaultValues.address).some(Boolean);
  const isBuilding = layer.semanticName === 'building';
  const isFloor = layer.semanticName === 'level';
  const showAddressDefault = isBuilding || hasAddress || defaultValues.useCustomCoordinates;
  const [showAddress, setShowAddress] = useState(showAddressDefault);
  const [showUpload, setShowUpload] = useState(!alreadyHasFloorPlan && isFloor);
  const formMethods = useCircadianForm({ defaultValues }, { resetFormOnSubmit: true });
  const { resetField, reset } = formMethods;

  const activeLocationId = useLocationWizardStore(({ activeId }) => activeId);

  const watchCustomCoordinatesEnabled = formMethods.watch('useCustomCoordinates');
  const floorPlanState = useFormState({ control: formMethods.control, name: 'floorPlan' });

  useEffect(() => {
    if (!watchCustomCoordinatesEnabled) {
      resetField('long');
      resetField('lat');
    }
  }, [resetField, watchCustomCoordinatesEnabled]);

  useEffect(() => {
    // resetting the form when switching locations
    reset();
    setShowAddress(showAddressDefault);
    setShowUpload(isFloor);
  }, [reset, activeLocationId, showAddressDefault, isFloor]);

  const handleAddAddressClick = () => setShowAddress(true);

  let titleLabel: string;
  let titleElement: React.ReactNode = null;
  if (mode.type === 'adding') {
    titleLabel = `Create a new ${layer.name} at ${parentLocation.name}`;
    titleElement = (
      <HStack component="span" sx={ellipsisStyle}>
        Create a new <LocationWithIcon layerName={layer.name} locationName={layer.name} /> at{' '}
        <LocationWithIcon showTooltip noWrap layerName={parentLocation.layerName} locationName={parentLocation.name} />
      </HStack>
    );
  } else {
    titleLabel = `Edit ${defaultValues.locationName}`;
    titleElement = (
      <HStack overflow="hidden">
        <HStack component="span" sx={ellipsisStyle} overflow="hidden" noFullWidth>
          Edit <LocationWithIcon showTooltip noWrap layerName={layer.name} locationName={defaultValues.locationName} />
        </HStack>
        <OpenInNewTabLink link={createLocationRoute(mode.editingId)} tooltip="Open location details" />
      </HStack>
    );
  }

  return (
    <CircadianForm style={formStyles} onSubmit={onSubmit} formMethods={formMethods}>
      <DialogTitle aria-label={titleLabel}>{titleElement}</DialogTitle>
      <ScrollAreaShadow flex={1}>
        <FormStack stackProps={{ p: 2 }}>
          <Text formKey="locationName" autoFocus />
          {/* TODO(iprokopovich)[CR-1470]: enable autocomplete here */}
          {/* <Text formKey="address" /> */}
          {!showAddress && <Button onClick={handleAddAddressClick}>Add Address</Button>}
          {isBuilding && !hasAddress && <Alert severity="info">{helperTexts.provideAddressNudge}</Alert>}
          <Collapse in={showAddress}>
            <FormStack>
              <Address formKey="address" />
              <Checkbox formKey="useCustomCoordinates" />

              {watchCustomCoordinatesEnabled && (
                <FormStack row>
                  <Numeric formKey="lat" />
                  <Numeric formKey="long" />
                </FormStack>
              )}
            </FormStack>
          </Collapse>

          {alreadyHasFloorPlan && (
            <Alert severity="warning">
              <AlertTitle>This location already has a floor plan</AlertTitle>
              Uploading new one will override it. Click "Open in new tab" at the top right to view the existing floor
              plan.
            </Alert>
          )}

          {!showUpload ? (
            <Button onClick={() => setShowUpload(true)}>Upload Floor Plan</Button>
          ) : (
            <>
              <File organizationId={'not-needed'} formKey="floorPlan" accept="application/pdf,image/jpeg,image/png" />
              {floorPlanState.isDirty && floorPlanState.isValid && (
                <Button onClick={() => resetField('floorPlan')}>Reset Floor Plan</Button>
              )}
            </>
          )}
        </FormStack>
      </ScrollAreaShadow>

      <DialogActions>
        <Button onClick={onCancel} color="error" variant="outlined">
          Cancel
        </Button>
        <SubmitButton />
      </DialogActions>
    </CircadianForm>
  );
};
