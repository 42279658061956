import { FCC } from '@circadian-risk/front-end-utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Collapse, IconButton, Paper, PaperProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';

const PREFIX = 'CollapsibleCard';

const classes = {
  top: `${PREFIX}-top`,
  content: `${PREFIX}-content`,
  iconButton: `${PREFIX}-iconButton`,
};

interface UseStylesProps {
  dense: CollapsibleCardProps['dense'];
  expanded: boolean;
}

const StyledPaper = styled(Paper, {
  shouldForwardProp: propName => !['dense', 'expanded'].includes(propName.toString()),
})<UseStylesProps>(({ theme, dense, expanded }) => ({
  [`& .${classes.top}`]: { borderBottom: `3px solid ${theme.palette.primary.main}` },
  [`& .${classes.content}`]: {
    padding: theme.spacing(2),
    paddingTop: dense ? theme.spacing(0.5) : theme.spacing(2),
    paddingBottom: dense ? theme.spacing(0.5) : theme.spacing(2),
  },
  [`& .${classes.iconButton}`]: {
    transform: expanded ? 'rotate(0deg)' : 'rotate(-90deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

export interface CollapsibleCardProps extends PaperProps {
  title: string;
  dense?: boolean;
  className?: string;
  extras?: React.ReactNode;
  collapsed?: boolean;
}
export const CollapsibleCard: FCC<CollapsibleCardProps> = ({
  dense,
  title,
  children,
  extras,
  collapsed,
  ...paperProps
}) => {
  const [expanded, setExpanded] = useState(collapsed ? false : true);

  const iconButtonSize = dense ? 'small' : 'medium';

  return (
    <StyledPaper {...paperProps} dense={dense} expanded={expanded}>
      <Box display="flex" flexDirection="row" alignItems="center" px={1} className={classes.top}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <IconButton
            size={iconButtonSize}
            onClick={() => setExpanded(!expanded)}
            aria-expanded={expanded}
            aria-label="show more"
            className={classes.iconButton}
          >
            <ExpandMoreIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {title}
          </Typography>
        </Box>
        <Box flex={1} ml={1} display="flex" flexDirection="row" justifyContent="flex-end">
          {extras}
        </Box>
      </Box>

      <Collapse in={expanded}>
        <Box className={classes.content}>{children}</Box>
      </Collapse>
    </StyledPaper>
  );
};
