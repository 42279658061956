import { Theme } from '@mui/material';

export const HIERARCHY_NAV_WIDTH = 300;
export const GRID_SPACING = 2;

export const layoutSx = {
  cardPadding: (theme: Theme) => ({
    padding: `0 ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(2)}`,
  }),
  pageHeaderText: (theme: Theme) => ({
    ...theme.typography.h4,
  }),
  pageWrapperForNavigator: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row', // Fixes some weird animations
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
  },
};
