import { ApolloError } from '@apollo/client';
import {
  GetAssessmentItemsDocument,
  useCompleteAssessmentMutation,
  useGetAssessmentHeaderInfoQuery,
} from '@circadian-risk/client-graphql-hooks';
import { UploadStatus, useFileManagerStore } from '@circadian-risk/file-manager';
import { fileSrc, useNotification } from '@circadian-risk/front-end-utils';
import { useConfirmationDialog, useDialog } from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { AssessmentState, USER_ROLE } from '@circadian-risk/shared';
import { useStandardOrgRoleQueryPrecedence } from '@circadian-risk/stores';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ForwardIcon from '@mui/icons-material/Forward';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Box, Button, CircularProgress, Dialog, DialogTitle, Grid, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import { CircadianHeader, CircadianHeaderAction } from '@web-app/components/CircadianHeader/CircadianHeader';
import NavLinkWithActiveOrgId from '@web-app/components/NavLinkWithActiveOrgId';
import { useHasRole } from '@web-app/lib/useHasRole';
import fromPairs from 'lodash/fromPairs';
import React, { useState } from 'react';
import { VscDebugRestartFrame } from 'react-icons/vsc';
import { generatePath } from 'react-router-dom';

import { useHistoryPushForActiveOrg } from '../../../../../lib/appHelpers';
import { AssessmentCloseDialogContents } from '../AssessmentCloseDialogContents';
import { ReopenAssessmentDialog } from '../ReopenAssessmentDialog';
import { isAssessmentComplete } from './helpers';

export interface ScenarioAssessmentHeaderProps {
  assessmentName: string;
  assessmentUrl: string;
  buttonLabel: string;
  assessmentId: number;
  physicalInspectionProgress: number | null;
  questionnaireProgress: number | null;
  assessmentState: AssessmentState;
  organizationId: string;
}

export const ScenarioAssessmentHeader: React.FC<ScenarioAssessmentHeaderProps> = ({
  assessmentName,
  assessmentUrl,
  buttonLabel,
  assessmentId,
  physicalInspectionProgress,
  questionnaireProgress,
  assessmentState,
  organizationId,
}) => {
  const [reopenAssessment, setReopenAssessment] = useState(false);
  const history = useHistoryPushForActiveOrg();
  const [downloadingReport, setDownloadingReport] = useState(false);
  const [completeAssessment] = useCompleteAssessmentMutation();
  const { data: assessmentReportData } = useGetAssessmentHeaderInfoQuery({
    variables: {
      organizationId,
      assessmentId,
    },
  });
  const { getConfirmation } = useConfirmationDialog();

  const { displayError } = useNotification();
  const {
    headers: { 'x-hasura-role': userRole },
  } = useStandardOrgRoleQueryPrecedence();

  const reportId = assessmentReportData?.reports[0]?.id;
  const reportFilepath = assessmentReportData?.reports[0]?.previews[0]?.file?.filepath ?? null;

  const handleReopenAssessmentClose = () => {
    setReopenAssessment(false);
  };

  const handleDownloadReportClick = async () => {
    setDownloadingReport(true);
    if (!reportId) {
      return;
    }
    try {
      const fileUrl = fileSrc(reportFilepath);
      window.open(fileUrl, '_blank');
    } catch (err) {
      displayError(err as Error);
    }

    setDownloadingReport(false);
  };

  const handleEditReport = () => {
    history.push(`${ROUTES.ASSESSMENTS_GENERATE_REPORT_LANDING.replace(':assessmentId', String(assessmentId))}`);
  };

  const [removeFile, filesToUpload] = useFileManagerStore(state => [
    state.removeFile,
    state.files.filter(f => f.status !== UploadStatus.Completed),
  ]);

  const { isOpen, openDialog, closeDialog } = useDialog();
  const isOrganizationAdmin = useHasRole(USER_ROLE.ORGANIZATION_ADMIN);
  const assessmentProgressComplete = isAssessmentComplete(physicalInspectionProgress, questionnaireProgress);
  const context = useStandardOrgRoleQueryPrecedence();

  const handleReassess = async () => {
    let hasNewTemplate = false;
    let templateInfo: { id: number; name: string };
    const template = assessmentReportData?.v2_assessments_by_pk?.assessmentTemplate ?? null;
    if (!template) {
      return;
    }
    const locationName = assessmentReportData!.v2_assessments_by_pk!.scoredNode!.name;

    if (template?.latestTemplate) {
      templateInfo = template.latestTemplate[0];
      hasNewTemplate = templateInfo.id !== template.id;
    } else {
      templateInfo = template;
    }

    const templateLink = generatePath(ROUTES.SCENARIO_ASSESSMENT_TEMPLATES_DETAIL, {
      organizationId,
      id: templateInfo.id,
    });

    const message: JSX.Element = (
      <Typography>
        Start creating assessment for {locationName} using the{' '}
        {hasNewTemplate ? `latest version of the assessment template` : `same assessment template`},{' '}
        <NavLinkWithActiveOrgId to={templateLink} target="_blank">
          {templateInfo.name}
        </NavLinkWithActiveOrgId>
        ?
      </Typography>
    );

    const confirmed = await getConfirmation({
      title: 'Reassess',
      message,
      confirmButton: {
        text: 'Continue',
      },
    });

    if (confirmed) {
      const params = new URLSearchParams();
      const path = generatePath(ROUTES.ASSESSMENTS_NEW_SINGLE, {
        organizationId,
      });
      params.set('previousAssessment', String(assessmentId));
      params.set('template', String(templateInfo.id));
      history.push(`${path}?${params.toString()}`);
    }
  };

  const isScheduledAssessment = Boolean(assessmentReportData?.v2_assessments_by_pk?.schedule_id);

  const actions: CircadianHeaderAction[] = [];
  if (assessmentState === 'InProgress') {
    actions.push({
      name: 'Close Assessment',
      icon: <HowToVoteIcon />,
      variant: 'outlined',
      color: 'primary',
      tooltip: !assessmentProgressComplete
        ? 'In order to finish this assessment, ensure the Inspection Questionnaire and Physical Inspection are 100% complete'
        : undefined,
      onClick: openDialog,
    });
  } else if (assessmentState === 'Complete' && !isScheduledAssessment) {
    actions.splice(1, 0, {
      name: 'Reassess',
      icon: <AutorenewIcon />,
      variant: 'contained',
      color: 'primary',
      onClick: handleReassess,
      'aria-label': 'start reassessment',
    });
  }

  if (isOrganizationAdmin) {
    if (reportFilepath) {
      actions.splice(1, 0, {
        name: 'Download Report',
        icon: downloadingReport ? <CircularProgress size={24} /> : <SaveAltIcon />,
        disabled: downloadingReport,
        variant: 'contained',
        color: 'primary',
        onClick: handleDownloadReportClick,
        featureFlag: 'report_generator',
        'aria-label': 'download report',
      });
    }

    if (assessmentState === 'Complete' && !isScheduledAssessment) {
      actions.splice(1, 0, {
        name: 'Reopen Assessment',
        icon: <VscDebugRestartFrame />,
        variant: 'contained',
        color: 'primary',
        onClick: () => setReopenAssessment(true),
        featureFlag: 'reopen_assessments',
        'aria-label': 'reopen assessment',
      });
    }

    actions.splice(1, 0, {
      name: 'Edit Report',
      icon: <ArtTrackIcon />,
      variant: 'contained',
      color: 'primary',
      onClick: handleEditReport,
      featureFlag: 'report_generator',
      'aria-label': 'go to report',
    });
  }

  return (
    <Grid item xs={12}>
      <CircadianHeader
        header={
          // adding margin left due to the side-bar button overlapping with text
          <Box ml={2.5}>
            <Typography variant="h4">{assessmentName}</Typography>
          </Box>
        }
        actions={actions}
        actionsNode={
          <a href={assessmentUrl}>
            <Button startIcon={<ForwardIcon />} variant="contained" color="primary">
              {buttonLabel} Assessment
            </Button>
          </a>
        }
      />
      <Dialog open={isOpen} onClose={closeDialog}>
        <DialogTitle>{assessmentName}</DialogTitle>
        <AssessmentCloseDialogContents
          organizationId={organizationId}
          assessmentId={String(assessmentId)}
          canClose={assessmentProgressComplete}
          handleCancel={closeDialog}
          handleCloseAssessment={async () => {
            // Remove files from manager
            for (const file of filesToUpload) {
              await removeFile(file.id);
            }

            const queryVariables = {
              organizationId,
              assessmentId,
            };

            try {
              await completeAssessment({
                variables: {
                  ...queryVariables,
                  // If the user is just an assessor, we shouldn't need to retrieve updated location
                  // scenario data since they should not be able to see it
                  retrieveScenarioInfo:
                    userRole === USER_ROLE.LOCATION_MANAGER || userRole === USER_ROLE.ORGANIZATION_ADMIN,
                },
                refetchQueries: [{ query: GetAssessmentItemsDocument, variables: queryVariables }],
                context,
                optimisticResponse: {
                  update_v2_assessments: {
                    returning: [
                      {
                        __typename: 'v2_assessments',
                        id: assessmentId,
                        state: 'Complete',
                        locations: [],
                      },
                    ],
                  },
                },
              });
            } catch (e) {
              if (e instanceof ApolloError) {
                // We want to make sure that we capture the actual details of the
                // Apollo error so that we can diagnose problems completing an assessment.
                const { stack: _stack, ...err } = e;
                const additionalContext = fromPairs(
                  Object.entries(err).map(([key, val]) => [key, JSON.stringify(val)]),
                );
                Sentry.captureException(e, { extra: additionalContext });
              }
              displayError(
                'There was an error marking this assessment as completed. The Circadian Risk team has been notified.',
              );
            }

            closeDialog();
          }}
        />
      </Dialog>
      <ReopenAssessmentDialog
        open={reopenAssessment}
        onClose={handleReopenAssessmentClose}
        assessmentId={assessmentId}
      />
    </Grid>
  );
};
