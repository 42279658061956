import {
  CustomRepeatFrequencyUnitSchema,
  NotificationTimeUnitType,
  NotificationType,
  TriggerType,
  UnitType,
  WeekdayType,
  WhenType,
} from '@circadian-risk/api-contract';
import { AutocompleteOption } from '@circadian-risk/form';

export const notificationTypeOptions: AutocompleteOption<NotificationType>[] = [
  {
    label: 'Email',
    value: 'email',
  },
  // (v-rrodrigues): This is not supported yet
  // {
  //   label: 'Push notification',
  //   value: 'push',
  // },
];

export const dueAfterUnitOptions: AutocompleteOption<UnitType>[] = [
  {
    label: 'Hours',
    value: 'hour',
  },
  {
    label: 'Days',
    value: 'day',
  },
  {
    label: 'Weeks',
    value: 'week',
  },
];

export const notificationTimeUnitOptions: AutocompleteOption<NotificationTimeUnitType>[] = [
  {
    label: 'Minutes',
    value: 'minute',
  },
  // These options are shared but Notification's can set minutes
  ...dueAfterUnitOptions,
];

export const startSequenceOptions: AutocompleteOption<WhenType>[] = [
  {
    label: 'Before',
    value: 'before',
  },
  {
    label: 'After',
    value: 'after',
  },
];

export const dueSequenceOptions: AutocompleteOption<WhenType>[] = [
  {
    label: 'Before',
    value: 'before',
  },
];

export const triggerOptions: AutocompleteOption<TriggerType>[] = [
  {
    label: 'Start',
    value: 'start',
  },
  {
    label: 'Due',
    value: 'due',
  },
];

export const weekdaysOptions: AutocompleteOption<WeekdayType>[] = [
  {
    label: 'Monday',
    value: 'mon',
  },
  {
    label: 'Tuesday',
    value: 'tues',
  },
  {
    label: 'Wednesday',
    value: 'wed',
  },
  {
    label: 'Thursday',
    value: 'thur',
  },
  {
    label: 'Friday',
    value: 'fri',
  },
  {
    label: 'Saturday',
    value: 'sat',
  },
  {
    label: 'Sunday',
    value: 'sun',
  },
];

export const customRepeatFrequencyUnitOptions: AutocompleteOption<CustomRepeatFrequencyUnitSchema>[] = [
  {
    label: 'Hours',
    value: 'hour',
  },
  {
    label: 'Days',
    value: 'day',
  },
  {
    label: 'Weeks',
    value: 'week',
  },
  {
    label: 'Months',
    value: 'month',
  },
  {
    label: 'Years',
    value: 'year',
  },
];
