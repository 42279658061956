import { useListenOrganizationDashboardSubscription } from '@circadian-risk/client-graphql-hooks';
import { createAgGridSetFilterSearchParams } from '@circadian-risk/data-grid';
import { useIsPhoneScreen } from '@circadian-risk/front-end-utils';
import { CardPlaceholder } from '@circadian-risk/graphql-utils';
import { HomeLocationsCard, HomeSmartAssessmentCard, HomeSmartInherentRiskCard } from '@circadian-risk/home';
import { useLocationWizardModal } from '@circadian-risk/location-wizard';
import { CircularProgress } from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { useOrganizationId } from '@circadian-risk/stores';
import { Box, Container } from '@mui/material';
import { CircadianHeader } from '@web-app/components/CircadianHeader/CircadianHeader';
import { Suspense } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

export const MainDashboardGql = () => {
  const organizationId = useOrganizationId();
  const history = useHistory();

  const { loading, data, error } = useListenOrganizationDashboardSubscription({ variables: { organizationId } });
  const isPhoneScreen = useIsPhoneScreen();
  const openLocationWizard = useLocationWizardModal();

  if (loading) {
    return (
      <Box height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <span>An error has occurred: {error.message}</span>;
  }

  if (!data?.organizations_by_pk) {
    return <span>Organization not found...</span>;
  }

  const onImportScenariosClick = () => history.push(generatePath(ROUTES.SCENARIO_CATALOG_LIST, { organizationId }));

  const onChartOptionClick = (status: string) => {
    const search = createAgGridSetFilterSearchParams('status', [status]);
    history.push({
      pathname: generatePath(ROUTES.ASSESSMENTS, { organizationId }),
      search: `?${search}`,
    });
  };

  const onCreateAssessmentClick = () => history.push(generatePath(ROUTES.ASSESSMENTS_NEW, { organizationId }));

  const onScenarioClick = (id: number) => {
    history.push(generatePath(ROUTES.SCENARIOS_DETAIL, { organizationId, id }));
  };

  const onInherentRiskOverviewClick = () => {
    history.push(generatePath(ROUTES.INHERENT_RISK, { organizationId }));
  };

  const createLocationRoute = (locationId: string) => {
    return generatePath(`${ROUTES.NODES}/${locationId}`, { organizationId });
  };

  const handleLocationWizardClick = () => openLocationWizard({ type: 'viewing' });

  const minCardWidthPx = isPhoneScreen ? '350' : '450';

  return (
    <Box>
      <CircadianHeader header="Dashboard" />
      <Container maxWidth="xl">
        <Box
          display="grid"
          flexWrap="wrap"
          gap={3}
          p={1}
          gridTemplateColumns={{
            xs: '1fr',
            sm: `repeat(auto-fill, minmax(${minCardWidthPx}px, 1fr))`,
          }}
        >
          <HomeLocationsCard onAddLocationClick={handleLocationWizardClick} createLocationRoute={createLocationRoute} />
          <Suspense fallback={<CardPlaceholder title="Inherent Risk" />}>
            <HomeSmartInherentRiskCard
              organizationId={organizationId}
              onScenarioClick={onScenarioClick}
              onOverviewClick={onInherentRiskOverviewClick}
              onImportScenariosClick={onImportScenariosClick}
            />
          </Suspense>
          <Suspense fallback={<CardPlaceholder title="Assessments" />}>
            <HomeSmartAssessmentCard
              organizationId={organizationId}
              onCreateAssessmentClick={onCreateAssessmentClick}
              onImportScenariosClick={onImportScenariosClick}
              onChartOptionClick={onChartOptionClick}
              onAddLocationClick={handleLocationWizardClick}
            />
          </Suspense>
        </Box>
      </Container>
    </Box>
  );
};
