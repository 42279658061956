import { useFormContext } from 'react-hook-form';

import { AssignmentSummary } from '../../../../components';
import { useSelectedLocationIds } from '../../../../hooks/useSelectedLocationIds';
import { CreateScheduledAssessmentFormSchema } from '../../../form';

export const ScheduledSmartAssignmentSummary: React.FC = () => {
  const { watch } = useFormContext<CreateScheduledAssessmentFormSchema>();
  const assignment = watch('assignment');
  const applicableLocationIds = useSelectedLocationIds();

  return <AssignmentSummary assignment={assignment} locationIds={applicableLocationIds} />;
};
