import { AnswerSummaryBar } from '@circadian-risk/assessment-components';
import { useGetTemplateLocationQuestionsAndActiveAnswersQuery } from '@circadian-risk/client-graphql-hooks';
import { LinearProgressWithLabel } from '@circadian-risk/presentational';
import { AssessmentState } from '@circadian-risk/shared';
import { Box, Typography } from '@mui/material';
import { DetailDescription, DetailDescriptionLabel } from '@web-app/components/Details/DetailListing';
import { calculateCompletionConditions } from '@web-app/modules/Assessments/AssessmentForm/LocationScenarioAssessmentForm/ScenarioAssessmentForm/useCompletionConditions';

import { useAssessmentDetailsData } from './hooks/useAssessmentDetailsData';

export const assessmentDetailsCardAriaLabels = {
  PhysicalInspectionProgressNA: 'no inspection questions associated',
  InspectionQuestionnaireProgressNA: 'no questionnaire questions associated',
};

const renderNAFallback = (key: keyof typeof assessmentDetailsCardAriaLabels) => (
  <Typography variant={'caption'} aria-label={assessmentDetailsCardAriaLabels[key]}>
    N/A
  </Typography>
);

type Props = {
  assessmentId: number;
};

export const AssessmentProgress: React.FC<Props> = ({ assessmentId }) => {
  const {
    state,
    assessment_template_id: assessmentTemplateId,
    locations,
    import_active_answers: importActiveAnswers,
    is_compliant_ratification_required,
    is_deficient_ratification_required,
    is_n_a_ratification_required,
    is_n_o_ratification_required,
    progressAggregates,
  } = useAssessmentDetailsData(assessmentId);
  const nodeIds = locations.map(l => l.node_id);

  const questionnaireProgress = progressAggregates?.inspection_progress ?? null;
  const physicalInspectionProgress = progressAggregates?.physical_progress ?? null;

  const { data } = useGetTemplateLocationQuestionsAndActiveAnswersQuery({
    variables: { templateId: Number(assessmentTemplateId), nodeIds },
    skip: assessmentTemplateId == null,
    fetchPolicy: 'network-only',
  });

  const renderSummaryBar = () => {
    const activeAnswersSummary = data?.fn_active_answers_summary ?? [];

    const result = calculateCompletionConditions({
      importActiveAnswers,
      ratificationRequired: {
        compliant: is_compliant_ratification_required,
        deficient: is_deficient_ratification_required,
        n_a: is_n_a_ratification_required,
        n_o: is_n_o_ratification_required,
      },
      answersSummary: activeAnswersSummary,
    });
    return <AnswerSummaryBar {...result} importedTotal={result.importTotal} />;
  };

  if (state === AssessmentState.NotStarted) {
    return renderSummaryBar();
  }

  return (
    <DetailDescription
      id="assessment-progress-bar"
      display="flex"
      flexGrow={1}
      flexDirection="column"
      minWidth={150}
      maxWidth={365}
    >
      <Box>
        <DetailDescriptionLabel>Inspection Questionnaire Progress</DetailDescriptionLabel>
        {questionnaireProgress != null ? (
          <LinearProgressWithLabel value={questionnaireProgress} />
        ) : (
          renderNAFallback('InspectionQuestionnaireProgressNA')
        )}
      </Box>

      <Box>
        <DetailDescriptionLabel>Physical Inspection Progress</DetailDescriptionLabel>
        {physicalInspectionProgress != null ? (
          <LinearProgressWithLabel value={physicalInspectionProgress} />
        ) : (
          renderNAFallback('PhysicalInspectionProgressNA')
        )}
      </Box>
    </DetailDescription>
  );
};
