import { ValueFormatterParams } from '@ag-grid-community/core';
import { AlignCell, CellRendererParams, ColDefOfType, dateFormatter } from '@circadian-risk/data-grid';
import { NodeIcon } from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { useOrganizationId, useOrganizationSessionStore } from '@circadian-risk/stores';
import CheckIcon from '@mui/icons-material/Check';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Button, useTheme } from '@mui/material';
import { generatePath, NavLink } from 'react-router-dom';

import { ReviewLocationDetailsRow } from './review-location-details-types';

const numericMaxWidth = 140;

const numericFormatter = (params: ValueFormatterParams) => {
  if (params.value === null) {
    return '-';
  }
  return params.value;
};

export const LocationCellRenderer = ({ data: { layer_id, name } }: CellRendererParams<ReviewLocationDetailsRow>) => {
  const layersById = useOrganizationSessionStore(state => state.layersById);
  const layerName = layersById[layer_id]?.name;
  return (
    <Box display="flex" alignItems={'center'}>
      {layerName && <NodeIcon fontSize="small" layerName={layerName} />}
      <Box ml={0.5}>{name}</Box>
    </Box>
  );
};

const MapCellRenderer = (params: CellRendererParams<ReviewLocationDetailsRow>) => {
  const theme = useTheme();
  const organizationId = useOrganizationId();
  const to = generatePath(ROUTES.NODES_DETAIL, { id: params.data.id, organizationId });

  if (params.data.hasMap) {
    return (
      <AlignCell>
        <Box
          style={{ color: theme.palette.info.main, fontSize: '14px', textTransform: 'uppercase' }}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <CheckIcon />
          <span>Map</span>
        </Box>
      </AlignCell>
    );
  }
  return (
    <AlignCell>
      <NavLink to={to} target="_blank">
        <Button variant="outlined">
          <span>Add a Map</span>
          <OpenInNewIcon />
        </Button>
      </NavLink>
    </AlignCell>
  );
};

const DetailsCellRenderer = (params: CellRendererParams<ReviewLocationDetailsRow>) => {
  const organizationId = useOrganizationId();
  const to = generatePath(ROUTES.NODES_DETAIL, { id: params.data.id, organizationId });

  return (
    <AlignCell>
      <NavLink to={to} target="_blank">
        <Button variant="outlined">
          <span>View</span>
          <OpenInNewIcon />
        </Button>
      </NavLink>
    </AlignCell>
  );
};

export const columnDefs: ColDefOfType<ReviewLocationDetailsRow>[] = [
  {
    field: 'lastAssessed',
    headerName: 'Last Assessed',
    valueFormatter: dateFormatter,
    filter: 'agDateColumnFilter',
  },
  {
    field: 'questionnaireItems',
    headerName: 'Questionnaire Items',
    valueFormatter: numericFormatter,
    resizable: false,
    maxWidth: numericMaxWidth,
    cellClass: ['ag-cell-questionnaire-colored', 'ag-cell-text-center'],
    headerClass: ['ag-cell-questionnaire-colored', 'ag-cell-text-center'],
  },
  {
    field: 'questionnaireQuestions',
    headerName: 'Questionnaire Questions',
    valueFormatter: numericFormatter,
    resizable: false,
    maxWidth: numericMaxWidth,
    cellClass: ['ag-cell-questionnaire-colored', 'ag-cell-text-center'],
    headerClass: ['ag-cell-questionnaire-colored', 'ag-cell-text-center'],
  },
  {
    field: 'questionnaireProperties',
    headerName: 'Questionnaire Properties',
    valueFormatter: numericFormatter,
    resizable: false,
    maxWidth: numericMaxWidth,
    cellClass: ['ag-cell-questionnaire-colored', 'ag-cell-text-center'],
    headerClass: ['ag-cell-questionnaire-colored', 'ag-cell-text-center'],
  },
  {
    field: 'inspectionItems',
    headerName: 'Inspection Items',
    valueFormatter: numericFormatter,
    resizable: false,
    maxWidth: numericMaxWidth,
    cellClass: ['ag-cell-inspection-colored', 'ag-cell-text-center'],
    headerClass: ['ag-cell-inspection-colored', 'ag-cell-text-center'],
  },
  {
    field: 'inspectionQuestions',
    headerName: 'Inspection Questions',
    valueFormatter: numericFormatter,
    resizable: false,
    maxWidth: numericMaxWidth,
    cellClass: ['ag-cell-inspection-colored', 'ag-cell-text-center'],
    headerClass: ['ag-cell-inspection-colored', 'ag-cell-text-center'],
  },
  {
    field: 'inspectionProperties',
    headerName: 'Inspection Properties',
    valueFormatter: numericFormatter,
    resizable: false,
    maxWidth: numericMaxWidth,
    cellClass: ['ag-cell-inspection-colored', 'ag-cell-text-center'],
    headerClass: ['ag-cell-inspection-colored', 'ag-cell-text-center'],
  },
  {
    field: 'hasMap',
    headerName: 'Map',
    headerClass: 'ag-cell-text-center',
    cellRenderer: MapCellRenderer,
  },
  {
    field: 'id',
    cellRenderer: DetailsCellRenderer,
    headerName: 'Details',
    headerClass: 'ag-cell-text-center',
    flex: 0,
    filter: false,
  },
];
