import { ColumnEvent, ColumnState, FirstDataRenderedEvent } from '@ag-grid-community/core';
import * as Sentry from '@sentry/react';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { compareAndSetModel } from '../utils';
import { ColStateParam } from './constants';

const COL_STATE_KEYS: (keyof ColumnState)[] = [
  'colId',
  'sort',
  'sortIndex',
  'hide',
  'pivot',
  'pivotIndex',
  'rowGroup',
  'rowGroupIndex',
  'aggFunc',
];

export const useColumnState = () => {
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const colStateParam = query.get(ColStateParam);

  const onFirstDataRendered = useCallback(
    ({ columnApi }: FirstDataRenderedEvent) => {
      if (!colStateParam) {
        return;
      }
      try {
        const urlObj = JSON.parse(colStateParam);
        // extract current column state with relevant properties
        const currentColumnState = columnApi.getColumnState().map(col => pick(col, COL_STATE_KEYS));
        if (!isEqual(urlObj, currentColumnState)) {
          columnApi.applyColumnState({ state: urlObj });
        }
      } catch (ex) {
        Sentry.captureException(ex);
      }
    },
    [colStateParam],
  );

  const onColumnChange = useCallback(
    ({ columnApi }: ColumnEvent) => {
      const columnState = columnApi.getColumnState();
      const mappedColumnState = columnState.map(col => pick(col, COL_STATE_KEYS));
      compareAndSetModel(ColStateParam, history, colStateParam, JSON.stringify(mappedColumnState));
    },
    [colStateParam, history],
  );

  return { onColumnChange, onFirstDataRendered };
};
