import { useReopenAssessmentMutation } from '@circadian-risk/client-graphql-hooks';
import { useNotification } from '@circadian-risk/front-end-utils';
import { useOrganizationId } from '@circadian-risk/stores';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useState } from 'react';

interface ReopenAssessmentDialogProps {
  open: boolean;
  onClose: () => void;
  assessmentId: number;
}

export const ReopenAssessmentDialog: React.FC<ReopenAssessmentDialogProps> = ({ open, onClose, assessmentId }) => {
  const [reopening, setReopening] = useState(false);
  const { displayError, displaySuccess } = useNotification();
  const organizationId = useOrganizationId();
  const [reopenAssessment] = useReopenAssessmentMutation();

  const handleReopening = async () => {
    try {
      setReopening(true);
      await reopenAssessment({
        variables: {
          organizationId,
          assessmentId,
        },
      });
      displaySuccess('Assessment re-opened succesfully.');
      onClose();
    } catch (e) {
      displayError(e);
    } finally {
      setReopening(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Reopen Assessment</DialogTitle>
      <DialogContent>Would you like to reopen this assessment?</DialogContent>
      <DialogActions>
        <Button variant={'text'} color={'secondary'} onClick={onClose}>
          Cancel
        </Button>
        <Button variant={'contained'} color={'primary'} onClick={handleReopening} disabled={reopening}>
          Reopen
        </Button>
      </DialogActions>
    </Dialog>
  );
};
