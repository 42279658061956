import { useMutation } from '@apollo/client';
import { CreateAssessmentFormGroupHeader } from '@circadian-risk/assessment-components';
import { FormStack } from '@circadian-risk/form';
import { Bold, HStack, useDialog, VStack } from '@circadian-risk/presentational';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import RoomIcon from '@mui/icons-material/Room';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { SaveLocationFilterDocument } from '../../../graphql/typed-nodes';
import { CreateLocationFilterDialog, CreateLocationFilterSchema } from '../../../LocationFilter';
import { RemoteLocationFilterSchema } from '../../../LocationFilter/types';
import { CreateScheduledAssessmentFormSchema, LocationPickerType } from '../../form';
import { AssessmentTemplateFormField } from './form-fields/AssessmentTemplateFormField';
import { DefaultLocationFormField } from './form-fields/DefaultLocationFormField';
import { LocationFilterFormField } from './form-fields/LocationFilterFormField';
import { LocationsAdditionalInformation } from './LocationsAdditionalInformation';

export const RootStep: React.FC = () => {
  const { setValue, watch } = useFormContext<CreateScheduledAssessmentFormSchema>();
  const { isOpen, openDialog, closeDialog } = useDialog();
  const locationOption = watch('locationPickerType');
  const [saveLocationFilter] = useMutation(SaveLocationFilterDocument);

  const handleFilterSave = async (filterState: CreateLocationFilterSchema) => {
    const mappedFilterObject: RemoteLocationFilterSchema = {
      includeChildren: filterState.includeChildren,
      baseLayerId: filterState.baseLayerId,
      belongsToId: filterState.belongsToId,
      tagIds: filterState.tagIds,
      filter: { type: 'none' },
    };

    await saveLocationFilter({
      variables: {
        filterObj: mappedFilterObject,
        name: filterState.name,
        description: filterState.description,
      },
    });
  };

  return (
    <>
      <FormStack>
        <Typography sx={{ mb: 2 }}>Location(s) and Template</Typography>
        <CreateAssessmentFormGroupHeader title="Choose location(s) and a template" />
        <AssessmentTemplateFormField />
        <VStack>
          <Bold>
            <Typography>Locations</Typography>
          </Bold>
          <Typography>Select all location that apply to this scenario assessment</Typography>
        </VStack>
        <HStack>
          <ToggleButtonGroup
            fullWidth
            color="primary"
            value={locationOption}
            exclusive
            onChange={(_e, value) => {
              // Prevent the user from unselecting the current option
              // or resetting the fields if the user selects the same option
              if (value === null || value === locationOption) {
                return;
              }

              setValue('locationPickerType', value);
              // Reset the values if the user changes the location picker type
              setValue('locationsIds', []);
              setValue('locationFilterId', null);
            }}
          >
            <ToggleButton value={LocationPickerType.Default} disableRipple>
              <RoomIcon />
              <Typography>Select a location</Typography>
            </ToggleButton>
            <ToggleButton value={LocationPickerType.LocationFilter} disableRipple>
              <FilterAltIcon />
              <Typography>Use location filter</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </HStack>
        {locationOption === LocationPickerType.Default ? (
          <DefaultLocationFormField />
        ) : (
          <LocationFilterFormField onCreate={openDialog} />
        )}
        <LocationsAdditionalInformation />
      </FormStack>
      <CreateLocationFilterDialog open={isOpen} onClose={closeDialog} onSave={handleFilterSave} />
    </>
  );
};
