import { HStack, ImportIcon, SelectableBox } from '@circadian-risk/presentational';
import { humanFormatDateTime } from '@circadian-risk/shared';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';

import { Question } from './Question';
import { QuestionProps } from './question.types';

export type ReImportQuestionProps = Pick<
  QuestionProps,
  | 'questionText'
  | 'badResponse'
  | 'standards'
  | 'files'
  | 'criticality'
  | 'answeredAt'
  | 'answeredBy'
  | 'value'
  | 'importedData'
  | 'optionsSectionProps'
>;

export interface ReImportQuestionDialogProps {
  activeQuestionState: ReImportQuestionProps;
  assessmentQuestionState: ReImportQuestionProps;
  open: boolean;
  onClose: () => void;
  onSubmit: () => Promise<void>;
}

const selectableBoxSx: SxProps = { flex: 1 };
export const ReImportQuestionDialog: React.FC<ReImportQuestionDialogProps> = ({
  open,
  activeQuestionState,
  assessmentQuestionState,
  onClose,
  onSubmit,
}) => {
  const [selected, setSelected] = useState<'previous' | 'current' | null>('previous');
  const [submitting, setSubmitting] = useState(false);

  const theme = useTheme();

  const submitProxy = async () => {
    setSubmitting(true);
    await onSubmit();
    setSubmitting(false);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        <HStack justifyContent="space-between">
          <Typography variant="h6">Import Answer</Typography>
          <IconButton onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </HStack>
      </DialogTitle>
      <DialogContent>
        <HStack justifyContent="center" alignItems="flex-start" flexWrap="wrap" spacing={3}>
          <Box display="flex" flex={1} flexDirection="column">
            <Typography variant="h6">
              <HStack spacing={0.5}>
                <ImportIcon fontSize="inherit" />
                <span>Answer from </span>
                <Box component="span" color={theme.palette.text.secondary}>
                  {humanFormatDateTime(activeQuestionState.answeredAt ?? '')}
                </Box>
              </HStack>
            </Typography>
            <SelectableBox
              active={selected === 'previous'}
              onClick={() => setSelected('previous')}
              sx={selectableBoxSx}
            >
              <Question {...activeQuestionState} isActionRequired disabled />
            </SelectableBox>
          </Box>

          <Box display="flex" flex={1} flexDirection="column">
            <Typography variant="h6">Answer from this Assessment</Typography>
            <SelectableBox active={selected === 'current'} onClick={() => setSelected('current')} sx={selectableBoxSx}>
              <Question {...assessmentQuestionState} disabled />
            </SelectableBox>
          </Box>
        </HStack>
      </DialogContent>
      <DialogActions>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          {selected === 'previous' ? (
            <LoadingButton
              color="error"
              loading={submitting}
              endIcon={<ImportIcon />}
              disabled={!selected}
              onClick={submitProxy}
            >
              Import Answer
            </LoadingButton>
          ) : (
            <Button color="primary" disabled={!selected} onClick={onClose}>
              Keep Current
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};
