import { Box, BoxProps } from '@mui/material';

interface NoDataPlaceholderImageProps {
  imgSrc: string;
  ariaLabel: string;
}

export const NoDataPlaceholderImage: React.FC<NoDataPlaceholderImageProps & BoxProps> = ({
  imgSrc,
  ariaLabel,
  ...boxProps
}) => {
  return (
    <Box display="flex" sx={{ width: '100%', ...boxProps.sx }} {...boxProps}>
      <Box
        aria-label={ariaLabel}
        component="img"
        src={imgSrc}
        alt={`placeholder ${ariaLabel}`}
        sx={{ margin: 'auto', opacity: '75%', width: '100%', height: '100%', p: 4, maxWidth: 350 }}
      />
    </Box>
  );
};
