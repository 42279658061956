import { PropertyDescriptionRichText } from '@circadian-risk/presentational';
import { SxProps } from '@mui/material';
import React from 'react';

import { CellRendererParams } from '../helpers';

const sx: SxProps = {
  paddingTop: '5px',
  paddingBottom: '5px',
  lineHeight: '1.5rem',
};

export const RichTextCellRenderer: React.FC<CellRendererParams<{ value: string | null }>> = ({ value }) => {
  return <PropertyDescriptionRichText value={value} sx={sx} />;
};
