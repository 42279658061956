import {
  OptionForConsiderationIcon,
  QuestionMoreInfoStatusIcons,
  QuestionMoreInfoStatusIconsMap,
} from '@circadian-risk/presentational';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button, List, ListItemButton, ListItemText, Popover, useTheme } from '@mui/material';
import React, { useRef, useState } from 'react';

import { AnswerFormName } from '../../AnswerFormData';
import { useActiveFields, useMoreInfoState } from '../../hooks';
import { ActiveFieldsState, QuestionProps, SharedQuestionComponentProps } from '../../question.types';
import { QuestionMoreInfoPanel, QuestionMoreInfoPanelProps } from '../QuestionMoreInfoPanel';
import { questionActiveFieldsAriaLabels } from './ariaLabels';
import { BottomRowWithLessMore } from './BottomRowWithLessMore';
import { PopoverAction } from './PopoverAction';

export type QuestionActiveFieldsInteractiveProps = SharedQuestionComponentProps &
  Pick<QuestionProps, 'value' | 'files' | 'standards'> & {
    optionsSectionProps: QuestionProps['optionsSectionProps'];
    addMoreInfoDisabled: boolean;
    disabledStates: QuestionMoreInfoPanelProps['disabledStates'];
  };

export const QuestionActiveFieldsInteractive: React.FC<QuestionActiveFieldsInteractiveProps> = ({
  handleChange,
  onAddFiles,
  addMoreInfoDisabled,
  onImageProcessing,
  value,
  files,
  standards,
  disabledStates,
  optionsSectionProps,
}) => {
  const [addMoreOpen, setAddMoreOpen] = useState(false);

  const { activeFields, setActiveField } = useActiveFields(value, files.length, optionsSectionProps?.options ?? []);
  const { moreInfoOpen, setMoreInfoOpen, toggle, hasMoreInfo } = useMoreInfoState({
    activeFields,
    standards,
  });

  const addMoreInfoRef = useRef<HTMLButtonElement | null>(null);
  const theme = useTheme();

  const ofcActions = optionsSectionProps.actions;

  return (
    <>
      <BottomRowWithLessMore
        open={moreInfoOpen}
        toggle={toggle}
        hasMoreInfo={hasMoreInfo}
        boxProps={{ 'aria-label': questionActiveFieldsAriaLabels.Interactive }}
        leftSlot={
          <>
            <QuestionMoreInfoStatusIcons activeFields={activeFields} />
            {!addMoreInfoDisabled && (
              <Button
                size="small"
                ref={addMoreInfoRef}
                onClick={() => setAddMoreOpen(prev => !prev)}
                startIcon={<AddCircleOutlineIcon htmlColor={theme.palette.primary.main} />}
                sx={theme => ({
                  color: theme.palette.primary.main,
                })}
              >
                Add Info
              </Button>
            )}
          </>
        }
      />

      <Popover
        open={addMoreOpen && !addMoreInfoDisabled}
        anchorEl={addMoreInfoRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() => setAddMoreOpen(false)}
      >
        <Box display="flex" flexDirection="column">
          <List dense>
            {Object.entries(activeFields).map(([name, value]) => {
              const fieldName = name as keyof ActiveFieldsState;
              const { icon: Icon, label } = QuestionMoreInfoStatusIconsMap[fieldName];
              const isFileField = name === 'files';
              const isOFC = name === 'options';
              const ofcDisabled = isOFC && disabledStates.ofcWithTooltip;

              const disabled = ofcDisabled ?? Boolean(value && !isFileField);

              const handleClick = () => {
                if (isFileField && onAddFiles) {
                  onAddFiles();
                }

                // File field should read always from the images uploaded
                if (!isFileField) {
                  setActiveField(fieldName, true);
                }

                if (isOFC && optionsSectionProps.actions) {
                  optionsSectionProps.actions.onNewCustom();
                }

                setAddMoreOpen(false);
                setMoreInfoOpen(true);
              };

              return (
                <React.Fragment key={name}>
                  <PopoverAction
                    key={name}
                    name={label}
                    icon={<Icon />}
                    disabled={disabled}
                    tooltip={ofcDisabled ? 'Response required first' : undefined}
                    onClick={handleClick}
                  />

                  {isOFC && ofcActions && (
                    <PopoverAction
                      key={'Option for Consideration from Library'}
                      name={'Option for Consideration from Library'}
                      icon={<OptionForConsiderationIcon />}
                      disabled={ofcDisabled}
                      tooltip={ofcDisabled ? 'Response required first' : undefined}
                      onClick={() => {
                        ofcActions.onAddFromLibrary();
                        setActiveField(AnswerFormName.options, true);
                        setAddMoreOpen(false);
                        setMoreInfoOpen(true);
                      }}
                    />
                  )}
                </React.Fragment>
              );
            })}

            <ListItemButton onClick={() => setAddMoreOpen(false)} alignItems="center">
              <ListItemText primary="Close" sx={{ textAlign: 'center' }} />
            </ListItemButton>
          </List>
        </Box>
      </Popover>

      <QuestionMoreInfoPanel
        handleChange={handleChange}
        onImageProcessing={onImageProcessing}
        onAddFiles={onAddFiles}
        expanded={moreInfoOpen}
        files={files}
        activeFields={activeFields}
        setActiveField={setActiveField}
        value={value!}
        standards={standards}
        disabledStates={disabledStates}
        optionsSectionProps={optionsSectionProps}
      />
    </>
  );
};
