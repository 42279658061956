import { useEffect, useState } from 'react';

import { ScenarioLinkInfo, TemplateData } from './ScenarioAssessmentForm.types';

export const useSelectedScenarios = (id: number | null, templates: TemplateData[]) => {
  const [selectedScenarios, setSelectedScenarios] = useState<ScenarioLinkInfo[] | null>(null);

  useEffect(() => {
    if (id) {
      const template = templates.find(t => t.id === id);
      if (!template) {
        return;
      }
      const scenarioLinks = template.scenarios.map(sc => ({ id: sc.id, name: sc.name }));

      setSelectedScenarios(scenarioLinks);
    } else {
      setSelectedScenarios(null);
    }
  }, [templates, id]);

  return selectedScenarios;
};
