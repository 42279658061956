import { FormStack } from '@circadian-risk/form';
import { HStack, VStack } from '@circadian-risk/presentational';
import { useOrganizationSessionStore } from '@circadian-risk/stores';
import { Box, styled, Typography } from '@mui/material';
import minBy from 'lodash/minBy';
import { SubmitHandler } from 'react-hook-form';

import { SmartApplicableLocations } from './ApplicableLocations';
import { createLocationFilterForm } from './create-location-filter.form';
import { CreateLocationFilterSchema } from './create-location-filter.schema';
import { BaseLayerFormField } from './form-fields/BaseLayerFormField';
import { BelongsToFormField } from './form-fields/BelongsToFormField';
import { TagsFormField } from './form-fields/TagsFormField';

const { Text, TextArea, useCircadianForm, GenericFormDialog, Checkbox } = createLocationFilterForm;

const BorderedBox = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  padding: theme.spacing(2),
  borderRadius: '8px',
  border: `1px solid ${theme.palette.secondary.background}`,
}));

type CreateLocationFilterDialogProps = {
  open: boolean;
  onClose: () => void;
  onSave: SubmitHandler<CreateLocationFilterSchema>;
};

export const CreateLocationFilterDialog: React.FC<CreateLocationFilterDialogProps> = ({ open, onClose, onSave }) => {
  const layers = useOrganizationSessionStore(state => state.layers);

  const formMethods = useCircadianForm({
    defaultValues: {
      name: '',
      description: null,
      includeChildren: false,
      // Defines the base layer for the filter
      baseLayerId: minBy(layers, l => l.layerDepth)?.id,
      tagIds: [],
      belongsToId: null,
    },
    shouldUnregister: true,
  });

  return (
    <GenericFormDialog
      title={
        <VStack>
          <Typography variant="h5">Create Location Filter</Typography>
          <Typography color="secondary" variant="caption">
            Create Location Filter Location filters are preset filter criteria that allow you to define a set of
            locations in your organization at once.
          </Typography>
        </VStack>
      }
      isOpen={open}
      onClose={onClose}
      dialogProps={{
        fullWidth: true,
        maxWidth: 'lg',
      }}
      showCancel={false}
      formProps={{ formMethods, onSubmit: onSave }}
    >
      <HStack alignItems={'flex-start'}>
        <BorderedBox>
          <FormStack>
            <Text formKey="name" />
            <TextArea formKey="description" />
            <BaseLayerFormField />
            <Checkbox formKey="includeChildren" />
            <BelongsToFormField />
            <TagsFormField />
          </FormStack>
        </BorderedBox>

        <BorderedBox>
          <SmartApplicableLocations />
        </BorderedBox>
      </HStack>
    </GenericFormDialog>
  );
};
