/* eslint-disable @typescript-eslint/no-explicit-any */
export const readFileAsString = async (file: Blob) =>
  new Promise<string>(resolve => {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      if (event) {
        const { result } = event.target;
        return resolve(result);
      }
    };
    reader.readAsText(file);
  });
