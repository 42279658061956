import { useTheme } from '@mui/material';
import sortBy from 'lodash/sortBy';
import { useMemo } from 'react';

import { Highlighter, IHighlightConfig } from './Highlighter';

export type HighlightedIndices = readonly [number, number][];
export interface HighlightsProps {
  indices: HighlightedIndices;
  markerColor?: string;
  zIndex?: number;
}

interface SpanWithHighlightsProps {
  highlights?: HighlightsProps[];
  children: string;
}

export const SpanWithHighlights: React.FC<SpanWithHighlightsProps> = ({ highlights, children }) => {
  const {
    palette: {
      primary: { main: defaultColor },
    },
  } = useTheme();

  const highlightsConfig = useMemo(() => {
    const sortedHighlightedText = sortBy(
      (highlights || []).map(h => {
        const zIndex = h.zIndex || 1;
        const markerColor = h.markerColor || defaultColor;

        return {
          zIndex,
          markerColor,
          indices: h.indices,
        };
      }),
      z => z.zIndex,
    );

    const config = sortedHighlightedText.reduce((acc, highlight) => {
      const { indices, markerColor } = highlight;
      acc.push({
        ranges: indices.map(([start, end]) => [start, end + 1]),
        style: {
          backgroundColor: 'unset',
          color: 'unset',
          borderBottomWidth: '2px',
          borderBottomStyle: 'solid',
          borderBottomColor: markerColor,
          fontWeight: 'bold',
        },
      });
      return acc;
    }, [] as IHighlightConfig[]);

    return config;
  }, [defaultColor, highlights]);

  return <Highlighter config={highlightsConfig} caseSensitive text={children} key={JSON.stringify(highlights)} />;
};
