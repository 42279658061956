import { Step, StepContent, StepLabel, Stepper } from '@mui/material';
import React from 'react';

import { CreateAssessmentFormStepperConfig } from './stepsConfig';

type CreateAssessmentFormStepperProps = {
  activeStep: number;
  steps: CreateAssessmentFormStepperConfig[];
};

export const CreateAssessmentFormStepper: React.FC<CreateAssessmentFormStepperProps> = ({ activeStep, steps }) => {
  return (
    <Stepper
      activeStep={activeStep}
      orientation={'vertical'}
      sx={{
        '&.MuiStepper-root': {
          background: 'transparent',
        },
      }}
    >
      {steps.map(sc => (
        <Step key={sc.title} expanded>
          <StepLabel>{sc.stepper?.titleOverride ?? sc.title}</StepLabel>
          {sc.stepper?.subtitle && <StepContent>{<sc.stepper.subtitle activeStep={activeStep} />}</StepContent>}
        </Step>
      ))}
    </Stepper>
  );
};
