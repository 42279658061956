import { FormControl, FormHelperText } from '@mui/material';
import { FieldValues, useFormContext } from 'react-hook-form';

import { PropertyFileFieldControlled, PropertyFileFieldControlledProps } from '../../FileAttachments';
import { PropertyFieldProps } from '../../PropertyFields';
import { useCircadianFormContext } from '../CircadianFormContext';
import { FormField } from './types';
import { useErrorMessage } from './utils';

export type FileFFProps<FormData> = FormField<FormData> &
  Omit<PropertyFieldProps, 'control' | 'htmlName' | 'label'> &
  Pick<
    PropertyFileFieldControlledProps,
    'organizationId' | 'inputProps' | 'uploadFn' | 'deleteFn' | 'helperText' | 'disableDownload'
  > & {
    accept?: string;
  };

export function FileFF<FormData extends FieldValues>({
  formKey,
  helperText,
  organizationId,
  accept,
  ...rest
}: FileFFProps<FormData>) {
  const { control, formState } = useFormContext<FormData>();
  const { ariaLabels, disabled } = useCircadianFormContext<FormData>();

  const errorMessage = useErrorMessage(formState.errors, formKey);

  return (
    <FormControl>
      <PropertyFileFieldControlled
        {...{
          disabled,
          control,
          errorMessage,
          organizationId,
          ...rest,
        }}
        label={ariaLabels[formKey]}
        htmlName={formKey}
        inputProps={{ accept }}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
