import { ICellEditor, ICellEditorParams } from '@ag-grid-community/core';
import { Input, InputBaseComponentProps } from '@mui/material';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';

const inputProps: InputBaseComponentProps = { min: 0, style: { textAlign: 'right' } };

export const NumericCellEditor = React.forwardRef<ICellEditor, ICellEditorParams>((props, ref) => {
  const [value, setValue] = useState(props.value);
  const refInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // focus on the input
    setTimeout(() => {
      if (refInput.current) {
        refInput.current.focus();
      }
    });
  }, []);

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue: () => {
        return Number(value);
      },

      // Gets called once before editing starts, to give editor a chance to
      // cancel the editing before it even starts.
      isCancelBeforeStart: () => {
        return false;
      },

      // Gets called once when editing is finished (eg if enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd: () => {
        return false;
      },
    };
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <Input
      type="number"
      inputRef={refInput}
      value={value ?? ''}
      onChange={handleChange}
      inputProps={inputProps}
      style={{ width: '100%' }}
    />
  );
});
