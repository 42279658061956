import { searchTree } from './treeHelpers';

interface LocationNode {
  id: string;
  parent_id: string | null;
  selectable: boolean;
  children?: LocationNode[];
}

/**
 * Filters tree-like data to only include minimal requred data based on permissions (selectable boolean).
 *
 * If a leaf node is not accessible since none of the parents are accessible, it will be removed from the tree.
 */
export const getLocationTableValidLocationIds = (location: LocationNode): Set<string> => {
  const validIds = new Set<string>();

  if (!location.selectable && (!location.children || location.children.length === 0)) {
    return validIds;
  }

  if (location.selectable) {
    validIds.add(location.id);
  }

  if (location.children) {
    for (const child of location.children) {
      // Skip if it's not selectable and does not have any children
      if (!child.selectable && (!child.children || child.children.length === 0)) {
        continue;
      }

      // Skip if there's no selectable items and the child is not even selectable
      if (!searchTree(child, n => n.selectable) && !child.selectable) {
        continue;
      }
      const validChildrenIds = getLocationTableValidLocationIds(child);

      // Add the top parent because we have children
      validIds.add(location.id);

      validChildrenIds.forEach(id => {
        validIds.add(id);
      });

      validIds.add(child.id);
    }
  }

  return validIds;
};
