import dayjs from 'dayjs';
import dayjsAdvancedFormat from 'dayjs/plugin/advancedFormat';
import dayjsDuration from 'dayjs/plugin/duration';
import dayjsRelativeTime from 'dayjs/plugin/relativeTime';
import dayjsTimezone from 'dayjs/plugin/timezone';
import dayjsUtc from 'dayjs/plugin/utc';
import isBoolean from 'lodash/isBoolean';

dayjs.extend(dayjsRelativeTime);
dayjs.extend(dayjsDuration);
dayjs.extend(dayjsTimezone);
dayjs.extend(dayjsUtc);
dayjs.extend(dayjsAdvancedFormat);

/**
 * @see https://day.js.org/docs/en/display/format
 */
export enum DateFormat {
  /**
   * 2020-01-05
   */
  YEAR_MONTH_DAY = 'YYYY-MM-DD',
  /**
   * 2020-01-05 10:00
   */
  YEAR_MONTH_DAY_WITH_TIME = 'YYYY-MM-DD hh:mm',
  /**
   * Jan 5th, 2020
   */
  MONTH_DAY_YEAR = 'MMM D, YYYY',
  /**
   * 01/05/2020
   */
  MONTH_DAY_YEAR_RAW = 'MM/DD/YYYY',

  /**
   * Jan 5th, 2020, 10:00 PM
   */
  MONTH_DAY_YEAR_WITH_TIME = 'MMM D, YYYY, h:mm A',

  /**
   * Jan 5th, 2020, 10:00PM EST
   */
  MONTH_DAY_YEAR_WITH_TIME_AND_TIMEZONE = 'MMM D, YYYY, h:mm A z',
  /**
   * 20:00
   */
  HOUR_AND_MINUTE = 'HH:mm',
  /**
   * 8:00 pm
   */
  HOUR_AND_MINUTE_AM_PM = 'hh:mm a',
  /**
   * Saturday or Monday
   */
  FULL_WEEKDAY = 'dddd',
  /**
   * 7/15
   */
  MONTH_DAY = 'M/DD',
}

type OverloadedHumanDateTimeFormatter = {
  /**
   * Overload signature offers an enum stored format as an arg
   */
  (date: string | Date, format: DateFormat): string;

  /**
   * Old signature just offers includeTime as an optional boolean
   */
  (date: string | Date, includeTime?: boolean): string;
};

/**
 * Formats a Date or date string into a human-readable format
 */
export const humanFormatDateTime: OverloadedHumanDateTimeFormatter = (
  str: string | Date,
  includeTimeOrDateFormat = true,
): string => {
  const thisDate = dayjs(str);

  if (!thisDate.isValid) {
    return String(str);
  }

  let format = DateFormat.MONTH_DAY_YEAR;
  if (isBoolean(includeTimeOrDateFormat)) {
    if (includeTimeOrDateFormat) {
      format = DateFormat.MONTH_DAY_YEAR_WITH_TIME;
    }
  } else {
    format = includeTimeOrDateFormat;
  }

  return thisDate.format(format);
};

/**
 * This function will calculate delta between two dates and output in a human friendly way:
 * now, 1 minute ago, yesterday, last month, 2 month ago, etc.
 *
 * If second parameter is omitted, it will use local time instead.**
 *
 * @param eventTime first date
 * @param secondEventTime second date (if not provided, will use local time)
 *
 */
export const timeAgo = (eventTime: string | Date, secondEventTime?: string | Date) => {
  return dayjs(secondEventTime).to(dayjs(eventTime));
};
