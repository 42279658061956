import { createEnhancedStore } from '@circadian-risk/front-end-utils';
import turfBbox from '@turf/bbox';
import { points } from '@turf/helpers';
import noop from 'lodash/noop';
import { LngLatBounds } from 'mapbox-gl';
import { useCallback, useState } from 'react';
import { MapProps, MapProvider, ViewState, ViewStateChangeEvent } from 'react-map-gl';

import { NATURAL_EARTH_DEFAULT_ZOOM, RootMap } from '../Map';
import { useMapboxStore } from '../Mapbox.store';
import { MapGestureHandling } from '../MapGestureHandling';
import { RemovePoi } from '../RemovePoi';
import { RealWorldMarkerContainer, RealWorldMarkerProps } from './RealWorldMarkerContainer';
import { createStackedMarkers } from './utils/createStackedMarkers';

const useRealWorldMapStore = createEnhancedStore<{ reset: () => void }>(() => ({
  reset: noop,
}));

export const REAL_WORLD_MAP_ID = 'real-world-map';

export const useRealWorldMapReset = () => {
  return useRealWorldMapStore(state => state.reset);
};

export type RealWorldMapProps = Partial<MapProps & RealWorldMarkerProps>;

export const RealWorldMap: React.FC<RealWorldMapProps> = ({
  mapStyle,
  items,
  stackEnabled,
  stackedItemsRenderer,
  searchFilter,
  ...props
}) => {
  const mapRef = useMapboxStore(state => state.refs.get(REAL_WORLD_MAP_ID));
  const initialZoom = props.zoom ?? NATURAL_EARTH_DEFAULT_ZOOM;
  const [viewState, setViewState] = useState<Partial<ViewState>>({
    zoom: initialZoom,
  });

  const onMove = useCallback(
    ({ viewState }: ViewStateChangeEvent) => {
      setViewState(viewState);
    },
    [setViewState],
  );

  const adjustViewportBasedOnItems = useCallback(() => {
    if (!items || items.length === 0) {
      return;
    }
    const features = points(
      items.map(e => {
        return [e.coordinates[0], e.coordinates[1]];
      }),
    );

    // calculate the bounding box of the feature
    const [minLng, minLat, maxLng, maxLat] = turfBbox(features);

    const bounds = new LngLatBounds([
      [minLng, minLat],
      [maxLng, maxLat],
    ]);

    const options: mapboxgl.FitBoundsOptions = {
      padding: 110,
      duration: 900,
    };

    const stackedMarkersCount = stackEnabled ? createStackedMarkers(items).length : 0;

    // In case theres not more than 1 marker we should limit the zoom
    if (stackedMarkersCount <= 1) {
      // https://docs.mapbox.com/help/glossary/zoom-level/
      // 15 stands for buildings zoom level
      options.maxZoom = 15;
    }

    mapRef?.fitBounds(bounds, options);
  }, [items, mapRef, stackEnabled]);

  const handleMapLoad = useCallback(() => {
    adjustViewportBasedOnItems();

    // Sync the external function callback so any parent consumer can safely call it
    useRealWorldMapStore.setState({ reset: adjustViewportBasedOnItems });
  }, [adjustViewportBasedOnItems]);

  return (
    <MapProvider>
      <RootMap
        {...props}
        {...viewState}
        mapStyle={mapStyle}
        onLoad={handleMapLoad}
        onMove={onMove}
        renderWorldCopies={false}
        mapRefId={REAL_WORLD_MAP_ID}
      >
        <MapGestureHandling map={mapRef ?? null} interactive={props.interactive} />
        <RemovePoi mapId={REAL_WORLD_MAP_ID} />
        <RealWorldMarkerContainer
          items={items ?? []}
          stackEnabled={stackEnabled}
          stackedItemsRenderer={stackedItemsRenderer}
          searchFilter={searchFilter}
        />
      </RootMap>
    </MapProvider>
  );
};
