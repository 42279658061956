import { ButtonBase, ButtonBaseProps, Radio, useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import React from 'react';

import { HStack, VStack } from '../../..';
import { ConditionallyWrappedTypography as RadioTypography } from './ConditionallyWrappedTypography';

export interface LargeRadioButtonProps extends Partial<ButtonBaseProps> {
  checked: boolean;
  value: string;
  label: string | React.ReactNode;
  disabled?: boolean;
  error?: boolean;
  hideRadio?: boolean;
  description?: string | React.ReactNode;
  /** @default "right" */
  radioPosition?: 'left' | 'right';
  onClick: () => void;
  fullWidth?: boolean;
}

export const LargeRadioButton: React.FC<LargeRadioButtonProps> = ({
  value,
  description,
  label,
  error,
  checked,
  disabled,
  hideRadio,
  radioPosition = 'right',
  onClick,
  fullWidth,
  ...buttonBaseProps
}) => {
  const { palette, transitions } = useTheme();
  const color = error ? palette.error : palette.primary;

  const checkedBgColor = color.background;
  const hoverBgColor = alpha(color.main, palette.action.hoverOpacity);
  const textColor = disabled ? palette.text.disabled : palette.text.primary;

  let borderColor: string = color.main;
  if (disabled) {
    borderColor = checked ? alpha(color.main, palette.action.disabledOpacity) : palette.action.disabled;
  } else {
    if (error) {
      borderColor = checked ? color.main : color.light;
    } else {
      borderColor = checked ? color.main : palette.action.active;
    }
  }

  const id = `${value}-radio-button`;

  return (
    <ButtonBase
      {...buttonBaseProps}
      aria-labelledby={id}
      onClick={onClick}
      disabled={disabled}
      disableRipple
      sx={{
        flex: 1,
        padding: 2,
        borderRadius: 2,
        border: `1px ${borderColor} solid`,
        bgcolor: checked ? checkedBgColor : 'unset',
        width: fullWidth ? '100%' : undefined,
        transition: transitions.create(['background-color', 'border'], {
          duration: transitions.duration.short,
        }),
        ':focus-visible': {
          outline: `2px solid ${checkedBgColor}`,
          borderColor: palette.primary.main,
        },
        ':hover': {
          borderColor: color.main,
          bgcolor: checked ? palette.utils.getHoverColor(checkedBgColor) : hoverBgColor,
        },
      }}
    >
      <VStack>
        <HStack justifyContent="space-between" alignItems="center">
          <RadioTypography id={id} variant="h5" color={textColor}>
            {label}
          </RadioTypography>
          {!hideRadio && !disabled && (
            <Radio value={value} checked={checked} sx={{ order: radioPosition === 'right' ? 'initial' : -1 }} />
          )}
        </HStack>

        {description && (
          <RadioTypography variant="caption" color={textColor}>
            {description}
          </RadioTypography>
        )}
      </VStack>
    </ButtonBase>
  );
};
