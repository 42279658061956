import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Divider, Drawer, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material';
import { useLayoutContext } from '@web-app/components/Layout/LayoutContextProvider';
import { MenuGroup } from '@web-app/lib/getNavigationGroups';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Attribution from '../../Attribution';
import { ListCollapsible } from './ListCollapsible';
import { ListPlain } from './ListPlain';

interface DrawerProps {
  open: boolean;
  onClose: () => void;
}

const LeftDrawer = ({ open, onClose }: DrawerProps) => {
  const { navigationMenuGroups } = useLayoutContext();
  const location = useLocation();

  useEffect(() => {
    onClose();
  }, [location, onClose]);

  const renderDashboardMenuItems = () => {
    return navigationMenuGroups.map((menuGroup: MenuGroup, index: number) => {
      const isLastItem = index === navigationMenuGroups.length - 1;
      const isCollapsed = menuGroup.isCollapsed === undefined ? true : menuGroup.isCollapsed;

      if (menuGroup.isCollapsible) {
        return (
          <ListCollapsible
            key={menuGroup.groupName}
            collapsed={isCollapsed}
            isLastItem={isLastItem}
            menuGroup={menuGroup}
            name={menuGroup.groupName}
            icon={menuGroup?.groupIcon}
          />
        );
      }

      if (menuGroup.customComponent) {
        return <React.Fragment key={menuGroup.groupName}>{menuGroup.customComponent}</React.Fragment>;
      }

      return <ListPlain key={menuGroup.groupName} menuGroupItems={menuGroup.items} isLastItem={isLastItem} />;
    });
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="left"
      variant="temporary"
      PaperProps={{
        sx: theme => ({
          width: '100%',
          [theme.breakpoints.up('sm')]: {
            width: 'initial',
          },
        }),
      }}
    >
      <Box
        sx={{ width: '100%', display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'space-between' }}
      >
        <List
          sx={theme => ({
            width: '100%',
            borderTop: `1px solid ${theme.palette.divider}`,
            padding: 'initial',

            [theme.breakpoints.up('sm')]: {
              width: '307px',
            },
          })}
        >
          <ListItem>
            <ListItemText>&nbsp;</ListItemText>
            <ListItemSecondaryAction sx={theme => ({ right: theme.spacing(1) })}>
              <IconButton onClick={onClose} size="large">
                <ChevronLeftIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
          {renderDashboardMenuItems()}
        </List>
      </Box>
      <Attribution />
    </Drawer>
  );
};

export default LeftDrawer;
