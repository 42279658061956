import { Box, BoxProps } from '@mui/material';
import React, { forwardRef } from 'react';

export interface HStackProps extends Omit<BoxProps, 'gap'> {
  noFullWidth?: boolean;
  spacing?: BoxProps['gap'];
  children: React.ReactNode;
}

export const HStack: React.FC<HStackProps> = forwardRef(({ children, noFullWidth, spacing = 1, ...boxProps }, ref) => {
  return (
    <Box
      ref={ref}
      display="flex"
      alignItems="center"
      width={noFullWidth ? 'unset' : '100%'}
      gap={spacing}
      {...boxProps}
    >
      {children}
    </Box>
  );
});
