import { FCC } from '@circadian-risk/front-end-utils';
import noop from 'lodash/noop';
import { createContext, useContext } from 'react';

import { Item } from './item.interface';

export type OnItemSave = (item: Partial<Item>, itemCategoryId: string) => Promise<string | undefined>;
export type OnUpdateItem = (id: string, item: Partial<Item>) => void;
export type OnDeleteItem = (id: string) => void;

export interface MapContextProviderProps {
  data: {
    onItemSave: OnItemSave;
    onUpdateItem: OnUpdateItem;
    onDeleteItem: OnDeleteItem;
    onSwitchToDetailsMode: () => void;
  };
}

export const MapContext = createContext<MapContextProviderProps['data']>({
  onItemSave: noop as OnItemSave,
  onUpdateItem: noop,
  onDeleteItem: noop,
  onSwitchToDetailsMode: noop,
});

export const useMapContext = () => useContext(MapContext);

export const MapContextProvider: FCC<MapContextProviderProps> = ({ data, children }) => {
  return <MapContext.Provider value={data}>{children}</MapContext.Provider>;
};
