import { HStack, InfoIconWithTooltip } from '@circadian-risk/presentational';
import { Box, Checkbox, CheckboxProps, FormControlLabel, FormControlLabelProps, FormHelperText } from '@mui/material';
import isNil from 'lodash/isNil';
import { Controller } from 'react-hook-form';

import { PropertyFieldProps } from '../types';

export type PropertyCheckboxFieldProps = PropertyFieldProps & {
  size?: CheckboxProps['size'];
};
export const PropertyCheckboxField: React.FC<PropertyCheckboxFieldProps> = ({
  label,
  htmlName,
  control,
  rules,
  disabled,
  boxProps,
  helperText,
  errorMessage,
  ariaLabel,
  size,
}) => {
  return (
    <Box {...boxProps}>
      <Controller
        control={control}
        name={htmlName}
        defaultValue={false}
        rules={rules}
        render={fieldProps => {
          const { value, onChange } = fieldProps.field;
          const checkValue = isNil(value) ? false : value;
          return (
            <HStack>
              <FormControlLabel
                disabled={disabled}
                control={<Checkbox checked={checkValue} onChange={() => onChange(!value)} size={size} />}
                label={label as FormControlLabelProps['label']}
                aria-label={ariaLabel}
              />
              {helperText && <InfoIconWithTooltip tooltipContent={helperText} />}
              {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
            </HStack>
          );
        }}
      />
    </Box>
  );
};
