import { Button, Typography } from '@mui/material';

import { ResponseIcon } from '../Icons';
import { HStack, VStack } from '../Layouts';

interface DeficientSelectorButtonProps extends DeficientResponseSwitchProps {
  forValue?: 'yes' | 'no';
}

const DeficientSelectorButton: React.VFC<DeficientSelectorButtonProps> = ({ value, forValue, onChange }) => {
  const selected = value === forValue;
  const notDefined = value === undefined;

  const icon = selected ? 'deficient' : notDefined && forValue === 'no' ? 'deficient' : 'compliant';

  return (
    <Button
      startIcon={<ResponseIcon response={icon} htmlColor="inherit" />}
      variant={selected ? 'contained' : 'outlined'}
      onClick={() => {
        if (selected) {
          onChange(undefined);
          return;
        }
        onChange(forValue);
      }}
      color={selected ? 'error' : notDefined ? 'secondary' : 'primary'}
    >
      {forValue}
    </Button>
  );
};

export interface DeficientResponseSwitchProps {
  value?: 'yes' | 'no';
  onChange: (value: DeficientResponseSwitchProps['value']) => void;
}

export const DeficientResponseSwitch: React.VFC<DeficientResponseSwitchProps> = ({ value, onChange }) => (
  <VStack>
    <Typography variant="subtitle2">Deficient Response</Typography>
    <HStack>
      <DeficientSelectorButton value={value} forValue="yes" onChange={onChange} />
      <DeficientSelectorButton value={value} forValue="no" onChange={onChange} />
    </HStack>
  </VStack>
);
