import { FormPaper, FormStack, FormTitle, generateCircadianForm, passwordSchema } from '@circadian-risk/form';
import { Box } from '@mui/material';
import { z } from 'zod';

import { ReactComponent as Logo } from '../../../assets/LogoWithText.svg';

export const resetPasswordFormSchema = z
  .object({
    password: passwordSchema,
    confirmPassword: passwordSchema,
    verificationCode: z.string().regex(/^\d{6}$/, 'Verification code must be a 6 digit number'),
  })
  .refine(data => data.password === data.confirmPassword, {
    message: 'Passwords do not match',
    path: ['confirmPassword'],
  });

export type ResetPasswordFormData = z.infer<typeof resetPasswordFormSchema>;

const { useCircadianForm, ariaLabels, Code, Password, SubmitButton, CircadianForm } = generateCircadianForm(
  resetPasswordFormSchema,
  {
    form: 'Reset Password',
    password: 'New password',
    confirmPassword: 'Confirm new password',
    verificationCode: 'Code',
    submit: 'Reset Password',
  },
);

export const ResetPasswordFormAriaLabels = ariaLabels;

export interface ResetPasswordFormProps {
  onSubmit: (formData: ResetPasswordFormData) => void;
  disabled: boolean;
}

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ onSubmit, disabled }) => {
  const formMethods = useCircadianForm({ defaultValues: { confirmPassword: '', password: '', verificationCode: '' } });
  return (
    <CircadianForm {...{ onSubmit, formMethods, disabled }}>
      <FormPaper showBg>
        <FormStack>
          <FormTitle>{ariaLabels.form}</FormTitle>

          <Box display="flex" justifyContent="center" p={2}>
            {/* TODO(iprokopovich): [CR-1507] move logo component to presentational and use it here */}
            <Logo width="210px" height="105px" />
          </Box>
          <Code autoFocus formKey="verificationCode" onlyNumbers maxLength={6} />
          <Password formKey="password" />
          <Password formKey="confirmPassword" />
          <SubmitButton color="success" />
        </FormStack>
      </FormPaper>
    </CircadianForm>
  );
};
