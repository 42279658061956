import { ColDef } from '@ag-grid-community/core';
import { Box } from '@mui/material';
import React from 'react';

import { CellRendererParams, formatCurrencyNoDecimalPlaces } from '../helpers';
import { EditButtonCellRendererComponent } from '.';

export const CostCellRenderer: React.FC<CellRendererParams<{ cost: number | null; answerId: string }>> = ({
  data,
  ...props
}) => {
  const { colDef, ...rest } = props;

  return (
    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
      <span>{!data.cost ? null : formatCurrencyNoDecimalPlaces(data.cost)}</span>
      <EditButtonCellRendererComponent colDef={colDef as ColDef} {...rest} data={{ ...data, id: data.answerId }} />
    </Box>
  );
};
