import {
  AssessmentItemStatusEnum,
  AssessmentItemStatuses,
  getInspectionItemStatus,
} from '@circadian-risk/front-end-utils';
import { AssessmentPerformanceItem } from '@web-app/modules/Assessments/PerformAssessment/PerformAssessmentData.helpers';

import { debug } from './common';

export const translateInspectionItemStatus = (item: AssessmentPerformanceItem): AssessmentItemStatuses => {
  try {
    return getInspectionItemStatus({
      isPresent: item.is_present,
      completedAnswers: item.answers.filter(a => !a.isWaitingForResponse).length,
      totalAnswers: item.answers.length,
      deficientAnswers: item.answers.filter(a => a.response === a.deficient_response).length,
    });
  } catch (e) {
    debug.extend('translateItemStatus')('Could not calculate Status! Check your item', item);
    return AssessmentItemStatusEnum.NotStarted;
  }
};
