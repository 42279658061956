import { Bold, HStack } from '@circadian-risk/presentational';
import { Typography } from '@mui/material';

type Props = {
  icon: React.ReactNode;
  title: React.ReactNode;
  value: React.ReactNode;
};

export const DetailsItem: React.FC<Props> = ({ icon, title, value }) => {
  return (
    <HStack alignItems="flex-start">
      {icon}
      <Typography flex={1} variant="subtitle2" sx={{ whiteSpace: 'nowrap' }}>
        <Bold>{title}</Bold>
      </Typography>
      <Typography color="secondary" variant="body2" sx={{ wordBreak: 'break-word' }}>
        {value}
      </Typography>
    </HStack>
  );
};
