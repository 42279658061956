import { ROUTES } from '@circadian-risk/routes';
import { USER_ROLE } from '@circadian-risk/shared';
import { lazyLoadWithVersionCheck } from '@web-app/router/lazyLoadWithVersionCheck';

const TaskDetailsPageWrapper = lazyLoadWithVersionCheck(() => import('../../modules/Tasks/index'));
const TaskPageWrapper = lazyLoadWithVersionCheck(() => import('./AllTasksPage'));

export const taskRoutes = () => [
  {
    path: ROUTES.TASKS,
    component: TaskPageWrapper,
    isExact: true,
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN, USER_ROLE.LOCATION_MANAGER],
  },
  {
    path: ROUTES.TASKS_DETAILS,
    component: TaskDetailsPageWrapper,
    isExact: false,
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN, USER_ROLE.LOCATION_MANAGER],
  },
];
