import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Alert, useTheme } from '@mui/material';

interface CardInfoBannerProps {
  ariaLabel: string;
  content: string;
  actionButton?: React.ReactNode;
}

export const CardInfoBanner: React.FC<CardInfoBannerProps> = ({ content, ariaLabel, actionButton }) => {
  const theme = useTheme();
  return (
    <Alert
      icon={<WarningAmberIcon htmlColor={theme.palette.getContrastText(theme.palette.primary.light)} />}
      sx={{
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
      }}
      aria-label={ariaLabel}
      action={actionButton}
    >
      {content}
    </Alert>
  );
};
