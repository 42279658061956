import { DetailsContents, DetailsPage } from '@web-app/layouts/DetailsPage';
import { SmartAssessmentsDrawer } from '@web-app/modules/Assessments/AssessmentDetails/components/AssessmentsDrawer.smart';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { AssessmentDetailsParams } from './AssessmentDetails.types';
import { ScenarioAssessmentDetailsPage } from './ScenarioAssessmentDetailsPage';

const AssessmentDetailsWrapperPage: React.FC = () => {
  const history = useHistory();
  const { id: assessmentId, organizationId } = useParams<AssessmentDetailsParams>();

  const isEditPage = history.location.pathname.includes('edit');
  const isReportPage = history.location.pathname.includes('report');

  return (
    <DetailsPage>
      {!isEditPage && !isReportPage && <SmartAssessmentsDrawer />}
      <DetailsContents sx={{ padding: 0 }}>
        <ScenarioAssessmentDetailsPage organizationId={organizationId} assessmentId={parseInt(assessmentId)} />
      </DetailsContents>
    </DetailsPage>
  );
};

export default AssessmentDetailsWrapperPage;
