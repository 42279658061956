import { Box } from '@mui/material';
import { defaultLayoutPlugin, ToolbarProps, ToolbarSlot } from '@react-pdf-viewer/default-layout';
import React from 'react';

const renderToolbar = (Toolbar: (props: ToolbarProps) => React.ReactElement) => (
  <Toolbar>
    {(slots: ToolbarSlot) => {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      const {
        CurrentPageInput,
        Download,
        EnterFullScreen,
        GoToNextPage,
        GoToPreviousPage,
        NumberOfPages,
        Print,
        ShowSearchPopover,
        Zoom,
        ZoomIn,
        ZoomOut,
      } = slots;
      return (
        <Box display="flex" alignItems="center" gap={2}>
          <ShowSearchPopover />
          <ZoomOut />
          <Zoom />
          <ZoomIn />
          <Box flex={1} display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap={2}>
            <GoToPreviousPage />
            <CurrentPageInput /> / <NumberOfPages />
            <GoToNextPage />
          </Box>
          <EnterFullScreen />
          <Download />
          <Print />
        </Box>
      );
    }}
  </Toolbar>
);

export const usePdfDefaultLayoutPlugin = () => {
  return defaultLayoutPlugin({
    sidebarTabs: defaultTabs => [defaultTabs[0]],
    renderToolbar,
  });
};
