import DoneIcon from '@mui/icons-material/Done';
import { useTheme } from '@mui/material';
import React from 'react';

import { OptionForConsiderationFlair } from '..';

export const AcceptedFlair: React.FC = () => {
  const theme = useTheme();
  return (
    <OptionForConsiderationFlair
      id="Accepted"
      Icon={DoneIcon}
      color={theme.palette.success.main}
      tooltip="This option was attached to the task action"
      text="Accepted"
    />
  );
};
