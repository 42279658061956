import { isImageExtension } from '@circadian-risk/front-end-utils';
import {
  DeleteConfirmationButtonConfig,
  HStack,
  Thumbnail,
  useConfirmationDialog,
  useLightbox,
} from '@circadian-risk/presentational';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Badge, Box, Divider, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';

import { FileListItemRenameModal } from './FileListItemRenameModal';
import { FileListItemProps } from './FileUpload.types';

export const FileListItem: React.FC<FileListItemProps> = ({
  id,
  name,
  thumbnailUrl,
  onDelete,
  onDownload,
  onRename,
  divider = true,
  dense,
  badgeContent,
}) => {
  const theme = useTheme();
  const { getConfirmation } = useConfirmationDialog();

  const [renameModalOpen, setRenameModalOpen] = useState(false);

  const { handleOpen } = useLightbox();
  const allowPreview = thumbnailUrl && isImageExtension(name);

  const handleDelete = async () => {
    const confirmed = await getConfirmation({
      title: 'Delete File',
      message: `Are you sure you want to delete ${name}?`,
      confirmButton: DeleteConfirmationButtonConfig,
    });

    if (confirmed) {
      onDelete?.();
    }
  };

  const thumbnail = (
    <Thumbnail
      name={name}
      src={thumbnailUrl ?? undefined}
      onImageClick={allowPreview ? () => handleOpen(id) : undefined}
    />
  );

  return (
    <>
      <Box display="flex">
        <Box
          width="100%"
          aria-label={`file ${id}`}
          component="li"
          sx={{
            listStyleType: 'none',
          }}
          key={id}
          flex={1}
          gap={theme.spacing(2)}
          margin={0}
          px={dense ? 0 : 2}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          {badgeContent ? (
            <Badge badgeContent={badgeContent} color="primary">
              {thumbnail}
            </Badge>
          ) : (
            thumbnail
          )}
          <Typography
            sx={{
              flexGrow: 1,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            variant="body2"
          >
            {name}
          </Typography>
          <HStack flex={0} justifySelf="flex-end">
            {onRename && (
              <>
                <Tooltip title="Rename">
                  <IconButton aria-label="rename" size="small" onClick={() => setRenameModalOpen(true)}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <FileListItemRenameModal
                  name={name}
                  onRename={onRename}
                  open={renameModalOpen}
                  onClose={() => setRenameModalOpen(false)}
                />
              </>
            )}

            {onDownload && (
              <Tooltip title="Download">
                <IconButton onClick={onDownload} aria-label="download" size="small">
                  <GetAppIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}

            {onDelete && (
              <Tooltip title="Delete">
                <IconButton aria-label="delete" size="small" onClick={handleDelete}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </HStack>
        </Box>
      </Box>
      {divider && <Divider sx={{ width: '100%' }} />}
    </>
  );
};
