import { useLocationWizardModal } from '@circadian-risk/location-wizard';
import { LocationChip } from '@circadian-risk/presentational';
import { Alert, Button } from '@mui/material';

type Props = {
  scoredLayerName: string;
};
export const MissingRelevantLocationsAlert: React.FC<Props> = ({ scoredLayerName }) => {
  const openLocationWizard = useLocationWizardModal();
  return (
    <Alert
      severity="warning"
      action={
        <Button color="warning" onClick={() => openLocationWizard({ type: 'adding' })}>
          Create Location(s)
        </Button>
      }
    >
      You need at least one <LocationChip sx={{ mx: 0.5 }} size="small" layerName={scoredLayerName} /> in order to
      leverage this template
    </Alert>
  );
};
