import { HStack } from '@circadian-risk/presentational';
import { Typography } from '@mui/material';
import React from 'react';

import { ariaLabels } from '../../ariaLabels';

export const NoCreationAllowedMessage: React.FC = () => {
  return (
    <HStack height="100%">
      <Typography
        align="center"
        color="textSecondary"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
        }}
      >
        {ariaLabels.CANT_CREATE_CHILDREN}
      </Typography>
    </HStack>
  );
};
