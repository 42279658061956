import { CircadianRoute, ROUTES } from '@circadian-risk/routes';
import { isIntersected } from '@circadian-risk/shared';
import { questionSetsRoutes } from '@web-app/modules/QuestionSets';
import { considerationOptionsRoutes } from '@web-app/pages/consideration-options';
import { filterRoutes } from '@web-app/pages/filters';
import { additionalItemRoutes } from '@web-app/pages/items';
import { reportsRoutes } from '@web-app/pages/reports';
import { scheduledAssessmentRoutes } from '@web-app/pages/scheduled-assessments';
import { taskRoutes } from '@web-app/pages/tasks';
import { matchPath } from 'react-router-dom';

import { adminRoutes } from '../modules/Admin';
import { assessmentRoutes } from '../modules/Assessments';
import { scenarioAssessmentTemplateRoutes } from '../modules/AssessmentTemplatesV2';
import { authRoutes } from '../modules/AuthUser';
import { dashboardRoutes } from '../modules/Dashboard';
import { deficienciesByQuestionRoutes } from '../modules/DeficienciesByQuestion';
import { filesRoutes } from '../modules/Files';
import { itemCategoryRoutes } from '../modules/ItemCategories';
import { itemRoutes } from '../modules/Items';
import { layerRoutes } from '../modules/Layers';
import { nodeRoutes } from '../modules/Nodes';
import { organizationRoutes } from '../modules/Organizations';
import { permissionsRoutes } from '../modules/Permissions';
import { scenarioRoutes } from '../modules/Scenarios';
import { userRoutes } from '../modules/Users';
import { projectRoutes } from '../pages/projects';
import NotFound from './NotFound';

export const routeConfig: CircadianRoute[] = [
  ...adminRoutes(),
  ...assessmentRoutes(),
  ...authRoutes(),
  ...itemCategoryRoutes(),
  ...itemRoutes(),
  ...layerRoutes,
  ...nodeRoutes(),
  ...organizationRoutes(),
  ...userRoutes(),
  ...questionSetsRoutes(),
  ...filesRoutes(),
  ...deficienciesByQuestionRoutes(),
  ...scenarioAssessmentTemplateRoutes(),
  ...taskRoutes(),
  ...considerationOptionsRoutes(),
  ...reportsRoutes(),
  ...dashboardRoutes,
  ...scenarioRoutes(),
  ...permissionsRoutes,
  ...additionalItemRoutes,
  ...projectRoutes,
  ...scheduledAssessmentRoutes,
  ...filterRoutes,
  { path: ROUTES.WILDCARD, component: NotFound, isPublic: true },
];

export const canAccessRoutePath = (
  path: string,
  isCircadianAdmin: boolean,
  roles: string[] = [],
  enabledFeatures: string[] = [],
): boolean => {
  const matchingRoute = routeConfig.find(route => {
    return matchPath(path, { ...route });
  });

  if (matchingRoute) {
    if (matchingRoute.isCircadianAdminOnly) {
      return isCircadianAdmin;
    }

    if (matchingRoute.featureFlag && !enabledFeatures.some(e => e === matchingRoute.featureFlag)) {
      return false;
    }

    if (matchingRoute.allowRoles) {
      return isIntersected(roles, matchingRoute.allowRoles);
    }
  }

  return true;
};

export const orgScopedRoutes = routeConfig.filter(route => {
  return route.path.startsWith('/organization');
});
