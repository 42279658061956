import { Theme } from '@mui/material';

const AVATAR_SIZE = 128;

export const authUserSx = {
  avatar: { width: AVATAR_SIZE, height: AVATAR_SIZE, justifySelf: 'center' },
  orgLogo: (theme: Theme) => ({ borderRadius: theme.spacing(1) }),
  wrap: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
  },
  root: (theme: Theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(20),
      height: theme.spacing(22),
    },
  }),
  paperWrapper: {
    width: '100%',
  },
  paper: (theme: Theme) => ({
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    width: '100%',
  }),
  title: (theme: Theme) => ({
    ...theme.typography.h2,
    fontSize: theme.typography.h4.fontSize,

    textAlign: 'center',
    flex: 1,
  }),
};
