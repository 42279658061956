import { CIRCADIAN_RISK_LIBRARY_ORG_ID } from '@circadian-risk/data-utils';
import { HStack } from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { useUserSessionStore } from '@circadian-risk/stores';
import TableIcon from '@mui/icons-material/TableRows';
import CardIcon from '@mui/icons-material/ViewComfy';
import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { CircadianRiskLibraryBanner } from './CircadianRiskLibraryBanner';
import { authUserSx } from './styles';
import { SwitchOrganizationCardView } from './SwitchOrganizationCardView';
import { SwitchOrganizationTableView } from './SwitchOrganizationTableView';

type ViewMode = 'table' | 'cards';

export const SwitchOrganizationPage: React.FC = () => {
  const { organizations, organizationIds, libraryOrg } = useUserSessionStore(({ organizations }) => {
    return {
      organizations,
      organizationIds: organizations.map(e => e.id),
      libraryOrg: organizations.find(e => e.id === CIRCADIAN_RISK_LIBRARY_ORG_ID),
    };
  });

  const [mode, setMode] = useState<ViewMode>(() => {
    return organizations.length > 10 ? 'table' : 'cards';
  });

  if (organizationIds.length === 1) {
    const redirectTo = ROUTES.ROOT.replace(':organizationId', organizationIds[0]);
    return <Redirect to={redirectTo} />;
  }

  const handleModeChange = (_event: React.MouseEvent<HTMLElement>, nextMode: ViewMode) => {
    // Avoid empty mode, a mode is always required
    // or when they are equal
    if (!nextMode || mode === nextMode) {
      return;
    }
    setMode(nextMode);
  };

  return (
    <Box sx={authUserSx.wrap}>
      <HStack mb={2} justifyContent={'space-between'} width={'100%'} px={2} m={2}>
        <Box width="100%">
          <Typography
            sx={{
              fontSize: {
                xs: 'h6.fontSize',
                sm: 'h5.fontSize',
                md: 'h4.fontSize',
              },
            }}
            component="h2"
          >
            Select an Organization
          </Typography>
        </Box>
        <HStack justifyContent="flex-end">
          {libraryOrg && <CircadianRiskLibraryBanner />}
          <ToggleButtonGroup
            color="primary"
            aria-label="choose view mode"
            value={mode}
            onChange={handleModeChange}
            size={'medium'}
            exclusive
          >
            <ToggleButton value="cards" aria-label={'cards view mode button'}>
              <CardIcon />
            </ToggleButton>
            <ToggleButton value="table" aria-label={'table view mode button'}>
              <TableIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </HStack>
      </HStack>

      {mode === 'table' && <SwitchOrganizationTableView rows={organizations} />}
      {mode === 'cards' && <SwitchOrganizationCardView rows={organizations} />}
    </Box>
  );
};
