import { AnswerSemanticMeaning } from '@circadian-risk/front-end-utils';
import { Enum_Answer_Responses_Enum } from '@circadian-risk/graphql-types';
import React from 'react';

import { ResponseButton, ResponseButtonProps } from './components';

const getMeaning = (
  response: Enum_Answer_Responses_Enum | null,
  badResponse: Enum_Answer_Responses_Enum,
): AnswerSemanticMeaning => {
  if (response === 'n_a' || response === 'n_o') {
    return response;
  }
  return response === badResponse ? 'deficient' : 'compliant';
};

export type QuestionResponseCompactProps = {
  deficientResponse: Enum_Answer_Responses_Enum;
  partialCompliance: ResponseButtonProps['partialCompliance'];
  isPartialComplianceEnabled: ResponseButtonProps['isPartialComplianceEnabled'];
  text: ResponseButtonProps['text'] | null;
};

export const QuestionResponseCompact: React.FC<QuestionResponseCompactProps> = ({
  deficientResponse,
  text,
  isPartialComplianceEnabled,
  partialCompliance,
}) => {
  if (!text) {
    return null;
  }

  return (
    <ResponseButton
      partialCompliance={partialCompliance}
      isPartialComplianceEnabled={isPartialComplianceEnabled}
      text={text}
      compact
      selected
      meaning={getMeaning(text, deficientResponse)}
      disabled
    />
  );
};
