/**
 * Returns the assessment item id and question id parts from a virtual assessment item question and answer
 * that is keyed in the form {assessmentItemId}:{questionId}
 *
 * @param key
 * @returns
 */
export const extractVirtualAssessmentItemAndQuestion = (key: string): [string, string] => {
  const [assessmentItemId, questionId] = key.split(':');
  return [assessmentItemId, questionId];
};

export type TooltipStatusLabels =
  | 'present deficient'
  | 'missing deficient'
  | 'not applicable'
  | 'not observed'
  | 'compliant'
  | 'accepted'
  | 'unknown';

export const getAnswerStatusLabel = (args: {
  itemIsPresent: boolean;
  isDeficient: boolean;
  isNotApplicable: boolean;
  isAccepted: boolean;
}): TooltipStatusLabels => {
  const { itemIsPresent, isDeficient, isNotApplicable, isAccepted } = args;
  if (isAccepted) {
    return 'accepted';
  }

  if (isDeficient) {
    return itemIsPresent ? 'present deficient' : 'missing deficient';
  }

  return isNotApplicable ? 'not applicable' : 'compliant';
};

export const getItemStatusLabel = (isPresent: boolean, itemRollupLabel: string): TooltipStatusLabels => {
  if (itemRollupLabel === 'deficient') {
    return isPresent ? 'present deficient' : 'missing deficient';
  }

  if (
    itemRollupLabel === 'not observed' ||
    itemRollupLabel === 'not applicable' ||
    itemRollupLabel === 'compliant' ||
    itemRollupLabel === 'accepted'
  ) {
    return itemRollupLabel;
  }

  return 'unknown';
};
