import { Box, BoxProps } from '@mui/material';

export interface DataGridLayoutProps {
  actions: React.ReactNode;
  grid: React.ReactNode;
  gridWrapperBoxProps?: Partial<BoxProps>;
}

export const DataGridLayout: React.FC<DataGridLayoutProps> = ({ actions, grid, gridWrapperBoxProps }) => {
  return (
    <Box height="100%" width="100%">
      <Box p={2}>{actions}</Box>
      <Box px={1} {...gridWrapperBoxProps}>
        {grid}
      </Box>
    </Box>
  );
};
