import { InfoBox } from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { useOrganizationId } from '@circadian-risk/stores';
import { Box, Button, useTheme } from '@mui/material';
import React, { useCallback } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import { sectionInfoPreviewText } from './helpers';

export interface ExecutiveSummaryInfoBoxProps {
  assessmentId?: number;
  disabled?: boolean;
}

export const ExecutiveSummaryInfoBox: React.FC<ExecutiveSummaryInfoBoxProps> = ({ assessmentId, disabled }) => {
  const theme = useTheme();
  const history = useHistory();
  const organizationId = useOrganizationId();
  const handleOpenAssessmentNarrativeClick = useCallback(() => {
    if (!assessmentId) {
      return;
    }
    const path = generatePath(ROUTES.ASSESSMENTS_DETAIL, {
      id: assessmentId,
      organizationId,
    });
    history.push(`${path}/narrative`);
  }, [history, organizationId, assessmentId]);

  return (
    <InfoBox disableTypographyWrapper>
      <Box aria-label={'executive summary info'} display={'flex'} alignItems={'center'}>
        <Box color={theme.palette.info.dark} flex={1}>
          Information in this section comes from the Assessment Narrative.&nbsp;
          {sectionInfoPreviewText}
        </Box>
        <Box ml={1}>
          <Button
            aria-label={'open assessment narrative'}
            variant="outlined"
            color="primary"
            onClick={handleOpenAssessmentNarrativeClick}
            disabled={disabled}
          >
            <Box color={theme.palette.info.dark}>Open Assessment Narrative</Box>
          </Button>
        </Box>
      </Box>
    </InfoBox>
  );
};
