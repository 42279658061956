import { imageSrc } from '@circadian-risk/front-end-utils';
import { HStack, UserAvatar } from '@circadian-risk/presentational';
import { Box, Typography } from '@mui/material';

import { CellRendererDataParams } from '../helpers';

export interface UserCellProps {
  userName: string;
  userId?: string;
  /** @default false */
  displayId?: boolean;
  last_signed_in_at: string | Date | null | undefined;
  avatar: { filepath: string | null | undefined } | null | undefined;
}

// TODO(v-rrodrigues)[CR-5724]: Update this component to be a smart component (userId only) as we can read the data from Apollo cache
export const UserCellRenderer: React.FC<CellRendererDataParams<UserCellProps>> = ({ data }) => {
  const { userName, avatar, last_signed_in_at, userId, displayId } = data;

  return (
    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
      <Box>
        <HStack>
          <UserAvatar
            image={imageSrc(avatar?.filepath ?? '')}
            name={userName}
            lastSignedInAt={last_signed_in_at}
            size={40}
            withTooltip
          />
        </HStack>
      </Box>
      <Box ml={2} display={'flex'} flexDirection={'column'}>
        <Typography variant={'body2'}>
          {userName}
          {displayId && (
            <>
              <br />
              [id: {userId}]
            </>
          )}
        </Typography>
      </Box>
    </Box>
  );
};
