import PriceChangeIcon from '@mui/icons-material/PriceChange';
import TimeEstimateIcon from '@mui/icons-material/Timelapse';
import UpdateIcon from '@mui/icons-material/Update';
import { SvgIconProps } from '@mui/material';
import React from 'react';

import { QuantityIcon } from '../Icons';
import { PrincipalActionIcon } from '../PrincipalActionIcon';
import { TaskStatusIcon } from '../TaskStatusIcon';
import { TooltipAvatar } from '../TooltipAvatar';
import { TaskActionProperty } from './types';

export const TaskActionPropertyIcon: React.FC<{ property: TaskActionProperty } & SvgIconProps> = ({
  property,
  ...chipIconProps
}) => {
  switch (property.type) {
    case 'principalAction':
      return <PrincipalActionIcon action={property.value} {...chipIconProps} />;
    case 'taskAssignee':
      return (
        <TooltipAvatar alt={property.value.fullName} image={property.value.imageSrc} size={24} {...chipIconProps} />
      );
    case 'dueDate':
      return <UpdateIcon {...chipIconProps} />;
    case 'initialCost':
      return <PriceChangeIcon {...chipIconProps} />;
    case 'ongoingCost':
      return <PriceChangeIcon {...chipIconProps} />;
    case 'quantity':
      return <QuantityIcon {...chipIconProps} />;
    case 'timeEstimate':
      return <TimeEstimateIcon {...chipIconProps} />;
    case 'status':
      return <TaskStatusIcon status={property.value} {...chipIconProps} />;
    default:
      return null;
  }
};
