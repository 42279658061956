import { Frequency, WeekdayType } from '@circadian-risk/api-contract';
import { ScheduledAssessmentScheduledFrequencyFragment } from '@circadian-risk/client-graphql-hooks';

/**
 * Convert a {@link ScheduledAssessmentScheduledFrequencyFragment} fragment to {@link Frequency} zod schema
 * @param fragment
 * @returns
 */
export const convertFrequencyFragmentToZodSchema = (
  fragment: ScheduledAssessmentScheduledFrequencyFragment,
): Frequency => {
  switch (fragment.type) {
    default:
      throw new Error('Failed to convert frequency fragment to zod schema.');
    case 'daily':
      return {
        type: 'daily',
      };
    case 'weekly':
      return {
        type: 'weekly',
      };
    case 'weekday':
      return {
        type: 'weekday',
      };
    case 'monthly':
      return {
        type: 'monthly',
      };
    case 'annually':
      return {
        type: 'annually',
      };
    case 'custom':
      switch (fragment.repeat_every_type) {
        case 'hour':
          return {
            type: 'custom',
            options: {
              repeatUnit: 'hour',
              value: fragment.repeat_every_value!,
            },
          };
        case 'day':
          return {
            type: 'custom',
            options: {
              repeatUnit: 'day',
              value: fragment.repeat_every_value!,
            },
          };
        case 'week':
          return {
            type: 'custom',
            options: {
              repeatUnit: 'week',
              value: fragment.repeat_every_value!,
              repeatOn: fragment.repeat_on! as unknown as WeekdayType[],
            },
          };
        case 'month':
          return {
            type: 'custom',
            options: {
              repeatUnit: 'month',
              value: fragment.repeat_every_value!,
            },
          };
        case 'year':
          return {
            type: 'custom',
            options: {
              repeatUnit: 'year',
              value: fragment.repeat_every_value!,
            },
          };
        default:
          throw new Error('Failed to convert custom frequency fragment to zod schema.');
      }
  }
};
