import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ColDef } from '@ag-grid-community/core';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import {
  DataGrid,
  DataGridSearch,
  TextTooltipCellRenderer,
  useControlledGridSelection,
  useGrid,
  UserCellProps,
  UserCellRenderer,
} from '@circadian-risk/data-grid';
import { DataGridLayout } from '@circadian-risk/layout';

export type UsersRowData = {
  userName: string | null;
  email: string;
  role: string | null;
  id: string;
  title?: string | null;
  company?: string | null;
  bio?: string | null;
  avatarImgSrc?: string | null;
} & UserCellProps;

export type ShowColumnOption =
  | keyof Omit<UsersRowData, 'id' | 'avatarImgSrc' | 'avatar' | 'last_signed_in_at' | 'displayId' | 'userId'>
  | 'link';

const ColumnOptionToDefinition: Record<ShowColumnOption, ColDef> = {
  userName: {
    field: 'userName',
    filter: 'agTextColumnFilter',
    cellRenderer: UserCellRenderer,
  },
  email: {
    field: 'email',
    filter: 'agTextColumnFilter',
  },
  role: {
    field: 'role',
    filter: 'agSetColumnFilter',
  },
  title: {
    field: 'title',
    filter: 'agSetColumnFilter',
  },
  company: {
    field: 'company',
  },
  bio: {
    field: 'bio',
    cellRenderer: TextTooltipCellRenderer,
    cellStyle: {
      overflow: 'hidden',
      'text-overflow': 'ellipsis',
    },
    cellClass: 'foobar-hello',
    maxWidth: 250,
  },
  link: {
    field: 'link',
  },
};

export interface UsersGridSelectorProps {
  users: UsersRowData[];
  selectedUserIds: string[];
  gridHeight?: string;
  onSelect?: (users: UsersRowData[]) => void;
  showColumns?: ShowColumnOption[];
}

export type UsersGridSelectorRef = {
  getSelectedUserIds(): string[];
};

export const UsersGridSelectorControlled: React.FC<UsersGridSelectorProps> = props => {
  const {
    users,
    selectedUserIds = [],
    gridHeight = '500px',
    onSelect,
    showColumns = ['userName', 'email', 'role'],
  } = props;
  const { onGridReady, gridApi } = useGrid();
  const { onFirstDataRendered, onSelectionChanged } = useControlledGridSelection(gridApi, selectedUserIds, onSelect);

  const columnDefs = showColumns.map((c, index) => {
    const columnDef = ColumnOptionToDefinition[c];
    if (index === 0) {
      columnDef.checkboxSelection = true;
      columnDef.headerCheckboxSelection = true;
    }
    return columnDef;
  });

  return (
    <DataGridLayout
      actions={<DataGridSearch gridApi={gridApi} />}
      grid={
        <DataGrid
          modules={[ClientSideRowModelModule, SetFilterModule]}
          defaultColDef={{
            flex: 1,
            filter: true,
            sortable: true,
            resizable: true,
          }}
          disableDeepLinking
          paginationAutoPageSize
          rowSelection={'multiple'}
          onGridReady={onGridReady}
          onSelectionChanged={onSelectionChanged}
          onFirstDataRendered={onFirstDataRendered}
          rowData={users}
          columnDefs={columnDefs}
          suppressRowClickSelection
          getRowId={({ data }) => (data as UsersRowData).id}
        />
      }
      gridWrapperBoxProps={{ height: gridHeight }}
    />
  );
};
