import { CreateAssessmentFormStepperConfig } from '../../stepsConfig';
import { CompletionCriteriaStep } from './CompletionCriteriaStep';
import { CompletionCriteriaStepStepperSubtitle } from './CompletionCriteriaStepStepperSubtitle';

export const completionCriteriaStepConfig: CreateAssessmentFormStepperConfig = {
  title: 'Import Answers and Required Actions',
  component: CompletionCriteriaStep,
  stepper: {
    subtitle: CompletionCriteriaStepStepperSubtitle,
  },
  validator: ({ trigger }) => {
    return trigger('importAnswers');
  },
};
