import { isImageExtension } from '@circadian-risk/front-end-utils';
import { Box, ButtonBase, useTheme } from '@mui/material';
import React from 'react';

import { FileIcon } from './FileIcon';

const DEFAULT_WIDTH = 40;

const StackShadow: React.FC<{ depth: number; width: number; height: number }> = ({ depth, width, height }) => {
  const { palette, spacing } = useTheme();
  const displacement = width / 7;
  const backgroundColor = palette.text.disabled;
  const colorStep = 0.3 * depth - 0.3;
  const adjustedColor = palette.utils.adjustColor(backgroundColor, colorStep, colorStep);

  return (
    <Box
      position="relative"
      style={{ zIndex: -1, opacity: 0.6, transform: `scale(${1 - depth * 0.1})` }}
      bgcolor={adjustedColor}
      left={displacement * depth}
      mt={`-${height}px`}
      mr={`${displacement * depth - 2}px`}
      borderRadius={spacing(0.5)}
      width={width}
      height={height}
    />
  );
};

export interface ThumbnailProps {
  name: string;
  src?: string;
  stack?: boolean;
  width?: number;
  height?: number;
  onImageClick?: () => void;
}

export const Thumbnail: React.FC<ThumbnailProps> = props => {
  const { name, src, stack, width = DEFAULT_WIDTH, height = DEFAULT_WIDTH, onImageClick } = props;
  const { palette, spacing } = useTheme();

  const allowPreview = onImageClick && src && isImageExtension(name);
  const dimensions = { width, height };

  return (
    <div>
      <Box
        bgcolor={palette.background.default}
        borderRadius={spacing(0.5)}
        display="flex"
        justifyContent="center"
        alignItems="center"
        {...dimensions}
      >
        <ButtonBase disabled={!allowPreview} onClick={onImageClick}>
          <FileIcon filename={name} url={src} {...dimensions} />
        </ButtonBase>
      </Box>
      {stack && (
        <>
          <StackShadow depth={1} {...dimensions} />
          <StackShadow depth={2} {...dimensions} />
        </>
      )}
    </div>
  );
};
