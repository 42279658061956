import { SmartLocationPicker } from '@circadian-risk/form';
import { useNotification } from '@circadian-risk/front-end-utils';
import { GenericDialog, GenericDialogProps, VStack } from '@circadian-risk/presentational';
import { GlobalOrganizationContextNode, useOrganizationSessionStore } from '@circadian-risk/stores';
import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle } from '@mui/material';
import React, { useState } from 'react';
import { isDirty } from 'zod';

import { CustomOption } from '../CustomBlockInput';
import { OptionFromLibrary } from '../OptionFromLibrary';

type DialogProps = Pick<GenericDialogProps, 'isOpen' | 'onClose'>;

export type SaveOptionDialogProps = {
  option: CustomOption;
  /**
   * Provides the assessment location ids that should be available
   * in the location dropdown
   * If not provided it will allow every node in the organization to be included
   */
  nodesToInclude?: string[];
  /**
   * Provides the default/selected locationId
   * @default root organization node id
   */
  initialLocation?: string;
  onSave: (option: CustomOption, locations: string[]) => Promise<void>;
} & DialogProps;

export const SaveOptionDialog: React.FC<SaveOptionDialogProps> = ({
  isOpen,
  onClose,
  onSave,
  option,
  initialLocation,
  nodesToInclude,
}) => {
  const { displayError } = useNotification();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const rootNodeId = useOrganizationSessionStore(state => state.rootNodeId);
  const [locationIds, setLocationIds] = useState<string[]>([initialLocation ?? rootNodeId]);

  const handleSave = async () => {
    if (locationIds) {
      setIsSubmitting(true);
      await onSave(option, locationIds);
      setIsSubmitting(false);
      onClose();
    } else {
      displayError('Location is required!');
    }
  };

  const nodeFilter = (node: GlobalOrganizationContextNode) => {
    // always include the organization node
    if (node.id === rootNodeId) {
      return true;
    }
    return nodesToInclude ? nodesToInclude.includes(node.id) : true;
  };

  return (
    <GenericDialog
      isOpen={isOpen}
      onClose={onClose}
      closeVerb="cancel"
      title={'Save to library'}
      actions={
        <LoadingButton loading={isSubmitting} variant="contained" disabled={!isDirty} onClick={handleSave}>
          Save
        </LoadingButton>
      }
    >
      <VStack spacing={2}>
        <Alert severity="info">
          <AlertTitle>Select location(s) for this Option for Consideration</AlertTitle>
          Chosen location(s) and all locations that exist beneath it (eg. Floors and Buildings for a selected Campus)
          will have the ability to use this option
        </Alert>
        <OptionFromLibrary
          value={{
            ...option,
            locationIds,
          }}
        />
        <SmartLocationPicker
          label="Locations"
          value={locationIds}
          nodeFilter={nodeFilter}
          onChange={setLocationIds}
          multiple
        />
      </VStack>
    </GenericDialog>
  );
};
