import { SxProps, Theme } from '@mui/system';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';

import { CreateScheduledAssessmentFormSchema } from './form';
import {
  cadenceDetailsStepConfig,
  completionCriteriaStepConfig,
  locationsStepConfig,
  rootStepConfig,
  summaryStepConfig,
  usersStepConfig,
} from './steps';

export type StepperSubtitleComponent = React.FC<{ activeStep: number }>;

export type CreateAssessmentFormStepperConfig = {
  title: string;
  component: React.FC;
  sx?: SxProps<Theme>;
  stepper?: {
    /**
     * Allows to override the stepper title
     * By default `title` property will be used
     */
    titleOverride?: string;
    /**
     * Subtitle component may have access to the form context
     * so render dynamic content that depends on the form state
     */
    subtitle?: StepperSubtitleComponent;
  };
  /**
   * Receives the form context in order to provide access to any form method
   * This callback is responsible to invoke any validation necessary and return whether the stepper may proceed to the next step
   * @param context
   * @returns
   */
  validator?: (context: UseFormReturn<CreateScheduledAssessmentFormSchema>) => Promise<boolean>;
};

export const stepsConfig: CreateAssessmentFormStepperConfig[] = [
  rootStepConfig,
  cadenceDetailsStepConfig,
  locationsStepConfig,
  completionCriteriaStepConfig,
  usersStepConfig,
  summaryStepConfig,
];
