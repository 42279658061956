import { useOrganizationSessionStore } from '@circadian-risk/stores';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { CreateScheduledAssessmentFormSchema } from '../CreateScheduledAssessment/form';
import { useLocationFilters } from '../CreateScheduledAssessment/steps/RootStep/hooks/useLocationFilters';
import {
  calculateRelevantLocations,
  CalculateRelevantLocationsFilterParam,
} from '../LocationFilter/ApplicableLocations/helpers';
import { LocationFilterRow } from '../LocationFilter/types';

export const useSelectedLocationIds = () => {
  const context = useFormContext<CreateScheduledAssessmentFormSchema>();
  const locationIds = context.watch('locationsIds');
  const filterId = context.watch('locationFilterId');
  const nodes = useOrganizationSessionStore(state => state.nodes);
  const layersById = useOrganizationSessionStore(state => state.layersById);

  const selectedFilter = useLocationFilters().find(filter => filter.id === filterId);

  const allLocations = useMemo(
    () =>
      nodes.map<LocationFilterRow>(n => {
        const layer = layersById[n.layer_id];
        return {
          ...n,
          layer,
          tagIds: n.tags.map(t => t.id),
          // Not relevant
          assessmentsCount: 0,
          itemsCount: 0,
          breadCrumbs: [],
        };
      }),
    [layersById, nodes],
  );

  const applicableLocationIds = useMemo(() => {
    if (selectedFilter) {
      const applicableFilterLocations = calculateRelevantLocations(
        allLocations,
        selectedFilter.filter_obj as CalculateRelevantLocationsFilterParam,
      );
      return applicableFilterLocations.map(row => row.id);
    }

    return locationIds;
  }, [allLocations, locationIds, selectedFilter]);

  return applicableLocationIds;
};
