import { Enum_Scenario_Type_Enum } from '@circadian-risk/graphql-types';
import { SvgIcon, SvgIconProps } from '@mui/material';
import { IconType } from 'react-icons/lib';
import { RiAlarmWarningLine, RiEarthquakeLine, RiShieldCheckLine } from 'react-icons/ri';

/**
 * @deprecated please use ScenarioIcon component instead
 */
export const scenarioIcons: Record<Enum_Scenario_Type_Enum, IconType> = {
  compliance: RiShieldCheckLine,
  threat: RiAlarmWarningLine,
  hazard: RiEarthquakeLine,
};

export interface ScenarioIconProps extends SvgIconProps {
  type: Enum_Scenario_Type_Enum;
}

export const ScenarioIcon: React.FC<ScenarioIconProps> = ({ type, ...iconProps }) => {
  const ReactIcon = scenarioIcons[type];
  return <SvgIcon inheritViewBox component={ReactIcon} {...iconProps} />;
};
