import { createEnhancedStore } from '@circadian-risk/front-end-utils';
import { PropertyConfig } from '@circadian-risk/shared';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import React from 'react';
import { StateCreator } from 'zustand';

export type ReportSectionField = {
  id: number;
  version: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  name: string;
  hasDefaultValue: boolean;
  kind: string;
  config?: PropertyConfig;
  placeholder?: string;
  /**
   * Allows some fields to use its description as the label
   *
   * @default name
   */
  labelType?: 'name' | 'description';
};

export interface ReportSection {
  id: string;
  visible: boolean;
  template: { id: string; name: string };
  fields: ReportSectionField[];
  isPreviewOnly: boolean;
  /**
   * Renders a custom node when the section is preview only
   *
   * @default undefined
   */
  customPreviewInfo?: React.ReactNode;
  /**
   * If provided renders this node after the fields
   *
   * @default undefined
   */
  extraContent?: React.ReactNode;
}

type ReportStatus = 'failed' | 'pending' | 'started' | 'completed';

export interface ReportGenerationStore {
  assessmentId: number;
  reportId: number;
  assessmentState: string;

  // Stepper
  sections: ReportSection[];
  activeSectionTemplateId: string;
  activeSection: ReportSection | undefined;
  activeSectionIndex: number;
  setActiveSectionTemplateId: (newSectionTemplateId: string) => void;
  setSections: (sections: ReportSection[]) => void;

  // Preview
  previewRequestCancelTokenSource: CancelTokenSource | null;
  triggerPreviewRequest: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    apiClientFn: (cancelToken: CancelToken) => Promise<{ status: ReportStatus } | undefined>,
  ) => Promise<{ status: ReportStatus } | undefined>;
  cancelPreviewRequest: () => void;
  previewDisabled: boolean;
}

const emptyStore: Pick<
  ReportGenerationStore,
  | 'sections'
  | 'activeSectionTemplateId'
  | 'activeSection'
  | 'activeSectionIndex'
  | 'assessmentId'
  | 'reportId'
  | 'assessmentState'
  | 'previewRequestCancelTokenSource'
  | 'previewDisabled'
> = {
  sections: [],
  activeSectionTemplateId: '',
  activeSection: undefined,
  activeSectionIndex: -1,
  assessmentId: 0,
  reportId: 0,
  assessmentState: '',
  previewRequestCancelTokenSource: null,
  previewDisabled: false,
};

const stateCreator: StateCreator<ReportGenerationStore> = (set, get) => ({
  ...emptyStore,

  setActiveSectionTemplateId: newSectionTemplateId => {
    const currentState = get();
    const activeSectionIndex = currentState.sections.findIndex(s => s.template.id === newSectionTemplateId);
    const activeSection = activeSectionIndex === -1 ? undefined : currentState.sections[activeSectionIndex];

    set({
      activeSection,
      activeSectionIndex,
      activeSectionTemplateId: newSectionTemplateId,
    });
  },

  setSections: sections => {
    set({
      sections,
    });
  },

  triggerPreviewRequest: async apiClientFn => {
    const cancelTokenSource = axios.CancelToken.source();
    set({
      previewRequestCancelTokenSource: cancelTokenSource,
    });

    try {
      const response = await apiClientFn(cancelTokenSource.token);
      set({ previewRequestCancelTokenSource: null });
      return response;
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }
      throw err;
    }
  },
  cancelPreviewRequest: () => {
    const cancelTokenSource = get().previewRequestCancelTokenSource;
    if (!cancelTokenSource) {
      return;
    }

    cancelTokenSource.cancel();
    set({
      previewRequestCancelTokenSource: null,
    });
  },
});

export const useReportGenerationStore = createEnhancedStore(stateCreator);
