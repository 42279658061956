import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();
export const scenarioCatalogsContract = c.router(
  {
    copy: {
      method: 'POST',
      path: '/:scenarioId/copy',
      pathParams: z.object({
        scenarioId: z.number(),
      }),
      body: z.object({
        targetOrgId: z.string().uuid(),
      }),
      responses: {
        201: z.object({
          scenarioId: z.number(),
          scenarioQuestionSetId: z.number(),
        }),
      },
    },
    import: {
      method: 'POST',
      path: '/:organizationId/:scenarioId/import',
      pathParams: z.object({
        organizationId: z.string().uuid(),
        scenarioId: z.coerce.number(),
      }),
      body: z.object({
        scenarioName: z.string().min(0),
      }),
      responses: {
        201: z.object({
          scenarioId: z.number(),
        }),
      },
    },
  },
  {
    pathPrefix: '/scenario-catalogs',
  },
);
