import { fileAlternatePropertySchema } from '@circadian-risk/shared';
import { z } from 'zod';

export const fileSchema = z.object({
  id: z.string().uuid(),
  filepath: z.string(),
  organizationId: z.string().uuid(),
  originalFilename: z.string(),
  entityId: z.string(),
  entityType: z.string(),
  uploadedUserId: z.string().uuid(),
  meta: z.record(z.string(), z.unknown()),
  alternativeFiles: fileAlternatePropertySchema.optional(),
});

export type FileRecord = z.infer<typeof fileSchema>;
