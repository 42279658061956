import { CircadianRoute, ROUTES } from '@circadian-risk/routes';
import { USER_ROLE } from '@circadian-risk/shared';
import { lazyLoadWithVersionCheck } from '@web-app/router/lazyLoadWithVersionCheck';

const ProjectsPage = lazyLoadWithVersionCheck(() => import('./ProjectsPage'));
const ProjectDetailsPage = lazyLoadWithVersionCheck(() => import('./ProjectDetailsPage'));

export const projectRoutes: CircadianRoute[] = [
  {
    path: ROUTES.PROJECTS,
    isExact: true,
    component: ProjectsPage,
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN, USER_ROLE.LOCATION_MANAGER],
  },
  {
    path: ROUTES.PROJECT,
    isExact: true,
    component: ProjectDetailsPage,
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN, USER_ROLE.LOCATION_MANAGER],
  },
];
