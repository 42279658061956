import { FCC } from '@circadian-risk/front-end-utils';
import { Box, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import React, { useContext, useMemo } from 'react';

import { DrawerContext } from '.';
import { DEFAULT_DRAWER_WIDTH, DrawerStyleProps, getDrawerSx } from './styles';

interface MainContentProps extends DrawerStyleProps {
  contentBreakpointDown?: 'xs' | 'sm' | 'md';
  sx?: SxProps<Theme>;
}

export const MainContent: FCC<MainContentProps> = props => {
  const { top, children, drawerWidth, contentBreakpointDown = 'sm', sx } = props;
  const theme = useTheme();
  const drawerContext = useContext(DrawerContext);
  const isOpen = drawerContext?.isOpen;
  const isMobileTablet = useMediaQuery(theme.breakpoints.down(contentBreakpointDown));
  const drawerSx = getDrawerSx({ drawerWidth, top });

  const styleMargins = useMemo(() => {
    if (isMobileTablet) {
      return {
        marginLeft: `max(-100vw, -${drawerWidth ?? DEFAULT_DRAWER_WIDTH}px)`,
      };
    }
    return {
      marginLeft: !isOpen ? -(drawerWidth ?? 300) : 0,
    };
  }, [isMobileTablet, isOpen, drawerWidth]);

  return (
    <Box
      id="perform-main-content"
      sx={[drawerSx.content, ...(isOpen ? [drawerSx.contentShift] : []), ...(Array.isArray(sx) ? sx : [sx])]}
      style={styleMargins}
    >
      {children}
    </Box>
  );
};
