import { BoxProps, Typography } from '@mui/material';
import isNil from 'lodash/isNil';
import React from 'react';

import { Bold, CriticalityCircleCount, HStack, InfoIconWithTooltip } from '../..';

export interface ControlPointsProps extends BoxProps {
  total?: number;
  tooltip?: string;
  criticalityCounts: {
    high: number;
    medium: number;
    low: number;
  };

  compact?: boolean;
}

export const ControlPoints: React.FC<ControlPointsProps> = ({
  total,
  tooltip,
  criticalityCounts,
  compact,
  ...boxProps
}) => {
  return (
    <HStack noFullWidth {...boxProps}>
      {!isNil(total) && (
        <Typography variant={compact ? 'button' : 'h5'} color={theme => theme.palette.text.secondary}>
          <Bold>{total}</Bold>
        </Typography>
      )}
      <HStack noFullWidth>
        <CriticalityCircleCount value={criticalityCounts.high} criticality={'3'} />
        <CriticalityCircleCount value={criticalityCounts.medium} criticality={'2'} />
        <CriticalityCircleCount value={criticalityCounts.low} criticality={'1'} />
      </HStack>
      {tooltip && <InfoIconWithTooltip tooltipContent={tooltip} />}
    </HStack>
  );
};
