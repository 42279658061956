import { TextField, TextFieldProps } from '@mui/material';
import { useEffect, useState } from 'react';

type ControlledTextFieldProps = {
  value: string | null | undefined;
  onBlur: (value: string) => void;
  textFieldProps?: Partial<Omit<TextFieldProps, 'value' | 'onBlur' | 'onChange' | 'defaultValue'>>;
};

export const ControlledTextField: React.FC<ControlledTextFieldProps> = ({ value, textFieldProps, onBlur }) => {
  const [internalValue, setInternalValue] = useState<string>(value ?? '');

  useEffect(() => {
    setInternalValue(value ?? '');
  }, [value]);

  return (
    <TextField
      {...textFieldProps}
      value={internalValue}
      onChange={e => setInternalValue(e.target.value)}
      onBlur={() => onBlur(internalValue)}
    />
  );
};
