import { AlignCell } from '@circadian-risk/data-grid';
import { AvatarGroup, BasicTable, Bold } from '@circadian-risk/presentational';

import { commonTableProps } from '../../CreateScheduledAssessment/steps/SummaryStep/components/common-table-props';
import { AssignmentTeamRow } from './types';

type Props = {
  teams: AssignmentTeamRow[];
};

type ColumnKey = 'name' | 'users' | 'assignmentType';

type RowData = Record<ColumnKey, React.ReactNode>;

const headers: { key: ColumnKey; name: string }[] = [
  {
    key: 'name',
    name: 'Name',
  },
  {
    key: 'users',
    name: 'Users',
  },
  {
    key: 'assignmentType',
    name: 'Assigned or Subscribed',
  },
];

export const TeamsTabContent: React.FC<Props> = ({ teams }) => {
  const rowData = teams.map<RowData>(team => {
    return {
      name: team.name,
      users: (
        <AlignCell justifyContent="flex-start">
          <AvatarGroup
            isStacked
            max={6}
            avatars={team.users.map(u => ({
              name: u.displayName,
              lastSignedInAt: u.lastSignedInAt,
              image: u.avatar,
            }))}
          />
        </AlignCell>
      ),
      assignmentType: <Bold>{team.assignmentType === 'assignee' ? 'Assigned' : 'Subscribed'}</Bold>,
    };
  });

  return <BasicTable data={rowData} headers={headers} {...commonTableProps} />;
};
