import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useQuery,
} from '@apollo/client';
import { DeepNonNullable } from '@circadian-risk/shared';
import React from 'react';
import isEqual from 'react-fast-compare';

import { GqlPlaceholder } from '../..';
import { Variant } from './types';

export interface QueryComponentOptions<TData, TVariables extends OperationVariables = OperationVariables>
  extends QueryHookOptions<TData, TVariables> {
  variant: Variant;
  children: (
    result: DeepNonNullable<Pick<QueryResult<TData, TVariables>, 'data' | 'refetch'>>,
  ) => React.ReactNode & JSX.Element;
  node: DocumentNode | TypedDocumentNode<TData, TVariables>;
}

export function QueryWrapper<Data, Variables extends OperationVariables>({
  children,
  node,
  variant,
  ...options
}: QueryComponentOptions<Data, Variables>) {
  const { refetch, data, loading, error } = useQuery(node, options);

  if (loading || error) {
    return <GqlPlaceholder state={{ loading, error }} variant={variant.type} title={variant.title} />;
  }

  return children({ data: data as NonNullable<Data>, refetch });
}

export const QueryWrapperMemoized = React.memo(QueryWrapper, isEqual) as typeof QueryWrapper;
