import { useApiClient } from '@circadian-risk/api-client-provider';
import {
  AssessmentAssignment,
  AssessmentAssignmentGroupRow,
  AssessmentAssignmentTeamRow,
  AssessmentAssignmentUserRow,
} from '@circadian-risk/api-contract';
import { useNotification } from '@circadian-risk/front-end-utils';
import { useDialog } from '@circadian-risk/presentational';
import { AssessmentState, USER_ROLE } from '@circadian-risk/shared';
import { useOrganizationId } from '@circadian-risk/stores';
import { Box, Grid } from '@mui/material';
import { useHasRole } from '@web-app/lib/useHasRole';
import { AssessmentLocationTreeCard } from '@web-app/smart-components/AssessmentLocationTree/AssessmentLocationTreeCard';
import { useMemo } from 'react';

import { ScenariosTotalScore } from '../../ScenarioAssessmentDetailsPage';
import { AssessmentAssignmentCard } from '../AssessmentAssignment/AssessmentAssignmentCard';
import { EditAssessmentAssignmentDialog } from '../AssessmentAssignment/EditAssessmentAssignmentDialog';
import { AssessmentCompliancePercentCard } from '../AssessmentCompliancePercentCard';
import { TOTAL_STATS_ID } from '../AssessmentCompliancePercentCard/AssessmentCompliancePercentCard';
import { ScenarioStatInput } from '../AssessmentCompliancePercentCard/types';
import { useAssessmentDetailsData } from '../DetailsCard/hooks/useAssessmentDetailsData';
import { ScheduledAssessmentDetailsCard } from '../DetailsCard/ScheduledAssessmentDetailsCard';
import { SingleAssessmentDetailsCard } from '../DetailsCard/SingleAssessmentDetailsCard';
import { extractCriticalityNumbersFromScore } from './helpers';

export interface ScenarioAssessmentOverviewProps {
  assessmentId: number;
  scenariosTotalScore: ScenariosTotalScore;
  scenarioStatInput: ScenarioStatInput[];
}

export const ScenarioAssessmentOverview: React.FC<ScenarioAssessmentOverviewProps> = ({
  assessmentId,
  scenariosTotalScore,
  scenarioStatInput,
}) => {
  const organizationId = useOrganizationId();
  const assessment = useAssessmentDetailsData(assessmentId);
  const assessmentState = assessment.state;
  const { tsRestClient } = useApiClient();
  const hasLocationManagerRole = useHasRole(USER_ROLE.LOCATION_MANAGER);
  const { displayError, displaySuccess } = useNotification();

  const assignment: AssessmentAssignment = useMemo(() => {
    return {
      user: [
        ...assessment.users.map<AssessmentAssignmentUserRow>(u => ({ id: u.user_id, type: 'assignee' })),
        ...assessment.subscribedUsers.map<AssessmentAssignmentUserRow>(su => ({ id: su.user_id, type: 'subscriber' })),
      ],
      userGroup: [
        ...assessment.assignedGroups.map<AssessmentAssignmentGroupRow>(ag => ({
          id: ag.user_group_id,
          type: 'assignee',
        })),
        ...assessment.subscribedGroups.map<AssessmentAssignmentGroupRow>(sg => ({
          id: sg.user_group_id,
          type: 'subscriber',
        })),
      ],
      teams: [
        ...assessment.assignedTeams.map<AssessmentAssignmentTeamRow>(at => ({
          id: at.layer_team_id,
          type: 'assignee',
        })),
        ...assessment.subscribedTeams.map<AssessmentAssignmentTeamRow>(st => ({
          id: st.layer_team_id,
          type: 'subscriber',
        })),
      ],
    };
  }, [
    assessment.assignedGroups,
    assessment.assignedTeams,
    assessment.subscribedGroups,
    assessment.subscribedTeams,
    assessment.subscribedUsers,
    assessment.users,
  ]);
  const {
    isOpen: editingAssignment,
    closeDialog: closeEditAssignmentDialog,
    openDialog: openEditAssignmentDialog,
  } = useDialog();

  const isScheduledAssessment = assessment.schedule_id !== null;
  const handleEditAssignmentClick =
    hasLocationManagerRole && assessmentState !== AssessmentState.Complete && !isScheduledAssessment
      ? openEditAssignmentDialog
      : undefined;

  const handleAssignmentEdit = async (assignmentUpdate: AssessmentAssignment) => {
    try {
      const { status } = await tsRestClient.assessments.updateAssignment({
        body: {
          assignment: assignmentUpdate,
        },
        params: {
          organizationId,
          id: assessment.id,
        },
      });

      if (status === 201) {
        displaySuccess('Successfully updated users assigned to assessment');
      }
    } catch (e) {
      displayError(e);
    }
  };

  const relevantLocationIds = assessment.locations.map(l => l.node_id);

  return (
    <Grid aria-label={'assessment-overview'} item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {assessment.schedule_id !== null ? (
                <ScheduledAssessmentDetailsCard assessmentId={assessment.id} />
              ) : (
                <SingleAssessmentDetailsCard assessmentId={assessment.id} />
              )}
            </Grid>

            <Grid item xs={12}>
              <AssessmentAssignmentCard
                onEditClick={handleEditAssignmentClick}
                assignment={assignment}
                relevantLocationIds={relevantLocationIds}
              />

              {hasLocationManagerRole && (
                <EditAssessmentAssignmentDialog
                  open={editingAssignment}
                  assignment={assignment}
                  onClose={closeEditAssignmentDialog}
                  onSubmit={handleAssignmentEdit}
                  locationIds={relevantLocationIds}
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <AssessmentLocationTreeCard organizationId={organizationId} assessmentId={assessment.id} />
            <AssessmentCompliancePercentCard
              scenarioStatInput={scenarioStatInput}
              overallStats={{
                id: TOTAL_STATS_ID,
                irrelevantQuestionsCount: 0,
                percentage: scenariosTotalScore.control_percentage,
                criticalityNumbers: extractCriticalityNumbersFromScore(scenariosTotalScore),
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
