import { combineSx, imageSrc } from '@circadian-risk/front-end-utils';
import { alpha, Box, SxProps, Theme, Tooltip, useTheme } from '@mui/material';
import initials from 'initials';
import isString from 'lodash/isString';
import React, { CSSProperties, useMemo, useRef } from 'react';
import isEqual from 'react-fast-compare';
import * as z from 'zod';

import { FONT_SIZES } from './helpers';

export enum ItemIconSizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
  extraLarge = 'extraLarge',
}

export enum ItemIconStates {
  compliant = 'compliant',
  deficient = 'deficient',
}

export const ITEM_ICON_SIZES = {
  small: { width: 24, height: 24 },
  medium: { width: 32, height: 32 },
  large: { width: 48, height: 48 },
  extraLarge: { width: 64, height: 64 },
};

type ItemIconFile =
  | {
      id?: string;
      filepath?: string;
      meta?: object;
    }
  | {
      id: string;
      filepath: string | null;
    };

export interface ItemIconProps {
  alt: string; // necessary for tooltip & backup text
  icon?: React.ReactNode;
  iconSrc?: string | ItemIconFile | null | undefined;
  disabled?: boolean;
  size?: ItemIconSizes;
  tooltipText?: string | JSX.Element;
  backgroundColor?: string;
  isBorderDashed?: boolean;
  hasBorder?: boolean;
  borderSize?: number;
  borderColor?: string;
  isInverted?: boolean;
  hasShadow?: boolean;
  style?: CSSProperties;
  teardrop?: boolean;
  tooltipDisabled?: boolean;
  contentSx?: SxProps<Theme>;
  onRootClick?: (target: HTMLDivElement) => void;
}
export const itemIconSchema = z.object({
  alt: z.string(),
});
export const itemIconArraySchema = z.array(itemIconSchema).nonempty();

const contentTeardropSx: SxProps = { display: 'flex', transform: 'rotate(135deg)' };

const NonMemoizedItemIcon: React.FC<ItemIconProps> = ({
  alt,
  iconSrc,
  size = ItemIconSizes.medium,
  tooltipText,
  isInverted,
  backgroundColor,
  isBorderDashed,
  hasBorder = false,
  hasShadow = false,
  teardrop,
  borderColor,
  borderSize,
  tooltipDisabled = false,
  contentSx,
  onRootClick,
  icon,
  ...rest
}) => {
  const rootRef = useRef<HTMLDivElement | null>();
  const src = isString(iconSrc) ? imageSrc(iconSrc) : imageSrc(iconSrc?.filepath ?? '');
  const theme = useTheme();

  const tooltipEnabled = !tooltipDisabled;
  const isClickable = onRootClick != null;
  const shouldShowPointerCursor = isClickable || tooltipEnabled;
  const mainColor = backgroundColor ?? theme.palette.primary.main;
  const primaryColor = isInverted ? mainColor : theme.palette.common.white;
  const secondaryColor = isInverted ? theme.palette.common.white : mainColor;

  const contentText = initials(alt).toString().slice(0, 2);

  const innerContent = useMemo(() => {
    const baseStyles = combineSx(contentSx, teardrop && contentTeardropSx);

    if (icon) {
      return <Box sx={baseStyles}>{icon}</Box>;
    }

    if (src && src !== 'custom') {
      return (
        <Box
          aria-describedby="icon image"
          height="70%"
          width="70%"
          sx={combineSx(baseStyles, {
            fontSize: FONT_SIZES[size],
            filter: `drop-shadow(${ITEM_ICON_SIZES[size].width}px 0 0px ${primaryColor})`,
            position: 'relative',
          })}
        >
          <img
            style={{
              left: `-${ITEM_ICON_SIZES[size].width}px`,
              position: 'absolute',
              top: 0,
            }}
            src={src}
            width="100%"
            height="100%"
            alt=""
          />
        </Box>
      );
    }

    return (
      <Box
        aria-describedby="icon text content"
        component="span"
        sx={combineSx(baseStyles, { fontSize: FONT_SIZES[size] })}
      >
        {contentText.toUpperCase()}
      </Box>
    );
  }, [contentSx, contentText, icon, primaryColor, size, src, teardrop]);

  const TooltipWrapper: React.FC<{ children: React.ReactElement }> = ({ children }) =>
    tooltipDisabled ? (
      children
    ) : (
      <Tooltip disableInteractive title={tooltipText ?? alt} placement={'bottom'}>
        {children as React.ReactElement}
      </Tooltip>
    );

  const extraWrapperSx = useMemo(() => {
    const sx: CSSProperties = {};
    if (hasBorder) {
      sx.border = `${borderSize ?? 4}px ${isBorderDashed ? 'dashed' : 'solid'} ${borderColor ?? primaryColor}`;
    }

    if (hasShadow) {
      sx.boxShadow = `0 0 24px ${alpha(mainColor, 1.0)}`;
    }
    return sx;
  }, [borderColor, borderSize, hasBorder, hasShadow, isBorderDashed, mainColor, primaryColor]);

  return (
    <Box
      ref={rootRef}
      onClick={
        onRootClick
          ? () => {
              if (rootRef.current) {
                onRootClick(rootRef.current);
              }
            }
          : undefined
      }
      role={onRootClick ? 'button' : 'presentation'}
      sx={[
        {
          userSelect: 'none',
          flexShrink: 0,
          alignItems: 'center',
          backgroundColor: secondaryColor,
          color: primaryColor,
          cursor: shouldShowPointerCursor ? 'pointer' : 'initial',
          display: 'inline-flex',
          justifyContent: 'center',
          overflow: 'hidden',
          ...ITEM_ICON_SIZES[size],
          ...extraWrapperSx,
        },
        teardrop
          ? {
              transformOrigin: 'top left',
              transform: 'rotate(-135deg)',
              borderRadius: 'initial',
              borderTopRightRadius: '50%',
              borderBottomLeftRadius: '50%',
              borderBottomRightRadius: '50%',
            }
          : { borderRadius: '50%' },
      ]}
      {...rest}
    >
      <TooltipWrapper>{innerContent}</TooltipWrapper>
    </Box>
  );
};

export const ItemIcon = React.memo(NonMemoizedItemIcon, isEqual);
