import isFunction from 'lodash/isFunction';

import { ErrorFormatter, ErrorParam } from '../interfaces';
import { convertConstraintsToUserFriendlyError, extractErrorMessage, formatDisplayMessage } from '.';

export const getDisplayMessage = (error: ErrorParam, formatterOrFriendlyMessage?: ErrorFormatter | string) => {
  const rawMessage = extractErrorMessage(error);
  const message = convertConstraintsToUserFriendlyError(rawMessage);

  const getMessageFromFormatter = () => {
    if (isFunction(formatterOrFriendlyMessage)) {
      return formatterOrFriendlyMessage(error);
    }
    return formatterOrFriendlyMessage;
  };

  return formatterOrFriendlyMessage ? getMessageFromFormatter() : formatDisplayMessage(message);
};
