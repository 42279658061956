import {
  GetAssessmentNarrativeDocument,
  useGetAssessmentNarrativeQuery,
  useUpdateAssessmentFieldMutation,
} from '@circadian-risk/client-graphql-hooks';
import { useQueryState } from '@circadian-risk/front-end-utils';
import { Bold, InfoIconWithTooltip, PropertyDescriptionRichText } from '@circadian-risk/presentational';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import isEmpty from 'lodash/isEmpty';

import { NarrativeEditDialog, NarrativeEditDialogProps } from './NarrativeEditDialog';

const EMPTY_CONTENT = 'There is no content for this section. Select the edit button to fill in information';
export const EXECUTIVE_SUMMARY_FIELDS = [
  { header: 'Background', field: 'background' },
  { header: 'Findings', field: 'findings' },
  { header: 'Recommendations', field: 'recommendations' },
];

export interface ScenarioAssessmentNarrativeProps {
  assessmentId: number;
  organizationId: string;
}

export const ScenarioAssessmentNarrative: React.FC<ScenarioAssessmentNarrativeProps> = props => {
  const { assessmentId, organizationId } = props;
  const [editing, setEditing] = useQueryState('editing', null);

  const [updateAssessmentField] = useUpdateAssessmentFieldMutation();
  const result = useGetAssessmentNarrativeQuery({
    variables: {
      assessmentId,
      organizationId,
    },
  });
  const data = result.data?.v2_assessments_by_pk;

  if (!data) {
    return null;
  }
  const { background, findings, recommendations, notes } = data;

  const getDefaultValues = () => {
    return {
      background,
      recommendations,
      findings,
    };
  };

  const renderHeaderTitle = (title: string, tooltip: string, onEditClick: ButtonProps['onClick']) => {
    return (
      <Box display={'flex'} alignItems={'center'}>
        <Box>{title}</Box>
        <Box display="flex" flexGrow={1}>
          <InfoIconWithTooltip tooltipContent={tooltip} tooltipProps={{ placement: 'bottom' }} />
        </Box>
        <Box>
          <Button onClick={onEditClick} variant={'contained'} color={'primary'} startIcon={<EditIcon />}>
            Edit
          </Button>
        </Box>
      </Box>
    );
  };

  const handleNotesSave: NarrativeEditDialogProps['onSave'] = async changes => {
    const newValue = changes.notes;
    if (newValue === notes) {
      setEditing(null);
      return null;
    }

    const result = await updateAssessmentField({
      variables: {
        organizationId,
        assessmentId,
        input: {
          notes: newValue,
        },
      },
    });

    setEditing(null);
    return !result.errors;
  };

  const handleSummarySave: NarrativeEditDialogProps['onSave'] = async changes => {
    setEditing(null);
    const result = await updateAssessmentField({
      variables: {
        organizationId: props.organizationId,
        assessmentId: props.assessmentId,
        input: changes,
      },
      refetchQueries: [GetAssessmentNarrativeDocument],
    });

    return !result.errors;
  };

  const renderContent = (value: string | null) => {
    if (isEmpty(value)) {
      return <Typography variant={'body1'}>{EMPTY_CONTENT}</Typography>;
    }
    return <PropertyDescriptionRichText value={value} />;
  };

  const getFields = () => {
    if (editing === null) {
      return [];
    }
    return editing === 'notes' ? [{ header: 'Notes', field: 'notes' }] : EXECUTIVE_SUMMARY_FIELDS;
  };

  return (
    <>
      <Grid aria-label={'assessment-narrative'} item xs={12}>
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <Card>
              <CardHeader
                title={renderHeaderTitle(
                  'Assessment Notes',
                  'Information contained in this section will not be included in the Assessment Report',
                  () => setEditing('notes'),
                )}
              />
              <CardContent>{renderContent(notes)}</CardContent>
            </Card>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Card>
              <CardHeader
                title={renderHeaderTitle(
                  'Executive Summary',
                  'Information contained in this section will be included in the Assessment Report',
                  () => setEditing('executive-summary'),
                )}
              />
              <CardContent>
                {EXECUTIVE_SUMMARY_FIELDS.map(({ field, header }) => (
                  <Box key={`executive-summary-field-${field}`}>
                    <Bold weight="bold">
                      <Typography variant={'h6'}>{header}</Typography>{' '}
                    </Bold>

                    {renderContent(data[field as 'background' | 'findings' | 'recommendations'])}
                    <br />
                  </Box>
                ))}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <NarrativeEditDialog
        title={editing === 'notes' ? 'Assessment Notes' : 'Executive Summary'}
        open={editing !== null}
        onClose={() => {
          setEditing(null);
        }}
        onSave={editing === 'notes' ? handleNotesSave : handleSummarySave}
        fields={getFields()}
        defaultValues={editing === 'notes' ? { notes } : getDefaultValues()}
      />
    </>
  );
};
