import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();

const uploadQuestionSetResponseSchema = z.object({
  jobId: z.number(),
});

export type UploadQuestionSetResponse = z.infer<typeof uploadQuestionSetResponseSchema>;

export const scenariosContract = c.router(
  {
    downloadTemplate: {
      method: 'POST',
      path: '/:organizationId/download-template',
      pathParams: z.object({
        organizationId: z.string().uuid(),
      }),
      body: z.object({
        layerTagIds: z.array(z.number()),
      }),
      responses: {
        201: z.object({}),
      },
    },
    uploadQuestionSet: {
      method: 'POST',
      path: '/:organizationId/upload-question-set/:scenarioId',
      contentType: 'multipart/form-data',
      pathParams: z.object({
        organizationId: z.string().uuid(),
        scenarioId: z.coerce.number(),
      }),
      query: z.object({
        name: z.string({ description: 'Name for the question set' }),
      }),
      body: z.object({
        file: z.custom<File>(),
      }),
      responses: {
        201: uploadQuestionSetResponseSchema,
      },
    },
    setPrimaryQuestionSet: {
      method: 'PATCH',
      path: '/:organizationId/primary-question-set/:scenarioId',
      pathParams: z.object({
        organizationId: z.string().uuid(),
        scenarioId: z.coerce.number(),
      }),
      body: z.object({
        scenarioQuestionSetId: z.number(),
      }),
      responses: {
        201: z.object({
          scenarioQuestionSetId: z.number(),
        }),
      },
    },
  },
  {
    pathPrefix: '/scenarios',
  },
);
