import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

import { PropertyFieldProps } from '../types';

export type PropertyDropdownFieldProps = PropertyFieldProps & { options: string[] };

/**
 * @deprecated Please use `PropertySelectField`
 */
export const PropertyDropdownField: React.FC<PropertyDropdownFieldProps> = ({
  label,
  htmlName,
  control,
  options,
  disabled,
  rules,
  boxProps,
  errorMessage,
}) => {
  return (
    <Box {...boxProps}>
      <Controller
        control={control}
        name={htmlName}
        defaultValue={null}
        rules={rules}
        render={fieldProps => {
          const { onBlur, onChange, value } = fieldProps.field;
          const labelId = `dropdown-field-${htmlName}-label`;
          return (
            <FormControl variant="outlined" fullWidth error={Boolean(errorMessage)}>
              <InputLabel id={labelId}>{label}</InputLabel>
              <Select
                value={value ?? ''}
                onChange={e => onChange(e.target.value)}
                onBlur={onBlur}
                labelId={labelId}
                label={label}
                displayEmpty
                fullWidth
                variant="outlined"
                data-name={label}
                disabled={disabled}
              >
                {options.map(option => {
                  return (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          );
        }}
      />
    </Box>
  );
};
