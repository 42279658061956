import { useEffect, useState } from 'react';
import { useKey, useLatest } from 'react-use';

import { SEARCH_ID } from '../components/constants';
import { isSelectAllFocused } from '../filter.helpers';

export const useFilterNavigation = (totalRows: number) => {
  const [index, setIndex] = useState(0);

  // the implementation of useKey seem to have a state reference to the event handlers
  const totalRowsLatest = useLatest(totalRows);

  // In case the total rows change we need to reset
  useEffect(() => {
    setIndex(0);
  }, [totalRows]);

  const focusOnSearch = () => {
    const searchEl = document.getElementById(SEARCH_ID);
    if (searchEl && isSelectAllFocused()) {
      searchEl.focus();
    }
  };

  const handleArrowUp = () => {
    focusOnSearch();
    setIndex(prev => {
      const next = prev - 1;
      return next === -1 ? totalRowsLatest.current - 1 : next;
    });
  };

  const handleArrowDown = () => {
    focusOnSearch();
    setIndex(prev => {
      const next = prev + 1;
      return next === totalRowsLatest.current ? 0 : next;
    });
  };

  useKey('ArrowUp', handleArrowUp);
  useKey('ArrowDown', handleArrowDown);

  return { index, setIndex };
};
