import { getSemanticMeaning } from '@circadian-risk/assessment-components';
import {
  Enum_Answer_Responses_Enum,
  GetTemplateLocationQuestionsAndActiveAnswersQuery,
  useGetTemplateLocationQuestionsAndActiveAnswersQuery,
} from '@circadian-risk/client-graphql-hooks';
import { AnswerSemanticMeaning } from '@circadian-risk/front-end-utils';
import { USER_ROLE } from '@circadian-risk/shared';
import { useOrgRoleQueryPrecedence } from '@circadian-risk/stores';
import size from 'lodash/size';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { ScenarioFormData } from './ScenarioAssessmentForm.types';

export interface QuestionAnswerFromTemplate {
  is_deficient: boolean;
  deficient_answer: Enum_Answer_Responses_Enum;
  response: Enum_Answer_Responses_Enum | null;
  question_id: string;
  node_id: string;
}

type CalculateCompletionConditions = Pick<ScenarioFormData, 'ratificationRequired' | 'importActiveAnswers'> & {
  answersSummary: GetTemplateLocationQuestionsAndActiveAnswersQuery['fn_active_answers_summary'];
};

const semanticPredicate = (aa: QuestionAnswerFromTemplate, response: AnswerSemanticMeaning) => {
  return getSemanticMeaning(aa.response, aa.deficient_answer) === response;
};

export const calculateCompletionConditions = ({
  importActiveAnswers: willImport,
  ratificationRequired: ratifyOptions,
  answersSummary,
}: CalculateCompletionConditions) => {
  const newQuestionsCount = answersSummary.filter(aa => aa.response == null).length;
  const allQuestionAnswers =
    answersSummary.map<QuestionAnswerFromTemplate>(aa => {
      return {
        is_deficient: aa.is_deficient ?? false,
        response: aa.response as Enum_Answer_Responses_Enum | null,
        question_id: aa.question_id,
        node_id: aa.node_id,
        deficient_answer: aa.question!.deficient_answer,
      };
    }) ?? [];

  const importedAnswers = allQuestionAnswers.filter(a => a.response !== null);

  const answerCountsByStatus = {
    compliant: size(importedAnswers.filter(aa => semanticPredicate(aa, 'compliant'))),
    deficient: size(importedAnswers.filter(aa => semanticPredicate(aa, 'deficient'))),
    n_a: size(importedAnswers.filter(aa => semanticPredicate(aa, 'n_a'))),
    n_o: size(importedAnswers.filter(aa => semanticPredicate(aa, 'n_o'))),
  };

  const ratifyEnabled = {
    compliant: willImport ? Boolean(ratifyOptions?.compliant) : false,
    deficient: willImport ? Boolean(ratifyOptions?.deficient) : false,
    n_a: willImport ? Boolean(ratifyOptions?.n_a) : false,
    n_o: willImport ? Boolean(ratifyOptions?.n_o) : false,
  };

  const ratifyCounts = {
    compliant: ratifyEnabled.compliant ? answerCountsByStatus.compliant : 0,
    deficient: ratifyEnabled.deficient ? answerCountsByStatus.deficient : 0,
    n_a: ratifyEnabled.n_a ? answerCountsByStatus.n_a : 0,
    n_o: ratifyEnabled.n_o ? answerCountsByStatus.n_o : 0,
  };

  const totalQuestionsCount = allQuestionAnswers.length;
  const ratifyTotal = ratifyCounts.deficient + ratifyCounts.compliant + ratifyCounts.n_a + ratifyCounts.n_o;
  const toDoTotal = willImport ? newQuestionsCount + ratifyTotal : totalQuestionsCount;
  const importTotal = willImport ? importedAnswers.length : 0;
  const areAnySelectedToRatify = ratifyTotal > 0;

  return {
    importedAnswers,
    allQuestionAnswers,
    toDoTotal,
    totalQuestionsCount,
    newQuestionsCount,
    answerCountsByStatus,
    importTotal,
    ratifyTotal,
    ratifyCounts,
    ratifyEnabled,
    areAnySelectedToRatify,
    willImport,
  };
};

export const useCompletionConditions = () => {
  const queryContext = useOrgRoleQueryPrecedence(USER_ROLE.ORGANIZATION_ADMIN, USER_ROLE.LOCATION_MANAGER);
  const { watch, setValue } = useFormContext<ScenarioFormData>();

  const watchRatifyOptions = watch('ratificationRequired');
  const importActiveAnswers = watch('importActiveAnswers');
  const templateId = watch('assessmentTemplateId');
  const nodeIds = watch('locationIds');

  const results = useGetTemplateLocationQuestionsAndActiveAnswersQuery({
    variables: { templateId, nodeIds },
    skip: templateId == null,
    context: queryContext,
    fetchPolicy: 'network-only',
  });

  const { data, loading } = results;

  const result = calculateCompletionConditions({
    importActiveAnswers,
    answersSummary: data?.fn_active_answers_summary ?? [],
    ratificationRequired: watchRatifyOptions,
  });

  const totalImportableAnswers = result.totalQuestionsCount;
  const shouldResetForm = !loading && totalImportableAnswers === 0;

  useEffect(() => {
    if (shouldResetForm) {
      setValue('importActiveAnswers', false);
    }
  }, [shouldResetForm, setValue]);

  return result;
};
