import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { OptionsObject, SnackbarAction, SnackbarMessage, useSnackbar } from 'notistack';
import React, { memo, useCallback, useMemo } from 'react';

import { isAxiosError } from '../isAxiosError';
import { FCC } from '../react-types';
import NotificationContext from './context/NotificationContext';
import { formatDisplayMessage } from './helpers';
import { getDisplayMessage } from './helpers/getDisplayMessage';
import { ErrorFormatter, ErrorParam, NotificationProviderContext } from './interfaces';

const DEFAULT_TIMEOUT = 5000;
const INFINITY_TIMEOUT_DELAY = 9999999;

const placementSnackbarOptions: OptionsObject = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
  autoHideDuration: DEFAULT_TIMEOUT,
};

export interface NotificationProviderProps {
  onError?: (error: unknown) => void;
}

/**
 * `NotificationProvider` is a HOC component that manages the snackbar display
 * This HOC requires `notistack` in order to use enqueueSnackbar
 */
export const NotificationProvider: FCC<NotificationProviderProps> = memo(({ children, onError }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const display = useCallback(
    (message: SnackbarMessage, options?: OptionsObject) => {
      enqueueSnackbar(formatDisplayMessage(message), options);
    },
    [enqueueSnackbar],
  );

  const displaySuccess = useCallback(
    (message: SnackbarMessage, action?: SnackbarAction) => {
      enqueueSnackbar(formatDisplayMessage(message), {
        ...placementSnackbarOptions,
        variant: 'success',
        action,
      });
    },
    [enqueueSnackbar],
  );

  const displayWarning = useCallback(
    (message: SnackbarMessage, action?: SnackbarAction) => {
      enqueueSnackbar(formatDisplayMessage(message), {
        ...placementSnackbarOptions,
        variant: 'warning',
        action: currentKey => {
          return (
            <>
              {action}
              <IconButton
                onClick={() => {
                  closeSnackbar(currentKey);
                }}
                size="large"
              >
                <CloseIcon htmlColor={'white'} />
              </IconButton>
            </>
          );
        },
        resumeHideDuration: INFINITY_TIMEOUT_DELAY,
      });
    },
    [closeSnackbar, enqueueSnackbar],
  );

  const displayError = useCallback(
    (
      error: ErrorParam,
      formatterOrFriendlyMessage?: ErrorFormatter | string,
      skipSentryReport?: boolean,
      action?: SnackbarAction,
    ) => {
      const isUnauthorizedError = isAxiosError(error) && error.response?.status === 401;

      // Shallow 401 errors, they are handled by the useSupertokensUnauthorized hook
      if (isUnauthorizedError) {
        return;
      }

      const displayMessage = getDisplayMessage(error, formatterOrFriendlyMessage);

      // log warning to console so that user can be directed by customer support to see details.
      // eslint-disable-next-line no-console
      console.warn(`[NotificationProvider] [${new Date().toISOString()}] `, displayMessage, error);

      enqueueSnackbar(displayMessage, {
        ...placementSnackbarOptions,
        variant: 'error',
        action: currentKey => {
          return (
            <>
              {action}
              <IconButton
                onClick={() => {
                  closeSnackbar(currentKey);
                }}
                size="large"
              >
                <CloseIcon htmlColor={'white'} />
              </IconButton>
            </>
          );
        },
        resumeHideDuration: INFINITY_TIMEOUT_DELAY,
      });

      if (onError && !skipSentryReport) {
        onError(error);
      }
    },
    [closeSnackbar, enqueueSnackbar, onError],
  );

  const contextValue: NotificationProviderContext = useMemo(
    () => ({
      displayError,
      displaySuccess,
      displayWarning,
      close: closeSnackbar,
      display,
    }),
    [closeSnackbar, display, displayError, displaySuccess, displayWarning],
  );

  return <NotificationContext.Provider value={contextValue}>{children}</NotificationContext.Provider>;
});
