import * as Sentry from '@sentry/react';
import { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

/**
 * An error boundary that displays a span. Useful for catching errors in
 * AG Grid cell renderers and other small UI components that should not
 * cause an entire error page takeover
 */
export class SpanErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, _errorInfo: ErrorInfo) {
    Sentry.captureException(error);
  }

  public render() {
    if (this.state.hasError) {
      return <span>Error</span>;
    }

    return this.props.children;
  }
}
