import { HStack, InfoBox, VStack } from '@circadian-risk/presentational';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { AnswerSummaryBarSmart } from '../../components/AnswerSummaryBarSmart';
import { AnswerSummaryTableSmart } from '../../components/AnswerSummaryTableSmart';
import { useAssessmentFormStore } from './AssessmentFormStore';
import { ScenarioFormData } from './ScenarioAssessmentForm.types';
import { useCompletionConditions } from './useCompletionConditions';

const hasImportableQuestionsText =
  'Some of the questions from this template have already been answered at this location. Would you like to import the active answers into this assessment?';
const noImportableAnswersText =
  'No questions from this template have ever been answered at this location. Assessors will need to start fresh and answer all questions.';

export const CompletionConditionsStep: React.FC = () => {
  const editing = useAssessmentFormStore(state => state.editing);
  const { watch, control } = useFormContext<ScenarioFormData>();

  const willImport = watch('importActiveAnswers');
  const assessmentTemplateId = watch('assessmentTemplateId');
  const nodeIds = watch('locationIds');

  const { importedAnswers, totalQuestionsCount, newQuestionsCount, answerCountsByStatus, areAnySelectedToRatify } =
    useCompletionConditions();

  const hasImportableAnswers = Boolean(importedAnswers.length);
  const shouldDisplayInfo = willImport && !areAnySelectedToRatify && hasImportableAnswers;

  return (
    <>
      <HStack spacing={4} alignItems="flex-start">
        <VStack spacing={1} flex={1}>
          <Typography variant="h6">Import Answers and Required Actions</Typography>
          {<Typography>{hasImportableAnswers ? hasImportableQuestionsText : noImportableAnswersText}</Typography>}
          <Box>
            <Controller
              name="importActiveAnswers"
              control={control}
              render={({ field }) => {
                const handleChange = (newValue: string) => {
                  if (newValue === 'true') {
                    field.onChange(true);
                  } else {
                    field.onChange(false);
                  }
                };

                const value = field.value ? 'true' : 'false';

                return (
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="Import Answers"
                      name="importAnswers"
                      value={value}
                      onChange={e => handleChange(e.target.value)}
                    >
                      <FormControlLabel
                        value="false"
                        control={<Radio color="primary" />}
                        label={<span>Start fresh (no answers will be imported)</span>}
                        disabled={editing}
                      />
                      <FormControlLabel
                        value="true"
                        control={<Radio color="primary" />}
                        label={<span>Import ({importedAnswers.length} answers will be imported)</span>}
                        disabled={editing || !hasImportableAnswers}
                      />
                    </RadioGroup>
                  </FormControl>
                );
              }}
            />

            <Box ml={4} display={willImport ? 'block' : 'none'}>
              <FormLabel>{'Selected groups will require assessor to review & ratify answers:'}</FormLabel>
              <Controller
                defaultValue={{
                  compliant: true,
                  deficient: true,
                  n_a: true,
                  n_o: true,
                }}
                name="ratificationRequired"
                control={control}
                render={({ field }) => {
                  const handleChange = (key: string, newValue: boolean) => {
                    field.onChange({
                      ...field.value,
                      [key]: newValue,
                    });
                  };

                  const value = field.value;
                  return (
                    <FormGroup>
                      <FormControlLabel
                        label={`Compliant (${answerCountsByStatus.compliant})`}
                        control={
                          <Checkbox
                            checked={Boolean(value?.compliant)}
                            onChange={e => handleChange('compliant', e.target.checked)}
                            name="compliant"
                            color="primary"
                            disabled={editing}
                          />
                        }
                      />
                      <FormControlLabel
                        label={`Deficient (${answerCountsByStatus.deficient})`}
                        control={
                          <Checkbox
                            checked={Boolean(value?.deficient)}
                            onChange={e => handleChange('deficient', e.target.checked)}
                            name="deficient"
                            color="primary"
                            disabled={editing}
                          />
                        }
                      />
                      <FormControlLabel
                        label={`N/A (${answerCountsByStatus.n_a})`}
                        control={
                          <Checkbox
                            checked={Boolean(value?.n_a)}
                            onChange={e => handleChange('n_a', e.target.checked)}
                            name="n_a"
                            color="primary"
                            disabled={editing}
                          />
                        }
                      />
                      <FormControlLabel
                        label={`N/O (${answerCountsByStatus.n_o})`}
                        control={
                          <Checkbox
                            checked={Boolean(value?.n_o)}
                            onChange={e => handleChange('n_o', e.target.checked)}
                            name="n_o"
                            color="primary"
                            disabled={editing}
                          />
                        }
                      />
                    </FormGroup>
                  );
                }}
              />
            </Box>
          </Box>
        </VStack>
        {assessmentTemplateId && nodeIds && (
          // setting min width here so the chart doesn't change size when table is shown / hidden
          <VStack minWidth={510} flex={1} spacing={2}>
            <Typography variant="h6">Answers Summary ({totalQuestionsCount} questions total)</Typography>
            <AnswerSummaryBarSmart />
            {willImport && <AnswerSummaryTableSmart />}
          </VStack>
        )}
      </HStack>
      {shouldDisplayInfo && (
        <InfoBox mt={2} disableTypographyWrapper>
          <Typography>
            {importedAnswers.length} answers will be imported, but none will require ratification.
          </Typography>
          {newQuestionsCount > 0 && (
            <Typography>{newQuestionsCount} new questions will require their first answer.</Typography>
          )}
        </InfoBox>
      )}
    </>
  );
};
