import { useApiClient } from '@circadian-risk/api-client-provider';
import { SessionAccessPayload, sessionAccessPayloadSchema } from '@circadian-risk/shared';
import { useOrganizationSessionStore, useUserSessionStore } from '@circadian-risk/stores';
import { AxiosError } from 'axios';
import { useEffect, useRef } from 'react';
import Session from 'supertokens-web-js/recipe/session';
import { clear, suspend } from 'suspend-react';

import { useSupertokensSession } from './useSupertokensSession';

const createOrgDataCacheKey = (organizationId: string, userId: string) => ['orgData', organizationId, userId];

export const useSuspendedOrgTokenHydration = (
  organizationId: string,
  skip = false,
  handleError?: (error: AxiosError) => void,
) => {
  const { tsRestClient } = useApiClient();
  const setOrganizationData = useOrganizationSessionStore(state => state.setOrganizationData);
  const userId = useUserSessionStore(state => state.id);
  const setNeedsToSignEula = useUserSessionStore(state => state.setNeedsToSignEula);
  const { signOut } = useSupertokensSession();
  const previousOrgId = useRef<string>(organizationId);

  useEffect(() => {
    if (previousOrgId.current !== null && previousOrgId.current !== organizationId) {
      clear(createOrgDataCacheKey(previousOrgId.current, userId));
      previousOrgId.current = organizationId;
    }
  }, [organizationId, userId]);

  // If the useSuspendedOrgTokenHydration hook gets unmounted we can clear cache for the current Org
  useEffect(() => {
    return () => {
      return clear(createOrgDataCacheKey(previousOrgId.current ?? '', userId));
    };
  }, [userId]);

  const setOrgDataFromSessionPayload = (orgName: string, payload: SessionAccessPayload) => {
    setOrganizationData({
      id: payload['https://hasura.io/jwt/claims']['x-hasura-org-id']!,
      roles: payload['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'],
      role: payload['https://hasura.io/jwt/claims']['x-hasura-default-role'],
      name: orgName,
    });
  };

  suspend(async () => {
    if (skip) {
      return;
    }

    let accessPayload: SessionAccessPayload | undefined = undefined;
    let sessionExists = false;

    await Session.getAccessTokenPayloadSecurely()
      .then(payload => {
        accessPayload = payload as SessionAccessPayload;
        sessionExists = accessPayload['https://hasura.io/jwt/claims']['x-hasura-org-id'] === organizationId;
      })
      .catch(ex => {
        // Avoid an exception if the session does not exist
        // The exception might be related to network availability
        // therefore we're lowering the importance of the log to be a warning
        // eslint-disable-next-line no-console
        console.warn(ex);
      });

    // If a session does not exist we can create one
    if (sessionExists && accessPayload) {
      setOrgDataFromSessionPayload('name', accessPayload);
      return;
    }

    try {
      const switchOrg = await tsRestClient.auth.switchOrganization({
        params: {
          organizationId,
        },
      });

      const updatedPayload = await Session.getAccessTokenPayloadSecurely();
      const result = sessionAccessPayloadSchema.safeParse(updatedPayload);
      if (!result.success) {
        // eslint-disable-next-line no-console
        console.warn('Failure parsing the session payload schema, logging out..');
        await signOut();
        return;
      }

      if (switchOrg.status === 200) {
        const { name } = switchOrg.body;
        setOrgDataFromSessionPayload(name, result.data);
        setNeedsToSignEula(result.data.needsToSignEula);
      }
    } catch (ex) {
      if (handleError) {
        handleError(ex);
      } else {
        // eslint-disable-next-line no-console
        console.error(ex);
      }
    }
  }, createOrgDataCacheKey(organizationId, userId));
};
