import { SvgIcon, useTheme } from '@mui/material';
import React, { useMemo } from 'react';

import { ReactComponent as ChecklistComplete } from '../assets/icons/checklist-complete.svg';
import { ReactComponent as ChecklistLocked } from '../assets/icons/checklist-locked.svg';
import { ReactComponent as ChecklistPending } from '../assets/icons/checklist-pending.svg';

export interface ChecklistIconProps {
  iconLabel: string;
  locked?: boolean;
  complete?: boolean;
}

export const ChecklistIcon: React.FC<ChecklistIconProps> = ({ iconLabel, complete, locked }) => {
  const { palette } = useTheme();
  const customYellowStroke = palette.utils.getDynamicColor('rgb(227, 192, 125)');

  const TheIcon = useMemo(() => {
    if (locked) {
      return ChecklistLocked;
    }
    return complete ? ChecklistComplete : ChecklistPending;
  }, [complete, locked]);

  let stroke: string;
  let background: string;
  if (complete) {
    stroke = palette.success.main;
    background = palette.success.background;
  } else if (locked) {
    stroke = customYellowStroke;
    background = palette.warning.background;
  } else {
    stroke = palette.action.disabled;
    background = palette.action.disabledBackground;
  }

  return (
    <SvgIcon
      aria-label={iconLabel}
      name={iconLabel}
      role="img"
      fontSize="large"
      inheritViewBox
      component={TheIcon}
      sx={{
        color: stroke,
        bgcolor: background,
        borderRadius: '50%',
      }}
    />
  );
};
