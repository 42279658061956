import { config } from '../environments/environment';

export const API_BASE_URL = `${config.API_HOST || 'http://localhost:3000'}`;
export const HASURA_URL = config.HASURA_ENDPOINT;

// prettier-ignore
// eslint-disable-next-line max-len
export const preventAssessmentTemplateEditMessage = 'The ability to edit an Assessment Template is still in development. To create a modified version of this Assessment Template, please create a new one.';

export const IPAD_MIN_SCREEN_WIDTH = 1024;

export const GQL_QUERY_POLLING_INTERVAL = 2500;
