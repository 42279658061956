import { SvgIconTypeMap, Typography, useTheme } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import React from 'react';

import { HStack, IconWrapper, TouchFriendlyTooltip } from '../..';

type OptionForConsiderationFlairProps = {
  id: string;
  Icon: OverridableComponent<SvgIconTypeMap>;
  color: string;
  text?: string;
  tooltip?: string;
};

export const OptionForConsiderationFlair: React.FC<OptionForConsiderationFlairProps> = flair => {
  const theme = useTheme();

  return (
    <TouchFriendlyTooltip key={flair.id} tooltipContent={flair.tooltip}>
      <Typography variant="caption" color={theme.palette.utils.getOutlineColor(flair.color)}>
        <HStack
          p={0.5}
          noFullWidth
          borderRadius={2}
          bgcolor={theme.palette.utils.getBackgroundColor(flair.color)}
          border={`1px solid ${theme.palette.utils.getOutlineColor(flair.color)}`}
          sx={{ userSelect: 'none' }}
        >
          {flair.Icon && (
            <IconWrapper
              htmlColor={theme.palette.utils.getOutlineColor(flair.color)}
              fontSize="small"
              icon={flair.Icon}
            />
          )}
          {flair.text}
        </HStack>
      </Typography>
    </TouchFriendlyTooltip>
  );
};
