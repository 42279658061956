import { Box } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import isString from 'lodash/isString';
import uniqueId from 'lodash/uniqueId';
import React, { useState } from 'react';
import { useInterval } from 'react-use';

export interface TextCycleProps {
  texts: string[];
  /** transition in ms @default: 1000ms */
  speed?: number;
}

const DISTANCE = 25;

export const TextCycle: React.FC<TextCycleProps> = ({ texts, speed = 3000 }) => {
  const [index, setIndex] = useState(0);
  const textIndex = index % texts.length;

  const handleNextWord = () => setIndex(index => index + 1);
  useInterval(handleNextWord, speed);

  return (
    <AnimatePresence mode="wait">
      {texts.map(
        (text, index) =>
          index === textIndex && (
            <Box
              key={isString(text) ? text : uniqueId()}
              component={motion.div}
              sx={{ display: 'inline-flex' }}
              initial={{
                opacity: 0,
                y: -DISTANCE,
              }}
              animate={{
                opacity: index === textIndex ? 1 : 0,
                y: 0,
              }}
              exit={{
                opacity: 0,
                y: DISTANCE,
              }}
              transition={{
                opacity: { duration: 0.4 },
              }}
            >
              {text}
            </Box>
          ),
      )}
    </AnimatePresence>
  );
};
