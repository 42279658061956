import { AssessmentNotification, DueAfter, Frequency } from '@circadian-risk/api-contract';
import { Bold, HStack, OpenInNewTabLink, VStack } from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { useOrganizationId } from '@circadian-risk/stores';
import NotificationsIcon from '@mui/icons-material/Notifications';
import RepeatIcon from '@mui/icons-material/Repeat';
import { Stack, Typography } from '@mui/material';
import { CalendarIcon, ClockIcon } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import pluralize from 'pluralize';
import React, { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { v4 } from 'uuid';

import { formatCadenceFrequency, formatNotification } from '../../helpers';
import { DetailsItem } from './DetailsItem';
import { DetailsTextGroup } from './DetailsTextGroup';

type Props = {
  description: string | null;
  startsAt: string;
  dueAt: string;
  frequency: Frequency;
  dueAfter: DueAfter;
  assessmentTemplate: {
    name: string;
    id: number;
  };
  reminders: AssessmentNotification[];
};

export const ScheduleDetails: React.FC<Props> = ({
  description,
  assessmentTemplate,
  frequency,
  dueAfter,
  startsAt,
  dueAt,
  reminders,
}) => {
  const organizationId = useOrganizationId();
  const formattedFrequency = formatCadenceFrequency(frequency, dayjs(startsAt).toDate());

  // Template might temporarily not exist until the query resolves
  // therefore having defensive approach is better
  const templateLink = assessmentTemplate.id
    ? generatePath(ROUTES.SCENARIO_ASSESSMENT_TEMPLATES_DETAIL, {
        id: assessmentTemplate.id,
        organizationId,
      })
    : '';

  // Generates a unique key as when creating the reminders we don't have a unique identifier
  const remindersWithKey = useMemo(() => reminders.map(r => ({ ...r, key: v4() })), [reminders]);
  const totalReminders = remindersWithKey.length;

  return (
    <VStack spacing={2}>
      <DetailsTextGroup title="Description" value={description} />

      <Stack direction={['column', 'row']} alignItems={'baseline'} gap={2}>
        <VStack>
          <DetailsItem icon={<CalendarIcon color="secondary" />} title="Start Date" value={startsAt} />
          <DetailsItem icon={<CalendarIcon color="secondary" />} title="Due Date" value={dueAt} />
        </VStack>

        <VStack>
          <DetailsItem icon={<RepeatIcon color="secondary" />} title="Frequency" value={formattedFrequency} />
          <DetailsItem
            icon={<ClockIcon color="secondary" />}
            title="Due After"
            value={`${dueAfter.value} ${pluralize(dueAfter.unit, dueAfter.value)}`}
          />
        </VStack>
      </Stack>

      <VStack>
        <Typography variant="subtitle2">
          <Bold>Assessment Template</Bold>
        </Typography>
        <OpenInNewTabLink link={templateLink} text={assessmentTemplate.name} />
      </VStack>

      {totalReminders > 0 && (
        <VStack>
          <Typography variant="subtitle1">Reminders ({totalReminders})</Typography>
          {remindersWithKey.map(r => (
            <HStack key={r.key}>
              <NotificationsIcon color="secondary" />
              <Typography variant="subtitle1">{formatNotification(r)}</Typography>
            </HStack>
          ))}
        </VStack>
      )}
    </VStack>
  );
};
