import { Box, Stack, StackProps, SxProps } from '@mui/material';
import React from 'react';

import { ROW_HEIGHT } from '..';

export interface QuestionQuickActionsLayoutProps {
  leftActions?: React.ReactNode;
  rightActions?: React.ReactNode;
}

const stackProps: StackProps = {
  direction: 'row',
  alignItems: 'center',
  gap: 1,
};

const floatSx: SxProps = {
  justifyContent: 'flex-end',
  float: 'right',
  pl: 1,
  pb: 1,
};

export const QuestionQuickActionsLayout: React.FC<QuestionQuickActionsLayoutProps> = ({
  leftActions,
  rightActions,
}) => (
  <Stack
    {...stackProps}
    id="question-quick-actions"
    justifyContent={'space-between'}
    sx={leftActions ? undefined : floatSx}
  >
    {leftActions && (
      <Box ml={-0.5} height={ROW_HEIGHT}>
        {leftActions}
      </Box>
    )}

    <Stack {...stackProps}>{rightActions}</Stack>
  </Stack>
);
