import { useListenLocationAssignmentsSubscription } from '@circadian-risk/client-graphql-hooks';
import { useOrganizationSessionStore, useUserSessionStore } from '@circadian-risk/stores';
import { withLocationManagerApolloClient } from '@web-app/router/apollo';

export const AllowedNodesProvider = withLocationManagerApolloClient(() => {
  const userId = useUserSessionStore(state => state.id);

  useListenLocationAssignmentsSubscription({
    variables: {
      userId,
    },
    onData: ({ data: { data } }) => {
      useOrganizationSessionStore.setState({
        allowedNodeIds: (data?.location_manager_inherited_assignments ?? [])
          .map(x => x.location_id ?? '')
          .filter(Boolean),
      });
    },
  });

  return null;
});
