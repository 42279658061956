import { MarkupReadyFile } from '@circadian-risk/form';
import { createZustandContext, FCC } from '@circadian-risk/front-end-utils';
import { StateCreator } from 'zustand';

import { FilesDialog, FilesDialogData } from '.';

interface FileMatch {
  fileIndex: number | null;
  answerIndex: number | null;
}

type StateData = Partial<FilesDialogData> | null;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Refetch = () => Promise<any> | any;

type FilesDialogStoreState = {
  open: boolean;
  data: StateData;
  onFileDelete: (id: string) => void;
  onFileChange: (id: string, fileData: Partial<MarkupReadyFile>) => void;
  setData: (props: StateData) => void;
  onClose: () => void;
};

const { StoreProvider, useStore } = createZustandContext<FilesDialogStoreState>();

const stateCreator: (refetch: Refetch) => StateCreator<FilesDialogStoreState> = refetch => (set, get) => {
  const findFile = (fileId: string): FileMatch => {
    const { data } = get();
    let match: FileMatch = { fileIndex: null, answerIndex: null };

    if (data?.files) {
      const fileIndex = data.files.findIndex(f => f.id === fileId);
      if (fileIndex != null && fileIndex !== -1) {
        return { fileIndex, answerIndex: null };
      }
    }

    if (data?.answers) {
      data.answers.findIndex((answer, answerIndex) => {
        const fileIndex = answer.files.findIndex(f => f.id === fileId);
        if (fileIndex !== -1) {
          match = { fileIndex, answerIndex };
          return true;
        }
        return false;
      });
    }

    return match;
  };

  return {
    open: false,
    data: null,
    onFileDelete: fileId => {
      const { data, setData } = get();
      const { fileIndex, answerIndex } = findFile(fileId);

      if (fileIndex != null && answerIndex == null && data?.files) {
        data.files.splice(fileIndex, 1);
      } else if (fileIndex != null && answerIndex != null && data?.answers?.[answerIndex]?.files?.[fileIndex]) {
        data.answers[answerIndex].files.splice(fileIndex, 1);
      }
      setData(data);
    },
    onFileChange: (fileId, newFileData) => {
      const { data, setData } = get();
      const { fileIndex, answerIndex } = findFile(fileId);

      if (fileIndex != null && answerIndex == null && data?.files) {
        const oldFileData = data.files[fileIndex];
        data.files[fileIndex] = { ...oldFileData, ...newFileData };
      } else if (fileIndex != null && answerIndex != null && data?.answers?.[answerIndex]?.files?.[fileIndex]) {
        const oldFileData = data.answers![answerIndex].files[fileIndex];
        data.answers![answerIndex].files[fileIndex] = { ...oldFileData, ...newFileData };
      }
      setData(data);
      void refetch();
    },
    setData: newData => {
      const { data } = get();
      set({ data: { ...data, ...newData }, open: true });
      void refetch();
    },
    onClose: () => set({ data: null, open: false }),
  };
};

const FilesDialogInner: FCC<{ refetch: () => Promise<void> | void }> = ({ children }) => {
  const { data, onFileChange, ...filesDialogProps } = useStore();

  return (
    <>
      <FilesDialog
        {...data}
        {...filesDialogProps}
        enableImageEditing
        onImageProcessingDone={newImage => onFileChange(newImage.id, newImage)}
      />
      {children}
    </>
  );
};

export interface FileDialogProviderProps {
  refetch: Refetch;
}

export const FilesDialogProvider: FCC<FileDialogProviderProps> = ({ children, refetch }) => {
  return (
    <StoreProvider initializer={stateCreator(refetch)}>
      <FilesDialogInner refetch={refetch}>{children}</FilesDialogInner>
    </StoreProvider>
  );
};

export const useFilesDialog = useStore;
