import { Avatar, AvatarProps, Tooltip } from '@mui/material';
import React, { CSSProperties } from 'react';

import { AssessmentTypeIcon } from '../Icons/AssessmentTypeIcon';

export interface ItemTypeIconProps {
  type: 'interview' | 'inspection';
  avatarStyles?: AvatarProps['style'];
  iconFontSize?: string | number;
}

export const ItemTypeIcon: React.FC<ItemTypeIconProps> = ({
  type,
  avatarStyles: avatarStylesOverrides,
  iconFontSize,
}) => {
  const isPhysical = type === 'inspection';
  const tooltipTitle = type === 'inspection' ? 'Physical Inspection Item' : 'Inspection Questionnaire Item';
  const iconStyles: CSSProperties = {
    color: 'white',
  };
  const avatarStyles: CSSProperties = {
    backgroundColor: isPhysical ? '#82A5B8' : '#83B882',
    height: '32px',
    width: '32px',
    fontSize: iconFontSize,
    ...avatarStylesOverrides,
  };

  return (
    <Tooltip title={tooltipTitle} placement={'right'}>
      <Avatar style={avatarStyles}>
        <AssessmentTypeIcon
          type={isPhysical ? 'Physical Inspection' : 'Inspection Questionnaire'}
          fontSize="inherit"
          style={iconStyles}
        />
      </Avatar>
    </Tooltip>
  );
};
