import { Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { createScheduleAssessmentForm, CreateScheduledAssessmentFormSchema } from '../../../form';
import { useLocationFilters } from '../hooks/useLocationFilters';

const { Autocomplete } = createScheduleAssessmentForm;

type Props = {
  onCreate: () => void;
};

export const LocationFilterFormField: React.FC<Props> = ({ onCreate }) => {
  const { watch } = useFormContext<CreateScheduledAssessmentFormSchema>();
  const assessmentTemplateId = watch('assessmentTemplateId');
  const disabled = !assessmentTemplateId;

  const filters = useLocationFilters();

  const options = filters.map(e => ({
    value: e.id,
    label: e.name,
  }));

  return (
    <div>
      <Autocomplete formKey="locationFilterId" options={options} disabled={disabled || options.length === 0} />
      <Button onClick={onCreate}>Create location filter</Button>
    </div>
  );
};
