import { useOrganizationSessionStore } from '@circadian-risk/stores';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import sortBy from 'lodash/sortBy';
import { Controller, useFormContext } from 'react-hook-form';

import { createLocationFilterForm } from '../create-location-filter.form';

const { ariaLabels } = createLocationFilterForm;

export const BaseLayerFormField: React.FC = () => {
  const layers = useOrganizationSessionStore(({ layers }) => {
    return sortBy(layers, l => l.layerDepth);
  });
  const { control, setValue } = useFormContext();
  return (
    <Controller
      name="baseLayerId"
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl component="fieldset">
          <FormLabel component="legend">{ariaLabels.baseLayerId}</FormLabel>
          <RadioGroup
            row
            name="baseLayerId"
            value={value}
            onChange={(...e) => {
              onChange(...e);
              // resetting the tags belongsTo since they might not be relevant for new layer
              setValue('belongsToId', null);
              setValue('tagIds', []);
            }}
          >
            {layers.map(layer => (
              <FormControlLabel key={layer.id} value={layer.id} label={layer.name} control={<Radio />} />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    />
  );
};
