import { diffObject } from '@circadian-risk/data-utils';
import { RxPlugin } from 'rxdb';

export const UPDATED_FIELDS_PROPERTY_KEY = 'updatedFields';

export const makeFieldDiffPlugin = (storeDiffAtDocFieldNamed: string): RxPlugin => {
  return {
    name: 'field-diff-plugin',
    rxdb: true,
    hooks: {
      createRxCollection: {
        after: ({ collection }) => {
          collection.preSave((data, doc) => {
            const diff = diffObject(doc.toJSON(), data, [
              '_attachments',
              '_deleted',
              '_meta',
              '_rev',
              storeDiffAtDocFieldNamed,
            ]);

            const updatedFields = Object.keys(diff);
            const existingUpdatedFields = data[storeDiffAtDocFieldNamed] ?? [];

            const allUpdatedFields = [...updatedFields, ...existingUpdatedFields];

            data[storeDiffAtDocFieldNamed] = Array.from(new Set(allUpdatedFields));
          }, false);
        },
      },
    },
  };
};
