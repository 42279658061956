import { FilterSearchParam } from '../hooks';

export const createAgGridSetFilterSearchParams = <T,>(field: string, values: T): string => {
  const params = new URLSearchParams({
    [FilterSearchParam]: JSON.stringify({
      [field]: {
        filterType: 'set',
        values,
      },
    }),
  });
  return params.toString();
};
