import { ApolloProvider } from '@apollo/client';
import { sessionBasedClientFactory } from '@circadian-risk/apollo-utils';
import { useSuspendedOrgTokenHydration } from '@circadian-risk/authentication';
import { useDownloadOrgFile } from '@circadian-risk/file-manager';
import { FileDownloadContextProvider } from '@circadian-risk/form';
import { REDIRECT_PATH_KEY_WEB_APP, useNotification } from '@circadian-risk/front-end-utils';
import { LocationWizardModalProvider } from '@circadian-risk/location-wizard';
import { CircadianRoute, ROUTES } from '@circadian-risk/routes';
import { useOrganizationSessionStore, useUserSessionStore } from '@circadian-risk/stores';
import { HASURA_URL } from '@web-app/lib/constants';
import { FeatureFlagsProvider } from '@web-app/lib/providers/FeatureFlagsProvider';
import { GlobalOrganizationWrapper } from '@web-app/lib/providers/GlobalOrganizationWrapper';
import { DatabaseProvider } from '@web-app/lib/rxdb';
import { AxiosError } from 'axios';
import isEmpty from 'lodash/isEmpty';
import React, { Suspense, useMemo } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { debug } from '../lib/helpers';
import { canAccessRoutePath } from './RouteConfig';

const log = debug.extend('OrgSection');

export const OrgSection: React.FC<{ orgScopedRoutes: CircadianRoute[] }> = ({ orgScopedRoutes }) => {
  const { pathname } = useLocation();
  const { organizations, isCircadianAdmin, userId, tokenDefaultOrgId } = useUserSessionStore(state => {
    const { organizations, isCircadianAdmin, id, tokenDefaultOrgId } = state;
    return { organizations, isCircadianAdmin, userId: id, tokenDefaultOrgId };
  });
  const { displayError } = useNotification();
  const { push } = useHistory();

  const { roles, enabledFeatures, finishedFetchingFeatures } = useOrganizationSessionStore(
    ({ id, roles, enabledFeatures, finishedFetchingFeatures }) => ({
      id,
      roles,
      enabledFeatures,
      finishedFetchingFeatures,
    }),
  );

  const orgRouteMatch = useRouteMatch<{ organizationId: string }>({
    path: '/organization/:organizationId',
    strict: true,
    sensitive: true,
  });

  const { organizationId } = orgRouteMatch?.params ?? { organizationId: '' };

  const isPseudoOrgId = pathname.includes(':organizationId');

  const handleError = (ex: AxiosError) => {
    // If a 400 is thrown it means the user is trying to access an invalid org or he does not have permissions
    if (ex.response?.status === 400) {
      displayError(
        'It looks like the organization that your trying to access does not exist or you do not have permissions to access',
      );
      push(ROUTES.ORGANIZATION_SWITCH);
    }
  };

  useSuspendedOrgTokenHydration(organizationId, Boolean(isPseudoOrgId) || isEmpty(organizationId), handleError);

  const apolloClient = useMemo(() => {
    return sessionBasedClientFactory({
      uri: HASURA_URL,
    });
  }, []);

  const downloadOrgFile = useDownloadOrgFile();

  const downloadContextValue = useMemo(() => {
    return {
      downloadOrgFile: (fileId: string, _organizationId: string, fileName: string) =>
        // Forward to an object based call
        downloadOrgFile({
          fileId,
          name: fileName,
        }),
    };
  }, [downloadOrgFile]);

  if (isPseudoOrgId) {
    if (organizations.length === 0) {
      return <Redirect to={ROUTES.CREATE_ORG} />;
    }

    const redirectPath = sessionStorage.getItem(REDIRECT_PATH_KEY_WEB_APP);
    if (redirectPath) {
      sessionStorage.removeItem(REDIRECT_PATH_KEY_WEB_APP);
      return <Redirect to={redirectPath} />;
    }

    // Parsing the last token from the Hasura claims is enough
    // to redirect as the user has already been authenticated to access the organization
    if (tokenDefaultOrgId) {
      log(`Redirecting to the last known organization for this user: ${tokenDefaultOrgId}`);
      const redirectedOrgUrl = ROUTES.ROOT.replace(':organizationId', tokenDefaultOrgId);
      return <Redirect to={redirectedOrgUrl} />;
    }

    return <Redirect to={ROUTES.ORGANIZATION_SWITCH} />;
  }

  const matchingOrg = organizations.find(x => x.id === organizationId);
  if (!matchingOrg) {
    log(`Organization ${organizationId} is not in the user's list of known orgs. Redirecting`);
    const redirectLocation = organizations.length > 0 ? ROUTES.ORGANIZATION_SWITCH : ROUTES.SIGN_IN;
    return <Redirect to={redirectLocation} />;
  }

  if (!finishedFetchingFeatures) {
    return (
      <ApolloProvider client={apolloClient}>
        <FeatureFlagsProvider organizationId={organizationId} />
      </ApolloProvider>
    );
  }

  const isAccessAllowed = canAccessRoutePath(pathname, isCircadianAdmin, roles, enabledFeatures);
  if (!isAccessAllowed) {
    return <Redirect to={ROUTES.ROOT} />;
  }

  return (
    <ApolloProvider client={apolloClient}>
      <FileDownloadContextProvider value={downloadContextValue}>
        <DatabaseProvider organizationId={organizationId} userId={userId}>
          <GlobalOrganizationWrapper organizationId={organizationId}>
            <LocationWizardModalProvider>
              <Suspense fallback={<div></div>}>
                <Switch>
                  {orgScopedRoutes.map(route => {
                    const routeProps = {
                      component: route.component,
                      exact: route.isExact,
                      path: route.path,
                      isPublic: route.isPublic,
                      allowRoles: route.allowRoles,
                    };

                    return <Route key={route.path} {...routeProps} />;
                  })}
                </Switch>
              </Suspense>
            </LocationWizardModalProvider>
          </GlobalOrganizationWrapper>
        </DatabaseProvider>
      </FileDownloadContextProvider>
    </ApolloProvider>
  );
};
