import { USER_ROLE } from '@circadian-risk/shared';
import Debug, { Debugger } from 'debug';


export type DisplayValue = string | number | boolean | File | { [key: string]: string };

export interface TableQueryParams {
  filters?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [headerKey: string]: any;
  };
  limit?: string;
  orderField?: string;
  order?: 'asc' | 'desc';
  page?: string;
  search?: string;
}

export const debug: Debugger = Debug('cr');

export const ASSIGNABLE_ROLES = [USER_ROLE.MEMBER, USER_ROLE.ORGANIZATION_ADMIN] as const;
