import { splitMpath } from '@circadian-risk/front-end-utils';
import { GlobalOrganizationContextNode } from '@circadian-risk/stores';
import sortBy from 'lodash/sortBy';

type GetNodeCoordinatesRow = {
  id: string;
  name: string;
  layer_id: string;
  coordinates: { lat: number; long: number } | null;
  mpath: string;
};

/**
 * Returns the node that contains coordinates looking through the scored node parent/children
 */
export const getMapNodeWithCoordinatesFallback = (
  row: GetNodeCoordinatesRow,
  contextNodes: GlobalOrganizationContextNode[],
) => {
  let node: GetNodeCoordinatesRow | undefined = undefined;

  if (!row?.coordinates) {
    const sortedParentNodes = sortBy(
      splitMpath(row.mpath).map((nodeId: string) => contextNodes.find(cn => cn.id === nodeId)!),
      e => e?.nodeDepth,
    ).filter(Boolean)

    // Grab the first parent that has coordinates
    for (const sortedNode of sortedParentNodes) {
      if (sortedNode.coordinates) {
        node = {
          id: sortedNode.id,
          name: sortedNode.name,
          coordinates: { lat: sortedNode.coordinates['lat'], long: sortedNode.coordinates['long'] },
          layer_id: sortedNode.layer_id,
          mpath: sortedNode.mpath,
        };
        break;
      }
    }

    // If we still don't have the coordinates then look over the first child
    if (!node) {
      const childrenNode = contextNodes.find(e => e.parent_id === row.id);
      if (childrenNode && childrenNode.coordinates) {
        node = {
          id: childrenNode.id,
          name: childrenNode.name,
          coordinates: { lat: childrenNode.coordinates['lat'], long: childrenNode.coordinates['long'] },
          layer_id: childrenNode.layer_id,
          mpath: childrenNode.mpath,
        };
      }
    }
  } else {
    node = row;
  }

  return node;
};
