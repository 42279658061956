import { contract } from '@circadian-risk/api-contract';
import { FCC, isAxiosError, NotificationProviderContext, useNotification } from '@circadian-risk/front-end-utils';
import { ApiFetcherArgs, initClient } from '@ts-rest/core';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import React, { createContext, useContext, useMemo } from 'react';

const createTsRestClient = (baseUrl: string, displayError: NotificationProviderContext['displayError']) => {
  return initClient(contract, {
    baseUrl,
    baseHeaders: {
      'Content-Type': 'application/json',
    },
    api: async (
      args: ApiFetcherArgs &
        Pick<AxiosRequestConfig, 'cancelToken' | 'onUploadProgress' | 'responseType'> & {
          onError?: (error: unknown) => void;
        },
    ) => {
      const { path, method, headers, body, onError, ...axiosConfig } = args;
      try {
        const result = await axios.request({
          method,
          url: path,
          headers,
          data: body,
          withCredentials: true,
          ...axiosConfig,
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return { status: result.status, body: result.data, headers: result.headers as any };
      } catch (e: Error | AxiosError | unknown) {
        if (!onError) {
          displayError(e);
          if (isAxiosError(e)) {
            const error = e as AxiosError;
            const response = error.response as AxiosResponse;
            return { status: response.status, body: response.data, headers: response.headers };
          }
        } else {
          onError(e);
        }
        return { status: 500, body: { message: 'Internal server error' }, headers: {} };
      }
    },
  });
};

interface ApiClientContextProps {
  baseUrl: string;
  tsRestClientInstance?: ReturnType<typeof createTsRestClient>;
}

export type TsRestClient = ReturnType<typeof createTsRestClient>;

interface ApiClientContextValue {
  tsRestClient: TsRestClient;
}

const apiClientContext = createContext<ApiClientContextValue | undefined>(undefined);

export const useApiClient = () => {
  const context = useContext(apiClientContext);
  if (!context) {
    throw Error('Tried to access ApiClientContext outside of provider');
  }
  return context;
};

const { Provider } = apiClientContext;

export const ApiClientContextProvider: FCC<ApiClientContextProps> = ({ baseUrl, children, tsRestClientInstance }) => {
  const { displayError } = useNotification();
  const value = useMemo(() => {
    const tsRestClient = tsRestClientInstance ?? createTsRestClient(baseUrl, displayError);
    return {
      tsRestClient,
    };
  }, [baseUrl, displayError, tsRestClientInstance]);

  return <Provider value={value}>{children}</Provider>;
};

export const MockedApiClientContextProvider: FCC = ({ children }) => (
  <ApiClientContextProvider baseUrl={''}>{children}</ApiClientContextProvider>
);
