import FlagIcon from '@mui/icons-material/Flag';
import { IconButton, useTheme } from '@mui/material';
import React from 'react';

import { CellRendererParams } from '../helpers';

export type FlagCellRendererProps = CellRendererParams<{ is_flagged: boolean }> & { disabled?: boolean };
export const FlagCellRenderer: React.FC<FlagCellRendererProps> = ({ data, disabled, column, node }) => {
  const theme = useTheme();
  const value = data.is_flagged;

  const handleChange = () => {
    const colKey = column?.getId();
    if (colKey) {
      node.setDataValue(colKey, !value);
    }
  };

  return (
    <IconButton size="small" color={value ? 'primary' : 'default'} onClick={handleChange} disabled={disabled}>
      <FlagIcon style={{ color: value ? theme.palette.error.main : theme.palette.action.active }} />
    </IconButton>
  );
};
