import noop from 'lodash/noop';
import React from 'react';

import { IFileItem } from '../types';

interface FileManagerContextState {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onFileClicked: (fileItem: IFileItem) => void;
}

export const FileManagerContext = React.createContext<FileManagerContextState>({
  isOpen: false,
  setIsOpen: noop,
  onFileClicked: noop,
});
