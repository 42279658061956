import {
  Autocomplete,
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  TextField,
} from '@mui/material';
import isString from 'lodash/isString';
import sortBy from 'lodash/sortBy';
import React, { FocusEventHandler, memo, useCallback, useEffect, useMemo, useState } from 'react';

import { AutocompleteOption } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface AutoCompleteInputProps<T = string> {
  value: T;
  onChange?: (newValue?: T | null) => void;
  options: AutocompleteOption[];
  disabled?: boolean;
  onBlur?: FocusEventHandler;
  /**
   * @default false
   */
  disableClearable?: boolean;
  label: string;
  errors?: string | string[];
  /**
   * Sorts the label ascending
   */
  disableDefaultSort?: boolean;
  /**
   * If acceptInvalid is enabled the input value will be used
   * Also make sure you validate whether it is a valid email
   *
   * @default false
   */
  acceptInvalid?: boolean;
  /**
   * Render the option, use `getOptionLabel` by default.
   *
   * @param {object} props The props to apply on the li element.
   * @param {T} option The option to render.
   * @param {object} state The state of the component.
   * @returns {ReactNode}
   */
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: AutocompleteOption<T>,
    state: AutocompleteRenderOptionState,
  ) => React.ReactNode;
}

export const AutoCompleteInput: React.FC<AutoCompleteInputProps> = memo(
  ({
    value,
    onChange,
    options,
    disabled,
    onBlur,
    label,
    errors,
    disableDefaultSort,
    acceptInvalid = false,
    renderOption,
    disableClearable = false,
  }) => {
    const [inputValue, setInputValue] = useState<string>('');

    useEffect(() => {
      if (!value) {
        setInputValue('');
      }
    }, [value]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const sortedOptions: AutocompleteOption<any>[] = useMemo(() => {
      if (disableDefaultSort) {
        return options;
      }
      return sortBy(options, e => e.label.toLowerCase());
    }, [disableDefaultSort, options]);

    const handleChange = useCallback(
      (_: unknown, newInputValue: unknown) => {
        if (onChange) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange((newInputValue as AutocompleteOption<any>)?.value ?? null);
        }

        if (newInputValue) {
          setInputValue((newInputValue as AutocompleteOption).label);
        }
      },
      [onChange],
    );

    const handleInputChange = useCallback(
      (_: unknown, newValue: string, reason: AutocompleteInputChangeReason) => {
        if (reason === 'clear') {
          return setInputValue('');
        }

        if (reason === 'input' || (reason === 'reset' && Boolean(newValue))) {
          if (acceptInvalid) {
            handleChange(undefined, { value: newValue, label: newValue });
          }
          return setInputValue(newValue);
        }
      },
      [acceptInvalid, handleChange],
    );

    const getOptionLabel = useCallback((option?: AutocompleteOption) => {
      return option?.label ?? '';
    }, []);

    const inputRenderer = useCallback(
      (params: AutocompleteRenderInputParams) => (
        <TextField {...params} label={label} variant="outlined" error={Boolean(errors)} helperText={errors} />
      ),
      [label, errors],
    );

    const getOptionSelected = useCallback((option: AutocompleteOption, value: AutocompleteOption) => {
      // Circadian form does not always map to the option therefore if the value
      // is narrowed down we should just compare and not try to unwrap
      if (isString(value)) {
        return option.value === value;
      }

      return option.value === value.value;
    }, []);

    return (
      <Autocomplete
        value={value}
        onChange={handleChange}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        options={sortedOptions}
        renderInput={inputRenderer}
        renderOption={renderOption}
        disabled={disabled}
        fullWidth
        isOptionEqualToValue={getOptionSelected}
        getOptionLabel={option => (isString(option) ? option : getOptionLabel(option))}
        onBlur={onBlur}
        freeSolo={acceptInvalid}
        disableClearable={disableClearable}
      />
    );
  },
);
