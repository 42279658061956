import { FileManager, IFileItem, UploadStatus, useFileManagerFilter } from '@circadian-risk/file-manager';
import { Card } from '@mui/material';
import { useCallback } from 'react';

interface ScenarioAssessmentPendingFilesProps {
  organizationId: string;
  assessmentId: number;
}

export const ScenarioAssessmentPendingFiles: React.FC<ScenarioAssessmentPendingFilesProps> = props => {
  const { organizationId, assessmentId } = props;
  const filter = useCallback(
    (file: IFileItem) => {
      return (
        file.uploadEndpointInfo.organizationId === organizationId,
        file.uploadEndpointInfo.metadata?.assessmentId === String(assessmentId) &&
          file.status !== UploadStatus.Completed
      );
    },
    [assessmentId, organizationId],
  );

  useFileManagerFilter(filter);

  return (
    <Card sx={{ flex: 1 }}>
      <FileManager />
    </Card>
  );
};
