import { IconButton, IconButtonProps, PopperPlacementType } from '@mui/material';

import { PersistentPopover } from '../PersistentPopover';
import { TouchFriendlyTooltip } from '../Tooltips';

export interface PopperTooltipProps {
  variant: 'persistent' | 'temporary';

  /** An icon or a element to activate the popover / tooltip */
  trigger: React.ReactElement<unknown, string>;
  triggerSize?: IconButtonProps['size'];
  children: React.ReactElement<unknown, string>;
  placement?: PopperPlacementType;
  zIndex?: number;
}

export const PopperTooltip: React.FC<PopperTooltipProps> = ({
  trigger,
  triggerSize,
  variant,
  children,
  placement,
  zIndex,
}) => {
  return variant === 'persistent' ? (
    <PersistentPopover
      placement={placement}
      trigger={<IconButton size={triggerSize ?? 'large'}>{trigger}</IconButton>}
      zIndex={zIndex}
    >
      {children}
    </PersistentPopover>
  ) : (
    <TouchFriendlyTooltip tooltipContent={children}>{trigger}</TouchFriendlyTooltip>
  );
};
