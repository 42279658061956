import { AssessmentTypeIcon } from '@circadian-risk/presentational';
import { Box, Card, CardProps, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import omit from 'lodash/omit';
import React from 'react';

import { AssessmentLocationTree } from './AssessmentLocationTree';
import { PROGRESS_WIDTH } from './LocationTree';

interface AssessmentLocationTreeCardProps extends CardProps {
  organizationId: string;
  assessmentId: number;
}

const iconWrapperSx: SxProps<Theme> = theme => ({
  flex: `0 0 ${PROGRESS_WIDTH}px`,
  width: 100,
  textAlign: 'center',
  marginRight: theme.spacing(0.5),
});

const iconSx: SxProps = { height: '22px', width: '22px' };

export const AssessmentLocationTreeCard: React.FC<AssessmentLocationTreeCardProps> = props => {
  const { organizationId, assessmentId } = props;
  const cardProps = omit(props, ['organizationId', 'assessmentId']);

  return (
    <Card {...cardProps}>
      <Box px={2} py={2} display="flex" flexDirection="column">
        <Typography variant="h5">Assessment Locations</Typography>

        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="body1">Locations</Typography>
          <Box flex={1} display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
            <Tooltip title="Inspection Questionnaire">
              <Box sx={iconWrapperSx}>
                <AssessmentTypeIcon type="Inspection Questionnaire" sx={iconSx} />
              </Box>
            </Tooltip>
            <Tooltip title="Physical Inspection">
              <Box sx={iconWrapperSx}>
                <AssessmentTypeIcon type="Physical Inspection" sx={iconSx} />
              </Box>
            </Tooltip>
          </Box>
        </Box>
        <AssessmentLocationTree organizationId={organizationId} assessmentId={assessmentId} />
      </Box>
    </Card>
  );
};
