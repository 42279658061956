import { FCC } from '@circadian-risk/front-end-utils';
import { Box, Card, Typography } from '@mui/material';
import React from 'react';

export const CenterMessagePage: FCC<{ title?: string }> = ({ title, children }) => {
  return (
    <Box position="fixed" top="25%" left="50%" sx={{ transform: 'translate(-50%,0%)' }}>
      <Card>
        <Box p={4} display="flex" flexDirection="column" alignItems="center">
          {title && (
            <Box mb={2}>
              <Typography variant="h5">{title}</Typography>
            </Box>
          )}
          {children}
        </Box>
      </Card>
    </Box>
  );
};
