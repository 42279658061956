import {
  FileAttachmentCommon,
  FileList,
  FileListItem,
  FileUploadModal,
  FileUploadModalProps,
} from '@circadian-risk/form';
import { LightBoxWithConverterProvider, LightboxWithEditingProps } from '@circadian-risk/presentational';
import sortBy from 'lodash/sortBy';
import React, { useState } from 'react';

type ItemAttachmentsProps = LightboxWithEditingProps & {
  files: FileAttachmentCommon[];
  readOnly?: boolean;
  hideListing?: FileUploadModalProps['hideListing'];
  onUploadFile: FileUploadModalProps['onUploadFile'];
};

export const ItemAttachments: React.FC<ItemAttachmentsProps> = ({
  files,
  readOnly,
  onUploadFile,
  hideListing,
  onImageProcessing,
}) => {
  const allFilesSorted = sortBy(files, e => e.name);
  const [fileModalOpen, setFileModalOpen] = useState(false);

  return (
    <>
      <LightBoxWithConverterProvider
        files={allFilesSorted}
        onImageProcessing={readOnly ? undefined : onImageProcessing}
      >
        <FileList
          onAddButtonClicked={readOnly ? undefined : () => setFileModalOpen(true)}
          wrapperProps={{ sx: { flex: 1, display: 'flex', flexDirection: 'column' } }}
        >
          {allFilesSorted.map(f => (
            <FileListItem key={f.id} {...f} />
          ))}
        </FileList>
      </LightBoxWithConverterProvider>

      <FileUploadModal
        hideListing={hideListing}
        open={fileModalOpen}
        onClose={() => setFileModalOpen(false)}
        onUploadFile={onUploadFile}
        uploadDropzoneProps={{
          inputProps: {
            multiple: true,
          },
        }}
      />
    </>
  );
};
