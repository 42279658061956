import { Box, Card, useTheme } from '@mui/material';
import React from 'react';

export interface BarChartTooltipProps {
  color: string | undefined;
  title: string | undefined;
  children: React.ReactNode;
}

export const BarChartTooltip: React.FC<BarChartTooltipProps> = ({ color, children, title = 'Title' }) => {
  const theme = useTheme();
  const tooltipColor = color ?? theme.palette.auxColor;

  return (
    <Card>
      <Box
        sx={{
          p: 1,
          fontWeight: 700,
          background: tooltipColor,
          color: theme.palette.getContrastText(tooltipColor),
        }}
      >
        {title}
      </Box>
      <Box p={1}>{children}</Box>
    </Card>
  );
};
