import { createScheduledAssessmentSchema } from '@circadian-risk/api-contract';
import { generateCircadianForm } from '@circadian-risk/form';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';

export enum LocationPickerType {
  Default = 'default',
  LocationFilter = 'locationFilter',
}

// The form schema wrap some properties that need to be
// of a different type locally
const formSchema = createScheduledAssessmentSchema.extend({
  // This is just relevant for our stepper form component
  // as it gives more context across the validators
  locationPickerType: z.nativeEnum(LocationPickerType),
});

export type CreateScheduledAssessmentFormSchema = z.output<typeof formSchema>;

export const createScheduleAssessmentForm = generateCircadianForm(formSchema, {
  form: 'create scheduled assessment form',
  name: 'Name',
  description: 'Description',
  startsAtDate: 'Assessment Start Date',
  startsAtTime: 'Time',
  timezone: 'Timezone',
  frequency: 'Frequency',
  dueAfter: 'Due After',
  notifications: 'Notifications',
  submit: 'Submit',
  assessmentTemplateId: 'Assessment Template',
  locationsIds: 'Locations',
  locationFilterId: 'Location Filter',
  assignment: 'Assignment Management',
  importAnswers: '',
  locationPickerType: '',
  endsOn: '',
});

export const useCreateScheduledAssessmentFormContext = () => useFormContext<CreateScheduledAssessmentFormSchema>();
