import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { CreateLocationFilterSchema } from '../create-location-filter.schema';
import { LocationFilterRow } from '../types';
import { calculateRelevantLocations } from './helpers';

export const useGetRelevantLocationIds = (locations: LocationFilterRow[]) => {
  const context = useFormContext<CreateLocationFilterSchema>();
  const includeChildren = context.watch('includeChildren');
  const baseLayerId = context.watch('baseLayerId');
  const belongsToId = context.watch('belongsToId');
  const tagIds = context.watch('tagIds');

  const filtered = useMemo(() => {
    return calculateRelevantLocations(locations, { baseLayerId, belongsToId, includeChildren, tagIds });
  }, [baseLayerId, belongsToId, includeChildren, locations, tagIds]);

  return filtered.map(f => f.id);
};
