import { InfoIconWithTooltip } from '@circadian-risk/presentational';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';

import { CriticalityNumber } from './types';

export const CriticalityNumberAndLabel: React.FC<
  Omit<CriticalityNumber, 'criticality'> & { color: string; disableView?: boolean }
> = ({ num, color, tooltip, label, onClick, disableView }) => (
  <Box display="flex" flexDirection="column" alignItems="center">
    <Typography variant="h2">
      <Box color={color}>{num}</Box>
    </Typography>

    <Box display="flex">
      <Typography>{label}</Typography>
      {tooltip && <InfoIconWithTooltip tooltipContent={tooltip} />}
    </Box>

    {!disableView && (
      <Button endIcon={<ChevronRight />} onClick={onClick} disabled={!onClick}>
        View
      </Button>
    )}
  </Box>
);
