import { useSuspenseQuery } from '@apollo/client';
import { useApiClient } from '@circadian-risk/api-client-provider';
import { CreateScheduledAssessment } from '@circadian-risk/api-contract';
import { defaultCache } from '@circadian-risk/apollo-utils';
import {
  CreateAssessmentActions,
  CreateAssessmentLayout,
  getCurrentUserTimezone,
} from '@circadian-risk/assessment-components';
import { useNotification } from '@circadian-risk/front-end-utils';
import { ROUTES } from '@circadian-risk/routes';
import { DateFormat } from '@circadian-risk/shared';
import { useOrganizationId } from '@circadian-risk/stores';
import { Card } from '@mui/material';
import dayjs from 'dayjs';
import noop from 'lodash/noop';
import React from 'react';
import { useFormState } from 'react-hook-form';
import { generatePath, useHistory } from 'react-router-dom';

import { GetDataForAssessmentDetailsDocument } from '../graphql/typed-nodes';
import { CreateAssessmentFormStepper } from './CreateAssessmentFormStepper';
import { createScheduleAssessmentForm, CreateScheduledAssessmentFormSchema, LocationPickerType } from './form';
import { useCreateScheduledAssessmentNavigation } from './hooks/useCreateScheduledAssessmentNavigation';
import { stepsConfig } from './stepsConfig';

const { useCircadianForm, CircadianForm } = createScheduleAssessmentForm;

type Props = {
  onSubmit: (data: CreateScheduledAssessmentFormSchema) => void;
};

const CreateScheduledAssessmentPageContent: React.FC<Props> = ({ onSubmit }) => {
  const history = useHistory();
  const organizationId = useOrganizationId();
  const handleCancel = () => history.push(generatePath(ROUTES.ASSESSMENTS, { organizationId }));
  const handleRedirectToSelection = () => history.push(generatePath(ROUTES.ASSESSMENTS_NEW, { organizationId }));

  const { activeStep, handleBack, handleNext } = useCreateScheduledAssessmentNavigation(
    handleRedirectToSelection,
    stepsConfig,
    onSubmit,
  );
  const step = stepsConfig[activeStep];

  const { isSubmitting: submitting } = useFormState<CreateScheduledAssessment>();

  const baseSubTitle = `Step ${activeStep + 1}`;
  const subTitle = `${baseSubTitle}: ${step.title}`;

  return (
    <CreateAssessmentLayout
      title={'Create A Scheduled Assessment'}
      subtitle={subTitle}
      activeStepView={
        <Card sx={{ p: 4, ...step.sx }}>
          <step.component />
        </Card>
      }
      actions={
        <CreateAssessmentActions
          onCancel={handleCancel}
          next={{
            onClick: handleNext,
            title: activeStep === stepsConfig.length - 1 ? 'Create Assessment' : 'Next',
            disabled: submitting,
          }}
          back={{ onClick: handleBack, disabled: submitting }}
        />
      }
      formStepper={<CreateAssessmentFormStepper activeStep={activeStep} steps={stepsConfig} />}
    />
  );
};

type CreateScheduledAssessmentPageProps = {
  defaultValues?: CreateScheduledAssessmentFormSchema;
};

export const CreateScheduledAssessmentPage: React.FC<CreateScheduledAssessmentPageProps> = ({ defaultValues }) => {
  const organizationId = useOrganizationId();
  const { displayError } = useNotification();
  const { tsRestClient } = useApiClient();
  const history = useHistory();
  const formMethods = useCircadianForm({
    defaultValues: defaultValues ?? {
      locationsIds: [],
      locationPickerType: LocationPickerType.Default,
      timezone: getCurrentUserTimezone(),
      frequency: {
        type: 'daily',
      },
      startsAtDate: new Date(),
      startsAtTime: dayjs().format(DateFormat.HOUR_AND_MINUTE_AM_PM),
      endsOn: {
        type: 'never',
        value: null,
      },
      dueAfter: {
        unit: 'hour',
        value: 1,
      },
      notifications: [],
      assignment: {
        user: [],
        userGroup: [],
        teams: [],
      },
      importAnswers: { type: 'fresh' },
    },
  });

  const handleSubmit = async (formData: CreateScheduledAssessmentFormSchema) => {
    try {
      const result = await tsRestClient.assessments.createScheduled({
        params: { organizationId },
        body: {
          ...formData,
          startsAtDate: dayjs(formData.startsAtDate).format(DateFormat.YEAR_MONTH_DAY),
        },
      });

      const { status } = result;
      if (status !== 201) {
        throw new Error(`Failed to create scheduled assessment`);
      }

      // Invalid the top level query to force a refetch
      defaultCache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'scheduled_assessment_schedule',
      });

      const path = generatePath(ROUTES.SCHEDULED_ASSESSMENTS, { organizationId });
      history.push(path);
    } catch (ex) {
      displayError(ex);
    }
  };

  useSuspenseQuery(GetDataForAssessmentDetailsDocument, {
    variables: { organizationId },
  });

  return (
    // onSubmit can be ignored on the form as we perform a special validation between
    // section steps
    <CircadianForm formMethods={formMethods} onSubmit={noop}>
      <CreateScheduledAssessmentPageContent onSubmit={handleSubmit} />
    </CircadianForm>
  );
};
