import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import isBoolean from 'lodash/isBoolean';
import React from 'react';
import { Controller } from 'react-hook-form';

import { DefaultOption, PropertySelectFieldProps } from '../types';

export const PropertySelectField = <Option extends DefaultOption>({
  label,
  htmlName,
  control,
  options,
  disabled,
  rules,
  boxProps,
  errorMessage,
  customRenderer,
  helperText,
  onChangeCallback,
  startAdornment,
  endAdornment,
  placeholder,
  size,
}: PropertySelectFieldProps<Option>) => {
  return (
    <Box {...boxProps}>
      <Controller
        control={control}
        name={htmlName}
        rules={rules}
        render={fieldProps => {
          const { onBlur, onChange, value } = fieldProps.field;

          const fieldError = errorMessage || fieldProps.fieldState.error?.message;

          const labelId = `select-field-${htmlName}-label`;
          const handleSubmit = (newValue: string | number | null) => {
            let normalizedValue = newValue;
            if (!options.find(option => option.value === newValue)) {
              normalizedValue = null;
            }

            onChange(normalizedValue);
            if (onChangeCallback) {
              onChangeCallback(normalizedValue);
            }
          };

          return (
            <FormControl variant="outlined" fullWidth error={Boolean(fieldError)}>
              <InputLabel id={labelId}>{label}</InputLabel>
              <Select
                value={value ?? ''}
                onChange={e => handleSubmit(e.target.value)}
                onBlur={onBlur}
                labelId={labelId}
                label={label}
                displayEmpty
                fullWidth
                variant="outlined"
                data-name={label}
                disabled={disabled}
                startAdornment={startAdornment}
                endAdornment={endAdornment}
                placeholder={placeholder}
                size={size}
                inputProps={
                  // By default it is very narrow when we're using
                  // a start adornment therefore this padding left
                  // will give it space to breathe
                  startAdornment
                    ? {
                        sx: {
                          pl: 1,
                        },
                      }
                    : {}
                }
              >
                {options.map(option => {
                  // MenuItem value does not accept booleans therefore we should cast
                  const value = isBoolean(option.value) ? String(option.value) : option.value;
                  if (customRenderer) {
                    return (
                      <MenuItem key={value} value={value}>
                        {customRenderer(option)}
                      </MenuItem>
                    );
                  }
                  return (
                    <MenuItem key={value} value={value}>
                      {option.label}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>{fieldError ?? helperText}</FormHelperText>
            </FormControl>
          );
        }}
      />
    </Box>
  );
};
