import { debug } from '@circadian-risk/front-end-utils';
import pluralize from 'pluralize';

import { FilterOperator, FilterOption } from '.';
import { SELECT_ALL_BUTTON_ID } from './components/constants';
import { CreateChipStateParams, FilterModel } from './filter.types';

export const parseFilterModelFromQueryState = <FilterKey extends string = string>(
  queryState?: string,
): FilterModel<FilterKey> => {
  if (!queryState) {
    return {} as FilterModel<FilterKey>;
  }
  try {
    const decoded = decodeURIComponent(queryState);
    const parsed = JSON.parse(decoded) as FilterModel;
    return parsed;
  } catch (ex) {
    debug.extend('filters')(ex);
    return {} as FilterModel<FilterKey>;
  }
};

/**
 * Creates the correct filter operator given the size of selected children options
 * @param size
 * @returns
 */
export const createFilterOperator = (size: number): FilterOperator => {
  // Zero state should return "noneOf"
  if (size === 0) {
    return 'noneOf';
  }
  return size === 1 ? 'is' : 'isAnyOf';
};

export const convertToFilterModel = (options: FilterOption[]): FilterModel => {
  return options.reduce((model, o) => {
    if (o.selected) {
      const selectedChildren = o.children.filter(c => c.selected);
      model[o.value] = {
        operator: o.operator ?? createFilterOperator(selectedChildren.length),
        values: selectedChildren.map(c => c.value),
      };
    }
    return model;
  }, {} as FilterModel);
};

type UpdateOptionStateParams = {
  options: FilterOption[];
  parentValue: string;
  childrenValue: string;
  /**
   * If nothing is provided the value will be toggled
   */
  state?: boolean;
};

/**
 * Updates an option and returns a new list with the updated parent/child
 * Note: The parent option will always be selected even if there isn't any children selection
 * assuming that this helper is invoked through an UI interactivity allowing the "zero state"
 * @param UpdateOptionStateParams
 * @returns FilterOption[]
 */
export const updateOptionState = ({
  options,
  parentValue,
  childrenValue,
  state,
}: UpdateOptionStateParams): FilterOption[] => {
  const newOptions = [...options];
  const parent = newOptions.find(o => o.value === parentValue);
  if (!parent) {
    return options;
  }

  const child = parent.children.find(c => c.value === childrenValue);

  if (child) {
    child.selected = state ?? !child.selected;
    parent.selected = true;
    parent.operator = createFilterOperator(parent.children.filter(c => c.selected).length);
  }

  return newOptions;
};

/**
 * Selects every children option given the parent
 * @returns FilterOption[]
 */
export const selectAllOptions = ({
  options,
  parentValue,
}: Pick<UpdateOptionStateParams, 'options' | 'parentValue'>) => {
  const newOptions = [...options];
  const parent = newOptions.find(o => o.value === parentValue);
  if (parent) {
    parent.children = parent.children.map(c => ({ ...c, selected: true }));
    parent.selected = true;
    parent.operator = createFilterOperator(parent.children.filter(c => c.selected).length);
  }

  return newOptions;
};

/**
 * Clears every child option selected
 * Note: If `preventClosing` is set to true it will not remove the parent option
 * and will stick as "0 options" (by default is set to false)
 * @returns FilterOption[]
 */
export const clearOptionSelection = ({
  options,
  parentValue,
  preventClosing = false,
}: Pick<UpdateOptionStateParams, 'options' | 'parentValue'> & { preventClosing?: boolean }) => {
  const newOptions = [...options];
  const parent = newOptions.find(o => o.value === parentValue);
  if (parent) {
    parent.children = parent.children.map(c => ({ ...c, selected: false }));
    parent.selected = preventClosing;
    parent.operator = parent.selected
      ? createFilterOperator(parent.children.filter(c => c.selected).length)
      : undefined;
  }

  return newOptions;
};

export const isSelectAllFocused = () => {
  const activeEl = document.activeElement;
  return activeEl?.id === SELECT_ALL_BUTTON_ID;
};

export const createDefaultChipState = ({
  filterOptionName,
  selectedChildrenCount,
  singleSelectionOptionLabel,
}: CreateChipStateParams) => {
  return selectedChildrenCount === 1
    ? singleSelectionOptionLabel
    : `${selectedChildrenCount} ${pluralize(filterOptionName)}`;
};
