import { useCompleteFragment } from '@circadian-risk/apollo-utils';
import { TypedNodes } from '@circadian-risk/client-graphql-hooks';
import { displayNameOrEmail } from '@circadian-risk/shared';
import { OrganizationUser, useOrganizationSessionStore } from '@circadian-risk/stores';
import { LinearProgress } from '@mui/material';
import omit from 'lodash/omit';
import { useEffect } from 'react';

interface UsersProviderProps {
  organizationId: string;
}

// TODO(v-rrodrigues)[CR-5724]: Remove this as it's no longer going to be required
export const UsersProvider: React.FC<UsersProviderProps> = ({ organizationId }) => {
  const { users_organizations_organizations: orgUsers } = useCompleteFragment({
    fragment: TypedNodes.OrgUsersFragmentDoc,
    key: {
      id: organizationId,
    },
  });

  useEffect(() => {
    useOrganizationSessionStore.setState({
      users: orgUsers.map<OrganizationUser>(({ user, role, allowAdminPasswordChange }) => {
        return {
          ...omit(user, '__typename', 'users_organizations_organizations'),
          displayName: displayNameOrEmail([user.first_name, user.last_name], user.email),
          role,
          lastSignedInAt: user.last_signed_in_at,
          created_at: user.created_at,
          allowAdminPasswordChange: allowAdminPasswordChange ?? false,
          created_by_id: user.created_by_id,
        };
      }),
    });
  }, [orgUsers]);

  if (orgUsers.length === 0) {
    return <LinearProgress />;
  }
  return null;
};
