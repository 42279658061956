import { FCC } from '@circadian-risk/front-end-utils';
import { Theme, Typography } from '@mui/material';
import MuiCard, { CardProps as MuiCardProps } from '@mui/material/Card';
import makeStyles from '@mui/styles/makeStyles';
import isString from 'lodash/isString';
import React, { memo } from 'react';

export interface LayoutCardProps extends MuiCardProps {
  aboveTitle?: React.ReactNode; // example: BreadCrumbs
  afterTitle?: React.ReactNode; // example: ToggleButton
  cardTitle: string | React.ReactNode;
  compact?: boolean;
}

const useStyles = makeStyles<Theme, { compact?: boolean }>((theme: Theme) => ({
  titleRow: {
    alignItems: 'center',
    paddingTop: ({ compact }) => (compact ? theme.spacing(0.5) : theme.spacing(2)),
    paddingBottom: ({ compact }) => (compact ? theme.spacing(0.5) : theme.spacing(2)),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  title: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  aboveTitle: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(0),
  },
}));

/**
 * A wrapper for Material's Card.
 * For nice children helpers (rendering images, etc.) see MUI docs (CardMedia, etc.)
 */
export const LayoutCard: FCC<React.PropsWithChildren<LayoutCardProps>> = memo(
  ({ aboveTitle, afterTitle, children, cardTitle, compact, ...rest }: LayoutCardProps) => {
    const classes = useStyles({ compact });
    const displayTitle = isString(cardTitle) ? (
      <Typography variant="h5" title={cardTitle} className={classes['title']}>
        {cardTitle}
      </Typography>
    ) : (
      cardTitle
    );
    const displayAboveTitle = aboveTitle && <div className={classes['aboveTitle']}>{aboveTitle}</div>;

    return (
      <MuiCard data-testid="card-wrapper" {...rest}>
        {displayAboveTitle}
        {displayTitle && (
          <div className={classes['titleRow']}>
            {displayTitle}
            {afterTitle}
          </div>
        )}
        {children}
      </MuiCard>
    );
  },
);
