import { Enum_Consideration_Type_Enum } from '@circadian-risk/graphql-types';
import { PRINCIPAL_ACTIONS, PrincipalActionWithIcon } from '@circadian-risk/presentational';
import { FormControl, FormControlProps, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';
import React from 'react';

import { CustomOption } from '.';

export type PrincipalActionDropdownProps = {
  variant?: SelectProps['variant'];
  readOnly?: boolean;
  value?: Enum_Consideration_Type_Enum | null;
  handleChange: (type: CustomOption['type']) => void;
  formControlProps?: Partial<Omit<FormControlProps, 'disabled'>>;
};

export const PrincipalActionDropdown: React.FC<PrincipalActionDropdownProps> = ({
  variant = 'standard',
  readOnly,
  value,
  handleChange,
  formControlProps,
}) => {
  return (
    <FormControl variant={variant} fullWidth disabled={readOnly} {...formControlProps}>
      <InputLabel>Principal Action</InputLabel>
      <Select
        sx={{ bgcolor: theme => theme.palette.background.paper }}
        variant={variant}
        displayEmpty
        value={value ?? 'custom'}
        onChange={e => handleChange(e.target.value as CustomOption['type'])}
        renderValue={action => <PrincipalActionWithIcon action={action as CustomOption['type']} />}
        label={'Principal Action'}
      >
        {PRINCIPAL_ACTIONS.map(action => (
          <MenuItem value={action} key={action}>
            <PrincipalActionWithIcon action={action} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
