import { timeAgo } from '@circadian-risk/shared';
import { Alert, Box, Button, Divider, Snackbar, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { SpecialZoomLevel, TextDirection, ThemeProps, Viewer, Worker } from '@react-pdf-viewer/core';
import dayjs from 'dayjs';
import dayjsLocalizedFormat from 'dayjs/plugin/localizedFormat';
import startCase from 'lodash/startCase';
import { useEffect, useState } from 'react';
import { useInterval } from 'react-use';

import { usePdfDefaultLayoutPlugin } from './usePdfDefaultLayoutPlugin';

dayjs.extend(dayjsLocalizedFormat);

interface PreviewReportContentProps {
  fileUrl?: string | null;
  status?: string;
  startedAt?: string | null;
  completedAt?: string | null;
  onRegenerateReport: (() => Promise<void>) | (() => void);
}

const formatDateTime = (dateStr?: string | null) => {
  if (!dateStr) {
    return '';
  }
  return dayjs(dateStr).format('lll');
};

const formatStartedAt = (date?: string | null) => {
  if (!date) {
    return '';
  }
  return timeAgo(date);
};

const pdfViewerTheme: ThemeProps = { direction: TextDirection.LeftToRight };
export const PreviewReportContent: React.FC<PreviewReportContentProps> = ({
  status,
  startedAt,
  completedAt,
  fileUrl,
  onRegenerateReport,
}) => {
  const pdfDefaultLayoutPluginInstance = usePdfDefaultLayoutPlugin();
  const plugins = [pdfDefaultLayoutPluginInstance];
  const loading = status === 'pending' || status === 'started';
  const [startedAtLabel, setStartedAtLabel] = useState('');
  const [retrying, setRetrying] = useState(false);

  useEffect(() => {
    if (loading) {
      setStartedAtLabel(formatStartedAt(startedAt));
    }
  }, [loading, startedAt]);

  // Every minute make sure the formatting is updated
  useInterval(() => {
    if (loading) {
      setStartedAtLabel(formatStartedAt(startedAt));
    }
  }, 60000);

  const handleRetryClick = async () => {
    setRetrying(true);
    await onRegenerateReport();
    setRetrying(false);
  };

  return (
    <>
      {loading && (
        <>
          <Box textAlign="center">
            <Typography variant="body1">
              Report Generation is in progress. You may navigate to other parts of the application during this time. If
              you make any changes to the report during generation and generate another preview, the process will be
              restarted.
            </Typography>
            <Divider light sx={theme => ({ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) })} />
            <Box mb={1}>
              <Typography variant="body2">Status: {startCase(status)}</Typography>
              <Typography variant="body2">Started At: {startedAtLabel}</Typography>
              <Typography variant="body2">Completed At: {formatDateTime(completedAt)}</Typography>
            </Box>
            <CircularProgress color="primary" />
          </Box>
          <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={loading}>
            <Alert severity="warning">Report Generation In Progress</Alert>
          </Snackbar>
        </>
      )}

      {fileUrl && !loading && (
        <Box height="75vh" minHeight="500px">
          {/** @todo (v-rrodrigues/sanda) Consider serving the workerjs locally */}
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
            <Viewer
              plugins={plugins}
              fileUrl={fileUrl}
              defaultScale={SpecialZoomLevel.PageWidth}
              theme={pdfViewerTheme}
              renderLoader={progress => <div>Loading: {Math.floor(progress)}%</div>}
            />
          </Worker>
        </Box>
      )}
      {status === 'failed' && (
        <Box flexDirection={'column'} display={'flex'} gap={2}>
          <Typography variant="body1">
            There was an issue generating your report. Contact support if this issue persists.
          </Typography>
          <Button variant="contained" onClick={handleRetryClick} disabled={loading || retrying}>
            Retry Generating Report
          </Button>
        </Box>
      )}
    </>
  );
};
