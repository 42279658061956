import { Overlay } from '@circadian-risk/presentational';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Alert, Button, Skeleton } from '@mui/material';

import { DataGrid } from './DataGrid';

export interface SkeletonDataGridProps {
  columns?: number;
  rows?: number;
  columnWidths?: number[];
  error?: boolean;
  refetch?: () => void;
}

export const SkeletonDataGrid: React.FC<SkeletonDataGridProps> = ({
  columns = 3,
  rows = 5,
  columnWidths = [200, 100, 100],
  error,
  refetch,
}) => {
  const rowData = Array.from({ length: rows }).map((_, index) => ({ index }));
  const columnDefs = Array.from({ length: columns }).map((_, index) => ({
    headerName: '-',
    headerComponent: () => (
      <Skeleton variant="text" width={columnWidths[index] || 100} animation={!error ? 'wave' : false} />
    ),
    cellRenderer: () => (
      <Skeleton variant="text" width={columnWidths[index] || 100} animation={!error ? 'wave' : false} />
    ),
    field: 'index',
  }));

  return (
    <Overlay
      visible={!!error}
      content={
        <Alert
          severity="error"
          action={
            refetch ? (
              <Button color="inherit" size="small" onClick={() => refetch()} startIcon={<RefreshIcon />}>
                Refresh
              </Button>
            ) : undefined
          }
        >
          Error loading table data
        </Alert>
      }
    >
      <div style={{ pointerEvents: 'none' }} aria-hidden>
        <DataGrid
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={{
            flex: 1,
          }}
          getRowId={({ data }: { data: { index: number } }) => {
            return data.index.toString();
          }}
          domLayout="autoHeight"
        />
      </div>
    </Overlay>
  );
};
