import { Autocomplete, AutocompleteProps, createFilterOptions, TextField } from '@mui/material';
import isString from 'lodash/isString';

type Tag = string;

const tagsFilter = createFilterOptions<Tag>();

type PartialAutoComplete = Pick<Partial<AutocompleteProps<string, true, false, true>>, 'options'>;

type RequiredAutocompleteProps = Omit<
  AutocompleteProps<string, true, false, true>,
  | 'value'
  | 'onChange'
  | 'options'
  | 'renderInput'
  | 'freeSolo'
  | 'handleHomeEndKeys'
  | 'clearOnBlur'
  | 'selectOnFocus'
  | 'multiple'
  | 'filterOptions'
  | 'getOptionLabel'
>;

export type TagsAutocompleteProps = PartialAutoComplete &
  RequiredAutocompleteProps & {
    value: Tag[];
    onChange: (tags: Tag[]) => void;
    label?: string | React.ReactNode;
    errorMessage?: string;
    helperText?: string;
  };

export const TagsAutocomplete: React.FC<TagsAutocompleteProps> = props => {
  const { value, label, errorMessage, helperText, onChange } = props;
  const tagsArray = value ?? [];

  const handleAddTag = (tag: Tag) => {
    onChange([...tagsArray, tag]);
  };

  return (
    <Autocomplete<string, true, false, true>
      {...props}
      sx={{ width: 300 }}
      options={props.options ?? []}
      multiple
      onChange={(_e, newValue, reason, detail) => {
        const newTags = newValue as unknown as Tag[];
        if (reason === 'clear') {
          onChange(newTags);
          return;
        }

        const tag = detail?.option;
        if (reason === 'removeOption') {
          onChange(newTags);
        } else if (reason === 'createOption' && isString(tag)) {
          handleAddTag(tag);
        } else if (reason === 'selectOption' && tag) {
          handleAddTag(tag);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = tagsFilter(options, params);

        if (params.inputValue !== '') {
          filtered.push(params.inputValue);
        }

        return filtered;
      }}
      getOptionLabel={option => {
        // e.g value selected with enter, right from the input
        if (isString(option)) {
          return option;
        }
        return option;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      renderOption={(props, option) => <li {...props}>{option}</li>}
      freeSolo
      renderInput={params => (
        <TextField {...params} label={label} error={Boolean(errorMessage)} helperText={helperText ?? errorMessage} />
      )}
    />
  );
};
