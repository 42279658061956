import { AnswerSemanticMeaning } from '@circadian-risk/front-end-utils';
import { Enum_Answer_Responses_Enum } from '@circadian-risk/graphql-types';

type ReplaceUnderscoreWithSlash<T extends string> = T extends `${infer Head}_${infer Tail}`
  ? `${Capitalize<Head>}/${Capitalize<Tail>}`
  : Capitalize<T>;

type LookupObject<T extends string> = {
  [P in T]: ReplaceUnderscoreWithSlash<P>;
};

export const humanReadableAnswerResponseLookup: LookupObject<Enum_Answer_Responses_Enum> = {
  n_a: 'N/A',
  n_o: 'N/O',
  no: 'No',
  yes: 'Yes',
};

export const accessibleMeaningLookup: Record<AnswerSemanticMeaning, string> = {
  n_a: 'not applicable',
  n_o: 'not observed',
  deficient: 'deficient',
  compliant: 'compliant',
};

export const getAccessibleResponseAndMeaning = (
  semanticMeaning: AnswerSemanticMeaning,
  responseText: Enum_Answer_Responses_Enum,
) => {
  const humanReadableSemanticMeaning = accessibleMeaningLookup[semanticMeaning];
  if (semanticMeaning === 'compliant' || semanticMeaning === 'deficient') {
    return `${responseText} - ${humanReadableSemanticMeaning}`;
  } else {
    return humanReadableSemanticMeaning;
  }
};

export type HumanReadableAnswerResponse = ReplaceUnderscoreWithSlash<Enum_Answer_Responses_Enum>;

export const humanReadableAnswerResponses: HumanReadableAnswerResponse[] = ['N/A', 'N/O', 'No', 'Yes'];

export const getHumanReadableResponse = (response: Enum_Answer_Responses_Enum): HumanReadableAnswerResponse =>
  humanReadableAnswerResponseLookup[response];

export const getSemanticMeaning = (
  response: Enum_Answer_Responses_Enum | null,
  deficientResponse: Enum_Answer_Responses_Enum,
): AnswerSemanticMeaning | null => {
  if (response === 'n_a') {
    return 'n_a';
  }

  if (response === 'n_o') {
    return 'n_o';
  }

  if (response === 'yes') {
    return deficientResponse === 'yes' ? 'deficient' : 'compliant';
  }

  if (response === 'no') {
    return deficientResponse === 'no' ? 'deficient' : 'compliant';
  }

  return null;
};
