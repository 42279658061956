import { realWorldPopoverSx } from '@circadian-risk/maps';
import { NodeIcon } from '@circadian-risk/presentational';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Popover,
  PopoverProps,
  styled,
  Table,
  TableBody,
  TableCell as TableCellMUI,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import slice from 'lodash/slice';
import sortBy from 'lodash/sortBy';
import React from 'react';
import { Link } from 'react-router-dom';

const TableCell = styled(TableCellMUI)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.text.primary,
  fontsize: theme.typography.body1.fontSize,
}));

export interface LocationRealWorldPopoverProps {
  open: boolean;
  rows: {
    layerName: string;
    locationId: string;
    locationName: string;
    depth: number | null;
  }[];
  onClose: () => void;
  anchorEl?: PopoverProps['anchorEl'];
  createLocationRoute: (locationId: string) => string;
}

export const LocationRealWorldPopover: React.FC<LocationRealWorldPopoverProps> = ({
  open,
  onClose,
  anchorEl,
  rows,
  createLocationRoute,
}) => {
  const anchorOrigin: PopoverProps['anchorOrigin'] = {
    vertical: 'top',
    horizontal: 'left',
  };
  const transformOrigin: PopoverProps['transformOrigin'] = {
    vertical: 'bottom',
    horizontal: 'left',
  };
  const paperProps: PopoverProps['PaperProps'] = {
    sx: realWorldPopoverSx['paper'],
  };

  const slicedItems = slice(
    sortBy(rows, e => [e.depth, e.locationName]),
    0,
    3,
  );

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      PaperProps={paperProps}
    >
      <Card elevation={0}>
        <CardHeader
          sx={realWorldPopoverSx['cardHeader']}
          title="Locations"
          action={
            <IconButton aria-label="close-icon" edge={'end'} onClick={onClose} size="large">
              <CloseIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Box width={'100%'}>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Layer</TableCell>
                    <TableCell>Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {slicedItems.map(row => (
                    <TableRow
                      key={`map-card-cell-${row.locationId}`}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="right">
                        <NodeIcon layerName={row.layerName} />
                      </TableCell>
                      <TableCell component="th" scope="row" width={'200px'}>
                        <Link to={createLocationRoute(row.locationId)}>{row.locationName}</Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Box display={'flex'} alignItems={'center'}>
                <Box flexGrow={1} mt={1}>
                  {rows.length > 3 && (
                    <Typography noWrap>
                      and <b>{rows.length - 3}</b> more
                    </Typography>
                  )}
                </Box>
              </Box>
            </TableContainer>
          </Box>
        </CardContent>
      </Card>
    </Popover>
  );
};
