import { Enum_Answer_Responses_Enum } from '@circadian-risk/graphql-types';

export type AnswerFormData = {
  is_flagged?: boolean | null;
  response?: Enum_Answer_Responses_Enum | null;
  description?: string | null;
  partial_compliance_multiplier?: number | null;
};

export enum AnswerFormName {
  flagged = 'flagged',
  response = 'response',
  description = 'description',
  partialCompliance = 'partialCompliance',
  options = 'options',
}
