import { PropsOf } from '@emotion/react';
import { ButtonBase, ButtonBaseTypeMap, ExtendButtonBase, Tooltip, Typography } from '@mui/material';
import React, { forwardRef } from 'react';

import { CHIP_HEIGHT, SPACING } from './constants';

type BaseButtonProps = PropsOf<ExtendButtonBase<ButtonBaseTypeMap>>;

export interface ChipButtonProps extends BaseButtonProps {
  dense?: boolean;
  tooltip?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const ChipButton = forwardRef<HTMLButtonElement, ChipButtonProps>((props, ref) => {
  const { sx, dense, tooltip, onClick, children } = props;
  return (
    <Tooltip title={tooltip ?? ''}>
      <ButtonBase
        {...props}
        id={``}
        ref={ref}
        focusRipple
        onClick={onClick}
        sx={theme => ({
          height: CHIP_HEIGHT,
          px: dense ? 0 : SPACING,
          transition: theme.transitions.create('background'),
          ':hover': {
            background: theme.palette.primary.background,
          },
          ...sx,
        })}
      >
        <Typography component="span" variant="caption" sx={{ display: 'flex', alignItems: 'center' }}>
          {children}
        </Typography>
      </ButtonBase>
    </Tooltip>
  );
});
