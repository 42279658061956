import { ValueGetterParams } from '@ag-grid-community/core';
import { IStandard, StandardsGroupWithDialog } from '@circadian-risk/presentational';
import React from 'react';

import { CellRendererParams } from '../helpers';

export interface StandardCellRendererProps {
  standards: IStandard[];
}

export const StandardCellRenderer: React.FC<CellRendererParams<StandardCellRendererProps>> = ({ data }) => {
  return <StandardsGroupWithDialog standards={data.standards} enableDynamicPopover />;
};

export const standardFilterParamsConfig = {
  valueGetter: (params: ValueGetterParams) => {
    return JSON.stringify(params.getValue('standards'));
  },
  valueFormatter: (params: { value: string }) => {
    const standards = JSON.parse(params.value) as IStandard[];
    return standards.map(s => s.name).join();
  },
};
