import { Enum_Answer_Responses_Enum } from '@circadian-risk/graphql-types';
import { ResponseIcon } from '@circadian-risk/presentational';
import { Box, Chip, useTheme } from '@mui/material';

import { getHumanReadableResponse, getSemanticMeaning } from '../utils';

interface ResponseChipProps {
  response: Enum_Answer_Responses_Enum | null;
  deficientResponse: Enum_Answer_Responses_Enum | null;
}

export const ResponseChip: React.FC<ResponseChipProps> = ({ response, deficientResponse }) => {
  const theme = useTheme();

  if (!response || !deficientResponse) {
    return null;
  }

  const meaning = getSemanticMeaning(response, deficientResponse);
  const colors = theme.palette.organizationColors.compliance;
  const chipColor = {
    compliant: colors.compliant,
    deficient: colors.deficient,
    n_a: colors.unknown,
    n_o: colors.unknown,
  }[meaning ?? 'n_a'];

  return (
    <Chip
      variant="outlined"
      icon={
        <Box display="flex" alignItems="center">
          <ResponseIcon response={meaning} />
        </Box>
      }
      label={getHumanReadableResponse(response)}
      sx={{ color: chipColor, borderColor: chipColor }}
    />
  );
};
