import { PRINCIPAL_ACTIONS } from '@circadian-risk/presentational';
import { faker } from '@faker-js/faker';
import random from 'lodash/random';
import sample from 'lodash/sample';

import { OptionsSectionProps } from './OptionsSection';
import { ICustomOption, ILibraryOption } from './types';

type Source = OptionsSectionProps['options'][number]['source'];

export const createRandomCustomOption = (overrides?: Omit<Partial<ICustomOption>, 'source'>): ICustomOption => ({
  id: faker.string.uuid(),
  title: faker.lorem.sentence(random(2, 6)),
  type: sample(PRINCIPAL_ACTIONS) ?? 'custom',
  description: faker.lorem.sentences(random(1, 5)),
  source: 'custom',
  createdAt: faker.date.past(),
  ...overrides,
});

export const createRandomLibraryOption = (overrides?: Omit<Partial<ILibraryOption>, 'source'>): ILibraryOption => ({
  id: faker.number.int(),
  title: faker.lorem.sentence(random(2, 6)),
  type: sample(PRINCIPAL_ACTIONS) ?? 'custom',
  description: faker.lorem.sentences(random(1, 5)),
  source: 'library',
  locationIds: ['orgId'],
  createdAt: faker.date.past(),
  libraryId: faker.number.int(),
  ...overrides,
});

export const createRandomOption = (overrides?: Partial<OptionsSectionProps['options'][number]>) => {
  const randomSource: Source = sample(['custom', 'library']) ?? 'library';
  const actualSource = overrides?.source ?? randomSource;

  return actualSource === 'custom'
    ? createRandomCustomOption(overrides as Partial<ICustomOption>)
    : createRandomLibraryOption(overrides as Partial<ILibraryOption>);
};
