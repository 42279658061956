import {
  AssessQuestion,
  BulkReassessOption,
  InitializeBulkEditParams,
  useBulkReassessModal,
} from '@web-app/smart-components/BulkReassess';
import { OneByOneReassessModalResolution, useOneByOneReassessModal } from '@web-app/smart-components/OneByOneReassess';

export type useHandleReassessHelperParams = {
  createQuestionsFactory: () => AssessQuestion[];
  /**
   * If provided "displayBack" will be enabled and so is the action
   */
  handleBackClick?: () => void;
  /**
   * Callback invoked once the reassess result is resolved
   */
  onReassessFinish?: (questionsAssessed: AssessQuestion[]) => void;
  selectedAnswersCount: number;
  bulkOptions?: Pick<InitializeBulkEditParams, 'disableCompliantResponse'>;
};

export const useHandleReassessHelper = ({
  createQuestionsFactory,
  handleBackClick,
  onReassessFinish,
  selectedAnswersCount,
  bulkOptions,
}: useHandleReassessHelperParams) => {
  const { initiateBulkEdit } = useBulkReassessModal([]);
  const { initiateOneByOne } = useOneByOneReassessModal([]);

  const handleReassess = async (option: 'bulkEdit' | 'oneByOne') => {
    const questionsToBeAssessed = createQuestionsFactory();
    let result: OneByOneReassessModalResolution | BulkReassessOption;
    const displayBack = Boolean(handleBackClick);
    if (option === 'oneByOne') {
      result = await initiateOneByOne(questionsToBeAssessed, displayBack);
    } else {
      result = await initiateBulkEdit({
        questionsOverride: questionsToBeAssessed,
        displayBack,
        ...bulkOptions,
      });
    }

    switch (result) {
      case 'bulkEdit':
        await handleReassess('bulkEdit');
        return;
      case 'oneByOne':
        await handleReassess('oneByOne');
        return;
      case 'back':
        handleBackClick?.();
        return;
      case 'close':
        return;
      default:
        break;
    }

    onReassessFinish?.(questionsToBeAssessed);
  };

  const handleReassessHelper = async () => {
    await handleReassess(selectedAnswersCount > 1 ? 'bulkEdit' : 'oneByOne');
  };

  return handleReassessHelper;
};
