import { Theme } from '@mui/material';

export const realWorldPopoverSx = {
  paper: {
    minWidth: '100px',
    minHeight: '50px',
  },
  tableCell: (theme: Theme) => ({
    color: theme.palette.text.primary,
    cursor: 'default',
    fontSize: theme.typography.body1.fontSize,
  }),
  arrowRightIcon: (theme: Theme) => ({
    marginLeft: theme.spacing(2),
  }),
  cardHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
