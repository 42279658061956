import { useIsPhoneScreen, useIsTabletScreen } from '@circadian-risk/front-end-utils';
import { ChevronControls, HStack, VStack } from '@circadian-risk/presentational';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { Box, ClickAwayListener, IconButton, styled, Tooltip } from '@mui/material';
import { motion } from 'framer-motion';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLatest, useLocalStorage, useTimeoutFn } from 'react-use';

import { ASSESSMENT_APP_BAR_HEIGHT, DRAWER_WIDTH } from '../../constants';
import { useAssessmentLayout } from '../../store';
import { ActionsColumn } from '..';
import { ActionsRow } from '../ActionsRow/ActionsRow';

export interface DrawerProps {
  children: React.ReactNode;
  actions: React.ReactNode;
}

const StyledNav = styled(motion.div)`
  overflow-x: hidden;
  height: calc(100dvh - ${ASSESSMENT_APP_BAR_HEIGHT}px);
  background-color: ${({ theme }) => theme.palette.background.paper};
  z-index: ${({ theme }) => theme.zIndex.drawer};
`;

const useDrawerInitialState = () => {
  const [isDrawerInitiallyShown, setDrawerInitiallyShown] = useLocalStorage('initial-item-drawer-shown', false);
  const defaultDrawerState = useLatest(isDrawerInitiallyShown);

  return [defaultDrawerState, setDrawerInitiallyShown] as const;
};

export const Drawer: React.FC<DrawerProps> = ({ children, actions }) => {
  const isPhone = useIsPhoneScreen();
  const isTablet = useIsTabletScreen();

  const { drawerOpened, openDrawer, closeDrawer } = useAssessmentLayout();
  const [isDrawerInitiallyShown] = useDrawerInitialState();

  useEffect(() => {
    if ((isTablet && isDrawerInitiallyShown.current) || isPhone) {
      // Default to closed on mobile and on tablet if it's the first time the user sees the drawer
      closeDrawer();
      return;
    }
    openDrawer();
  }, [closeDrawer, isDrawerInitiallyShown, isPhone, isTablet, openDrawer]);

  const visibleDrawerWidth = isPhone ? '100vw' : DRAWER_WIDTH;

  return (
    <HStack
      zIndex={theme => theme.zIndex.drawer}
      position={isPhone || isTablet ? 'absolute' : 'sticky'}
      noFullWidth
      spacing={0}
      alignItems="stretch"
    >
      <StyledNav
        animate={drawerOpened ? 'visible' : 'hidden'}
        transition={{ duration: 0.4 }}
        initial={false}
        variants={{
          hidden: {
            width: 0,
          },
          visible: {
            width: visibleDrawerWidth,
          },
        }}
      >
        {/* this wrapper allows creating illusion of a drawer since it always keeps its width and parent hides overflow */}
        <Box minWidth={visibleDrawerWidth}>
          <VStack spacing={0}>
            {isPhone && actions && <ActionsRow>{actions}</ActionsRow>}
            {children}
          </VStack>
        </Box>
      </StyledNav>
      {/* actions column is hidden on mobile, but shows as a row inside the drawer */}
      {!isPhone && <ActionsColumn>{actions}</ActionsColumn>}
    </HStack>
  );
};

const TableDrawerTrigger: React.VFC = () => {
  const [isDrawerInitiallyShown, setDrawerInitiallyShown] = useDrawerInitialState();
  const [showTooltip, setShowTooltip] = useState(false);
  const isTablet = useIsTabletScreen();
  const { drawerOpened, toggleDrawer } = useAssessmentLayout();

  useTimeoutFn(() => {
    if (isTablet && !isDrawerInitiallyShown.current) {
      setShowTooltip(true);
      setDrawerInitiallyShown(true);
    }
  }, 1000);

  return (
    // TODO(iprokopovich)[CR-6092]: replace this with <TouchFriendlyTooltip/>
    <ClickAwayListener onClickAway={() => setShowTooltip(false)}>
      <Tooltip title="Select item" open={showTooltip} arrow placement="bottom">
        <IconButton
          onClick={() => {
            toggleDrawer();
            setShowTooltip(false);
          }}
        >
          <ChevronControls variant="sideDrawer" expanded={!drawerOpened} />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
};

export const DrawerTrigger: React.FC<{ forPhone?: boolean }> = ({ forPhone }) => {
  const isPhone = useIsPhoneScreen();
  const isTablet = useIsTabletScreen();
  const { drawerOpened, toggleDrawer } = useAssessmentLayout();

  if (forPhone && !isPhone) {
    return null;
  }

  if (isTablet) {
    return <TableDrawerTrigger />;
  }

  return (
    <IconButton onClick={toggleDrawer}>
      {isPhone ? (
        drawerOpened ? (
          <MenuOpenIcon />
        ) : (
          <MenuIcon />
        )
      ) : (
        <ChevronControls variant="sideDrawer" expanded={!drawerOpened} />
      )}
    </IconButton>
  );
};
