import ButtonBase from '@mui/material/ButtonBase';
import React, { useRef, useState } from 'react';
import { ChangeHandler } from 'react-hook-form';
import mergeRefs from 'react-merge-refs';

export const FileImageContext = React.createContext<string | null>(null);

export const readFileSelected = (fileSelected: Blob, onLoad: FileReader['onload']) => {
  const reader = new FileReader();
  reader.onload = onLoad;
  reader.readAsDataURL(fileSelected);
};

type AvatarImageUploaderProps = Pick<HTMLInputElement, 'accept' | 'name'> & {
  children: JSX.Element;
  onChange: ChangeHandler;
};

/**
 * React Hook form friendly implementation of NewFileField.tsx
 */
export const FileImageUploader = React.forwardRef((props: AvatarImageUploaderProps, ref: React.Ref<unknown>) => {
  const [file, setFile] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const onInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const input = e.target;
    const fileSelected = input.files![0];

    if (fileSelected) {
      await props.onChange(e);
      readFileSelected(fileSelected, evt => {
        return evt && setFile((evt.target as { result: string | null }).result);
      });
    }
  };

  const triggerFileBrowser = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <FileImageContext.Provider value={file}>
      <ButtonBase focusRipple onClick={triggerFileBrowser}>
        <input
          accept={props.accept}
          className={'sr-only'}
          type="file"
          name={props.name}
          onChange={onInputChange}
          tabIndex={-1}
          ref={mergeRefs([inputRef, ref])}
        />
        {props.children}
      </ButtonBase>
    </FileImageContext.Provider>
  );
});
