import DoneIcon from '@mui/icons-material/Done';
import { useTheme } from '@mui/material';
import React from 'react';

import { OptionForConsiderationFlair } from '..';

export const ImportedFlair: React.FC = () => {
  const theme = useTheme();
  return (
    <OptionForConsiderationFlair
      id="Already Imported"
      Icon={DoneIcon}
      color={theme.palette.success.main}
      tooltip="This option has already been added to the response"
      text="Added"
    />
  );
};
