import { faker } from '@faker-js/faker';
import { Factory } from 'fishery';
import keyBy from 'lodash/keyBy';
import { v4 as uuid } from 'uuid';

import { ActiveOrganizationData, GlobalOrganizationContextNode, useOrganizationSessionStore } from '..';

const ORG_ID = uuid();
const ORG_LAYER_ID = uuid();

// TODO(iprokopovich)[CR-2320]: are there any easy ways to mock the global org store? It could be helpful for the cosmos to export them from @circadian-risk/stores or maybe even separate @circadian-risk/mocks
export const locationFactory = Factory.define<GlobalOrganizationContextNode>(() => ({
  address: null,
  childrenCount: 0,
  coordinates: null,
  created_at: faker.date.recent().toString(),
  nodeDepth: 0,
  id: ORG_ID,
  layer_id: ORG_LAYER_ID,
  name: 'Organization X',
  parent_id: null,
  mpath: ORG_ID,
  ordinal: 0,
  tags: [],
  use_custom_coordinates: false,
  path: '',
  teamsAndUserIds: [],
}));

export const layerFactory = Factory.define<ActiveOrganizationData['layers'][0]>(() => ({
  id: ORG_LAYER_ID,
  name: 'Organization',
  hierarchy_type: 0,
  parent_id: null,
  layerDepth: 0,
  mpath: ORG_LAYER_ID,
  nodeCount: 1,
  semanticName: 'organization',
  teams: [],
}));

const nodes = [locationFactory.build()];
const layers = [layerFactory.build()];

export const mockOrgSessionStoreContent = {
  ORG_ID,
  ORG_LAYER_ID,
  store: {
    nodes,
    layers,
    nodesById: keyBy(nodes, l => l.id),
    layersById: keyBy(layers, l => l.id),
  },
};

export const mockOrgSessionStore = () => {
  useOrganizationSessionStore.setState(mockOrgSessionStoreContent.store);
};
