import { ROUTES } from '@circadian-risk/routes';
import { USER_ROLE } from '@circadian-risk/shared';
import { lazyLoadWithVersionCheck } from '@web-app/router/lazyLoadWithVersionCheck';

const ConsiderationOptionsPage = lazyLoadWithVersionCheck(() => import('./ConsiderationOptionsPage'));

export const considerationOptionsRoutes = () => [
  {
    path: ROUTES.OPTIONS_FOR_CONSIDERATION,
    component: ConsiderationOptionsPage,
    isExact: true,
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN, USER_ROLE.LOCATION_MANAGER],
  },
];
