import InfoIcon from '@mui/icons-material/Info';
import { Typography, useTheme } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import omit from 'lodash/omit';
import React from 'react';

export interface InfoBoxProps extends BoxProps {
  InfoIconComponent?: React.ReactNode;
  /**
   * Renders directly the children component instead of wrapping within a `Typography`
   *
   * @default false
   */
  disableTypographyWrapper?: boolean;
}

export const InfoBox: React.FC<InfoBoxProps> = ({ children, ...props }) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      borderRadius="5px"
      bgcolor={theme.palette.info.background}
      py={1}
      px={2}
      alignItems="center"
      {...omit(props, 'InfoIconComponent', 'disableTypographyWrapper')}
    >
      <Box display={'flex'} color="info.main" width={'100%'}>
        <Box display={'flex'} alignItems={'center'}>
          <InfoIcon htmlColor={theme.palette.info.main} display={'flex'} />
        </Box>
        <Box ml={1} width={'100%'}>
          {props.disableTypographyWrapper ? (
            children
          ) : (
            <Typography component="span" color="inherit" sx={{ display: 'flex', width: '100%' }}>
              {children}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
