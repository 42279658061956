import { CreateScheduledAssessment } from '@circadian-risk/api-contract';
import { TimezonePicker } from '@circadian-risk/assessment-components';
import { Controller, useFormContext } from 'react-hook-form';

export const TimezoneFormField: React.FC = () => {
  const { control } = useFormContext<CreateScheduledAssessment>();
  return (
    <Controller
      name="timezone"
      control={control}
      render={({ field: { value, onChange } }) => {
        return <TimezonePicker selectedTimezone={value} onChange={onChange} />;
      }}
    />
  );
};
