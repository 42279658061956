import * as z from 'zod';

const fileOrUrlSchema = z.string();

export const fileAlternatePropertySchema = z.object({
  th: fileOrUrlSchema,
  md: fileOrUrlSchema,
  ml: fileOrUrlSchema,
  lg: fileOrUrlSchema,
});
