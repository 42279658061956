import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import { optionsSchema } from './shared-schemas';

const c = initContract();

const provideAnswerSchema = z
  .object({
    questionId: z.string().uuid(),
    response: z.string(),
    description: z.string().nullish(),
    flagged: z.boolean().nullish(),
    partialComplianceMultiplier: z.number().nullish(),
    answered_at: z.string().optional(),
    fileIdsToRemove: z.array(z.string()).optional(),
  })
  .merge(optionsSchema);

const updateAnswerSchema = z
  .object({
    flagged: z.boolean().nullish(),
    criticalityValue: z.number().nullish(),
    cost: z.number().nullish(),
    isAccepted: z.boolean().nullish(),
    acceptedRiskJustification: z.string().nullish(),
  })
  .merge(optionsSchema);

const bulkAnswerReassessSchema = z.array(
  provideAnswerSchema.extend({
    itemId: z.string().uuid(),
  }),
);

export type ProvideAnswer = z.infer<typeof provideAnswerSchema>;
export type UpdateAnswer = z.infer<typeof updateAnswerSchema>;
export type BulkAnswerReassess = z.infer<typeof bulkAnswerReassessSchema>;

export const organizationItemsContract = c.router(
  {
    provideAnswer: {
      method: 'POST',
      path: '/:organizationId/:id/answer',
      description:
        'Allows a user to provide an answer for a question about an item outside of the normal assessment workflow',
      pathParams: z.object({
        organizationId: z.string().uuid(),
        id: z.string().uuid(),
      }),
      body: provideAnswerSchema,
      responses: {
        201: z.object({
          id: z.string(),
        }),
      },
    },
    updateAnswer: {
      method: 'PATCH',
      path: '/:organizationId/:id/answer/:questionId',
      description: 'Update an item answer',
      pathParams: z.object({
        organizationId: z.string().uuid(),
        id: z.string().uuid(),
        questionId: z.string().uuid(),
      }),
      body: updateAnswerSchema,
      responses: {
        200: z.object({}),
      },
    },
    bulkReassess: {
      method: 'POST',
      path: '/:organizationId/answers/reassess',
      description:
        'Allows a user to provide multiple answers for multiple item(s) outside the normal assessment workflow',
      pathParams: z.object({
        organizationId: z.string().uuid(),
      }),
      body: bulkAnswerReassessSchema,
      responses: {
        201: z.array(
          z.object({
            organizationId: z.string().uuid(),
            questionId: z.string().uuid(),
            itemId: z.string().uuid(),
          }),
          { description: 'Returns the answerId, itemId, questionId for each newly created answer' },
        ),
      },
    },
  },
  {
    pathPrefix: '/organization-items',
  },
);
