import { FCC } from '@circadian-risk/front-end-utils';
import { IconButton, IconButtonProps, Menu, MenuItem as MuiMenuItem, MenuProps, styled } from '@mui/material';
import omit from 'lodash/omit';
import React, { useCallback, useState } from 'react';

export interface MenuWithTriggerItem {
  component: JSX.Element;
  isNotClickable?: boolean;
  shouldCloseOnClick?: boolean;
  wrapperComponent?: FCC;
}

export interface MenuWithTriggerProps {
  menuId: string;
  items: MenuWithTriggerItem[];
  triggerButtonIcon: JSX.Element;
  triggerAriaLabel?: string;
  triggerButtonIconProps?: IconButtonProps;
}

const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  minWidth: 120,
  [theme.breakpoints.down('sm')]: {
    paddingBottom: '0px',
    paddingTop: '0px',
    fontSize: '22px',
  },
  [theme.breakpoints.up('sm')]: {
    padding: 0,
  },
}));

export const MenuWithTrigger = (props: Partial<MenuProps> & MenuWithTriggerProps) => {
  const [forceOpacity, setForceOpacity] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { menuId, items, triggerButtonIcon, triggerAriaLabel, ...menuProps } = props;

  const handleFocus = useCallback(
    (returnToToggle?: boolean) => {
      if (returnToToggle) {
        const targetButtonIcon = document.getElementById(`${menuId}-trigger`);
        if (targetButtonIcon) {
          targetButtonIcon.focus();
        }
        setForceOpacity(false);
      } else {
        const firstElem = document.getElementById(`${menuId}-0`);
        if (firstElem) {
          firstElem.focus();
        }
        setForceOpacity(true);
      }
    },
    [menuId],
  );

  const handleToggleClick = useCallback(
    (e: React.MouseEvent) => {
      if (!isOpen) {
        setAnchorEl(e.target as Element);
        handleFocus();
      } else {
        setAnchorEl(null);
        handleFocus(true);
      }
      setIsOpen(!isOpen);
    },
    [handleFocus, isOpen],
  );

  return (
    <div>
      <IconButton
        onClick={handleToggleClick}
        aria-label={triggerAriaLabel ?? 'toggle this menu'}
        style={forceOpacity ? { opacity: 1 } : undefined}
        disabled={!items.length}
        size="large"
        {...props.triggerButtonIconProps}
      >
        {triggerButtonIcon}
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={anchorEl}
        transformOrigin={{ vertical: -10, horizontal: 80 }}
        onClose={handleToggleClick}
        {...omit(menuProps, 'isSmallViewPort')}
      >
        {items.map((item, i) => {
          const content = item.isNotClickable ? (
            <div key={`menu-item-${menuId}-${i}`}>{item.component}</div>
          ) : (
            <MenuItem
              onClick={e => item.shouldCloseOnClick && handleToggleClick(e)}
              key={`menu-item-${menuId}-${i}`}
              id={`${menuId}-${i}`}
            >
              {item.component}
            </MenuItem>
          );

          if (item.wrapperComponent) {
            const Wrapper = item.wrapperComponent;
            return <Wrapper key={`menu-item-${menuId}-${i}`}>{content}</Wrapper>;
          }

          return content;
        })}
      </Menu>
    </div>
  );
};
