import { useQuery } from '@apollo/client';
import { getNormalizedMpath } from '@circadian-risk/front-end-utils';
import { useOrganizationSessionStore, useStandardOrgRoleQueryPrecedence } from '@circadian-risk/stores';
import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { ReviewLocationDetailsGrid, ReviewLocationDetailsRow } from '../../../components/ReviewLocationDetails';
import { GetLocationReviewStepDataDocument } from '../../../graphql/typed-nodes';
import { useSelectedLocationIds } from '../../../hooks/useSelectedLocationIds';
import { CreateScheduledAssessmentFormSchema } from '../../form';

export const LocationsStep: React.FC = () => {
  const nodesById = useOrganizationSessionStore(state => state.nodesById);
  const applicableLocationIds = useSelectedLocationIds();
  const { watch } = useFormContext<CreateScheduledAssessmentFormSchema>();
  const selectedTemplateId = watch('assessmentTemplateId');
  const filterId = watch('locationFilterId');
  const queryContext = useStandardOrgRoleQueryPrecedence();

  const { data } = useQuery(GetLocationReviewStepDataDocument, {
    skip: applicableLocationIds.length === 0 || !selectedTemplateId,
    variables: {
      ids: applicableLocationIds,
      templateId: selectedTemplateId,
    },
    context: queryContext,
    fetchPolicy: 'cache-first',
  });

  const rows = useMemo(() => {
    if (!data?.nodes?.length) {
      return [];
    }

    return data.nodes.map<ReviewLocationDetailsRow>(({ id, scenarioAssessmentNodes, templateSummaryInfo }) => {
      const node = nodesById[id];
      const completedAt =
        scenarioAssessmentNodes.length > 0 ? new Date(scenarioAssessmentNodes[0].assessment!.completed_at!) : null;

      const aggregates = templateSummaryInfo?.[0];

      // If a location filter is selected, the root location id is the first applicable location id
      // as it is sorted otherwise the node itself would be the root location
      const rootLocationId = filterId ? applicableLocationIds[0] : id;

      return {
        id,
        name: node.name,
        relativeMpath: getNormalizedMpath(node.mpath, rootLocationId),
        childrenCount: node.childrenCount,
        layer_id: node.layer_id,
        lastAssessed: completedAt,
        questionnaireItems: aggregates?.inspection_questionnaire_item_categories_count ?? null,
        questionnaireQuestions: aggregates?.inspection_questionnaire_questions_count ?? null,
        questionnaireProperties: aggregates?.inspection_questionnaire_properties_count ?? null,
        inspectionItems: aggregates?.physical_inspection_item_categories_count ?? null,
        inspectionQuestions: aggregates?.physical_inspection_questions_count ?? null,
        inspectionProperties: aggregates?.physical_inspection_properties_count ?? null,
        hasMap: Boolean(node.hasFloorPlan),
        disabled: false,
        selectable: false,
      };
    });
  }, [applicableLocationIds, data?.nodes, filterId, nodesById]);

  return (
    <Box height="calc(100vh - 360px)" minHeight="600px">
      <ReviewLocationDetailsGrid rows={rows} disableSelection />
    </Box>
  );
};
