import { FCC } from '@circadian-risk/front-end-utils';
import { Box, Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useRef } from 'react';
import { useIntersection } from 'react-use';

import { EULA_DIALOG_ARIA_LABELS } from './ariaLabels';
import { EulaDialogProps } from './EulaDialog';

/**
 * Contents of the dialog modal that will make sure the user scrolls all the way to the bottom if bottom is not visible
 *
 * IMPLEMENTATION NOTE: in order for the observer to properly work with React life-cycles I had to
 * extract inner dialog content into a separate component
 */
export const EulaDialogContent: FCC<Pick<EulaDialogProps, 'onClose' | 'onAccept'>> = ({
  children,
  onAccept,
  onClose,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const intersection = useIntersection(contentRef, {});
  const scrolledToBottom = intersection && intersection.isIntersecting;

  return (
    <>
      <DialogTitle>Terms of Service</DialogTitle>
      <DialogContent>
        {children}
        <Box ref={contentRef} />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onAccept} disabled={!scrolledToBottom}>
          {EULA_DIALOG_ARIA_LABELS.agree}
        </Button>
      </DialogActions>
    </>
  );
};
