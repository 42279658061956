import { FCC } from '@circadian-risk/front-end-utils';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, IconButtonProps, Menu, SxProps, Theme } from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';

interface ListItemActionMenuProps {
  sx?: SxProps<Theme>;
  size?: IconButtonProps['size'];
}

/**
 * Used as a child of MUI's ListItemSecondaryAction
 */
export const ListItemActionMenu: FCC<ListItemActionMenuProps> = ({ size = 'medium', sx, children }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(prev => !prev);
  }, []);

  const handleClose = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(false);
  }, []);

  return (
    <>
      <IconButton edge="end" onClick={handleClick} ref={ref} size={size} sx={sx}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={ref.current}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {children}
      </Menu>
    </>
  );
};
