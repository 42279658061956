import { useApiClient } from '@circadian-risk/api-client-provider';
import { useNotification } from '@circadian-risk/front-end-utils';
import { PageFormLayout } from '@circadian-risk/layout';
import { sessionAccessPayloadSchema } from '@circadian-risk/shared';
import { useUserSessionStore } from '@circadian-risk/stores';
import { useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Session from 'supertokens-web-js/recipe/session';

import { ReactComponent as CitySvg } from './CityVector.svg';
import { OrganizationCreationForm } from './OrganizationCreationForm';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OrganizationCreationPageProps {}

export const OrganizationCreationPage: React.FC<OrganizationCreationPageProps> = () => {
  const { tsRestClient } = useApiClient();
  const [submitted, setSubmitted] = useState(false);
  const setSessionData = useUserSessionStore(state => state.setSessionData);
  const { displayError } = useNotification();
  const theme = useTheme();
  const tempBackground = theme.palette.primary.background;

  useEffect(() => {
    document.body.style.backgroundColor = tempBackground;
    return () => {
      document.body.removeAttribute('style');
    };
  }, [tempBackground]);

  return (
    <PageFormLayout
      layout={{ formMaxWidth: 600, fullHeight: false }}
      backgroundGraphics={<CitySvg height="100%" width="100%" />}
      form={
        <OrganizationCreationForm
          disabled={submitted}
          onSubmit={async data => {
            const {
              orgName,
              employeeCount,
              hierarchyType,
              industry,
              locationsCount: locationCount,
              numberOfAssessmentsPerYear,
            } = data;

            try {
              const { status } = await tsRestClient.organizations.create({
                body: {
                  name: orgName,
                  isRegionLayerIncluded: hierarchyType === 'plusRegion',
                  additionalMeta: {
                    min_employee_count: employeeCount.min,
                    max_employee_count: employeeCount.max,
                    min_location_count: locationCount.min,
                    max_location_count: locationCount.max,
                    industry,
                    min_yearly_assessment_count: numberOfAssessmentsPerYear.min,
                    max_yearly_assessment_count: numberOfAssessmentsPerYear.max,
                  },
                },
              });

              if (status !== 201) {
                throw new Error('Failed to create a new organization');
              }

              const payload = await Session.getAccessTokenPayloadSecurely();
              const result = sessionAccessPayloadSchema.safeParse(payload);
              if (!result.success) {
                throw new Error('Error while parsing the session information');
              }
              setSessionData(result.data);
              setSubmitted(true);
            } catch (ex) {
              displayError(ex);
            }
          }}
        />
      }
    />
  );
};
