import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Step, StepContent, StepIconProps, StepLabel, Stepper } from '@mui/material';
import React from 'react';

import { useReportGenerationStore } from './reportGenerationStore';

const iconSx = { width: 20, height: 20 };

const DefaultComponent: React.FC<StepIconProps> = props => {
  const { active, icon, completed } = props;

  return (
    <Box
      sx={theme => ({
        zIndex: 1,
        color: theme.palette.getContrastText(theme.palette.secondary.light),
        width: 24,
        height: 24,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        background: active
          ? theme.palette.primary.main
          : completed
          ? theme.palette.success.main
          : theme.palette.secondary.light,
      })}
    >
      {icon}
    </Box>
  );
};

export interface SectionStepperProps {
  handleNavigationChange: (newSectionTemplateId: string) => void;
}

export const SectionStepper: React.FC<SectionStepperProps> = ({ handleNavigationChange }) => {
  const sections = useReportGenerationStore(state => state.sections);
  const activeSectionIndex = useReportGenerationStore(state => state.activeSectionIndex);

  return (
    <Stepper
      activeStep={activeSectionIndex}
      sx={{
        '&.MuiStepper-root': {
          background: 'transparent',
        },
      }}
      orientation="vertical"
    >
      {sections.map((s, index) => {
        let content: React.ReactNode = s.template.name;
        let isComplete = false;
        let isPreviewOnly = false;
        let isVisible = true;
        let iconPropsIcon: React.ReactNode = index;

        if (s.fields.length === 0) {
          content = 'Preview only';
          isComplete = true;
          isPreviewOnly = true;
          iconPropsIcon = <CheckIcon sx={iconSx} />;
        } else {
          const fieldsFilled = s.fields.filter(f => f.value).length;
          content = `${fieldsFilled} of ${s.fields.length} fields complete`;
          if (fieldsFilled === s.fields.length) {
            isComplete = true;
            iconPropsIcon = <CheckIcon sx={iconSx} />;
          }
        }

        if (!s.visible) {
          content = 'This section is turned off';
          isComplete = true;
          isVisible = false;
          iconPropsIcon = <BlockIcon sx={iconSx} />;
        }

        return (
          <Step expanded key={s.id}>
            <StepLabel
              StepIconComponent={DefaultComponent}
              StepIconProps={{
                active: index === activeSectionIndex,
                completed: isComplete && isVisible && !isPreviewOnly,
                icon: iconPropsIcon,
              }}
              onClick={() => handleNavigationChange(s.template.id)}
              sx={theme => ({
                '&.MuiStepLabel-label': {
                  cursor: 'pointer',
                  color: theme.palette.primary.main,
                  '&:hover': {
                    color: theme.palette.primary.light,
                  },
                },
                '&.MuiStepLabel-completed': {
                  fontWeight: 400,
                },
              })}
            >
              {s.template.name}
            </StepLabel>
            <StepContent>{content}</StepContent>
          </Step>
        );
      })}
    </Stepper>
  );
};
