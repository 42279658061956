import { REDIRECT_PATH_KEY_WEB_APP } from '@circadian-risk/front-end-utils';
import { ErrorPage } from '@circadian-risk/graphql-utils';
import { ConfirmationDialogProvider } from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { useUserSessionStore } from '@circadian-risk/stores';
import { browserHistory } from '@web-app/lib/browserHistory';
import React, { Suspense, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet } from 'react-helmet';
import { matchPath, Route, RouteChildrenProps, Router, Switch, useLocation } from 'react-router-dom';
import { LastLocationProvider, RedirectWithoutLastLocation } from 'react-router-last-location';

import { config } from '../environments/environment';
import { AppAuthRouteGuard } from './AppAuthRouteGuard';
import { AuthenticatedSection } from './AuthenticatedSection';
import { LoadingPage } from './LoadingPage';
import NotFound from './NotFound';
import { PublicSection } from './PublicSection';
import { routeConfig } from './RouteConfig';

const PageTitle = () => {
  const location = useLocation();
  const matchingRoutes = routeConfig.filter(route => {
    return matchPath(location.pathname, { ...route });
  });

  const title = matchingRoutes.length === 0 ? undefined : matchingRoutes[0].pageTitle;
  const defaultName = config.WEBSITE_NAME || 'Circadian Risk';
  const pageTitle = title ? `${title} | ${defaultName}` : defaultName;

  return (
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>
  );
};

interface Props {
  history?: RouteChildrenProps['history'];
}

const publicRoutes = routeConfig.filter(x => x.isPublic && x.path !== ROUTES.WILDCARD);

const publicRoutesPaths = publicRoutes.map(x => x.path);

export const AllRoutes: React.FC = () => {
  const isAuthenticated = useUserSessionStore(state => state.isAuthenticated);

  const rootRoute = useMemo(() => {
    if (!isAuthenticated) {
      const path = window.location.pathname;
      if (path.includes('organization/')) {
        sessionStorage.setItem(REDIRECT_PATH_KEY_WEB_APP, path);
      }

      return ROUTES.SIGN_IN;
    }

    return '/organization/:organizationId';
  }, [isAuthenticated]);

  return (
    <Suspense fallback={<LoadingPage devMessage="Suspense" />}>
      <PageTitle />
      <Switch>
        <Route path={`/(organization|admin|switch|profile|change-password|${ROUTES.CREATE_ORG.replace('/', '')})`}>
          <AppAuthRouteGuard>
            <ErrorBoundary fallbackRender={() => <ErrorPage />}>
              <AuthenticatedSection />
            </ErrorBoundary>
          </AppAuthRouteGuard>
        </Route>
        <Route path={publicRoutesPaths}>
          <PublicSection>
            <Switch>
              {publicRoutes.map(route => {
                const routeProps = {
                  component: route.component,
                  exact: route.isExact,
                  path: route.path,
                  isPublic: route.isPublic,
                  allowRoles: route.allowRoles,
                };

                return <Route key={route.path} {...routeProps} />;
              })}
            </Switch>
          </PublicSection>
        </Route>
        <Route path="/" exact component={() => <RedirectWithoutLastLocation to={rootRoute} />} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Suspense>
  );
};

const MainRouter: React.FC<Props> = () => (
  <Router history={browserHistory}>
    <ConfirmationDialogProvider>
      <LastLocationProvider>
        <AllRoutes />
      </LastLocationProvider>
    </ConfirmationDialogProvider>
  </Router>
);

export default MainRouter;
