import { CircadianRoute, ROUTES } from '@circadian-risk/routes';
import { USER_ROLE } from '@circadian-risk/shared';
import { lazyLoadWithVersionCheck } from '@web-app/router/lazyLoadWithVersionCheck';

const DeficienciesByQuestionPage = lazyLoadWithVersionCheck(() => import('./DeficienciesByQuestionPage'));

export const deficienciesByQuestionRoutes = (): CircadianRoute[] => [
  {
    path: ROUTES.DEFICIENCIES_BY_QUESTION,
    component: DeficienciesByQuestionPage,
    isExact: true,
    allowRoles: [USER_ROLE.ORGANIZATION_ADMIN, USER_ROLE.LOCATION_MANAGER],
  },
];
