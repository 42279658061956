import { createEnhancedStore } from '@circadian-risk/front-end-utils';
import { CircadianColors } from '@circadian-risk/presentational';
import { DeepPartial } from '@circadian-risk/shared';
import { z } from 'zod';
import { StateCreator } from 'zustand';

export const darkModeLocalStorageKey = 'darkMode';
const colorModeValues = ['dark', 'light', 'system'] as const;
const LocalStorageSchema = z.enum(colorModeValues);
export type ColorModePreferences = z.infer<typeof LocalStorageSchema>;

const getColorModeLocalStorage = (): ColorModePreferences | null => {
  const colorMode = localStorage.getItem(darkModeLocalStorageKey);
  const colorModeParsed = LocalStorageSchema.safeParse(colorMode);
  return colorModeParsed.success ? colorModeParsed.data : null;
};

const setDarkModeLocalStorage = (colorMode: ColorModePreferences) => {
  const colorModeParsed = LocalStorageSchema.safeParse(colorMode);
  localStorage.setItem(darkModeLocalStorageKey, colorModeParsed.success ? colorMode : 'system');
};

interface ThemeStore {
  primaryColor?: string;
  organizationColors?: DeepPartial<CircadianColors>;
  lightLogoSrc?: string;
  darkLogoSrc?: string;
  colorMode: ColorModePreferences;
  finishedFetchingTheme: boolean;
  setColorMode: (mode: ColorModePreferences) => void;
  reset: () => void;
}

const stateCreator: StateCreator<ThemeStore> = set => ({
  finishedFetchingTheme: false,
  colorMode: getColorModeLocalStorage() ?? 'system',
  getLocalStorageValue: () => getColorModeLocalStorage(),
  setColorMode: mode => {
    setDarkModeLocalStorage(mode);
    set({ colorMode: mode });
  },
  reset: () =>
    set({
      primaryColor: undefined,
      lightLogoSrc: undefined,
      darkLogoSrc: undefined,
      organizationColors: undefined,
    }),
});

export const useThemeStore = createEnhancedStore(stateCreator, { byPassReset: true });
