import { AssessmentAssignment } from '@circadian-risk/api-contract';
import { TabObject, TabsWithContent } from '@circadian-risk/presentational';

import { AssignmentTable, AssignmentTableProps } from './AssignmentTable';
import { AssignmentType } from './AssignOrSubscribeCellRenderer';
import { teamsColDefs, userColDefs, userGroupsColDefs } from './col-defs';

export type AssignmentTab = 'user' | 'userGroup' | 'teams';

export type UserRow = {
  id: string;
  displayName: string;
  avatar?: string | null;
  lastSignedInAt?: string | null;
  email: string;
  role: string;
};

export type GroupRow = {
  // The entity id is a "numeric" but for the simplicity when
  // comparing we're going with string values
  id: string;
  name: string;
  users: UserRow[];
};

export type TeamRow = {
  // The entity id is a "numeric" but for the simplicity when
  // comparing we're going with string values
  id: string;
  name: string;
  users: UserRow[];
};

type UserAssignmentRows = AssessmentAssignment[keyof AssessmentAssignment];

const getSelectionIds = (rows: UserAssignmentRows) => {
  const assignedIds: string[] = [];
  const subscribedIds: string[] = [];

  for (const row of rows) {
    if (row.type === 'assignee') {
      assignedIds.push(String(row.id));
    } else {
      subscribedIds.push(String(row.id));
    }
  }

  return {
    assignedIds,
    subscribedIds,
  };
};

export type SelectionChangeParams = {
  id: string;
  type: AssignmentType;
  tab: AssignmentTab;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props = Pick<AssignmentTableProps<any>, 'gridAutoHeight'> & {
  groups: GroupRow[];
  teams: TeamRow[];
  users: UserRow[];
  assignment: AssessmentAssignment;
  /**
   * If the callback is not present, a label will be displayed instead
   * @param id
   * @param type
   * @param tab
   * @returns
   */
  onSelectionChange?: (params: SelectionChangeParams) => void;
};

export const AssessmentAssignmentTabbedTable: React.FC<Props> = ({
  users,
  groups,
  teams,
  assignment,
  onSelectionChange,
  gridAutoHeight,
}) => {
  const tabs: TabObject<AssignmentTab>[] = [
    {
      content: (
        <AssignmentTable
          {...getSelectionIds(assignment.user)}
          onSelect={onSelectionChange ? (id, type) => onSelectionChange({ id, type, tab: 'user' }) : undefined}
          colDefs={userColDefs}
          rows={users}
          gridAutoHeight={gridAutoHeight}
        />
      ),
      value: 'user',
      customLabel: 'User',
    },
    {
      content: (
        <AssignmentTable
          {...getSelectionIds(assignment.userGroup)}
          onSelect={onSelectionChange ? (id, type) => onSelectionChange({ id, type, tab: 'userGroup' }) : undefined}
          colDefs={userGroupsColDefs}
          rows={groups}
          gridAutoHeight={gridAutoHeight}
        />
      ),
      value: 'userGroup',
      customLabel: 'User Groups',
    },
    {
      content: (
        <AssignmentTable
          {...getSelectionIds(assignment.teams)}
          onSelect={onSelectionChange ? (id, type) => onSelectionChange({ id, type, tab: 'teams' }) : undefined}
          colDefs={teamsColDefs}
          rows={teams}
          gridAutoHeight={gridAutoHeight}
        />
      ),
      value: 'teams',
      customLabel: 'Teams',
    },
  ];

  return <TabsWithContent initialTabSelected="user" tabs={tabs} />;
};
