import { useIsPhoneScreen } from '@circadian-risk/front-end-utils';
import { GenericDialog } from '@circadian-risk/presentational';
import { Box } from '@mui/material';
import React from 'react';

import { LocationWizardProps } from './components/LocationWizard/LocationWizard';
import { LocationWizardSmart } from './LocationWizard.smart';

export interface LocationWizardSmartModalProps {
  initialMode?: LocationWizardProps['initialMode'];
  isOpen: boolean;
  onClose: () => void;
}

export const LocationWizardSmartModal: React.FC<LocationWizardSmartModalProps> = ({ isOpen, onClose, initialMode }) => {
  const isPhoneScreen = useIsPhoneScreen();

  return (
    <GenericDialog
      title="Location Wizard"
      isOpen={isOpen}
      onClose={onClose}
      closeVerb="Close"
      dialogContentProps={{
        sx: { overflowX: 'hidden' },
      }}
      dialogProps={{
        fullScreen: isPhoneScreen,
        fullWidth: true,
        maxWidth: 'lg',
        sx: { backdropFilter: 'blur(3px)' },
      }}
    >
      {/* this allows height to match dialog's max height */}
      <Box height={isPhoneScreen ? 'unset' : 'calc(100vh - 220px)'}>
        <LocationWizardSmart initialMode={initialMode} />
      </Box>
    </GenericDialog>
  );
};
