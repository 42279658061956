import { UsersStepContent } from '@circadian-risk/scheduled-assessments';
import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { ScenarioFormData } from './ScenarioAssessmentForm.types';

export const SmartUsersStepContent: React.FC = () => {
  const { watch } = useFormContext<ScenarioFormData>();
  const locationIds = watch('locationIds');
  return (
    <Box p={2}>
      <UsersStepContent locationIds={locationIds} />;
    </Box>
  );
};
