import { z } from 'zod';

import { USER_ROLE } from '../data/types';

export const sessionAccessPayloadSchema = z.object({
  isCircadianAdmin: z.boolean(),
  isPendingEmailVerification: z.boolean(),
  // Persists the timestamp when the activation expires
  activationExpiry: z.number().optional(),
  consentsToMarketingCommunication: z.boolean(),
  isSsoUser: z.boolean(),
  email: z.string().email(),
  needsToSignEula: z.boolean().default(false),
  jwt: z.string().optional(),
  'https://hasura.io/jwt/claims': z.object({
    'x-hasura-user-id': z.string(),
    'x-hasura-org-id': z.string().optional(),
    'x-hasura-default-role': z.string(),
    'x-hasura-allowed-roles': z.array(z.nativeEnum(USER_ROLE)),
  }),
  subscriberHash: z.string(),
});

export type SessionAccessPayload = z.infer<typeof sessionAccessPayloadSchema>;
