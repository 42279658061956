import { useCompleteFragment } from '@circadian-risk/apollo-utils';
import { useOrganizationId } from '@circadian-risk/stores';

import { LocationFiltersForCreateAssessmentFragmentDoc } from '../../../../graphql/typed-nodes';

export const useLocationFilters = () => {
  const organizationId = useOrganizationId();
  const data = useCompleteFragment({
    fragment: LocationFiltersForCreateAssessmentFragmentDoc,
    key: { id: organizationId },
  });

  return data.filters;
};
