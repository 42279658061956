import { FCC } from '@circadian-risk/front-end-utils';
import { Box, SxProps, Theme } from '@mui/material';
import React, { useContext } from 'react';

import { DrawerContext } from './DrawerContext';
import { DEFAULT_DRAWER_WIDTH, DrawerStyleProps } from './styles';

interface ControlsProps extends DrawerStyleProps {
  sx?: SxProps<Theme>;
}

export const Controls: FCC<ControlsProps> = ({ children, drawerWidth, sx }) => {
  const drawerContext = useContext(DrawerContext);
  const isOpen = drawerContext?.isOpen;

  return (
    <Box
      position="fixed"
      left={isOpen ? drawerWidth ?? DEFAULT_DRAWER_WIDTH : 0}
      marginTop={1}
      sx={[
        theme => ({
          transition: theme.transitions.create('left', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          ...(isOpen
            ? {
                transition: theme.transitions.create('left', {
                  easing: theme.transitions.easing.easeOut,
                  duration: theme.transitions.duration.enteringScreen,
                }),
              }
            : {}),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Box>
  );
};
