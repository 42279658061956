import {
  AriaLabels,
  FormPaper,
  FormStack,
  FormTitle,
  generateCircadianForm,
  passwordSchema,
} from '@circadian-risk/form';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { z } from 'zod';

import { ReactComponent as Logo } from '../../assets/LogoWithText.svg';

export const signInFormSchema = z.object({
  email: z.string().min(1, 'Email is required'),
  password: passwordSchema,
  rememberEmail: z.boolean().optional(),
});

export type SignInFormData = z.infer<typeof signInFormSchema>;

export const signInFormAriaLabels: AriaLabels<SignInFormData> & { continue: string } = {
  form: 'Sign In',
  email: 'Email',
  password: 'Password',
  continue: 'Continue',
  rememberEmail: 'Remember my email',
  submit: 'Sign In',
};

const { useCircadianForm, Text, Checkbox, Password, SubmitButton, CircadianForm } = generateCircadianForm(
  signInFormSchema,
  signInFormAriaLabels,
);

export interface SignInFormProps {
  email: string | null;
  rememberEmail: boolean;
  checkLoginOptions: (email: string, rememberEmail?: boolean) => Promise<'email-and-pass' | 'SSO' | null>;
  onSubmit: (data: SignInFormData) => Promise<void>;
  onResetPassword: (email?: string) => void;
}

export const SignInForm: React.FC<SignInFormProps> = ({
  email,
  rememberEmail,
  checkLoginOptions,
  onSubmit,
  onResetPassword,
}) => {
  const [loginOption, setLoginOption] = useState<'email-and-pass' | null>(null);

  const formMethods = useCircadianForm({
    defaultValues: {
      email: email ?? '',
      password: '',
      rememberEmail,
    },
  });

  const [watchEmail, watchRememberEmail] = formMethods.watch(['email', 'rememberEmail']);

  const focusPasswordField = () => {
    const passwordSelector = `[type="password"]`;
    const passwordField: HTMLInputElement | null = document.querySelector(passwordSelector);
    passwordField && passwordField.focus();
  };

  const handleContinue = async () => {
    const loginOptionForTheEmail = await checkLoginOptions(watchEmail, watchRememberEmail);

    if (loginOptionForTheEmail === 'email-and-pass') {
      setLoginOption(loginOptionForTheEmail);
      focusPasswordField();
    }
  };

  const handleEmailFieldKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    // if user pressed enter, prevent form submission but check login options
    if (e.key === 'Enter') {
      e.preventDefault();
      handleContinue();
    }
  };

  const continueButton = (
    <Button variant="contained" onClick={handleContinue} disabled={!watchEmail}>
      {signInFormAriaLabels.continue}
    </Button>
  );

  return (
    <CircadianForm formMethods={formMethods} onSubmit={onSubmit}>
      <FormPaper showBg>
        <FormStack>
          <FormTitle>{signInFormAriaLabels.form}</FormTitle>

          <Box display="flex" justifyContent="center" p={2}>
            {/* TODO(iprokopovich): [CR-1507] move logo component to presentational and use it here */}
            <Logo width="210px" height="105px" />
          </Box>

          <Text formKey="email" boxProps={{ onKeyDown: handleEmailFieldKeyDown }} />

          {loginOption != null && <Password formKey="password" />}

          {loginOption == null ? continueButton : <SubmitButton />}

          <Button variant="text" onClick={() => onResetPassword(formMethods.getValues('email'))}>
            Reset Password
          </Button>

          <Checkbox formKey="rememberEmail" />
        </FormStack>
      </FormPaper>
    </CircadianForm>
  );
};
