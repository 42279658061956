import ArrowRightIcon from '@mui/icons-material/PlayArrowSharp';
import { Typography, useTheme } from '@mui/material';
import React from 'react';

import { Bold, HStack, Strikethrough } from '../..';
import { EmptyValue } from './EmptyValue';

const DiffValue: React.FC<{ value?: number | null; strikethrough?: boolean; bold?: boolean; color?: string }> = ({
  value,
  color,
  bold,
  strikethrough,
}) => {
  if (value === undefined) {
    return null;
  }

  if (value === null) {
    return <EmptyValue />;
  }

  let displayValue: React.ReactNode = value;

  if (bold) {
    displayValue = <Bold weight="bold">{displayValue}</Bold>;
  }

  if (strikethrough) {
    displayValue = <Strikethrough>{displayValue}</Strikethrough>;
  }

  return (
    <Typography fontSize="inherit" color={color}>
      {displayValue}
    </Typography>
  );
};

export interface NumberDiffProps {
  previous?: number | null;
  current?: number | null;
}

export const NumberDiff: React.FC<NumberDiffProps> = ({ previous, current }) => {
  const theme = useTheme();

  const hasChanges = current !== previous;

  // only show diff if both values are explicitly provided, null is valid
  const hasBothValues = previous !== undefined && current !== undefined;

  // don't show diff if both values are null or undefined
  if (previous == null && current == null) {
    return <EmptyValue />;
  }

  // hide previous if it was null or undefined
  if (previous == null && current != null) {
    return <DiffValue bold value={current} />;
  }

  return (
    <HStack noFullWidth spacing={0.5}>
      {hasChanges && <DiffValue strikethrough={hasBothValues} value={previous} color={theme.palette.text.secondary} />}
      {hasChanges && hasBothValues && <ArrowRightIcon color="action" sx={{ fontSize: '0.7em' }} />}
      <DiffValue bold value={current} />
    </HStack>
  );
};
