import { Typography } from '@mui/material';

interface Props {
  error: React.ReactNode;
}

export const FieldError: React.FC<Props> = ({ error }: Props) => {
  return (
    <Typography variant="caption" sx={theme => ({ color: theme.palette.error.main })}>
      {error}
    </Typography>
  );
};
