import DoneIcon from '@mui/icons-material/Done';
import { Box, styled, Typography, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import React, { CSSProperties } from 'react';

import { HStack, VStack } from '../..';
import { Bold } from '../Text';

export interface StepProps {
  title: string;
  description?: string;
  number: number;
  content?: React.ReactNode;

  active: boolean;
  required: boolean;
  completed: boolean;
  error?: boolean;
  keepDescriptionOnCompleted?: boolean;
  onClick?: () => void;
}

const size = 24;
const iconStyles: CSSProperties = {
  position: 'absolute',
  width: size,
  height: size,
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transform: 'translate(-50%, -50%)',
};

const AnimatedIcon: React.FC<{ show: boolean; children: React.ReactNode }> = ({ show, children }) => (
  <motion.div
    style={{ ...iconStyles }}
    transition={{ duration: 0.2 }}
    initial={false}
    animate={{
      scale: show ? 1 : 0,
      rotate: show ? 0 : -60,
      opacity: show ? 1 : 0,
    }}
  >
    {children}
  </motion.div>
);

const StepIcon: React.FC<Pick<StepProps, 'completed' | 'number' | 'active' | 'error'>> = ({
  completed,
  error,
  active,
  number,
}) => {
  const { palette } = useTheme();

  const backgroundColor = (() => {
    if (error) {
      return active ? palette.error.main : palette.error.light;
    }
    if (completed || active) {
      return palette.primary.main;
    }
    return palette.secondary.light;
  })();

  const color = palette.getContrastText(backgroundColor);

  return (
    <Box
      component={motion.div}
      animate={{ backgroundColor, color }}
      sx={{
        position: 'relative',
        minWidth: size,
        minHeight: size,
        borderRadius: '50%',
      }}
    >
      <AnimatedIcon show={!completed}>{number}</AnimatedIcon>
      <AnimatedIcon show={completed}>
        <DoneIcon sx={{ p: 0.25 }} />
      </AnimatedIcon>
    </Box>
  );
};

const Wrapper = styled(HStack)(({ theme }) => ({
  alignItems: 'flex-start',
  padding: theme.spacing(2),
  borderLeft: `0px solid ${theme.palette.primary.main}`,
  transition: theme.transitions.create(['border-left-width', 'background-color'], {
    duration: theme.transitions.duration.shortest,
  }),

  '&:hover': {
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.background,
  },

  '&[data-active=true]': {
    borderLeftWidth: 4,
    backgroundColor: theme.palette.primary.background,
  },

  '&[data-error=true]': {
    borderLeftWidth: 4,
    borderColor: theme.palette.error.light,
    '&:hover': {
      backgroundColor: theme.palette.error.background,
    },
    '&[data-active=true]': {
      backgroundColor: theme.palette.error.background,
      borderColor: theme.palette.error.main,
    },
  },
}));

const AnimatedCollapse: React.FC<{ show: boolean; children: React.ReactNode }> = ({ show, children }) => (
  <motion.div
    style={{ overflow: 'hidden' }}
    animate={{ height: show ? 'auto' : 0 }}
    initial={false}
    transition={{ duration: 0.2 }}
  >
    {children}
  </motion.div>
);

export const Step: React.FC<StepProps> = ({
  title,
  description,
  number,
  content,
  active,
  required,
  completed,
  error,
  onClick,
  keepDescriptionOnCompleted,
}) => {
  return (
    <Wrapper data-active={active} data-error={error} onClick={onClick}>
      <VStack spacing={0}>
        <HStack spacing={2}>
          <StepIcon completed={completed} error={error} number={number} active={active} />
          <VStack spacing={0}>
            <Typography>{title}</Typography>

            {required && (
              <Typography component={'span'} variant="body2" color={error ? 'error' : 'secondary'}>
                <Bold>Required</Bold>
              </Typography>
            )}
          </VStack>
        </HStack>

        <VStack spacing={0} pl={5}>
          {description && (
            <AnimatedCollapse show={keepDescriptionOnCompleted || !completed}>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                {description}
              </Typography>
            </AnimatedCollapse>
          )}

          {content && (
            <AnimatedCollapse show={active}>
              <Box sx={{ mt: 1 }}>{content}</Box>
            </AnimatedCollapse>
          )}
        </VStack>
      </VStack>
    </Wrapper>
  );
};
