import { CellRendererParams } from '@circadian-risk/data-grid';
import { CriticalityIcon, PopperTooltip } from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { USER_ROLE } from '@circadian-risk/shared';
import { useOrganizationRole } from '@circadian-risk/stores';
import NoteIcon from '@mui/icons-material/Note';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from '@mui/material';
import NavLinkWithActiveOrgId from '@web-app/components/NavLinkWithActiveOrgId';
import React from 'react';

export const ScenarioCellRenderer: React.FC<
  CellRendererParams<{ scenarios: { criticality: number; isRequired: boolean; id: number; name: string }[] }>
> = ({ data }) => {
  const { scenarios } = data;
  const theme = useTheme();
  const role = useOrganizationRole();

  return (
    <Box display="flex" alignItems="center">
      <Box mr={1}>{scenarios.length}</Box>
      {scenarios.length > 0 && (
        <PopperTooltip variant="persistent" trigger={<NoteIcon />}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell variant="head">Related Scenario</TableCell>
                  <TableCell variant="head">Required</TableCell>
                  <TableCell variant="head">Criticality</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {scenarios.map(scenario => {
                  const { criticality, isRequired, id, name } = scenario;
                  const safeCriticality = criticality as 1 | 2 | 3;
                  return (
                    <TableRow key={id}>
                      <TableCell>
                        {role === USER_ROLE.ORGANIZATION_ADMIN ? (
                          <NavLinkWithActiveOrgId to={ROUTES.SCENARIOS_DETAIL.replace(':id', id.toString())}>
                            {name}
                          </NavLinkWithActiveOrgId>
                        ) : (
                          <>{name}</>
                        )}
                      </TableCell>
                      <TableCell>{isRequired ? 'yes' : 'no'}</TableCell>
                      <TableCell>
                        <CriticalityIcon
                          criticality={criticality}
                          color={theme.palette.organizationColors.criticality[safeCriticality]}
                          maxCriticality={3}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </PopperTooltip>
      )}
    </Box>
  );
};
