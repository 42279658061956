import { imageSrc } from '@circadian-risk/front-end-utils';
import { alpha, Box, Typography, useTheme } from '@mui/material';
import React from 'react';

type Size = 'small' | 'medium' | 'large' | 'extraLarge';

const dimensions: Record<Size, number> = {
  small: 24,
  medium: 32,
  large: 48,
  extraLarge: 64,
};

export const fontSizes: Record<Size, string> = {
  small: '0.8em',
  medium: '1.0em',
  large: '1.5em',
  extraLarge: '3em',
};

export interface ScenarioSourceProps {
  name: string;
  iconUrl?: string;
  size?: Size;
}

export const ScenarioSource: React.FC<ScenarioSourceProps> = ({ name, iconUrl, size = 'medium' }) => {
  const theme = useTheme();
  const palette = theme.palette;

  // this color is just an experiment, eventually we might add this to be our secondary color
  const backgroundColor = palette.utils.getDynamicColor('#d5c2a3');
  const primaryColor = palette.utils.getDynamicColor(backgroundColor);
  const contrastColor = palette.getContrastText(primaryColor);

  const dimension = dimensions[size];
  const fontSize = fontSizes[size];

  return (
    <Box
      bgcolor={primaryColor}
      display="flex"
      flexDirection="row"
      flexWrap="nowrap"
      alignItems="center"
      maxWidth="fit-content"
      height={dimension}
      gap={size === 'small' ? 0.5 : 1}
      py={0.5}
      px={1}
      borderRadius={2}
      sx={{ cursor: 'default' }}
    >
      {iconUrl && (
        <Box
          sx={{
            mask: `url(${imageSrc(iconUrl)}) no-repeat center`,
            maskSize: 'contain',
            width: dimension - 16,
            height: dimension - 16,
            bgcolor: contrastColor,
            color: contrastColor,
          }}
        />
      )}
      {iconUrl && <Box sx={{ bgcolor: alpha(contrastColor, 0.6), width: '1px', height: dimension - 16 }} />}
      <Typography noWrap color={contrastColor} fontSize={fontSize}>
        {name}
      </Typography>
    </Box>
  );
};
