import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';
import { MdQuestionAnswer as InspectionQuestionnaireIcon } from 'react-icons/md';

import { ReactComponent as PhysicalInspectionIcon } from '../assets/icons/physical-inspection-icon.svg';

export interface AssessmentTypeIconProps extends SvgIconProps {
  type: 'Inspection Questionnaire' | 'Physical Inspection';
}

export const AssessmentTypeIcon: React.FC<AssessmentTypeIconProps> = ({ type, ...iconProps }) => {
  const Icon = type === 'Inspection Questionnaire' ? InspectionQuestionnaireIcon : PhysicalInspectionIcon;

  return <SvgIcon inheritViewBox component={Icon} {...iconProps} />;
};
