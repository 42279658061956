import { useNotification } from '@circadian-risk/front-end-utils';
import includes from 'lodash/includes';
import { Redirect, useParams } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';

export const AssessorDashboard: React.FC = () => {
  const lastLocation = useLastLocation();
  const { organizationId } = useParams<{ organizationId: string }>();
  const { displayWarning } = useNotification();

  if (includes(['/sign-in', '/switch', '/organization/:organizationId'], lastLocation?.pathname)) {
    return <Redirect to={`/organization/${organizationId}/assessments`} />;
  }

  displayWarning('You do not have access to this page.');

  if (!lastLocation) {
    return <Redirect to={`/organization/${organizationId}/assessments`} />;
  }

  return <Redirect to={lastLocation.pathname} />;
};
