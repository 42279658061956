import { useIsPhoneScreen } from '@circadian-risk/front-end-utils';
import { Box, BoxProps, Checkbox, FormControlLabel, Typography } from '@mui/material';
import React from 'react';

export interface CheckboxMultiFieldProps {
  label?: React.ReactNode;
  value?: string[] | null;
  onChange(value: string[]): void;
  options: { value: string; label: string }[];
  disabled?: boolean;
  endAdornment?: React.ReactNode;
  boxProps?: BoxProps;
}

export const CheckboxMultiField: React.FC<CheckboxMultiFieldProps> = ({
  label,
  options,
  value,
  onChange,
  disabled,
  endAdornment,
  boxProps,
}) => {
  const isPhoneScreenSize = useIsPhoneScreen();
  const selectedValues = value;

  const handleChange = (changedValue: string) => {
    if (selectedValues) {
      const previouslyChecked = selectedValues.findIndex(v => v === changedValue);
      if (previouslyChecked === -1) {
        onChange([...selectedValues, changedValue]);
      } else {
        const newValue = [...selectedValues];
        newValue.splice(previouslyChecked, 1);
        onChange(newValue);
      }
    } else {
      onChange([changedValue]);
    }
  };

  return (
    <>
      <Typography variant="body1">{label}</Typography>
      <Box
        flexDirection={isPhoneScreenSize ? 'column' : 'row'}
        display={'flex'}
        alignItems={isPhoneScreenSize ? 'flex-start' : 'center'}
        {...boxProps}
      >
        {options.map(option => (
          <FormControlLabel
            key={option.value}
            label={option.label}
            control={
              <Checkbox
                name={option.value}
                checked={selectedValues ? Boolean(selectedValues.find(o => o === option.value)) : false}
                onChange={() => handleChange(option.value)}
                disabled={disabled}
              />
            }
          />
        ))}
        {endAdornment}
      </Box>
    </>
  );
};
