import { Link, Typography, TypographyProps, useTheme } from '@mui/material';
import omit from 'lodash/omit';
import React from 'react';

import { NodeIcon } from '../..';
import { TouchFriendlyTooltip } from '../Tooltips';

export interface LocationWithIconProps extends Omit<TypographyProps, 'children' | 'onClick'> {
  layerName: string;
  locationName: string;
  disabled?: boolean;
  showTooltip?: boolean;
  onClick?: () => void;
}

export const LocationWithIcon: React.FC<LocationWithIconProps> = ({
  layerName,
  locationName,
  showTooltip,
  onClick,
  disabled,
  ...typographyProps
}) => {
  const theme = useTheme();
  const allTypographyProps = {
    color: disabled ? theme.palette.text.disabled : typographyProps.color,
    fontWeight: 'inherit',
    fontSize: typographyProps.variant ? undefined : 'inherit',
    ...typographyProps,
  };

  const LocationComponent = (
    <TouchFriendlyTooltip tooltipContent={showTooltip ? `${locationName} is a ${layerName}` : undefined}>
      <Typography {...allTypographyProps} display="flex" gap={0.5} alignItems="center" component={'span'}>
        <NodeIcon fontSize="inherit" layerName={layerName} />
        <Typography {...allTypographyProps} component={'span'}>
          {locationName}
        </Typography>
      </Typography>
    </TouchFriendlyTooltip>
  );

  if (onClick) {
    return (
      <Link {...omit(allTypographyProps, ['ref'])} onClick={onClick} underline="hover" sx={{ cursor: 'pointer' }}>
        {LocationComponent}
      </Link>
    );
  } else {
    return LocationComponent;
  }
};
