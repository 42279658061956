import { DeepPartial } from '@circadian-risk/shared';
import { jsonrepair } from 'jsonrepair';
import { useEffect, useState } from 'react';
import { useThrottle } from 'react-use';

export const useParseJSONStream = <T>(throttleTime = 200) => {
  const [dataStream, setDataStream] = useState<string>('');
  const [data, setData] = useState<DeepPartial<T>>({});

  const throttledDataStream = useThrottle(dataStream, throttleTime);

  useEffect(() => {
    if (throttledDataStream) {
      try {
        const json = jsonrepair(throttledDataStream);
        const data = JSON.parse(json) as DeepPartial<T>;
        setData(data);
      } catch (e) {
        return;
      }
    }
  }, [throttledDataStream]);

  const appendDataStream = (data: string) => {
    setDataStream(prev => prev + data);
  };

  const resetDataStream = () => {
    setData({});
    setDataStream('');
  };

  return [
    data,
    {
      appendDataStream,
      setDataStream,
      resetDataStream,
    },
  ] as const;
};
