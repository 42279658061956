import React from 'react';

import { useCompletionConditions } from '../LocationScenarioAssessmentForm/ScenarioAssessmentForm/useCompletionConditions';
import { AnswerSummaryTable } from './AnswerSummaryTable';

export const AnswerSummaryTableSmart: React.FC = () => {
  const { toDoTotal, ratifyCounts, ratifyEnabled, newQuestionsCount } = useCompletionConditions();

  return (
    <AnswerSummaryTable
      breakdown={{
        compliant: ratifyEnabled.compliant ? ratifyCounts.compliant : null,
        deficient: ratifyEnabled.deficient ? ratifyCounts.deficient : null,
        n_a: ratifyEnabled.n_a ? ratifyCounts.n_a : null,
        n_o: ratifyEnabled.n_o ? ratifyCounts.n_o : null,
      }}
      newCount={newQuestionsCount}
      total={toDoTotal}
    />
  );
};
