import { AssessmentItemStatuses, useStructuralMemo } from '@circadian-risk/front-end-utils';
import { ItemIconProps } from '@circadian-risk/presentational';
import { Coordinates } from '@circadian-risk/shared';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorEvent, MapLayerMouseEvent, MapProps as ReactMapGlProps, MapProvider } from 'react-map-gl';
import { useUpdateEffect } from 'react-use';

import { HandlePointChange, HandlePointClick, MarkerPointLayer, PopoverMode, RemovePoi, RootMap } from '../..';
import { debug } from '../utils/debug';
import { MapContextProvider, MapContextProviderProps, OnDeleteItem, OnItemSave, OnUpdateItem } from './MapContext';
import { MarkerImage } from './MarkerImage';
import { MarkerPoint } from './MarketPoint';

export interface MapProps<T extends Record<string, any>> extends Partial<ReactMapGlProps> {
  mapId: string;
  panToMarkerCoordinates: (coordinates: [number, number]) => void;
  initialActivePoint?: string | null;
  markerImages?: MarkerImage[];
  markerPoints: MarkerPoint<T>[];
  onItemSave: OnItemSave;
  onUpdateItem: OnUpdateItem;
  onDeleteItem: OnDeleteItem;
  setMapActive: (value: boolean) => void;
  setDetailsActive: (value: boolean) => void;
  onPointChange?: HandlePointChange;
  selectedId?: string | null;
  setSelectedId: (id: string | null) => void;
  onMapClickOrTap?: (coords: Coordinates) => void;
  isReadOnly?: boolean;
  popoverMode: PopoverMode;
  setPopoverMode: (mode: PopoverMode) => void;
  mapStyles: {
    statusToStylePropMap: Record<AssessmentItemStatuses, Omit<ItemIconProps, 'alt'>>;
  };
}

interface MarkerPointsById<T extends Record<string, any>> {
  [key: string]: MarkerPoint<T>[];
}

export const Map = <T extends Record<string, any>>({
  panToMarkerCoordinates,
  onPointChange: _onPointChange,
  markerImages: _markerImages,
  markerPoints,
  initialActivePoint,
  onItemSave,
  onUpdateItem,
  onDeleteItem,
  mapStyle,
  selectedId,
  setSelectedId,
  onMapClickOrTap,
  setMapActive: _setMapActive,
  setDetailsActive,
  isReadOnly: _isReadOnly,
  popoverMode,
  setPopoverMode,
  mapId,
  mapStyles,
  ...props
}: MapProps<T>) => {
  // we should only allow one single point to be dragged at once
  const [activeDraggingPoint, setActiveDraggingPoint] = useState<string | undefined>(undefined);
  const { statusToStylePropMap } = mapStyles;

  // Ensure we reset the dragging point if the popover changes to select
  useUpdateEffect(() => {
    if (popoverMode === 'select' && activeDraggingPoint) {
      setActiveDraggingPoint(undefined);
    }
  }, [popoverMode]);

  const handlePointClick: HandlePointClick = useCallback(
    ({ id, coordinates }) => {
      if (popoverMode !== 'move' && id === selectedId) {
        setSelectedId(null);
        setActiveDraggingPoint(undefined);
      } else {
        panToMarkerCoordinates(coordinates);
        setSelectedId(id);
        popoverMode === 'move' && setActiveDraggingPoint(id);
      }
    },
    [popoverMode, selectedId, setSelectedId, panToMarkerCoordinates],
  );

  const handlePopupClose = useCallback(() => {
    setActiveDraggingPoint(undefined);
    setPopoverMode('select');
  }, [setActiveDraggingPoint, setPopoverMode]);

  const handleMapClick = useCallback(
    (event: MapLayerMouseEvent) => {
      if (onMapClickOrTap) {
        onMapClickOrTap([event.lngLat.lng, event.lngLat.lat]);
      }
      if (popoverMode === 'move') {
        setPopoverMode('select');
        setActiveDraggingPoint(undefined);
      } else {
        handlePopupClose();
      }
    },
    [handlePopupClose, onMapClickOrTap, popoverMode, setPopoverMode],
  );

  useEffect(() => {
    if (initialActivePoint && initialActivePoint !== activeDraggingPoint && popoverMode === 'move') {
      setActiveDraggingPoint(initialActivePoint);
    }
  }, [initialActivePoint, activeDraggingPoint, popoverMode]);

  const interceptPointChange: HandlePointChange = useCallback(
    (id, coordinates) => {
      onUpdateItem(id, { coordinates: { x: coordinates[0], y: coordinates[1] } });
    },
    [onUpdateItem],
  );

  const mapLayerDependencies = useStructuralMemo({
    markerPoints,
    activeDraggingPoint,
    popoverMode,
    statusToStylePropMap,
    selectedId,
    interceptPointChange,
    handlePointClick,
  });

  const memoizedMarkerLayers = useMemo(() => {
    debug.extend('Map Rendering')('building map layers and markers');

    const {
      markerPoints,
      activeDraggingPoint,
      popoverMode,
      statusToStylePropMap,
      selectedId,
      interceptPointChange,
      handlePointClick,
    } = mapLayerDependencies;

    const markerPointsById: MarkerPointsById<T> = markerPoints.reduce(
      (markerPoints: MarkerPointsById<T>, markerPoint) => {
        // make one layer for the draggable point
        //  - seems to result in more responsive initial drag detection
        //  - also allows special styling
        if (markerPoint.id === activeDraggingPoint && popoverMode === 'move') {
          markerPoints['draggable'] = [markerPoint];
          return { ...markerPoints };
        }

        // a new layer for every group of features dependent on the same image type
        // eg: All Cameras share the same layer.
        // a.cameras = markerpoint[] <-- pseudocode
        if (markerPoint.iconImageId) {
          if (!markerPoints[markerPoint.iconImageId]) {
            markerPoints[markerPoint.iconImageId] = [];
          }
          markerPoints[markerPoint.iconImageId].push(markerPoint);
          return { ...markerPoints };
        }

        // these end up as dots on the map since they have no image
        if (!markerPoints['noImage']) {
          markerPoints['noImage'] = [];
        }
        markerPoints['noImage'].push(markerPoint);
        return { ...markerPoints };
      },
      { draggable: [] },
    );

    const pointsWithStyle: [key: string, points: MarkerPoint<T>[]][] = Object.entries(markerPointsById).map(
      ([key, pointArray]) => {
        const pointArrayWithStyles = pointArray.map(point => {
          return {
            ...point,
            itemIconProps: {
              ...statusToStylePropMap[point.status],
              isInverted: selectedId ? point.id !== selectedId : true,
              hasShadow: selectedId ? selectedId === point.id : false,
            },
          };
        });
        return [key, pointArrayWithStyles];
      },
    );

    const layers = pointsWithStyle.map(([key, pointArray]) => (
      <MarkerPointLayer
        activeDraggingPoint={activeDraggingPoint}
        handlePointChange={interceptPointChange}
        handlePointClick={handlePointClick}
        id={key}
        key={`marker-point-layer-${key}`}
        markerPoints={pointArray}
      />
    ));

    return layers;
  }, [mapLayerDependencies]);

  const mapContextData: MapContextProviderProps['data'] = useMemo(
    () => ({
      onItemSave,
      onUpdateItem,
      onDeleteItem,
      onSwitchToDetailsMode: () => setDetailsActive(true),
    }),
    [onItemSave, onUpdateItem, onDeleteItem, setDetailsActive],
  );

  const handleMapError = useCallback((e: ErrorEvent) => {
    // Detect 404 due to invalid map style
    if (e.error.message === 'Not Found') {
      e.target.setStyle('mapbox://styles/mapbox/light-v10');
    }
  }, []);

  return (
    <MapContextProvider data={mapContextData}>
      <MapProvider>
        <RootMap
          mapRefId={mapId}
          {...props}
          data-testid="map"
          mapStyle={mapStyle}
          touchZoomRotate
          dragRotate
          onClick={handleMapClick}
          onError={handleMapError}
        >
          {memoizedMarkerLayers}
          <RemovePoi mapId={mapId} />
        </RootMap>
      </MapProvider>
    </MapContextProvider>
  );
};
