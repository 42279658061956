import { Box } from '@mui/material';
import capitalize from 'lodash/capitalize';

import { CellRendererParams } from '../helpers';

export const AnswerResponseCellRenderer: React.FC<CellRendererParams<{ response: string }>> = props => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <span>{capitalize(props.data.response)}</span>
    </Box>
  );
};
