import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { DataGrid, DataGridProps } from '@circadian-risk/data-grid';
import { splitMpath } from '@circadian-risk/front-end-utils';

import { columnDefs, LocationCellRenderer } from './review-location-details-column-defs';
import { ReviewLocationDetailsRow } from './review-location-details-types';

export type ReviewLocationDetailsGridProps = {
  rows: ReviewLocationDetailsRow[];
  dataGridProps?: Partial<DataGridProps>;
  /**
   * @default false
   */
  disableSelection?: boolean;
};

export const ReviewLocationDetailsGrid: React.FC<ReviewLocationDetailsGridProps> = ({
  rows,
  dataGridProps,
  disableSelection = false,
}) => {
  return (
    <DataGrid
      modules={[RowGroupingModule]}
      defaultColDef={{
        flex: 0,
        resizable: true,
        filter: false,
        // https://blog.ag-grid.com/wrapping-column-header-text/
        headerComponentParams: {
          template:
            '<div class="ag-cell-label-container" role="presentation">' +
            '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
            '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
            '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
            '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
            '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
            '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
            '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
            '  </div>' +
            '</div>',
        },
      }}
      treeData
      rowData={rows}
      columnDefs={columnDefs}
      gridOptions={{
        getDataPath: (data: ReviewLocationDetailsRow) => {
          return splitMpath(data.relativeMpath);
        },
        isRowSelectable: node => {
          return node.data.selectable;
        },
      }}
      animateRows
      suppressRowClickSelection
      groupDefaultExpanded={-1}
      autoGroupColumnDef={{
        headerName: 'Locations',
        minWidth: 330,
        headerCheckboxSelection: !disableSelection,
        cellRendererParams: {
          checkbox: !disableSelection,
          suppressCount: true,
          innerRenderer: LocationCellRenderer,
        },
        pinned: 'left',

        filter: 'agTextColumnFilter',
        flex: 1,
      }}
      getRowId={({ data }: { data: ReviewLocationDetailsRow }) => {
        return data.id;
      }}
      {...dataGridProps}
    />
  );
};
