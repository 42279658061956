import { AssessmentAssignment } from '@circadian-risk/api-contract';
import { AssessmentAssignmentTabbedTable } from '@circadian-risk/assessment-components';
import { UserCellProps } from '@circadian-risk/data-grid';
import { HStack, InfoButtonPopover, VStack } from '@circadian-risk/presentational';
import { AssignmentGroupRow, AssignmentTeamRow } from '@circadian-risk/scheduled-assessments';
import { useOrganizationSessionStore, useOrganizationUserGroups, useOrganizationUsers } from '@circadian-risk/stores';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Card, CardContent, CardHeader, IconButton, Tooltip, Typography } from '@mui/material';
import keyBy from 'lodash/keyBy';

export const ASSIGNMENT_CARD_EDIT_BUTTON_ARIA_LABEL = 'assignment-card-edit-button';
export const ASSIGNMENT_CARD_NO_ASSIGNMENTS_MESSAGE = 'There are no assignments to this assessment';

export type UserRow = {
  id: string;
  email: string;
  role: string | null;
} & UserCellProps;

export interface AssessmentUsersProps {
  assignment: AssessmentAssignment;
  onEditClick?: () => void;
  /**
   * Assessment locations relevant to get the teams
   */
  relevantLocationIds: string[];
}

const popoverHelperText = `The users listed here are those that have been specifically added to this assessment, 
and does not include users that have provisioned access to this location and its assessments.`;

export const AssessmentAssignmentCard: React.FC<AssessmentUsersProps> = ({
  onEditClick,
  assignment,
  relevantLocationIds,
}) => {
  const assignedUsersById = keyBy(assignment.user, u => u.id);
  const assignedGroupById = keyBy(assignment.userGroup, u => u.id);
  const assignedTeamById = keyBy(assignment.teams, u => u.id);

  const users = useOrganizationUsers()
    .filter(ou => !!assignedUsersById[ou.id])
    .map(u => {
      return {
        id: u.id,
        displayName: u.displayName,
        email: u.email,
        role: u.role,
        avatar: u.avatar?.filepath,
        assignmentType: assignedUsersById[u.id].type,
      };
    });

  const groups = useOrganizationUserGroups()
    .filter(g => !!assignedGroupById[g.id])
    .map<AssignmentGroupRow>(group => {
      return {
        id: String(group.id),
        name: group.name,
        assignmentType: assignedGroupById[group.id].type,
        users: group.users.map(u => ({
          id: u.id,
          displayName: u.displayName,
          email: u.email,
          role: u.role,
          avatar: u.avatar?.filepath,
        })),
      };
    });

  const teams = useOrganizationSessionStore(state => {
    return state
      .getRelevantLocationTeams(...relevantLocationIds)
      .filter(t => !!assignedTeamById[t.id])
      .map<AssignmentTeamRow>(team => {
        return {
          id: String(team.id),
          name: team.name,
          assignmentType: assignedTeamById[team.id].type,
          users: team.users.map(u => ({
            id: u.id,
            displayName: u.displayName,
            email: u.email,
            role: u.role,
            avatar: u.avatar?.filepath,
          })),
        };
      });
  });

  const isAssignedEmpty = users.length === 0 && teams.length === 0 && groups.length === 0;

  return (
    <Card>
      <CardHeader
        title={
          <VStack>
            <HStack>
              <Typography variant="h5">Assigned & Subscribed users</Typography>
              <Box flex={1}>
                <InfoButtonPopover text={popoverHelperText} />
              </Box>
              {onEditClick && (
                <Box>
                  <Tooltip title={'Edit users'}>
                    <IconButton
                      role={'button'}
                      aria-label={ASSIGNMENT_CARD_EDIT_BUTTON_ARIA_LABEL}
                      onClick={onEditClick}
                      size="large"
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </HStack>
            <Typography color="secondary" variant="caption">
              Assigned users are subscribed by default. Subscribers will only receive notification on assessment
              activity but are not responsible for completing assessments.
            </Typography>
          </VStack>
        }
      />
      {!isAssignedEmpty ? (
        <AssessmentAssignmentTabbedTable
          users={users}
          groups={groups}
          teams={teams}
          assignment={assignment}
          gridAutoHeight
        />
      ) : (
        <CardContent>
          <Typography>{ASSIGNMENT_CARD_NO_ASSIGNMENTS_MESSAGE}</Typography>
        </CardContent>
      )}
    </Card>
  );
};
