import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { AiFillFileExcel as FileExcelIcon } from 'react-icons/ai';

type ExcelExportIconProps = {
  onClick: () => void;
};

export const ExcelExportIcon: React.FC<ExcelExportIconProps> = ({ onClick }) => {
  return (
    <Tooltip title={'Export to Excel file'}>
      <IconButton onClick={onClick} aria-label={'excel export button'} size="large">
        <FileExcelIcon />
      </IconButton>
    </Tooltip>
  );
};
