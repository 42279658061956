import { Button, IconButton, TooltipProps, useMediaQuery } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import React, { useCallback } from 'react';

import { ButtonTooltip } from '../../Tooltips';

interface ResponsiveIconButtonProps {
  text: string;
  icon: React.ReactNode;
  iconPosition: 'start' | 'end';
  color?: 'primary' | 'secondary';
  onClick?: () => void;
  variant?: ButtonProps['variant'];
  tooltip?: TooltipProps['title'];
  disabled?: boolean;
  ariaLabel?: string;
}

export const ResponsiveIconButton: React.FC<ResponsiveIconButtonProps> = ({
  text,
  icon,
  iconPosition,
  color,
  onClick,
  tooltip,
  disabled,
  variant,
  ariaLabel,
}) => {
  const theme = useTheme();
  const isSmallViewPort = useMediaQuery(theme.breakpoints.down('md'));
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  if (isSmallViewPort) {
    return (
      <ButtonTooltip title={tooltip ?? text}>
        <IconButton
          aria-label={ariaLabel}
          sx={theme => ({
            '&.MuiIconButton-root': {
              transition: theme.transitions.create('color', {
                duration: theme.transitions.duration.shorter,
              }),
            },
            '&.MuiIconButton-colorPrimary': {
              color: theme.palette.info.main,
            },
            '&.MuiIconButton-colorSecondary': {
              color: theme.palette.secondary.main,
            },
          })}
          color={color}
          onClick={handleClick}
          disabled={disabled}
          size="large"
        >
          {icon}
        </IconButton>
      </ButtonTooltip>
    );
  }

  return (
    <ButtonTooltip title={tooltip}>
      <Button
        variant={variant ?? 'outlined'}
        sx={theme => ({
          '&.MuiButton-root': {
            transition: theme.transitions.create('color', {
              duration: theme.transitions.duration.shorter,
            }),
          },
          '&.Mui-focusVisible': {
            color: theme.palette.info.main,
            borderColor: theme.palette.info.main,
          },
          '&.MuiButton-textPrimary': {
            color: theme.palette.info.main,
            borderColor: theme.palette.info.main,
          },
          '&.MuiButton-textSecondary': {
            color: theme.palette.text.secondary,
            borderColor: theme.palette.text.secondary,
          },
          '&.MuiButton-outlinedPrimary': {
            color: theme.palette.info.main,
            borderColor: theme.palette.info.main,
          },
          '&.MuiButton-outlinedSecondary': {
            color: theme.palette.text.secondary,
            borderColor: theme.palette.text.secondary,
          },
        })}
        startIcon={iconPosition === 'start' ? icon : undefined}
        endIcon={iconPosition === 'end' ? icon : undefined}
        color={color}
        onClick={handleClick}
        disabled={disabled}
        aria-label={ariaLabel}
      >
        {text}
      </Button>
    </ButtonTooltip>
  );
};
