import { CircadianRoute, ROUTES } from '@circadian-risk/routes';
import { lazyLoadWithVersionCheck } from '@web-app/router/lazyLoadWithVersionCheck';

import { SsoCallback } from './SsoCallback';
import { SSORoute } from './SSORoute';
import { SwitchOrganizationPage } from './SwitchOrganization/SwitchOrganizationPage';

const OrganizationUserProfilePage = lazyLoadWithVersionCheck(() => import('./UserProfile/OrganizationUserProfilePage'));
const UserPage = lazyLoadWithVersionCheck(() => import('./UserProfile/UserPage'));
const SignUpPageLazy = lazyLoadWithVersionCheck(() => import('./SignUpRoute'));
const OrganizationCreationPageLazy = lazyLoadWithVersionCheck(() => import('./CreateOrganizationRoute'));
const SignInPageLazy = lazyLoadWithVersionCheck(() => import('./SignInRoute'));
const ResetPasswordLazy = lazyLoadWithVersionCheck(() => import('./ResetPassword/ResetPasswordRoute'));
const ForgotPasswordPageLazy = lazyLoadWithVersionCheck(() => import('./ForgotPassword/ForgotPasswordRoute'));

const authRoutes = (): CircadianRoute[] => [
  { path: '/sso-callback', component: SsoCallback, isExact: true, isPublic: true },
  { path: ROUTES.PROFILE, component: UserPage, isExact: true, pageTitle: 'Profile' },
  {
    path: ROUTES.ORGANIZATION_USER_PROFILE,
    component: OrganizationUserProfilePage,
    isExact: true,
    pageTitle: 'User Profile',
  },
  {
    path: ROUTES.ORGANIZATION_SWITCH,
    component: SwitchOrganizationPage,
    isExact: true,
    pageTitle: 'Switch Organizations',
  },
  { path: ROUTES.SIGN_UP, component: SignUpPageLazy, isPublic: true, isExact: false, pageTitle: 'Sign Up' },
  {
    path: ROUTES.CREATE_ORG,
    component: OrganizationCreationPageLazy,
    isPublic: false,
    isExact: false,
    pageTitle: 'Create an Organization',
  },
  {
    path: ROUTES.SIGN_IN,
    component: SignInPageLazy,
    isPublic: true,
    pageTitle: 'Sign In',
  },
  {
    path: ROUTES.RESET_PASSWORD,
    component: ResetPasswordLazy,
    isPublic: true,
    pageTitle: 'Reset Password',
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    component: ForgotPasswordPageLazy,
    isPublic: true,
    pageTitle: 'Forgot Password',
  },
  {
    path: ROUTES.SSO,
    component: SSORoute,
    isPublic: true,
    pageTitle: 'Validating Session',
  },
];

export default authRoutes;
