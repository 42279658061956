import { CellRendererParams } from '@circadian-risk/data-grid';
import { HStack, ItemWithLink, LightboxGalleryImage } from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { useOrganizationId } from '@circadian-risk/stores';
import SideDrawerIcon from '@mui/icons-material/ChromeReaderMode';
import { Button } from '@mui/material';
import { useSubmitImageMarkup } from '@web-app/components/LightBox';
import { generatePath } from 'react-router-dom';

// Before changing this make sure the change is reflect at the App.css
// className: .ag-row-hover #view-button
const VIEW_BUTTON_ID = 'view-button';

export const ItemLinkCellRenderer: React.FC<
  CellRendererParams<
    {
      item_name: string;
      itemCategoryName: string;
      item_id: string;
      disableItemLink?: boolean;
      itemCategoryIconSrc?: string;
      photos?: LightboxGalleryImage[];
    },
    {
      refetch?: () => void;
      /**
       * If provided will render a view button
       */
      onViewClick?: (itemId: string) => void;
    }
  >
> = ({ data, context }) => {
  const { item_name, itemCategoryIconSrc, disableItemLink, item_id: id, photos, itemCategoryName } = data;
  const { onViewClick, refetch } = context;
  const organizationId = useOrganizationId();
  const submitImageMarkup = useSubmitImageMarkup();

  return (
    <HStack>
      <ItemWithLink
        itemCategoryName={itemCategoryName}
        name={item_name}
        photos={photos}
        link={disableItemLink ? undefined : generatePath(ROUTES.ITEMS_DETAIL, { organizationId, id })}
        iconSrc={itemCategoryIconSrc}
        onImageProcessing={async options => {
          await submitImageMarkup(options);
          refetch && refetch();
        }}
      />
      {onViewClick && (
        <Button
          id={VIEW_BUTTON_ID}
          sx={theme => ({
            position: 'absolute',
            right: 0,
            display: 'none',
            background: theme.palette.background.paper,
            color: theme.palette.primary.main,
            ':hover': {
              border: 'none',
              background: theme.palette.background.paper,
              boxShadow: theme.shadows['2'],
            },
          })}
          onClick={() => onViewClick(data.item_id)}
          variant="outlined"
          size="small"
          startIcon={<SideDrawerIcon fontSize="small" />}
        >
          View
        </Button>
      )}
    </HStack>
  );
};
