import { AssessmentAssignment } from '@circadian-risk/api-contract';

import { SelectionChangeParams } from '../../AssessmentAssignmentTabbedTable';

export const getUpdatedAssignment = (
  currentAssignment: AssessmentAssignment,
  { id, type, tab }: SelectionChangeParams,
) => {
  const updated = [...currentAssignment[tab]];
  // There's a mix of serial ids and uuid therefore casting to string will make it easier to compare
  const index = updated.findIndex(u => String(u.id) === String(id));
  if (index !== -1) {
    // If type comes undefined then it means that the user want's to remove the user
    if (type === undefined) {
      updated.splice(index, 1);
    } else {
      updated[index].type = type;
    }
  } else if (type) {
    if (tab === 'user') {
      updated.push({ id, type });
    } else {
      // Remove the string cast and make sure the id is a number for both team and group
      updated.push({ id: Number(id), type });
    }
  }

  return {
    ...currentAssignment,
    [tab]: updated,
  };
};
