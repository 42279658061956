import { Stack, StackProps } from '@mui/material';
import React from 'react';

export interface FormVStackProps {
  stackProps?: StackProps;
  children: React.ReactNode[];
}

export const FormVStack: React.FC<FormVStackProps> = ({ stackProps, children }) => {
  return (
    <Stack gap={2} direction="column" {...stackProps}>
      {children}
    </Stack>
  );
};
