import { Box, SxProps } from '@mui/material';
import React from 'react';

interface StackableProps {
  children?: React.ReactNode[];
  sx?: SxProps;
}

export const Stackable: React.FC<StackableProps> = ({ sx, children }) => {
  if (!children || !children.length) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        overflow: 'hidden',
        transition: theme => theme.transitions.create('all', { duration: theme.transitions.duration.short }),
        ...sx,
      }}
    >
      {children?.map((child, id) => (
        <Box
          key={id}
          sx={{
            minWidth: 0,
            ':not(:first-of-type)': {
              ml: 0.5,
            },
            ':last-child': {
              minWidth: 'max-content',
            },
          }}
        >
          <Box minWidth="min-content">{child}</Box>
        </Box>
      ))}
    </Box>
  );
};
