import { ChevronControls } from '@circadian-risk/presentational';
import { Button, IconButton } from '@mui/material';

interface LessOrMoreProps {
  compact: boolean;
  open: boolean;
  toggle: () => void;
}

export const LessOrMore: React.FC<LessOrMoreProps> = ({ compact, open, toggle }) => {
  return compact ? (
    <IconButton onClick={toggle} size="small">
      <ChevronControls variant="bottomDrawer" expanded={open} />
    </IconButton>
  ) : (
    <Button onClick={toggle} endIcon={<ChevronControls variant="bottomDrawer" expanded={open} />}>
      {open ? 'Less' : 'More'}
    </Button>
  );
};
