import { ReviewLocationDetailsRow } from '@circadian-risk/scheduled-assessments';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { FormLocationSelector } from '../../components/FormLocationSelector';
import { useAssessmentFormStore } from './AssessmentFormStore';
import { ScenarioFormData } from './ScenarioAssessmentForm.types';

export const LocationsStep = () => {
  const relevantNodes = useAssessmentFormStore(state => state.relevantNodes);
  const {
    watch,
    control,
    formState: { errors },
  } = useFormContext<ScenarioFormData>();
  const rootLocationId = watch('rootLocationId');

  // @ts-ignore Typing is incorrect for array form state errors
  const errorMessage = errors.locationIds?.message;
  return (
    <Controller
      name="locationIds"
      control={control}
      render={({ field: { value, onChange } }) => {
        const handleSelect = (locations: ReviewLocationDetailsRow[]) => {
          onChange(locations.map(l => l.id));
        };
        return (
          <Box>
            <FormLocationSelector data={relevantNodes} selectedLocations={value} onSelect={handleSelect} />
            {errorMessage && (
              <Box p={1}>
                <Typography variant="body2" color="error">
                  {errorMessage}
                </Typography>
              </Box>
            )}
          </Box>
        );
      }}
      rules={{
        validate: (locationIds: string[]) => {
          if (rootLocationId && !locationIds.includes(rootLocationId)) {
            return 'The top-level location must be apart of the assessment.';
          }
          return locationIds.length > 0;
        },
      }}
    />
  );
};
