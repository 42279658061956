import { Box, BoxProps, Tab, TabProps, Tabs, TabsProps, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import isNil from 'lodash/isNil';
import React from 'react';

import { TabObject } from './TabsWithContent.types';

const nonResponsiveTabStyle: React.CSSProperties = { minWidth: 'auto' };
export interface TabsWithContentControlledProps<Tab extends string> {
  tabs: TabObject<Tab>[];
  activeTab: Tab;
  tabsContainerProps?: Partial<Omit<TabsProps, 'onChange' | 'value' | 'centered' | 'variant'>>;
  tabProps?: Partial<Omit<TabProps, 'label' | 'value'>>;
  contentProps?: Omit<BoxProps, 'role' | 'hidden' | 'aria-labelledby'>;

  /**
   * @default true
   */
  responsive?: boolean;
  variant?: TabsProps['variant'];
  onChange?: (value: Tab) => void;
  centered?: TabsProps['centered'];
}

export const TabsWithContentControlled = <Tab extends string>({
  tabs,
  tabProps,
  tabsContainerProps,
  contentProps,
  activeTab,
  responsive = true,
  onChange,
  variant: variantOverride,
  centered: centeredOverride,
}: TabsWithContentControlledProps<Tab>) => {
  const theme = useTheme();

  const handleSwitchTabs = (_event: unknown, newActiveTab: Tab) => {
    onChange && onChange(newActiveTab);
  };

  const isLargeDevice = useMediaQuery(theme.breakpoints.up('md'));
  const responsiveVariant = isLargeDevice && responsive ? 'standard' : 'fullWidth';
  const variant = variantOverride || responsiveVariant;
  const centered = !isNil(centeredOverride) ? centeredOverride : !responsive || !isLargeDevice;

  return (
    <>
      <Tabs
        variant={variant}
        centered={centered}
        value={activeTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleSwitchTabs}
        aria-label="navigation tabs"
        aria-controls="tab-panel"
        {...tabsContainerProps}
      >
        {tabs.map(tab => (
          <Tab
            {...tabProps}
            key={tab.value}
            disableTouchRipple
            id={`tab-${tab.value}`}
            label={tab.customLabel ?? tab.value}
            value={tab.value}
            style={responsive ? undefined : nonResponsiveTabStyle}
            wrapped
            disabled={tab.disabled}
          />
        ))}
      </Tabs>

      <Box id="tab-panel" width="100%" height="100%">
        {tabs.map(tab => (
          <Box
            height="100%"
            width="100%"
            key={tab.value}
            role="tabpanel"
            hidden={activeTab !== tab.value}
            aria-labelledby={`tab-${tab.value}`}
            {...contentProps}
          >
            {activeTab === tab.value && tab.content}
          </Box>
        ))}
      </Box>
    </>
  );
};
