import FilterListIcon from '@mui/icons-material/FilterList';
import { Badge, IconButton, IconButtonProps, Tooltip } from '@mui/material';
import React, { useMemo } from 'react';
import { FaStarOfLife } from 'react-icons/fa';

export interface FilterButtonProps {
  count: number | null;
  onClick: IconButtonProps['onClick'];
  outdated?: boolean;
}

export const FilterButton = React.forwardRef(
  ({ count, onClick, outdated }: FilterButtonProps, ref: IconButtonProps['ref']) => {
    const tooltip = useMemo(() => {
      if (!count) {
        return 'No filters applied';
      }
      if (outdated) {
        return `Applied filters are outdated, click to re-apply`;
      }
      return `${count} filters applied`;
    }, [count, outdated]);

    return (
      <Tooltip title={tooltip}>
        <IconButton color={count ? 'primary' : 'default'} onClick={onClick} ref={ref} size="medium">
          <Badge
            variant="standard"
            color={outdated ? 'warning' : 'primary'}
            badgeContent={outdated ? <FaStarOfLife color="white" /> : count}
          >
            <FilterListIcon />
          </Badge>
        </IconButton>
      </Tooltip>
    );
  },
);
