import { formatBytes } from '@circadian-risk/shared';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, LinearProgress, Typography } from '@mui/material';
import round from 'lodash/round';
import React from 'react';

import { FileAttachmentStatus } from '../FileAttachments';
import { UploadingFile } from './FileUploadDropzone';

export const FileUploadModalListItem: React.FC<UploadingFile> = ({
  filename,
  fileSize,
  onRemove,
  uploadProgress,
  status,
}) => {
  let statusText;
  switch (status) {
    case FileAttachmentStatus.Available:
      statusText = 'Complete';
      break;
    case FileAttachmentStatus.UploadingFailed:
      statusText = 'Upload Failed';
      break;
    default:
      statusText = `${round(uploadProgress)}% done`;
      break;
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="flex-end" py={1}>
        <Box mr={3}>
          <Typography variant="body2">{filename}</Typography>
        </Box>
        <Box flex={1} justifyContent="flex-start" alignItems="center">
          <Typography variant="body2" color="textSecondary">
            {formatBytes(fileSize)}
          </Typography>
        </Box>
        {onRemove && (
          <Box>
            <IconButton onClick={onRemove} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      <LinearProgress
        variant="determinate"
        value={status === FileAttachmentStatus.Available ? 100 : uploadProgress}
        color="secondary"
      />
      <Box py={1}>
        <Typography variant="body2" color="textSecondary">
          {statusText}
        </Typography>
      </Box>
    </Box>
  );
};
