import './quill.circadian.css';

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import ReactQuill from 'react-quill';

import { PropertyFieldProps } from '../types';

const PREFIX = 'PropertyRichTextField';

const classes = {
  darkMode: `${PREFIX}-darkMode`,
};

const StyledBox = styled(Box, {
  shouldForwardProp: propName => !['hasErrors', 'disabled'].includes(propName.toString()),
})<{ disabled?: boolean; hasErrors: boolean }>(({ theme, hasErrors, disabled }) => ({
  // quill doesn't seem to have a dark mode theme so we need to override the colors manually
  [`& .${classes.darkMode}`]: {
    '& *': {
      color: theme.palette.text.primary + ' !important',
      backgroundColor: theme.palette.background.paper + ' !important',
    },
    '& .ql-stroke': {
      stroke: theme.palette.text.primary,
    },
    '& .ql-editor.ql-blank::before': {
      color: theme.palette.text.secondary,
    },
  },
  ...(hasErrors
    ? {
        '& .ql-toolbar, & .ql-container': {
          borderColor: theme.palette.error.main,
        },
      }
    : {}),
  ...(disabled ? { opacity: 0.72 } : {}),
}));

export type PropertyRichTextFieldProps = PropertyFieldProps & {
  quillRef?: React.LegacyRef<ReactQuill>;
  placeholder?: string;
};

const formats = [
  'bold',
  'font',
  'code',
  'italic',
  'link',
  'size',
  'strike',
  'underline',
  'blockquote',
  'header',
  'indent',
  'list',
  'align',
  'direction',
];

export const PropertyRichTextField: React.FC<PropertyRichTextFieldProps> = ({
  label,
  htmlName,
  control,
  disabled,
  readOnlyInput,
  rules,
  boxProps,
  errorMessage,
  endAdornment,
  placeholder,
  onBlur: blurCallback,
  quillRef,
}) => {
  const modules = useMemo(() => {
    return disabled
      ? { toolbar: false }
      : {
          clipboard: {
            matchVisual: false,
          },
        };
  }, [disabled]);

  const disabledSx = disabled ? { opacity: 0.72 } : undefined;
  return (
    <StyledBox {...boxProps} hasErrors={!!errorMessage} disabled={disabled || readOnlyInput}>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" my={0.5} mx={1.75}>
        <Typography variant="body1" sx={disabledSx}>
          {label}
        </Typography>
        {endAdornment}
      </Box>
      <Controller
        control={control}
        name={htmlName}
        defaultValue={null}
        rules={rules}
        render={fieldProps => {
          const { onChange, value, onBlur } = fieldProps.field;
          const handleBlur = () => {
            onBlur();
            if (blurCallback) {
              blurCallback(value);
            }
          };

          return (
            <ReactQuill
              ref={quillRef}
              theme={undefined}
              value={value ?? null}
              placeholder={placeholder}
              /**
               * Whitelist of supported formats excluding video, image, color, background color, script and code-block
               *
               * @see https://quilljs.com/docs/formats/
               */
              formats={formats}
              onChange={(content, _delta, source) => {
                // Ignores api changes because internally it can create updates with extra empty
                // spaces which resolves in having JT-2069
                if (source === 'api') {
                  return;
                }
                // Checks if we only have an empty space (which in HTML is an empty paragraph)
                // more context at: https://github.com/quilljs/quill/issues/1328
                if (content === '<p><br></p>') {
                  return onChange('');
                }
                onChange(content);
              }}
              modules={modules}
              onBlur={handleBlur}
              readOnly={disabled || readOnlyInput}
            />
          );
        }}
      />
      {errorMessage && (
        <Box mt={1}>
          <Typography variant="body2" color="error">
            {errorMessage}
          </Typography>
        </Box>
      )}
    </StyledBox>
  );
};
