import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import CloseIcon from '@mui/icons-material/Close';
import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import dayjsLocalizedFormat from 'dayjs/plugin/localizedFormat';
import React from 'react';

import { PreviewReportContent } from './PreviewReportContent';

dayjs.extend(dayjsLocalizedFormat);

interface PreviewReportProps {
  open: boolean;
  onClose: () => void;
  fileUrl: string | null;
  reportHeader?: string;
  startedAt?: string | null;
  completedAt?: string | null;
  status?: string;
  onRegenerateReport: () => void;
}

export const PreviewReport: React.FC<PreviewReportProps> = ({
  open,
  onClose,
  fileUrl,
  status,
  startedAt,
  completedAt,
  onRegenerateReport,
}) => {
  const theme = useTheme();
  const handleClose = (_event: unknown, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  const loading = status === 'pending' || status === 'started';

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      maxWidth={fileUrl ? 'lg' : 'sm'}
      fullWidth={fileUrl === null ? false : status === 'completed'}
      keepMounted={false}
    >
      <>
        <DialogTitle sx={theme => ({ marginRight: theme.spacing(6) })}>
          {loading ? 'Generating Report' : 'Report Preview'}
        </DialogTitle>
        <DialogContent>
          <PreviewReportContent
            status={status}
            startedAt={startedAt}
            completedAt={completedAt}
            fileUrl={fileUrl}
            onRegenerateReport={onRegenerateReport}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
        <Box position="absolute" top={theme.spacing(1)} right={theme.spacing(1)}>
          <IconButton onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </Box>
      </>
    </Dialog>
  );
};
