import { Theme, useTheme } from '@mui/material';
import isNumber from 'lodash/isNumber';

type Bracket = 'low' | 'medium' | 'high' | null;

/**
 * Function to determine risk color given the thresholds
 */
export const calculateRiskLabelBracket = (value: number, upperThreshold?: number, lowerThreshold?: number): Bracket => {
  if (upperThreshold != null && lowerThreshold != null) {
    return value >= upperThreshold ? 'high' : value < lowerThreshold ? 'low' : 'medium';
  } else {
    return null;
  }
};

const getRailBackground = ({
  lowerThresholdPercent,
  upperThresholdPercent,
  palette,
}: {
  lowerThresholdPercent?: number;
  upperThresholdPercent?: number;
  palette: Theme['palette'];
}) => {
  if (lowerThresholdPercent === undefined || upperThresholdPercent === undefined) {
    return palette.grey[500];
  }

  const leftColor = palette.organizationColors.risk.low;
  const middleColor = palette.organizationColors.risk.medium;
  const rightColor = palette.organizationColors.risk.high;
  return `linear-gradient(90deg,
        ${leftColor} 0%, 
        ${leftColor} ${lowerThresholdPercent}%, 
        ${middleColor} ${lowerThresholdPercent}%, 
        ${middleColor} ${upperThresholdPercent}%,
        ${rightColor} ${upperThresholdPercent}%)`;
};

export const useThumbColor = (
  value: number | number[] | undefined,
  upperThresholdPercent?: number,
  lowerThresholdPercent?: number,
) => {
  const theme = useTheme();
  const { palette } = theme;

  let thumbBgColor;
  if (value) {
    const valueForColor = isNumber(value) ? value : value[0];
    const bracket = calculateRiskLabelBracket(valueForColor, upperThresholdPercent, lowerThresholdPercent);
    thumbBgColor = bracket ? palette.organizationColors.risk[bracket] : palette.grey[500];
  } else {
    thumbBgColor = palette.grey[500];
  }

  return {
    thumbBgColor,
    railBgColor: getRailBackground({ lowerThresholdPercent, upperThresholdPercent, palette }),
  };
};
