export const ICON_LIMIT = 5;

export interface BaseAvatar {
  name: string;
  image?: string | null;
  lastSignedInAt?: string | Date | null;
}
export interface BaseAvatarGroupProps {
  max?: number;
  isStacked?: boolean;
  size?: number;
}

export const useVisibleAvatars = <T extends BaseAvatar>(avatars: T[], max?: number) => {
  const limit = (max ?? ICON_LIMIT) - 1;
  let visibleIcons = avatars.slice(0, limit).reverse();
  let extras = avatars.slice(limit);

  if (extras.length === 1) {
    visibleIcons = avatars.reverse();
    extras = [];
  }

  return {
    visibleIcons,
    extras,
  };
};
