import { ReturnTo, ReturnToProps } from '@circadian-risk/presentational';
import { ROUTES_LOCAL } from '@circadian-risk/routes';
import isFunction from 'lodash/isFunction';

const TO = {
  pathname: ROUTES_LOCAL.SIGN_IN,
};

interface ReturnToSignInProps extends Pick<ReturnToProps, 'beforePush'> {
  /**
   * Optional email state to push to the Sign In route
   */
  email?: string | (() => string);
  /**
   * Optional label override
   *
   * Defaults to "Return to sign-in"
   */
  label?: string;
}

export const RETURN_TO_SIGN_IN_LABEL = `Return to sign-in`;

/**
 * Convenience link for consistent "Return to sign-in" experience
 */
export const ReturnToSignIn: React.FC<ReturnToSignInProps> = ({ email, label: labelOverride, beforePush }) => {
  const to = () => ({
    ...TO,
    state: {
      email: isFunction(email) ? email() : email,
    },
  });

  const label = labelOverride ?? RETURN_TO_SIGN_IN_LABEL;

  return <ReturnTo {...{ label, to, beforePush }} />;
};
