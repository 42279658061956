import { useCompleteFragment } from '@circadian-risk/apollo-utils';
import { TypedNodes } from '@circadian-risk/client-graphql-hooks';

export const useAssessmentDetailsData = (assessmentId: number) => {
  return useCompleteFragment({
    fragment: TypedNodes.AssessmentForDetailsFragmentDoc,
    key: { id: assessmentId },
    strict: true,
  });
};
