import { Typography, TypographyProps } from '@mui/material';
import React from 'react';

export interface FormTitleProps extends Omit<TypographyProps<'h2'>, 'children'> {
  children: string | React.ReactNode;
}

export const FormTitle: React.FC<FormTitleProps> = ({ children, ...typographyProps }) => {
  return (
    <Typography variant="h4" component="h2" align="center" {...typographyProps}>
      {children}
    </Typography>
  );
};
