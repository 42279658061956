import { Enum_Organization_File_Entity_Enum } from '@circadian-risk/client-graphql-hooks';
import { useQueryState } from '@circadian-risk/front-end-utils';
import { CancelTokenSource } from 'axios';
import { useEffect, useRef } from 'react';

export type UploadEndpointInfo = {
  organizationId: string;
  endpoint: string;
  entityId: string;
  entityType: Enum_Organization_File_Entity_Enum;
  /**
   * TODO(backlog)[CR-5756]
   * @deprecated
   */
  path: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metadata: Record<string, any> | null;
};

export type FileEntity = {
  id: string;

  /**
   * Original file name
   */
  name: string;

  /**
   * Provided by the browser's File object
   */
  size: number;

  /**
   * Provided by the browser's File object
   */
  type: string;

  /**
   * Timestamp
   */
  insertedAt: number;

  status: UploadStatus | null;

  uploadEndpointInfo: UploadEndpointInfo;

  _deleted?: boolean;
};

/**
 * NA - When the file entity does not need to be uploaded e.g. files that are added to the store but have already been uploaded
 * Uploading
 * Pending
 * Finished
 * Failed
 */
export enum UploadStatus {
  NA = 'NA',
  Uploading = 'Uploading',
  Pending = 'Pending',
  Completed = 'Completed',
  Failed = 'Failed',
}

export interface IFileItem
  extends Pick<FileEntity, 'id' | 'name' | 'size' | 'type' | 'status' | 'insertedAt' | 'uploadEndpointInfo'> {
  uploadProgressInfo?: {
    speed: number;
    percentage: number;
    loaded: number;
    total: number;
  };
  cancelToken?: CancelTokenSource;
  url?: string;
}

export type SubscribeHandler = (fileEntities: IFileItem[]) => void;

export interface FileManagerDatabase {
  subscribe: (handler: SubscribeHandler) => void;
  get: () => Promise<IFileItem[]>;
  insert: (file: File, uploadEndpointInfo: UploadEndpointInfo) => Promise<void>;
  remove: (id: string) => Promise<boolean>;
  update: (id: string, input: Partial<IFileItem>) => Promise<void>;
  getBlob: (id: string) => Promise<Blob | null>;
  unsubscribe: (() => void) | (() => Promise<void>);
  removeAll(): Promise<boolean>;
}

export const LinkEntityUrlParamKey = 'linkedFileEntity';

export const useMatchedLinkEntityEffect = (matches: boolean, callback: () => void) => {
  // eslint-disable-next-line unused-imports/no-unused-imports
  const [_, setLinkedFileEntity] = useQueryState(LinkEntityUrlParamKey, null);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (matches) {
      setTimeout(callback, 650);

      timer.current = setTimeout(() => {
        setLinkedFileEntity(null);
      }, 5000);
    } else if (timer.current) {
      clearTimeout(timer.current);
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  });
};
