import { Box } from '@mui/material';
import React from 'react';

import { IconWrapper } from '../../Icons';
import { FilterIconType } from '../filter.types';

export interface FilterIconProps {
  icon: FilterIconType;
}

export const FilterIcon: React.FC<FilterIconProps> = ({ icon }) => {
  if (React.isValidElement(icon)) {
    return (
      <Box
        sx={{
          color: theme => theme.palette.text.secondary,
          display: 'flex',
          fontSize: 'small !important',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        {icon}
      </Box>
    );
  } else {
    return <IconWrapper icon={icon} fontSize="small" sx={{ color: theme => theme.palette.text.secondary }} />;
  }
};
