import { CIRCADIAN_RISK_LIBRARY_ORG_ID } from '@circadian-risk/data-utils';
import { HStack, ScenarioCatalogIcon, SelectableCard } from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';

export const CircadianRiskLibraryBanner: React.FC = () => {
  const { push } = useHistory();
  return (
    <Box role="banner">
      <SelectableCard
        active={false}
        onClick={() => {
          push(
            generatePath(ROUTES.SCENARIOS, {
              organizationId: CIRCADIAN_RISK_LIBRARY_ORG_ID,
            }),
          );
        }}
        sx={{
          width: '100%',
        }}
        cardProps={{
          sx: {
            padding: 1,
            width: '100%',
          },
        }}
      >
        <HStack pr={1}>
          <HStack flex={0}>
            <ScenarioCatalogIcon sx={{ width: '35px', height: '35px' }} color="primary" />
          </HStack>
          <Typography variant="body1" sx={{ flex: '0 0 auto' }}>
            Manage the Scenario Catalog
          </Typography>
        </HStack>
      </SelectableCard>
    </Box>
  );
};
