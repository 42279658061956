import { QueryHookOptions, QueryResult } from '@apollo/client';
import { useNotification } from '@circadian-risk/front-end-utils';
import { Exact } from '@circadian-risk/graphql-types';
import { DeepNonNullable } from '@circadian-risk/shared';
import { useEffect, useMemo } from 'react';

import { GqlPlaceholder, GqlPlaceholderProps } from '../GqlPlaceholder';

/**
 * A wrapper around an Apollo GQL query that handles error and loading states
 *
 * @param queryFunc The hook function
 * @param options Standard options object for the hook function
 * @param render A render function that will receive the data and refetch function. Make sure this is memoized
 * with useCallback for performance
 * @param handlerOptions
 * @param handlerOptions.isCard
 * @param handlerOptions.cardTitle
 * @param handlerOptions.isCallback
 */
export const useGqlQueryHandler = <Data, Variables extends { [key: string]: unknown }>(
  queryFunc: (arg: QueryHookOptions<Data, Variables>) => QueryResult<Data, Exact<Variables>>,
  options: QueryHookOptions<Data, Variables>,
  render: (result: DeepNonNullable<Pick<QueryResult<Data, Exact<Variables>>, 'data' | 'refetch'>>) => JSX.Element,
  handlerOptions?: {
    isCard?: boolean;
    cardTitle?: string;
    isCallback?: boolean;
    loadingPageProps?: GqlPlaceholderProps['loadingPageProps'];
    loadingTableProps?: GqlPlaceholderProps['loadingTableProps'];
    variant?: GqlPlaceholderProps['variant'];
  },
  additionalDeps?: unknown[],
) => {
  const { displayError } = useNotification();
  const { data, loading, error, refetch } = queryFunc(options);

  const { cardTitle, isCard, isCallback, loadingPageProps, loadingTableProps, variant } = handlerOptions ?? {};

  useEffect(() => {
    if (error) {
      displayError(error);
    }
  }, [error, displayError]);

  const rootDeps = [loading, error, data, ...(additionalDeps ?? [])];
  const memoDeps = isCallback ? [...rootDeps, render] : rootDeps;

  const content = useMemo(() => {
    if (loading || error || !data) {
      return (
        <GqlPlaceholder
          loadingPageProps={loadingPageProps}
          loadingTableProps={loadingTableProps}
          state={{ loading, error }}
          variant={isCard ? 'card' : variant || 'page'}
          title={cardTitle}
          refetch={refetch}
        />
      );
    } else {
      return render({ data: data as NonNullable<Data>, refetch });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, memoDeps);

  return {
    refetch,
    content,
  };
};

export type QueryHandlerComponentProps<T> = T extends (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  arg: QueryHookOptions<infer Data, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => QueryResult<infer Data, Exact<any>>
  ? {
      data: DeepNonNullable<Pick<QueryResult<Data>, 'data'>>['data'];
      refetch: QueryResult<Data>['refetch'];
    }
  : never;
