import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import { createZustandContext, debug, FCC } from '@circadian-risk/front-end-utils';
import isFunction from 'lodash/isFunction';
import { useLatest } from 'react-use';
import { StateCreator } from 'zustand';

import {
  AttachmentFile,
  convertAttachmentFilesToLightboxImages,
  LightboxImage,
  LightboxWithEditing,
  LightboxWithEditingProps,
} from '.';

type LightboxStoreState = {
  isOpen: boolean;
  currIndex: number;
  currId: string;
  mainSrc: string;
  nextSrc: string | undefined;
  prevSrc: string | undefined;
  getImages: () => LightboxImage[];
  onMoveNextRequest: () => void;
  onMovePrevRequest: () => void;
  onCloseRequest: () => void;
  handleOpen: (imageId: string) => void;
};

const { StoreProvider, useStore } = createZustandContext<LightboxStoreState>();

const stateCreator: (images: LightboxImage[] | (() => LightboxImage[])) => StateCreator<LightboxStoreState> =
  initialImages => (set, get) => {
    const log = debug.extend('Lightbox');

    const setNewIndex = (newIndex: number) => {
      const { getImages } = get();
      const images = getImages();

      set({
        currIndex: newIndex,
        currId: images[newIndex].id,
        mainSrc: images[newIndex].url,
        nextSrc: images[newIndex + 1]?.url,
        prevSrc: images[newIndex - 1]?.url,
      });
    };

    return {
      isOpen: false,
      currIndex: 0,
      currId: isFunction(initialImages) ? initialImages()[0]?.id : initialImages[0]?.id,
      mainSrc: '',
      prevSrc: undefined,
      nextSrc: undefined,
      getImages: () => (isFunction(initialImages) ? initialImages() : initialImages),
      onMoveNextRequest: () => setNewIndex(get().currIndex + 1),
      onMovePrevRequest: () => setNewIndex(get().currIndex - 1),
      onCloseRequest: () => set({ isOpen: false }),
      handleOpen: imageId => {
        const { getImages } = get();
        const images = getImages();
        const indexOfImage = images.findIndex(image => image.id === imageId);
        if (indexOfImage !== -1) {
          set({ isOpen: true });
          setNewIndex(indexOfImage);
        } else {
          log(`Image with id: ${imageId} not present`);
        }
      },
    };
  };

/**
 * Use this provider when you need a quick lightbox.
 * Make sure you filter files to only include valid images
 * If data is coming from a GQL query using `image` fragment, you can use `LightBoxWithConverterProvider` to avoid shaping data
 *
 * If performance is a concern, consider using `LightboxProviderWithImageGetter`
 */
export const LightBoxProvider: FCC<{ images: LightboxImage[] } & LightboxWithEditingProps> = ({
  images,
  children,
  ...lightboxProps
}) => {
  // zustand context that is initialized with a prop can sometimes get out of sync, this helps
  const imagesLatest = useLatest(images);
  const getImages = () => imagesLatest.current;
  return (
    <StoreProvider initializer={stateCreator(getImages)}>
      <LightboxWithEditing {...lightboxProps}>{children}</LightboxWithEditing>
    </StoreProvider>
  );
};

/**
 * Use this provider when you need a quick lightbox created from files fetched from our backend
 * If performance is a concern, consider using `LightboxProviderWithImageGetter`
 */
export const LightBoxWithConverterProvider: FCC<{ files: AttachmentFile[] } & LightboxWithEditingProps> = ({
  files,
  children,
  ...lightboxProps
}) => (
  <LightBoxProvider images={convertAttachmentFilesToLightboxImages(files)} {...lightboxProps}>
    {children}
  </LightBoxProvider>
);

export const LightboxProviderWithImageGetter: FCC<{ getImages: () => LightboxImage[] } & LightboxWithEditingProps> = ({
  getImages,
  children,
  ...lightboxProps
}) => {
  return (
    <StoreProvider initializer={stateCreator(getImages)}>
      <LightboxWithEditing {...lightboxProps}>{children}</LightboxWithEditing>
    </StoreProvider>
  );
};

export const useLightbox = useStore;
