import { AssessmentItemStatusEnum } from '../common-types';
import { getInspectionItemStatus } from './getInspectionItemStatus';

export const getQuestionnaireItemStatus = (options: {
  isPresent: boolean | null;
  completedAnswers: number;
  totalAnswers: number;
  deficientAnswers: number;
  isRequired?: boolean;
}) => {
  const { isPresent, completedAnswers, totalAnswers, deficientAnswers, isRequired } = options;

  if (isPresent === false && isRequired === false && deficientAnswers === 0 && completedAnswers === totalAnswers) {
    return AssessmentItemStatusEnum.NotNeeded;
  }

  return getInspectionItemStatus(options);
};
