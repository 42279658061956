import { useSupertokensSession } from '@circadian-risk/authentication';
import { imageSrc, useIsDarkMode } from '@circadian-risk/front-end-utils';
import { MenuWithTrigger, MenuWithTriggerItem, TooltipAvatar } from '@circadian-risk/presentational';
import { ROUTES } from '@circadian-risk/routes';
import { File, USER_ROLE } from '@circadian-risk/shared';
import { useOrganizationSessionStore, useUserSessionStore } from '@circadian-risk/stores';
import { Box, Typography } from '@mui/material';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import {
  IMessage,
  INovuPopoverTheme,
  INovuThemePopoverProvider,
  NotificationBell,
  PopoverNotificationCenter,
} from '@novu/notification-center';
import { useClearSessionDependencies } from '@web-app/lib/useClearSessionDependencies';
import React from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { FiLogOut } from 'react-icons/fi';
import { HiOutlineSwitchHorizontal } from 'react-icons/hi';
import { MdBusiness } from 'react-icons/md';
import { RiDashboardLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';

import { LinkWithOrgId } from '../../../NavLinkWithActiveOrgId';
import OrganizationMenu from './OrganizationMenu';

export type CurrentUser = {
  isAuthenticated: boolean;
  id: string;
  firstName: string;
  lastName: string;
  email?: string;
  avatar?: File;
  organizations?: string[];
  circadianAdmin?: boolean;
};

export interface AccountMenuProps {
  organizationRecords: { [id: string]: unknown };
  isSmallViewPort: boolean;
}

const menuButtonSx: SxProps<Theme> = theme => ({
  display: 'inline-block',
  padding: theme.spacing(1),
  width: '100%',
});

const menuTextSx: SxProps<Theme> = theme => ({
  marginLeft: theme.spacing(1.5),
});

const smallViewPortTextSx: SxProps<Theme> = theme => ({
  fontSize: theme.typography.body1.fontSize,
});

const paperProps = {
  sx: {
    width: '280px',
  },
};

const AccountMenu: React.FC<AccountMenuProps> = ({ organizationRecords, isSmallViewPort }) => {
  const { avatarFilePath, isCircadianAdmin, email, firstName, lastName } = useUserSessionStore(
    ({ email, firstName, lastName, isCircadianAdmin, avatarFilePath }) => ({
      email,
      firstName,
      lastName,
      isCircadianAdmin,
      avatarFilePath,
    }),
  );

  const theme = useTheme();

  // Changes the default `beforeBrand` color to main instead of the default
  // gradient that looks like an error. For more advanced use cases
  // we might have to provide a custom renderer and apply the custom logic inside
  const novuCommonThemeOverrides: INovuPopoverTheme = {
    notificationItem: {
      unread: {
        notificationItemBeforeBrandColor: theme.palette.info.main,
      },
    },
  };

  const customNovuTheme: INovuThemePopoverProvider = {
    light: {
      ...novuCommonThemeOverrides,
    },
    dark: {
      ...novuCommonThemeOverrides,
    },
  };

  const name = `${firstName} ${lastName}`;
  const userNameDisplay = firstName ? name : email;
  const UserAvatar = <TooltipAvatar alt={userNameDisplay} image={imageSrc(avatarFilePath)} />;
  const isDarkMode = useIsDarkMode();
  const [organizationId, userRole] = useOrganizationSessionStore(state => [state.id, state.role]);
  const { signOut } = useSupertokensSession();
  const history = useHistory();
  const clearSessionDependencies = useClearSessionDependencies();

  const baseSmallViewportSx = isSmallViewPort ? [smallViewPortTextSx] : [];

  const handleLogout = async () => {
    await signOut();
    await clearSessionDependencies();
    history.push({ pathname: ROUTES.SIGN_IN, state: { preventLastLocation: true } });
  };

  const signOutMenuItem: MenuWithTriggerItem = {
    wrapperComponent: ({ children }) => (
      <Box sx={theme => ({ borderTop: `1px solid ${theme.palette.divider}` })}>{children}</Box>
    ),
    component: (
      <Box role={'button'} aria-label={'logout'} onClick={handleLogout} sx={menuButtonSx}>
        <Box justifyContent={'center'} alignItems={'center'} display={'flex'}>
          <FiLogOut />
          <Box component="span" sx={[menuTextSx, ...baseSmallViewportSx]}>
            Sign Out
          </Box>
        </Box>
      </Box>
    ),
    shouldCloseOnClick: true,
  };

  const menuItems: MenuWithTriggerItem[] = [
    {
      component: (
        <Typography
          sx={
            isSmallViewPort
              ? {
                  paddingLeft: theme.spacing(1),
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  paddingBottom: theme.spacing(1),
                  borderBottom: `1px solid ${theme.palette.divider}`,
                  color: theme.palette.secondary.main,
                  fontSize: theme.typography.body1.fontSize,
                }
              : {
                  color: theme.palette.grey[500],
                  fontSize: theme.typography.caption.fontSize,
                  paddingLeft: theme.spacing(1),
                  textTransform: 'uppercase',
                }
          }
        >
          {userNameDisplay}
        </Typography>
      ),
      isNotClickable: true,
    },
    {
      component: (
        <LinkWithOrgId sx={menuButtonSx} href={ROUTES.PROFILE}>
          <Box justifyContent={'center'} alignItems={'center'} display={'flex'}>
            <FaUserCircle />
            <Box component="span" sx={[menuTextSx, ...baseSmallViewportSx]}>
              Profile
            </Box>
          </Box>
        </LinkWithOrgId>
      ),
      shouldCloseOnClick: true,
    },
  ];

  if (isCircadianAdmin) {
    menuItems.splice(-1, 0, {
      component: (
        <LinkWithOrgId sx={menuButtonSx} href={ROUTES.ADMIN_DASHBOARD}>
          <Box justifyContent={'center'} alignItems={'center'} display={'flex'}>
            <RiDashboardLine />
            <Box component="span" sx={[menuTextSx, ...baseSmallViewportSx]}>
              Admin Dashboard
            </Box>
          </Box>
        </LinkWithOrgId>
      ),
      shouldCloseOnClick: true,
    });
  }

  // Add custom small view port options for small devices
  if (isSmallViewPort) {
    if (organizationId && userRole === USER_ROLE.ORGANIZATION_ADMIN) {
      menuItems.push({
        component: (
          <LinkWithOrgId sx={menuButtonSx} href={ROUTES.ORGANIZATION_PROFILE}>
            <Box justifyContent={'center'} alignItems={'center'} display={'flex'}>
              <MdBusiness />
              <Box component="span" sx={[menuTextSx, ...baseSmallViewportSx]}>
                Organization Details
              </Box>
            </Box>
          </LinkWithOrgId>
        ),
        shouldCloseOnClick: true,
      });
    }

    const organizationSwitcherVisible = Object.values(organizationRecords).length > 1;
    if (organizationSwitcherVisible) {
      menuItems.push({
        component: (
          <LinkWithOrgId sx={menuButtonSx} href={ROUTES.ORGANIZATION_SWITCH}>
            <Box justifyContent={'center'} alignItems={'center'} display={'flex'}>
              <HiOutlineSwitchHorizontal />
              <Box component="span" sx={[menuTextSx, ...baseSmallViewportSx]}>
                Switch Organization
              </Box>
            </Box>
          </LinkWithOrgId>
        ),
        shouldCloseOnClick: true,
      });
    }
  }

  // Add signOut menu item in the end
  menuItems.push(signOutMenuItem);

  function onNotificationClick(message: IMessage) {
    // your logic to handle the notification click
    if (message?.cta?.data?.url) {
      window.location.href = message.cta.data.url;
    }
  }

  const notifications = (
    <PopoverNotificationCenter
      colorScheme={isDarkMode ? 'dark' : 'light'}
      theme={customNovuTheme}
      onNotificationClick={onNotificationClick}
      footer={() => <></>}
    >
      {({ unseenCount }) => <NotificationBell unseenCount={unseenCount} />}
    </PopoverNotificationCenter>
  );

  if (isSmallViewPort) {
    return (
      <Box display="flex" alignItems="center">
        <MenuWithTrigger
          items={menuItems}
          menuId="accountMenu"
          triggerButtonIcon={UserAvatar}
          PaperProps={paperProps}
          triggerAriaLabel="Toggle Account Menu"
        />
        {notifications}
      </Box>
    );
  }

  return (
    <Box display="flex" alignItems="center">
      <OrganizationMenu organizationRecords={organizationRecords} />
      <MenuWithTrigger
        items={menuItems}
        menuId="accountMenu"
        triggerButtonIcon={UserAvatar}
        triggerAriaLabel="Toggle Account Menu"
      />
      {notifications}
    </Box>
  );
};

export default AccountMenu;
