import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

import { CreateLocationFilterSchema } from '../create-location-filter.schema';
import { LocationFilterRow } from '../types';

const findChildrenRecursively = (
  allLocations: LocationFilterRow[],
  currentNode: LocationFilterRow,
): LocationFilterRow[] => {
  const children: LocationFilterRow[] = [];
  const filtered = allLocations.filter(l => l.parent_id === currentNode.id);

  // Adds the current node to the candidates list
  children.push(currentNode);

  for (const child of filtered) {
    children.push(child);
    const grandChildren = allLocations
      .filter(l => l.parent_id === child.id)
      .map(l => findChildrenRecursively(allLocations, l))
      .flat();
    children.push(...grandChildren);
  }

  return children;
};

export const findParentsRecursively = <TLocation extends { id: string; parent_id: string | null }>(
  allLocations: TLocation[],
  currentRow: TLocation,
): TLocation[] => {
  const parents: TLocation[] = [];
  const target = allLocations.find(location => location.id === currentRow.parent_id);
  if (target) {
    // Include itself as part of the parents list
    parents.push(target);

    parents.push(...findParentsRecursively(allLocations, target));
  }
  return parents;
};

export type CalculateRelevantLocationsFilterParam = Pick<
  CreateLocationFilterSchema,
  'baseLayerId' | 'belongsToId' | 'includeChildren' | 'tagIds'
>;

export const calculateRelevantLocations = (
  locations: LocationFilterRow[],
  { baseLayerId, belongsToId, includeChildren, tagIds }: CalculateRelevantLocationsFilterParam,
) => {
  const filteredLocations = locations
    .filter(location => {
      return (
        location.layer.id === baseLayerId &&
        (!belongsToId || location.parent_id === belongsToId) &&
        (tagIds.length === 0 || location.tagIds.some(tid => tagIds.includes(tid)))
      );
    })
    .flatMap(location => {
      // Include the base parents
      const parents = findParentsRecursively(locations, location);
      return [...parents, location];
    });

  if (includeChildren) {
    // Determine the max depth found in the filtered rows will help to just gather children(s) underneath it
    const maxDepth = Math.max(...filteredLocations.map(l => l.nodeDepth!));
    const lastDepthRows = filteredLocations.filter(l => l.nodeDepth === maxDepth);

    for (const row of lastDepthRows) {
      const children = findChildrenRecursively(locations, row);
      filteredLocations.push(...children);
    }
  }

  const uniqueLocations = uniqBy(filteredLocations, e => e.id);
  const sortedLocations = sortBy(uniqueLocations, e => e.nodeDepth);

  return sortedLocations;
};
