import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import { fileSchema } from './shared-schemas';

const c = initContract();

const layerToCreateSchema = z.object({
  name: z.string(),
  mapsTo: z.enum(['organization', 'building', 'campus', 'level']).optional(),
});

const createOrganizationSchema = z.object({
  name: z.string().min(3),
  requiresEula: z.boolean().nullish(),
  isActualCustomer: z.boolean().nullish(),
  layersToCreate: z.array(layerToCreateSchema).min(1),
  assessmentsPurchased: z.number().nullish(),
  additionalMeta: z
    .object({
      min_employee_count: z.number().nullish(),
      max_employee_count: z.number().nullish(),
      min_location_count: z.number().nullish(),
      max_location_count: z.number().nullish(),
      min_yearly_assessment_count: z.number().nullish(),
      max_yearly_assessment_count: z.number().nullish(),
      industry: z.string().nullish(),
    })
    .optional(),
});

const userCreateOrganizationSchema = createOrganizationSchema.omit({ layersToCreate: true }).extend({
  isRegionLayerIncluded: z.boolean().optional(),
});

const createOrganizationResponseSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export type CreateOrganizationLayer = z.infer<typeof layerToCreateSchema>;
export type LayerMapsTo = NonNullable<CreateOrganizationLayer['mapsTo']>;
export type CreateOrganization = z.infer<typeof createOrganizationSchema>;
export type CreateOrganizationResponse = z.infer<typeof createOrganizationResponseSchema>;
export type UserCreateOrganization = z.infer<typeof userCreateOrganizationSchema>;

export const organizationsContract = c.router(
  {
    createWithLayers: {
      method: 'POST',
      path: '/create-with-layers',
      description: 'Creates a new organization with specified layers',
      body: createOrganizationSchema,
      responses: {
        201: createOrganizationResponseSchema,
      },
    },
    create: {
      method: 'POST',
      path: '/',
      body: userCreateOrganizationSchema,
      responses: {
        201: createOrganizationResponseSchema,
      },
    },
    uploadLogo: {
      method: 'POST',
      path: '/:id/logo',
      description: 'Upload current Organization logo image',
      contentType: 'multipart/form-data',
      body: z.object({
        logo: z.custom<File>(),
      }),
      responses: {
        201: fileSchema,
      },
    },
  },
  {
    pathPrefix: '/organizations',
  },
);
