import { Coordinates } from '@circadian-risk/shared';

export const isSameCoordinatesFilter = ({
  coordinates1,
  coordinates2,
}: {
  coordinates1: Coordinates;
  coordinates2: Coordinates;
}): boolean => {
  return coordinates1[0] === coordinates2[0] && coordinates1[1] === coordinates2[1];
};
