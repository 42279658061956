import { convertPercentageToScore } from '@circadian-risk/data-utils';
import { FormHelperText, Slider, SliderTypeMap, useTheme } from '@mui/material';
import noop from 'lodash/noop';
import React from 'react';

import { useThumbColor } from './ScenarioThresholdsSlider.helpers';

export interface ScenarioThresholdsSliderProps {
  onChange?: (value: number[] | number) => void;
  /**
   * This should be a percentage number or number[] where 0 < number < 100.
   */
  value?: number | number[];

  maxValue: number | 'percentage';

  /**
   * This should be a percentage number where 0 < number < 100
   */
  lowerThresholdPercent?: number;

  /**
   * This should be a percentage number where 0 < number < 100
   */
  upperThresholdPercent?: number;
  disabled?: boolean;
  hideLabels?: boolean;
  lowValue?: number;
  muiSliderProps?: SliderTypeMap['props'];
  errorText?: string;
}

export const ScenarioThresholdsSlider: React.FC<ScenarioThresholdsSliderProps> = props => {
  const {
    onChange,
    upperThresholdPercent,
    lowerThresholdPercent,
    maxValue,
    hideLabels,
    lowValue = 0,
    value,
    disabled,
    muiSliderProps,
    errorText,
  } = props;

  const theme = useTheme();
  const { palette } = theme;
  const adjustedMaxValue = maxValue === 'percentage' ? 100 : maxValue;

  const { thumbBgColor, railBgColor } = useThumbColor(value, upperThresholdPercent, lowerThresholdPercent);

  return (
    <>
      <Slider
        sx={{
          // accounting for always showing the labels
          height: '8px',
          marginTop: '32px',
          width: '100%',

          // sub-component specific styles
          '.MuiSlider-thumb': {
            '.MuiSlider-valueLabel': {
              backgroundColor: palette.organizationColors.risk.low,
            },
            ':last-of-type': {
              color: palette.organizationColors.risk.high,
              '.MuiSlider-valueLabel': {
                backgroundColor: palette.organizationColors.risk.high,
              },
            },
          },
          // hiding the slider handles and shadow if non-interactive
          '.MuiSlider-thumb.Mui-disabled': {
            background: 'none',
            ':before': {
              display: 'none',
            },
            // when disabled, move the label down and make it look disabled
            '.MuiSlider-valueLabelOpen': {
              backgroundColor: thumbBgColor,
              opacity: `0.7 !important`,
              transform: `translateY(-20px) !important`,
            },
          },
        }}
        slotProps={{
          // hiding the track, since we have a custom gradient over it
          track: {
            style: {
              display: 'none',
            },
          },
          // showing custom gradient for the rail with the thresholds
          rail: {
            style: {
              opacity: disabled ? 0.7 : 1,
              background: railBgColor,
            },
          },
        }}
        disabled={disabled || adjustedMaxValue === 0}
        value={value}
        onChange={onChange ? (_, v) => onChange(v) : noop}
        valueLabelFormat={v => {
          const converted = convertPercentageToScore(v, adjustedMaxValue);
          return maxValue === 'percentage' ? `${converted}%` : converted;
        }}
        valueLabelDisplay={hideLabels ? 'off' : 'on'}
        // if we want a more precise slider,
        // we could change the step here or override where component is used
        step={100 / adjustedMaxValue}
        min={0}
        max={100}
        marks={[
          { value: lowValue, label: lowValue },
          { value: 100, label: adjustedMaxValue },
        ]}
        {...muiSliderProps}
      />
      {errorText && <FormHelperText error>{errorText}</FormHelperText>}
    </>
  );
};
