import type { LayerIconNames } from '@circadian-risk/shared';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import get from 'lodash/get';
import React from 'react';
import { GoGlobe } from 'react-icons/go';
import { IconType } from 'react-icons/lib';
import { MdGroupWork, MdTerrain } from 'react-icons/md';

// importing svg like this gives us the best flexibility. Here is more info:
// https://github.com/facebook/create-react-app/issues/1388#issuecomment-272476528
import { ReactComponent as GhBuilding } from '../assets/icons/GhBuilding.svg';
import { ReactComponent as GhCampus } from '../assets/icons/GhCampus.svg';
import { ReactComponent as GhLevel } from '../assets/icons/GhLevel.svg';

type AcceptableIconType = IconType | React.FC<React.SVGProps<SVGSVGElement>>;

export const layerIcons: Record<LayerIconNames, AcceptableIconType> = {
  // Vanilla
  organization: GoGlobe,
  region: MdGroupWork,
  country: MdTerrain,
  campus: GhCampus,
  building: GhBuilding,
  level: GhLevel,

  // Customized
  'region/state': MdGroupWork,
  site: GhCampus,
  floor: GhLevel,
  institution: GhCampus,
  'state/country': MdGroupWork,
  facility: GhBuilding,
  district: GhCampus,
};

export interface NodeIconProps extends SvgIconProps {
  layerName: string;
}

const NodeIconComponent: React.FC<NodeIconProps> = ({ layerName, ...iconProps }) => {
  if (!layerName) {
    return <div>No layer name: {layerName}</div>;
  }

  // TODO(iprokopovich)[CR-5051]: use getStandardizedLayerName here instead
  const ReactIcon = get(layerIcons, layerName.toLowerCase(), FmdGoodIcon);
  return <SvgIcon inheritViewBox component={ReactIcon} {...iconProps} />;
};

export const NodeIcon = React.memo(NodeIconComponent);
