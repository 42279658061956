import { Alert, BoxProps } from '@mui/material';
import React from 'react';

import { NoDataPlaceholderImage, VStack } from '..';

export interface NoDataCardContentProps {
  fallbackImageSrc: string;
  helperText: string;
}

export const NoDataCardContent: React.FC<NoDataCardContentProps & BoxProps> = ({
  fallbackImageSrc,
  helperText,
  ...boxProps
}) => {
  return (
    <VStack pb={4} {...boxProps}>
      <Alert severity="info">{helperText}</Alert>
      <NoDataPlaceholderImage imgSrc={fallbackImageSrc} ariaLabel="screen with warning signs" />
    </VStack>
  );
};
