import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();

export const createLayerSchema = z.object({
  name: z.string().min(1),
  relatedLayerId: z.string().uuid().optional(),
  organizationId: z.string().uuid(),
});

export type CreateLayer = z.infer<typeof createLayerSchema>;

export const layersContract = c.router(
  {
    create: {
      method: 'POST',
      path: '/',
      description: 'Creates a new layer at the lowest layer depth',
      body: createLayerSchema,
      responses: {
        201: z.object({
          id: z.string(),
          mpath: z.string(),
        }),
      },
    },
    delete: {
      method: 'DELETE',
      path: '/:id',
      body: z.NEVER,
      responses: {
        200: z.object({}),
      },
    },
  },
  {
    pathPrefix: '/layers',
  },
);
