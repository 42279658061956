/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO(v-rrodrigues)[CR-6210]: re-import the types from the graphql-types package
// import { Location_Scenario_Info_Bool_Exp, Nodes_Bool_Exp } from '@circadian-risk/graphql-types';

// TODO(v-rrodrigues)[CR-6210]
type LocationFilter = any;

// TODO(v-rrodrigues)[CR-6210]: re-import the types from the graphql-types package
type ReturnType = any; // Nodes_Bool_Exp

export const convertLocationFilterToBoolExp = (organizationId: string, locationFilter: LocationFilter): ReturnType => {
  const { baseLayerId, belongsToId, tagIds, scenarioId, filter } = locationFilter;

  // TODO(v-rrodrigues)[CR-6210]: Nodes_Bool_Exp
  const andFilters: any[] = [
    {
      organization_id: { _eq: organizationId },
    },
  ];

  if (baseLayerId) {
    andFilters.push({
      layer_id: {
        _eq: baseLayerId,
      },
    });
  }

  if (belongsToId) {
    andFilters.push({
      parent_id: { _eq: belongsToId },
    });
  }

  if (tagIds && tagIds.length) {
    andFilters.push({
      layerTags: {
        layerTag: {
          id: { _in: tagIds },
        },
      },
    });
  }

  if (scenarioId) {
    // TODO(v-rrodrigues)[CR-6210]: Nodes_Bool_Exp
    const scenarioFilters: any[] = [
      {
        scenario_id: { _eq: scenarioId },
      },
      { is_complete: { _eq: true } },
    ];

    if (filter.type !== 'none') {
      switch (filter.type) {
        case 'controlPercentage': {
          const { value } = filter;
          if (!value || !value.length) {
            break;
          }

          const [low, high] = value;
          scenarioFilters.push({
            _and: [
              {
                control_percentage: {
                  _gte: low,
                },
              },
              {
                control_percentage: {
                  _lte: high,
                },
              },
            ],
          });
          break;
        }
        case 'residualRisk': {
          const { value } = filter;
          if (!value || !value.length) {
            break;
          }

          const [low, high] = value;
          scenarioFilters.push({
            _and: [
              {
                residual_percentage: {
                  _gte: low,
                },
              },
              {
                residual_percentage: {
                  _lte: high,
                },
              },
            ],
          });
          break;
        }
        case 'threshold': {
          const { value } = filter;
          if (!value) {
            break;
          }

          scenarioFilters.push({
            threshold: {
              _eq: value,
            },
          });
          break;
        }
      }
    }

    andFilters.push({
      scenariosInfo: {
        _and: scenarioFilters,
      },
    });
  }

  return {
    _and: andFilters,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const processLocationFilterResults = <TResult extends Array<{ id: string; descendants?: any[] | null }>>(
  includeChildren: boolean,
  results: TResult,
) => {
  const nodeIds = includeChildren
    ? results.flatMap(node => [node.id, ...(node.descendants ?? []).map(d => d.id)])
    : results.map(node => node.id);

  return nodeIds;
};
