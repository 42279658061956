import partition from 'lodash/partition';

import { sumBytes } from '../helpers';
import { UploadStatus } from '../types';
import { FileManagerStore } from './useFileManagerStore';

export const fileCountsByStatusSelector = (state: FileManagerStore) => {
  let uploadingFilesCount = 0;
  let pendingFilesCount = 0;
  let notCompletedFilesCount = 0;
  let completedFilesCount = 0;

  state.files.forEach(file => {
    if (file.status === UploadStatus.Uploading) {
      uploadingFilesCount++;
    }
    if (file.status === UploadStatus.Pending) {
      pendingFilesCount++;
    }
    if (file.status !== UploadStatus.Completed) {
      notCompletedFilesCount++;
    }
    if (file.status === UploadStatus.Completed) {
      completedFilesCount++;
    }
  });

  return {
    uploadingFilesCount,
    pendingFilesCount,
    notCompletedFilesCount,
    completedFilesCount,
  };
};

export const pendingFilesBytesSelector = (state: FileManagerStore) => {
  const pendingFiles = state.files.filter(f => f.status === UploadStatus.Pending);
  return sumBytes(pendingFiles);
};

export const completedAndNotCompletedFilesSelector = (state: FileManagerStore) =>
  partition(state.files, file => file.status !== UploadStatus.Completed);

export const uploadingBytesSelector = (state: FileManagerStore) => {
  const uploadingFiles = state.files.filter(f => f.status === UploadStatus.Uploading);
  const totalBytes = sumBytes(uploadingFiles);
  const bytesPerSecond = uploadingFiles.reduce((total, f) => (total += f.uploadProgressInfo?.speed ?? 0), 0);
  const remainingBytes = uploadingFiles.reduce((total, f) => {
    if (!f.uploadProgressInfo) {
      return total;
    }
    return (total += f.uploadProgressInfo.total - f.uploadProgressInfo.loaded);
  }, 0);

  return {
    bytesPerSecond,
    totalBytes,
    remainingBytes,
  };
};
