import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import { HStack, Thumbnail } from '../..';
import { LightboxImage } from './lightbox.types';
import { LightboxWithEditingProps } from './LightboxWithEditing';
import { LightBoxProvider, useLightbox } from './useLightboxContext';

export type LightboxGalleryImage = LightboxImage & { thumbnailUrl: string };

export interface LightBoxGalleryProps {
  images: LightboxGalleryImage[];
  stack?: boolean;
  thumbnailSize?: number;
  onImageProcessing?: LightboxWithEditingProps['onImageProcessing'];
}

interface LightboxGalleryContentProps {
  thumbnails: Omit<LightboxGalleryImage, 'url'>[];
  size?: number;
  stack?: boolean;
}

const LightboxGalleryContent: React.FC<LightboxGalleryContentProps> = ({ thumbnails, size, stack }) => {
  const { handleOpen } = useLightbox();

  return (
    <HStack flexWrap="wrap" width="fit-content">
      {stack && thumbnails.length > 1 ? (
        <Thumbnail
          width={size}
          height={size}
          name={thumbnails[0].id + '.jpg'}
          onImageClick={() => handleOpen(thumbnails[0].id)}
          src={thumbnails[0].thumbnailUrl}
          stack
        />
      ) : (
        thumbnails.map(({ id, thumbnailUrl }) => (
          <Thumbnail
            key={`gallery-button-${id}`}
            width={size}
            height={size}
            name={id + '.jpg'}
            onImageClick={() => handleOpen(id)}
            src={thumbnailUrl}
          />
        ))
      )}
    </HStack>
  );
};

export const LightBoxGallery: React.FC<LightBoxGalleryProps> = ({
  images,
  stack,
  thumbnailSize,
  onImageProcessing,
}) => {
  return (
    <LightBoxProvider onImageProcessing={onImageProcessing} images={images}>
      <LightboxGalleryContent
        stack={stack}
        size={thumbnailSize}
        thumbnails={images.map(({ id, thumbnailUrl }) => ({ id, thumbnailUrl }))}
      />
    </LightBoxProvider>
  );
};
