import { getHumanReadableResponse } from '@circadian-risk/assessment-components';
import { Enum_Answer_Responses_Enum } from '@circadian-risk/client-graphql-hooks';
import { ResponseIcon, ResponseIconProps } from '@circadian-risk/presentational';
import { Box, useTheme } from '@mui/material';
import React from 'react';

const transformQuestionAnswerResponse = (
  response: Enum_Answer_Responses_Enum,
  isDeficient: boolean,
): ResponseIconProps['response'] => {
  let responseValue: ResponseIconProps['response'];
  if (isDeficient) {
    responseValue = 'deficient';
  } else if (response !== 'n_o' && response !== 'n_a') {
    responseValue = 'compliant';
  } else {
    responseValue = response;
  }
  return responseValue;
};

export interface QuestionAnswerResponseCellProps {
  value: ResponseIconProps['response'] | 'yes' | null;
  data: {
    isDeficient: boolean;
  };
}

export const QuestionAnswerResponseCell: React.FC<{
  value: Enum_Answer_Responses_Enum;
  data: { isDeficient: boolean };
}> = ({ value, data }) => {
  const theme = useTheme();
  const complianceColors = theme.palette.organizationColors.compliance;
  let color;
  if (value) {
    if (data.isDeficient) {
      color = complianceColors.deficient;
    } else {
      color = complianceColors.compliant;
    }
  }
  const responseValue = value ? transformQuestionAnswerResponse(value, data.isDeficient) : null;
  const response = value ? getHumanReadableResponse(value) : null;
  if (responseValue === 'n_a' || responseValue === 'n_o') {
    color = complianceColors.unknown;
  }
  return (
    <Box display={'flex'} alignItems={'center'} color={color}>
      <Box display={'flex'}>
        <ResponseIcon response={responseValue} />
      </Box>
      <Box ml={1}>{response}</Box>
    </Box>
  );
};
