import isBoolean from 'lodash/isBoolean';
import isNil from 'lodash/isNil';

import { AnswerFormData } from '../AnswerFormData';
import { DisabledStates, QuestionProps } from '../question.types';

export const resolveDisabledStates = (valueOrUndefined: QuestionProps['disabled']): DisabledStates => {
  // If disabled is undefined everything should be enabled
  const state = isNil(valueOrUndefined) ? false : valueOrUndefined;

  if (isBoolean(state)) {
    const valueStates: DisabledStates['values'] = {
      response: state,
      partial_compliance_multiplier: state,
      description: state,
      is_flagged: state,
    };
    const addMoreInfo = Object.values(valueStates).every(disabled => disabled);
    return {
      values: valueStates,
      addFiles: state,
      compliantAnswer: state,
      import: state,
      // Disables "add more info" if there isn't a field that we can edit
      addMoreInfo,
      provideAnswer: state,
      criticality: state,
      ofcWithTooltip: state,
    };
  }

  const resolveValueState = (key: keyof AnswerFormData): boolean => {
    // If the value is not explicitly defined then it becomes enabled
    if (isNil(state.values)) {
      return false;
    }
    if (isBoolean(state.values)) {
      return state.values;
    }
    return (state.values ?? {})[key] ?? false;
  };

  const resolveState = (value: boolean | null | undefined): boolean => {
    return !isNil(value) ? value : false;
  };

  const valueStates: DisabledStates['values'] = {
    response: resolveValueState('response'),
    partial_compliance_multiplier: resolveValueState('partial_compliance_multiplier'),
    description: resolveValueState('description'),
    is_flagged: resolveValueState('is_flagged'),
  };

  const addMoreInfo = Object.values(valueStates).every(disabled => disabled);
  return {
    values: valueStates,
    addFiles: resolveState(state.addFiles),
    compliantAnswer: resolveState(state.compliantAnswer),
    import: resolveState(Boolean(state.import)),
    addMoreInfo,
    provideAnswer: resolveState(state.provideAnswer),
    criticality: resolveState(state.criticality),
    ofcWithTooltip: resolveState(state.ofcWithTooltip),
  };
};
