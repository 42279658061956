import { FractionBar, FractionBarProps } from '@circadian-risk/presentational';
import { useTheme } from '@mui/material';
import React from 'react';

import { getStripeFill } from '../Question/components';

export interface AnswerSummaryBarProps {
  willImport?: boolean;
  importedTotal: number;
  ratifyTotal: number;
  newQuestionsCount: number;
  totalQuestionsCount: number;
}

export const AnswerSummaryBar: React.FC<AnswerSummaryBarProps> = ({
  willImport,
  importedTotal,
  newQuestionsCount,
  totalQuestionsCount,
  ratifyTotal,
}) => {
  const { palette } = useTheme();
  const { stripedBackground } = getStripeFill(palette.grey[400], palette.grey[300], palette.grey[300]);
  let fractionBarItems: FractionBarProps['items'];

  if (willImport) {
    fractionBarItems = [
      {
        count: importedTotal - ratifyTotal,
        tooltip: 'Imported (No Action Required)',
        color: palette.primary.main,
      },
      {
        count: ratifyTotal,
        tooltip: 'Imported (Require Ratification)',
        color: stripedBackground,
        textColor: palette.text.primary,
      },
      {
        count: newQuestionsCount,
        tooltip: 'New Questions (Require Answers)',
        color: palette.grey[100],
      },
    ];
  } else {
    fractionBarItems = [
      {
        count: totalQuestionsCount,
        tooltip: `Questions (Require Answers)`,
        label: `All ${totalQuestionsCount} questions will require answers`,
        color: palette.grey[100],
      },
    ];
  }

  return <FractionBar items={fractionBarItems} />;
};
