import { Frequency, FrequencyType } from '@circadian-risk/api-contract';
import { match, P } from 'ts-pattern';

import { useCreateScheduledAssessmentFormContext } from '../../../form';

export const useResetFrequencyOnChange = (openCustomRepeat: () => void) => {
  const { resetField } = useCreateScheduledAssessmentFormContext();

  const resetFrequency = (type: FrequencyType | null) => {
    const defaultValue = match(type)
      .returnType<Frequency | undefined>()
      .with('custom', () => ({
        type: 'custom',
        options: { repeatUnit: 'day', value: 1, time: '00:00 pm' },
      }))
      .with('daily', () => ({
        type: 'daily',
      }))
      .with('weekly', () => ({
        type: 'weekly',
        weekday: 'mon',
      }))
      .with('monthly', () => ({
        type: 'monthly',
        time: '00:00 pm',
      }))
      .with('annually', () => ({
        type: 'annually',
      }))
      .with('weekday', () => ({
        type: 'weekday',
      }))
      .with(P._, () => undefined)
      .exhaustive();

    if (defaultValue) {
      resetField('frequency', { defaultValue });
      if (defaultValue.type === 'custom') {
        openCustomRepeat();
      }
    }
  };
  return resetFrequency;
};
