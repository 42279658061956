import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();

const messageRoleSchema = z.enum(['user', 'assistant', 'system']);

const actionFilterOperationSchema = z.enum(['is', 'is-not', 'is-not-specified']);

const functionCallSchema = z.object({
  name: z.string(),
  arguments: z.string(),
});

const messageSchema = z.object({
  role: messageRoleSchema,
  content: z.string().min(0).optional(),
  function_call: functionCallSchema.optional(),
});

const actionsFilterSchema = z.object({
  filters: z.array(
    z.object({
      key: z.string(),
      labels: z.array(z.string()),
    }),
  ),
  chat: z.array(messageSchema),
});

const actionsFilterResponseSchema = z.object({
  filters: z.array(
    z.object({
      key: z.string(),
      operator: z.enum(['is', 'is-not']),
      label: z.string(),
    }),
  ),
  message: messageSchema,
});

export type AiMessageRole = z.infer<typeof messageRoleSchema>;
export type AiMessage = z.infer<typeof messageSchema>;
export type FunctionCall = z.infer<typeof functionCallSchema>;
export type ActionsFilter = z.infer<typeof actionsFilterSchema>;
export type ActionsFilterResponse = z.infer<typeof actionsFilterResponseSchema>;
export type ActionFilterOperation = z.infer<typeof actionFilterOperationSchema>;

export const aiContract = c.router(
  {
    completeFilter: {
      method: 'POST',
      path: '/actions-filter',
      body: actionsFilterSchema,
      responses: {
        201: actionsFilterResponseSchema,
      },
    },
  },
  {
    pathPrefix: '/ai',
  },
);
