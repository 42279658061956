import { useMediaQuery, useTheme } from '@mui/material';

export const useRecommendedPaginationPageSize = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.down('lg'));
  let paginationPageSize = 15;
  if (isSmall) {
    paginationPageSize = 7;
  } else if (isMedium) {
    paginationPageSize = 12;
  }
  return paginationPageSize;
};
