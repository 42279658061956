import { Typography } from '@mui/material';

import { useSelectedLocationIds } from '../../../hooks/useSelectedLocationIds';

export const LocationsStepStepperSubtitle: React.FC = () => {
  const locationsCount = useSelectedLocationIds().length;

  if (locationsCount === 0) {
    return null;
  }

  return <Typography variant="body2">{locationsCount} Locations Selected</Typography>;
};
