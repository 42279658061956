import { ChevronRight, ModeEditOutline as PencilIcon } from '@mui/icons-material';
import { Box, Button, ButtonProps, Tooltip, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { PercentCircularProgress } from '.';

const ICON_SIZE = 24;
const BUTTON_WIDTH = 60;
const BORDER_WIDTH = 2;
const BUTTON_HEIGHT = 32;

export interface ProgressButtonProps extends ButtonProps {
  percentComplete: number | null;
  active?: boolean;
  link?: string;
  /**
   * External links will render a native anchor element and not NavLink component
   * @default false
   */
  isExternalLink?: boolean;
  disableTooltip?: boolean;
}

/**
 * 1. Animate transition of chevron sliding out when transitioning to active state
 * 2. Animate checkmark appearance by animating the path, slightly enlarging the background of the checkmark and fading in
 * 3. Animate transition from progress indicator to checkmark
 */
export const ProgressButton: React.FC<ProgressButtonProps> = ({
  percentComplete,
  active,
  link,
  disableTooltip,
  isExternalLink = false,
  ...buttonProps
}) => {
  const theme = useTheme();

  const isStarted = percentComplete != null;

  const color = !isStarted
    ? theme.palette.action.active
    : percentComplete === 100
    ? theme.palette.success.main
    : theme.palette.primary.light;

  const contrastColor = theme.palette.getContrastText(color);

  const statusTooltip = !isStarted ? 'Not Started' : `${percentComplete}% Complete`;

  const showPencil = !isStarted;
  const showProgress = isStarted;
  const showChevron = !active;

  const [component, href, to] = isExternalLink ? ['a', link, undefined] : [NavLink, undefined, link];

  return (
    <Tooltip title={disableTooltip ? '' : statusTooltip} placement="top" arrow enterDelay={1500}>
      {/* this div is needed here to allow tooltip to show up for disabled button: 
      https://material-ui.com/components/tooltips/#disabled-elements */}
      <span id="tooltip-span">
        <Button
          sx={{
            height: BUTTON_HEIGHT,
            width: BUTTON_WIDTH,
            minWidth: BUTTON_WIDTH,
            borderRadius: 100,
            backgroundColor: active ? `${color}!important` : undefined,
            borderWidth: `${BORDER_WIDTH}px !important`, // by design request CR-1730
          }}
          color="secondary"
          {...{ href, to }}
          component={link ? component : 'button'}
          variant={'outlined'}
          disableElevation
          {...buttonProps}
          disabled={active || buttonProps.disabled}
        >
          <Box
            display="flex"
            alignContent="center"
            justifyContent="center"
            height={ICON_SIZE}
            width={ICON_SIZE}
            sx={{ opacity: buttonProps.disabled ? theme.palette.action.disabledOpacity : 1 }}
          >
            <Box
              display="flex"
              alignContent="center"
              justifyContent="center"
              component={motion.span}
              initial={false}
              height={ICON_SIZE}
              position="absolute"
              animate={{
                left: active ? (BUTTON_WIDTH - ICON_SIZE - BORDER_WIDTH * 2) / 2 : 4,
                opacity: showProgress ? 1 : 0,
                scale: showProgress ? 1 : 0.6,
                rotate: showProgress ? 0 : 420,
              }}
            >
              <PercentCircularProgress value={percentComplete ?? 0} progressBgColorWhite={active} disableTooltip />
            </Box>

            <Box
              display="flex"
              alignContent="center"
              justifyContent="center"
              position="absolute"
              left={4}
              height={ICON_SIZE}
              component={motion.span}
              initial={false}
              animate={{
                opacity: showPencil ? 1 : 0,
                marginLeft: showChevron ? 0 : 12,
                scale: showPencil ? 1 : 0.6,
                rotate: showPencil ? 0 : 180,
              }}
            >
              <PencilIcon sx={{ color: active ? contrastColor : undefined, p: '1px' }} />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              borderRadius: 50,
              top: -2,
              height: ICON_SIZE,
              paddingRight: 0,
              paddingLeft: `${(BUTTON_WIDTH + BORDER_WIDTH * 2) / 2 + BORDER_WIDTH * 2}px`,
              paddingY: `${BUTTON_HEIGHT / 2}px`,
              position: 'absolute',
            }}
            component={motion.span}
            initial={false}
            animate={{
              right: showChevron ? -2 : -8,
              opacity: showChevron ? 1 : 0,
            }}
            whileHover={{ right: 1 }}
            transition={{ ease: 'easeOut' }}
          >
            <ChevronRight />
          </Box>
        </Button>
      </span>
    </Tooltip>
  );
};
