import { FCC } from '@circadian-risk/front-end-utils';
import { Enum_Property_Kind_Enum } from '@circadian-risk/graphql-types';
import CheckIcon from '@mui/icons-material/Check';
import { Box, BoxProps, Chip, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import capitalize from 'lodash/capitalize';
import isNil from 'lodash/isNil';
import React from 'react';

export const DetailDescriptionList: React.FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box
      component="div"
      marginTop={0}
      marginBottom={0}
      sx={theme => ({
        '& > :not(:first-of-type)': {
          marginTop: theme.spacing(1),
        },
      })}
      {...props}
    >
      {children}
    </Box>
  );
};

export const DetailDescription: React.FC<BoxProps> = ({ children, ...props }) => (
  <Box display="flex" flexWrap="wrap" flexDirection="column" {...props}>
    {children}
  </Box>
);

export const DetailDescriptionLabel: FCC = ({ children }) => {
  const theme = useTheme();

  return (
    <Box color={theme.palette.grey[700]} component="div">
      <Typography fontSize="10px">{children}</Typography>
    </Box>
  );
};

export const DetailDescriptionContent: FCC = ({ children }) => (
  <Box component="div" m={0}>
    {children}
  </Box>
);

export const PropertyDescriptionText: React.FC<{ value?: string | null }> = ({ value }) => {
  return <Typography variant="body2">{value}</Typography>;
};

export const PropertyDescriptionDate: React.FC<{ value?: Date | string | null }> = ({ value }) => {
  const formattedDate = value ? dayjs(value).format('MMM DD, YYYY') : '';
  return <Typography variant="body2">{formattedDate}</Typography>;
};

export const PropertyDescriptionDropdown: React.FC<{ value?: string | null }> = ({ value }) => {
  return <Typography variant="body2">{value}</Typography>;
};

export const PropertyDescriptionCheckbox: React.FC<{ value?: string[] | null }> = ({ value }) => {
  return (
    <Box display="flex" flexDirection="row" flexWrap="wrap">
      {value?.map(v => {
        return (
          <Box key={v} m={0.5}>
            <Chip size="small" icon={<CheckIcon />} label={v} color="primary" />
          </Box>
        );
      })}
    </Box>
  );
};

export const PropertyDescriptionToggle: React.FC<{ value?: boolean }> = ({ value }) => {
  let text: string | null;
  if (isNil(value)) {
    text = null;
  } else {
    text = capitalize(String(value));
  }
  return <Typography variant="body2">{text}</Typography>;
};

export const kindToComponent: Record<Enum_Property_Kind_Enum, React.FC<{ value: any }>> = {
  text: PropertyDescriptionText,
  date: PropertyDescriptionDate,
  numeric: PropertyDescriptionText,
  dropdown: PropertyDescriptionDropdown,
  checkbox: PropertyDescriptionCheckbox,
  toggle: PropertyDescriptionToggle,
};
