import { createBadgeContent } from '@circadian-risk/file-manager';
import { FileList, FileListItem } from '@circadian-risk/form';
import {
  convertAttachmentFilesToLightboxImages,
  LightBoxProvider,
  StandardsGroupWithDialog,
  VStack,
} from '@circadian-risk/presentational';
import { Collapse } from '@mui/material';
import sortBy from 'lodash/sortBy';
import { useCallback, useMemo } from 'react';

import { OptionsSection, OptionsSectionActions, OptionsSectionProps } from '../../OptionsForConsideration';
import { AnswerFormData, AnswerFormName } from '../AnswerFormData';
import {
  ActiveFieldsState,
  DisabledStates,
  QuestionProps,
  SetActiveFieldCallback,
  SharedQuestionComponentProps,
} from '../question.types';
import { QuestionDescription } from './QuestionDescription';

export type QuestionMoreInfoPanelProps = SharedQuestionComponentProps &
  Pick<QuestionProps, 'files' | 'standards' | 'optionsSectionProps'> & {
    activeFields: ActiveFieldsState;
    setActiveField: SetActiveFieldCallback;
    expanded: boolean;
    disabledStates: Pick<DisabledStates['values'], 'description'> & Pick<DisabledStates, 'addFiles' | 'ofcWithTooltip'>;
    value: NonNullable<QuestionProps['value']>;
  };

export const QuestionMoreInfoPanel: React.FC<QuestionMoreInfoPanelProps> = ({
  activeFields,
  setActiveField,
  standards,
  onAddFiles,
  onImageProcessing,
  handleChange,
  expanded,
  files,
  disabledStates,
  value,
  optionsSectionProps,
}) => {
  const {
    addFiles: addFilesDisabled,
    description: descriptionDisabled,
    ofcWithTooltip: newOfcDisabled,
  } = disabledStates;

  const { description } = value;

  const onDescriptionChange = useCallback(
    (newDescription: AnswerFormData['description']) => {
      handleChange({
        description: newDescription,
      });
    },
    [handleChange],
  );

  const handleOptionRemove: OptionsSectionActions['onRemove'] = option => {
    if (optionsSectionProps.options.length === 1) {
      setActiveField(AnswerFormName.options, false);
    }
    optionsSectionProps.actions && optionsSectionProps.actions.onRemove(option);
  };

  const lightboxImages = useMemo(() => convertAttachmentFilesToLightboxImages(files), [files]);
  const filesSorted = sortBy(files, file => file.name);

  const ofcActions: OptionsSectionProps['actions'] =
    newOfcDisabled || !optionsSectionProps.actions
      ? undefined
      : {
          ...optionsSectionProps.actions,
          onRemove: handleOptionRemove,
        };

  return (
    <Collapse in={expanded} mountOnEnter unmountOnExit aria-label="more info panel">
      <VStack pb={1}>
        <StandardsGroupWithDialog standards={standards ?? []} />
        <LightBoxProvider images={lightboxImages} onImageProcessing={!addFilesDisabled ? onImageProcessing : undefined}>
          <FileList
            onAddButtonClicked={!addFilesDisabled && onAddFiles ? onAddFiles : undefined}
            title="Photos and Files"
            dense
          >
            {filesSorted.map(file => (
              <FileListItem
                key={file.id}
                divider={false}
                dense
                {...file}
                onDelete={!addFilesDisabled ? file.onDelete : undefined}
                badgeContent={file.status ? createBadgeContent(file.status) : file.badgeContent}
              />
            ))}
          </FileList>
        </LightBoxProvider>

        <QuestionDescription
          active={activeFields.description}
          disabled={descriptionDisabled}
          value={description}
          onChange={onDescriptionChange}
          onDelete={() => {
            setActiveField(AnswerFormName.description, false);
            handleChange({
              description: null,
            });
          }}
        />

        {optionsSectionProps && activeFields.options && (
          <OptionsSection options={optionsSectionProps.options} actions={ofcActions} />
        )}
      </VStack>
    </Collapse>
  );
};
