import { useRelevantLocations } from '@circadian-risk/assessment-components';
import { SmartLocationPicker } from '@circadian-risk/form';
import intersection from 'lodash/intersection';
import { Controller, useFormContext } from 'react-hook-form';

import { createScheduleAssessmentForm, CreateScheduledAssessmentFormSchema } from '../../../form';
import { useSelectedTemplate } from '../hooks/useSelectedTemplate';

const { ariaLabels } = createScheduleAssessmentForm;

export const DefaultLocationFormField: React.FC = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<CreateScheduledAssessmentFormSchema>();
  const selectedTemplate = useSelectedTemplate();
  const relevantLocations = useRelevantLocations(selectedTemplate?.scoredLayerId);
  const nodesWithIntersectedTags = selectedTemplate
    ? relevantLocations.filter(l => {
        const locationTagIds = l.tags.map(t => t.id);
        const templateTagIds = selectedTemplate.tags.map(t => t.layer_tag_id);

        const intersectedRows = intersection(locationTagIds, templateTagIds);
        return intersectedRows.length > 0;
      })
    : [];

  const allowedLocationIds = nodesWithIntersectedTags.map(location => location.id);

  // TODO(v-rrodrigues):[CR-5417]: re-use this across single and scheduled assessment xp
  // there's some common logic that could be extracted
  return (
    <Controller
      name="locationsIds"
      control={control}
      render={({ field: { value, onChange } }) => {
        return (
          <SmartLocationPicker
            value={value}
            multiple
            disabled={!selectedTemplate}
            label={ariaLabels.locationsIds}
            onChange={newValue => onChange(newValue)}
            allowedNodeIds={allowedLocationIds}
            errorMessage={errors.locationsIds?.message}
            helperText={!selectedTemplate ? 'Select an assessment template to see available locations.' : undefined}
          />
        );
      }}
    />
  );
};
