import { FCC } from '@circadian-risk/front-end-utils';
import { Box } from '@mui/material';
import React from 'react';

export const TablePage: FCC = ({ children }) => {
  return (
    <Box height="100%" width="100%" display="flex" flexDirection="column">
      {children}
    </Box>
  );
};
