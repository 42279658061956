import HiddenSemanticHeader from '@web-app/components/Layout/components/customHeaders/HiddenSemanticHeader';
import React from 'react';

const headerMatcher = (currentPath: string): JSX.Element | undefined => {
  if (currentPath.match(/\/organization\/.+\/assessments\/.+\/perform/g)) {
    return <HiddenSemanticHeader />;
  }
  return undefined;
};

export default headerMatcher;
